import React, { useState, useContext, useEffect } from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { TabsWrapper, SubTab, tabsWrapperScrollButtons, tabsWrapperVariants } from "../../../ui-components/ui-elements/tab/Tabs";
import { MyPendingSchedule, UnAssignedTasks, AssignedTasks } from "./my-schedule-checklist/MyScheduleChecklist";
import { PendingSchedule } from "./schedule-check-list/ScheduleChecklist";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import {_get} from "../../../../helpers/common-helpers/lodash.wrappers";


const AllPendingSchedule = (props) => {
  
  return (
    <DashboardTemplate
      pageTitle="Manage Task"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        { urlTo: "/all_tasks", displayName: "Tasks" },
      ]}
    >
      
      <AllTasks props={{ ...props }} />
    </DashboardTemplate>
  )
};
const AllTasks = (props) => {
  const [authStatus] = useContext(AuthContext);


  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  console.log('ppp', props.props.location);
  const [activeStep, setActiveStep] = useState(roleCategoryCode === 'OPT' && props.props.location.state === undefined ? "my_pending_tasks" : "unassigned_tasks");
 
  useEffect(() => {
    if(roleCategoryCode === 'OPT' && props.props.location.state === undefined) {
      setActiveStep("my_pending_tasks")
    }
  }, [props.props.location.state])
  return (
    <TabsWrapper
      isTabFullWidth={false}
      active={activeStep}
      variant={tabsWrapperVariants.scrollable}
      scrollButtons={tabsWrapperScrollButtons.on}
      onChangeFn={(event) => setActiveStep(event.value)}
    >
      <SubTab tabid="my_pending_tasks" header="All Approval Request Tasks" >
        <MyPendingSchedule row={{ ...props.row }} />
      </SubTab>
      <SubTab tabid="unassigned_tasks" header="Un-assigned Tasks" >
        <UnAssignedTasks row={{ historyPushData: props.props.location.state, ...props.row }} />
      </SubTab>
      <SubTab tabid="my_tasks" header="Assigned Tasks" >
        <AssignedTasks row={{ ...props.row }} />
      </SubTab>

    </TabsWrapper>
  )
}
export default AllPendingSchedule;
