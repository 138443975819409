/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 15:51:13
 * @Last Modified by: Nipuna Chandimal
 * @Last Modified time: 2021-01-13 10:22:35
 */

import React, { Fragment } from "react";

import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import {ViewPublicPortalTransactions , ReconciledTransactionHistory , ReconcilePublicPortalTransaction} from "./pages/ManageTransactionsReconciliation";
import authorized from "../../hoc-middlewares/Authorized";
const ManageTransactionsReconciliationRoutes = () => {
  return (
    <Fragment>
      <Navigate exact={true} path="/manage-transactions-reconciliation" routeKey={"manage-transactions-reconciliation"} component={authorized(ViewPublicPortalTransactions)} routePermissions={permissions.RECONCILE_TRANSACTIONS.permissions} />
      <Navigate exact={true} path="/reconcile-public-portal-transaction" routeKey={"reconcile-public-portal-transaction"} component={authorized(ReconcilePublicPortalTransaction)} routePermissions={permissions.NONE.permissions} />
      <Navigate exact={true} path="/reconciled-transaction-history" routeKey={"reconciled-transaction-history"} component={authorized(ReconciledTransactionHistory)} routePermissions={permissions.NONE.permissions} />
       </Fragment>
  );
};
 
export default ManageTransactionsReconciliationRoutes;
