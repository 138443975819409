/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Document Uploader
 * @Date: 2021-07-08 13:24:56
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 13:25:13
 */
import React, { useContext, useState } from "react";

import { UICard } from "../common/BaseElements";
import { FormWrapper, InputBoxWithState, SubmitButton } from "../form";
import { UploadComponent } from "../upload/UploadComponent";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import { templateTypes } from "../../../../config/template.config";
import { actionBtnTypes, apiResponseStoringType, DataTable } from "../table/DataTable";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { fileUploadsDetailsAPI, uploadsAPI, uploadDeleteAPI, uploadDetailSaveOriginalAPI, uploadDeleteOriginalAPI } from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { RowNo, DeleteBtn, DeleteUI, DownloadUI } from "./documents-uploader-includes/documents-uploader-includes";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { UIContext } from "../../context-providers/UIContext.provider";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { InputButton } from "../form";


const DocumentsUploader = ({
    elementWrapperStyle = "",
    formGroupName = "",
    isUploadFn = true,
    isDownload = true,
    isDelete = true,
    tableApiUrl = fileUploadsDetailsAPI,
    uploadsApiUrl = uploadsAPI.url,
    requestId = "",
    requestType,
    uiType

}) => {
    const [, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    const [btnClicked, setBtnClicked] = useState(false)
    const [authStatus, authAction] = useContext(AuthContext);
    // const [tableState] = useContext(CoreContext);
    // const tableData = _get(tableState, `dataTableResponses.${formGroupName}.results`, "").length;
    const [coreState, coreAction] = useContext(CoreContext);
    const maxCount = 10;

    const download = async (fileUrl, fileName, props) => {
        console.log('PPPP', props);
        var a = document.createElement("a");
        a.href = await fileUrl;
        a.setAttribute("download", fileName);
        a.click();
        props.onClosePopupFn();
    }
    const DownloadBtn = (props) => {
        return (
            <InputButton
                startIcon={"fas fa-download"}
                btnText={"Yes"}
                onClickBtnFn={() => download(`${props.row.document_path}`, props.row.file_name, props)}
            />
        )
    }

    const getActionTypesList = () => {
        const list = [];



        //   download("/static/ad920d8be55b3543cac62c795aa80df5/codesource.png", "Codesource_Logo.png");

        if (isDownload) {
            list.push({
                actionType: actionBtnTypes.other,
                actionBtn: {
                    tooltip: 'Download',
                    icon: 'fas fa-download',
                },
                modelContentElement: DownloadUI,
                dialogPopupProps: {
                    isFullScreen: false,
                    isFullWidth: false,
                    dialogTitle: "Document Download",
                    closeButton: {
                        btnText: "No",
                        startIcon: "far fa-times-circle",
                    },
                    isCustomButton: true,
                    customButtonComponent: DownloadBtn,
                },
            });
        }

        if (isDelete) {
            list.push({
                actionType: actionBtnTypes.delete,
                actionBtn: { tooltip: 'Remove' },
                modelContentElement: DeleteUI,
                dialogPopupProps: {
                    isFullScreen: false,
                    isFullWidth: false,
                    dialogTitle: "Delete File",
                    closeButton: {
                        btnText: "No",
                        startIcon: "far fa-times-circle",
                    },
                    isCustomButton: true,
                    customButtonComponent: DeleteBtn,
                    customButtonComponentProps: {
                        dataTableKey: `${formGroupName}_dataTableKey`,
                        tableApiUrl: requestType ? uploadDeleteOriginalAPI : uploadDeleteAPI,
                        requestType: requestType
                    }
                },
                checkBeforeActionRenderFn: (rawData) => {
                    return (_get(rawData, "row.created_by", "") === _get(authStatus, "authUser.id", ""))
                }
            });
        }

        return list;
    };

    return (
        <div className={`row ${elementWrapperStyle}`}>
            <div className={"col-md-12"}>

                <UICard
                    elementStyle={isUploadFn ? "" : "hidePaddingClass"}
                >
                    {
                        (isUploadFn) ? (
                            <FormWrapper
                                setGroupName={formGroupName}
                                style={{padding: 0}}
                            >
                                <div className={"row defaultMarginBottom"}>
                                    <div className={"col-md-2 defaultPaddingTop"}>
                                        <p>Upload Document</p>
                                    </div>

                                    <div className={"col-md-4 defaultPaddingTop"}>
                                        <div>    <UploadComponent
                                            formGroupName={formGroupName}
                                            formKey={"file_name"}
                                            isMultiple={true}
                                            labelText={"Test"}
                                            getApiUrl={`${uploadsApiUrl}/`}
                                            uploadApiUrl={uploadsApiUrl}
                                            formObjectAppendKey={"files"}
                                            isButtonOnly={true}
                                        /></div>
                                        <br></br>
                                        <p >Max File Size : 15MB</p>
                                    </div>

                                    <div className={"col-md-4"}>
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName={formGroupName}
                                            inputName={"description"}
                                            labelText="Document Description"
                                            isMultiline={true}
                                            rows={2}
                                            isRequired={true}
                                            maxLength={150}
                                        />
                                    </div>

                                    <div className={"col-md-2 defaultPaddingTop"}>
                                        <SubmitButton
                                            btnText={"Add"}
                                            formGroupName={formGroupName}
                                            dataTableKey={`${formGroupName}_dataTableKey`}
                                            startIcon={"fas fa-plus"}
                                            isValidate={true}
                                            validationObject={{
                                                fileds: {
                                                    description: "Document Description",
                                                    // file_name: "File Name"
                                                },
                                                rules: {
                                                    description: "required",
                                                    // file_name: "required"
                                                },
                                                message: {
                                                    "description.required": "Document Description is required.",
                                                    // "file_name.required": "Choose a file to add"
                                                }
                                            }}
                                            callApiObject={{
                                                isSetHeaders: true,
                                                multipart: false,
                                                method: "post",
                                                onUpload: false
                                            }}
                                            apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: apiResponseStoringType.responseLinkToFrom,
                                            }}
                                            onGetAPIEndPointFn={(formObject) => {
                                                return {
                                                    url: uploadDetailSaveOriginalAPI.url,
                                                    key: uploadDetailSaveOriginalAPI.key
                                                }
                                            }}

                                            onChangeRequestBodyFn={(formObject) => {
                                                if (formObject && formObject.file_name && formObject.file_name.length !== 0) {
                                                    if (_get(coreState, `dataTableResponses`, null) !== null && _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey`, null) !== null && _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey.results`, []) && _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey.results`, []).length < maxCount) {
                                                        return getDataByFormObject({
                                                            ...formObject,
                                                            file_name: formObject.file_name && formObject.file_name[formObject.file_name.length - 1],
                                                            document_reference: formObject.file_name_doc_references && formObject.file_name_doc_references[formObject.file_name_doc_references.length - 1],
                                                            ui_request_id: requestId,
                                                            document_path: formObject.file_name_doc_references && formObject.file_name_doc_references[formObject.file_name_doc_references.length - 1],
                                                            description: formObject.description
                                                        });
                                                    } else {
                                                        uiAction.setFlashMessage({
                                                            status: true,
                                                            message: `Maximum files count that can be attached is ${maxCount}.`,
                                                            messageType: "error",
                                                        });
                                                        // setBtnClicked(false)
                                                    }

                                                } else {
                                                    uiAction.setFlashMessage({
                                                        status: true,
                                                        message: 'Choose a file to add',
                                                        messageType: "error",
                                                    });
                                                    // setBtnClicked(false)
                                                }
                                                // }


                                            }}
                                            onResponseCallBackFn={(error, result) => {
                                                if (!error && result.data.data.file_name !== null &&
                                                    _get(coreState, `dataTableResponses`, null) !== null &&
                                                    _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey`, null) !== null &&
                                                    _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey.results`, []) &&
                                                    _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey.results`, []).length < maxCount
                                                ) {
                                                    uiAction.setFlashMessage({
                                                        status: true,
                                                        message: `Document uploaded successfully.`,
                                                        messageType: "success",
                                                    });
                                                    // setBtnClicked(false)
                                                    formAction.initFromObjectFn(formGroupName)
                                                        .isBackProsess(false)
                                                        .setDescription(formGroupName)
                                                        .setGroupName(formGroupName)
                                                        .setLinkWithOthers(formGroupName)
                                                        .setFormObject({})
                                                        .setOnLoad(false)
                                                        .generate();
                                                }
                                                // setBtnClicked(false)
                                                // }

                                            }}
                                        />
                                    </div>
                                </div>
                            </FormWrapper>
                        ) : null
                    }
                    <div className={""}>
                        <div className={""}>
                            <DataTable
                                isSetTableHeader={false}
                                apiUrl={tableApiUrl}
                                dataTableKey={`${formGroupName}_dataTableKey`}
                                isSetSearchFrom={false}
                                tableHeaderList={[
                                    { displayName: "No.", key: "__no__", isSort: false },
                                    {
                                        displayName: "Uploaded Date & Time", key: "created_at", isSort: false, onPrintFn: (rawData) => {
                                            return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM");
                                        }
                                    },
                                    { displayName: "Uploaded by", key: "created_by_name", isSort: false },
                                    { displayName: "Document Description", key: "description", isSort: false },
                                ]}
                                isSetAction={isDownload || isDelete}
                                actionTypeList={getActionTypesList()}
                                specificComponents={{
                                    "__no__": RowNo
                                }}
                            />
                        </div>

                    </div>
                </UICard>

            </div>
        </div>
    );
};

export default DocumentsUploader;