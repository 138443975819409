/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-10-09 08:11:25 
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-08 16:26:18
 */

import React,{Fragment, useState} from 'react';
import {NormalLicense} from "./NormalLicense";
import {ViewQuotation} from "./Quotation";
import {DataTable,actionBtnTypes} from "../../../../ui-components/ui-elements/table/DataTable";
import {FormWrapper,InputBoxWithState} from "../../../../ui-components/ui-elements/form";

const emptyFun = (...para) => undefined;

const RevenueLicenseQuotation=()=>{
    return (
        <Fragment>
            <div className={"col-md-12"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <strong>To: </strong> M.L.K Jayasundara
                    </div>
                    <div className={"col-md-6"}>
                        <strong>Quotation ID: </strong> 00000012
                    </div>
                    <div className={"col-md-6"}>
                        <strong>Address: </strong> NO:5,SULEIMAN TERRACE,COLOMBO 05.
                    </div>
                    <div className={"col-md-6"}>
                        <strong>Date & Time: </strong> 12-01-2020 14:20
                    </div>

                    <div className={"col-md-12 defaultMarginTop"}>
                        <div className={"row"}>

                            <DataTable
                                tableName="Vehicle List"
                                dataTableKey={"vehicalList_qt"}
                                tableHeaderList={[
                                    { displayName: "Vehicle ID", key: "vehicleID", isSort: false },
                                    { displayName: "Chassis Number", key: "chassis", isSort: false },
                                    { displayName: "Vehicle Class", key: "vehicleClass", isSort: false },
                                    { displayName: "Owner's Name", key: "owner", isSort: false },
                                    { displayName: "Licence Type", key: "licenseType", isSort: false },
                                    { displayName: "Validity Period (From - To)", key: "validity", isSort: false },
                                    { displayName: "Arrears(LKR)", key: "arrears", isSort: false },
                                    { displayName: "Fee(LKR)", key: "fee", isSort: false },
                                    { displayName: "Fine(LKR)", key: "fine", isSort: false },
                                    { displayName: "Additions(LKR)", key: "additions", isSort: false },
                                    { displayName: "Deductions(LKR)", key: "deductions", isSort: false },
                                    { displayName: "Total(LKR)", key: "total", isSort: false },
                                ]}
                                tableBodyList={[
                                    {
                                        "vehicleID":"KS-5555",
                                        "chassis":"KSP902053525",
                                        "vehicleClass":"MOTOR CAR",
                                        "owner":"R.M.L JAYASUNDARA",
                                        "licenseType":"NL",
                                        "validity":"27-04-2018 - 26-04-2019",
                                        "arrears":"0.00",
                                        "fee":"2,200.00",
                                        "additions":"0.00",
                                        "deductions":"0.00",
                                        "total":"2,200.00",
                                    },{
                                        "vehicleID":"KB-9855",
                                        "chassis":"LKP900003599",
                                        "vehicleClass":"MOTOR CAR",
                                        "owner":"R.M.L JAYASUNDARA",
                                        "licenseType":"NL",
                                        "validity":"27-04-2018 - 26-04-2019",
                                        "arrears":"0.00",
                                        "fee":"2,600.00",
                                        "additions":"1000.00",
                                        "deductions":"0.00",
                                        "total":"3,600.00",
                                    },{
                                        "vehicleID":"",
                                        "chassis":"",
                                        "vehicleClass":"",
                                        "owner":"Total Amount(LKR)",
                                        "licenseType":"",
                                        "validity":"",
                                        "arrears":"0.00",
                                        "fee":"4,800.00",
                                        "additions":"1000.00",
                                        "deductions":"0.00",
                                        "total":"5,800.00",
                                    }
                                ]}
                                isSetAction={false}
                                isSetTableFooter={false}
                            />

                            <div className={"col-md-12 defaultMarginTop"}>
                                <div className={"row"}>
                                    <p>
                                    <b>Special Note:</b>All Fee and Arrears calculations are baseed on the Quotation Created Date - {"<Quotation Created Date in DD/MM/YYYY format>"} and will be valid only for the Quotation Created Date 
                                    </p>

                                    <div className="col-md-12 defaultMarginTop">
                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <strong>Generated By:</strong> M.L.K Gunawardana (PDMT-Admin)
                                        </div>
                                        <div className="col-md-6 text-center">
                                        </div>
                                        <div className="col-md-6">
                                            <strong>Generated Location:</strong> Western Province (Kaduwela)
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <strong>...................................</strong> <br/>
                                            <strong>Authorised Signature</strong>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>

                
            </div>
        </Fragment>
    )
}

const QuotationSRS=({
    setShowHideFooterFN=emptyFun
})=>{

    const [getState,setState]=useState([]);

    const addNewQuotationFn=()=>{
        setState([
            ...getState,
            ...[{
              "sequenceNo":"#0012",
              "vehicleID":"KS-5555",
              "chassisNumber":"KSP902053525",
              "vehicleClass":"MOTOR CAR",
              "ownerName":"R.M.L Jayasundara",
              "licenceType":"NL",
              "validityPeriod":"01/12/2019 to 31/12/2020",
              "arrears":"00.00",
              "fine":"220.00",
              "fee":"2,200.00",
              "additions":"00.00",
              "deductions":"00.00",
              "total":"2,420.00"
            }]
          ]);
    }
   
    return (
        <div className="quotationWrapper">
            <NormalLicense 
                setShowHideFooterFN={(status)=>setShowHideFooterFN(status)} 
                uiType={"quotation"}
                addNewQuotationFn={()=>addNewQuotationFn()}
            />


            {
                (getState.length>0)?(
                    <div className={"fullWidthDiv floatLeft defaultMarginTop vehicalListFromWrapper"}>

                    <div className={"fullWidthDiv floatLeft defaultMarginBottom"}>
                        <FormWrapper
                            setFormObject={{}}
                            setGroupName={"vehicalListFrom"}
                        >
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputBoxWithState
                                            elementWrapperStyle={""}
                                            labelText={"To"}
                                            formGroupName={"vehicalListFrom"}
                                            inputName={"to"}
                                            isRequired={true}
                                        />
                                        <InputBoxWithState
                                            elementWrapperStyle={""}
                                            labelText={"Address Line 1"}
                                            formGroupName={"vehicalListFrom"}
                                            inputName={"address1"}
                                            isRequired={true}
                                        />

                                    </div>
                                    <div className="col-md-6">
                                        <InputBoxWithState
                                            elementWrapperStyle={""}
                                            labelText={"Address Line 2"}
                                            formGroupName={"vehicalListFrom"}
                                            inputName={"address2"}
                                        />
                                        <InputBoxWithState
                                            elementWrapperStyle={""}
                                            labelText={"City"}
                                            formGroupName={"vehicalListFrom"}
                                            inputName={"city"}
                                            isRequired={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </FormWrapper>
                    </div>


                    <DataTable
                        tableName="Vehicle List"
                        dataTableKey={"vehicalList"}
                        tableHeaderList={[                        
                        { displayName: "Vehicle ID", key: "vehicleID", isSort: false },
                        { displayName: "Chassis Number", key: "chassisNumber", isSort: false },
                        {
                            displayName: "Vehicle Class",
                            key: "vehicleClass",
                            isSort: false,
                        },
                        { displayName: "Owner’s Name", key: "ownerName", isSort: false },
                        { displayName: "Licence Type", key: "licenceType", isSort: false },
                        { displayName: "Validity Period (From – To)", key: "validityPeriod", isSort: false },
                        {
                            displayName: "Arrears (LKR)",
                            key: "arrears",
                            isSort: false,
                        },                        
                        {
                            displayName: "Fee (LKR)",
                            key: "fee",
                            isSort: false,
                        },
                        {
                            displayName: "Fine (LKR)",
                            key: "fine",
                            isSort: false,
                        },
                        {
                            displayName: "Additions (LKR)",
                            key: "additions",
                            isSort: false,
                        },
                        {
                            displayName: "Deductions (LKR)",
                            key: "deductions",
                            isSort: false,
                        },
                        {
                            displayName: "Total (LKR)",
                            key: "total",
                            isSort: false,
                        },
                        ]}
                        tableBodyList={getState}
                        actionTypeList={[ 
                            {
                                actionType: actionBtnTypes.view,   
                                modelContentElement:ViewQuotation,
                                dialogPopupProps:{
                                  "isFullScreen":true,
                                  "dialogTitle":"View Quotation",
                                  "isCustomButton":false,       
                                }
                            },
                            {
                                actionType: actionBtnTypes.delete,
                                actionBtn:{
                                    icon:"mdi mdi-trash-can-outline",
                                    tooltip:"Remove",
                                    onClickBtnFn:()=>{
                                        getState.pop();
                                        setState([...getState])
                                    }
                                },                   
                              },                       
                        ]}
                        isSetTableFooter={true}
                        isSetTableHeader={true}          
                    />
            </div>
                ):(null)
            }

            
        </div>
    )
}


export {
    QuotationSRS,
    RevenueLicenseQuotation
}