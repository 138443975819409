/*
 * @Author: Anjula Karunarathne 
 * @Date: 2021-01-11 10:39:23 
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-18 11:30:08
 */

import React, { Fragment, useState, useEffect, useContext } from 'react';
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { emptyFun, UICard } from '../../../ui-components/ui-elements/common/BaseElements';
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { templateTypes } from "../../../../config/template.config";
import { thousandSeparator } from "../../../../helpers/common-helpers/common.helpers";
import { isEmpty } from 'lodash';
import {
  _getInt,
  _get,
} from "../../../../helpers/common-helpers/lodash.wrappers";
import {
  DatePickerWithState,
  InputButton, SubmitButton
} from "../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { RevenueSettlementNote } from './includes/RevenueSettlementNote';
import { ViewTransactions } from './includes/ViewTransactions';
import { settlementOutstandingAPI, settlementPostAPI } from "../../../../config/apiUrl.config";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { useHistory } from "react-router-dom";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { isEmptyValue } from '../../../../helpers/common-helpers/common.helpers';
import {AccountNumberListAPI}from "../../../../config/apiUrl.config"
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { UIContext } from '../../../ui-components/context-providers/UIContext.provider';
import { ProvincesPrefixMatcher } from '../../../../config/enum.config';
const revenueSettlementNoteFormKey = "revenueSettlementForm";
const tableKey = "revenueSettlementTable";

const InterProvinceRevenueSettlementElement = () => {
  
  const [state, setState] = useState({
    showSubmitPopup: false,
  });
  //   let date = new Date()
  // let day = date.getDate();
  // let month = date.getMonth()+1;
  // let year = date.getFullYear();
  // let hour = date.getHours();
  // let minutes = date.getMinutes();
  // let second = date.getSeconds();
  // let today = `${year}-${month}-${day}`;
  let currentTime = '23:59:59';

  let yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const [formState, formAction] = useContext(FormContext);
  const [pending, setPending] = useState([]);
  const [authStatus, authAction] = useContext(AuthContext);
  const provinceId=_get(authStatus, "authUser.pdmtId", "")
  const serviceId=_get(authStatus, "authUser.service_id", "")

   const[accountNumber,setAccountNumber]=useState("")
  let selectedTo = dateObjectToString(_get(formState[`${tableKey}_search`], "transaction_to", yesterday))

  useEffect(() => {
    callApi(`${settlementOutstandingAPI.url}?transaction_to=${selectedTo}&pdmt_id=${provinceId}`)
      .headers(true)
      .method("get")
      .send((err, result) => {
        if (result) {
          console.log('AAAAA', result.data);
          setPending(result.data);
        }
      });
  }, [selectedTo]);
  

    useEffect(()=>{
        callApi(`${AccountNumberListAPI.url}/${provinceId}`)
        .method("get")
        .headers(true)
        .body({})
        .isMultipart(false)
        .send((error, result) => {

          if(!error){
            setAccountNumber(result.data.data.account_number)
           
          }
        });

    },[])
  const history = useHistory();

  return (
    <Fragment>
      <UICard>
        <div className="row">
          <div className="col-12">
            {console.log('CCCCCC', new Date(`${pending.last_settlement_to}`))}
            <strong>Transaction upto Last Settlement Note:</strong> {pending.last_settlement_to?dateObjectToString(new Date(`${pending.last_settlement_to}`),"dd/mm/YY"):""}
          </div>

          <div className="col-12 defaultMarginTop">
            <DataTable
              tableName={"Revenue Settlement"}
              dataTableKey={tableKey}
              apiUrl={`${settlementOutstandingAPI.url}`}
              isSetAction={true}
              isSetSearchFrom={true}
              searchFromComponent={<SearchElement dataTableKey={tableKey} maxDate={yesterday} minDate={pending.last_settlement_to} />}
              defaultSearchFormObject={{
                transaction_to: dateObjectToString(yesterday),
                pdmt_id: provinceId
              }}
              onSetSearchObjectFn={(searchObject) => {
                let tempSearchObject = { ...searchObject };

                if (!isEmptyValue(searchObject.transaction_to)) {
                  tempSearchObject["transaction_to"] = dateObjectToString(searchObject.transaction_to, "YY-mm-dd");
                }
                return tempSearchObject;
              }}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.view,
                  modelContentElement: ViewTransactions,
                  dialogPopupProps: {
                    isFullScreen: true,
                    dialogTitle: "View Transactions",
                    isCustomButton: false,
                    closeButton: {
                      btnText: "Close",
                    },
                  },
                },
              ]}
              tableHeaderList={[
                { displayName: "Province", key: "province", isSort: false },
                { displayName: "Transaction From", key: "transaction_from",isSort: false,onPrintFn:(rawData)=>dateObjectToString(rawData.transaction_from,"dd/mm/YY HH:MM") },
                { displayName: "Revenue to be Transferred", key: "amount", isRightAlign:true, cellStyle:{ 'width': '90px'},isSort: false,onPrintFn:(rawData)=>thousandSeparator(rawData.amount,2)},
              ]}
            //tableBodyList={pending.provinces}
            />
          </div>

          <div className="col-12 defaultMarginTop">
            <div className="row">
              <div className="col-8">
                <strong>Total Settlement Amount:</strong> Rs. {pending.total ? thousandSeparator(pending.total,2) : '0.00'}
              </div>
              <div className="col-4 text-right">
                {(pending.total && parseInt(pending.total) > 0) ? 
                <InputButton
                  btnName={"settle"}
                  btnText={"Settle"}
                  onClickBtnFn={state => setState({
                    showSubmitPopup: !state.showSubmitPopup
                  })}
                />
              :null}
             
              </div>
            </div>
          </div>
        </div>
      </UICard>

      <DialogPopup
        isFullWidth
        isOpen={state.showSubmitPopup}
        dialogTitle={"Revenue Settlement Note"}
        isConfirmationPopup={true}
        isCustomButton={true}
        customButtonComponent={ModelBtnWrapper}
        confirmationMsg={"Are you sure you want to complete the payment?"}
        customButtonComponentProps={{
          formGroupName: revenueSettlementNoteFormKey, selectedTo: selectedTo,
          history: history, total: parseInt(pending.total),accountNo:accountNumber
          
        }}
        closeButton={{
          btnText: "Cancel",
        }}
        onClosePopupFn={state => setState({
          ...state,
          showSubmitPopup: false
        })}
      >
        <RevenueSettlementNote formGroupName={revenueSettlementNoteFormKey}
          pending={pending} today={selectedTo} currentTime={currentTime} accountNo={accountNumber} />
      </DialogPopup>

      
    </Fragment >
  );
};

const SearchElement = ({
  dataTableKey = tableKey,
  maxDate,
  minDate
}) => {
  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="row">
          <div className="col-12">
            <DatePickerWithState
              uiType={templateTypes.bootstrap}
              formGroupName={`${dataTableKey}_search`}
              labelText="Transactions until"
              inputName={"transaction_to"}
              isRequired={true}
              maxDate={maxDate}
              minDate={minDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InterProvinceRevenueSettlement = () => {
  return (
    <DashboardTemplate
      pageTitle="Inter Province Revenue Settlement"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          displayName: "Inter Province Revenue Settlement",
        },
      ]}
    >
      <InterProvinceRevenueSettlementElement />
    </DashboardTemplate>
  );
};

const ModelBtnWrapper = (props) => {
  const [openPrint, setOpenPrint] = useState({ showPopup: false, });
  const [formState, formAction] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);
  const closePopup = () => {
    setOpenPrint({
      ...openPrint,
      showPopup: false
    })
    props.onClosePopupFn()
  }
  const isBtnDisabled = () =>{
    let isDissable = true;
    
    if(!isEmpty(_get(formState, `${props.formGroupName}.account_balance`, null)) && !isEmpty(_get(formState, `${props.formGroupName}.remarks`, null)) && _get(formState, `${props.formGroupName}.account_balance`, null) >= props.total){
      isDissable = false;
    }
    return isDissable;
  }

  const isAlphaNumeric=()=>{ 
    const value = _get(formState, `${props.formGroupName}.remarks`, null);
    const regex = /[^0-9a-zA-Z-\s]+/ig; //this will admit letters, numbers and dashes
    if (value.match(regex) || value === "") {
      uiAction.setFlashMessage({
        status: true,
        message: "The Remark Should be only contain letters and numbers.",
        messageType: "error",
      });  
    }
    else{
       setOpenPrint({
        ...openPrint,
        showPopup: true
      })
      
    }
  }
  return (
    <>
      
      <InputButton
        elementWrapperStyle={"defaultHalfMarginLeftRight"}
        btnText="Submit"
        onClickBtnFn={isAlphaNumeric}
        isBtnDisabled={isBtnDisabled()}

      />

      <DialogPopup
        isDialogActions={false}
        dialogTitle={"Please Confirm"}
        isFullWidth={false}
        isFullScreen={false}
        isCloseButton={true}
        customButtonComponentProps={true}
        children={
          <ModelSubmitButton selectedTo={props.selectedTo} closePopup={closePopup} history={props.history} total={props.total} confirmationMsg={`Are you sure you want to generate the Revenue Settlement Note for Amount of ${thousandSeparator(parseInt(props.total), 2)} from the Account Number of ${props.accountNo}?`} />
         
        }
        isOpen={openPrint.showPopup}
        onClosePopupFn={closePopup}
      />
    </>
  )
}

const ModelSubmitButton = ({
  formGroupName = "", selectedTo, history, total, confirmationMsg = "",closePopup=emptyFun
}) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);

  const provinceId=_get(authStatus, "authUser.pdmtId", "")
  return (


    <div className="row">
      <div className="col-md-12">
        <strong>{confirmationMsg}</strong>
      </div>
      <div className="col-md-12 text-right defaultMarginTop">
        <SubmitButton
          btnText={"Yes"}
          elementStyle="btnWrapper"
          startIcon={"far fa-save"}
          formGroupName={formGroupName}
          isValidate={true}
          flashMessages={{
            "success": {
              status: true,
              message: "Revenue Settlement Note Successfully submitted.",
              messageType: "success"
            },
            "failed": {
              status: true,
              message: "An error has occurred",
              messageType: "error"
            }
          }}
          // validationObject={{
          //   fileds: {
          //     account_balance: "Account Balance",
          //     remarks: "Remarks"
          //   },
          //   rules: {
          //     account_balance: "required|minAmount: " + total,
          //     remarks: "required"
          //   },
          //   message: {
          //     "account_balance.minAmount": "Account Balance should be greater than or equal to " + total
          //   }
          // }}
          callApiObject={{
            isSetHeaders: true,
            multipart: false,
            method: "post",
            onUpload: false
          }}
          onGetAPIEndPointFn={(formState) => {
            return {
              url: `${settlementPostAPI.url}`,
              key: settlementPostAPI.key
            };
          }}
          onChangeRequestBodyFn={(formObject) => {
            return getDataByFormObject({
              ...formObject,
              transaction_to: selectedTo,
              account_balance:parseInt(_get(formState, `revenueSettlementForm.account_balance`, 0)),
              remarks:_get(formState, `revenueSettlementForm.remarks`, null),
              province_id: provinceId
            });
          }}
          apiDataStoringObject={{
            setLoader: true,
            storingType: "API_RESPONSE_LINKED_FORM",
            mergeToSuccessResponse: null,
            mergeToErrorResponse: null,
          }}
          onResponseCallBackFn={(error) => {
            if (!error) {
              history.push("/revenue-settlement-note-history");
            }
            else{
              closePopup()
            }
          }}
        />
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="No"
          onClickBtnFn={closePopup}
          startIcon={"fas fa-times"}
        />
      </div>
    </div>

  )

}
export { InterProvinceRevenueSettlement, ModelSubmitButton };