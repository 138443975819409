/*
 * @Author:Pradeepa Sandaruwan
 * @Date: 2021-01-12 10:21:07
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:40:47
 */

import React, { useContext, useEffect } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import {
      SelectBoxWithState,
      SubmitButton,
      FormWrapper
} from "../../../../../../ui-components/ui-elements/form";
import { reportGenerateAPI, licenceTypesListAPI } from "../../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { downloadFile } from "../../../../../../../helpers/download-helpers/download.helpers"
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { apiResponseStoringType, CoreContext } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import { useState } from "react";
const emptyFn = (...para) => undefined
const SearchCriteria = ({
      reportCode,
      formGroupName,
      setReportgeneratedValues = emptyFn,
      onGenerateSubmit = emptyFn,
      onResetClick = emptyFn,
      reportName
}) => {

      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const [coreState, coreAction] = useContext(CoreContext);
      const date_time = new Date();
      const onExportToPdfSubmit = (event) => {
            downloadFile(new Blob([event]), `${reportName}.pdf`);
      };
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
      const currentDate = dateObjectToString(new Date());
      let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
      let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
      let authorityName = _get(authStatus, 'authUser.authorityName', "");
      let generated_by = _get(authStatus, 'authUser.name', "")
      let role = _get(authStatus, 'authUser.rolePrefix', "")

      let licenceTypeId = "All"
      let licenceTypeName = "All"
      const [dataList,setDatalist]=useState([])
      useEffect(() => {
            coreAction
                  .sendRequestFn(
                        `${licenceTypesListAPI.url}?sort_by=name|asc`
                  )
                  .method("get")
                  .setInitStoring(apiResponseStoringType.setResponse, {
                        responseKey: licenceTypesListAPI.key,
                  })
                  .headers(true)
                  .send((error, result) => {
                        if (!error) {
                              setDatalist(result.data.data.filter((item) => item.code !== "FE"));
                          
                        }
                  });
      }, [])
      return (
            <UICard>
                  <FormWrapper
                        setGroupName={formGroupName}
                        formGroupLinkWith={formGroupName}
                        onDestroyUnsetFormObject={true}
                        setFormObject={{

                        }}

                  >
                        <div className={"row"} >
                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          labelText="Licence Type"
                                          inputName={"licenceType"}
                                          emptySelectOptionTxt={" "}
                                         // apiUrl={`${licenceTypesListAPI.url}?sort_by=name|asc`}
                                          //apiStateKey={licenceTypesListAPI.key}
                                          keyName={"name"}
                                          valueName={"name"}
                                          isRequired={true}
                                          dataList={dataList}
                                    />
                              </div>


                              <div className="col-md-6 col-sm-12 floatLeft">

                                    <div className="mt-3 "><strong>Date:&nbsp;&nbsp;</strong>{date}</div>

                              </div>


                              <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">

                                    <SubmitButton
                                          btnText={"Download"}
                                          elementStyle="btnWrapper"
                                          formGroupName={formGroupName}
                                          isValidate={true}
                                          validationObject={{
                                                fileds: {
                                                      licenceType: "Licence Type",

                                                },
                                                rules: {
                                                      licenceType: "required",


                                                },
                                                message: {}
                                          }}

                                          callApiObject={{
                                                isSetHeaders: true,
                                                method: "get",
                                                multipart: false,
                                                onUpload: false,
                                                responseType: 'arraybuffer'
                                          }}
                                          apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: "API_RESPONSE_LINKED_FORM",
                                                mergeToSuccessResponse: true,
                                                mergeToErrorResponse: true
                                          }}
                                          onGetAPIEndPointFn={(formObject) => {

                                                if (!isEmptyValue(formObject.licenceType)) {
                                                      licenceTypeName = formObject.licenceType;
                                                      licenceTypeId = (_get(coreState, `apiResponses.${licenceTypesListAPI.key}.result`, []).find(item => item.name === licenceTypeName)?.id)

                                                }


                                                return {
                                                      url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=pdf&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_licenceTypeId=${licenceTypeId}&report_effectiveDate=${currentDate}&report_licenceTypeName=${licenceTypeName}&report_provinceName=${provinceName}&report_authorityName=${authorityName}&report_dateFormatDisplay=dd/MM/yyyy &report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.provinceCode', "") : _get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                                      key: `${reportGenerateAPI.key}`
                                                }
                                          }}

                                          onChangeRequestBodyFn={(formObject) => {
                                                return getDataByFormObject(formObject);
                                          }}
                                          onResponseCallBackFn={(error, response,) => {
                                                if (response?._statue) {
                                                      onExportToPdfSubmit(response?.data)
                                                }
                                          }}
                                    />



                              </div>
                        </div>
                  </FormWrapper>
            </UICard>


      );
};

export {
      SearchCriteria
}