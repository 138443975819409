import React, { Fragment, useContext, useEffect, useState } from "react";
import { DocumentUploader } from "../../../ui-components/ui-elements/common/BaseElements";
import { Action } from "./includes/Action";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { Reason } from "./includes/Reason";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { getDataByFormObject, fromObjectToQueryString } from "../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { FormWrapper, SelectBoxWithState, SubmitButton } from "../../../ui-components/ui-elements/form";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { RequestStatus, RequestTypesList, ActionTypeList, LicenceType, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { licenceInvalidDocuments, getTempLicenceRenewablePeriodAPI, uploadDetailGetAPI, adminConfigDaysGetApi, vetAPI, vftCertificateAPI, vetCompanyAPI, vrpAPI, calculateLicenceFeeAPI, getLatestLicenseAPI, insuranceAPI, insuranceListAPI, vftCompanyListAPI, vrpCompanyListAPI } from "../../../../config/apiUrl.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { numberThousandSeperator } from "../../../../helpers/common-helpers/common.helpers";
import { normalLicenceEndDatesAPI, normalLicenceStartDateAPI, issueRLValidationAPI } from "../../../../config/apiUrl.config";
import { isEmpty } from 'lodash';

const InvalidDocumentsSubmitButton = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [, uiAction] = useContext(UIContext);
  const { sessionId } = props
  // const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  const approvalAction = _get(
    formState,
    "Request_Licence_Invalid_Doc.action",
    null
  )
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const request_id = _get(formState, "request-licence-invalid-doc.request_id", null);
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={props.uiType === 'assign&Perform' ? "Request_Licence_Invalid_Doc" : "request-licence-invalid-doc"}
        tooltip={"Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${request_id}`,
            messageType: "success",
          },
          // failed: props.uiType === 'assign&Perform' ? {
          //   status: true,
          //   message: `Approval Request Decision Submit Failed. Please Try Again.`,
          //   messageType: "error",
          // } : {
          //   // status: true,
          //   // message: `Approval Request Submit Failed. Please Try Again.`,
          //   // messageType: "error",
          // },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "Request_Licence_Invalid_Doc.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Request_Licence_Invalid_Doc.action", "") === 'FRD' ? (_get(formState, "Request_Licence_Invalid_Doc.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
              issue_mode: "Issue Mode",
              run: "Run"

            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              issue_mode: "Issue Mode",
              run: "Run"

            }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            issue_mode: "Issue Mode",
            run: "Run"
          }) : {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            "issue_mode": "Issue Mode"

          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "Request_Licence_Invalid_Doc.action", "") === 'FRD' ? (_get(formState, "Request_Licence_Invalid_Doc.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",
            issue_mode: "required",
            run: "required"
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            issue_mode: "required",
            run: "required"
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            issue_mode: "required",
            run: "required"
          }) : {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required",
            "issue_mode": "requiredIf:user_role_level,INT_PRV"

          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "Request_Licence_Invalid_Doc.action", "") === 'FRD' ? (_get(formState, "Request_Licence_Invalid_Doc.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required.",
            "forward_authority": "Authority is required.",
            "issue_mode": "Issue Mode is required.",
            "run": "Run is required."
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province.required": "Province is required.",
            "issue_mode.required": "Issue Mode is required.",
            "run.required": "Run is required."
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "issue_mode.required": "Issue Mode is required.",
            "run.required": "Run is required."
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
            "action.required": "Action is required.",
            "issue_mode.requiredIf": "Issue Mode"

          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${licenceInvalidDocuments.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
            key: licenceInvalidDocuments.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "Request_Licence_Invalid_Doc.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "Request_Licence_Invalid_Doc_new.issue_mode", null) === null) {
              uiAction.setFlashMessage({
                status: true,
                message: `Issue Mode is required`,
                messageType: "error",
              });

              return
            }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["lcn_w_invalid_doc"],
              // current_licence_type: current_licence_type,
              ui_request_id: props.approvalRequestId,
              // status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              // authority_id: authorityId,
              // request_id: fromObject.request_id,
              reason_id: formState['Request_Licence_Invalid_Doc-new'].reason_id,
              issue_mode: props.uiType === 'assign&Perform' ? formState['Request_Licence_Invalid_Doc_new'].issue_mode : (fromObject.issue_mode ? fromObject.issue_mode : null),
              run: fromObject.issue_mode && fromObject.issue_mode === 'FUL' ? true : formState['Request_Licence_Invalid_Doc_new'].run ? formState['Request_Licence_Invalid_Doc_new'].run === 'ALD' ? true : false : true,
              counter_session_id: '00001',
              forward_authority_service_id: serviceId,
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              },
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["lcn_w_invalid_doc"],
              current_licence_type: current_licence_type,
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: fromObject.request_id,
              reason_id: fromObject.reason_id,
              current_licence_start_date: _get(formState, `licence_charges_form.currentLicenceStartDate`, null) ?
                _get(formState, `licence_charges_form.currentLicenceStartDate`, "") :
                _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL' ?
                  _get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null) !== null ?
                    _get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', '-') :
                    _get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-') : "",
              issue_mode: fromObject.issue_mode ? fromObject.issue_mode : null,
              run: fromObject.issue_mode && fromObject.issue_mode === 'FUL' ? true : fromObject.run ? fromObject.run === 'ALD' ? true : false : true,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === 'assign&Perform' ? "put" : "post",

        }}
        onResponseCallBackFn={(response, error) => {
          if (!isEmpty(response.data.errors)) {
            if (props.uiType !== 'assign&Perform') {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }

          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          } 
          else {
            if (props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
              });
            } 
            else {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
        }
        }
      />
    </Fragment>
  );
};


const RequestLicenceInvalidDocUI = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
  const formKey = props.formKey;
  const uiType = props.uiType;
  const current_licence_type = _get(formState, "vehicle_search_form.data.current_license_type.code", null);
  const current_vehicle_province = _get(formState, "vehicle_search_form.data.province_id", null);
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  console.log('ooop=========', props)
  // province_id
  const isTheCarnet = ((_get(formState, "latest_licence_data.data.vehicleData.current_license_type.code", null) === 'CA' || _get(formState, "latest_licence_data.data.vehicleData.current_license_type.code", null) === 'NO' || _get(formState, "latest_licence_data.data.vehicleData.current_license_type.code", null) === 'FE') ? true : false)

  const isSameProvince = (pdmtId === (uiType === 'APR' ? _get(formState, "latest_licence_data.data.vehicleData.province_id", null) : current_vehicle_province)) ? true : false
  const vehicleProvince = uiType === 'APR' ? _get(formState, "latest_licence_data.data.vehicleData.province_id", null) : current_vehicle_province

  // console.log('TTTT--->', current_vehicle_province, pdmtId);
  const [requestId, setRequestId] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  // const [requestId, setRequestId] = useState(null);
  // const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8))
    getNewRelatedData()
    if (props.uiType === 'APR') {
      getLicenceStartDateFn()
      getReqApprovalLicenceTypeData(props)
      getReqApprovalData(props)

    }
    getInsuranceData()
    getVetData()
    getVftData()
    getVrpData()
    getAdminConfigDaysData()
    getTempLicenceRenewablePeriod()
  }, [])

  const getAdminConfigDaysData = (props) => {
    callApi(`${adminConfigDaysGetApi.url}/${vehicleProvince}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          // console.log('RWWWWW==CCC', result.data.data);
          formAction.changeInputFn("vet_validity_period", "days", "", result.data.data.vet_validity_period);
          formAction.changeInputFn("vft_validity_period", "days", "", result.data.data.vft_validity_period);

        }
      })


  }

  const getTempLicenceRenewablePeriod = (props) => {
    callApi(`${getTempLicenceRenewablePeriodAPI.url}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          // value_type: "MNT"
          formAction.changeInputFn('temp_lcn_renewable_period', "data1", "", result.data.data)

        }
      });
  }

  const getReqApprovalData = (props) => {
    callApi(`${licenceInvalidDocuments.url}/get-documents/${props.approvalRequestId}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          // console.log('RWWWWW==', result.data.data);
          const runn = result.data.data.run && result.data.data.run === 1 ? 'ALD' : 'NAL'
          formAction.changeInputFn("Request_Licence_Invalid_Doc_new", "issue_mode", "", result.data.data.issue_mode);
          formAction.changeInputFn("Request_Licence_Invalid_Doc_new", "run", "", runn);

        }
      })


  }

  const getReqApprovalLicenceTypeData = (props) => {
    callApi(`${getLatestLicenseAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

        }
      });
  }

  const getNormalLicenceFee = (licenceEndtype = 0, isReIssuance = false, startDate = null, endDate = null, temp_request = false) => {
    let tempReq = temp_request;
    if (temp_request) {
      tempReq = {
        runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false, validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0), charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
      }
    }

    const queryObj = {
      vehicle_no: props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.data.vehicle_no", ""),
      licence_end_type: licenceEndtype,
      licence_period_start_date: startDate !== null ? dateObjectToString(startDate, "YY-mm-dd") : dateObjectToString(startingDate),
      licence_period_end_date: endDate ? dateObjectToString(endDate, "YY-mm-dd") : null,
      is_reissuance: isReIssuance,
      temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
      requested_licence_type: _get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, ""),
    };

    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        console.log('DDDDDDD====', error);
        if (!error) {
          console.log('DDDDDDD====', result);
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;


          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            _get(result, "data.additions.arrears", 0) + _get(result, "data.additions.current_licence", 0) -
            (_get(result, "data.deductions.arrears", 0) + _get(result, "data.deductions.current_licence", 0)));

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "total", "", total);
          formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
          console.log('ERR===>', error);
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
      });
  };

  const getNewRelatedData = () => {

    const vetCompaniesPromise = sendAPIRequest(`${vetCompanyAPI.url}/list?sort_by=name|asc&active=true`);

    const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);

    const vftCompaniesPromise = sendAPIRequest(`${vftCompanyListAPI.url}/list?sort_by=name|asc&active=true`);

    const vrpCompaniesPromise = sendAPIRequest(`${vrpCompanyListAPI.url}/list?sort_by=name|asc&active=true`);

    Promise.all([vetCompaniesPromise, insuranceCompaniesPromise, vftCompaniesPromise, vrpCompaniesPromise])
      .then(result => {
        const vetCompanies = result[0]['data']
        const insuranceCompanies = result[1]['data']
        const vftCompanies = result[2]['data']
        const vrpCompanies = result[3]['data']

        const theVetCompany = vetCompanies.filter(value => value.service['id'] === _get(formState, `latest_licence_data.data.vet_service_id`, null))
        const theInsuranceCompany = insuranceCompanies.filter(value => value.service['id'] === _get(formState, `latest_licence_data.data.insurance_service_id`, null))
        const theVftCompany = vftCompanies.filter(value => value.service['id'] === _get(formState, `latest_licence_data.data.vft_service_id`, null))
        const theVrpCompany = vrpCompanies.filter(value => value.service['id'] === _get(formState, `latest_licence_data.data.vrp_service_id`, null))

        const documentValidationCompanies = {
          vetCompany: theVetCompany && theVetCompany.length !== 0 ? theVetCompany[0].service.name : '-',
          insuranceCompany: theInsuranceCompany && theInsuranceCompany.length !== 0 ? theInsuranceCompany[0].service.name : '-',
          vftCompany: theVftCompany && theVftCompany.length !== 0 ? theVftCompany[0].service.name : '-',
          vrpCompany: theVrpCompany && theVrpCompany.length !== 0 ? theVrpCompany[0].service.name : '-',
        }


        formAction.changeInputFn(formKey, "companyData", "", documentValidationCompanies);
      }).catch(error => {
        console.log('ERRRRRRRRR', error);
      });
  };

  const getRelatedData = (docData) => {

    const vetCompaniesPromise = sendAPIRequest(`${vetCompanyAPI.url}/list?sort_by=name|asc&active=true`);
    const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);
    const vftCompaniesPromise = sendAPIRequest(`${vftCompanyListAPI.url}/list?sort_by=name|asc&active=true`);
    const vrpCompaniesPromise = sendAPIRequest(`${vrpCompanyListAPI.url}/list?sort_by=name|asc&active=true`);

    Promise.all([vetCompaniesPromise, insuranceCompaniesPromise, vftCompaniesPromise, vrpCompaniesPromise])
      .then(result => {
        const vetCompanies = result[0]['data']
        const insuranceCompanies = result[1]['data']
        const vftCompanies = result[2]['data']
        const vrpCompanies = result[3]['data']

        // console.log('CNMPANIES=====>', vftCompanies);
        const theVetCompany = vetCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theInsuranceCompany = insuranceCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theVftCompany = vftCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theVrpCompany = vrpCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id !== null ? docData.service_id : docData.service.id : '-'))


        const vetCompany = theVetCompany && theVetCompany.length !== 0 ? theVetCompany[0].service.name : '-'
        const insuranceCompany = theInsuranceCompany && theInsuranceCompany.length !== 0 ? theInsuranceCompany[0].service.name : '-'
        const vftCompany = theVftCompany && theVftCompany.length !== 0 ? theVftCompany[0].service.name : '-'
        const vrpCompany = theVrpCompany && theVrpCompany.length !== 0 ? theVrpCompany[0].service.name : '-'

        if (vetCompany !== '-') {
          formAction.changeInputFn(formKey, "vetCompanyData", "", vetCompany);
        }
        if (insuranceCompany !== '-') {
          formAction.changeInputFn(formKey, "insCompanyData", "", insuranceCompany);
        }
        if (vftCompany !== '-') {
          formAction.changeInputFn(formKey, "vftCompanyData", "", vftCompany);
        }
        if (vrpCompany !== '-') {
          formAction.changeInputFn(formKey, "vrpCompanyData", "", vrpCompany);
        }
      }).catch(err => {
        console.log('ESRRR--->', err);
      });
  };

  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result?.data);
        })
    });
  };

  const licenceMonthDiff = (from, to) => {
    let months
    months = (to.getFullYear() - from.getFullYear()) * 12;
    months -= from.getMonth();
    months += to.getMonth();
    months = (months <= 0) ? 0 : months;

    if (from.getDate() == 1) {
      months += 1; // addressing special scenarion where the licence date starting from the 1st of month
    }
    return months;
  }

  const getdropdownDataWithTempValidations = () => {
    const tempLcnPeriod = _get(formState, "temp_lcn_renewable_period.data1.value", null) !== null ?
      _get(formState, "temp_lcn_renewable_period.data1.value", null) : 0

    const currentLcnStartDate = _get(formState, `licence_charges_form.currentLicenceStartDate`, null) ?
      dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") :
      _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL' ?
        _get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null) !== null ?
          dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', '-'), "dd/mm/YY") :
          dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-'), "dd/mm/YY") : ""

    const isInTempPeriod = licenceMonthDiff(new Date(currentLcnStartDate), new Date()) * 1 <= tempLcnPeriod * 1 ? true : false
    if (((uiType === 'APR' ? isTheCarnet : props.isCarnet) || current_licence_type === 'NO' || current_licence_type === 'FE') && isSameProvince) {
      return [
        {
          id: "FUL",
          value: "Full Licence"
        },
        {
          id: "TMP",
          value: "Temporary Licence"
        },
      ]
    } else {
      return [
        {
          id: "FUL",
          value: "Full Licence"
        }
      ]
    }
  }

  const getVrpData = () => {
    callApi(`${vrpAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}?status=Active`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==vrp', result.data);
          //formKeycertificate_info_vrp
          formAction.changeInputFn(formKey, "certificate_info_vrp", "", result.data.data[0]);
          getRelatedData(result.data.data[0])
        } else {
          console.log('err--->vrp', error);
        }
      })


  }

  const getInsuranceData = () => {
    callApi(`${insuranceAPI.url}?vehicle_number=${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==ins', result.data);
          //formKeycertificate_info_insurance
          formAction.changeInputFn(formKey, "certificate_info_insurance", "", result.data.data);
          getRelatedData(result.data.data)
        } else {

          console.log('err--->ins', error);
        }
      })


  }

  const getVetData = () => {
    callApi(`${vetAPI.url}?vehicle_reg_no=${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==vet', result.data);
          //formKeycertificate_info_vet
          formAction.changeInputFn(formKey, "certificate_info_vet", "", result.data.data[0]);
          getRelatedData(result.data.data[0])
        } else {

          console.log('err--->vet', error);
        }
      })


  }

  const getVftData = () => {
    callApi(`${vftCertificateAPI.url}?sort_by=issued_date|DESC&vehicle_id=${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==vft', result.data);
          //formKeycertificate_info_vft
          formAction.changeInputFn(formKey, "certificate_info_vft", "", result.data.data[0]);
          getRelatedData(result.data.data[0])
        } else {

          console.log('err--->vft', error);
        }
      })


  }

  const vetValidity = new Date(_get(formState, `${formKey}.certificate_info_vet.issued_at`, null))
  if (vetValidity && vetValidity !== null) {
    vetValidity.setDate(vetValidity.getDate() + _get(formState, 'vet_validity_period.days', 0))
  }
  const vftValidity = new Date(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null))
  if (vftValidity && vftValidity !== null) {
    vftValidity.setDate(vftValidity.getDate() + _get(formState, 'vft_validity_period.days', 0))
  }

  const insStatus = _get(formState, `${formKey}.certificate_info_insurance.status`, null)
  const insuranceUsed = _get(formState, `${formKey}.certificate_info_insurance.used`, null)
  const insValidStatus = (insStatus === "ACT") &&
    (insuranceUsed === 0) ? 'Valid' : 'Invalid'

  const vetValidTo = new Date(vetValidity).setHours(0, 0, 0, 0)
  const vetStatus = _get(formState, `${formKey}.certificate_info_vet.status`, null)
  const vetUsed = _get(formState, `${formKey}.certificate_info_vet.used`, null)
  console.log('aaaa',);
  const vetValidStatus = (vetValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vetStatus === 1) &&
    (vetUsed === 0) ? 'Valid' : 'Invalid'

  const vftValidTo = new Date(vftValidity).setHours(0, 0, 0, 0)
  const vftStatus = _get(formState, `${formKey}.certificate_info_vft.type`, null)
  const vftUsed = _get(formState, `${formKey}.certificate_info_vft.used`, null)
  const vftValidStatus = (vftValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vftStatus === "PSS") &&
    (vftUsed === 0) ? 'Valid' : 'Invalid'

  const vrpValidTo = new Date(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null)).setHours(0, 0, 0, 0)
  const vrpStatus = _get(formState, `${formKey}.certificate_info_vrp.status`, null)
  const vrpUsed = _get(formState, `${formKey}.certificate_info_vrp.used`, null)
  const vrpValidStatus = (vrpValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vrpStatus === "Active") &&
    (vrpUsed === 0) ? 'Valid' : 'Invalid'


  const getLicenceStartDateFn = (isReIssuance = false, temp_request = false) => {
    let tempReq = temp_request;
    if (temp_request) {
      tempReq = {
        runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
        validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
        charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
      }

    } else {
      tempReq = null
    }
    const queryObj = {
      vehicle_no: props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.data.vehicle_no", null),
      is_reissuance: isReIssuance,
      licence_period_start_date: null,
      licence_period_end_date: null,
      is_use_start_date: false,
      temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
      requested_licence_type: _get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, "")
    };

    callApi(`${normalLicenceStartDateAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('ERRn', result);
          setStartingDate(new Date(result.data.data))
          formAction.changeInputFn('licence_validity_period', "startDate", "", new Date(result.data.data));
          formAction.changeInputFn('licence_validity_period', "start_onLoad", "", false);
          getLicenceEndDateFn(false, false, new Date(result.data.data))
        } else {
          console.log('ERR', error);
          formAction.changeInputFn('licence_validity_period', "start_onLoad", "", false);
        }

      });
  }


  const getLicenceEndDateFn = (isReIssuance = false, temp_request = false, startsDate) => {
    let tempReq = temp_request;
    if (temp_request) {
      tempReq = {
        runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
        validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
        charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
      }

    } else {
      tempReq = null
    }

    const queryObj = {
      vehicle_no: props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.data.vehicle_no", null),
      is_reissuance: isReIssuance,
      licence_period_start_date: null,
      licence_period_end_date: null,
      is_use_start_date: false,
      temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
      requested_licence_type: _get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, "")
    };
    callApi(`${normalLicenceEndDatesAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('ERRd', result);

          const endDates = result.data.data.map(value => {
            return {
              ...value,
              requested_licence_end_date: value.value,
              value: dateObjectToString(value.value, "dd/mm/YY")
            };
          });

          // if (_get(props.getLatestLicenceState, `current_licence_status`, "") === 'VA') {
          //     formAction.changeInputFn('licence_validity_period', "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));

          // }
          formAction.changeInputFn('licence_validity_period', "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));
          formAction.changeInputFn('licence_validity_period', "firstEnddDate", "", endDates[0]);
          formAction.changeInputFn('licence_validity_period', "endDatesData", "", endDates);
          formAction.changeInputFn('licence_validity_period', "endDate", "", !isEmpty(endDates) ? endDates[0].id : '0');
          formAction.changeInputFn('licence_validity_period', "_onLoad", "", false);
          getNormalLicenceFee(!isEmpty(endDates) ? endDates[0].id : '0', startsDate, null)


        }
        formAction.changeInputFn('licence_validity_period', "_onLoad", "", false);
      })
  }


  return (
    <div className="row">
      <FormWrapper
        setGroupName={formKey}
        setFormObject={props.uiType === 'APR' ? {
          ui_request_id: props.approvalRequestId,
        } : {
          request_id: _set(formState, `${formKey}.request_id`, requestId),
        }}
      // apiUrl={`${licenceInvalidDocuments.url}/certificates-info/${uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
      //       onRebuildResponseFn={(response)=> {
      //         getRelatedData(response.vrp,response.vft,response.insurance,response.vet)
      //         return {
      //           certificate_info_vrp: response.vrp,
      //         certificate_info_vft: response.vft,
      //         certificate_info_insurance: response.insurance,
      //         certificate_info_vet: response.vet,

      //     "_onLoad": true
      //   }
      // }
      // }
      >

        <div className="col-md-12 col-sm-12">

          <UICard>
            <table className="col-md-12 defaultMarginTopBottom">
              <tr>
                <th>Current Licence Validity Period</th>
                <th className={"text-md-right"}>Fee (LKR)</th>
                <th className={"text-md-right"}>Fine (LKR)</th>
                <th className={"text-md-right"}>Additions (LKR)</th>
                <th className={"text-md-right"}>Deductions (LKR)</th>
                <th className={"text-md-right"}>Service Charge (LKR)</th>
                <th className={"text-md-right"}>Total (LKR)</th>
              </tr>
              {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
                <tr>
                  <td className={"text-md-left"}>{_get(formState, `licence_charges_form.currentLicenceStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; - &nbsp;&nbsp; {_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : ""}</td>
                  {/* text-md-right */}
                  <td className={"text-md-right"}>{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0)).toFixed(2))}</td>
                  <td className={"text-md-right"}>{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fine_total`, 0)).toFixed(2))}</td>
                  <td className={"text-md-right"}>{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_addition_total`, 0)).toFixed(2))}</td>
                  <td className={"text-md-right"}>({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0))).toFixed(2))})</td>
                  <td className={"text-md-right"}>({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.serviceCharge`, 0))).toFixed(2))})</td>

                  <td className={"text-md-right"}>{
                    numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0) +
                      _get(formState, `licence_charges_form.data.current_licence_fine_total`, 0) +
                      (_get(formState, `licence_charges_form.serviceCharge`, 0) * 1) +
                      _get(formState, `licence_charges_form.data.current_licence_addition_total`, 0) -
                      _get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0)).toFixed(2))
                  }</td>

                </tr>
                :
                <tr>No data found</tr>
              }
            </table>

          </UICard>
        </div>

        <div className="col-md-12 col-sm-12">

          <UICard>
            {/* {console.log('FORRRRRRMMMMMMMMMM',formState)} */}
            <table className={"fullWidthDiv"}>
              {((_get(formState, `${formKey}.certificate_info_vet`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vet.id`, null) && (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.vet", false) === true : _get(formState, "vehicle_search_form.data.document_validations.vet", false) === true)) ||
                (_get(formState, `${formKey}.certificate_info_insurance`, null) !== undefined && _get(formState, `${formKey}.certificate_info_insurance.id`, null) && (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.insurance", false) === true : _get(formState, "vehicle_search_form.data.document_validations.insurance", false) === true)) ||
                (_get(formState, `${formKey}.certificate_info_vft`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vft.id`, null) && (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.vft", false) === true : _get(formState, "vehicle_search_form.data.document_validations.vft", false) === true)) ||
                (_get(formState, `${formKey}.certificate_info_vrp`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vrp.id`, null) && (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.vrp", false) === true : _get(formState, "vehicle_search_form.data.document_validations.vrp", false) === true)))
                ?
                <thead>
                  <tr>
                    <th>Document</th>
                    <th>Status</th>
                    <th>Company</th>
                    <th>Document No</th>
                    <th>Issued Date</th>
                    <th>Expiry Date</th>
                    <th>Validity Expiry Date</th>
                  </tr>
                </thead>
                :
                <tr>No document data found</tr>
              }
              {console.log('DDDDD--', _get(formState, `${formKey}.certificate_info_vet`, null), _get(formState, `${formKey}.certificate_info_vet.id`, null))}
              {console.log('DDDDD2--', _get(formState, "vehicle.data.data.document_validations.vet", false), _get(formState, "vehicle_search_form.data.document_validations.vet", false))}
              <tbody>
                {(_get(formState, `${formKey}.certificate_info_vet`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vet.id`, null)) &&
                  (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.vet", false) === true : _get(formState, "vehicle_search_form.data.document_validations.vet", false) === true) &&
                  <tr>
                    <td><strong>VET</strong></td>
                    <td>{vetValidStatus}</td>
                    <td>{_get(formState, `${formKey}.vetCompanyData`, '-')}</td>
                    <td>{_get(formState, `${formKey}.certificate_info_vet.vet_no`, null)}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.issued_at`, null), "dd/mm/YY") : '-'}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                    <td>{dateObjectToString(vetValidity, "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(vetValidity, "dd/mm/YY") : '-'}</td>
                  </tr>
                }
                {(_get(formState, `${formKey}.certificate_info_insurance`, null) !== undefined && _get(formState, `${formKey}.certificate_info_insurance.id`, null)) &&
                  (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.insurance", false) === true : _get(formState, "vehicle_search_form.data.document_validations.insurance", false) === true) &&
                  <tr>
                    <td><strong>Insurance</strong></td>
                    <td>{insValidStatus}</td>
                    <td>{_get(formState, `${formKey}.insCompanyData`, '-')}</td>
                    <td>{_get(formState, `${formKey}.certificate_info_insurance.reference`, null)}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.issued_at`, null), "dd/mm/YY") : '-'}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") : '-'}</td>
                    <td>-</td>
                  </tr>
                }
                {(_get(formState, `${formKey}.certificate_info_vft`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vft.id`, null)) &&
                  (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.vft", false) === true : _get(formState, "vehicle_search_form.data.document_validations.vft", false) === true) &&
                  <tr>
                    <td><strong>VFT</strong></td>
                    <td>{vftValidStatus}</td>
                    <td>{_get(formState, `${formKey}.vftCompanyData`, '-')}</td>
                    <td>{_get(formState, `${formKey}.certificate_info_vft.certificate_no`, null)}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null), "dd/mm/YY") : '-'}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                    <td>{dateObjectToString(vftValidity, "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(vftValidity, "dd/mm/YY") : '-'}</td>
                  </tr>
                }
                {(_get(formState, `${formKey}.certificate_info_vrp`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vrp.id`, null)) &&
                  (props.uiType === 'APR' ? _get(formState, "vehicle.data.data.document_validations.vrp", false) === true : _get(formState, "vehicle_search_form.data.document_validations.vrp", false) === true) &&
                  <tr>
                    <td><strong>VRP</strong></td>
                    <td>{vrpValidStatus}</td>
                    <td>{_get(formState, `${formKey}.vrpCompanyData`, '-')}</td>
                    <td>{_get(formState, `${formKey}.certificate_info_vrp.route_permit_no`, null)}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.issued_at`, null), "dd/mm/YY") : '-'}</td>
                    <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                      dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                    <td>-</td>
                  </tr>
                }
              </tbody>
            </table>

          </UICard>


        </div>

        <div className="col-md-12 col-sm-12">
          <UICard>
            {/* <div className="col-md-12"> */}
            <div className="row">
              <div className="col-md-3">Current Licence Type:</div>
              <div className="col-md-6">{props.uiType === 'APR' ? LicenceType[`${_get(formState, 'latest_licence_data.data.vehicleData.current_license_type.code', '-')}`] : _get(formState, `vehicle_search_form.data.current_license_type.name`, '-')}</div>
            </div>
            <div className="row">
              <div className="col-md-3">Current Licence Starting Date:</div>
              <div className="col-md-6">{_get(formState, `licence_charges_form.currentLicenceStartDate`, null) ?
                dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") :
                _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL' ?
                  _get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null) !== null ?
                    dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', '-'), "dd/mm/YY") :
                    dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-'), "dd/mm/YY") :
                  _get(formState, 'latest_licence_data.data.valid_from', null) !== null ?
                    dateObjectToString(_get(formState, 'latest_licence_data.data.valid_from', '-'), "dd/mm/YY") : ""}</div>
            </div>
            <div className="row">
              <div className="col-md-3 defaultMarginTopBottom">Issue Mode {props.uiType === 'APR' && <sup className="text-danger">*</sup>}:</div>
              <div className="col-md-3">
                <SelectBoxWithState
                  emptySelectOptionTxt="Select Issue Mode"
                  labelText={""}
                  formGroupName={props.uiType === 'APR' ? `${formKey}_new` : formKey}
                  inputName={"issue_mode"}
                  dataList={getdropdownDataWithTempValidations()}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">Licence Validity Period (From - To):</div>
              <div className="col-md-6">
                {_get(formState, `licence_validity_period.startDate`, null) ?
                  dateObjectToString(_get(formState, `licence_validity_period.startDate`, ""), "dd/mm/YY") : _get(formState, `licence_charges_form.currentLicenceStartDate`, null) ?
                    dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL' ?
                      _get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-') : ""} &nbsp;&nbsp; - &nbsp;&nbsp; {
                  _get(formState, `licence_validity_period.firstEnddDate`, null) ?
                    dateObjectToString(_get(formState, `licence_validity_period.firstEnddDate.requested_licence_end_date`, ""), "dd/mm/YY") : _get(formState, `licence_charges_form.currentLicenceEndDate`, null) ?
                      dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL' ?
                        `${new Date(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-')).getDate() - 1}/${new Date(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-')).getMonth()}/${new Date().getFullYear()}` : ""}
              </div>

            </div>
            <div className="row">
              {_get(formState, `${props.uiType === 'APR' ? `${formKey}_new` : formKey}.issue_mode`, undefined) === "TMP" ?
                <div className="col-md-3 defaultMarginTopBottom">Run:{props.uiType === 'APR' && <sup className="text-danger">*</sup>}</div>
                : null}
              <div className="col-md-3">
                {_get(formState, `${props.uiType === 'APR' ? `${formKey}_new` : formKey}.issue_mode`, undefined) === "TMP" ?
                  <SelectBoxWithState
                    labelText={""}
                    formGroupName={props.uiType === 'APR' ? `${formKey}_new` : formKey}
                    inputName={"run"}
                    dataList={[
                      {
                        id: "ALD",
                        value: "Allowed"
                      },
                      {
                        id: "NAL",
                        value: "Not Allowed"
                      }
                    ]}
                    emptySelectOptionTxt="Run"
                    inputValue="ALD"
                  />
                  : null}
              </div>
            </div>

            {/* </div> */}
          </UICard>
        </div>
      </FormWrapper>
      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <Reason
          filterKey={'request_licence_invalid_documents'}
          requestType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
          formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <Action
          formKey={formKey}
          uiType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
        />
      </div>




    </div>
  );
};


const RequestLicenceInvalidDoc = () => {
  const formKey = "invalidDocs";
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Request Licence–Invalid Documents" },
      ]}
      pageTitle="Request Licence – Invalid Documents"
    >
      <RequestLicenceInvalidDocUI formKey={formKey} />
    </DashboardTemplate>
  );
};

export default RequestLicenceInvalidDoc;
export { RequestLicenceInvalidDocUI, InvalidDocumentsSubmitButton };
