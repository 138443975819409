/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 16:06:43 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-06 10:20:22
 */
import React,{useState, Fragment} from 'react';
import {NormalLicense} from "./NormalLicense";

const emptyFn = (...para) => undefined;

const Carnet=({
    setShowHideFooterFN={emptyFn}
})=>{
    return (
        <NormalLicense uiType={"carnet"} setShowHideFooterFN={(status)=>setShowHideFooterFN(status)}/>
    )
}

export {
    Carnet
}