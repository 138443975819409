/*
 * @Author: Pradeepa Sandaruwan
 * @Date: 2020-09-22 16:06:43
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-10 16:35:56
 */
import React, { useState, useContext } from "react";

import { PaddingLessUICard } from "../../../ui-components/ui-elements/common/BaseElements.js";
import { InputButton, FormWrapper, RoundButton, roundBtnSizes } from "../../../ui-components/ui-elements/form";
import { QuotationVehicleRevenueLicence } from "./includes/QuotationVehicleRevenueLicence.js";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup.js";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { _get, _unset, _find } from "../../../../helpers/common-helpers/lodash.wrappers";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { PaymentCategoriesEnum } from "../../../../config/enum.config.js";
import { isEmpty } from "lodash";
import { quotationVehicleListAPI } from "../../../../config/apiUrl.config.js";
import { QuotationConfirmationPopup } from "./includes/QuotationConfirmationPopup.js";
import { QuotationAmountBreakdown } from "./includes/QuotationAmountBreakdown.js";
import { QuotationPaymentType } from "./includes/QuotationPaymentType.js";

const emptyFn = (...para) => undefined;

const IssueQuotation = ({
  uiType = "quotation",
  formGroupName = "issueQuotation",
  latestLicence = {},
  qtData = {},
  closeMainPopup = emptyFn,
  mainPopupCloseFn = emptyFn
}) => {

  const vehicleLicenseListKey = "quotation_license_vehiclelist_form"
  const [openPrint, setOpenPrint] = useState({ showPopup: false, });
  const [formState, formAction] = useContext(FormContext);

  const [index, setIndex] = useState(0); // create state to keep track of licence index, set the default index to 0

  const slideRight = () => {
    setIndex((index + 1) % _get(formState, `${vehicleLicenseListKey}.data`, []).length); // increases index by 1
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(_get(formState, `${vehicleLicenseListKey}.data`, []).length - 1); // returns last index of licence array if index is less than 0
    } else {
      setIndex(nextIndex);
    }
  };

  const onPrintFn = () => {
    const paymentFormKey = "paymentTypeFormKey";
    const errorObject = [];
    const paymentFormObj = getDataByFormObject(_get(formState, paymentFormKey, {}));
    _unset(paymentFormObj, "isDisplay");
    _unset(paymentFormObj, "payment_categories");
    _unset(paymentFormObj, "paid_amount");
    _unset(paymentFormObj, "cash_amount");
    _unset(paymentFormObj, "cheque_amount");
    _unset(paymentFormObj, "card_amount");
    formAction.setFormErrorFn("paymentTypeFormKey", []);

    let isValid = false;

    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        let index = key.replace("payment_types_", "");
        switch (paymentFormObj[key]['payment_type']) {
          case PaymentCategoriesEnum.CASH:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cash Received is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CHEQUE:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cheque Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.cheque_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.cheque_no`,
                "message": "Cheque No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.bank`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.bank`,
                "message": "Bank is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CREDITCARDS:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Credit Card Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.reference_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.reference_no`,
                "message": "Reference No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.approval_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.approval_no`,
                "message": "Approval No. is required."
              });
            }
            break;
        }
        formAction.setFormErrorFn(paymentFormKey, errorObject);
      }
    });

    if (isEmpty(errorObject)) {
      isValid = true;
    }
    if (isValid) {
      formAction.changeInputFn(formGroupName, "print_error", "", "");
      formAction.setFormErrorFn("paymentTypeFormKey", [])
      setOpenPrint({
        ...openPrint,
        showPopup: true
      });
    } else {
      formAction.changeInputFn(formGroupName, "print_error", "", "User cannot proceed with the payment");
    }
  };
  const closePopup = () => {
    setOpenPrint({
      ...openPrint,
      showPopup: false
    })
  }
  const isBtnDisabled = () => {
    const total = _get(formState, "licence_charges_form.total", 0);
    let cardAmount = _get(formState, "paymentTypeFormKey.card_amount", 0);
    let cashAmount = _get(formState, "paymentTypeFormKey.cash_amount", 0);
    let chequeAmount = _get(formState, "paymentTypeFormKey.cheque_amount", 0);
    let isDissable = true;

    if ((cardAmount + chequeAmount) < total) {
      if (cashAmount >= total) {
        isDissable = false;
      }
      if ((cashAmount + cardAmount + chequeAmount) >= total) {
        isDissable = false;
      }
    }
    if ((cardAmount + chequeAmount) == total) {
      isDissable = false;
    }
    return isDissable;
  }
  return (
    <FormWrapper
      elementStyle={"col-md-12"}
      setGroupName={"quotation_license_vehiclelist_form"}
      formGroupLinkWith={"quotation_license_vehiclelist_form"}
      isLoaderOnError={false}
      apiUrl={`${quotationVehicleListAPI.url}?quotation_id=${_get(qtData, 'row.id', "")}`}
      onDestroyUnsetFormObject={true}
      onRebuildResponseFn={(response) => {
        let arrearsFee = 0;
        let arrearsFine = 0;
        let addition = 0;
        let deduction = 0;
        let fee = 0;
        let fine = 0;
        let serviceCharge = 0;
        let total = 0;
        response.forEach(item => {
          arrearsFee += parseFloat(item.arrears_fee);
          arrearsFine += parseFloat(item.arrears_fine);
          addition += parseFloat(item.addition);
          deduction += parseFloat(item.deduction);
          fee += parseFloat(item.fee);
          fine += parseFloat(item.fine);
          serviceCharge += parseFloat(item.service_charge);
        })
        total = parseFloat(fee) +
          parseFloat(fine) +
          parseFloat(arrearsFee) +
          parseFloat(arrearsFine) +
          parseFloat(addition) -
          parseFloat(deduction) +
          parseFloat(serviceCharge);

        const charges = {
          'arrears_fee': parseFloat(arrearsFee),
          'arrears_fine': parseFloat(arrearsFine),
          'addition': parseFloat(addition),
          'deduction': parseFloat(deduction),
          'fee': parseFloat(fee),
          'fine': parseFloat(fine),
          'service_charge': parseFloat(serviceCharge),
          'total': parseFloat(total)
        }
        return {
          data: response,
          charges: charges,
          "_onLoad": false
        }

      }}
    >
      <div className="row">
        <div className="col-md-5">
          <PaddingLessUICard cardHeading="Vehicle Revenue Licence">
            <div className={`license-slide-container`}>
              <RoundButton
                btnSize={roundBtnSizes.medium}
                elementStyle={"prev"}
                iconClass={"fas fa-chevron-left"}
                onClickBtnFn={slideLeft}>
                tooltip={"Next"}
              </RoundButton>

              <QuotationVehicleRevenueLicence index={index} licenseData={_get(formState, `${vehicleLicenseListKey}.data`, [])[index]} className="dealerLicenseImage" uiType={uiType} />


              <RoundButton
                elementStyle={"next"}
                iconClass={"fas fa-chevron-right"}
                onClickBtnFn={slideRight}>
                tooltip={"Prev"}
              </RoundButton>
              <div className="helperText"></div>
            </div>
            {/* </FormWrapper> */}

          </PaddingLessUICard>
        </div>

        <div className="col-md-7">
          <div className="row">
            <div className="col-md-5">
              <PaddingLessUICard cardHeading="Amount Breakdown">
                <QuotationAmountBreakdown uiType={uiType} charges={_get(formState, `${vehicleLicenseListKey}.charges`, null)} />
              </PaddingLessUICard>
            </div>

            <div className="col-md-7 ">
              <PaddingLessUICard elementStyle="payment-type-card" cardHeading="Payment Type">
                <QuotationPaymentType uiType={uiType} charges={_get(formState, `${vehicleLicenseListKey}.charges`, null)} />
                <div className="fullWidthDiv">
                  <div className="row ">
                    <div className="col-md-4"></div>
                    <div className="col-md-6">
                      <div className="text-right defaultMarginTop">
                        {
                          (_get(formState, `${formGroupName}.print_error`, "") !== "") ? (
                            <p className={"defaultHalfMargin"} style={{ color: "red" }}>{_get(formState, `${formGroupName}.print_error`, "")}</p>
                          ) : null
                        }
                      </div>                   
                      <div className="floatRight">                      
                        <InputButton
                          elementWrapperStyle={"payBtnStyle"}
                          btnText={"Pay & Print"}
                          onClickBtnFn={() => onPrintFn()}
                          isBtnDisabled={isBtnDisabled()}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </PaddingLessUICard>
            </div>



            <DialogPopup
              isDialogActions={false}
              dialogTitle={"Please Confirm"}
              isFullWidth={false}
              isFullScreen={false}
              isCloseButton={true}
              children={
                <QuotationConfirmationPopup
                  closePopupFn={closePopup}
                  onClickSuccessFn={closeMainPopup}
                  uiType={uiType}
                  quotationId={_get(qtData, 'row.id', "")}
                  qtData={qtData}
                  vehicleCount={_get(formState, `${vehicleLicenseListKey}.data`, []).length}
                />
              }
              isOpen={openPrint.showPopup}
              onClosePopupFn={closePopup}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default IssueQuotation;
