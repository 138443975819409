/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-06 12:33:54
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-09-21 22:06:55
 */
import { isEmpty } from "lodash";
import React, { useContext, useEffect } from "react";
import { getLicenceTransactionDetailsAPI } from "../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getUsernamenLocationFn } from "../../../../../helpers/common-helpers/getUsernamenLocation.helpers";
import { _get, _without, _getFloat } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { RowNo } from "./../../../../ui-components/ui-elements/documents-uploader/documents-uploader-includes/documents-uploader-includes";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { setThousandSeparator } from "../web-licence/WebLicence";
import { ProvincesPrefixMatcher, pdmtPaymentCategories } from "../../../../../config/enum.config";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";

const ViewTransaction = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = props.fromParent.formKey;
  const uiType = props.fromParent.uiType;
  const licenceType = props.fromParent.licenceType;

  let filter;
  switch (licenceType) {
    case 'web-licence':
      filter = `${_get(props, 'row.licence_id', '')}`;
      break;
    case 'normal-licence':
      filter = `${_get(props, 'row.lcn_id')}`;
      break;
    // case 'dealer-licence':
    //   filter = `${_get(props, 'row.lcn_id')}`;
    //   break;
    case 'vehicle-licence':
      filter = `${_get(props, 'row.lcn_id')}`;
      break;
    case 'carnet-licence':
      filter = `${_get(props, 'row.lcn_id')}`;
      break;
    default:
  }

  useEffect(() => {

    getUsernamenLocationFn({ value: _get(formState, `${formKey}.licence.created_by`, null) }, "user", "created_user_name", formAction, formKey)



  }, [_get(formState, `${formKey}.created_by`, ''), _get(formState, `${formKey}.requested_location`, '')]);


  return (
    <FormWrapper
      setGroupName={formKey}
      apiUrl={`${getLicenceTransactionDetailsAPI.url}/${filter}`}
      onRebuildResponseFn={(response) => {
        console.log("aalddd", response)
        return {
          tx_action_type: _get(response, "licence.lc_txn_action_id", ""),
          licence_number: _get(response, "licence.licence_number", ""),
          lcn_type_code: _get(response, "licence.licence_type_code", ""),
          lcn_valid_from: _get(response, "licence.valid_from", ""),
          lcn_valid_to: _get(response, "licence.valid_to", ""),
          total: _getFloat(response, "paymentsDetails.totalAmount", ""),
          tx_no: _get(response, "licence.txn_no", ""),
          lcn_status: _get(response, "licence.lcn_status", ""),
          tx_date: _get(response, "licence.txn_date_and_time", ""),
          tx_type: _get(response, "licence.txn_type", ""),
          tx_created_by: _get(response, "licence.txn_created_by", ""),
          tx_created_at: _get(response, "licence.txn_created_at", null),
          tx_role_id: _get(response, "licence.txn_created_role_id", null),
          tx_province: _get(response, "licence.txn_province", null),
          lcn_issue_mode: _get(response, "licence.issue_mode", ""),
          print_status: _get(response, "licence.print_status", ""),
          owner_name: _get(response, "licence.owner_name", ""),
          vehicle_number: _get(response, "licence.vehicle_number", ""),
          paid_from: _get(response, "licence.paid_from", ""),
          paid_to: _get(response, "licence.paid_to", ""),
          web_user_email: _get(response, "licence.web_user_email", ""),
          web_user_contact_number: _get(response, "licence.web_user_contact_number", ""),
          temp_licence: _get(response, "licence.temp_licence", 0) === 1 ? 'Yes' : 'No',
          manual_licence: _get(response, "licence.manual_licence", 0) === 1 ? 'Yes' : 'No',
          arrears_only: _get(response, "licence.arrears_only", 0) === 1 ? 'Yes' : 'No',
          reissueance: _get(response, "licence.reissueance", 0) === 1 ? 'Yes' : 'No',
          is_web_licence: _get(response, "licence.is_web_licence", 0) === 1 ? 'Yes' : 'No',
          migrated: _get(response, "licence.migrated", 0),
          free_org: _get(response, "licence.free_licence_org", ""),
          free_org_address: _get(response, "licence.free_licence_org_address", ""),
          free_licence_issuedate: _get(response, "licence.free_licence_requested_date", ""),
          non_user_org: _get(response, "licence.non_user_org", ""),
          non_user_org_address: _get(response, "licence.non_user_org_address", ""),
          non_user_licence_issueddate: _get(response, "licence.non_user_letter_date", ""),
          document: _get(response, "document", null),
          print_history: _get(response, "print_history", null),
          payments: _get(response, "payments", null),
          manual_receipt_number: _get(response, "payments[0].manual_receipt_number", ""),
          paymentDetails: _get(response, "paymentsDetails", null),
          print_id: _get(response, "print_history[0].printed_by", null),
          reversed_request: _get(response, "reversed_request", null),
          reversed_txn: _get(response, "reversed_txn", null),
          reversed_request_id: _get(response, "reversed_txn[0].reversed_request_id", null),
          service_id: _get(response, "reversed_txn[0].service_id", null),
          reversed_at: _get(response, "reversed_txn[0].reversed_at", null),
          ui_request_id: _get(response, "reversed_request[0].ui_request_id", null),
          reversed_approved_by: _get(response, "reversed_request[0].approved_user", null),
          reversed_approved_role: _get(response, "reversed_request[0].approved_role", null),
          reversed_approved_at: _get(response, "reversed_request[0].approved_at", null),
          reversed_approved_authority: _get(response, "reversed_request[0].approved_authority", null),
          reversed_approved_prefix: _get(response, "reversed_request[0].approved_province", null),
          reversed_created_at: _get(response, "reversed_request[0].created_at", null),
          reversed_created_by: _get(response, "reversed_request[0].requested_user", null),
          reversed_created_role: _get(response, "reversed_request[0].requested_role", null),
          reversed_created_authority: _get(response, "reversed_request[0].requested_authority", null),
          reversed_created_prefix: _get(response, "reversed_request[0].requested_province", null),
          documents: _get(response, "documents", []),
          charges: _get(response, "charges", []),
          _onLoad: true,
        };
      }}
    >
      <div className="fullWidthDiv">
        <div className="row">
          <div className="col-md-12">
            <div className="row">

              <div className="col-md-6">
                <strong>Vehicle No.: </strong> {_get(formState, `${formKey}.vehicle_number`, null)}
              </div>

              <div className="col-md-6">
                <strong>Owner: </strong> {getLabelValue(_get(formState, `${formKey}.owner_name`, null), true)}
              </div>

            </div>
            <div className="row">

              <div className="col-md-6">
                <strong>Status: </strong> {_get(formState, `${formKey}.lcn_status`, null) === 'Open' ? "Success" : (_get(formState, `${formKey}.lcn_status`, null) === "Cancled") ? "Cancelled" : _get(formState, `${formKey}.lcn_status`, "-")}
              </div>

              <div className="col-md-6">
                <strong>Txn. Date & Time: </strong> {dateObjectToString(_get(formState, `${formKey}.tx_date`, null), 'dd/mm/YY HH:MM')}
              </div>
              <div className="col-md-6">
                <strong>Txn. Created Location: </strong>{_get(formState, `${formKey}.tx_province`, "") === null ? "" : `${ProvincesPrefixMatcher[_get(formState, `${formKey}.tx_province`, "")]} - `}{_get(formState, `${formKey}.tx_created_at`, "") === null ? "" : (_get(formState, `${formKey}.tx_created_at`, ""))}
              </div>


              <div className="col-md-6">
                <strong>Txn. Type: </strong>

                {_get(formState, `${formKey}.tx_type`, "")}
              </div>

              <div className="col-md-6">

                <strong>Txn. Created By: </strong> {_get(formState, `${formKey}.tx_created_by`, '') + (_get(formState, `${formKey}.tx_role_id`, '') === null || _get(formState, `${formKey}.tx_role_id`, '') === "-" ? " " : ('(' + _get(formState, `${formKey}.tx_role_id`, '') + ')'))}
              </div>
              <div className="col-md-6">
                <strong>Txn. No.: </strong> {_get(formState, `${formKey}.tx_no`, '-')}
              </div>

              {_get(formState, `${formKey}.migrated`, 0) === 0 ? '' :
                <div className="col-md-6">
                  <strong>Migrated</strong>
                </div>
              }
            </div>
          </div>
          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-6">
                <strong>Lic. No. /Rec. No.: </strong> {_get(formState, `${formKey}.licence_number`, "-")}
              </div>
              <div className="col-md-6">
                <strong>Licence Type: </strong>
                {_get(formState, `${formKey}.lcn_type_code`, "")}
              </div>

              <div className="col-md-6">
                <strong>Manual Receipt No.: </strong>
                {_get(formState, `${formKey}.arrears_only`, "No") === "Yes" ? _get(formState, `${formKey}.manual_receipt_number`, "") : null}
              </div>

              <div className="col-md-6">
                <strong>Licence Issue Mode: </strong>
                {_get(formState, `${formKey}.lcn_issue_mode`, false) === "TEMP" ? 'Temporary Licence' : 'Full Licence'}
              </div>

              <div className="col-md-6">
                <strong>Year: </strong>{dateObjectToString(_get(formState, `${formKey}.tx_date`, '-'), "YYYY")}
              </div>
              <div className="col-md-6 d-flex flex-row">
                <strong>Validity Period:&nbsp; <br /> (From - To)  </strong>
                <span>{`${isEmpty(_get(formState, `${formKey}.lcn_valid_from`, null)) ? "" : dateObjectToString(_get(formState, `${formKey}.lcn_valid_from`, null), 'dd/mm/YY')} - ${isEmpty(_get(formState, `${formKey}.lcn_valid_to`, null)) ? "" : dateObjectToString(_get(formState, `${formKey}.lcn_valid_to`, null), 'dd/mm/YY')}`}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 defaultMarginTop">
            <div className="row">

              <div className="col-md-6">
                <strong>Arrears Only: </strong> {_get(formState, `${formKey}.arrears_only`, 'No')}
              </div>

              <div className="col-md-6">
                <strong>Temporary Licence: </strong> {_get(formState, `${formKey}.temp_licence`, 'No')}
              </div>

              <div className="col-md-6">
                <strong>Re-Issuance: </strong> {_get(formState, `${formKey}.reissueance`, 'No')}
              </div>



              <div className="col-md-6">
                <strong>Web Licence: </strong> {_get(formState, `${formKey}.is_web_licence`, 'No')}
              </div>

              <div className="col-md-6 d-flex flex-row">
                <strong>Paid Period:&nbsp;</strong>
                <span>{`${isEmpty(_get(formState, `${formKey}.paid_from`, null)) ? "" : dateObjectToString(_get(formState, `${formKey}.paid_from`, null), 'dd/mm/YY')} - ${isEmpty(_get(formState, `${formKey}.paid_to`, null)) ? "" : dateObjectToString(_get(formState, `${formKey}.paid_to`, null), 'dd/mm/YY')}`}</span>
              </div>

            </div>
          </div>
          {uiType === 'web-licence' &&
            <div className="col-md-12 defaultMarginTop">
              <div className="row">

                <div className="col-md-6">
                  <strong>Web User Contact No.: </strong> {_get(formState, `${formKey}.web_user_contact_number`, '')}
                </div>
                <div className="col-md-6">
                  <strong>Print Status: </strong> {_get(formState, `${formKey}.print_status`, '')}
                </div>

                <div className="col-md-12">
                  <strong>Web User Email: </strong> {_get(formState, `${formKey}.web_user_email`, '')}
                </div>

              </div>
            </div>
          }
          {!isEmpty(_get(formState, `${formKey}.free_org`, '')) ?
            <div className="col-md-12 defaultMarginTop">
              <div className="row">
                <div className="col-md-6">
                  <strong>Free Licence Issued Organization: </strong> {_get(formState, `${formKey}.free_org`, '')}
                </div>

                <div className="col-md-6">
                  <strong>Organization Address: </strong> {_get(formState, `${formKey}.free_org_address`, '')}
                </div>

                <div className="col-md-6">
                  <strong>Free Licence Issued Date: </strong> {_get(formState, `${formKey}.free_licence_issuedate`, null) ? dateObjectToString(_get(formState, `${formKey}.free_licence_issuedate`, null), 'dd/mm/YY') : ""}
                </div>
              </div>
            </div> : null}

          {!isEmpty(_get(formState, `${formKey}.non_user_org`, '')) ?
            <div className="col-md-12 defaultMarginTop">
              <div className="row">
                <div className="col-md-6">
                  <strong>Non-User Licence Issued Organization: </strong> {_get(formState, `${formKey}.non_user_org`, '')}
                </div>

                <div className="col-md-6">
                  <strong>Organization Address: </strong> {_get(formState, `${formKey}.non_user_org_address`, '')}
                </div>

                <div className="col-md-6">
                  <strong>Non-User Licence Issued Date: </strong> {_get(formState, `${formKey}.non_user_licence_issueddate`, null)?dateObjectToString(_get(formState, `${formKey}.non_user_licence_issueddate`, null), 'dd/mm/YY'):""}
                </div>
              </div>
            </div> : null}

          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
                <UICard cardHeading="Document Validation Details">
                  {
                    isEmpty(_get(formState, `${formKey}.documents`, [])) ?
                      <div className="notificationContentWrapper">
                        <p>No record(s) found.</p>
                      </div>
                      : <DataTable
                        isSetTableHeader={false}
                        isSetAction={false}
                        tableHeaderList={[
                          { displayName: "Document", key: "doc_type_code", isSort: false, onPrintFn: ({ doc_type_code }) => doc_type_code === 'INS' ? "Insurance" : doc_type_code },
                          { displayName: "Document No.", key: "doc_ref_no", isSort: false, onPrintFn: ({ doc_ref_no }) => isEmpty(doc_ref_no) ? "-" : doc_ref_no },
                          { displayName: "Company", key: "doc_company_name", isSort: false, onPrintFn: ({ doc_company_name }) => isEmpty(doc_company_name) ? "-" : doc_company_name },
                          { displayName: "Issued Date", key: "issued_date", isSort: false, onPrintFn: ({ issued_date }) => isEmpty(issued_date) ? "-" : dateObjectToString(issued_date, "dd/mm/YY") },
                          { displayName: "Expiry Date", key: "expiry_date", isSort: false, onPrintFn: ({ expiry_date }) => isEmpty(expiry_date) ? "-" : dateObjectToString(expiry_date, "dd/mm/YY") },
                        ]}
                        tableBodyList={_get(formState, `${formKey}.documents`, [])}
                        isSetTableFooter={false}
                      />
                  }
                </UICard>
              </div>

            </div>
          </div>

          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
                <UICard cardHeading={`Charge Details`}>
                  {
                    isEmpty(_get(formState, `${formKey}.charges`, [])) ?
                      <div className="notificationContentWrapper">
                        <p>No record(s) found.</p>
                      </div>
                      :
                      <DataTable
                        tableName={`Total Amount (LKR) : ${setThousandSeparator(_get(formState, `${formKey}.paymentDetails.totalAmount`, '0.00'), 2)}`}
                        isSetTableHeader={true}
                        isSetAction={false}
                        tableHeaderList={[
                          { displayName: "Charge Type", key: "charge_type_name", isSort: false },
                          { displayName: "Duration", key: "charge_from", isSort: false, onPrintFn: (row) => `${isEmpty(row.charge_from) ? "" : dateObjectToString(row.charge_from, "dd/mm/YY")} - ${isEmpty(row.charge_to) ? "" : dateObjectToString(row.charge_to, "dd/mm/YY")}` },
                          { displayName: "Addition/ Deduction", key: "addition_deduction_name", isSort: false, onPrintFn: ({ addition_deduction_name }) => isEmpty(addition_deduction_name) ? "" : addition_deduction_name },
                          { displayName: "Charge Prov. Code", key: "charge_pdmt_code", isSort: false },
                          { displayName: "Amount(LKR)", key: "amount", isSort: false, isRightAlign: true, onPrintFn: ({ amount }) => parseFloat(amount) < 0 ? `(${setThousandSeparator(Math.abs(parseFloat(amount)), 2)})` : setThousandSeparator(parseFloat(amount), 2) },
                          { displayName: "Waived Off Amount(LKR)", key: "waived_off_amount", isSort: false, isRightAlign: true, onPrintFn: ({ waived_off_amount }) => isEmpty(waived_off_amount) || waived_off_amount === 0 ? "0.00" : `(${waived_off_amount})` },
                        ]}
                        tableBodyList={_get(formState, `${formKey}.charges`, [])}
                        isSetTableFooter={false}
                      />}
                </UICard>
              </div>
            </div>
          </div>
          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
                <UICard cardHeading="Payment Details">
                  <DataTable
                    isSetAction={false}
                    tableHeaderList={[
                      { displayName: "Payment Ref. No", key: "payment_request_ref_no", isSort: false },
                      {
                        displayName: "Payment Type",
                        key: "payment_type_name",
                        isSort: false,
                        onPrintFn: (rawData) => pdmtPaymentCategories[rawData.payment_type_name]
                      },
                      { displayName: "Amount(LKR)", key: "payment_type_amount", isSort: false, isRightAlign: true, onPrintFn: ({ payment_type_amount }) => !isEmpty(payment_type_amount) ? setThousandSeparator(payment_type_amount, 2) : '' },
                      { displayName: "Approval Code", key: "approval_code", isSort: false, onPrintFn: ({ approval_code }) => isEmpty(approval_code) ? "" : approval_code },
                      { displayName: "Payment Gateway Code", key: "payment_gateway_code", isSort: false, onPrintFn: ({ payment_gateway_code }) => isEmpty(payment_gateway_code) ? "" : payment_gateway_code }
                    ]}
                    tableBodyList={_get(formState, `${formKey}.payments`, [])}
                    isSetTableFooter={false}
                    isSetTableHeader={false}
                  />
                </UICard>
              </div>
            </div>
          </div>


          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
                <UICard cardHeading="Printed Details">
                  {!isEmpty(_get(formState, `${formKey}.print_history`, [])) ?
                    <DataTable
                      isSetTableHeader={false}
                      isSetAction={false}
                      tableHeaderList={[
                        { displayName: "Print Seq. No.", key: "__no__", isSort: false },
                        { displayName: "Print Type", key: "print_type", isSort: false },
                        { displayName: "Date & Time", key: "printed_at", isSort: false, onPrintFn: ({ printed_at }) => isEmpty(printed_at) ? "-" : dateObjectToString(printed_at, "dd/mm/YY HH:MM") },
                        {
                          displayName: "Printed by",
                          key: "printed_by",
                          isSort: false,
                          onPrintFn: (rawData) => isEmpty(rawData.printed_by) ? "-" : `${rawData.printed_by} (${rawData.printed_role_id})`
                        },
                        {
                          displayName: "Printed at",
                          key: "printed_location",
                          isSort: false,
                          onPrintFn: (rawData) => rawData.province == '-' ? "" :
                            (rawData.province == '-' || isEmpty(rawData.province) ? ""
                              : ProvincesPrefixMatcher[rawData.province]) + "(" + (isEmpty(rawData.printed_location) ? "-" : rawData.printed_location) + ")"
                        },
                        {
                          displayName: "Serial Number",
                          key: "serial_number",
                          isSort: false,
                          onPrintFn: ({ serial_number }) => (_get(formState, `${formKey}.manual_licence`, 'No') === 'Yes' && isEmpty(serial_number))
                            ? _get(formState, `${formKey}.manual_receipt_number`, '') : serial_number
                        },
                      ]}
                      tableBodyList={_get(formState, `${formKey}.print_history`, [])}
                      isSetTableFooter={false}

                      specificComponents={{
                        "__no__": RowNo
                      }}

                    /> : <div className="notificationContentWrapper">
                      <p>No record(s) found.</p>
                    </div>}
                </UICard>
              </div>
            </div>
          </div>
          {uiType != 'web-licence' && _get(formState, `${formKey}.reversed_txn`, null) ?
            <div className="col-md-12 defaultMarginTop">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="defaultHeading defaultHalfMarginBottom">
                    Reversed Transaction Details
                  </h1>

                  <div className="row">
                    <div className="col-md-6">
                      <strong>Txn. Reversed Request ID: </strong> {_get(formState, `${formKey}.ui_request_id`, '-')}
                    </div>

                    <div className="col-md-6 d-flex flex-row">
                      <strong>Txn. Reversed Requested:&nbsp; <br />
                        Date & Time  </strong> <span>{_get(formState, `${formKey}.reversed_created_at`, null) === null ? "-" : dateObjectToString(_get(formState, `${formKey}.reversed_created_at`, null), 'dd/mm/YY HH:MM')}</span>
                    </div>
                    <div className="col-md-6">
                      <strong>Reversal Approved Date & Time: </strong> {_get(formState, `${formKey}.reversed_approved_at`, null) === null ? "-" : dateObjectToString(_get(formState, `${formKey}.reversed_approved_at`, null), 'dd/mm/YY HH:MM')}
                    </div>

                    <div className="col-md-6">
                      <strong>Requested By: </strong> {_get(formState, `${formKey}.reversed_created_by`, '-')}({_get(formState, `${formKey}.reversed_created_role`, '-')})
                    </div>
                    <div className="col-md-6">
                      <strong>Approved By: </strong> {_get(formState, `${formKey}.reversed_approved_by`, '')}({_get(formState, `${formKey}.reversed_approved_role`, '')})
                    </div>
                    <div className="col-md-6">
                      <strong>Requested Location: </strong>{ProvincesPrefixMatcher[_get(formState, `${formKey}.reversed_created_prefix`, '')]} ({_get(formState, `${formKey}.reversed_created_authority`, '')})
                    </div>
                    <div className="col-md-6">
                      <strong>Approved Location: </strong> {ProvincesPrefixMatcher[_get(formState, `${formKey}.reversed_approved_prefix`, '')]}({_get(formState, `${formKey}.reversed_approved_authority`, '')})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : ''
          }
        </div>
      </div>
    </FormWrapper>
  );
};

export { ViewTransaction };
