import React, { Fragment } from "react";
import { ReportLicense } from "./pages/license-reports/License";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
// import {VehicleClassSummary} from './pages/license-reports/vehicle-classes-summary/VehicleClassSummary'

//Income details Report start
import { IncomeDistributionReport } from "./pages/income-details-reports/income-distribution-report/IncomeDistributionReport"
import { IncomeDetailsReports } from "./pages/income-details-reports/IncomeDetailsReports";
import { DetailedIncomeDistributionReport } from "./pages/income-details-reports/detailed-income-distribution-report/DetailedIncomeDistributionReport";
import { IncomeFromOtherProvinceReport } from "./pages/income-details-reports/income-from-other-province/IncomeFromOtherProvinceReport"
import { DetailedIncomeReport } from "./pages/income-details-reports/detailed-income/DetailedIncomeReport";
import { OtherIncomeReport } from "./pages/income-details-reports/other-income/OtherIncomeReport";
import { DetailedAdditionDeductionReport } from "./pages/income-details-reports/detailed-addition-deduction/DetailedAdditionDeductionReport";
import { TransactionSummaryReport } from "./pages/income-details-reports/transaction-summary/TransactionSummaryReport";
import { YearlyTransactionSummaryReport } from "./pages/income-details-reports/yearly-transaction-summary/YearlyTransactionSummaryReport";
import { MonthlyTransactionSummaryReport } from "./pages/income-details-reports/monthly-transaction-summary/MonthlyTransactionSummaryReport";
import { SummaryMotorVehicleRevenueReport } from './pages/income-details-reports/summary-motor-vehicle-revenue/SummaryMotorVehicleRevenueReport';
import { DailyCounterTransactionSummaryReport } from './pages/income-details-reports/daily-counter-transaction-summary/DailyCounterTransactionSummaryReport';
import { VehicleLicenceRevenueTurnover } from './pages/income-details-reports/vehicle-licence-revenue-turnover/VehicleLicenceRevenueTurnover';
import { VehicleRevenueLicenceCollectionReport } from './pages/income-details-reports/vehicle-revenue-licence-collection/VehicleRevenueLicenceCollectionReport';
import { SummaryOfArrearsPaymentReport } from "./pages/income-details-reports/summary-arrears-payment/SummaryOfArrearsPaymentReport";
import { DailyAuthorityWiseSummaryReport } from './pages/income-details-reports/daily-authority-wise-summary/DailyAuthorityWiseSummaryReport';
// Income Details Report end

import authorized from "../../hoc-middlewares/Authorized";

//Serial Number Details Report start
import { ReportSerial } from "./pages/serial-number/Serial"
import { DiscardedSerialNumberReport } from "./pages/serial-number/discarded-serial-number/DiscardedSerialNumberReport";
import { PreprintedSerialNumberSummaryReport } from "./pages/serial-number/preprinted-serial-number/PreprintedSerialNumberSummaryReport";
//Serial Number Details Report end

// Other Report start
import { Other } from "./pages/other/Other"
import {FitnessIssuanceReport} from "./pages/other/fitness-issuance/FitnessIssuanceReport";
import {LossRevenueReport} from "./pages/other/loss-revenue/LossRevenueReport";
import {ManualEntryReport} from "./pages/other/manual-entry/ManualEntryReport";
import {RevenueLicenceRatesReport} from "./pages/other/revenue-licence-rate/RevenueLicenceRatesReport";
// Other Report end

//import { DetailedIncomeReportFinance } from "./pages/finance-reports/detailed-income/DetailedIncomeReport"
//import { IncomeDistributionReportFinance } from "./pages/finance-reports/income-distribution-report/IncomeDistributionReport"

// Vehicle Details starts
import { VehicleDetailReport } from "./pages/vehicle-details-reports/VehicleDetailReport";
import { VehicleTransferReport } from "./pages/vehicle-details-reports/vehicle-transfer-report/VehicleTransferReport"
import { VehicleTransferValidationReport } from "./pages/vehicle-details-reports/vehicle-transfer-validation-report/VehicleTransferValidationReport"
import { VehicleCountSummaryReport } from "./pages/vehicle-details-reports/vehicle-count-summary-report/VehicleCountSummaryReport"
import { VehicleEditSummaryReport } from "./pages/vehicle-details-reports/vehicle-edit-summary-report/VehicleEditSummaryReport"
// Vehicle Details end

// License Reports starts
import { SummaryOfVehicleClassReport} from "./pages/license-reports/summary-of-vehicle-classes-report/license-issuance/SummaryOfVehicleClassReport"
import { SummaryOfVehicleClassReport2} from "./pages/license-reports/summary-of-vehicle-classes-report/license-issuance-from-other-provinces/SummaryOfVehicleClassReport"
import { ReversedLicenseReport} from "./pages/license-reports/reversed-license-report/ReversedLicenseReport"
import { VehicleLicenseHistoryReport} from "./pages/license-reports/vehicle-license-history-report/VehicleLicenseHistoryReport"
import { IssuedLicenseVehicleClassDetailReport} from "./pages/license-reports/issued-license-vehicle-class-detail-report/IssuedLicenseVehicleClassDetailReport"
import { FirstLicenseIssuedVehicleDetailsReport} from "./pages/license-reports/first-license-issued-vehicle-details-report/FirstLicenseIssuedVehicleDetailsReport"
import { IssuedLicenseCountSummaryReport} from "./pages/license-reports/issued-license-count-summary-report/IssuedLicenseCountSummaryReport"
import { NonLicensedVehiclesReport} from "./pages/license-reports/non-licensed-vehicles-reports/NonLicensedVehiclesReport"
import { UserLicenseIssuedCountReport} from "./pages/license-reports/user-license-issued-count-report/UserLicenseIssuedCountReport"
import { EcoTestCertificateReport} from "./pages/license-reports/eco-test-certificat-report/license-issuance/EcoTestCertificateReportLicenseIssuance"
import { EcoTestCertificateReport2 } from "./pages/license-reports/eco-test-certificat-report/license-issuance-from-other-provinces/EcoTestCertificateReportLicenseIssuance2";
// License Reports end

// Online License Details starts
import { OnlineLicenseDetailsReport } from "./pages/online-license-details-reports/OnlineLicenseDetails";
import { OnlineLicenseRequestReport } from "./pages/online-license-details-reports/online-license-request-report/OnlineLicenseRequestReport";
import { OnlineLicenseRenewalRequestReport } from "./pages/online-license-details-reports/online-license-renewal-request-report/OnlineLicenseRenewalRequestReport";
import { OnlineTransactionReport } from "./pages/online-license-details-reports/online-transaction-report/OnlineTransactionReport";
import { PrintedWebLicenseReport } from "./pages/online-license-details-reports/printed-web-license-report/PrintedWebLicenseReport";
// Online License Details end

// User Details starts
import { UserDetailsReport } from "./pages/user-details-reports/UserDetailReportsList";
import { ActiveUserReport } from "./pages/user-details-reports/active-user-report/ActiveUserReport";
import { InactiveUserReport } from "./pages/user-details-reports/inactive-user-report/InactiveUserReport";
import { LoginAfterOfficeHoursReport } from "./pages/user-details-reports/login-after-office-hours-report/LoginAfterOfficeHoursReport";
import { UserActivityLogReport } from "./pages/user-details-reports/user-activity-log-report/UserActivityLogReport";
// User Details end

const ReportRoutes = () => {
  return (
    <Fragment>
      {/* Report categories  list start*/}


      <Navigate
        exact={true}
        path="/report-user-details"
        routeKey={"report-user-details"}
        component={authorized(UserDetailsReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/report-license"
        routeKey={"report-license"}
        component={authorized(ReportLicense)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/report-online-licence"
        routeKey={"report-online-licence"}
        component={authorized(OnlineLicenseDetailsReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/income-details"
        routeKey={"income-details"}
        component={authorized(IncomeDetailsReports)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/vehicle-details"
        routeKey={"vehicle-details"}
        component={authorized(VehicleDetailReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/report-serial-number"
        routeKey={"report-serial-number"}
        component={authorized(ReportSerial)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/report-other"
        routeKey={"report-other"}
        component={authorized(Other)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Report categories  list end*/}

      {/*---------------- Income Details Reports Routes start-------------- */}
      <Navigate
        exact={true}
        path="/income-distribution"
        routeKey={"income-distribution"}
        component={authorized(IncomeDistributionReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/detailed-income-distribution"
        routeKey={"detailed-income-distribution"}
        component={authorized(DetailedIncomeDistributionReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/report-detailed-income"
        routeKey={"report-detailed-income"}
        component={authorized(DetailedIncomeReport)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/income-province"
        routeKey={"income-province"}
        component={authorized(IncomeFromOtherProvinceReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/other-income"
        routeKey={"other-income"}
        component={authorized(OtherIncomeReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/detailed-addition-deduction"
        routeKey={"detailed-addition-deduction"}
        component={authorized(DetailedAdditionDeductionReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/transaction-summary"
        routeKey={"transaction-summary"}
        component={authorized(TransactionSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/yearly-transaction-summary"
        routeKey={"yearly-transaction-summary"}
        component={authorized(YearlyTransactionSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/monthly-transaction-summary"
        routeKey={"monthly-transaction-summary"}
        component={authorized(MonthlyTransactionSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/summary-motor-vehicle-revenue"
        routeKey={"summary-motor-vehicle-revenue"}
        component={authorized(SummaryMotorVehicleRevenueReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/daily-counter-transaction-summary"
        routeKey={"daily-counter-transaction-summary"}
        component={authorized(DailyCounterTransactionSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/vehicle-licence-revenue-turnover"
        routeKey={"vehicle-licence-revenue-turnover"}
        component={authorized(VehicleLicenceRevenueTurnover)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/vehicle-revenue-licence-collection"
        routeKey={"vehicle-revenue-licence-collection"}
        component={authorized(VehicleRevenueLicenceCollectionReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/summary-arrears-payment"
        routeKey={"summary-arrears-payment"}
        component={authorized(SummaryOfArrearsPaymentReport)}
        routePermissions={permissions.NONE.permissions}
      />


      <Navigate
        exact={true}
        path="/daily-authority-wise-summary"
        routeKey={"daily-authority-wise-summary"}
        component={authorized(DailyAuthorityWiseSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />
      {/*---------------- Income Details Reports Routes end-------------- */}

      {/*---------------- Serial Number Details Reports Routes start-------------- */}

      <Navigate
        exact={true}
        path="/preprinted-serial-number"
        routeKey={"preprinted-serial-number"}
        component={authorized(PreprintedSerialNumberSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/discarded-serial-number"
        routeKey={"discarded-serial-number"}
        component={authorized(DiscardedSerialNumberReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/*---------------- Serial Number Details Routes end-------------- */}

      {/*---------------- Other Reports Routes start-------------- */}

      <Navigate
        exact={true}
        path="/fitness-issuance"
        routeKey={"fitness-issuance"}
        component={authorized(FitnessIssuanceReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/loss-revenue"
        routeKey={"loss-revenue"}
        component={authorized(LossRevenueReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/manual-entry"
        routeKey={"manual-entry"}
        component={authorized(ManualEntryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/revenue-licence-rate"
        routeKey={"revenue-licence-rate"}
        component={authorized(RevenueLicenceRatesReport)}
        routePermissions={permissions.NONE.permissions}
      />


      {/*---------------- Other Reports Routes end-------------- */}



      {/*---------------- License Reports Routes start-------------- */}

      {/* Summary Of Vehicle Class Report - License Issuance */}
      <Navigate
        exact={true}
        path="/summary-of-vehicle-class-report"
        routeKey={"summary-of-vehicle-class-report"}
        component={authorized(SummaryOfVehicleClassReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Summary Of Vehicle Class Report - License Issuanc From Other Provinces */}
      <Navigate
        exact={true}
        path="/summary-of-vehicle-class-report-other-provinces"
        routeKey={"summary-of-vehicle-class-report-other-provinces"}
        component={authorized(SummaryOfVehicleClassReport2)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Reversed License Report */}
      <Navigate
        exact={true}
        path="/reversed-license-report"
        routeKey={"reversed-license-reports"}
        component={authorized(ReversedLicenseReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Vehicle License History Report */}
      <Navigate
        exact={true}
        path="/vehicle-license-history-report"
        routeKey={"vehicle-license-history-report"}
        component={authorized(VehicleLicenseHistoryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Issued License Vehicle Class Detail Report */}
      <Navigate
        exact={true}
        path="/issued-license-vehicle-class-detail-report"
        routeKey={"issued-license-vehicle-class-detail-report"}
        component={authorized(IssuedLicenseVehicleClassDetailReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* First License Issued Vehicle Details Report */}
      <Navigate
        exact={true}
        path="/first-license-issued-vehicle-details-report"
        routeKey={"first-license-issued-vehicle-details-report"}
        component={authorized(FirstLicenseIssuedVehicleDetailsReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Issued License Count Summary Report */}
      <Navigate
        exact={true}
        path="/issued-license-count-summary-report"
        routeKey={"issued-license-count-summary-report"}
        component={authorized(IssuedLicenseCountSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Non Licensed Vehicles Report */}
      <Navigate
        exact={true}
        path="/non-licensed-vehicles-report"
        routeKey={"non-licensed-vehicles-report"}
        component={authorized(NonLicensedVehiclesReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* User License Issued Counter Report */}
      <Navigate
        exact={true}
        path="/user-license-issued-counter-report"
        routeKey={"user-license-issued-counter-report"}
        component={authorized(UserLicenseIssuedCountReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Eco Test Certificate Report - License Issuance */}
      <Navigate
        exact={true}
        path="/eco-test-certificate-report"
        routeKey={"eco-test-certificate-report"}
        component={authorized(EcoTestCertificateReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Eco Test Certificate Report - License Issuance From Other Provinces*/}
      <Navigate
        exact={true}
        path="/eco-test-certificate-report-other-provinces"
        routeKey={"eco-test-certificate-report-other-provinces"}
        component={authorized(EcoTestCertificateReport2)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* <Navigate
        exact={true}
        path="/license-income-distribution"
        routeKey={"licence-income-distribution"}
        component={authorized(LicenseIncomeDistributionReport)}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/license-detailed-income"
        routeKey={"licence-detailed-income"}
        component={authorized(LicenseDetailedIncomeReport)}
        routePermissions={permissions.NONE.permissions}
      />



      <Navigate
        exact={true}
        path="/summary"
        routeKey={"summary"}
        component={authorized(LicenseSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      /> */}

      {/*---------------- License Reports Routes end-------------- */}



      {/* ---------------- Finance Reports Routes start-------------- */}

      {/* DetailedIncomeReport */}
      {/* <Navigate
        exact={true}
        // path="/income-distribution"
        path="/finance-detailed-income-report"
        routeKey={"/finance-detailed-income-report"}
        component={authorized(DetailedIncomeReportFinance)}
        routePermissions={permissions.NONE.permissions}
      /> */}

      {/* IncomeDistributionReport */}
      {/* <Navigate
        exact={true}
        // path="/income-distribution"
        path="/finance-income-distribution-report"
        routeKey={"/finance-income-distribution-report"}
        component={authorized(IncomeDistributionReportFinance)}
        routePermissions={permissions.NONE.permissions}
      /> */}
      
      {/*---------------- Finance Reports Routes end-------------- */}


      {/*---------------- Vehicle Detail Reports Routes start-------------- */}

      {/* Vehicle Transfer Report */}
      <Navigate
        exact={true}
        path="/vehicle-transfer-report"
        routeKey={"vehicle-transfer-report"}
        component={authorized(VehicleTransferReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Vehicle Transfer Validation Report */}
      <Navigate
        exact={true}
        path="/vehicle-transfer-validation-report"
        routeKey={"vehicle-transfer-validation-report"}
        component={authorized(VehicleTransferValidationReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Vehicle Count Summary Report */}
      <Navigate
        exact={true}
        path="/vehicle-count-summary-report"
        routeKey={"vehicle-count-summary-report"}
        component={authorized(VehicleCountSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Vehicle Edit Smmary Report */}
      <Navigate
        exact={true}
        path="/vehicle-edit-summary-report"
        routeKey={"vehicle-edit-summary-report"}
        component={authorized(VehicleEditSummaryReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/*---------------- Vehicle Detail Reports Routes end-------------- */}

      {/* --------------- User Detail Reports Routes starts-------------- */}

      {/* Active User Report */}
      <Navigate
        exact={true}
        path="/active-user-report"
        routeKey={"active-user-report"}
        component={authorized(ActiveUserReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Inactive User Report */}
      <Navigate
        exact={true}
        path="/inactive-user-report"
        routeKey={"inactive-user-report"}
        component={authorized(InactiveUserReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Login after office hours Report */}
      <Navigate
        exact={true}
        path="/login-after-office-hours-report"
        routeKey={"login-after-office-hours-report"}
        component={authorized(LoginAfterOfficeHoursReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* User Activity Log Report */}
      <Navigate
        exact={true}
        path="/user-activity-log-report"
        routeKey={"user-activity-log-report"}
        component={authorized(UserActivityLogReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* --------------- User Detail Reports Routes starts--------------- */}

      {/* --------------- Online License Details Report Routes starts-----*/}

      {/* Online License Request Report */}
      <Navigate
        exact={true}
        path="/online-license-request-report"
        routeKey={"online-license-request-reort"}
        component={authorized(OnlineLicenseRequestReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Online License Renewal Request Report */}
      <Navigate
        exact={true}
        path="/online-license-renewal-request-report"
        routeKey={"online-license-renewal-request-report"}
        component={authorized(OnlineLicenseRenewalRequestReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Online Transaction Report */}
      <Navigate
        exact={true}
        path="/online-transaction-report"
        routeKey={"online-transaction-report"}
        component={authorized(OnlineTransactionReport)}
        routePermissions={permissions.NONE.permissions}
      />

      {/* Printed Web License Report */}
      <Navigate
        exact={true}
        path="/printed-web-license-report"
        routeKey={"printed-web-license-report"}
        component={authorized(PrintedWebLicenseReport)}
        routePermissions={permissions.NONE.permissions}
      />
      
      {/* --------------- Online License Details Report Routes end-----*/}

    </Fragment>
  );
};

export default ReportRoutes;
