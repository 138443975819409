import { getVehicleByNoAPI, updateVehicleAPI, getChargeUpdateLatestDateForVehicleAPI, uploadDetailGetAPI, updateVehicleRequestAPI } from "../../../../config/apiUrl.config";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { templateTypes } from "../../../../config/template.config";
import { getDataByFormObject, isEmptyValue, } from "../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import {
  DatePickerWithState,
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  SubmitButton,
} from "../../../ui-components/ui-elements/form";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { Action } from "./includes/Action";
import { Reason } from "./includes/Reason";
import { VehicleInformationDisplay } from "./includes/VehicleInformationDisplay";
import { RequestStatus, RequestTypesList, ActionTypeList, ProvincesIdMatcher, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { isEmpty } from "lodash";
const today = new Date();
const max_date = new Date(new Date().setHours(23, 59, 0));

const UpdateVehicleBtn = (props) => {
  const [formState] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [, uiAction] = useContext(UIContext);

  const user_role_level = _get(authStatus, "authUser.role_level_code", "");
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");

  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const approvalAction = _get(
    formState,
    "Update_Vehicle.action",
    null
  )
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={props.uiType === 'assign&Perform' ? 'Update_Vehicle' : 'update-vehicle'}
        tooltip={"Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${reqId}.`,
            messageType: "success",
          },
          failed: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request Decision Submit Failed. Please Try Again.`,
            messageType: "error",
          } : {
            // status: true,
            // message: `Approval Request Submit Failed. Please Try Again.`,
            // messageType: "error",
          },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "Update_Vehicle.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Update_Vehicle.action", "") === 'FRD' ? (_get(formState, "Update_Vehicle.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",

              chassis_no: "Chassis",
              engine_no: "Engine",
              first_reg_date: "1st Reg",
              tare_weight: "Tare",
              gross_weight: "Gross",
              no_of_seats: "Seats",
              fuel_type: "Fuel Type",
              vehicle_class: "Class",
              province: "Province",
            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",

              chassis_no: "Chassis",
              engine_no: "Engine",
              first_reg_date: "1st Reg",
              tare_weight: "Tare",
              gross_weight: "Gross",
              no_of_seats: "Seats",
              fuel_type: "Fuel Type",
              vehicle_class: "Class",
              province: "Province",
            }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",

            chassis_no: "Chassis",
            engine_no: "Engine",
            first_reg_date: "1st Reg",
            tare_weight: "Tare",
            gross_weight: "Gross",
            no_of_seats: "Seats",
            fuel_type: "Fuel Type",
            vehicle_class: "Class",
            province: "Province",

          }) : {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            "effective_date": "Effective Date",
            "new_licence_type": "New Licence Type"
          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "Update_Vehicle.action", "") === 'FRD' ? (_get(formState, "Update_Vehicle.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",

            chassis_no: "required",
            engine_no: "required",
            first_reg_date: "required",
            tare_weight: "required|minAmount:1",
            gross_weight: "required|minAmount:1",
            no_of_seats: "required|maxAmount:100|minAmount:0",
            fuel_type: "required",
            vehicle_class: "required",
            province: "required",

          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",

            chassis_no: "required",
            engine_no: "required",
            first_reg_date: "required",
            tare_weight: "required|minAmount:1",
            gross_weight: "required|minAmount:1",
            no_of_seats: "required|maxAmount:100|minAmount:0",
            fuel_type: "required",
            vehicle_class: "required",
            province: "required",
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",

            chassis_no: "required",
            engine_no: "required",
            first_reg_date: "required",
            tare_weight: "required|minAmount:1",
            gross_weight: "required|minAmount:1",
            no_of_seats: "required|maxAmount:100|minAmount:0",
            fuel_type: "required",
            vehicle_class: "required",
            province: "required",

          }) : {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required",
            "effective_date": "requiredIf:user_role_level,INT_PRV"
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "Update_Vehicle.action", "") === 'FRD' ? (_get(formState, "Update_Vehicle.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province.required": "Province is required",
            "forward_authority.required": "Authority is required",

            "chassis_no.required": "Chassis is required.",
            "engine_no.required": "Engine is required.",
            "first_reg_date.required": "1st Reg is required.",
            "tare_weight.required": "Tare is required.",
            "gross_weight.required": "Gross is required.",
            "tare_weight.minAmount": "Invalid Tare.",
            "gross_weight.minAmount": "Invalid Gross.",
            "no_of_seats.required": "Seats is required.",
            "no_of_seats.minAmount": "Please enter a number between 0-100",
            "no_of_seats.maxAmount": "Please enter a number between 0-100",
            "fuel_type.required": "Fuel Type is required.",
            "vehicle_class.required": "Class is required.",
            "province.required": "Province is required.",
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",

            "chassis_no.required": "Chassis is required.",
            "engine_no.required": "Engine is required.",
            "first_reg_date.required": "1st Reg is required.",
            "tare_weight.required": "Tare is required.",
            "gross_weight.required": "Gross is required.",
            "tare_weight.minAmount": "Invalid Tare.",
            "gross_weight.minAmount": "Invalid Gross.",
            "no_of_seats.required": "Seats is required.",
            "no_of_seats.minAmount": "Please enter a number between 0-100",
            "no_of_seats.maxAmount": "Please enter a number between 0-100",
            "fuel_type.required": "Fuel Type is required.",
            "vehicle_class.required": "Class is required.",
            "province.required": "Province is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",

            "chassis_no.required": "Chassis is required.",
            "engine_no.required": "Engine is required.",
            "first_reg_date.required": "1st Reg is required.",
            "tare_weight.required": "Tare is required.",
            "gross_weight.required": "Gross is required.",
            "tare_weight.minAmount": "Invalid Tare.",
            "gross_weight.minAmount": "Invalid Gross.",
            "no_of_seats.required": "Seats is required.",
            "no_of_seats.minAmount": "Please enter a number between 0-100",
            "no_of_seats.maxAmount": "Please enter a number between 0-100",
            "fuel_type.required": "Fuel Type is required.",
            "vehicle_class.required": "Class is required.",
            "province.required": "Province is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province": "Forward Province is required.",
            "forward_role": "Forward Role is required.",
            "action": "Action is required.",
            "effective_date.requiredIf": "Effective Date"

          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${updateVehicleAPI.url}${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
            key: updateVehicleAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "Update_Vehicle.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "Update_Vehicle.tare_weight", undefined) < 1) {
              if (_get(formState, "Update_Vehicle.gross_weight", undefined) < 1) {
                if (_get(formState, "Update_Vehicle.no_of_seats", undefined) < 0) {
                  uiAction.setFlashMessage({
                    status: true,
                    message: `Invalid number of seats, Please enter a number between 0-100.`,
                    messageType: "error",
                  });

                  return
                } else {
                  // if(_get(formState, "Update_Vehicle.cylinder_capacity", undefined) < 1) {
                  //   uiAction.setFlashMessage({
                  //       status: true,
                  //       message: `Invalid Cylinder Capacity.`,
                  //       messageType: "error",
                  //     });

                  //     return
                  // }
                  // if(_get(formState, "Update_Vehicle.tyre_size", undefined) < 1) {
                  //   uiAction.setFlashMessage({
                  //       status: true,
                  //       message: `Invalid Tyre Size.`,
                  //       messageType: "error",
                  //     });

                  //     return
                  // }

                  return getDataByFormObject({
                    ...fromObject,
                    userName: userName,
                    currentProvince: currentProvince,
                    authority: authority,
                    requestType: RequestTypesList["update_vcl"],
                    current_licence_type: current_licence_type,
                    // status: RequestStatus.APPROVAL_PENDING,
                    pdmt_id: pdmtId,
                    // authority_id: authorityId,
                    counter_session_id: '00001',
                    forward_authority_service_id: serviceId,
                    ui_request_id: props.approvalRequestId,
                    name: isEmptyValue(fromObject.name) ? null : fromObject.name,
                    address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
                    address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
                    city: isEmptyValue(fromObject.city) ? null : fromObject.city,
                    absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
                    absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
                    absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
                    absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
                    chassis_no: isEmptyValue(fromObject.chassis_no) ? " " : fromObject.chassis_no,
                    engine_no: isEmptyValue(fromObject.engine_no) ? " " : fromObject.engine_no,
                    first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
                    tare_weight: isEmptyValue(fromObject.tare_weight) ? " " : parseFloat(fromObject.tare_weight).toFixed(2),
                    gross_weight: isEmptyValue(fromObject.gross_weight)
                      ? " "
                      : parseFloat(fromObject.gross_weight).toFixed(2),
                    no_of_seats: isEmptyValue(fromObject.no_of_seats)
                      ? " "
                      : parseInt(fromObject.no_of_seats),
                    vehicle_reg_condition: isEmptyValue(fromObject.vehicle_reg_condition) ? " " : fromObject.vehicle_reg_condition,
                    fuel_type: isEmptyValue(fromObject.fuel_type) ? " " : fromObject.fuel_type,
                    vehicle_class: isEmptyValue(fromObject.vehicle_class) ? " " : fromObject.vehicle_class,
                    province: isEmptyValue(fromObject.province) ? " " : ProvincesIdMatcher[`${fromObject.province}`],
                    manufactured_year: isEmptyValue(fromObject.manufactured_year) ? " " : new Date(fromObject.manufactured_year).getFullYear(),
                    cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                      ? " "
                      : parseFloat(fromObject.cylinder_capacity).toFixed(2),
                    tyre_size: isEmptyValue(fromObject.tyre_size)
                      ? " "
                      : fromObject.tyre_size,
                    wheel_base: isEmptyValue(fromObject.wheel_base)
                      ? " "
                      : parseFloat(fromObject.wheel_base).toFixed(2),
                    type_of_body: isEmptyValue(fromObject.type_of_body) ? " " : fromObject.type_of_body,
                    make: isEmptyValue(fromObject.make) ? " " : fromObject.make,
                    model: isEmptyValue(fromObject.model) ? " " : fromObject.model,
                    color_1: isEmptyValue(fromObject.color_1) ? " " : fromObject.color_1,
                    color_2: isEmptyValue(fromObject.color_2) ? " " : fromObject.color_2,
                    color_3: isEmptyValue(fromObject.color_3) ? " " : fromObject.color_3,
                    archived: fromObject.archived == 1 ? true : false,
                    effective_date: isEmptyValue(fromObject.effective_date) ? today : fromObject.effective_date,
                    reason_id: formState['Update_Vehicle-new'].reason_id,
                    document_details: [],
                    action: fromObject.action == 'FRD' ? {
                      action: fromObject.action,
                      remark: fromObject.remark,
                      forward_province: fromObject.forward_province,
                      forward_role: fromObject.forward_role,
                      forward_authority: fromObject.forward_authority
                    } : {
                      action: fromObject.action,
                      remark: fromObject.remark,
                    },
                  });
                }
                // uiAction.setFlashMessage({
                //     status: true,
                //     message: `Invalid Gross Weight.`,
                //     messageType: "error",
                //   });

                //   return
              } else {
                // if(_get(formState, "Update_Vehicle.cylinder_capacity", undefined) < 1) {
                //   uiAction.setFlashMessage({
                //       status: true,
                //       message: `Invalid Cylinder Capacity.`,
                //       messageType: "error",
                //     });

                //     return
                // }
                // if(_get(formState, "Update_Vehicle.tyre_size", undefined) < 1) {
                //   uiAction.setFlashMessage({
                //       status: true,
                //       message: `Invalid Tyre Size.`,
                //       messageType: "error",
                //     });

                //     return
                // }

                return getDataByFormObject({
                  ...fromObject,
                  userName: userName,
                  currentProvince: currentProvince,
                  authority: authority,
                  requestType: RequestTypesList["update_vcl"],
                  current_licence_type: current_licence_type,
                  // status: RequestStatus.APPROVAL_PENDING,
                  pdmt_id: pdmtId,
                  // authority_id: authorityId,
                  counter_session_id: '00001',
                  forward_authority_service_id: serviceId,
                  ui_request_id: props.approvalRequestId,
                  name: isEmptyValue(fromObject.name) ? null : fromObject.name,
                  address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
                  address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
                  city: isEmptyValue(fromObject.city) ? null : fromObject.city,
                  absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
                  absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
                  absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
                  absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
                  chassis_no: isEmptyValue(fromObject.chassis_no) ? " " : fromObject.chassis_no,
                  engine_no: isEmptyValue(fromObject.engine_no) ? " " : fromObject.engine_no,
                  first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
                  tare_weight: isEmptyValue(fromObject.tare_weight) ? " " : parseFloat(fromObject.tare_weight).toFixed(2),
                  gross_weight: isEmptyValue(fromObject.gross_weight)
                    ? " "
                    : parseFloat(fromObject.gross_weight).toFixed(2),
                  no_of_seats: isEmptyValue(fromObject.no_of_seats)
                    ? " "
                    : parseInt(fromObject.no_of_seats),
                  vehicle_reg_condition: isEmptyValue(fromObject.vehicle_reg_condition) ? " " : fromObject.vehicle_reg_condition,
                  fuel_type: isEmptyValue(fromObject.fuel_type) ? " " : fromObject.fuel_type,
                  vehicle_class: isEmptyValue(fromObject.vehicle_class) ? " " : fromObject.vehicle_class,
                  province: isEmptyValue(fromObject.province) ? " " : ProvincesIdMatcher[`${fromObject.province}`],
                  manufactured_year: isEmptyValue(fromObject.manufactured_year) ? " " : new Date(fromObject.manufactured_year).getFullYear(),
                  cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                    ? " "
                    : parseFloat(fromObject.cylinder_capacity).toFixed(2),
                  tyre_size: isEmptyValue(fromObject.tyre_size)
                    ? " "
                    : fromObject.tyre_size,
                  wheel_base: isEmptyValue(fromObject.wheel_base)
                    ? " "
                    : parseFloat(fromObject.wheel_base).toFixed(2),
                  type_of_body: isEmptyValue(fromObject.type_of_body) ? " " : fromObject.type_of_body,
                  make: isEmptyValue(fromObject.make) ? " " : fromObject.make,
                  model: isEmptyValue(fromObject.model) ? " " : fromObject.model,
                  color_1: isEmptyValue(fromObject.color_1) ? " " : fromObject.color_1,
                  color_2: isEmptyValue(fromObject.color_2) ? " " : fromObject.color_2,
                  color_3: isEmptyValue(fromObject.color_3) ? " " : fromObject.color_3,
                  archived: fromObject.archived == 1 ? true : false,
                  effective_date: isEmptyValue(fromObject.effective_date) ? today : fromObject.effective_date,
                  reason_id: formState['Update_Vehicle-new'].reason_id,
                  document_details: [],
                  action: fromObject.action == 'FRD' ? {
                    action: fromObject.action,
                    remark: fromObject.remark,
                    forward_province: fromObject.forward_province,
                    forward_role: fromObject.forward_role,
                    forward_authority: fromObject.forward_authority
                  } : {
                    action: fromObject.action,
                    remark: fromObject.remark,
                  },
                });
              }
            } else {
              // if(_get(formState, "Update_Vehicle.cylinder_capacity", undefined) < 1) {
              //   uiAction.setFlashMessage({
              //       status: true,
              //       message: `Invalid Cylinder Capacity.`,
              //       messageType: "error",
              //     });

              //     return
              // }
              // if(_get(formState, "Update_Vehicle.tyre_size", undefined) < 1) {
              //   uiAction.setFlashMessage({
              //       status: true,
              //       message: `Invalid Tyre Size.`,
              //       messageType: "error",
              //     });

              //     return
              // }

              return getDataByFormObject({
                ...fromObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["update_vcl"],
                current_licence_type: current_licence_type,
                // status: RequestStatus.APPROVAL_PENDING,
                pdmt_id: pdmtId,
                // authority_id: authorityId,
                counter_session_id: '00001',
                forward_authority_service_id: serviceId,
                ui_request_id: props.approvalRequestId,
                name: isEmptyValue(fromObject.name) ? null : fromObject.name,
                address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
                address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
                city: isEmptyValue(fromObject.city) ? null : fromObject.city,
                absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
                absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
                absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
                absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
                chassis_no: isEmptyValue(fromObject.chassis_no) ? " " : fromObject.chassis_no,
                engine_no: isEmptyValue(fromObject.engine_no) ? " " : fromObject.engine_no,
                first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
                tare_weight: isEmptyValue(fromObject.tare_weight) ? " " : parseFloat(fromObject.tare_weight).toFixed(2),
                gross_weight: isEmptyValue(fromObject.gross_weight)
                  ? " "
                  : parseFloat(fromObject.gross_weight).toFixed(2),
                no_of_seats: isEmptyValue(fromObject.no_of_seats)
                  ? " "
                  : parseInt(fromObject.no_of_seats),
                vehicle_reg_condition: isEmptyValue(fromObject.vehicle_reg_condition) ? " " : fromObject.vehicle_reg_condition,
                fuel_type: isEmptyValue(fromObject.fuel_type) ? " " : fromObject.fuel_type,
                vehicle_class: isEmptyValue(fromObject.vehicle_class) ? " " : fromObject.vehicle_class,
                province: isEmptyValue(fromObject.province) ? " " : ProvincesIdMatcher[`${fromObject.province}`],
                manufactured_year: isEmptyValue(fromObject.manufactured_year) ? " " : new Date(fromObject.manufactured_year).getFullYear(),
                cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                  ? " "
                  : parseFloat(fromObject.cylinder_capacity).toFixed(2),
                tyre_size: isEmptyValue(fromObject.tyre_size)
                  ? " "
                  : fromObject.tyre_size,
                wheel_base: isEmptyValue(fromObject.wheel_base)
                  ? " "
                  : parseFloat(fromObject.wheel_base).toFixed(2),
                type_of_body: isEmptyValue(fromObject.type_of_body) ? " " : fromObject.type_of_body,
                make: isEmptyValue(fromObject.make) ? " " : fromObject.make,
                model: isEmptyValue(fromObject.model) ? " " : fromObject.model,
                color_1: isEmptyValue(fromObject.color_1) ? " " : fromObject.color_1,
                color_2: isEmptyValue(fromObject.color_2) ? " " : fromObject.color_2,
                color_3: isEmptyValue(fromObject.color_3) ? " " : fromObject.color_3,
                archived: fromObject.archived == 1 ? true : false,
                effective_date: isEmptyValue(fromObject.effective_date) ? today : fromObject.effective_date,
                reason_id: formState['Update_Vehicle-new'].reason_id,
                document_details: [],
                action: fromObject.action == 'FRD' ? {
                  action: fromObject.action,
                  remark: fromObject.remark,
                  forward_province: fromObject.forward_province,
                  forward_role: fromObject.forward_role,
                  forward_authority: fromObject.forward_authority
                } : {
                  action: fromObject.action,
                  remark: fromObject.remark,
                },
              });
            }

          } else {
            if (_get(formState, "update-vehicle.tare_weight", undefined) < 1) {
              if (_get(formState, "update-vehicle.gross_weight", undefined) < 1) {
                if (_get(formState, "update-vehicle.no_of_seats", undefined) < 0) {
                  uiAction.setFlashMessage({
                    status: true,
                    message: `Invalid number of seats, Please enter a number between 0-100.`,
                    messageType: "error",
                  });

                  return
                } else {
                  // if(_get(formState, "update-vehicle.cylinder_capacity", undefined) < 1) {
                  //   uiAction.setFlashMessage({
                  //       status: true,
                  //       message: `Invalid Cylinder Capacity.`,
                  //       messageType: "error",
                  //     });

                  //     return
                  // }
                  // if(_get(formState, "update-vehicle.tyre_size", undefined) < 1) {
                  //   uiAction.setFlashMessage({
                  //       status: true,
                  //       message: `Invalid Tyre Size.`,
                  //       messageType: "error",
                  //     });

                  //     return
                  // }
                  return getDataByFormObject({
                    ...fromObject,
                    userName: userName,
                    currentProvince: currentProvince,
                    authority: authority,
                    requestType: RequestTypesList["update_vcl"],
                    current_licence_type: current_licence_type,
                    status: RequestStatus.APPROVAL_PENDING,
                    pdmt_id: pdmtId,
                    authority_id: authorityId,
                    counter_session_id: sessionId ? sessionId : null,
                    forward_authority_service_id: serviceId,
                    ui_request_id: fromObject.request_id,
                    name: isEmptyValue(fromObject.name) ? null : fromObject.name,
                    address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
                    address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
                    city: isEmptyValue(fromObject.city) ? null : fromObject.city,
                    absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
                    absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
                    absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
                    absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
                    chassis_no: isEmptyValue(fromObject.chassis_no) ? " " : fromObject.chassis_no,
                    engine_no: isEmptyValue(fromObject.engine_no) ? " " : fromObject.engine_no,
                    first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
                    tare_weight: isEmptyValue(fromObject.tare_weight) ? 0.00 : parseFloat(fromObject.tare_weight).toFixed(2),
                    gross_weight: isEmptyValue(fromObject.gross_weight)
                      ? 0.00
                      : parseFloat(fromObject.gross_weight).toFixed(2),
                    no_of_seats: isEmptyValue(fromObject.no_of_seats)
                      ? " "
                      : parseInt(fromObject.no_of_seats),
                    vehicle_reg_condition: isEmptyValue(fromObject.vehicle_reg_condition) ? " " : fromObject.vehicle_reg_condition,
                    fuel_type: isEmptyValue(fromObject.fuel_type) ? " " : fromObject.fuel_type,
                    vehicle_class: isEmptyValue(fromObject.vehicle_class) ? " " : fromObject.vehicle_class,
                    province: isEmptyValue(fromObject.province) ? " " : ProvincesIdMatcher[`${fromObject.province}`],
                    manufactured_year: isEmptyValue(fromObject.manufactured_year) ? " " : new Date(fromObject.manufactured_year).getFullYear(),
                    cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                      ? 0.00
                      : parseFloat(fromObject.cylinder_capacity).toFixed(2),
                    tyre_size: isEmptyValue(fromObject.tyre_size)
                      ? " "
                      : fromObject.tyre_size,
                    wheel_base: isEmptyValue(fromObject.wheel_base)
                      ? 0.00
                      : parseFloat(fromObject.wheel_base).toFixed(2),
                    type_of_body: isEmptyValue(fromObject.type_of_body) ? " " : fromObject.type_of_body,
                    make: isEmptyValue(fromObject.make) ? " " : fromObject.make,
                    model: isEmptyValue(fromObject.model) ? " " : fromObject.model,
                    color_1: isEmptyValue(fromObject.color_1) ? " " : fromObject.color_1,
                    color_2: isEmptyValue(fromObject.color_2) ? " " : fromObject.color_2,
                    color_3: isEmptyValue(fromObject.color_3) ? " " : fromObject.color_3,
                    archived: fromObject.archived === "1" ? true : false,
                    carnet_licence_start_at: props.isCarnet && _get(formState, 'carnet.fromDate', null),
                    carnet_licence_end_at: props.isCarnet && _get(formState, 'carnet.toDate', null),
                    effective_date: isEmptyValue(fromObject.effective_date) ? today : fromObject.effective_date,
                    reason_id: fromObject.reason_id,
                    document_details: [],
                    action: {
                      action: fromObject.action,
                      remark: fromObject.remark,
                      forward_province: fromObject.forward_province,
                      forward_role: fromObject.forward_role,
                      forward_authority: fromObject.forward_authority
                    }
                  });
                }
                // uiAction.setFlashMessage({
                //     status: true,
                //     message: `Invalid Gross Weight.`,
                //     messageType: "error",
                //   });

                //   return
              } else {
                // if(_get(formState, "update-vehicle.cylinder_capacity", undefined) < 1) {
                //   uiAction.setFlashMessage({
                //       status: true,
                //       message: `Invalid Cylinder Capacity.`,
                //       messageType: "error",
                //     });

                //     return
                // }
                // if(_get(formState, "update-vehicle.tyre_size", undefined) < 1) {
                //   uiAction.setFlashMessage({
                //       status: true,
                //       message: `Invalid Tyre Size.`,
                //       messageType: "error",
                //     });

                //     return
                // }
                return getDataByFormObject({
                  ...fromObject,
                  userName: userName,
                  currentProvince: currentProvince,
                  authority: authority,
                  requestType: RequestTypesList["update_vcl"],
                  current_licence_type: current_licence_type,
                  status: RequestStatus.APPROVAL_PENDING,
                  pdmt_id: pdmtId,
                  authority_id: authorityId,
                  counter_session_id: sessionId ? sessionId : null,
                  forward_authority_service_id: serviceId,
                  ui_request_id: fromObject.request_id,
                  name: isEmptyValue(fromObject.name) ? null : fromObject.name,
                  address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
                  address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
                  city: isEmptyValue(fromObject.city) ? null : fromObject.city,
                  absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
                  absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
                  absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
                  absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
                  chassis_no: isEmptyValue(fromObject.chassis_no) ? " " : fromObject.chassis_no,
                  engine_no: isEmptyValue(fromObject.engine_no) ? " " : fromObject.engine_no,
                  first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
                  tare_weight: isEmptyValue(fromObject.tare_weight) ? 0.00 : parseFloat(fromObject.tare_weight).toFixed(2),
                  gross_weight: isEmptyValue(fromObject.gross_weight)
                    ? 0.00
                    : parseFloat(fromObject.gross_weight).toFixed(2),
                  no_of_seats: isEmptyValue(fromObject.no_of_seats)
                    ? " "
                    : parseInt(fromObject.no_of_seats),
                  vehicle_reg_condition: isEmptyValue(fromObject.vehicle_reg_condition) ? " " : fromObject.vehicle_reg_condition,
                  fuel_type: isEmptyValue(fromObject.fuel_type) ? " " : fromObject.fuel_type,
                  vehicle_class: isEmptyValue(fromObject.vehicle_class) ? " " : fromObject.vehicle_class,
                  province: isEmptyValue(fromObject.province) ? " " : ProvincesIdMatcher[`${fromObject.province}`],
                  manufactured_year: isEmptyValue(fromObject.manufactured_year) ? " " : new Date(fromObject.manufactured_year).getFullYear(),
                  cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                    ? 0.00
                    : parseFloat(fromObject.cylinder_capacity).toFixed(2),
                  tyre_size: isEmptyValue(fromObject.tyre_size)
                    ? " "
                    : fromObject.tyre_size,
                  wheel_base: isEmptyValue(fromObject.wheel_base)
                    ? 0.00
                    : parseFloat(fromObject.wheel_base).toFixed(2),
                  type_of_body: isEmptyValue(fromObject.type_of_body) ? " " : fromObject.type_of_body,
                  make: isEmptyValue(fromObject.make) ? " " : fromObject.make,
                  model: isEmptyValue(fromObject.model) ? " " : fromObject.model,
                  color_1: isEmptyValue(fromObject.color_1) ? " " : fromObject.color_1,
                  color_2: isEmptyValue(fromObject.color_2) ? " " : fromObject.color_2,
                  color_3: isEmptyValue(fromObject.color_3) ? " " : fromObject.color_3,
                  archived: fromObject.archived === "1" ? true : false,
                  carnet_licence_start_at: props.isCarnet && _get(formState, 'carnet.fromDate', null),
                  carnet_licence_end_at: props.isCarnet && _get(formState, 'carnet.toDate', null),
                  effective_date: isEmptyValue(fromObject.effective_date) ? today : fromObject.effective_date,
                  reason_id: fromObject.reason_id,
                  document_details: [],
                  action: {
                    action: fromObject.action,
                    remark: fromObject.remark,
                    forward_province: fromObject.forward_province,
                    forward_role: fromObject.forward_role,
                    forward_authority: fromObject.forward_authority
                  }
                });
              }
              // uiAction.setFlashMessage({
              //     status: true,
              //     message: `Invalid Tare Weight.`,
              //     messageType: "error",
              //   });

              //   return
            } else {
              // if(_get(formState, "update-vehicle.cylinder_capacity", undefined) < 1) {
              //   uiAction.setFlashMessage({
              //       status: true,
              //       message: `Invalid Cylinder Capacity.`,
              //       messageType: "error",
              //     });

              //     return
              // }
              // if(_get(formState, "update-vehicle.tyre_size", undefined) < 1) {
              //   uiAction.setFlashMessage({
              //       status: true,
              //       message: `Invalid Tyre Size.`,
              //       messageType: "error",
              //     });

              //     return
              // }
              return getDataByFormObject({
                ...fromObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["update_vcl"],
                current_licence_type: current_licence_type,
                status: RequestStatus.APPROVAL_PENDING,
                pdmt_id: pdmtId,
                authority_id: authorityId,
                counter_session_id: sessionId ? sessionId : null,
                forward_authority_service_id: serviceId,
                ui_request_id: fromObject.request_id,
                name: isEmptyValue(fromObject.name) ? null : fromObject.name,
                address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
                address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
                city: isEmptyValue(fromObject.city) ? null : fromObject.city,
                absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
                absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
                absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
                absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
                chassis_no: isEmptyValue(fromObject.chassis_no) ? " " : fromObject.chassis_no,
                engine_no: isEmptyValue(fromObject.engine_no) ? " " : fromObject.engine_no,
                first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
                tare_weight: isEmptyValue(fromObject.tare_weight) ? 0.00 : parseFloat(fromObject.tare_weight).toFixed(2),
                gross_weight: isEmptyValue(fromObject.gross_weight)
                  ? 0.00
                  : parseFloat(fromObject.gross_weight).toFixed(2),
                no_of_seats: isEmptyValue(fromObject.no_of_seats)
                  ? " "
                  : parseInt(fromObject.no_of_seats),
                vehicle_reg_condition: isEmptyValue(fromObject.vehicle_reg_condition) ? " " : fromObject.vehicle_reg_condition,
                fuel_type: isEmptyValue(fromObject.fuel_type) ? " " : fromObject.fuel_type,
                vehicle_class: isEmptyValue(fromObject.vehicle_class) ? " " : fromObject.vehicle_class,
                province: isEmptyValue(fromObject.province) ? " " : ProvincesIdMatcher[`${fromObject.province}`],
                manufactured_year: isEmptyValue(fromObject.manufactured_year) ? " " : new Date(fromObject.manufactured_year).getFullYear(),
                cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                  ? 0.00
                  : parseFloat(fromObject.cylinder_capacity).toFixed(2),
                tyre_size: isEmptyValue(fromObject.tyre_size)
                  ? " "
                  : fromObject.tyre_size,
                wheel_base: isEmptyValue(fromObject.wheel_base)
                  ? 0.00
                  : parseFloat(fromObject.wheel_base).toFixed(2),
                type_of_body: isEmptyValue(fromObject.type_of_body) ? " " : fromObject.type_of_body,
                make: isEmptyValue(fromObject.make) ? " " : fromObject.make,
                model: isEmptyValue(fromObject.model) ? " " : fromObject.model,
                color_1: isEmptyValue(fromObject.color_1) ? " " : fromObject.color_1,
                color_2: isEmptyValue(fromObject.color_2) ? " " : fromObject.color_2,
                color_3: isEmptyValue(fromObject.color_3) ? " " : fromObject.color_3,
                archived: fromObject.archived === "1" ? true : false,
                carnet_licence_start_at: props.isCarnet ? _get(formState, 'carnet.fromDate', null) : null,
                carnet_licence_end_at: props.isCarnet ? _get(formState, 'carnet.toDate', null) : null,
                effective_date: isEmptyValue(fromObject.effective_date) ? today : fromObject.effective_date,
                reason_id: fromObject.reason_id,
                document_details: [],
                action: {
                  action: fromObject.action,
                  remark: fromObject.remark,
                  forward_province: fromObject.forward_province,
                  forward_role: fromObject.forward_role,
                  forward_authority: fromObject.forward_authority
                }
              });
            }
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === 'assign&Perform' ? "put" : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          if (!isEmpty(response.data.errors)) {
            if (props.uiType !== 'assign&Perform') {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          }
        }}
      />
    </Fragment>
  );
};

let reqId
const UpdateVehicleUI = (props) => {
  const formKey = props.formKey;
  const uiType = props.uiType;
  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();
    getChargeUpdateLatestDate()
    setRequestId(requ.substr(requ.length - 8))
  }, [])
  const [formState, formAction] = useContext(FormContext);
  const vehicleId = _get(formState, "latest_licence_data.data.vehicleData.id", null)

  const getChargeUpdateLatestDate = async () => {
    await callApi(`${getChargeUpdateLatestDateForVehicleAPI.url}/${vehicleId}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, response) => {
        console.log('LOGHH-', response, error);
        if (!error) {
          formAction.changeInputFn('latest_charge_update_date', "data", "", response.data.data);
        }
      });
  }

  reqId = requestId
  return (
    <div className="row">
      <FormWrapper setGroupName={formKey}>
        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <div className="row">
            <div className="col-md-2 col-sm-12"></div>
            <div className="col-md-3 col-sm-12">Current Value</div>
            <div className="col-md-3 col-sm-12">DMT Value</div>
            <div className="col-md-3 col-sm-12">New Value</div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-12">
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">Chassis</div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">Engine</div>
              </div>
              <div className="row update-vehicle-date-picker-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  1st Reg
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Tare (Kg)
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Gross (Kg)
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper ">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Seats (Exp. Driver)
                </div>
              </div>
              <div className="row update-vehicle-dropdwon-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Vehicle Status
                </div>
              </div>
              <div className="row update-vehicle-dropdwon-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Fuel Type
                </div>
              </div>
              <div className="row update-vehicle-dropdwon-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Class
                </div>
              </div>
              <div className="row update-vehicle-dropdwon-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Province Office
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Manufactured Year
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Cylinder Capacity
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Tyre Size
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Wheel Base
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Type of Body
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">Make</div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">Model</div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Color 1{" "}
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Color 2{" "}
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Color 3{" "}
                </div>
              </div>
              <div className="row update-vehicle-text-box-label-wraapper">
                <div className="col-md-12 col-sm-12 update-vehicle-label">
                  Archive Vehicle{" "}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <VehicleInformationDisplay
                formKey={`${formKey}-current`}
                url={props.uiType === 'APR' ? updateVehicleRequestAPI.url : getVehicleByNoAPI.url}
                uiType={props.uiType}
                approvalRequestId={props.approvalRequestId}
                dataType={'current&Dmt'}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <VehicleInformationDisplay
                formKey={`${formKey}-dmt`}
                url={props.uiType === 'APR' ? updateVehicleRequestAPI.url : getVehicleByNoAPI.url}
                uiType={props.uiType}
                approvalRequestId={props.approvalRequestId}
                dataType={'current&Dmt'}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <VehicleInformationDisplay
                formKey={formKey}
                url={props.uiType === 'APR' ? updateVehicleRequestAPI.url : getVehicleByNoAPI.url}
                requestId={requestId}
                uiType={props.uiType}
                approvalRequestId={props.approvalRequestId}
                dataType={'new'}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
      {/* <div className="col-md-12 defaultMarginTop">
        <UICard>
          <div className="row">
            <div className="col-md-2 col-sm-6"></div>
            <div className="col-md-3 col-sm-6">Current Value</div>
            <div className="col-md-3 col-sm-6">DMT Value</div>
            <div className="col-md-3 col-sm-6">New Value</div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-6">Archive Vehicle</div>
            <div className="col-md-3 col-sm-6">
              <InputBoxWithState inputName={"archived"} isDisabled={true} />
            </div>
            <div className="col-md-3 col-sm-6">
              <InputBoxWithState inputName={"archived"} isDisabled={true} />
            </div>
            <div className="col-md-3 col-sm-6">
              <SelectBoxWithState
                formGroupName={formKey}
                inputName={"archived"}
                emptySelectOptionTxt={null}
                dataList={[
                  {
                    id: "1",
                    value: "Yes",
                  },
                  {
                    id: "0",
                    value: "No",
                  }
                ]}
              />
            </div>
          </div>
        </UICard>
      </div> */}
      <div className="col-md-12 defaultMarginTop">
        <UICard>
          <div className="row col-md-3 col-sm-6">
            <DatePickerWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              labelText="Effective Date"
              inputName={"effective_date"}
              // minDate={new Date(_get(formState, "latest_charge_update_date.data.changed_date", null))}
              maxDate={max_date}
            />
          </div>
        </UICard>
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <Reason filterKey={'update_vehicle'}
          requestType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
          formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <Action
          dataSubmittedForApproval={props.dataSubmittedForApproval}
          formKey={formKey}
          uiType={uiType}
        />
      </div>
    </div>
  );
};

const UpdateVehicle = () => {
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Update Vehicle" },
      ]}
      pageTitle="Update Vehicle"
    >
      <UpdateVehicleUI />
    </DashboardTemplate>
  );
};

export default UpdateVehicle;
export { UpdateVehicleUI, UpdateVehicleBtn };
