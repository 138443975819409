/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-07 10:28:35
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-08 15:59:53
 */
import React, { useEffect, useContext, useState } from "react";
import {
  FormWrapper,
  InputButton,
  SelectBoxWithState,
  SubmitButton
} from "../../../../ui-components/ui-elements/form";
import { apiResponseStoringType, DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { reportGenerateAPI } from "../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { CircleLoaderElement, UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { templateTypes } from "../../../../../config/template.config";
import { downloadFile } from "../../../../../helpers/download-helpers/download.helpers";
import { UIContext } from '../../../../ui-components/context-providers/UIContext.provider';

const ActivityLog = ({
  uiType = "vehicle",

}) => {
  let formGroupName = "ActivityLog"
  const [, uiDispatch] = useContext(UIContext);
  const formKey = formGroupName;
  const searchFormKey = "vehicle_search_form";
  const [formState] = useContext(FormContext);
  const [, coreAction] = useContext(CoreContext);
  const apiStateKey = 'activityLogReport';
  const reportCode = 'fr23int_vehicle_activity_log';
  const [htmlTemplate, setHtmlTemplate] = useState(null);
  const reportName = "Activity Log Report";
  const [scrollView, setscrollView] = useState({
    "onload": false,
    "bar": false
  });
  const onExportToPdfSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.pdf`);
  };

  const onExportToExcelSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.xlsx`);
  };
  let vehicle_id = uiType === "vehicle" ? _get(formState, `vehicle_search_form.data.id`, null) : _get(formState, `vehicle_dealer_search_form.data.data.id`, null);
  let apiUrl = `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=1000&report_offset=0&report_vehicleId=${uiType === "vehicle" ? _get(formState, `vehicle_search_form.data.id`, null) : _get(formState, `vehicle_dealer_search_form.data.data.id`, null)}`;

  useEffect(() => {

    coreAction.sendRequestFn(apiUrl)
      .isMultipart(false)
      .method("get")
      // .setLoader(true)
      // .setInitStoring("none", null)
      .setInitStoring(apiResponseStoringType.setResponse, {
				responseKey:  reportGenerateAPI.key,
			})
      .headers(true)
      .send((error, result) => {
        if (result) {
          setHtmlTemplate(result.data)
          setscrollView({
            "onload": true,
            "bar": JSON.stringify({ __html: result?.data }).includes("No results found").toString()
          })
        }
      });

  }, [])


  return (
    <div className={"fullWidthDiv"}>


      <div class="mt-2 report-html-view">
        <UICard>
          {(htmlTemplate && scrollView.onload === true) ? (
            <div class={`${scrollView.bar}`} dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
          ) :
            <div>
              <br />
              <center>
                <CircleLoaderElement />
              </center>
            </div>
          }
        </UICard>
      </div>


      {htmlTemplate && (
        <div class="mt-2">
          <UICard>
            {/* <FormWrapper
              onDestroyUnsetFormObject={true}
              setFormObject={{
                export_to: null
              }}
            >
              <div className={"reportExportToAlignWrapper"}>
                <div className="exporTypeSelectDropdown">
                  <SelectBoxWithState
                    uiType={templateTypes.bootstrap}
                    formGroupName={formGroupName}
                    emptySelectOptionTxt={'  '}
                    inputValue={null}
                    dataList={[
                      {
                        id: "xlsx",
                        value: "Excel",
                      },
                      {
                        id: "pdf",
                        value: "PDF",
                      }
                    ]}
                    inputName={"export_to"}
                    labelText="Export To"
                  />

                </div>
                <div className="exportBtn">
                  <SubmitButton
                    btnText={"Export"}
                    elementStyle="btnWrapper"
                    formGroupName={formGroupName}
                    isValidate={true}
                    validationObject={{
                      fileds: {
                        export_to: "Export To"
                      },
                      rules: {
                        export_to: "required"
                      },
                      message: {}
                    }}
                    callApiObject={{
                      isSetHeaders: true,
                      method: "get",
                      multipart: false,
                      onUpload: false,
                      responseType: 'arraybuffer'
                    }}
                    apiDataStoringObject={{
                      setLoader: true,
                      storingType: "API_RESPONSE_LINKED_FORM",
                      mergeToSuccessResponse: true,
                      mergeToErrorResponse: true
                    }}
                    onGetAPIEndPointFn={(formState) => {
                      return {
                        url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=${formState?.export_to}&report_limit=1000&report_offset=0&report_vehicleId=${vehicle_id}`,
                        key: `${reportGenerateAPI.key}`
                      }
                    }}
                    onResponseCallBackFn={(error, response,) => {
                      if (response?._statue) {
                        if (_get(formState[formGroupName], "export_to", "") === 'pdf') {
                          onExportToPdfSubmit(response?.data)
                        }
                        if (_get(formState[formGroupName], "export_to", "") === 'xlsx') {
                          onExportToExcelSubmit(response?.data)
                        }
                        uiDispatch.setFlashMessage({
                          status: true,
                          message: 'Vehicle Activity Log successfully exported',
                          messageType: "success"
                        });
                      }

                      if (error) {
                        uiDispatch.setFlashMessage({
                          status: true,
                          message: 'Vehicle Activity Log failed to export',
                          messageType: "error"
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </FormWrapper> */}
          </UICard>
        </div>
      )}


    </div>
  );
};

export { ActivityLog };