/*
 * @Author:  Pradeepa Sandaruwan
 * @Date: 2021-01-06 14:33:04
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-10-27 08:57:30
 */

import React, { Fragment, useState } from 'react';
import { InputButton, SubmitButton, FormWrapper, InputBoxWithState } from "../../../../../ui-components/ui-elements/form/index";
import { DataTable, actionBtnTypes } from "../../../../../ui-components/ui-elements/table/DataTable";
import { useContext } from 'react';
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { quotationAPI, quotationDeleteAPI, quotationVehicleListAPI } from '../../../../../../config/apiUrl.config';
import { CreateQuotation } from './CreateQuotation';
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import { CoreContext } from '../../../../core/context-providers/CoreContext.provider';
import { _get } from '../../../../../../helpers/common-helpers/lodash.wrappers';
import { AuthContext } from '../../../../core/context-providers/AuthContext.provider';
import { QuotationConfirmation, QuotationDetails } from './QuotationDetails';
import { DeleteQuotation, DeleteQuotationBtn } from './DeleteQuotation';
import { DialogPopup } from '../../../../../ui-components/ui-elements/modal/DialogPopup';
import IssueQuotation from '../../../../payment/pages/IssueQuotation';
import { isEmpty } from 'lodash';

const ViewQuotation = ({
  formKey = ""
}) => {
  const searchFormKey = "quotation_search_form"
  const [quotationState, setQuotationState] = useState({
    "isShowInfo": false
  });
  const [coreState, coreAction] = useContext(CoreContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);
  const [getState, setState] = useState({
    status: false,
    modelType: "",
  });

  const latestLicence = {}

  const ConfirmPayButtons = (props) => {
    const [formState, formAction] = useContext(FormContext);
    return <InputButton
      btnText={"Yes"}
      tooltip={"Yes"}
      startIcon="far fa-save"
      onClickBtnFn={() => {
        formAction.changeInputFn('quotation_payment', "isPopupOpen", "", true);
      }
      }
    />
  }

  const closePopup = () => {
    setState({
      ...getState,
      status: false,
      modelType: "",
    })
  };

  const UpdateVehicleBtn = () => {
    return (
      <InputButton
        btnName="submit"
        btnText={"Update Vehicle"}
      />
    );
  }

  return (
    <Fragment>
      <div className="normalLicenseWrapper">
        <div className="topWrapper">
          <div className="searchWrapper">
            <FormWrapper
              elementStyle={""}
              setGroupName={searchFormKey}>

              <div className={"searchInputWrapper"}>
                <label className="control-labe">Quotation ID:</label>
                <InputBoxWithState
                  inputName={"searchKey"}
                  formGroupName={searchFormKey}
                  elementStyle="marginTopBottomNone"
                  elementWrapperStyle={"searchInputBoxWrapper"}
                  maxLength="20"
                />

                <SubmitButton
                  btnText={"Search"}
                  formGroupName={searchFormKey}
                  dataTableKey={"quotationAPIKey_list"}
                  tooltip={"Search"}
                  startIcon="mdi mdi-magnify-plus-outline"
                  onGetAPIEndPointFn={(formState) => {
                    return {
                      url: `${quotationAPI.url}?sort_by=created_at|DESC&authority_id=${_get(authStatus, 'authUser.authorityId', '')}&quotation_no=${formState.searchKey}`,
                      key: `${quotationAPI.key}`
                    }
                  }}
                  callApiObject={{
                    isSetHeaders: true,
                    method: "get",
                  }}
                  apiDataStoringObject={{
                    setLoader: true,
                    storingType: "none"
                  }}
                  onResponseCallBackFn={(error, response) => {
                    if (!error) {

                      coreAction.setDataTable(
                        "quotationAPIKey_list",
                        response.data.data,
                        'done',
                        response.data.meta.current_page,
                        response.data.meta.total_pages,
                        response.data.data.length,
                        response.data.meta.total_items,
                        response.data.meta.page_size
                      )

                    }
                  }}
                />

                <SubmitButton
                  btnText={"Reset"}
                  formGroupName={searchFormKey}
                  dataTableKey={"quotationAPIKey_list"}
                  tooltip={"Reset"}
                  startIcon={"fas fa-sync-alt"}
                  elementWrapperStyle={"refreshBtn"}
                  onGetAPIEndPointFn={(formState) => {
                    return {
                      url: `${quotationAPI.url}?sort_by=created_at|DESC&authority_id=${_get(authStatus, 'authUser.authorityId', '')}`,
                      key: `${quotationAPI.key}`
                    }
                  }}
                  callApiObject={{
                    isSetHeaders: true,
                    method: "get",
                  }}
                  apiDataStoringObject={{
                    setLoader: true,
                    storingType: "none"
                  }}
                  onResponseCallBackFn={(error, response) => {
                    if (!error) {
                      formAction.changeInputFn('quotation_search_form', "searchKey", "", "");
                      coreAction.setDataTable(
                        "quotationAPIKey_list",
                        response.data.data,
                        'done',
                        response.data.meta.current_page,
                        response.data.meta.total_pages,
                        response.data.data.length,
                        response.data.meta.total_items,
                        response.data.meta.page_size
                      )

                    }
                  }}
                />
              </div>
            </FormWrapper>
          </div>

        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <DataTable
            tableName="Manage Quotation"
            elementWrapperStyle={"defaultMarginBottom"}
            apiUrl={`${quotationAPI.url}?sort_by=created_at|DESC&authority_id=${_get(authStatus, 'authUser.authorityId', '')}`}
            dataTableKey={"quotationAPIKey_list"}
            tableHeaderList={[
              { displayName: "Quotation No", key: "quotation_no", isSort: false },
              { displayName: "Created By", key: "created_by_name", isSort: false, onPrintFn: (rawData) => isEmpty(rawData.created_by_name) ? '-' : rawData.created_by_name},
              { displayName: "Created Date & Time", key: "created_at", isSort: false, onPrintFn: (rawData) => isEmpty(rawData.created_at) ? '-' : dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM") },
              { displayName: "Status", key: "payment_date", isSort: false, onPrintFn: (rawData) => isEmpty(rawData.payment_date) ? 'Payment Pending' : 'Paid' },
              { displayName: "Payment Date & Time", key: "payment_date", isSort: false, onPrintFn: (rawData) => isEmpty(rawData.payment_date) ? '-' : dateObjectToString(rawData.payment_date, "dd/mm/YY HH:MM") },
              { displayName: "No. of Licences", key: "number_of_licence", isSort: false, isRightAlign: true },
              { displayName: "No. of Licences Printed", key: "printed_licence", isSort: false, isRightAlign: true },
            ]}

            actionTypeList={[
              {
                actionType: actionBtnTypes.create,
                modelContentElement: CreateQuotation,
                actionBtn: {
                  tooltip: 'Add Quotation',
                  btnText: "Add Quotation",
                },
                dialogPopupProps: {
                  isFullScreen: true,
                  dialogTitle: "Add Quotation",
                  closeButton: {
                    btnText: "Cancel",
                    startIcon: "far fa-times-circle",
                  },
                  isSaveButton: false,
                  isConfirmationPopup: true,
                  customButtonComponentProps: { btnText: "Save", uiType: "CREATE", dataTableKey: "" },
                },
                parentToModelProps: {
                  uiType: "CREATE",
                  formKey: "",

                },
              },
              {
                actionType: actionBtnTypes.view,
                modelContentElement: QuotationDetails,
                dialogPopupProps: {
                  isFullScreen: true,
                  dialogTitle: "Detailed Quotation Information",
                  isCustomButton: false,
                  closeButton: {
                    btnText: "Close",
                    startIcon: "far fa-times-circle",
                  },
                },
                actionBtn: {
                  tooltip: 'View Quotation',
                  onClickBtnFn: () => setQuotationState({
                    ...quotationState,
                    isShowInfo: true
                  })
                },
              },
              {
                actionType: actionBtnTypes.delete,
                actionBtn: {
                  tooltip: 'Delete Quotation',
                },
                modelContentElement: DeleteQuotation,
                dialogPopupProps: {
                  isFullScreen: false,
                  dialogTitle: "Remove Quotation",
                  closeButton: {
                    btnText: "No",
                    startIcon: "far fa-times-circle",
                  },
                  isCustomButton: true,
                  customButtonComponent: DeleteQuotationBtn,
                  customButtonComponentProps: {
                    dataTableKey: "quotationAPIKey_list",
                    uiType: "delete",
                  },
                  closeButton: {
                    btnText: "No",
                    startIcon: "far fa-times-circle",
                  },
                },
                parentToModelProps: {
                  formKey: quotationDeleteAPI.key,
                  dataTableKey: "quotationAPIKey_list",
                },
                checkBeforeActionRenderFn: (rawData) => {
                  return (rawData.row.payment_date !== null) ? false : true;
                }
              },
              {
                actionType: actionBtnTypes.other,
                modelContentElement: QuotationConfirmation,
                actionBtn: {
                  tooltip: 'Proceed Quotation',
                },
                dialogPopupProps: {
                  isFullScreen: false,
                  dialogTitle: "Proceed Quotation Payment",
                  closeButton: {
                    btnText: "No",
                    startIcon: "far fa-times-circle",
                  },
                  isSaveButton: false,
                  isCustomButton: true,
                  customButtonComponent: ConfirmPayButtons,
                  customButtonComponentProps: {
                    btnText: "Pay",
                    uiType: "PROCEED",
                    dataTableKey: quotationVehicleListAPI.key
                  },
                },
                parentToModelProps: {
                  uiType: "PROCEED",
                  formKey: "quotation_payment",
                  dataTableKey: quotationVehicleListAPI.key,
                },
                checkBeforeActionRenderFn: (rawData) => {
                  return (rawData.row.payment_date !== null) ? false : true;
                }
              }
            ]}
            isSetTableFooter={true}
            isSetTableHeader={true}
          />
        </div>
        <DialogPopup
          isOpen={getState.status}
          isFullScreen={true}
          dialogTitle={"Make Payment - Quotation"}
          onClosePopupFn={() =>
            setState({
              ...getState,
              status: false,
              modelType: "",
            })
          }
          closeButton={{
            btnText: "Back"
          }}
          isConfirmationPopup={true}
          confirmationMessage={"Are you sure you want to go back?"}
        >
          <IssueQuotation uiType={"quotation_payment"} latestLicence={latestLicence} closeMainPopup={closePopup} qtData={getState} />
        </DialogPopup>
      </div >
    </Fragment>

  );
}

export { ViewQuotation };