/*
 * @Author: Chamith jayathilaka
 * @Date: 2022-08-28 16:06:43
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-08-28 15:19:12
 */
import React, { useState, useContext } from "react";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  RoundButton,
  roundBtnSizes,
} from "../../../../../ui-components/ui-elements/form";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { EnvelopPreview } from "./EnvelopPreview.js";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { multipleEnvelopPrintRequestAPI } from "../../../../../../config/apiUrl.config";

const EnvelopPrintPopup = ({
  uiType = "ENVELOP", // ENVELOP | REPRINTENVELOP
}) => {
  const [openPrint, setOpenPrint] = useState({ showPopup: false });

  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);

  const [index, setIndex] = useState(0); // create state to keep track of envelope index, set the default index to 0

  const slideRight = () => {
    setIndex(
      (index + 1) %
        _get(formState, `envelope_print_form.data.data.envelops`, []).length
    ); // increases index by 1
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(
        _get(formState, `envelope_print_form.data.data.envelops`, []).length - 1
      ); // returns last index of envelope array if index is less than 0
    } else {
      setIndex(nextIndex);
    }
  };

  const getenvelopeIdsArray = () => {
    let envelopeArrays = [];
    if (uiType === "ENVELOP") {
      envelopeArrays = _get(
        formState,
        `webLicencePrintListAPIKeyPrintedEnvelope`,
        []
      );
    } else {
      envelopeArrays = _get(formState, `webLicencePrintListAPIKeyPrinted`, []);
    }

    if (envelopeArrays?.length > 0) {
      let envelopeIdsArray = envelopeArrays?.map((item) => {
        return item.licence_id;
      });
      return envelopeIdsArray.toString();
    } else {
      return "";
    }
  };

  return (
    <FormWrapper
      elementStyle={"col-md-12"}
      setGroupName={"envelope_print_form"}
      formGroupLinkWith={"envelope_print_form"}
      isLoaderOnError={false}
      apiUrl={`${multipleEnvelopPrintRequestAPI.url}/${getenvelopeIdsArray()}`}
      onDestroyUnsetFormObject={true}
      onRebuildResponseFn={(response) => {
        return {
          data: response,
          _onLoad: false,
        };
      }}
    >
      <div className={`envelop-slide-container-web`}>
        <RoundButton
          btnSize={roundBtnSizes.medium}
          elementStyle={"prev"}
          iconClass={"fas fa-chevron-left"}
          onClickBtnFn={slideLeft}
        >
          tooltip={"Next"}
        </RoundButton>
        <div className="w-100">
          <EnvelopPreview
            index={index}
            envelopData={
              _get(formState, `envelope_print_form.data.data.envelops`, "")[
                index
              ]
            }
            className="dealerLicenseImage"
            uiType={uiType}
          />
        </div>

        <RoundButton
          elementStyle={"next"}
          iconClass={"fas fa-chevron-right"}
          onClickBtnFn={slideRight}
        >
          tooltip={"Prev"}
        </RoundButton>
        <div className="helperText"></div>
      </div>
    </FormWrapper>
  );
};

export { EnvelopPrintPopup };
