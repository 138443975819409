/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-23 18:46:29
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-08 16:29:31
 */

import React, { useState, Fragment } from "react";

import {
  FormWrapper,
  InputButton,
  CheckBoxWithState,
  InputBoxWithState,
  SelectBoxWithState
} from "../../../../ui-components/ui-elements/form/index";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DataTable,actionBtnTypes } from "../../../../ui-components/ui-elements/table/DataTable";
import { normalLicenseDummy } from "../../../../../config/dummyData.config";
import {
  OwnerInfoDisplay,
  VehicleInfoDisplay,
  OwnerInfoEdit,
  VehicleInfoEdit,
  OwnerDetailsIcons,
  ViewLicenseArrears,
  CommentsHistory,
  LicenseArrearsIcons,
  LicencePeriodIcons,
  LicenseElement,
  NormalLicense
} from "./NormalLicense";
import { roundToNearestMinutes } from "date-fns";

const emptyFun = (...para) => undefined;

const QuotationUI=()=>{

  const [quotationState,setQuotationState]=useState({
    "isShowInfo":false
  });

  return(
    <Fragment>
      <DataTable
          tableName="Quotation List"
          elementWrapperStyle={"defaultMarginBottom"}
          dataTableKey={"quotationList"}
          tableHeaderList={[
            { displayName: "Quotation ID", key: "quotationID", isSort: false },
            { displayName: "Created by", key: "createdBy", isSort: false },
            { displayName: "Created Date & Time", key: "createdDateTime", isSort: false },
            // {
            //   displayName: "Total Amount (LKR)",
            //   key: "totalAmount",
            //   isSort: false,
            // },
            { displayName: "Payment Date & Time", key: "paymentDateTime", isSort: false },
            { displayName: "No. of Licences", key: "licenceNo", isSort: false },
            { displayName: "No. of Licences Printed", key: "licenceNoPrinted", isSort: false },            
          ]}
          tableBodyList={[{
            "quotationID":"0000000010",
            "createdBy":"R.M.L Jayasundara",
            "createdDateTime":"2020-08-20 15:40",
            "totalAmount":"2,860.00",
            "paymentDateTime":"2020-08-20 15:40",
            "licenceNo":"5",
            "licenceNoPrinted":"5",
          },{
            "quotationID":"0000000010",
            "createdBy":"R.M.L Jayasundara",
            "createdDateTime":"2019-08-20 15:40",
            "totalAmount":"2,860.00",
            "paymentDateTime":"2019-08-20 15:40",
            "licenceNo":"5",
            "licenceNoPrinted":"5",
          }
         ]}
          actionTypeList={[
            {
              actionType: actionBtnTypes.view, 
              actionBtn:{
                onClickBtnFn:()=>setQuotationState({
                  ...quotationState,
                  isShowInfo:true
                })
              },              
            },
            {
              actionType: actionBtnTypes.delete,                           
            }
          ]}
          isSetTableFooter={true}
          isSetTableHeader={true}          
        />


        {
          (quotationState.isShowInfo===true)?(
            <Fragment>
              <div className="fullWidthDiv defaultHalfMarginTopBottom">

                <div className="row">
                    <div className="col-md-4">
                      <strong>Quotation ID: </strong>  0000000010             
                    </div>
                    <div className="col-md-4">
                      <strong>Created by: </strong>  R.M.L Jayasundara           
                    </div>
                    <div className="col-md-4">
                      <strong>Created Date & Time: </strong>  2020-08-20 15:40       
                    </div>
                    <div className="col-md-4">
                      <strong>Payment Date & Time: </strong>  2019-08-20 15:40
                    </div>
                    <div className="col-md-8 text-right defaultMarginBottom">
                          <InputButton
                            btnText="Print All"
                            startIcon={"mdi mdi-printer-settings"}
                          />
                    </div>
                </div>

                </div>  

                <DataTable
                  tableName="Quotation Information"
                  dataTableKey={"quotationInformation"}
                  elementWrapperStyle={"defaultMarginBottom"}
                  tableHeaderList={[
                    { displayName: "Sequence No", key: "sequenceNO", isSort: false },
                    { displayName: "Vehicle ID", key: "vehicleID", isSort: false },
                    { displayName: "Chassis Number", key: "chassisNo", isSort: false },
                    {
                      displayName: "Vehicle Class",
                      key: "vehicleClass",
                      isSort: false,
                    },
                    { displayName: "Owner's Name", key: "ownerName", isSort: false },
                    { displayName: "Licence Type", key: "licenceType", isSort: false },
                    { displayName: "Validity Period (From-To)", key: "validityPeriod", isSort: false },     
                    { displayName: "Arrears", key: "arrears", isSort: false, isRightAlign: true },     
                    { displayName: "Fee", key: "fee", isSort: false, isRightAlign: true },        
                    { displayName: "Fine", key: "fine", isSort: false, isRightAlign: true },        
                    { displayName: "Wave Off", key: "waveOff", isSort: false, isRightAlign: true },        
                    { displayName: "Total", key: "total", isSort: false, isRightAlign: true },       
                    { displayName: "Document Validation", key: "documentValidation", isSort: false },          
                  ]}
                  tableBodyList={[{
                    "sequenceNO":"0000000010",
                    "vehicleID":"KS-5555",
                    "chassisNo":"KSP902053525",
                    "ownerName":"R.M.L JAYASUNDARA",
                    "licenceType":"NL",
                    "validityPeriod":"27-04-2018 - 26-04-2019",
                    "arrears":"00.00",
                    "fee":"2,200.00",
                    "fine":"660.00",
                    "waveOff":"00.00",
                    "total":"2,860.00",
                    "documentValidation":"-",
                    "vehicleClass":"MOTOR CAR"
                  },{
                    "sequenceNO":"",
                    "vehicleID":"",
                    "chassisNo":"",
                    "ownerName":"Total Amount (LKR)",
                    "licenceType":"",
                    "validityPeriod":"",
                    "arrears":"00.00",
                    "fee":"2,200.00",
                    "fine":"660.00",
                    "waveOff":"00.00",
                    "total":"2,860.00",
                    "documentValidation":"",
                    "vehicleClass":""
                  }
                  ]}
                  actionTypeList={[
                    {
                      actionType: actionBtnTypes.view,   
                      modelContentElement:ViewQuotation,
                      dialogPopupProps:{
                        "isFullScreen":true,
                        "dialogTitle":"View Quotation",
                        "isCustomButton":false,       
                      }
                    },{
                      actionType: actionBtnTypes.delete,                           
                    },
                    {
                      actionType: actionBtnTypes.view, 
                      actionBtn:{
                          "icon":"mdi mdi-printer-settings"
                      }                           
                    }
                  ]}
                  isSetTableFooter={true}
                  isSetTableHeader={true}          
                />
            </Fragment>
          ):(null)
        }
      

        
    </Fragment>
  );
}


const CreateNewBtn=(props)=>{
  return (
      <Fragment>
            <InputButton
              elementWrapperStyle={'defaultHalfMarginLeftRight'}
              btnName="submit"
              btnText={"Activity Log"}
            />
            <InputButton
                elementWrapperStyle={'defaultHalfMarginLeftRight'}
                btnName="submit"
                btnText={"View Documents"}
            />

          <InputButton
            btnText="Create Licence"
            onClickBtnFn={()=>{
              props.onClickFn();
              props.onClosePopupFn();
            }}
          />
      </Fragment>    
  )
}

const PrintQuotation=(props)=>{
  return (
      <Fragment>           

          <InputButton
            btnText="Print Quotation"
          />
      </Fragment>    
  )
}

const ViewQuotation=()=>{
  return <LicenseElement getState={{
    searchKey: "ks-5555",
    currentObject: normalLicenseDummy["ks-5555"],
  }} />
}

const NewQuotation = () => {

  const [getState,setState]=useState([]);

  return (
    <div className={"col-md-12 floatLeft"}>
      <FormWrapper
        setGroupName={"newQuotation"}
        elementStyle={""}
      >
      <div className={"col-md-6 floatLeft"}>
        <strong>Quotation ID: </strong> #0001
      </div>
      <div className={"col-md-6 floatLeft"}>
            <InputBoxWithState
                labelText={"Address 1"}
                formGroupName={"newQuotation"}
                inputName={"address1"}
            />
            <InputBoxWithState
                labelText={"Address 2"}
                formGroupName={"newQuotation"}
                inputName={"address2"}
            />
      </div>
      </FormWrapper>
      <div className={"col-md-12 floatLeft defaultMarginTop"}>
        <DataTable
            tableName="Vehicle List"
            dataTableKey={"vehicalList"}
            tableHeaderList={[
              { displayName: "Sequence No", key: "sequenceNo", isSort: false },
              { displayName: "Vehicle ID", key: "vehicleID", isSort: false },
              { displayName: "Chassis Number", key: "chassisNumber", isSort: false },
              {
                displayName: "Vehicle Class",
                key: "vehicleClass",
                isSort: false,
              },
              { displayName: "Owner’s Name", key: "ownerName", isSort: false },
              { displayName: "Licence Type", key: "licenceType", isSort: false },
              { displayName: "Validity Period (From – To)", key: "validityPeriod", isSort: false },
              {
                displayName: "Arrears (LKR)",
                key: "arrears",
                isSort: false,
              },
              {
                displayName: "Note",
                key: "note",
                isSort: false,
              },
              {
                displayName: "Fee (LKR)",
                key: "fee",
                isSort: false,
              },
              {
                displayName: "Waive Off (LKR)",
                key: "waiveOff",
                isSort: false,
              },
              {
                displayName: "Total (LKR)",
                key: "total",
                isSort: false,
              },
            ]}
            tableBodyList={getState}
            actionTypeList={[
              {
                actionType: actionBtnTypes.create,   
                modelContentElement:NormalLicense,
                dialogPopupProps:{
                  "isFullScreen":true,
                  "dialogTitle":"Add New Vehicle",
                  "isCustomButton":true,
                  "customButtonComponent":CreateNewBtn,
                  "customButtonComponentProps":{
                    "onClickFn":()=>{
                        setState([
                        ...getState,
                        ...[{
                          "sequenceNo":"#0012",
                          "vehicleID":"KI-5555",
                          "chassisNumber":"212e12e12e12e21e",
                          "vehicleClass":"-",
                          "ownerName":"R.M.L Jayasundara",
                          "licenceType":"R.M.L Jayasundara",
                          "validityPeriod":"01/12/2019 to 21/12/2020",
                          "arrears":"0.00/=",
                          "note":"-",
                          "fee":"10000/=",
                          "waiveOff":"100/=",
                          "total":"10100.00/="
                        }]
                      ]);                    
                    }
                  }
                }
              }
            ]}
            isSetTableFooter={true}
            isSetTableHeader={true}          
          />
      </div>
    </div>
  );
};

const Quotation = ({ setShowHideFooterFN = emptyFun }) => {
  return (
    <div className="normalLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper">
          <FormWrapper elementStyle={""} setGroupName={"searchFromKey"}>
            <div className="searchInputWrapper">
              <label className="control-labe">Quotation ID:</label>
              <input type="text" className="form-control" />
              <InputButton
                btnName="search"              
                overrideTextToIcon={
                  <i className="mdi mdi-magnify-plus-outline" />
                }
              />
            </div>
          </FormWrapper>
        </div>        
      </div>

      <div className={"col-md-12 floatLeft defaultMarginTop"}>
       <DataTable
          tableName="Quotation List"
          dataTableKey={"quotationList"}
          tableHeaderList={[
            { displayName: "Sequence No", key: "sequenceNo", isSort: false },
            { displayName: "Vehicle ID", key: "vehicleID", isSort: false },
            { displayName: "Chassis Number", key: "chassisNumber", isSort: false },
            {
              displayName: "Vehicle Class",
              key: "vehicleClass",
              isSort: false,
            },
            { displayName: "Owner’s Name", key: "ownerName", isSort: false },
            { displayName: "Licence Type", key: "licenceType", isSort: false },
            { displayName: "Validity Period (From – To)", key: "validityPeriod", isSort: false },
            {
              displayName: "Arrears (LKR)",
              key: "arrears",
              isSort: false,
            },
            {
              displayName: "Note",
              key: "note",
              isSort: false,
            },
            {
              displayName: "Fee (LKR)",
              key: "fee",
              isSort: false,
            },
            {
              displayName: "Waive Off (LKR)",
              key: "waiveOff",
              isSort: false,
            },
            {
              displayName: "Total (LKR)",
              key: "total",
              isSort: false,
            },
          ]}
          tableBodyList={[{
            "sequenceNo":"0000001",
            "vehicleID":"KI-5555",
            "chassisNumber":"JKP902053525",
            "vehicleClass":"MOTOR CAR",
            "ownerName":"R.M.L Jayasundara",
            "licenceType":"NL",
            "validityPeriod":"01/12/2019 to 31/12/2020",
            "arrears":"660.00",
            "note":"-",
            "fee":"2,200.00",
            "waiveOff":"0.00",
            "total":"2,860.00"
          },{
            "sequenceNo":"0000001",
            "vehicleID":"KS-5555",
            "chassisNumber":"KKP902053525",
            "vehicleClass":"MOTOR CAR",
            "ownerName":"D.L Perera",
            "licenceType":"NL",
            "validityPeriod":"01/12/2019 to 31/12/2020",
            "arrears":"0.00",
            "note":"-",
            "fee":"2,200.00",
            "waiveOff":"0.00",
            "total":"2,200.00"
          },{
            "sequenceNo":"0000001",
            "vehicleID":"KG-5555",
            "chassisNumber":"AKP902053525",
            "vehicleClass":"MOTOR CAR",
            "ownerName":"F.G Peiris",
            "licenceType":"NL",
            "validityPeriod":"01/12/2019 to 31/12/2020",
            "arrears":"0.00",
            "note":"-",
            "fee":"2,200.00",
            "waiveOff":"0.00",
            "total":"2,200.00"
          }]}
          actionTypeList={[
            {
              actionType: actionBtnTypes.create,   
              modelContentElement:NewQuotation,
              dialogPopupProps:{
                "isFullScreen":true,
                "dialogTitle":"Add New Quotation",
                "isCustomButton":true,
                "customButtonComponent":PrintQuotation,              
              }
            },{
              actionType: actionBtnTypes.view,   
              modelContentElement:ViewQuotation,
              dialogPopupProps:{
                "isFullScreen":true,
                "dialogTitle":"View Quotation",
                "isCustomButton":false,       
              }
            }
          ]}
          isSetTableFooter={true}
          isSetTableHeader={true}          
        />
    </div>         


    </div>
  )
}

export { Quotation,ViewQuotation,QuotationUI };
