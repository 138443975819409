/*
 * @Author:Gajani Kangesan
 * @Date:2022-02-18 11:18:45
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:19:48
 */

import React, { Fragment, useContext } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import {
    SelectBoxWithState,
    SubmitButton,
    DatePickerWithState,
    FormWrapper,
    InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportGenerateAPI, provincesOfficesListAPI, vehicleClassListAPI } from "../../../../../../../config/apiUrl.config";
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { CoreContext } from "../../../../../core/context-providers/CoreContext.provider";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined
const SearchCriteria = ({
    reportCode,
    formGroupName,
    setReportgeneratedValues = emptyFn,
    onGenerateSubmit = emptyFn,
    onResetClick = emptyFn,
}) => {

    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const [coreState, coreAction] = useContext(CoreContext);
    let datefrom = new Date();
    let dateto = new Date();
    let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
    let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
    let vehicle_class = "All";
    let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
    let generated_by = _get(authStatus, 'authUser.name', "")
    let role = _get(authStatus, 'authUser.rolePrefix', "")
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    let vehicleClassName = 'All';
   
    let vehicleClassId = "All";
    
    

    return (
        <div>
            <Fragment>
                <UICard>
                    <FormWrapper
                        setGroupName={formGroupName}
                        formGroupLinkWith={formGroupName}
                        onDestroyUnsetFormObject={true}
                        setFormObject={{
                            date_from: null,
                            date_to: null,

                        }}

                    >

                        <div className="row ">
                            <div className="col-md-6 col-sm-12 ">
                                <SelectBoxWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    apiUrl={provincesOfficesListAPI.url}
                                    keyName={"id"}
                                    valueName={"province.name"}
                                    apiStateKey={provincesOfficesListAPI.key}
                                    emptySelectOptionTxt={provinceName}
                                    labelText="Province"
                                    inputName={"province"}
                                    isDisabled={true}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 ">
                                <DatePickerWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Date From"
                                    inputName={"date_from"}
                                    maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                                    minDate={setminDateRangeFn(_get(formState[formGroupName], "date_to", null),_get(formState[formGroupName], "date_from", null))}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 ">
                                <SelectBoxWithState
                                    isRequired={false}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Vehicle Class"
                                    inputName={"vehicle_class"}
                                    apiUrl={`${vehicleClassListAPI.url}?sort_by=name|asc`}
                                    keyName={"id"}
                                    valueName={"name"}
                                    apiStateKey={vehicleClassListAPI.key}
                                    emptySelectOptionTxt={"All"}
                                />
                            </div>



                            <div className="col-md-6 col-sm-12 ">
                                <DatePickerWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Date To"
                                    inputName={"date_to"}
                                    minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                                    maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "date_from", null))}
                                />
                            </div>

                            <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">
                                <SubmitButton
                                    btnName={"generate"}
                                    btnText={"Generate"}
                                    formGroupName={formGroupName}
                                    startIcon="fas fa-wrench"
                                    isValidate={true}
                                    validationObject={{
                                        fileds: {
                                            date_from: "Date From",
                                            date_to: "Date To",
                                        },
                                        rules: {
                                            date_from: "required",
                                            date_to: "required",
                                        },
                                        message: {}
                                    }}
                                    callApiObject={{
                                        isSetHeaders: true,
                                        method: "get",
                                        multipart: false,
                                        onUpload: false,
                                        responseType: 'text'
                                    }}
                                    apiDataStoringObject={{
                                        setLoader: true,
                                        storingType: "API_RESPONSE_LINKED_FORM",
                                        mergeToSuccessResponse: true,
                                        mergeToErrorResponse: true
                                    }}
                                    onGetAPIEndPointFn={(formObject) => {

                                        if (!isEmptyValue(formObject.date_from)) {
                                            datefrom = dateObjectToString(new Date(formObject.date_from));
                                        }
                                        if (!isEmptyValue(formObject.date_to)) {
                                            dateto = dateObjectToString(new Date(formObject.date_to));
                                        }

                                        if (!isEmptyValue(formObject.province)) {
                                            provinceId = formObject.province
                                            provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                        }

                                        if (!isEmptyValue(formObject.vehicle_class)) {
                                            vehicleClassId = formObject.vehicle_class
                                            vehicleClassName = (_get(coreState, `apiResponses.${vehicleClassListAPI.key}.result`, []).find(item => item.id === vehicleClassId)?.name)
                                            vehicleClassName = (vehicleClassName == null || '') ? ' ' : vehicleClassName
                                        }

                                        return {
                                            url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_fromDate=${datefrom}&report_toDate=${dateto}&report_provinceName=${provinceName}&report_pdmtId=${pdmtId}&report_vehicleClassId=${vehicleClassId}&report_vehicleClass=${vehicleClassName}&report_dateFormatDisplay=dd/MM/yyyy&report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                            key: `${reportGenerateAPI.key}`
                                        }
                                    }}

                                    onChangeRequestBodyFn={(formObject) => {
                                        return getDataByFormObject(formObject);
                                    }}

                                    onResponseCallBackFn={(error, response,) => {
                                        if (response?._statue) {
                                            onGenerateSubmit(response?.data)
                                            setReportgeneratedValues({
                                                datefrom,
                                                dateto,
                                                provinceId,
                                                provinceName,
                                                vehicleClassId,
                                                pdmtId,
                                                vehicle_class: vehicle_class,
                                                limit: reportDataLimit,
                                                offset: reportDataOffset,
                                                reportGeneratedUser: generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                            })
                                        }


                                    }}
                                />

                                <InputButtonWithState
                                    btnText="Reset"
                                    startIcon="fas fa-sync-alt"
                                    elementWrapperStyle={"refreshBtn"}
                                    formGroupName={formGroupName}
                                    mergeToForm={{
                                        date_from: null,
                                        date_to: null,
                                        province: null,
                                        vehicle_class: null

                                    }}
                                    onClickBtnFn={(event) => {
                                        formAction.setFormErrorFn(formGroupName);
                                        onResetClick()

                                    }}
                                />


                            </div>
                        </div>
                    </FormWrapper>
                    <br />


                </UICard>




            </Fragment>
        </div>


    );
};

export {
    SearchCriteria
}