/* eslint-disable no-unused-vars */
/*
 * @Author: Chamith jayathilaka
 * @Date: 2022-08-28 16:06:43
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-08-28 15:19:12
 */
import React, { useState, useContext } from "react";
import { _get, _unset, _find } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper, RoundButton, roundBtnSizes } from "../../../../../ui-components/ui-elements/form";
import { AuthContext } from '../../../../core/context-providers/AuthContext.provider';
import { LicencePrintPreview } from "./LicencePrintPreview.js";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { CoreContext } from "../../../../core/context-providers/CoreContext.provider";
import { multipleLicencePrintRequestAPI } from '../../../../../../config/apiUrl.config';


const emptyFn = () => undefined;

const LicencePrintPopup = ({ uiType = "dealer"}) => {
  const vehicleLicenseListKey = "dealer_license_vehiclelist_form"
  const [openPrint, setOpenPrint] = useState({showPopup: false,});

  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const [index, setIndex] = useState(0); // create state to keep track of licence index, set the default index to 0

  const slideRight = () => {
    setIndex((index + 1) % _get(formState, `web_licennce_print_form.data.data.licences`, []).length); // increases index by 1
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(_get(formState, `web_licennce_print_form.data.data.licences`, []).length - 1); // returns last index of licence array if index is less than 0
    } else {
      setIndex(nextIndex);
    }
  };



  const getLicenceIdsArray =() =>{
    let licenceArrays =_get(formState, `webLicencePrintListAPIKey`, [])
    if(licenceArrays?.length >0){

      let licenceIdsArray =licenceArrays?.map((item)=> {return item.licence_id})
      let queryString=`licence_id=${licenceIdsArray[0]}`
      if(licenceIdsArray.length> 0){
        for (let index = 1; index < licenceIdsArray.length; index++) {
            queryString+=`&licence_id=${licenceIdsArray[index]}`
        }
        return queryString
    }
    }else{
      return ''
    }
  }

  return (
            <FormWrapper
              elementStyle={"col-md-12"}
              setGroupName={"web_licennce_print_form"}
              formGroupLinkWith={"web_licennce_print_form"}
              isLoaderOnError={false}
              apiUrl={`${multipleLicencePrintRequestAPI.url}?${getLicenceIdsArray()}&template_code=LIC`}
              onDestroyUnsetFormObject={true}
              onRebuildResponseFn={(response) => {
                return {
                  data: response,
                  "_onLoad": false
                }

              }}
            >
              <div className={`license-slide-container-web`}>
                <RoundButton
                  btnSize={roundBtnSizes.medium}
                  elementStyle={"prev"}
                  iconClass={"fas fa-chevron-left"}
                  onClickBtnFn={slideLeft}>
                  tooltip={"Next"}
                </RoundButton>
                <div className="w-100">
                <LicencePrintPreview index={index} licenseData={_get(formState, `web_licennce_print_form.data.data.licences`, "")[index]} className="dealerLicenseImage" uiType={uiType} />
                </div>
              

                <RoundButton
                  elementStyle={"next"}
                  iconClass={"fas fa-chevron-right"}
                  onClickBtnFn={slideRight}>
                  tooltip={"Prev"}
                </RoundButton>
                <div className="helperText"></div>
              </div>
            </FormWrapper>


  );
};

export  {LicencePrintPopup};
