/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 15:51:13
 * @Last Modified by: Nipuna Chandimal
 * @Last Modified time: 2021-01-19 16:58:08
 */

import React, { Fragment } from "react";

import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import { CounterSessionHistory } from "./pages/CounterSessionHistory";
import authorized from "../../hoc-middlewares/Authorized";

const CounterSessionHistoryRoutes = () => {
  return (
    <Fragment>
      <Navigate exact={true} path="/counter-session-history" routeKey={"counter-session-history"} component={authorized(CounterSessionHistory)} routePermissions={permissions.COUNTER_SESSION_HISTORY.permissions} />
    </Fragment>
  );
};
 
export default CounterSessionHistoryRoutes;
