import React, { useState, Fragment, useEffect, useContext } from 'react';
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import DocumentsUploader from "../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import { Reason } from "../../../request-for-approval/pages/includes/Reason";
import {
    AddVehicleInfoDisplay, ChangeSerialNumberInfoDisplay, LicenceReprintInfoDisplay, LicenceTypeChangeInfoDisplay,
    LicenceWaiveOffInfoDisplay, RequestLicenceInfoDisplay, ReverseLastTransactionInfoDisplay, RoutePermitFineInfoDisplay,
    SetEffectiveDateInfoDisplay, TransferVehicleInfoDisplay, UpdateVehicleInfoDisplay, VehicleStatusChangeInfoDisplay,
    AddLicenceRecieptInfoDisplay, LicenseWithArrearsInfoDisplay, AddLicenceTransactionDisplay, DealersLicenceReissuanceDisplay,
    OtherAdditionsDeductions, CancelLicenceTrxInformation, AddDealerInfoDisplay, UpdateDealerInfoDisplay, DealerLicenceReprintInfoDisplay, DealerLicenceChangeSerialInfoDisplay,
    CancelLicenceTransactionDisplay
} from "../../../request-for-approval/pages/includes/ApprovalRequestInfoDisplay";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import ViewVehicleInfo from "./ViewVehicleInfo";
import { ActivityLog } from "./ActivityLog";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { actionApi, uploadDetailGetAPI, pdmtListAPI, getVehicleInfoAPI, getVehicleMetaDataAPI } from '../../../../../config/apiUrl.config';
import { RequestTypeList } from '../../../../../config/enum.config';
import { getCodeName } from '../../../../../helpers/common-helpers/common.helpers';
import { RawIndexMemo } from "../../../../ui-components/ui-elements/table/DataTable";
import { _getInt } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import ViewDealerInfo from "./ViewDealerInfo";
const RowNo = (props) => {
    return (
        <RawIndexMemo
            page_size={_getInt(props.pagingObject, "per_page", 1)}
            current_page={_getInt(props.pagingObject, "current_page", 1)}
            index={props.rowIndex || 0}
        />
    )
};

const ApprovalRequestPopup = (props) => {
    const formKey = "approvalRequest";
    const request_id = props.row.request_id;
    const request_type = _get(props, "row.request_type", null);
    const [formState, formAction] = useContext(FormContext);
    const [getState, setState] = useState({
        "ShowPop": false,
        "ShowActivityLog": false,
    });
    useEffect(() => {
        formAction.initFromFn("vehicle", "_onLoad", "", true);
        formAction.initFromFn("province", "_onLoad", "", true);
        vehicleInfo()
    }, []);


    const vehicleInfo = () => {
        callApi(
            `${getVehicleInfoAPI.url}?vehicle_no=${_get(props, "row.vehicle_no", null)}`
        )
            .method("get")
            .headers(true)
            .send((error, result) => {
                if (!error) {
                    formAction.changeInputFn("vehicle", "data", "", result.data);
                    formAction.changeInputFn('vehicle', "_onLoad", "", false);
                    getProvinceData(result.data.data.province_id)
                    getVehileMetaDataFn(_get(props, "row.vehicle_no", null), result.data.data.current_license_type.code)
                }

            });
    }
    const getVehileMetaDataFn = (vehicle_No, current_license_type) => {
        callApi(`${getVehicleMetaDataAPI.url}?vehicle_no=${vehicle_No}&requested_licence_type=${current_license_type}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    formAction.changeInputFn('vehicle-meta-data', "data", "", result.data.data);

                }
            });
    }
    const getProvinceData = (province_id) => {
        callApi(`${pdmtListAPI.url}/${province_id}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {

                    formAction.changeInputFn('province', "provinceData", "", result.data.data);
                    formAction.changeInputFn('province', "_onLoad", "", false);

                }

            });

    }
    const emptyFn = (...para) => undefined;
    const getViewVehicleBtn = () => {

        if (props.row.request_type === "cncl_dlr_lcn_txn" || props.row.request_type === "add_lcn_txn" || props.row.request_type === "reissue_dlr_lcn" || props.row.request_type === "chng_dlr_se_num" || props.row.request_type === "reprint_dlr_lcn" || props.row.request_type === "update_dlr") {
            return (<div className="fullWidthDiv">
                <InputButton
                    elementWrapperStyle={"floatRight"}
                    btnName="view"
                    btnText={"View Dealer Info"}
                    onClickBtnFn={() =>
                        setState({
                            ...getState,
                            ShowPop: true,
                            status: true,
                        })
                    }
                />

                <div className="fullWidthDiv">

                    <DialogPopup
                        isOpen={getState.ShowPop}
                        isFullScreen={true}
                        isCustomButton={false}
                        dialogTitle={"View Dealer Information"}
                        onClosePopupFn={() => setState({
                            ...getState,
                            ShowPop: false,
                        })}
                    >
                        <ViewDealerInfo {...props} />
                    </DialogPopup>


                </div>
            </div>)
        }
        else {
            if (props.row.request_type !== "add_vcl" && props.row.request_type !== "add_dlr") {
                return (
                    <div className="fullWidthDiv">
                        <InputButton
                            elementWrapperStyle={"floatRight"}
                            btnName="view"
                            btnText={"View Vehicle Info"}
                            onClickBtnFn={() =>
                                setState({
                                    ...getState,
                                    ShowPop: true,
                                    status: true,
                                })
                            }
                        />

                        <div className="fullWidthDiv">

                            <DialogPopup
                                isOpen={getState.ShowPop}
                                isFullScreen={true}
                                isCustomButton={true}
                                customButtonComponent={CustomButtonsActivityLog}
                                dialogTitle={"View Vehicle Information"}
                                onClosePopupFn={() => setState({
                                    ...getState,
                                    ShowPop: false,
                                })}
                            >
                                <ViewVehicleInfo  {...props} />
                            </DialogPopup>
                            <DialogPopup
                                isOpen={getState.ShowActivityLog}
                                dialogTitle={"Activity Log"}
                                isFullScreen={true}
                                onClosePopupFn={() => setState({
                                    ...getState,
                                    ShowActivityLog: false,
                                })}
                            >
                                <ActivityLog vehicleId={_get(formState, "vehicle.data.data.id", null)} />
                            </DialogPopup>

                        </div>
                    </div>)
            }
        }
    };
    const getModelDealerDetailCardChange = () => {

        switch (props.row.request_type) {
            case "cncl_dlr_lcn_txn":
                return (

                    <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                        <p>
                            <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                        </p>
                        <p>{_get(props, "row.dealer_id", "-")} </p>
                    </div>

                );
            case "add_lcn_txn":
                if (_get(props, "row.vehicle_no", null) === null) {
                    return (

                        <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                            <p>
                                <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                            </p>
                            <p>{_get(props, "row.dealer_id", "-")} </p>
                        </div>

                    );
                }
                else {
                    return (

                        <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                            <p>
                                <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                            </p>
                            <p>{_get(props, "row.dealer_id", "-")} </p>
                        </div>

                    );
                }

            case "reissue_dlr_lcn":
                return (

                    <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                        <p>
                            <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                        </p>
                        <p>{_get(props, "row.dealer_id", "-")} </p>
                    </div>

                );
            case "update_dlr":
                return (

                    <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                        <p>
                            <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                        </p>
                        <p>{_get(props, "row.dealer_id", "-")} </p>
                    </div>

                );
            case "chng_dlr_se_num":
                return (

                    <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                        <p>
                            <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                        </p>
                        <p>{_get(props, "row.dealer_id", "-")} </p>
                    </div>

                );
            case "reprint_dlr_lcn":
                return (

                    <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                        <p>
                            <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                        </p>
                        <p>{_get(props, "row.dealer_id", "-")} </p>
                    </div>

                );
            case "add_dlr":
                return (

                    <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                        <p>
                            <b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
                        </p>
                        <p>{_get(props, "row.dealer_id", "-")} </p>
                    </div>


                );

            default:
                return (

                    <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                        <p>
                            <b>Vehicle ID&nbsp;&nbsp; :&nbsp;</b>
                        </p>
                        <p>{_get(props, "row.vehicle_no", "-")} </p>
                    </div>


                );
        }
    };
    const CustomButtonsActivityLog = ({
        onClosePopupFn = emptyFn,
        formGroupName = ""
    }) => {

        const close = () => {
            onClosePopupFn();
        }

        return (
            <Fragment>
                <InputButton
                    elementWrapperStyle={"floatRight"}
                    btnName="view"
                    btnText={"Activity Log"}
                    onClickBtnFn={() => setState({
                        ...getState,
                        ShowActivityLog: true,
                    })}
                />

            </Fragment>
        )
    }

    return (

        <div className="row">
            <div className="col-md-12 col-sm-12">
                <UICard cardHeading={`${getCodeName(RequestTypeList, props.row.request_type)} Request`} >
                    <div className='row'>
                        <div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
                            <p><b>Request ID&nbsp;:&nbsp;</b></p>
                            <p> {_get(props, "row.id", null)}</p>
                        </div>


                        <div className="col-md-3 col-sm-4 floatLeft d-flex flex-row">
                            <p><b>Request Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;</b></p>
                            <p> {getCodeName(RequestTypeList, request_type)} </p>
                        </div>


                        <div className="col-md-3 col-sm-4 floatLeft d-flex flex-row">
                            <p> <b>Requested Date & Time &nbsp;:&nbsp;</b></p>
                            <p> {dateObjectToString(_get(props, "row.created_at", null), "dd/mm/YY HH:MM")} </p>
                        </div>



                        <div className="col-md-4 col-sm-4 floatLeft d-flex flex-row">
                            <p><b>Requested by&nbsp;:&nbsp;</b></p>
                            <p>{_get(props, "row.created_by_name", "-")}({_get(props, "row.created_role_name", "-")}) </p>
                        </div>
                    </div>
                    <div className='row'>
                        {getModelDealerDetailCardChange()}



                        <div className="col-md-3 col-sm-4 floatLeft d-flex flex-row">
                            <p><b>Request Status &nbsp; :&nbsp;</b></p>
                            <p> {_get(props, "row.status", "-") === "PEN" ? "Approval Pending" : (_get(props, "row.status", "-") === "APP" ? "Approved" : (_get(props, "row.status", "-") === "REJ" && _get(props, "row.action", "-") === "FRD" ? "Withdrawn" : "Rejected"))} </p>
                        </div>

                        <div className="col-md-5 col-sm-4 floatLeft d-flex flex-row">
                            <p><b>Requested Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b></p>
                            <p>{_get(props, "row.created_location_province_code", "-")}({_get(props, "row.created_location_name", "-")}) </p>
                        </div>

                    </div>

                    {getViewVehicleBtn()}


                </UICard >
            </div >
            <div className="col-md-12 col-sm-12 defaultMarginTop">

                {/* 
    
    
    Dealers_Cancel_Licence_Receipt_Transaction = 'cncl_dlr_lcn_txn', */}
                {
                    props.row.request_type === "vcl_transfer" ? (
                        <TransferVehicleInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "add_vcl" ? (
                        <AddVehicleInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "update_vcl" ? (
                        <UpdateVehicleInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "chng_lcn_type" ? (
                        <LicenceTypeChangeInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "reverse_last_txn" ? (
                        <ReverseLastTransactionInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "chng_vcl_status" ? (
                        <VehicleStatusChangeInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "lcn_w_invalid_doc" ? (
                        <RequestLicenceInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "reprint_lcn" ? (
                        <LicenceReprintInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "set_eff_date" ? (
                        <SetEffectiveDateInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "waiveoff_lcn" ? (
                        <LicenceWaiveOffInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "chng_se_num" ? (
                        <ChangeSerialNumberInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "lcn_w_arrears" ? (
                        <LicenseWithArrearsInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "add_lcn_r_txn" ? (
                        <AddLicenceRecieptInfoDisplay uiType={'viewApproval'} {...props} />

                    ) : props.row.request_type === "other_ad_ded" ? (
                        <OtherAdditionsDeductions uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "add_lcn_txn" ? (
                        <AddLicenceTransactionDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "cncl_dlr_lcn_txn" ? (
                        <CancelLicenceTransactionDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "cncl_lcn_r_txn" ? (
                        <CancelLicenceTrxInformation uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "reissue_dlr_lcn" ? (
                        <DealersLicenceReissuanceDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "add_dlr" ? (
                        <AddDealerInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "update_dlr" ? (
                        <UpdateDealerInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "reprint_dlr_lcn" ? (
                        <DealerLicenceReprintInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : props.row.request_type === "chng_dlr_se_num" ? (
                        <DealerLicenceChangeSerialInfoDisplay uiType={'viewApproval'} {...props} />
                    ) : null
                }

            </div>
            <div className="col-md-12 floatLeft defaultMarginTopBottom">
                <Reason
                    formKey={props.uiType === 'APR' ? `${formKey}-new` : formKey}
                    requestType={props.uiType}
                    dataSubmittedForApproval={props.dataSubmittedForApproval}
                    row={props.row}
                    isViewApproval={true}
                />
            </div>
            <div className="col-md-12 floatLeft defaultMarginTopBottom">
                <DocumentsUploader
                    isUploadFn={false}
                    formGroupName={formKey}
                    tableApiUrl={_get(props, "row.request_id", null) ? `${uploadDetailGetAPI.url}?request_id=${_get(props, "row.request_id", null)}` : null}
                    requestId={_get(props, "row.request_id", null)}
                    isDelete={false}
                />
            </div>
            <div className="col-md-12 floatLeft defaultMarginTopBottom">

                <DataTable
                    apiUrl={`${actionApi.url}/${request_id}`}
                    dataTableKey={actionApi.key}
                    isSetAction={false}
                    isSetSearchFrom={false}
                    isSelectRow={false}
                    isSetTableFooter={true}
                    isSetTableHeader={true}
                    tableHeaderList={[
                        { displayName: "No.", key: "__no__", isSort: false },
                        {
                            displayName: "Date & Time", key: "created_at", onPrintFn: (rawData) => {
                                return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM");
                            }, isSort: false
                        },
                        {
                            displayName: "Action", key: "action", onPrintFn: (rawData) => {
                                if (rawData.action === 'FRD') {
                                    return 'Forward'
                                } else if (rawData.action === 'APP' || rawData.action === 'APR') {
                                    return 'Approved'
                                } else {
                                    return 'Rejected'
                                }
                            }, isSort: false,
                        },
                        { displayName: "Forwarded Location", key: "forwarded_location", isSort: false },
                        {
                            displayName: "Forwarded Role", key: "forward_role_code", onPrintFn: (rawData) => {
                                if (rawData.forward_role_code === 'OPT') {
                                    return 'Operator'
                                } else if (rawData.forward_role_code === 'SUP') {
                                    return 'Supervisor'
                                } else if (rawData.forward_role_code === 'COM') {
                                    return 'Commissioner'
                                } else if (rawData.forward_role_code === 'ACC') {
                                    return 'Accountant'
                                } else {
                                    return '-'
                                }
                            }, isSort: false,
                        },
                        { displayName: "Action by", key: "created_by", isSort: false },
                        {
                            displayName: "Action Role & Location", key: "created_role_id", onPrintFn: (rawData) => {
                                return rawData.created_role_id.split('-')[0] + " & " + rawData.created_location
                            }, isSort: false
                        },
                        { displayName: "Remark", key: "remark", isSort: false },

                    ]}
                    specificComponents={{
                        "__no__": RowNo
                    }}
                />

            </div>
        </div >
    );
};


export default ApprovalRequestPopup;
