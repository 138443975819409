/*
 * @Author: Nipuna Chandimal
 * @Date: 2021-01-04 17:01:32
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-09-27 10:47:54
 */
import React, { useContext } from "react";
import { AuthContext } from "../../../../modules/core/context-providers/AuthContext.provider";
import {
  AuthorityListApi,
  availableCounterListApi,
  usersbySessionAuthorityListApi,
} from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  DatePickerWithState,
  FormWrapper,
  SelectBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";
const CounterSessionSearchCriteria = ({ dataTableKey = "sessions" }) => {
  const formGroupName = `${dataTableKey}_search`;
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [coreState, coreAction] = useContext(CoreContext);
  let roleLevelCode = _get(authStatus, "authUser.role_level_code", "");
  let authorityId = _get(authStatus, "authUser.authorityId", "");
  let provinceCode = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtCode', ""):_get(authStatus, 'authUser.pdmtId.code', "");
  let provinceId = _get(authStatus, "authUser.provinceId", "");
  const serviceId=_get(formState, `${formGroupName}.authority_id`, authorityId)===null? _get(authStatus, "authUser.service_id", ""):_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === _get(formState, `${formGroupName}.authority_id`, authorityId))?.service.id;
  const filter = `&session_status=CLOSE&from=${dateObjectToString(_get(formState, `${formGroupName}.from`, new Date()))}&to=${dateObjectToString(_get(formState, `${formGroupName}.to`, new Date()))}`;
  return (
    <FormWrapper setGroupName={formGroupName}>
      <div className="row">
        <div className="col-3">
          <SelectBoxWithState
            labelText={"Authority"}
            elementWrapperStyle={"counter-history-authority-dropdown"}
            formGroupName={formGroupName}
            inputName={"authority_id"}
            elementStyle={"floatLeft"}
            apiUrl={`${AuthorityListApi.url}?sort_by=name|asc&province_code=${provinceCode}`}
            apiStateKey={AuthorityListApi.key}
            valueName={"service.name"}
            isEnableAllOption={roleLevelCode != "INT_AU"}
            isDisabled={roleLevelCode == "INT_AU"}
            keyName={"id"}
            onChangeFn={() => {
              //For Update Status Error
              formAction.changeInputFn(formGroupName, "counter_id", "", null);
              formAction.changeInputFn(formGroupName, "user_id", "", null);
            }}
          />
        </div>
        <div className="col-2">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formGroupName}
            emptySelectOptionTxt={"Counter"}
            apiUrl={`${availableCounterListApi.url}/by-service-id/${serviceId}/counters/list?sort_by=name|asc`}
            apiStateKey={availableCounterListApi.key}
            keyName={"id"}
            valueName={"name"}
            labelText="Counter"
            inputName={"counter_id"}
          />
        </div>
        <div className="col-2">
          <SelectBoxWithState
            labelText={"User"}
            formGroupName={formGroupName}
            apiUrl={`${usersbySessionAuthorityListApi.url}/${_get(formState, `${formGroupName}.authority_id`, authorityId)}/list?sort_by=name|asc${filter}`}
            apiStateKey={usersbySessionAuthorityListApi.key}
            keyName={"id"}
            emptySelectOptionTxt={"User"}
            valueName={"user_name"}
            inputName={"user_id"}
          />
        </div>
        <div className="col-2">
          <DatePickerWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formGroupName}
            labelText={"From"}
            inputName={"from"}
            minDate={new Date(1970 - 1 - 1)}
            maxDate={new Date()}
          />
        </div>
        <div className="col-2">
          <DatePickerWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formGroupName}
            labelText={"To"}
            inputName={"to"}
            minDate={_get(formState[formGroupName], "from", null)}
            maxDate={new Date()}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export { CounterSessionSearchCriteria };
