import React from 'react';
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { getDataByFormObject } from '../../../../../helpers/common-helpers/common.helpers.js';
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { getAllTask, reqstStatusChangeAPI } from '../../../../../config/apiUrl.config';

const WithDrawSubmitButton = (props) => {
    // console.log('Propsssss====', props);

    return (
        <SubmitButton
            startIcon={"far fa-save"}
            btnText="Withdraw"
            elementStyle="btnWrapper"
            formGroupName={props.formGroupName}
            dataTableKey={getAllTask.key}
            tooltip={"Withdraw"}
            isValidate={false}
            isSetHeaders={true}
            flashMessages={{
                success: {
                    status: true,
                    message: "Successfully Withdraw.",
                    messageType: "success",
                },
            }}
            onGetAPIEndPointFn={(formState) => {
                // console.log('formSTate====', formState);
                return {
                    url: `${reqstStatusChangeAPI.url}/${props.row.request_id}`,
                    key: reqstStatusChangeAPI.key,
                };
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject({
                    ...formObject,
                    status: "REJ"
                });
            }}
            callApiObject={{
                isSetHeaders: true,
                method: "put",
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.responseLinkToFrom,
            }}
            onResponseCallBackFn={(response, error) => {
                if (
                    response.length === undefined &&
                    error === undefined &&
                    response.data.errors === undefined
                ) {
                    props.refreshMainScreenAfterPerformFn()
                    props.onClosePopupFn();
                }
            }}
        />

    )
}

export {
    WithDrawSubmitButton
}