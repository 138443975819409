/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-07 13:47:09
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 13:22:46
 */

import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Badge from '@material-ui/core/Badge';

import { MenuWrapper, MenuItemWrapper } from "../../ui-elements/lists-and-menus/ListsAndMenus";
import { Icon, UICard } from "../../ui-elements/common/BaseElements";
import { DialogPopup } from "../../ui-elements/modal/DialogPopup";
import { FormWrapper, SubmitButton } from "../../ui-elements/form/index";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { userDelegationChangeAPI, userDelegationCreateAPI, userLogoutAPI, userInfoAPI } from "../../../../config/apiUrl.config";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import { _find, _findindex, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { callApi } from '../../../../helpers/common-helpers/callApi.helpers';
import { setToLocalStorage } from '../../../../helpers/common-helpers/manageStorage.helpers';
import { authTokenStorageKey, ReactAppVerNO } from "../../../../config/core.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { UIContext } from "../../context-providers/UIContext.provider";
import { permissions } from "../../../../config/permission.config";
const emptyFn = (...para) => undefined;
const ChangeDelegation = (
    {
        delegation_id = "",
        user_id = "",
        onClosePopupFn = emptyFn,
    }
) => {
    const history = useHistory();
    const [authStatus, authAction] = useContext(AuthContext);
    const [formState, formAction] = useContext(FormContext);
    const [uiState, uiAction] = useContext(UIContext);
    // useEffect(()=>{
    //     formAction.changeInputFn("Notification", "count", "",0 )
    // },[])
    return (
        <SubmitButton
            btnText={'Change'}
            startIcon={'fas fa-exchange-alt'}
            isValidate={true}
            formGroupName={"switchDelegation"}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Delegation successfully switched",
                    messageType: "success"
                }
            }}
            validationObject={{
                fileds: {
                    "switchDelegation": "Switch Delegation",
                },
                rules: {
                    "switchDelegation": "switchDeligationError",
                }
            }}
            onChangeRequestBodyFn={(fromObject) => {
                return getDataByFormObject(fromObject);
                

            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.responseLinkToFrom
            }}
            onGetAPIEndPointFn={(formState) => {
                return {
                    "url": `${userDelegationChangeAPI.url}/${user_id}/user/${delegation_id}`,
                    "key": userDelegationChangeAPI.key
                }
            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    onClosePopupFn();
                      callApi(userInfoAPI.url)
                        .headers(true)
                        .method('get')
                        .send((err, result) => {
                            if (!err) {
                                if (_get(result, 'data.role_level_code', null) == 'INT_AU') {
                                    const tokens = {
                                        accessToken: _get(authStatus, "accessToken", ""),
                                        refreshToken: _get(authStatus, "refreshToken", ""),
                                        keycloakObject: _get(authStatus, "keycloakObject", {}),
                                    };
                                    tokens['id'] = _get(result, 'data.sub', null);
                                    tokens['name'] = _get(result, 'data.display_name', null);
                                    tokens['role'] = _get(result, 'data.role_name', null);
                                    tokens['permission'] = _get(result, 'data.permissions', []);
                                    tokens['current_delegation'] = _get(result, 'data.current_delegation_id', null);
                                    tokens['service_id'] = _get(result, 'data.service_id', null);
                                    tokens['pdmt_code'] = _get(result, 'data.pdmt.code', null);
                                    tokens['pdmt_id'] = _get(result, 'data.authority.pdmt_id', null);
                                    tokens['province_code'] = _get(result, 'data.authority.province.code', null);
                                    tokens['role_name'] = _get(result, 'data.role_name', null);
                                    tokens['role_level_code'] = _get(result, 'data.role_level_code', null);
                                    tokens['role_prefix'] = _get(result, 'data.role_prefix', null);
                                    tokens['role_category_code'] = _get(result, 'data.role_category_code', null);
                                    tokens['authority_type_code'] = _get(result, 'data.authority.authority_type.code', null);

                                    tokens['authority_name'] = _get(result, 'data.authority.service.name', null);
                                    tokens['authority_code'] = _get(result, 'data.authority.code', null);
                                    tokens['province_code'] = _get(result, 'data.authority.pdmt_id.code', null);
                                    tokens['authority_prefix'] = _get(result, 'data.authority.prefix', null);
                                    tokens['authority_id'] = _get(result, 'data.authority.id', null);

                                    tokens['keycloak'] = _get(authStatus, "keycloak", "");
                                    tokens['province_id'] = _get(result, 'data.authority.province.id', null);
                                    tokens['province_name'] = _get(result, 'data.authority.province.name', null);
                                    setToLocalStorage(authTokenStorageKey, tokens);
                                    authAction.setTokensFn(tokens);
                                } else if (_get(result, 'data.role_level_code', null) == 'INT_PRV') {
                                    const tokens = {
                                        accessToken: _get(authStatus, "accessToken", ""),
                                        refreshToken: _get(authStatus, "refreshToken", ""),
                                        keycloakObject: _get(authStatus, "keycloakObject", {}),
                                    };
                                    tokens['id'] = _get(result, 'data.sub', null);
                                    tokens['name'] = _get(result, 'data.display_name', null);
                                    tokens['role'] = _get(result, 'data.role_name', null);
                                    tokens['permission'] = _get(result, 'data.permissions', []);
                                    tokens['current_delegation'] = _get(result, 'data.current_delegation_id', null);
                                    tokens['service_id'] = _get(result, 'data.service_id', null);
                                    tokens['pdmt_code'] = _get(result, 'data.pdmt.code', null);
                                    tokens['pdmt_id'] = _get(result, 'data.pdmt.id', null);
                                    tokens['province_code'] = _get(result, 'data.authority.province.code', null);
                                    tokens['role_name'] = _get(result, 'data.role_name', null);
                                    tokens['role_level_code'] = _get(result, 'data.role_level_code', null);
                                    tokens['role_prefix'] = _get(result, 'data.role_prefix', null);
                                    tokens['role_category_code'] = _get(result, 'data.role_category_code', null);

                                    tokens['authority_code'] = _get(result, 'data.authority.code', null);
                                    tokens['authority_prefix'] = _get(result, 'data.authority.prefix', null);
                                    tokens['authority_id'] = _get(result, 'data.authority.id', null);
                                    tokens['authority_name'] = _get(result, 'data.authority.service.name', null);
                                    
                                    tokens['keycloak'] = _get(authStatus, "keycloak", "");
                                    tokens['province_id'] = _get(result, 'data.authority.province.id', null);
                                    tokens['province_name'] = _get(result, 'data.authority.province.name', null);
                                    tokens['province_code'] = _get(result, 'data.province.code', null);
                                    setToLocalStorage(authTokenStorageKey, tokens);
                                    authAction.setTokensFn(tokens);
                                }
                                setTimeout(() => {
                                uiAction.getNotificationCount({
                                    read: false,
                                    pdmt_service_id: _get(result, 'data.role_category_code', null) !== undefined && 
                                    (_get(result, 'data.role_category_code', null) === "SUP" || _get(result, 'data.role_category_code', null) === "COM") ? _get(result, 'data.service_id', null) : _get(result, 'data.authority.pdmt_id.service.id', null),
                                     user_id: _get(result, 'data.sub', null),
                                    role_category_code: _get(result, 'data.role_category_code', null),
                                    current_role_category: _get(result, 'data.role_category_code', null)
                                  });
                                }, 6000);  
                            }
                        })
                        
                    setTimeout(() => {
                        history.push("/"); 
                    }, 2000);
        
                   
                    //formAction.changeInputFn("Notification", "count", "",uiState.notificationCount )
                }
            }}
        />
    );

};



const NotificationWrapper = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [getState, setState] = useState({
        delegationModelStatus: false,
    });
    const history = useHistory();
    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const [getDelegation, setDelegation] = useState(null);
    const [, coreAction] = useContext(CoreContext);
    const [uiState] = useContext(UIContext);
   
    const roleLevelCode = _get(authStatus, 'authUser.role_level_code', null);
    const setDeligationValue = (id) => {
        setDelegation(id)
        formAction.changeInputFn("switchDelegation", "switchDelegation", "", id)
        
    }

    const logoutApiCall = () => {
        const requestBody = {
            refresh_token: _get(authStatus, 'keycloak.refreshToken', null)
        }

        coreAction.sendRequestFn(userLogoutAPI.url)
            .isMultipart(false)
            .method("post")
            .body(requestBody)
            .setLoader(true)
            .setInitStoring("none", null)
            .headers(true)
            .send((error, result) => {
                if (!error) {
                    authAction.unauthorisedUserFn();
                }
            });

    }

    const profileMenuFn = (key) => {
        if (key === 'delegation') {
            setState({
                ...getState,
                delegationModelStatus: true,
            });
        } else if (key === 'logout') {
            logoutApiCall();
        } else if (key === 'notification') {
            history.push('/notification');
        } else if (key === 'changePassword') {
            history.push('/change-password');
        }
        setAnchorEl(undefined);
    };

    const switchDeligationError = () => {
        const check = _find(_get(formState, `switchDelegation._errors`, []), (e) => e.property === "switchDelegation");
        if (check !== undefined) {
            return {
                status: true,
                message: check.message
            };
        } else {
            return {
                status: false
            }
        }
    };
    useEffect(()=>{
        formAction.changeInputFn("Notification", "count", "",uiState.notificationCount )
    },[uiState.notificationCount])

    return (
        <div className={`notificationNaviBar`}>
            <ul className={'notificationUL'}>
                <li className="topName" lang="en-US">
                    <strong className="w-100">{_get(authStatus, 'authUser.name', "")} ({_get(authStatus, 'authUser.roles', "")})
                        {(_get(authStatus, 'authUser.authorityCode', "")) ?
                            (` - ${_get(authStatus, 'authUser.pdmtId.code', '')} (${_get(authStatus, 'authUser.authorityName', '')})`) : //bug #3885
                            (` - ${_get(authStatus, 'authUser.pdmtCode', '')}`)
                        }
                    </strong>
                </li>
                <li className="notificationMenu">
                    <span onClick={() => profileMenuFn('notification')}>
                        {
                            (uiState.notificationCount === null || uiState.notificationCount === 0 || uiState.notificationCount < 0) ? (
                                <i className="mdi mdi-bell mainIcon"></i>
                            ) : (    
                                    <Badge badgeContent={uiState.notificationCount} color="error">
                                        <i className="mdi mdi-bell mainIcon"></i>
                                        {/*<i className="mdi mdi-chevron-down arraow"></i>*/}
                                    </Badge> 
                                    
                            )
                        }
                    </span>
                </li>
                <li aria-controls="profileMenu" className="profileMenu" aria-haspopup="true">
                    <div onClick={(event) => setAnchorEl(event.currentTarget)} style={{ display: 'inline-block' }}>
                        <i className="mdi mdi-account mainIcon"></i>
                        <i className="mdi mdi-chevron-down arraow"></i>
                    </div>

                    <MenuWrapper
                        id="profileMenu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onCloseFn={() => profileMenuFn(null)}
                        elementStyle={'hedaerMenus profileMenu'}
                    >
                        {_get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.DELEGATION_SWITCH.permissions[0]) && <MenuItemWrapper
                            elementStyle={'profileMenuItem'}
                            menuKey="delegation"
                            onClickFn={profileMenuFn}
                            onCloseFn={() => profileMenuFn(null)}
                        >
                           <div className="d-flex flex-row">
                              <div style={{width:"35px"}}>  <Icon iconClass="fas fa-user-clock" /></div>
                              <p className="mt-1"> Switch Delegation
                            </p>
                            </div>
                        </MenuItemWrapper>
                        }

                        {_get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.PASSWARD_CHANGE.permissions[0]) && <MenuItemWrapper
                            elementStyle={'profileMenuItem'}
                            menuKey="changePassword"
                            onClickFn={profileMenuFn}
                            onCloseFn={() => profileMenuFn(null)}
                        >
                            <div className="d-flex flex-row">
                            <div style={{width:"35px"}}>   <Icon iconClass="fas fa-user-edit" /></div>
                             <p className="mt-1">  Change Password</p> 
                            </div>
                        </MenuItemWrapper>}

                        <MenuItemWrapper
                            elementStyle={'profileMenuItem'}
                            menuKey="logout"
                            onClickFn={profileMenuFn}
                            onCloseFn={() => profileMenuFn(null)}
                        >
                            <div className="d-flex flex-row">
                            <div style={{width:"35px"}}>  <Icon iconClass="mdi mdi-logout" /></div>
                              <p className="mt-1">  Logout</p>
                            </div>
                        </MenuItemWrapper>
                        <MenuItemWrapper
                            elementStyle={'profileMenuItem'}
                            menuKey="logout"
                            onCloseFn={() => profileMenuFn(null)}
                        >
                            <p>
                                {`Version ${ReactAppVerNO}`}
                            </p>

                        </MenuItemWrapper>
                    </MenuWrapper>
                </li>
            </ul>

            <DialogPopup
                isOpen={getState.delegationModelStatus}
                dialogTitle={'Switch Delegation'}
                onClosePopupFn={() =>
                    {
                    setState({
                        ...getState,
                        delegationModelStatus: !getState.delegationModelStatus,
                    })
                    setDelegation(null)
                }
                }
                isCloseButton={true}
                isCustomButton={true}
                customButtonComponent={ChangeDelegation}
                customButtonComponentProps={
                    {
                        delegation_id: getDelegation,
                        user_id: _get(authStatus, 'authUser.id', "")
                    }
                }
            >
                <UICard>
                    <FormWrapper
                        // elementStyle="col-md-12"
                        setGroupName={'switchDelegation'}
                        apiUrl={`${userDelegationCreateAPI.url}/${_get(authStatus, 'authUser.id', "")}/list`}
                        onRebuildResponseFn={(response) => {
                            const index = _findindex(response, (e) => {
                                return e.id === _get(authStatus, 'authUser.current_delegation', "")
                            })
                            const current_delegation = response.splice(index, 1);
                            return {
                                "current_delegation": current_delegation[0],
                                "delegations": response,
                                "_onLoad": false,
                            }
                        }}
                    >
                        <div className="row">
                            <div className="col-md-12">

                                <dl className="row">
                                    <dt className="col-sm-6 text-right">Current Delegation</dt>
                                    <dd className="col-sm-6">{_get(formState, 'switchDelegation.current_delegation.role.name', "")}
                                        {_get(formState, 'switchDelegation.current_delegation.orgData.pdmt[0].code', null) ?
                                            (` - ${_get(formState, 'switchDelegation.current_delegation.orgData.pdmt[0].code', '')}`) :
                                            (` - ${_get(formState, 'switchDelegation.current_delegation.orgData.authority[0].pdmt[0].code', '')}`)
                                        }</dd>
                                </dl>

                            </div>
                            <div className="col-md-12">
                                {
                                    (switchDeligationError().status === true) ? (
                                        <div className={"defaultFormHelperText text-danger"}>
                                            {switchDeligationError().message}
                                        </div>
                                    ) : null
                                }
                                <UICard
                                    cardHeading="Switch To"
                                    inputName={"switchDelegation"}
                                >
                                    <React.Fragment>
                                        {
                                            _get(formState, 'switchDelegation.delegations', []).map(delegation => (
                                                <div className="col-md-12" key={delegation.id}>
                                                    <p onClick={() => { setDeligationValue(_get(delegation, 'id', "")) }} style={{ cursor: "pointer" }}>
                                                        {
                                                            (getDelegation === _get(delegation, 'id', "") ? (
                                                                <i className="mdi mdi-check mainIcon" />
                                                            ) : null)
                                                        }
                                                        {_get(delegation, 'role.name', "")} {(_get(delegation, 'orgData.pdmt[0].code', null) ?
                                                            ` - ${_get(delegation, 'orgData.pdmt[0].service.name', '-')}` : ` - ${_get(delegation, 'orgData.authority[0].pdmt[0].service.name', '-')}`)}
                                                    </p>
                                                </div>
                                            ))
                                        }
                                    </React.Fragment>
                                </UICard>

                            </div>
                        </div>
                    </FormWrapper>
                </UICard>
            </DialogPopup>
        </div>
    );
}

const HeaderWrapper = ({
    fixedMenuStatus = true,
    pageTitle = "",
    breadCrumbList = []
}) => {

    return (
        <div className={`headerWrapper ${fixedMenuStatus === true ? "halfHeader" : "fullHeader"}`} >
            <div className={`breadcrumbWrapper`}>
                <div className="headerTxtWrapper">
                    <h1 className="pageHeadingTxt">{pageTitle}</h1>
                </div>
                <div className="pageLinks">
                    {
                        (breadCrumbList.length !== 0) ? (
                            <span className="otherLinks">
                                <i className="mdi mdi-home"></i>
                                <span className="separator">/</span>
                            </span>
                        ) : (null)
                    }
                    {
                        breadCrumbList.map((item, index) => {
                            if (index === (breadCrumbList.length - 1)) {
                                return (
                                    <span key={index} className="lastLink">
                                        {item.displayName}
                                    </span>
                                );
                            } else {
                                return (
                                    <Link key={index} to={item.urlTo} className={`defaultLinkClass`}>
                                        <span key={index} className="otherLinks">
                                            {item.displayName}
                                            <span className="separator">/</span>
                                        </span>
                                    </Link>
                                );
                            }
                        })
                    }
                </div>
            </div>

            <NotificationWrapper />

        </div>
    );
};

export { HeaderWrapper };
