/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 15:51:13
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-04 17:04:00
 */

import React, { Fragment } from "react";

import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import CounterSession from "./pages/CounterSession";
import authorized from "../../hoc-middlewares/Authorized";

const DashboardRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/counter-session"
        routeKey={"counter-session"}
        component={authorized(CounterSession)}
        routePermissions={permissions.COUNTER.permissions}
      />
    </Fragment>
  );
};

export default DashboardRoutes;
