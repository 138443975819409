/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2019-08-11 11:25:28
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-04-06 14:19:18
 */

import { getInputsForValidate } from "../../common-helpers/common.helpers";

const switchDeligationError = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue.length === 0) {
            cb("At least one delegation should be selected");
        } else {
            cb(null, true);
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (minAmount)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
};

export {
    switchDeligationError
}