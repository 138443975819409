/*
 * @Author: Madhawa
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-10 13:48:24
 */

import React, { Fragment, useContext } from "react";
import { useEffect } from "react";
import { fuelAPI, pdmtListAPI } from "../../../../../config/apiUrl.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { getLabelValue, isEmptyValue, thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { FormContext } from "../../../core/context-providers/FormContext.provider";


const QuotationVehicleRevenueLicence = ({ uiType = "quotation", latestLicence = {}, licenseData = {}, index }) => {
  const searchFormKey = "quotation_search_form";
  const vehicleLicenseListKey = "quotation_license_vehiclelist_form"
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);

  const helperText = [];
  const dataList = [
    "images/licence-preview-empty.jpg",
    "images/file-word.png",
    "images/profile_img.png",
  ];

  useEffect(() => {
    callApi(`${pdmtListAPI.url}/${_get(licenseData, "vehicle_id.province_id", "")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .body({})
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('province', "code", "", result.data.data.code);
        }
      });
    callApi(`${fuelAPI.url}/${_get(licenseData, "vehicle_id.fuel_type", "")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .body({})
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('fuel', "data", "", result.data.data.name);
        }
      });
  }, []);

  const allDocumentValidationDetails = _get(formState, 'quotation_payment.documentValidations', []);
  const indexOfDetails = allDocumentValidationDetails.map(e => e.vehicle_no).indexOf(_get(licenseData, "vehicle_no", ""));
  const docDetails = allDocumentValidationDetails[indexOfDetails];

  const getWeight = (gross, tare) => {
    if (gross !== 0 && gross !== '0.00' && gross != null && gross !== 0.00 && tare !== 0 && tare !== '0.00' && tare != null && tare !== 0.00) {
      return `${gross} kg / ${tare} kg`
    } else if (gross !== 0 && gross !== '0.00' && gross != null && gross !== 0.00) {
      return `${gross} kg`
    } else {
      return `${tare} kg`
    }
  }
  return (
    dataList.length > 0 && (
      <Fragment>
        <div className="licence-preview-card ml-5">
          <img src={dataList[0]} alt={""} />
          <div className="mainContent">
          <div className="row d-flex justify-content-center">
              <div className="licence-date float-left">
                {_get(licenseData, "validity_to", null) ? dateObjectToString(_get(licenseData, "validity_to", null), "MMM YYY") : dateObjectToString(new Date(), "MMM YYY")}
              </div>
            </div>

            <br />
            <div className="row">
              <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
                {`${_get(licenseData, "vehicle_class_id.name", "")} | ${_get(formState, "fuel.data", "")}`}
              </div>
            </div>
            <div className="row">
              <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
                {`${_get(formState, "province.code", "")} | ${_get(licenseData, "vehicle_no", "")}|   ${_get(licenseData, "licence_type_id.name", "").toUpperCase()} ${_get(formState, "vehicle_search_form.isTempLicence", false) ? '(TEMPORARY)' : ''}`}
              </div>
            </div>
            <div className="row">
            </div>


            <div className="col-md-12 col-sm-12  main-container">
              <div className="row owner-name-address-label">
                Vehicle Owner's Name & Address:
              </div>
              <div className="row owner-name">
                {getLabelValue(_get(licenseData, "owner_name", ""), true)}
              </div>

              <div className="row owner-address">
                {getLabelValue(_get(licenseData, "vehicle_id.owner_id.address_line_1", ""), true) + ", " + getLabelValue(_get(licenseData, "vehicle_id.owner_id.address_line_2", ""), true) + (_get(licenseData, "vehicle_id.owner_id.city", "") === null ? "" : (", " + _get(licenseData, "vehicle_id.owner_id.city", "")))}
              </div>

              <br />
              <div className="amount-container">
                <div className="row">
                  <div className="floatLeft col-md-3 licence-total-amount-label">
                    Amount (Rs.):
                  </div>
                  <div className="floatLeft col-md-9 licence-amounts-label">
                    Fee +  Arrears + Fine + Additions - Deductions
                  </div>
                </div>
                <div className="row">
                  <div className="floatLeft col-md-3 licence-total-amount">
                    {thousandSeparator(_get(licenseData, "total", 0), true)} :
                  </div>
                  <div className="floatLeft col-md-9 licence-amounts">
                    {thousandSeparator(_get(licenseData, "fee", 0), true)} +
                    {thousandSeparator((parseFloat(_get(licenseData, "arrears_fee", 0)) + parseFloat(_get(licenseData, "arrears_fine", 0))), true)} + {thousandSeparator(_get(licenseData, "fine", 0), true)} + {thousandSeparator((parseFloat(_get(licenseData, "addition", 0)) + parseFloat(_get(licenseData, "service_charge", 0))), true)}  - {thousandSeparator(_get(licenseData, "deduction", 0), true)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row bottom-label-container">
              <div className="col-md-6">
                <div className="validity-period-label">
                  Validity Period:
                </div>
                <div className="validity-period-value">
                  {
                    `${dateObjectToString(_get(licenseData, "validity_from", null), "dd/mm/YY")} To ${dateObjectToString(_get(licenseData, "validity_to", null), "dd/mm/YY")}`
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="vet-no-label">
                  VET Number:
                </div>
                <div className="vet-no-value">
                  {!isEmptyValue(_get(docDetails, `vet_no`, "")) ? _get(docDetails, `vet_no`, "") : ''}
                </div>
              </div>

              <div className="row bottom-label-container2">
                <div className="col-md-6">
                  <div className="row" style={{ marginLeft: 0 }}>
                    <div className="unladen-weight-label">
                      Unladen/ Gross Weight:
                    </div>
                    <div className="unladen-weight-value">
                      {getWeight(_get(licenseData, `vehicle_id.gross_weight`, 0), _get(licenseData, `vehicle_id.tare_weight`, 0))}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row" style={{ marginLeft: 0 }}>
                    <div className="no-of-seats-label">
                      Number of Seats:
                    </div>
                    <div className="no-of-seats-value">
                      {_get(licenseData, `vehicle_id.no_of_seats`, "")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="signature-place">
                    .......................................
                  </div>
                  <div className="signature-details">
                    Signature/ Designation & Date
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="signature-place">
                    {_get(authStatus, "authUser.name", "")}
                  </div>
                  <div className="signature-details">
                    {dateObjectToString(new Date(), "dd/mm/YY")}
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="helperText">{helperText[0]}</div>
        </div>
      </Fragment>
    )
  );
};

export { QuotationVehicleRevenueLicence };
