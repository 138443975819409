/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-02-28 17:31:46 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-11 10:36:16
 */

import React, { Fragment } from 'react';

import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import PaymentUI from './pages/Payment';
import authorized from '../../hoc-middlewares/Authorized';

const PaymentRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/pay" routeKey={"test"} component={authorized(PaymentUI)} routePermissions={permissions.NONE.permissions} />
        </Fragment>
    )
};

export default PaymentRoutes;
