import React from "react";

import { templateTypes } from "../../../../../../config/template.config";
import { licenceTypesListAPI } from "../../../../../../config/apiUrl.config";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
} from "../../../../../ui-components/ui-elements/form";

const SearchElement = (props) => {
  const formKey = `${props.dataTableKey}_search`;

  return (
    <div className="row">
      <div className={"col-md-6 col-sm-12"}>
        <InputBoxWithState
          labelText={
            "Vehicle ID / Dealer's ID / Licence Number / Counter Sequence Number"
          }
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          inputName={"licence_filter_id"}
          inputPlaceholder=""
          maxLength={25}
        />
      </div>

      <div className={"col-md-6 col-sm-12"}>
        <SelectBoxWithState
          uiType={templateTypes.bootstrap}
          emptySelectOptionTxt={"Licence Type"}
          inputName={"licence_type_code"}
          formGroupName={formKey}
          apiUrl={`${licenceTypesListAPI.url}?sort_by=name|asc`}
          apiStateKey={licenceTypesListAPI.key}
          keyName={"code"}
          valueName={"name"}
          labelText="Licence Type"
        />
      </div>
    </div>
  );
};

export default SearchElement;
