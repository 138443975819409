/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-09-22 20:41:48
 */

import React, { Fragment, useContext, useEffect } from 'react';
import { FormWrapper } from "../../../../../ui-components/ui-elements/form/index";
import { PaddingLessUICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { CarnetLicenceValidityPeriod } from './CarnetLicenceValidityPeriod';
import { DocumentValidation } from '../../includes/DocumentValidation';
import { OwnerInfoDisplay } from '../../includes/OwnerInfoDisplay';
import { VehicleInfoDisplay } from '../../includes/VehicleInfoDisplay';
import { OwnerDetailsIcons } from '../../includes/OwnerDetailsIcons';
import { CommentsSection } from '../../includes/CommentsSection'
import { calculateLicenceFeeAPI, carnetPaymentHistoryAPI } from '../../../../../../config/apiUrl.config';
import { fromObjectToQueryString } from '../../../../../../helpers/common-helpers/common.helpers';
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { callApi } from '../../../../../../helpers/common-helpers/callApi.helpers';
import { thousandSeparator, numberThousandSeperator } from "../../../../../../helpers/common-helpers/common.helpers";
import { PaymentHistory } from '../../includes/PaymentHistory';
import { Specialmessage } from '../../includes/Specialmessage';
import { AuthContext } from '../../../../core/context-providers/AuthContext.provider';

const emptyFn = (...para) => undefined;
const formKey = "carnet";

const CarnetLicenceElement = ({
	getState = {
		currentObject: null,
	},
	setLatestOwnerInfo = emptyFn,
	getLatestLicenceState = null,
	setState = emptyFn,
	uiType = "carnet",
	setShowHideFooterFN = emptyFn,
	getVehicleStatusCode = 'ELI'
}) => {
	
	const [formState, formAction] = useContext(FormContext);
	const [authStatus, authAction] = useContext(AuthContext);
    const userProvinceId=_get(authStatus,"authUser.role_level_code",null)==="INT_AU"?_get(authStatus,"authUser.pdmtId.id",null):_get(authStatus,"authUser.pdmtId",null)

	const getOnemonthAfterDate = () => {
		var d = new Date();
		d.setMonth(d.getMonth() + 1);
		return d
	};
	const tableHeaderList = [

		{
			displayName: "Tx. Date & Time",
			key: "tx_date_time",
			onPrintFn: (rawData) => {
				return dateObjectToString(rawData.tx_date_time, "dd/mm/YY HH:MM");
			},
			isSort: false,
		},
		{ displayName: "Lic. No.", key: "lic_no_rec_no", isSort: false },
		{
			displayName: "Validity Period (From - To)",
			key: "validity_period",
			onPrintFn: (rawData) => {
				return dateObjectToString(rawData.valid_from, "dd/mm/YY") + "-" + dateObjectToString(rawData.valid_to, "dd/mm/YY");

			},
			isSort: false,
		},
		{ displayName: "Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fee).toFixed(2)) },
		{ displayName: "Additions (LKR)", key: "additions", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.additions).toFixed(2)) },
		{
			displayName: "Deductions (LKR)",
			key: "deductions",
			onPrintFn: (rawData) => "(" + numberThousandSeperator(Math.abs((Number(rawData.deductions))).toFixed(2)) + ")",
			isSort: false,
			isRightAlign: true, 
		},
		{
			displayName: "Service Charge (LKR)",
			key: "service_charge",
			isSort: false,
			isRightAlign: true, 
			onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.service_charge).toFixed(2))
		},
		{
			displayName: "Total (LKR)",
			key: "subtotal",
			isSort: false,
			isRightAlign: true, 
			onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.total).toFixed(2))
		},
		{ displayName: "Location", key: "location", onPrintFn: (rawData) => (rawData.province_prefix==="-"?"":rawData.province_prefix) + (rawData.service_name ==="-"?"":("(" + rawData.service_name + ")")), isSort: false },

		{
			displayName: "User's Name",
			key: "username",
			onPrintFn: (rawData) => (rawData.user_name==="-"?"":rawData.user_name) +  (rawData.created_role_prefix ==="-" ?"":("(" + rawData.created_role_prefix + ")")),
			isSort: false,
		},

	]

	useEffect(() => {

		formAction.initFromFn({ "special": { _onLoad: true } });
		if (_get(formState, 'carnet.fromDate', null) !== null && _get(formState, 'carnet.toDate', null) !== null) {

			getNormalLicenceFee();
		} else {
			formAction.changeInputFn("licence_charges_form", "data", "", []);
			formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
			formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
			formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
			formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
			formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
			formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
			formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
			formAction.changeInputFn("licence_charges_form", "total", "", 0);
		}
		
	}, [_get(formState, 'carnet.fromDate', ''), _get(formState, 'carnet.toDate', ''), _get(formState, 'carnet.isReIssuance', '')]);



	const getNormalLicenceFee = (licenceEndDate = 0) => {

		const queryObj = {
			vehicle_no: _get(formState, "vehicle_search_form.data.vehicle_no", ""),
			licence_end_type: licenceEndDate,
			is_reissuance: _get(formState, 'carnet.isReIssuance', ''),
			licence_period_start_date: dateObjectToString(_get(formState, 'carnet.fromDate', '')),
			licence_period_end_date: dateObjectToString(_get(formState, 'carnet.toDate', ''))
		};

		callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
			.method("get")
			.headers(true)
			.isMultipart(false)
			.send((error, result) => {
				if (!error) {
					let totArrearsFee = 0;
					let totAddition = 0;
					let totdeduction = 0;
					let totCurrentLicenceFee = 0;
					let currentLicenceStartDate = null;
					let currentLicenceEndDate = null;

					const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

					_get(result, "data.addition_deduction_summary", []).forEach(value => {
						if (value.charge_effect_code === "DED") {
							totdeduction += value.total

						}
						if (value.charge_effect_code === "ADD") {
							totAddition += value.total

						}

					});


					totCurrentLicenceFee = _get(result, "data.current_licence_fee_total", 0)



					if (_get(result, "data.current_licences", []).length > 0) {
						currentLicenceStartDate = result.data.current_licences[0]["start_date"];
						currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
					}

					let total = (totArrearsFee + totCurrentLicenceFee +
						totAddition + _get(result, "data.additions.current_licence", 0) -
						(totdeduction + _get(result, "data.deductions.current_licence", 0))) + serviceCharge;

					if (total.isNaN) {
						total = _get(result, "data.fee", 0)
					}
					formAction.changeInputFn("licence_charges_form", "data", "", result.data);
					formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
					formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
					formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
					formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
					formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
					formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
					formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
					formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
					formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
				} else {
					formAction.changeInputFn("licence_charges_form", "data", "", []);
					formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
					formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
					formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
					formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
					formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
					formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
					formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
					formAction.changeInputFn("licence_charges_form", "total", "", 0);
				}
				formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
			});
	};
	


	return (
		<FormWrapper
			setGroupName={formKey}
			elementStyle={"normalLicenceWrapper"}
			setFormObject={{
				"isVerifyRegister": _get(getState.currentObject, 'documentValidation.isVerifyRegister', false),
				"licenceValidityeriodTo": _get(getState.currentObject, 'status.currentLicenceStatus', "1") === "2" ? "1" : "0",
				"vet_no": _get(getState.currentObject, 'documentValidation.VETNo', ""),
				"isVet": _get(getState.currentObject, 'documentValidation.checkVET', false),
				"vetCompany": _get(getState.currentObject, 'documentValidation.VETCompany', ""),
				"insurance": _get(getState.currentObject, 'documentValidation.insuranceCompany', ""),
				"isInsurance": _get(getState.currentObject, 'documentValidation.checkInsurance', false),
				"fitnessCompany": _get(getState.currentObject, 'documentValidation.FitnessCompany', ""),
				"certNo": _get(getState.currentObject, 'documentValidation.FitnessNo', ""),
				"isfitness": _get(getState.currentObject, 'documentValidation.checkFitness', false),
				"routePermComapny": _get(getState.currentObject, 'documentValidation.RoutePermCompany', "0"),
				"vrpNo": _get(getState.currentObject, 'documentValidation.RoutePermNo', ""),
				"isroutePerm": _get(getState.currentObject, 'documentValidation.checkRoutePerm', false),
				"dmtCarnetLetter": true,
				"fromDate": null,
				"toDate": null,
				"isReIssuance": false,
			}}
		>
			{
				(getState.currentObject !== null
					&& getState.currentObject !== "none"
					&& Object.keys(getState.currentObject).length !== 0
					&& (_get(getState, `currentObject.current_license_type.code`, "") === "CA")
				) ? (
					<Fragment>
						<div className="col-md-6 floatLeft">
						
									 <Specialmessage vehicleId={_get(getState, `currentObject.id`, null)} provinceId={userProvinceId} vehicleNo={_get(formState, "vehicle_search_form.data.vehicle_no", "")}/>

							<div className="fullWidthDiv">
								<PaddingLessUICard
									cardHeading="Owner Information"
									cardHeadingTail={OwnerDetailsIcons}
									passPropsToCardHeadingTail={{
										uiType: "owner_infor",
										formGroupName: formKey,
										ownerInfo: _get(getState.currentObject, `owner_id`, {}),
										absoluteOwnerInfo: _get(formState, `vehicle_search_form.data.absolute_owner_id`, {}),
										getVehicleStatusCode: getVehicleStatusCode,
									}}
								>
									<OwnerInfoDisplay
										ownerInfo={_get(getState.currentObject, `owner_id`, {})}
										absoluteOwnerInfo={_get(getState.currentObject, `absolute_owner_id`, {})}
										uiType={"ownerInfo"}
									/>
								</PaddingLessUICard>
							</div>

							<div className="fullWidthDiv">
								<PaddingLessUICard
									cardHeading="Vehicle Information"
									cardHeadingTail={OwnerDetailsIcons}
									passPropsToCardHeadingTail={{
										uiType: "vehicle_infor",
										formGroupName: formKey,
										vehicleInfo: { ...getState.currentObject, 'vehicle_status': getVehicleStatusCode },
										getVehicleStatusCode: getVehicleStatusCode,
									}}
								>
									<VehicleInfoDisplay
										uiType={"summary"}
										vehicleInfo={getState.currentObject}
									/>
								</PaddingLessUICard>
							</div>

							<div className="fullWidthDiv">

								<PaymentHistory
									apiUrl={`${carnetPaymentHistoryAPI.url}/${(_get(formState, 'vehicle_search_form.searchKey', ''))}`}
									dataTableKey={carnetPaymentHistoryAPI}
									tableHeaderList={tableHeaderList}
									licenceType={"carnet-licence"}
									formKey="carnetLicence"
									vehicleId={_get(formState, 'vehicle_search_form.searchKey', '')}
									isView={true}
									isReprint={true}
									category={"VT"}
									reprintStatus={true}

								/>

							</div>

							<div className="fullWidthDiv">
								<CommentsSection
									formKey="carnetComment"
									vehicleId={getState.currentObject.id}
									vehicle_no={getState.currentObject.vehicle_no}
									getVehicleStatusCode={getVehicleStatusCode}
								></CommentsSection>
							</div>
						</div>

						<div className="col-md-6 floatRight ">
							<div className="fullWidthDiv">
								{getLatestLicenceState ? (<DocumentValidation
									uiType={"carnet"}
									getState={getState}
									setState={setState}
									disableAll={getVehicleStatusCode === 'INE'}
									setShowHideFooterFN={setShowHideFooterFN}
									getLatestLicenceState={getLatestLicenceState}
									getVehicleStatusCode={getVehicleStatusCode}
									formGroupName={formKey}
								/>) : null}
							</div>

							<div className="fullWidthDiv">
								<CarnetLicenceValidityPeriod
									uiType={"carnet"}
									formGroupName={formKey}
									getState={getState}
									setState={setState}
									getLatestLicenceState={getLatestLicenceState}
									getVehicleStatusCode={getVehicleStatusCode}
									effectiveDate={_get(formState, `vehicle_search_form.data.licence_effective_date`, null)}
								/>
							</div>

							<div className="fullWidthDiv">
								<FormWrapper
									setGroupName={"licence_charges_form"}
								>
									<PaddingLessUICard cardHeading="Payment Amount">

										<table style={{ width: "100%" }}>
											<tbody>
												<tr>
													<th>Fee (LKR)</th>
													<td className="text-right">{thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFee", 0), true)}</td>
												</tr>

												<tr>
													<th>Additions (LKR)</th>

													<td className="text-right">{Number(_get(formState, "licence_charges_form.totAddition", 0)) === 0 ? "0.00" : ""}</td>

												</tr>




												{_get(formState, `licence_charges_form.data.addition_deduction_summary`, []).map((item) => (item.charge_effect_code === "ADD" && item.total !== 0) && (

													<tr>

														<th>
															<p className="text-left">{item.addition_deduction_name}</p>
														</th>

														<td >
															<p className="text-right">{thousandSeparator(+item.total, true)}</p>
														</td>
													</tr>

												))}



												<tr>
													<th>Deductions (LKR)</th>
													<td className="text-right">{Number(_get(formState, "licence_charges_form.totdeduction", 0)) === 0 ? "(0.00)" : ""}</td>
												</tr>



												{_get(formState, `licence_charges_form.data.addition_deduction_summary`, []).map((item) => (item.charge_effect_code === "DED" && item.total !== 0) && (
													<tr>
														<th>
															<p className="text-left">{item.addition_deduction_name}</p>
														</th>
														<td >
															<p className="text-right">({item.total ? thousandSeparator(item.total, true) : 0.00})</p>
														</td>
													</tr>

												))}


												{/* {_get(formState, "licence_charges_form.addition", null) ? (<tr>
													<th>Additions (LKR)</th>
													<td className="text-right">{thousandSeparator(_get(formState, "licence_charges_form.addition", 0.00), true)}</td>
												</tr>) : null}
												{_get(formState, "licence_charges_form.deduction", null) ? <tr>
													<th>Deductions (LKR)</th>
													<td className="text-right">({thousandSeparator(_get(formState, "licence_charges_form.deduction", 0.00), true)})</td>
												</tr> : null} */}
												<tr>
													<th></th>
													<td className="text-right"></td>
												</tr>
												<tr>
													<th>Service Charge (LKR)</th>
													<td className="text-right">
														{thousandSeparator(_get(formState, "licence_charges_form.service_charge", 0), true)}
													</td>
												</tr>
												<tr>
													<th>Total Amount (LKR)</th>
													<td className="text-right">
														<b>
															{
																thousandSeparator((Number(_get(formState, `licence_charges_form.totCurrentLicenceFee`, 0)) +
																	Number(_get(formState, `licence_charges_form.totAddition`, 0)) +
																	-
																	Number(_get(formState, `licence_charges_form.totdeduction`, 0))), true)
															}
														</b>
													</td>
												</tr>
											</tbody>
										</table>
									</PaddingLessUICard>
								</FormWrapper>
							</div>
						</div>
					</Fragment>
				) : (null)
			}

		</FormWrapper>
	);
};

export { CarnetLicenceElement };