import React, { useState, Fragment, useContext, useEffect } from "react";
import { InputButton } from "../../../../ui-components/ui-elements/form/index";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { MenuWrapper, MenuItemWrapper } from "../../../../ui-components/ui-elements/lists-and-menus/ListsAndMenus";
import { LicenceTypeChangeUI, LicenceChageSubmitBtn } from "../../../request-for-approval/pages/LicenceTypeChange";
import { AddVehicleBtn, AddVehicleUI } from "../../../request-for-approval/pages/AddVehicle";
import { TransferVehicleUI, VehicleRequestSubmitBtn } from "../../../request-for-approval/pages/TransferVehicle";
import { VehicleStatusChangeUI, StatusSubmitBtn } from "../../../request-for-approval/pages/VehicleStatusChange";
import { UpdateVehicleBtn, UpdateVehicleUI } from "../../../request-for-approval/pages/UpdateVehicle";
import { ReverseLastTransactionUI, ReverseTransactionsSubmitBtn } from "../../../request-for-approval/pages/ReverseLastTransaction";
import { LiceneceWaveOffUI, WaveOffSubmitBtn } from "../../../request-for-approval/pages/LicenceWaveOff";
import { SetEffectiveDateUI, SetDateSubmitBtn } from "../../../request-for-approval/pages/SetEffectiveDate";
import { RoutePermitUI } from "../../../request-for-approval/pages/RoutePermiteFine";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { OtherAdditionsDeductionUI, OtherADSubmitBtn } from "../../../request-for-approval/pages/OtherAdditionsDeductions";
import { AddLicenceUI } from "../../../request-for-approval/pages/AddLicence";
import { CancelLcnSubmitBtn, CancelLicenceUI } from "../../../request-for-approval/pages/CancelLicence";
import { RequestLicenceInvalidDocUI, InvalidDocumentsSubmitButton } from "../../../request-for-approval/pages/RequestLicenceInvalidDoc";
import { RequestLicenceArrearsUI, LicenceArrearsSubmitButton, } from "../../../request-for-approval/pages/RequestLicenceArrears";
import { LicenceReprintUI, LicenceReprintSubmitButton } from "../../../request-for-approval/pages/LicenceReprint";
import { ChangeSerialNumberUI, ChangeSerialSubmitButton } from "../../../request-for-approval/pages/ChangeSerialNumber";
import { LcnSubmitBtn } from "../../../request-for-approval/pages/includes/AddLicenceUI";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
// import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AddDealer, AddDealerBtn } from "../../../request-for-approval/pages/AddDealer";
import { UpdateDealerBtn } from "../../../request-for-approval/pages/UpdateDealer";
import { vehiclePaymentHistoryAPI, AdditionDeductionListAPI } from "../../../../../config/apiUrl.config";
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { getNonUserConfigDaysAPI,getTrxnDataByTrxnIdFromCashierTrxn, getWfArrearsConfigYearsAPI, getHolidayRangesAPI, licenceInvalidDocuments } from "../../../../../config/apiUrl.config";
import { isEmpty } from "lodash";
import { permissions } from "../../../../../config/permission.config"; 
const emptyFn = (...para) => undefined;
const RequestFor = ({ sessionId, counterId, isCarnet = false, refreshMainScreenDataFn = emptyFn }) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const dtaset = isCarnet ? _get(coreState, `dataTableResponses.carnetPaymentHistoryAPI`, undefined) : _get(coreState, `dataTableResponses.${vehiclePaymentHistoryAPI.key}`, undefined)
  const newDataSet = dtaset && dtaset.results && dtaset.results.length !== 0 ? dtaset.results.sort((a, b) => {
    const date1 = new Date(a['tx_date_time'])
    const date2 = new Date(b['tx_date_time'])
    return date2 - date1
  }) : []

  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );

  useEffect(() => {
    getNonUserConfigDays()
    getWfArrearsConfigYears()
    getLtestInvalidDoc()
    getCashierTrxnDetails()
    if(_get(formState, "latest_licence_data.data.current_licence_status", null) === "EX") {
      getHolidayRanges()
      workingDaysBetweenDates(d, today);
    }
    
  }, [_get(formState, "latest_licence_data.data.valid_to", null)])

  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  

  const isMigratedLicence = _get(formState, "latest_licence_data.data.migrated", null) === 1 ? true : false
  const vehicleOwnerProvinceId = _get(formState, "latest_licence_data.data.vehicleData.province_id", null)
  const isLicenceValid = _get(formState, "latest_licence_data.data.current_licence_status", null) === "VA" ? true : false
  
  const licenceIssuedServiceId = _get(formState, "latest_licence_data.data.issued_service_id", null)
  const paymentCompleteTrxnId = _get(formState, "latest_licence_data.data.payment_completed_txn_id", null)
  const currentUserServiceId = _get(authStatus, "authUser.service_id", null);

  const getNonUserConfigDays = (props) => {
    callApi(`${getNonUserConfigDaysAPI.url}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          // value_type: "WRK"
          if(result.data.data && result.data.data.value_type === "WRK") {
            formAction.changeInputFn('isWorkindDays', "data1", "", true);
            formAction.changeInputFn('non_user_admin_config', "data1", "", result.data.data);
          } else {
            formAction.changeInputFn('isWorkindDays', "data1", "", false);
            formAction.changeInputFn('non_user_admin_config', "data1", "", result.data.data);
            // console.log('REEE****1111DDD===>1111', result);
          }
          
        }
      });
  }
  
  const getWfArrearsConfigYears = (props) => {
    callApi(`${getWfArrearsConfigYearsAPI.url}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('DIFF2', result);
          formAction.changeInputFn('wf_arrears_admin_config', "data1", "", result.data.data);
        }
      });
  }

  const getLtestInvalidDoc = (props) => {
    callApi(`${licenceInvalidDocuments.url}/get-latest-documents/${vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        console.log('REEESS', result, error);
        if (!error) {
          // console.log('REEE****1111DDD===>', result);
          formAction.changeInputFn('invalid_doc_latest', "data", "", result.data.data);
        }
      });
  }

  const getCashierTrxnDetails = (props) => {
    callApi(`${getTrxnDataByTrxnIdFromCashierTrxn.url}/${paymentCompleteTrxnId}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        console.log('REEES', result, error);
        if (!error) {
          // console.log('REEE****1111DDD===>', result);
          formAction.changeInputFn('cashier_trxn', "data", "", result.data.data);
        }
      });
  }

  
  const appliedDate = new Date().setHours(0, 0, 0, 0)

  const parseDate = (input) => {
    var parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
  }

  const holidayWithinRange = (fromDate, toDate, appliedDate, holidayData) => {
    //fromdate- startindate
    //todate - fromdate + fine excemotions
    //hoildays = holiday array
    console.log('DDD==>', fromDate);
    let numberOfDays = 0;
    let holidays = [];
    const getDateAsDate = parseDate(fromDate)
    const endDate = new Date(toDate);
    const strdate = new Date(getDateAsDate.getTime());
    strdate.setUTCHours(0, 0, 0, 0);      

    for (let day = new Date(strdate); day < toDate && day <= appliedDate; day.setDate(day.getDate() + 1)) {
      let holidayFound = false;
      console.log('REEE****1111DDD===>000044', day, toDate, fromDate, appliedDate);
      for (const hDrange of holidayData) {
        
        const rangeFrom = new Date(hDrange.from_date);
        const rangeTo = new Date(hDrange.to_date); 
        if (rangeFrom <= day && rangeTo >= day) {
          holidayFound = true;          
          break;
        }
      }

      if (holidayFound) {
        holidays.push(new Date(day));
        numberOfDays += 1;
      }
    }


    return {
      numberOfDays: numberOfDays,
      holidays: holidays,
    }

  }


  const getHolidayRanges = (props) => {
    // const holidayReqDto = {
    //   pdmt_id: pdmtId,
    //   authority_id: null,
    //   from_date: _get(formState, "licence_validity_period.startDate", null),
    //   to_date: new Date()
    // }

    callApi(`${getHolidayRangesAPI.url}?pdmt_id=${pdmtId}&authority_id=null&from_date=${_get(formState, "latest_licence_data.data.valid_to", null)}&to_date=${new Date()}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send(async (error, result) => {
        if (!error) {
          const holidayDetailsObj = [];
          if(_get(formState, "latest_licence_data.data.valid_to", null) !== null) {
            if (result.data.data.length > 0) {
              for (let obj of result.data.data) {
                let holidayRang = {
                  from_date: obj.from_date,
                  to_date: obj.to_date
                };
          
                holidayDetailsObj.push(holidayRang);
              }
              console.log('REEE****1111DDD===>56599', holidayDetailsObj);
              const retrnData = await holidayWithinRange(_get(formState, "latest_licence_data.data.valid_to", null), new Date(), appliedDate, holidayDetailsObj)
              console.log('REEE****1111DDD===>565', retrnData);
              // const day = d1.getDay();
              // if ((day === 6) || (day === 0)) {
              const findingDaysWithoutWeekendsInARange = retrnData && retrnData.holidays.length !== 0 && retrnData.holidays.map(item => {
                const dd = (new Date(item).getDay() === 6 || new Date(item).getDay() === 0) ? item : null
                return dd 
              })
              console.log('REEE****1111DDD===>5651', findingDaysWithoutWeekendsInARange);

              const daysWithoutWeekendsInARange = findingDaysWithoutWeekendsInARange  && findingDaysWithoutWeekendsInARange.length > 0 && findingDaysWithoutWeekendsInARange.filter(day => day !== null)
              console.log('REEE****1111DDD===>5652', daysWithoutWeekendsInARange);

              const noOfWeekEndDaysInRange = daysWithoutWeekendsInARange && daysWithoutWeekendsInARange.length 
              console.log('REEE****1111DDD===>5653', noOfWeekEndDaysInRange);


              retrnData.numberOfDays = retrnData.numberOfDays - noOfWeekEndDaysInRange
              console.log('REEE****1111DDD===>5654', retrnData);

              formAction.changeInputFn('holiday_range_data', "data1", "", retrnData);
            } else {
              console.log('REEE****1111DDD===>2323', result);
            }
          }
        }
      });
  }


  let isNotSameDayTransaction = false




  const createYear = (newDataSet && newDataSet.length !== 0) ? new Date(newDataSet[0].tx_date_time).getFullYear() : null
  const createMonth = (newDataSet && newDataSet.length !== 0) ? new Date(newDataSet[0].tx_date_time).getMonth() : null
  const createDay = (newDataSet && newDataSet.length !== 0) ? new Date(newDataSet[0].tx_date_time).getDate() : null

  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const currentDay = new Date().getDate()

  if (
    createYear !== currentYear ||
    createMonth !== currentMonth ||
    createDay !== currentDay) {
    isNotSameDayTransaction = true
  } else {
    isNotSameDayTransaction = false
  }
  // permissions.NONE.permissions
  
  const addVehicle_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_ADD_VEHICLE_ADD.permissions[0]) ? true : false
  const setEffective_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_SET_EFFECTIVE_DATE_ADD.permissions[0]) ? true : false
  const changeSerial_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_CHANGE_SERIAL_NUMBER_ADD.permissions[0]) ? true : false
  const licenceWaive_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_LICENCE_WAIVE_OFF_ADD.permissions[0]) ? true : false
  const updateVehicle_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_UPDATE_VEHICLE_ADD.permissions[0]) ? true : false
  const InvalidDoc_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_REQUEST_LICENCE_INVALID_DOCUMENTS_ADD.permissions[0]) ? true : false
  const licenceArrear_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_REQUEST_LICENCE_LICENCE_WITH_ARREARS_ADD.permissions[0]) ? true : false
  const vehicleTransfer_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_VEHICLE_TRANSFER_ADD.permissions[0]) ? true : false
  const vehicleChange_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_VEHICLE_STATUS_CHANGE_ADD.permissions[0]) ? true : false
  const cancelLicence_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_CANCEL_LICENCE_RECEIPT_TRANSACTION_ADD.permissions[0]) ? true : false
  const LicenceReprint_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission ===  permissions.WF_LICENCE_RECEPT_REPRINT_ADD.permissions[0]) ? true : false
  const ReverseLastTransaction_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_REVERSE_LAST_TRANSACTION_ADD.permissions[0]) ? true : false
  const otherAddDed_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_OTHER_ADDITION_DEDUCTION_ADD.permissions[0]) ? true : false
  const AddLicenceReceipt_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_ADD_LICENCE_RECEIPT_TRANSACTION_ADD.permissions[0]) ? true : false
  const LicenceTypeChange_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_LICENCE_TYPE_CHANGE_ADD.permissions[0]) ? true : false
  const licencedAuthorityId = _get(formState, 'latest_licence_data.data.issued_service_id', null)
  const licencedPdmtId = _get(formState, 'latest_licence_data.data.issued_pdmt', null)
  const isLstLicenceMigrated = (_get(formState, 'latest_licence_data.data.migrated', null) === 1) ? true : false
  const loggedInUserAuthorityId = _get(authStatus, "authUser.service_id", "")
  const licencedCarnetAuthorityId = _get(formState, 'latest_licence_data.data.issued_service_id', null)

  const searchFormKey = "vehicle_search_form";
  const pendingWorkflowFormKey = "pending_workflow_key";
  let data = [];
  data = _get(formState, `${pendingWorkflowFormKey}.workflows`, [])
  const changeVehicleStatus = data && data.length !== 0 ? data.filter(item => item.request_type === "chng_vcl_status").length === 0 ? true : false : true
  const licenceInvalidDoc = data && data.length !== 0 ? data.filter(item => item.request_type === 'lcn_w_invalid_doc').length === 0 ? true : false : true
  const licencetypechange = data && data.length !== 0 ? data.filter(item => item.request_type === 'chng_lcn_type').length === 0 ? true : false : true
  const changeSerialNum = data && data.length !== 0 ? data.filter(item => item.request_type === 'chng_se_num').length === 0 ? true : false : true
  const updateVehicle = data && data.length !== 0 ? data.filter(item => item.request_type === 'update_vcl').length === 0 ? true : false : true
  const setEffectiveDate = data && data.length !== 0 ? data.filter(item => item.request_type === 'set_eff_date').length === 0 ? true : false : true
  const licenceReprint = data && data.length !== 0 ? data.filter(item => item.request_type === 'reprint_lcn').length === 0 ? true : false : true
  const reverselastTransaction = data && data.length !== 0 ? data.filter(item => item.request_type === 'reverse_last_txn').length === 0 ? true : false : true
  const vehicleTransfer = data && data.length !== 0 ? data.filter(item => item.request_type === 'vcl_transfer').length === 0 ? true : false : true
  const licenceArrears = data && data.length !== 0 ? data.filter(item => item.request_type === 'lcn_w_arrears').length === 0 ? true : false : true
  const licenceWaiveOff = data && data.length !== 0 ? data.filter(item => item.request_type === 'waiveoff_lcn').length === 0 ? true : false : true
  const otherAddition = data && data.length !== 0 ? data.filter(item => item.request_type === 'other_ad_ded').length === 0 ? true : false : true
  const canelLicenceTransaction = data && data.length !== 0 ? data.filter(item => item.request_type === 'cncl_lcn_r_txn').length === 0 ? true : false : true
  const addLicenceTransaction = data && data.length !== 0 ? data.filter(item => item.request_type === 'add_lcn_r_txn').length === 0 ? true : false : true



  const isVehicleExist = _get(formState, 'vehicle_search_form.data', null) && addVehicle_permission && _get(formState, 'vehicle_search_form.data.vehicle_no', null) === _get(formState, 'vehicle_search_form.searchKey', null)?.trim() ? true : false
  const isLicenceExpired = _get(formState, 'latest_licence_data.data', null) && _get(formState, 'latest_licence_data.data.valid_to', null) !== null && new Date(_get(formState, 'latest_licence_data.data.valid_to', null)) < new Date() ? true : false
  // _get(formState, `licence_charges_form.currentLicenceStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; TO &nbsp;&nbsp; {_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : ""
  const arrearsStartYear = _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL' ? 
  (_get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null) !== null  ? 
  new Date(_get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null)).getFullYear() : 
  new Date(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', null)).getFullYear()) :
  _get(formState, `licence_charges_form.arrearsStartDate`, null) !== null && _get(formState, `licence_charges_form.arrearsStartDate`, null) !== 0 ? 
  new Date(_get(formState, `licence_charges_form.arrearsStartDate`, null)).getFullYear() : _get(formState, `licence_validity_period.startDate`, null) !== null &&
  new Date(_get(formState, `licence_validity_period.startDate`, null)).getFullYear()

  const arrearsEndYear = _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL' ? new Date().getFullYear() : 
  _get(formState, `licence_charges_form.arrearsEndDate`, null) !== null && _get(formState, `licence_charges_form.arrearsEndDate`, null) !== 0 ?
  new Date(_get(formState, `licence_charges_form.arrearsEndDate`, null)).getFullYear() : _get(formState, `licence_validity_period.firstEnddDate.requested_licence_end_date`, null) !== null &&
  new Date(_get(formState, `licence_validity_period.firstEnddDate.requested_licence_end_date`, null)).getFullYear()
  console.log('ARREARS', arrearsStartYear, arrearsEndYear);
  const diff = Number(arrearsEndYear) - Number(arrearsStartYear)
  const isInsuranceValid = _get(formState, `insurance_form`, null) !== null ? (_get(formState, `insurance_form.isInsurance`, null) ? true : false) : null
  const isVetValid = _get(formState, `vet_certificate_form`, null) !== null ? (_get(formState, `vet_certificate_form.isVet`, null) ? true : false) : null
  const isVftValid = _get(formState, `vft_certificate_form`, null) !== null ? (_get(formState, `vft_certificate_form.isfitness`, null) ? true : false) : null
  const isVrpValid = _get(formState, `vrp_form`, null) !== null ? (_get(formState, `vrp_form.isroutePerm`, null) ? true : false) : null
  
  const isSameAuthority = isCarnet ? (licencedCarnetAuthorityId === loggedInUserAuthorityId ? true : false) : (licencedAuthorityId === loggedInUserAuthorityId ? true : false)
  const cancelLicenceValidity = isCarnet ? (licencedCarnetAuthorityId === loggedInUserAuthorityId ? true : false) : 
  (isLstLicenceMigrated && (licencedPdmtId === pdmtId)) ? true : (licencedAuthorityId === loggedInUserAuthorityId ? true : false)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [getState, setState] = useState({
    searchKey: "",
    showMenu: false,
    anchorEl: null,
    modelType: null,
    showModel: false,
  });

  const authorityTypeCode = _get(
    authStatus,"authUser.authority_type_code", null
  );

  const vehicleStatus = _get(
    formState,
    "vehicle_search_form.data.vehicle_status",
    null
  );
  
  const isWebAuthority = authorityTypeCode !== null &&
  authorityTypeCode === "WLA" ? 
  true : false;


  console.log('isWebAuthority', authorityTypeCode, isWebAuthority);
  
  const currentLicenceDate = _get(formState, "latest_licence_data.data.valid_to", null) !== null ? _get(formState, "latest_licence_data.data.valid_to", null) : 
  _get(formState, "licence_validity_period.startDate", null) !== null ? _get(formState, "licence_validity_period.startDate", null) : new Date()
  const nonUserConfigDays = Number(_get(formState, "non_user_admin_config.data1.value", null))
  const wfArrearsConfigYears = Number(_get(formState, "wf_arrears_admin_config.data1.value", null))
  const holidays = _get(formState, "holiday_range_data", null) !== null ? _get(formState, "holiday_range_data.data1.numberOfDays", null) : 0
  const isWorkingDays = _get(formState, "isWorkindDays.data1", null)
  const weekendDays = _get(formState, "weekends_in_range.days", null)

  const invalidDocValidityStartDate = (_get(formState, "invalid_doc_latest.data", null) !== null &&
  _get(formState, "invalid_doc_latest.data", null) !== 'No approved workflows.' && 
  _get(formState, "invalid_doc_latest.data", null) !== 'No invalid doc workflows.') ? _get(formState, "invalid_doc_latest.data.current_licence_starting_date", null) : null
    console.log('REEESS22', new Date(invalidDocValidityStartDate));
  ///
  const lcnValidityFrom = _get(formState, "licence_validity_period.startDate", null) !== null ? _get(formState, "licence_validity_period.startDate", null) : null
  
  const originalDate = invalidDocValidityStartDate !== null ? new Date(invalidDocValidityStartDate) : new Date()
  const newYear = originalDate.getFullYear() + 1;

  const newDate = new Date(originalDate);
  newDate.setFullYear(newYear);
///
  const invalidDocValidityEndDate = invalidDocValidityStartDate !== null ? newDate : originalDate

  const isInvalidDocValid = ((new Date() > new Date(invalidDocValidityEndDate)) || invalidDocValidityStartDate === null || (new Date(invalidDocValidityEndDate) < new Date(lcnValidityFrom))) ? true : false

  console.log('REEESS1', invalidDocValidityStartDate, invalidDocValidityEndDate, isInvalidDocValid, new Date(), new Date(invalidDocValidityEndDate));

  const changeSerialValidity = (_get(formState, "latest_licence_data.data.migrated", null) === 1 &&
  (_get(formState, "latest_licence_data.data.issued_pdmt", null) === pdmtId)) ? true : (_get(formState, "latest_licence_data.data.migrated", null) === 0 && 
  (_get(formState, "latest_licence_data.data.issued_service_id", null) === _get(authStatus, "authUser.service_id", "")) || (_get(formState, "latest_licence_data.data.arrears_only", null) === 1 && 
  _get(formState, "latest_licence_data.data.manual_receipt_number", null) !== null) &&
  (_get(formState, "cashier_trxn.data.counter_id", null) === counterId)) ? true : false

  const today = new Date().setHours(0,0,0,0)
  const d = new Date(currentLicenceDate)
  const greaterThan7Days = +today > +d + ((isWorkingDays !== null && isWorkingDays) ? (nonUserConfigDays + holidays + weekendDays) : nonUserConfigDays) * 24 * 60 * 60 * 1000

  //date holidYS

  const workingDaysBetweenDates = (date1, date2) => {
    const d1 = new Date(date1);
    d1.setDate(d1.getDate() + 1);
    const d2 = new Date(date2); 
        let days = 0
    while (d1 < d2) {
        const day = d1.getDay();
        if ((day === 6) || (day === 0)) {
            days++
            const nextDate = d1; // if one weekend is found, check the next date
            nextDate.setDate(d1.getDate() + 1); // set the next date
            const nextDay = nextDate.getDay(); // get the next day
            if ((nextDay === 6) || (nextDay === 0)) {
                days++; // if next day is also a weekend, return true
            }
        }
        d1.setDate(d1.getDate() + 1);
    }
    formAction.changeInputFn('weekends_in_range', "days", "", days);
}
             
  
  ///////////

  // useEffect(() => {
  //   console.log('DDD---NN', workingDaysBetweenDates(d, today)); 
  // }, [d])



  const isBlackListedOrArchived = (vehicleStatus === 'ACH' || vehicleStatus === 'BLK') ? true : false

  const isNotValidLicence = 
  _get(formState, 'latest_licence_data.data.vehicleData.current_license_type.code', null) === 'NU' || 
  ((_get(formState, 'latest_licence_data.data.current_licence_status', null) === 'EX' && greaterThan7Days) ||
    _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NA')
     ? true : false

  const getVehicleStatusCode = () => {
    let vehiclestatusCode = 'INE'
    vehiclestatusCode = _get(formState, `${searchFormKey}.data.vehicle_status`,)
    if ((_get(formState, `${searchFormKey}.data.gross_weight`, 0) === null || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === '0.00')
      && (_get(formState, `${searchFormKey}.data.tare_weight`, 0) === null || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === '0.00')
    ) {
      vehiclestatusCode = "INE"
    }

    if (_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length > 0) {
      vehiclestatusCode = "INE"
    }

    if (_get(formState, `${searchFormKey}.data.fuel_type.name`,) === 'NOT STATED' && _get(formState, `${searchFormKey}.data.vehicle_class.prefix`,) !== 'TR' || _get(formState, `${searchFormKey}.data.fuel_type`,) === null || _get(formState, `${searchFormKey}.data.fuel_type`,) === "") {
      vehiclestatusCode = "INE"
    }

    return vehiclestatusCode
  }

  const vehicleStatusCarnetGetter = getVehicleStatusCode();
  const vehicleStatusCarnet = (vehicleStatusCarnetGetter === 'INE' || vehicleStatusCarnetGetter === 'ELI') ? true : false

  const getModelTitle = () => {
    if (getState.modelType === "add-vehicle") {
      return "Add Vehicle";
    } else if (getState.modelType === "licence_type_change") {
      return "Licence Type Change";
    } else if (getState.modelType === "transfer_vehicle") {
      return "Transfer Vehicle";
    } else if (getState.modelType === "change-serial") {
      return "Change Serial Number";
    } else if (getState.modelType === "update-vehicle") {
      return "Update Vehicle";
    } else if (getState.modelType === "vehicle-status-change") {
      return "Vehicle Status Change";
    } else if (getState.modelType === "reverse-last-transaction") {
      return "Reverse Last Transaction";
    } else if (getState.modelType === "request-licence-invalid-doc") {
      return "Request Licence - Invalid Documents";
    } else if (getState.modelType === "request-licence-arrears") {
      return "Request Licence - Licence with Arrears";
    } else if (getState.modelType === "licence-reprint") {
      return "Licence / Receipt Reprint";
    } else if (getState.modelType === "set-effective-date") {
      return "Set Effective Date";
    } else if (getState.modelType === "licence-wave-off") {
      return "Licence Waive-Off";
    } else if (getState.modelType === "route-permit-fine") {
      return "Route Permit Fine";
    } else if (getState.modelType === "other-additions-deduction") {
      return "Other Additions / Deductions";
    } else if (getState.modelType === "cancel-licence") {
      return "Cancel Licence / Receipt Transaction";
    } else if (getState.modelType === "add-licence") {
      return "Add Licence / Receipt Transaction";
    } else {
      return "";
    }
  };

  const getModelUI = (props) => {
    if (getState.modelType === "add-vehicle") {
      return <AddVehicleUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "licence_type_change") {
      return <LicenceTypeChangeUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "transfer_vehicle") {
      return <TransferVehicleUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "update-vehicle") {
      return <UpdateVehicleUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "vehicle-status-change") {
      return <VehicleStatusChangeUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "reverse-last-transaction") {
      return <ReverseLastTransactionUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "request-licence-invalid-doc") {
      return <RequestLicenceInvalidDocUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "request-licence-arrears") {
      return <RequestLicenceArrearsUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "licence-reprint") {
      return <LicenceReprintUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "change-serial") {
      return <ChangeSerialNumberUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "set-effective-date") {
      return <SetEffectiveDateUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "licence-wave-off") {
      return <LiceneceWaveOffUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "route-permit-fine") {
      return <RoutePermitUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "other-additions-deduction") {
      return <OtherAdditionsDeductionUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "cancel-licence") {
      return <CancelLicenceUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    } else if (getState.modelType === "add-licence") {
      return <AddLicenceUI formKey={getState.modelType} uiType={'request'} isCarnet={isCarnet} />;
    }
    else if (getState.modelType === "add_dealer") {
      return <AddDealer formKey={getState.modelType} uiType={'request'} />;
    }
    else {
      return null;
    }
  };


  const getBtn = (props) => {


    if (getState.modelType === "add-vehicle") {
      return <AddVehicleBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "licence_type_change") {
      return <LicenceChageSubmitBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "transfer_vehicle") {
      return <VehicleRequestSubmitBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "update-vehicle") {
      return <UpdateVehicleBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "vehicle-status-change") {
      return <StatusSubmitBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "reverse-last-transaction") {
      return <ReverseTransactionsSubmitBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "request-licence-invalid-doc") {
      return <InvalidDocumentsSubmitButton sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "request-licence-arrears") {
      return <LicenceArrearsSubmitButton sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "licence-reprint") {
      return <LicenceReprintSubmitButton sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "set-effective-date") {
      return <SetDateSubmitBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    } else if (getState.modelType === "licence-wave-off") {
      return <WaveOffSubmitBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
      // } else if (getState.modelType === "route-permit-fine") {
      //   return <RoutePermitUI />;
      // } else if (getState.modelType === "other-additions-deduction") {
      //   return <OtherAdditionsDeductionUI />;
      // } else if (getState.modelType === "cancel-licence") {
      //   return <CancelLicenceUI />;
    }
    else if (getState.modelType === "other-additions-deduction") {
      return <OtherADSubmitBtn sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    }
    else if (getState.modelType === "cancel-licence") {
      return <CancelLcnSubmitBtn counterId={counterId} sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    }
    else if (getState.modelType === "add-licence") {
      return <LcnSubmitBtn counterId={counterId} sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    }
    else if (getState.modelType === "change-serial") {
      return <ChangeSerialSubmitButton counterId={counterId} sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    }
    else if (getState.modelType === "add-dealer") {
      return <AddDealerBtn counterId={counterId} sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    }
    else if (getState.modelType === "update-dealer") {
      return <UpdateDealerBtn counterId={counterId} sessionId={sessionId} {...props} isCarnet={isCarnet} />;
    }
    else {
      return null;
    }
  };

    return (
      <Fragment>
        {isBlackListedOrArchived ?
          <Fragment>
            <span
              onClick={(event) =>
                setState({
                  ...getState,
                  anchorEl: event.currentTarget,
                  showMenu: true,
                })
              }
            >
              <InputButton
                elementWrapperStyle={""}
                btnName="submit"
                btnText={"Request For"}
              />
              <InputButton
                elementWrapperStyle={""}
                btnName="submit"
                overrideTextToIcon={<i class="fas fa-caret-down"></i>}
              />
            </span>
            <MenuWrapper
              anchorEl={getState.anchorEl}
              open={getState.showMenu}
              onCloseFn={() =>
                setState({
                  ...getState,
                  anchorEl: null,
                  showMenu: false,
                })
              }
              elementStyle={"requestFor"}
            >
  
              {!isWebAuthority && (pdmtId == vehicleOwnerProvinceId) && changeVehicleStatus && <MenuItemWrapper
                elementStyle={"profileMenuItem"}
                menuKey="delegation"
                onClickFn={() =>
                  setState({
                    ...getState,
                    modelType: "vehicle-status-change",
                    showModel: true,
                    anchorEl: null,
                    showMenu: false,
                  })
                }
              >
                <p>Vehicle Status Change</p>
              </MenuItemWrapper>}
                {console.log('REEESS3', isInvalidDocValid)}
              {isInvalidDocValid && !isWebAuthority && !isLicenceValid && (pdmtId == vehicleOwnerProvinceId) && licenceInvalidDoc && !isCarnet && (isInsuranceValid === false || isVetValid === false || isVftValid === false || isVrpValid === false) && <MenuItemWrapper
                elementStyle={"profileMenuItem"}
                menuKey="delegation"
                onClickFn={() =>
                  setState({
                    ...getState,
                    modelType: "request-licence-invalid-doc",
                    showModel: true,
                    anchorEl: null,
                    showMenu: false,
                  })
                }
              >
                <p>Request Licence - Invalid Documents</p>
              </MenuItemWrapper>}
            </MenuWrapper>
            <DialogPopup
              dialogTitle={getModelTitle()}
              isFullScreen={true}
              isOpen={getState.showModel}
              onClosePopupFn={() =>
                setState({
                  ...getState,
                  modelType: null,
                  showModel: false,
                })
              }
              isCustomButton={true}
              customButtonComponent={getBtn}
              customButtonComponentProps={{ refreshMainScreenDataFn }}
              isConfirmationPopup={true}
            >
              {getModelUI()}
            </DialogPopup>
          </Fragment>
  
          :
          <Fragment>
            {!isEmpty(_get(formState, 'vehicle_search_form.searchKey', null)?.trim())?
            <span
              onClick={(event) =>
                setState({
                  ...getState,
                  anchorEl: event.currentTarget,
                  showMenu: true,
                })
              }
            >
              <InputButton
                elementWrapperStyle={""}
                btnName="submit"
                btnText={"Request For"}
              />
              <InputButton
                elementWrapperStyle={""}
                btnName="submit"
                overrideTextToIcon={<i class="fas fa-caret-down"></i>}
              />
            </span>:" "}
            <MenuWrapper
              anchorEl={getState.anchorEl}
              open={getState.showMenu}
              onCloseFn={() =>
                setState({
                  ...getState,
                  anchorEl: null,
                  showMenu: false,
                })
              }
              elementStyle={"requestFor"}
            >
              {!isWebAuthority && !isVehicleExist && addVehicle_permission ? <MenuItemWrapper
                elementStyle={"requestForMenuItem"}
                menuKey="delegation"
                onClickFn={() =>
                  setState({
                    ...getState,
                    modelType: "add-vehicle",
                    showModel: true,
                    anchorEl: null,
                    showMenu: false,
                  })
                }
              >
                <p>Add Vehicle</p>
              </MenuItemWrapper>
                :
                <Fragment>
                  {!isWebAuthority && (pdmtId == vehicleOwnerProvinceId) && changeVehicleStatus && vehicleChange_permission &&
                    <MenuItemWrapper
                      elementStyle={"profileMenuItem"}
                      menuKey="delegation"
                      onClickFn={() =>
                        setState({
                          ...getState,
                          modelType: "vehicle-status-change",
                          showModel: true,
                          anchorEl: null,
                          showMenu: false,
                        })
                      }
                    >
                      <p>Vehicle Status Change</p>
                    </MenuItemWrapper>}
                  {isInvalidDocValid && !isWebAuthority && !isLicenceValid && (pdmtId == vehicleOwnerProvinceId) && licenceInvalidDoc && InvalidDoc_permission && !isCarnet && (isInsuranceValid === false || isVetValid === false || isVftValid === false || isVrpValid === false) &&
  
                    <MenuItemWrapper
                      elementStyle={"profileMenuItem"}
                      menuKey="delegation"
                      onClickFn={() =>
                        setState({
                          ...getState,
                          modelType: "request-licence-invalid-doc",
                          showModel: true,
                          anchorEl: null,
                          showMenu: false,
                        })
                      }
                      >
                      <p>Request Licence - Invalid Documents</p>
                    </MenuItemWrapper>
                    }
                    
                  {!isWebAuthority && licencetypechange && (!isCarnet && !isNotValidLicence) && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "licence_type_change",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Licence Type Change</p>
                  </MenuItemWrapper>}
                    {console.log('VALIDD--', licenceIssuedServiceId, currentUserServiceId, changeSerialNum, changeSerial_permission)}
                  {changeSerialNum && changeSerialValidity && changeSerial_permission && (isWebAuthority || (isSameAuthority && !isCarnet) || (isCarnet && vehicleStatusCarnet && isSameAuthority)) && 
                  <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "change-serial",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Change Serial Number</p>
                  </MenuItemWrapper>
                  }
  
                  {!isWebAuthority && (pdmtId == vehicleOwnerProvinceId) && updateVehicle && updateVehicle_permission && (!isCarnet || (isCarnet && vehicleStatusCarnet)) && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "update-vehicle",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Update Vehicle</p>
                  </MenuItemWrapper>}
                  {!isWebAuthority && setEffectiveDate && setEffective_permission && (!isLicenceExpired || (isCarnet && vehicleStatusCarnet)) && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "set-effective-date",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Set Effective Date</p>
                  </MenuItemWrapper>}
                  {console.log('VALIDD22--', _get(formState, "latest_licence_data.data.reprint"), licenceReprint, LicenceReprint_permission)}

                  {licenceReprint &&  LicenceReprint_permission && _get(formState, "latest_licence_data.data.reprint") === 0 && (isWebAuthority || (isSameAuthority && !isCarnet) || (isCarnet && vehicleStatusCarnet && isSameAuthority)) &&
                    <MenuItemWrapper
                      elementStyle={"profileMenuItem"}
                      menuKey="delegation"
                      onClickFn={() =>
                        setState({
                          ...getState,
                          modelType: "licence-reprint",
                          showModel: true,
                          anchorEl: null,
                          showMenu: false,
                        })
                      }
                    >
                      <p>Licence / Receipt Reprint</p>
                    </MenuItemWrapper>
                  }
                  {!isMigratedLicence && !isWebAuthority && (licenceIssuedServiceId == currentUserServiceId) && reverselastTransaction && ReverseLastTransaction_permission && ((!isNotSameDayTransaction && isSameAuthority && !isCarnet) || (isCarnet && vehicleStatusCarnet && isSameAuthority)) &&
                    <MenuItemWrapper
                      elementStyle={"profileMenuItem"}
                      menuKey="delegation"
                      onClickFn={() =>
                        setState({
                          ...getState,
                          modelType: "reverse-last-transaction",
                          showModel: true,
                          anchorEl: null,
                          showMenu: false,
                        })
                      }
                    >
                      <p>Reverse Last Transaction</p>
                    </MenuItemWrapper>
                  }
                  {!isWebAuthority && vehicleTransfer && vehicleTransfer_permission && !isCarnet && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "transfer_vehicle",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Vehicle Transfer</p>
                  </MenuItemWrapper>}
                    {console.log('DIFF', diff, wfArrearsConfigYears)}
                  {!isWebAuthority && licenceArrears && licenceArrear_permission && (!isCarnet && (diff > wfArrearsConfigYears)) && 
                  <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "request-licence-arrears",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Request Licence - Licence with Arrears</p>
                  </MenuItemWrapper>
                  }
                  {!isWebAuthority && (pdmtId == vehicleOwnerProvinceId) && licenceWaiveOff && licenceWaive_permission && (!isCarnet) && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "licence-wave-off",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Licence Waive Off</p>
                  </MenuItemWrapper>}
                  {!isWebAuthority && (pdmtId == vehicleOwnerProvinceId) && otherAddition &&  otherAddDed_permission && ((isCarnet && vehicleStatusCarnet) || !isCarnet) && <MenuItemWrapper
                    elementStyle={"requestForMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "other-additions-deduction",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Other Additions / Deductions</p>
                  </MenuItemWrapper>}
                  {!isWebAuthority && canelLicenceTransaction &&  cancelLicence_permission && (!isCarnet && cancelLicenceValidity) && 
                  <MenuItemWrapper
                    elementStyle={"requestForMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "cancel-licence",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Cancel Licence / Receipt Transaction</p>
                  </MenuItemWrapper>
                  }
                  {!isWebAuthority && addLicenceTransaction && AddLicenceReceipt_permission && !isCarnet && 
                  <MenuItemWrapper
                    elementStyle={"requestForMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                      setState({
                        ...getState,
                        modelType: "add-licence",
                        showModel: true,
                        anchorEl: null,
                        showMenu: false,
                      })
                    }
                  >
                    <p>Add Licence / Receipt Transaction</p>
                  </MenuItemWrapper>
                  }
                </Fragment>
              }
  
            </MenuWrapper>
            <DialogPopup
              dialogTitle={getModelTitle()}
              isFullScreen={true}
              isOpen={getState.showModel}
              onClosePopupFn={() => {
                  coreAction.setDataTable(AdditionDeductionListAPI.key, [], 'done', 1, null, null)
                  setState({
                    ...getState,
                    modelType: null,
                    showModel: false,
                  })
                }
              }
              isCustomButton={true}
              customButtonComponent={getBtn}
              customButtonComponentProps={{ refreshMainScreenDataFn }}
              isConfirmationPopup={true}
            >
              {getModelUI()}
            </DialogPopup>
          </Fragment>
  
        }
      </Fragment>
  
  
    );

  
};

export { RequestFor };
