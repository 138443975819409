/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-08 23:23:02
 */

import React, { useContext } from 'react';
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { LimitTextLength } from "../../../../ui-components/ui-elements/common/BaseElements";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { ownerInformationAPI  } from '../../../../../config/apiUrl.config';
import { FormWrapper } from '../../../../ui-components/ui-elements/form';

const OwnerInfoDisplay = () => {
    const [formState] = useContext(FormContext);
    return (
        <FormWrapper
            setGroupName={'viewOwnerInformation'}
            apiUrl={`${ownerInformationAPI.url}/${_get(formState,'vehicle_search_form.searchKey','')}`}
            onRebuildResponseFn={(response) => {
                return {
                    ...response,
                    _onLoad: false
                }
            }}
        >
            <div className="row">
                <div className="col-md-6">
                    <strong>Name: </strong>
                    <LimitTextLength
                        maxLenth={50}
                        displayText={getLabelValue(_get(formState,'viewOwnerInformation.name',null), true)}
                    />
                </div>

                <div className="col-md-6">
                    <strong>Identification Number: </strong>
                    <LimitTextLength
                        displayText={getLabelValue(_get(formState,'viewOwnerInformation.nic',null), true) }
                    />
                </div>

                <div className="col-md-6">
                    <strong>Address Line 1: </strong>
                    <LimitTextLength
                        maxLenth={50}
                        displayText={getLabelValue(_get(formState,'viewOwnerInformation.address_line_1',null), true) }
                    />
                </div>

                <div className="col-md-6">
                    <strong>City: </strong>
                    <LimitTextLength
                        maxLenth={50}
                        displayText={getLabelValue(_get(formState,'viewOwnerInformation.city',null))}
                    />
                </div>

                <div className="col-md-6">
                    <strong>Line 2: </strong>
                    <LimitTextLength
                        maxLenth={50}
                        displayText={getLabelValue(_get(formState,'viewOwnerInformation.address_line_2',null), true)}
                    />
                </div>

                <div className="col-md-6">
                    <strong>Mobile Number 0094: </strong>
                    <LimitTextLength
                        maxLenth={50}
                        displayText={getLabelValue(_get(formState,'viewOwnerInformation.contact_number',null))}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};



export { OwnerInfoDisplay };