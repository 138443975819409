/*
 * @Author: Chamith
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-08-23 13:23:31
 */

import React, {  useContext } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { getVehicleLicensePrintDetailsAPI } from '../../../../../config/apiUrl.config';
import {
	FormWrapper,
} from "../../../../ui-components/ui-elements/form/index";

const LicenceReprintPreview = ( props ) => {
  const [authStatus, authAction] = useContext(AuthContext);
  const formKey = "licenceReprintFormKey";
  const licenceType = props.fromParent.licenceType;
  const [formState] = useContext(FormContext);
  const helperText = [];
  const dataList = [
    "images/licence-preview-empty.jpg",
    "images/file-word.png",
    "images/profile_img.png",
  ];
  const ownerInfo = _get(formState, "viewOwnerInformation", "");
  const vehicleInfo = _get(formState, "vehicle_search_form.data", "");

  const getWeight=(gross,tare)=>{
      if(gross !== 0 && gross !=='0.00'  && gross !=null  && gross !== 0.00 && tare !== 0 && tare !=='0.00'  && tare !=null  && tare !== 0.00){
        return `${gross} kg | ${tare} kg`
      }else if (gross !== 0 && gross !=='0.00'  && gross !=null  && gross !== 0.00) {
        return `${gross} kg`
      }else{
        return `${tare} kg`
      }
  }


  const getAddress=(line1,line2,line3)=>{
    if(line1 && line2 && line3){
      return `${line1} , ${line2} , ${line3} `
    }else if (line1 && line3) {
      return `${line1} , ${line3}`
    }else if (line1 && line2) {
      return `${line1} , ${line2}`
    }
    else if(line1){
      return `${line1}`
    }
    else if(line3){
      return `${line3}`
    }else{
      return ''
    }
}

  const licenceEndType = _get(formState, "licence_validity_period.endDate", 0);
  const licenceValidityPeriodEndDate = _get(formState, `licence_validity_period.endDatesData[${licenceEndType}].value`, null) ;
  

  return (
    dataList.length > 0 && (
        <div  style={{maxWidth:'500px'}}>
        <FormWrapper
        style={{maxWidth:'500px'}}
        setGroupName={formKey}
        apiUrl={`${getVehicleLicensePrintDetailsAPI.url}/?licence_id=${licenceType==="dealer"?props.row.licence_id:props?.row.lcn_id}&template_code=LIC`}
        onRebuildResponseFn={(response) => {
          return {
            licenceData:response
          };
        }}
      >

        <div className="licence-preview-card">
          <img src={dataList[0]} alt={""} />
          <div className="defaultPaddingLeft counter_name">
              {_get(formState, `${formKey}.licenceData.data.lhs_issued_counter`, "")} 
              </div>
          <div className="mainContent">
          <div className="row d-flex justify-content-center">
              <div className="licence-date float-left">
              {_get(formState, `${formKey}.licenceData.data.lhs_licence_year_month`, "")} 
              </div>
            </div>
           
          <div className="row">
              <div className="defaultPaddingLeft licence_number">
              {_get(formState, `${formKey}.licenceData.data.lhs_licence_no`, "")} 
              </div>
            </div>
            <div className="row">
              <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
              {_get(formState, `${formKey}.licenceData.data.lhs_vehicle_type`, "")} 
              </div>
            </div>
            <div className="row">
              <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
                {_get(formState, `${formKey}.licenceData.data.lhs_licence_type`, "")} 
              </div>
            </div>
           

            <div className="col-md-12 col-sm-12  main-container">
            <div className="row owner-name-address-label">
            Vehicle Owner's Name & Address:
              </div>
              <div className="row owner-name">
              {_get(formState, `${formKey}.licenceData.data.rhs_owner_name_line1`, "")} 
              </div>

              <div className="row owner-name-2">
              {_get(formState, `${formKey}.licenceData.data.rhs_owner_name_line2`, "")} 
              </div>

              <div className="row owner-address">
              {getAddress(_get(formState, `${formKey}.licenceData.data.rhs_owner_address_line1`, null),_get(formState, `${formKey}.licenceData.data.rhs_owner_address_line2`, null),_get(formState, `${formKey}.licenceData.data.rhs_owner_address_line3`, null))}
              </div>
     
                <br />
                <div className="amount-container">

              <div className="row"> 
              <div className="floatLeft col-md-3 licence-total-amount-label">
                Amount (Rs.):
                </div>               
            
                <div className="floatLeft col-md-9 licence-amounts-label">
                  Fee +  Arrears + Fine + Additions - Deductions
                </div>
                </div>
                                  
          
                <div className="row"> 
              <div className="floatLeft col-md-3 licence-total-amount">
              {_get(formState, `${formKey}.licenceData.data.lhs_total`, "")}:
                </div>               
            
                <div className="floatLeft col-md-9 licence-amounts">
                {_get(formState, `${formKey}.licenceData.data.lhs_charges_breakdown_line1`, "")} 
                </div>
                </div>
              </div>
              </div>
              <div className="row bottom-label-container">                
                <div className="col-md-7">
                  <div className="validity-period-label">
                  Validity Period:
                  </div>
                  <div className="validity-period-value">
                  {
                    `${_get(formState, `${formKey}.licenceData.data.lhs_valid_from`, "")}  To ${_get(formState, `${formKey}.licenceData.data.lhs_valid_to`, "")}`
                  }
                  </div>
                 </div>
                 <div className="col-md-5">
                  <div className="vet-no-label">
                       VET Number:
                  </div>
                  <div className="vet-no-value">
                  {_get(formState, `${formKey}.licenceData.data.lhs_vet_no`, "")} 
                  </div>
                 </div>
                 
                <div className="row bottom-label-container2">                
                <div className="col-md-7">
                <div className="row" style={{marginLeft:0}}>
                  <div className="unladen-weight-label">
                  Unladen/ Gross Weight:
                  </div>
                  <div className="unladen-weight-value">
                  {_get(formState, `${formKey}.licenceData.data.lhs_weight`, "")} 
                  </div>
                  </div>
                  </div>
           
                 <div className="col-md-5">
                 <div className="row" style={{marginLeft:0}}>
                 <div className="no-of-seats-label">
                       Number of Seats:
                  </div>
                  <div className="no-of-seats-value">
                  {_get(formState, `${formKey}.licenceData.data.lhs_no_of_seats`, "")} 
                  </div>
                  </div>
                 </div>
              </div>
              </div>

              <div className="footer-content">
                <div className="row">
                <div className="col-md-7">
                <div className="signature-place">
                      .......................................
                  </div>
                  <div className="signature-details">
                  Signature/ Designation & Date
                    </div>
                  </div>

                  <div className="col-md-5" style={{marginLeft:'-5px'}}>
                <div className="signature-place">
                {_get(formState, `${formKey}.licenceData.data.lhs_issued_by`, "")} 
                  </div>
                  <div className="signature-details">
                {_get(formState, `${formKey}.licenceData.data.lhs_issued_date`, "")} 
                  </div>
                  </div>
                </div>

              </div>
            </div>


          <div className="helperText">{helperText[0]}</div>
        </div>
      </FormWrapper>
      </div> 
    )
  );
};

export { LicenceReprintPreview };
