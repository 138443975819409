import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { permissions } from '../../../../../config/permission.config';
import { _get, } from "../../../../../helpers/common-helpers/lodash.wrappers";

const Other = () => {
      const [authStatus, authAction] = useContext(AuthContext);
      const ViewPermission = _get(authStatus, 'authUser.permissions', "");
      let FR41, FR42, FR43, FR44
      ViewPermission.forEach(item => {
            if (item.permission === permissions.VIEW_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE.permissions[0]) {
                  FR41 = true
            }
            else if (item.permission === permissions.VIEW_FR42_LOSS_REVENUE_REPORT_ENTIRE.permissions[0]) {
                  FR42 = true
            }
            else if (item.permission === permissions.VIEW_FR43_MANUAL_ENTRY_REPORT_ENTIRE.permissions[0]) {
                  FR43 = true
            }
            else if (item.permission === permissions.VIEW_FR44_REVENUE_LICENCE_RATES_REPORT.permissions[0]) {
                  FR44 = true
            }
      })
      return (
            <DashboardTemplate
                  pageTitle="Other Reports"
                  breadCrumbList={[
                        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
                        {
                              //urlTo: "/report",
                              displayName: "Other Reports List",
                        },
                  ]}
            >
                  <UICard>
                        < ol type="1">
                              <div className="App">
                                    {FR41 &&
                                          <li>
                                                <Link to="/fitness-issuance">
                                                      <strong>Fitness Issuance Report</strong>
                                                </Link>
                                                <br />
                                                <p> This is the Fitness Issuance Report</p>
                                                <br />
                                          </li>
                                    }
                                    {FR42 &&
                                          <li>
                                                <Link to="/loss-revenue">
                                                      <strong>Loss Revenue Report</strong>
                                                </Link>
                                                <br />
                                                <p> This is the Loss Revenue Report</p>
                                                <br />
                                          </li>
                                    }
                                    {FR43 && <li>
                                          <Link to="/manual-entry">
                                                <strong>Manual Entry Report</strong>
                                          </Link>
                                          <br />
                                          <p> This is the Manual Entry Report</p>
                                          <br />
                                    </li>}
                                    {FR44 &&
                                          <li>
                                                <Link to="/revenue-licence-rate">
                                                      <strong>Revenue Licence Rates Report</strong>
                                                </Link>
                                                <br />
                                                <p> This is the Revenue Licence Rates Report</p>
                                          </li>}

                              </div>
                        </ ol>
                  </UICard>
            </DashboardTemplate>

      );
};

export { Other };