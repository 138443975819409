/*
 * @Author: Anjula Karunarathne 
 * @Date: 2021-01-12 14:28:35 
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-13 16:43:08
 */

import React, { Fragment, useContext, useState, useEffect } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import {
      FormWrapper,
      SelectBoxWithState,
      SubmitButton,
      DatePickerWithState,
      InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import { reportGenerateAPI, provincesOfficesListAPI, AuthorityListApi } from "../../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { CoreContext, apiResponseStoringType } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined;
const SearchCriteria = ({
      reportCode,
      formGroupName,
      setReportgeneratedValues = emptyFn,
      onGenerateSubmit = emptyFn,
      onResetClick = emptyFn,
}) => {
      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const [coreState, coreAction] = useContext(CoreContext);

      let datefrom = new Date();
      let dateto = new Date();
      let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
      let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
      let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
      let authorityId = _get(authStatus, 'authUser.authorityId', "");
      let generated_by = _get(authStatus, 'authUser.name', "")
      let role = _get(authStatus, 'authUser.rolePrefix', "")
      let authorityName = '';
      const [getdatalist, setDatalist] = useState(" ");
      useEffect(() => {
            coreAction
                  .sendRequestFn(
                        `${AuthorityListApi.url}?check_user_role=1&sort_by=name|asc`
                  )
                  .method("get")
                  .setInitStoring(apiResponseStoringType.setResponse, {
                        responseKey: AuthorityListApi.key,
                  })
                  .headers(true)
                  .send((error, result) => {
                        if (!error) {
                              if (roleLevelCode === "INT_AU") {
                                    setDatalist(result.data.data.filter((item) => item.id === authorityId));

                              }
                              else {
                                    setDatalist(result.data.data.filter((item) => item.province.id === provinceId))
                              }

                        }
                  });

      }, []);
      

      return (
            <Fragment>
                  <UICard>
                        <FormWrapper setGroupName={formGroupName}
                              formGroupLinkWith={formGroupName}
                              onDestroyUnsetFormObject={true}
                              setFormObject={{
                                    dateFrom: null,
                                    dateTo: null,
                                    province: provinceId,

                              }}
                        >
                              <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                          <SelectBoxWithState
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                apiUrl={provincesOfficesListAPI.url}
                                                keyName={"id"}
                                                valueName={"province.name"}
                                                apiStateKey={provincesOfficesListAPI.key}
                                                emptySelectOptionTxt={provinceName}
                                                labelText="Province"
                                                inputName={"province"}
                                                isDisabled={"true"}

                                          />
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                          <SelectBoxWithState
                                                isRequired={true}
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                emptySelectOptionTxt={" "}
                                                dataList={getdatalist}
                                                keyName={"id"}
                                                valueName={"service.name"}
                                                labelText="Authority"
                                                inputName={"authority"}
                                                onChangeFn={(value) => {
                                                      formAction.changeInputFn(formGroupName, "counter", null);
                                                }}
                                          />
                                    </div>

                              </div>

                              <div className="row">
                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <DatePickerWithState
                                                isRequired={true}
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                labelText="Date From"
                                                inputName={"dateFrom"}
                                                maxDate={setMaxDateFn(_get(formState[formGroupName], "dateTo", null))}
                                                minDate={setminDateRangeFn(_get(formState[formGroupName], "dateTo", null),_get(formState[formGroupName], "dateFrom", null))}
                                          />
                                    </div>
                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <DatePickerWithState
                                                isRequired={true}
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                labelText="Date To"
                                                inputName={"dateTo"}
                                                minDate={setMinDateFn(_get(formState[formGroupName], "dateFrom", null))}
                                                maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "dateFrom", null))}
                                          />
                                    </div>

                                    <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">
                                          <SubmitButton
                                                btnName={"generate"}
                                                btnText={"Generate"}
                                                startIcon="fas fa-wrench"
                                                elementWrapperStyle={"searchBtn"}
                                                formGroupName={formGroupName}
                                                isValidate={true}
                                                validationObject={{
                                                      fileds: {
                                                            dateFrom: "Date From",
                                                            dateTo: "Date To",
                                                            authority: "Authority",
                                                      },
                                                      rules: {
                                                            dateFrom: "required",
                                                            dateTo: "required",
                                                            authority: "required",

                                                      },
                                                      message: {}
                                                }}
                                                callApiObject={{
                                                      isSetHeaders: true,
                                                      method: "get",
                                                      multipart: false,
                                                      onUpload: false,
                                                      responseType: 'text'
                                                }}
                                                apiDataStoringObject={{
                                                      setLoader: true,
                                                      storingType: "API_RESPONSE_LINKED_FORM",
                                                      mergeToSuccessResponse: true,
                                                      mergeToErrorResponse: true
                                                }}
                                                onGetAPIEndPointFn={(formObject) => {

                                                      if (!isEmptyValue(formObject.dateFrom)) {
                                                            datefrom = dateObjectToString(new Date(formObject.dateFrom));
                                                      }

                                                      if (!isEmptyValue(formObject.dateTo)) {
                                                            dateto = dateObjectToString(new Date(formObject.dateTo));
                                                      }
                                                      if (!isEmptyValue(formObject.province)) {
                                                            provinceId = formObject.province
                                                            provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                                      }
                                                      if (!isEmptyValue(formObject.authority)) {
                                                            authorityId = formObject.authority
                                                            authorityName = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.name)
                                                      }


                                                      return {
                                                            url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestFromDate=${datefrom}&report_requestToDate=${dateto}&report_requestProvince=${provinceName}&report_provinceId=${provinceId}&report_authorityId=${authorityId}&report_requestAuthority=${authorityName}&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                                            key: `${reportGenerateAPI.key}`
                                                      }
                                                }}

                                                onChangeRequestBodyFn={(formObject) => {
                                                      return getDataByFormObject(formObject);
                                                }}

                                                onResponseCallBackFn={(error, response,) => {
                                                      if (response?._statue) {
                                                            onGenerateSubmit(response?.data)
                                                            setReportgeneratedValues({
                                                                  datefrom,
                                                                  dateto,
                                                                  provinceId,
                                                                  provinceName,
                                                                  authorityId,
                                                                  authorityName: authorityName,
                                                                  limit: reportDataLimit,
                                                                  offset: reportDataOffset,
                                                                  reportGeneratedUser: generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                                            })
                                                      }


                                                }}
                                          />


                                          <InputButtonWithState
                                                btnText="Reset"
                                                startIcon="fas fa-sync-alt"
                                                elementWrapperStyle={"refreshBtn"}
                                                formGroupName={formGroupName}
                                                mergeToForm={{
                                                      dateFrom: null,
                                                      dateTo: null,
                                                      province: null,
                                                      authority: null

                                                }}
                                                onClickBtnFn={(event) => {
                                                      formAction.setFormErrorFn(formGroupName);
                                                      onResetClick()

                                                }}
                                          />

                                    </div>

                              </div>
                        </FormWrapper>
                  </UICard>




            </Fragment>

      );
};

export { SearchCriteria };
