import React, { Fragment, useContext } from "react";
import { useLocation } from "react-router-dom";

import { DataTable,RawIndexMemo } from "../../../../ui-components/ui-elements/table/DataTable";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { SearchElementOne, SearchElementTwo } from "./includes/SearchElements";
import { sessionCollectionsAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { _getInt } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";


const RowNo = (props) => {
    let index = props.rowIndex;
    return (
        <RawIndexMemo
            prefix='000'
            page_size={_getInt(props.pagingObject, "per_page", 1)}
            current_page={_getInt(props.pagingObject, "current_page", 1)}
            index={index || 0}
        />
    );
  };

const TransactionList = ({sessionId,sessionRowData,currentSessionStartAt}) => {
    const [formState] = useContext(FormContext);
    let location = useLocation();

    const currentSession = currentSessionStartAt && currentSessionStartAt !== '-' ? `${dateObjectToString(currentSessionStartAt,'dd/mm/YY')} - ${dateObjectToString(currentSessionStartAt,'HH:MM')} - Session Still On` : '-'

    const getTotal = (value) => {
        if (value)
            return parseFloat(value);
        else
            return 0;
    };

    const getDateAndSession=()=>{
        let sessionDateString =''
        if(sessionRowData?.start_at && sessionRowData?.end_at ){
            sessionDateString =`   ${dateObjectToString(sessionRowData?.start_at ,'dd/mm/YY')} - ${dateObjectToString(sessionRowData.start_at,'HH:MM') +'-' +dateObjectToString(sessionRowData.end_at,'HH:MM')}`
        }
        return sessionDateString
    }

    const displayAmount = (status, amount) => {
        let value = amount;
        if(status !== 'RE'){
            value = amount < 0 ? `(${thousandSeparator(amount * -1, true)})` : thousandSeparator(amount, true);
        }else{
            value = amount < 0 ? thousandSeparator(amount * -1, true) : `(${thousandSeparator(amount, true)})`;
        }
        return value;
    };

    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 p-1"}>

                    <UICard
                        cardHeading={`Issued Transactions / Reversed Transactions  ${currentSession && currentSession !== '-' ? currentSession : getDateAndSession()}`}
                    >
                        <DataTable
                            apiUrl={`${sessionCollectionsAPI.url}/transactions/${sessionId}/issued_reversed`}
                            dataTableKey={`${sessionCollectionsAPI.key}_issued_reversed`}
                            isSetSearchFrom={true}
                            defaultSearchFormObject={{
                                vehical_id: "",
                                licence_type_code: "",
                                transaction_type_code: "",
                                transaction_status: ""
                            }}
                            searchFromComponent={<SearchElementOne dataTableKey={`${sessionCollectionsAPI.key}_issued_reversed`} showTransactionStatus={true} />}
                            tableHeaderList={[
                                { displayName: "No.", key: "__no__", isSort: false},
                                { 
                                    displayName: "Tx. Date & Time", 
                                    key: "tx_date_time", 
                                    isSort: false, 
                                    onPrintFn: (row) => dateObjectToString(row.tx_date_time,'dd/mm/YY HH:MM'),
                                    cellStyle:{ 'white-space': 'nowrap'}
                                },
                                { 
                                    displayName: "Lic. No./Rec. No.", 
                                    key: "licence_number", 
                                    isSort: false, 
                                    onPrintFn: (row) => row.licence_number ? row.licence_number: ""
                                },
                                {
                                    displayName: "Vehicle No.",
                                    key: "vehicle_number",
                                    isSort: false,
                                },
                                {
                                    displayName: "Owner Name/Dealer's Name",
                                    key: "owner_name",
                                    isSort: false,
                                    onPrintFn: (rowData)=>getLabelValue(rowData.owner_name,true),
                                },
                                {
                                    displayName: "Validity Period",
                                    key: "valid_period",
                                    isSort: false,
                                    onPrintFn: (row) => `${dateObjectToString(row.valid_from, "dd/mm/YY")} - ${dateObjectToString(row.valid_to, "dd/mm/YY")}`
                                },
                                { 
                                    displayName: "Licence Type", 
                                    key: "licence_type", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Type", 
                                    key: "transaction_type_code", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Status", 
                                    key: "status", 
                                    isSort: false , 
                                    onPrintFn: (row) => row.status !=='RE' ? "Success": "Reversed"
                                },
                                {
                                    displayName: "Fee (LKR)",
                                    key: "fee_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fee_amount)
                                },
                                {
                                    displayName: "Fine (LKR)",
                                    key: "fine_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fine_amount)
                                },
                                {
                                    displayName: "Additions (LKR)",
                                    key: "addition_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.addition_amount)
                                },
                                {
                                    displayName: "Deductions (LKR)",
                                    key: "deduction_amount",
                                    isSort: false,
                                    isRightAlign: true, 
                                    onPrintFn: (row) => displayAmount(row.status, row.deduction_amount)
                                },
                                {
                                    displayName: "Service Charges (LKR)",
                                    key: "service_charge",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.service_charge)
                                },
                                {
                                    displayName: "Total (LKR)",
                                    key: "total_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) =>  displayAmount(row.status, row.total_amount)
                                },
                                {
                                    displayName: "Cumulative Total (LKR)",
                                    key: "cumulative_total_amount",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) => row.cumulative_total_amount < 0 ? `(${thousandSeparator(row.cumulative_total_amount * -1, true)})` : thousandSeparator(row.cumulative_total_amount, true)
                                },
                            ]}
                            specificComponents={{
                                __no__: RowNo,
                            }}
                            isSetAction={false}
                            
                        />

                            <FormWrapper
                            setGroupName={"issuesReversed"}
                            apiUrl={`${sessionCollectionsAPI.url}/transaction-details/${sessionId}/issued_reversed`}
                            onRebuildResponseFn={(response) => {
                                return {
                                    ...response,
                                    _onLoad: false
                                };
                            }}
                        >
                            <div className="row">
                                <div className="col-md-4  cashier-history-label-wrapper col-sm-12">
                                    <strong>Total Transactions: </strong>
                                    {
                                        parseInt(_get(formState, "issuesReversed.licence.total_transactions_count", "0")) +
                                        parseInt(_get(formState, "issuesReversed.licence_reversed.total_transactions_count", "0"))
                                    }
                                    {' '}
                                        :
                                        {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "issuesReversed.licence.licence_transactions_sum", "0")) 
                                            - getTotal(_get(formState, "issuesReversed.licence_reversed.licence_transactions_sum", "0"))
                                            + getTotal(_get(formState, "issuesReversed.licence.arrears_transactions_sum", "0")) 
                                            - getTotal(_get(formState, "issuesReversed.licence_reversed.arrears_transactions_sum", "0"))
                                            + getTotal(_get(formState, "issuesReversed.licence.licence_transactions_deductions", "0"))
                                            + getTotal(_get(formState, "issuesReversed.licence.arrears_transactions_deductions", "0")) 
                                            - getTotal(_get(formState, "issuesReversed.licence_reversed.licence_transactions_deductions", "0")) 
                                            - getTotal(_get(formState, "issuesReversed.licence_reversed.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 cashier-history-label-wrapper col-sm-12">
                                    <strong>Total Licence Transactions: </strong>
                                    {_get(formState, "issuesReversed.licence.licence_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "issuesReversed.licence.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "issuesReversed.licence.licence_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 cashier-history-label-wrapper col-sm-12">
                                    <strong>Total Arrears Transactions: </strong>
                                    {_get(formState, "issuesReversed.licence.arrears_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "issuesReversed.licence.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "issuesReversed.licence.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 cashier-history-label-wrapper col-sm-12">
                                    <strong>Total Licence Reversed Transactions: </strong>
                                    {_get(formState, "issuesReversed.licence_reversed.licence_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "issuesReversed.licence_reversed.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "issuesReversed.licence_reversed.licence_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4  cashier-history-label-wrapper col-sm-12">
                                    <strong>Total Arrears Reversed Transactions: </strong>
                                    {_get(formState, "issuesReversed.licence_reversed.arrears_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "issuesReversed.licence_reversed.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "issuesReversed.licence_reversed.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>
                            </div>
                        </FormWrapper>
                    </UICard>

                </div>
            </div>

            <div className={"row defaultMarginTop"}>
                <div className={"col-md-12 col-sm-12 p-1"}>

                    <UICard
                        cardHeading={`Manual Entries - Correction Transactions  ${currentSession && currentSession !== '-' ? currentSession : getDateAndSession()}`}
                    >

                        <DataTable
                            apiUrl={`${sessionCollectionsAPI.url}/transactions/${sessionId}/correction`}
                            dataTableKey={`${sessionCollectionsAPI.key}_correction`}
                            isSetSearchFrom={true}
                            defaultSearchFormObject={{
                                vehical_id: "",
                                licence_type_code: "",
                                transaction_type_code: "",
                                transaction_status: ""
                            }}
                            searchFromComponent={<SearchElementOne dataTableKey={`${sessionCollectionsAPI.key}_correction`} showTransactionStatus={false} />}
                            tableHeaderList={[
                                { displayName: "No.", key: "__no__", isSort: false},
                                { 
                                    displayName: "Tx. Date & Time", 
                                    key: "tx_date_time", 
                                    isSort: false, 
                                    onPrintFn: (row) => dateObjectToString(row.tx_date_time,'dd/mm/YY HH:MM'),
                                    cellStyle:{ 'white-space': 'nowrap'}
                                },
                                { 
                                    displayName: "Lic. No./Rec. No.", 
                                    key: "licence_number", 
                                    isSort: false, 
                                    onPrintFn: (row) => row.licence_number ? row.licence_number: ""
                                },
                                {
                                    displayName: "Vehicle No.",
                                    key: "vehicle_number",
                                    isSort: false,
                                },
                                {
                                    displayName: "Owner Name/Dealer's Name",
                                    key: "owner_name",
                                    isSort: false,
                                    onPrintFn: (rowData)=>getLabelValue(rowData.owner_name,true),
                                },
                                {
                                    displayName: "Validity Period",
                                    key: "valid_period",
                                    isSort: false,
                                    onPrintFn: (row) => `${dateObjectToString(row.valid_from, "dd/mm/YY")} - ${dateObjectToString(row.valid_to, "dd/mm/YY")}`
                                },
                                { 
                                    displayName: "Licence Type", 
                                    key: "licence_type", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Type", 
                                    key: "transaction_type_code", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Status", 
                                    key: "status", 
                                    isSort: false , 
                                    onPrintFn: (row) => row.status ==='CR' ? "Success": ""
                                },
                                {
                                    displayName: "Fee (LKR)",
                                    key: "fee_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fee_amount)
                                },
                                {
                                    displayName: "Fine (LKR)",
                                    key: "fine_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fine_amount)
                                },
                                {
                                    displayName: "Additions (LKR)",
                                    key: "addition_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.addition_amount)
                                },
                                {
                                    displayName: "Deductions (LKR)",
                                    key: "deduction_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.deduction_amount)
                                },
                                {
                                    displayName: "Service Charges (LKR)",
                                    key: "service_charge",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.service_charge)
                                },
                                {
                                    displayName: "Total (LKR)",
                                    key: "total_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.total_amount)
                                },
                                {
                                    displayName: "Cumulative Total (LKR)",
                                    key: "cumulative_total_amount",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) => row.cumulative_total_amount < 0 ? `(${thousandSeparator(row.cumulative_total_amount * -1, true)})` : thousandSeparator(row.cumulative_total_amount, true)
                                },
                            ]}
                            specificComponents={{
                                __no__: RowNo,
                            }}
                            isSetAction={false}
                        />

                        <FormWrapper
                            setGroupName={"correctionTx"}
                            apiUrl={`${sessionCollectionsAPI.url}/transaction-details/${sessionId}/correction`}
                            onRebuildResponseFn={(response) => {
                                return {
                                    ...response,
                                    _onLoad: false
                                };
                            }}
                        >
                            <div className="row">
                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Transactions: </strong>
                                    {
                                        parseInt(_get(formState, "correctionTx.total_transactions_count", "0"))
                                    }
                                    {' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "correctionTx.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "correctionTx.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "correctionTx.licence_transactions_deductions", "0")) +
                                            getTotal(_get(formState, "correctionTx.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Licence Transactions: </strong>
                                    {_get(formState, "correctionTx.licence_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "correctionTx.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "correctionTx.licence_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Arrears Transactions: </strong>
                                    {_get(formState, "correctionTx.arrears_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "correctionTx.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "correctionTx.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>
                            </div>
                        </FormWrapper>
                    </UICard>                   

                </div>
            </div>

            <div className={"row defaultMarginTop"}>
                <div className={"col-md-12 col-sm-12 p-1"}>

                    <UICard
                        cardHeading={`Manual Entries - Missing Record Transactions  ${currentSession && currentSession !== '-' ? currentSession : getDateAndSession()}`}
                    >

                        <DataTable
                            apiUrl={`${sessionCollectionsAPI.url}/transactions/${sessionId}/missing`}
                            dataTableKey={`${sessionCollectionsAPI.key}_missing`}
                            isSetSearchFrom={true}
                            defaultSearchFormObject={{
                                vehical_id: "",
                                licence_type_code: "",
                                transaction_type_code: "",
                                transaction_status: ""
                            }}
                            searchFromComponent={<SearchElementOne dataTableKey={`${sessionCollectionsAPI.key}_missing`}  showTransactionStatus={false}/>}
                            tableHeaderList={[
                                { displayName: "No.", key: "__no__", isSort: false},
                                { 
                                    displayName: "Tx. Date & Time", 
                                    key: "tx_date_time", 
                                    isSort: false, 
                                    onPrintFn: (row) => dateObjectToString(row.tx_date_time,'dd/mm/YY HH:MM'),
                                    cellStyle:{ 'white-space': 'nowrap'}
                                },
                                { 
                                    displayName: "Lic. No./Rec. No.", 
                                    key: "licence_number", 
                                    isSort: false, 
                                    onPrintFn: (row) => row.licence_number ? row.licence_number: ""
                                },
                                {
                                    displayName: "Vehicle No.",
                                    key: "vehicle_number",
                                    isSort: false,
                                },
                                {
                                    displayName: "Owner Name/Dealer's Name",
                                    key: "owner_name",
                                    isSort: false,
                                    onPrintFn: (rowData)=>getLabelValue(rowData.owner_name,true),
                                },
                                {
                                    displayName: "Validity Period",
                                    key: "valid_period",
                                    isSort: false,
                                    onPrintFn: (row) => `${dateObjectToString(row.valid_from, "dd/mm/YY")} - ${dateObjectToString(row.valid_to, "dd/mm/YY")}`
                                },
                                { 
                                    displayName: "Licence Type", 
                                    key: "licence_type", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Type", 
                                    key: "transaction_type_code", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Status", 
                                    key: "status", 
                                    isSort: false , 
                                    onPrintFn: (row) => row.status ==='AL' ? "Success": ""
                                },
                                {
                                    displayName: "Fee (LKR)",
                                    key: "fee_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fee_amount)
                                },
                                {
                                    displayName: "Fine (LKR)",
                                    key: "fine_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fine_amount)
                                },
                                {
                                    displayName: "Additions (LKR)",
                                    key: "addition_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.addition_amount)
                                },
                                {
                                    displayName: "Deductions (LKR)",
                                    key: "deduction_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.deduction_amount)
                                },
                                {
                                    displayName: "Service Charges (LKR)",
                                    key: "service_charge",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.service_charge)
                                },
                                {
                                    displayName: "Total (LKR)",
                                    key: "total_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) =>  displayAmount(row.status, row.total_amount)
                                },
                                {
                                    displayName: "Cumulative Total (LKR)",
                                    key: "cumulative_total_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => row.cumulative_total_amount < 0 ? `(${thousandSeparator(row.cumulative_total_amount * -1, true)})` : thousandSeparator(row.cumulative_total_amount, true)
                                },
                            ]}
                            specificComponents={{
                                __no__: RowNo,
                            }}
                            isSetAction={false}
                        />

                        <FormWrapper
                            setGroupName={"missingTx"}
                            apiUrl={`${sessionCollectionsAPI.url}/transaction-details/${sessionId}/missing`}
                            onRebuildResponseFn={(response) => {
                                return {
                                    ...response,
                                    _onLoad: false
                                };
                            }}
                        >
                            <div className="row">
                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Transactions: </strong>
                                    {
                                        parseInt(_get(formState, "missingTx.total_transactions_count", "0"))
                                    }
                                    {' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "missingTx.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "missingTx.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "missingTx.licence_transactions_deductions", "0")) +
                                            getTotal(_get(formState, "missingTx.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Licence Transactions: </strong>
                                    {_get(formState, "missingTx.licence_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "missingTx.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "missingTx.licence_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Arrears Transactions: </strong>
                                    {_get(formState, "missingTx.arrears_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "missingTx.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "missingTx.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>
                            </div>
                        </FormWrapper>
                    </UICard>

                </div>
            </div>

            <div className={"row defaultMarginTop"}>
                <div className={"col-md-12 col-sm-12 p-1"}>

                    <UICard
                        cardHeading={`Cancelled Transactions  ${currentSession && currentSession !== '-' ? currentSession : getDateAndSession()}`}
                    >
                        <DataTable
                            apiUrl={`${sessionCollectionsAPI.url}/transactions/${sessionId}/canceled`}
                            dataTableKey={`${sessionCollectionsAPI.key}_canceled`}
                            isSetSearchFrom={true}
                            defaultSearchFormObject={{
                                vehical_id: "",
                                licence_type_code: "",
                                transaction_type_code: "",
                                transaction_status: ""
                            }}
                            searchFromComponent={<SearchElementOne dataTableKey={`${sessionCollectionsAPI.key}_canceled`}  showTransactionStatus={false}/>}
                            tableHeaderList={[
                                { displayName: "No.", key: "__no__", isSort: false},
                                { 
                                    displayName: "Tx. Date & Time", 
                                    key: "tx_date_time", isSort: false, 
                                    onPrintFn: (row) => dateObjectToString(row.tx_date_time,'dd/mm/YY HH:MM'),
                                    cellStyle:{ 'white-space': 'nowrap'}
                                },
                                { 
                                    displayName: "Lic. No./Rec. No.", 
                                    key: "licence_number", 
                                    isSort: false, 
                                    onPrintFn: (row) => row.licence_number ? row.licence_number: ""
                                },
                                {
                                    displayName: "Vehicle No.",
                                    key: "vehicle_number",
                                    isSort: false,
                                },
                                {
                                    displayName: "Owner Name/Dealer's Name",
                                    key: "owner_name",
                                    isSort: false,
                                    onPrintFn: (rowData)=>getLabelValue(rowData.owner_name,true),
                                },
                                {
                                    displayName: "Validity Period",
                                    key: "valid_period",
                                    isSort: false,
                                    onPrintFn: (row) => `${dateObjectToString(row.valid_from,'dd/mm/YY')} - ${dateObjectToString(row.valid_to,'dd/mm/YY')}`
                                },
                                { 
                                    displayName: "Licence Type", 
                                    key: "licence_type", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Type", 
                                    key: "transaction_type_code", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Status", 
                                    key: "status", isSort: false , 
                                    onPrintFn: (row) => row.status ==='CL' ? "Cancelled": ""
                                },
                                {
                                    displayName: "Fee (LKR)",
                                    key: "fee_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fee_amount)
                                },
                                {
                                    displayName: "Fine (LKR)",
                                    key: "fine_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.fine_amount)
                                },
                                {
                                    displayName: "Additions (LKR)",
                                    key: "addition_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.addition_amount)
                                },
                                {
                                    displayName: "Deductions (LKR)",
                                    key: "deduction_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.deduction_amount)
                                },
                                {
                                    displayName: "Service Charges (LKR)",
                                    key: "service_charge",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.service_charge)
                                },
                                {
                                    displayName: "Total (LKR)",
                                    key: "total_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => displayAmount(row.status, row.total_amount)
                                },
                                {
                                    displayName: "Cumulative Total (LKR)",
                                    key: "cumulative_total_amount",
                                    isSort: false,
                                    isRightAlign: true,
                                    onPrintFn: (row) => row.cumulative_total_amount < 0 ? `(${thousandSeparator(row.cumulative_total_amount * -1, true)})` : thousandSeparator(row.cumulative_total_amount, true)
                                },
                                
                            ]}
                            specificComponents={{
                                __no__: RowNo,
                            }}
                            isSetAction={false}
                        />

                        <FormWrapper
                            setGroupName={"cancelledTx"}
                            apiUrl={`${sessionCollectionsAPI.url}/transaction-details/${sessionId}/canceled`}
                            onRebuildResponseFn={(response) => {
                                return {
                                    ...response,
                                    _onLoad: false
                                };
                            }}
                        >
                            <div className="row">
                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Transactions: </strong>
                                    {
                                        parseInt(_get(formState, "cancelledTx.total_transactions_count", "0"))
                                    }
                                    {' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "cancelledTx.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "cancelledTx.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "cancelledTx.licence_transactions_deductions", "0")) +
                                            getTotal(_get(formState, "cancelledTx.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Licence Transaction: </strong>
                                    {_get(formState, "cancelledTx.licence_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "cancelledTx.licence_transactions_sum", "0")) +
                                            getTotal(_get(formState, "cancelledTx.licence_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>

                                <div className="col-md-4 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total Arrears Transactions: </strong>
                                    {_get(formState, "cancelledTx.arrears_transactions_count", "0")}{' '}
                                    :
                                    {' '}
                                    {
                                        thousandSeparator(
                                            getTotal(_get(formState, "cancelledTx.arrears_transactions_sum", "0")) +
                                            getTotal(_get(formState, "cancelledTx.arrears_transactions_deductions", "0")),
                                            true
                                        )
                                    }
                                </div>
                            </div>
                        </FormWrapper>
                    </UICard>

                </div>
            </div>
        </Fragment>        
    );
};



export default TransactionList;
