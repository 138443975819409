import React, { Fragment } from "react";
import { PendingSchedule } from "./pages/schedule-check-list/ScheduleChecklist";
import { MyPendingSchedule } from "./pages/my-schedule-checklist/MyScheduleChecklist";
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import authorized from '../../hoc-middlewares/Authorized';
import AllPendingSchedule from "./pages/common-tasks";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";


const TasksRoutes = () => {
  return (
    <Fragment>
      <Navigate path="/all-tasks" exact={true} routeKey={"task.allTasks2"} component={authorized(AllPendingSchedule)} routePermissions={permissions.TASK.permissions} />
      <Navigate path="/schedule-check-list" exact={true} routeKey={"task.pendingSchedule"} component={authorized(PendingSchedule)} />
      <Navigate path="/my-schedule-check-list" exact={true} routeKey={"task.myPendingSchedule"} component={authorized(MyPendingSchedule)} />
    </Fragment>
  );
};

export default TasksRoutes;
