import React from 'react'
import { _get } from '../../../../../../helpers/common-helpers/lodash.wrappers'
import { PaddingLessUICard } from '../../../../../ui-components/ui-elements/common/BaseElements'
import { OwnerDetailsIcons } from '../../includes/OwnerDetailsIcons'
import { OwnerInfoDisplay } from '../../includes/OwnerInfoDisplay'
import { VehicleInfoDisplay } from "../../includes/VehicleInfoDisplay";

export const OwnerInfo = (props) => {
    const formKey = props.formKey;
    const getVehicleStatusCode = props.getVehicleStatusCode;
    const params = props.params;
    const getState = props.getState
    return (
        <PaddingLessUICard
            cardHeading="Owner Information"
            passPropsToCardHeadingTail={{
                uiType: "owner_infor",
                formGroupName: { formKey },
                ownerInfo: _get(getState, `currentObject.owner_id`, {}),
                absoluteOwnerInfo: _get(getState, `currentObject.absolute_owner_id`, {}),
                getVehicleStatusCode: getVehicleStatusCode,
                params: params
            }}
            cardHeadingTail={OwnerDetailsIcons}
        >
            <OwnerInfoDisplay
                ownerInfo={_get(getState, `currentObject.owner_id`, {})}
                absoluteOwnerInfo={_get(getState, `currentObject.absolute_owner_id`, {})}
                uiType={"ownerInfo"}
            />
        </PaddingLessUICard>
    )
}


export const VehicleInfo = ({
    formKey = "",
    getVehicleStatusCode = "",
    getState = {
        currentObject: null,
        licenseArrearsRecords: [],
    },
}) => {

    return (
        <PaddingLessUICard
            cardHeading="Vehicle Information"
            passPropsToCardHeadingTail={{
                uiType: "vehicle_infor",
                formGroupName: { formKey },
                vehicleInfo: { ...getState.currentObject, 'vehicle_status': getVehicleStatusCode },
                getVehicleStatusCode: getVehicleStatusCode,
            }}
            cardHeadingTail={OwnerDetailsIcons}
        >
            <VehicleInfoDisplay
                uiType={"summary"}
                vehicleInfo={getState.currentObject}
            />
        </PaddingLessUICard>
    )
}
