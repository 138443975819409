/*
 * @Author: Madhawa
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-19 13:23:31
 */

import React, { Fragment, useContext, useEffect } from "react";
import { thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import { isEmpty } from "lodash";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { getLicenceTransactionDetailsAPI } from "../../../../../config/apiUrl.config";
const VehicleRevenueLicence = ({ uiType = "normal", latestLicence = {} }) => {
  const [authStatus, authAction] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);
  const helperText = [];
  const dataList = [
    "images/licence-preview-empty.jpg",
    "images/file-word.png",
    "images/profile_img.png",
  ];
  const ownerInfo = _get(formState, "viewOwnerInformation", "");
  const vehicleInfo = _get(formState, "vehicle_search_form.data", "");
  const licenceid = _get(formState, "licence_charges_form.licenceId", null);
  const licenceEndType = _get(formState, "licence_validity_period.endDate", 0);
  const licenceValidityPeriodEndDate = _get(formState, `licence_validity_period.endDatesData[${licenceEndType}].value`, null);

  const getWeight = (gross, tare) => {
    if (gross !== 0 && gross !== '0.00' && gross != null && gross !== 0.00 && tare !== 0 && tare !== '0.00' && tare != null && tare !== 0.00) {
      return `${gross} kg / ${tare} kg`
    } else if (gross !== 0 && gross !== '0.00' && gross != null && gross !== 0.00) {
      return `${gross} kg`
    } else {
      return `${tare} kg`
    }
  };

  const getLicenceValidityPeriod = () => {
    if (_get(formState, "vehicle_search_form.isTempLicence", false)) {
      return `${dateObjectToString(_get(formState, "licence_validity_period.startDate", null), "dd-mm-YY")} To ${_get(formState, `licence_validity_period.tempLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_validity_period.tempLicenceEndDate`, null), "dd-mm-YY") : "-"}`
    }
    // else if (_get(formState, "latest_licence_data.data.current_licence_status", false) === 'VA'
    //   && (!_get(formState, "latest_licence_data.data.temp_licence", 0) == 1)) {
    //   return `${dateObjectToString(_get(formState, `vehicle_search_form.data.last_licence_from`, null), "dd-mm-YY")} To ${dateObjectToString(new Date(_get(formState, `vehicle_search_form.data.last_licence_to`, null)), "dd-mm-YY")}`
    // } 
    else if ((_get(formState, "latest_licence_data.data.current_licence_status", null) === 'VA' || _get(formState, "latest_licence_data.data.current_licence_status", null) === 'RE')
      && (_get(formState, "licence_validity_period.isReIssuance", false))) {
      return `${dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, null), "dd-mm-YY")} To ${_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, null), "dd-mm-YY") : "-"}`
    }
    else {
      return (`${dateObjectToString(_get(formState, "licence_charges_form.data.valid_from", null), "dd-mm-YY")} To ${_get(formState, "licence_charges_form.data.valid_to", null) ? dateObjectToString((_get(formState, "licence_charges_form.data.valid_to", null)), "dd-mm-YY") : "-"}`)
    }
    // else if ((_get(formState, "latest_licence_data.data.current_licence_status", false)==='VA' || _get(formState, "latest_licence_data.data.current_licence_status", false)==='RE')
    //    && (!_get(formState, "latest_licence_data.data.temp_licence", 0)==1 || _get(formState, "licence_validity_period.isReissuance", false) )){
    //     return  `${dateObjectToString(_get(formState, `vehicle_search_form.data.last_licence_from`, null), "dd-mm-YY")} To ${dateObjectToString(new Date(_get(formState, `vehicle_search_form.data.last_licence_to`, null)), "dd-mm-YY")}`
    // }

  };

  useEffect(() => {
    callApi(`${getLicenceTransactionDetailsAPI.url}/${_get(formState, "licence_charges_form.licenceId", null)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          _get(result, "data.data.documents", []).map(item => {
            if (item.doc_type_code === "VET") {
              formAction.changeInputFn('vet', "number", "", result.data.data.documents[1].doc_ref_no)
            }

          })


        }

      })

  }, [_get(formState, "licence_charges_form.licenceId", null)]);

  return (
    dataList.length > 0 && (
      <Fragment>
        <div className="licence-preview-card">
          <img src={dataList[0]} alt={""} />
          <div className="mainContent">
            <div className="row d-flex justify-content-center">
              <div className="licence-date float-left">
                {uiType === 'carnet' ?
                  dateObjectToString(_get(formState, "carnet.toDate", null), "MMM YYY")
                  :
                  _get(formState, "licence_charges_form.currentLicenceEndDate", null) ? dateObjectToString(_get(formState, "licence_charges_form.currentLicenceEndDate", null), "MMM YYY") : dateObjectToString(_get(formState, `licence_validity_period.reissanceEnddDate`, null), "MMM YYY")
                }
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
                {`${_get(formState, "vehicle_search_form.provinceData.code", "")} | ${_get(formState, "vehicle_search_form.searchKey", "")}|   ${_get(vehicleInfo, "current_license_type.name", "").toUpperCase()} ${_get(formState, "vehicle_search_form.isTempLicence", false) || (_get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "TMP" && _get(formState, "licence_validity_period.isReIssuance", false) === true) ? '(TEMPORARY)' : ''}`}
              </div>
            </div>
            <div className="row">
              <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
                {`${_get(vehicleInfo, "vehicle_class.name", "")} | ${_get(vehicleInfo, "fuel_type.name", "")}`}
              </div>
            </div>
          

            <div className="col-md-12 col-sm-12  main-container">
              <div className="row owner-name-address-label">
                Vehicle Owner's Name & Address:
              </div>
              <div className="row owner-name">
                {getLabelValue(_get(ownerInfo, "name", ""), true)}
              </div>

              <div className="row owner-address">
                {(getLabelValue(_get(ownerInfo, 'address_line_1', ''), true) + ", " + getLabelValue(_get(ownerInfo, 'address_line_2', ''), true) + (!isEmpty(_get(ownerInfo, 'city', '')) ? (", " + _get(ownerInfo, 'city', '')) : ""))}
              </div>

              <br />
              <div className="amount-container">

                <div className="row">
                  <div className="floatLeft col-md-3 licence-total-amount-label">
                    Amount (Rs.):
                  </div>

                  <div className="floatLeft col-md-9 licence-amounts-label">
                    Fee +  Arrears + Fine + Additions - Deductions
                  </div>
                </div>


                <div className="row">
                  <div className="floatLeft col-md-3 licence-total-amount">
                    {uiType === 'carnet' ?
                      `${thousandSeparator(_get(formState, "licence_charges_form.total", 0), true)}`
                      :
                      `${thousandSeparator(_get(formState, "licence_charges_form.total", 0), true)}
                    `
                    }:
                  </div>

                  <div className="floatLeft col-md-9 licence-amounts">
                    {uiType === 'carnet' ?
                      `${thousandSeparator(_get(formState, `licence_charges_form.totCurrentLicenceFee`, 0), true)} 
                    + ${thousandSeparator(_get(formState, "licence_charges_form.totAddition", 0), true)} 
                    - ${thousandSeparator(_get(formState, "licence_charges_form.totdeduction", 0), true)}
                    `
                      :
                      `${thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFee", 0), true)}
                     +${thousandSeparator((Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)) +
                        Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)) +
                        Number(_get(formState, `licence_charges_form.totArrearsAddition`, 0)) -
                        Number(_get(formState, `licence_charges_form.totArrearsDeduction`, 0))), true)}
                     + ${thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFine", 0), true)}
                     + ${thousandSeparator(+(_get(formState, "licence_charges_form.data.current_licence_addition_total", 0) + (+_get(formState, "licence_charges_form.serviceCharge", 0))), true)}
                     - ${thousandSeparator(_get(formState, "licence_charges_form.data.current_licence_deduction_total", 0), true)}
                    `
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row bottom-label-container">
              <div className="col-md-7">
                <div className="validity-period-label">
                  Validity Period:
                </div>
                <div className="validity-period-value">
                  {uiType === 'carnet' ?
                    `${dateObjectToString(_get(formState, "carnet.fromDate", null), "dd/mm/YY")} To ${dateObjectToString(_get(formState, "carnet.toDate", null), "dd/mm/YY")}`
                    :
                    getLicenceValidityPeriod()
                  }
                </div>
              </div>
              <div className="col-md-5">
                <div className="vet-no-label">
                  VET Number:
                </div>
                <div className="vet-no-value">
                  {_get(formState, "licence_validity_period.isReIssuance", false) === true ? _get(formState, "vet.number", "") : _get(formState, "vet_certificate_form.vet_no", "")}
                </div>
              </div>

              <div className="row bottom-label-container2">
                <div className="col-md-7">
                  <div className="row" style={{ marginLeft: 0 }}>
                    <div className="unladen-weight-label">
                      Unladen/ Gross Weight:
                    </div>
                    <div className="unladen-weight-value">
                      {getWeight(_get(vehicleInfo, 'gross_weight', 0), _get(vehicleInfo, 'tare_weight', 0))}
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="row" style={{ marginLeft: 0 }}>
                    <div className="no-of-seats-label">
                      Number of Seats:
                    </div>
                    <div className="no-of-seats-value">
                      {_get(vehicleInfo, 'no_of_seats', '0') ? String(_get(vehicleInfo, 'no_of_seats', '0'))?.padStart(2, "0") : '00'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {_get(formState, "vehicle_search_form.data.current_license_type.code", "") === "NU"
              || (_get(formState, "vehicle_search_form.isTempLicence", false) && _get(formState, "vehicle_search_form.documentRequest.run", 0) === 0) ? (
              <div className="w-100 not_for_running_container" >
                <div className="mt-1 not_for_running" >
                  NOT FOR RUNNING
                </div>
              </div>) : null}



            <div className="footer-content">
              <div className="row">
                <div className="col-md-7">
                  <div className="signature-place">
                    .......................................
                  </div>
                  <div className="signature-details">
                    Signature/ Designation & Date
                  </div>
                </div>

                <div className="col-md-5" style={{ marginLeft: '-5px' }}>
                  <div className="signature-place">
                    {_get(authStatus, "authUser.name", "")}
                  </div>
                  <div className="signature-details">
                    {dateObjectToString(new Date(), "dd-mm-YY")}
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="helperText">{helperText[0]}</div>
        </div>
      </Fragment>
    )
  );
};

export { VehicleRevenueLicence };
