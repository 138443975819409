/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-07 14:26:01
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-28 12:36:39
 */
import React, { Fragment, useState, useContext, useEffect } from "react";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../../../ui-components/ui-elements/modal/DialogPopup";
import { RevenueLicenseQuotation } from "../../../../dashboard/pages/includes/QuotationSRS";
import PayArrearsOnly from "../../../../payment/pages/PayArrearsOnly";
import IssueTemp from "../../../../payment/pages/IssueTemp";
import { ViewDocuments } from "../../includes/ViewDocuments";
import { ActivityLog } from "../../includes/ActivityLog";
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { issueRLValidationAPI } from "../../../../../../config/apiUrl.config";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { isEmpty } from "lodash";
import { getCodeName } from "../../../../../../helpers/common-helpers/common.helpers";
import { CoreContext } from "../../../../core/context-providers/CoreContext.provider";
import { MaxArrearsPeriod } from "../../../../../../config/enum.config";
import { VehicleStatus, } from "../../../../../../config/enum.config";
import { fromObjectToQueryString } from "../../../../../../helpers/common-helpers/common.helpers"
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";


const emptyFun = (...para) => undefined;

const NormalFooter = ({
  currentTab = "tab1",
  formChild = {
    issueMode: "1",
    isPendingWorkFlow: false,
    payArrearsOnly: true,
  },
  latestLicence = {}
}) => {
  const searchFormKey = "vehicle_search_form";
  const pendingWorkflowFormKey = "pending_workflow_key";
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [getState, setState] = useState({
    status: false,
    modelType: "",
  });
  const authorityTypeCode = _get(authStatus, "authUser.authority_type_code", null);

  const closePopup = () => {
    setState({
      ...getState,
      status: false,
      modelType: "",
    })
  };

  const btnName = () => {
    if (_get(formState, "vehicle_search_form.isTempLicence", false)) {
      return "Issue Temp";
    } else {
      return "Issue RL";
    }
  };
  const isWebAuthority = authorityTypeCode !== null &&
    authorityTypeCode === "WLA" ?
    true : false;
  useEffect(() => {
    formAction.initFromFn({ "issue-RL-Validation": { _onLoad: true } });

    if (!isEmpty(_get(formState, "vehicle_search_form.data", null)) && _get(formState, `licence_validity_period.isLicenceEndDate`, true) === false && _get(formState, `licence_validity_period._onLoad`, true) === false && _get(formState, `licence_validity_period.start_onLoad`, true) === false && _get(formState, "licence_charges_form._onLoad", true) === false) {
      issueValid()
    }
  },
    [
      _get(formState, 'vehicle_search_form.data', null),
      _get(formState, "vrp_form", null),
      _get(formState, "licence_charges_form.rateRange", null),
      _get(formState, "vehicle_search_form.documentRequest", ""),
      _get(formState, "licence_validity_period.isReIssuance", false),
      _get(formState, "licence_validity_period.requestedLicenceEndDate", null),
      _get(formState, `licence_validity_period.isLicenceEndDate`, true),
      _get(formState, `licence_validity_period.reissanceEnddDate`, null),
      _get(formState, `licence_validity_period._onLoad`, true),
      _get(formState, "licence_charges_form._onLoad", true),
      _get(formState, `licence_validity_period.start_onLoad`, true)
    ]
  );

  const issueValid = () => {
    let document_validations

    if (_get(formState, "vehicle_search_form.isTempLicence", false) === true) {
      document_validations = false
    }
    else {

      document_validations = ((_get(formState,"vehicle-meta-data.data.vet_required",false) === _get(formState, "vet_certificate_form.isVet", false))
        && ( _get(formState,"vehicle-meta-data.data.insurance_required",false) === _get(formState, "insurance_form.isInsurance", false))
        && ( _get(formState,"vehicle-meta-data.data.vft_required",false) === _get(formState, "vft_certificate_form.isfitness", false))
        && ( _get(formState,"vehicle-meta-data.data.vrp_required",false) === _get(formState, "vrp_form.isroutePerm", false))) ? true : false

    }

    const queryObj = {
      vehicle_number: _get(formState, `${searchFormKey}.searchKey`, ""),
      eligible: _get(formState,"vehicle-meta-data.data.eligible",false)===true ? true : false,
      requested_licence_type: _get(formState, `vehicle_search_form.data.current_license_type.code`, ""),
      requested_licence_mode: _get(formState, "vehicle_search_form.isTempLicence", false) === true ? "TEMP" : "FULL",
      requested_licence_end_date: _get(formState, "licence_validity_period.isReIssuance", false) === true ? dateObjectToString(_get(formState, `licence_validity_period.reissanceEnddDate`, null)) : dateObjectToString(_get(formState, "licence_validity_period.requestedLicenceEndDate", null)),
      documents_validated: document_validations,
      is_reissuance: _get(formState, "licence_validity_period.isReIssuance", false),
      rate_range_detail_id: _get(formState, "licence_charges_form.rateRange", null),
      //requested_licence_mode: _get(formState, `latest_licence_data.data.temp_licence`, 0) === 1 || _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "TMP" ? "TEMP" : "FULL",


    };

    callApi(`${issueRLValidationAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('issue-RL-Validation', "data", "", result.data.data)

        }

        formAction.changeInputFn("issue-RL-Validation", "_onLoad", "", false);
      })

  }

  // const getVehicleStatusCode = () => {
  //   let vehiclestatusCode = 'INE'
  //   vehiclestatusCode = _get(formState, `${searchFormKey}.data.vehicle_status`,)
  //   if ((_get(formState, `${searchFormKey}.data.gross_weight`, 0) === null || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === '0.00')
  //     && (_get(formState, `${searchFormKey}.data.tare_weight`, 0) === null || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === '0.00')
  //   ) {
  //     vehiclestatusCode = "INE"
  //   }

  //   if (_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length > 0) {
  //     vehiclestatusCode = "INE"
  //   }

  //   if (_get(formState, `${searchFormKey}.data.fuel_type.name`,) === 'NOT STATED' && _get(formState, `${searchFormKey}.data.vehicle_class.prefix`,) !== 'TR') {
  //     vehiclestatusCode = "INE"
  //   }

  //   return vehiclestatusCode
  // }

  const totalArrears =
    _get(formState, "licence_charges_form.data.arrears_fee_total", 0) +
    _get(formState, "licence_charges_form.data.arrears_fine_total", 0) +
    _get(formState, "licence_charges_form.data.arrears_addition_total", 0) -
    _get(formState, "licence_charges_form.data.arrears_deduction_total", 0);


  const getModelName = () => {
    if (getState.modelType === "payment") {
      return `Make Payment - ${_get(formState, "vehicle_search_form.data.current_license_type.name", "")}`;
    } else if (getState.modelType === "payment-arrears") {
      return "Make Payment – Arrears";
    } else if (getState.modelType === "viewDocuments") {
      return "View & Upload Documents";
    } else if (getState.modelType === "activityLog") {
      return "Activity Log";
    } else if (getState.modelType === "quotationReport") {
      return "Revenue Licence Quotation";
    }
  };

  const getModel = () => {
    if (getState.modelType === "payment") {
      let type = "normal";
      return <IssueTemp uiType={type} latestLicence={latestLicence} closeMainPopup={closePopup} isTempLicence={_get(formChild, "issueMode", "1") === "2"} />;
    } else if (getState.modelType === "payment-arrears") {
      return <PayArrearsOnly uiType={"arrears"} closeMainPopup={closePopup} />;
    } else if (getState.modelType === "viewDocuments") {
      return <ViewDocuments />;
    } else if (getState.modelType === "activityLog") {
      return <ActivityLog  uiType={"vehicle"}/>;
    } else if (getState.modelType === "quotationReport") {
      return <RevenueLicenseQuotation />;
    }
  };

  const validateFreeNonUserLcn = () => {
    const errorObject = [];
    let validated = true;
    const freeLicenceFormkey = "freeLicenceFormkey";
    const nonUserFormKey = 'nonUserFormkey';
    if (_get(formState, "vehicle_search_form.licence_code", "") === "NU") {
      if (isEmpty(_get(formState, `${nonUserFormKey}.issued_by`, null))) {
        validated = false;
        errorObject.push({
          "property": `issued_by`,
          "message": "Issued Organization is required."
        });
      }
    }
    if (_get(formState, "vehicle_search_form.licence_code", "") === "FE") {
      if (isEmpty(_get(formState, `${freeLicenceFormkey}.requested_by`, null))) {
        validated = false;
        errorObject.push({
          "property": `requested_by`,
          "message": "Requested Organization is required."
        });
      }
    }
    if (!isEmpty(errorObject)) {
      validated = false;
      formAction.setFormErrorFn(freeLicenceFormkey, errorObject);
      formAction.setFormErrorFn(nonUserFormKey, errorObject);
    } else {
      formAction.setFormErrorFn(freeLicenceFormkey, []);
      formAction.setFormErrorFn(nonUserFormKey, []);
    }

    return validated;
  };

  const getYearDiffWithMonth = (startDate, endDate) => {

    if (startDate == null || endDate == null) {
      return 0
    } else {
      const ms = (new Date(endDate).getTime()) - (new Date(startDate).getTime());
      const date = new Date(ms);
      return Math.abs(date.getUTCFullYear() - 1970);
    }

  }

  return (
    <div className="row">
      <div className="col-md-6">
        <Fragment>
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            isBtnDisabled={!_get(formState, `${searchFormKey}.data`, null)}
            btnText={"Activity Log"}
            onClickBtnFn={() =>
              setState({
                ...getState,
                status: true,
                modelType: "activityLog",
              })
            }
          />
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            isBtnDisabled={!_get(formState, `${searchFormKey}.data`, null)}
            btnText={"View/Upload Documents"}
            onClickBtnFn={() =>
              setState({
                ...getState,
                status: true,
                modelType: "viewDocuments",
              })
            }
          />
        </Fragment>
      </div>

      <div className="col-md-6 text-right">
        {
          _get(formChild, "isPendingWorkFlow", false) === false &&
            _get(formChild, "payArrearsOnly", true) === true &&
            authorityTypeCode !== 'WLA'&&
            totalArrears != 0 && _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","") === 'ELI' //will show only if eligibale
            ? (
              <InputButton
                elementWrapperStyle={"defaultHalfMarginLeftRight"}
                btnName="submit"
                btnText={"Pay Arrears Only"}
                isBtnDisabled={!isEmpty(_get(formState, "vehicle_search_form.data", "")) ? (_get(formState, `issue-RL-Validation.data.canIssueArrearsRL`, false) === true ? false : true) : true}
                onClickBtnFn={() =>
                  setState({
                    ...getState,
                    status: true,
                    modelType: "payment-arrears",
                  })
                }
              />
            )
            : null}

        {_get(formChild, "isPendingWorkFlow", false) === false && _get(formState, "issue-RL-Validation._onLoad", false) === false && _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","") === 'ELI' && !isWebAuthority ? (
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            btnText={btnName(currentTab)}
           isBtnDisabled={((_get(formState, "vehicle_search_form.data", "")) ?  (_get(formState, `issue-RL-Validation.data.canIssueRL`, false) === true ? false : true) : true)}
            onClickBtnFn={() => {
              let validated = true;

                if (_get(formState, "vehicle_search_form.data.current_license_type.code", "") === "NU" || _get(formState, "vehicle_search_form.data.current_license_type.code", "") === "FE") {
                  validated = validateFreeNonUserLcn();
                }
                if (validated) {
                  setState({
                    ...getState,
                    status: true,
                    modelType: "payment",
                  })
                }
              }}
            />
            ) 
          : null
        }

      </div>
      <DialogPopup
        isOpen={getState.status}
        isFullScreen={getState.modelType === "viewDocuments" ? false : true}
        dialogTitle={getModelName()}
        onClosePopupFn={() =>
          setState({
            ...getState,
            status: false,
            modelType: "",
          })
        }
        closeButton={{
          elementStyle: "defaultSamebutton",
          startIcon: "far fa-times-circle",
          btnText: getState.modelType !== "viewDocuments" ? 'Cancel' : 'Cancel'
        }}
        isConfirmationPopup={getState.modelType === "payment" ? true : false}
        confirmationMessage={"Are you sure you want to go back?"}
      >
        {getModel()}
      </DialogPopup>
    </div>
  );
};

export { NormalFooter };
