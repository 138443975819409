/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-14 22:20:13
 */

import React, { Fragment } from "react";
import { useContext } from 'react';
import { FormWrapper, InputButtonWithState, RadioButtonsGroupWithState } from "../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getFlooredFixed, thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { isEmpty } from "lodash";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { YearMonthPickerWithState } from "../../../../ui-components/ui-elements/form/DatePicker";
import { arrearsPayCalculationAPI } from "../../../../../config/apiUrl.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { TargetType,ProvincesMatcher } from "../../../../../config/enum.config";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";

const AmountBreakdown = ({ uiType = "normal" }) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const arrearsFormGroupKey = 'arrearsFormKey';
  const paymentformKey = "paymentTypeFormKey";
  const arrearsStartDate = _get(formState, `licence_charges_form.arrearsStartDate`, null);
  const pdmtId = _get(authStatus, "authUser.pdmtId.id", "");

  const arrearsPayCalculationFn = () => {
    let filterQuery = '';
    const targetAmount = _get(formState, `${arrearsFormGroupKey}.amount`, 0);

    const totalArrearsAmount = parseFloat(_get(formState, "licence_charges_form.totArrearsFee", 0)) +
      parseFloat(_get(formState, "licence_charges_form.totArrearsFine", 0)) +
      parseFloat(_get(formState, "licence_charges_form.totArrearsAddition", 0)) -
      parseFloat(_get(formState, "licence_charges_form.totArrearsDeduction", 0)) +
      parseFloat(_get(formState, "licence_charges_form.serviceCharge", 0));

    const arrearsStartDate = dateObjectToString(_get(formState, `${arrearsFormGroupKey}.from`, ""), "YY-mm-dd HH:MM:II");

    if (targetAmount > totalArrearsAmount) {
      const errorObject = [];
      errorObject.push({
        "property": `amount`,
        "message": "Amount Willing to Pay field amount can not be greater than the Total Arrears."
      });
      formAction.setFormErrorFn(arrearsFormGroupKey, errorObject);
    } else {
      //remove errors
      formAction.setFormErrorFn(arrearsFormGroupKey, []);

      const targetType = _get(formState, `${arrearsFormGroupKey}.target_type`, TargetType.AMOUNT);
      const endDate = new Date(_get(formState, `${arrearsFormGroupKey}.to`, null));

      const targetYear = endDate.getFullYear();
      const targetMonth = endDate.getMonth();

      filterQuery = targetType === "A" ?
        `from_date=${arrearsStartDate}&target_type=${TargetType.AMOUNT}&target_amount=${targetAmount}`
        : `from_date=${arrearsStartDate}&target_type=${TargetType.DATE}&target_year=${targetYear}&target_month=${targetMonth}`;

      callApi(`${arrearsPayCalculationAPI.url}?vehicle_no=${_get(formState, "vehicle_search_form.data.vehicle_no", "")}&${filterQuery}&issued_pdmt_id=${pdmtId}`)
        .method("get")
        .headers(true)
        .body({})
        .isMultipart(false)
        .send(async (error, result) => {
          if (!error) {
            formAction.changeInputFn(arrearsFormGroupKey, "to_date", "", _get(result, "data.data.to_date", null));
            formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_fee", "", _get(result, "data.data.total_arrears_fee", null));
            formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_fine", "", _get(result, "data.data.total_arrears_fine", null));
            formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_additions", "", _get(result, "data.data.total_arrears_additions", null));
            formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_deductions", "", _get(result, "data.data.total_arrears_deductions", null));
            formAction.changeInputFn(arrearsFormGroupKey, "service_charge", "", _get(result, "data.data.service_charge", null));
            formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_amount", "", _get(result, "data.data.total_arrears_amount", null));
          }
        });
    }
  };

  const resetFn = () => {
    if (_get(formState, `${arrearsFormGroupKey}.target_type`, null) === TargetType.DATE) {
      formAction.changeInputFn(arrearsFormGroupKey, "amount", "", 0);
    }
    if (_get(formState, `${arrearsFormGroupKey}.target_type`, null) === TargetType.AMOUNT) {
      const serviceCharge = _get(formState, "licence_charges_form.data.service_charge.applied", false)
        ? _get(formState, "licence_charges_form.data.service_charge.charge", 0) : 0;
      formAction.changeInputFn(arrearsFormGroupKey, "amount", "",
        _get(formState, "licence_charges_form.data.arrears_fee_total", 0) +
        _get(formState, "licence_charges_form.data.arrears_fine_total", 0) +
        _get(formState, "licence_charges_form.data.arrears_addition_total", 0) -
        _get(formState, "licence_charges_form.data.arrears_deduction_total", 0) +
        serviceCharge);
    }
    formAction.changeInputFn(arrearsFormGroupKey, "to", "", _get(formState, `licence_charges_form.arrearsEndDate`, ""));
    formAction.changeInputFn(arrearsFormGroupKey, "to_date", "", null);
    formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_amount", "", 0.00);
    formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_fee", "", 0.00);
    formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_fine", "", 0.00);
    formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_additions", "", 0.00);
    formAction.changeInputFn(arrearsFormGroupKey, "total_arrears_deductions", "", 0.00);
    formAction.changeInputFn(arrearsFormGroupKey, "service_charge", "", 0.00);
    formAction.changeInputFn(paymentformKey, "paid_amount", "", 0);
    formAction.changeInputFn(paymentformKey, "cash_amount", "", 0);
    formAction.changeInputFn(paymentformKey, "cheque_amount", "", 0);
    formAction.changeInputFn(paymentformKey, "card_amount", "", 0);
  };


  return (
    <Fragment>
      <div className="amount-breakedown-card">
        {uiType === "normal" || uiType === "quotation" ? (
          <table style={{ width: "100%" }}>
            <tr>
              <td className="text-left"><b>Arrears</b></td>
              <td className="text-right">
                {isEmpty(_get(formState, `licence_charges_form.arrearsStartDate`, "")) || isEmpty(_get(formState, `licence_charges_form.arrearsEndDate`, "")) ?
                  '-'
                  :
                  `${dateObjectToString(_get(formState, `licence_charges_form.arrearsStartDate`, ""), "dd/mm/YY")} To ${dateObjectToString(_get(formState, `licence_charges_form.arrearsEndDate`, ""), "dd/mm/YY")}`
                }
              </td>
            </tr>
         
           <tr>
              <td className="text-left">Fee (LKR)</td>
              <td className="text-right">{_get(formState, "licence_charges_form.data.arrears_fee_total", 0)===0?thousandSeparator(parseFloat(_get(formState, "licence_charges_form.data.arrears_fee_total", 0)).toFixed(2), true):""}</td>
            </tr>
             {_get(formState, `licence_charges_form.data.arrears`, []).map((item) =>  (
              <tr >
                <th>
                  <p className="pl-2" >{ProvincesMatcher[item.province_id]}</p>
                </th>

                <td >
                  <p className="text-right">{thousandSeparator(item.fee, true)}</p>
                </td>
              </tr>
            ))}
             <tr>
              <td className="text-left">Fine (LKR)</td>
              <td className="text-right">{_get(formState, "licence_charges_form.data.arrears_fine_total", 0)===0?thousandSeparator(parseFloat(_get(formState, "licence_charges_form.data.arrears_fine_total", 0)).toFixed(2), true):""}</td>
            </tr>
            {_get(formState, `licence_charges_form.data.arrears`, []).map((item) =>  (
              <tr >
                <th>
                  <p className="pl-2" >{ProvincesMatcher[item.province_id]}</p>
                </th>

                <td >
                  <p className="text-right">{thousandSeparator(item.fine, true)}</p>
                </td>
              </tr>
            ))}
            <tr>
              <td className="text-left">Additions (LKR)</td>
              <td className="text-right">{_get(formState, "licence_charges_form.data.arrears_addition_total", 0)===0?"0.00":""}</td>
            </tr>
            {(_get(formState, "licence_charges_form.data.arrears_addition_total", 0)!==0 )&& _get(formState, `licence_charges_form.data.arrears`, []).map((item) => (item.additions_deductions[0].charge_effect_code === "ADD") && (
              <tr >
                <th>
                  <p className="pl-2" >{item.additions_deductions[0].addition_deduction_name}</p>
                </th>

                <td >
                  <p className="text-right">{thousandSeparator(item.additions_deductions[0].addition_deduction, true)}</p>
                </td>
              </tr>
            ))}
            <tr>
              <td className="text-left">Deductions (LKR)</td>
              <td className="text-right">{_get(formState, "licence_charges_form.data.arrears_deduction_total", 0)===0?`(${thousandSeparator(parseFloat(_get(formState, "licence_charges_form.data.arrears_deduction_total", 0)).toFixed(2), true)})`:""}</td>
            </tr>
            {(_get(formState, "licence_charges_form.data.arrears_deduction_total", 0)!==0) &&_get(formState, `licence_charges_form.data.arrears`, []).map((item) => (item.additions_deductions[0].charge_effect_code === "DED") && (
              <tr >
                <th>
                  <p className="pl-2">{item.additions_deductions[0].addition_deduction_name}</p>
                </th>

                <td >
                  <p className="text-right">{thousandSeparator(item.additions_deductions[0].addition_deduction, true)}</p>
                </td>
              </tr>
            ))} 
            <tr>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td colSpan="2">
                <b>Current Licence </b>
              </td>
            </tr>

            <tr>
              <td className="text-left">Fee (LKR)</td>
              <td className="text-right">{thousandSeparator(parseFloat(_get(formState, "licence_charges_form.totCurrentLicenceFee", 0)).toFixed(2), true)}</td>
            </tr>
            <tr>
              <td className="text-left">Fine (LKR)</td>
              <td className="text-right">{thousandSeparator(parseFloat(_get(formState, "licence_charges_form.totCurrentLicenceFine", 0)).toFixed(2), true)}</td>
            </tr>
            <tr>
              <td className="text-left" style={{ marginTop: '8px' }}>Additions (LKR)</td>
              <td className="text-right">{Number(_get(formState, "licence_charges_form.totAddition", 0)) === 0 ? "0.00" : " "}</td>
            </tr>

            {_get(formState, `licence_charges_form.data.current_licences[0].additions_deductions`, []).map((item) => (item.charge_effect_code === "ADD") && (
              <tr >
                <th>
                  <p className="pl-2" >{item.addition_deduction_name}</p>
                </th>

                <td >
                  <p className="text-right">{thousandSeparator(item.addition_deduction, true)}</p>
                </td>
              </tr>
            ))}
            <tr>
              <td className="text-left" style={{ marginTop: '8px' }}>Deductions (LKR)</td>
              <td className="text-right">{Number(_get(formState, "licence_charges_form.totdeduction", 0)) === 0 ? "(0.00)" : " "}</td>
            </tr>



            {_get(formState, `licence_charges_form.data.current_licences[0].additions_deductions`, []).map((item) => (item.charge_effect_code === "DED") && (
              <tr >
                <th >
                  <p className="pl-2" >{item.addition_deduction_name}</p>
                </th>
                <td >
                  <p className="text-right">({thousandSeparator(item.addition_deduction, true)})</p>
                </td>
              </tr>

            ))}
            <tr>
              <td className="text-left">Service Charge (LKR)</td>
              <td className="text-right">{thousandSeparator(parseFloat(_get(formState, "licence_charges_form.serviceCharge", 0)).toFixed(2), true)}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td className="text-left">
                <b>Total (LKR)</b>
              </td>
              <td className="text-right">
                <b>{thousandSeparator(parseFloat(_get(formState, "licence_charges_form.total", 0)).toFixed(2), true)}</b>
              </td>
            </tr>
          </table>
        ) : (
          <FormWrapper
            setGroupName={arrearsFormGroupKey}
          >
            {uiType !== "carnet" &&
              <Fragment>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td className="text-left">Total Arrears (LKR)</td>
                    <td className="text-right">{
                      thousandSeparator(
                        parseFloat(_get(formState, "licence_charges_form.totArrearsFee", 0)) +
                        parseFloat(_get(formState, "licence_charges_form.totArrearsFine", 0)) +
                        parseFloat(_get(formState, "licence_charges_form.totArrearsAddition", 0)) -
                        parseFloat(_get(formState, "licence_charges_form.totArrearsDeduction", 0)) +
                        parseFloat(_get(formState, "licence_charges_form.serviceCharge", 0))
                        , true)
                    }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      Amount Willing to Pay (LKR)<sup className="text-danger">*</sup>
                    </td>
                    <td className="text-right">
                      <NumberInputBoxWithState
                        elementStyle={"text-right"}
                        labelText={""}
                        formGroupName={arrearsFormGroupKey}
                        inputName={"amount"}
                        isAllowNegative={false}
                        isThousandSeparator={true}
                        decimalScale={2}
                        isFixedDecimalScale={true}
                        isAllowedFn={(value) => {
                          let checkValue = value.value.toString();

                          if (checkValue.includes(".")) {
                            checkValue = checkValue.split(".")[0];
                          }

                          return checkValue.length <= 18;
                        }}
                        isDisabled={_get(formState, `${arrearsFormGroupKey}.target_type`, null) === TargetType.DATE ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">From </td>
                    <td className="text-right">
                      {dateObjectToString(arrearsStartDate, "dd/mm/YY")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      To <sup className="text-danger">*</sup>
                    </td>
                    <td className="text-right">
                      <YearMonthPickerWithState
                        labelText={""}
                        formGroupName={arrearsFormGroupKey}
                        inputName={"to"}
                        minDate={_get(formState, `licence_charges_form.arrearsStartDate`, "")}
                        maxDate={_get(formState, `licence_charges_form.arrearsEndDate`, "")}
                        format={"MM/yyyy"}
                        isDisabled={_get(formState, `${arrearsFormGroupKey}.target_type`, null) === TargetType.AMOUNT ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <RadioButtonsGroupWithState
                        dataList={[
                          {
                            value: TargetType.AMOUNT,
                            label: "Amount",
                          },
                          {
                            value: TargetType.DATE,
                            label: "Date",
                          },
                        ]}
                        formGroupName={arrearsFormGroupKey}
                        inputName={"target_type"}
                        inputValue={TargetType.AMOUNT}
                        onChangeFn={() => {
                          resetFn()
                        }}
                      />
                    </td>
                    <td>
                      <InputButtonWithState
                        elementWrapperStyle={"defaultHalfMarginLeftRight"}
                        btnText={"Calculate"}
                        onClickBtnFn={() => arrearsPayCalculationFn()}
                        isBtnDisabled={
                          _get(formState, `${arrearsFormGroupKey}.target_type`, null) === TargetType.AMOUNT &&
                            !isEmpty(_get(formState, `${arrearsFormGroupKey}.amount`, null))
                            ? false :
                            _get(formState, `${arrearsFormGroupKey}.target_type`, null) === TargetType.DATE
                              // !isEmpty(_get(formState, `${arrearsFormGroupKey}.target_year`, null)) && 
                              // !isEmpty(_get(formState, `${arrearsFormGroupKey}.target_month`, null)) 
                              ? false : true
                        }
                        formGroupName={arrearsFormGroupKey}
                      />
                    </td>
                  </tr>
                </table>
                <PaddingLessUICard cardHeading="Amount Breakdown">
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td className="text-left">Fee (LKR)</td>
                      <td className="text-right">{thousandSeparator(getFlooredFixed(_get(formState, `${arrearsFormGroupKey}.total_arrears_fee`, 0), 2), true)}</td>
                    </tr>
                    <tr>
                      <td className="text-left">Fine (LKR)</td>
                      <td className="text-right">{thousandSeparator(getFlooredFixed(_get(formState, `${arrearsFormGroupKey}.total_arrears_fine`, 0), 2), true)}</td>
                    </tr>
                    {_get(formState, `${arrearsFormGroupKey}.total_arrears_additions`) == 0 ? null :
                      <tr>
                        <td className="text-left">Additions (LKR)</td>
                        <td className="text-right">{thousandSeparator(getFlooredFixed(_get(formState, `${arrearsFormGroupKey}.total_arrears_additions`, 0), 2), true)}</td>
                      </tr>
                    }
                    {_get(formState, `${arrearsFormGroupKey}.total_arrears_deductions`) == 0 ? null :
                      <tr>
                        <td className="text-left">Deductions (LKR)</td>
                        <td className="text-right">({thousandSeparator(getFlooredFixed(_get(formState, `${arrearsFormGroupKey}.total_arrears_deductions`, 0), 2), true)})</td>
                      </tr>
                    }
                    {_get(formState, `${arrearsFormGroupKey}.service_charge`) == 0 ? null :
                      <tr>
                        <td className="text-left">Service Charge (LKR)</td>
                        <td className="text-right">{thousandSeparator(getFlooredFixed(_get(formState, `${arrearsFormGroupKey}.service_charge`, 0), 2), true)}</td>
                      </tr>
                    }
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Calculated To Date</b>
                      </td>
                      <td className="text-right">
                        <b>{
                          isEmpty(_get(formState, `${arrearsFormGroupKey}.to_date`, null)) ? '-' :
                            dateObjectToString(_get(formState, `${arrearsFormGroupKey}.to_date`, null), "dd/mm/YY")}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Calculated Amount to Pay (LKR)</b>
                      </td>
                      <td className="text-right">
                        <b>{thousandSeparator(getFlooredFixed(_get(formState, `${arrearsFormGroupKey}.total_arrears_amount`, 0), 2), true)}</b>
                      </td>
                    </tr>
                  </table>
                </PaddingLessUICard>
              </Fragment>
            }

            {uiType === "carnet" &&
              <table style={{ width: "100%" }}>
                <Fragment>
                  <tr>
                    <td colSpan="2">
                      <b>Amount Breakdown </b>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">Fee (LKR)</td>
                    <td className="text-right">{thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFee", 0), true)}</td>
                  </tr>
                  {/* <tr>
                      <td className="text-left">Fine (LKR)</td>
                      <td className="text-right">{thousandSeparator(_get(formState, "carnet_fee_form.fine", 0), true)}</td>
                    </tr> */}
                  <tr>
                    <td className="text-left">Additions (LKR)</td>
                    <td className="text-right">{Number(_get(formState, "licence_charges_form.totAddition", 0)) === 0 ? "0.00" : " "}</td>
                  </tr>
                  {_get(formState, `licence_charges_form.data.addition_deduction_summary`, []).map((item) => (item.charge_effect_code === "ADD" && item.total !== 0) && (

                    <tr>

                      <th>
                        <p className="pl-2" >{item.addition_deduction_name}</p>
                      </th>

                      <td >
                        <p className="text-right">{thousandSeparator(+item.total, true)}</p>
                      </td>
                    </tr>

                  ))}
                  <tr>
                    <td className="text-left">Deductions (LKR)</td>
                    <td className="text-right">{Number(_get(formState, "licence_charges_form.totdeduction", 0)) === 0 ? "(0.00)" : " "}</td>
                  </tr>
                  {_get(formState, `licence_charges_form.data.addition_deduction_summary`, []).map((item) => (item.charge_effect_code === "DED" && item.total !== 0) && (
                    <tr>
                      <th>
                        <p className="pl-2">{item.addition_deduction_name}</p>
                      </th>
                      <td >
                        <p className="text-right">({item.total ? thousandSeparator(item.total, true) : 0.00})</p>
                      </td>
                    </tr>

                  ))}
                  <tr>
                    <td className="text-left">Service Charge (LKR)</td>
                    <td className="text-right">{thousandSeparator(_get(formState, "licence_charges_form.serviceCharge", 0), true)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <b>Total (LKR)</b>
                    </td>
                    <td className="text-right">
                      <b>{thousandSeparator((Number(_get(formState, `licence_charges_form.totCurrentLicenceFee`, 0)) +
                        Number(_get(formState, `licence_charges_form.totAddition`, 0)) - Number(_get(formState, `licence_charges_form.totdeduction`, 0))), true)}</b>
                    </td>
                  </tr>

                </Fragment>
              </table>
            }
          </FormWrapper>
        )
        }
      </div>
    </Fragment>
  );
};

export { AmountBreakdown };
