import React, { useContext, Fragment } from "react";

import { DataTable, actionBtnTypes, apiResponseStoringType, RawIndexMemo } from "../../../../ui-components/ui-elements/table/DataTable";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { _getInt, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { InputButton, SubmitButton } from "../../../../ui-components/ui-elements/form";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { permissions } from "../../../../../config/permission.config";
import { usePermission } from "../../../../hooks/common-hooks/usePermission.hook";

const emptyFn = (...para) => undefined;
const RowNo = (props) => {
  return (
    <RawIndexMemo
      page_size={_getInt(props.pagingObject, "per_page", 1)}
      current_page={_getInt(props.pagingObject, "current_page", 1)}
      index={props.rowIndex || 0}
    />
  )
}



const PendingSchedule = (props) => {

  const [formState] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);
  const [authState] = useContext(AuthContext);
  const [, checkISAllowedFn] = usePermission();

  const checkEvidenceRemarks = (data) => {
    const idSet = (data.hasOwnProperty('criteria_ids')) ? data.criteria_ids : data.certificate_ids;

    for (const value of idSet) {
      if (value.status === "0") {
        if (data.remarks.find(element => element.id === value.id).remark === "") {
          return false;
        }
      }
    }

    return true;
  };


  return (
    <div className="fullWidthDiv">
      <DataTable
        tableName="My Pending Tasks"
        //apiUrl={`${getTaskListAPI.url}`}
        // dataTableKey={`${getTaskListAPI.key}_tasks`}
        isSetSearchFrom={true}
        //searchFromComponent={<SearchElement dataTableKey={`${getTaskListAPI.key}_tasks`} />}
        defaultSearchFormObject={{
          "element_id": "null",
          "inspection_requirement_id": "null",
          "checklist_id": "null",
          "task_type_id": "null",
          "skipped": "null"
        }}

        tableHeaderList={[
          { displayName: "No", key: "__no__", isSort: false },
          // { displayName: "Check List Code", key: "code", isSort: true },
          { displayName: "Check List Name", key: "checklist_name", isSort: false },
          { displayName: "Check List Type", key: "checklist_type", isSort: false },
          { displayName: "GMP Element", key: "element", isSort: false },
          { displayName: "Inspection Requirement", key: "inspection_requirement", isSort: false },
          { displayName: "Inspect Item", key: "resource_name", isSort: false },
          { displayName: "Scheduled Inspection Date", key: "task_start_date", isSort: false },
          { displayName: "Due Days", key: "due", isSort: false },
        ]}
        isSetAction={true}
        actionTypeList={[
        ]}
        specificComponents={{
          "__no__": RowNo
        }}
      />
    </div>
  );
};

export { PendingSchedule};
