import React from "react";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { InputBoxWithState } from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";

const RotePermitFineInformation = () => {

    return (
        <UICard>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-3"><strong>Current Licence Type</strong></div>
                        <div className="col-md-3">Normal Licence</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"><strong>Current Vehicle Class</strong></div>
                        <div className="col-md-3">Private Coach</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"><strong>Licence Period</strong></div>
                        <div className="col-md-3">2019/10/15</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-3">2020/10/15</div>
                    </div>
                </div>
                <div className="col-md-12 defaultMarginTop">
                    <div className="row">
                        <DataTable
                        tableName={"Route Permit History"}
                        isSetAction={false}
                        isSetTableFooter={false}
                        tableHeaderList={[
                            {
                                displayName: "Route Permit Number",
                                key: "routePermit",
                                isSort: false
                            },
                            {
                                displayName: "VRP Organisation Name",
                                key: "vrpOrganization",
                                isSort: false
                            },
                            {
                                displayName: "Route Permit Issue Date",
                                key: "routePermitIssueDate",
                                isSort: false
                            },
                            {
                                displayName: "Route Permit Expiry Date",
                                key: "routePermitExpDate",
                                isSort: false
                            },
                        ]}
                        tableBodyList={[
                            {
                                routePermit: "02",
                                vrpOrganization: "NTC",
                                routePermitIssueDate: "2019/10/15",
                                routePermitExpDate: "2020/10/15"
                            },
                            {
                                routePermit: "02",
                                vrpOrganization: "NTC",
                                routePermitIssueDate: "2020/10/15",
                                routePermitExpDate: "2021/10/15"
                            }
                        ]}
                    />
                    </div>
                </div>
                <div className="col-md-12 defaultMarginTop">
                    <div className="row">
                        <div className="col-md-3">
                            <InputBoxWithState
                                isRequired={true}
                                labelText={"Fine (LKR)"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </UICard>
    );
};

export default RotePermitFineInformation;