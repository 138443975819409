/*
 * @Author: Madhawa
 * @Date: 2020-08-10 12:18:11
 * @Last Modified by: Madhawa
 * @Last Modified time: 2021-01-10 15:32:21
 */
import React, { useContext, useEffect } from "react";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { getLatestLicenseAPI } from "../../../../../config/apiUrl.config";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { pdmtPaymentCategories } from "../../../../../config/enum.config";
import { vetAPI, insuranceListAPI, vftCompanyListAPI, vrpCompanyListAPI, getLicenceTransactionAPI, getLicenceTransactionRecieptPrintDataAPI, vetCompanyAPI, vehiclePaymentHistoryAPI } from "../../../../../config/apiUrl.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { numberThousandSeperator } from "../../../../../helpers/common-helpers/common.helpers";
import { _getInt } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { RawIndexMemo } from "../../../../ui-components/ui-elements/table/DataTable";
import { isEmpty } from "lodash";
const LastLicenceInformation = (props) => {
    const [formState, formAction] = useContext(FormContext);
    //const request_id = props.row.request_id;
    const {uiType} = props
    const request_id = "99998888";
    const formKey = "approvalRequest_payments";
    const vehicle_no = _get(
        formState,
        "vehicle_search_form.searchKey",
        null
      );
    useEffect(() => {
        if(uiType === 'viewApproval' || uiType === 'APR') {
            getNewData()
         }
        getPaymentDetails()
      }, [])

    const getNewData = () => {
            callApi(`${getLatestLicenseAPI.url}/${uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : props.row.vehicle_no}`)
                        .method("get")
                        .headers(true)
                        .isMultipart(false)
                        .send((error, result) => {
                          if (!error) {
                            console.log('LICENCE-->', result.data.data);
                            formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

                          }
                        });
        }
    

    const getPaymentDetails = () => {
        callApi(`${vehiclePaymentHistoryAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}/true`)
                        .method("get")
                        .headers(true)
                        .isMultipart(false)
                        .send((error, result) => {
                          if (!error) {
                            const newData = result.data.data && result.data.data.length !== 0 ? result.data.data.sort((a,b) =>{
                                const date1 = new Date(a['tx_date_time'])
                                const date2 = new Date(b['tx_date_time'])
                                 return date2 - date1
                              }) : []
                            formAction.changeInputFn('payment_data', "data", "", newData && newData.length !== 0 ? newData[0] : []);

                          }
                        });
    }

    const RowNo = (props) => {
        return (
            <RawIndexMemo
                page_size={_getInt(props.pagingObject, "per_page", 1)}
                current_page={_getInt(props.pagingObject, "current_page", 1)}
                index={props.rowIndex || 0}
            />
        )
    };


    return (
        <div className="row">
            {console.log('FORM_SATATEEE', formState)}
            <div className="col-md-12 col-sm-12 defaultPaddingTopBottom">
          <UICard>
            {console.log('FORRRRRRMMMMMMMMMM',_get(formState, `latest_licence_data.data.documents`, null))}
            <table className={"fullWidthDiv"}>
              {(_get(formState, `latest_licence_data.data.documents`, null) !== null && _get(formState, `latest_licence_data.data.documents`, null).length > 0) ? <thead>
                <tr>
                  <th>Document</th>
                  {/* <th>Status</th> */}
                  <th>Document No</th>
                  <th>Company</th>
                  <th>Issued Date</th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
            :
            <thead>No Document Details Found</thead>  
            }

              <tbody>
              {(_get(formState, `latest_licence_data.data.documents`, null) !== null && _get(formState, `latest_licence_data.data.documents`, null).length > 0) ?
              _get(formState, `latest_licence_data.data.documents`, null).map(doc => {
                return (
                    <tr>
                  <td>{doc.doc_type_code === "INS" ? 'Insurance' : doc.doc_type_code === "VFT" ? 'VFT' : doc.doc_type_code === "VET" ? 'VET' : doc.doc_type_code === "VRP" ? 'VRP' : null}</td>
                  {/* <td>{_get(formState, `insurance_form.isInsurance`, null) ? "Valid" : "Invalid"}</td> */}
                  <td>{doc.doc_ref_no}</td>
                  <td>{doc.doc_company_name}</td>
                  <td>{dateObjectToString(doc.issued_date, "dd/mm/YY") !== '01/01/1970' ? dateObjectToString(doc.issued_date, "dd/mm/YY"):"-"}</td>
                  <td>{dateObjectToString(doc.expiry_date, "dd/mm/YY") !== '01/01/1970' ? dateObjectToString(doc.expiry_date, "dd/mm/YY") : '-'}</td>
                  {/* <td>{dateObjectToString(_get(formState, `insurance_form.exp_date`, null), "dd/mm/YY")}</td> */}
                </tr>
                )
              }) : null
              }
            
              </tbody>
            </table>
          </UICard>


        </div>
            {/* <div className="col-md-12 defaultPaddingTopBottom">
                <DataTable
                    tableName={"Document Validation Details"}

                    isSetTableFooter={false}
                    isSetAction={false}
                    tableHeaderList={[
                        { displayName: "Document", key: "document", isSort: false },
                        { displayName: "Document No", key: "document_no", isSort: false },
                        { displayName: "Company", key: "comany", isSort: false },
                        { displayName: "Issued Date", key: "issuedate", isSort: false },
                        { displayName: "Expiry Date", key: "expiredate", isSort: false },
                    ]}
                    tableBodyList={[
                        {
                            "document": "Doc 1",
                            "document_no": "001",
                            "company": "AIA",
                            "issuedate": "2020/10/10",
                            "expiredate": "2021/10/09"
                        },
                        {
                            "document": "Doc 2",
                            "document_no": "002",
                            "company": "AIA",
                            "issuedate": "2020/10/10",
                            "expiredate": "2021/10/09"
                        },
                    ]}
                />

            </div> */}

            <div className="col-md-8">
                <UICard cardHeading="Payment Details">
                    <FormWrapper
                        setGroupName={formKey}             
                        apiUrl={`${getLicenceTransactionAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
                        isReverseTx={true}
                        onRebuildResponseFn={(response, error) => {
                            if(response && response.length !== 0 && 
                                response[0].transaction_details && response[0].licence_data) { 
                            formAction.changeInputFn(formKey, "payTypeData", "", response[0].transaction_details['payment_type_data']);
                            return {
                                tx_type: response[0].arrears_only == 1 ? 'Arrears' : 'Licence',
                                tx_status: response[0].transaction_details.status === 1 ? 'Success' : 'Failed',
                                licence_type: response[0].licence_type_code,
                                issue_mode: response[0].temp_licence == 1 ? 'Temporary Licence' : 'Full Licence',
                                serial_receipt_no: response[0].transaction_details.id,
                                licence_receipt_no: response[0].licence_number,
                                from: response[0].licence_data.valid_from,
                                to: response[0].licence_data.valid_to,
                                // issued_date: response[0].issued_date,
                                fee: response[0].fee,
                                fine: response[0].fine,
                                addition: response[0].addition,
                                deduction: response[0].deduction,
                                total: response[0].total,
                                service_charge: response[0].service_charge,
                                postal_charge: response[0].postal_charge,
                                // print_required: response[0].print_required,

                                "_onLoad": true
                            }
                        }
                        }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3"><strong>Fee(LKR)</strong></div>
                                    <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.fee`, '0.00')).toFixed(2))}</div>
                                </div>
                                <div className="row">

                                    <div className="col-md-3"><strong>Fine(LKR)</strong></div>
                                    <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.fine`, '0.00')).toFixed(2))}</div>
                                </div>
                                <div className="row">

                                    <div className="col-md-3"><strong>Service Charge(LKR)</strong></div>
                                <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.service_charge`, '0.00')).toFixed(2))}</div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">

                                    <div className="col-md-3"><strong>Additions(LKR)</strong></div>
                                    <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.additions`, '0.00')).toFixed(2))}</div>
                                </div>
                                <div className="row">

                                    <div className="col-md-3"><strong>Deductions(LKR)</strong></div>
                                    <div className="col-md-3 text-md-right">({numberThousandSeperator(Math.abs(Number(_get(formState, `payment_data.data.deductions`, '0.00'))).toFixed(2))}) </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-3"><strong>Total(LKR)</strong></div>
                                    <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.total`, '0.00')).toFixed(2))}</div>
                                </div>
                            </div>

                        </div>
                    </FormWrapper>
                </UICard>
            </div>
            <div className="col-md-4">
                <DataTable

                    isSetTableHeader={false}
                    isSetTableFooter={false}
                    isSetAction={false}
                    elementWrapperStyle={"paymentType"}
                    tableHeaderList={[
                        { displayName: "Payment Type", key: "payment_type_code", isSort: false, onPrintFn: ({payment_type_code})=> payment_type_code ? pdmtPaymentCategories[`${payment_type_code}`] : "-", },
                        { displayName: "Amount(LKR)", key: "amount", isSort: false,isRightAlign:true, onPrintFn: ({amount})=> amount ? numberThousandSeperator(Number(amount).toFixed(2)) : "0.00"},

                    ]}
                    tableBodyList={_get(formState, `${formKey}.payTypeData`, [])}
                />

            </div>
            <div className="col-md-12 defaultPaddingTop">
                <DataTable
                    tableName={"Printed Details"}
                    apiUrl={`${getLicenceTransactionRecieptPrintDataAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
                    isSetTableFooter={false}
                    isSetAction={false}
                    tableHeaderList={[
                        { displayName: "Print Sequence No", key: "__no__", isSort: false },
                        { displayName: "Print Type", key: "is_reprint", isSort: false, onPrintFn: ({is_reprint})=> is_reprint === 1 ? 'Re-print' : is_reprint === 0 ?"New" :""},
                        { displayName: "Date & Time", key: "printed_at", isSort: false, onPrintFn: ({printed_at})=> !isEmpty(printed_at) ? dateObjectToString(printed_at, "dd/mm/YY HH:MM"):"-"},
                        { displayName: "Printed By", key: "print_by_name", isSort: false },
                        { displayName: "Printed At", key: "printed_location_name", isSort: false },
                        { displayName: "Serial Number", key: "serial_number", isSort: false },
                    ]}
                    specificComponents={{
                        "__no__": RowNo
                    }}
                />
            </div>
        </div>
    );
};

export default LastLicenceInformation;