import React, { useState, useEffect } from "react";
import {
  TabsWrapper,
  SubTab,
  tabsWrapperVariants,
  tabsWrapperScrollButtons,
} from "../../../../ui-components/ui-elements/tab/Tabs";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import { UICardWithBackGround } from "../../../../ui-components/ui-elements/common/BaseElements";
import { PrintedEnvelopeUI } from "./includes/PrintedEnvelopeUI";
import { PrintedPendingUI } from "./includes/PrintedPendingUI";
import { PrintedUI } from "./includes/PrintedUI";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

const emptyFun = (...para) => undefined;

export const setThousandSeparator = (value, decimal = 0) => {
    if(decimal != 0){
        value = (+value).toFixed(decimal);
        const result = value.split('.');
        return result[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +'.'+ result[1];
    }else{
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  
}
const WebLicence = ({ setShowHideFooterFN = emptyFun }) => {
  const [getState, setState] = useState({
    currentTab: "tab1",
  });
  useEffect(() => {
    setShowHideFooterFN(true, {
      issueMode: _get(getState.currentObject, `status.issueMode`, "1"),
      isPendingWorkFlow: _get(
        getState.currentObject,
        `status.isPendingWorkFlow`,
        false
      ),
      payArrearsOnly: _get(
        getState.currentObject,
        `status.payArrearsOnly`,
        true
      ),
      currentTab: getState.currentTab,
    });
  }, [getState.currentTab]);

  return (
    <UICardWithBackGround>
      <TabsWrapper
        onChangeFn={(event) =>
          setState({
            ...getState,
            currentTab: event.value,
          })
        }
        elementWrapperStyle={"webLicenceTabWrapper"}
        active={getState.currentTab}
        variant={tabsWrapperVariants.scrollable}
        scrollButtons={tabsWrapperScrollButtons.on}
      >
        <SubTab tabid={"tab1"} header={"Print Pending - Revenue Licence"}>
          <PrintedPendingUI tableHeading={"Print Pending - Revenue Licence"} />
          <div className="row">
            <div className="col-md-12 col-sm-12 text-right defaultMargin">
    
            </div>
          </div>
        </SubTab>
        <SubTab tabid={"tab2"} header={"Print Pending - Envelope"}>
          <PrintedEnvelopeUI tableHeading={"Print Pending - Envelope"} />
          <div className="row">
            <div className="col-md-12 col-sm-12 text-right defaultMargin">
              
            </div>
          </div>
        </SubTab>
        <SubTab tabid={"tab3"} header={"Completed"}>
          <PrintedUI tableHeading={"Completed"} />
          <div className="row">
            <div className="col-md-12 col-sm-12 text-right defaultMargin">
              
            </div>
          </div>
        </SubTab>
      </TabsWrapper>
    </UICardWithBackGround>
  );
};

export { WebLicence };
