/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-30 13:07:24 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-01 15:15:54
 */

import React, { Fragment, useContext } from 'react';

import { CollapseWrapper } from "../../common/BaseElements";
import { FormWrapper } from "../../form";
import { InputButtonWithState } from "../../form";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import {
    _get,
  } from "../../../../../helpers/common-helpers/lodash.wrappers";
  import { webReconciliationAPI, AdditionDeductionListAPI } from '../../../../../config/apiUrl.config';
//   import { vehiclePaymentHistoryAPI, AdditionDeductionListAPI } from "../../../../../config/apiUrl.config";

const emptyFun = (...para) => undefined;

const SearchBoxWrapper = ({
    isSetSearchFrom = false,
    dataTableState = {},
    defaultSearchFormObject = {},
    dataTableKey = "",
    searchFromComponent = null,
    onSetSearchObjectFn = false,
    isSearchWithSelect = false,
    setDataTableStateFn = emptyFun,
    onGetData = emptyFun,
    onClickSearchFn = emptyFun,
    customSearchFunc = null
}) => {
  const [coreState, coreAction] = useContext(CoreContext);
  const [formState, formAction] = useContext(FormContext);

  const formGroupName = 'ViewPublicPortalTransactionsFormKey'

    const clickSearch = () => {
        formAction.changeInputFn(formGroupName, "reconcileAllStatus", "", null);
        _get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).map((value, index) => {
        console.log('11112');

        formAction.changeInputFn(`${index}_reconcileVoidReason`, "reconcileVoidReason", "", undefined);
        formAction.changeInputFn(`${index}_reconcileStatus`, "reconcileStatus", "", undefined);
        })
        console.log('11113');
    }
    const clickReset = () => {
        formAction.changeInputFn(formGroupName, "reconcileAllStatus", "", null);
        _get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).map((value, index) => {
        console.log('11112');

        formAction.changeInputFn(`${index}_reconcileVoidReason`, "reconcileVoidReason", "", undefined);
        formAction.changeInputFn(`${index}_reconcileStatus`, "reconcileStatus", "", undefined);
        })
        coreAction.initDataTableReset(webReconciliationAPI.key);
        console.log('11113');
    }
    const clickResetOtherAddDed = () => {
        formAction.changeInputFn('OtherAdditionDeductionAPIKey_search', "name", "", '');
        formAction.changeInputFn('OtherAdditionDeductionAPIKey_search', "search", "", null);
        coreAction.setDataTable(AdditionDeductionListAPI.key, [], 'done', 1, null, null)
        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
    }
    return (
        <Fragment>
            {
                (isSetSearchFrom) ? (
                    <CollapseWrapper
                        component={"div"}
                        elementStyle={"dataTableSearchBoxWrapper"}
                        isIn={dataTableState.isShowSearch}
                        isUnMountOnExit={true}
                    >
                        <FormWrapper
                            elementStyle="fullWidthDiv"
                            setGroupName={`${dataTableKey}_search`}
                            formGroupLinkWith={dataTableKey}
                            setFormObject={defaultSearchFormObject}
                        >
                            <div className="">                                
                                {searchFromComponent}
                            </div>

                            <div className="fullWidthDiv buttonWrapper">
                                {!isSearchWithSelect && <InputButtonWithState
                                    btnText="Search"
                                    startIcon="mdi mdi-magnify-plus-outline"
                                    elementWrapperStyle={"searchBtn"}
                                    formGroupName={`${dataTableKey}_search`}
                                    onClickBtnFn={(event) => {
                                        let searchObject = event.formObject || {};

                                        if (onSetSearchObjectFn !== false) {
                                            searchObject = onSetSearchObjectFn(event.formObject || {});
                                        }

                                        if(customSearchFunc) {
                                            customSearchFunc()
                                        }

                                        if(onClickSearchFn) {
                                            clickSearch()
                                        }

                                        setDataTableStateFn({
                                            ...dataTableState,
                                            formObject: getDataByFormObject(searchObject)
                                        });
                                        onGetData("searchFrom", searchObject);
                                    }}
                                />}
                                <InputButtonWithState
                                    btnText="Reset"
                                    startIcon="fas fa-sync-alt"
                                    elementWrapperStyle={"refreshBtn"}
                                    formGroupName={`${dataTableKey}_search`}
                                    mergeToForm={defaultSearchFormObject}
                                    onClickBtnFn={(event) => {

                                        let searchObject = defaultSearchFormObject || {};

                                        if (onSetSearchObjectFn !== false) {
                                            searchObject = onSetSearchObjectFn(defaultSearchFormObject || {});
                                        }
                                        if(customSearchFunc) {
                                            clickResetOtherAddDed()
                                        }
                                        if(onClickSearchFn) {
                                            clickReset()
                                        }

                                        setDataTableStateFn({
                                            ...dataTableState,
                                            formObject: searchObject
                                        });
                                        onGetData("searchFrom", searchObject);
                                    }}
                                />
                            </div>

                        </FormWrapper>
                    </CollapseWrapper>
                ) : (null)
            }
        </Fragment>
    )
}

export {
    SearchBoxWrapper
}