/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-30 14:18:33
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-05-06 07:45:00
 */
import React, { Fragment } from "react";

import { Icon } from "../../../ui-elements/common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { screenSizeTypes } from "../../../../../config/template.config";
import Checkbox from "@material-ui/core/Checkbox";

const emptyFun = (...para) => undefined;

/**
 * @Author: Nisal Madusanka(EruliaF)
 * @param {*} param0
 * @param {*} screenType
 * @param {*} size
 */
const getHeaderList = (
  [...tableHeaderList],
  screenType = undefined,
  size = 3,
  start = 0
) => {
  if (screenType === screenSizeTypes.largeDevice.key) {
    return tableHeaderList;
  } else if (screenType === screenSizeTypes.mediumDevice.key) {
    return tableHeaderList.slice(start, size);
  } else {
    return tableHeaderList.slice(start, size);
  }
};

const TableHeader = ({
  tableHeaderList = [],
  customActionHeader,
  dataTableKey = "",
  isSetAction = true,
  isSelectRow = false,
  isSelectAllCheckActive=true,
  shortObject = {},
  maxItemCount = 3,
  screenType = screenSizeTypes.largeDevice.key,
  onShortFn = emptyFun,
  selectAllRowStatus = false,
  onCheckSelectALLFn = emptyFun,
}) => {

  const getHeaderStyles =(screenType,header) =>{
    let styleObj=_get(header,"headerStyle",{})
    if(screenType === screenSizeTypes.largeDevice.key) { styleObj["minWidth"] = _get(header, "fixWidth", 60) }
    return styleObj
  }

  return (
    <thead>
      <tr>

        {
          isSelectRow === true ? (
            <th>
              <div><b>Select All</b></div>
              {isSelectAllCheckActive && 
                <Checkbox
                  className={`dataTableCheckBox`}
                  name={"selectItem"}
                  checked={selectAllRowStatus}
                  onChange={() => onCheckSelectALLFn(!selectAllRowStatus)}
                />
              }
            </th>
          ) : null
        }

        {getHeaderList(tableHeaderList, screenType, maxItemCount).map(
          (header, key) => {
            return (
              <Fragment key={key}>
                {screenType === screenSizeTypes.largeDevice.key ||
                  screenType === screenSizeTypes.mediumDevice.key ? (
                  <Fragment>
                    <th
                      key={key}
                      className={_get(header,"isRightAlign",false)===true ? 'sort header-right-align':'sort'}
                      onClick={() =>
                        _get(header, "isSort", false) === false
                          ? emptyFun()
                          : onShortFn(
                            dataTableKey,
                            header.key,
                            !_get(shortObject, `${header.key}`, false)
                          )
                      }
                      style= {getHeaderStyles(screenType,header)}
                    >
                      {header.displayName}
                      {_get(header, "isSort", false) ? (
                        <Fragment>
                          {_get(shortObject, `${header.key}`, "NONE") ===
                            true ? (
                            <Icon
                              elementStyle="shortArrow upArrow"
                              iconClass={"fas fa-sort-up"}
                            />
                          ) : null}

                          {_get(shortObject, `${header.key}`, "NONE") ===
                            false ? (
                            <Icon
                              elementStyle="shortArrow downArrow"
                              iconClass={"fas fa-sort-down"}
                            />
                          ) : null}

                          {_get(shortObject, `${header.key}`, "NONE") ===
                            "NONE" ? (
                            <Icon
                              elementStyle="shortArrow"
                              iconClass={"fas fa-sort"}
                            />
                          ) : null}
                        </Fragment>
                      ) : null}
                    </th>
                  </Fragment>
                ) : null}
              </Fragment>
            );
          }
        )}
        {screenType === screenSizeTypes.smallDevice.key ? (
          <th key={"mobile_head"} style={{ minWidth: "90%" }}></th>
        ) : null}
        {isSetAction === true ||
          screenType !== screenSizeTypes.largeDevice.key ? (
          <th key={"action"} className='textAlignCenter'>{customActionHeader ? customActionHeader : 'Action'}</th>
        ) : null}
      </tr>
    </thead>
  );
};

export { TableHeader, getHeaderList };
