/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-11 16:45:47
 */

import React, { Fragment } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getCodeName } from "../../../../../helpers/common-helpers/common.helpers";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import { ProvincesByIds } from "../../../../../config/enum.config";

const VehicleInfoDisplay = ({ uiType = "fullInfo", vehicleInfo = {} }) => {
  let columnClass = "col-md-4";
  if (uiType === "vehicle_infor") {
    columnClass = "col-md-6 mt-1";
  }
  return (
    <div className="row">
      <div className={columnClass}>
        <strong>
          {uiType === "vehicle_infor" ? "Chassis Number:" : "Chassis:"}{" "}
        </strong>
        {getLabelValue(_get(vehicleInfo, `chassis_no`, null), true)}
      </div>
      <div className={columnClass}>
        <strong>
        {uiType === "vehicle_infor" ? "Tare Weight (kg):" : "Tare (kg):"}{" "} </strong>
        {getLabelValue(_get(vehicleInfo, `tare_weight`, null))}
      </div>
      <div className={columnClass}>
        <strong>Vehicle Condition: </strong>
        {getLabelValue(_get(vehicleInfo, `vehicle_reg_condition.name`, null))}
      </div>
      <div className={columnClass}>
        <strong>
        {uiType === "vehicle_infor" ? "Engine Number:" : "Engine:"}{" "}</strong>
        {getLabelValue(_get(vehicleInfo, `engine_no`,null), true)}
      </div>
      <div className={columnClass}>
        <strong>
        {uiType === "vehicle_infor" ? "Gross Weight (kg):" : "Gross (kg):"}{" "}
         </strong>
        {getLabelValue(_get(vehicleInfo, `gross_weight`, null))}
      </div>
      <div className={columnClass}>
        <strong>Fuel Type: </strong>
        {getLabelValue(_get(vehicleInfo, `fuel_type.name`, null)) }
      </div>
      <div className={columnClass}>
        <strong>
          {uiType === "vehicle_infor"
            ? "1st Registration Date:"
            : "1st Reg. Date:"}{" "}
        </strong>{" "}
        {dateObjectToString(vehicleInfo.first_reg_date, "dd/mm/YY")}
      </div>
      <div className={columnClass}>
        <strong>Seats (Exp. Driver): </strong>{" "}
        {getLabelValue(_get(vehicleInfo, `no_of_seats`, null))}
      </div>
      <div className={columnClass}>
        <strong>
           {uiType === "vehicle_infor" ? "Vehicle Class:" : "Class:"}</strong>{" "}
        {getLabelValue(_get(vehicleInfo, `vehicle_class.name`, null))}
      </div>
      <div className={columnClass}>
        <strong>Province: </strong>{" "}
        {getLabelValue(
          (getCodeName( ProvincesByIds, _get(vehicleInfo, `province_id`, null)))
          )}
      </div>
      <div className={columnClass}>
        <strong>Manufactured Year: </strong> {getLabelValue(_get(vehicleInfo, `yom`,null))}
      </div>
      {uiType === "vehicle_infor" && (
        <Fragment>
          <div className={columnClass}>
            <strong>Cylinder Capacity: </strong>{" "}
            {getLabelValue(_get(vehicleInfo, `cylinder_capacity`, null))}
          </div>
          <div className={columnClass}>
            <strong>Tyre Size (cm): </strong>{" "}
            {getLabelValue(_get(vehicleInfo, `tyre_size`, null))}
          </div>
          <div className={columnClass}>
            <strong>Wheel Base: </strong> {getLabelValue(_get(vehicleInfo, `wheel_base`, null))}
          </div>
          <div className={columnClass}>
            <strong>Type of body: </strong>{" "}
            {getLabelValue(_get(vehicleInfo, `type_of_body`, null))}
          </div>
          <div className={columnClass}>
            <strong>Make: </strong> {getLabelValue(_get(vehicleInfo, `make`, null))}
          </div>
          <div className={columnClass}>
            <strong>Model: </strong> {getLabelValue(_get(vehicleInfo, `model`, null))}
          </div>
          <div className={columnClass}>
            <div style={{ display: 'flex' }}>
            <strong>Colors: </strong><span >{createListArrayfromcommaSeperatedString(
              _get(vehicleInfo, `colors`, "-")
            )}</span>
            </div>
          </div>


        </Fragment>
      )}
    </div>
  );
};
const createListArrayfromcommaSeperatedString = (string) => {
  if ((string?.length === 0)) {
    return "-";
  }
  let rawArray = string?.split(",");
  // remove if empty
  let processedArray = rawArray?.filter((item) => item.trim().length != 0);
  const listItems = processedArray?.map((item) =>
  <div className="pl-2">{item}</div>
)

  return listItems;
};



export { VehicleInfoDisplay };
