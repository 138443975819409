/*
 * @Author:Gajani Kangesan
 * @Date: 2022-02-17 16:26:00
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:21:41
 */

import React, { Fragment, useContext } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import {
    SubmitButton,
    DatePickerWithState,
    FormWrapper,
    InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { reportGenerateAPI } from "../../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined
const SearchCriteria = ({ reportCode,
    formGroupName,
    setReportgeneratedValues = emptyFn,
    onGenerateSubmit = emptyFn,
    onResetClick = emptyFn
}) => {

    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    let datefrom = new Date();
    let dateto = new Date();
    let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
    let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
    let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
    let generated_by = _get(authStatus, 'authUser.name', "")
    
    let role = _get(authStatus, 'authUser.rolePrefix', "")

   
    return (
        <div>
            <Fragment>
                <UICard>
                    <FormWrapper
                        setGroupName={formGroupName}
                        formGroupLinkWith={formGroupName}
                        onDestroyUnsetFormObject={true}
                        setFormObject={{
                            date_from: null,
                            date_to: null,
                        }}

                    >
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-6 col-sm-12 floatLeft">
                                <DatePickerWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Date From"
                                    inputName={"date_from"}
                                    maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                                    minDate={setminDateRangeFn(_get(formState[formGroupName], "date_to", null),_get(formState[formGroupName], "date_from", null))}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 floatLeft">
                                <DatePickerWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Date To"
                                    inputName={"date_to"}
                                    minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                                    maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "date_from", null))}
                                />
                            </div>
                            <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">
                                <SubmitButton
                                    btnName={"generate"}
                                    btnText={"Generate"}
                                    tooltip={"Search"}
                                    startIcon="fas fa-wrench"
                                    elementWrapperStyle={"searchBtn"}
                                    formGroupName={formGroupName}
                                    isValidate={true}
                                    validationObject={{
                                        fileds: {
                                            date_from: "Date From",
                                            date_to: "Date To",

                                        },
                                        rules: {
                                            date_from: "required",
                                            date_to: "required",

                                        },
                                        message: {}
                                    }}


                                    callApiObject={{
                                        isSetHeaders: true,
                                        method: "get",
                                        multipart: false,
                                        onUpload: false,
                                        responseType: 'text'
                                    }}
                                    apiDataStoringObject={{
                                        setLoader: true,
                                        storingType: "API_RESPONSE_LINKED_FORM",
                                        mergeToSuccessResponse: true,
                                        mergeToErrorResponse: true
                                    }}
                                    onGetAPIEndPointFn={(formObject) => {

                                        if (!isEmptyValue(formObject.date_from)) {
                                            datefrom = dateObjectToString(new Date(formObject.date_from));
                                        }
                                        if (!isEmptyValue(formObject.date_to)) {
                                            dateto = dateObjectToString(new Date(formObject.date_to));
                                        }


                                        return {
                                            url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestFromDate=${datefrom}&report_requestToDate=${dateto}&report_requestProvince=${provinceName}&report_provinceId=${provinceId}&report_generatedUser=${generated_by + "-" +(roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                            key: `${reportGenerateAPI.key}`
                                        }
                                    }}

                                    onChangeRequestBodyFn={(formObject) => {
                                        return getDataByFormObject(formObject);
                                    }}

                                    onResponseCallBackFn={(error, response,) => {
                                        if (response?._statue) {
                                            onGenerateSubmit(response?.data)
                                            setReportgeneratedValues({
                                                datefrom,
                                                dateto,
                                                limit: reportDataLimit,
                                                offset: reportDataOffset,
                                                provinceId: provinceId,
                                                provinceName: provinceName,
                                                reportGeneratedUser: generated_by + "-" +(roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                            })
                                        }


                                    }}

                                />

                                <InputButtonWithState
                                    btnText="Reset"
                                    startIcon="fas fa-sync-alt"
                                    elementWrapperStyle={"refreshBtn"}
                                    formGroupName={formGroupName}
                                    mergeToForm={{
                                        date_from: null,
                                        date_to: null,

                                    }}
                                    onClickBtnFn={(event) => {
                                        formAction.setFormErrorFn(formGroupName);
                                        onResetClick()

                                    }}
                                />


                            </div>
                        </div>
                    </FormWrapper>



                </UICard>






            </Fragment>
        </div>


    );
};

export {
    SearchCriteria
}