/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-10 14:01:54
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-29 18:31:52
 */

const baseUrl = process.env.REACT_APP_BASE_URL;

const mockApiBaseURL = "http://localhost:8086/";

const internalBffBaseURL = process.env.REACT_APP_ERL_INTERNAL_BFF_BASE_URL;
// const internalBffBaseURL = process.env.REACT_APP_ERL_INTERNAL_BFF_BASE_URL_QA;
// const internalBffBaseURL = process.env.REACT_APP_ERL_INTERNAL_BFF_BASE_URL_STAGING;
// const internalBffBaseURL = process.env.REACT_APP_ERL_INTERNAL_BFF_BASE_URL_PRODUCTION;

// const internalBffBaseURL = "http://localhost:3001/";

const internalSockertBaseURL = process.env.REACT_APP_ERL_INTERNAL_SOCKET_BASE_URL;

const internalPrintURL = process.env.REACT_APP_PRINT_URL;

const authServiceClient = process.env.REACT_APP_AUTH_SERVICE_CLIENT;

const authServiceClientSecret = process.env.REACT_APP_AUTH_SERVICE_CLIENT_SECRET;



const ReactAppVerNO = "V0.15.54";

const authTokenStorageKey = "@webAuthKeyINT";

const uploadComponentConfig = {
  txt: "file-txt.png",
  pdf: "file-pdf.png",
  docx: "file-word.png",
  xlsx: "file-excel.png",
};

const validImageTypes = {
  png: "image/png",
  jpg: "image/jpg",
  jpeg: "image/jpeg",
};

const validFileTypes = {
  txt: "text/plain",
  pdf: "application/pdf",
  docx:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export {
  mockApiBaseURL,
  authTokenStorageKey,
  baseUrl,
  internalBffBaseURL,
  internalSockertBaseURL,
  internalPrintURL,
  uploadComponentConfig,
  validImageTypes,
  validFileTypes,
  authServiceClient,
  authServiceClientSecret,
  ReactAppVerNO
};
