/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-05 15:19:48
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 17:41:49
 */
import React, { useEffect, useState, useContext } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { TooltipWrapper } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  InputBoxWithState,
  InputButton,
  SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { NormalLicenceElement } from "./includes/NormalLicenceElement";
import { DuplicateVehicleModel } from "../includes/DuplicateVehicleModel";
import { RequestFor } from "../includes/RequestFor";
import { PendingWorkflow } from "../includes/PendingWorkflow";
import { getDMTDataListAPI, calculateLicenceFeeAPI,AdditionDeductionListAPI, availableSessionListApi, requestTypesListAPI, getAllTask, getLatestLicenseAPI, issueRLValidationAPI, getVehicleMetaDataAPI } from "../../../../../config/apiUrl.config";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { getCodeName, getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { CurrentLicenceStatus, LicenceTypeCodes, LicenceTypeForTopPanel, VehicleStatus } from "../../../../../config/enum.config";
import { isEmpty } from "lodash"
import { Specialmessage } from "../includes/Specialmessage";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { fromObjectToQueryString } from "../../../../../helpers/common-helpers/common.helpers"
const emptyFun = (...para) => undefined;
const NormalLicence = ({
  setShowHideFooterFN = emptyFun,
  setLatestLicenceFN = emptyFun,
  uiType = "normalLicence",
  sessionId,
  counterId,
  vehicleNumber,
  sessionState,
  ...params
}) => {

  const searchFormKey = "vehicle_search_form";
  const pendingWorkflowFormKey = "pending_workflow_key";
  const initialStateForGetState = {
    onPageLoad: true,
    currentObject: {},
    isDuplicateVehicle: false,
    showPendingWorkflow: false,
    pendingWorkflows: [],
    isNoRecordFound: false
  };
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [, uiAction] = useContext(UIContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [getState, setState] = useState(initialStateForGetState);
  const [getLatestLicenceState, setLatestLicenceState] = useState(null);
  const [selectedLictype, setselectedLictype] = useState(LicenceTypeForTopPanel.NU);
  const user_id = _get(authStatus, "authUser.id", "");


  useEffect(() => {
    setShowHideFooterFN(true, {
      issueMode: _get(getState.currentObject, `status.issueMode`, "1"),
      isPendingWorkFlow: _get(getState.currentObject, `status.isPendingWorkFlow`, false),
      payArrearsOnly: _get(getState.currentObject, `status.payArrearsOnly`, true),
    });

    if (_get(params, 'fromParent.uiType', '') === "QUOTATION_UPDATE" || _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW") {
      formAction.changeInputFn(searchFormKey, "searchKey", "", _get(params, 'row.vehicle_no', ''));
      handleKeyDown(_get(params, 'row.vehicle_no', ''))
    }

    coreAction
      .sendRequestFn(
        `${requestTypesListAPI.url}/list`
      )
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: requestTypesListAPI.key,
      })
      .send();

  }, []);


  const handleKeyDown = (event) => {
    formAction.changeInputFn(searchFormKey, "data", "", undefined);
    formAction.changeInputFn('latest_licence_data', "data", "", undefined)
    formAction.changeInputFn('vehicle_search_form', "isTempLicence", "", false)
    formAction.changeInputFn('vehicle_search_form', "documentRequest", "", null)
    formAction.changeInputFn('vehicle_search_form', "isNonUserLicence", "", false)
    formAction.changeInputFn('licence_validity_period', "isReIssuance", "", false)
    formAction.changeInputFn('licence_validity_period', "requestedLicenceEndDate", "", null)
    formAction.changeInputFn('licence_validity_period', "isLicenceEndDate", "", true)
    formAction.changeInputFn('licence_validity_period', "start_onLoad", "", true)
    formAction.changeInputFn('licence_validity_period', "_onLoad", "", true)
    formAction.changeInputFn("additions_deductions", "selected_ids", "", [])
    coreAction.setDataTable(AdditionDeductionListAPI.key, [], 'done', 1, null, null);
    
    coreAction.sendRequestFn(`${getDMTDataListAPI.url}/${event}`)
    .isMultipart(false)
    .method("get")
    .setLoader(true)
    // .setInitStoring("none", null)
    .setInitStoring(apiResponseStoringType.setResponse, {
      responseKey:  getDMTDataListAPI.key,
    })
    .headers(true)
    .send((error, response) => {
      searchVehicle(error, response, event)
    });



    // callApi(`${getDMTDataListAPI.url}/${event}`)
    //   .method("get")
    //   .headers(true)
    //   .isMultipart(false)
    //   .send((error, response) => {
    //     searchVehicle(error, response, event)
    //   });

  };

  // for after payment Success  
  useEffect(() => {
    if (_get(formState, `${searchFormKey}.shouldVehicleRefresh`, false)) {
      formAction.changeInputFn('vehicle_search_form', "data", "", undefined)
      formAction.changeInputFn('latest_licence_data', "data", "", undefined)
      formAction.changeInputFn('vehicle_search_form', "isTempLicence", "", false)
      formAction.changeInputFn('licence_validity_period', "isReIssuance", "", false)
      formAction.changeInputFn('vehicle_search_form', "documentRequest", "", null)
      setselectedLictype(LicenceTypeForTopPanel.NU)
      setState(initialStateForGetState);
      setLatestLicenceState(null);
      window.scrollTo(0, 0);
    }
  }, [_get(formState, `${searchFormKey}.shouldVehicleRefresh`, false)]);

  const getLatestLicenceDetail = (vehicle_No) => {
    callApi(`${getLatestLicenseAPI.url}/${vehicle_No}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

          setLatestLicenceState(result.data.data,)
          setLatestLicenceFN(result.data.data);

        }
      });
  }

  const getPendingWorkFlowDetails = () => {
    coreAction
      .sendRequestFn(`${getAllTask.url}?vehicle_id=${_get(formState, `${searchFormKey}.searchKey`, "")}&status=PEN&assigned_user_id=notnull&sort_by=created_at|desc`)
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: pendingWorkflowFormKey,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          setState({ ...getState, pendingWorkflows: result.data })
          let count = _get(result, "data.data", []).filter(item => item.request_type === "add_vcl")
          formAction.changeInputFn(pendingWorkflowFormKey, "workflows", "", result.data.data);
          formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", false);
          formAction.changeInputFn(pendingWorkflowFormKey, "addCount", "", count);
        } else {

        }
       
      });
     
  }

  const getVehileMetaDataFn = (vehicle_No, current_license_type) => {
    callApi(`${getVehicleMetaDataAPI.url}?vehicle_no=${vehicle_No}&requested_licence_type=${current_license_type}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('vehicle-meta-data', "data", "", result.data.data);
          // _get(formState,"vehicle-meta-data.data.allow_temp",false)
          // _get(formState,"vehicle-meta-data.data.allow_re_issue",false)
          // _get(formState,"vehicle-meta-data.data.eligible",false)
          // _get(formState,"vehicle-meta-data.data.vet_validation",false)==="MAN"
            // _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")==="MAN"

        }
      });
  }

  const searchVehicle = (error, response, event) => {
    //#region function start
    if (!error) {

      if (_get(response.data.data, `current_license_type.code`, "") === LicenceTypeCodes.NORMAL_LICENCE ||
        _get(response.data.data, `current_license_type.code`, "") === LicenceTypeCodes.NON_USER_LICENCE ||
        _get(response.data.data, `current_license_type.code`, "") === LicenceTypeCodes.FREE_LICENCE) {

        formAction.changeInputFn(searchFormKey, "data", "", response.data.data);
        formAction.changeInputFn('licence_validity_period', "requestedLicenceEndDate", "", null)
        formAction.changeInputFn('licence_validity_period', "isLicenceEndDate", "", true)
        formAction.changeInputFn('licence_validity_period', "start_onLoad", "", true)
        formAction.changeInputFn('licence_validity_period', "_onLoad", "", true)
        formAction.changeInputFn(searchFormKey, "licence_code", "", response.data.data.current_license_type.code);
        formAction.changeInputFn(searchFormKey, "shouldVehicleRefresh", "", false);
        if (_get(response, "data.data.current_license_type.code", null) === "NU") {
          formAction.changeInputFn('vehicle_search_form', "isNonUserLicence", "", true)
        }
        setState(prevState => {
          return {
            ...prevState,
            currentObject: response.data.data,
            onPageLoad: false,

          };
        });
        getLatestLicenceDetail(event)
        getVehileMetaDataFn(event, response.data.data.current_license_type.code)
        //getCounterSerialDetail()

        if (_get(response, "data.data.errorCode", 0) > 0) {
          uiAction.setFlashMessage({
            status: true,
            message: `Failed to retrieve update from DMT. Vehicle data may be outdated.Refer the special messages for more details`,
            messageType: "error",
          });
        }
        return
      }
      else {
        uiAction.setFlashMessage({
          status: true,
          message: `This vehicle is not allowed to get Normal/ Non-User/ Free Licence.Current licence type : ${LicenceTypeForTopPanel[_get(response.data.data, `current_license_type.code`, "")]}`,
          messageType: "error",
        });
        setState(prevState => {
          return {
            ...prevState,
            currentObject: null,
            onPageLoad: false,
            isNoRecordFound: true,
          };
        });
      }
    } else {

      let errorToPop = 'Vehicle not found!';

      if (_get(error, 'data.body.errors.details[0].message', '') === 'DMT_API_ERROR') {
        errorToPop = 'DMT Service Error';
      }

      if (_get(error, 'data.body.errors.details[0].message', '') === 'VEHICLE_INCOM_DATA_IN_DMT') {
        errorToPop = 'Mandatory Vehicle Information Missing in DMT';
      }

      uiAction.setFlashMessage({
        status: true,
        message: errorToPop,
        messageType: "error",
      });
      setState(prevState => {
        return {
          ...prevState,
          currentObject: null,
          onPageLoad: false,
          isNoRecordFound: true,
        };
      });
    }
    //#endregion function end
  }

  const refreshMainScreenDataFn = (event) => {
    //#region start 
    getLatestLicenceDetail(_get(formState, `${searchFormKey}.searchKey`, ""))
    formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", true);
    getPendingWorkFlowDetails();
    getVehileMetaDataFn(_get(formState, `${searchFormKey}.searchKey`, ""), _get(formState, `${searchFormKey}.data.current_license_type.code`, ""))
    //#endregion end
  };

  const onLicTypeChange = (event) => {
    setselectedLictype(event)

    callApi(`${getDMTDataListAPI.url}/${_get(formState, 'vehicle_search_form.searchKey', null)}?licence_type=${event}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, response) => {
        if (!error) {

          if (_get(response.data.data, `current_license_type.code`, "") === LicenceTypeCodes.NORMAL_LICENCE ||
            _get(response.data.data, `current_license_type.code`, "") === LicenceTypeCodes.NON_USER_LICENCE ||
            _get(response.data.data, `current_license_type.code`, "") === LicenceTypeCodes.FREE_LICENCE) {
            formAction.changeInputFn(searchFormKey, "data", "", response.data.data);
            formAction.changeInputFn(searchFormKey, "shouldVehicleRefresh", "", false);
            formAction.changeInputFn('vehicle_search_form', "isNonUserLicence", "", true)
            setState(prevState => {
              return {
                ...prevState,
                currentObject: response.data.data,
                onPageLoad: false,

              };
            });
          }
        }
      });
    formAction.changeInputFn('licence_validity_period', "start_onLoad", "", true)
    formAction.changeInputFn('licence_validity_period', "_onLoad", "", true)
    formAction.changeInputFn("licence_charges_form", "_onLoad", "", true);
    getVehileMetaDataFn(_get(formState, `${searchFormKey}.searchKey`, ""),event)
    getLatestLicenceDetail(_get(formState, `${searchFormKey}.searchKey`, ""))
    formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", true);
    getPendingWorkFlowDetails();
    formAction.changeInputFn(searchFormKey, "licence_code", "", event);
  };


  return (
    <div className="normalLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper">
          <FormWrapper
            setGroupName={searchFormKey}
            formGroupLinkWith={searchFormKey}
            onDestroyUnsetFormObject={true}
            setFormObject={{
              searchKey: _get(formState, `${searchFormKey}.searchKey`, ""),
            }}
          >

            <div className={"searchInputWrapper"}>
              <label required={true} className="control-labe">Vehicle ID: <span style={{ color: "red" }}>*</span></label>

              <InputBoxWithState
                inputName={"searchKey"}
                formGroupName={searchFormKey}
                elementStyle="marginTopBottomNone"
                elementWrapperStyle={"searchInputBoxWrapper"}
                onChangeTxtFn={(eventData) => {
                  formAction.changeInputFn(searchFormKey, "data", "", undefined)
                  setselectedLictype(LicenceTypeForTopPanel.NU)
                  formAction.changeInputFn('latest_licence_data', "data", "", undefined)
                  formAction.changeInputFn('vehicle_search_form', "isTempLicence", "", false)
                  formAction.changeInputFn('vehicle_search_form', "documentRequest", "", null)
                  formAction.changeInputFn('vehicle_search_form', "isNonUserLicence", "", false)
                  formAction.changeInputFn('licence_validity_period', "isReIssuance", "", false)
                  formAction.changeInputFn('licence_validity_period', "start_onLoad", "", true)
                  formAction.changeInputFn('licence_validity_period', "_onLoad", "", true)
                  formAction.changeInputFn('licence_validity_period', "requestedLicenceEndDate", "", null)
                  formAction.changeInputFn('licence_validity_period', "isLicenceEndDate", "", true)
                  

                  setState(initialStateForGetState);
                  setLatestLicenceState(null);

                  //To change to Upper Case and not allowing whitespaces
                  if (eventData) {
                    formAction.changeInputFn(searchFormKey, "searchKey", "", eventData.value.toUpperCase()?.trim());
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter")
                    handleKeyDown(event.target.value)
                }}
                onFocus={(event) => event.target.select()}
                maxLength={20}
                isDisabled={_get(params, 'fromParent.uiType', '') === "QUOTATION_UPDATE" || _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" ? true : false}
              />

              <SubmitButton
                btnText={"Search"}
                formGroupName={searchFormKey}
                tooltip={"Search"}
                startIcon="mdi mdi-magnify-plus-outline"
                isValidate={true}
                validationObject={{
                  fileds: {
                    "searchKey": "Vehicle Id",
                  },
                  rules: {
                    "searchKey": "required",
                  },
                  message: {}
                }}
                onGetAPIEndPointFn={(formState) => {
                  return {
                    url: `${getDMTDataListAPI.url}/${formState.searchKey}`,
                    key: getDMTDataListAPI.key
                  }
                }}
                callApiObject={{
                  isSetHeaders: true,
                  method: "get",
                }}
                apiDataStoringObject={{
                  setLoader: true,
                  storingType: "none"
                }}
                onChangeRequestBodyFn={(formObject) => {
                  formAction.changeInputFn(searchFormKey, "data", "", undefined);
                  formAction.changeInputFn('latest_licence_data', "data", "", undefined)
                  formAction.changeInputFn('vehicle_search_form', "isTempLicence", "", false)
                  formAction.changeInputFn('vehicle_search_form', "documentRequest", "", null)
                  formAction.changeInputFn('vehicle_search_form', "isNonUserLicence", "", false)
                  formAction.changeInputFn('licence_validity_period', "isReIssuance", "", false)
                  formAction.changeInputFn('licence_validity_period', "requestedLicenceEndDate", "", null)
                  formAction.changeInputFn('licence_validity_period', "isLicenceEndDate", "", true)
                  formAction.changeInputFn('licence_validity_period', "start_onLoad", "", true)
                  formAction.changeInputFn('licence_validity_period', "_onLoad", "", true)
                  formAction.changeInputFn("additions_deductions", "selected_ids", "", [])
                  coreAction.setDataTable(AdditionDeductionListAPI.key, [], 'done', 1, null, null);

                  setselectedLictype(LicenceTypeForTopPanel.NU)
                  setState(initialStateForGetState);

                  return getDataByFormObject(formObject);
                }}

                onResponseCallBackFn={(error, response) => {
                  searchVehicle(error, response, _get(formState, 'vehicle_search_form.searchKey', null))


                }}
              />
            </div>
          </FormWrapper>
        </div>
        {/* && formState.searchKey === '' */}
        {(getState.currentObject !== null ||
          getState.currentObject !== "none") &&
          getState.onPageLoad !== true ? (
          <div className="statusWrapper">
            <ul className="statusUL">
              {getState.onPageLoad !== true && !isEmpty(_get(formState, 'vehicle_search_form.searchKey', null)?.trim()) ? (
                <FormWrapper
                  elementStyle="btnFormWrapper"
                  setGroupName={pendingWorkflowFormKey}
                  apiUrl={`${getAllTask.url}?vehicle_id=${_get(formState, `${searchFormKey}.searchKey`, "")}&status=PEN&assigned_user_id=notnull&sort_by=created_at|desc`}
                  onRebuildResponseFn={(response) => {
                    setState({ ...getState, pendingWorkflows: response })
                    let count = response.filter(item => item.request_type === "add_vcl")
                    return {
                      workflows: response,
                      addCount: count,
                      pdmtId: response.find(item => item.request_type === "add_vcl")?.pdmt_id,
                      "_onLoad": false
                    }
                  }
                  }
                >
                  <li className="setBorder">
                    <InputButton
                      btnName="pendingWorkflow"
                      isBtnDisabled={_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length === 0 || _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW"}
                      btnText={"Pending Workflows"}
                      onClickBtnFn={() =>
                        setState({
                          ...getState,
                          showPendingWorkflow: true,
                        })
                      }
                    />
                  </li>
                </FormWrapper>
              ) : null}
              <li className="setBorder">
                <TooltipWrapper description={"Current Vehicle Status for Licence Issuance"}>
                  <span className={`${_get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")}`}>
                    {_get(getState, `currentObject`, '-') ? getCodeName(VehicleStatus,_get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")) : ''}
                  </span>
                </TooltipWrapper>
              </li>

              <li className="setBorder">
                <TooltipWrapper description={"Current Licence Status"}>
                  <span className={`licence-${_get(getLatestLicenceState, `current_licence_status`, 'NL')}`}>
                    {_get(getState, `currentObject`, '-') ? getCodeName(CurrentLicenceStatus, _get(getLatestLicenceState, `current_licence_status`, 'NL')) : null}
                  </span>
                </TooltipWrapper>
              </li>

              {(
                _get(getLatestLicenceState, `status`, null) === 'OPN' && _get(getLatestLicenceState, `print_count`, null) === 0
              ) ? (
                <li className="setBorder">
                  <TooltipWrapper description={"Printing Status"}>
                    <span>
                      Print Pending
                    </span>
                  </TooltipWrapper>
                </li>
              ) : null}

              {_get(getLatestLicenceState, `temp_licence`, 0) === 1 || _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "TMP" ? (
                <li className="setBorder">
                  <TooltipWrapper description={"Current Licence Issue Mode"}>
                    <span>
                      {_get(getState, `currentObject`, null) ? 'Temp. Licence' : null}
                    </span>
                  </TooltipWrapper>
                </li>
              ) : null}
              {_get(formState, "pending_workflow_key._onLoad", "") === false &&
                <li className="setBorder">

                  <TooltipWrapper description={"New Licence Type"}>
                    {
                      _get(formState, 'vehicle_search_form.isNonUserLicence', "", false) === true ?
                        (
                          <select name="licType" id="licType" value={selectedLictype} onChange={e => onLicTypeChange(e.target.value)}>
                            <option value="NU">
                              {LicenceTypeForTopPanel.NU}
                            </option>
                            <option value="NO">
                              {LicenceTypeForTopPanel.NO}
                            </option>
                          </select>
                        ) :
                        (_get(formState, `${searchFormKey}.data.current_license_type.code`, "") === LicenceTypeCodes.NORMAL_LICENCE ||
                          _get(formState, `${searchFormKey}.data.current_license_type.code`, "") === LicenceTypeCodes.FREE_LICENCE) ?
                          (
                            <select disabled>
                              <option>
                                {LicenceTypeForTopPanel[_get(formState, `${searchFormKey}.data.current_license_type.code`, "")]}
                              </option>
                            </select>
                          ) :
                          (
                            <select disabled>
                              <option>N/A</option>
                            </select>
                          )
                    }
                  </TooltipWrapper>
                </li>}
              <li>
                {
                  (_get(formState, "pending_workflow_key._onLoad", false) === false && _get(formState, `${pendingWorkflowFormKey}.addCount`, []).length === 0 &&
                    _get(params, 'fromParent.uiType', '') !== "QUOTATION_VEHICLE_DETAILS_VIEW") &&
                    _get(params, 'fromParent.uiType', '') !== "QUOTATION_UPDATE" &&
                    _get(params, 'fromParent.uiType', '') !== "QUOTATION_CREATE" ?
                    <RequestFor
                      sessionId={sessionId}
                      counterId={counterId}
                      refreshMainScreenDataFn={() => refreshMainScreenDataFn()} />
                    : null
                }

              </li>
            </ul>
          </div>
        ) : null}
      </div>
      {getState.isNoRecordFound === true ?
        _get(formState, `${pendingWorkflowFormKey}.addCount`, []).length !== 0 &&
        <div className="col-md-6">
          <div>
            <Specialmessage provinceId={_get(formState, `${pendingWorkflowFormKey}.pdmtId`, "")} vehicleNo={_get(formState, 'vehicle_search_form.searchKey', null)} />
          </div>
        </div>
        : null}


      {
        getState.onPageLoad === false ? (
          <div className="fullWidthDiv">
            {getLatestLicenceState ?
              <NormalLicenceElement
                getState={getState}
                sessionState={sessionState}
                setState={setState}
                getLatestLicenceState={getLatestLicenceState}
                uiType={uiType}
                params={params}
                getVehicleStatusCode={_get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")}
                //refreshIssueValidFn={refreshIssueValidFn}
                setShowHideFooterFN={(status, mergeWithPerent) =>
                  setShowHideFooterFN(status, mergeWithPerent)
                }
              /> : null}
          </div>
        ) : null
      }

      <DuplicateVehicleModel
        getState={getState}
        setState={setState}
        setShowHideFooterFN={setShowHideFooterFN}
      />

      <PendingWorkflow
        getState={getState}
        setState={setState}
        setShowHideFooterFN={setShowHideFooterFN}
      />
    </div>
  );
};




export default NormalLicence;
