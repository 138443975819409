import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from '../../../../../config/permission.config';

function License() {
    const [authStatus, authAction] = useContext(AuthContext);
    const ViewPermission = _get(authStatus, 'authUser.permissions', "");
    let FR16, FR17, FR18, FR19, FR20, FR21, FR22, FR23, FR24, FR25, FR26
    ViewPermission.forEach(item => {
        if (item.permission === permissions.VIEW_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE.permissions[0]) {
            FR16 = true
        }
        else if (item.permission === permissions.VIEW_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES.permissions[0]) {
            FR17 = true
        }
        else if (item.permission === permissions.VIEW_FR18_REVERSED_REISSUED_LICENCE_REPORT.permissions[0]) {
            FR18 = true
        }
        else if (item.permission === permissions.VIEW_FR19_VEHICLE_LICENCE_HISTORY_REPORT.permissions[0]) {
            FR19 = true
        }
        else if (item.permission === permissions.VIEW_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT.permissions[0]) {
            FR20 = true
        }
        else if (item.permission === permissions.VIEW_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT.permissions[0]) {
            FR21 = true
        }
        else if (item.permission === permissions.VIEW_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT.permissions[0]) {
            FR22 = true
        }
        else if (item.permission === permissions.VIEW_FR23_NON_LICENCED_VEHICLES_REPORT.permissions[0]) {
            FR23 = true
        }
        else if (item.permission === permissions.VIEW_FR24_USER_LICENCE_ISSUED_COUNT_REPORT.permissions[0]) {
            FR24 = true
        }
        else if (item.permission === permissions.VIEW_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE.permissions[0]) {
            FR25 = true
        }
        else if (item.permission === permissions.VIEW_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER.permissions[0]) {
            FR26 = true
        }

    });



    return (
        <UICard>
            < ol type="1">
                <div className="App">
                    {FR16 &&
                        <li>
                            <Link to="/summary-of-vehicle-class-report">
                                <strong>Summary of Vehicle Classes Report - Licence Issuance</strong>
                            </Link>
                            <br />
                            <p> This is the Summary of Vehicle Classes Report - Licence Issuance</p>
                            <br />
                        </li>

                    }
                    {FR17 &&
                        <li>
                            <Link to="/summary-of-vehicle-class-report-other-provinces">
                                <strong>Summary of Vehicle Classes Report - Licence Issuance From Other Provinces</strong></Link>
                            <br />
                            <p> This is the Summary of Vehicle Classes Report - Licence Issuance From Other Provinces</p>
                            <br />
                        </li>
                    }
                    {FR18 &&
                        <li>
                            <Link to="/reversed-license-report">
                                <strong>Reversed / Reissued Licence Report</strong></Link>
                            <br />
                            <p> This is the Reversed / Reissued Licence Report</p>
                            <br />
                        </li>
                    }
                    {FR19 &&
                        <li>
                            <Link to="/vehicle-license-history-report">
                                <strong> Vehicle Licence History Report</strong></Link>
                            <br />
                            <p> This is the Vehicle Licence History Report</p>
                            <br />
                        </li>
                    }
                    {FR20 &&
                        <li>
                            <Link to="/issued-license-vehicle-class-detail-report">
                                <strong> Issued Licence Vehicle class Detail Report</strong></Link>
                            <br />
                            <p> This is the Issued Licence Vehicle class Detail Report</p>
                            <br />
                        </li>
                    }
                    {FR21 &&
                        <li>
                            <Link to="/first-license-issued-vehicle-details-report">
                                <strong> First Licence Issued Vehicle Details Report</strong></Link>
                            <br />
                            <p> This is the First Licence Issued Vehicle Details Report</p>
                            <br />
                        </li>
                    }
                    {FR22 &&
                        <li>
                            <Link to="/issued-license-count-summary-report">
                                <strong> Issued Licence Count Summary Report</strong></Link>
                            <br />
                            <p> This is the Issued Licence Count Summary Report</p>
                            <br />
                        </li>
                    }
                    {FR23 &&
                        <li>
                            <Link to="/non-licensed-vehicles-report">
                                <strong> Non Licenced Vehicles Report</strong></Link>
                            <br />
                            <p> This is the Non Licenced Vehicles Report</p>
                            <br />
                        </li>
                    }
                    {FR24 &&
                        <li>
                            <Link to="/user-license-issued-counter-report"
                            ><strong> User Licence Issued Counter Report</strong></Link>
                            <br />
                            <p> This is the User Licence Issued Counter Report</p>
                            <br />
                        </li>
                    }
                    {FR25 &&
                        <li>
                            <Link to="/eco-test-certificate-report">
                                <strong> Eco Test Certificate Report - Licence Issuance</strong></Link>
                            <br />
                            <p> This is the Eco Test Certificate Report - Licence Issuance</p>
                            <br />
                        </li>
                    }
                    {FR26 &&
                        <li>
                            <Link to="/eco-test-certificate-report-other-provinces"><strong> Eco Test Certificate Report - Licence Issuance From Other Provinces</strong></Link>
                            <br />
                            <p> This is the Eco Test Certificate Report - Licence Issuance From Other Provinces</p>
                        </li>

                    }
                </div>
            </ ol>
        </UICard>
    );
};

const ReportLicense = () => {
    return (
        <DashboardTemplate
            pageTitle="Licence Details Reports"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
                {
                    //urlTo: "/report",
                    displayName: "Licence Details Reports list",
                },
            ]}
        >
            <License />
        </DashboardTemplate>
    );
};

export { ReportLicense };