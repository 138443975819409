import React, { Fragment, useContext } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";

import {
  FormWrapper,
  SubmitButton,
  DatePickerWithState,
  InputBoxWithState,
  InputButtonWithState,
} from "../../../../../../ui-components/ui-elements/form";
import { reportGenerateAPI } from "../../../../../../../config/apiUrl.config";
import { reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { isEmptyValue } from "../../../../../../../helpers/common-helpers/common.helpers";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined

const VehicleLicenseHistorySearchCriteria = ({
  reportCode,
  formGroupName,
  setReportgeneratedValues = emptyFn,
  onGenerateSubmit = emptyFn,
  onResetClick = emptyFn,
}) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
  let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
  let authorityName = _get(authStatus, 'authUser.authorityName', "");
  let generated_by = _get(authStatus, 'authUser.name', "")
  let role = _get(authStatus, 'authUser.rolePrefix', "")

  let datefrom ="";
  let dateto = "";
  let vehicleNo;
  let address;
  let email = "";

  


  return (
    <Fragment>
      <UICard>
        <FormWrapper
          setGroupName={formGroupName}
          formGroupLinkWith={formGroupName}
          onDestroyUnsetFormObject={true}
          setFormObject={{
            date_from: null,
            date_to: null,
            vehicle_no: '',
            address: '',
            email: '',
          }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <InputBoxWithState
                labelText={"Vehicle ID"}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                inputName={"vehicle_no"}
                maxLength={"20"}
                isRequired={true}
                helperText={''}
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <InputBoxWithState
                labelText={"Address To"}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                inputName={"address"}
                maxLength={"200"}
                isRequired={true}
                isMultiline={true}
              />
            </div>

          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 floatLeft">
              <DatePickerWithState
                isRequired={false}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                labelText="Date From"
                inputName={"date_from"}
                maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                minDate={new Date(1970, 1, 1)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 floatRight">
              <DatePickerWithState
                isRequired={false}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                labelText="Date To"
                inputName={"date_to"}
                minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                maxDate={new Date()}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <InputBoxWithState
                labelText={"Email Address"}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                inputName={"email"}
                maxLength={"200"}
                inputType={"email"}
                isMultiline={true}
              />
            </div>
          </div>

          <div className="col-md-12 col-sm-12 mt-2 text-center report-btn-wrapper">
            <SubmitButton
              btnName={"generate"}
              btnText={"Generate"}
              startIcon="fas fa-wrench"
              formGroupName={formGroupName}
              isValidate={true}
              validationObject={{
                fileds: {
                  address: "Address To",
                  vehicle_no: "Vehicle ID",
                  email: "Email Address"
                },
                rules: {
                  address: "required",
                  vehicle_no: "required",
                  email: "email"
                },
                message: {}
              }}
              callApiObject={{
                isSetHeaders: true,
                method: "get",
                multipart: false,
                onUpload: false,
                responseType: 'text'
              }}
              apiDataStoringObject={{
                setLoader: true,
                storingType: "API_RESPONSE_LINKED_FORM",
                mergeToSuccessResponse: true,
                mergeToErrorResponse: true
              }}
              onGetAPIEndPointFn={(formObject) => {

                if (!isEmptyValue(formObject.date_from)) {
                  datefrom = dateObjectToString(new Date(formObject.date_from));
                }
                if (!isEmptyValue(formObject.date_to)) {
                  dateto = dateObjectToString(new Date(formObject.date_to));
                }
                if (!isEmptyValue(formObject.vehicle_no)) {
                  vehicleNo = formObject.vehicle_no;
                }
                if (!isEmptyValue(formObject.email)) {
                  email = formObject.email;
                  email = (email == null || "") ? '-' : email
                }
                if (!isEmptyValue(formObject.address)) {
                  address = formObject.address;
                }

                return {
                  url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_fromDate=${datefrom}&report_toDate=${dateto}&report_email=${email}&report_address=${address}&report_vehicle_no=${vehicleNo}&report_provinceName=${provinceName}&report_authorityName=${authorityName}&report_dateFormatDisplay=dd/MM/yyyy&report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                  key: `${reportGenerateAPI.key}`
                }
              }}

              onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
              }}

              onResponseCallBackFn={(error, response,) => {
                if (response?._statue) {
                  onGenerateSubmit(response?.data)
                  setReportgeneratedValues({
                    datefrom,
                    dateto,
                    address,
                    email,
                    vehicleNo,
                    limit: reportDataLimit,
                    offset: reportDataOffset,
                    reportGeneratedUser: generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                  })
                }
              }}
            />
            <InputButtonWithState
              btnName="refresh"
              startIcon="fas fa-sync-alt"
              formGroupName={formGroupName}
              btnText="Reset"
              elementWrapperStyle={"refreshBtn"}
              mergeToForm={{
                date_from: null,
                date_to: null,
                vehicle_no: '',
                address: '',
                email: '',
              }}
              tooltip="Reset"
              onClickBtnFn={(value) => {
                onResetClick()
                formAction.setFormErrorFn(formGroupName)
              }}
            />
          </div>
        </FormWrapper>
      </UICard>
      <br />
    </Fragment>

  );
};

export {
  VehicleLicenseHistorySearchCriteria
}
