import React, { Fragment, useContext, useState, useEffect } from "react";

import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DataTable, actionBtnTypes } from "../../../../ui-components/ui-elements/table/DataTable";
import {
    InputBox,
    InputBoxWithState,
    DatePickerWithState,
    SelectBoxWithState
 } from "../../../../ui-components/ui-elements/form";
 import { NumberInputBoxWithState} from "../../../../ui-components/ui-elements/form/InputBox";
import { templateTypes } from "../../../../../config/template.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import {
    additionDeductionCreateAPI,
    OtherAdditionDeductionTempAPI,
    tempOtherAdditionDeductionAPI,
    additionDeductionListAPI,
    OtherAdditionDeductionAPIAdmin,
    AdditionDeductionListAPI
} from "../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { ApplicabilityTypeCode, ChargeEffectCode, ChargeTypeCode } from "../../../../../config/enum.config";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { getCodeName } from "./ViewAdditionDeduction";
import { CustomAddDedButton } from "./CustomButton";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";

import { RemoveAdditionDeductionForm, CustomButtonDelete} from './RemoveAdditionsDeductions'
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { isEmpty } from "lodash";
import { numberThousandSeperator } from "../../../../../helpers/common-helpers/common.helpers";

let createdRequestId = new Date();
createdRequestId = createdRequestId.valueOf().toString();
createdRequestId = createdRequestId.substr(createdRequestId.length - 8);

let a = '';
let b = '';

const DtailPanel = (props) => {

    const {formKey,requestId, formState} = props
    // const currentLicenceType = _get(formState, "vehicle_search_form.data.current_license_type.name", null)
    // const currentVehicleClass = _get(formState, "vehicle_search_form.data.vehicle_class.name", null)
    // const last_licence_from = _get(formState, `licence_charges_form.currentLicenceStartDate`, "")
    // const last_licence_to = _get(formState, `licence_charges_form.currentLicenceEndDate`, "")
    // const toDateLicencePeriod = _get(formState, `licence_validity_period.endDatesData`, []) && _get(formState, `licence_validity_period.endDatesData`, []).length !== 0 ? _get(formState, `licence_validity_period.endDatesData`, [])[0]['value'] : new Date()
   
    a = props.toDateLicencePeriod
    b = props.fromDateLicencePeriod
    
    
    return (
        <div className="row">
            <div className="col-md-12 col-sm-12">
                <FormWrapper setGroupName={`${formKey}`}>
                        {/* {console.log('STAAAAAAAAAAAAAAATE======', formState)} */}
                  {/* {!props.uiType === 'APR' &&  <UICard>
                        <div className="row">
                            <div className="defaultMarginLeft"><strong>Current Licence Type:</strong></div>
                            <div className="col-md-2">{currentLicenceType}</div>
                            <div><strong>Current Vehicle Class:</strong></div>
                            <div className="col-md-2">{currentVehicleClass}</div>
                            <div><strong>Licence Period:</strong></div>
                            <div className="col-md-2">{`${dateObjectToString(_get(formState, `licence_validity_period.startDate`, null), "dd/mm/YY")} To ${dateObjectToString(toDateLicencePeriod, "dd/mm/YY")}`} </div>
                        </div>
                    </UICard>} */}
{/* {console.log('ccccccccyyy', coreState)} */}
                    <div className="defaultMarginTop">
                        <DataTable
                            tableName="Added Additions / Deductions"
                            apiUrl={`${OtherAdditionDeductionTempAPI.url}/${props.uiType === 'APR' ? props.approvalRequestId : requestId}`}
                            isSetTableHeader={true}
                            
                            dataTableKey={OtherAdditionDeductionTempAPI.key}
                            isSetAction={true}
                            isSetSearchFrom={false}
                            startIcon="mdi mdi-magnify-plus-outline"
                            tableHeaderList={[
                                {
                                    displayName: "Type", key: "charge_effect_code", isSort: false,
                                    onPrintFn: ({ charge_effect_code }) => charge_effect_code ? getCodeName(ChargeEffectCode, charge_effect_code) : "-",
                                },
                                { displayName: "Addition/Deduction", key: "name", isSort: false, },

                                // {
                                //     displayName: "Applicability Type", key: "applicability_type_code", isSort: false,
                                //     onPrintFn: ({ applicability_type_code }) => applicability_type_code ? getCodeName(ApplicabilityTypeCode, applicability_type_code) : "-",
                                // },
                                
                                {
                                    displayName: "Applicable Date", key: "applicable_date", isSort: false, onPrintFn: ({ applicable_date }) =>
                                        applicable_date ? dateObjectToString(applicable_date, "dd/mm/YY") : "-",
                                },
                                { displayName: "Amount", key: "amount", isRightAlign:true, isSort: false, onPrintFn:(rawData)=>numberThousandSeperator(Number(rawData.amount).toFixed(2))},
                                { displayName: "", key: "", isSort: false, default:"" },
                            ]}

                            // isSetAction={true}
                            actionTypeList={[
                                {
                                    actionType: actionBtnTypes.create,
                                    modelContentElement: Form,
                                    dialogPopupProps: {
                                        isFullScreen: true,
                                        isCustomButton: false,
                                        isSaveButton: false,
                                        isCloseButton: true,
                                        dialogTitle: "Add Other Additions / Deductions",

                                        customButtonComponent: CustomAddDedButton,
                                        customButtonComponentProps: { btnText: "Save", uiType: "add", externalProps: props, },

                                        closeButton: {
                                            btnText: "Cancel",
                                            startIcon: "far fa-times-circle",
                                        },
                                        isConfirmationPopup: true,
                                        confirmationMessage: 'You are about to leave from Add Addition / Deduction screen. Are you sure, you want to leave?'
                                    },
                                    parentToModelProps: {
                                        formKey: formKey,
                                        UItype: "add",
                                        externalProps: props,
                                        requestId: props.requestId
                                    },
                                },
                                { 
                                    actionType: actionBtnTypes.delete,
                                    actionBtn: { tooltip: "Remove" },
                                    modelContentElement: RemoveAdditionDeductionForm,
                                    dialogPopupProps: {
                                      isFullScreen: false,
                                      dialogTitle: "Remove Addition / Deduction ",
                                      isCustomButton: true,
                                      customButtonComponent: CustomButtonDelete,
                                      customButtonComponentProps: {
                                        dataTableKey: OtherAdditionDeductionTempAPI.key,
                                        uiType: "delete",
                                      },
                                      closeButton: {
                                        btnText: "No",
                                        startIcon: "far fa-times-circle",
                                      },
                                      isConfirmationPopup: true,
                                    },
                                    parentToModelProps: {
                                      formKey: OtherAdditionDeductionTempAPI.key,
                                      dataTableKey: OtherAdditionDeductionTempAPI.key,
                                    }
                                  }
                            ]}
                        />

                    </div>
                </FormWrapper>

            </div>


        </div>

    );
};

const ChargeTypeValue = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [authStatus] = useContext(AuthContext);
  const user_role_level = _get(authStatus, "authUser.role_level_code", "");
    const isRequired = user_role_level !== "INT_PRV" ? true : false
    // const datalist = _get(formState, `${dataTableKey}`, [])
    const onChangeValueHandler = (eventData) => {
        props.onChangeValueFn(`${props.rowIndex}.charge_type_value_new`, eventData.value)

    }
   

    return (
        <Fragment>
            <NumberInputBoxWithState
                isAlignRight={true}
                isThousandSeparator={false}
                formGroupName={`${props.rowIndex}_charge_type_value_new`}
                inputName={"charge_type_value_new"}
                decimalScale={2}
                isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                if (checkValue.includes(".")) {
                    checkValue = checkValue.split(".")[0];
                }

                return checkValue.length <= 8;
                }}
                isAllowNegative={false}
                lableText="Amount"
                onChangeTxtFn={(eventData) => onChangeValueHandler(eventData)}
                // isRequired={isRequired}
            />
        </Fragment>
    )
}

const ApplicableDate = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [authStatus] = useContext(AuthContext);
  const user_role_level = _get(authStatus, "authUser.role_level_code", "");
  


    const isRequired = user_role_level !== "INT_PRV" ? true : false
    const today = new Date();
    const onChangeEffDateHandler = (eventData) => {

        props.onChangeValueFn(`${props.rowIndex}.effective_date_new`, new Date(eventData.value))

    }
    const g = a.split('/')
    const h = b.split('/')
    const min = `${h && h.length !== 0 ? h[1] : null}/${h && h.length !== 0 ? h[0] : null}/${h && h.length !== 0 ? h[2] : null}`
    const max = `${g && g.length !== 0 ? g[1] : null}/${g && g.length !== 0 ? g[0] : null}/${g && g.length !== 0 ? g[2] : null}`
    return (
        <Fragment>
            {console.log('----', a, b, min, max)}
             <DatePickerWithState
                    uiType={templateTypes.bootstrap}
                  formGroupName={`${props.rowIndex}_effective_date_new`}
                  labelText=" "
                  inputName={"effective_date_new"}
                  onChangeFn={(eventData) => onChangeEffDateHandler(eventData)}
                  minDate={min}
                  maxDate={max}
                    // isRequired={isRequired}
                />
        </Fragment>
    )
   
}

const AdditionDeductionSearchElement = ({
    formGroupName = "additionDeductionListAPIKey_search",
  }) => {
    return (
      <FormWrapper
        setGroupName={formGroupName}
      >
        <div className="row">
          <div className="col-md-6 floatLeft">
            <div className="row">
              <div className="col-3">
                <SelectBoxWithState
                  labelText={"Type"}
                  formGroupName={formGroupName}
                  inputName={"search"}
                  dataList={[{ id: 'ADD', value: "Addition" }, { id: 'DED', value: "Deduction" }]}
                />
              </div>
              <div className="col-5">
                <InputBoxWithState
                  uiType={templateTypes.bootstrap}
                  inputType={"text"}
                  labelText={"Name"}
                  formGroupName={formGroupName}
                  inputName={"name"}
                />
              </div>
            </div>
          </div>
        </div></FormWrapper>
    );
  };

const Form = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const selectedType = _get(formState, `OtherAdditionDeductionAPIKey_search.search`, null)
    const selectedName = _get(formState, `OtherAdditionDeductionAPIKey_search.name`, null)
    const formKey = "OtherAdditionDeductionAPIKey";
    const reqId = props.fromParent.requestId;
    const [coreState, coreAction] = useContext(CoreContext)

    useEffect(() => {
        getRelatedData()
    }, [_get(coreState, `dataTableResponses.${AdditionDeductionListAPI.key}.current_page`, 1)])

    const getRelatedData = async () => {
        
        const page = _get(coreState, `dataTableResponses.${AdditionDeductionListAPI.key}.current_page`, 1)
        const AddDedData = sendAPIRequest(`${AdditionDeductionListAPI.url}?search=${selectedName}&charge_effect_code=${selectedType}&applicability_type_code=VHS&active=true&page=${page}`);
        // console.log('WEERRRRR==yyyyyyyyyyyyyyyyyyyygggg', props.fromParent.externalProps.uiType);
        Promise.all([AddDedData])
        .then(result => {
            console.log('RESULTTTT', result?.[0]?.meta?.current_page);
            coreAction.setDataTable(AdditionDeductionListAPI.key,[], 'done', result?.[0]?.meta?.current_page, result?.[0]?.meta?.total_pages, 10, result?.[0]?.meta?.total_items, 10);
            // "current_page": _get(coreState, `dataTableResponses.${_dataTableKey}.current_page`, 1),
            // "per_page": _get(coreState, `dataTableResponses.${_dataTableKey}.per_page`, 10),
            // "total": _get(coreState, `dataTableResponses.${_dataTableKey}.total`, null),
            // "page_count": _get(coreState, `dataTableResponses.${_dataTableKey}.page_count`, null),
            // "page_size": _get(coreState, `dataTableResponses.${_dataTableKey}.page_size`, 10),
            let defaultIds = []
            if(props.fromParent.externalProps.uiType === 'APR') {
                // console.log('WEERRRRR==yyyyyyyyyyyyyyyyyyyy');
                const defaultData = coreState.dataTableResponses && 
                coreState.dataTableResponses.OtherAdditionDeductionTempAPIKey &&
                coreState.dataTableResponses.OtherAdditionDeductionTempAPIKey.results &&
                coreState.dataTableResponses.OtherAdditionDeductionTempAPIKey.results.length !== 0 ?
                _get(coreState, "dataTableResponses.OtherAdditionDeductionTempAPIKey.results", []) : null
                // console.log('WEERRRRR==yyyyyyyyyyyyyyyyyyyy3333333', defaultData);
    
                if(defaultData !== null) {
                   const Data = defaultData.map(value => {
                        return value.lcn_addition_deduction_id
                    })
                    // console.log('WEERRRRR==', Data);
                    defaultIds = [...Data]
                }
    
    
    
            }
            // console.log('WEERRRRR==yyyyyyyyyyyyyyyyyyyy3333333', defaultIds);
            result && result[0] && result[0]["data"] && result[0]["data"].length !== 0 && result[0]["data"].forEach((element, index) => {
                formAction.changeInputFn(`${index}_charge_type_value_new`, "charge_type_value_new", "", "")
                formAction.changeInputFn(`${index}_effective_date_new`, "effective_date_new", "", new Date())
              });
              formAction.changeInputFn("OtherAdditionDeductionAPIKey", "additions_deductions", "", [])
              const selectedIds = [..._get(formState, "additions_deductions.selected_ids", []), ...defaultIds]
              // const result = _get(formState, `OtherAdditionDeductionAPIKey.additions_deductions`, [])
              const resultData = result && result[0] && result[0]["data"] && result[0]["data"].length !== 0 ? result[0]["data"] : []
              const newDataSet = selectedIds && selectedIds.length !== 0 ? resultData.filter(obj => !selectedIds.includes(obj.id)) : resultData
            formAction.changeInputFn("OtherAdditionDeductionAPIKey", "additions_deductions", "", newDataSet)
                // formAction.changeInputFn(formKey, "additions_deductions", "", result[0]["data"])
            })
    };
    
    const sendAPIRequest = (apiUrl) => {
        return new Promise((resolve, reject) => {
            callApi(apiUrl)
                .headers(true)
                .method("get")
                .isMultipart(false)
                .send((error, result) => {
                    if (error) {
                        reject(error);
                    }

                    resolve(result?.data);
                })
        });
    };

    return (
        <Fragment>
            <DataTable
                tableName="Additions / Deductions"
                apiUrl={`${AdditionDeductionListAPI.url}?search=${selectedName}&charge_effect_code=${selectedType}&applicability_type_code=VHS&active=true`}
                dataTableKey={AdditionDeductionListAPI.key}
                isSetSearchFrom={true}
                customSearchFunc={getRelatedData}
                // isSearchWithSelect={true}
                
                searchFromComponent={
                <AdditionDeductionSearchElement formGroupName={`OtherAdditionDeductionAPIKey_search`} />
                }
                tableBodyList={_get(formState, `${formKey}.additions_deductions`, [])}
                actionTypeList={[


                    {
                        actionType: actionBtnTypes.add,
                        actionBtn: { tooltip: "Add" },
                        modelContentElement: AddAdditionDeductionForm,
                        dialogPopupProps: {
                            isFullScreen: false,
                            isSaveButton: false,
                            isCloseButton: true,
                            isCustomButton: true,
                            isFullWidth: true,
                            customButtonComponent: CustomAddDedButton,
                            customButtonComponentProps: { formKey: `${formKey}_add`, btnText: "Yes", uiType: "add", dataTableKey: OtherAdditionDeductionTempAPI.key, requestId: reqId, externalProps: props },
                            dialogTitle: "Add Addition / Deduction",
                            closeButton: {
                                btnText: "No",
                                startIcon: "fas fa-times",
                            },
                            isConfirmationPopup: false,
                            
                            
                        },
                        parentToModelProps: {
                            formKey: formKey,
                            dataTableKey: additionDeductionListAPI.key,
                            uiType: "add"
                        },
                    },


                ]}
                tableHeaderList={[
                    {
                        displayName: "Type", key: "charge_effect_code", isSort: false,
                        onPrintFn: ({ charge_effect_code }) => charge_effect_code ? getCodeName(ChargeEffectCode, charge_effect_code) : "-",
                    },
                    { displayName: "Addition/Deduction", key: "name", isSort: false, },
                    // {
                    //     displayName: "Applicability Type", key: "applicability_type_code", isSort: false,
                    //     onPrintFn: ({ applicability_type_code }) => applicability_type_code ? getCodeName(ApplicabilityTypeCode, applicability_type_code) : "-",
                    // },
                   
                    // {
                    //     displayName: "Charge Type", key: "charge_type_code", isSort: false,
                    //     onPrintFn: ({ charge_type_code }) => charge_type_code ? getCodeName(ChargeTypeCode, charge_type_code) : "-",
                    // },
                    
                    {
                        displayName: "Applicable Date", key: "effective_date", default: "-", fixWidth: 45
                    },
                    { displayName: "Amount", key: "charge_type_value",isRightAlign: true,  default: "-", fixWidth: 45 },
                ]}
                specificComponents={{
                    effective_date: ApplicableDate,
                    charge_type_value: ChargeTypeValue,
                  }}
            />




        </Fragment>
    );
};

const AddAdditionDeductionForm = (props) => {
    const [formState] = useContext(FormContext);
    const [addDedState, setaddDedState] = useState([]);
    const formKey = 'OtherAdditionDeductionAPIKey';
    const {
        name,
        applicability_type_code,
        charge_effect_code,
        charge_type_code,
        effective_date,
        charge_type_value,
        active,
        charge_component,
        date_base,
        date_base_applied,
        date_base_code,
        date_duration_code,
        date_value,
        engine_capacity_base_applied,
        engine_capacity_from,
        engine_capacity_to,
        fuel_type_id,
        id,
        licence_type_id,
        seat_base_applied,
        seat_base_from,
        seat_base_to,
        vehicle_class_id,
        weight_base_applied,
        weight_base_code,
        weight_base_value_from,
        weight_base_value_to
    } = props.row
console.log('ROWWWW==', props);
    const slelectedIndex = _get(formState, `OtherAdditionDeductionAPIKey.additions_deductions`, []).map((x) => {
        return x.id;
    }).indexOf(id);
    const newValue = _get(formState, `${slelectedIndex}_charge_type_value_new.charge_type_value_new`, 0)
    const newDate = _get(formState, `${slelectedIndex}_effective_date_new.effective_date_new`, new Date())
    // console.log('NMMMMMM===', formState);

    return (
        <FormWrapper
            setGroupName={`${formKey}_add`}
            formGroupLinkWith={`${formKey}_add`}
            setFormObject={{
                //id: props.row.id,
                name: name,
                applicability_type_code: applicability_type_code,
                charge_effect_code: charge_effect_code,
                charge_type_code: charge_type_code,
                effective_date: props.row.effective_date_new ? props.row.effective_date_new : newDate,
                charge_type_value: props.row.charge_type_value_new && props.row.charge_type_value_new !== "" ? props.row.charge_type_value_new : (newValue !== "" ? newValue : 0),
                applicable_date: props.row.effective_date_new ? props.row.effective_date_new : newDate,
                lcn_addition_deduction_id: id,
                charge_component_code: charge_component?.code,
                active: active,
                licence_type_id: licence_type_id ? licence_type_id.id : null,
                vehicle_class_id: vehicle_class_id ? vehicle_class_id.id : null,
                fuel_type_id: fuel_type_id ? fuel_type_id.id : null,
                weight_base_applied: weight_base_applied,
                weight_base_code: weight_base_code,
                weight_base_value_from: weight_base_value_from,
                weight_base_value_to: weight_base_value_to,
                seat_base_applied: seat_base_applied,
                seat_base_from: seat_base_from,
                seat_base_to: seat_base_to,
                date_base_applied: date_base_applied,
                date_base_code: date_base_code,
                based_date: date_base,
                date_duration_code: date_duration_code,
                duration: date_value,
                engine_capacity_base_applied: engine_capacity_base_applied,
                engine_capacity_from: engine_capacity_from,
                engine_capacity_to: engine_capacity_to,


            }}
            onDestroyUnsetFormObject={false}
        >
            <div className="removeContent">
                <p>Are you sure you want to add this addition / deduction?</p>
                <br />
                <br />
            </div>
        </FormWrapper>
    );
};


export { DtailPanel };