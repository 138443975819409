/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 15:02:30
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-30 15:45:49
 */

import {
  mockApiBaseURL,
  internalBffBaseURL,
  internalPrintURL
} from "./core.config";

export const paginAPI = {
  url: `${mockApiBaseURL}api/master/dummies/paging`,
  key: "paginResultKEY",
};

export const fileUploadsDetailsAPI = {
  url: `http://localhost:4000/api/licence-service/file-uploads`,
  key: "fileUploadsDetailsAPIKey",
};

export const uploadsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/uploads`,
  key: "uploadsAPIKey",
};

export const uploadDeleteAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service`,
  key: "uploadDeleteAPIKey",
};

export const uploadDeleteOriginalAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service`,
  key: "uploadDeleteOriginalAPI",
};

export const uploadDetailSaveAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/document-upload-temp`,
  key: "uploadDetailSaveAPIKey",
};

export const uploadDetailSaveOriginalAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/document-upload`,
  key: "uploadDetailSaveOriginalAPI",
};

export const uploadDetailGetAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/document-upload`,
  key: "uploadDetailGetAPI",
};

export const getPaymentDetailsForDearlerAPI ={
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-dealer-transaction-details`,
  key: "getPaymentDetailsForDearlerApiKey",
}

//User Service

export const userAuthServiceAPI = {
  url: `${internalBffBaseURL}auth`,
  key: "userAuthServiceAPIKey",
};

export const userRoleListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/user-role-category/list`,
  key: "userRoleListAPIKey",
};
export const userRoleAPI = {
  url: `${internalBffBaseURL}api/user-service/v1/roles`,
  key: "userRoleListAPIKey",
};

export const userTitlesListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/user-tittles`,
  key: "userTitlesListAPIKey",
};

export const userRolesAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/roles`,
  key: "userRolesAPIKey",
};

export const userRolesListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/roles/list`,
  key: "userRolesListAPIKey",
};

export const userRolesPermissionsListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/roles/permissions`,
  key: "userRolesPermissionsListAPIKey",
};

export const internalUserAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/internal-user`,
  key: "internalUserCreateAPIKey",
};

export const getInternalUserListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/internal-users`,
  key: "getInternalUsersListKEY"
}

export const userDefaultDelegationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/user-default-delegation`,
  key: "userDefaultDelegationKEY"
}

export const userDelegationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/user-delegation`,
  key: "userDelegationKEY"
}

export const userDelegationCreateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/user-delegation`,
  key: "userDelegationCreateKEY"
}

export const userDelegationChangeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users`,
  key: "userDelegationChangeKEY"
}

export const userPasswordResetAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/reset-password`,
  key: "userPasswordResetKEY"
}

export const userPasswordChangeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/password-update`,
  key: "userPasswordChangeAPIKey"
}

export const userInfoAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/user-login`,
  key: "userInfoAPIKEY"
}

export const userLogoutAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/logout`,
  key: "userLogoutAPIKEY"
}

export const refreshTokenAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/token-refresh`,
  key: "refreshTokenAPIKey",
};

//Role category
export const userRoleCategoryListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/user-role-category/list`,
  key: "userRoleCategoryListAPIKey",
};

//Role category for action
export const userRoleCategoryListForActionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/user-role-category/list/action`,
  key: "userRoleCategoryListAPIKey",
};

//aproval history get
export const approvalHistoryGetAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/work-flow-requests/wf-approval-history`,
  key: "approvalHistoryGetAPIKey",
};

//Authority 

export const AuthorityListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/authorities/list`,
  key: "provinceListKEY"
}

//Districts
export const districtListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/districts/list`,
  key: "districtListAPIKey",
};

//Province

export const provincesOfficesListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/province-offices/list`,
  key: "provincesOfficesListAPIKey",
}

export const provinceListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/provinces/list`,
  key: "provinceListKEY"
}

export const pdmtListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/province-offices`,
  key: "pdmtListAPIKey",
};

//Change Serial Number Reason

export const changeSerialNumberReasonListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/changed-serial-number-reason/list`,
  key: "changeSerialNumberReasonListKEY"
}

//Change Serial Number Range

export const changeSerialNumberRangeApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/changed-serial-number-range`,
  key: "changeSerialNumberRangeKEY"
}
//counter
export const availableCounterListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/authorities`,
  key: "availableCounterListKEY"
}

export const createSessionApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/counters`,
  key: "createSessionKEY"
}

export const availableSessionListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/users`,
  key: "availableSessionListKEY"
}

//Counter Serial 
export const counterSerialListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/counters`,
  key: "counterSerialListAPIKey",
}

//Load Serial
export const loadSerialCreateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/counter-serial-number-load`,
  key: "loadSerialCreateAPIKey",
}

//get Current Serial Number
export const currentSerialListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service`,
  key: "currentSerialListAPIKey",
}

//get Counter Sessions
export const counterSessionsListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/sessions/list`,
  key: "counterSessionsListAPIKey",
}

//get users list  by authorityId
export const usersbySessionAuthorityListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/session/users`,
  key: "usersbySessionAuthorityListApiKey",
}

//VRP Service Details
export const vrpAPI = {
  url: `${internalBffBaseURL}api/bff-internal/vrp-service/vrp`,
  key: "vrpDataTablekey",
}

export const vrpCompanyListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/vrp-companies`,
  key: "vrpCompanyListkey",
}

//get DMT DataList
export const getDMTDataListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle/get-update`,
  key: "getDMTDataListAPIKey",
}

export const getDMTTransferListForAVehicleAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle/get-dmt-transfers`,
  key: "getDMTTransferListForAVehicleAPI",
}

export const notificationsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/notification-service/notify-messages`,
  key: "notificationsAPIKey",
}

export const ownerEditAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-owner`,
  key: "ownerEditAPIKey",
}

export const getDealerDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/dealer/info`,
  key: "getDealerDetailsAPIKey",
}
export const AllLicencedVehicleAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/dealer/licenced-vehicles-all`,
  key: "AllLicencedVehicleAPI",
}

export const vftCertificateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/vft-service/vft-certificates`,
  key: "vftCertificateAPIKey",
}

export const vftCompanyListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/vft-companies`,
  key: "vftCompanyListAPIKey",
}

export const insuranceAPI = {
  url: `${internalBffBaseURL}api/bff-internal/insurance-service/insurances`,
  key: "insuranceAPIKey",
}

export const insuranceListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/insurance-companies`,
  key: "insuranceListAPIKey",
}

export const vetAPI = {
  url: `${internalBffBaseURL}api/bff-internal/vet-service/vet`,
  key: "vetAPIKey",
}

export const vetCompanyAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/vet-companies`,
  key: "vetCompanyAPIKey",
}

//ActivityLog Data Table
export const activityLogAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/activity-log`,
  key: "activityLogDataTablekey",
}

export const commentPostAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-comments`,
  key: "commentPostAPIKey",
}

export const dealerCommentPostAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer-comments`,
  key: "dealerCommentPostAPIKey",
}
export const dealerSpecialMessageAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer`,
  key: "dealerSpecialMessageKey",
}
//Licence Fee API
export const licenceFeeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/calculate-licence-fee`,
  key: "licenceFeeAPIKey",
}
export const DealerFeeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/calculate-dealer-fee`,
  key: " DealerFeeAPIKey",
}

export const DealerVehicleFeeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/calculate-dealer-vehicles-fee`,
  key: " DealerVehicleFeeAPIKey",
}
export const DealerLicenceFeeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/dealer/fee`,
  key: "DealerLicenceFeeAPIKey",
}
//Licence Addition Deduction API
export const licenceAdditionDeductionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/addition-deductions`,
  key: "licenceAdditionDeductionAPIKey",
}

//vehicle owner
export const ownerInformationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-owner`,
  key: "ownerInformationAPIKey",
}

//get non user licence configured days from admin
export const getNonUserConfigDaysAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/general-configurations/filter/non-user`,
  key: "getLatestLicenseAPIKey",
}

export const getTrxnDataByTrxnIdFromCashierTrxn = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-trxn-data`,
  key: "getTrxnDataByTrxnIdFromCashierTrxnAPIKey",
}

//get temp licence renewable period from admin
export const getTempLicenceRenewablePeriodAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/general-configurations/filter/temp-lcn-period`,
  key: "getTempLicenceRenewablePeriodAPIKey",
}

//get wf arrears licence configured years from admin
export const getWfArrearsConfigYearsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/general-configurations/filter/arrears`,
  key: "getLatestLicenseAPIKey",
}

//get holiday ranges
export const getHolidayRangesAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-holiday-ranges`,
  key: "getLatestLicenseAPIKey",
}

// latest License Data
export const getLatestLicenseAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-latest-licence`,
  key: "getLatestLicenseAPIKey",
}

// latest Reversed License Data
export const getLatestRevLicenseAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-latest-rev-licence`,
  key: "getLatestLicenseAPIKey",
}

export const getOtherAddDedGetAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/other-additions-deductions`,
  key: "getOtherAddDedGetAPIKey",
}
// latest eff requested date
export const getRequestedEffDataAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/set-effective-date/get-effective-date`,
  key: "getRequestedEffDataAPI",
}

// latest License Data
export const getCarnetLicenseFeeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/calculate-carnet-licence-fee`,
  key: "getCarnetLicenseFeeAPIKey",
}


export const previousOwnerInformationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-owner/history-list`,
  key: "previousOwnerInformationAPIKey",
}

export const previousOwnerInformationWithPagingAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-owner/history`,
  key: "previousOwnerInformationWithPagingAPIKey",
}

//Roles List for dropdown
export const rolesListAPI = {
  url: `${internalBffBaseURL}api/bff/user-service/roles/list?active=true`,
  key: "roleListKEY",
};

//Licence type List for dropdown
export const licenceTypesListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/licence-types/list`,
  key: "licenceTypesListKEY",
};

//Request type List
export const requestTypesListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/request-types`,
  key: "requestTypesListKEY",
};

//Add Licence-receipt transaction
export const addLicenceReceiptTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-receipt-transaction`,
  key: "addLicenceReceiptTransactionKEY",
};

//Cancel Licence-receipt transaction
export const cancelLicenceReceiptTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-receipt-transaction-cancel`,
  key: "cancelLicenceReceiptTransactionKEY",
};

//Cancel Licence-receipt transaction
export const dealerCancelLicenceReceiptTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer/licence-receipt-transaction-cancel`,
  key: "dealerCancelLicenceReceiptTransactionKEY",
};

//Cancel Licence-receipt transaction TEMP
export const cancelLicenceReceiptTransactionTempAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-receipt-transaction-cancel/trx`,
  key: "cancelLicenceReceiptTransactionTempKEY",
};

export const licenceArrearsApi = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-with-arrears`,
  key: "licenceWithArrears",
};

export const licenceInvalidDocuments = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-invalid-documents`,
  key: "licenceInvalidDocuments",
};

//admin config days api
export const adminConfigDaysGetApi = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-pdmt-licence-validations`,
  key: "adminConfigDaysGetApi",
};
// Request For
//Reason List for workflow requests
export const reasonListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/reason/list`,
  key: "reasonListKEY",
};

//Add vehicle request
export const addVehicleAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-add-request/`,
  key: "addVehicleKEY",
};

//Update vehicle request
export const updateVehicleAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-update-request/`,
  key: "updateVehicleKEY",
};

export const getChargeUpdateLatestDateForVehicleAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-update-request/get-latest-charge-date`,
  key: "getChargeUpdateLatestDateForAVehicleKEY",
};

//Get Licence Transaction
export const getLicenceTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-receipt-transaction-cancel`,
  key: "getLicenceTransactionKEY",
};

//Get Transaction Reciept Print Data
export const getLicenceTransactionRecieptPrintDataAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/reverse-transaction/get-print-data`,
  key: "getLicenceTransactionRecieptPrintDataKEY",
};

export const getLicenceTransactionRecieptPrintRevDataAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/reverse-transaction/get-rev-print-data`,
  key: "getLicenceTransactionRecieptPrintDataKEY",
};

//Get Temp Licence Transaction
export const getTempLicenceTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-receipt-transaction-cancel/get-transaction`,
  key: "getTempLicenceTransactionKEY",
};

//delete Temp Licence Transaction
export const deleteTempLicenceTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-receipt-transaction-cancel/delete-transaction`,
  key: "deleteTempLicenceTransactionKEY",
};

// Get Vehicle by vehicle No
export const getVehicleByNoAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-update-request/get-by-vehicle-no`,
  key: "getVehicleByNoKEY",
};

//Vehicle class list
export const vehicleClassListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-classes/list`,
  key: "vehicleClassListKEY",
};

//Vehicle fuel list
export const fuelListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/fuel-types/list`,
  key: "fuelListKEY",
};

export const fuelAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/fuel-types`,
  key: "fuelAPIKEY",
};

//Vehicle condition list
export const vehicleConditionListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-conditions/list`,
  key: "vehicleConditionListKEY",
};
//Vehicle condition
export const vehicleConditionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-conditions`,
  key: "vehicleConditionAPIKEY",
};

//Vehicle Status Change
export const addVehicleStatusChangeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-status-change`,
  key: "addVehicleStatusChangeKEY",
};

//licence Type Change
export const licenceTypeChangeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-change`,
  key: "licenceTypeChangKEY",
}
export const nonUserLicenceValidityPeriodAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/non-user-validity-period`,
  key: "nonUserLicenceValidityPeriodKEY",
}
export const transferVehicleAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/transfer-vehicle/transfer-vehicle`,
  key: "transferVehicleAPIKEY",
};

export const licenceWaveOffAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-waiveoff`,
  key: "licenceWaveOffAPIKEY",
}
//Set effective date
export const setEffectiveDateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/set-effective-date`,
  key: "setEffectiveDateKEY",
};

// /Licence GET API
export const getLicenceAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/get-licence`,
  key: "getLicenceKEY",
};

export const licenceReceiptReprintApi = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-receipt-reprint`,
  key: "licenceReceiptReprintApi",
};

//Authority get by id
export const authorityByIdAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/authorities/`,
  key: "AuthorityByIdKEY"
};

export const changeSerialNumberWorkFlowApi = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/change-serial-number`,
  key: "changeSerialNumberWorkFlowApi",
};

// All task
export const getAllTask = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/work-flow-requests`,
  key: "wgetAllTaskApi",
};

//Addition deduction 
export const additionDeductionListAPI = {
  url: `${internalBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionListAPIKey",
};

export const additionDeductionCreateAPI = {
  url: `${internalBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionCreateAPIKey",
};

export const additionDeductionEditAPI = {
  url: `${internalBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionEditAPIKey",
};

export const additionDeductionDeleteAPI = {
  url: `${internalBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionDeleteAPIKey",
};

export const tempOtherAdditionDeductionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/other-additions-deductions-temp`,
  key: "tempOtherAdditionDeductionAPIKey",

};

export const OtherAdditionDeductionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/other-additions-deductions`,
  key: "OtherAdditionDeductionAPIKey",
};

export const reverseTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/reverse-transaction`,
  key: "reverseTransactionAPIAPIKey",
};

export const OtherAdditionDeductionAPIAdmin = {
  url: `${internalBffBaseURL}api/bff/licence-service/additions-deductions?applicability_type_code=VHS`,
  key: "OtherAdditionDeductionAPIKey",
};

export const AdditionDeductionListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/additions-deductions`,
  key: "AdditionDeductionListAPIKey",
};
export const AdditionDeductionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/additions-deductions/list`,
  key: "AdditionDeductionAPIKey",
};

// Add licence transaction (Dealer)
export const AddLicenceTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/add-licence-transaction`,
  key: "AddLicenceTransactionAPIKey",
};

// Vehicle Information Temp
export const AutharizedVehicleInformationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-info-temp`,
  key: "AutharizedVehicleInformationAPIKey",
};

// Action
export const actionApi = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/approval-request`,
  key: "actionApiKey",
};

//UpdateVehicleRequest
export const updateVehicleRequestAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-update-request/all-tasks/get-request`,
  key: "updateVehicleApiKey",
};
//Change Serial No
export const changeSerialAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/change-serial-number/get-serial-number`,
  key: "changeSerialKEY",
};

export const changeDealerSerialAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/change-serial-number/get-dealer-serial-number`,
  key: "changeDealerSerialKEY",
};


//LicenceTypeChange
export const licenceChangeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence-change/all-tasks/get-request`,
  key: "licenceChangeApiKey",
};

//Licence 
export const licenceAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence`,
  key: "licenceApiKey",
};

//Quotation 
export const quotationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/quotation`,
  key: "quotationAPIKey",
};

export const quotationVehicleListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/quotation/vehicle-detail`,
  key: "quotationVehicleListKey",
};

export const quotationCompleteAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/quotation/complete`,
  key: "quotationCompleteKey",
};

export const quotationVehicleAddAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/quotation/vehicle`,
  key: "quotationVehicleAddKey",
};

export const quotationVehicleUpdateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/quotation/vehicle-update`,
  key: "quotationVehicleUpdateKey",
};

export const quotationDeleteAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/quotation`,
  key: "quotationDeleteKey",
};

export const quotationVehicleDeleteAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/quotation/vehicle`,
  key: "quotationVehicleDeleteKey",
};

//insurance company api
export const insuranceCompany = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/insurance-companies`,
  key: "insuranceCompanyKey",
}
export const EditInsuranceCompanyAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/dealer`,
  key: "EditInsuranceCompanyAPIKey",
}
export const DealerTempAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/dealer-temp`,
  key: "DealerTempAPIKey",
}
export const getDealerVehicleDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/vehicles`,
  key: "getDealerVehicleDetailsAPIKey",
}
export const getDealerPendingVehicleDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/dealer/pending-vehicles`,
  key: "getDealerPendingVehicleDetailsAPIKey",
}

export const getDealerVehicleDetailssAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/vehicles-list`,
  key: "getDealerVehicleDetailsAPIKey",
}

export const getDealerVehicleDetailsOrganisationServiceAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer/licence-reprint/dealer-vehicles`,
  key: "getDealerVehicleDetailsAPIKey",
}
export const getDealerCurrentValidlicenceDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/dealer/licenced-vehicles`,
  key: "getDealerCurrentValidlicenceDetailsAPIKey",
}

export const getDealerVehicleTrxnDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer/licence-reprint/dealer-vehicle-transactions`,
  key: "getDealerVehicleDetailsAPIKey",
}

export const getVehicleInfoAPI = {
  url: `${internalBffBaseURL}api/bff-internal/vehicle-service/vehicle-info/vehicle`,
  key: "getVehicleInfoAPIKey",
}

export const getDealerInfoAPI = {
  url: `${internalBffBaseURL}api/bff-internal/vehicle-service/vehicle-info/dealer`,
  key: "getDealerInfoAPIKey",
}
export const OtherAdditionDeductionTempAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/other-additions-deductions-temp`,
  key: "OtherAdditionDeductionTempAPIKey",
}

export const dealerVehicleTempAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer-vehicles-temp`,
  key: "dealerVehicleTempAPIKey",
}

export const getDealerVehicleLicenceDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealers-licence-reissuance-request`,
  key: "getDealerVehicleLicenceDetailsAPI",
}

export const getDealerTempRequestsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealers-licence-reissuance-request/get-temp-requests`,
  key: "getDealerTempRequestsAPI",
}

export const DealersLicenceReissuanceTempRequestsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealers-licence-reissuance-request/temp`,
  key: "DealersLicenceReissuanceTempRequestsAPI",
}

export const DealersLicenceReissuanceRequestsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealers-licence-reissuance-request/get-request`,
  key: "DealersLicenceReissuanceRequestsAPI",
}

export const DealersLicenceReissuanceRequestAddAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealers-licence-reissuance-request`,
  key: "DealersLicenceReissuanceRequestAddAPI",
}

export const vehicleCommentHistoryAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-comments`,
  key: "dealerVehicleCommentHistoryAPIKey",
}

export const addDealerVehicleReqstAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/add-dealers`,
  key: "addDealerVehicleAPIKey",
}

export const updateDealerVehicleReqstAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/update-dealers`,
  key: "UpdateDealerVehicleAPIKey",
}

// request unassign
export const reqstUserUnassignAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/work-flow-requests/assigned-user`,
  key: "reqstUserUnassignAPIKey",
}

// request status Change
export const reqstStatusChangeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/work-flow-requests/request-status-change`,
  key: "reqstStatusChangeAPIKey",
}

export const dealerLicenceReprintAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer/licence-reprint`,
  key: "dealerLicenceReprintAPIKey",
}

export const dealerLicenceReissuanceAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealers-licence-reissuance-request/get-request`,
  key: "dealerLicenceReprintAPIKey",
}

//Oustanding revenue settlement
export const settlementOutstandingAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements/calculate-outstanding`,
  key: "settlementOutstandingAPIKey",
};

//revenue settlement List
export const settlementListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements/list`,
  key: "settlementListAPIKey",
};

//revenue settlement 
export const settlementAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements`,
  key: "settlementAPIKey",
};

export const settlementPostAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements`,
  key: "settlementPostAPIKey",
};

export const settlementOutstandingTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements/outstanding-transactions`,
  key: "settlementOutstandingTransactionAPIKey",
};

export const settlementTransactionAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements/settlement-transactions`,
  key: "settlementTransactionAPIKey",
};

export const settlementStatusCheckAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements/status-check`,
  key: "settlementStatusCheckAPIKey",
};

//report APIs
export const reportGenerateAPI = {
  url: `${internalBffBaseURL}api/bff/report-service/report`,
  key: "generateReportAPIKEY",
}

export const userListAPI = {
  url: `${internalBffBaseURL}api/bff/user-service/users/internal-user`,
  key: "userListAPIKEY",
}

// vet companies APIs
export const vetCompaniesListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/vet-companies/list`,
  key: "vetCompaniesListAPIKEY",
}

export const sessionEndApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/counter-session`,
  key: "sessionEndKEY"
};

export const calculateLicenceFeeAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/calculate-licence-fee`,
  key: "calculateLicenceFeeAPIKey"
};

export const counterOperationTypeAllowedListApi = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/counter-operation-types-allowed`,
  key: "counterOperationTypeAllowedListKEY"
};

export const normalLicenceStartDateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-licence-starting-date`,
  key: "normalLicenceStartDateAPIKey"
};

export const normalLicenceEndDatesAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-licence-end-dates`,
  key: "normalLicenceEndDatesAPIKey"
};

export const sessionCollectionsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/session-collections`,
  key: "sessionCollectionsAPIKey"
};

export const webLicencePrintListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/web-licence`,
  key: "webLicencePrintListAPIKey"
};

// reconciliation ai
export const webReconciliationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/reconcile-transactions`,
  key: "webReconciliationAPIKey"
};

export const webLicencePrintListUpdateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/web-licence`,
  key: "webLicencePrintListUpdateAPIKey"
};

export const EnvelopLicencePrintListUpdateAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/web-licence`,
  key: "webLicencePrintListUpdateAPIKey"
};

export const deliveryMethodsListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/delivery-methods/list`,
  key: "deliveryMethodsListAPIKey"
};

export const getLicenceTransactionDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-licence-transaction-details`,
  key: "getLicenceTransactionDetailsAPIKey"
};

export const getDealerTransactionDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-dealer-transaction-details`,
  key: "getDealerTransactionDetailsAPIKey"
};


export const getUsernameAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/get-username-by-user-id`,
  key: "getUsernameAPIKey"
}

export const getLocationNameAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/services/get-location-name`,
  key: "getLocationNameAPIKey"
}

//FR 30 - Arrears only - licence create
export const createLicenceArrearsOnlyAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/arrears-only`,
  key: "makePaymentArrearsOnlyAPIKey"
}

//FR 30 - Arrears only - licence payment complete
export const paymentCompleteArrearsOnlyAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/complete-pay/arrears-only`,
  key: "paymentCompleteArrearsOnlyAPIKey"
}

//FR 30 - Arrears only - licence Arrears Pay Calculation
export const arrearsPayCalculationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/arrears-only/pay-calculation`,
  key: "arrearsPayCalculationAPIKey"
}

//vehicle document upload
export const vehicleUploadOriginalAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-document-upload`,
  key: "vehicleUploadOriginalAPI",
};

//dealer document upload
export const dealerDocUploadOriginalAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer-document-upload`,
  key: "dealerDocUploadOriginalAPIKey",
};

export const completePaymentAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/issue-rl`,
  key: "completePaymentAPIKey"
}

export const issueRLValidationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/issue-rl-validation/normal-nunuser-free`,
  key: "issueRLValidationAPIKey"
}

export const payAndPrintLicence = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/licence-pay-and-print`,
  key: "payAndPrintLicenceAPIKey"
}

export const completeQuotationPaymentAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/proceed-quotation-and-print`,
  key: "completeQuotationPaymentAPIKey"
}

export const carnetCompletePaymentAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/issue-carnet-rl`,
  key: "carnetCompletePaymentAPIKey"
}

export const carnetCompletePaymentAndPrintAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/issue-carnet-rl-and-print`,
  key: "carnetCompletePaymentAndPrintAPIKey"
}

export const licencePrintLicenceCount = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/re-print-licence-count`,
  key: "licencePrintLicenceCountAPIKey"
}

export const dealerCompletePaymentAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/issue-dealer-rl`,
  key: "dealerCompletePaymentAPIKey"
}

export const dealerCompletePaymentAPIandPrint = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/issue-dealer-rl-and-print`,
  key: "dealerCompletePaymentAndPrintAPIKey"
}

export const banksListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/banks/list`,
  key: "banksListAPIKey"
}

export const DMSMediatorApi = {
  url: `${internalBffBaseURL}dms/v1/dms-mediator`,
  key: "DMSmediatorAPIKey"
}

export const paymentGatewayAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/ipg-types`,
  key: "paymentGatewayAPIKey"
}

export const dealerVehiceLicenceListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/vehicles-list`,
  key: " dealerVehiceLicenceListAPIKey"
}

export const addDealerVehicleLicenceAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/create-dealer-vehicle`,
  key: "addDealerVehicleLicenceAPIKey"
}

export const vehiclePaymentHistoryAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-payment-history`,
  key: "vehiclePaymentHistoryAPIKey"
}


export const vehicleDocumentValidationAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/work-flow-requests/document-validation`,
  key: "vehicleDocumentValidationAPIKey"
}


export const getAvailabelSerialNumbersAPI = {
  url: `${internalBffBaseURL}api/bff-internal/organization-service/loaded-counter-serial/get-serial-numbers`,
  key: "getAvailabelSerialNumbersAPIKey"
}

export const dealerPaymentHistoryAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/dealer-payment-history`,
  key: "dealerPaymentHistoryAPIKey"
}

export const carnetPaymentHistoryAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/carnet-payment-history`,
  key: "carnetPaymentHistoryAPI"
}
export const specialMessageAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-charge-updates/special-messages`,
  key: "specialMessageAPI"
}

export const printLicenseAPI = {
  url: `${internalPrintURL}`,
  key: "printLicenseAPIKey"
}

export const dailyRevenueLicenceDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/transactions/list`,
  key: "dailyRevenueLicenceDetailsAPIKey"
}

export const authorityServiceIdsGetAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/transactions/authority-service-ids/list`,
  key: "authorityServiceIdsGetAPI"
}

export const vehicleClassAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/vehicle-classes`,
  key: "vehicleClassAPIKey"
}

export const getVehicleLicensePrintDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/licence-print-request`,
  key: "getVehicleLicensePrintDetailsAPI"
}
export const getLicenseReceiptPrintDetailsAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/licence-print-receipt-request`,
  key: "getVehicleLicensePrintDetailsAPI"
}

export const sequenceNumberGetAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/sequence-number`,
  key: "sequenceNumberGetAPIKey"
}

export const defaultPrinterAPI = {
  url: 'https://print.erl2.gov.lk:1967/print-service/print-template',
  key: 'defaultPrinterEndpoint'
}

export const localPrinterAPI = {
  url: 'http://localhost:1963/print-service/print-template',
  key: 'localPrinterAPI'
}

//Printer URL
export const printerAPI = {
  url: `https://print.erl2.gov.lk:1967/print-service/print-template`,
  key: "printerAPIKEY"
}

export const multipleLicencePrintRequestAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/licence-multiple-print-request`,
  key: "multipleLicencePrintRequestAPIKEY"
}

export const multipleEnvelopPrintRequestAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/print-multiple-envelope`,
  key: "multipleEnvelopPrintRequestAPIKEY"
}

export const printHistoryAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/print-history`,
  key: "printHistoryAPIKey",
};
export const internalUserListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/users/internal-user-list`,
  key: "internalUserListAPKey",
};

export const lossRevenueDateListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-lost-revenue/date-range`,
  key: "lossRevenueDateListAPIKey",
};
export const AccountNumberListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/settlements/account-number`,
  key: "AccountNumberListAPIKey",
};
export const lossRevenueNewDateListAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-lost-revenue-gen-dates`,
  key: "lossRevenueNewDateListAPIKey",
};

export const userActivityLogReportAPI = {
  url: `${internalBffBaseURL}api/bff-internal/user-service/user-activity-log/report`,
  key: "userActivityLogReportAPIKey",
};

export const licenceMetaDataAPI = {
  url: `${internalBffBaseURL}api/bff-internal/licence-service/licence/get-temp-licence-data`,
  key: "licenceMetaDataAPIKey",
};

export const getVehicleMetaDataAPI = {
  url: `${internalBffBaseURL}api/bff-internal/vehicle-service/vehicle-info/get-vehicle-meta-data`,
  key: "getVehicleMetaDataAPIKey",
};
