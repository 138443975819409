/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 11:15:40
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-04 11:41:46
 */
import React, { Fragment } from "react";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  CheckBoxWithState,
  SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  actionBtnTypes,
  DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
// import AdditionalRolesAndLocations from "./AdditionalRolesAndLocations";
import {provinceListApi, userRolesListAPI, userTitlesListAPI} from "../../../../../config/apiUrl.config";
import {AdditionalRolesAndLocations} from "./AdditionalRolesAndLocations";

const emptyFn = (...para) => undefined;
const CustomButtons = ({ onClosePopupFn = emptyFn, formGroupName = "" }) => {
  const close = () => {
    onClosePopupFn();
  };

  return (
    <Fragment>
      <SubmitButton btnText="Save" elementStyle="btnWrapper" />
    </Fragment>
  );
};

const CustomButtonEdit = ({ onClosePopupFn = emptyFn, formGroupName = "" }) => {
  const close = () => {
    onClosePopupFn();
  };

  return (
    <Fragment>
      <SubmitButton btnText="Update" elementStyle="btnWrapper" />
    </Fragment>
  );
};

const SearchElement = ({ dataTableKey = "" }) => {
  const formKey = dataTableKey;
  return (
    <FormWrapper setGroupName={formKey}>
      <div className="row">
        <div className="col-md-3 col-sm-12 floatLeft">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"FullName"}
            labelText="Full Name"
            inputValue=""
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"DisplayName"}
            labelText="Display Name"
            inputValue=""
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"Username"}
            labelText="Username"
            inputValue=""
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            emptySelectOptionTxt={"Internal"}
            dataList={[
              {
                id: "1",
                value: "Admin",
              },
            ]}
            inputName={"UserRole"}
            labelText="User Role"
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            emptySelectOptionTxt={"All"}
            dataList={[
              {
                id: "1",
                value: "Active",
              },
              {
                id: "2",
                value: "Inactive",
              },
            ]}
            inputName={"Status"}
            labelText="Status"
          />
        </div>
      </div>
    </FormWrapper>
  );
};

const Form = () => {
  const formKey = "testInputs";
  const datatablekey = "datatablekey";
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 ">
        <UICard cardHeading="User Information">
          <FormWrapper setGroupName={formKey}>
            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      isRequired={true}
                      apiUrl={userTitlesListAPI.url}
                      apiStateKey={userTitlesListAPI.key}
                      keyName={"id"}
                      valueName={"name"}
                      emptySelectOptionTxt={""}
                      inputName={"title"}
                      labelText="Title"
                      inputError={"Title is required"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      inputName={"fullName"}
                      labelText="Full Name"
                      isRequired={true}
                      inputError={"Full Name is required"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      inputName={"designation"}
                      labelText="Designation"
                      isRequired={true}
                      inputError={"Designation is required"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      inputName={"nic"}
                      labelText="NIC"
                      isRequired={true}
                      inputError={"NIC is required"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      inputName={"email"}
                      labelText="Email"
                      isRequired={true}
                      inputError={"Email is required"}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      inputName={"mobileNumber"}
                      labelText="Mobile Number"
                      isRequired={true}
                      inputError={"Mobile Number is required"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      dataList={[
                        {
                          id: "1",
                          value: "Inactive",
                        },
                      ]}
                      inputName={"status"}
                      labelText="Status"
                      emptySelectOptionTxt="Active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormWrapper>
        </UICard>
        <UICard cardHeading="User Login Information">
          <FormWrapper setGroupName={formKey}>
            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      inputName={"username"}
                      labelText="Username"
                      isRequired={true}
                      inputError={"Username is required"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      inputName={"displayName"}
                      labelText="Display Name"
                      isRequired={true}
                      inputError={"Display Name is required"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <CheckBoxWithState
                      labelText={"Notify via SMS"}
                      formGroupName={formKey}
                      inputName={"notifyViaSMS"}
                      inputValue={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <CheckBoxWithState
                      labelText={"Notify via Email"}
                      formGroupName={formKey}
                      inputName={"notifyViaEmail"}
                      inputValue={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 floatLeft">
                    <SubmitButton
                      elementWrapperStyle={"floatLeft"}
                      formGroupName={formKey}
                      btnName={"ResetPassword"}
                      btnText={"Reset Password"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormWrapper>
        </UICard>
        <UICard cardHeading="Default Role & Location">
          <FormWrapper setGroupName={formKey}>
            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <CheckBoxWithState
                      labelText={"Internal Module"}
                      formGroupName={formKey}
                      inputName={"internalModule"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"User Role"}
                      apiUrl={userRolesListAPI.url}
                      apiStateKey={userRolesListAPI.key}
                      keyName={"id"}
                      valueName={"name"}
                      inputName={"UserRole"}
                      labelText="User Role"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"Province"}
                      apiUrl={provinceListApi.url}
                      apiStateKey={provinceListApi.key}
                      keyName={"id"}
                      valueName={"name"}
                      inputName={"province"}
                      labelText="Province"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"Authority"}
                      dataList={[
                        {
                          id: "1",
                          value: "Authority 1",
                        },
                        {
                          id: "2",
                          value: "Authority 2",
                        },
                      ]}
                      inputName={"authority"}
                      labelText="Authority"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <CheckBoxWithState
                      labelText={"Admin Module"}
                      formGroupName={formKey}
                      inputName={"adminModule"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"User Role"}
                      dataList={[
                        {
                          id: "1",
                          value: "Internal",
                        },
                        {
                          id: "2",
                          value: "Admin",
                        },
                      ]}
                      inputName={"UserRole"}
                      labelText="User Role"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"Province"}
                      dataList={[
                        {
                          id: "1",
                          value: "Western Province",
                        },
                        {
                          id: "2",
                          value: "Southern Province",
                        },
                        {
                          id: "3",
                          value: "Central Province",
                        },
                      ]}
                      inputName={"province"}
                      labelText="Province"
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormWrapper>
        </UICard>
        <DataTable
          tableName="Additional Roles & Locations"
          dataTableKey={datatablekey}
          isSetAction={true}
          actionTypeList={[
            {
              actionType: actionBtnTypes.create,
              modelContentElement: AdditionalRolesAndLocations,
              dialogPopupProps: {
                isFullScreen: false,
                dialogTitle: "Add Additional Role & Location",

                closeButton: {
                  btnText: "Cancel",
                },
                isCustomButton: true,
                customButtonComponent: CustomButtons,
              },
            },
            {
              actionType: actionBtnTypes.delete,
              actionBtn: { tooltip: "Remove" },
            },
            {
              actionType: actionBtnTypes.edit,
              actionBtn: { tooltip: "Edit" },
              modelContentElement: AdditionalRolesAndLocations,
              dialogPopupProps: {
                isFullScreen: false,
                dialogTitle: "Edit Additional Roles & Locations",

                closeButton: {
                  btnText: "Cancel",
                },
                isCustomButton: true,
                customButtonComponent: CustomButtonEdit,
              },
            },
          ]}
          tableHeaderList={[
            {
              displayName: "Province",
              key: "province",
              isSort: false,
            },
            {
              displayName: "Authority",
              key: "authority",
              isSort: false,
            },

            {
              displayName: "User Role",
              key: "user_role",
              isSort: false,
            },
            {
              displayName: "Status",
              key: "status",
              isSort: false,
            },
            {
              displayName: "Expire Date",
              key: "expire_date",
              isSort: false,
            },
          ]}
          tableBodyList={[
            {
              province: "1",
              authority: "5000",
              user_role: "Internal",
              status: "8",
              expire_date: "545465",
            },
          ]}
        />
      </div>
    </div>
  );
};

export { Form, SearchElement };
