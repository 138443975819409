import React from 'react';
import { SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { getDataByFormObject } from '../../../../../../helpers/common-helpers/common.helpers.js';
import { apiResponseStoringType } from "../../../../../../config/apiResponseKey";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { getAllTask, reqstUserUnassignAPI } from '../../../../../../config/apiUrl.config';

const UnassignSubmitButton = (props) => {
    return (
        <SubmitButton
            startIcon={"far fa-save"}
            btnText="Yes"
            elementStyle="btnWrapper"
            formGroupName={props.formGroupName}
            dataTableKey={`${getAllTask.key}Assigned`}
            tooltip={"Unassigned"}
            isValidate={false}
            isSetHeaders={true}
            flashMessages={{
                success: {
                    status: true,
                    message: "Successfully unassigned.",
                    messageType: "success",
                },
            }}
            onGetAPIEndPointFn={(formState) => {
                return {
                    url: `${reqstUserUnassignAPI.url}/${formState.request_id}`,
                    key: reqstUserUnassignAPI.key,
                };
            }}
            
            callApiObject={{
                isSetHeaders: true,
                method: "put",
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.responseLinkToFrom,
            }}
            onResponseCallBackFn={(response, error) => {
                if (
                    response.length === undefined &&
                    error === undefined &&
                    response.data.errors === undefined
                ) {
                    props.refreshMainScreenAfterPerformFn()
                    props.onClosePopupFn();
                }
            }}
        />

    )
}

export {
    UnassignSubmitButton
}