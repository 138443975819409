/*
 * @Author:Pradeepa Sandaruwan
 * @Date: 2021-01-12 10:21:07
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-13 14:39:07
 */


import React, { useState, useContext } from "react";
import { DashboardTemplate } from "../../../../../ui-components/templates/DashboardTemplate";
import { UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, SelectBoxWithState, SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { downloadFile } from "../../../../../../helpers/download-helpers/download.helpers"
import {
  _get,
} from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { reportGenerateAPI } from "../../../../../../config/apiUrl.config";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { templateTypes } from "../../../../../../config/template.config";
import { SearchCriteria } from "./includes/SearchCriteria";

const LossRevenueReport = () => {
  const reportCode = `fr42-loss-revenue-report-entire`
  const searchformGroupName = `lossrevenue`;
  const exportformGroupName = `export_report_form`;
  const [htmlTemplate, sethtmlTemplate] = useState(null);
  const [reportgeneratedValues, setReportgeneratedValues] = useState({});
  const [formState, formAction] = useContext(FormContext);
  const reportName = "Loss Revenue Report"

  const onGenerateSubmit = (event) => {
    sethtmlTemplate({ __html: event });
  };

  const onSetGeneratedValues = (event) => {
    setReportgeneratedValues(event)
  };
 
  const onResetClick = () =>{
    sethtmlTemplate(null)
    setReportgeneratedValues({})
  }

  return (
        <DashboardTemplate
              pageTitle="Loss Revenue Report"
              breadCrumbList={[
                    { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
                    { urlTo: "/report-other", displayName: "Other Reports", },
                    {
                          urlTo: "/report",
                          displayName: "Loss Revenue Report ",
                    },
              ]}
        >
      <SearchCriteria
        reportCode={reportCode}
        formGroupName={searchformGroupName}
        onGenerateSubmit={onGenerateSubmit}
        setReportgeneratedValues={onSetGeneratedValues}
        onResetClick={onResetClick}
        reportName={reportName}
      />

      
    </DashboardTemplate>
  );
};

export { LossRevenueReport };
