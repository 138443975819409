/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-30 12:21:13 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-06-20 05:30:51
 */
import React,{useState, Fragment} from 'react';
import {TabsWrapper,SubTab,tabsWrapperVariants,tabsWrapperScrollButtons} from "../../../../ui-components/ui-elements/tab/Tabs";
import {DataTable,actionBtnTypes} from "../../../../ui-components/ui-elements/table/DataTable";
import {FormWrapper,InputBoxWithState,DatePickerWithState,SelectBoxWithState} from "../../../../ui-components/ui-elements/form/index";
import {UICardWithBackGround} from "../../../../ui-components/ui-elements/common/BaseElements";
import {ViewTransaction} from "./NormalLicense";


const SearchElement = ({ 
    dataTableKey = "table1",
    uiType="printed"
}) => {
    return (        
        <div className={"row"}>
            <div className="col-md-4">
                <InputBoxWithState
                    labelText={"Search"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"search"}
                />
            </div>
            <div className="col-md-4">
                <SelectBoxWithState
                    labelText={"Delivery Method"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"deliveryMethod"}
                    dataList={[
                        {
                            "id":"1",
                            "value":"Register Post"
                        },{
                            "id":"2",
                            "value":"Courier Service"
                        }                                 
                    ]}
                />
            </div>

            {/* {
                (uiType==="pending-printed")?(
                    <div className="col-md-4">
                        <SelectBoxWithState
                            labelText={"Print Options"}
                            formGroupName={`${dataTableKey}_search`}
                            inputName={"printOptions"}
                            dataList={[
                                {
                                    "id":"1",
                                    "value":"Envelope"
                                },{
                                    "id":"2",
                                    "value":"Revenue Licence"
                                }                                 
                            ]}
                        />
                    </div>
                ):(null)
            } */}
            <div className="col-md-4">
                <DatePickerWithState
                    labelText={"From"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"from"}
                />
            </div>
            <div className="col-md-4">
                <DatePickerWithState
                    labelText={"To"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"to"}
                />
            </div>

            {/* {
                (uiType==="pending-printed")?(
                    <div className="col-md-4">
                        <SelectBoxWithState
                            labelText={"Print Status"}
                            formGroupName={`${dataTableKey}_search`}
                            inputName={"printStatus"}
                            dataList={[
                                {
                                    "id":"1",
                                    "value":"Success"
                                },{
                                    "id":"2",
                                    "value":"Pending"
                                }                                 
                            ]}
                        />
                    </div>
                ):(null)
            } */}
            
        </div>
    );
};


const PrintedUI=({
    tableHeading=""
})=>{
    return (
        <DataTable
        //tableName={tableHeading}
        dataTableKey="table1"
        isSetSearchFrom={true}
        isSelectRow={true}
        elementWrapperStyle={"scrolTable paymentHistoryTable"}
        searchFromComponent={<SearchElement dataTableKey="table1"  uiType="printed" />}
        tableHeaderList={[
          { displayName: "No", key: "no", isSort: false},
          { displayName: "Vehicle ID", key: "vehicle_id", isSort: false },
          { displayName: "Reference Number", key: "referenceNo", isSort: false },
          { displayName: "Owner Name", key: "ownerName", isSort: false },
          { displayName: "Tx. Date & Time", key: "tx_date_time", isSort: false },
          { displayName: "Delivery Method", key: "deliveryMethod", isSort: false },
          { displayName: "Validity Period (From – To)", key: "validityPeriod", isSort: false },
          { displayName: "Fee (LKR)", key: "fee", isSort: false },
          { displayName: "Fine (LKR)", key: "fine", isSort: false },
          { displayName: "Delivery Fee (LKR)", key: "deliveryFee", isSort: false },
          { displayName: "Additions (LKR)", key: "additions", isSort: false },
          { displayName: "Deductions (LKR)", key: "deductions", isSort: false },
          { displayName: "Total (LKR)", key: "total", isSort: false },
        ]}
        tableBodyList={[
          {
            "no": "0000000003",
            "vehicle_id": "KS-5555",
            "referenceNo": "00030",
            "ownerName": "K.M.S Pathirana",
            "tx_date_time": "01/08/2020 08.00",
            "deliveryMethod": "-",
            "validityPeriod":"02/08/2019 - 01/08/2020",  
            "fee":"2200.00",
            "fine":"200.00",
            "deliveryFee":"100.00",
            "additions":"00.00",
            "deductions":"00.00",
            "total":"2300.00"
          }         
        ]}

        isSetAction={true}  
        actionTypeList={[
            {
                actionType: actionBtnTypes.view,  
                modelContentElement: ViewTransaction,
                dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "View Licence Transaction Details",
                    isCustomButton: false,
                    closeButton: {
                      btnText: "Close",
                      startIcon: "far fa-times-circle",
                    },
                  },                
              },
        ]}       
        />
    )
}

const PrintedEnvelopeUI=({
    tableHeading=""
})=>{
    return (
        <DataTable
        //tableName={tableHeading}
        dataTableKey="table1"
        isSetSearchFrom={true}
        isSelectRow={true}
       
        searchFromComponent={<SearchElement dataTableKey="table1"  uiType="printed" />}
        tableHeaderList={[
          { displayName: "No", key: "no", isSort: false},
          { displayName: "Vehicle ID", key: "vehicle_id", isSort: false },
          { displayName: "Reference Number", key: "referenceNo", isSort: false },
          { displayName: "Owner Name", key: "ownerName", isSort: false },
          { displayName: "Tx. Date & Time", key: "tx_date_time", isSort: false },
          { displayName: "Delivery Method", key: "deliveryMethod", isSort: false },
          { displayName: "Validity Period (From – To)", key: "validityPeriod", isSort: false },
          { displayName: "Fee (LKR)", key: "fee", isSort: false },
          { displayName: "Fine (LKR)", key: "fine", isSort: false },
          { displayName: "Delivery Fee (LKR)", key: "deliveryFee", isSort: false },
          { displayName: "Additions (LKR)", key: "additions", isSort: false },
          { displayName: "Deductions (LKR)", key: "deductions", isSort: false },
          { displayName: "Total (LKR)", key: "total", isSort: false },
        ]}
        tableBodyList={[
          {
            "no": "0000000020",
            "vehicle_id": "KS-6666",
            "referenceNo": "00020",
            "ownerName": "M.L.S Gunawardana",
            "tx_date_time": "01/01/2020 08.00",
            "deliveryMethod": "-",
            "validityPeriod":"02/01/2019 - 01/01/2020",  
            "fee":"2200.00",
            "fine":"200.00",
            "deliveryFee":"100.00",
            "additions":"00.00",
            "deductions":"00.00",
            "total":"2300.00"
          }         
        ]}

        isSetAction={true}  
        actionTypeList={[
            {
                actionType: actionBtnTypes.view,  
                modelContentElement: ViewTransaction,
                dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "View Licence Transaction Details",
                    isCustomButton: false,
                    closeButton: {
                      btnText: "Close",
                      startIcon: "far fa-times-circle",
                    },
                  },                
              },
        ]}       
        />
    )
}

const PrintedPendingUI=({
    tableHeading=""
})=>{
    return (
        <DataTable
            //tableName={tableHeading}
            dataTableKey="table1"
            elementWrapperStyle={"weblicence"}
            isSetSearchFrom={true}
            isSelectRow={true}
            searchFromComponent={<SearchElement dataTableKey="table1" uiType="pending-printed" />}
            tableHeaderList={[
            { displayName: "No", key: "no", isSort: false},
            { displayName: "Vehicle ID", key: "vehicle_id", isSort: false },
            { displayName: "Reference Number", key: "referenceNo", isSort: false },
            { displayName: "Owner Name", key: "ownerName", isSort: false },
            { displayName: "Tx. Date & Time", key: "tx_date_time", isSort: false },
            { displayName: "Delivery Method", key: "deliveryMethod", isSort: false },
            { displayName: "Validity Period (From – To)", key: "validityPeriod", isSort: false },
            { displayName: "Fee (LKR)", key: "fee", isSort: false },
            { displayName: "Fine (LKR)", key: "fine", isSort: false },
            { displayName: "Delivery Fee (LKR)", key: "deliveryFee", isSort: false },
            { displayName: "Additions (LKR)", key: "additions", isSort: false },
            { displayName: "Deductions (LKR)", key: "deductions", isSort: false },
            { displayName: "Total (LKR)", key: "total", isSort: false },
            { displayName: "Revenue Licence Print Status", key: "revenueLicencePrintStatus", isSort: false },
            { displayName: "Envelop Print Status", key: "envelopPrintStatus", isSort: false },
            ]}
            tableBodyList={[
                {
                    "no": "0000000001",
                    "vehicle_id": "KS-7777",
                    "referenceNo": "00001",
                    "ownerName": "L.M.P Jayasuriya",
                    "tx_date_time": "01/09/2020 08.00",
                    "deliveryMethod": "-",
                    "validityPeriod":"02/09/2019 - 01/09/2020",  
                    "fee":"2200.00",
                    "fine":"200.00",
                    "deliveryFee":"100.00",
                    "additions":"00.00",
                    "deductions":"00.00",
                    "total":"2300.00",
                    "revenueLicencePrintStatus":"Pending",
                    "envelopPrintStatus":"Pending"
                }         
            ]}
            isSetAction={true}
            actionTypeList={[
                {
                  actionType: actionBtnTypes.view,  
                  modelContentElement: ViewTransaction,
                  dialogPopupProps: {
                      isFullScreen: false,
                      dialogTitle: "View Licence Transaction Details",
                      isCustomButton: false,
                      closeButton: {
                        btnText: "Close",
                        startIcon: "far fa-times-circle",
                      },
                    },                
                },
                // {
                //     actionType: actionBtnTypes.view, 
                //     actionBtn:{
                //         "icon":"mdi mdi-printer-settings"
                //     }                 
                // },
            ]}        
        />
    )
}

 const WebLicence=()=>{

    const [getState,setState]=useState({
        "currentTab":"tab1",
      });

     return (
        <UICardWithBackGround>
            <TabsWrapper
                onChangeFn={(event)=>setState({
                    ...getState,
                    "currentTab":event.value
                })}
                elementWrapperStyle={"webLicenceTabWrapper"}
                active={getState.currentTab}
                variant={tabsWrapperVariants.scrollable}
                scrollButtons={tabsWrapperScrollButtons.on}
            >
                <SubTab tabid={"tab1"} header={"Print Pending - Revenue Licence"}>
                    <PrintedPendingUI tableHeading={"Print Pending - Revenue Licence"}/>
                </SubTab>  
                <SubTab tabid={"tab2"} header={"Print Pending - Envelope"}>
                    <PrintedEnvelopeUI tableHeading={"Print Pending - Envelope"}/>
                </SubTab>
                <SubTab tabid={"tab3"} header={"Completed"}>
                    <PrintedUI tableHeading={"Completed"}/>
                </SubTab>                             
            </TabsWrapper>
        </UICardWithBackGround>
     )
 }

 export {
    WebLicence
 }