
import React, { useContext, useEffect, useState } from "react";
import { PaddingLessUICard, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getDealerPendingVehicleDetailsAPI, DealerLicenceFeeAPI, getDealerCurrentValidlicenceDetailsAPI, getDealerDetailsAPI, dealerPaymentHistoryAPI } from "../../../../../config/apiUrl.config";
import { getLabelValue, thousandSeparator, fromObjectToQueryString, numberThousandSeperator } from "../../../../../helpers/common-helpers/common.helpers";
import { PaymentHistory } from "../../../counter-session/pages/includes/PaymentHistory";
import {
  apiResponseStoringType,
  CoreContext,
} from "../../../core/context-providers/CoreContext.provider.js";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { ProvincesPrefixMatcher } from "../../../../../config/enum.config"
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";

const Dealer = () => {
  const searchFormKey = "vehicle_dealer_search_form";
  const formKey = "dealerLicence";
  const labelClassName = "label-left-align"
  const [coreState, coreAction] = useContext(CoreContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const issuedPdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const [getLatestLicenceState, setLatestLicenceState] = useState(null);

  const tableHeaderList = [

    // { displayName: "Tx. Num", key: "id", isSort: false },
    {
      displayName: "Tx. Date & Time",
      key: "created_date_time",
      isSort: false,
      onPrintFn: (rawData) => {
        return dateObjectToString(rawData.created_date_time, "dd/mm/YY HH:MM");
      },
      isSort: false,
    },

    {
      displayName: "Total Amount(LKR)",
      key: "total_amount",
      isSort: false,
      isRightAlign: true,
      onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.total_amount).toFixed(2))

    },
    {
      displayName: "",
      key: "",
      isSort: false,
      default: ""
    },
    {
      displayName: "Province",
      key: "pdmt",
      isSort: false,
    },
    {
      displayName: "Authority",
      key: "authority",
      isSort: false,
    },


  ]
  useEffect(() => {
    coreAction
      .sendRequestFn(
        `${getDealerDetailsAPI.url}/${_get(
          formState,
          `${searchFormKey}.dealer_id`,
          null
        )}`
      )
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: getDealerDetailsAPI.key,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn("latest_licence", "data", "", result.data.data);
          setLatestLicenceState(result.data.data);

        }
      });
    getDealerLicenceFee()

  }, []);

  const getDealerLicenceFee = () => {

    const queryObj = {
      dealer_id: _get(formState, `${searchFormKey}.dealer_id`, null),
      issued_pdmt_id: issuedPdmtId,

    };

    callApi(`${DealerLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {

          let totAddition = 0;
          let totdeduction = 0;
          let totFee = 0;
          let serviceCharge = 0;

          _get(result, "data.data.additons_deductions_total", []).forEach(value => {
            if (value.charge_effect_code === "DED") {
              totdeduction += value.total

            }
            if (value.charge_effect_code === "ADD") {
              totAddition += value.total

            }

          });

          totFee = _get(result, "data.data.fee_total", "");
          serviceCharge = _get(result, "data.data.service_charge_total", "");

          let total = (totFee + totAddition + serviceCharge - totdeduction);

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("dealer_charges_form", "data", "", result.data.data);
          formAction.changeInputFn("dealer_charges_form", "totFee", "", totFee.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "totAddition", "", totAddition.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "totdeduction", "", totdeduction.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "total", "", total.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("dealer_charges_form", "data", "", []);
          formAction.changeInputFn("dealer_charges_form", "totFee", "", 0);
          formAction.changeInputFn("dealer_charges_form", "serviceCharge", "", 0);
          formAction.changeInputFn("dealer_charges_form", "totAddition", "", 0);
          formAction.changeInputFn("dealer_charges_form", "totdeduction", "", 0);
          formAction.changeInputFn("dealer_charges_form", "total", "", 0);
        }
        formAction.changeInputFn("dealer_charges_form", "_onLoad", "", false);
      });
  };

  return (

    <div className="normalLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper">
          <div className="col-md-12">
            <strong>Dealer Id: </strong>
            <span>
              {_get(formState, `${searchFormKey}.data.dealer_id`, "")}
            </span>
          </div>
        </div>

      </div>
      <div className="col-md-12 defaultPaddingTop floatLeft">

        <div className="col-md-6 floatLeft">
          <div className="fullWidthDiv defaultHalfMarginBottom">
            <PaddingLessUICard cardHeading="Dealer's Information">

              <ViewDetailBlock
                labelWrapperStyle={labelClassName}
                label={"Name of Dealer/Manufacturer/Repairer:"}
                value={getLabelValue(
                  _get(formState, `${searchFormKey}.data.dealer_name`, "")
                )}
                labelCol={6}
              />


              <div> <strong>Dealer's Office Address: </strong></div>

              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"Line 1"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.office_address.line1`, "")
                  )}
                  labelCol={6}
                />
              </div>
              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"Line 2"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.office_address.line2`, "")
                  )}
                  labelCol={6}
                />
              </div>
              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"City"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.office_address.city`, "")
                  )}
                  labelCol={6}
                />
              </div>

              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"District"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.office_address.district.name`, "")
                  )}
                  labelCol={6}
                />
              </div>

              <div><strong>Dealer's Address: </strong></div>
              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"Line 1"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.dealer_address.line1`, "")
                  )}
                  labelCol={6}
                />
              </div>
              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"Line 2"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.dealer_address.line2`, "")
                  )}
                  labelCol={6}
                />
              </div>
              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"City"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.dealer_address.line2`, "")
                  )}
                  labelCol={6}
                />
              </div>
              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"District"}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.dealer_address.district.name`, "")
                  )}
                  labelCol={6}
                />
              </div>
              <div className="col-md-12 defaultHalfMarginBottom">
                <ViewDetailBlock
                  labelWrapperStyle={labelClassName}
                  label={"Province "}
                  value={getLabelValue(
                    _get(formState, `${searchFormKey}.data.dealer_address.province.name`, "")
                  )}
                  labelCol={6}
                />


              </div>
            </PaddingLessUICard>
          </div>
          <div className="fullWidthDiv defaultHalfMarginBottom">
            <PaymentHistory
              apiUrl={`${dealerPaymentHistoryAPI.url}/${_get(
                formState,
                `latest_licence.data.id`,
                null
              )}`}
              dataTableKey={dealerPaymentHistoryAPI}
              tableHeaderList={tableHeaderList}
              licenceType={"dealer-licence"}
              formKey={formKey}
              isView={true}
              isReprint={false}
              category={"DT"}
            />
          </div>
        </div>

        <div className="col-md-6 floatRight ">
          <div className="fullWidthDiv defaultHalfMarginBottom">
            <PaddingLessUICard cardHeading="Pending Licence">
              <DataTable
                apiUrl={`${getDealerPendingVehicleDetailsAPI.url}/${_get(formState, `${searchFormKey}.data.dealer_id`, "")}`}
                dataTableKey={"dealer_pending_licence"}
                elementWrapperStyle={"pendinLicenceTable"}
                //  elementWrapperStyle={"scrolTable"}
                tableHeaderList={[
                  {
                    displayName: "Authorized Vehicle No.",
                    key: "vehicle_number",
                    isSort: true,
                  },

                  {
                    displayName: "Validity Period (From -To)",
                    key: "start_date",
                    isSort: false,
                    onPrintFn: (rawData) => {
                      return (dateObjectToString(rawData.validity_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.validity_to, "dd/mm/YY")))
                    }
                  },
                  {
                    displayName: "Insurance Company",
                    key: "insurance_company.service.name",
                    isSort: false,
                  },
                  {
                    displayName: "Insurance Expiry Date",
                    key: "insurance_expire_date",
                    isSort: false,
                    onPrintFn: (rawData) => {
                      if (rawData.insurance_expire_date != null) {
                        return (dateObjectToString(rawData.insurance_expire_date, "dd/mm/YY"))
                      }
                      else {
                        return ("-")
                      }
                    },
                  },
                  {
                    displayName: "Request ID",
                    key: "request_id",
                    isSort: false,
                  },
                  {
                    displayName: "Requested by",
                    key: "requested_by",
                    isSort: false
                  },
                  {
                    displayName: "Requested Location",
                    key: "requested_location",
                    isSort: false,
                    onPrintFn: (rawData) => ((rawData.requested_province && rawData.requested_province !== "-") ? ProvincesPrefixMatcher[rawData.requested_province] : "") + ((rawData.requested_location && rawData.requested_location !== "-") ? ("(" + rawData.requested_location + ")") : "")

                  },
                ]}
                // tableBodyList={_get(formState, `${searchFormKey}.data.vehicle`, [])}
                isSetTableFooter={true}
                isSetTableHeader={false}
                isSetAction={false}

              />
            </PaddingLessUICard>
          </div>
          <div className="fullWidthDiv defaultHalfMarginBottom">
            <PaddingLessUICard cardHeading="Current Valid Licences ">
              <DataTable
                // elementWrapperStyle={"scrolTable"}
                elementWrapperStyle={"pendinLicenceTable"}
                apiUrl={`${getDealerCurrentValidlicenceDetailsAPI.url}/${_get(formState, `${searchFormKey}.data.dealer_id`, "")}?is_with_licence_id=true&is_with_valid_licence=true&sort_by=validity_from|asc`}
                dataTableKey={getDealerCurrentValidlicenceDetailsAPI.key}
                tableHeaderList={[
                  {
                    displayName: "Authorized Vehicle No.",
                    key: "vehicle_number",
                    default: "-",
                    isSort: false
                  },
                  { displayName: "Lic. No.", key: "licence_number", isSort: false },
                  {
                    displayName: "Serial No.",
                    key: "serial_number",
                    isSort: false,
                  },
                  {
                    displayName: "Validity Period (From - To)",
                    key: "validity_period",
                    onPrintFn: (rawData) => {
                      return (dateObjectToString(rawData.validity_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.validity_to, "dd/mm/YY")))
                    },
                    isSort: false
                  },
                  {
                    displayName: "Insurance Company",
                    key: "ins_omapny",
                    onPrintFn: (rawData) => {
                      if (rawData.insurance_company != null) {
                        return rawData.insurance_company.service.name;
                      }
                    },
                    isSort: false,
                  },
                  {
                    displayName: "Insurance Expiry Date",
                    key: "insurance_expire_date",
                    onPrintFn: (rawData) => {
                      if (rawData.insurance_expire_date != null) {
                        return (dateObjectToString(rawData.insurance_expire_date, "dd/mm/YY"))
                      }
                      else {
                        return ("-")
                      }
                    },
                    isSort: false,
                  },
                ]}
                isSetTableFooter={true}
                isSetTableHeader={false}
                isSetAction={false}
              // actionTypeList={[
              //   {
              //     actionType: actionBtnTypes.reprint,
              //     actionBtn: {
              //       icon: "mdi mdi-printer-settings",
              //     },
              //   },
              // ]}
              />
            </PaddingLessUICard>
          </div>
          <div className="fullWidthDiv">
            <PaddingLessUICard cardHeading="Total">
              <table style={{ width: "100%" }}>
                <tr>
                  <th>Fee (LKR)</th>
                  <td className="text-right">{thousandSeparator(_get(formState, "dealer_charges_form.totFee", 0), true)}</td>
                </tr>
                <tr>
                  <th>Additions (LKR)</th>

                  <td className="text-right">{Number(_get(formState, "dealer_charges_form.totAddition", 0)) === 0 ? "0.00" : " "}</td>

                </tr>
                {_get(formState, `dealer_charges_form.data.additons_deductions_total`, []).map((item) => (item.charge_effect_code === "ADD") &&
                  (
                    <tr>

                      <th>
                        <p className="text-left">{item.addition_deduction_name}</p>
                      </th>

                      <td >
                        <p className="text-right">{thousandSeparator(item.total, true)}</p>
                      </td>
                    </tr>

                  ))}

                <tr>
                  <th>Deductions (LKR)</th>
                  <td className="text-right">{Number(_get(formState, "dealer_charges_form.totdeduction", 0)) === 0 ? "(0.00)" : " "}</td>
                </tr>

                {_get(formState, `dealer_charges_form.data.additons_deductions_total`, []).map((item) => (item.charge_effect_code === "DED") &&
                  (
                    <tr>
                      <th>
                        <p className="text-left">{item.addition_deduction_name}</p>
                      </th>
                      <td >
                        <p className="text-right">({thousandSeparator(item.total, true)})</p>
                      </td>
                    </tr>

                  ))}
                <tr>
                  <th>Service Charge (LKR)</th>
                  <td className="text-right">{thousandSeparator(_get(formState, "dealer_charges_form.serviceCharge", 0), true)}</td>
                </tr>

                <tr>
                  <th>Total Amount (LKR)</th>
                  <td className="text-right">
                    <b>{thousandSeparator(_get(formState, "dealer_charges_form.total", 0), true)}</b>
                  </td>
                </tr>
              </table>
            </PaddingLessUICard>
          </div>
        </div>
        

       

      </div>
    </div>

  );
};

export default Dealer;
