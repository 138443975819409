/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-07 12:09:06
 */

import React, { useContext } from "react";
import {  DataTable } from "../../../../../ui-components/ui-elements/table/DataTable";
import { dealerCommentPostAPI } from "../../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";


const DealerCommentHistory = () => {
  const searchFormKey = "dealer_search_form";
  const [formState, formAction] = useContext(FormContext);
  return (
    // <div className="commentHistoryWrapper">
    //   <div className="commentElementWrapper">
    //     <div className={"row"}>
    //       <div className={"col-md-6"}>
    //         <h1 className="defaultHeading">
    //           <strong>Commented At : </strong> 09/10/2020 13:05
    //         </h1>
    //       </div>
    //       <div className={"col-md-6"}>
    //         <h1 className="defaultHeading">
    //           <strong>Commented By : </strong> Roshan Liyanage
    //         </h1>
    //       </div>
    //       <div className={"col-md-6"}>
    //         <h1 className="defaultHeading">
    //           <strong>Authority : </strong> Kaduwela
    //         </h1>
    //       </div>
    //       <div className={"col-md-6"}>
    //         <h1 className="defaultHeading">
    //           <strong>Province : </strong> Western Province
    //         </h1>
    //       </div>
    //     </div>

    //     <p>
    //       Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
    //       commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
    //       et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
    //       felis, ultricies nec
    //     </p>
    //   </div>
    // </div>

    <DataTable
        tableName="View Comment History"
        apiUrl={`${dealerCommentPostAPI.url}/${_get(formState,`${searchFormKey}.data.id`,'')}`}
        dataTableKey={dealerCommentPostAPI.key}
        isSetSearchFrom={false}
        // defaultSearchFormObject={{
        //     "name": "",
        //     "code": "",
        //     "active": null
        //   }}
        //searchFromComponent={<SearchElement dataTableKey={commentPostAPI.key} />}
        isSetAction={false}
        tableHeaderList={[
            { displayName: "Vehicle Comment", key: "comment", isSort: false },
            { displayName: "Commented at", key: "created_date", onPrintFn: (rawData)=>dateObjectToString(rawData.created_date, "dd/mm/YY HH:MM") , isSort: false },
            { displayName: "Commented by", key: "created_by", isSort: false,onPrintFn: (rawData)=>rawData.created_by+"("+rawData.created_role_name+")"          },
            { displayName: "Authority", key: "authority", isSort: false },
            { displayName: "Province", key: "province", isSort: false },
        ]}
    />
  );
};

export { DealerCommentHistory };
