/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 09:25:25
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-04-05 19:43:03
 */

import React, { createContext, useReducer, useContext } from 'react';

import { authAction } from '../core-helpers/authContext.helpers';
import {
  setAuthTokenKey,
  setUnauthorisedUserKey,
  setAuthUserObjectKey,
} from '../../../../config/actionKeys.config';

import { UIContext } from '../../../ui-components/context-providers/UIContext.provider';

const initialState = {
  accessToken: null,
  refreshToken: null,
  keycloakObject: null,
  isAuthenticated: null,
  keycloak: null,
  authUser: {
    id: null,
    name: null,
    avatar: null,
    organizations: [],
    roles: [],
    permissions: [],
    current_delegation: null,
    service_id: null,
    rolePrefix:null,
    authorityId:null,
    role_level_code:null,
    role_category_code:null,
    provinceId:null,
    provinceName:null,
  },
};

const AuthContext = createContext({});

const authReducer = (state, action) => {
  switch (action.type) {
    case setAuthTokenKey:
      if(action.playload.role_level_code == 'INT_AU') {
        return {
          ...state,
          accessToken: action.playload.accessToken,
          refreshToken: action.playload.refreshToken,
          keycloakObject: action.playload.keycloakObject,
          isAuthenticated: true,
          keycloak: action.playload.keycloak,
          authUser: {
            ...state.authUser,
            id: action.playload.id,
            name: action.playload.name,
            roles: action.playload.role,
            permissions: action.playload.permission,
            current_delegation: action.playload.current_delegation,
            service_id: action.playload.service_id,
            rolePrefix: action.playload.role_prefix,
            // pdmtCode: action.playload.pdmtCode,
            authority_type_code: action.playload.authority_type_code,
            authorityName: action.playload.authority_name,
            authorityCode: action.playload.authority_code,
            provinceCode: action.playload.province_code,
            authorityPrefix: action.playload.authority_prefix,
            authorityId:action.playload.authority_id,
            role_level_code:action.playload.role_level_code,
            pdmtId:action.playload.pdmt_id,
            role_category_code:action.playload.role_category_code,
            provinceId:action.playload.province_id,
            provinceName:action.playload.province_name,
          },
        };
      } else if(action.playload.role_level_code == 'INT_PRV') {
        return {
          ...state,
          accessToken: action.playload.accessToken,
          refreshToken: action.playload.refreshToken,
          keycloakObject: action.playload.keycloakObject,
          isAuthenticated: true,
          keycloak: action.playload.keycloak,
          authUser: {
            ...state.authUser,
            id: action.playload.id,
            name: action.playload.name,
            roles: action.playload.role,
            permissions: action.playload.permission,
            current_delegation: action.playload.current_delegation,
            service_id: action.playload.service_id,
            rolePrefix: action.playload.role_prefix,
            pdmtCode: action.playload.pdmt_code,
            authorityCode: action.playload.authority_code,
            authorityPrefix: action.playload.authority_prefix,
            authorityId:action.playload.authority_id,
            authorityName: action.playload.authority_name,
            role_level_code:action.playload.role_level_code,
            pdmtId:action.playload.pdmt_id,
            role_category_code:action.playload.role_category_code,
            provinceId:action.playload.province_id,
            provinceName:action.playload.province_name,
            provinceCode:action.playload.province_code

          },
        };
      }
      
    // eslint-disable-next-line no-fallthrough
    case setUnauthorisedUserKey:
      return {
        ...state,
        ...initialState,
        isAuthenticated: false,
      };
    case setAuthUserObjectKey:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          ...action.playload,
        },
      };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [, uiDispatch] = useContext(UIContext);
  const dispatchFuntion = authAction(dispatch, uiDispatch);
  return (
    <AuthContext.Provider value={[state, dispatchFuntion]}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
