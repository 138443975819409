import { callApi } from "./callApi.helpers";
import { getLocationNameAPI, getUsernameAPI } from "../../config/apiUrl.config";
import { isEmpty } from "lodash";

const getUsernamenLocationFn = (event, apiType, field, formAction, formGroupName) => {
    const api = apiType === "user" ? getUsernameAPI.url : getLocationNameAPI.url;
    
    if (isEmpty(event.value)) {
        formAction.changeInputFn(formGroupName, field, "", "");
    } else {
        callApi(`${api}/${event.value}`)
            .headers(true)
            .method("get")
            .send((err, result) => {
                const keyName = apiType === "user" ? "username" : "location_name";
                if (err) {
                    console.log("???????error???????......", err)
                } else {
                    if (result) {
                        formAction.changeInputFn(formGroupName, field, "", result.data.data[keyName])
                    } else {
                        console.log("???????error2???????..", result)
                    }
                }
            })
    }
}

export {
    getUsernamenLocationFn
}