import React, { Fragment, useContext, useEffect, useState } from "react";

import {
  FormWrapper,
  SubmitButton,
} from "../../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import {
  actionBtnTypes,
  DataTable,
} from "../../../../../ui-components/ui-elements/table/DataTable";
import { Reason } from "../../../../request-for-approval/pages/includes/Reason";
import DocumentsUploader from "../../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import {
  DealersLicenceReissuanceRequestAddAPI,
  DealersLicenceReissuanceTempRequestsAPI,
  getDealerTempRequestsAPI,
  getDealerVehicleLicenceDetailsAPI,
  uploadDetailSaveAPI,
  getDealerVehicleDetailsOrganisationServiceAPI,
  uploadDetailGetAPI,
  dealerLicenceReprintAPI,
  getDealerVehicleDetailsAPI,
  dealerLicenceReissuanceAPI
} from "../../../../../../config/apiUrl.config";
import { Action } from "../../../../request-for-approval/pages/includes/Action";
import {
  _get,
  _set,
} from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { apiResponseStoringType } from "../../../../core/context-providers/CoreContext.provider";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import {
  getDataByFormObject,
  isEmptyValue,
} from "../../../../../../helpers/common-helpers/common.helpers";
import {
  RequestTypesList,
  RequestType,
  ActionTypeList,
  ProvincesByCodeMatcher
} from "../../../../../../config/enum.config";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";

const searchFormKey = "dealer_search_form";
let recId = "";

const DealersLicenceReissuance = (props) => {
  const {uiType, formKey, requestType, approvalRequestId, dataSubmittedForApproval} = props;
  const [formState, formAction] = useContext(FormContext);
  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8));
  }, []);

  recId = requestId;

  return (
    <div className={"container-fluid p-0"}>
      <div className={"row"}>
        <FormWrapper 
            setGroupName={formKey}
            setFormObject={props.uiType === "APR" ? { ui_request_id: props.approvalRequestId } : {
                request_id: _set(formState, `${formKey}.request_id`, requestId),
        }}>
          <div className="col-md-12">
        <DataTable
          tableName="Authorized Vehicle Number Information"
          apiUrl={ props.uiType === "APR" ? `${dealerLicenceReissuanceAPI.url}/${approvalRequestId}?isApproval=true` : `${getDealerVehicleDetailsOrganisationServiceAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}?is_with_licence_id=true&is_with_valid_licence=true`}
          dataTableKey={getDealerVehicleDetailsAPI.key}
          tableHeaderList={[
            {
              displayName: "Authorised Vehicle No.",
              key: props.uiType === "APR" ? "vehicle_no" : "vehicle_number",
              isSort: true,
            },
            {
              displayName: "Current Licence Number",
              key: props.uiType === "APR" ? "current_licence_number" : "licence_number",
              isSort: false,
            },
            {
              displayName: "Insurance Company",
              key: props.uiType === "APR" ? "insurance_company" : "insurance_company.service.name",
              isSort: false,
            },
            {
              displayName: "Insurance Expiry Date",
              key: "insurance_expire_date",
              isSort: false,
              onPrintFn: (rawData) => {
                return dateObjectToString(
                  rawData.insurance_expire_date,
                  "dd/mm/YY"
                );
              },
            },
          ]}
          isSetTableHeader={true}
          isSetAction={true}
          actionTypeList={[
            {
                actionType: actionBtnTypes.add,
                actionBtn: { tooltip: 'Add' },
                
            }
        ]}
        />
      </div>
        </FormWrapper>
      </div>
      {console.log('huuuuu000',_get(formState, getDealerVehicleDetailsAPI.key, []))}
      {_get(formState, getDealerVehicleDetailsAPI.key, []).length !==0 && <div className={"row defaultMarginTop"}>
                    <div className={"col-md-12"}>
                        <DataTable
                            tableName={"Selected Vehicles For Re-issuance"}
                            dataTableKey={getDealerVehicleDetailsAPI.key}
                            isSetSearchFrom={false}
                            isSelectRow={false}
                            tableHeaderList={[
                              { 
                                displayName: "Authorized Vehicle No",
                                key: `${props.uiType === "APR" ? 'vehicle_no' : 'vehicle_number'}`, 
                                default: "-", 
                                isSort: false 
                            },
                            {
                              displayName: "Current Licence Number",
                              key: props.uiType === "APR" ? "current_licence_number" : "licence_number",
                              isSort: false,
                            },
                            {
                              displayName: "Insurance Company",
                              key: props.uiType === "APR" ? "insurance_company" : "insurance_company.service.name",
                              isSort: false,
                            },
                            {
                              displayName: "Insurance Expiry Date",
                              key: "insurance_expire_date",
                              isSort: false,
                              onPrintFn: (rawData) => {
                                return dateObjectToString(
                                  rawData.insurance_expire_date,
                                  "dd/mm/YY"
                                );
                              },
                            },
                            ]}
                            tableBodyList={_get(formState, getDealerVehicleDetailsAPI.key, [])}
                            actionTypeList={[
                                {
                                    actionType: actionBtnTypes.delete,
                                    actionBtn: { tooltip: 'Remove' },
                                }
                            ]}
                            isSetAction={true}
                            isSetTableHeader={true}
                            isSetTableFooter={true}
                        />
                    </div>

                </div>}
      <div className={"row"}>
        <div className="col-md-12 col-sm-12 defaultMarginTop">
          <Reason filterKey={'dealers_licence_reissuance'} formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
                     requestType={uiType}
                     dataSubmittedForApproval={props.dataSubmittedForApproval} />
        </div>

        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <DocumentsUploader
          requestType={requestType}
            isUploadFn={true}
            formGroupName={`${formKey}-upload`}
            tableApiUrl={uiType  === 'APR' ?  `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
            requestId={uiType  === 'APR' ?  props.approvalRequestId : requestId}
            uiType={uiType}
          />
        </div>

        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <Action 
            formKey={formKey} uiType={uiType} dataSubmittedForApproval={props.dataSubmittedForApproval}
          />
        </div>
      </div>
    </div>
  );
};

const DealersLicenceReissuanceBtn = (props) => {
  const { approvalRequestId, requestType, dataSubmittedForApproval} = props;

  const [formState] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  const dealerId = _get(formState, `${searchFormKey}.searchKey`, "");
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const formKey = props.formKey;
  const [, uiAction] = useContext(UIContext);
  const selectedVehicles = _get(formState, getDealerVehicleDetailsAPI.key, []).map(vehicle => {
    return {
        vehicle_no: props.uiType === 'assign&Perform' ? vehicle.vehicle_no : vehicle.vehicle_number,
        licence_no: props.uiType === 'assign&Perform' ? vehicle.current_licence_number : vehicle.licence_number,
        insurance_company_id: props.uiType === 'assign&Perform' ? vehicle.insurance_company_id ? vehicle.insurance_company_id : vehicle.insurance_company : vehicle.insurance_company,
        insurance_expire_date: vehicle.insurance_expire_date
    }
})
const approvalAction = _get(
  formState,
  `${formKey}.action`,
 null
)
const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
      const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
const userName = _get(authStatus, "authUser.name", "<anonymous name>");
const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ? 
  _get(authStatus, "authUser.provinceName", "<anonymous province>") : 
  ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");
  
return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        formGroupName={formKey}
        tooltip={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        isValidate={true}
        isSetHeaders={true}
        validationObject={{
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Dealers_Licence_Reissuance.action", "") === 'FRD' ? (_get(formState, "Dealers_Licence_Reissuance.forward_role", "") === 'OPT' ?
          {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            forward_province: "Province",
            forward_role: "Role",
            forward_authority: "Authority",
          } : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            forward_province: "Province",
            forward_role: "Role",
          }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            }) : {
              "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            
          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "Dealers_Licence_Reissuance.action", "") === 'FRD' ? (_get(formState, "Dealers_Licence_Reissuance.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
          }): {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            }) : {
              "reason_id": "required",
              "remark": "required|max:500",
              "forward_province": "required",
              "forward_role": "required",
              "action": "required",
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "Dealers_Licence_Reissuance.action", "") === 'FRD' ? (_get(formState, "Dealers_Licence_Reissuance.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
            "forward_authority": "Authority is required",
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
          }): {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            }) : {
              "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province": "Forward Province is required.",
            "forward_role": "Forward Role is required.",
            "action": "Action is required.",
          },
      }}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
              status: true,
              message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
              messageType: "success",
          } : {
              status: true,
              message: `Approval Request successfully submitted. Request ID : ${recId}.`,
              messageType: "success",
          },
          failed: props.uiType === 'assign&Perform' ? {
              status: true,
              message: `Approval Request Decision Submit Failed. Please Try Again.`,
              messageType: "error",
          } : {
              status: true,
              message: `Approval Request Submit Failed. Please Try Again.`,
              messageType: "error",
            },
          
      }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${DealersLicenceReissuanceRequestAddAPI.url}/${props.uiType === 'assign&Perform' ? dataSubmittedForApproval.dealer_id : dealerId}`,
            key: DealersLicenceReissuanceRequestAddAPI.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          if(_get(formState, getDealerVehicleDetailsAPI.key, []).length !== 0 || formObject.action == 'REJ') {
            if(props.uiType === 'assign&Perform') {
              return getDataByFormObject({
                ...formObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["reissue_dlr_lcn"],
                // status: RequestStatus.APPROVAL_PENDING,
                request_type: RequestType.Dealers_Licence_Reissuance,
                pdmt_id: pdmtId,
                // authority_id: authorityId,
                // request_id: formObject.request_id,
                ui_request_id: _get(props, "approvalRequestId", null),
                reason_id: formState[`Dealers_Licence_Reissuance-new`].reason_id,
                counter_session_id: sessionId ? sessionId : null,
                forward_authority_service_id: serviceId,
                document_details: [],
                action: formObject.action == 'FRD' ? {
                  action: formObject.action,
                  remark: formObject.remark,
                  forward_province: formObject.forward_province,
                  forward_role: formObject.forward_role,
                  forward_authority: formObject.forward_authority
                } : {
                  action: formObject.action,
                  remark: formObject.remark,
                },
                selected_vehicles: selectedVehicles
              });
            } else {
              return getDataByFormObject({
                ...formObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["reissue_dlr_lcn"],
                // status: RequestStatus.APPROVAL_PENDING,
                request_type: RequestType.Dealers_Licence_Reissuance,
                pdmt_id: pdmtId,
                authority_id: authorityId,
                request_id: formObject.request_id,
                reason_id: formObject.reason_id,
                counter_session_id: sessionId ? sessionId : null,
                forward_authority_service_id: serviceId,
                document_details: [],
                action: {
                  action: formObject.action,
                  remark: formObject.remark,
                  forward_province: formObject.forward_province,
                  forward_role: formObject.forward_role,
                  forward_authority: formObject.forward_authority
                },
                selected_vehicles: selectedVehicles
              });
            }
            
          } else {
            uiAction.setFlashMessage({
              status: true,
              message: 'Please select at least one Authorized Vehicle Number for reissuance',
              messageType: "error",
            });
          }
          
        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "assign&Perform" ? 'put' : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          // console.log('REEEEE', response);
          // console.log('REEEEErrrrrrrrr', error);

          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if(props.uiType === 'assign&Perform') {
                props.refreshMainScreenAfterPerformFn()
                props.onClosePopupFn();
              } else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request successfully submitted. Request ID : ${recId}`,
                  messageType: "success",
                });
                props.onClosePopupFn();
              }
          } else {
            console.log('errorpp', error, response);
            uiAction.setFlashMessage({
              status:
                _get(response, "data.errors.details[0].message", null) == null
                  ? false
                  : true,
              message: 'Please select at least one Authorized Vehicle Number for reissuance.',
              messageType: "error",
            });
            
          }
        }}
      />
    </Fragment>
  );
};

export { DealersLicenceReissuance, DealersLicenceReissuanceBtn };
