/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-12 15:23:52
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-10-22 19:45:35
 */
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  addLicenceReceiptTransactionAPI,
  licenceTypesListAPI, uploadDetailGetAPI
} from "../../../../../config/apiUrl.config";
import {
  EntryMode,
  IssuedSystem,
  IssueMode,
  TransactionType,
  IssueModeNonUser
} from "../../../../../config/enum.config";
import { templateTypes } from "../../../../../config/template.config";
import {
  getDataByFormObject,
  isEmptyValue,
  numberThousandSeperator
} from "../../../../../helpers/common-helpers/common.helpers";
import {
  _get,
  _getFloat,
  _set,
} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  DocumentUploader,
  UICard,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import DocumentsUploader from "../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import {
  DatePickerWithState,
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { Action } from "./Action";
import { Reason } from "./Reason";
import { RequestStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../../config/enum.config";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";

const today = new Date();
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1)

const LcnSubmitBtn = (props) => {
  const [formState] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  const [, uiAction] = useContext(UIContext);

  const user_role_level = _get(authStatus, "authUser.role_level_code", "");
  const reqId = _get(formState, "add-licence.request_id", "")
  // const vehicle_no = _get(
  //   formState,
  //   "vehicle_search_form.data.vehicle_no",
  //   null
  // );
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  const approvalAction = _get(
    formState,
    "add-licence.action",
    null
  )
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={"add-licence"}
        tooltip={"Submit"}
        isValidate={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${reqId}`,
            messageType: "success",
          },
          failed: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request Decision Submit Failed. Please Try Again.`,
            messageType: "error",
          } : {
            status: true,
            message: `Approval Request Submit Failed. Please Try Again.`,
            messageType: "error",
          },
        }}
        validationObject={(props.uiType === 'assign&Perform' && _get(formState, "add-licence.action", "") === 'REJ') ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "add-licence.action", "") === 'FRD' ? (_get(formState, "add-licence.forward_role", "") === 'OPT' ?
          {
            issued_system: "Issued System",
            entry_mode: "Entry Mode",
            tx_type: "Tx. Type",
            licence_type: "Licence Type",
            issue_mode: "Issue Mode",
            serial_receipt_no: "Serial Number / Receipt Number",
            licence_receipt_no: "Lic. No / Rec. No",
            from: "From",
            to: "To",
            issued_date: "Issued Date",
            fee: "Fee",
            fine: "Fine",
            addition: "Additions",
            deduction: "Deductions",
            print_required: "Print Required",
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            forward_province: "Province",
            forward_role: "Role",
          } : {
            issued_system: "Issued System",
            entry_mode: "Entry Mode",
            tx_type: "Tx. Type",
            licence_type: "Licence Type",
            issue_mode: "Issue Mode",
            serial_receipt_no: "Serial Number / Receipt Number",
            licence_receipt_no: "Lic. No / Rec. No",
            from: "From",
            to: "To",
            issued_date: "Issued Date",
            fee: "Fee",
            fine: "Fine",
            addition: "Additions",
            deduction: "Deductions",
            print_required: "Print Required",
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            forward_province: "Province",
            forward_role: "Role",
          }) : {
            issued_system: "Issued System",
            entry_mode: "Entry Mode",
            tx_type: "Tx. Type",
            licence_type: "Licence Type",
            issue_mode: "Issue Mode",
            serial_receipt_no: "Serial Number / Receipt Number",
            licence_receipt_no: "Lic. No / Rec. No",
            from: "From",
            to: "To",
            issued_date: "Issued Date",
            fee: "Fee",
            fine: "Fine",
            addition: "Additions",
            deduction: "Deductions",
            print_required: "Print Required",
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
          }) : {
          reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            forward_province: "Province",
            forward_role: "Role",

        },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "add-licence.action", "") === 'FRD' ? (_get(formState, "add-licence.forward_role", "") === 'OPT' ?
          {
            issued_system: "required",
            entry_mode: "required",
            tx_type: "required",
            licence_type: "required",
            issue_mode: "required",
            serial_receipt_no: "required",
            licence_receipt_no: "required",
            from: "required",
            to: "required",
            issued_date: "required",
            fee: "required",
            fine: "required",
            addition: "required",
            deduction: "required",
            print_required: "required",
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_province: "required",
            forward_role: "required",
          } : {
            issued_system: "required",
            entry_mode: "required",
            tx_type: "required",
            licence_type: "required",
            issue_mode: "required",
            serial_receipt_no: "required",
            licence_receipt_no: "required",
            from: "required",
            to: "required",
            issued_date: "required",
            fee: "required",
            fine: "required",
            addition: "required",
            deduction: "required",
            print_required: "required",
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_province: "required",
            forward_role: "required",
          }) : {
            issued_system: "required",
            entry_mode: "required",
            tx_type: "required",
            licence_type: "required",
            issue_mode: "required",
            serial_receipt_no: "required",
            licence_receipt_no: "required",
            from: "required",
            to: "required",
            issued_date: "required",
            fee: "required",
            fine: "required",
            addition: "required",
            deduction: "required",
            print_required: "required",
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
          }) : {
            reason_id: "required",
            remark: "required|max:500",
            forward_province: "required",
            forward_role: "required",
            action: "required"

        },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "add-licence.action", "") === 'FRD' ? (_get(formState, "add-licence.forward_role", "") === 'OPT' ?
          {
            "issued_system.required": "Issued System is required.",
            "entry_mode.required": "Entry Mode is required.",
            "tx_type.required": "Tx. Type is required.",
            "licence_type.required": "Licence Type is required.",
            "issue_mode.required": "Issue Mode is required.",
            "serial_receipt_no.required":
              "Serial Number / Receipt Number is required.",
            "licence_receipt_no.required": "Lic. No / Rec. No is required.",
            "from.required": "From is required.",
            "to.required": "To is required.",
            "issued_date.required": "Issued Date is required.",
            "fee.required": "Fee is required.",
            "fine.required": "Fine is required.",
            "addition.required": "Additions is required.",
            "deduction.required": "Deductions is required.",
            "print_required.required": "Print Required is required.",
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
          } : {
            "issued_system.required": "Issued System is required.",
            "entry_mode.required": "Entry Mode is required.",
            "tx_type.required": "Tx. Type is required.",
            "licence_type.required": "Licence Type is required.",
            "issue_mode.required": "Issue Mode is required.",
            "serial_receipt_no.required":
              "Serial Number / Receipt Number is required.",
            "licence_receipt_no.required": "Lic. No / Rec. No is required.",
            "from.required": "From is required.",
            "to.required": "To is required.",
            "issued_date.required": "Issued Date is required.",
            "fee.required": "Fee is required.",
            "fine.required": "Fine is required.",
            "addition.required": "Additions is required.",
            "deduction.required": "Deductions is required.",
            "print_required.required": "Print Required is required.",
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
          }) : {
            "issued_system.required": "Issued System is required.",
            "entry_mode.required": "Entry Mode is required.",
            "tx_type.required": "Tx. Type is required.",
            "licence_type.required": "Licence Type is required.",
            "issue_mode.required": "Issue Mode is required.",
            "serial_receipt_no.required":
              "Serial Number / Receipt Number is required.",
            "licence_receipt_no.required": "Lic. No / Rec. No is required.",
            "from.required": "From is required.",
            "to.required": "To is required.",
            "issued_date.required": "Issued Date is required.",
            "fee.required": "Fee is required.",
            "fine.required": "Fine is required.",
            "addition.required": "Additions is required.",
            "deduction.required": "Deductions is required.",
            "print_required.required": "Print Required is required.",
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
        },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${addLicenceReceiptTransactionAPI.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
            key: addLicenceReceiptTransactionAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "add-licence.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.serial_receipt_no) {
              uiAction.setFlashMessage({
                status: true,
                message: `Serial number is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.licence_receipt_no) {
              uiAction.setFlashMessage({
                status: true,
                message: `Licence number is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.issued_system) {
              uiAction.setFlashMessage({
                status: true,
                message: `Issued System is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.entry_mode) {
              uiAction.setFlashMessage({
                status: true,
                message: `Entry Mode is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.tx_type) {
              uiAction.setFlashMessage({
                status: true,
                message: `Tx. Type is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.licence_type) {
              uiAction.setFlashMessage({
                status: true,
                message: `Licence Type is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.issue_mode) {
              uiAction.setFlashMessage({
                status: true,
                message: `Issue Mode is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.fee) {
              uiAction.setFlashMessage({
                status: true,
                message: `Fee is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.fine) {
              uiAction.setFlashMessage({
                status: true,
                message: `Fine is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.addition) {
              uiAction.setFlashMessage({
                status: true,
                message: `Addition is required`,
                messageType: "error",
              });

              return
            }
            if (_get(formState, "add-licence.action", "") !== 'REJ' && !fromObject.deduction) {
              uiAction.setFlashMessage({
                status: true,
                message: `Deduction is required`,
                messageType: "error",
              });

              return
            }

            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["add_lcn_r_txn"],
              current_licence_type: current_licence_type,
              pdmt_id: pdmtId,
              ui_request_id: props.approvalRequestId,
              // authority_id: authorityId,
              // request_id: fromObject.request_id,
              issued_system: isEmptyValue(fromObject.issued_system)
                ? null
                : fromObject.issued_system,
              entry_mode: isEmptyValue(fromObject.entry_mode)
                ? null
                : fromObject.entry_mode,
              tx_type: fromObject.tx_type,
              // licence_type: fromObject.licence_type,
              issue_mode: fromObject.issue_mode,
              serial_receipt_no: fromObject.serial_receipt_no,
              licence_receipt_no: fromObject.licence_receipt_no,
              from: isEmptyValue(fromObject.from) ? today : fromObject.from,
              to: isEmptyValue(fromObject.to) ? today : fromObject.to,
              issued_date: isEmptyValue(fromObject.issued_date)
                ? today
                : fromObject.issued_date,
              fee: isEmptyValue(fromObject.fee)
                ? null
                : parseFloat(fromObject.fee).toFixed(2),
              fine: isEmptyValue(fromObject.fine)
                ? null
                : parseFloat(fromObject.fine).toFixed(2),
              addition: isEmptyValue(fromObject.addition)
                ? null
                : parseFloat(fromObject.addition).toFixed(2),
              deduction: isEmptyValue(fromObject.deduction)
                ? null
                : parseFloat(fromObject.deduction).toFixed(2),
              print_required: isEmptyValue(fromObject.print_required)
                ? true
                : fromObject.print_required,
              reason_id: formState['add-licence-new'].reason_id,
              vehicle_no: vehicle_no,
              counter_session_id: '00001',
              forward_authority_service_id: serviceId,
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              },
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["add_lcn_r_txn"],
              current_licence_type: current_licence_type,
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: fromObject.request_id,
              issued_system: isEmptyValue(fromObject.issued_system)
                ? null
                : fromObject.issued_system,
              entry_mode: isEmptyValue(fromObject.entry_mode)
                ? null
                : fromObject.entry_mode,
              tx_type: fromObject.tx_type,
              licence_type: fromObject.licence_type,
              issue_mode: fromObject.issue_mode,
              serial_receipt_no: fromObject.serial_receipt_no,
              licence_receipt_no: fromObject.licence_receipt_no,
              from: isEmptyValue(fromObject.from) ? today : fromObject.from,
              to: isEmptyValue(fromObject.to) ? today : fromObject.to,
              issued_date: isEmptyValue(fromObject.issued_date)
                ? today
                : fromObject.issued_date,
              fee: isEmptyValue(fromObject.fee)
                ? null
                : parseFloat(fromObject.fee).toFixed(2),
              fine: isEmptyValue(fromObject.fine)
                ? null
                : parseFloat(fromObject.fine).toFixed(2),
              addition: isEmptyValue(fromObject.addition)
                ? null
                : parseFloat(fromObject.addition).toFixed(2),
              deduction: isEmptyValue(fromObject.deduction)
                ? null
                : parseFloat(fromObject.deduction).toFixed(2),
              print_required: fromObject.tx_type === "ARR" ? false : isEmptyValue(fromObject.print_required)
                ? true
                : fromObject.print_required,
              reason_id: fromObject.reason_id,
              vehicle_no: vehicle_no,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              },
            });
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === 'assign&Perform' ? "put" : "post",
        }}
        onResponseCallBackFn={(response, error) => {

          console.log('RRRR', response, error);
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              if(response.data?.response?.statusCode === 400) {
                uiAction.setFlashMessage({
                  status: true,
                  message: `${response.data.response?.message ?? 'Approval Request Decision Submit Failed. Please Try Again.'}`,
                  messageType: "error",
                });
              } else {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
              }
            } else {
              if(response.data?.response?.statusCode === 400) {
                uiAction.setFlashMessage({
                  status: true,
                  message: `${response.data.response?.message ?? 'Approval Request Decision Submit Failed. Please Try Again.'}`,
                  messageType: "error",
                });
              } else {
                props.refreshMainScreenDataFn()
                props.onClosePopupFn();
              }
              
            }

          } else {
            if(response.data?.meta?.message === 'failed' && Object.keys(response.data.errors).length === 1) {
              uiAction.setFlashMessage({
                status: true,
                message: `${response.data.errors?.name ?? 'Approval Request Decision Submit Failed. Please Try Again.'}`,
                messageType: "error",
              });
            }
          }
        }}
      />
    </Fragment>
  );
};

const AddLicenceUI = (props) => {
  const formKey = props.formKey;
  const uiType = props.uiType;
  const [formState] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  const userRole = _get(authStatus, "authUser.role_level_code", "");
  const mandatory = props.uiType === 'APR' ? true : false;
  let total = props.uiType === 'APR' ? (
    _getFloat(formState, `${formKey}.fee`, 0) +
    _getFloat(formState, `${formKey}.fine`, 0) +
    _getFloat(formState, `${formKey}.addition`, 0) -
    _getFloat(formState, `${formKey}.deduction`, 0)
  ).toFixed(2) : (
    _getFloat(formState, `${formKey}.fee`, 0) +
    _getFloat(formState, `${formKey}.fine`, 0) +
    _getFloat(formState, `${formKey}.addition`, 0) -
    _getFloat(formState, `${formKey}.deduction`, 0)
  ).toFixed(2);

  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8));
  }, []);
  return (
    <div className="row">
      <FormWrapper
        setGroupName={formKey}
        apiUrl={`${addLicenceReceiptTransactionAPI.url}/get-licence/${props.approvalRequestId}`}
        onRebuildResponseFn={(response) => {
          // console.log('REE===', response);
          return {
            issued_system: response.issued_system,
            entry_mode: response.entry_mode,
            tx_type: response.txn_type,
            licence_type: response.licence_type_code !== 'nul' ? response.licence_type_code : undefined,
            issue_mode: response.issue_mode,
            serial_receipt_no: response.receipt_serial_number !== null ? response.receipt_serial_number : '',
            licence_receipt_no: response.licence_no_receipt_no !== null ? response.licence_no_receipt_no : '',
            from: response.from,
            to: response.to,
            issued_date: response.issued_date,
            fee: response.fee,
            fine: response.fine,
            addition: response.addition,
            deduction: response.deduction,
            print_required: response.print_required,
            total: (Number(response.fee) + Number(response.fine) + Number(response.addition) - Number(response.deduction)).toFixed(2),

            "_onLoad": true
          }
        }}
        setFormObject={props.uiType !== 'APR' && {
          request_id: _set(formState, `${formKey}.request_id`, requestId),
        }}
      >
        <div className="col-md-12">
          <UICard>
          <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Issued System <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={IssuedSystem}
                  inputName={"issued_system"}
                  emptySelectOptionTxt={"Select Issued System"}
                //isRequired={mandatory}
                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Entry Mode <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={EntryMode}
                  inputName={"entry_mode"}
                  emptySelectOptionTxt={"Select Entry Mode"}
                //isRequired={mandatory}
                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Tx. Type <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={TransactionType}
                  inputName={"tx_type"}
                  emptySelectOptionTxt={"Select Tx. Type"}
                //isRequired={mandatory}
                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Licence Type <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <SelectBoxWithState
                  apiUrl={`${licenceTypesListAPI.url}?sort_by=display_order%7Casc&prefixes=NO&prefixes=FE&prefixes=NU&prefixes=CA`}
                  formGroupName={formKey}
                  apiStateKey={licenceTypesListAPI.key}
                  keyName={"code"}
                  valueName={"name"}
                  inputName={"licence_type"}
                  emptySelectOptionTxt={"Select Licence Type"}
                //isRequired={mandatory}
                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Issue Mode <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>

              <div className="col-md-3 col-sm-6">
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={_get(formState, `${formKey}.licence_type`, null) === 'NU' ? IssueModeNonUser : IssueMode}
                  inputName={"issue_mode"}
                  emptySelectOptionTxt={"Select Issue Mode"}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">
                Serial Number / Receipt Number <span className="mandatory">{mandatory === true ? "*" : ""}</span>
              </div>
              <div className="col-md-3 col-sm-6">
                <InputBoxWithState
                  formGroupName={formKey}
                  inputValue={""}
                  inputName={"serial_receipt_no"}
                  maxLength={12}
                  elementStyle={"text-right"}


                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Lic. No / Rec. No <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <InputBoxWithState
                  formGroupName={formKey}
                  inputValue={""}
                  inputName={"licence_receipt_no"}
                  maxLength={15}
                  elementStyle={"text-right"}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">From <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <DatePickerWithState
                  uiType={templateTypes.bootstrap}
                  formGroupName={formKey}
                  labelText=" "
                  inputName={"from"}
                  maxDate={today}
                  minDate={"1970/01/01"}
                  inputValue={today}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">To <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <DatePickerWithState
                  uiType={templateTypes.bootstrap}
                  formGroupName={formKey}
                  labelText=" "
                  inputName={"to"}
                  maxDate={"2100/01/01"}
                  minDate={_get(formState, `${formKey}.from`, undefined) !== undefined ? _get(formState, `${formKey}.from`, undefined) : new Date()}
                  inputValue={today}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Issued Date <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <DatePickerWithState
                  uiType={templateTypes.bootstrap}
                  formGroupName={formKey}
                  labelText=" "
                  inputName={"issued_date"}
                  minDate={"1970/01/01"}
                  maxDate={today}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Fee (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <NumberInputBoxWithState
                  elementStyle={"text-right"}
                  formGroupName={formKey}
                  inputName={"fee"}
                  isThousandSeparator={false}
                  isAllowNegative={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }

                    return checkValue.length <= 8;
                  }}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Fine (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <NumberInputBoxWithState
                  elementStyle={"text-right"}
                  formGroupName={formKey}
                  inputName={"fine"}
                  isThousandSeparator={false}
                  isAllowNegative={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }

                    return checkValue.length <= 8;
                  }}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Additions (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <NumberInputBoxWithState
                  elementStyle={"text-right"}
                  formGroupName={formKey}
                  inputName={"addition"}
                  isAllowNegative={false}
                  isThousandSeparator={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }

                    return checkValue.length <= 8;
                  }}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Deductions (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <NumberInputBoxWithState
                  elementStyle={"text-right"}
                  formGroupName={formKey}
                  inputName={"deduction"}
                  isAllowNegative={false}
                  isThousandSeparator={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }

                    return checkValue.length <= 8;
                  }}

                />
              </div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Total (LKR)</div>
              <div className="col-md-3 col-sm-6 mt "><p className="text-right mr-2">{numberThousandSeperator(Number(total).toFixed(2))}</p></div>
            </div>
            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Print Required <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={[
                    {
                      id: "1",
                      value: "Required",
                    },
                    {
                      id: "0",
                      value: "Not Required",
                    },
                  ]}
                  inputName={"print_required"}
                  labelText=""
                  inputValue={_get(formState, `${formKey}.tx_type`, null) === "ARR" && "0"}
                  emptySelectOptionTxt={"Print Required"}
                  isDisabled={_get(formState, `${formKey}.tx_type`, null) === "ARR"}
                />
              </div>
            </div>
          </UICard>
        </div>
      </FormWrapper>
      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <Reason filterKey={'add_licence_receipt_transaction'}
          requestType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
          formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <Action dataSubmittedForApproval={props.dataSubmittedForApproval} formKey={formKey} uiType={uiType} />
      </div>
    </div>
  );
};

export { AddLicenceUI, LcnSubmitBtn };
