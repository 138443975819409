/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-27 03:10:40
 */

import React, { useContext } from "react";
import { dailyRevenueLicenceDetailsAPI } from "../../../../config/apiUrl.config";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";

const Dashboard = () => {
  const [authStatus, authAction] = useContext(AuthContext);
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const date = dateObjectToString(new Date(), "YY-mm-dd");

  return (
    <DashboardTemplate
      pageTitle="Dashboard"
      breadCrumbList={[
        { urlTo: "", displayName: "Dashboard" },
      ]}
    >
      <DataTable
        tableName={"Daily Revenue Licence Details"}
        apiUrl={`${dailyRevenueLicenceDetailsAPI.url}?from_date=${date}&to_date=${date}&service_id=${serviceId}`}
        dataTableKey={dailyRevenueLicenceDetailsAPI.key}
        isSetAction={false}
        isSetSearchFrom={false}
        tableHeaderList={[
          {
            displayName: "Authority Name", key: "authority_name", isSort: false,
            onPrintFn: ({ authority_name }) => isEmptyValue(authority_name) ? 0 : authority_name
          },
          {
            displayName: "Issued Licences", key: "issued_licence", isSort: false, isRightAlign: true,
            onPrintFn: ({ issued_licence }) => isEmptyValue(issued_licence) ? 0 : issued_licence
          },
          {
            displayName: "Cancelled Licences", key: "cancelled_licence", isSort: false,isRightAlign:true,
            onPrintFn: ({ cancelled_licence }) => isEmptyValue(cancelled_licence) ? 0 : cancelled_licence
          },
          {
            displayName: "Reversed Licences", key: "reversed_licence", isSort: false,isRightAlign:true,
            onPrintFn: ({ reversed_licence }) => isEmptyValue(reversed_licence) ? 0 : reversed_licence
          },
          {
            displayName: "Printed Licences", key: "printed_licence", isSort: false,isRightAlign:true,
            onPrintFn: ({ printed_licence }) => isEmptyValue(printed_licence) ? 0 : printed_licence
          },
          {
            displayName: "Print Pending Licences", key: "print_pending_licence", isSort: false,isRightAlign:true,
            onPrintFn: ({ print_pending_licence }) => isEmptyValue(print_pending_licence) ? 0 : print_pending_licence
          },
        ]}
      />
    </DashboardTemplate>
  );
};


export default Dashboard;
