/*
 * @Author: Madhawa
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-09 13:30:26
 */

import React, { useState, Fragment, useContext } from "react";

import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState
} from "../../../../ui-components/ui-elements/form/index";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import SplitButton from "../../../../ui-components/ui-elements/form/SplitBtn";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get, _unset } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { banksListAPI, pdmtListAPI } from "../../../../../config/apiUrl.config";
import { paymentCategoriesArray, PaymentCategoriesEnum, pdmtPaymentCategories } from "../../../../../config/enum.config";
import { getDataByFormObject, isEmptyValue, thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";

const QuotationPaymentType = ({ uiType = "quotation", charges = {} }) => {
  const formKey = "paymentTypeFormKey";

  const [inputList, setInputList] = useState([1]);
  const [dataList, setDataList] = useState({
    "pay_type_0": []
  });

  const [formState, formAction] = useContext(FormContext);
  const [authState] = useContext(AuthContext);
  const [removeConfiramtionPopup, setRemoveConfiramtionPopup] = useState({
    showPopup: false,
    index: null
  });

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);

    formAction.changeInputFn(formKey, "", `${formKey}.payment_types_${index}`, undefined);
    setInputList(list);
    onChangeValueHandler();
    closeRemoveConfirmationPopup()
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    const selectedValues = [];
    const chequeCount = 3;
    let selectedChequeCount = 0;

    for (let i = 0; i <= inputList.length - 1; i++) {
      selectedChequeCount = _get(formState, `${formKey}.payment_types_${i}.payment_type`, "") === PaymentCategoriesEnum.CHEQUE ? selectedChequeCount + 1 : selectedChequeCount;
      selectedValues.push(_get(formState, `${formKey}.payment_types_${i}.payment_type`, ""));
    }

    const selectedValue = _get(formState, `${formKey}.payment_types_${inputList.length - 1}.payment_type`, "");

    const temp = (selectedChequeCount === chequeCount && selectedValue === PaymentCategoriesEnum.CHEQUE) || selectedValue !== PaymentCategoriesEnum.CHEQUE
      ? _get(formState, `${formKey}.payment_categories`, []).filter((payment, index, arr) => {
        return !selectedValues.includes(payment.key);
      })
      : _get(formState, `${formKey}.payment_categories`, []).filter((payment, indx, arr) => {
        const index = selectedValues.indexOf(PaymentCategoriesEnum.CHEQUE);
        if (index > -1) {
          selectedValues.splice(index, 1); // 2nd parameter means remove one item only
        }
        return !selectedValues.includes(payment.key);
      });

    formAction.changeInputFn(formKey, "", `${formKey}.payment_types_${inputList.length}.payment_type`, temp[0]["key"]);

    setDataList((prevState) => {
      setInputList([...inputList, 1]);

      return {
        ...prevState,
        [`pay_type_${inputList.length}`]: temp
      };
    });
  };

  const setPaymentCategories = (categories = []) => {
    if (categories.length === 0) {
      return {
        isDisplay: false
      };
    }

    const temp = [];
    let obj = {};

    categories.forEach((value) => {
      temp.push({
        key: value["payment_type_code"],
        value: pdmtPaymentCategories[value["payment_type_code"]]
      });
    });

    if (temp.length > 0) {
      temp.map((object, index) => {
        if (object.key === PaymentCategoriesEnum.CASH) {
          obj = {
            "payment_types_0": {
              "payment_type": temp[index]["key"]
            }
          }
        }
      });
    }
    var order = paymentCategoriesArray;

    temp.sort((a, b) => {
      // sort based on the index in order array
      return order.indexOf(a.key) - order.indexOf(b.key);
    })

    setDataList({
      "pay_type_0": temp
    });

    return {
      ...obj,
      payment_categories: temp,
      isDisplay: true
    };
  };

  const getProvinceOfficeId = () => {
    const pdmtId = _get(authState, "authUser.pdmtId.id", null);

    if (isEmptyValue(pdmtId))
      return null;
    else
      return pdmtId;
  };

  const checkVisibilityOfPlusBtn = () => {
    return inputList.length < _get(formState, `${formKey}.payment_categories`, []).length;
  };

  const onChangeValueHandler = (eventData) => {

    let total = 0;
    let cash_amount = 0;
    let cheque_amount = 0;
    let card_amount = 0;

    const paymentFormObj = getDataByFormObject(_get(formState, "paymentTypeFormKey", {}));
    _unset(paymentFormObj, "isDisplay");
    _unset(paymentFormObj, "payment_categories");

    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        total = total + parseFloat(_get(paymentFormObj, `[${key}]['amount']`, 0));
        switch (paymentFormObj[key]['payment_type']) {
          case PaymentCategoriesEnum.CASH:
            cash_amount = cash_amount + parseFloat(_get(paymentFormObj, `[${key}]['amount']`, 0));
            break;
          case PaymentCategoriesEnum.CHEQUE:
            cheque_amount = cheque_amount + parseFloat(_get(paymentFormObj, `[${key}]['amount']`, 0));
            break;
          case PaymentCategoriesEnum.CREDITCARDS:
            card_amount = card_amount + parseFloat(_get(paymentFormObj, `[${key}]['amount']`, 0));
            break;
        }
      }
    });

    formAction.changeInputFn(formKey, "cash_amount", "", cash_amount);
    formAction.changeInputFn(formKey, "cheque_amount", "", cheque_amount);
    formAction.changeInputFn(formKey, "card_amount", "", card_amount);
    formAction.changeInputFn(formKey, "paid_amount", "", total || 0);
  };

  const closeRemoveConfirmationPopup = () => {
    setRemoveConfiramtionPopup({
      showPopup: false,
      index: null
    })
  };

  const openRemoveConfirmationPopup = (index) => {
    setRemoveConfiramtionPopup({
      showPopup: true,
      index: index
    })
  };
  return (
    <FormWrapper
      setGroupName={formKey}
      apiUrl={`${pdmtListAPI.url}/${getProvinceOfficeId()}`}
      onRebuildResponseFn={(response) => {
        return {
          ...setPaymentCategories(_get(response, "service.payment_types_allowed", [])),
          paid_amount: 0,
          cash_amount: 0,
          cheque_amount: 0,
          card_amount: 0,
          "_onLoad": false
        }
      }}
    >
      {
        (_get(formState, `${formKey}.isDisplay`, false) === false) ? (
          <div>N/A</div>
        ) : (
          <div className="paymentType">
            <div className="fullWidthDiv">
              {inputList.map((x, i) => {
                const selectedKey = _get(formState, `${formKey}.payment_types_${i}.payment_type`, null);

                return (
                  <Fragment>
                    <div className="row">
                      <div className="defaultPaddingTop floatLeft col-md-5">
                        <SplitButton
                          formGroupName={formKey}
                          inputStatePath={`${formKey}.payment_types_${i}.payment_type`}
                          dataList={dataList[`pay_type_${i}`]}
                          isDisabled={i < inputList.length - 1}
                        />
                      </div>

                      {selectedKey === PaymentCategoriesEnum.CASH ? ( // Cash Amount
                        <div className="floatLeft col-md-5">
                          {
                            (_get(formState, `${formKey}.cash_amount_error_${i}`, "") !== "") ? (
                              <p className={"defaultHalfMargin"} style={{ color: "red" }}>{_get(formState, `${formKey}.cash_amount_error_${i}`, "")}</p>
                            ) : null
                          }
                          <NumberInputBoxWithState
                            elementStyle={"text-right"}
                            formGroupName={formKey}
                            inputStatePath={`${formKey}.payment_types_${i}.amount`}
                            labelText={"Cash Received"}
                            isRequired={true}
                            isAllowNegative={false}
                            isThousandSeparator={false}
                            decimalScale={2}
                            isAllowedFn={(value) => {
                              let checkValue = value.value.toString();

                              if (checkValue.includes(".")) {
                                checkValue = checkValue.split(".")[0];
                              }

                              return checkValue.length <= 18;
                            }}
                            onChangeTxtFn={(eventData) => onChangeValueHandler(eventData)}
                          />
                        </div>
                      ) : selectedKey === PaymentCategoriesEnum.CHEQUE ? ( //Cheque Amount
                        <div className="floatLeft col-md-5">
                          <NumberInputBoxWithState
                            elementStyle={"text-right"}
                            formGroupName={formKey}
                            inputStatePath={`${formKey}.payment_types_${i}.amount`}
                            labelText={"Cheque Amount"}
                            isRequired={true}
                            isAllowNegative={false}
                            isThousandSeparator={false}
                            decimalScale={2}
                            isAllowedFn={(value) => {
                              let checkValue = value.value.toString();

                              if (checkValue.includes(".")) {
                                checkValue = checkValue.split(".")[0];
                              }

                              return checkValue.length <= 18;
                            }}
                            onChangeTxtFn={(eventData) => onChangeValueHandler(eventData)}
                          />
                          <InputBoxWithState
                            elementStyle={"text-right"}
                            formGroupName={formKey}
                            inputStatePath={`${formKey}.payment_types_${i}.cheque_no`}
                            labelText={"Cheque No."}
                            isRequired={true}
                            maxLength={25}
                            inputPlaceholder={"Enter Cheque No."}
                          />
                          <SelectBoxWithState
                            formGroupName={formKey}
                            inputStatePath={`${formKey}.payment_types_${i}.bank`}
                            labelText={"Bank"}
                            isRequired={true}
                            apiUrl={`${banksListAPI.url}?sort_by=code|asc&active=true`}
                            apiStateKey={banksListAPI.key}
                            keyName={"id"}
                            valueName={"display_value"}
                            emptySelectOptionTxt={"Select Bank"}
                          />
                        </div>
                      ) : selectedKey === PaymentCategoriesEnum.CREDITCARDS ? ( //Credit Card Amount
                        <div className="floatLeft col-md-5">
                          <NumberInputBoxWithState
                            elementStyle={"text-right"}
                            formGroupName={formKey}
                            isRequired={true}
                            inputStatePath={`${formKey}.payment_types_${i}.amount`}
                            labelText={"Credit Card Amount"}
                            isAllowNegative={false}
                            isThousandSeparator={false}
                            decimalScale={2}
                            isAllowedFn={(value) => {
                              let checkValue = value.value.toString();

                              if (checkValue.includes(".")) {
                                checkValue = checkValue.split(".")[0];
                              }

                              return checkValue.length <= 18;
                            }}
                            onChangeTxtFn={(eventData) => onChangeValueHandler(eventData)}
                          />
                          <InputBoxWithState
                            elementStyle={"text-right"}
                            formGroupName={formKey}
                            inputStatePath={`${formKey}.payment_types_${i}.reference_no`}
                            labelText={"Reference No."}
                            isRequired={true}
                            maxLength={25}
                            inputPlaceholder={"Enter Reference No."}
                          />
                          <InputBoxWithState
                            elementStyle={"text-right"}
                            formGroupName={formKey}
                            inputStatePath={`${formKey}.payment_types_${i}.approval_no`}
                            labelText={"Approval No."}
                            isRequired={true}
                            maxLength={25}
                            inputPlaceholder={"Enter Approval No."}
                          />
                        </div>
                      ) : selectedKey === PaymentCategoriesEnum.MCASH ? ( // Mobile Cash Amount
                        <div className="floatLeft col-md-5">
                          <NumberInputBoxWithState
                            elementStyle={"text-right"}
                            formGroupName={formKey}
                            inputStatePath={`${formKey}.payment_types_${i}.mobile_cash_amount`}
                            labelText={"Mobile Cash Amount"}
                            isAllowNegative={false}
                            isThousandSeparator={false}
                            decimalScale={2}
                            isAllowedFn={(value) => {
                              let checkValue = value.value.toString();

                              if (checkValue.includes(".")) {
                                checkValue = checkValue.split(".")[0];
                              }

                              return checkValue.length <= 18;
                            }}
                            onChangeTxtFn={(eventData) => onChangeValueHandler(eventData)}
                          />
                        </div>
                      ) : null}

                      {i === 0 ? null : inputList.length === i + 1 ? (
                        <div className="mt-3 col-md-1">
                          <div className="floatRight">                            
                            <InputButton
                              elementStyle={"errorBtn"}
                              overrideTextToIcon={<i className="fas fa-minus"></i>}
                              onClickBtnFn={() => openRemoveConfirmationPopup(i)}
                            />
                          </div>
                        </div>
                      ) : null}

                      {
                        (checkVisibilityOfPlusBtn() === true) && i === 0 ? (
                          <div className="mt-3 col-md-1">
                            <div className="floatRight">                            
                              <InputButton
                                btnColor={"secondary"}
                                overrideTextToIcon={<i className="fas fa-plus"></i>}
                                onClickBtnFn={handleAddClick}
                              />
                            </div>
                          </div>
                        ) : null
                      }
                    </div>
                  </Fragment>
                );
              })}
            </div>
            <div className="d-flex flex-row fullWidthDiv paymentInfo">
              <div className="halfWidthDiv">
                <p className="ml-1">Cash Balance (LKR) *</p>
              </div>
              <div className="col-md-4">
                <p className="text-right" style={{ marginLeft: "50px" }} >
                  <b>
                    <b>{
                      `${thousandSeparator(
                        parseFloat(
                          _get(formState, "paymentTypeFormKey.paid_amount", 0)
                          - _get(charges, "total", 0)
                        ).toFixed(2), true)}`
                    }
                    </b>
                  </b>
                </p>
              </div>

            </div>

            <div className="fullWidthDiv defaultHalfMarginTop">
              <p>* This indicates the Cash Balance to be paid to the customer.</p>
            </div>
          </div>
        )
      }
      {<DialogPopup
        isDialogActions={false}
        isFullWidth={false}
        isFullScreen={false}
        isCloseButton={false}
        dialogTitle={"Please Confirm"}
        children={
          <ConfirmationMessagePopup
            index={removeConfiramtionPopup.index}
            handleSubmitFn={event => handleRemoveClick(event)}
            closePopupFn={closeRemoveConfirmationPopup}
            msg={'Are you sure you want to remove?'}
          />
        }
        isOpen={removeConfiramtionPopup.showPopup}
        onClosePopupFn={closeRemoveConfirmationPopup}
      />}
    </FormWrapper>
  );
};

const ConfirmationMessagePopup = (props) => {
  const display_msg = props.msg;
  const index = props.index
  const closePopupFn = props.closePopupFn;
  const handleSubmitFn = props.handleSubmitFn

  function wrapHandleClick(event) {
    // 👉️ your logic before
    handleSubmitFn(index);

    // 👉️ your logic after
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <strong>{display_msg}</strong>
      </div>
      <div className="col-md-12 text-right defaultMarginTop">
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="Yes"
          onClickBtnFn={wrapHandleClick}
          startIcon={"fas fa-check"}
        />
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="No"
          onClickBtnFn={closePopupFn}
          startIcon={"fas fa-times"}
        />
      </div>

    </div>

  );
}


export { QuotationPaymentType };