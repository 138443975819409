import React, {useContext} from "react";

import { RawIndexMemo } from "../../../../../ui-components/ui-elements/table/DataTable"; 
import { _getInt } from "../../../../../../helpers/common-helpers/lodash.wrappers"; 
import { SubmitButton } from "../../../../../ui-components/ui-elements/form"; 
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider"; 
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers"; 


const VehicleInfoRowNo = (props) => {
    return (
        <RawIndexMemo
            page_size={_getInt(props.pagingObject, "per_page", 1)}
            current_page={_getInt(props.pagingObject, "current_page", 1)}
            index={props.rowIndex || 0}
        />
    )
};

const VehicleInfoDeleteUI = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure want to delete this record?</p>
        </div>
    )
}

const AddVehicleInfoDeleteBtn = (props) => {    
    const [, uiAction] = useContext(UIContext);
    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            dataTableKey={props.dataTableKey}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Record successfully deleted",
                    messageType: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${props.tableApiUrl.url}/remove-vehicle-info/${props.row.id}`,
                    key: props.tableApiUrl.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Record is successfully deleted.",
                        messageType: "success"
                    });

                    props.onClosePopupFn(false);
                }
            }}
        />
    )
};

export {
    VehicleInfoRowNo,
    VehicleInfoDeleteUI,
    AddVehicleInfoDeleteBtn
}
