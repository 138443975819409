import React, { useContext, useEffect, useState } from 'react';
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { ChangeSerialNumber, EndSessionBtn, EndSessionForm, LoadSerialNumber } from "../../../../ui-components/templates/CounterTemplate";
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { callApi } from '../../../../../helpers/common-helpers/callApi.helpers';
import { ChangeDiscardSerialSubmitBtn } from '../../../cashier/pages/Cashier';
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { TooltipWrapper } from '../../../../ui-components/ui-elements/common/BaseElements';
import { isEmpty } from 'lodash';
import { availableSessionListApi } from '../../../../../config/apiUrl.config';
import { isEmptyValue } from '../../../../../helpers/common-helpers/common.helpers';
import { SubmitButton } from '../../../../ui-components/ui-elements/form';
import { PageLoader } from '../../../../ui-components/templates/common-includes/PageLoader';
import { UIContext } from '../../../../ui-components/context-providers/UIContext.provider';

export const SerialNumberWrapper = (props) => {
    const [getState, setState] = useState({
        "showPopup": false,
        "popupType": null,
        "onLoad": true
    });
    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const user_id = _get(authStatus, "authUser.id", "");
    const authority_id = _get(authStatus, "authUser.authorityId", "");
    const uiType = _get(props, "uiType", null);
    const [, uiAction] = useContext(UIContext);
    const formGroupName =
      uiType === "normal"
        ? "issueTemp"
        : uiType === "carnet"
        ? "issueCarnet"
        : uiType === "arrears"
        ? "arrearsFormKey"
        : "issueTemp";

    useEffect(() => {
        uiAction.setPageLoader(true);
        callApi(`${availableSessionListApi.url}/${user_id}/sessions/list`)
        .method("get")
        .headers(true)
        .body({})
        .isMultipart(false)
        .send(async (error, result) => {
            if(!error){
                const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");
                const counter_id = isEmpty(_get(result, "data.data[0].counter_id.id", "")) ? "" : _get(result, "data.data[0].counter_id.id", "");
                const counter_code = isEmpty(_get(result, "data.data[0].counter_id.code", "")) ? "" : _get(result, "data.data[0].counter_id.code", "");
                const counter_serial_number_range_id = isEmpty(_get(result, "data.data[0].counter_id.current_counter_serial_number_range_id", "")) ? null : _get(result, "data.data[0].counter_id.current_counter_serial_number_range_id", "");
                const counter_name = isEmpty(_get(result, "data.data[0].counter_id.name", "")) ? "" : _get(result, "data.data[0].counter_id.name", "");
                const session_id = isEmpty(_get(result, "data.data[0].id", "")) ? "" : _get(result, "data.data[0].id", "");
                
                formAction.changeInputFn(formGroupName, "next_serial", "", next_serial);
                formAction.changeInputFn(formGroupName, "counter_id", "", counter_id);
                formAction.changeInputFn(formGroupName, "counter_code", "", counter_code);
                formAction.changeInputFn(formGroupName, "counter_session_id", "", session_id);
                formAction.changeInputFn(formGroupName, "counter_serial_number_range_id", "", counter_serial_number_range_id);
                setState({
                    ...getState,
                    "licenseSequenceVal": next_serial,
                    "counterId": counter_id,
                    "counterName": counter_name,
                    "sessionId": session_id,
                    "showPopup": isEmpty(next_serial) ? true : false,
                    "popupType": isEmpty(next_serial) ? "load-serial" : null,
                    "onLoad": false
                })
                uiAction.setPageLoader(false);
                formAction.changeInputFn("counter-serial", "id", "", next_serial);
                formAction.changeInputFn("counter-serial", "active", "", _get(result, "data.data[0].counter_id.active", ""));
            }
        });
    }, [_get(formState, "change_serial_on_session", "")]);
    const closePopup = () => {
        setState({
        ...getState,
        showPopup: !getState.showPopup,
        })
    }
    
    const EndSessionBtnForLoadSerial = () => {
        return (
            <SubmitButton
                startIcon={"mdi mdi-logout"}
                btnText={"End Session"}
                onClickBtnFn={() => setState({
                    ...getState,
                    showPopup: true,
                    popupType: "end-session",
                    sessionId: getState.sessionId,
                })}
            />
        );
    };
    return (            
        <div className="serialNumberWrapper">
            { getState.onLoad == false ? 
                <div className="licenseSequenceInputBox">
                    <div className="serialLabel"><b>Current Serial Number</b></div>
                    <label>{getState.licenseSequenceVal}</label>
                    <TooltipWrapper description={'Change Serial Number'}>
                        <button 
                            onClick={() => setState({
                                ...getState,
                                showPopup: true,
                                popupType: "edit-model"
                            })}>
                                <i className="mdi mdi-lead-pencil"></i>
                        </button>
                    </TooltipWrapper>
                </div>
            : 
                <PageLoader />
            }            

            <DialogPopup
                isOpen={getState.showPopup}
                isFullScreen={false}
                // dialogTitle={"Change Serial Number"}
                dialogTitle={isEmptyValue(_get(formState, `counter-serial.id`, "")) ? ("Load Serial Numbers") : ("Change Serial Number")}
                onClosePopupFn={() => setState({
                    ...getState,
                    showPopup: false,
                    popupType: null
                })}
                isCustomButton={true}
                customButtonComponent={
                    !isEmptyValue(_get(formState, `counter-serial.id`, "")) 
                    ? 
                    ChangeDiscardSerialSubmitBtn
                    : 
                    getState.popupType === "end-session" ? EndSessionBtn : EndSessionBtnForLoadSerial 
                }
                customButtonComponentProps={{
                    "popupType": getState.popupType,
                    "current_serial": getState.licenseSequenceVal,
                    "formKey": _get(formState, `cashierSessionForm`, ""),
                    "formGroupName": "cashierSessionForm",
                    "sessionId": getState.sessionId,
                    "counter_name": getState.counterName,
                    "closeSerialUi": closePopup
                }}
                // closeButton={{
                //     btnText: "Cancel",
                //     startIcon: "far fa-times-circle",
                // }}
                closeButton={{
                    btnText: getState.popupType === "edit-model" ? "Cancel" : 
                        getState.popupType === "end-session" ? "No" : "Close",
                    startIcon: getState.popupType === "end-session" ? "fas fa-times" : "far fa-times-circle",
                    isBtnDisabled: getState.popupType === "load-serial" ? true : false,
                }}
                isCloseButton={getState.popupType === "load-serial" ? false : true}
            >
                {/* <ChangeSerialNumber
                    formKey={"cashierSessionForm"}
                    current_serial={getState.licenseSequenceVal}
                    counter_id={getState.counterId}
                    popupType={getState.popupType}
                /> */}
                {
                    isEmptyValue(_get(formState, `counter-serial.id`, "")) ? (
                        getState.popupType === "end-session" ?
                            <EndSessionForm 
                                formKey={"cashierSessionForm"}
                                sessionId={getState.sessionId}/>
                        :
                            <LoadSerialNumber 
                                counterId={getState.counterId} 
                                authorityId={authority_id}
                                closeSerialUi={closePopup} 
                                popupType={getState.popupType}/>
                    ) : (
                        <ChangeSerialNumber
                            formKey={"cashierSessionForm"}
                            current_serial={getState.licenseSequenceVal} 
                            counter_id={getState.counterId}
                            popupType={getState.popupType}
                        />
                    )
                }
            </DialogPopup>
        </div>
    );
}