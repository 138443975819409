import React from 'react'
import { useContext } from 'react'
import { thousandSeparator } from '../../../../../../helpers/common-helpers/common.helpers'
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import { _get } from '../../../../../../helpers/common-helpers/lodash.wrappers';
import { CircleLoaderElement, PaddingLessUICard } from '../../../../../ui-components/ui-elements/common/BaseElements';
import { FormContext } from '../../../../core/context-providers/FormContext.provider'
import { LicenseArrearsIcons } from "../../includes/LicenseArrearsIcons";
export default function LicenceArrearsDetails(props) {
    const[formState,formAction]=useContext(FormContext)
    return (
        <PaddingLessUICard
            cardHeading="Licence Arrears"
            cardHeadingTail={LicenseArrearsIcons}
            passPropsToCardHeadingTail={{ formkey: 'licence_charges_form', getState:props.getState, getVehicleStatusCode: props.getVehicleStatusCode }}
        >
            {
                _get(formState, "licence_charges_form._onLoad", true) === true ? (
                    <div>
                        <br />
                        <center>
                            <CircleLoaderElement />
                        </center>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-4">
                            <strong>From: </strong>
                            {_get(formState, `licence_charges_form.arrearsStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsStartDate`, ""), "dd/mm/YY") : ""}
                        </div>

                        <div className="col-md-4">
                            <strong>To: </strong>
                            {_get(formState, `licence_charges_form.arrearsEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsEndDate`, ""), "dd/mm/YY") : ""}
                        </div>

                        <div className="col-md-4">
                            <strong>Total Fee (LKR): </strong>
                            {thousandSeparator(_get(formState, `licence_charges_form.totArrearsFee`, 0), true)}
                        </div>

                        <div className="col-md-4">
                            <strong>Total Fine (LKR): </strong>
                            {thousandSeparator(_get(formState, `licence_charges_form.totArrearsFine`, 0), true)}
                        </div>

                        <div className="col-md-4">
                            <strong>Additions (LKR): </strong>
                            {thousandSeparator(_get(formState, `licence_charges_form.totArrearsAddition`, 0), true)}
                        </div>

                        <div className="col-md-4">
                            <strong>Deductions (LKR): </strong>
                            {'(' + thousandSeparator(_get(formState, `licence_charges_form.totArrearsDeduction`, 0), true) + ')'}
                        </div>

                        <div className="col-md-4">
                            <strong>Total Amount (LKR): </strong>
                            {
                                thousandSeparator((Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)) +
                                    Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)) +
                                    Number(_get(formState, `licence_charges_form.totArrearsAddition`, 0)) -
                                    Number(_get(formState, `licence_charges_form.totArrearsDeduction`, 0))), true)
                            }
                        </div>
                    </div>
                )
            }
        </PaddingLessUICard>
    )
}
