import React, { Fragment, useEffect, useState, useContext } from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { Action } from "./includes/Action";
import { DtailPanel } from "./includes/OtherAdditionsDeductionInformation";
import { Reason } from './includes/Reason';
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { FormWrapper, SubmitButton } from "../../../ui-components/ui-elements/form";
import { getDataByFormObject, fromObjectToQueryString } from "../../../../helpers/common-helpers/common.helpers";
import { RequestStatus, RequestTypesList, ActionTypeList, LicenceType, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { getLatestLicenseAPI, OtherAdditionDeductionAPI, uploadDetailGetAPI, getOtherAddDedGetAPI, vehicleClassListAPI, calculateLicenceFeeAPI } from "../../../../config/apiUrl.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { CoreContext } from "../../core/context-providers/CoreContext.provider";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { isEmpty } from "lodash";


const OtherADSubmitBtn = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [coreState] = useContext(CoreContext)

    //   const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
    const isAddDedSelected = (!coreState.dataTableResponses ||
        !coreState.dataTableResponses.OtherAdditionDeductionTempAPIKey ||
        !coreState.dataTableResponses.OtherAdditionDeductionTempAPIKey.results ||
        !coreState.dataTableResponses.OtherAdditionDeductionTempAPIKey.results.length === 0) ? false : true
    const vehicle_no = _get(
        formState,
        "vehicle_search_form.searchKey",
        null
    );
    const [authStatus] = useContext(AuthContext);
    const [, uiAction] = useContext(UIContext);

    const { sessionId } = props
    const user_role_level = _get(authStatus, "authUser.role_level_code", "");
    const serviceId = _get(authStatus, "authUser.service_id", "");
    const current_licence_type = _get(
        formState,
        "vehicle_search_form.data.current_license_type.code",
        null
    );
    const approvalAction = _get(
        formState,
        "Other_Addition_Deduction.action",
        null
    )
    const authorityId = _get(authStatus, "authUser.authorityId", "");
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

    const userName = _get(authStatus, "authUser.name", "<anonymous name>");
    const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
        _get(authStatus, "authUser.provinceName", "<anonymous province>") :
        ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
    const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
                startIcon={"far fa-save"}
                formGroupName={props.uiType === 'assign&Perform' ? 'Other_Addition_Deduction' : 'other-additions-deduction'}
                tooltip={"Submit"}
                isValidate={true}
                isSetHeaders={true}
                flashMessages={{
                    success: props.uiType === 'assign&Perform' ? {
                        status: true,
                        message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
                        messageType: "success",
                    } : {
                        status: true,
                        message: `Approval Request successfully submitted. Request ID : ${reqId}.`,
                        messageType: "success",
                    },
                    // failed: props.uiType === 'assign&Perform' ? {
                    //     status: true,
                    //     message: `Approval Request Decision Submit Failed. Please Try Again.`,
                    //     messageType: "error",
                    // } : {
                    //     status: true,
                    //     message: `Approval Request Submit Failed. Please Try Again.`,
                    //     messageType: "error",
                    // },
                }}
                validationObject={props.uiType === 'assign&Perform' && _get(formState, "Other_Addition_Deduction.action", "") === 'REJ' ? {
                    fileds: {
                        remark: "Remark",
                    },
                    rules: {
                        remark: "required|max:500",
                    },
                    message: {
                        "remark.required": "Remark is required.",
                    }
                } : {
                    fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Other_Addition_Deduction.action", "") === 'FRD' ? (_get(formState, "Other_Addition_Deduction.forward_role", "") === 'OPT' ?
                        {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                            forward_authority: "Authority",
                        } : {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                        }) : {
                        reason_id: "Reason",
                        action: "Action",
                        remark: "Remark",
                    }) : {
                        "reason_id": "Reason",
                        "remark": "Remark",
                        "forward_province": "Forward Province",
                        "forward_role": "Forward Role",
                        "action": "Action",
                    },
                    rules: props.uiType === 'assign&Perform' ? (_get(formState, "Other_Addition_Deduction.action", "") === 'FRD' ? (_get(formState, "Other_Addition_Deduction.forward_role", "") === 'OPT' ? {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                        forward_authority: "required",
                    } : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                    }) : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                    }) : {
                        "reason_id": "required",
                        "remark": "required|max:500",
                        "forward_province": "required",
                        "forward_role": "required",
                        "action": "required",
                    },
                    message: props.uiType === 'assign&Perform' ? (_get(formState, "Other_Addition_Deduction.action", "") === 'FRD' ? (_get(formState, "Other_Addition_Deduction.forward_role", "") === 'OPT' ? {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province": "Province is required",
                        "forward_authority": "Authority is required",
                    } : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province": "Province is required",
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "remark.required": "Remark is required.",
                        "forward_province": "Forward Province is required.",
                        "forward_role": "Forward Role is required.",
                        "action": "Action is required.",

                    },
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${OtherAdditionDeductionAPI.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
                        key: OtherAdditionDeductionAPI.key,
                    };
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    console.log('FFFFFFF========', fromObject);
                    if (!isAddDedSelected && user_role_level === 'INT_PRV') {
                        uiAction.setFlashMessage({
                            status: true,
                            message: `Need Atleast One Addition / Deduction To Continue.`,
                            messageType: "error",
                        });

                    } else {
                        if (props.uiType === 'assign&Perform') {
                            if (_get(formState, "Other_Addition_Deduction.action", undefined) === undefined) {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Action is required`,
                                    messageType: "error",
                                });

                                return
                            }
                            return getDataByFormObject({
                                ...fromObject,
                                userName: userName,
                                currentProvince: currentProvince,
                                authority: authority,
                                requestType: RequestTypesList["other_ad_ded"],
                                // licence_type: current_licence_type,
                                ui_request_id: props.approvalRequestId,
                                // status: RequestStatus.APPROVAL_PENDING,
                                pdmt_id: pdmtId,
                                // authority_id: authorityId,
                                // request_id: fromObject.request_id,
                                reason_id: formState['Other_Addition_Deduction-new'].reason_id,
                                counter_session_id: '00001',
                                forward_authority_service_id: serviceId,
                                document_details: [],
                                action: fromObject.action == 'FRD' ? {
                                    action: fromObject.action,
                                    remark: fromObject.remark,
                                    forward_province: fromObject.forward_province,
                                    forward_role: fromObject.forward_role,
                                    forward_authority: fromObject.forward_authority
                                } : {
                                    action: fromObject.action,
                                    remark: fromObject.remark,
                                },
                            });
                        } else {
                            return getDataByFormObject({
                                ...fromObject,
                                userName: userName,
                                currentProvince: currentProvince,
                                authority: authority,
                                requestType: RequestTypesList["other_ad_ded"],
                                licence_type: current_licence_type,
                                status: RequestStatus.APPROVAL_PENDING,
                                pdmt_id: pdmtId,
                                authority_id: authorityId,
                                request_id: fromObject.request_id,
                                reason_id: fromObject.reason_id,
                                counter_session_id: sessionId ? sessionId : null,
                                forward_authority_service_id: serviceId,
                                carnet_licence_start_at: props.isCarnet ? _get(formState, 'carnet.fromDate', null) : null,
                                carnet_licence_end_at: props.isCarnet ? _get(formState, 'carnet.toDate', null) : null,
                                document_details: [],
                                action: {
                                    action: fromObject.action,
                                    remark: fromObject.remark,
                                    forward_province: fromObject.forward_province,
                                    forward_role: fromObject.forward_role,
                                    forward_authority: fromObject.forward_authority
                                },
                            });
                        }

                    }

                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: props.uiType === 'assign&Perform' ? "put" : "post",
                }}

                onResponseCallBackFn={(response, error) => {
                    if (
                        response.length === undefined &&
                        error === undefined &&
                        response.data.errors === undefined
                    ) {
                        if (props.uiType === 'assign&Perform') {
                            props.refreshMainScreenAfterPerformFn()
                            props.onClosePopupFn();
                            formAction.changeInputFn("additions_deductions", "selected_ids", "", [])
                        } else {
                            props.refreshMainScreenDataFn()
                            props.onClosePopupFn();
                            formAction.changeInputFn("additions_deductions", "selected_ids", "", [])
                        }

                    }
                    else {
                        if (props.uiType === 'assign&Perform') {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                                messageType: "error",
                            });
                        }
                        else {
                            if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: response.data.errors.details[0].message,
                                    messageType: "error",
                                });
                            }
                            else {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Approval Request Submit Failed. Please Try Again.`,
                                    messageType: "error",
                                });
                            }
                        }
                    }
                }}
            />
        </Fragment>
    );
};

let reqId;
let fromDateLicencePeriod;
let toDateLicencePeriod;
const OtherAdditionsDeductionUI = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [coreState] = useContext(CoreContext)
    const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
    const formKey = props.formKey;
    const uiType = props.uiType;
    console.log('PROOOOO===', props);
    const [requestId, setRequestId] = useState(null);
    const [authStatus, authAction] = useContext(AuthContext);
    const [getClass, setClass] = useState(null);
    let vehicleClassName;
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

    useEffect(() => {
        const rId = new Date();
        const requ = rId.valueOf().toString();

        setRequestId(requ.substr(requ.length - 8))
        if (uiType === 'APR') {
            getReqApprovalLicenceTypeData(props)
            getReqApprovalWFData(props)
            getNormalLicenceFee(props)
        }


    }, [])

    const getNormalLicenceFee = (props) => {
        let queryObj = {}
        if ((_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null) &&
            (_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null)) {
            queryObj = {
                vehicle_no: props.dataSubmittedForApproval.vehicle_no,
                licence_end_type: 0,
                is_reissuance: false,
                licence_period_start_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", null)) : null,
                licence_period_end_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_end_at", null)) : null,
            };
        } else {
            queryObj = {
                vehicle_no: props.dataSubmittedForApproval.vehicle_no,
                licence_end_type: 0,
                is_reissuance: false,
                temp_request: false
            };
        }


        callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    let totArrearsFee = 0;
                    let totArrearsFine = 0;
                    let totArrearsAddition = 0;
                    let totArrearsDeduction = 0;
                    let totAddition = 0;
                    let totdeduction = 0;
                    let totCurrentLicenceFee = 0;
                    let totCurrentLicenceFine = 0;
                    let arrearsStartDate = null;
                    let arrearsEndDate = null;
                    let currentLicenceStartDate = null;
                    let currentLicenceEndDate = null;

                    const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

                    _get(result, "data.addition_deduction_summary", []).forEach(value => {
                        if (value.charge_effect_code === "DED") {
                            totdeduction += value.total

                        }
                        if (value.charge_effect_code === "ADD") {
                            totAddition += value.total

                        }

                    });
                    _get(result, "data.arrears", []).forEach(value => {
                        totArrearsFee += value.fee;
                        totArrearsFine += value.fine;
                        totArrearsAddition += value.addition_total
                        totArrearsDeduction += value.deduction_total
                    });

                    if (_get(result, "data.current_licences", []).length > 0) {
                        _get(result, "data.current_licences", []).forEach(value => {
                            totCurrentLicenceFee += value.fee;
                            totCurrentLicenceFine += value.fine;
                        });
                    }
                    else {
                        totCurrentLicenceFee = _get(result, "data.fee", 0)
                    }


                    if (_get(result, "data.arrears", []).length > 0) {
                        arrearsStartDate = result.data.arrears[0]["start_date"];
                        arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
                    }

                    if (_get(result, "data.current_licences", []).length > 0) {
                        currentLicenceStartDate = result.data.current_licences[0]["start_date"];
                        currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
                    }

                    let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
                        totAddition + _get(result, "data.additions.current_licence", 0) -
                        (totdeduction + _get(result, "data.deductions.current_licence", 0)))
                        + serviceCharge;

                    if (total.isNaN) {
                        total = _get(result, "data.fee", 0)
                    }
                    formAction.changeInputFn("licence_charges_form", "data", "", result.data);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
                    formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
                    formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
                } else {
                    formAction.changeInputFn("licence_charges_form", "data", "", []);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
                    formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
                    formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
                    formAction.changeInputFn("licence_charges_form", "total", "", 0);
                }
                formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
            });
    };

    toDateLicencePeriod = !isEmpty(_get(formState, 'licence_charges_form.currentLicenceEndDate', null)) ? dateObjectToString(_get(formState, 'licence_charges_form.currentLicenceEndDate', '-'), "dd/mm/YY") :
        _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null ? _get(formState, "work_flow_record.data.carnet_licence_end_at", '-') :
            _get(formState, 'latest_licence_data.data.valid_to', null) !== null ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_to', '-'), "dd/mm/YY") : _get(formState, 'licence_validity_period.endDatesData', null) && _get(formState, 'licence_validity_period.endDatesData', null) !== null && _get(formState, 'licence_validity_period.endDatesData', []).length !== 0 ? _get(formState, 'licence_validity_period.endDatesData', [{ id: "0", value: "-" }])[0]['value'] : dateObjectToString(new Date(), "dd/mm/YY")

    fromDateLicencePeriod = _get(formState, 'latest_licence_data.data.valid_from', null) !== null
        ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_from', '-'), "dd/mm/YY")

        : _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null ?
            dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", '-'), "dd/mm/YY")
            : (props.carnet ? (!isEmpty(_get(formState, 'licence_charges_form.currentLicenceStartDate', null))
                ? dateObjectToString(_get(formState, 'licence_charges_form.currentLicenceStartDate', '-'), "dd/mm/YY") : "")

                : (props.isCarnet && _get(formState, 'carnet.fromDate', null) !== null ? dateObjectToString(_get(formState, 'carnet.fromDate', null), "dd/mm/YY") :
                    !props.isCarnet && _get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null) !== null ? dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', '-'), "dd/mm/YY") : !isEmpty(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-')) ?
                        dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-'), "dd/mm/YY") :
                        _get(formState, 'licence_validity_period.startDate', null) !== null && _get(formState, 'licence_validity_period.startDate', null) !== '' ?
                            dateObjectToString(_get(formState, 'licence_validity_period.startDate', null), "dd/mm/YY") :
                            dateObjectToString(new Date(), "dd/mm/YY")))

    reqId = requestId
    const currentVehicleClass = _get(formState, "vehicle_search_form.data.vehicle_class.name", null)
    const getReqApprovalLicenceTypeData = (props) => {
        callApi(`${getLatestLicenseAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);
                }
            });
    }

    const getReqApprovalWFData = (props) => {
        callApi(`${getOtherAddDedGetAPI.url}/get-wf-data/${props.approvalRequestId}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    const { work_flow_record } = result.data.data
                    console.log('REEE**===>', result);
                    formAction.changeInputFn('work_flow_record', "data", "", work_flow_record);


                } else {
                    console.log('ERR-->', error);
                }
            });
    }

    const Carnet = (props.uiType === 'APR' ? LicenceType[`${_get(formState, 'latest_licence_data.data.vehicleData.current_license_type.code', '-')}`] : _get(formState, `vehicle_search_form.data.current_license_type.name`, '-')) === "CN - Carnet Licence" ? true : false

    const vehicleClassId = (_get(formState, "latest_licence_data.data.vehicleData.vehicle_class", "-"))
    const vehicleClass = (_get(coreState, `apiResponses.${vehicleClassListAPI.key}.result`, []).find(item => item.id === vehicleClassId)?.name)

    return (
        <div className="row">
            <FormWrapper

                setGroupName={formKey}
                setFormObject={{
                    request_id: _set(formState, `${formKey}.request_id`, requestId),
                }}
            >
                <div className="col-md-12 col-sm-12 defaultMarginTop">
                    <UICard>
                        <div className="row">
                            <div className="col-md-4 col-sm-4 floatLeft d-flex flex-row">
                                <p>
                                    <b>Current Licene Type&nbsp;&nbsp; :&nbsp;&nbsp;</b>
                                </p>
                                <p>{props.uiType === 'APR' ? LicenceType[`${_get(formState, 'latest_licence_data.data.vehicleData.current_license_type.code', '-')}`] : _get(formState, `vehicle_search_form.data.current_license_type.name`, '-')}
                                </p>
                            </div>

                            <div className="col-md-4 col-sm-4 floatLeft d-flex flex-row">
                                <p>
                                    <b>Current Vehicle Class &nbsp;&nbsp;:&nbsp;&nbsp;</b>
                                </p>
                                <p>{_get(formState, "latest_licence_data.data.vehicleData.vehicle_class.name", "-")}</p>
                            </div>

                            <div className="col-md-4 col-sm-4 floatLeft d-flex flex-row ">
                                <p>
                                    <b>Licence Period&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                                </p>
                                <p>{fromDateLicencePeriod} &nbsp;&nbsp;
                                    - &nbsp;&nbsp;
                                    {toDateLicencePeriod}</p>
                            </div>

                            {/* <div className="col-md-3 col-sm-2 floatLeft">
							 <p>{ (_get(formState, "latest_licence_data.data.valid_from", null)===null && _get(formState, "latest_licence_data.data.valid_to", null)===null)?"-":dateObjectToString(_get(formState, "latest_licence_data.data.valid_from", null),"dd/mm/YY")} - {dateObjectToString(_get(formState, "latest_licence_data.data.valid_to", null),"dd/mm/YY")}</p> 
                          
						</div> */}


                        </div>
                    </UICard>

                </div>



                <div className="col-md-12 col-sm-12 defaultMarginTop">
                    <DtailPanel
                        formGroupName={`${formKey}`}
                        requestId={requestId}
                        formState={formState}
                        carnet={Carnet}
                        toDateLicencePeriod={toDateLicencePeriod}
                        fromDateLicencePeriod={fromDateLicencePeriod}
                        {...props}
                    />
                </div>

                <div className="col-md-12 col-sm-12 defaultMarginTop">
                    <Reason
                        filterKey={'other_additions_deductions'}
                        requestType={uiType}
                        dataSubmittedForApproval={props.dataSubmittedForApproval}
                        formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
                    />
                </div>


                <div className="col-md-12 col-sm-12 defaultPaddingTop">
                    <DocumentsUploader
                        isUploadFn={true}
                        formGroupName={`${formKey}-upload`}
                        tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
                        requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
                        uiType={uiType}
                    />
                </div>


            </FormWrapper>
            <div className="col-md-12 col-sm-12 defaultPaddingTop">

                <Action
                    formKey={`${formKey}`}
                    uiType={uiType}
                    dataSubmittedForApproval={props.dataSubmittedForApproval}
                // isWaiveOff={_get(formState, "latest_licence_data.data.vehicleData.province_id", null) !== null && _get(formState, "latest_licence_data.data.vehicleData.province_id", null) !== pdmtId ? true : false}
                />

            </div>
        </div>

    );
};


const OtherAdditionsDeductions = () => {
    return (
        <DashboardTemplate
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Additions Deductions" },
            ]}
            pageTitle="Other Additions / Deductions"
        >
            <OtherAdditionsDeductionUI />
        </DashboardTemplate>
    )
}

export default OtherAdditionsDeductions;
export { OtherAdditionsDeductionUI, OtherADSubmitBtn };
