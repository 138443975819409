/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-08 14:43:53
 */

import React, { Fragment, useContext } from 'react';
import { getCodeName } from "../../../../../helpers/common-helpers/common.helpers";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { MaskMobile, LimitTextLength } from "../../../../ui-components/ui-elements/common/BaseElements";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import {
    DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

import { previousOwnerInformationWithPagingAPI } from '../../../../../config/apiUrl.config';
import { ProvincesByIds } from "../../../../../config/enum.config";
import {isEmpty} from "lodash"

const emptyFn = (...para) => undefined;

const OwnerInfoDisplayPopup = () => {
    const [formState] = useContext(FormContext);
    return (
        <div className="row" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <div className="col-md-6 label-conatiner-center-wrapper">
                <div className="col-md-6  label-right-align" >
                    <strong>Name: </strong>
                </div>
                <div className="col-md-6 value-left-align">
                    <LimitTextLength
                        maxLenth={200}
                        displayText={getLabelValue(_get(formState, 'viewOwnerInformation.name', '-'), true)}
                    />
                </div>
            </div>

            <div className="col-md-6 label-conatiner-center-wrapper"></div>

            <div className="col-md-6 label-conatiner-center-wrapper">
                <div className="col-md-6  label-right-align" >
                    <strong>Identification Number: </strong>
                </div>
                <LimitTextLength
                    displayText={getLabelValue(_get(formState, 'viewOwnerInformation.nic', '-'), true)}
                />
            </div>


            <div className="col-md-6 label-conatiner-center-wrapper">
                <div className="col-md-6  label-right-align" >
                    <strong>Mobile Number 0094: </strong>
                </div>
                <div className="col-md-6 value-left-align">
                    <LimitTextLength
                        maxLenth={200}
                        displayText={getLabelValue(_get(formState, 'viewOwnerInformation.contact_number', '-'))}
                    />
                </div>
            </div>



            <div className="col-md-6 label-conatiner-center-wrapper">
                <div className="col-md-6  label-right-align" >
                    <strong>Address Line 1: </strong>
                </div>
                <div className="col-md-6 value-left-align">
                    <LimitTextLength
                        maxLenth={200}
                        displayText={getLabelValue(_get(formState, 'viewOwnerInformation.address_line_1', '-'), true)}
                    />
                </div>
            </div>

            <div className="col-md-6 label-conatiner-center-wrapper"></div>

            <div className="col-md-6 label-conatiner-center-wrapper">
                <div className="col-md-6  label-right-align" >
                    <strong>Line 2: </strong>
                </div>
                <div className="col-md-6 value-left-align">
                    <LimitTextLength
                        maxLenth={200}
                        displayText={getLabelValue(_get(formState, 'viewOwnerInformation.address_line_2', '-'), true)}
                    />
                </div>
            </div>

            <div className="col-md-6 label-conatiner-center-wrapper"></div>

            <div className="col-md-6 label-conatiner-center-wrapper">
                <div className="col-md-6  label-right-align" >
                    <strong>City: </strong>
                </div>
                <div className="col-md-6 value-left-align">
                    <LimitTextLength
                        maxLenth={200}
                        displayText={getLabelValue(_get(formState, 'viewOwnerInformation.city', '-'))}
                    />
                </div>
            </div>

            <div className="col-md-6 label-conatiner-center-wrapper"></div>

            <Fragment>
                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-6  label-right-align" >
                        <strong>SMS Alerts: </strong>
                    </div>
                    <div className="col-md-6 value-left-align">
                        {_get(formState, 'viewOwnerInformation.sms_alerts', 0) === 1 ? 'Yes' : 'No'}
                    </div>
                </div>

                <div className="col-md-12 ">
                    <div className="row defaultHalfMarginTop">
                        <div className="col-md-6 label-conatiner-center-wrapper">
                            <div className="col-md-6  label-right-align" >
                                <strong>Absolute Owner Name: </strong>
                            </div>
                            <div className="col-md-6 value-left-align">
                                <LimitTextLength
                                    maxLenth={200}
                                    displayText={_get(formState, `vehicle_search_form.data.absolute_owner_id`)!==null?getLabelValue(_get(formState, `vehicle_search_form.data.absolute_owner_id.name`, "-"), true):"-"}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 label-conatiner-center-wrapper">

                        </div>

                        <div className="col-md-6 label-conatiner-center-wrapper">
                            <div className="col-md-6  label-right-align" >

                                <strong>Absolute Owner Address: </strong>
                            </div>
                            <div className="col-md-6 value-left-align">
                                <LimitTextLength
                                    maxLenth={200}
                                    displayText={_get(formState, `vehicle_search_form.data.absolute_owner_id`) !==null?`${getLabelValue(_get(formState, `vehicle_search_form.data.absolute_owner_id.address_line_1`, "-"), true)}, ${getLabelValue(_get(formState, `vehicle_search_form.data.absolute_owner_id.address_line_2`, "-"), true)}, ${getLabelValue(_get(formState, `vehicle_search_form.data.absolute_owner_id.city`, "-"))}`:"-"}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 label-conatiner-center-wrapper">

                        </div>
                        <div className="col-md-6 mt-2 label-conatiner-center-wrapper">
                            <div className="col-md-6  label-right-align" >
                                <strong>Additional Information: </strong>
                            </div>
                            <div className="col-md-6 value-left-align">
                                <LimitTextLength
                                    maxLenth={200}
                                    displayText={`${getLabelValue(_get(formState, `viewOwnerInformation.additional_information`, "-"))}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 defaultMarginTop">
                    <DataTable
                        tableName="Previous Owner Information"
                        elementWrapperStyle={"scrolTable"}
                        apiUrl={`${previousOwnerInformationWithPagingAPI.url}/${_get(formState, 'vehicle_search_form.data.vehicle_no', '')}?sort_by=transferred_on|desc`}
                        dataTableKey={previousOwnerInformationWithPagingAPI.key}
                        tableHeaderList={[
                            { displayName: "Name", key: "name", isSort: false, onPrintFn: ({name}) => getLabelValue(name, true) },
                            { displayName: "Identification Number", key: "nic", isSort: false, onPrintFn: ({nic}) => getLabelValue(nic, true) },
                            { displayName: "Mobile Number", key: "contact_number", isSort: false },
                            { 
                                displayName: "Address", 
                                key: "address", 
                                onPrintFn: (rawData) => (
                                    (!isEmpty(rawData.address_line_1) ? 
                                        getLabelValue(rawData.address_line_1, true) : "" ) + (!isEmpty(rawData.address_line_2) ? ("," + getLabelValue(rawData.address_line_2, true) ) : "")  + (!isEmpty(rawData.city)? (","+rawData.city):"")), isSort: false },
                            { displayName: "Province", key: "province", onPrintFn: (rawData) => (getCodeName(ProvincesByIds, rawData.province)), isSort: false },
                            { displayName: "Transferred Date", key: "transferred_on", isSort: false, onPrintFn: (rawData) => (rawData.transferred_on ? (dateObjectToString(rawData.transferred_on, 'dd/mm/YY')) : null), isSort: false },
                        ]}
                        isSetTableFooter={false}
                        isSetTableHeader={true}
                        isSetAction={false}
                    />
                </div>
            </Fragment>
        </div>

    );
};



export { OwnerInfoDisplayPopup };