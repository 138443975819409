import React, { useContext, useEffect, useState } from "react";

import { CheckBoxWithState, checkBoxLabelPlacement } from "../../../../../ui-components/ui-elements/form/index";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { FormWrapper } from "../../../../../ui-components/ui-elements/form/index";
import { isEmptyValue } from "../../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";

const emptyFn = (...para) => undefined;

const LicencePeriodIcons = ({
  formGroupName = "normalLicence",
  onChangeStatusFn = emptyFn,
  getVehicleStatusCode = "",
}) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);

  const [getState, setState] = useState({
    isReIssuanceLicence: false,
  });
  const authorityTypeCode = _get(authStatus, "authUser.authority_type_code", null);

  useEffect(() => {
    if (_get(formState, "vehicle_search_form.isTempLicence", false) === true) {
      formAction.changeInputFn(formGroupName, "isReIssuance", "", false);
      setState({
        ...getState,
        isReIssuanceLicence: false,
      })

    }
  }, [_get(formState, "vehicle_search_form.isTempLicence", false)])

  return (

    <FormWrapper
      setGroupName={formGroupName}
      formGroupLinkWith={formGroupName}
      onDestroyUnsetFormObject={true}
      setFormObject={{
        isReIssuance:false,
      }}

    >
  
        {isEmptyValue(_get(formState, "createQuotation.quotation_no", null)) &&
          !isEmptyValue(authorityTypeCode) &&
          authorityTypeCode !== 'WLA' &&
          !formState.hasOwnProperty('quotation_search_form')
          ?
            <CheckBoxWithState
              labelText={"Re issuance"}
              formGroupName={formGroupName}
              isDisabled={_get(formState, `pending_workflow_key.workflows`, []).length > 0 || getVehicleStatusCode !== 'ELI'}
              labelPlacement={checkBoxLabelPlacement.start}
              inputName={"isReIssuance"}
              inputValue={getState.isReIssuanceLicence}
              //  cacheLevel={"NONE"}
              onChangeFn={(e) => {
                formAction.changeInputFn('vehicle_search_form', "isTempLicence", "", false)
                formAction.changeInputFn(formGroupName, "isReIssuance", "", e.value);
                formAction.changeInputFn("licence_validity_period", "start_onLoad", "",true);
                formAction.changeInputFn("licence_charges_form", "_onLoad", "", true);
                setState({
                  ...getState,
                  isReIssuanceLicence: !getState.isReIssuanceLicence,
                })
                //onChangeStatusFn(e.value)
              }
              }
            />
          : null
        }
     
     </FormWrapper>


  );
};

export default LicencePeriodIcons;
