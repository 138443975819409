import React, { useContext, useEffect } from 'react';

import { SelectBoxWithState } from "../../../../../ui-components/ui-elements/form/index";
import { CircleLoaderElement, PaddingLessUICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import LicencePeriodIcons from './LicencePeriodIcons';
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { normalLicenceEndDatesAPI, normalLicenceStartDateAPI, issueRLValidationAPI } from "../../../../../../config/apiUrl.config";
import { fromObjectToQueryString } from "../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { VehicleStatus, } from "../../../../../../config/enum.config";
import { getCodeName } from "../../../../../../helpers/common-helpers/common.helpers";
import { isEmpty } from 'lodash';
import { useState } from 'react';
const LicenceValidityPeriod = (props) => {
    const formGroupName = "licence_validity_period";
    const [formState, formAction] = useContext(FormContext);
    const [checkReissuance, SetCheckReissuance] = useState(false)

    useEffect(() => {
        formAction.initFromFn({ [formGroupName]: { endDate: "0", isReIssuance: false, _onLoad: true, start_onLoad: true, isLicenceEndDate: true } });
    }, []);


    useEffect(() => {
        onChangeStartDates(_get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, "vehicle_search_form.isTempLicence", false))

    }, [_get(formState, "vehicle_search_form.isTempLicence", false), _get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, "vehicle_search_form.data.current_license_type.code", null), _get(formState, "vehicle_search_form.data.vehicle_no", null)]);


    useEffect(() => {
        if (_get(formState, `licence_validity_period.start_onLoad`, true) === false) {
            onChangeEndtDates(_get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, "vehicle_search_form.isTempLicence", false))
        }
        // onChangeStartAndEndDates(_get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, "vehicle_search_form.isTempLicence", false))
    }, [_get(formState, "vehicle_search_form.isTempLicence", false), _get(formState, `licence_validity_period.start_onLoad`, true), _get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, "vehicle_search_form.data.current_license_type.code", null), _get(formState, "vehicle_search_form.data.vehicle_no", null)]);

    useEffect(() => {
        if (((_get(formState, "vehicle_search_form.data.document_validations.vet", false) === _get(formState, "vet_certificate_form.isVet", false))
            && (_get(formState, "vehicle_search_form.data.document_validations.insurance", false) === _get(formState, "insurance_form.isInsurance", false))
            && (_get(formState, "vehicle_search_form.data.document_validations.vft", false) === _get(formState, "vft_certificate_form.isfitness", false))
            && (_get(formState, "vehicle_search_form.data.document_validations.vrp", false) === _get(formState, "vrp_form.isroutePerm", false)) === true) && (_get(formState, `latest_licence_data.data.temp_licence`, 0) === 1 || _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "TMP")) {
            formAction.changeInputFn("licence_charges_form", "_onLoad", "", true);
            onChangeEndtDates(_get(formState, `${formGroupName}.isReIssuance`, false), null, "NO")

        }

    }, [_get(formState, "vehicle_search_form.data.document_validations", false),
    _get(formState, "vehicle_search_form.isTempLicence", false),
    _get(formState, `${formGroupName}.isReIssuance`, false),
    _get(formState, "vehicle_search_form.data.current_license_type.code", null),
    _get(formState, "vehicle_search_form.data.vehicle_no", null),
    _get(formState, "vrp_form.isroutePerm", false),
    _get(formState, "vft_certificate_form.isfitness", false),
    _get(formState, "insurance_form.isInsurance", false),
    _get(formState, "vet_certificate_form.isVet", false)]);



    useEffect(() => {
        if (_get(formState, `${formGroupName}._onLoad`, true) === false && _get(formState, `${formGroupName}.start_onLoad`, true) === false) {
            // if (_get(formState, `${formGroupName}.isReIssuance`, false) === true) {
            //     formAction.changeInputFn(formGroupName, "requestedLicenceEndDate", "", null);
            //     props.refreshDataFn(_get(formState, `${formGroupName}.endDate`, "0"), _get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, `${formGroupName}.startDate`, null), dateObjectToString(_get(formState, `${formGroupName}.reissanceEnddDate`, null)), _get(formState, "vehicle_search_form.isTempLicence", false));
            // }
             if (!isEmpty(_get(formState, `${formGroupName}.endDatesData`, []))  ) {
                _get(formState, `${formGroupName}.endDatesData`, []).map(item => {
                    if (item.id === _get(formState, `${formGroupName}.endDate`, "0")) {
                        formAction.changeInputFn(formGroupName, "tempLicenceEndDate", "", new Date(item.requested_licence_end_date));
                        formAction.changeInputFn(formGroupName, "requestedLicenceEndDate", "", item.requested_licence_end_date);
                        props.refreshDataFn(_get(formState, `${formGroupName}.endDate`, "0"), _get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, `${formGroupName}.startDate`, null), new Date(item.requested_licence_end_date), _get(formState, "vehicle_search_form.isTempLicence", false));
                    }

                })
            }
            else {
                formAction.changeInputFn(formGroupName, "requestedLicenceEndDate", "", null);
                formAction.changeInputFn("licence_charges_form", "_onLoad", "", true);
                // props.refreshDataFn(_get(formState, `${formGroupName}.endDate`, "0"), _get(formState, `${formGroupName}.isReIssuance`, false), _get(formState, `${formGroupName}.startDate`, null), null, _get(formState, "vehicle_search_form.isTempLicence", false));
            }
            formAction.changeInputFn(formGroupName, "isLicenceEndDate", "", false);
        }


    }, [_get(formState, `${formGroupName}._onLoad`, true),
    _get(formState, `${formGroupName}.start_onLoad`, true),
    _get(formState, `${formGroupName}.endDatesData`, []),
    _get(formState, "vehicle_search_form.isTempLicence", false),
    _get(formState, `${formGroupName}.isReIssuance`, false),
    _get(formState, `${formGroupName}.endDate`, ""),
    _get(formState, `${formGroupName}.reissanceEnddDate`, null)
    ])



    const onChangeStartDates = (isReIssuance = false, temp_request = false) => {
        formAction.changeInputFn(formGroupName, "start_onLoad", "", true);
        formAction.changeInputFn(formGroupName, "_onLoad", "", true);
        getLicenceStartDateFn(isReIssuance, temp_request)
    }
    const onChangeEndtDates = (isReIssuance = false, temp_request = false) => {
        formAction.changeInputFn(formGroupName, "_onLoad", "", true);
        getLicenceEndDateFn(isReIssuance, temp_request)

    }

    // const getRelatedData = (isReIssuance = false, temp_request = false, licence_type = _get(formState, "vehicle_search_form.data.current_license_type.code", null)) => {
    //     let tempReq = temp_request;
    //     if (temp_request) {
    //         tempReq = {
    //             runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
    //             validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
    //             charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
    //         }

    //     }
    //     const queryObj = {
    //         vehicle_no: _get(formState, "vehicle_search_form.data.vehicle_no", null),
    //         is_reissuance: isReIssuance,
    //         licence_period_start_date: null,
    //         //licence_period_start_date:_get(formState,"licence_charges_form.currentLicenceStartDate",null),
    //         licence_period_end_date: null,
    //         is_use_start_date: false,
    //         temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
    //         requested_licence_type: licence_type
    //     };

    //     const startDatePromise = sendAPIRequest(`${normalLicenceStartDateAPI.url}?${fromObjectToQueryString(queryObj)}`);
    //     const endDatesPromise = sendAPIRequest(`${normalLicenceEndDatesAPI.url}?${fromObjectToQueryString(queryObj)}`);

    //     Promise.all([startDatePromise, endDatesPromise])
    //         .then(result => {
    //             console.log("aaaabbbbb===", result)
    //             const endDates = result[1]["data"].map(value => {
    //                 return {
    //                     ...value,
    //                     requested_licence_end_date: value.value,
    //                     value: dateObjectToString(value.value, "dd/mm/YY")
    //                 };
    //             });
    //             if (_get(props.getLatestLicenceState, `current_licence_status`, "") === 'VA') {
    //                 // if (!isReIssuance) {
    //                 //     formAction.changeInputFn(formGroupName, "startDate", "", new Date(_get(props.currentObject, `last_licence_from`, "")));
    //                 // } else {
    //                 //     formAction.changeInputFn(formGroupName, "startDate", "", new Date(result[0]["data"]));
    //                 // }

    //                 formAction.changeInputFn(formGroupName, "startDate", "", new Date(result[0]["data"]));
    //                 formAction.changeInputFn(formGroupName, "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));

    //                 // formAction.changeInputFn(formGroupName, "startDate", "", '-');
    //             }
    //             // else if (_get(props.getLatestLicenceState, `current_licence_status`, "") === 'EX'
    //             //     || _get(props.getLatestLicenceState, `current_licence_status`, "") === 'RE'
    //             //     || _get(props.getLatestLicenceState, `current_licence_status`, "") === 'NL'
    //             // ) {
    //             //     formAction.changeInputFn(formGroupName, "startDate", "", new Date(result[0]["data"]));
    //             // }
    //             formAction.changeInputFn(formGroupName, "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));
    //             formAction.changeInputFn(formGroupName, "firstEnddDate", "", endDates[0]);
    //             formAction.changeInputFn(formGroupName, "endDatesData", "", endDates);
    //             formAction.changeInputFn(formGroupName, "endDate", "", !isEmpty(endDates) ? endDates[0].id : '0');
    //             formAction.changeInputFn(formGroupName, "_onLoad", "", false);


    //         });

    // };

    const getLicenceStartDateFn = (isReIssuance = false, temp_request = false) => {
        let tempReq = temp_request;
        if (temp_request) {
            tempReq = {
                runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
                validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
                charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
            }

        } else {
            tempReq = null
        }
        const queryObj = {
            vehicle_no: _get(formState, "vehicle_search_form.data.vehicle_no", null),
            is_reissuance: isReIssuance,
            licence_period_start_date: null,
            licence_period_end_date: null,
            is_use_start_date: false,
            temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
            requested_licence_type: _get(formState, "vehicle_search_form.data.current_license_type.code", null)
        };

        callApi(`${normalLicenceStartDateAPI.url}?${fromObjectToQueryString(queryObj)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    if (_get(props.getLatestLicenceState, `current_licence_status`, "") === 'EX'
                        || _get(props.getLatestLicenceState, `current_licence_status`, "") === 'RE'
                        || _get(props.getLatestLicenceState, `current_licence_status`, "") === 'NL'
                        || _get(props.getLatestLicenceState, `current_licence_status`, "") === 'VA'
                    ) {
                        formAction.changeInputFn(formGroupName, "startDate", "", new Date(result.data.data));
                    }

                    formAction.changeInputFn(formGroupName, "start_onLoad", "", false);
                } else {
                    formAction.changeInputFn(formGroupName, "start_onLoad", "", false);
                }

            });
    }


    const getLicenceEndDateFn = (isReIssuance = false, temp_request = false) => {
        let tempReq = temp_request;
        if (temp_request) {
            tempReq = {
                runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
                validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
                charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
            }

        } else {
            tempReq = null
        }

        const queryObj = {
            vehicle_no: _get(formState, "vehicle_search_form.data.vehicle_no", null),
            is_reissuance: isReIssuance,
            licence_period_start_date: null,
            licence_period_end_date: null,
            is_use_start_date: false,
            temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
            requested_licence_type: _get(formState, "vehicle_search_form.data.current_license_type.code", null)
        };
        (_get(formState, `licence_validity_period.start_onLoad`, true) === false &&
            callApi(`${normalLicenceEndDatesAPI.url}?${fromObjectToQueryString(queryObj)}`)
                .method("get")
                .headers(true)
                .isMultipart(false)
                .send((error, result) => {
                    if (!error) {

                        const endDates = result.data.data.map(value => {
                            return {
                                ...value,
                                requested_licence_end_date: value.value,
                                value: dateObjectToString(value.value, "dd/mm/YY")
                            };
                        });

                        if (_get(props.getLatestLicenceState, `current_licence_status`, "") === 'VA') {
                            formAction.changeInputFn(formGroupName, "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));

                        }
                        formAction.changeInputFn(formGroupName, "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));
                        formAction.changeInputFn(formGroupName, "firstEnddDate", "", endDates[0]);
                        formAction.changeInputFn(formGroupName, "endDatesData", "", endDates);
                        formAction.changeInputFn(formGroupName, "endDate", "", !isEmpty(endDates) ? endDates[0].id : '0');
                        formAction.changeInputFn(formGroupName, "_onLoad", "", false);


                    }
                    formAction.changeInputFn(formGroupName, "_onLoad", "", false);
                }))
    }



    // const sendAPIRequest = (apiUrl) => {
    //     return new Promise((resolve, reject) => {
    //         callApi(apiUrl)
    //             .headers(true)
    //             .method("get")
    //             .isMultipart(false)
    //             .send((error, result) => {
    //                 if (error) {
    //                     reject(error);
    //                 }

    //                 resolve(result.data);
    //             })
    //     });
    // };


    const getToDateRender = () => {
        // if (_get(formState, `${formGroupName}.isReIssuance`, false)) {
        //     return <div style={{ marginTop: '20px', marginLeft: "4px" }}>
        //         {dateObjectToString(_get(formState, `${formGroupName}.reissanceEnddDate`, null), "dd/mm/YY")}
        //     </div>
        // }

       
            if (isEmpty(_get(formState, `${formGroupName}.endDatesData`, []))) {
                return <div style={{ marginTop: '20px', marginLeft: "4px" }}>
                    -
                </div>
            }
            else {
                return <div className="col-10">
                    <SelectBoxWithState
                        inputName={"endDate"}
                        emptySelectOptionTxt={" "}
                        inputValue={dateObjectToString(_get(formState, `${formGroupName}.endDate`, ""), "dd/mm/YY")}
                        dataList={_get(formState, `${formGroupName}.endDatesData`, [])}
                        onChangeFn={(e) => {
                            formAction.changeInputFn(formGroupName, "endDate", "", e.value);
                            // props.refreshDataFn(e.value, _get(formState, `licence_validity_period.isReIssuance`, false));
                        }}
                        isDisabled={
                            _get(props.getLatestLicenceState, `current_licence_status`, "") === 'VA' &&
                            _get(formState, `${formGroupName}.isReIssuance`, false) === false ||
                            props.getVehicleStatusCode !== "ELI" ||
                            _get(props, 'params.fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW"
                        }
                    />
                </div>
            }
        
    }

    const getFromDateToRender = () => {
        return _get(formState, `${formGroupName}.startDate`, null) ? dateObjectToString(_get(formState, `${formGroupName}.startDate`, null), "dd/mm/YY") : "-"

    }

    // const onReIssuanceChangeFn = (isReIssuance) => {
    //     // formAction.changeInputFn(formGroupName, "_onLoad", "", true);
    //     // getRelatedData(isReIssuance);
    //     // props.refreshDataFn(_get(formState, `${formGroupName}.endDate`, "0"), isReIssuance, _get(formState, `${formGroupName}.startDate`, null), dateObjectToString(_get(formState, `${formGroupName}.reissanceEnddDate`, null)), _get(formState, "vehicle_search_form.isTempLicence", false));
    //     // refreshIssueValidFn(isReIssuance)

    // };

    return (

        <PaddingLessUICard
            cardHeading="Licence Validity Period"
            cardHeadingTail={ _get(formState,"vehicle-meta-data.data.allow_re_issue",false) === true? LicencePeriodIcons : null}  //#38953 removed Eligibale
            passPropsToCardHeadingTail={{
                formGroupName: formGroupName,
                //onChangeStatusFn: (isReIssuance) => onReIssuanceChangeFn(isReIssuance),
                getVehicleStatusCode: props.getVehicleStatusCode
            }}
        >
            {
                _get(formState, `${formGroupName}._onLoad`, true) === true ? (
                    <div>
                        <br />
                        <center>
                            <CircleLoaderElement />
                        </center>
                    </div>
                ) : (
                    <div className="row" style={{ marginTop: '-10px' }}>
                        <div className="col-5 mt-4">
                            <strong>From: </strong>
                            {isEmpty(_get(formState, `${formGroupName}.endDatesData`, []))?"-":getFromDateToRender()}
                        </div>

                        <div className="col-7">
                            <div className="row">

                                <div className="col-1" style={{ marginTop: '20px' }}>
                                    <strong  >To: </strong>
                                </div>
                                {getToDateRender()}
                            </div>
                        </div>
                    </div>
                )
            }
        </PaddingLessUICard>

    );
};

export default LicenceValidityPeriod;