/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-21 16:05:28
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-08-16 11:28:05
 */
import React, { Fragment, useState, useContext } from "react";
import {
  additionDeductionCreateAPI,
  additionDeductionEditAPI,
  additionDeductionListAPI,
  tempOtherAdditionDeductionAPI,
  OtherAdditionDeductionAPIAdmin,
  OtherAdditionDeductionTempAPI
} from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { _findindex } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { apiResponseStoringType } from "../../../core/context-providers/CoreContext.provider";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get , _set} from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const CustomAddDedButton = (props) => {
  const [addDedState, setaddDedState] = useState([]);
	const [, uiAction] = useContext(UIContext);
  const [authStatus] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);
  const user_role_level = _get(authStatus, "authUser.role_level_code", "");
  const btnText = props.btnText;
  const uiType = props.uiType;
  let selectedIds = []
  let selectedIdss = []
  // const newUiType = 
  return (
    <Fragment>
      <SubmitButton
        btnText={props.btnText}
        startIcon={"far fa-save"}
        formGroupName={props.formKey}
        dataTableKey={props.dataTableKey}
        elementStyle="btnWrapper"
        isValidate={true}
        // validationObject={{
        //   fileds: {
        //     //name: "Name",
        //     charge_type_value: "charge type value"

        //   },
        //   rules: {
        //     //name: "required|max:50",
        //     charge_type_value: "required"
        //   },
        //   message: {
        //     "charge_type_value.required": "Amount Is Required"
        //   }
        // }}
        flashMessages={{
          success: {
            status: true,
            message:
              uiType === "add"
                ? "Addition / Deduction is successfully added."
                : "Addition / Deduction is successfully updated.",
            messageType: "success",
          },
          failed: props.externalProps.fromParent.externalProps.uiType === 'APR' ? {} : {
            status: true,
            message: `Addition of Addition or Deduction is failed.`,
            messageType: "error",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          
          return {
            url: OtherAdditionDeductionTempAPI.url,
            key: OtherAdditionDeductionTempAPI.key,

          };
        
          
        }}
        onChangeRequestBodyFn={(formObject) => {
          console.log('PPPPPPPPPPPPPPPPPPPPP', props);
          console.log('PPPPPPPPPPPPPPPPPPPPP===', formState);
          
          selectedIds = _get(formState, "additions_deductions.selected_ids", [])
          selectedIdss = [...selectedIds];
          if(formObject.lcn_addition_deduction_id) {
            selectedIdss.push(formObject.lcn_addition_deduction_id)
            formAction.changeInputFn("additions_deductions", "selected_ids", "", selectedIdss)
            
          }
          if(user_role_level === 'INT_AU') {
            return getDataByFormObject({
              ...formObject,
              // name: formObject.name
              applicability_type_code: formObject.applicability_type_code,
              charge_effect_code: formObject.charge_effect_code,
              // charge_type_code: formObject.charge_type_code,
              charge_type_value: formObject.charge_type_value,
              effective_date: formObject.effective_date ? formObject.effective_date : new Date(),
              request_id: props.externalProps.fromParent.externalProps.uiType === 'APR' ? props.externalProps.fromParent.externalProps.approvalRequestId : props.requestId
            });
          }
          if(user_role_level === 'INT_PRV' && (formObject.charge_type_value && formObject.charge_type_value != null && formObject.charge_type_value !== '')) {
            return getDataByFormObject({
              ...formObject,
              // name: formObject.name
              applicability_type_code: formObject.applicability_type_code,
              charge_effect_code: formObject.charge_effect_code,
              // charge_type_code: formObject.charge_type_code,
              charge_type_value: formObject.charge_type_value,
              effective_date: formObject.effective_date,
              request_id: props.externalProps.fromParent.externalProps.uiType === 'APR' ? props.externalProps.fromParent.externalProps.approvalRequestId : props.requestId
            });
          }
          uiAction.setFlashMessage({
            status: true,
            message: `Amount is required to add this addition / deduction.`,
            messageType: "error",
            });
          

        }}
        callApiObject={{
          isSetHeaders: true,
          method: "post",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.responseLinkToFrom,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            const result = _get(formState, `OtherAdditionDeductionAPIKey.additions_deductions`, [])
            const resultData = result && result.length !== 0 ? result : []
            const newDataSet = selectedIdss && selectedIdss.length !== 0 ? resultData.filter(obj => !selectedIdss.includes(obj.id)) : resultData
            formAction.changeInputFn("OtherAdditionDeductionAPIKey", "additions_deductions", "", newDataSet)
            _get(formState, `OtherAdditionDeductionAPIKey.additions_deductions`, []).forEach((element, index) => {
              formAction.changeInputFn(`${index}_charge_type_value_new`, "charge_type_value_new", "", "")
              formAction.changeInputFn(`${index}_effective_date_new`, "effective_date_new", "", new Date())
            });
            formAction.changeInputFn(`OtherAdditionDeductionAPIKey_search`, "search", "",'')
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

export { CustomAddDedButton };
