import React, { useContext } from "react";

import { RawIndexMemo } from "../../table/DataTable";
import { _getInt } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { SubmitButton } from "../../form";
import { UIContext } from "../../../context-providers/UIContext.provider";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";


const RowNo = (props) => {
    return (
        <RawIndexMemo
            page_size={_getInt(props.pagingObject, "per_page", 1)}
            current_page={_getInt(props.pagingObject, "current_page", 1)}
            index={props.rowIndex || 0}
        />
    )
};

const DeleteUI = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure want to delete this record?</p>
        </div>
    )
}

const DownloadUI = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure want to download this document?</p>
        </div>
    )
}

const DeleteBtn = (props) => {
    const [, uiAction] = useContext(UIContext);
    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            dataTableKey={props.dataTableKey}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Document removed successfully.",
                    messageType: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${props.tableApiUrl.url}/document-upload/${props.row.id}`,
                    key: props.tableApiUrl.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Document removed successfully.",
                        messageType: "success"
                    });

                    props.onClosePopupFn(false);
                }
            }}
        />
    )
};

const DeleteOtherBtn = (props) => {
    const [, uiAction] = useContext(UIContext);
    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            dataTableKey={props.dataTableKey}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Document removed successfully.",
                    messageType: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${props.tableApiUrl.url}/${props.row.id}`,
                    key: props.tableApiUrl.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Document removed successfully.",
                        messageType: "success"
                    });
                    


                props.onClosePopupFn(false);
            }
            else {
                console.log(error)
            }
            }}
/>
    )
};

export {
    RowNo,
    DeleteUI,
    DeleteBtn,
    DeleteOtherBtn,
    DownloadUI
}
