import React, { useContext, useEffect } from "react";
import { Base64 } from "js-base64";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { addVehicleAPI } from "../../../../../config/apiUrl.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";

const OwnerInformation = (props) => {
  const [authStatus] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);

  const userRole = _get(authStatus, "authUser.role_level_code", "");
  const mandatory = false;
  const formKey = props.formKey;

  useEffect(() => {
    if(props.uiType === 'APR') {
      getOwnerInfo()
    }
  }, [])

  const getOwnerInfo = () => {
    callApi(`${addVehicleAPI.url}${props.approvalRequestId}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      if(result) {
        const {name, address_line_1, address_line_2, city, absolute_owner_name, 
          absolute_owner_address_line_1, absolute_owner_address_line_2, absolute_owner_city} = result.data.data
        formAction.changeInputFn(formKey, "name", "", name !== null ? Base64.decode(name) : "")
        formAction.changeInputFn(formKey, "address_line_1", "", address_line_1 !== null ? Base64.decode(address_line_1) : "")
        formAction.changeInputFn(formKey, "address_line_2", "", address_line_2 !== null ? Base64.decode(address_line_2) : "")
        formAction.changeInputFn(formKey, "city", "", city !== null ? city : "")
        formAction.changeInputFn(formKey, "absolute_owner_name", "", absolute_owner_name !== null ? Base64.decode(absolute_owner_name) : "")
        formAction.changeInputFn(formKey, "absolute_owner_address_line_1", "", absolute_owner_address_line_1 !== null ? Base64.decode(absolute_owner_address_line_1) : "")
        formAction.changeInputFn(formKey, "absolute_owner_address_line_2", "", absolute_owner_address_line_2 !== null ? Base64.decode(absolute_owner_address_line_2) : "")
        formAction.changeInputFn(formKey, "absolute_owner_city", "", absolute_owner_city !== null ? absolute_owner_city : "")

        // console.log('RESULT---------->', result);
      }
    })
  }

  return (
    <UICard cardHeading="Owner Information">
      {/* <FormWrapper setGroupName={formKey}> */}
        <InputBoxWithState
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          inputName={"name"}
          labelText="Name"
          maxLength={20}
          isRequired={props.uiType === 'APR' ? true : false}
        />

        <InputBoxWithState
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          inputName={"address_line_1"}
          labelText="Address Line 1"
          maxLength={50}
          isRequired={props.uiType === 'APR' ? true : false}
        />

        <InputBoxWithState
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          inputName={"address_line_2"}
          maxLength={50}
          labelText="Address Line 2"
        />

        <InputBoxWithState
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          inputName={"city"}
          labelText="City"
          maxLength={50}
          isRequired={props.uiType === 'APR' ? true : false}
        />

        <div className="defaultPaddingBottom">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"absolute_owner_name"}
            maxLength={20}
            isRequired={mandatory}
            labelText="Absolute Owner"
          />
        </div>
        <div className="defaultPaddingBottom">Absolute Owner </div>
        <div className="row">
          <div className="col-md-4">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"absolute_owner_address_line_1"}
              maxLength={50}
              labelText="Address Line 1"
              isRequired={mandatory}
            />
          </div>
          <div className="col-md-4">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"absolute_owner_address_line_2"}
              maxLength={50}
              labelText="Address Line 2"
              isRequired={mandatory}
            />
          </div>
          <div className="col-md-4">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"absolute_owner_city"}
              maxLength={50}
              labelText="City"
              isRequired={mandatory}
            />
          </div>
        </div>
      {/* </FormWrapper> */}
    </UICard>
  );
};

export { OwnerInformation };
