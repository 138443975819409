/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 17:01:32
 * @Last Modified by: Malinda
 * @Last Modified time: 2022-12-01 14:19:06
 */
import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../ui-components/ui-elements/common/BaseElements';
import {
  DataTable,
  RawIndexMemo,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { FormContext } from "../../core/context-providers/FormContext.provider";

import { templateTypes } from "../../../../config/template.config";
import { IPGTypesEnum, ReconciliationStatusTypesEnum, WebLicenceStatus } from "../../../../config/enum.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import {
  _getInt,
  _get,
} from "../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  SubmitButton,
  DatePickerWithState,
  InputButton,
  SelectBoxWithState
} from "../../../ui-components/ui-elements/form";
import { ViewReconciledTransactions } from './includes/ViewReconciledTransactions';
import { ProceedPopups } from './includes/ReconcilePublicPortalTransactionPopups';
import { webReconciliationAPI, reasonListAPI } from '../../../../config/apiUrl.config';
import { AuthContext } from "../../core/context-providers/AuthContext.provider.js";
import { CoreContext } from "../../core/context-providers/CoreContext.provider";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import ViewVehicleInfo from "../../tasks/pages/includes/ViewVehicleInfo";

const ViewPublicPortalTransactionsFormKey = "transactionSearchForm";
const reconciledTransactionHistoryFormKey = "reconciledTransactionHistoryForm";
const ReconcilePublicPortalTransactionFormKey = "reconcilePublicPortalTransactionForm";

const ViewPublicPortalTransactions = () => {
  return (
    <DashboardTemplate
      pageTitle="View Public Portal Transactions"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          displayName: "View Public Portal Transactions",
        },
      ]}
    >
      <ViewPublicPortalTransactionsElement formGroupName={"ViewPublicPortalTransactionsFormKey"} />
    </DashboardTemplate>
  );
};

const dataTableKey = 'web_reconcile'

let a = []
const ReconcileStatus = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);

  const datalist = _get(formState, `${dataTableKey}`, [])

  const onChangeValueHandler = (eventData) => {
    if(props.row.reconcileVoidReason) {
      delete props.row.reconcileVoidReason
      formAction.changeInputFn(`${props.rowIndex}_reconcileVoidReason`, "reconcileVoidReason", "", null);
    }
    props.row.reconcileStatus = eventData.value
    const checkSelectedExists = (element) => element.id == props.row.id;
    const tableData = _get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, [])
    console.log('CORE--', coreState);
    console.log('FORMM00', props.row);
    
    const selectedIndex = a.findIndex(checkSelectedExists);
    const selectedTdataIdx = tableData.findIndex(checkSelectedExists)
    tableData[selectedTdataIdx] = props.row
    coreAction.setDataTable(`webReconciliationAPIKey`, tableData, 'done', 1, null, null);
    formAction.setTableInputFuncValues(dataTableKey, tableData)
    a = a.length !== 0 ? a.filter(vehicle => vehicle.id !== props.row.id) : []
    a.push(props.row)
    console.log('FORMM===', a);
    
    console.log('FORMM--ppp', selectedIndex);
    if(selectedIndex === -1) {
      formAction.setTableInputFuncValues(dataTableKey, a)
    } else {
    console.log('FORMM--p');
      if(props.row.reconcileStatus !== "") {
    console.log('FORMM--p1');

        const updatedList = a.filter(vehicle => vehicle.id !== props.row.id)
        formAction.setTableInputFuncValues(dataTableKey, [...updatedList, a[selectedIndex] = props.row])
    } else {
    console.log('FORMM--p2');

        const updatedList = a.filter(vehicle => vehicle.id !== props.row.id)
        formAction.setTableInputFuncValues(dataTableKey, [...updatedList])
    }
    }

  }


 
  console.log('FORMM===111', formState);
  console.log('CORE--111', coreState);

  return (
      <Fragment>
          <SelectBoxWithState
                formGroupName={`${props.rowIndex}_reconcileStatus`}
                inputName={`reconcileStatus`}
                dataList={ReconciliationStatusTypesEnum}
                isRequired={false}
                onChangeFn={(eventData) => onChangeValueHandler(eventData)}
                inputValue={_get(formState, `ViewPublicPortalTransactionsFormKey`, null) !== null &&
                _get(formState, `ViewPublicPortalTransactionsFormKey.reconcileAllStatus`, null) !== null ?
                _get(formState, `ViewPublicPortalTransactionsFormKey.reconcileAllStatus`, null) : null
                }
              />
      </Fragment>
  )
}

let b = []
const ReconcileVoidReason = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const datalist = _get(formState, `${dataTableKey}`, [])
  
  const onChangeValueHandler = (eventData) => {
    props.row.reconcileVoidReason = eventData.value
    console.log('FORMM00', eventData);
    console.log('FORMM===', props);
    
    const checkSelectedExists = (element) => element.id == props.row.id;
        const selectedIndex = datalist.findIndex(checkSelectedExists);
        datalist[selectedIndex] = props.row
        formAction.setTableInputFuncValues(dataTableKey, datalist)

  }

  if(props.row.reconcileStatus === undefined || props.row.reconcileStatus != WebLicenceStatus.PAYMENT_RECONCILIATION_VOID) {
    return (
      <p>-</p>
    )
  }
  
    return (
        <Fragment>
          <div className={"col-md-12"}>
            <SelectBoxWithState
                  apiUrl={`${reasonListAPI.url}?${'webpayment_reconciliation'}=${true}`}
                  formGroupName={`${props.rowIndex}_reconcileVoidReason`}
                  inputName={`reconcileVoidReason`}
                  keyName={"id"}
                  valueName={"name"}
                  emptySelectOptionTxt={"Select Reason"}
                  apiStateKey={reasonListAPI.key}
                  isRequired={false}
                  onChangeFn={(eventData) => onChangeValueHandler(eventData)}
                />
                </div>
        </Fragment>
    )
  }

const ViewPublicPortalTransactionsElement = ({ formGroupName }) => {
  const [state, setState] = useState({
    showSubmitPopup: false,
  });
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [uiState, uiAction] = useContext(UIContext);
  const [coreState, coreAction] = useContext(CoreContext);

  const getWebAuthorityForSearch = () => {
    _get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).map((value, index) => {
      formAction.changeInputFn(`${index}_reconcileVoidReason`, "reconcileVoidReason", "", undefined);
      formAction.changeInputFn(`${index}_reconcileStatus`, "reconcileStatus", "", undefined);
    })
      if(_get(formState, "webReconciliationAPIKey_search", null) !== null && 
      (_get(formState, "webReconciliationAPIKey_search.payment_method", null) !== null ||
      _get(formState, "webReconciliationAPIKey_search.transactions_until", null) !== null)
      ){
        formAction.changeInputFn(`webReconciliationAPIKey_search`, "web_authority", "", `${_get(authStatus, "authUser.service_id", null)}`)
      }
  }

  console.log('CORE___', coreState);
  useEffect(() => {
    getWebAuthorityForSearch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []),_get(formState, "webReconciliationAPIKey_search.payment_method", null), _get(formState, "webReconciliationAPIKey_search.transactions_until", null)])

  let history = useHistory();

  const onChangeValueHandler = async (eventData) => {
    let updatedData = []
    console.log('11-',coreState);
    if(_get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).length !== 0) {
      await _get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).map(item => {
        item.reconcileStatus = eventData.value
    console.log('11--', item);

        updatedData.push(item)
      })
      console.log('11---', updatedData);
      formAction.setTableInputFuncValues(dataTableKey, updatedData)
      coreAction.setDataTable(`webReconciliationAPIKey`, updatedData, 'done', 1, null, null);
    }
    console.log('CORE--all', coreState)
    console.log('CORE--all=all', formState);
  }

  const emptyFun = (...para) => undefined;

  

  return (
    <UICard cardHeading='Reconcile Public Portal Transactions' >
        <Fragment>
      <FormWrapper>
        <div className="row">
          <div className="col-md-5  col-sm-2 defaultMarginTop ">
            <p><strong>Web Authority:</strong> {_get(authStatus, "authUser.authorityName", '-')}</p>
          </div>
          <div className="col-md-5  col-sm-8 defaultMarginTop "> </div>
          
          <div className="col-md-2  col-sm-2 defaultMarginTop ">
            <InputButton
              btnName={"viewhistory"}
              elementWrapperStyle={"floatRight"}
              btnText={"View History"}
              startIcon={"mdi mdi-magnify-plus-outline"}
              onClickBtnFn={() => history.push("/reconciled-transaction-history")}
            />
          </div>
          <div className="col-md-9  col-sm-9 defaultMarginTop "></div>
          
          <div className="col-12 defaultMarginTop">
            <DataTable
              // tableName={""}
              apiUrl={`${webReconciliationAPI.url}/web-reconciliation/list`}
              dataTableKey={`${webReconciliationAPI.key}`}
              elementWrapperStyle={"webreconcile"}
              isSetSearchFrom={true}
              searchFromComponent={<PaymentSearchElement dataTableKey={`${webReconciliationAPI.key}_search`} />}
              onClickSearchFn={emptyFun}
              defaultSearchFormObject={{
                "transactions_until": null,
                "payment_method": null,
                "web_authority": null
              }}
              onRowStyleFn={(data) => {
                // console.log('ROW--', data);
                if (data.row.reconcileStatus == WebLicenceStatus.PAYMENT_RECONCILIATION_VOID) {
                  // console.log('AAAA', data.row.reconcileStatus);
                        return "Void"
                    } else if (data.row.reconcileStatus == WebLicenceStatus.PAYMENT_RECONCILIATION_SETTLED) {
                  // console.log('AAAA222', data.row.reconcileStatus);

                        return "Settled"
                    } else {
                  // console.log('AAAA333', data.row.reconcileStatus);

                      return "Pending"
                    }
              }}
              isSetAction={false}
              isSetTableHeader={true}
              isSetTableFooter={false}
              tableHeaderList={[
                { displayName: "Transaction at", key: "transaction_at", onPrintFn: (rawData)=>{
                  return (dateObjectToString(rawData.transaction_at, "dd/mm/YY HH:MM"))
                }, isSort: false },
                { displayName: "Vehicle No.", key: "vehicle_no", isSort: false, },
                { displayName: "Licence No.", key: "licence_no", isSort: false },
                { displayName: "Payment Request Ref. No.", key: "payment_request_ref_no", isSort: false, },
                { displayName: "Payment Response Ref. No.", key: "payment_response_ref_no", isSort: false },
                { displayName: "Licence Payment Status", key: "licence_payment_status", onPrintFn: (rawData)=>{
                  return rawData.licence_payment_status === "SUC" ? 'Success' : '-'
                }, isSort: false },
                { displayName: "Reason", key: "reconcileVoidReason", fixWidth: 150,},
                { displayName: "eRL Transaction Status", key: "reconcileStatus", fixWidth: 65 },

              ]}
              specificComponents={{
                __no__: RowNo,
                reconcileStatus: ReconcileStatus,
                reconcileVoidReason: ReconcileVoidReason
              }}
            />
          </div>

          
        </div>
        
        </FormWrapper>
        <div className="row">
          <div className="col-md-2 col-sm-2 floatLeft" style={{marginTop: 20}}>   
                  <strong>Settled: </strong>{" "}
                  <i class="fas fa-square-full" style={{ color: "rgb(138, 243, 171)" }}></i>
          </div>
          <div className="col-md-2 col-sm-2 floatLeft" style={{marginTop: 20}}>   
                  <strong>Void: </strong>{" "}
                  <i class="fas fa-square-full" style={{ color: "rgb(240, 134, 134)" }}></i>
          </div>
          <div className="col-md-3  col-sm-3" style={{display: 'flex', alignItems: 'baseline'}}>
          <strong style={{width: 100}}>Mark All As: </strong>{" "}
          <SelectBoxWithState
                // labelText={""}
                elementWrapperStyle={"floatRight"}
                formGroupName={formGroupName}
                inputName={"reconcileAllStatus"}
                onChangeFn={(eventData) => onChangeValueHandler(eventData) }
                dataList={ReconciliationStatusTypesEnum}
                isRequired={false}
              />
          </div>
          <div className="col-md-2 col-sm-2">   </div>
          <div className="col-md-3  col-sm-3 floatRight" style={{marginTop: 16}}>
            <SubmitButton
              btnName={"cancel"}
              btnText={"Cancel"}
              elementWrapperStyle={"floatRight"}
              startIcon={"far fa-times-circle"}
              onClickBtnFn={()=> {
                formAction.changeInputFn(formGroupName, "reconcileAllStatus", "", null);
                _get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).map((value, index) => {
                  formAction.changeInputFn(`${index}_reconcileVoidReason`, "reconcileVoidReason", "", undefined);
                  formAction.changeInputFn(`${index}_reconcileStatus`, "reconcileStatus", "", undefined);
                })
                coreAction.initDataTableReset(webReconciliationAPI.key);

                // formAction.setTableInputFuncValues(dataTableKey, [])
                // formAction.setTableInputFuncValues(webReconciliationAPI.key, [])
              }}
              formGroupName={formGroupName}
            />
            <SubmitButton
              btnText={"Submit"}
              tooltip={"Submit"}
              formGroupName={'web_reconcile'}
              elementWrapperStyle={"floatRight defaultMarginRight"}
              isValidate={true}
              isBtnDisabled={_get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).length !== 0 ? false : true}
              startIcon={"far fa-save"}
              isSetHeaders={true}
              flashMessages={{
                success: {
                    status: true,
                    message: `Reconciliation Successfully Updated`,
                    messageType: "success",
                },
                failed: {
                    status: true,
                    message: `Reconcilation Submit Failed.`,
                    messageType: "error",
                  },
                }}
              onGetAPIEndPointFn={() => {
                return {
                  url: `${webReconciliationAPI.url}/web-reconciliation/update`,
                  key: webReconciliationAPI.key,
                };
              }}
              onChangeRequestBodyFn={(fromObject) => { 
                console.log('FOmTYPE---', typeof fromObject);

                console.log('FOm--', fromObject.length);
                if(fromObject.length === undefined){
                    setTimeout(() => {
                      uiAction.setFlashMessage({
                        status: true,
                        message: `Please Update at least one eRL Transaction Status to proceed.`,
                        messageType: "error",
                      });
                    }, 3000)
                    return
                  }

                if(fromObject._updateStatus == undefined) {
                  const results = fromObject && fromObject.length !== 0 && fromObject.filter(item => {
                    return item.reconcileStatus == "7" && item.reconcileVoidReason === undefined
                  })
                  console.log('FOm--1', results);
                    if(results && results.length !== 0) {
                      // log
                      setTimeout(() => {
                        uiAction.setFlashMessage({
                          status: true,
                          message: `Please enter the void reason.`,
                          messageType: "error",
                        });
                      }, 3000)
                      
  
                    } else {
                      return getDataByFormObject({
                        update_data: fromObject,
                      
                      });
                    }
                }
                
                
              }}
              callApiObject={{
                isSetHeaders: true,
                method: 'put',
              }}
              onResponseCallBackFn={(response, error) => {
                formAction.setTableInputFuncValues(dataTableKey, [])
                console.log('iii', response, error);
                if (
                  response.length === undefined &&
                  error === undefined &&
                  response.data.errors === undefined
                ) {
                  formAction.changeInputFn(formGroupName, "reconcileAllStatus", "", null);
                _get(coreState, `dataTableResponses.webReconciliationAPIKey.results`, []).map((value, index) => {
                  formAction.changeInputFn(`${index}_reconcileVoidReason`, "reconcileVoidReason", "", undefined);
                  formAction.changeInputFn(`${index}_reconcileStatus`, "reconcileStatus", "", undefined);
                })
                  coreAction.initDataTableReset(webReconciliationAPI.key);
                  // formAction.setFormErrorFn(webReconciliationAPI.key);
                  // formAction.setTableInputFuncValues(webReconciliationAPI.key, [])
                } else {
                  formAction.setTableInputFuncValues(dataTableKey, [])
                }
              }}
            />
          </div>
        </div>
    </Fragment >
      </UICard>
  )
};
const RowNo = (props) => {
  return (
    <RawIndexMemo
      page_size={_getInt(props.pagingObject, "per_page", 1)}
      current_page={_getInt(props.pagingObject, "current_page", 1)}
      index={props.rowIndex || 0}
    />
  );
};

const HistorySearchElement = ({
  dataTableKey = "",
}) => {
  const formKey = dataTableKey;
  const [formState, formAction] = useContext(FormContext);
  return (
    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      onDestroyUnsetFormObject={true}
    >
      <div className="row">
        <div className="col-md-6 floatLeft">
          <div className="row">
            <div className="col-6">
            <SelectBoxWithState
                labelText={"Payment Method"}
                formGroupName={formKey}
                apiUrl={`${webReconciliationAPI.url}/web-reconciliation/web-payment-types`}
                emptySelectOptionTxt={"Select Payment Method"}
                keyName={"id"}
                valueName={"name"}
                inputName={"payment_method"}
                // dataList={IPGTypesEnum}
                isRequired={false}
              />
              
            </div>
            <div className="col-6"></div>
            {/* <div className="col-2"></div> */}
            <div className="col-6">
            <DatePickerWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                labelText="Reconciled Date - From"
                inputName={"reconciled_from"}
                maxDate={new Date()}
                isRequired={false}
              />
            </div>
            <div className="col-6">
            <DatePickerWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                labelText="Reconciled Date - To"
                inputName={"reconciled_to"}
                minDate={_get(formState, `${formKey}.reconciled_from`, "")}
                inputValue={new Date()}
                maxDate={new Date()}
                isRequired={false}
              />
            </div>
            {/* <div className="col-2"></div> */}
            <div className="col-6">
            <DatePickerWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                labelText="Transaction Date - From"
                inputName={"transactions_from"}
                maxDate={new Date()}
                isRequired={false}
              />
            </div>
            <div className="col-6">
            <DatePickerWithState
                uiType={templateTypes.bootstrap}
                // formGroupName={formKey}
                labelText="Transaction Date - To"
                inputName={"transactions_to"}
                inputValue={new Date()}
                minDate={_get(formState, `${formKey}.transactions_from`, "")}
                maxDate={new Date()}
                isRequired={false}
              />
            </div>
            {/* <div className="col-2"></div> */}
          </div>
        </div>
      </div></FormWrapper>
  );
};

const PaymentSearchElement = ({
  dataTableKey = "",
}) => {
  const formKey = dataTableKey;
  return (
    <FormWrapper
      // formGroupName="search1"
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      onDestroyUnsetFormObject={true}
    >
      <div className="row">
        <div className="col-md-6 floatLeft">
          <div className="row">
            <div className="col-6">
            <SelectBoxWithState
                labelText={"Payment Method"}
                formGroupName={formKey}
                // apiUrl={`${webReconciliationAPI.url}/web-reconciliation/web-payment-types`}
                emptySelectOptionTxt={"Select Payment Method"}
                keyName={"id"}
                valueName={"value"}
                inputName={"payment_method"}
                dataList={IPGTypesEnum}
                isRequired={true}
              />
              
            </div>
            <div className="col-6">
            <DatePickerWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                labelText="Transactions until"
                inputName={"transactions_until"}
                maxDate={new Date()}
                isRequired={true}
              />
            </div>
          </div>
        </div>
      </div></FormWrapper>
  );
};
const ReconciledTransactionHistory = () => {
  return (
    <DashboardTemplate
      pageTitle="Reconciled Transaction History"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          displayName: "Reconciled Transaction History",
        },
      ]}
    >
      <ReconciledTransactionHistoryElement formGroupName={reconciledTransactionHistoryFormKey} />
    </DashboardTemplate>
  );
};
const ReconciledTransactionHistoryElement = ({ formGroupName = "" }) => {
  const [state, setState] = useState({
    showSubmitPopup: false,
  });
  const [authStatus, authAction] = useContext(AuthContext);


  let history = useHistory();
  return (
    <Fragment>
      <UICard>
        <div className="row">
          <FormWrapper>
          <div className="col-md-5  col-sm-2 defaultMarginTop ">
            <p><strong>Web Authority:</strong> {_get(authStatus, "authUser.authorityName", '-')}</p>
          </div>
            <div className="col-md-5  col-sm-8"></div>
            <div className="col-md-2  col-sm-2 defaultMarginBottom defaultMarginTop floatRight ">
              <InputButton
                btnName={"viewhistory"}
                elementWrapperStyle={"floatRight"}
                btnText={"Back"}
                startIcon={"fa fa-arrow-left"}
                onClickBtnFn={() => history.push("/manage-transactions-reconciliation")}
              />
            </div>
            <div className="col-12 defaultMarginTop">
              <DataTable
                tableName={"Reconciled Transactions History"}
                apiUrl={`${webReconciliationAPI.url}/web-reconciliation/history/list`}
                dataTableKey={`${webReconciliationAPI.key}_history`}
                isSetSearchFrom={true}
                isSetAction={true}
                isSetTableHeader={true}
                searchFromComponent={<HistorySearchElement dataTableKey={`${webReconciliationAPI.key}_history_search`} />}
                defaultSearchFormObject={{
                  "web_authority": `${_get(authStatus, "authUser.service_id", null)}`,
                  "payment_method": null,
                  "transactions_from": null,
                  "transactions_to": new Date(),
                  "reconciled_from": null,
                  "reconciled_to": new Date(),
                }}
                tableHeaderList={[
                  { displayName: "Reconciled at", key: "reconciled_at", onPrintFn: (rawData)=>{
                    return (dateObjectToString(rawData.reconciled_at, "dd/mm/YY HH:MM"))
                  }, isSort: false },
                  { displayName: "Transaction at", key: "transaction_at", onPrintFn: (rawData)=>{
                    return (dateObjectToString(rawData.transaction_at, "dd/mm/YY HH:MM"))
                  }, isSort: false, },
                  { displayName: "Payment Method", key: "payment_method", onPrintFn: (rawData)=>{
                    console.log('rr', rawData);
                    const newD = IPGTypesEnum.filter(t => t.id == rawData.payment_method)
                    return rawData.payment_method && rawData.payment_method !== null ? newD?.[0]?.value : '-'
                  }, isSort: false },
                  { displayName: "Vehicle No.", key: "vehicle_no", isSort: false },
                  { displayName: "Payment Request Ref. No.", key: "payment_request_ref_no", isSort: false, },
                  { displayName: "Payment Response Ref. No.", key: "payment_response_ref_no", isSort: false },
                  { displayName: "Licence Payment Status", key: "licence_payment_status", onPrintFn: (rawData)=>{
                    return rawData.licence_payment_status === "SUC" ? 'Success' : '-'
                  }, isSort: false },
                  { displayName: "eRL Reconciliation Status", key: "reconciliation_status", onPrintFn: (rawData)=>{
                      console.log('DFDFDF', rawData);
                    return rawData.reconciliation_status && rawData.reconciliation_status !== null ? ReconciliationStatusTypesEnum[rawData.reconciliation_status - 5]["value"] : '-'
                  }, isSort: false },
                  { displayName: "Reason", key: "reason_name", isSort: false },
                  { displayName: "Reconciled by", key: "reconciled_by_name", isSort: false },

                ]}
                actionTypeList={[
                  {
                    actionType: actionBtnTypes.view,
                    modelContentElement: ViewVehicleInfo,
                    actionBtn: {
                      tooltip: 'View Vehicle Info',
                      btnText: "View Vehicle Info",
                    },
                    dialogPopupProps: {
                      "isFullScreen": true,
                      "dialogTitle": "Vehicle Information",
                      "isCustomButton": false,
                    },
                    parentToModelProps: {
                      uiType: "RECONCILE_VIEW_VEHICLE",
                  }
                  }]}
                specificComponents={{
                  __no__: RowNo,
                }}
              />
            </div> </FormWrapper>
        </div>
      </UICard>
    </Fragment >
  )
};
const ReconcilePublicPortalTransaction = () => {
  return (
    <DashboardTemplate
      pageTitle="Reconcile Public Portal Transaction"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        { displayName: "View Public Portal Transactions" },
        {
          displayName: "Reconcile PublicPortal Transaction",
        },
      ]}
    >
      <ReconcilePublicPortalTransactionElement formGroupName={ReconcilePublicPortalTransactionFormKey} />
    </DashboardTemplate>
  );
};
const ReconcilePublicPortalTransactionElement = ({ formGroupName = "" }) => {
  const [state, setState] = useState({
    showSubmitPopup: false,
  });
  let history = useHistory();
  return (
    <Fragment>
      <UICard>
        <div className="row">
          <div className="col-md-10  col-sm-10 defaultMarginTop ">
            <p>Payment Gateway <strong>{"<Payment Gateway Name>"}</strong>  Transaction until <strong>{"<DD/MM/YYYY>"}</strong> </p>
          </div>
          <div className="col-md-2  col-sm-2 defaultMarginTop defaultMarginBottom ">
            <InputButton
              btnName={"back"}
              elementWrapperStyle={"floatRight"}
              btnText={"Back"}
              onClickBtnFn={() => history.push("/manage-transactions-reconciliation")}
            />
          </div>
          <div className="col-12 defaultMarginTop">
            <DataTable
              tableName={"Revenue Settlement"}
              isSetSearchFrom={false}
              isSetAction={true}
              isSetTableHeader={false}
              tableHeaderList={[
                { displayName: "Sequance Number", key: "sequanceNumber", isSort: false },
                { displayName: "Licence Number", key: "licenceNumber", isSort: false },
                { displayName: "Reference Number", key: "referenceNumber", isSort: false, },
                { displayName: "Approval Code", key: "approvalCode", isSort: false, },
                { displayName: "Transaction Number", key: "transactionNumber", isSort: false },
                { displayName: "Transaction At.", key: "transactionAt", isSort: false, },
                { displayName: "eRL Transaction Status", key: "eRLTransactionStatus", isSort: false },
                { displayName: "Payment Gateway Status", key: "paymentGatewayStatus", isSort: false },
                { displayName: "Record Status", key: "recordStatus", isSort: false },
              ]}
              tableBodyList={[{}]}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.view,
                  actionBtn: {
                    icon: "fa fa-barcode",
                    tooltip: 'Void'
                  },
                  modelContentElement: ProceedPopups,
                  dialogPopupProps: {
                    "isFullScreen": false,
                    "dialogTitle": "Proceed Void",
                    "isCustomButton": false,
                    "popupType": "void",
                  },
                  parentToModelProps: {
                    formKey: formGroupName + "_proceedVoid"
                  }
                },
                {
                  actionType: actionBtnTypes.view,
                  actionBtn: {
                    icon: "fa fa-barcode",
                    tooltip: 'Undo Void'
                  },
                }, {
                  actionType: actionBtnTypes.view,
                  actionBtn: {
                    icon: "fa fa-barcode",
                    tooltip: 'Mark as Settled'
                  },
                  modelContentElement: ProceedPopups,
                  dialogPopupProps: {
                    "isFullScreen": false,
                    "dialogTitle": "Proceed Settlement",
                    "isCustomButton": false,
                    "formKey": formGroupName + "_proceedSettlement",
                    "popupType": "markAsSettled",
                  },
                  parentToModelProps: {
                    formKey: formGroupName + "_proceedSettlement",
                  }
                }, {
                  actionType: actionBtnTypes.view,
                  actionBtn: {
                    icon: "fa fa-barcode",
                    tooltip: 'Undo Settled'
                  },
                }
              ]}
              specificComponents={{
                __no__: RowNo,
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 col-sm-10">   </div>
          <div className="col-md-2  col-sm-2 defaultMarginTop floatRight">
            <SubmitButton
              btnName={"cancel"}
              btnText={"Cancel"}
              elementWrapperStyle={"floatRight"}
              formGroupName={formGroupName}
            />
            <SubmitButton
              btnName={"submit"}
              btnText={"Submit"}
              formGroupName={formGroupName}
              elementWrapperStyle={"floatRight defaultMarginRight"}
            />
          </div>
        </div>
      </UICard>
    </Fragment >
  )
};
export { ViewPublicPortalTransactions, ReconciledTransactionHistory, ReconcilePublicPortalTransaction }