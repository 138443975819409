import React, { Fragment } from 'react';
import guest from "../../hoc-middlewares/Guest";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import AddVehicle from "./pages/AddVehicle";
import UpdateVehicle from './pages/UpdateVehicle';
import TransferVehicle from './pages/TransferVehicle';
import LicenceTypeChange from './pages/LicenceTypeChange';
import ReverseLastTransaction from './pages/ReverseLastTransaction';
import VehicleStatusChange from './pages/VehicleStatusChange';
import LicenceReprint from './pages/LicenceReprint';
import SetEffectiveDate from './pages/SetEffectiveDate';
import ChangeSerialNumber from './pages/ChangeSerialNumber';
import LiceneceWaveOff from './pages/LicenceWaveOff';
import RoutePermitFine from './pages/RoutePermiteFine';
import OtherAdditionsDeductions from './pages/OtherAdditionsDeductions';
import CancelLicence from './pages/CancelLicence';
import RequestLicenceArrears from './pages/RequestLicenceArrears';
import RequestLicenceInvalidDoc from './pages/RequestLicenceInvalidDoc';
import AddLicence from './pages/AddLicence';
import { ViewApprovalHistory } from './pages/includes/ViewApprovalHistory';
import authorized from '../../hoc-middlewares/Authorized';

const RequestForApprovalRoutes = () => {
    return (
        <Fragment>

            <Navigate exact={true} path="/add-vehicle" routeKey={"add-vehicle"} component={authorized(AddVehicle)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/update-vehicle" routeKey={"update-vehicle"} component={authorized(UpdateVehicle)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/transfer-vehicle" routeKey={"transfer-vehicle"} component={authorized(TransferVehicle)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/licence-type-change" routeKey={"transfer-vehicle"} component={authorized(LicenceTypeChange)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/vehicle-status-change" routeKey={"vehicle-status-change"} component={authorized(VehicleStatusChange)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/reverse-last-transaction" routeKey={"reverse-last-transaction"} component={authorized(ReverseLastTransaction)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/licence-reprint" routeKey={"licence-reprint"} component={authorized(LicenceReprint)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/set-effective-date" routeKey={"set-effective-date"} component={authorized(SetEffectiveDate)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/change-serial-number" routeKey={"change-serial-number"} component={authorized(ChangeSerialNumber)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/licence-wave-off" routeKey={"licence-wave-off"} component={authorized(LiceneceWaveOff)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/route-permit-fine" routeKey={"route-permit-fine"} component={authorized(RoutePermitFine)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/other-additions-deduction" routeKey={"other-additions-deduction"} component={authorized(OtherAdditionsDeductions)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/invalid-doc" routeKey={"invalid-doc"} component={authorized(RequestLicenceInvalidDoc)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/licence-arrears" routeKey={"licence-arrears"} component={authorized(RequestLicenceArrears)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/cancel-licence" routeKey={"cancel-licence"} component={authorized(CancelLicence)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/add-licence" routeKey={"add-licence"} component={authorized(AddLicence)} routePermissions={permissions.NONE.permissions} />

            <Navigate exact={true} path="/view-approval-history" routeKey={"view-approval-history"} component={authorized(ViewApprovalHistory)} routePermissions={permissions.NONE.permissions} />
          
        </Fragment>
    )
};

export default RequestForApprovalRoutes;
