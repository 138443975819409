/*
 * @Author: Nipuna Chandimal
 * @Date:  2021-01-12 13:15:55
 * @Last Modified by: Nipuna Chandimal
 * @Last Modified time: 2021-01-13 14:43:31
 */
import React, { Fragment } from 'react';
import { _getInt, _get, } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBox,
} from "../../../../ui-components/ui-elements/form";

const ProceedPopups = (props) => {
  let dataList = ""
  if (props.fromParent.formKey == "reconcilePublicPortalTransactionForm_proceedVoid") {
    dataList = [{ key: "recordIsMismatched", value: "Record is mismatched" }, { key: "recordIsDuplicated", value: "RecordIsDuplicated" }]
  } else if (props.fromParent.formKey == "reconcilePublicPortalTransactionForm_proceedSettlement") {
    dataList = [{ key: "toSettleTheRecord", value: "To settle the record – records match" }, { key: "recordIsDuplicated", value: "RecordIsDuplicated" }]
  }
  return (
    <Fragment>
      <div className="col-md-12">
        <FormWrapper setGroupName={`${props.fromParent.formKey}`}  >
          <div className="col-md-12 floatLeft">
            <SelectBoxWithState
              labelText={"Reason"}
              formGroupName={`${props.fromParent.formKey}`}
              inputName={"reason"}
              dataList={dataList}
              isRequired
            />
          </div>
          <div className="col-md-12 floatLeft">
            <InputBox
              formGroupName={`${props.fromParent.formKey}`}
              labelText="Comment"
              inputName={"comment"}
              isMultiline={true}
            />
          </div>
        </FormWrapper>
      </div>
    </Fragment>
  )
};
const ReasonPopup = (props) => {
  return (
    <Fragment>
      <div className="col-md-12">
        <FormWrapper setGroupName={`${props.fromParent.formKey}`}  >
          <div className="col-md-12 floatLeft">
            <div className="col-md-6 floatLeft">
              Reason
            </div>
            <div className="col-md-6 floatLeft">
              <strong> {"<Reason> "} </strong>
            </div>

          </div>
          <div className="col-md-12 floatLeft">
            <div className="col-md-6 floatLeft">
              Comment
            </div>
            <div className="col-md-6 floatLeft">
              <strong> {"<Comment> "} </strong>
            </div>
          </div>
        </FormWrapper>
      </div>
    </Fragment>
  )
};
export { ProceedPopups, ReasonPopup }
