import React, { useContext, useState, useEffect } from "react";
import { Fragment } from "react";
import { DatePickerWithState, FormWrapper, SelectBoxWithState, RoundButton } from "../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { numberThousandSeperator, fromObjectToQueryString } from "../../../../../helpers/common-helpers/common.helpers";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import {
    getLatestLicenseAPI, calculateLicenceFeeAPI, normalLicenceStartDateAPI,
    normalLicenceEndDatesAPI
} from "../../../../../config/apiUrl.config";
import { LicenceType } from "../../../../../config/enum.config";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { isEmpty } from "lodash";
const WaiveOffUpperPannel = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [getState, setState] = useState({
        "ShowTable1": false,
    });
    const [startingDate, setStartingDate] = useState(null);

    const startAt = _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) //props.workFlowRecord.carnet_licence_start_at
    const endAt = _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) //props.workFlowRecord.carnet_licence_end_at

    useEffect(() => {
        if (props.uiType === 'APR' || props.uiType === 'viewApproval') {
            // getNormalLicenceFee(props)
            getLicenceStartDateFn()
            getReqApprovalLicenceTypeData(props)
        }
    }, [_get(formState, "work_flow_record.data", null)])


    const getReqApprovalLicenceTypeData = (props) => {
        callApi(`${getLatestLicenseAPI.url}/${props.uiType === 'viewApproval' ? props.row.vehicle_no : props.dataSubmittedForApproval.vehicle_no}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

                }
            });
    }

    const getNormalLicenceFee = (licenceEndtype = 0, isReIssuance = false, startDate = null, endDate = null, temp_request = false) => {
        let queryObj = {}
        if (!isEmpty(startAt) && !isEmpty(endAt)) {
            queryObj = {
                vehicle_no: props.uiType === 'viewApproval' ? props.row.vehicle_no : props.dataSubmittedForApproval.vehicle_no,
                licence_end_type: 0,
                is_reissuance: false,
                temp_request: false,
                licence_period_start_date: dateObjectToString(startAt),
                licence_period_end_date: dateObjectToString(endAt)
            };
        } else {
            let tempReq = temp_request;
            if (temp_request) {
                tempReq = {
                    runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false, validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0), charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
                }
            }
    
            queryObj = {
                vehicle_no: props.uiType === 'viewApproval' ? props.row.vehicle_no : props.dataSubmittedForApproval.vehicle_no,
                licence_end_type: licenceEndtype,
                licence_period_start_date: startDate !== null ? dateObjectToString(startDate, "YY-mm-dd") : dateObjectToString(startingDate),
                licence_period_end_date: endDate ? dateObjectToString(endDate, "YY-mm-dd") : null,
                is_reissuance: isReIssuance,
                temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
                requested_licence_type: _get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, ""),
            };
            // queryObj = {
            //     vehicle_no: props.uiType === 'viewApproval' ? props.row.vehicle_no : props.dataSubmittedForApproval.vehicle_no,
            //     licence_end_type: 0,
            //     is_reissuance: false,
            //     temp_request: false
            // };
        }


        callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    let totArrearsFee = 0;
                    let totArrearsFine = 0;
                    let totArrearsAddition = 0;
                    let totArrearsDeduction = 0;
                    let totAddition = 0;
                    let totdeduction = 0;
                    let totCurrentLicenceFee = 0;
                    let totCurrentLicenceFine = 0;
                    let arrearsStartDate = null;
                    let arrearsEndDate = null;
                    let currentLicenceStartDate = null;
                    let currentLicenceEndDate = null;

                    const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

                    _get(result, "data.addition_deduction_summary", []).forEach(value => {
                        if (value.charge_effect_code === "DED") {
                            totdeduction += value.total

                        }
                        if (value.charge_effect_code === "ADD") {
                            totAddition += value.total

                        }

                    });
                    _get(result, "data.arrears", []).forEach(value => {
                        totArrearsFee += value.fee;
                        totArrearsFine += value.fine;
                        totArrearsAddition += value.addition_total
                        totArrearsDeduction += value.deduction_total
                    });

                    if (_get(result, "data.current_licences", []).length > 0) {
                        _get(result, "data.current_licences", []).forEach(value => {
                            totCurrentLicenceFee += value.fee;
                            totCurrentLicenceFine += value.fine;
                        });
                    }
                    else {
                        totCurrentLicenceFee = _get(result, "data.fee", 0)
                    }


                    if (_get(result, "data.arrears", []).length > 0) {
                        arrearsStartDate = result.data.arrears[0]["start_date"];
                        arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
                    }

                    if (_get(result, "data.current_licences", []).length > 0) {
                        currentLicenceStartDate = result.data.current_licences[0]["start_date"];
                        currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
                    }

                    let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
                        totAddition + _get(result, "data.additions.current_licence", 0) -
                        (totdeduction + _get(result, "data.deductions.current_licence", 0)))
                        + serviceCharge;

                    if (total.isNaN) {
                        total = _get(result, "data.fee", 0)
                    }
                    formAction.changeInputFn("licence_charges_form", "data", "", result.data);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
                    formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
                    formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
                } else {
                    formAction.changeInputFn("licence_charges_form", "data", "", []);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
                    formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
                    formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
                    formAction.changeInputFn("licence_charges_form", "total", "", 0);
                }
                formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
            });
    };

    const getLicenceStartDateFn = (isReIssuance = false, temp_request = false) => {
        let tempReq = temp_request;
        if (temp_request) {
            tempReq = {
                runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
                validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
                charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
            }
  
        }else{
            tempReq=null
        }
        const queryObj = {
            vehicle_no: props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.data.vehicle_no", null),
            is_reissuance: isReIssuance,
            licence_period_start_date: null,
            licence_period_end_date: null,
            is_use_start_date: false,
            temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
            requested_licence_type: _get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, "")
        };
  
        callApi(`${normalLicenceStartDateAPI.url}?${fromObjectToQueryString(queryObj)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                  console.log('ERRn', result);
                  setStartingDate(new Date(result.data.data))
                        formAction.changeInputFn('licence_validity_period', "startDate", "", new Date(result.data.data));
                    formAction.changeInputFn('licence_validity_period', "start_onLoad", "", false);
                    getLicenceEndDateFn(false, false, new Date(result.data.data))
                } else {
                  console.log('ERR', error);
                    formAction.changeInputFn('licence_validity_period', "start_onLoad", "", false);
                }
  
            });
    }

    const getLicenceEndDateFn = (isReIssuance = false, temp_request = false, startsDate) => {
        let tempReq = temp_request;
        if (temp_request) {
            tempReq = {
                runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
                validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
                charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
            }
  
        }else{
            tempReq=null
        }
  
        const queryObj = {
            vehicle_no: props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.data.vehicle_no", null),
            is_reissuance: isReIssuance,
            licence_period_start_date: null,
            licence_period_end_date: null,
            is_use_start_date: false,
            temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
            requested_licence_type: _get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, "")
        };
            callApi(`${normalLicenceEndDatesAPI.url}?${fromObjectToQueryString(queryObj)}`)
                .method("get")
                .headers(true)
                .isMultipart(false)
                .send((error, result) => {
                    if (!error) {
                      console.log('ERRd', result);
  
                        const endDates = result.data.data.map(value => {
                            return {
                                ...value,
                                requested_licence_end_date: value.value,
                                value: dateObjectToString(value.value, "dd/mm/YY")
                            };
                        });
  
                        // if (_get(props.getLatestLicenceState, `current_licence_status`, "") === 'VA') {
                        //     formAction.changeInputFn('licence_validity_period', "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));
  
                        // }
                        formAction.changeInputFn('licence_validity_period', "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));
                        formAction.changeInputFn('licence_validity_period', "firstEnddDate", "", endDates[0]);
                        formAction.changeInputFn('licence_validity_period', "endDatesData", "", endDates);
                        formAction.changeInputFn('licence_validity_period', "endDate", "", !isEmpty(endDates) ? endDates[0].id : '0');
                        formAction.changeInputFn('licence_validity_period', "_onLoad", "", false);
                        getNormalLicenceFee(!isEmpty(endDates) ? endDates[0].id : '0', startsDate, null)
  
  
                    }
                    formAction.changeInputFn('licence_validity_period', "_onLoad", "", false);
                })
    }

    const getAreas = () => {
        if (!props.isCarnet) {
            if (isEmpty(startAt) && isEmpty(endAt)) {
                return (

                    <table className="col-md-11 defaultMarginTopBottom">
                        <tr>
                            <th style={{ width: "25%" }}>Arrears Licence Period</th>
                            <th className="text-right">Fee (LKR)</th>
                            <th className="text-right">Fine (LKR)</th>
                            <th className="text-right">Additions (LKR)</th>
                            <th className="text-right">Deductions (LKR)</th>
                            <th className="text-right">Total (LKR)</th>
                        </tr>
                        {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
                            <tr>
                                <td className={"text-md-left"}>{_get(formState, `licence_charges_form.arrearsStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; - &nbsp;&nbsp; {_get(formState, `licence_charges_form.arrearsEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsEndDate`, ""), "dd/mm/YY") : ""}</td>
                                <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)).toFixed(2))}</td>
                                <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)).toFixed(2))}</td>
                                <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsAddition`, 0)).toFixed(2))}</td>
                                <td className="text-right">({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.totArrearsDeduction`, 0))).toFixed(2))})</td>
                                <td className="text-right">{
                                    numberThousandSeperator((Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)) +
                                        Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)) +
                                        Number(_get(formState, `licence_charges_form.totArrearsAddition`, 0)) -
                                        Number(_get(formState, `licence_charges_form.totArrearsDeduction`, 0))).toFixed(2))
                                }</td>

                            </tr>
                            :
                            <tr>No data found</tr>
                        }
                    </table>
                )
            }
        }
    }

    return (
        <Fragment>
            <UICard>
                <table className="col-md-12 defaultMarginBottom">
                    <tr>
                        <td><strong>Current Licence Type:</strong> &nbsp;&nbsp; {LicenceType[`${_get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, null)}`]} </td>
                    </tr>
                </table>
                {getAreas()}
                <table className="col-md-11 defaultMarginTopBottom">
                    <tr>
                        <th style={{ width: "25%" }}>Current Licence Period</th>
                        <th className="text-right">Fee (LKR)</th>
                        <th className="text-right">Fine (LKR)</th>
                        <th className="text-right">Additions (LKR)</th>
                        <th className="text-right">Deductions (LKR)</th>
                        <th className="text-right">Total (LKR)</th>
                    </tr>
                    {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
                        <tr>
                            <td className={"text-md-left"}>{_get(formState, `licence_charges_form.currentLicenceStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; - &nbsp;&nbsp; {_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : ""}</td>
                            {/* text-md-right */}
                            <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0)).toFixed(2))}</td>
                            <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fine_total`, 0)).toFixed(2))}</td>
                            <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_addition_total`, 0)).toFixed(2))}</td>
                            <td className="text-right">({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0))).toFixed(2))})</td>
                            <td className="text-right">{
                                numberThousandSeperator(Number(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0) +
                                    Number(_get(formState, `licence_charges_form.data.current_licence_fine_total`, 0)) +
                                    Number(_get(formState, `licence_charges_form.data.current_licence_addition_total`, 0)) -
                                    Number(_get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0)))).toFixed(2))
                            }</td>

                        </tr>
                        :
                        <tr>No data found</tr>
                    }
                </table>

                <table className="col-md-11 defaultMarginTopBottom">
                    <tr style={{ width: "350px" }}>
                        <td className={"text-right d-flex flex-row floatRight"}>
                            <p style={{ width: "200px" }}><strong>Service Charge (LKR)&nbsp;&nbsp;:</strong>&nbsp;&nbsp;</p>
                            <p style={{ width: "150px" }}>{
                                numberThousandSeperator(((Number(_get(formState, `licence_charges_form.serviceCharge`, 0)))).toFixed(2))}</p> </td>
                    </tr>
                    <tr style={{ width: "350px" }}>
                        <td className={"text-right d-flex flex-row floatRight"}>
                            <p style={{ width: "200px" }} className="text-right"><strong>Total Amount (LKR)&nbsp;&nbsp;:</strong>&nbsp;&nbsp;</p>
                            <p style={{ width: "150px" }}>{
                                numberThousandSeperator(((Number(_get(formState, `licence_charges_form.total`, 0)))).toFixed(2))} </p></td>
                    </tr>
                </table>
            </UICard>
        </Fragment>
    );
};

const WaiveOffType = (props) => {
    return (
        <FormWrapper >
            <UICard>
                <div className="col-md-12 paddingLeftNone defaultPaddingTop">
                    <div className="row">
                        <div className="col-md-3 ">
                            <SelectBoxWithState
                                formGroupName={props.uiType === 'APR' ? "Licence_waive_Off" : "licence-wave-off"}
                                inputName={"waive_off_type"}
                                emptySelectOptionTxt={"Select Waive Off Type"}
                                dataList={[
                                    {
                                        id: "DUR",
                                        value: "Duration base"
                                    },
                                    {
                                        id: "AMT",
                                        value: "Amount base"
                                    },
                                ]}
                                labelText=" Waive off Type"
                            />
                        </div>
                    </div>
                </div>
            </UICard>
        </FormWrapper>
    )
}

const WaiveOffDurationBase = (props) => {

    const checkPlusBtnVisibility = (index) => {
        return (

            Object.keys(_get(formState, `${props.formKey}.wave_off_duration_base`, {})).length === index + 1

        );
    };



    const [formState, formAction] = useContext(FormContext);

    const getTotalWaiveOff = () => {
        let totalIdxWise = 0.00
        const total = Object.keys(_get(formState, `${props.formKey}.wave_off_duration_base`, {})).map((value, index) => {
            totalIdxWise = totalIdxWise + _get(formState, `${props.formKey}.wave_off_duration_base.${index}.amount`, 0) * 1
            return totalIdxWise;
        })

        return numberThousandSeperator(Number(totalIdxWise).toFixed(2))

    }

    return (
        <FormWrapper >
            <UICard>
                <div className="col-md-12 paddingLeftNone">

                    {/* <div className="col-md-3">
                    <SelectBoxWithState
                        formGroupName="${props.formKey}"
                        inputName={"waive_off_type"}
                        emptySelectOptionTxt={"Waive Off Type"}
                        dataList={[
                            {
                                id: "DUR",
                                value: "Duration base"
                            },
                            {
                                id: "AMT",
                                value: "Amount base"
                            },
                        ]}
                        labelText=" Waive off Type"
                    />
                </div> */}


                    <div className="row defaultPaddingTop">
                        <div className="col-md-2"><strong>From Date</strong></div>
                        <div className="col-md-2"><strong>To Date</strong></div>
                        <div className="col-md-3"><strong>Waive off Component</strong></div>
                    </div>

                    {
                        Object.keys(_get(formState, `${props.formKey}.wave_off_duration_base`, {})).map((value, index) => {

                            return (
                                <div className="row">
                                    <div className="col-md-2">
                                        <DatePickerWithState
                                            formGroupName={props.formKey}
                                            inputStatePath={`${props.formKey}.wave_off_duration_base.${index}.fromDate`}
                                            labelText=" "
                                            minDate={props.isCarnet ? '01/01/1970' : _get(formState, `licence_charges_form.arrearsStartDate`, null) ?
                                                _get(formState, `licence_charges_form.arrearsStartDate`, null) : _get(formState, `licence_charges_form.currentLicenceStartDate`, null)}
                                            maxDate={props.isCarnet ? '30/01/2100' : _get(formState, `licence_charges_form.currentLicenceEndDate`, null)}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <DatePickerWithState
                                            formGroupName={props.formKey}
                                            inputStatePath={`${props.formKey}.wave_off_duration_base.${index}.toDate`}
                                            labelText=" "
                                            minDate={_get(formState, `${props.formKey}.wave_off_duration_base.${index}.fromDate`, null)}
                                            maxDate={props.isCarnet ? '30/01/2100' : _get(formState, `licence_charges_form.currentLicenceEndDate`, null)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <SelectBoxWithState
                                            formGroupName={props.formKey}
                                            inputStatePath={`${props.formKey}.wave_off_duration_base.${index}.wave_component`}
                                            emptySelectOptionTxt={"Select Waive Off Component"}
                                            dataList={[
                                                {
                                                    id: "FINE",
                                                    value: "Fine Only"
                                                },
                                                {
                                                    id: "FIFE",
                                                    value: "Fee & Fine Only"
                                                },
                                            ]}
                                        />
                                    </div>
                                    {/* <div className="col-md-2 defaultPadding defaultHalfMarginTop">
                                    4444.67
                                </div> */}
                                    {/* <div className="col-md-3">
                                    <NumberInputBoxWithState
                                        inputStatePath={`${props.formKey}.wave_off_duration_base.${index}.amount`}
                                        inputName={"amount"}
                                        labelText=" "
                                        decimalScale={2}
                                        isThousandSeparator={false}
                                        isAllowNegative={false}
                                        isAllowedFn={(value) => {
                                            let checkValue = value.value.toString();
                            
                                            if (checkValue.includes(".")) {
                                                checkValue = checkValue.split(".")[0];
                                            }
                            
                                            return checkValue.length <= 8;
                                            }}
                                    />
                                </div> */}

                                    {
                                        (checkPlusBtnVisibility(index)) ? (
                                            <div className="floatRight">
                                                <RoundButton
                                                    elementStyle={"mr-2"}
                                                    iconClass={"fas fa-plus"}
                                                    onClickBtnFn={(eventData) => {
                                                        formAction.changeInputFn(`${props.formKey}`, "", `${props.formKey}.wave_off_duration_base.${index + 1}.name`);
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }


                                    {
                                        (index > 0) ? (
                                            <RoundButton
                                                iconClass={"fas fa-minus"}
                                                elementStyle={"errorBtn"}
                                                onClickBtnFn={(eventData) => {
                                                    let monthRangeObj = _get(formState, `${props.formKey}.wave_off_duration_base`, {});
                                                    delete monthRangeObj[index.toString()];
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.wave_off_duration_base`, monthRangeObj);
                                                }}
                                            />
                                        ) : null
                                    }
                                </div>
                            )
                        })
                    }
                    {/* <div className="row defaultPaddingTop">
                    <div className="col-md-2"><strong>Total Waive Off Amount (LKR)</strong></div>
                    <div className="col-md-2">{getTotalWaiveOff()}</div>
                </div> */}

                </div>
            </UICard>
        </FormWrapper>

    );
};

const WaiveOffAmountBase = (props) => {
    const { fired, setFired } = props


    const [formState, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    // const [fired, setFired] = useState(false)
    const checkPlusBtnVisibility = (index) => {
        return (

            Object.keys(_get(formState, `${props.formKey}.wave_off_amount_base`, {})).length === index + 1

        );
    };
    const maxAmountFine = Number(_get(formState, `licence_charges_form.totArrearsFine`, 0) * 1 + _get(formState, `licence_charges_form.data.current_licences[0].fine`, 0) * 1).toFixed(2)
    const maxAmountFeeFine = Number(_get(formState, `licence_charges_form.totArrearsFee`, 0) * 1 +
        _get(formState, `licence_charges_form.totArrearsFine`, 0) * 1 +
        _get(formState, `licence_charges_form.data.current_licences[0].fee`, 0) * 1 +
        _get(formState, `licence_charges_form.data.current_licences[0].fine`, 0) * 1
    ).toFixed(2)


    const getTotalWaiveOff = () => {
        let totalIdxWise = 0.00
        const total = Object.keys(_get(formState, `${props.formKey}.wave_off_amount_base`, {})).map((value, index) => {
            totalIdxWise = totalIdxWise + _get(formState, `${props.formKey}.wave_off_amount_base.${index}.amount`, 0) * 1
            return totalIdxWise;
        })
        console.log('SSSSS--->', totalIdxWise);
        return numberThousandSeperator(Number(totalIdxWise).toFixed(2))

    }
    const onChangeValueHandler = (eventData, formKey, index, waiveComp) => {


        if (Number(eventData.value) > (waiveComp === 'FINE' ? maxAmountFine : waiveComp === 'FIFE' ? (maxAmountFeeFine) : 0)) {
            console.log('validation33--', _get(formState, `amount.isValidationFired`, null));
            if (_get(formState, `amount.isValidationFired`, true) === false) {
                if (waiveComp === 'FINE' && maxAmountFine == 0) {
                    return
                } else if (waiveComp === 'FIFE' && maxAmountFeeFine == 0) {
                    return
                }
                uiAction.setFlashMessage({
                    status: true,
                    message: `Amount (LKR) should be less than or equal to ${waiveComp === 'FINE' ? maxAmountFine : waiveComp === 'FIFE' ? (maxAmountFeeFine) : '0.00'}(LKR)`,
                    messageType: "error",
                });
            }
            formAction.changeInputFn('amount', "isValidationFired", "", true)

            // setFired(true)
            // console.log('EVVVVV======>', eventData);
            // console.log('EVVVVVPROPSSSSS======>', props);

            // formAction.changeInputFn(formKey, `wave_off_amount_base.${index}.amount`, "", '')
        } else {
            formAction.changeInputFn('amount', "isValidationFired", "", false)

            // setFired(false)
        }

    }
    return (
        <FormWrapper >
            <UICard>
                <div className="col-md-12 paddingLeftNone">

                    <div className="row defaultPaddingTop">
                        <div className="col-md-3"><strong>Waive off Component</strong></div>
                        <div className="col-md-2 text-right"><strong>Applicable Amount (LKR)</strong></div>
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-3"><strong>Amount (LKR)</strong></div>
                        <div className="col-md-1">
                        </div>
                    </div>

                    {
                        Object.keys(_get(formState, `${props.formKey}.wave_off_amount_base`, {})).map((value, index) => {

                            return (
                                <div className="row">
                                    <div className="col-md-3">
                                        <SelectBoxWithState
                                            formGroupName={props.formKey}
                                            inputStatePath={`${props.formKey}.wave_off_amount_base.${index}.wave_component`}
                                            emptySelectOptionTxt={"Select Waive Off Component"}
                                            dataList={[
                                                {
                                                    id: "FINE",
                                                    value: "Fine Only"
                                                },
                                                {
                                                    id: "FIFE",
                                                    value: "Fee & Fine Only"
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-md-2 defaultPadding defaultHalfMarginTop text-right">
                                        {_get(formState, `${props.formKey}.wave_off_amount_base.${index}.wave_component`, null) === 'FINE' ? numberThousandSeperator(Number(maxAmountFine).toFixed(2)) : _get(formState, `${props.formKey}.wave_off_amount_base.${index}.wave_component`, null) === 'FIFE' ? numberThousandSeperator(Number(maxAmountFeeFine).toFixed(2)) : '0.00'}
                                    </div>
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-3">
                                        <NumberInputBoxWithState
                                            elementStyle={"text-right"}
                                            labelText={""}
                                            inputStatePath={`${props.formKey}.wave_off_amount_base.${index}.amount`}
                                            inputName={"amount"}
                                            decimalScale={2}
                                            isThousandSeparator={false}
                                            isAllowNegative={false}
                                            isAllowedFn={(value) => {
                                                let checkValue = value.value.toString();
                                                if (Number(checkValue) > (_get(formState, `${props.formKey}.wave_off_amount_base.${index}.wave_component`, null) === 'FINE' ? maxAmountFine : maxAmountFeeFine)) {
                                                    let maxFine = maxAmountFine.toString()
                                                    let maxFeeFine = maxAmountFeeFine.toString()
                                                    if (_get(formState, `${props.formKey}.wave_off_amount_base.${index}.wave_component`, null) === 'FINE') {
                                                        if (maxFine.includes(".")) {
                                                            maxFine = checkValue.split(".")[0];

                                                        }
                                                        return checkValue.length < maxFine
                                                    } else {
                                                        if (maxFeeFine.includes(".")) {
                                                            maxFeeFine = checkValue.split(".")[0];

                                                        }
                                                        return checkValue.length < maxFeeFine
                                                    }

                                                } else {
                                                    if (checkValue.includes(".")) {
                                                        checkValue = checkValue.split(".")[0];
                                                    }

                                                    return checkValue.length <= 8;
                                                }

                                            }}
                                            onChangeTxtFn={(eventData) => onChangeValueHandler(eventData, props.formKey, index, _get(formState, `${props.formKey}.wave_off_amount_base.${index}.wave_component`, null))}
                                        />
                                    </div>
                                    {
                                        (checkPlusBtnVisibility(index)) ? (
                                            <div className="floatRight mt-3">
                                                <RoundButton
                                                    elementStyle={"mr-2"}
                                                    iconClass={"fas fa-plus"}
                                                    onClickBtnFn={(eventData) => {
                                                        formAction.changeInputFn(props.formKey, "", `${props.formKey}.wave_off_amount_base.${index + 1}.name`);
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }


                                    {
                                        (index > 0) ? (
                                            <div className=" mt-3">
                                                <RoundButton
                                                    iconClass={"fas fa-minus"}
                                                    elementStyle={"errorBtn"}
                                                    onClickBtnFn={(eventData) => {
                                                        let monthRangeObj = _get(formState, `${props.formKey}.wave_off_amount_base`, {});
                                                        delete monthRangeObj[index.toString()];
                                                        formAction.changeInputFn(props.formKey, "", `${props.formKey}.wave_off_amount_base`, monthRangeObj);
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }
                                </div>
                            )
                        })
                    }

                    <div className="row defaultPaddingTop">
                        <div className="col-md-2"><strong>Total Waive Off Amount (LKR)</strong></div>
                        <div className="col-md-1 text-right">{getTotalWaiveOff()}</div>
                    </div>

                </div>
            </UICard>
        </FormWrapper>
    );
};

export { WaiveOffUpperPannel, WaiveOffDurationBase, WaiveOffAmountBase, WaiveOffType };