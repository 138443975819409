/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-26 20:53:10 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-06-15 10:35:52
 */
import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { siteMenu } from "../../../../../config/menu.config";
import { ListWrapper, ListItemWrapper } from "../../../ui-elements/lists-and-menus/ListsAndMenus";
import { CollapseWrapper, CheckPermission } from "../../../ui-elements/common/BaseElements";
import { UIContext } from "../../../context-providers/UIContext.provider";
import { AuthContext } from "../../../../modules/core/context-providers/AuthContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UIMenuContext } from "../../../context-providers/UIMenuContext.provider";


const emptyFun = (...para) => undefined;


const SecondMenu = ({
    open = {},
    setOpen = emptyFun,
    menuList = []
}) => {
    const [uiState] = useContext(UIContext);
    const [authStatus] = useContext(AuthContext);

    return (
        <ListWrapper
            isDisablePadding={true}
            elementStyle={"pl-3"}
        >
            {
                menuList.map((mainMenu, index) => {
                    return (
                        <CheckPermission permission={mainMenu.permissions}>
                            {
                                (mainMenu.child === false) ? (
                                    <Link key={"link" + mainMenu.routeName} to={mainMenu.url} >
                                        <ListItemWrapper
                                            elementStyle={(uiState.currentRouteKey === mainMenu.routeName) ? "firstChildItem menuActive secondMenuStyle" : "firstChildItem secondMenuStyle" }
                                            keyValue={mainMenu.routeName}
                                            listItemText={mainMenu.labelText}
                                             leftIcon={mainMenu.icon}
                                        />
                                    </Link>
                                ) : (
                                    <div key={index + mainMenu.routeName}>
                                        <ListItemWrapper
                                            elementStyle={"firstChildItem"}
                                            keyValue={mainMenu.routeName}
                                            listItemText={mainMenu.labelText}
                                            leftIcon={mainMenu.icon}
                                        />
                                        <CollapseWrapper
                                            component={"li"}
                                            isIn={open[mainMenu.routeName]}
                                            isUnMountOnExit={true}
                                        >
                                        </CollapseWrapper>
                                    </div>
                                )
                            }
                        </CheckPermission>
                    )
                })
            }
        </ListWrapper>
    )
}


const FullMenu = () => {
    const [open, setOpen] = useState({});
    const [uiState] = useContext(UIContext);
    const [authStatus] = useContext(AuthContext);
    const [sidenavConfig, setSidenavConfigurations, type] = useContext(UIMenuContext);
    const authorityTypeCode = _get(
        authStatus, "authUser.authority_type_code", null
    );

    useEffect(() => {
        // setOpen({
        //     [uiState.currentRouteKey.split('.')[0]]: true
        // })
    }, []);

    const handleClick = (id) => {
        console.log("run")
        let temp_state = [...sidenavConfig];
        temp_state.map((item, index) => {
            if (item.id != id) {
                let temp_element = { ...temp_state[index] };
                temp_element.open = false;
                temp_state[index] = temp_element;
            } else {
                let temp_element = { ...temp_state[id - 1] };
                temp_element.open = !temp_element.open;
                temp_state[id - 1] = temp_element;
            }
        });
        setSidenavConfigurations(temp_state);
    };
    return (
        <ListWrapper>
            {
                siteMenu.map((mainMenu, index) => {
                    return (
                        // <CheckPermission permission={mainMenu.permissions}>
                        <CheckPermission key={index} permission={mainMenu.permissions}>
                            {

                                (mainMenu.child === false) ? (
                                    <>
                                        {authorityTypeCode !== null &&
                                            authorityTypeCode === "WLA" &&
                                            mainMenu.routeName == "counter-session-history" ? null : <Link key={"link" + mainMenu.routeName} to={mainMenu.url} >

                                            <ListItemWrapper
                                                key={mainMenu.routeName}
                                                elementStyle={(uiState.currentRouteKey === mainMenu.routeName) ? "parentItem menuActive" : "parentItem"}
                                                keyValue={mainMenu.routeName}
                                                listItemText={mainMenu.labelText}
                                                leftIcon={mainMenu.icon}
                                                onClickFn={() => handleClick(mainMenu.id)}
                                                size={mainMenu.size}
                                            />
                                        </Link>}
                                    </>
                                ) : (
                                    <>
                                        {authorityTypeCode !== null &&
                                            authorityTypeCode === "WLA" &&
                                            mainMenu.routeName == "counter-session-history" ? null : <div key={index + mainMenu.routeName}>
                                            <ListItemWrapper
                                                keyValue={mainMenu.routeName}
                                                listItemText={mainMenu.labelText}
                                                leftIcon={mainMenu.icon}
                                                rightIcon={(sidenavConfig[index].open === true) ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}
                                                onClickFn={() => handleClick(mainMenu.id)}
                                                size={mainMenu.size}
                                            />
                                            <CollapseWrapper
                                                component={"li"}
                                                isIn={sidenavConfig[index].open}
                                                isUnMountOnExit={true}
                                            >
                                                <SecondMenu menuList={mainMenu.nested} />
                                            </CollapseWrapper>
                                        </div>}
                                    </>
                                )
                            }
                        </CheckPermission>
                    )
                })
            }
        </ListWrapper>
    );
}



export {
    FullMenu
}
