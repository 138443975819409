import React, { useState } from "react";

import { RoundButton, roundBtnSizes } from "../form/index";

const Slide = ({
    elementWrapperStyle = "",
    helperText = [],
    dataList = [],

}) => {
    const [index, setIndex] = useState(0); // create state to keep track of images index, set the default index to 0

    const slideRight = () => {
        setIndex((index + 1) % dataList.length); // increases index by 1
    };

    const slideLeft = () => {
        const nextIndex = index - 1;
        if (nextIndex < 0) {
            setIndex(dataList.length - 1); // returns last index of images array if index is less than 0
        } else {
            setIndex(nextIndex);
        }
    };
    return (dataList.length > 0 && (
        <div className={`slideshow-container ${elementWrapperStyle}`}>
            <RoundButton
                btnSize={roundBtnSizes.medium}
                elementStyle={"prev"}
                iconClass={"fas fa-chevron-left"}
                onClickBtnFn={slideLeft}>
                tooltip={"Next"}
            </RoundButton>

            <img src={dataList[index]} alt={index} />


            <RoundButton
                elementStyle={"next"}
                iconClass={"fas fa-chevron-right"}
                onClickBtnFn={slideRight}>
                tooltip={"Prev"}
            </RoundButton>
            <div className="helperText">{helperText[index]}</div>
        </div>
    ));
};


export {
    Slide
};

