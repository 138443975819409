/*
 * @Author: Madhawa
 * @Date: 2020-10-13 11:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-10-21 14:12:27
 */

import React, { Fragment, useContext, useEffect, useState } from "react";

import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { DatePickerWithState, FormWrapper, SubmitButton, } from "../../../ui-components/ui-elements/form";
import { Action } from "./includes/Action";
import { templateTypes } from "../../../../config/template.config";
import { setEffectiveDateAPI, uploadDetailGetAPI, calculateLicenceFeeAPI, getLatestLicenseAPI } from "../../../../config/apiUrl.config";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject, fromObjectToQueryString } from "../../../../helpers/common-helpers/common.helpers";

import { Reason } from "./includes/Reason";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { RequestStatus, CurrentLicenceStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { getCodeName } from "../../../../helpers/common-helpers/common.helpers";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { isEmpty } from "lodash";

const SetDateSubmitBtn = (props) => {
  const [formState] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);

  // const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  const approvalAction = _get(
    formState,
    "SetEffectiveDateKey.action",
    null
  )
  const [authStatus] = useContext(AuthContext);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const request_id = _get(formState, "SetEffectiveDateKey.request_id", null);
  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={"SetEffectiveDateKey"}
        tooltip={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${request_id}.`,
            messageType: "success",
          },
          //   failed: props.uiType === 'assign&Perform' ? {
          //     status: true,
          //     message: `Approval Request Decision Submit Failed. Please Try Again.`,
          //     messageType: "error",
          // } : {
          //     status: true,
          //     message: `Approval Request Submit Failed. Please Try Again.`,
          //     messageType: "error",
          //   },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "SetEffectiveDateKey.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "SetEffectiveDateKey.action", "") === 'FRD' ? (_get(formState, "SetEffectiveDateKey.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
              effective_date: "Effective Date"
            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              effective_date: "Effective Date"

            }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            effective_date: "Effective Date"
          }) : {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            "effective_date": "Effective Date"

          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "SetEffectiveDateKey.action", "") === 'FRD' ? (_get(formState, "SetEffectiveDateKey.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",
            effective_date: "required"
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            effective_date: "required"
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            effective_date: "required"
          }) : {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required",
            "effective_date": "requiredIf:user_role_level,INT_PRV"

          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "SetEffectiveDateKey.action", "") === 'FRD' ? (_get(formState, "SetEffectiveDateKey.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province.required": "Province is required",
            "forward_authority.required": "Authority is required",
            "effective_date.required": "Effective Date is required"
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province.required": "Province is required",
            "effective_date.required": "Effective Date is required"
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "effective_date.required": "Effective Date is required."
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
            "action.required": "Action is required.",
            "effective_date.requiredIf": "Effective Date"

          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: props.uiType === "assign&Perform" ? `${setEffectiveDateAPI.url}/${_get(props, "dataSubmittedForApproval.id", null)}` : `${setEffectiveDateAPI.url}/${vehicle_no}`,
            key: setEffectiveDateAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "SetEffectiveDateKey.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["set_eff_date"],
              effective_date: fromObject.effective_date ? new Date(fromObject.effective_date) : new Date(),
              licence_type: current_licence_type,
              // status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              // authority_id: authorityId,
              // request_id: props.uiType ==="assign&Perform"?(_get(props, "dataSubmittedForApproval.id", null)):(fromObject.request_id),
              reason_id: formState['SetEffectiveDateKey-new'].reason_id,
              counter_session_id: '00001',
              forward_authority_service_id: serviceId,
              ui_request_id: _get(props, "approvalRequestId", null),
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              }
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["set_eff_date"],
              effective_date: fromObject.effective_date ? new Date(fromObject.effective_date) : new Date(),
              licence_type: current_licence_type,
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              carnet_licence_start_at: props.isCarnet ? _get(formState, 'carnet.fromDate', null) !== null ? _get(formState, 'carnet.fromDate', null) : new Date(1970, 1, 0, 10, 10, 10) : null,
              carnet_licence_end_at: props.isCarnet ? _get(formState, 'carnet.toDate', null) !== null ? _get(formState, 'carnet.toDate', null) : new Date(1970, 1, 1, 10, 10, 10) : null,
              request_id: props.uiType === "assign&Perform" ? (_get(props, "dataSubmittedForApproval.id", null)) : (fromObject.request_id),
              reason_id: fromObject.reason_id,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              ui_request_id: props.uiType === "assign&Perform" ? (_get(props, "approvalRequestId", null)) : fromObject.request_id,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "assign&Perform" ? "put" : "post",
        }}

        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          }
          else {
            if (props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
              });
            }
            else {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
        }}
      />
    </Fragment>
  );
};

let fromDateLicencePeriod;
let toDateLicencePeriod;
let toCarDate;
let fromCarDate;

const SetEffectiveDateUI = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = "SetEffectiveDateKey";
  const todayDate = new Date();
  const firstRegDate = props.uiType === "APR" ? (_get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null) === null ? _get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-') : _get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null)) : (_get(formState, "vehicle_search_form.data.licence_effective_date", null) === null ? _get(formState, "vehicle_search_form.data.first_reg_date", null) : _get(formState, "vehicle_search_form.data.licence_effective_date", null));
  // const firstRegDate = _get(formState, "vehicle_search_form.data.first_reg_date", null);
  const issuedDate = _get(formState, "latest_licence_data.data.valid_from", null);
  const startDate = new Date(issuedDate);
  const minDate = new Date(firstRegDate);
  const uiType = props.uiType;
  const isCarnet = props.isCarnet

  const vehicleStatus = _get(
    formState,
    "vehicle_search_form.data.vehicle_status",
    null
  );

  const licenceType = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );

  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();
    if (props.uiType === 'APR') {
      getReqApprovalLicenceTypeData(props)
      getNormalLicenceFee(props)
    }

    setRequestId(props.uiType === "APR" ? props.approvalRequestId : requ.substr(requ.length - 8))
  }, [])

  const getNormalLicenceFee = (props) => {
    let queryObj = {}
    if ((_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null) &&
      (_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null)) {
      queryObj = {
        vehicle_no: props.dataSubmittedForApproval.vehicle_no,
        licence_end_type: 1,
        is_reissuance: false,
        licence_period_start_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", null)) : null,
        licence_period_end_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_end_at", null)) : null,
      };
    } else {
      queryObj = {
        vehicle_no: props.dataSubmittedForApproval.vehicle_no,
        licence_end_type: 0,
        is_reissuance: false,
        temp_request: false
      };
    }


    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totArrearsAddition = 0;
          let totArrearsDeduction = 0;
          let totAddition = 0;
          let totdeduction = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;

          const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

          _get(result, "data.addition_deduction_summary", []).forEach(value => {
            if (value.charge_effect_code === "DED") {
              totdeduction += value.total

            }
            if (value.charge_effect_code === "ADD") {
              totAddition += value.total

            }

          });
          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
            totArrearsAddition += value.addition_total
            totArrearsDeduction += value.deduction_total
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            totAddition + _get(result, "data.additions.current_licence", 0) -
            (totdeduction + _get(result, "data.deductions.current_licence", 0)))
            + serviceCharge;

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
      });
  };

  const getReqApprovalLicenceTypeData = (props) => {
    callApi(`${getLatestLicenseAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {

          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

        }
      });
  }

  const carnetPendingStartDate = "1970-01-31T04:40:10.000Z"
  const carnetPendingEndDate = "1970-02-01T04:40:10.000Z"

  const validCarnetPendingStartDate = _get(formState, "work_flow_record", null) !== null && _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null &&
    _get(formState, "work_flow_record.data.carnet_licence_start_at", '-') === carnetPendingStartDate ? '-' : null

  const validCarnetPendingEndDate = _get(formState, "work_flow_record", null) !== null && _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null &&
    _get(formState, "work_flow_record.data.carnet_licence_end_at", '-') === carnetPendingEndDate ? '-' : null

  toDateLicencePeriod = _get(formState, "work_flow_record", null) !== null && _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null ?
    dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_end_at", '-'), "dd/mm/YY") : !isEmpty(_get(formState, 'licence_charges_form.currentLicenceEndDate', null)) ? dateObjectToString(_get(formState, 'licence_charges_form.currentLicenceEndDate', '-'), "dd/mm/YY") : _get(formState, 'licence_validity_period', null) !== null && _get(formState, 'licence_validity_period.endDatesData', null) && _get(formState, 'licence_validity_period.endDatesData', null) !== null && _get(formState, 'licence_validity_period.endDatesData', []).length !== 0 ? _get(formState, 'licence_validity_period.endDatesData', [{ id: "0", value: "-" }])[0]['value'] :
      _get(formState, 'latest_licence_data.data', null) !== null && _get(formState, 'latest_licence_data.data.valid_to', null) !== null ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_to', '-'), "dd/mm/YY") : dateObjectToString(new Date(), "dd/mm/YY")

  fromDateLicencePeriod = _get(formState, "work_flow_record", null) !== null && _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null ?
    dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", '-'), "dd/mm/YY") : !isEmpty(_get(formState, 'licence_charges_form.currentLicenceStartDate', null))
      ? dateObjectToString(_get(formState, 'licence_charges_form.currentLicenceStartDate', '-'), "dd/mm/YY") : _get(formState, 'licence_validity_period', null) !== null && _get(formState, 'licence_validity_period.startDate', null) !== null && _get(formState, 'licence_validity_period.startDate', null) !== '' ?
        dateObjectToString(_get(formState, 'licence_validity_period.startDate', null), "dd/mm/YY") : _get(formState, 'latest_licence_data.data', null) !== null && _get(formState, 'latest_licence_data.data.valid_from', null) !== null
          ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_from', '-'), "dd/mm/YY")


          : (props.carnet ? (!isEmpty(_get(formState, 'licence_charges_form.currentLicenceStartDate', null))
            ? dateObjectToString(_get(formState, 'licence_charges_form.currentLicenceStartDate', '-'), "dd/mm/YY") : "")

            : (props.isCarnet && _get(formState, 'carnet.fromDate', null) !== null ? dateObjectToString(_get(formState, 'carnet.fromDate', null), "dd/mm/YY") : !props.isCarnet && !isEmpty(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-')) ? dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-'), "dd/mm/YY") :
              dateObjectToString(new Date(), "dd/mm/YY")))

  toCarDate = props.isCarnet && _get(formState, 'carnet.toDate', null) !== null ? dateObjectToString(_get(formState, 'carnet.toDate', null), "dd/mm/YY") : '-'
  fromCarDate = props.isCarnet && _get(formState, 'carnet.fromDate', null) !== null ? dateObjectToString(_get(formState, 'carnet.fromDate', null), "dd/mm/YY") : '-'

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 defaultMarginBottom">
        <UICard>
          <FormWrapper
            setGroupName={formKey}
            apiUrl={`${setEffectiveDateAPI.url}/get-effective-date/${_get(props, "approvalRequestId", "")}`}
            onRebuildResponseFn={(response) => {
              formAction.changeInputFn('work_flow_record', "data", "", _get(response, "work_flow_record", {}));
              return {
                effective_date: _get(response, "effective_date", ""),
                reason_id: _get(response, "work_flow_record.reason_id", "")
              };
            }}
            setFormObject={props.uiType === 'APR' ? {
              ui_request_id: props.approvalRequestId,
            } : {
              request_id: _set(
                formState,
                `${formKey}.request_id`,
                requestId
              ),
            }}
          >
            <div className="fullWidthDiv">
              <div className="floatLeft col-md-2 col-sm-12 paddingRightNone">
                <strong>Licence Period </strong>
              </div>

              <div className="floatLeft col-md-2 paddingLeftNone">
                {props.isCarnet ? _get(formState, 'latest_licence_data.data.valid_from', null) !== null
                  ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_from', '-'), "dd/mm/YY") : fromCarDate : _get(formState, 'latest_licence_data.data.valid_from', null) !== null
                  ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_from', '-'), "dd/mm/YY") : validCarnetPendingStartDate === null ? fromDateLicencePeriod : '-'}
                <br />
                {props.isCarnet ? _get(formState, 'latest_licence_data.data.valid_to', null) !== null ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_to', '-'), "dd/mm/YY") :
                  toCarDate : _get(formState, 'latest_licence_data.data.valid_to', null) !== null ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_to', '-'), "dd/mm/YY") : validCarnetPendingEndDate === null ? toDateLicencePeriod : '-'}
              </div>
            </div>
            <div className="fullWidthDiv defaultPaddingTop">
              <div className="floatLeft paddingRightNone col-md-2 col-sm-6">
                <strong>Licence Status </strong>
              </div>
              <div className="floatLeft col-md-2 col-sm-12 paddingLeftNone">
                {_get(formState, `latest_licence_data.data.current_licence_status`, undefined) !== undefined ?
                  (getCodeName(CurrentLicenceStatus, _get(formState, `latest_licence_data.data.current_licence_status`, 'NL')) === "Renewable" && props.isCarnet) ? "Valid" :
                    (getCodeName(CurrentLicenceStatus, _get(formState, `latest_licence_data.data.current_licence_status`, 'NL')) === "Renewable") ? "Valid" :
                      getCodeName(CurrentLicenceStatus, _get(formState, `latest_licence_data.data.current_licence_status`, 'NL')) : 'No current licence'
                }
              </div>
            </div>
            {/* {console.log('eeeeeEEE', vehicleStatus)} */}
            {(vehicleStatus !== 'ACH' || vehicleStatus !== 'BLK') && <div className="floatLeft col-md-4 col-sm-12 defaultPaddingTop">
              <DatePickerWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                labelText="Set Effective Date"
                inputName={"effective_date"}
                maxDate={new Date()}
              // maxDate={isCarnet && _get(formState, `latest_licence_data.data.current_licence_status`, undefined) === 'NL' ? '01/01/2100' : _get(formState, `latest_licence_data.data.current_licence_status`, undefined) === 'NL' ? todayDate : startDate}
              //  minDate={isCarnet && _get(formState, `latest_licence_data.data.current_licence_status`, undefined) === 'NL' ? minDate : _get(formState, `latest_licence_data.data.current_licence_status`, undefined) !== 'VA' ? minDate : todayDate}
              />
            </div>}
          </FormWrapper>
        </UICard>
      </div>

      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <Reason
          filterKey={'set_effective_date'}
          formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
          requestType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
        />
      </div>

      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
        <Action
          formKey={formKey}
          uiType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
        />
      </div>
    </div>
  );
};

const SetEffectiveDate = () => {

  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Set Effective Date" },
      ]}
      pageTitle="Set Effective Date"
    >
      <SetEffectiveDateUI />
    </DashboardTemplate>
  );
};

export default SetEffectiveDate;
export { SetEffectiveDateUI, SetDateSubmitBtn };
