import React, {useEffect, useState} from "react";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
    FormWrapper, 
} from "../../../../ui-components/ui-elements/form"; 
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import {
  approvalHistoryGetAPI
} from "../../../../../config/apiUrl.config";

const ViewApprovalHistory = () => {
    const formKey = "ViewApprovalHistory";
    return (
        <UICard cardHeading="View Approval History">
            <FormWrapper setGroupName={formKey}>  
              <DataTable
                apiUrl={`${approvalHistoryGetAPI.url}`}
                tableName={"View Approval History"}
                isSetSearchFrom={false}
                isSetAction={false}
                dataTableKey={approvalHistoryGetAPI.key}
                isSetTableHeader={false} 
                tableHeaderList={[
                  { displayName: "No", key: "no", isSort: false },
                  { displayName: "Date & Time", key: "created_at", isSort: false, },
                  { displayName: "Action", key: "action", isSort: false, },
                  { displayName: "Forwarded Location", key: "forward_province_id", isSort: false, },
                  { displayName: "Forwarded Role", key: "forward_role_code", isSort: false, },
                  { displayName: "Action by", key: "updated_by", isSort: false, },
                  { displayName: "Location", key: "created_location", isSort: false, },
                  { displayName: "Remark", key: "remark", isSort: false }, 
                ]}
                
            
              /> 
             </FormWrapper>
        </UICard>
    )
};

export { ViewApprovalHistory };