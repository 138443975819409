/*
 * @Author: Gajani Kangesan
 * @Date: 2023-03-14 10:05:57 
 * @Last Modified by: Gajani Kangesan
 * @Last Modified time: 2023-03-14 15:00:00
 */


import React, { Fragment, useState, useEffect, useContext } from "react";
import { ViewDetailBlock } from '../../../../../ui-components/ui-elements/common/BaseElements';
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { reasonListAPI, AccountNumberListAPI, settlementStatusCheckAPI } from "../../../../../../config/apiUrl.config";
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import {
  _getInt,
  _get,
} from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { FormWrapper, InputButton, SelectBoxWithState } from "../../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { isEmpty } from "lodash";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";


const RefreshSettlement = (props) => {
  const [authStatus, authAction] = useContext(AuthContext);
  // revenue_settlement
  const formKey = "refreshSettlement"
  const [formState, formAction] = useContext(FormContext)
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "")
  const [, uiAction] = useContext(UIContext);
  const provinceId = roleCategoryCode === "COM" ? _get(authStatus, "authUser.pdmtId", "") : _get(authStatus, "authUser.pdmtId.id", "");
  useEffect(() => {
    formAction.initFromFn({ "refreshSettlement": { transactionStatus: props.row.status==="SUC"?"Successs":props.row.status==="PRO"?"Processing":"Failed" ,isChanged:false} });
  }, [])
 
  
  const statusCheckFn = () => {
    callApi(`${settlementStatusCheckAPI.url}?pdmt_id=${provinceId}&service_settlement_id=${props.row.service_settlement_id}`)
      .method("get")
      .headers(true)
      .body({})
      .isMultipart(false)
      .send((error, result) => {

        if (!error) {
          formAction.changeInputFn(formKey, "transactionStatus", "", result.data.data.status);
          formAction.changeInputFn(formKey, "transactionKey", "", result.data.data.key);
         
          if (_get(result, "data.data.key", "")==="SUC" || _get(result, "data.data.key", "")==="FAL" ) {
            formAction.changeInputFn(formKey, "isChanged", "", true);     
            formAction.changeInputFn(formKey,"status","",result.data.data.key)   
          }else{
            formAction.changeInputFn(formKey,"status","","")
            formAction.changeInputFn(formKey, "isChanged", "",false); 
          }

          
          uiAction.setFlashMessage({
            status: true,
            message: `Transaction status from BOC : ${_get(formState, `${formKey}.transactionStatus`, "")}`,
            messageType: "success",
          });

        }
        else {
          uiAction.setFlashMessage({
            status: true,
            message: `Can not get data from BOC.`,
            messageType: "error",
          });
          formAction.changeInputFn(formKey, "isChanged", "", false);
        }
      });
  }

 
  return (
    <Fragment>
      <FormWrapper
       setGroupName={formKey}
       formGroupLinkWith={formKey}
       onDestroyUnsetFormObject={true}
      >
      <div className={"col-md-12 mb-2"}>
        <div className="row">

          <div className="col-md-5 float-left " >
            <ViewDetailBlock
              label={"Transfer Status :"}
              value={_get(formState, `${formKey}.transactionStatus`, "")}
              labelCol={8}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
          </div>

          <div className="col-md-6 float-left">
            <InputButton
              btnText={"Refresh From BOC"}
              startIcon={"fas fa-sync-alt"}
              // elementStyle="btnWrapper"
              onClickBtnFn={() => statusCheckFn()}
            />
          </div>

        </div>

      </div>
     
      <div className={"col-md-12"}>
        <SelectBoxWithState
          formGroupName={formKey}
          inputName={`status`}
          keyName={"id"}
          valueName={"name"}
          emptySelectOptionTxt={_get(formState, `${formKey}.isChanged`, false)===true?_get(formState, `${formKey}.transactionStatus`, ""):" "}
          isRequired={true}
          labelText="Mark All As"
          dataList={[
            {
              id: "PEN",
              name: ""
            },
            {
              id: "SUC",
              name: "Success"
            },
            {
              id: "FAL",
              name: "Failed"
            }
          ]}
          isDisabled={_get(formState, `${formKey}.isChanged`, "")==true?true: false}
        />
      </div>
      <div className={"col-md-12"}>
        <SelectBoxWithState
          apiUrl={`${reasonListAPI.url}?${'revenue_settlement'}=${true}`}
          formGroupName={formKey}
          inputName={'manually_settled_reason_id'}
          keyName={"id"}
          valueName={"name"}
          emptySelectOptionTxt={" "}
          apiStateKey={reasonListAPI.key}
          isRequired={true}
          labelText="Reason"
          isDisabled={_get(formState, `${formKey}.isChanged`, "")==true?true: false}
        />
      </div>
      </FormWrapper>
    </Fragment>
  )
}

export { RefreshSettlement }