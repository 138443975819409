/**
 * @author INOVA
 * @description purpose of this hook is the call the print service thourgh single unit of code
 * @param printData (formatted print request from the backend including base64 encoded template)
 */

import { useState, useContext } from "react";
import { defaultPrinterAPI, localPrinterAPI } from "../../../config/apiUrl.config";
import { coreAction } from "../../modules/core/core-helpers/coreContext.helpers";
import { UIContext } from '../../ui-components/context-providers/UIContext.provider';

const environment = process.env.REACT_APP_ENV ?? 'development';

const basePrintFn = async (printRequest, successMsg = '', errorMsg = '', printerResponses, setPrinterResponses, uiDispatch) => {
    const url = environment === 'production' ? printRequest?.printURL ?? defaultPrinterAPI.url : localPrinterAPI.url;
    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(printRequest)
    }).then(
        response => response.text()
    ).then((data) => {
        setPrinterResponses(...printerResponses, [data]);
        uiDispatch.setFlashMessage({
            status: true,
            message: data === 'OK' ? successMsg : `${errorMsg} Error ${data}`,
            messageType: data === 'OK' ? "success" : "error"
        });
    }).catch(error => {
        uiDispatch.setFlashMessage({
            status: true,
            message: `${errorMsg} Error ${error.message}`,
            messageType: "error"
        });
    })
}

const useLicencePrint = () => {
    const [printerResponses, setPrinterResponses] = useState([]);
    const [, uiDispatch] = useContext(UIContext);

    const printFn = async (coreAction, paymentResponse, successMsg = null, errorMsg = null) => {
        let serial = paymentResponse?.data?.lhs_licence_no ?? paymentResponse?.data?.rhs_licence_no;
        successMsg = successMsg ?? `Printing Licence ${serial}`
        errorMsg = errorMsg ?? `Failed to Print Licence ${serial}`
        await basePrintFn(paymentResponse, successMsg, errorMsg, printerResponses, setPrinterResponses, uiDispatch);
    }

    const bulkPrintFn = async (coreAction, paymentResponse) => {
        let bulkRequest = paymentResponse[0] ?? false;
        let template = bulkRequest.template;

        if (bulkRequest) {
            let requestId = bulkRequest.requestId;
            let requestType = bulkRequest.requestType;
            if (bulkRequest.data.licences.length > 0) {
                for (let licence of bulkRequest.data.licences) {
                    await printFn(
                        coreAction,
                        {
                            requestId: requestId,
                            requestType: requestType,
                            template: template,
                            printBorders: bulkRequest.data.printBorders,
                            data: licence
                        }
                    )
                }
            }
        }
    }

    return { printFn, bulkPrintFn, printerResponses }
}



const useEnvelopPrint = () => {
    const [printerResponses, setPrinterResponses] = useState([]);
    const [, uiDispatch] = useContext(UIContext);

    const printFn = async (coreAction, paymentResponse, successMsg = null, errorMsg = null) => {
        let trackingId = paymentResponse?.data?.tracking_id;
        successMsg = successMsg ?? `Printed Envelop, Tracking Id - ${trackingId}`
        errorMsg = errorMsg ?? `Failed to print Envelop ${trackingId}`
        await basePrintFn(paymentResponse, successMsg, errorMsg, printerResponses, setPrinterResponses, uiDispatch);
    }

    const bulkEnvelopPrintFn = async (coreAction, paymentResponse) => {
        let bulkRequest = paymentResponse[0] ?? false;
        let template = bulkRequest.template;
        if (bulkRequest) {
            let requestId = bulkRequest.requestId;
            let requestType = bulkRequest.requestType;
            if (bulkRequest.data.envelops.length > 0) {
                for (let printData of bulkRequest.data.envelops) {
                    await printFn(
                        coreAction,
                        {
                            requestId: requestId,
                            requestType: requestType,
                            template: template,
                            printBorders: bulkRequest.data.printBorders,
                            data: printData
                        }
                    )
                }
            }
        }
    }

    return { printFn, bulkEnvelopPrintFn, printerResponses }
}

const useReceiptPrint = () => {
    const [printerResponses, setPrinterResponses] = useState([]);
    const [, uiDispatch] = useContext(UIContext);

    const printFn = async (coreAction, paymentResponse, successMsg = null, errorMsg = null) => {
        let reqId = paymentResponse?.data?.data?.reqId;
        successMsg = successMsg ?? `Receipt is successfully Printed . `
        errorMsg = errorMsg ?? `Failed to Print Receipt. `
        await basePrintFn(paymentResponse, successMsg, errorMsg, printerResponses, setPrinterResponses, uiDispatch);
    }

    

    return { printFn,  printerResponses }
}


export { useLicencePrint ,useEnvelopPrint,useReceiptPrint  }