/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-06 12:12:55
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-14 21:01:47
 */
import React, { useContext } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  DatePickerWithState,
  FormWrapper,
  InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const formKey = 'nonUserFormkey';
const NonUserLicence = () => {
  const [formState, formAction] = useContext(FormContext);
  const today = new Date();
  return (
    <div className="fullWidthDiv">
      <PaddingLessUICard cardHeading="Non-User Licence Request">
        <FormWrapper
          elementStyle={""}
          setGroupName={formKey}
          setFormObject={{}}
        >
          <div className="row">
            <div className="col-md-6">
              <InputBoxWithState
                labelText={"Issued Organization"}
                formGroupName={formKey}
                inputName={"issued_by"}
                isRequired={true}
                maxLength= "50"
                isDisabled={_get(formState, "vehicle_search_form.data.vehicle_status", "") !== "ELI" ? true : false}
              />
            </div>
            <div className="col-md-6">
              <DatePickerWithState
                labelText={"Issued Date"}
                formGroupName={formKey}
                inputName={"issued_date"}
                maxDate= {today}
                isDisabled={_get(formState, "vehicle_search_form.data.vehicle_status", "") !== "ELI" ? true : false}
              />
            </div>
            <div className="col-md-12">
              <InputBoxWithState
                labelText={"Address"}
                formGroupName={formKey}
                inputName={"issued_location"}
                maxLength= "100"
                isDisabled={_get(formState, "vehicle_search_form.data.vehicle_status", "") !== "ELI" ? true : false}
              />
            </div>
          </div>
        </FormWrapper>
      </PaddingLessUICard>
    </div>
  );
};

export { NonUserLicence };
