/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-18 16:22:16
 */

import React, { useState, useContext, Fragment } from 'react';
import {
  InputBoxWithState,
  RadioButtonsGroupWithState, FormWrapper
} from "../../../ui-components/ui-elements/form/index";
import { getDealerInfoAPI, getVehicleInfoAPI, getLatestLicenseAPI, getVehicleMetaDataAPI } from "../../../../config/apiUrl.config";
import { FormContext } from '../../core/context-providers/FormContext.provider';
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { SubmitButton, InputButton } from "../../../ui-components/ui-elements/form/Button";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { templateTypes } from "../../../../config/template.config";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import Vehicle from "./includes/Vehicle.js";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import Dealer from "./includes/Dealer.js";
import { ActivityLog } from '../../counter-session/pages/includes/ActivityLog';
const VehicleInfo = (props) => {
  const searchFormKey = "vehicle_dealer_search_form";

  const [formState, formAction] = useContext(FormContext);
  const [getLicenceState, setLicenceState] = useState(null);
  const [isModel, setModel] = useState(false);
  const [, uiAction] = useContext(UIContext);
  const [getState, setState] = useState({ status: false});


  const activityLog = () => {
    return (
      <div class='row'>
        <div className="col-md-12 text-right" >
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            btnText={"Activity Log"}
            onClickBtnFn={()=>
              setState({
                ...getState,
                status: true,
              })
            }
          />
        </div>
        <DialogPopup
          isOpen={getState.status}
          dialogTitle={"Activity Log"}
          isFullScreen={true}
          onClosePopupFn={() =>
            setState({
              ...getState,
              status: false,
            })
          }
          
        >
         
          <ActivityLog uiType='Vehicle-dealer' />
        </DialogPopup>
      </div>
    )
  }

  const VehicleHandleKeyDown = () => {

    let urlAttached = '';

    if (_get(formState, `${searchFormKey}.vehicle_no`) != undefined) {
      urlAttached = `vehicle_no=${_get(formState, `${searchFormKey}.vehicle_no`)}`;
    }
    if (_get(formState, `${searchFormKey}.chassis_no`) != undefined) {
      if (urlAttached === '') {
        urlAttached = `chassis_no=${_get(formState, `${searchFormKey}.chassis_no`)}`;
      } else {
        urlAttached = `${urlAttached}&chassis_no=${_get(formState, `${searchFormKey}.chassis_no`)}`;
      }
    }
    if (_get(formState, `${searchFormKey}.revenue_licence_id`) != undefined) {
      if (urlAttached === '') {
        urlAttached = `revenue_licence_id=${_get(formState, `${searchFormKey}.revenue_licence_id`)}`;
      } else {
        urlAttached = `${urlAttached}&revenue_licence_id=${_get(formState, `${searchFormKey}.revenue_licence_id`)}`;
      }

    }
   
   
    callApi(`${getVehicleInfoAPI.url}?${urlAttached}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, response) => {
        searchVehicleInfo(response,error)
      });

  }

  const DealerHandleKeyDown = () => {

    let urlAttached = '';   
    if (_get(formState, `${searchFormKey}.dealer_vehicle_no`) != undefined) {
      urlAttached = `vehicle_no=${_get(formState, `${searchFormKey}.dealer_vehicle_no`)}`;
    }
    if (_get(formState, `${searchFormKey}.dealer_id`) != undefined) {
      if (urlAttached === '') {
        urlAttached = `dealer_id=${_get(formState, `${searchFormKey}.dealer_id`)}`;
      } else {
        urlAttached = `${urlAttached}&dealer_id=${_get(formState, `${searchFormKey}.dealer_id`)}`;
      }
    }
    if (_get(formState, `${searchFormKey}.revenue_licence_id`) != undefined) {
      if (urlAttached === '') {
        urlAttached = `revenue_licence_id=${_get(formState, `${searchFormKey}.revenue_licence_id`)}`;
      } else {
        urlAttached = `${urlAttached}&revenue_licence_id=${_get(formState, `${searchFormKey}.revenue_licence_id`)}`;
      }
    }
   
    callApi(`${getDealerInfoAPI.url}?${urlAttached}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, response) => {
        searchDealerInfo(response,error)
      });

  }

  const searchVehicleInfo = (response, error) => {
    if (!error) {
      getVehileMetaDataFn(response.data.data.vehicle_no,response.data.data.current_license_type.code)
      formAction.changeInputFn(searchFormKey, "data", "", response.data);
      setModel(true);
    } else {
      uiAction.setFlashMessage({
        status: true,
        message: `No existing records!`,
        messageType: "error",
      });
    }
  }

  const searchDealerInfo = (response, error) => {
    if (!error) {
      formAction.changeInputFn(searchFormKey, "data", "", response.data);
      setModel(true);
    } else {
      uiAction.setFlashMessage({
        status: true,
        message: `No existing records!`,
        messageType: "error",
      });
    }
  }

  const getVehileMetaDataFn = (vehicle_No, current_license_type) => {
    callApi(`${getVehicleMetaDataAPI.url}?vehicle_no=${vehicle_No}&requested_licence_type=${current_license_type}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('vehicle-meta-data', "data", "", result.data.data);

          // _get(formState,"vehicle-meta-data.data.allow_temp",false)
          // _get(formState,"vehicle-meta-data.data.allow_re_issue",false)
          // _get(formState,"vehicle-meta-data.data.eligible",false)
          // _get(formState,"vehicle-meta-data.data.vet_validation",false)==="MAN"
          // _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")

        }
      });
  }
  return (
    <DashboardTemplate
      pageTitle="Search Vehicle / Dealer Information "
      breadCrumbList={[
        { urlTo: "", displayName: "Search Information " },
      ]}
    >
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-6">
            <UICard>
              <FormWrapper
                setGroupName={searchFormKey}
                setFormObject={{
                  option: '1'
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <RadioButtonsGroupWithState
                      dataList={[
                        {
                          value: "1",
                          label: "Vehicle",
                        },
                        {
                          value: "2",
                          label: "Dealer",
                        },
                      ]}
                      formGroupName={searchFormKey}
                      inputName={"option"}
                    />
                  </div>
                  {
                    (_get(formState, `${searchFormKey}.option`, '1')) === '1' ? (
                      <Fragment>
                        <div className="col-md-12">
                          <InputBoxWithState
                            labelText={"Vehicle Id"}
                            uiType={templateTypes.bootstrap}
                            formGroupName={searchFormKey}
                            inputName={"vehicle_no"}
                            maxLength={"20"}
                            inputPlaceholder={"Vehicle Id"}
                            onKeyDown={(event) => {
                              if (event.key === "Enter")
                                VehicleHandleKeyDown()
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <InputBoxWithState
                            labelText={"Chassis"}
                            uiType={templateTypes.bootstrap}
                            formGroupName={searchFormKey}
                            inputName={"chassis_no"}
                            maxLength={"50"}
                            inputPlaceholder={"Chassis Number"}
                            onKeyDown={(event) => {
                              if (event.key === "Enter")
                                VehicleHandleKeyDown()
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <InputBoxWithState
                            labelText={"Revenue Licence Number"}
                            uiType={templateTypes.bootstrap}
                            formGroupName={searchFormKey}
                            inputName={"revenue_licence_id"}
                            maxLength={"50"}
                            inputPlaceholder={"Revenue Licence Number"}
                            onKeyDown={(event) => {
                              if (event.key === "Enter")
                                VehicleHandleKeyDown()
                            }}
                          />
                        </div>
                        <div className="col-md-2">
                          <SubmitButton
                            btnText={"Search"}
                            formGroupName={searchFormKey}
                            tooltip={"Search"}
                            startIcon="mdi mdi-magnify-plus-outline"
                            isValidate={true}
                            onGetAPIEndPointFn={(form) => {
                              let urlAttached = '';
                              if (form.vehicle_no != undefined) {
                                urlAttached = `vehicle_no=${form.vehicle_no}`;
                              }
                              if (form.chassis_no != undefined) {
                                if (urlAttached === '') {
                                  urlAttached = `chassis_no=${form.chassis_no}`;
                                } else {
                                  urlAttached = `${urlAttached}&chassis_no=${form.chassis_no}`;
                                }
                              }
                              if (form.revenue_licence_id != undefined) {
                                if (urlAttached === '') {
                                  urlAttached = `revenue_licence_id=${form.revenue_licence_id}`;
                                } else {
                                  urlAttached = `${urlAttached}&revenue_licence_id=${form.revenue_licence_id}`;
                                }
                              }
                              return {
                                url: `${getVehicleInfoAPI.url}?${urlAttached}`,
                                key: getVehicleInfoAPI.key
                              }
                            }}
                            callApiObject={{
                              isSetHeaders: true,
                              method: "get",
                            }}
                            apiDataStoringObject={{
                              setLoader: true,
                              storingType: "none"
                            }}
                            onResponseCallBackFn={(error, response) => {

                              searchVehicleInfo(response, error)
                            }}
                          />
                        </div>
                        <div className="col-md-10">
                          <SubmitButton
                            btnText={"Reset"}
                            formGroupName={searchFormKey}
                            tooltip={"reset"}
                            startIcon="fas fa-sync-alt"
                            isValidate={false}
                            onClickBtnFn={(form) => {
                              formAction.changeInputFn(searchFormKey, "vehicle_no", "", "");
                              formAction.changeInputFn(searchFormKey, "chassis_no", "", "");
                              formAction.changeInputFn(searchFormKey, "revenue_licence_id", "", "");
                            }}
                          />
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="col-md-12">
                          <InputBoxWithState
                            labelText={"Vehicle Id"}
                            uiType={templateTypes.bootstrap}
                            formGroupName={searchFormKey}
                            inputName={"dealer_vehicle_no"}
                            maxLength={"20"}
                            inputPlaceholder={"Vehicle Id"}
                            onKeyDown={(event) => {
                              if (event.key === "Enter")
                               DealerHandleKeyDown()
                            }}
                          />
                        </div>
                        <div className="col-md-12 ">
                          <InputBoxWithState
                            labelText={"Dealer Id"}
                            uiType={templateTypes.bootstrap}
                            formGroupName={searchFormKey}
                            inputName={"dealer_id"}
                            maxLength={"20"}
                            inputPlaceholder={"Dealer Id"}
                            onKeyDown={(event) => {
                              if (event.key === "Enter")
                               DealerHandleKeyDown()
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <InputBoxWithState
                            labelText={"Revenue Licence Number"}
                            uiType={templateTypes.bootstrap}
                            formGroupName={searchFormKey}
                            inputName={"revenue_licence_id2"}
                            maxLength={"50"}
                            inputPlaceholder={"Revenue Licence Number"}
                            onKeyDown={(event) => {
                              if (event.key === "Enter")
                                DealerHandleKeyDown()
                            }}
                          />
                        </div>
                        <div className="col-md-2">
                          <SubmitButton
                            btnText={"Search"}
                            formGroupName={searchFormKey}
                            tooltip={"Search"}
                            startIcon="mdi mdi-magnify-plus-outline"
                            isValidate={false}

                            onGetAPIEndPointFn={(form) => {
                              let urlAttached = '';
                              if (form.dealer_vehicle_no != undefined) {
                                urlAttached = `vehicle_no=${form.dealer_vehicle_no}`;
                              }
                              if (form.dealer_id != undefined) {
                                if (urlAttached === '') {
                                  urlAttached = `dealer_id=${form.dealer_id}`;
                                } else {
                                  urlAttached = `${urlAttached}&dealer_id=${form.dealer_id}`;
                                }
                              }
                              if (form.revenue_licence_id != undefined) {
                                if (urlAttached === '') {
                                  urlAttached = `revenue_licence_id=${form.revenue_licence_id}`;
                                } else {
                                  urlAttached = `${urlAttached}&revenue_licence_id=${form.revenue_licence_id}`;
                                }

                              }
                              return {
                                url: `${getDealerInfoAPI.url}?${urlAttached}`,
                                key: getDealerInfoAPI.key
                              }
                            }}
                            callApiObject={{
                              isSetHeaders: true,
                              method: "get",
                            }}
                            apiDataStoringObject={{
                              setLoader: true,
                              storingType: "none"
                            }}
                            onResponseCallBackFn={(error, response) => {
                              searchDealerInfo(response, error)
                            }}
                          />
                        </div>
                        <div className="col-md-10">
                          <SubmitButton
                            btnText={"Reset"}
                            formGroupName={searchFormKey}
                            tooltip={"reset"}
                            startIcon="fas fa-sync-alt"
                            isValidate={false}
                            onClickBtnFn={(form) => {
                              formAction.changeInputFn(searchFormKey, "dealer_vehicle_no", "", "");
                              formAction.changeInputFn(searchFormKey, "dealer_id", "", "");
                              formAction.changeInputFn(searchFormKey, "revenue_licence_id2", "", "");
                            }
                            }


                          />
                        </div>
                      </Fragment>
                    )

                  }
                </div>

              </FormWrapper>
            </UICard>
          </div>
        </div>
      </div>
      <DialogPopup
        isOpen={isModel}
        isFullScreen={true}
        dialogTitle={
          (_get(formState, `${searchFormKey}.option`, '1')) === '1' ? (
            "View Vehicle Information  "
          ) : ("View Dealer Information")

        }
        isCustomButton={(_get(formState, `${searchFormKey}.option`, '1')) === '1' ? true : false}
        customButtonComponent={(_get(formState, `${searchFormKey}.option`, '1')) === '1' ? activityLog : null}
        formGroupName={searchFormKey}
        onClosePopupFn={() =>
          setModel(false)
        }
      >
        {
          (_get(formState, `${searchFormKey}.option`, '1')) === '1' ? (
            <Vehicle />

          ) : (<Dealer />)

        }
      </DialogPopup>

    </DashboardTemplate>
  );
};


export default VehicleInfo;
