
import React, { useContext } from "react";
import { PaddingLessUICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { DealerCommentSectionIcons } from "./DealerCommentSectionIcons";
import { LimitTextLength } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper } from "../../../../../ui-components/ui-elements/form/index";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { dealerCommentPostAPI } from "../../../../../../config/apiUrl.config";
const CommentsSection = ({
  formKey = "delaerComment", //CarnetComment || NormalComment
 // veId = "xxxxx-xxxx-xxxx-xxxx-xxxxxxxxxx", //Need The raw id for the api
  dealerId = "CAK-XXX", //vehicleId from vehicle search
}) => {
  const [formState, formAction] = useContext(FormContext);
  return (
    <PaddingLessUICard
      cardHeading="Comments"
      passPropsToCardHeadingTail={{
        uiType: "comments",
        formGroupName: formKey,
        dealerId: dealerId,
      }}
      cardHeadingTail={DealerCommentSectionIcons}
    >
      <FormWrapper
        elementStyle={"counterCommentWrapper"}
        setGroupName={ `${formKey}_view`}
        apiUrl={`${dealerCommentPostAPI.url}/latest/${dealerId}`} // Until Api for latest comment
        onRebuildResponseFn={(response) => {
          return {
            latestRecord: response,
            _onLoad: false,
          };
        }}
      >
        <LimitTextLength
          maxLenth={500}
          displayText={
            _get(formState, `${formKey}_view`, null)?.latestRecord?.comment
          }
        />
         
      </FormWrapper>
    </PaddingLessUICard>
  );
};

export { CommentsSection };
