/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-08 13:38:39
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-03-31 12:00:00
 */

import React ,{useContext} from "react";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { RequestType } from "../../../../../config/enum.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const emptyFn = (...para) => undefined;

const PendingWorkflow = ({ getState = {}, setState = emptyFn ,pendingWorkflowFormKey ='pending_workflow_key'}) => {
  const [formState, formAction] = useContext(FormContext);
  return (
    <DialogPopup
      isFullScreen={false}
      isCloseButton={true}
      closeButton={
        {
          btnText: 'Cancel',
          btnName: 'close_model',
          startIcon: 'far fa-times-circle',
          elementStyle: 'hiddenBtn',
          isBtnDisabled: false,
        }
      }
      isOpen={getState.showPendingWorkflow}
      onClosePopupFn={() =>
        setState({
          ...getState,
          showPendingWorkflow: false,
        })
      }
      dialogTitle={"Pending Workflows"}
    >
      <DataTable
        isSetTableHeader={true}
        tableHeaderList={[
          { displayName: "Workflow Name", key: "name", isSort: false , onPrintFn: (rawData)=>{
            return (getWorkflowname(rawData.request_type))
          }},
          { displayName: "Requested by", key: "created_by_name", isSort: false,onPrintFn: (rawData)=>{
            return rawData.created_by_name + (rawData.created_role_name ? ' ('+rawData.created_role_name+ ')' :'')
          } },
          { displayName: "Requested at", key: "created_at", isSort: false,      onPrintFn: (rawData)=>{
            return (dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM"))
          } },
          { displayName: "Requested Location", key: "created_location_name", isSort: false,onPrintFn: (rawData)=>{
            return rawData.created_location_province_code + ' ('+rawData.created_location_name+ ')'} },
        ]}
        tableBodyList={_get(formState, `${pendingWorkflowFormKey}.workflows`, [])}
        isSetTableFooter={true}
        isSetAction={false}
      />
    </DialogPopup>
  );
};

const getWorkflowname = (name)=>{
  if(!name){
    return
  }
  return Object.keys(RequestType).find(key => RequestType[key] === name).replace(/_/g, ' ');
}

export { PendingWorkflow };
