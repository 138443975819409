import React, { useContext } from 'react';
import { deliveryMethodsListAPI } from '../../../../../../config/apiUrl.config';
import { _get } from '../../../../../../helpers/common-helpers/lodash.wrappers';
import {InputBoxWithState,DatePickerWithState,SelectBoxWithState} from "../../../../../ui-components/ui-elements/form/index";
import { FormContext } from '../../../../core/context-providers/FormContext.provider';

const SearchElement = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const dataTableKey = props.dataTableKey;
    
    return (        
        <div className={"row"}>
            <div className="col-md-4">
                <InputBoxWithState
                    inputPlaceholder={"Vehicle ID"}
                    labelText={"Vehicle ID"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"vehicle_no"}
                    maxLength={50}
                />
            </div>
            <div className="col-md-4">
                <InputBoxWithState
                    inputPlaceholder={"Licence Number"}
                    labelText={"Licence Number"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"reference_number"}
                    maxLength={50}
                />
            </div>
            {dataTableKey == 'webLicencePrintListAPIKey' ? '' : 
                <div className="col-md-4">
                    <InputBoxWithState
                        inputPlaceholder={"Tracking ID"}
                        labelText={"Tracking ID"}
                        formGroupName={`${dataTableKey}_search`}
                        inputName={"tracking_id"}
                        maxLength={50}
                    />
                </div>
            }
            <div className="col-md-4">
                <SelectBoxWithState
                    labelText={"Delivery Method"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"delivery_method"}
                    apiUrl={`${deliveryMethodsListAPI.url}?sort_by=name|asc&active=true`}
                    apiStateKey={deliveryMethodsListAPI.key}
                    keyName={"id"}
                    valueName={"name"}
                />
            </div>
            <div className="col-md-4">
                <DatePickerWithState
                    labelText={"From"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"from"}
                    minDate={new Date(1970-1-1)}
                    maxDate={new Date()}
                    minDateMessage={"‘From’ should be less than or equal to ‘To’."}
                />
            </div>
            <div className="col-md-4">
                <DatePickerWithState
                    labelText={"To"}
                    formGroupName={`${dataTableKey}_search`}
                    inputName={"to"}
                    minDate={_get(formState, `${dataTableKey}_search.from`, null)}
                    maxDate={new Date()}
                    minDateMessage={"‘To’ should be greater than or equal to the ‘From’."}
                />
            </div>        
        </div>
    );
};

export {SearchElement}