/*
 * @Author:Gajani Kangesan
 * @Date: 2022-02-22 17:21:07
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:39:33
 */

import React, { useContext } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import {
      SelectBoxWithState,
      SubmitButton,
      DatePickerWithState,
      FormWrapper,
      InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { reportGenerateAPI, vftCompanyListAPI, provincesOfficesListAPI } from "../../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { CoreContext } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined
const SearchCriteria = ({
      reportCode,
      formGroupName,
      setReportgeneratedValues = emptyFn,
      onGenerateSubmit = emptyFn,
      onResetClick = emptyFn,
}) => {

      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const [coreState, coreAction] = useContext(CoreContext);
      let datefrom = new Date();
      let dateto = new Date();
      let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
      let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
      let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
      let generated_by = _get(authStatus, 'authUser.name', "")
      let role = _get(authStatus, 'authUser.rolePrefix', "")
      let vftcenterName = "All";
      let vftcenterId = "All"
      let fitnessId = "All";
      let fitnessName = "All"
      let province_code=_get(authStatus, 'authUser.pdmtCode', "") ?_get(authStatus, 'authUser.pdmtCode', ""):_get(authStatus, 'authUser.provinceCode', "")  
      const dataList = [

            {
                  id: "fail",
                  value: "Fail",
            },
            {
                  id: "pass",
                  value: "Pass",
            }

      ]
      return (
            <UICard>
                  <FormWrapper
                        setGroupName={formGroupName}
                        formGroupLinkWith={formGroupName}
                        onDestroyUnsetFormObject={true}
                        setFormObject={{
                              date_from: null,
                              date_to: null,
                              vftcenter: vftcenterId,

                        }}

                  >
                        <div className={"row"} >
                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          apiUrl={provincesOfficesListAPI.url}
                                          keyName={"id"}
                                          valueName={"province.name"}
                                          apiStateKey={provincesOfficesListAPI.key}
                                          emptySelectOptionTxt={provinceName}
                                          labelText="Province"
                                          inputName={"province"}
                                          isDisabled={true}

                                    />
                              </div>


                              <div className="col-md-6 col-sm-12 floatLeft">
                                    <DatePickerWithState
                                          isRequired={true}
                                          formGroupName={formGroupName}
                                          uiType={templateTypes.bootstrap}
                                          labelText="Date From"
                                          inputName={"date_from"}
                                          maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                                          minDate={setminDateRangeFn(_get(formState[formGroupName], "date_to", null),_get(formState[formGroupName], "date_from", null))}

                                    />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          isRequired={true}
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          emptySelectOptionTxt={""}
                                          apiUrl={`${vftCompanyListAPI.url}/list?province_code=${province_code}&sort_by=name|asc`}
                                          apiStateKey={vftCompanyListAPI.key}
                                          isEnableAllOption={true}
                                          keyName={"service.id"}
                                          valueName={"service.name"}
                                          labelText="VFT Center"
                                          inputName={"vftcenter"}

                                    />
                              </div>
                              <div className="col-md-6 col-sm-12 floatLeft">
                                    <DatePickerWithState
                                          isRequired={true}
                                          formGroupName={formGroupName}
                                          uiType={templateTypes.bootstrap}
                                          labelText="Date To"
                                          inputName={"date_to"}
                                          minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                                          maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "date_from", null))}

                                    />
                              </div>


                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          labelText="Fitness Status"
                                          inputName={"fitness"}
                                          isEnableAllOption={true}
                                          emptySelectOptionTxt={"All"}
                                          dataList={dataList}

                                    />
                              </div>


                              <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">

                                    <SubmitButton
                                          startIcon="fas fa-wrench"
                                          btnText={"Generate"}
                                          elementStyle="btnWrapper"
                                          formGroupName={formGroupName}
                                          isValidate={true}
                                          elementWrapperStyle={"searchBtn"}
                                          validationObject={{
                                                fileds: {
                                                      date_from: "Date From",
                                                      date_to: "Date To",

                                                },
                                                rules: {
                                                      date_from: "required",
                                                      date_to: "required",

                                                },
                                                message: {}
                                          }}


                                          callApiObject={{
                                                isSetHeaders: true,
                                                method: "get",
                                                multipart: false,
                                                onUpload: false,
                                                responseType: 'text'
                                          }}
                                          apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: "API_RESPONSE_LINKED_FORM",
                                                mergeToSuccessResponse: true,
                                                mergeToErrorResponse: true
                                          }}
                                          onGetAPIEndPointFn={(formObject) => {

                                                if (!isEmptyValue(formObject.date_from)) {
                                                      datefrom = dateObjectToString(new Date(formObject.date_from));
                                                }
                                                if (!isEmptyValue(formObject.date_to)) {
                                                      dateto = dateObjectToString(new Date(formObject.date_to));
                                                }
                                                if (!isEmptyValue(formObject.province)) {
                                                      provinceId = formObject.province
                                                      provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                                }
                                                // if(formObject.fitness===undefined){
                                                //       fitnessId=""
                                                //       fitnessName=""
                                                // }
                                                // else if(isEmptyValue(formObject.fitness)){
                                                //       fitnessId="All"
                                                //       fitnessName="All"
                                                // }
                                                if (!isEmptyValue(formObject.fitness)) {
                                                      fitnessId = formObject.fitness
                                                      fitnessName = dataList.find((item) => (item.id) === fitnessId)?.value
                                                      fitnessName = (fitnessName === undefined) ? "All" : fitnessName

                                                }
                                                if (!isEmptyValue(formObject.vftcenter)) {
                                                      vftcenterId = formObject.vftcenter
                                                      vftcenterName = (_get(coreState, `apiResponses.${vftCompanyListAPI.key}.result`, []).find(item => item.service.id === vftcenterId)?.service.name)
                                                      vftcenterName = (vftcenterName == null || '') ? 'All' : vftcenterName
                                                }


                                                return {
                                                      url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestFromDate=${datefrom}&report_requestToDate=${dateto}&report_requestProvince=${provinceName}&report_provinceId=${provinceId}&report_vftCenterId=${vftcenterId}&report_vftCenterName=${vftcenterName}&report_fitnessStatusName=${fitnessName}&report_fitnessStatusId=${fitnessId}&report_dateFormatDisplay=dd/MM/yyyy&report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.provinceCode', "") : _get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                                      key: `${reportGenerateAPI.key}`
                                                }
                                          }}

                                          onChangeRequestBodyFn={(formObject) => {
                                                return getDataByFormObject(formObject);
                                          }}

                                          onResponseCallBackFn={(error, response,) => {
                                                if (response?._statue) {
                                                      onGenerateSubmit(response?.data)
                                                      setReportgeneratedValues({
                                                            datefrom,
                                                            dateto,
                                                            provinceId,
                                                            provinceName,
                                                            fitnessName,
                                                            fitnessId,
                                                            vftcenterId,
                                                            vftcenterName,
                                                            limit: reportDataLimit,
                                                            offset: reportDataOffset,
                                                            reportGeneratedUser: generated_by + "-" + (roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.provinceCode', "") : _get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                                      })
                                                }


                                          }}

                                    />
                                    <InputButtonWithState
                                          btnText="Reset"
                                          startIcon="fas fa-sync-alt"
                                          elementWrapperStyle={"refreshBtn"}
                                          formGroupName={formGroupName}
                                          mergeToForm={{
                                                date_from: null,
                                                date_to: null,
                                                vftcenter: vftcenterId,
                                                province: provinceId,
                                                fitness: undefined

                                          }}
                                          onClickBtnFn={(event) => {
                                                formAction.setFormErrorFn(formGroupName);
                                                onResetClick()

                                          }}
                                    />


                              </div>
                        </div>
                  </FormWrapper>
            </UICard>


      );
};

export {
      SearchCriteria
}