/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-07 17:05:33
 */

import React, { Fragment, useContext } from 'react';
import {
    roundBtnSizes,
} from "../../../../ui-components/ui-elements/form/index";
import { RoundButtonWithPopups } from "../../../../ui-components/ui-elements/form/Button";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { ViewLicenseArrears } from './ViewLicenseArrears';
import { CommentsHistory } from './CommentHistory';
import { FormContext } from '../../../core/context-providers/FormContext.provider';

const emptyFn = (...para) => undefined;

const LicenseArrearsIcons = (props) => {
    const [formState, formAction] = useContext(FormContext);
    return (
        <Fragment>
            <RoundButtonWithPopups
                btnName="viewmore"
                iconClass={"mdi mdi-magnify-plus-outline"}
                btnSize={roundBtnSizes.small}
                tooltip={` ${props.uiType == "comments" ? "View History" : "Licence Arrears (LKR)"} `}
                dialogProps={{ dialogTitle: ` ${props.uiType == "comments" ? "Comments" : "Licence Arrears (LKR)"} `, }}
                modelComponent={
                    props.uiType == "comments" ? CommentsHistory : ViewLicenseArrears
                }
                propsToModel={props}
                isBtnDisabled={props.getVehicleStatusCode !== "ELI" ? true : false}
            />
        </Fragment>
    );
};

export { LicenseArrearsIcons };