import React, { Fragment } from "react";
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import authorized from '../../hoc-middlewares/Authorized';
import Test from "../cashier/pages/Cashier";
import {permissions} from "../../../config/permission.config";


const CashierRoutes = () => {
  return (
    <Fragment>
      <Navigate 
        path="/counter" 
        exact={true} 
        routeKey={"counter"} 
        component={authorized(Test)} 
        // routePermissions={permissions.NONE.permissions}
        routePermissions={permissions.COUNTER.permissions}
      />
    </Fragment>
  );
};

export default CashierRoutes;
