/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-08 15:48:59
 */
import React, { Fragment, useEffect } from "react";
import {
  CheckBoxWithState,
  checkBoxLabelPlacement,
  FormWrapper,
} from "../../../../ui-components/ui-elements/form/index";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { useContext } from 'react';
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const emptyFn = (...para) => undefined;

const LicencePeriodIcons = ({
  uiType = "paymentHistory",
  formGroupName = "normalLicence",
  getVehicleStatusCode = null,
  onChangeStatusFn = emptyFn,
}) => {
  useEffect(() => {
   
   
  }, []);
 
  const [formState, formAction] = useContext(FormContext);
  return (
    <FormWrapper
                setGroupName={formGroupName}
                formGroupLinkWith={formGroupName}
                onDestroyUnsetFormObject={true}
                setFormObject={{  
                  viewAll: false,
                }}

            >
      <Fragment>
        {
          (uiType !== "paymentHistory" && _get(formState, `licence_arrears_form.licence_status`, "") === "EXPIRED") ?
            (null) : (
              <CheckBoxWithState
                labelText={uiType === "paymentHistory" ? "View All" : "Reissuance"}
                formGroupName={formGroupName}
                // isDisabled={uiType !== "paymentHistory" ?(_get(formState, `pending_workflow_key.workflows`, []).length >0 ):false}
                isDisabled={(getVehicleStatusCode !== "ELI" || uiType !== "paymentHistory" && (_get(formState, `pending_workflow_key.workflows`, []).length > 0)) ? true : false}
                labelPlacement={checkBoxLabelPlacement.start}
                inputName={uiType === "paymentHistory" ? "viewAll" : "isReIssuance"}
                onChangeFn={(event) => {
                
                  // if(event.value){
                  //   formAction.changeInputFn(formGroupName, "fromDate", "", new Date());
                  //   // formAction.changeInputFn(formGroupName, "expiryDate", "", new Date());
                  // }
                  if (uiType === "paymentHistory") {
                    if(event.value){
                      formAction.changeInputFn("All", "viewAll", "", event.value);
                    }else{
                      formAction.changeInputFn("All", "viewAll", "", false);
                    }
                    
                    onChangeStatusFn();
                   // emptyFn();
                  } else {
                    onChangeStatusFn();
                  }
                }}
              />
            )
        }

      </Fragment>
      </FormWrapper>
  );
};

export { LicencePeriodIcons };
