import React, { Fragment, useContext, useEffect, useState } from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { OwnerInformation } from "./includes/OwnerInformation";
import { VehicleInformation } from "./includes/VehicleInformation";
import { Action } from "./includes/Action";
import { Reason } from "./includes/Reason";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { CoreContext } from "../../core/context-providers/CoreContext.provider";

import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { addVehicleAPI, uploadDetailSaveAPI, requestTypesListAPI, uploadDetailGetAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { RequestStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { isEmpty } from "lodash";
const today = new Date();
let reqId=''
const AddVehicleBtn = (props) => {
  const [formState] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [coreState] = useContext(CoreContext);
  const [, uiAction] = useContext(UIContext);

  const { sessionId, isCarnet } = props
  const user_role_level = _get(authStatus, "authUser.role_level_code", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ? 
  _get(authStatus, "authUser.provinceName", "<anonymous province>") : 
  ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");


  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  const approvalAction = _get(
    formState,
    "Add_Vehicle.action",
   null
  )
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={props.uiType === 'assign&Perform' ? 'Add_Vehicle' : 'add-vehicle'}
        tooltip={"Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
        } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${reqId}.`,
            messageType: "success",
          },
          failed: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request Decision Submit Failed. Please Try Again.`,
            messageType: "error",
        } : {
            // status: true,
            // message: `Approval Request Submit Failed. Please Try Again.`,
            // messageType: "error",
          },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "Add_Vehicle.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Add_Vehicle.action", "") === 'FRD' ? (_get(formState, "Add_Vehicle.forward_role", "") === 'OPT' ?
          {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
            name: "Name",
            address_line_1: "Address Line 1",
            city: "City",
            chassis_no: "Chassis",
            engine_no: "Engine",
            first_reg_date: "1st Reg",
            tare_weight: "Tare",
            gross_weight: "Gross",
            no_of_seats: "Seats",
            fuel_type: "Fuel Type",
            vehicle_class: "Class",
            province: "Province",

          } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              name: "Name",
            address_line_1: "Address Line 1",
            city: "City",
            chassis_no: "Chassis",
            engine_no: "Engine",
            first_reg_date: "1st Reg",
            tare_weight: "Tare",
            gross_weight: "Gross",
            no_of_seats: "Seats",
            fuel_type: "Fuel Type",
            vehicle_class: "Class",
            province: "Province",

          }) : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              name: "Name",
            address_line_1: "Address Line 1",
            city: "City",
            chassis_no: "Chassis",
            engine_no: "Engine",
            first_reg_date: "1st Reg",
            tare_weight: "Tare",
            gross_weight: "Gross",
            no_of_seats: "Seats",
            fuel_type: "Fuel Type",
            vehicle_class: "Class",
            province: "Province",

           
            }) : {
              "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            "effective_date": "Effective Date",
            "new_licence_type": "New Licence Type"
          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "Add_Vehicle.action", "") === 'FRD' ? (_get(formState, "Add_Vehicle.forward_role", "") === 'OPT' ? {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              forward_role: "required",
              forward_province: "required",
              forward_authority: "required",
              name: "required",
            address_line_1: "required",
            city: "required",
            chassis_no: "required",
            engine_no: "required",
            first_reg_date: "required",
            tare_weight: "required|minAmount:1",
            gross_weight: "required|minAmount:1",
            no_of_seats: "required|maxAmount:100|minAmount:0",
            fuel_type: "required",
            vehicle_class: "required",
            province: "required",
              
          } : {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              forward_role: "required",
              forward_province: "required",
              name: "required",
            address_line_1: "required",
            city: "required",
            chassis_no: "required",
            engine_no: "required",
            first_reg_date: "required",
            tare_weight: "required|minAmount:1",
            gross_weight: "required|minAmount:1",
            no_of_seats: "required|maxAmount:100|minAmount:0",
            fuel_type: "required",
            vehicle_class: "required",
            province: "required",
          }): {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              name: "required",
            address_line_1: "required",
            city: "required",
            chassis_no: "required",
            engine_no: "required",
            first_reg_date: "required",
            tare_weight: "required|minAmount:1",
            gross_weight: "required|minAmount:1",
            no_of_seats: "required|maxAmount:100|minAmount:0",
            fuel_type: "required",
            vehicle_class: "required",
            province: "required",
           
            }) : {
              "reason_id": "required",
              "remark": "required|max:500",
              "forward_province": "required",
              "forward_role": "required",
              "action": "required",
              "effective_date": "requiredIf:user_role_level,INT_PRV"
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "Add_Vehicle.action", "") === 'FRD' ? (_get(formState, "Add_Vehicle.forward_role", "") === 'OPT' ? {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              "forward_role.required": "Role is required.",
              "forward_province.required": "Province is required",
              "forward_authority.required": "Authority is required",
              "name.required": "Name is required.",
            "address_line_1.required": "Address Line 1 is required.",
            "city.required": "City is required.",
            "chassis_no.required": "Chassis is required.",
            "engine_no.required": "Engine is required.",
            "first_reg_date.required": "1st Reg is required.",
            "tare_weight.required": "Tare is required.",
            "gross_weight.required": "Gross is required.",
            "tare_weight.minAmount": "Invalid Tare.",
            "gross_weight.minAmount": "Invalid Gross.",
            "no_of_seats.required": "Seats is required.",
            "no_of_seats.minAmount": "Please enter a number between 0-100",
            "no_of_seats.maxAmount": "Please enter a number between 0-100",
            "fuel_type.required": "Fuel Type is required.",
            "vehicle_class.required": "Class is required.",
            "province.required": "Province is required.",
          } : {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              "forward_role.required": "Role is required.",
              "forward_province": "Province is required",
              "name.required": "Name is required.",
            "address_line_1.required": "Address Line 1 is required.",
            "city.required": "City is required.",
            "chassis_no.required": "Chassis is required.",
            "engine_no.required": "Engine is required.",
            "first_reg_date.required": "1st Reg is required.",
            "tare_weight.required": "Tare is required.",
            "gross_weight.required": "Gross is required.",
            "tare_weight.minAmount": "Invalid Tare.",
            "gross_weight.minAmount": "Invalid Gross.",
            "no_of_seats.required": "Seats is required.",
            "no_of_seats.minAmount": "Please enter a number between 0-100",
            "no_of_seats.maxAmount": "Please enter a number between 0-100",
            "fuel_type.required": "Fuel Type is required.",
            "vehicle_class.required": "Class is required.",
            "province.required": "Province is required.",
          }): {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              "name.required": "Name is required.",
            "address_line_1.required": "Address Line 1 is required.",
            "city.required": "City is required.",
            "chassis_no.required": "Chassis is required.",
            "engine_no.required": "Engine is required.",
            "first_reg_date.required": "1st Reg is required.",
            "tare_weight.required": "Tare is required.",
            "gross_weight.required": "Gross is required.",
            "tare_weight.minAmount": "Invalid Tare.",
            "gross_weight.minAmount": "Invalid Gross.",
            "no_of_seats.required": "Seats is required.",
            "no_of_seats.minAmount": "Please enter a number between 0-100",
            "no_of_seats.maxAmount": "Please enter a number between 0-100",
            "fuel_type.required": "Fuel Type is required.",
            "vehicle_class.required": "Class is required.",
            "province.required": "Province is required.",
         
            }) : {
              "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province": "Forward Province is required.",
            "forward_role": "Forward Role is required.",
            "action": "Action is required.",
            "effective_date.requiredIf": "Effective Date"

          },
      }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${addVehicleAPI.url}${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
            key: addVehicleAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if(props.uiType === 'assign&Perform') {
            if(_get(formState, "Add_Vehicle.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                  status: true,
                  message: `Action is required`,
                  messageType: "error",
                });

                return
          }
              return getDataByFormObject({
                ...fromObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["add_vcl"],
                current_licence_type: current_licence_type,
                // status: RequestStatus.APPROVAL_PENDING,
                pdmt_id: pdmtId,
                // authority_id: authorityId,
                ui_request_id: props.approvalRequestId,
                name: isEmptyValue(fromObject.name) ? null : fromObject.name,
                address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
                address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
                city: isEmptyValue(fromObject.city) ? null : fromObject.city,
                absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
                absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
                absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
                absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
                chassis_no: isEmptyValue(fromObject.chassis_no) ? null : fromObject.chassis_no,
                engine_no: isEmptyValue(fromObject.engine_no) ? null : fromObject.engine_no,
                first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
                tare_weight: isEmptyValue(fromObject.tare_weight) ? null : parseFloat(fromObject.tare_weight).toFixed(2),
                gross_weight: isEmptyValue(fromObject.gross_weight)
                  ? null
                  : parseFloat(fromObject.gross_weight).toFixed(2),
                no_of_seats: isEmptyValue(fromObject.no_of_seats)
                  ? null
                  : parseInt(fromObject.no_of_seats),
                vehicle_reg_condition_id: isEmptyValue(fromObject.vehicle_reg_condition) ? null : fromObject.vehicle_reg_condition,
                fuel_type_id: isEmptyValue(fromObject.fuel_type) ? null : fromObject.fuel_type,
                vehicle_class_id: isEmptyValue(fromObject.vehicle_class) ? null : fromObject.vehicle_class,
                province_id: isEmptyValue(fromObject.province) ? null : fromObject.province,
                manufactured_year: isEmptyValue(fromObject.manufactured_year) ? null : new Date(fromObject.manufactured_year).getFullYear(),
                cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                  ? null
                  : parseFloat(fromObject.cylinder_capacity).toFixed(2),
                tyre_size: isEmptyValue(fromObject.tyre_size)
                  ? null
                  : fromObject.tyre_size,
                wheel_base: isEmptyValue(fromObject.wheel_base)
                  ? null
                  : parseFloat(fromObject.wheel_base).toFixed(2),
                type_of_body: isEmptyValue(fromObject.type_of_body) ? null : fromObject.type_of_body,
                make: isEmptyValue(fromObject.make) ? null : fromObject.make,
                model: isEmptyValue(fromObject.model) ? null : fromObject.model,
                color_1: isEmptyValue(fromObject.color_1) ? null : fromObject.color_1,
                color_2: isEmptyValue(fromObject.color_2) ? null : fromObject.color_2,
                color_3: isEmptyValue(fromObject.color_3) ? null : fromObject.color_3,
                reason_id: formState['Add_Vehicle-new'].reason_id,
                counter_session_id: sessionId ? sessionId : null,
                forward_authority_service_id: serviceId,
                document_details: [],
                action: fromObject.action == 'FRD' ? {
                  action: fromObject.action,
                  remark: fromObject.remark,
                  forward_province: fromObject.forward_province,
                  forward_role: fromObject.forward_role,
                  forward_authority: fromObject.forward_authority
                } : {
                  action: fromObject.action,
                  remark: fromObject.remark,
                },
              });
            
           
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["add_vcl"],
              current_licence_type: isCarnet ? 'CA' : 'NO',
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              ui_request_id: fromObject.request_id,
              name: isEmptyValue(fromObject.name) ? null : fromObject.name,
              address_line_1: isEmptyValue(fromObject.address_line_1) ? null : fromObject.address_line_1,
              address_line_2: isEmptyValue(fromObject.address_line_2) ? null : fromObject.address_line_2,
              city: isEmptyValue(fromObject.city) ? null : fromObject.city,
              absolute_owner_name: isEmptyValue(fromObject.absolute_owner_name) ? null : fromObject.absolute_owner_name,
              absolute_owner_address_line_1: isEmptyValue(fromObject.absolute_owner_address_line_1) ? null : fromObject.absolute_owner_address_line_1,
              absolute_owner_address_line_2: isEmptyValue(fromObject.absolute_owner_address_line_2) ? null : fromObject.absolute_owner_address_line_2,
              absolute_owner_city: isEmptyValue(fromObject.absolute_owner_city) ? null : fromObject.absolute_owner_city,
              chassis_no: isEmptyValue(fromObject.chassis_no) ? null : fromObject.chassis_no,
              engine_no: isEmptyValue(fromObject.engine_no) ? null : fromObject.engine_no,
              first_reg_date: isEmptyValue(fromObject.first_reg_date) ? today : fromObject.first_reg_date,
              tare_weight: isEmptyValue(fromObject.tare_weight) ? null : parseFloat(fromObject.tare_weight).toFixed(2),
              gross_weight: isEmptyValue(fromObject.gross_weight)
                ? null
                : parseFloat(fromObject.gross_weight).toFixed(2),
              no_of_seats: isEmptyValue(fromObject.no_of_seats)
                ? null
                : parseInt(fromObject.no_of_seats),
              vehicle_reg_condition_id: isEmptyValue(fromObject.vehicle_reg_condition) ? null : fromObject.vehicle_reg_condition,
              fuel_type_id: isEmptyValue(fromObject.fuel_type) ? null : fromObject.fuel_type,
              vehicle_class_id: isEmptyValue(fromObject.vehicle_class) ? null : fromObject.vehicle_class,
              province_id: isEmptyValue(fromObject.province) ? null : fromObject.province,
              manufactured_year: isEmptyValue(fromObject.manufactured_year) ? new Date().getFullYear() : new Date(fromObject.manufactured_year).getFullYear(),
              cylinder_capacity: isEmptyValue(fromObject.cylinder_capacity)
                ? null
                : parseFloat(fromObject.cylinder_capacity).toFixed(2),
              tyre_size: isEmptyValue(fromObject.tyre_size)
                ? null
                : fromObject.tyre_size,
              wheel_base: isEmptyValue(fromObject.wheel_base)
                ? null
                : parseFloat(fromObject.wheel_base).toFixed(2),
              type_of_body: isEmptyValue(fromObject.type_of_body) ? null : fromObject.type_of_body,
              make: isEmptyValue(fromObject.make) ? null : fromObject.make,
              model: isEmptyValue(fromObject.model) ? null : fromObject.model,
              color_1: isEmptyValue(fromObject.color_1) ? null : fromObject.color_1,
              color_2: isEmptyValue(fromObject.color_2) ? null : fromObject.color_2,
              color_3: isEmptyValue(fromObject.color_3) ? null : fromObject.color_3,
             
              reason_id: fromObject.reason_id,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }
          
        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === 'assign&Perform' ? "put" : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          if (!isEmpty(response.data.errors)) {
            if (props.uiType !== 'assign&Perform') {
              if (response.data.errors.details[0].message==="Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if(props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
          } else {
              console.log('REE,', response);
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
          }
          }
        }}
      />
    </Fragment>
  );
};

const AddVehicleUI = (props) => {
  const formKey = props.formKey;
  const [formState] = useContext(FormContext);
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );  
  const [requestId, setRequestId] = useState(null);
  const uiType = props.uiType;
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8))
  }, [])

  reqId = requestId

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 defaultPaddingTop defaultPaddingBottom defaultDisplayLabel">Vehicle ID: {uiType  === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}</div>
      <div className="col-md-6 col-sm-12 paddingLeftNone">
        <div className="col-md-12 col-sm-12">
          <OwnerInformation formKey={formKey} {...props} />
        </div>
        <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
          <Reason filterKey={'add_vehicle'} isHalfWidth={true} requestType={uiType} 
                        dataSubmittedForApproval={props.dataSubmittedForApproval} 
                        formKey={uiType === 'APR' ? `${formKey}-new` : formKey} />
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <VehicleInformation uiType={uiType}  formKey = {formKey} requestId={requestId} {...props} />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType  === 'APR' ?  `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType  === 'APR' ?  props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <Action formKey={formKey} uiType={uiType} 
                            dataSubmittedForApproval={props.dataSubmittedForApproval} />
      </div>
    </div>
  );
};

const AddVehicle = () => {
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Add Vehicle" },
      ]}
      pageTitle="Add Vehicle"
    >
      <AddVehicleUI />
    </DashboardTemplate>
  );
};

export default AddVehicle;
export { AddVehicleUI, AddVehicleBtn };
