/*
 * @Author:Gajani Kangesan
 * @Date: 2022-02-18 11:31:20
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:21:27
 */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements"
import {
      SelectBoxWithState,
      SubmitButton,
      FormWrapper,
      InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { _getInt, _get, } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import { templateTypes } from "../../../../../../../config/template.config";
import { reportGenerateAPI, provincesOfficesListAPI, AuthorityListApi } from "../../../../../../../config/apiUrl.config";
import { reportGenerationMaxRangeMonths, reportDataOffset, reportDataLimit } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { YearMonthPickerWithState } from "../../../../../../ui-components/ui-elements/form/DatePicker";
import { CoreContext, apiResponseStoringType } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined


const SearchCriteria = ({
      reportCode,
      formGroupName,
      setReportgeneratedValues = emptyFn,
      onGenerateSubmit = emptyFn,
      onResetClick = emptyFn,
}) => {

      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const [coreState, coreAction] = useContext(CoreContext);
      let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
      let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
      let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
      let authorityId = roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.authorityId', ""):"All";
      let provinceCode =roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtCode', ""):_get(authStatus, 'authUser.pdmtId.code', "");
      let generated_by = _get(authStatus, 'authUser.name', "")
      let role = _get(authStatus, 'authUser.rolePrefix', "")
      let authorityName = 'All';
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;

      const [getdatalist, setDatalist] = useState(" ");
      useEffect(() => {

            coreAction
                  .sendRequestFn(
                        `${AuthorityListApi.url}?province_code=${provinceCode}&sort_by=name|asc`
                  )
                  .method("get")
                  .setInitStoring(apiResponseStoringType.setResponse, {
                        responseKey: AuthorityListApi.key,
                  })
                  .headers(true)
                  .send((error, result) => {
                        if (!error) {
                              if (roleLevelCode === "INT_AU") {
                                    setDatalist(result.data.data.filter((item) => item.id === authorityId));

                              }
                              else {
                                    setDatalist(result.data.data);
                              }

                        }
                  });

      }, []);

       return (
            <Fragment>
                  <UICard>
                        <FormWrapper setGroupName={formGroupName}
                              formGroupLinkWith={formGroupName}
                              onDestroyUnsetFormObject={true}
                              setFormObject={{
                                    year_month: null,
                                    authority:roleLevelCode === "INT_AU" ?null:authorityId,

                              }}>

                              <div className="row d-flex align-items-center justify-content-center">
                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <SelectBoxWithState
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                apiUrl={provincesOfficesListAPI.url}
                                                keyName={"id"}
                                                valueName={"province.name"}
                                                apiStateKey={provincesOfficesListAPI.key}
                                                emptySelectOptionTxt={provinceName}
                                                labelText="Province"
                                                inputName={"province"}
                                                isDisabled={true}

                                          />
                                    </div>

                                    <div className="col-md-6 col-sm-12 floatLeft">

                                          <YearMonthPickerWithState
                                                isRequired={true}
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                labelText="Year & Month"
                                                inputName={"year_month"}
                                                maxDate={setMaxDateFn(_get(formState[formGroupName], "dateTo", null))}
                                                minDate={setminDateRangeFn(_get(formState[formGroupName], "dateTo", null))}
                                          />


                                    </div>

                              </div>

                              <div class="row">
                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <SelectBoxWithState
                                                isRequired={true}
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                emptySelectOptionTxt={" "}
                                                dataList={getdatalist}
                                                isEnableAllOption={roleLevelCode === "INT_AU" ? false : true}
                                                // apiUrl={`${AuthorityListApi.url}?province_code=${provinceCode}&sort_by=name|asc`}
                                                // apiStateKey={AuthorityListApi.key}
                                                keyName={"id"}
                                                valueName={"service.name"}
                                                labelText="Authority"
                                                inputName={"authority"}
                                                onChangeFn={(value) => {
                                                      formAction.changeInputFn(formGroupName, "counter", null);
                                                }}
                                          />

                                    </div>
                                    <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">
                                          <SubmitButton
                                                btnName={"generate"}
                                                btnText={"Generate"}
                                                startIcon="fas fa-wrench"
                                                formGroupName={formGroupName}
                                                isValidate={true}
                                                validationObject={{
                                                      fileds: {
                                                            authority: "Authority",
                                                            year_month: "Year & Month",

                                                      },
                                                      rules: {
                                                            authority: roleLevelCode === "INT_AU"  ? "required" :"alphaSpecial",
                                                            year_month: "required",

                                                      },
                                                      message: {}
                                                }}
                                                callApiObject={{
                                                      isSetHeaders: true,
                                                      method: "get",
                                                      multipart: false,
                                                      onUpload: false,
                                                      responseType: 'text'
                                                }}
                                                apiDataStoringObject={{
                                                      setLoader: true,
                                                      storingType: "API_RESPONSE_LINKED_FORM",
                                                      mergeToSuccessResponse: true,
                                                      mergeToErrorResponse: true
                                                }}
                                                onGetAPIEndPointFn={(formObject) => {
                                                      if (!isEmptyValue(formObject.province)) {
                                                            provinceId = formObject.province
                                                            provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                                      }
                                                      if (!isEmptyValue(formObject.authority)) {
                                                            authorityId = formObject.authority
                                                            authorityName = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.name)
                                                            authorityName = (authorityName === undefined )? "All" : authorityName
                                                      }
                                                      if (!isEmptyValue(formObject.year_month)) {
                                                            year = formObject.year_month.getFullYear();

                                                      }
                                                      if (!isEmptyValue(formObject.year_month)) {
                                                            month = formObject.year_month.getMonth() + 1;

                                                      }



                                                      return {
                                                            url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestProvince=${provinceName}&report_provinceId=${provinceId}&report_authorityId=${authorityId}&report_requestAuthority=${authorityName}&report_requestYear=${year}&report_requestMonth=${month}&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                                            key: `${reportGenerateAPI.key}`
                                                      }
                                                }}

                                                onChangeRequestBodyFn={(formObject) => {
                                                      return getDataByFormObject(formObject);
                                                }}

                                                onResponseCallBackFn={(error, response,) => {
                                                      if (response?._statue) {
                                                            onGenerateSubmit(response?.data)
                                                            setReportgeneratedValues({
                                                                  provinceId,
                                                                  provinceName,
                                                                  authorityId,
                                                                  authorityName: authorityName,
                                                                  year,
                                                                  month,
                                                                  limit: reportDataLimit,
                                                                  offset: reportDataOffset,
                                                                  reportGeneratedUser:generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                                            })
                                                      }


                                                }}
                                          />

                                          <InputButtonWithState
                                                btnText="Reset"
                                                startIcon="fas fa-sync-alt"
                                                elementWrapperStyle={"refreshBtn"}
                                                formGroupName={formGroupName}
                                                mergeToForm={{
                                                      province: null,
                                                      authority:roleLevelCode === "INT_AU" ?null:authorityId,
                                                      year_month: null

                                                }}
                                                onClickBtnFn={(event) => {
                                                      formAction.setFormErrorFn(formGroupName);
                                                      onResetClick()

                                                }}
                                          />
                                    </div>
                              </div>
                              <br />


                        </FormWrapper>
                  </UICard>
            </Fragment>
      )
}

export {
      SearchCriteria
}
