/*
 * @Author: sureshg
 * @Date: 2021-01-06 16:09:43
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-09-22 20:42:52
 */


import React, { useState, Fragment, useEffect, useContext } from 'react';
import {
  FormWrapper, InputBoxWithState,
  InputButton
} from "../../../../ui-components/ui-elements/form/index";
import { EditInsuranceCompany, CustomButtonEdit } from "../includes/EditInsuranceCompany";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  DataTable,
  actionBtnTypes,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { SubmitButton } from "../../../../ui-components/ui-elements/form/Button";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { ProvincesPrefixMatcher } from "../../../../../config/enum.config"
import { getDealerDetailsAPI, DealerLicenceFeeAPI, dealerPaymentHistoryAPI, availableSessionListApi, licencePrintLicenceCount, getDealerCurrentValidlicenceDetailsAPI, getDealerPendingVehicleDetailsAPI, getDealerVehicleDetailsAPI, getDealerVehicleDetailsOrganisationServiceAPI, getAllTask } from "../../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue, numberThousandSeperator } from "../../../../../helpers/common-helpers/common.helpers"
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import RequestForBtn from "./includes/RequestForBtn";
import { PendingWorkflow } from "../includes/PendingWorkflow";
import { CommentsSection } from "./includes/CommentsSection";
import { PaymentHistory } from '../includes/PaymentHistory';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { DealerLicenceFee } from './includes/DealerLicenceFee';
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { LicenceReprintPreview } from "../includes/LicenceReprintPreview";
import { useLicencePrint } from '../../../../hooks/common-hooks/usePrinter.hook';
import { DealerSpecialMessage } from './includes/DealerSpecialMessage';
import { isEmpty } from "lodash";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { callApi } from '../../../../../helpers/common-helpers/callApi.helpers';
import LicenceReceiptPrint from '../../../payment/pages/includes/LicenceReceiptPrint';

const emptyFun = (...para) => undefined;



const tableHeaderList = [
  // { displayName: "Tx. No", key: "txNo", isSort: false },
  {
    displayName: "Tx. Date & Time",
    key: "created_date_time",
    isSort: false,
    onPrintFn: (rawData) => dateObjectToString(rawData.created_date_time, 'dd/mm/YY HH:MM')
  },
  {
    displayName: "Total Amount",
    key: "totalAmount",
    isSort: false,
    isRightAlign: true,
    onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.total_amount).toFixed(2))
  },
  {
    displayName: "",
    key: "",
    isSort: false,
    default: ""
  },
  {
    displayName: "Province",
    key: "pdmt",
    isSort: false,
  },
  {
    displayName: "Authority",
    key: "authority",
    isSort: false,
  },
]
const DealerLicense = ({ setShowHideFooterFN = emptyFun, sessionId, counterId }) => {
  const searchFormKey = "dealer_search_form";
  const pendingWorkflowFormKey = "pending_workflow_key";
  const initialStateForGetState = {
    onPageLoad: true,
    currentObject: {},
    isDuplicateVehicle: false,
    showPendingWorkflow: false,
    pendingWorkflows: [],
    isNoRecordFound: false
  };
  const [getState, setState] = useState(initialStateForGetState);

  const [, uiAction] = useContext(UIContext);
  const formKey = "dealerlicence"
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const [coreState, coreAction] = useContext(CoreContext);
  const issuedPdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const user_id = _get(authStatus, "authUser.id", "");
  const refreshMainScreenDataFn = () => {
    formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
    formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", true);
    coreAction
      .sendRequestFn(`${getAllTask.url}?dealer_id=${_get(formState, `${searchFormKey}.searchKey`, "")}&assigned_user_id=notnull&status=PEN&sort_by=created_at|desc`)
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: pendingWorkflowFormKey,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          setState({ ...getState, pendingWorkflows: result.data })
          let count = _get(result, "data.data", []).filter(item => item.request_type === "add_dlr")
          formAction.changeInputFn(pendingWorkflowFormKey, "workflows", "", result.data.data);
          formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", false);
          formAction.changeInputFn(pendingWorkflowFormKey, "addCount", "", count);
        } else {
          formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", false);
          console.log('ERRRRRRR===', error);
        }
      });



    formAction.changeInputFn("pending-licence", "_onLoad", "", true);
    coreAction
      .sendRequestFn(
        `${getDealerPendingVehicleDetailsAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}`
      )
      .method("get")
      .headers(true)
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: getDealerPendingVehicleDetailsAPI.key,
      })
      .send((error, result) => {
        let count = 0;
        if (!error) {

          _get(result, "data.data", []).forEach(value => {
            if (value.insurance_company !== null) {
              count += 1;
            }

          })

          formAction.changeInputFn("pending-licence", "data", "", result.data.data);
          formAction.changeInputFn("pending-licence", "count", "", count);
        }
        else {
          formAction.changeInputFn("pending-licence", "data", "", []);
          formAction.changeInputFn("pending-licence", "count", "", 0);
        }
        formAction.changeInputFn("pending-licence", "_onLoad", "", false);

      });
    formAction.changeInputFn("dealer_charges_form", "_onLoad", "", true);

    callApi(`${DealerLicenceFeeAPI.url}?dealer_id=${_get(formState, `${searchFormKey}.searchKey`, "")}&issued_pdmt_id=${issuedPdmtId}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {

          let totAddition = 0;
          let totdeduction = 0;
          let totFee = 0;
          let serviceCharge = 0;
          let payableAddition = 0;

          _get(result, "data.data.additons_deductions_total", []).forEach(value => {
            if (value.charge_effect_code === "DED") {
              totdeduction += value.total

            }
            if (value.charge_effect_code === "ADD") {
              totAddition += value.total

            }

          });


          totFee = _get(result, "data.data.fee_total", "");
          serviceCharge = _get(result, "data.data.service_charge_total", "");




          payableAddition = serviceCharge + totAddition;
          let total = (totFee + totAddition + serviceCharge - totdeduction);

          // else {
          //     totCurrentLicenceFee = _get(result, "data.data.fee", 0)
          // }
          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("dealer_charges_form", "data", "", result.data.data);
          formAction.changeInputFn("dealer_charges_form", "totFee", "", totFee.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "payableAddition", "", payableAddition.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "totAddition", "", totAddition.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "totdeduction", "", totdeduction.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "total", "", total.toFixed(2));
          formAction.changeInputFn("dealer_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("dealer_charges_form", "data", "", []);
          formAction.changeInputFn("dealer_charges_form", "totFee", "", 0);
          formAction.changeInputFn("dealer_charges_form", "payableAddition", "", 0);
          formAction.changeInputFn("dealer_charges_form", "serviceCharge", "", 0);
          formAction.changeInputFn("dealer_charges_form", "totAddition", "", 0);
          formAction.changeInputFn("dealer_charges_form", "totdeduction", "", 0);
          formAction.changeInputFn("dealer_charges_form", "total", "", 0);
        }
        formAction.changeInputFn("dealer_charges_form", "_onLoad", "", false);
      });

    callApi(`${availableSessionListApi.url}/${user_id}/sessions/list`)
      .method("get")
      .headers(true)
      .body({})
      .isMultipart(false)
      .send(async (error, result) => {
        if (!error) {
          const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");
          formAction.changeInputFn("counter-serial", "id", "", next_serial);
          formAction.changeInputFn("counter-serial", "active", "", _get(result, "data.data[0].counter_id.active", ""));
        }
      });

  }

  const handleKeyDown = (event) => {
    coreAction.sendRequestFn(`${getDealerDetailsAPI.url}/${event}`)
    .isMultipart(false)
    .method("get")
    .setLoader(true)
    // .setInitStoring("none", null)
    .setInitStoring(apiResponseStoringType.setResponse, {
      responseKey:  getDealerDetailsAPI.key,
    })
    .headers(true)
    .send((error, response) => {
      if (!error) {
        //to refresh workflows
        refreshMainScreenDataFn()

        formAction.changeInputFn(searchFormKey, "data", "", response.data.data);
        setState(prevState => {
          return {
            ...prevState,
            currentObject: response.data.data,
            onPageLoad: false
          };
        });

        return

      }
      else {
        let errorToPop = 'Dealer not found!';

        uiAction.setFlashMessage({
          status: true,
          message: errorToPop,
          messageType: "error",
        });
        setState(prevState => {
          return {
            ...prevState,
            currentObject: null,
            onPageLoad: false,
            isNoRecordFound: true,
          };
        });

      }
    });


    // callApi(`${getDealerDetailsAPI.url}/${event}`)
    //   .method("get")
    //   .headers(true)
    //   .isMultipart(false)
    //   .send((error, response) => {
    //     if (!error) {
    //       //to refresh workflows
    //       refreshMainScreenDataFn()

    //       formAction.changeInputFn(searchFormKey, "data", "", response.data.data);
    //       setState(prevState => {
    //         return {
    //           ...prevState,
    //           currentObject: response.data.data,
    //           onPageLoad: false
    //         };
    //       });

    //       return

    //     }
    //     else {
    //       let errorToPop = 'Dealer not found!';

    //       uiAction.setFlashMessage({
    //         status: true,
    //         message: errorToPop,
    //         messageType: "error",
    //       });
    //       setState(prevState => {
    //         return {
    //           ...prevState,
    //           currentObject: null,
    //           onPageLoad: false,
    //           isNoRecordFound: true,
    //         };
    //       });

    //     }
    //   })

  }

  // for after payment Success  
  useEffect(() => {
    formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", true);

    if (_get(formState, `${searchFormKey}.shouldDealerRefresh`, false)) {
      // formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
      formAction.changeInputFn('dealer_search_form', "data", "", undefined)
      formAction.changeInputFn('dealer_charges_form', "", "", undefined)
      setState(initialStateForGetState);
      window.scrollTo(0, 0);
      formAction.changeInputFn("dealer_search_form", "shouldDealerRefresh", "", false);
    }

  }, [_get(formState, `${searchFormKey}.shouldDealerRefresh`, false)]);


  return (
    <div className="normalLicenseWrapper ">
      <div className="topWrapper">
        <div className="fullWidthDiv">
          <div className="col-md-6 floatLeft">
            <div className="searchWrapper">
              <FormWrapper elementStyle={""} setGroupName={searchFormKey}>
                <div className="searchInputWrapper">
                  <label className="control-labe">Dealer ID: <span style={{ color: "red" }}>*</span></label>
                  <InputBoxWithState
                    inputName={"searchKey"}
                    maxLength='20'
                    formGroupName={searchFormKey}
                    elementStyle="marginTopBottomNone"
                    onChangeTxtFn={(eventData) => {
                      formAction.changeInputFn(searchFormKey, "data", "", undefined)
                      setState(initialStateForGetState);;
                      //To chaNge to Upper Case and not allowing whitespaces
                      if (eventData) {
                        formAction.changeInputFn(searchFormKey, "searchKey", "", eventData.value.toUpperCase()?.trim());
                      }

                    }}
                    elementWrapperStyle={"searchInputBoxWrapper"}
                    onFocus={(event) => event.target.select()}
                    onKeyDown={(event) => {
                      if (event.key === "Enter")
                        handleKeyDown(event.target.value)
                    }}
                  />
                  <SubmitButton
                    btnText={"Search"}
                    formGroupName={searchFormKey}
                    tooltip={"Search"}
                    startIcon="mdi mdi-magnify-plus-outline"
                    isValidate={true}
                    validationObject={{
                      fileds: {
                        "searchKey": "Dealer Id",
                      },
                      rules: {
                        "searchKey": "required",
                      },
                      message: {}
                    }}
                    onChangeRequestBodyFn={(formObject) => {
                      formAction.changeInputFn(searchFormKey, "data", "", undefined);
                      setState(initialStateForGetState);
                      return getDataByFormObject(formObject);
                    }}
                    onGetAPIEndPointFn={(formState) => {
                      return {
                        url: `${getDealerDetailsAPI.url}/${formState.searchKey}`,
                        key: getDealerDetailsAPI.key
                      }
                    }}
                    callApiObject={{
                      isSetHeaders: true,
                      method: "get",
                    }}
                    apiDataStoringObject={{
                      setLoader: true,
                      storingType: "none"
                    }}
                    onResponseCallBackFn={(error, response) => {
                      if (!error) {
                        //to refresh workflows
                        refreshMainScreenDataFn()

                        formAction.changeInputFn(searchFormKey, "data", "", response.data.data);
                        
                        setState(prevState => {
                          return {
                            ...prevState,
                            currentObject: response.data.data,
                            onPageLoad: false
                          };
                        });

                      }
                      else {
                        let errorToPop = 'Dealer not found!';

                        uiAction.setFlashMessage({
                          status: true,
                          message: errorToPop,
                          messageType: "error",
                        });
                        setState(prevState => {
                          return {
                            ...prevState,
                            currentObject: null,
                            onPageLoad: false,
                            isNoRecordFound: true,
                          };
                        });

                      }
                    }}
                  />
                </div>
              </FormWrapper>
            </div>
          </div>
          {(getState.currentObject !== null ||
            getState.currentObject !== "none") &&
            getState.onPageLoad !== true ? (
            <div className="statusWrapper">
              <ul className="statusUL">
                {getState.onPageLoad !== true ? (<FormWrapper
                  elementStyle="btnFormWrapper"
                  setGroupName={pendingWorkflowFormKey}
                  apiUrl={`${getAllTask.url}?dealer_id=${_get(formState, `${searchFormKey}.searchKey`, "")}&status=PEN&assigned_user_id=notnull&sort_by=created_at|desc`}
                  onRebuildResponseFn={(response) => {
                    setState({ ...getState, pendingWorkflows: response })
                    let count = response.filter(item => item.request_type === "add_dlr")
                    return {
                      workflows: response,
                      addCount: count,
                      "_onLoad": false
                    }
                  }}
                >

                  <li className="setBorder">
                    {!isEmpty(_get(formState, 'dealer_search_form.searchKey', null)?.trim()) &&
                      <InputButton
                        btnName="pendingWorkflow"
                        isBtnDisabled={_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length === 0}
                        btnText={"Pending Workflows"}
                        onClickBtnFn={() =>
                          setState({
                            ...getState,
                            showPendingWorkflow: true,
                          })
                        }
                      />}
                  </li>

                </FormWrapper>
                ) : null}
                <li>
                  {(_get(formState, "pending_workflow_key._onLoad", false) === false 
                    && _get(formState, `${pendingWorkflowFormKey}.addCount`, []).length === 0) &&
                    <RequestForBtn sessionId={sessionId} counterId={counterId} refreshMainScreenDataFn={() => refreshMainScreenDataFn()} />}
                </li>
              </ul>
            </div>

          ) : null}
        </div>
      </div>
      {
        (Object.keys(_get(formState, `${searchFormKey}.data`, {})).length > 0) ? (
          <div className="fullWidthDiv">
            <FormWrapper
              setGroupName={"dealerLicense"}
              elementStyle={"normalLicenceWrapper"}
            >
              <div className="col-md-6 floatLeft">

                <DealerSpecialMessage dealerId={_get(formState, `${searchFormKey}.searchKey`, "")} />
                <div className="fullWidthDiv">
                  <PaddingLessUICard cardHeading="Dealer's Information">
                    <div className="col-md-6 defaultHalfMarginBottom">
                      <strong>Name of Dealer/ Manufacturer/ Repairer : </strong>
                      <span>
                        {_get(formState, `${searchFormKey}.data.dealer_name`, "")}
                      </span>
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <strong> Dealer’s Office : </strong>
                      <hr className="marginTopNone defaultHalfMarginBottom" />
                      <div className="row">
                        <div className="col-md-6">
                          <strong>Line 1 : </strong>{""}
                          {_get(formState, `${searchFormKey}.data.office_address.line1`, "")}
                        </div>
                        <div className="col-md-6">
                          <strong>Line 2 : </strong>{""}
                          {_get(formState, `${searchFormKey}.data.office_address.line2`, "")}
                        </div>
                        <div className="col-md-6">
                          <strong>City : </strong>  {_get(formState, `${searchFormKey}.data.office_address.city`, "")}
                        </div>
                        <div className="col-md-6">
                          <strong>District : </strong>  {_get(formState, `${searchFormKey}.data.office_address.district.name`, "")}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <strong>Dealer's Address : </strong>
                      <hr className="marginTopNone defaultHalfMarginBottom" />
                      <div className="row">
                        <div className="col-md-6">
                          <strong>Line 1 : </strong>{""}
                          {_get(formState, `${searchFormKey}.data.dealer_address.line1`, "")}
                        </div>
                        <div className="col-md-6">
                          <strong>Line 2 : </strong>{""}
                          {_get(formState, `${searchFormKey}.data.dealer_address.line2`, "")}
                        </div>
                        <div className="col-md-6">
                          <strong>City : </strong>  {_get(formState, `${searchFormKey}.data.dealer_address.city`, "")}
                        </div>
                        <div className="col-md-6">
                          <strong>District : </strong>  {_get(formState, `${searchFormKey}.data.dealer_address.district.name`, "")}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <strong>Province : </strong> {_get(formState, `${searchFormKey}.data.dealer_address.province.name`, "")}
                    </div>
                  </PaddingLessUICard>
                </div>

                <div className="fullWidthDiv defaultHalfMarginBottom">
                  <PaymentHistory
                    apiUrl={`${dealerPaymentHistoryAPI.url}/${_get(formState, `${searchFormKey}.data.id`, "")}`}
                    dataTableKey={dealerPaymentHistoryAPI}
                    tableHeaderList={tableHeaderList}
                    licenceType={"dealer-licence"}
                    formKey="dealerLicence"
                    isView={true}
                    category={"DT"}
                    isReprint={true}
                  />

                </div>


                <div className="fullWidthDiv defaultHalfMarginBottom">
                  <CommentsSection
                    formKey="carnetComment"
                    dealerId={_get(formState, `${searchFormKey}.data.id`, "")}
                  ></CommentsSection>
                </div>
              </div>

              <div className="col-md-6 floatRight ">
                <div className="fullWidthDiv defaultHalfMarginBottom">
                  <PaddingLessUICard cardHeading="Pending Licence">
                    <DataTable
                      apiUrl={`${getDealerPendingVehicleDetailsAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}`}
                      dataTableKey={"dealer_pending_licence"}
                      elementWrapperStyle={"pendinLicenceTable"}
                      tableHeaderList={[
                        {
                          displayName: "Authorized Vehicle No.",
                          key: "vehicle_number",
                          isSort: true,
                        },

                        {
                          displayName: "Validity Period (From -To)",
                          key: "start_date",
                          isSort: false,
                          onPrintFn: (rawData) => {
                            return ((rawData.validity_from && rawData.validity_to) ? (dateObjectToString(rawData.validity_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.validity_to, "dd/mm/YY"))) : null)
                          }
                        },
                        {
                          displayName: "Insurance Company",
                          key: "insurance_company.service.name",
                          isSort: false,
                        },
                        {
                          displayName: "Insurance Expiry Date",
                          key: "insurance_expire_date",
                          isSort: false,
                          onPrintFn: (rawData) => {
                            if (rawData.insurance_expire_date != null) {
                              return (dateObjectToString(rawData.insurance_expire_date, "dd/mm/YY"))
                            }
                            else {
                              return ("-")
                            }
                          },

                        },
                        {
                          displayName: "Request ID",
                          key: "request_id",
                          isSort: false,

                        },
                        {
                          displayName: "Requested by",
                          key: "requested_by",
                          isSort: false,
                          onPrintFn: (rawData) => (rawData.requested_by !== "-" && rawData.request_role_name !== "-") ? (rawData.requested_by + "(" + rawData.request_role_name + ")") : "-"
                        },
                        {
                          displayName: "Requested Location",
                          key: "requested_location",
                          isSort: false,
                          onPrintFn: (rawData) => (rawData.requested_province !== "-" ? ProvincesPrefixMatcher[rawData.requested_province] : "") + (rawData.requested_location !== "-" ? ("(" + rawData.requested_location + ")") : "")
                        },
                      ]}
                      //tableBodyList={_get(formState, `pending-licence.data`, [])}

                      isSetAction={true}
                      actionTypeList={[
                        {
                          actionType: actionBtnTypes.edit,
                          modelContentElement: EditInsuranceCompany,
                          dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Edit Insurance Company",
                            closeButton: {
                              btnText: "Cancel",
                              startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: CustomButtonEdit,
                            customButtonComponentProps: { refreshMainScreenAfterPerformFn: refreshMainScreenDataFn, btnText: "Update", uiType: "edit" },


                          },

                        }
                      ]}

                      isSetTableFooter={true}
                      isSetTableHeader={false}


                    />
                  </PaddingLessUICard>

                </div>


                <div className="fullWidthDiv defaultHalfMarginBottom">
                  <PaddingLessUICard cardHeading="Current Valid Licences Information">
                    <DataTable
                      // elementWrapperStyle={"scrolTable"}
                      apiUrl={`${getDealerCurrentValidlicenceDetailsAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}?is_with_licence_id=true&is_with_valid_licence=true&sort_by=vehicle_number|asc,validity_from|desc`}
                      dataTableKey={getDealerCurrentValidlicenceDetailsAPI.key}
                      elementWrapperStyle={"pendinLicenceTable"}
                      tableHeaderList={[
                        {
                          displayName: "Authorized Vehicle No.",
                          key: "vehicle_number",
                          default: "-",
                          isSort: true
                        },
                        { displayName: "Lic. No.", key: "licence_number", isSort: false },
                        {
                          displayName: "Serial No.",
                          key: "serial_number",
                          isSort: false,
                        },
                        {
                          displayName: "Validity Period (From - To)",
                          key: "validity_period",
                          onPrintFn: (rawData) => {
                            return (dateObjectToString(rawData.validity_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.validity_to, "dd/mm/YY")))
                          },
                          isSort: false
                        },
                        {
                          displayName: "Insurance Company",
                          key: "ins_omapny",
                          onPrintFn: (rawData) => {
                            if (rawData.insurance_company != null) {
                              return rawData.insurance_company.service.name;
                            }
                          },
                          isSort: false,
                        },
                        {
                          displayName: "Insurance Expiry Date",
                          key: "insurance_expire_date",
                          onPrintFn: (rawData) => {
                            if (rawData.insurance_expire_date != null) {
                              return (dateObjectToString(rawData.insurance_expire_date, "dd/mm/YY"))
                            }
                            else {
                              return ("-")
                            }
                          },
                          isSort: false,
                        },
                      ]}
                      isSetTableFooter={true}
                      isSetTableHeader={false}
                      isSetAction={true}
                      // customButtonComponent: SubmitBtn,
                      actionTypeList={[
                        {
                          actionType: actionBtnTypes.reprint,
                          actionBtn: {
                            icon: "mdi mdi-printer-settings",
                          },
                          modelContentElement: LicenceReprintPreview,
                          dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Print Preview",
                            closeButton: {
                              btnText: "Cancel",
                              startIcon: "far fa-times-circle",
                            },

                            isCustomButton: true,
                            customButtonComponent: SubmitBtn,
                          },
                          parentToModelProps: {
                            licenceType: "dealer",

                          },
                          checkBeforeActionRenderFn: (rawData) => {
                            return (_get(rawData, "row.is_reprint", "") === true)
                          }
                        }

                      ]}
                    />
                  </PaddingLessUICard>
                </div>

                <div className="fullWidthDiv defaultHalfMarginBottom">
                  <DealerLicenceFee />

                </div>

              </div>
            </FormWrapper>
          </div>
        ) : (null)
      }

      <PendingWorkflow
        getState={getState}
        setState={setState}
        setShowHideFooterFN={setShowHideFooterFN}
      />
    </div>

  );
};

const SubmitBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [, uiAction] = useContext(UIContext);
  const { printFn, bulkPrintFn } = useLicencePrint();
  const [authStatus, authAction] = useContext(AuthContext);
  const authority_id = _get(authStatus, "authUser.authorityId", "");

  const licencePrint = (printFrame) => {
    let printElement = document.getElementById(printFrame);
    let printWindow = window.open('', 'PRINT', 'height=400,width=600');
    printWindow.document.write(document.documentElement.innerHTML);
    // printWindow.document.body.style.margin = '0 0';
    printWindow.document.body.innerHTML = printElement.innerHTML;
    // printWindow.document.close(); // necessary for IE >= 10
    // printWindow.focus(); // necessary for IE >= 10*/
    printWindow.print();
    printWindow.close();
    // setTimeout(() => { // Needed for large documents
    //   printWindow.document.body.style.margin = '0 0';
    //   printWindow.document.body.innerHTML = printElement.outerHTML;
    //   printWindow.document.close(); // necessary for IE >= 10
    //   printWindow.focus(); // necessary for IE >= 10*/
    //   printWindow.print();
    //   printWindow.close();
    // }, 50)



    // var printContent = document.getElementById(printFrame);
    // var WinPrint = window.open('', '', 'width=5,height=5');
    // WinPrint.document.write(printContent.innerHTML);
    // WinPrint.document.close();
    // WinPrint.print();
    // WinPrint.close();

    // var mywindow = window.open();
    // let printContents = document.getElementById(printFrame).innerHTML;
    // mywindow.document.write(printContents);
    // mywindow.document.write('</body></html>');
    // mywindow.print();
    // mywindow.close();
    // document.body.innerHTML = printContents;
    // window.onload=function();
    // window.print();
    //   let originalContents = document.body.innerHTML;

    //   window.print();
    //  // window.location.reload();
    //    document.body.innerHTML = originalContents;
    //   window.close()
  }

  return (
    <Fragment>
      <InputButton
        btnText="Print"
        startIcon={"far fa-save"}
        elementStyle="btnWrapper"
        onClickBtnFn={() => {
          // printFn(coreAction, _get(formState, 'licenceReprintFormKey.licenceData', ""));
          licencePrint("print-card")
          coreAction
            .sendRequestFn(
              `${licencePrintLicenceCount.url}/${props.row.licence_id}?serial_number=${props.row.serial_number}&authority_id=${authority_id}`
            )
            .isMultipart(false)
            .method("put")
            .body({})
            .setLoader(false)
            .setInitStoring(apiResponseStoringType.responseLinkToFrom, {
              responseKey: 'printCountLicenseResponse',
            })
            .send((error, response) => {
              if (!error) {
                coreAction.resetDataTable(getDealerCurrentValidlicenceDetailsAPI.key);
                if(!isEmptyValue(_get(response, "data.next_serial_number", null))){
                  formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", _get(response, "data.next_serial_number", null));
                }
              }

            });
            uiAction.setFlashMessage({
              status: true,
              message: `Printing Licence ${_get(formState, 'licenceReprintFormKey.licenceData.data.lhs_licence_no', "")}.`,
              messageType: "success",
            });
          props.onClosePopupFn();
         


        }}
      />
      <div className='licence-visibility'>
        <div id="print-card">
          <LicenceReceiptPrint dataList={_get(formState, 'licenceReprintFormKey.licenceData.data', "")} />
        </div>
      </div>
    </Fragment>
  );
};

export { DealerLicense };
