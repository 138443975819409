import React, { Fragment, useContext, useEffect, useState } from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { Action } from "./includes/Action";
import { WaiveOffUpperPannel, WaiveOffAmountBase, WaiveOffDurationBase, WaiveOffType } from "./includes/LicenceWaveOffInformation";
import { licenceWaveOffAPI, uploadDetailGetAPI, getLatestLicenseAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { Reason } from "./includes/Reason";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { RequestStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
const WaveOffSubmitBtn = (props) => {
    const [formState] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const { sessionId } = props
    const serviceId = _get(authStatus, "authUser.service_id", "");
    const user_role_level = _get(authStatus, "authUser.role_level_code", "");
    // const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
    const vehicle_no = _get(
        formState,
        "vehicle_search_form.searchKey",
        null
    );
    const approvalAction = _get(
        formState,
        "Licence_waive_Off.action",
        null
    )
    const current_licence_type = _get(formState, "vehicle_search_form.data.current_license_type.code", null);
    const authorityId = _get(authStatus, "authUser.authorityId", "");
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    const [, uiAction] = useContext(UIContext);
    const userName = _get(authStatus, "authUser.name", "<anonymous name>");
    const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
        _get(authStatus, "authUser.provinceName", "<anonymous province>") :
        ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
    const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
                startIcon={"far fa-save"}
                formGroupName={props.uiType === 'assign&Perform' ? "Licence_waive_Off" : "licence-wave-off"}
                tooltip={"Submit"}
                isValidate={true}
                isSetHeaders={true}
                flashMessages={{
                    success: props.uiType === 'assign&Perform' ? {
                        status: true,
                        message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
                        messageType: "success",
                    } : {
                        status: true,
                        message: `Approval Request successfully submitted. Request ID : ${recId}`,
                        messageType: "success",
                    },
                    // failed: props.uiType === 'assign&Perform' && {
                    //     status: true,
                    //     message: `Approval Request Decision Submit Failed. Please Try Again.`,
                    //     messageType: "error",
                    // },
                }}
                validationObject={props.uiType === 'assign&Perform' && _get(formState, "Licence_waive_Off.action", "") === 'REJ' ? {
                    fileds: {
                      remark: "Remark",
                    },
                    rules: {
                      remark: "required|max:500",
                    },
                    message: {
                      "remark.required": "Remark is required.",
                    }
                  } : {
                    fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Licence_waive_Off.action", "") === 'FRD' ? (_get(formState, "Licence_waive_Off.forward_role", "") === 'OPT' ?
                        {
                            user_role_level: "User role",
                            reason_id: "Reason",
                            "action": "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                            forward_authority: "Authority"
                        } : {
                            user_role_level: "User role",
                            reason_id: "Reason",
                            "action": "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role"

                        }) : _get(formState, "Licence_waive_Off.action", "") !== 'REJ' && {
                            user_role_level: "User role",
                            reason_id: "Reason",
                            "action": "Action",
                            remark: "Remark",
                        }) : {
                        user_role_level: "User role",
                        reason_id: "Reason",
                        "action": "Action",
                        remark: "Remark",
                        forward_province: "Province",
                        forward_role: "Role"

                    },
                    rules: props.uiType === 'assign&Perform' ? (_get(formState, "Licence_waive_Off.action", "") === 'FRD' ? (_get(formState, "Licence_waive_Off.forward_role", "") === 'OPT' ? {
                        reason_id: "required",
                        "action": "required|max:500",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                        forward_authority: "required"
                    } : {
                        reason_id: "required",
                        "action": "required|max:500",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required"
                    }) : _get(formState, "Licence_waive_Off.action", "") !== 'REJ' && {
                        reason_id: "required",
                        "action": "required|max:500",
                        remark: "required|max:500",
                    }) : {
                        reason_id: "required",
                        "action": "required|max:500",
                        remark: "required|max:500",
                        forward_role: "required",

                    },
                    message: props.uiType === 'assign&Perform' ? (_get(formState, "Licence_waive_Off.action", "") === 'FRD' ? (_get(formState, "Licence_waive_Off.forward_role", "") === 'OPT' ? {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province.required": "Province is required",
                        "forward_authority.required": "Authority is required"
                    } : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province.required": "Province is required"
                    }) : _get(formState, "Licence_waive_Off.action", "") !== 'REJ' && {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",

                    },
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${licenceWaveOffAPI.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
                        key: licenceWaveOffAPI.key,
                    };
                }}
                onChangeRequestBodyFn={(fromObject) => {

                    const wave_off_duration_base_bulk_list = [];
                    const wave_off_amount_base_bulk_list = [];
                    if (props.uiType === 'assign&Perform' && fromObject.wave_off_duration_base && Object.keys(fromObject.wave_off_duration_base).length !== 0) {
                        if (_get(formState, "Licence_waive_Off.action", undefined) === undefined) {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Action is required`,
                                messageType: "error",
                            });

                            return
                        }
                        Object.keys(fromObject.wave_off_duration_base).forEach((value) => {
                            if (fromObject.action != 'REJ' && !fromObject.wave_off_duration_base[value].wave_component) {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Waive off component is required`,
                                    messageType: "error",
                                });

                                return false
                            }
                            wave_off_duration_base_bulk_list.push({
                                fromDate: (!fromObject.wave_off_duration_base[value].fromDate || fromObject.wave_off_duration_base[value].fromDate === undefined || fromObject.wave_off_duration_base[value].fromDate === null) ? new Date() : fromObject.wave_off_duration_base[value].fromDate,
                                toDate: (!fromObject.wave_off_duration_base[value].toDate || fromObject.wave_off_duration_base[value].toDate === undefined || fromObject.wave_off_duration_base[value].toDate === null) ? new Date() : fromObject.wave_off_duration_base[value].toDate,
                                wave_component: fromObject.wave_off_duration_base[value].wave_component,

                            });
                        });
                        if (wave_off_duration_base_bulk_list.length !== 0) {
                            const n = fromObject.wave_off_duration_base && Object.keys(fromObject.wave_off_duration_base).length
                            const r = (n === wave_off_duration_base_bulk_list.length);

                            if (fromObject.action != 'REJ') {
                                if (r === true) {
                                    return getDataByFormObject({
                                        ...fromObject,
                                        userName: userName,
                                        currentProvince: currentProvince,
                                        authority: authority,
                                        requestType: RequestTypesList["waiveoff_lcn"],
                                        // request_id: recId,
                                        reason_id: formState['Licence_waive_Off-new'].reason_id,
                                        wave_off_duration_base: wave_off_duration_base_bulk_list,
                                        wave_off_amount_base: wave_off_amount_base_bulk_list,
                                        current_licence_type: current_licence_type,
                                        ui_request_id: props.approvalRequestId,
                                        // status: RequestStatus.APPROVAL_PENDING,
                                        pdmt_id: pdmtId,
                                        // authority_id: authorityId,
                                        counter_session_id: '00001',
                                        forward_authority_service_id: serviceId,
                                        document_details: [],
                                        action: fromObject.action == 'FRD' ? {
                                            action: fromObject.action,
                                            remark: fromObject.remark,
                                            forward_province: fromObject.forward_province,
                                            forward_role: fromObject.forward_role,
                                            forward_authority: fromObject.forward_authority
                                        } : {
                                            action: fromObject.action,
                                            remark: fromObject.remark,
                                        },
                                    });
                                }
                            } else {
                                console.log('SSSS');
                                return getDataByFormObject({
                                    ...fromObject,
                                    userName: userName,
                                    currentProvince: currentProvince,
                                    authority: authority,
                                    requestType: RequestTypesList["waiveoff_lcn"],
                                    // request_id: recId,
                                    reason_id: formState['Licence_waive_Off-new'].reason_id,
                                    wave_off_duration_base: [],
                                    wave_off_amount_base: wave_off_amount_base_bulk_list,
                                    current_licence_type: current_licence_type,
                                    ui_request_id: props.approvalRequestId,
                                    // status: RequestStatus.APPROVAL_PENDING,
                                    pdmt_id: pdmtId,
                                    // authority_id: authorityId,
                                    counter_session_id: '00001',
                                    forward_authority_service_id: serviceId,
                                    document_details: [],
                                    action: fromObject.action == 'FRD' ? {
                                        action: fromObject.action,
                                        remark: fromObject.remark,
                                        forward_province: fromObject.forward_province,
                                        forward_role: fromObject.forward_role,
                                        forward_authority: fromObject.forward_authority
                                    } : {
                                        action: fromObject.action,
                                        remark: fromObject.remark,
                                    },
                                });

                            }

                        }

                    } else if (props.uiType === 'assign&Perform' && fromObject.wave_off_amount_base && Object.keys(fromObject.wave_off_amount_base).length !== 0) {
                        if (_get(formState, "Licence_waive_Off.action", undefined) === undefined) {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Action is required`,
                                messageType: "error",
                            });

                            return
                        }
                        Object.keys(fromObject.wave_off_amount_base).forEach((value) => {
                            console.log('AWWW====>', fromObject.wave_off_amount_base[value]);
                            if (fromObject.action != 'REJ' && !fromObject.wave_off_amount_base[value].wave_component) {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Waive off component is required`,
                                    messageType: "error",
                                });

                                return false
                            }
                            if (fromObject.action != 'REJ' && (!fromObject.wave_off_amount_base[value].amount || fromObject.wave_off_amount_base[value].amount === '' || fromObject.wave_off_amount_base[value].amount === null)) {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Amount is required`,
                                    messageType: "error",
                                });

                                return false
                            }
                            console.log('validation--->', _get(formState, "amount.isValidationFired", null));
                            if (fromObject.action != 'REJ' && _get(formState, "amount.isValidationFired", false) === true && (fromObject.wave_off_amount_base[value].amount || fromObject.wave_off_amount_base[value].amount !== '' || fromObject.wave_off_amount_base[value].amount !== null)) {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Amount (LKR) should be less than or equal to Applicable Amount (LKR)`,
                                    messageType: "error",
                                });

                                return false
                            }
                            wave_off_amount_base_bulk_list.push({

                                wave_component: fromObject.wave_off_amount_base[value].wave_component,
                                amount: fromObject.wave_off_amount_base[value].amount

                            });
                        });
                        if (wave_off_amount_base_bulk_list.length !== 0) {
                            const n = fromObject.wave_off_amount_base && Object.keys(fromObject.wave_off_amount_base).length
                            const r = (n === wave_off_amount_base_bulk_list.length)
                            // console.log('NNNNNNN==11', wave_off_amount_base_bulk_list);
                            // console.log('NNNNNNN==12', r);
                            if (fromObject.action != 'REJ') {
                                if (r === true) {
                                    return getDataByFormObject({
                                        ...fromObject,
                                        userName: userName,
                                        currentProvince: currentProvince,
                                        authority: authority,
                                        requestType: RequestTypesList["waiveoff_lcn"],
                                        // request_id: recId,
                                        reason_id: formState['Licence_waive_Off-new'].reason_id,
                                        wave_off_duration_base: wave_off_duration_base_bulk_list,
                                        wave_off_amount_base: wave_off_amount_base_bulk_list,
                                        current_licence_type: current_licence_type,
                                        ui_request_id: props.approvalRequestId,
                                        // status: RequestStatus.APPROVAL_PENDING,
                                        pdmt_id: pdmtId,
                                        // authority_id: authorityId,
                                        counter_session_id: '00001',
                                        forward_authority_service_id: serviceId,
                                        document_details: [],
                                        action: fromObject.action == 'FRD' ? {
                                            action: fromObject.action,
                                            remark: fromObject.remark,
                                            forward_province: fromObject.forward_province,
                                            forward_role: fromObject.forward_role,
                                            forward_authority: fromObject.forward_authority
                                        } : {
                                            action: fromObject.action,
                                            remark: fromObject.remark,
                                        },
                                    });
                                }
                            } else {
                                console.log('SSSS2');

                                return getDataByFormObject({
                                    ...fromObject,
                                    userName: userName,
                                    currentProvince: currentProvince,
                                    authority: authority,
                                    requestType: RequestTypesList["waiveoff_lcn"],
                                    // request_id: recId,
                                    reason_id: formState['Licence_waive_Off-new'].reason_id,
                                    wave_off_duration_base: [],
                                    wave_off_amount_base: [],
                                    current_licence_type: current_licence_type,
                                    ui_request_id: props.approvalRequestId,
                                    // status: RequestStatus.APPROVAL_PENDING,
                                    pdmt_id: pdmtId,
                                    // authority_id: authorityId,
                                    counter_session_id: '00001',
                                    forward_authority_service_id: serviceId,
                                    document_details: [],
                                    action: fromObject.action == 'FRD' ? {
                                        action: fromObject.action,
                                        remark: fromObject.remark,
                                        forward_province: fromObject.forward_province,
                                        forward_role: fromObject.forward_role,
                                        forward_authority: fromObject.forward_authority
                                    } : {
                                        action: fromObject.action,
                                        remark: fromObject.remark,
                                    },
                                });
                            }

                        }
                    } else if (props.uiType === 'assign&Perform' && !fromObject.wave_off_amount_base &&
                        !fromObject.wave_off_duration_base) {
                        if (_get(formState, "Licence_waive_Off.action", undefined) === undefined) {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Action is required`,
                                messageType: "error",
                            });

                            return
                        }
                        if (fromObject.action != 'REJ') {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Waive off type is required`,
                                messageType: "error",
                            });
                        } else {
                            return getDataByFormObject({
                                ...fromObject,
                                userName: userName,
                                currentProvince: currentProvince,
                                authority: authority,
                                requestType: RequestTypesList["waiveoff_lcn"],
                                // request_id: recId,
                                reason_id: formState['Licence_waive_Off-new'].reason_id,
                                wave_off_duration_base: [],
                                wave_off_amount_base: [],
                                current_licence_type: current_licence_type,
                                ui_request_id: props.approvalRequestId,
                                // status: RequestStatus.APPROVAL_PENDING,
                                pdmt_id: pdmtId,
                                // authority_id: authorityId,
                                counter_session_id: '00001',
                                forward_authority_service_id: serviceId,
                                document_details: [],
                                action: fromObject.action == 'FRD' ? {
                                    action: fromObject.action,
                                    remark: fromObject.remark,
                                    forward_province: fromObject.forward_province,
                                    forward_role: fromObject.forward_role,
                                    forward_authority: fromObject.forward_authority
                                } : {
                                    action: fromObject.action,
                                    remark: fromObject.remark,
                                },
                            });
                        }

                    } else if (props.uiType !== 'assign&Perform') {
                        if (fromObject.wave_off_duration_base && Object.keys(fromObject.wave_off_duration_base).length !== 0) {
                            Object.keys(fromObject.wave_off_duration_base).forEach((value) => {
                                if ((fromObject.wave_off_duration_base[value].fromDate)
                                    || (fromObject.wave_off_duration_base[value].toDate)) {
                                    if (fromObject.wave_off_duration_base[value].wave_component) {
                                        wave_off_duration_base_bulk_list.push({
                                            fromDate: (!fromObject.wave_off_duration_base[value].fromDate || fromObject.wave_off_duration_base[value].fromDate === undefined || fromObject.wave_off_duration_base[value].fromDate === null) ? new Date() : fromObject.wave_off_duration_base[value].fromDate,
                                            toDate: (!fromObject.wave_off_duration_base[value].toDate || fromObject.wave_off_duration_base[value].toDate === undefined || fromObject.wave_off_duration_base[value].toDate === null) ? new Date() : fromObject.wave_off_duration_base[value].toDate,
                                            wave_component: fromObject.wave_off_duration_base[value].wave_component,

                                        });


                                    } else if (fromObject.action != 'REJ') {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Waive off cmponent is required`,
                                            messageType: "error",
                                        });

                                        return false
                                    }
                                } else {
                                    wave_off_duration_base_bulk_list.push({
                                        fromDate: (!fromObject.wave_off_duration_base[value].fromDate || fromObject.wave_off_duration_base[value].fromDate === undefined || fromObject.wave_off_duration_base[value].fromDate === null) ? new Date() : fromObject.wave_off_duration_base[value].fromDate,
                                        toDate: (!fromObject.wave_off_duration_base[value].toDate || fromObject.wave_off_duration_base[value].toDate === undefined || fromObject.wave_off_duration_base[value].toDate === null) ? new Date() : fromObject.wave_off_duration_base[value].toDate,
                                        wave_component: fromObject.wave_off_duration_base[value].wave_component,

                                    });
                                }

                            });
                            if (wave_off_duration_base_bulk_list.length !== 0) {
                                const n = fromObject.wave_off_duration_base && Object.keys(fromObject.wave_off_duration_base).length
                                const r = (n === wave_off_duration_base_bulk_list.length)
                                // console.log('NNNNNNN==11', wave_off_amount_base_bulk_list);
                                // console.log('NNNNNNN==12', r);

                                if (r === true) {
                                    return getDataByFormObject({
                                        ...fromObject,
                                        userName: userName,
                                        currentProvince: currentProvince,
                                        authority: authority,
                                        requestType: RequestTypesList["waiveoff_lcn"],
                                        request_id: recId,
                                        reason_id: fromObject.reason_id,
                                        wave_off_duration_base: wave_off_duration_base_bulk_list,
                                        wave_off_amount_base: wave_off_amount_base_bulk_list,
                                        current_licence_type: current_licence_type,
                                        carnet_licence_start_at: props.isCarnet ? _get(formState, 'carnet.fromDate', null) : null,
                                        carnet_licence_end_at: props.isCarnet ? _get(formState, 'carnet.toDate', null) : null,
                                        status: RequestStatus.APPROVAL_PENDING,
                                        pdmt_id: pdmtId,
                                        authority_id: authorityId,
                                        counter_session_id: sessionId ? sessionId : null,
                                        forward_authority_service_id: serviceId,
                                        document_details: [],
                                        action: {
                                            action: fromObject.action,
                                            remark: fromObject.remark,
                                            forward_province: fromObject.forward_province,
                                            forward_role: fromObject.forward_role,
                                            forward_authority: fromObject.forward_authority
                                        }
                                    });
                                }
                            }
                        }
                        if (fromObject.wave_off_amount_base && Object.keys(fromObject.wave_off_amount_base).length !== 0) {
                            Object.keys(fromObject.wave_off_amount_base).forEach((value) => {
                                console.log('AWWW====11111>', fromObject.wave_off_amount_base[value]);
                                if ((fromObject.wave_off_amount_base[value].amount)) {
                                    console.log('validation---1111>', _get(formState, "amount.isValidationFired", null));
                                    if (fromObject.action != 'REJ' && _get(formState, "amount.isValidationFired", false) === true && (fromObject.wave_off_amount_base[value].amount || fromObject.wave_off_amount_base[value].amount !== '' || fromObject.wave_off_amount_base[value].amount !== null)) {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Amount(LKR) should be less than or equal to Applicable Amount(LKR)`,
                                            messageType: "error",
                                        });

                                        return false
                                    }
                                    if (fromObject.wave_off_amount_base[value].wave_component) {
                                        console.log('cccccccc---->111', fromObject.wave_off_amount_base[value].wave_component);

                                        wave_off_amount_base_bulk_list.push({

                                            wave_component: fromObject.wave_off_amount_base[value].wave_component,
                                            amount: fromObject.wave_off_amount_base[value].amount

                                        });
                                    } else if (fromObject.action != 'REJ') {
                                        // console.log('cccccccc---->', fromObject.wave_off_amount_base[value].wave_component);
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Waive off cmponent is required`,
                                            messageType: "error",
                                        });

                                        return false
                                    }
                                } else {
                                    wave_off_amount_base_bulk_list.push({

                                        wave_component: fromObject.wave_off_amount_base[value].wave_component,
                                        amount: fromObject.wave_off_amount_base[value].amount

                                    });
                                }

                            });
                            if (wave_off_amount_base_bulk_list.length !== 0) {
                                const n = fromObject.wave_off_amount_base && Object.keys(fromObject.wave_off_amount_base).length
                                const r = (n === wave_off_amount_base_bulk_list.length)
                                // console.log('NNNNNNN==11', wave_off_amount_base_bulk_list);
                                // console.log('NNNNNNN==12', r);

                                if (r === true) {
                                    return getDataByFormObject({
                                        ...fromObject,
                                        userName: userName,
                                        currentProvince: currentProvince,
                                        authority: authority,
                                        requestType: RequestTypesList["waiveoff_lcn"],
                                        request_id: recId,
                                        reason_id: fromObject.reason_id,
                                        wave_off_duration_base: wave_off_duration_base_bulk_list,
                                        wave_off_amount_base: wave_off_amount_base_bulk_list,
                                        current_licence_type: current_licence_type,
                                        carnet_licence_start_at: props.isCarnet ? _get(formState, 'carnet.fromDate', null) : null,
                                        carnet_licence_end_at: props.isCarnet ? _get(formState, 'carnet.toDate', null) : null,
                                        status: RequestStatus.APPROVAL_PENDING,
                                        pdmt_id: pdmtId,
                                        authority_id: authorityId,
                                        counter_session_id: sessionId ? sessionId : null,
                                        forward_authority_service_id: serviceId,
                                        document_details: [],
                                        action: {
                                            action: fromObject.action,
                                            remark: fromObject.remark,
                                            forward_province: fromObject.forward_province,
                                            forward_role: fromObject.forward_role,
                                            forward_authority: fromObject.forward_authority
                                        }
                                    });
                                }
                            }

                        } else {
                            return getDataByFormObject({
                                ...fromObject,
                                userName: userName,
                                currentProvince: currentProvince,
                                authority: authority,
                                requestType: RequestTypesList["waiveoff_lcn"],
                                request_id: recId,
                                reason_id: fromObject.reason_id,
                                wave_off_duration_base: wave_off_duration_base_bulk_list,
                                wave_off_amount_base: wave_off_amount_base_bulk_list,
                                carnet_licence_start_at: props.isCarnet ? _get(formState, 'carnet.fromDate', null) : null,
                                carnet_licence_end_at: props.isCarnet ? _get(formState, 'carnet.toDate', null) : null,
                                current_licence_type: current_licence_type,
                                status: RequestStatus.APPROVAL_PENDING,
                                pdmt_id: pdmtId,
                                authority_id: authorityId,
                                counter_session_id: sessionId ? sessionId : null,
                                forward_authority_service_id: serviceId,
                                document_details: [],
                                action: {
                                    action: fromObject.action,
                                    remark: fromObject.remark,
                                    forward_province: fromObject.forward_province,
                                    forward_role: fromObject.forward_role,
                                    forward_authority: fromObject.forward_authority
                                }
                            });
                        }

                    }




                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: props.uiType === 'assign&Perform' ? "put" : "post",
                }}

                onResponseCallBackFn={(response, error) => {
                    if (
                        response.length === undefined &&
                        error === undefined &&
                        response.data.errors === undefined
                    ) {
                        if (props.uiType === 'assign&Perform') {
                            props.refreshMainScreenAfterPerformFn()
                            props.onClosePopupFn();
                        } else {
                            props.refreshMainScreenDataFn()
                            props.onClosePopupFn();
                        }
                    }  else {
                        if (props.uiType === 'assign&Perform') {
                          uiAction.setFlashMessage({
                            status: true,
                            message: `Approval Request Decision Submit Failed. Please Try Again.`,
                            messageType: "error",
                          });
                        }
                        else {
                          if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                            uiAction.setFlashMessage({
                              status: true,
                              message: response.data.errors.details[0].message,
                              messageType: "error",
                            });
                          }
                          else {
                            uiAction.setFlashMessage({
                              status: true,
                              message: `Approval Request Submit Failed. Please Try Again.`,
                              messageType: "error",
                            });
                          }
                        }
                      }
                }}
            />
        </Fragment>
    );
};

let recId = "";
const LiceneceWaveOffUI = (props) => {
    const formKey = props.formKey;
    const uiType = props.uiType;
    const [formState, formAction] = useContext(FormContext);
    const [requestId, setRequestId] = useState(null);
    const [authStatus, authAction] = useContext(AuthContext);
    const [fired, setFired] = useState(false);
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

    useEffect(() => {
        const rId = new Date();
        const requ = rId.valueOf().toString();
        setRequestId(requ.substr(requ.length - 8))
        if (uiType === 'APR') {
            getApprovalPendingData(props)
            getReqApprovalLicenceTypeData(props)
        }
    }, []);
    // _get(formState, "Licence_waive_Off.action", "") === 'FRD';


    useEffect(() => {

        if (_get(formState, `${formKey}.waive_off_type`, 'NUL') !== 'NUL') {
            if (_get(formState, `${formKey}.waive_off_type`, null) === 'DUR') {
                if (uiType === 'APR') {
                    formAction.changeInputFn(formKey, "wave_off_duration_base", "", { "0": {} })
                    formAction.changeInputFn(formKey, "wave_off_amount_base", "", {})
                } else {
                    formAction.changeInputFn(formKey, "wave_off_duration_base", "", { "0": {} })
                    formAction.changeInputFn(formKey, "wave_off_amount_base", "", {})
                }
            } else {
                formAction.changeInputFn('amount', "isValidationFired", "", false)
                if (uiType === 'APR') {
                    formAction.changeInputFn(formKey, "wave_off_amount_base", "", { "0": {} })
                    formAction.changeInputFn(formKey, "wave_off_duration_base", "", {})
                } else {
                    formAction.changeInputFn(formKey, "wave_off_amount_base", "", { "0": {} })
                    formAction.changeInputFn(formKey, "wave_off_duration_base", "", {})
                }

            }
        }
    }
        , [_get(formState, `${formKey}.waive_off_type`, 'NUL')])
    recId = requestId;

    const getApprovalPendingData = (props) => {
        callApi(`${licenceWaveOffAPI.url}/get-licence/${props.approvalRequestId}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                const { waveoff_type, WaveOffAmountBases, WaveOffDurationBases, work_flow_record } = result.data.data
                if (!error) {
                    formAction.changeInputFn('work_flow_record', "data", "", work_flow_record);
                    if (waveoff_type === 'NUL') return
                    formAction.changeInputFn("Licence_waive_Off", "waive_off_type", "", waveoff_type);
                    if (waveoff_type === 'DUR') {
                        const newObjDur = {}
                        WaveOffDurationBases.map((obj, index) => {
                            const dataObj = {
                                fromDate: obj.from_date,
                                toDate: obj.to_date,
                                wave_component: obj.wave_component,
                            }

                            newObjDur[index] = dataObj

                        })
                        if (newObjDur && Object.keys(newObjDur).length !== 0) {

                            formAction.changeInputFn(formKey, "wave_off_duration_base", "", newObjDur);
                        }
                    } else if (waveoff_type === 'AMT') {

                        const newObj = {}
                        WaveOffAmountBases.map((obj, index) => {
                            const dataObj = {
                                wave_component: obj.wave_component,
                                amount: obj.amount
                            }

                            newObj[index] = dataObj

                        })
                        if (newObj && Object.keys(newObj).length !== 0) {

                            formAction.changeInputFn(formKey, "wave_off_amount_base", "", newObj);
                        }
                    }

                }
            });
    }

    const getReqApprovalLicenceTypeData = (props) => {
        callApi(`${getLatestLicenseAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                  
                    formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

                }
            });
    }

    const getDataSets = () => {
        if (props.uiType === 'APR') {
            return (
                <>
                    {_get(formState, "Licence_waive_Off.waive_off_type", 'NUL') === 'NUL' ?
                        null
                        :
                        _get(formState, "Licence_waive_Off.waive_off_type", 'NUL') === 'DUR' ?
                            <div className="col-md-12 col-sm-12 defaultPaddingTop">

                                <WaiveOffDurationBase {...props} formKey={`${formKey}`} />

                            </div>
                            :
                            <div className="col-md-12 col-sm-12 defaultPaddingTop">

                                <WaiveOffAmountBase fired={fired} setFired={setFired} {...props} formKey={formKey} />

                            </div>
                    }
                </>
            )
        } else {
            return (
                <>
                    {_get(formState, "licence-wave-off.waive_off_type", null) === null ?
                        null
                        :
                        _get(formState, "licence-wave-off.waive_off_type", null) === 'DUR' ?
                            <div className="col-md-12 col-sm-12 defaultPaddingTop">

                                <WaiveOffDurationBase {...props} formKey={`${formKey}`} />

                            </div>
                            :
                            <div className="col-md-12 col-sm-12 defaultPaddingTop">

                                <WaiveOffAmountBase fired={fired} setFired={setFired} {...props} formKey={formKey} />

                            </div>
                    }
                </>
            )
        }
    }



    return (
        <div className="row ">
            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                {((_get(formState, "work_flow_record.data", null) !== null && uiType === 'APR') || !props.isCarnet || props.isCarnet) ? <WaiveOffUpperPannel workFlowRecord={_get(formState, "work_flow_record.data", {})} {...props} formGroupName={formKey} /> : <div>No data(s) found</div>}

            </div>

            <div className="col-md-12 col-sm-12">
                
                    <WaiveOffType {...props} formGroupName={formKey} />
                
            </div>

            {getDataSets()}




            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                <Reason
                    filterKey={'licence_waive_off'}
                    requestType={uiType}
                    dataSubmittedForApproval={props.dataSubmittedForApproval}
                    formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
                />
            </div>

            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                <DocumentsUploader
                    isUploadFn={true}
                    formGroupName={`${formKey}-upload`}
                    tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
                    requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
                    uiType={uiType}
                />
            </div>
            <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
                <Action
                    formKey={formKey}
                    uiType={uiType}
                    dataSubmittedForApproval={props.dataSubmittedForApproval}
                // isWaiveOff={_get(formState, "latest_licence_data.data.vehicleData.province_id", null) !== null && _get(formState, "latest_licence_data.data.vehicleData.province_id", null) !== pdmtId ? true : false}
                />
            </div>


        </div >
    );
};







const LiceneceWaveOff = () => {
    return (
        <DashboardTemplate
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Licence Wave Off" },
            ]}
            pageTitle="Licence Wave Off"
        >
            <LiceneceWaveOffUI />
        </DashboardTemplate>
    )
}

export default LiceneceWaveOff;
export { LiceneceWaveOffUI, WaveOffSubmitBtn };