import React, { useContext } from "react";

import { FormWrapper, DatePickerWithState } from "../../../../../ui-components/ui-elements/form/index";
import { SelectBoxWithState, CheckBoxWithState, InputBoxWithState } from "../../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../../config/template.config";
import { AuthorityListApi, licenceTypesListAPI, provincesOfficesListAPI } from "../../../../../../config/apiUrl.config";
import { RequestType, RequestTypeList , ProvincesCodeMatcher} from "../../../../../../config/enum.config";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";


const SearchElement = ({ dataTableKey = "" }) => {
  const formKey = dataTableKey;
  const [formState, formAction] = useContext(FormContext);
  const [authState] = useContext(AuthContext);

  const role = _get(authState, "authUser.role_category_code", undefined)
  return (
    <FormWrapper
      elementStyle=""
      formGroupName="search1"
      setGroupName={formKey}
      onDestroyUnsetFormObject={true}
    >
      <div className={"row"}>
        <div className="col-md-3">
          <CheckBoxWithState
            inputValue={true}
            inputName={"approved"}
            formGroupName={formKey}
            labelText={"Approved"}
          />
        </div>
        <div className="col-md-3">
          <CheckBoxWithState
            inputName={"approvalPending"}
            formGroupName={formKey}
            inputValue={true}
            labelText={"Approval Pending"}
          />
        </div>
        <div className="col-md-3">
          <CheckBoxWithState
            inputName={"rejected"}
            formGroupName={formKey}
            inputValue={true}
            labelText={"Rejected"}
          />
        </div>
        <div className="col-md-3">
          <CheckBoxWithState
            inputName={"withdrawn"}
            formGroupName={formKey}
            inputValue={true}
            labelText={"Withdrawn"}
          />
        </div>
        <div className="col-md-6">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            dataList={role === 'OPT' ? [
              {
                id: "1",
                value: "My Requests",
              },
              {
                id: "2",
                value: "Authority Requests",
              },
            ] : [
              {
                id: "1",
                value: "My Requests",
              },
              {
                id: "2",
                value: "Authority Requests",
              },
              {
                id: "3",
                value: "Province Requests",
              },
            ]}
            inputName={"show"}
            labelText="Show"
            isEnableAllOption={true}
          />
        </div>

        {/* <div className="col-md-6">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            apiUrl={provincesOfficesListAPI.url}
            formGroupName={formKey}
            keyName={"id"}
            valueName={"province.name"}
            apiStateKey={provincesOfficesListAPI.key}
            emptySelectOptionTxt={"Province"}
            inputName={"province"}
            labelText="Province"
          />
        </div>*/}
        <div className="col-md-6">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            //emptySelectOptionTxt={"Authority"}
            inputName={"authority"}
            apiUrl={`${AuthorityListApi.url}?active=true`}
            apiStateKey={AuthorityListApi.key}
            keyName={"id"}
            valueName={"service.name"}
            labelText="Authority"
            isEnableAllOption={true}
            isDisabled={_get(authState, "authUser.role_level_code", "") === "INT_AU"}
          />
        </div>
        <div className="col-md-6">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            dataList={RequestTypeList}
            inputName={"request_type"}
            labelText="Request Type"
            isEnableAllOption={true}
          />
        </div>
        {/* <div className="col-md-6">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            //emptySelectOptionTxt={'Licence Type'}
            formGroupName={formKey}
            inputName={'licence_type'}
            apiUrl={`${licenceTypesListAPI.url}?sort_by=name|asc`}
            apiStateKey={licenceTypesListAPI.key}
            keyName={"code"}
            valueName={"name"}
            labelText="Licence Type"
            isEnableAllOption={true}
          />
        </div> */}
        <div className="col-md-6">
          <DatePickerWithState
            formGroupName={formKey}
            inputName={"from"}
            labelText={"From"}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-6">
          <DatePickerWithState
            formGroupName={formKey}
            inputName={"to"}
            labelText={"To"}
            minDate={_get(formState, `${formKey}.from`, "")}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"vehicle_no"}
            labelText="Vehicle ID"
            inputPlaceholder="Vehicle ID"
            maxLength="25"
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"ui_request_id"}
            labelText="Request ID"
            inputPlaceholder="Request ID"
            maxLength="25"
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"dealer_id"}
            labelText="Dealer ID"
            inputPlaceholder="Dealer ID"
            maxLength="25"
          />
        </div>

      </div>

    </FormWrapper>

  );
};

const SearchElementUnAssign = ({ dataTableKey = "" }) => {
  const formKey = dataTableKey;
  const [formState, formAction] = useContext(FormContext);
  const [authState] = useContext(AuthContext);
  return (
    <FormWrapper
      elementStyle=""
      formGroupName="search1"
      setGroupName={formKey}
      onDestroyUnsetFormObject={true}
    >
      <div className={"row"}>
        <div className="col-md-6">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            dataList={RequestTypeList}
            inputName={"request_type"}
            labelText="Request Type"
            isEnableAllOption={true}
          />
        </div>
        <div className="col-md-6">
        <DatePickerWithState
            formGroupName={formKey}
            inputName={"from"}
            labelText={"From"}
            minDate={'01/01/1970'}
            maxDate={new Date()}
            format={"dd/MM/yyyy"}
          />
         
        </div>
        <div className="col-md-6">
        <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            apiUrl={provincesOfficesListAPI.url}
            formGroupName={formKey}
            keyName={"id"}
            valueName={"province.name"}
            apiStateKey={provincesOfficesListAPI.key}
            emptySelectOptionTxt={"Province"}
            inputName={"province"}
            labelText="Province"
            isEnableAllOption={true}
          />
          
        </div>
        {/* <div className="col-md-6">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            //emptySelectOptionTxt={'Licence Type'}
            formGroupName={formKey}
            inputName={'licence_type'}
            apiUrl={`${licenceTypesListAPI.url}?sort_by=name|asc`}
            apiStateKey={licenceTypesListAPI.key}
            keyName={"code"}
            valueName={"name"}
            labelText="Licence Type"
            isEnableAllOption={true}
          />
        </div> */}
        <div className="col-md-6">
        <DatePickerWithState
            formGroupName={formKey}
            inputName={"to"}
            labelText={"To"}
            minDate={_get(formState, `${formKey}.from`, "")}
            maxDate={new Date()}
            format={"dd/MM/yyyy"}
          />
        </div>
        <div className="col-md-6">
        <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            emptySelectOptionTxt={"Authority"}
            inputName={"authority"}
            apiUrl={`${AuthorityListApi.url}?province_code=${ProvincesCodeMatcher[`${_get(formState, `${formKey}.province`, null)}`]}&active=true`}
            apiStateKey={AuthorityListApi.key}
            keyName={"id"}
            valueName={"service.name"}
            labelText="Authority"
            isEnableAllOption={true}
            isDisabled={_get(authState, "authUser.role_level_code", "") === "INT_AU"}
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"ui_request_id"}
            labelText="Request ID"
            inputPlaceholder="Request ID"
            maxLength="25"
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"vehicle_no"}
            labelText="Vehicle ID"
            inputPlaceholder="Vehicle ID"
            maxLength="25"
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"dealer_id"}
            labelText="Dealer ID"
            inputPlaceholder="Dealer ID"
            maxLength="25"
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export { SearchElement, SearchElementUnAssign };
