export const IPGTypesEnum = [
  // {id: 'QR', value: 'LANKA QR'},
  // {id: 'BOC', value: 'BOC IPG'},
  // {id: 'SMP', value: 'Sampath'},
  {id: 'LGP', value: 'LGPS'},
  {id: 'SMV', value: 'Sampath Vishwa'}
]

export const WebLicenceStatus = {
  "INITIATED": '0', // initiated 
  "DOCUMENTS_CHECKED": '1', // documents checked 
  "PAYMENT_CALCULATED": '2', // payment calculated 
  "PAYMENT_INITIATED": '3', // payment initiated 
  "PAYMENT_COMPLETED": '4', // payment completed (payment reconciliation pending)
  "PAYMENT_RECONCILIATION_SETTLED": '6', // payment reconciliation settled 		
  "LICENCE_ISSUED": '5', // licence issued 
  "PAYMENT_RECONCILIATION_VOID": '7', // payment reconciliation void
  "PAYMENT_FAILED": '8', // payment Failed (After Payment Initiated Web Payment Failed)
}

export const ReconciliationStatusTypesEnum = [
  // {id: 0, value: 'Initiated'},
  // {id: 1, value: 'Document Checked'},
  // {id: 2, value: 'Payment Calculated'},
  // {id: 3, value: 'Payment Initiated'},
  // {id: 4, value: 'Payment Completed'},
  // {id: 5, value: 'Licence Issued'},
  {id: 4, value: 'Pending'},
  {id: 6, value: 'Settled'},
  {id: 7, value: 'Void'},

]

export const Provinces = {
  WES: {
    code: "WES",
    name: "Western Province",
  },
  SUO: {
    code: "SUT",
    name: "Southern Province",
  },
  CEN: {
    code: "CEN",
    name: "Central Province",
  },
  NOR: {
    code: "NOT",
    name: "Northern Province",
  },
  SAB: {
    code: "SAB",
    name: "Sabaragamuwa Province",
  },
};

export const ProvincesMatcher = {
  "1": "Central",
  "2": "Eastern",
  "3": "North Central",
  "4": "Northern",
  "5": "North Western",
  "6": "Sabaragamuwa",
  "7": "Southern",
  "8": "Uva",
  "9": "Western",
};

export const ProvincesCodeMatcher = {
  "1": "CP",
  "2": "EP",
  "3": "NC",
  "4": "NP",
  "5": "NW",
  "6": "SG",
  "7": "SP",
  "8": "UP",
  "9": "WP",
};

export const ProvincesIdMatcher = {
  "CP": "1", 
  "EP": "2", 
  "NC": "3", 
  "NP": "4", 
  "NW": "5", 
  "SG": "6", 
  "SP": "7", 
  "UP": "8", 
  "WP": "9", 
};

export const ProvincesByCodeMatcher = {
  "CP": "Central",
  "EP": "Eastern",
  "NC": "North Central",
  "NP": "Northern",
  "NW": "North Western",
  "SG": "Sabaragamuwa",
  "SP": "Southern",
  "UP": "Uva",
  "WP": "Western",
};

export const ProvincesForDealer = [
  {code: "CP", service: {name: "Central"}},
  {code: "EP", service: {name: "Eastern"}},
  {code: "NC", service: {name: "North Central"}},
  {code: "NP", service: {name: "Northern"}},
  {code: "NW", service: {name: "North Western"}},
  {code: "SG", service: {name: "Sabaragamuwa"}},
  {code: "SP", service: {name: "Southern"}},
  {code: "UP", service: {name: "Uva"}},
  {code: "WP", service: {name: "Western"}},
];

export const ProvincesPrefixMatcher = {
  "Central": "CP",
  "Eastern": "EP",
  "North Central": "NC",
  "Northern":"NP",
  "North Western":"NW",
  "Sabaragamuwa":"SG",
  "Southern":"SP",
  "Uva":"UP",
  "Western":"WP",
};

export const ContactTypeCode = {
  PHONE: "PHO",
  EMAIL: "EMA",
  FAX: "FAX",
  MOBILE: "MOB",
};

export const LicenceType = {
  NO: 'NL - Normal Revenue Licence',
  FE: 'FR - Free Licence',
  NU: 'NU - Non-User Licence',
  DE: `DL - Dealer's Licence`,
  CA: 'CN - Carnet Licence',
  NOT: 'NL-T - Normal Revenue Licence - Temporary',
  FET: 'FR-T - Free Licence - Temporary',
  CAT: 'CN-T - Carnet Licence - Temporary'
};

export const LicenceTypeForTopPanel = {
  NO: 'Normal',
  FE: 'Free',
  NU: 'Non-User',
  DE: 'Dealer',
  CA: 'Carnet'
};

export const RequestStatus = {
  APPROVED: 'APP',
  APPROVAL_PENDING: 'PEN',
  REJECTED: 'REJ',
  WITHDRAWN: 'WIT'
};

export const RequestType = {
  Add_Vehicle: 'add_vcl',
  Update_Vehicle: 'update_vcl',
  Vehicle_Transfer: 'vcl_transfer',
  Licence_Type_Change: 'chng_lcn_type',
  Vehicle_Status_Change: 'chng_vcl_status',
  Licence_Receipt_Reprint: 'reprint_lcn',
  Set_Effective_Date: 'set_eff_date',
  Change_Serial_Number: 'chng_se_num',
  Dealer_Change_Serial_Number: 'chng_dlr_se_num',
  Reverse_Last_Transaction: 'reverse_last_txn',
  Licence_Waive_Off: 'waiveoff_lcn',
  Request_Licence_Invalid_Documents: 'lcn_w_invalid_doc',
  Request_Licence_Licence_Arrears: 'lcn_w_arrears',
  Other_Additions_Deductions: 'other_ad_ded',
  Cancel_Licence_Receipt_Transaction: 'cncl_lcn_r_txn',
  Add_Licence_Receipt_Transaction: 'add_lcn_r_txn',
  Add_Dealer: 'add_dlr',
  Update_Dealer: 'update_dlr',
  Dealers_Licence_Reissuance: 'reissue_dlr_lcn',
  Dealers_Cancel_Licence_Receipt_Transaction: 'cncl_dlr_lcn_txn',
  Add_Licence_Transaction: 'add_lcn_txn',
  Dealers_Licence_Reprint: 'reprint_dlr_lcn'
};


export const ApplicabilityTypeCode = [
  {
    id: "GEN",
    value: "General",
  },
  {
    id: "VHS",
    value: "Vehicle Specific",
  },
];

export const ChargeEffectCode = [
  {
    id: "ADD",
    value: "Addition",
  },
  {
    id: "DED",
    value: "Deduction",
  },
];

export const ChargeTypeCode = [
  {
    id: "PRE",
    value: "Percentage",
  },
  {
    id: "FLR",
    value: "Flat Rate",
  },
];

export const WeightBaseCode = [
  {
    id: "GWT",
    value: "Gross Weight",
  },
  {
    id: "TWT",
    value: "Tare Weight",
  },
];

export const DateBaseCode = [
  {
    id: "VRD",
    value: "Vehicle Registration Date",
  },
  {
    id: "CUD",
    value: "Custom Date",
  },
];

export const DateDurationCode = [
  {
    id: "MON",
    value: "Month",
  },
  {
    id: "YER",
    value: "Year",
  },
];

export const EntryMode = [
  {
    id: "MIS",
    value: "Missing Licence / Receipt",
  },
  {
    id: "COR",
    value: "Licence / Receipt Correction",
  },
];

export const IssuedSystem = [
  {
    id: "ERL1",
    value: "ERL 1.0",
  },
  {
    id: "ERL2",
    value: "ERL 2.0",
  },
];

export const TransactionType = [
  {
    id: "LIC",
    value: "Licence",
  },
  {
    id: "ARR",
    value: "Arrears",
  },
];

export const IssueMode = [
  {
    id: "FUL",
    value: "Full Licence",
  },
  {
    id: "TMP",
    value: "Temporary Licence",
  },
];

export const IssueModeNonUser = [
  {
    id: "FUL",
    value: "Full Licence",
  },
];

export const RoleLevelCode = [
  {
    id: "INT_AU",
    value: "Authority",
  },
  {
    id: "INT_PRV",
    value: "Province",
  },
];

export const ActionListWaiveOff = [
  {
    id: 'FRD',
    value: 'Forward'
  },
];

export const ActionListLastTrxn = [
  {
    id: 'REJ',
    value: 'Reject'
  }
];

export const ActionList = [
  {
    id: 'FRD',
    value: 'Forward'
  },
  {
    id: 'APR',
    value: 'Approve'
  },
  {
    id: 'REJ',
    value: 'Reject'
  }
];

export const pdmtPaymentCategories = {
  CSH: "Cash",
  CHQ: "Cheque",
  CRD: "Debit/ Credit Card",
  ECH: "eZ-Cash",
  MCH: "M-Cash",
  SMV: "Sampath Vishwa",
  LGP: "LGPS",
  QR: "QR Payment",
  BOC: "BOC Transfer"
};
//do not change the order
export const paymentCategoriesArray = ['CSH', 'CHQ', 'CRD', 'ECH', 'MCH'];

export const PaymentCategoriesEnum = {
  CASH: "CSH",
  CHEQUE: "CHQ",
  CREDITCARDS: "CRD",
  EZCASH: "ECH",
  MCASH: "MCH",
};

export const RequestTypesList = {
  "add_vcl": "Add Vehicle",
  "update_vcl": "Update Vehicle",
  "vcl_transfer": "Transfer Vehicle",
  "chng_lcn_type": "Licence Type Change",
  "chng_vcl_status": "Vehicle Status Change",
  "reprint_lcn": "Licence / Receipt Reprint",
  "set_eff_date": "Set Effective Date",
  "chng_se_num": "Change Serial Number",
  "reverse_last_txn": "Reverse Last Transaction",
  "waiveoff_lcn": "Licence Waive Off",
  "lcn_w_invalid_doc": "Request Licence - Invalid Documents",
  "lcn_w_arrears": "Request Licence - Licence with Arrears",
  "other_ad_ded": "Other Additions / Deductions",
  "add_lcn_r_txn": "Add Licence / Receipt Transaction",
  "cncl_lcn_r_txn": "Cancel Licence / Receipt Transaction",
  "add_dlr": "Add Dealer",
  "update_dlr": "Update Dealer",
  "reprint_dlr_lcn": "Dealer Licence Reprint",
  "chng_dlr_se_num": "Dealer Change Serial Number",
  "reissue_dlr_lcn": "Dealer Licence Re-issuance",
  "add_lcn_txn": "Add Licence Transaction",
  "cncl_lcn_txn": "Cancel Licence Transaction"
}

export const ActionTypeList = {
  'FRD': 'Foward',
  'APR': 'Approved',
  'REJ': 'Rejected',
}

export const NotificationTypesList = {
  "Role_Change": "T01",
  "Serial_number_Change": "T02",
  "Approval_Requests": "T03",
  "ReOrder_Level_Reached": "T04",
  "ReOrder_Level_Reached": "T04",
  "Switch_Delegation":"T07"
}



export const RequestTypeList = [
  {
    id: "add_vcl",
    value: "Add Vehicle",
  },
  {
    id: "update_vcl",
    value: "Update Vehicle",
  },
  {
    id: "vcl_transfer",
    value: "Transfer Vehicle",
  },
  {
    id: "chng_lcn_type",
    value: "Licence Type Change",
  },
  {
    id: "chng_vcl_status",
    value: "Vehicle Status Change",
  },
  {
    id: "reprint_lcn",
    value: "Licence / Receipt Reprint",
  },
  {
    id: "set_eff_date",
    value: "Set Effective Date",
  },
  {
    id: "chng_se_num",
    value: "Change Serial Number",
  },
  {
    id: "reverse_last_txn",
    value: "Reverse Last Transaction",
  },
  {
    id: "waiveoff_lcn",
    value: "Licence Waive Off",
  },
  {
    id: "lcn_w_invalid_doc",
    value: "Request Licence - Invalid Documents",
  },
  {
    id: "lcn_w_arrears",
    value: "Request Licence - Licence with Arrears",
  },
  {
    id: "other_ad_ded",
    value: "Other Additions / Deductions",
  },
  {
    id: "add_lcn_txn",
    value: "Add Dealer Licence Transaction",
  },
  {
    id: "cncl_dlr_lcn_txn",
    value: "Cancel Dealer Licence Transaction",
  },
  {
    id: "add_lcn_r_txn",
    value: "Add Licence / Receipt Transaction",
  },
  {
    id: "cncl_lcn_r_txn",
    value: "Cancel Licence / Receipt Transaction",
  },
  {
    id: "add_dlr",
    value: "Add Dealer",
  },
  {
    id: "update_dlr",
    value: "Update Dealer",
  },
  {
    id: "reprint_dlr_lcn",
    value: "Reprint Dealer Licence",
  },
  {
    id: "chng_dlr_se_num",
    value: "Change Dealer Serial Number",
  },
  
];

export const VehicleStatus = [
  {
    id: "INE",
    value: "Ineligible",
  },
  {
    id: "ELI",
    value: "Eligible",
  },
  {
    id: "BLK",
    value: "Blacklisted",
  },
  {
    id: "ACH",
    value: "Archived",
  },
];

export const CurrentLicenceStatus = [
  {
    id: "VA",
    value: "Valid",
  },
  {
    id: "EX",
    value: "Expired",
  },
  {
    id: "NL",
    value: "No Current Licence",
  },
  {
    id: "RE",
    value: "Renewable",
  },
  {
    id: "NA",
    value: "N/A",
  }
];

export const ProvincesByIds = [
  {
    id: "1",
    value: "CENTRAL",
  },
  {
    id: "2",
    value: "EASTERN",
  },
  {
    id: "3",
    value: "NORTH CENTRAL",
  },
  {
    id: "4",
    value: "NORTHERN",
  },
  {
    id: "5",
    value: "NORTH WESTERN",
  },
  {
    id: "6",
    value: "SABARAGAMUWA",
  },
  {
    id: "7",
    value: "SOUTHERN",
  },
  {
    id: "8",
    value: "UVA",
  },
  {
    id: "9",
    value: "WESTERN",
  }
];

export const TargetType = {
  AMOUNT: "A",
  DATE: "D"
};

export const MaxArrearsPeriod = {
  NumberOfYears: 2
}

export const LicenceTypeCodes = {
  TEMPORARY_LICENCE : "TL",
  ARREARS_ONLY : "AO",
  QUOTATIONS : "QT",
  WEB_LICENCE : "WL",
  NORMAL_LICENCE : "NO",
  FREE_LICENCE : "FE",
  NON_USER_LICENCE : "NU",
  CARNET_LICENCE : "CA",
  DEALERS_LICENCE : "DE",
}