import React, { useContext, Fragment, useState, useEffect } from "react";
import { DataTable, actionBtnTypes } from "../../../../ui-components/ui-elements/table/DataTable";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { SubmitButton } from "../../../../ui-components/ui-elements/form/Button";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { _getInt, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { usePermission } from "../../../../hooks/common-hooks/usePermission.hook";
import { SearchElement, SearchElementUnAssign } from "../my-schedule-checklist/my-schedule-checklist-includes/PageElements"
import ApprovalRequestPopup from "../includes/ApprovalRequestPopup";
import { PendingApprovalRequest } from "../includes/PendingApprovalRequest";
import { getAllTask, reqstUserUnassignAPI } from "../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { UnassignSubmitButton } from "../my-schedule-checklist/my-schedule-checklist-includes/UnassignSubmitButton";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { WithDrawSubmitButton } from "../includes/WithDrawSubmitButton";
import { LicenceReprintBtn } from "../../../counter-session/pages/dealers-licence/includes/LicenceReprint";
import { ChangeSerialNumberBtn } from "../../../counter-session/pages/dealers-licence/includes/ChangeSerialNumber";
import { DealersLicenceReissuanceBtn } from "../../../counter-session/pages/dealers-licence/includes/DealersLicenceReissuance";
import { LicenceChageSubmitBtn } from '../../../request-for-approval/pages/LicenceTypeChange'
import { StatusSubmitBtn } from '../../../request-for-approval/pages/VehicleStatusChange'
import { AddVehicleBtn } from '../../../request-for-approval/pages/AddVehicle'
import { UpdateVehicleBtn } from '../../../request-for-approval/pages/UpdateVehicle'
import { VehicleRequestSubmitBtn } from '../../../request-for-approval/pages/TransferVehicle'
import { ReverseTransactionsSubmitBtn } from '../../../request-for-approval/pages/ReverseLastTransaction'
import { InvalidDocumentsSubmitButton } from '../../../request-for-approval/pages/RequestLicenceInvalidDoc'
import { LicenceReprintSubmitButton } from '../../../request-for-approval/pages/LicenceReprint'
import { SetDateSubmitBtn } from '../../../request-for-approval/pages/SetEffectiveDate'
import { WaveOffSubmitBtn } from '../../../request-for-approval/pages/LicenceWaveOff'
import { LicenceArrearsSubmitButton } from '../../../request-for-approval/pages/RequestLicenceArrears'
import { OtherADSubmitBtn } from '../../../request-for-approval/pages/OtherAdditionsDeductions'
import { ChangeSerialSubmitButton } from '../../../request-for-approval/pages/ChangeSerialNumber'
import { RequestTypeList } from '../../../../../config/enum.config';
import { getCodeName, isEmptyValue } from '../../../../../helpers/common-helpers/common.helpers';
import { AddDealerBtn } from "../../../request-for-approval/pages/AddDealer";
import { UpdateDealerBtn } from "../../../request-for-approval/pages/UpdateDealer";
import { LcnSubmitBtn } from "../../../request-for-approval/pages/includes/AddLicenceUI";
import { CancelLcnSubmitBtn } from "../../../request-for-approval/pages/CancelLicence";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { isEmpty } from "lodash";
import { TransactionSubmitBtn } from "../../../counter-session/pages/dealers-licence/includes/AddLicenceTransaction";
import { CancelDlrLcnSubmitBtn } from "../../../counter-session/pages/dealers-licence/includes/CancelLicence";

const emptyFn = (...para) => undefined;

const MyPendingSchedule = (props) => {

  const [authState] = useContext(AuthContext);
  const [, checkISAllowedFn] = usePermission();
  const [, coreAction] = useContext(CoreContext);
  const [formState, formAction] = useContext(FormContext);

  const WithdrawUI = () => {
    return (
      <div className="fullWidthDiv">
        <p>Are you sure want to withdraw this approval request?</p>
      </div>
    )
  }
  const roleCategoryCode = _get(authState, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authState, "authUser.pdmtId.id", "") : _get(authState, "authUser.pdmtId", "");
  const userServiceId = _get(authState, "authUser.service_id", "");

  const refreshMainScreenAfterPerformFn = () => {
    coreAction
      .sendRequestFn(`${getAllTask.url}?assigned_user_id=notnull&from=${dateObjectToString(new Date(), "YY-mm-dd")}&to=${dateObjectToString(new Date(), "YY-mm-dd")}&sort_by=created_at|asc`)
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: `${getAllTask.key}`,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          coreAction.setDataTable(`${getAllTask.key}`, result.data.data, 'done', 1, null, null);
        } else {
          console.log('ERRRRRRR===', error);
        }
      });
  }

  return (
    <div className="fullWidthDiv">
      <DataTable
        tableName="Tasks"
        apiUrl={`${getAllTask.url}?assigned_user_id=notnull&sort_by=created_at|asc`}
        dataTableKey={getAllTask.key}
        isSetSearchFrom={true}
        searchFromComponent={<SearchElement dataTableKey={`${getAllTask.key}_search`} />}
        defaultSearchFormObject={{
          "vehicle_no": "",
          "request_type": null,
          "province": pdmtId,
          "authority": _get(authState, "authUser.authorityId", ""),
          "licence_type": null,
          "from": dateObjectToString(new Date(), "YY-mm-dd"),
          "to": dateObjectToString(new Date(), "YY-mm-dd"),
          "ui_request_id": "",
          "vehicle_no": "",
          "dealer_id": "",
          "show": null,
          "approved": 'true',
          "approvalPending": 'true',
          "rejected": 'true',
          "withdrawn": 'true',
          "isAtleastOneChecked": 'true'
        }}
        onSetSearchObjectFn={(searchObject) => {
          let tempSearchObject = { ...searchObject };

          if (!isEmptyValue(searchObject.from)) {
            tempSearchObject["from"] = dateObjectToString(searchObject.from, "YY-mm-dd");
          }

          if (!isEmptyValue(searchObject.to)) {
            tempSearchObject["to"] = dateObjectToString(searchObject.to, "YY-mm-dd");
          }
          return tempSearchObject;
        }}
        tableHeaderList={[
          { displayName: "Request ID", key: "id", isSort: false },
          {
            displayName: "Vehicle ID / Dealer ID", key: "vehicleDealerId", isSort: false,
            onPrintFn: (rawData) => {
              if (rawData.vehicle_no === null) {
                return rawData.dealer_id
              } else if (rawData.dealer_id === null) {
                return rawData.vehicle_no
              }
            }
          },
          //{ displayName: "Licence Type", key: "current_licence_type", isSort: false, onPrintFn: (rawData) => { return LicenceType[rawData.current_licence_type] } },
          {
            displayName: "Request Type", key: "request_type",
            onPrintFn: (rawData) => {
              return getCodeName(RequestTypeList, rawData.request_type)
            }, isSort: false
          },
          { displayName: "Requested Date and Time", key: "created_at", isSort: false, onPrintFn: (rawData) => { return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM") } },
          { displayName: "Requested by", key: "created_by_name", isSort: false, onPrintFn: (rawData) => rawData.created_by_name + "(" + rawData.created_role_name + ")" },
          { displayName: "Requested Location", key: "created_location_name", isSort: false, onPrintFn: (rawData) => rawData.created_location_province_code + "(" + rawData.created_location_name + ")" },
          {
            displayName: "Approved Date and Time", key: "approved_time", isSort: false, onPrintFn: (rawData) => {
              if (!isEmpty(rawData.approved_time)) {
                return dateObjectToString(rawData.approved_time, "dd/mm/YY HH:MM")
              }
              else {
                return ""
              }
            }
          },
          {
            displayName: "Approved by", key: "approved_by", isSort: false, onPrintFn: (rawData) =>( rawData.approved_by_name ?
              rawData.approved_by_name:"") + ( rawData.appoved_role_name ? "(" + rawData.appoved_role_name + ")" : "")
  },
          {
            displayName: "Approved Location", key: "approved_location", isSort: false, onPrintFn: (rawData) => (!isEmpty(rawData.approved_location_province_code) ? rawData.approved_location_province_code : "" )+ (!isEmpty(rawData.approved_location_name) ? "(" + rawData.approved_location_name + ")" : "")
          },
          {
            displayName: "Status", key: "status", onPrintFn: (rawData) => {
              if (rawData.status === 'APP') {
                return 'Approved'
              } else if (rawData.status === 'PEN') {
                return 'Pending'
              } else if (rawData.action === 'FRD' && rawData.status === 'REJ') {
                return 'Withdrawn'
              } else {
                return 'Rejected'
              }
            }, isSort: false
          },
        ]}
        isSetAction={true}
        actionTypeList={(roleCategoryCode === 'OPT') ? [
          {
            actionBtn: { tooltip: 'View' },
            actionType: actionBtnTypes.view,
            modelContentElement: ApprovalRequestPopup,
            dialogPopupProps: {
              "isFullScreen": true,
              "dialogTitle": "View Approval Request"
            }
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { icon: "fab fa-bandcamp", tooltip: 'Withdraw' },
            modelContentElement: WithdrawUI,
            dialogPopupProps: {
              isFullScreen: false,
              isFullWidth: false,
              dialogTitle: "Withdraw Approve Request",
              closeButton: {
                btnText: "No",
                startIcon: "far fa-times-circle",
              },
              isCustomButton: true,
              customButtonComponent: WithDrawSubmitButton,
              customButtonComponentProps: {
                uiType: "withdraw",
                formKey: getAllTask.key,
                refreshMainScreenAfterPerformFn: refreshMainScreenAfterPerformFn
              }
            },
            checkBeforeActionRenderFn: (rawData) => {
              return (_get(rawData, "row.status", "") === "PEN" && (userServiceId === _get(rawData, "row.created_location", "")));
            }
          },
        ] : [
          {
            actionBtn: { tooltip: 'View' },
            actionType: actionBtnTypes.view,
            modelContentElement: ApprovalRequestPopup,
            dialogPopupProps: {
              "isFullScreen": true,
              "dialogTitle": "View Approval Request"
            }
          },
        ]}

      />
    </div>
  );
};

const UnAssignedTasks = (props) => {
  // const { historyPushData } = props
  const [formState, formAction] = useContext(FormContext);
  const [, coreAction] = useContext(CoreContext);

  const [, uiAction] = useContext(UIContext);
  const [authState] = useContext(AuthContext);
  const [, checkISAllowedFn] = usePermission();
  const [refresh, setRefresh] = useState(true)
  const emptyFn = (...para) => undefined;

  const roleCategoryCode = _get(authState, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authState, "authUser.pdmtId.id", "") : _get(authState, "authUser.pdmtId", "");
  
  useEffect(() => {
    if(props.row.historyPushData && props.row.historyPushData.request_id && props.row.historyPushData.request_id !== undefined) {
      getDataWhenComingFromNotificationViewTasks(props)
    }
  }, [])

  const CustomButtonsSave = ({
    onClosePopupFn = emptyFn,
    formGroupName = ""
  }) => {

    const close = () => {
      onClosePopupFn();
    }


    return (
      <Fragment>
        <SubmitButton
          btnText="Save & Continue"
          elementStyle="btnWrapper"
        />

        <SubmitButton
          btnText="Skip to next Request"
          elementStyle="btnWrapper"
        />
      </Fragment>
    )
  }

  

  const getDataWhenComingFromNotificationViewTasks = (props) => {
    coreAction
      .sendRequestFn(`${getAllTask.url}?province=${pdmtId}&forward_role=${roleCategoryCode}&isApproved=false&from=${dateObjectToString(new Date(), "YY-mm-dd")}&to=${dateObjectToString(new Date(), "YY-mm-dd")}&sort_by=created_at|desc`)
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: `${getAllTask.key}`,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          // if(result.data.data && result.data.data.length !== 0){
          coreAction.setDataTable(`${getAllTask.key}`, result.data.data, 'done', 1, null, null);

          //   const notifiedData = result.data.data.filter(value => value.request_id === props.row.historyPushData.request_id)
            
          //   if(notifiedData.length === 0) {
              
          //     const newNotifyData = result.data.data.filter(value => value.id === props.row.historyPushData.request_id)
          //     if(newNotifyData.length !== 0) {
          //       coreAction.setDataTable(`${getAllTask.key}`, newNotifyData, 'done', 1, null, null);
          //     } else {
          //       coreAction.setDataTable(`${getAllTask.key}`, [], 'done', 1, null, null);
          //     }
          //   } else {
          //     coreAction.setDataTable(`${getAllTask.key}`, notifiedData, 'done', 1, null, null);
          //   }
          // } 
        } else {
          console.log('ERRRRRRR===', error);
        }
      });
  }
  const refreshMainScreenAfterPerformFn = () => {
    coreAction
      .sendRequestFn(`${getAllTask.url}?province=${pdmtId}&forward_role=${roleCategoryCode}&isApproved=false&from=${dateObjectToString(new Date(), "YY-mm-dd")}&to=${dateObjectToString(new Date(), "YY-mm-dd")}&sort_by=created_at|asc`)
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: `${getAllTask.key}`,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          coreAction.setDataTable(`${getAllTask.key}`, result.data.data, 'done', 1, null, null);
        } else {
          console.log('ERRRRRRR===', error);
        }
      });
  }

  const getTable = (props) => {
    if(props.row.historyPushData === undefined) {
      return (
        <DataTable
        tableName="Un Assigned Tasks"
        //apiUrl={`${getAllTask.url}?province=${pdmtId}&assigned_user_id=null&isApproved=false`}
        apiUrl={`${getAllTask.url}?forward_role=${roleCategoryCode}&isApproved=false&sort_by=created_at|asc`}
        dataTableKey={`${getAllTask.key}`}
        isSetSearchFrom={true}
        searchFromComponent={<SearchElementUnAssign dataTableKey={`${getAllTask.key}_search`} />}
        isOnCloseFunc={true}
        onCloseFunc={refreshMainScreenAfterPerformFn}
        defaultSearchFormObject={{
          "vehicle_no": "",
          "request_type": null,
          "province": pdmtId,
          "authority": _get(authState, "authUser.authorityId", ""),
          "licence_type": null,
          "from": dateObjectToString(new Date(), "YY-mm-dd"),
          "to": dateObjectToString(new Date(), "YY-mm-dd"),
          "ui_request_id": "",
          "vehicle_no": "",
          "dealer_id": "",
        }}
        onSetSearchObjectFn={(searchObject) => {
          let tempSearchObject = { ...searchObject };

          if (!isEmptyValue(searchObject.from)) {
            tempSearchObject["from"] = dateObjectToString(searchObject.from, "YY-mm-dd");
          }

          if (!isEmptyValue(searchObject.to)) {
            tempSearchObject["to"] = dateObjectToString(searchObject.to, "YY-mm-dd");
          }
          return tempSearchObject;
        }}
        tableHeaderList={[
          { displayName: "Request ID", key: "id", isSort: false },
          {
            displayName: "Vehicle ID / Dealer ID", key: "vehicleDealerId", isSort: false,
            onPrintFn: (rawData) => {
              if (rawData.vehicle_no === null) {
                return rawData.dealer_id
              } else if (rawData.dealer_id === null) {
                return rawData.vehicle_no
              }
            }
          },
          //{ displayName: "Licence Type", key: "current_licence_type", isSort: false, onPrintFn: (rawData) => { return LicenceType[rawData.current_licence_type] } },
          {
            displayName: "Request Type", key: "request_type",
            onPrintFn: (rawData) => {
              return getCodeName(RequestTypeList, rawData.request_type)
            }, isSort: false
          },
          { displayName: "Requested Date and Time", key: "created_at", isSort: false, onPrintFn: (rawData) => { return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM") } },
          { displayName: "Requested by", key: "created_by_name", isSort: false, onPrintFn: (rawData) => rawData.created_by_name + "(" + rawData.created_role_name + ")" },
          { displayName: "Requested Location", key: "created_location_name", isSort: false, onPrintFn: (rawData) => rawData.created_location_province_code + "(" + rawData.created_location_name + ")" },
        ]}
        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: 'View' },
            modelContentElement: ApprovalRequestPopup,
            dialogPopupProps: {
              "isFullScreen": true,
              "dialogTitle": "Approval Request"
            }
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { icon: "mdi mdi-account-multiple-plus", tooltip: 'Assign & Perform' },
            modelContentElement: PendingApprovalRequest,
            dialogPopupProps: {
              isFullScreen: true,
              isCustomButton: true,
              customButtonComponent: CustomButtonsSaveAndSkip,
              customButtonComponentProps: { refreshMainScreenAfterPerformFn: refreshMainScreenAfterPerformFn, uiType: "assign&Perform", formGroupName: getAllTask.key, dataTableKey: `${getAllTask.key}Unassigned` },
              dialogTitle: "Pending Approval Request",
              closeButton: {
                btnText: "Cancel",
                btnName: 'close_model',
                startIcon: 'far fa-times-circle',
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              uiType: "assign&Perform",
              formKey: getAllTask.key,
            },
          },
        ]}
      />
      )
    } else {
      return (
        <DataTable
        tableName="Un Assigned Tasks"
        //apiUrl={`${getAllTask.url}?province=${pdmtId}&assigned_user_id=null&isApproved=false`}
        // apiUrl={`${getAllTask.url}?forward_role=${roleCategoryCode}&isApproved=false&sort_by=created_at|asc`}
        dataTableKey={`${getAllTask.key}`}
        isSetSearchFrom={true}
        searchFromComponent={<SearchElementUnAssign dataTableKey={`${getAllTask.key}_search`} />}
        isOnCloseFunc={true}
        onCloseFunc={refreshMainScreenAfterPerformFn}
        defaultSearchFormObject={{
          "vehicle_no": "",
          "request_type": null,
          "province": pdmtId,
          "authority": _get(authState, "authUser.authorityId", ""),
          "licence_type": null,
          "from": dateObjectToString(new Date(), "YY-mm-dd"),
          "to": dateObjectToString(new Date(), "YY-mm-dd"),
          "ui_request_id": "",
          "vehicle_no": "",
          "dealer_id": "",
        }}
        onSetSearchObjectFn={(searchObject) => {
          let tempSearchObject = { ...searchObject };

          if (!isEmptyValue(searchObject.from)) {
            tempSearchObject["from"] = dateObjectToString(searchObject.from, "YY-mm-dd");
          }

          if (!isEmptyValue(searchObject.to)) {
            tempSearchObject["to"] = dateObjectToString(searchObject.to, "YY-mm-dd");
          }
          return tempSearchObject;
        }}
        tableHeaderList={[
          { displayName: "Request ID", key: "id", isSort: false },
          {
            displayName: "Vehicle ID / Dealer ID", key: "vehicleDealerId", isSort: false,
            onPrintFn: (rawData) => {
              if (rawData.vehicle_no === null) {
                return rawData.dealer_id
              } else if (rawData.dealer_id === null) {
                return rawData.vehicle_no
              }
            }
          },
          //{ displayName: "Licence Type", key: "current_licence_type", isSort: false, onPrintFn: (rawData) => { return LicenceType[rawData.current_licence_type] } },
          {
            displayName: "Request Type", key: "request_type",
            onPrintFn: (rawData) => {
              return getCodeName(RequestTypeList, rawData.request_type)
            }, isSort: false
          },
          { displayName: "Requested Date and Time", key: "created_at", isSort: false, onPrintFn: (rawData) => { return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM") } },
          { displayName: "Requested by", key: "created_by_name", isSort: false, onPrintFn: (rawData) => rawData.created_by_name + "(" + rawData.created_role_name + ")" },
          { displayName: "Requested Location", key: "created_location_name", isSort: false, onPrintFn: (rawData) => rawData.created_location_province_code + "(" + rawData.created_location_name + ")" },
        ]}
        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: 'View' },
            modelContentElement: ApprovalRequestPopup,
            dialogPopupProps: {
              "isFullScreen": true,
              "dialogTitle": "Approval Request"
            }
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { icon: "mdi mdi-account-multiple-plus", tooltip: 'Assign & Perform' },
            modelContentElement: PendingApprovalRequest,
            dialogPopupProps: {
              isFullScreen: true,
              isCustomButton: true,
              customButtonComponent: CustomButtonsSaveAndSkip,
              customButtonComponentProps: { refreshMainScreenAfterPerformFn: refreshMainScreenAfterPerformFn, uiType: "assign&Perform", formGroupName: getAllTask.key, dataTableKey: `${getAllTask.key}Unassigned` },
              dialogTitle: "Pending Approval Request",
              closeButton: {
                btnText: "Cancel",
                btnName: 'close_model',
                startIcon: 'far fa-times-circle',
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              uiType: "assign&Perform",
              formKey: getAllTask.key,
            },
          },
        ]}
      />
      )
    }
  }

  return (
    <div className="fullWidthDiv">
      
      {getTable(props)}
    </div>
  );
};

const CustomButtonsSaveAndSkip = (props, {
  refreshMainScreenAfterPerformFn = emptyFn,
  onClosePopupFn = emptyFn,
  formGroupName = "",
  dataTableKey = ""
}) => {
  const close = () => {
    onClosePopupFn();
  }

  const getSubmitButton = (props) => {
    switch (props.row.request_type) {
      case "other_ad_ded":
        return (
          <OtherADSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "lcn_w_arrears":
        return (
          <LicenceArrearsSubmitButton refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "chng_se_num":
        return (
          <ChangeSerialSubmitButton refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "waiveoff_lcn":
        return (
          <WaveOffSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "set_eff_date":
        return (
          <SetDateSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "reprint_lcn":
        return (
          <LicenceReprintSubmitButton refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "lcn_w_invalid_doc":
        return (
          <InvalidDocumentsSubmitButton refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "reverse_last_txn":
        return (
          <ReverseTransactionsSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "vcl_transfer":
        return (
          <VehicleRequestSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "update_vcl":
        return (
          <UpdateVehicleBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "add_vcl":
        return (
          <AddVehicleBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "chng_vcl_status":
        return (
          <StatusSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'vehicle-status-change'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "chng_lcn_type":
        return (
          <LicenceChageSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'licence_type_change'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "reprint_dlr_lcn":
        return (
          <LicenceReprintBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "chng_dlr_se_num":
        return (
          <ChangeSerialNumberBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealer_Change_Serial_Number'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "reissue_dlr_lcn":
        return (
          <DealersLicenceReissuanceBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'Dealers_Licence_Reissuance'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "add_dlr":
        return (
          <AddDealerBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'dealerVehicleTempAPIKey_create'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "update_dlr":
        return (
          <UpdateDealerBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'dealerVehicleTempAPIKey_create'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "add_lcn_txn":
        return (
          <TransactionSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'add-licence'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "cncl_dlr_lcn_txn":
        return (
          <CancelDlrLcnSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'add-licence'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "add_lcn_r_txn":
        return (
          <LcnSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'add-licence'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      case "cncl_lcn_r_txn":
        return (
          <CancelLcnSubmitBtn refreshMainScreenAfterPerformFn={props.refreshMainScreenAfterPerformFn} uiType={props.uiType} onClosePopupFn={props.onClosePopupFn} formKey={'add-licence'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {getSubmitButton(props)}
    </Fragment>

  )
}

const AssignedTasks = (props) => {

  const [authState] = useContext(AuthContext);
  const [, checkISAllowedFn] = usePermission();
  const emptyFn = (...para) => undefined;
  const [refresh, setRefresh] = useState(true)
  const [, coreAction] = useContext(CoreContext);



  const CustomButtonsSave = ({
    onClosePopupFn = emptyFn,
    formGroupName = ""
  }) => {

    const close = () => {
      onClosePopupFn();
    }

    return (
      <Fragment>
        <SubmitButton
          btnText="Submit"
          elementStyle="btnWrapper"
        />

      </Fragment>
    )
  }
  const currentUserId = _get(authState, "authUser.id", null);
  const roleCategoryCode = _get(authState, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authState, "authUser.pdmtId.id", "") : _get(authState, "authUser.pdmtId", "");

  const refreshMainScreenAfterPerformFn = () => {
    coreAction
      .sendRequestFn(`${getAllTask.url}?assigned_user_id=${currentUserId}&from=${dateObjectToString(new Date(), "YY-mm-dd")}&to=${dateObjectToString(new Date(), "YY-mm-dd")}&sort_by=created_at|asc`)
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: `${getAllTask.key}Assigned`,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          coreAction.setDataTable(`${getAllTask.key}Assigned`, result.data.data, 'done', 1, null, null);
        } else {
          console.log('ERRRRRRR===', error);
        }
      });
  }

  return (
    <div className="fullWidthDiv">
      <DataTable
        tableName="Assigned Tasks"
        apiUrl={`${getAllTask.url}?assigned_user_id=${currentUserId}&sort_by=created_at|asc`}
        dataTableKey={`${getAllTask.key}Assigned`}
        isSetSearchFrom={true}
        searchFromComponent={<SearchElementUnAssign dataTableKey={`${getAllTask.key}Assigned_search`} />}

        defaultSearchFormObject={{
          "vehicle_no": "",
          "request_type": null,
          "province": pdmtId,
          "authority": _get(authState, "authUser.authorityId", ""),
          "licence_type": null,
          "from": dateObjectToString(new Date(), "YY-mm-dd"),
          "to": dateObjectToString(new Date(), "YY-mm-dd"),
          "ui_request_id": "",
          "vehicle_no": "",
          "dealer_id": "",
        }}
        onSetSearchObjectFn={(searchObject) => {
          let tempSearchObject = { ...searchObject };

          if (!isEmptyValue(searchObject.from)) {
            tempSearchObject["from"] = dateObjectToString(searchObject.from, "YY-mm-dd");
          }

          if (!isEmptyValue(searchObject.to)) {
            tempSearchObject["to"] = dateObjectToString(searchObject.to, "YY-mm-dd");
          }
          return tempSearchObject;
        }}
        tableHeaderList={[
          { displayName: "Request ID", key: "id", isSort: false },
          {
            displayName: "Vehicle ID / Dealer ID", key: "vehicleDealerId", isSort: false,
            onPrintFn: (rawData) => {
              if (rawData.vehicle_no === null) {
                return rawData.dealer_id
              } else if (rawData.dealer_id === null) {
                return rawData.vehicle_no
              }
            }
          },
          // { displayName: "Licence Type", key: "current_licence_type", isSort: false, onPrintFn: (rawData) => { return LicenceType[rawData.current_licence_type] } },
          {
            displayName: "Request Type", key: "request_type",
            onPrintFn: (rawData) => {
              return getCodeName(RequestTypeList, rawData.request_type)
            }, isSort: false
          },
          { displayName: "Requested Date and Time", key: "created_at", isSort: false, onPrintFn: (rawData) => { return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM") } },
          { displayName: "Requested by", key: "created_by_name", isSort: false, onPrintFn: (rawData) => rawData.created_by_name + "(" + rawData.created_role_name + ")" },
          { displayName: "Requested Location", key: "created_location_name", isSort: false, onPrintFn: (rawData) => rawData.created_location_province_code + "(" + rawData.created_location_name + ")" },
          {
            displayName: "Status", key: "status", onPrintFn: (rawData) => {
              if (rawData.status === 'APP') {
                return 'Approved'
              } else if (rawData.status === 'PEN') {
                return 'Pending'
              } else if (rawData.action === 'FRD' && rawData.status === 'REJ') {
                return 'Withdrawn'
              } else {
                return 'Rejected'
              }
            }, isSort: false
          },
        ]}
        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: 'View' },
            modelContentElement: ApprovalRequestPopup,
            dialogPopupProps: {
              "isFullScreen": true,
              "dialogTitle": "View Approval Request"
            },
            parentToModelProps: {
              ui_type: "assigned"
            },
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { icon: "mdi mdi-server-plus", tooltip: 'Perform' },
            modelContentElement: PendingApprovalRequest,
            dialogPopupProps: {
              isFullScreen: true,
              isCustomButton: true,
              customButtonComponent: CustomButtonsSaveAndSkip,
              customButtonComponentProps: { refreshMainScreenAfterPerformFn: refreshMainScreenAfterPerformFn, uiType: "assign&Perform", formGroupName: getAllTask.key, dataTableKey: `${getAllTask.key}Assigned` },
              dialogTitle: "Pending Approval Request",
              closeButton: {
                btnText: "Cancel",
                startIcon: 'far fa-times-circle'
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              formKey: getAllTask.key,
              uiType: "assign&Perform",
            },
            checkBeforeActionRenderFn: (rawData) => {
              return (_get(rawData, "row.action", "") === "FRD")
            }
          },
          // {
          //   actionType: actionBtnTypes.edit,
          //   actionBtn: { icon: "mdi mdi-clipboard-text", tooltip: 'Acknowledge' },
          //   modelContentElement: AcknowledgeApprovalRequest,
          //   dialogPopupProps: {
          //     isFullScreen: true,
          //     isCustomButton: true,
          //     dialogTitle: "Acknowledge Approval Request",
          //     closeButton: {
          //       btnText: "Cancel",
          //       startIcon: 'far fa-times-circle'
          //     },
          //     customButtonComponent: AcknowledgeSubmitButton,
          //     isConfirmationPopup: true,
          //     customButtonComponentProps: { refreshMainScreenAfterPerformFn: refreshMainScreenAfterPerformFn, dataTableKey: getAllTask.key },
          //   },
          //   parentToModelProps: {
          //     uiType: "Acknowledge",
          //     formKey: getAllTask.key,
          //   },
          //   checkBeforeActionRenderFn: (rawData) => {
          //     return _get(rawData, "row.action", "") === "APR";
          //   }
          // },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { icon: "mdi mdi-account-check", tooltip: 'Un-Assign' },
            modelContentElement: UserUnAssignUI,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "User unassign",
              closeButton: {
                btnText: "No",
              },
              isCustomButton: true,
              customButtonComponent: UnassignSubmitButton,
              customButtonComponentProps: { refreshMainScreenAfterPerformFn: refreshMainScreenAfterPerformFn, dataTableKey: getAllTask.key },
            },
            parentToModelProps: {
              uiType: "Unassign",
              formKey: reqstUserUnassignAPI.key,
            },
            checkBeforeActionRenderFn: (rawData) => {
              return _get(rawData, "row.action", "") === "FRD";
            }
          },
        ]}
      />
    </div>
  );
};

const UserUnAssignUI = (props) => {
  return (
    <FormWrapper
      setGroupName={props.fromParent.formKey}
      formGroupLinkWith={props.fromParent.formKey}
      setFormObject={{
        request_id: _get(props, "row.request_id", null),
        assigned_user_id: null
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="fullWidthDiv">
        <p>Are you sure you want to unassign?</p>
      </div>

    </FormWrapper>

  )
};

export {
  MyPendingSchedule, UnAssignedTasks, AssignedTasks
};
