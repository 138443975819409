/*
 * @Author: Nipuna Chandimal
 * @Date: 2020-09-22 16:06:43
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:25:21
 */
import React, { useState, useContext, useEffect } from "react";
import { _get, _unset, _find } from "../../../../helpers/common-helpers/lodash.wrappers";
import { PaddingLessUICard } from "../../../ui-components/ui-elements/common/BaseElements.js";
import { InputButton, FormWrapper, RoundButton, roundBtnSizes } from "../../../ui-components/ui-elements/form";
import { DealerLicenseDetails } from "./includes/DealerLicenseDetails.js";
import { PaymentType } from "./includes/PaymentType.js";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup.js";
import { AuthContext } from '../../core/context-providers/AuthContext.provider';
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { thousandSeparator, getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { isEmpty } from "lodash";
import { ConfirmationPopupDealer } from "./includes/ConfirmationPopupDealer.js";
import { dealerVehiceLicenceListAPI } from "../../../../config/apiUrl.config.js";
import { PaymentCategoriesEnum } from "../../../../config/enum.config.js";
import { callApi } from '../../../../helpers/common-helpers/callApi.helpers';

import { availableSessionListApi, getAvailabelSerialNumbersAPI } from '../../../../config/apiUrl.config';

const emptyFn = (...para) => undefined;

const IssueDealer = ({ uiType = "dealer", formGroupName = "issueDealer",
  latestLicence = {},
  closeMainPopup = emptyFn
}) => {
  const searchFormKey = "dealer_search_form";
  const vehicleLicenseListKey = "dealer_license_vehiclelist_form"
  const [openPrint, setOpenPrint] = useState({ showPopup: false, });

  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const user_id = _get(authStatus, "authUser.id", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const issuedPdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const [index, setIndex] = useState(0); // create state to keep track of licence index, set the default index to 0

  const slideRight = () => {
    setIndex((index + 1) % _get(formState, `${vehicleLicenseListKey}.data`, []).length); // increases index by 1
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(_get(formState, `${vehicleLicenseListKey}.data`, []).length - 1); // returns last index of licence array if index is less than 0
    } else {
      setIndex(nextIndex);
    }
  };

  useEffect(() => {
    if (_get(formState, `${vehicleLicenseListKey}.data`, null)) {
      callApi(`${availableSessionListApi.url}/${user_id}/sessions/list`)
        .method("get")
        .headers(true)
        .body({})
        .isMultipart(false)
        .send(async (error, result) => {
          if (!error) {
            const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");
            const counter_id = isEmpty(_get(result, "data.data[0].counter_id.id", "")) ? "" : _get(result, "data.data[0].counter_id.id", "");
            const counter_code = isEmpty(_get(result, "data.data[0].counter_id.code", "")) ? "" : _get(result, "data.data[0].counter_id.code", "");
            const counter_serial_number_range_id = isEmpty(_get(result, "data.data[0].counter_id.current_counter_serial_number_range_id", "")) ? null : _get(result, "data.data[0].counter_id.current_counter_serial_number_range_id", "");
            const session_id = isEmpty(_get(result, "data.data[0].id", "")) ? "" : _get(result, "data.data[0].id", "");
            formAction.changeInputFn(formGroupName, "next_serial", "", next_serial)
            formAction.changeInputFn(formGroupName, "counter_id", "", counter_id)
            formAction.changeInputFn(formGroupName, "counter_code", "", counter_code)
            formAction.changeInputFn(formGroupName, "sessionId", "", session_id)
            formAction.changeInputFn(formGroupName, "counter_serial_number_range_id", "", counter_serial_number_range_id)
            callApi(`${getAvailabelSerialNumbersAPI.url}?serial_number=${next_serial}&counter_id=${counter_id}&counter_serial_number_range_id=${counter_serial_number_range_id}&records_limit=${_get(formState, `${vehicleLicenseListKey}.data`, []).length}`)
              .method("get")
              .headers(true)
              .body({})
              .isMultipart(false)
              .send(async (error, result) => {
                if (result._statue == true) {
                  formAction.changeInputFn(formGroupName, "serial_numbers", "", result.data)
                }
              })

          }
        });
    }

  }, [_get(formState, `${vehicleLicenseListKey}.data`, null)]);

  const onPrintFn = () => {
    const paymentFormKey = "paymentTypeFormKey";
    const errorObject = [];
    const paymentFormObj = getDataByFormObject(_get(formState, paymentFormKey, {}));
    _unset(paymentFormObj, "isDisplay");
    _unset(paymentFormObj, "payment_categories");
    _unset(paymentFormObj, "paid_amount");
    _unset(paymentFormObj, "cash_amount");
    _unset(paymentFormObj, "cheque_amount");
    _unset(paymentFormObj, "card_amount");
    formAction.setFormErrorFn("paymentTypeFormKey", []);


    let isValid = false;

    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        let index = key.replace("payment_types_", "");
        switch (paymentFormObj[key]['payment_type']) {
          case PaymentCategoriesEnum.CASH:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cash Received is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CHEQUE:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cheque Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.cheque_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.cheque_no`,
                "message": "Cheque No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.bank`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.bank`,
                "message": "Bank is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CREDITCARDS:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Credit Card Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.reference_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.reference_no`,
                "message": "Reference No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.approval_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.approval_no`,
                "message": "Approval No. is required."
              });
            }
            break;
          default:
            return
        }
        formAction.setFormErrorFn(paymentFormKey, errorObject);
      }
    });


    if (isEmpty(errorObject)) {
      isValid = true;
    }
    if (isValid) {
      formAction.changeInputFn(formGroupName, "print_error", "", "");
      formAction.setFormErrorFn("paymentTypeFormKey", [])
      setOpenPrint({
        ...openPrint,
        showPopup: true
      });
    } else {
      formAction.changeInputFn(formGroupName, "print_error", "", "User cannot proceed with the payment");
    }
  };
  const closePopup = () => {
    setOpenPrint({
      ...openPrint,
      showPopup: false
    })
  }
  const isBtnDisabled = () => {
    const total = (_get(formState, `dealer_charges_form.total`, "0"));
    let cardAmount = _get(formState, "paymentTypeFormKey.card_amount", 0);
    let cashAmount = _get(formState, "paymentTypeFormKey.cash_amount", 0);
    let chequeAmount = _get(formState, "paymentTypeFormKey.cheque_amount", 0);
    let isDissable = true;

    if ((cardAmount + chequeAmount) < total) {
      if (cashAmount >= total) {
        isDissable = false;
      }
      if ((cashAmount + cardAmount + chequeAmount) >= total) {
        isDissable = false;
      }
    }
    if ((cardAmount + chequeAmount) == total) {
      isDissable = false;
    }
    return isDissable;
  }

  return (
    <FormWrapper
      setGroupName={formGroupName}
      setFormObject={{
        "total_amount": _get(formState, "dealer_charges_form.total", 0),
        "print_error": ""
      }}
    >
      <div className="row">
        <div className="col-md-6">
          <PaddingLessUICard cardHeading="Payment Information">
            <table style={{ width: "100%" }}>
              <tr>
                <td className="text-left"><b>Number of Dealer's Licences:</b></td>
                <td className="text-right">
                  {
                    _get(formState, `${vehicleLicenseListKey}.data`, []).length
                  }
                </td>
              </tr>
              <tr>
                <td className="text-left"><b>Payment for One Dealer's Licence:</b></td>
                <td className="text-right">
                  {
                    thousandSeparator(+(_get(formState, "dealer_charges_form.total", 0) / (_get(formState, `${vehicleLicenseListKey}.data`, []).length > 0 ? _get(formState, `${vehicleLicenseListKey}.data`, []).length : 1)), true)
                  }
                </td>
              </tr>
              <tr>
                <td className="text-left"><b>Total (LKR):</b></td>
                <td className="text-right">
                  {thousandSeparator(_get(formState, "dealer_charges_form.total", 0), true)}
                </td>
              </tr>
              <tr>
                <td className="text-left"><b>Additions (LKR):</b></td>
                <td className="text-right">
                  {Number(_get(formState, "dealer_charges_form.totAddition", 0)) === 0 ? "0.00" : " "}
                </td>
              </tr>
              {_get(formState, `dealer_charges_form.data.additons_deductions_total`, []).map((item) => (item.charge_effect_code === "ADD") &&
                (
                  <tr>

                    <th>
                      <p className="text-left">{item.addition_deduction_name}</p>
                    </th>

                    <td >
                      <p className="text-right">{thousandSeparator(item.total, true)}</p>
                    </td>
                  </tr>

                ))}

              <tr>
                <td className="text-left"><b>Service Charge (LKR):</b></td>
                <td className="text-right">{thousandSeparator(_get(formState, "dealer_charges_form.serviceCharge", 0), true)}</td>
              </tr>
              <tr>
                <td className="text-left"><b>Deductions (LKR):</b></td>
                <td className="text-right">
                  {Number(_get(formState, "dealer_charges_form.totdeduction", 0)) === 0 ? "(0.00)" : " "}
                </td>
              </tr>
              {_get(formState, `dealer_charges_form.data.additons_deductions_total`, []).map((item) => (item.charge_effect_code === "DED") &&
                (
                  <tr>
                    <th>
                      <p className="text-left">{item.addition_deduction_name}</p>
                    </th>
                    <td >
                      <p className="text-right">({thousandSeparator(item.total, true)})</p>
                    </td>
                  </tr>

                ))}
            </table>


          </PaddingLessUICard>

          <PaddingLessUICard cardHeading="Vehicle Revenue Licence">
            <FormWrapper
              elementStyle={"col-md-12"}
              setGroupName={"dealer_license_vehiclelist_form"}
              formGroupLinkWith={"dealer_license_vehiclelist_form"}
              isLoaderOnError={false}
              apiUrl={`${dealerVehiceLicenceListAPI.url}/${_get(formState, "dealer_search_form.searchKey")}`}
              onDestroyUnsetFormObject={true}
              onRebuildResponseFn={(response) => {
                return {
                  data: response,
                  "_onLoad": false
                }


              }}
            >
              <div className={`license-slide-container license-slide-container-dealer`}>
                <RoundButton
                  btnSize={roundBtnSizes.medium}
                  elementStyle={"prev"}
                  iconClass={"fas fa-chevron-left"}
                  onClickBtnFn={slideLeft}>
                  tooltip={"Next"}
                </RoundButton>

                <DealerLicenseDetails index={index} licenseData={_get(formState, `${vehicleLicenseListKey}.data`, [])[index]} className="dealerLicenseImage" uiType={uiType} />


                <RoundButton
                  elementStyle={"next"}
                  iconClass={"fas fa-chevron-right"}
                  onClickBtnFn={slideRight}>
                  tooltip={"Prev"}
                </RoundButton>
                <div className="helperText"></div>
              </div>
            </FormWrapper>


          </PaddingLessUICard>
        </div>

        <div className="col-md-6">
          <PaddingLessUICard
            elementStyle="payment-type-card"
            cardHeading="Payment Type"
          >
            <PaymentType uiType="dealer" />
            <div className="fullWidthDiv">
              <div className="row ">
                <div className="col-md-4"></div>
                <div className={`${_get(authStatus, "authUser.pdmtId.code", "") === _get(formState, "vehicle_search_form.provinceData.code", "") ? 'col-md-6' : 'col-md-7' }`}>
                  <div className="mt-4 text-right " >
                    {
                      (_get(formState, `${formGroupName}.print_error`, "") !== "") ? (
                        <p className={"defaultHalfMargin"} style={{ color: "red" }}>{_get(formState, `${formGroupName}.print_error`, "")}</p>
                      ) : null
                    }
                    {
                      (_get(formState, `${vehicleLicenseListKey}.data`, [])?.length > _get(formState, `issueDealer.serial_numbers.data`, [])?.length && _get(formState, `issueDealer.serial_numbers.data`, null)) ? (
                        <p className={"defaultHalfMargin"} style={{ color: "red" }}>Serial Number Count is not enough</p>
                      ) : null
                    }
                  </div>
                  <div className="floatRight">
                    <InputButton
                      elementWrapperStyle={"payBtnStyle"}
                      btnText={"Pay & Print"}
                      onClickBtnFn={() => onPrintFn()}
                      isBtnDisabled={isBtnDisabled() || _get(formState, `${vehicleLicenseListKey}.data`, [])?.length > _get(formState, `issueDealer.serial_numbers.data`, "0")?.length}
                    />
                  </div>
                </div>
              </div>
              </div>
          </PaddingLessUICard>


          <DialogPopup
            isDialogActions={false}
            dialogTitle="Please Confirm"
            isFullWidth={false}
            isFullScreen={false}
            isCloseButton={true}
            children={
              <ConfirmationPopupDealer
                closePopupFn={closePopup}
                onClickSuccessFn={closeMainPopup}
                uiType={uiType}
                vehicleCount={_get(formState, `${vehicleLicenseListKey}.data`, []).length}
              />
            }
            isOpen={openPrint.showPopup}
            onClosePopupFn={closePopup}
          />
        </div>




      </div>
    </FormWrapper>
  );
};

export default IssueDealer;
