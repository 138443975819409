/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-09 13:44:04
 */

import React, { Fragment } from "react";
import { useContext } from 'react';
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { isEmpty } from "lodash";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { YearMonthPickerWithState } from "../../../../ui-components/ui-elements/form/DatePicker";

const QuotationAmountBreakdown = ({ uiType = "quotation", charges = {} }) => {
  const [formState, formAction] = useContext(FormContext);
  
  return (
    <Fragment>
      <div className="amount-breakedown-card">
        <table style={{ width: "100%" }}>
          <tr>
            <td className="text-left"><b>Arrears</b></td>
          </tr>
          <tr>
            <td className="text-left">Fee (LKR)</td>
            <td className="text-right">{thousandSeparator(parseFloat(_get(charges, "arrears_fee", 0)).toFixed(2), true)}</td>
          </tr>
          <tr>
            <td className="text-left">Fine (LKR)</td>
            <td className="text-right">{thousandSeparator(parseFloat(_get(charges, "arrears_fine", 0)).toFixed(2), true)}</td>
          </tr>
          {/* <tr>
            <td className="text-left">Additions (LKR)</td>
            <td className="text-right">{thousandSeparator(parseFloat(_get(charges, "arrears_addition", 0)).toFixed(2), true)}</td>
          </tr>
          <tr>
            <td className="text-left">Deductions (LKR)</td>
            <td className="text-right">({thousandSeparator(parseFloat(_get(charges, "licence_charges_form.data.arrears_deduction_total", 0)).toFixed(2), true)})</td>
          </tr> */}
          <tr>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td colSpan="2">
              <b>Current Licence </b>
            </td>
          </tr>

          <tr>
            <td className="text-left">Fee (LKR)</td>
            <td className="text-right">{thousandSeparator(parseFloat(_get(charges, "fee", 0)).toFixed(2), true)}</td>
          </tr>
          <tr>
            <td className="text-left">Fine (LKR)</td>
            <td className="text-right">{thousandSeparator(parseFloat(_get(charges, "fine", 0)).toFixed(2), true)}</td>
          </tr>

          <tr>
            <td colSpan="2">
            </td>
          </tr>
          
          <tr>
            <td className="text-left">Additions (LKR)</td>
            <td className="text-right">{thousandSeparator(parseFloat(_get(charges, "addition", 0)).toFixed(2), true)}</td>
          </tr>
          <tr>
            <td className="text-left">Deductions (LKR)</td>
            <td className="text-right">({thousandSeparator(parseFloat(_get(charges, "deduction", 0)).toFixed(2), true)})</td>
          </tr>
          <tr>
            <td className="text-left">Service Charges (LKR)</td>
            <td className="text-right">{thousandSeparator(parseFloat(_get(charges, "service_charge", 0)).toFixed(2), true)}</td>
          </tr>

          <tr>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td className="text-left">
              <b>Total (LKR)</b>
            </td>
            <td className="text-right">
              <b>{thousandSeparator(parseFloat(_get(charges, "total", 0)).toFixed(2), true)}</b>
            </td>
          </tr>
        </table>

      </div>
    </Fragment>
  );
};

export { QuotationAmountBreakdown };
