import React, {useContext} from "react"; 
import { Base64 } from "js-base64";

import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { ProvincesMatcher } from "../../../../../config/enum.config"
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import { PageLoader } from "../../../../ui-components/templates/common-includes/PageLoader";

const DmtInformation = ({ 
    tableName="", 
    dataTableKey="",
    isTransferred,
    isLoading,
    uiType
  }) => {
    const [formState] = useContext(FormContext);

    const dmtPreviousData = _get(formState, "dmt_transfers.data", []) && _get(formState, "dmt_transfers.data", []).length !== 0 ? _get(formState, "dmt_transfers.data", []) : []
    console.log('DMT=====>', dmtPreviousData);
    const sortedData = dmtPreviousData.sort((a,b) =>{
        const date1 = new Date(uiType === 'APR' ? a['TransferredOn'] : a['TransferredOn'])
        const date2 = new Date(uiType === 'APR' ? b['TransferredOn'] : b['TransferredOn'])
         return date2 - date1
      })
    return (
            <>
                {isLoading ? 
                    <PageLoader /> : 
                    <div className="">
                            {(_get(formState, "dmt_transfers.data", []) && _get(formState, "dmt_transfers.data", []).length !== 0) ? 
                            (<DataTable
                                tableName={tableName}
                                dataTableKey={dataTableKey}
                                tableHeaderList={[
                                    { displayName: "Name", key: "OwnerName", isSort: false, },
                                    { displayName: "Identification No.", key: "nic", isSort: false, },
                                    { displayName: "Mobile No.", key: "contact_no", isSort: false, },
                                    { displayName: "Address Line 1", key: "OwnerAddr1", isSort: false, },
                                    { displayName: "Address Line 2", key: "OwnerAddr2", isSort: false, },
                                    { displayName: "City", key: "OwnerCity", isSort: false, },
                                    { displayName: "Province", key: "OwnerProvince", isSort: false, onPrintFn: ({OwnerProvince}) => ProvincesMatcher[`${OwnerProvince}`] },
                                    { displayName: "Transferred Date", key: "TransferredOn", isSort: false, onPrintFn: ({TransferredOn}) => dateObjectToString(TransferredOn, "dd/mm/YY") }

                                ]}
                                tableBodyList={sortedData}

                                isSetTableFooter={false}
                                isSetAction={false}
                            /> )
                                :
                        ( <div className="p-4">
                                <h6>No Previous DMT Transfer Data Found</h6>
                            </div>)
                        }
                        </div>
                }
            </>
        


    )
};

export { DmtInformation };