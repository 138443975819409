import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get, } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from '../../../../../config/permission.config';

const IncomeDetailsReports = () => {
  const [authStatus, authAction] = useContext(AuthContext);
  const ViewPermission = _get(authStatus, 'authUser.permissions', "");
  
  let FR1, FR2, FR3, FR4, FR5, FR6, FR7, FR8, FR9, FR10, FR11, FR12, FR13, FR14, FR15
  ViewPermission.forEach(item => {
    if (item.permission === permissions.VIEW_FR01_INCOME_DISTRIBUTION_ENTIRE.permissions[0]) {
      FR1 = true
    }
    else if (item.permission === permissions.VIEW_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE.permissions[0]) {
      FR2 = true
    }
    else if (item.permission === permissions.VIEW_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE.permissions[0]) {
      FR3 = true
    }
    else if (item.permission === permissions.VIEW_FR04_DETAILED_INCOME_REPORT_ENTIRE.permissions[0]) {
      FR4 = true
    }
    else if (item.permission === permissions.VIEW_FR05_OTHER_INCOME_REPORT_ENTIRE.permissions[0]) {
      FR5 = true
    }
    else if (item.permission === permissions.VIEW_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT.permissions[0]) {
      FR6 = true
    }
    else if (item.permission === permissions.VIEW_FR07_TRANSACTION_SUMMARY_ENTIRE.permissions[0]) {
      FR7 = true
    }
    else if (item.permission === permissions.VIEW_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE.permissions[0]) {
      FR8 = true
    }
    else if (item.permission === permissions.VIEW_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE.permissions[0]) {
      FR9 = true
    }
    else if (item.permission === permissions.VIEW_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT.permissions[0]) {
      FR10 = true
    }
    else if (item.permission === permissions.VIEW_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT.permissions[0]) {
      FR11 = true
    }
    else if (item.permission === permissions.VIEW_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE.permissions[0]) {
      FR12 = true
    }
    else if (item.permission === permissions.VIEW_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT.permissions[0]) {
      FR13 = true
    }
    else if (item.permission === permissions.VIEW_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE.permissions[0]) {
      FR14 = true
    }
    else if (item.permission === permissions.VIEW_FR15_DAILY_AUTHORITY_WISE_SUMMARY.permissions[0]) {
      FR15 = true
    }
  });

  return (
    <DashboardTemplate
      pageTitle="Income Details Reports"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          //urlTo: "/report",
          displayName: "Income Details Reports List",
        },
      ]}
    >
      <UICard>
        < ol type="1">
          <div className="App">
            {FR1 &&
              <li>
                <Link
                  to="/income-distribution"
                >
                  <strong>Income Distribution Report</strong>
                </Link>
                <br />
                <p> This is the Income Distribution Report</p>
                <br />
              </li>}

            {FR2 && <li>
              <Link
                to="/detailed-income-distribution"
              >
                <strong>Detailed Income Distribution Report</strong>
              </Link>.
              <br />
              <p> This is the Detailed Income Distribution Report</p>
              <br />
            </li>}

            {FR3 && <li>
              <Link to="/income-province">
                <strong>Income from Other Province Report</strong>
              </Link>
              <br />
              <p> This is the Income from Other Province Report</p>
              <br />
            </li>}

            {FR4 && <li>
              <Link to="/report-detailed-income">
                <strong>Detailed Income Report</strong>
              </Link>
              <br />
              <p> This is the Detailed Income Report</p>
              <br />
            </li>}

            {FR5 && <li>
              <Link to="/other-income">
                <strong>Other Income Report</strong>
              </Link>
              <br />
              <p> This is the Other Income Report</p>
              <br />
            </li>}

            {FR6 && 
            <li>
              <Link to="/detailed-addition-deduction">
                <strong>Detailed Additions & Deductions Report</strong>
              </Link>
              <br />
              <p> This is the Detailed Additions & Deductions Report</p>
              <br />
            </li>
}
            {FR7 && <li>
              <Link to="/transaction-summary">
                <strong>Transaction Summary Report</strong>
              </Link>
              <br />
              <p> This is the Transaction Summary Report</p>
              <br />
            </li>}

            {FR8 && <li>
              <Link to="/yearly-transaction-summary">
                <strong>Yearly Transaction Summary Report</strong>
              </Link>
              <br />
              <p> This is the Yearly Transaction Summary Report</p>
              <br />
            </li>}

            {FR9 && <li>
              <Link to="/monthly-transaction-summary">
                <strong>Monthly Transaction Summary Report</strong>
              </Link>
              <br />
              <p> This is the Monthly Transaction Summary Report</p>
              <br />
            </li>}

            {FR10 && <li>
              <Link to="/summary-motor-vehicle-revenue">
                <strong>Summary of the Motor Vehicle Revenue Collection Report</strong>
              </Link>
              <br />
              <p> This is the Summary of the Motor Vehicle Revenue Collection Report</p>
              <br />

            </li>}

            {FR11 && <li>
              <Link to="/daily-counter-transaction-summary">
                <strong>Daily Counter Transaction Summary Report</strong>
              </Link>
              <br />
              <p> This is the Daily Counter Transaction Summary Report</p>
              <br />
            </li>}

            {FR12 && <li>
              <Link to="/vehicle-licence-revenue-turnover">
                <strong>Vehicle Licence Revenue Turnover Report</strong>
              </Link>
              <br />
              <p> This is the Vehicle Licence Revenue Turnover Report</p>
              <br />
            </li>}
            {FR13 && 
            <li>
              <Link to="/vehicle-revenue-licence-collection">
                <strong>Vehicle Revenue Licence Collection Report</strong>
              </Link>
              <br />
              <p> This is the Vehicle Revenue Licence Collection Report</p>
              <br />
            </li>
}
            {FR14 && <li>
              <Link to="/summary-arrears-payment">
                <strong>Summary of Arrears Payments Report</strong>
              </Link>
              <br />
              <p> This is the Summary of Arrears Payments Report</p>
              <br />
            </li>}

            {FR15 && <li>
              <Link to="/daily-authority-wise-summary">
                <strong>Daily Authority Wise Summary Report</strong>
              </Link>
              <br />
              <p> This is the Daily Authority Wise Summary Report</p>
            </li>}



          </div>
        </ ol>
      </UICard>
    </DashboardTemplate>
  );
};

export {
  IncomeDetailsReports
};