import React from 'react'
import { useContext } from 'react'
import { thousandSeparator } from '../../../../../../helpers/common-helpers/common.helpers'
import { _get } from '../../../../../../helpers/common-helpers/lodash.wrappers'
import { CircleLoaderElement, PaddingLessUICard } from '../../../../../ui-components/ui-elements/common/BaseElements'
import { FormContext } from '../../../../core/context-providers/FormContext.provider'

export const ChargingDetails = () => {
    const[formState,formAction]=useContext(FormContext)
    return (
        <PaddingLessUICard cardHeading="Payment Amount">
            {
                _get(formState, "licence_charges_form._onLoad", true) === true ? (
                    <div>
                        <br />
                        <center>
                            <CircleLoaderElement />
                        </center>
                    </div>
                ) : (
                    <table style={{ width: "100%" }}>
                        <tr>
                            <th>Fee (LKR)</th>
                            <td className="text-right">
                                {thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFee", 0), true)}
                            </td>
                        </tr>

                        <tr>
                            <th>Fine (LKR)</th>
                            <td className="text-right">
                                {thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFine", 0), true)}
                            </td>
                        </tr>

                        <tr>
                            <th>Arrears Fee (LKR)</th>
                            <td className="text-right">
                                {thousandSeparator(_get(formState, "licence_charges_form.totArrearsFee", 0), true)}
                            </td>
                        </tr>

                        <tr>
                            <th>Arrears Fine (LKR)</th>
                            <td className="text-right">
                                {thousandSeparator(_get(formState, "licence_charges_form.totArrearsFine", 0), true)}
                            </td>
                        </tr>

                        <tr>
                            <th>Additions (LKR)</th>
                            {/* <td className="text-right">
                                                            {
                                                                thousandSeparator((+_get(formState, "licence_charges_form.data.additions.arrears", 0) +
                                                                    +_get(formState, "licence_charges_form.data.additions.current_licence", 0)), true)
                                                            }
                                                        </td> */}
                            <td className="text-right">{Number(_get(formState, "licence_charges_form.totAddition", 0)) === 0 ? "0.00" : " "}</td>

                        </tr>




                        {_get(formState, `licence_charges_form.data.addition_deduction_summary`, []).map((item) => (item.charge_effect_code === "ADD") && (

                            <tr>

                                <th>
                                    <p className="text-left">{item.addition_deduction_name}</p>
                                </th>

                                <td >
                                    <p className="text-right">{thousandSeparator(item.total, true)}</p>
                                </td>
                            </tr>

                        ))}



                        <tr>
                            <th>Deductions (LKR)</th>
                            <td className="text-right">{Number(_get(formState, "licence_charges_form.totdeduction", 0)) === 0 ? "(0.00)" : " "}</td>
                        </tr>



                        {_get(formState, `licence_charges_form.data.addition_deduction_summary`, []).map((item) => (item.charge_effect_code === "DED") && (
                            <tr>
                                <th>
                                    <p className="text-left">{item.addition_deduction_name}</p>
                                </th>
                                <td >
                                    <p className="text-right">({thousandSeparator(item.total, true)})</p>
                                </td>
                            </tr>

                        ))}
                        <tr>
                            <th>Service Charge (LKR)</th>
                            <td className="text-right">
                                {thousandSeparator(_get(formState, "licence_charges_form.serviceCharge", 0), true)}
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td className="text-right"></td>
                        </tr>

                        <tr>
                            <th>Total Amount (LKR)</th>
                            <td className="text-right">
                                <b>{thousandSeparator(_get(formState, "licence_charges_form.total", 0), true)}</b>
                            </td>
                        </tr>
                    </table>
                )
            }
        </PaddingLessUICard>
    )
}
