import { uploadDetailSaveAPI } from "../../../../config/apiUrl.config";
import React, { useEffect, useState } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { Action } from "./includes/Action";
import RotePermitFineInformation from "./includes/RoutePermitFineInformation";

const RoutePermitUI = () => {

  
    const [requestId, setRequestId] = useState(null);
    useEffect(() => {
      const rId = new Date();
      const requ = rId.valueOf().toString();
  
      setRequestId(requ.substr(requ.length - 8))
    }, [])
    return (
        <div className="row">
            <UICard>
                <div className="col-md-12 col-sm-12">
                    <RotePermitFineInformation />
                </div>
                <div className="col-md-12 col-sm-12 defaultPaddingTop">
                    {/* <DocumentsUploader
                        isUploadFn={true}
                        formGroupName={formKey}
                        tableApiUrl={`${uploadDetailSaveAPI.url}?request_id=1232`}
                    /> */}
                </div>
                <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
                    <Action />
                </div>

            </UICard>
        </div>
    )
}

const RoutePermitFine = () => {
    return (
        <DashboardTemplate
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Route Permit Fine" },
            ]}
            pageTitle="Route Permit Fine"
        >
            <RoutePermitUI />
        </DashboardTemplate>
    );
};

export default RoutePermitFine;
export { RoutePermitUI };