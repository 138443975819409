/* eslint-disable react-hooks/rules-of-hooks */
import React, {  useContext ,useState } from "react";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../../../ui-components/ui-elements/modal/DialogPopup";
import {LicencePrintPopup} from "./LicencePrintPopup.js"
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import {EnvelopPrintPopup} from "./EnvelopPrintPopup.js"
import {ConfirmationPopup} from "./ConfirmationPopup"

const WebLicenceFooter = ({
  formChild = {
    issueMode: "1",
    isPendingWorkFlow: false,
    payArrearsOnly: true,
    currentTab: "tab1",
  },
}) => {

  const [formState, formAction] = useContext(FormContext);
  const [getLicencePrintPreviewModelState, setLicencePrintPreviewModelState] = useState({
    status: false,
  });
  const [getEnvelopPrintPreviewModelState, setEnvelopPrintPreviewModelState] = useState({
    status: false,
  });
  const [getEnvelopRePrintPreviewModelState, setEnvelopRePrintPreviewModelState] = useState({
    status: false,
  });
  

  const [isOpenLicencePrintConfirmation, setOpenLicencePrintOpenConfirmation] = useState(false);
  const [isOpenEnvelopPrintConfirmation, setOpenEnvelopPrintOpenConfirmation] = useState(false);
  const [isOpenEnvelopRePrintConfirmation, setEnvelopRePrintOpenConfirmation] = useState(false);

  const printWebLicenceConfirmBtn =(closePopupFn) =>{
    return <InputButton
    elementWrapperStyle={"defaultHalfMarginLeftRight"}
    btnText="Print"
    onClickBtnFn={()=>setOpenLicencePrintOpenConfirmation(true)}
    startIcon={"fas fa-print"}
  />
  }

  const printEnvelopeConfirmBtn =(closePopupFn) =>{
    return <InputButton
    elementWrapperStyle={"defaultHalfMarginLeftRight"}
    btnText="Print"
    onClickBtnFn={()=>setOpenEnvelopPrintOpenConfirmation(true)}
    startIcon={"fas fa-print"}
  />
  }

  const rePrintEnvelopeConfirmBtn =(closePopupFn) =>{
    return <InputButton
    elementWrapperStyle={"defaultHalfMarginLeftRight"}
    btnText="Print"
    onClickBtnFn={()=>setEnvelopRePrintOpenConfirmation(true)}
    startIcon={"fas fa-print"}
  />
  }
  

  const getBottomButton =() =>{

    if(_get(formChild, "currentTab", "tab1") === "tab1"){
      return              <InputButton
      elementWrapperStyle={"defaultHalfMarginLeftRight"}
      btnName="submit"
      btnText={"Print"}
      isBtnDisabled={_get(formState, `webLicencePrintListAPIKey`, [])?.length === 0}
      startIcon={"fas fa-print"}
      onClickBtnFn={() =>
        setLicencePrintPreviewModelState({
          status: true,
        })
      }
    />
    }

    if(_get(formChild, "currentTab", "tab1") === "tab2"){
      return <InputButton
      elementWrapperStyle={"defaultHalfMarginLeftRight"}
      btnName="submit"
      isBtnDisabled={_get(formState, `webLicencePrintListAPIKeyPrintedEnvelope`, [])?.length === 0}
      btnText={"Print"}
      startIcon={"fas fa-print"}
      onClickBtnFn={() =>
        setEnvelopPrintPreviewModelState({
          status: true,
        })
      }
    />
    }

    if(_get(formChild, "currentTab", "tab1") === "tab3"){
      return <InputButton
      elementWrapperStyle={"defaultHalfMarginLeftRight"}
      btnName="submit"
      btnText={"Envelop Re-print"}
      isBtnDisabled={_get(formState, `webLicencePrintListAPIKeyPrinted`, [])?.length === 0}
      startIcon={"fas fa-print"}
      onClickBtnFn={() =>
        setEnvelopRePrintPreviewModelState({
          status: true,
        })
      }
    />
    }


  }

  return (
    <div className="row">
      <div className="col-md-6"></div>
      <div className="col-md-6 text-right">
        {_get(formChild, "isPendingWorkFlow", false) === false ? (
               getBottomButton()
        ):null}
      </div>

      {/* Web Licence Print */}
      <DialogPopup
        isOpen={getLicencePrintPreviewModelState.status}
        dialogTitle={"Print Licence"}
        isFullScreen={false}
        isCustomButton={true}
        customButtonComponent={printWebLicenceConfirmBtn}
        onClosePopupFn={() =>
          setLicencePrintPreviewModelState({
            ...getLicencePrintPreviewModelState,
            status: false,
            modelType: "",
          })
        }
      >
          <LicencePrintPopup />
      </DialogPopup>
{/* Envelop Print */}
      <DialogPopup
        isOpen={getEnvelopPrintPreviewModelState.status}
        dialogTitle={"Print Licence Envelop"}
        isFullScreen={false}
        isCustomButton={true}
        customButtonComponent={printEnvelopeConfirmBtn}
        onClosePopupFn={() =>
          setEnvelopPrintPreviewModelState({
            status: false,
          })
        }
      >
          <EnvelopPrintPopup uiType="ENVELOP" />

      </DialogPopup>

{/* Envelop Reprint */}
      <DialogPopup
        isOpen={getEnvelopRePrintPreviewModelState.status}
        dialogTitle={"Print Licence Envelop"}
        isFullScreen={false}
        isCustomButton={true}
        customButtonComponent={rePrintEnvelopeConfirmBtn}
        onClosePopupFn={() =>
          setEnvelopRePrintPreviewModelState({
            status: false,
          })
        }
      >
          <EnvelopPrintPopup  uiType="REPRINTENVELOP" />

      </DialogPopup>

{/* Web Licence Print Popup */}
      <DialogPopup
              isDialogActions={false}
              dialogTitle="Please Confirm"
              isFullWidth={false}
              isFullScreen={false}
              isCloseButton={true}
              children={
                <ConfirmationPopup
                    uiType="WEBLICENCE" 
                    closePopupFn={()=>setOpenLicencePrintOpenConfirmation(false)}
                    onClickSuccessFn={()=>
                      {
                        setOpenLicencePrintOpenConfirmation(false)
                        setLicencePrintPreviewModelState({...getLicencePrintPreviewModelState,status:false})
                      }
                    }
                />
              }
              isOpen={isOpenLicencePrintConfirmation}
               onClosePopupFn={() =>setOpenLicencePrintOpenConfirmation(false)}
            />

    {/* Envelope Print Popup */}
      <DialogPopup
              isDialogActions={false}
              dialogTitle="Please Confirm"
              isFullWidth={false}
              isFullScreen={false}
              isCloseButton={true}
              children={
                <ConfirmationPopup
                    uiType="ENVELOPE"
                    closePopupFn={()=>setOpenEnvelopPrintOpenConfirmation(false)}
                    onClickSuccessFn={()=>
                      {
                        setOpenEnvelopPrintOpenConfirmation(false)
                        setEnvelopPrintPreviewModelState({status:false})
                      }
                    }
                />
              }
              isOpen={isOpenEnvelopPrintConfirmation}
               onClosePopupFn={() =>setOpenEnvelopPrintOpenConfirmation(false)}
            />


      {/* Envelope Reprint Popup */}
      <DialogPopup
              isDialogActions={false}
              dialogTitle="Please Confirm"
              isFullWidth={false}
              isFullScreen={false}
              isCloseButton={true}
              children={
                <ConfirmationPopup
                    uiType="RE_PRINT_ENVELOPE"
                    closePopupFn={()=>setEnvelopRePrintOpenConfirmation(false)}
                    onClickSuccessFn={()=>
                      {
                        setEnvelopRePrintOpenConfirmation(false)
                        setEnvelopRePrintPreviewModelState({status:false})
                      }
                    }
                />
              }
              isOpen={isOpenEnvelopRePrintConfirmation}
               onClosePopupFn={() =>setEnvelopRePrintOpenConfirmation(false)}
            />
    </div>
  );

  
};



export { WebLicenceFooter };
