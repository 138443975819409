/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-09-27 19:07:38
 */

import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  FormWrapper,
  InputButton,
  SelectBoxWithState,
  InputBoxWithState,
  SubmitButton,
} from "../../../ui-components/ui-elements/form";
import {
  _get,
  _size,
  _findindex,
} from "../../../../helpers/common-helpers/lodash.wrappers";
import { ChangeSerialNumber, LoadSerialNumber } from "../../../ui-components/templates/CounterTemplate";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import {
  availableCounterListApi,
  availableSessionListApi,
  changeSerialNumberRangeApi,
  createSessionApi,
} from "../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType, CoreContext } from "../../core/context-providers/CoreContext.provider";
import { PageLoader } from "../../../ui-components/templates/common-includes/PageLoader";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";

const formKey = "cashierSessionForm";

const Cashier = (props) => {
  return (
    <DashboardTemplate
      pageTitle="Counter"
      breadCrumbList={[{ urlTo: "/cashier", displayName: "Counter" }]}
    >
      <CashierElement props={{ ...props }} />
    </DashboardTemplate>
  );
};

const ChangeDiscardSerialSubmitBtn = (props) => {
  const [authStatus, authAction] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);
  const popupType = _get(props, 'popupType', null);
  const authority_id = _get(authStatus, "authUser.authorityId", "");
  const pdmt_id = _get(authStatus, "authUser.pdmtId.id", "");
  let counter_id = _get(formState, `${formKey}_change_serial.counter_id`, null);
  let counter_name = popupType === "edit-model" ? _get(props, 'counter_name', '') : _get(formState, `${formKey}.counter_name`, null);
  const session_id = _get(props, "sessionId", null);
  
  return (
    <SubmitButton
      btnText={"Submit"}
      startIcon={"far fa-edit"}
      formGroupName={props.formGroupName + "_change_serial"}
      tooltip={"Submit"}
      dataTableKey={props.dataTableKey}
      isValidate={true}
      flashMessages={{
        success: {
          status: true,
          message: `Serial Number successfully updated.`,
          messageType: "success",
        },
        failed: {
          status: true,
          message: `Serial Number Change fail.`,
          messageType: "error",
        },
      }}
      validationObject={{
        fileds: {
          serial_number_to: "Change Up to",
          changed_reason_id: "Reason",
          note: "Note",
        },
        rules: {
          serial_number_to: "required|maxAmount:999999999999|minAmount:1",
          changed_reason_id: "required",
          note: "max:200",
        },
        message: {
          "serial_number_to.maxAmount": "Invalid Serial Number. Please enter a valid Serial Number.",
          "serial_number_to.minAmount": "Invalid Serial Number. Please enter a valid Serial Number."
        }
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          url: changeSerialNumberRangeApi.url,
          key: changeSerialNumberRangeApi.key,
        };
      }}
      onChangeRequestBodyFn={(fromObject) => {
        fromObject.id = undefined;
        return getDataByFormObject({
          ...fromObject,
          pdmt_id: pdmt_id,
          counter_id: counter_id,
          counter_name: counter_name,
          serial_number_from: popupType == 'edit-model'? _get(props, `current_serial`, "") : _get(formState, `${props.formGroupName}.serial_number`, ""),
          discarded_counter_session_id: session_id
        });
      }}
      callApiObject={{
        isSetHeaders: true,
        method: "post",
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.responseLinkToFrom,
      }}
      onResponseCallBackFn={(response, error) => {
        if (response.length === undefined && error === undefined && response.data.errors === undefined) {
          callApi(`${availableCounterListApi.url}/${authority_id}/counters/${counter_id}`)
          .method("get")
          .headers(true)
          .body({})
          .isMultipart(false)
          .send((error, result) => {

            if(!error){
              const nxt_serial = _get(result, "data.data.next_serial_number", "") == null ? "" : _get(result, "data.data.next_serial_number", "");
              if(popupType != 'edit-model'){
                formAction.changeInputFn(formKey, "serial_number", "", nxt_serial);
              }else{
                formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", nxt_serial);
              }
            }
          });
          callApi(`${availableCounterListApi.url}/${authority_id}/counters/available-counters/list?sort_by=name|asc&active=true`)
          .method("get")
          .headers(true)
          .body({})
          .isMultipart(false)
          .send((error, result) => {

            if(!error && popupType != 'edit-model'){
              formAction.changeInputFn(formKey, "session_data", "", _get(result, "data.data", []));
            }
          });
          popupType == 'edit-model' ? props.closeSerialUi() : props.onClosePopupFn();
          
        }
      }}
    />
  );
};


const CashierElement = (props) => {

  let history = useHistory();
  const [uiState, uiAction] = useContext(UIContext);
  const [getState, setState] = useState({
    showPopup: false,
    nextSerialNumber: null,
    counterId: null,
  });
  const [authStatus, authAction] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [form, setForm] = useState(false);

  const user_id = _get(authStatus, "authUser.id", "");
  const authority_id = _get(authStatus, "authUser.authorityId", "");
  let counter_id = _get(formState, `${formKey}.counter_id`, null);

  useEffect(() => {
    coreAction
        .sendRequestFn(
            `${availableSessionListApi.url}/${user_id}/sessions/list?authority_id=${authority_id}`
        )
        .method("get")
        .setInitStoring(apiResponseStoringType.setResponse, {
          responseKey: availableSessionListApi.key,
        })
        .send((error, response) => {
          if (_size(_get(response, "data.data", [])) > 0) {
            
            uiAction.toggleFullScreen();
            history.push({pathname:"/counter-session", state: {
              counter_id: _get(response, "data.data[0].counter_id.id", []),
              session_id: _get(response, "data.data[0].id", [])
            }});
          } else {
            setForm(true);
          }
        });
  }, []);

  const closePopup = () => {
    setState({
      ...getState,
      showPopup: !getState.showPopup,
    })
  }

  return (
    <div>
      {form === true ? (
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <UICard
              cardHeading="Counter Session"
              elementStyle={"cashierWrapper"}
            >
              <FormWrapper
                setGroupName={formKey}
                apiUrl={`${availableCounterListApi.url}/${authority_id}/counters/available-counters/list?sort_by=name|asc&active=true`}
                onRebuildResponseFn={(response) => {
                  return {
                    session_data: response,
                    serial_number: _get(props, "row.serial_number", ""),
                    counter_id: _get(props, "row.counter_id", null),
                    user_id: user_id,
                    _onLoad: false,
                  };
                }}
              >
                <div className="col-md-8">
                  <SelectBoxWithState
                    formGroupName={formKey}
                    dataList={_get(formState, `${formKey}.session_data`, [])}
                    keyName={"id"}
                    valueName={"name"}
                    inputName={"counter_id"}
                    labelText="Counter Name"
                    isRequired
                    emptySelectOptionTxt={"Select"}
                    onChangeFn={(event) => {
                      const index = _findindex(
                        _get(formState, `${formKey}.session_data`, []),
                        (e) => e.id === event.value
                      );
                      const val = _get(
                        formState,
                        `${formKey}.session_data`,
                        []
                      )[index];
                      const nxt_serial_no = val.next_serial_number === null ? '' : _get(val, "next_serial_number", "");
                      formAction.changeInputFn(formKey, "serial_number", "", nxt_serial_no);
                      formAction.changeInputFn(formKey, "counter_name", "", _get(val, "name", ""));
                    }}
                  />
                </div>

                <div className="col-md-8 floatLeft">
                  <InputBoxWithState
                    formGroupName={formKey}
                    inputName={"serial_number"}
                    labelText="Serial Number"
                    isDisabled={true}
                  />
                </div>
                <div className="col-md-4fullWidthDiv text-right defaultHalfMarginTop">
                  {counter_id !== null ? (
                    <div className="floatright mt-2 defaultHalfPaddingTopBottom">
                      <InputButton
                        btnText={
                          isEmptyValue(_get(formState, `${formKey}.serial_number`, ""))
                            ? "Load Serial"
                            : "Edit Serial No."
                        }
                        btnName="edit"
                        endIcon={
                          isEmptyValue(_get(formState, `${formKey}.serial_number`, ""))
                            ? "fas fa-upload"
                            : "mdi mdi-pencil"
                        }
                        //overrideTextToIcon={<i className="mdi mdi-pencil" />}
                        onClickBtnFn={() =>

                          setState({
                            ...getState,
                            showPopup: !getState.showPopup,
                          })
                        }
                      />
                    </div>
                  ) : null}
                </div>
                <DialogPopup
                  isOpen={getState.showPopup}
                  dialogTitle={isEmptyValue(_get(formState, `${formKey}.serial_number`, "")) ? ("Load Serial Numbers") : ("Change Serial Number")}
                  isFullScreen={false}
                  isCustomButton={isEmptyValue(_get(formState, `${formKey}.serial_number`, "")) ? false : true}
                  customButtonComponent={ChangeDiscardSerialSubmitBtn}
                  formGroupName={formKey}
                  onClosePopupFn={() =>
                    setState({
                      ...getState,
                      showPopup: !getState.showPopup,
                    })
                  }
                  closeButton={{
                    btnText: "Cancel",
                    startIcon: "far fa-times-circle"
                  }}
                >
                  {isEmptyValue(_get(formState, `${formKey}.serial_number`, "")) ? (
                    <LoadSerialNumber 
                      counterId={counter_id} 
                      authorityId={authority_id}
                      closeSerialUi={closePopup} />
                  ) : (
                    <ChangeSerialNumber
                      formKey={formKey}
                      popupType={"Change_Serial"}
                      current_serial={_get(formState, `${formKey}.serial_number`, "")} 
                    />
                  )}

                </DialogPopup>


                <div className="fullWidthDiv text-right defaultHalfMarginTop">

                  {counter_id !== null &&
                    _get(formState, `${formKey}.serial_number`, "") != "" ? (
                    <div className="fullWidthDiv text-right defaultHalfMarginTop">
                      <SubmitButton
                        startIcon={"fas fa-hourglass-start"}
                        btnText={"Start Session"}
                        formGroupName={formKey}
                        isValidate={true}
                        validationObject={{
                          fileds: {
                            counter_id: "Counter Name",
                          },
                          rules: {
                            counter_id: "required",
                          },
                        }}
                        flashMessages={{
                          success: {
                            status: true,
                            message: "Session successfully started.",
                            messageType: "success",
                          },
                          "failed": {
                              status: true,
                              message: "A session already exists with the user.",
                              messageType: "error"
                          }
                        }}
                        onGetAPIEndPointFn={(formState) => {
                          return {
                            url: `${createSessionApi.url}/${formState.counter_id}/sessions`,
                            key: createSessionApi.key,
                          };
                        }}
                        onChangeRequestBodyFn={(formObject) => {
                          return getDataByFormObject({
                            counter_id: formObject.counter_id,
                            user_id: formObject.user_id,
                          });
                        }}
                        callApiObject={{
                          isSetHeaders: true,
                          method: "post",
                        }}
                        apiDataStoringObject={{
                          setLoader: true,
                          storingType: apiResponseStoringType.setResponse,
                        }}
                        onResponseCallBackFn={(error, response) => {
                          if (!error) {
                            formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", _get(response, `data.data.started_serial_number`, ""));
                            
                            uiAction.toggleFullScreen();
                            history.push({ pathname: "/counter-session", state: {
                              counter_id: counter_id,
                              session_id: _get(response, "data.data.id", "")
                            }});
                          }
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </FormWrapper>
            </UICard>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Cashier;
export {ChangeDiscardSerialSubmitBtn};
