/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-11 16:45:33
 */

import React, { useContext } from 'react';
import {
    FormWrapper,
    InputBoxWithState,
    CheckBoxWithState,
} from "../../../../ui-components/ui-elements/form/index";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
 
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";

const emptyFn = (...para) => undefined;

const OwnerInfoEdit = (props) => {
    const [formState,formAction] = useContext(FormContext);
    const formKey = "editOwnerInformation";
    return (
        <FormWrapper
            elementStyle={""}
            setGroupName={formKey}
            setFormObject={
                {
                    id: _get(formState,'viewOwnerInformation.id',0),
                    vehicle_id: _get(formState,'viewOwnerInformation.vehicle_id',0),
                    name: getLabelValue(_get(formState,'viewOwnerInformation.name', ''), true, true),
                    address_line_1: getLabelValue(_get(formState,'viewOwnerInformation.address_line_1', ''), true, true),
                    address_line_2: getLabelValue(_get(formState,'viewOwnerInformation.address_line_2', ''), true, true),
                    city: _get(formState,'viewOwnerInformation.city',null) ??'',
                    contact_number:_get(formState,'viewOwnerInformation.contact_number',null) ??'',
                    nic: getLabelValue(_get(formState,'viewOwnerInformation.nic', ''), true, true),
                    additional_information:_get(formState,'viewOwnerInformation.additional_information',null) ?? '',
                    absolute_owner_name:  getLabelValue(_get(formState,'vehicle_search_form.data.absolute_owner_id.name', ''), true, true),
                    absolute_owner_address_line_1: getLabelValue(_get(formState,'vehicle_search_form.data.absolute_owner_id.address_line_1', ''), true, true),
                    absolute_owner_address_line_2: getLabelValue(_get(formState,'vehicle_search_form.data.absolute_owner_id.address_line_2', ''), true, true),
                    absolute_owner_city: _get(formState,'vehicle_search_form.data.absolute_owner_id.city',null)  ??'',
                    sms_alerts:  (_get(formState,'viewOwnerInformation.sms_alerts',null) ===1 ? true:false ),
                }
            }
        >
            <div className="row">
                <div className="col-md-12">
                    <InputBoxWithState
                        isRequired={true}
                        labelText={"Name"}
                        formGroupName={formKey}
                        inputName={"name"}
                        maxLength={100}
                    />
                </div>

                <div className="col-md-12">
                    <InputBoxWithState
                        isRequired={true}
                        labelText={"Address Line 1"}
                        formGroupName={formKey}
                        inputName={"address_line_1"}
                        maxLength={50}
                    />
                </div>

                <div className="col-md-12">
                    <InputBoxWithState
                        labelText={"Line 2"}
                        formGroupName={formKey}
                        inputName={"address_line_2"}
                        maxLength={50}
                    />
                </div>

                <div className="col-md-12">
                    <InputBoxWithState
                        isRequired={true}
                        labelText={"City"}
                        formGroupName={formKey}
                        inputName={"city"}
                        maxLength={50}
                    />
                </div>
                <div className="col-md-6">
                    <InputBoxWithState
                        labelText={"Mobile Number"}
                        formGroupName={formKey}
                        inputName={"contact_number"}
                        inputStatePath={`${formKey}.contact_number`}
                        isAllowNegative={false}
                        isThousandSeparator={false}
                        isAllowLeadingZeros={false}
                        maxLength={9}
                        inputPrefixText={"0094"}
                        decimalScale={0}
                        isAllowedFn={(value) => {
                            const checkValue = value.value.toString();
                            return checkValue.length <= 10;
                        }}
                        onChangeTxtFn={(value) => {
                            const checkValue = value.value.toString();
                            if(checkValue ==0){
                                formAction.changeInputFn(formKey, "contact_number", "", '');
                            }
                        }}
                        inputPlaceholder={"Mobile Number"}
                    />
                </div>

                <div className="col-md-6">
                    <InputBoxWithState
                        labelText={"Identification Number"}
                        formGroupName={formKey}
                        maxLength={12}
                        inputName={"nic"}
                    />
                </div>

                <div className="col-md-6 defaultHalfMarginLeft">
                    <CheckBoxWithState
                        formGroupName={formKey}
                        inputName={"sms_alerts"}
                        labelText={"SMS Alerts"}
                 
                    />
                </div>

                <div className="col-md-12">
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <InputBoxWithState
                                isRequired={_get(formState,'')}
                                labelText={"Absolute Owner Name"}
                                formGroupName={formKey}
                                inputName={"absolute_owner_name"}
                                maxLength={100}
                            />
                        </div>

                        <div className="col-md-6">
                            <InputBoxWithState
                                isRequired={(_get(formState, `${formKey}.absolute_owner_name`, '').length >0)}
                                labelText={"Absolute Owner Address Line 1"}
                                formGroupName={formKey}
                                maxLength={50}
                                inputName={"absolute_owner_address_line_1"}
                            />
                        </div>

                        <div className="col-md-6">
                            <InputBoxWithState
                                labelText={"Line 2"}
                                formGroupName={formKey}
                                maxLength={50}
                                inputName={"absolute_owner_address_line_2"}
                            />
                        </div>

                        <div className="col-md-6">
                            <InputBoxWithState
                                //isRequired={true}
                                labelText={"City"}
                                formGroupName={formKey}
                                maxLength={50}
                                inputName={"absolute_owner_city"}
                            />
                        </div>
                    </div>
                    <hr />
                </div>

                <div className="col-md-12">
                    <InputBoxWithState
                        labelText={"Additional Information"}
                        formGroupName={formKey}
                        isMultiline={true}
                        rows={2}
                        maxLength={200}
                        inputName={"additional_information"}
                    />
                    <hr />
                </div>
            </div>
        </FormWrapper>
    );
};

export { OwnerInfoEdit };