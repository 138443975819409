/*
 * @Author: Pradeepa Sandaruwan
 * @Date: 2021-01-06 14:31:25 
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-20 16:52:16
 */

import React, { useState } from 'react';
import { DataTable, actionBtnTypes } from "../../../../ui-components/ui-elements/table/DataTable";
import { FormWrapper, InputBoxWithState } from "../../../../ui-components/ui-elements/form";
import { LicenseDetail } from './includes/LicenseDetail';
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { ViewQuotation } from './includes/ViewQuotation';

const emptyFun = (...para) => undefined;
const formKey = "quatation";

const Quotation = ({
  setShowHideFooterFN = emptyFun
}) => {
  const [quotationVehicles, setQuotationVehicles] = useState([]);
  const [licenceDetailUiType, setLicenceDetailUiType] = useState("add");

  return (
    <div className="quotationWrapper defaultPaddingBottom">
      <ViewQuotation
        setShowHideFooterFN={(status, mergeWithPerent) => setShowHideFooterFN(status, mergeWithPerent)}
        // addNewQuotationFn={() => addNewQuotationFn()}
        updateQuotationVehicleFn={(vehicle_id) => setLicenceDetailUiType("add")}
        formKey={formKey}
        uiType={licenceDetailUiType}
      />

      
    </div>
  );
}
export { Quotation };