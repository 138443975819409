/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-13 13:08:43 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-12 15:24:41
 */

import React, { useContext, useEffect, useState } from "react";
import { PaddingLessUICard } from "../../../ui-components/ui-elements/common/BaseElements.js";
import {
  FormWrapper,
  InputButton,
  SubmitButton,
} from "../../../ui-components/ui-elements/form";
import { AmountBreakdown } from "./includes/AmountBreakdown.js";
import { PaymentType } from "./includes/PaymentType.js";
import { DialogPopup, dialogPopupMaxWidth } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { PrintReceiptConfirmation } from "./includes/PrintReceiptConfirmation.js";
import { FormContext } from "../../core/context-providers/FormContext.provider.js";
import { getDataByFormObject, getFlooredFixed } from "../../../../helpers/common-helpers/common.helpers.js";
import { _find, _get, _unset } from "../../../../helpers/common-helpers/lodash.wrappers.js";
import { PaymentCategoriesEnum, TargetType } from "../../../../config/enum.config.js";
import { availableSessionListApi, createLicenceArrearsOnlyAPI } from "../../../../config/apiUrl.config.js";
import { apiResponseStoringType } from "../../core/context-providers/CoreContext.provider.js";
import { isEmpty } from "lodash";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers.js";
import { ConfirmationPopup } from "./includes/ConfirmationPopup.js";
import { AuthContext } from "../../core/context-providers/AuthContext.provider.js";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers.js";

const emptyFn = (...para) => undefined;


const PayArrearsOnlyBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formGroupName = "arrearsFormKey";
  const paid_types = [];
  const endDate = new Date(_get(formState, `${formGroupName}.to`, null));

  const targetYear = endDate.getFullYear();
  const targetMonth = endDate.getMonth();

  const paymentFormObj = getDataByFormObject(_get(formState, "paymentTypeFormKey", {}));
  _unset(paymentFormObj, "isDisplay");
  _unset(paymentFormObj, "payment_categories");
  _unset(paymentFormObj, "paid_amount");
  _unset(paymentFormObj, "cash_amount");
  _unset(paymentFormObj, "cheque_amount");
  _unset(paymentFormObj, "card_amount");

  if (!isEmpty(paymentFormObj)) {
    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        const cashBalance = parseFloat(_get(formState, "paymentTypeFormKey.paid_amount", 0) - _get(formState, "licence_charges_form.total", 0)).toFixed(2);

        const totAmount = _get(paymentFormObj, `[${key}]['payment_type']`, null) === PaymentCategoriesEnum.CASH
          ? parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null)) - cashBalance
          : parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null));
        paid_types.push({
          "payment_type_code": _get(paymentFormObj, `[${key}]['payment_type']`, null),
          "payment_gateway_code": null,
          "amount": totAmount,
          "cus_paid_amount": totAmount,
          "cheque_number": _get(paymentFormObj, `[${key}]['cheque_no']`, null),
          "cheque_date": null,
          "bank_id": _get(paymentFormObj, `[${key}]['bank']`, null),
          "card_type": null,
          "card_holder_name": null,
          "request_ref_no": _get(paymentFormObj, `[${key}]['reference_no']`, null),
          "payment_ref": null,
          "ipg_ref": null,
          "phone_no": null,
          "approval_code": _get(paymentFormObj, `[${key}]['approval_no']`, null),
          "note": null,
          "request_str": null,
          "response_str": null,
          "success": true,
          "legacy_cheque_id": null
        });
      }
    });
  }
  return (
    <SubmitButton
      isBtnDisabled={props.isBtnDisabled}
      btnText={props.btnText}
      startIcon={"fas fa-check"}
      formGroupName={formGroupName}
      isValidate={false}
      onClickBtnFn={props.onClickBtnFn}
      flashMessages={{
        success: {
          status: true,
          message: `Payment successfully completed.`,
          messageType: "success",
        },
        failed: {
          status: true,
          message: `Payment fail.`,
          messageType: "error",
        },
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          url: createLicenceArrearsOnlyAPI.url,
          key: createLicenceArrearsOnlyAPI.key,
        };
      }}
      onChangeRequestBodyFn={(fromObject) => {
        return getDataByFormObject({
          vehicle_no: _get(formState, "vehicle_search_form.data.vehicle_no", ""),
          paid_types: paid_types,
          licence_end_type: 0,
          target_amount: _get(formState, `${formGroupName}.total_arrears_amount`, 0),
          target_type: _get(formState, `${formGroupName}.target_type`, TargetType.AMOUNT),
          target_month: _get(formState, `${formGroupName}.target_type`, TargetType.AMOUNT) === TargetType.DATE ? targetMonth : null,
          target_year: _get(formState, `${formGroupName}.target_type`, TargetType.AMOUNT) === TargetType.DATE ? targetYear : null,
          from_date: _get(formState, `${formGroupName}.from`, null),
          serial_numbers: null,
          counter_id: _get(formState, `${formGroupName}.counter_id`, null),
          counter_session_id: _get(formState, `${formGroupName}.counter_session_id`, null),
          counter_serial_number_range_id: _get(formState, `${formGroupName}.counter_serial_number_range_id`, null),
        });
      }}
      callApiObject={{
        isSetHeaders: true,
        method: "post",
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.setResponse,
      }}
      onResponseCallBackFn={(response, error) => {
        if (response.length === undefined && error === undefined && response.data.errors === undefined) {
          formAction.changeInputFn(formGroupName, "print_error", "", "");
          props.closePopupFn();
          props.openPopupFn();
        } else {
          formAction.changeInputFn(formGroupName, "print_error", "", "User cannot proceed with the payment");
        }
      }}
    />
  );
};

const PaymentUI = ({
  uiType = "normal",
  closeMainPopup = emptyFn,
  formGroupName = "arrearsFormKey",
}) => {
  const [openPrint, setOpenPrint] = useState({ showPopup: false, });
  const [openConfirmation, setOpenConfirmation] = useState({ showPopup: false, });
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const user_id = _get(authStatus, "authUser.id", "");
  useEffect(() => {
    callApi(`${availableSessionListApi.url}/${user_id}/sessions/list`)
      .method("get")
      .headers(true)
      .body({})
      .isMultipart(false)
      .send(async (error, result) => {
        if (!error) {
          const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");
          const counter_id = isEmpty(_get(result, "data.data[0].counter_id.id", "")) ? "" : _get(result, "data.data[0].counter_id.id", "");
          const counter_serial_number_range_id = isEmpty(_get(result, "data.data[0].counter_id.current_counter_serial_number_range_id", "")) ? null : _get(result, "data.data[0].counter_id.current_counter_serial_number_range_id", "");
          const counter_name = isEmpty(_get(result, "data.data[0].counter_id.name", "")) ? "" : _get(result, "data.data[0].counter_id.name", "");
          const counter_code = isEmpty(_get(result, "data.data[0].counter_id.code", "")) ? "" : _get(result, "data.data[0].counter_id.code", "");
          const session_id = isEmpty(_get(result, "data.data[0].id", "")) ? "" : _get(result, "data.data[0].id", "");
          const formGroupName = "arrearsFormKey";

          formAction.changeInputFn(formGroupName, "next_serial", "", next_serial);
          formAction.changeInputFn(formGroupName, "counter_id", "", counter_id);
          formAction.changeInputFn(formGroupName, "counter_code", "", counter_code);
          formAction.changeInputFn(formGroupName, "counter_session_id", "", session_id);
          formAction.changeInputFn(formGroupName, "counter_serial_number_range_id", "", counter_serial_number_range_id);
        }
      });
  }, [_get(formState, "change_serial_on_session", "")]);

  const onPrintFn = () => {
    const paymentFormKey = "paymentTypeFormKey";
    const errorObject = [];
    const paymentFormObj = getDataByFormObject(_get(formState, paymentFormKey, {}));
    _unset(paymentFormObj, "isDisplay");
    _unset(paymentFormObj, "payment_categories");
    _unset(paymentFormObj, "paid_amount");
    _unset(paymentFormObj, "cash_amount");
    _unset(paymentFormObj, "cheque_amount");
    _unset(paymentFormObj, "card_amount");
    formAction.setFormErrorFn("paymentTypeFormKey", []);

    let isValid = false;

    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        let index = key.replace("payment_types_", "");
        switch (paymentFormObj[key]['payment_type']) {
          case PaymentCategoriesEnum.CASH:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cash Received is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CHEQUE:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cheque Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.cheque_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.cheque_no`,
                "message": "Cheque No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.bank`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.bank`,
                "message": "Bank is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CREDITCARDS:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Credit Card Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.reference_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.reference_no`,
                "message": "Reference No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.approval_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.approval_no`,
                "message": "Approval No. is required."
              });
            }
            break;
        }
        formAction.setFormErrorFn(paymentFormKey, errorObject);
      }
    });

    if (isEmpty(errorObject)) {
      isValid = true;
    }
    if (isValid) {
      formAction.changeInputFn(formGroupName, "print_error", "", "");
      formAction.setFormErrorFn("paymentTypeFormKey", [])
      setOpenConfirmation({
        ...openConfirmation,
        showPopup: true
      });
    } else {
      formAction.changeInputFn(formGroupName, "print_error", "", "User cannot proceed with the payment");
    }
  };

  const closeConfirmationPopup = () => {
    setOpenConfirmation({
      ...openConfirmation,
      showPopup: false
    })
  }

  const closePopup = () => {
    setOpenPrint({
      ...openPrint,
      showPopup: false
    })
  }

  const openPopup = () => {
    setOpenPrint({
      ...openPrint,
      showPopup: true
    })
  }

  const isBtnDisabled = () => {
    const total = getFlooredFixed(_get(formState, `${formGroupName}.total_arrears_amount`, 0), 2);
    let cardAmount = _get(formState, "paymentTypeFormKey.card_amount", 0);
    let cashAmount = _get(formState, "paymentTypeFormKey.cash_amount", 0);
    let chequeAmount = _get(formState, "paymentTypeFormKey.cheque_amount", 0);
    let isDissable = true;
    if (total > 0) {
      if ((cardAmount + chequeAmount) < total) {
        if (cashAmount >= total) {
          isDissable = false;
        }
        if ((cashAmount + cardAmount + chequeAmount) >= total) {
          isDissable = false;
        }
      }
      if ((cardAmount + chequeAmount) == total) {
        isDissable = false;
      }
    }
    return isDissable;
  }

  const serviceCharge = _get(formState, "licence_charges_form.data.service_charge.applied", false)
    ? _get(formState, "licence_charges_form.data.service_charge.charge", 0) : 0;

  return (
    <FormWrapper
      setGroupName={formGroupName}
      setFormObject={{
        print_error: "",
        from: dateObjectToString(_get(formState, `licence_charges_form.arrearsStartDate`, ""), "YY-mm-dd HH:MM:II"),
        to: _get(formState, `licence_charges_form.arrearsEndDate`, ""),
        target_type: TargetType.AMOUNT,
        target_year: new Date(_get(formState, `licence_charges_form.arrearsEndDate`, null)).getFullYear(),
        target_month: new Date(_get(formState, `licence_charges_form.arrearsEndDate`, null)).getMonth() + 1,
        total_arrears_fee: 0.00,
        total_arrears_fine: 0.00,
        total_arrears_additions: 0.00,
        total_arrears_deductions: 0.00,
        service_charge: 0.00,
        amount: _get(formState, "licence_charges_form.data.arrears_fee_total", 0) +
          _get(formState, "licence_charges_form.data.arrears_fine_total", 0) +
          _get(formState, "licence_charges_form.data.arrears_addition_total", 0) -
          _get(formState, "licence_charges_form.data.arrears_deduction_total", 0) +
          serviceCharge,
      }}
    >
      <div className="row">
        <div className={"col-md-12"}>
          <div className="row">
            <div className="col-md-6">
              <PaddingLessUICard cardHeading="Arrears Payment">
                <AmountBreakdown uiType={uiType} />
              </PaddingLessUICard>
            </div>

            <div className="col-md-6 ">
              <PaddingLessUICard elementStyle="payment-type-card" cardHeading="Payment Type">
                <PaymentType uiType={uiType} />
                <div className="fullWidthDiv">
                  <div className="row ">
                    <div className="col-md-4"></div>
                    <div className={`${_get(authStatus, "authUser.pdmtId.code", "") === _get(formState, "vehicle_search_form.provinceData.code", "") ? 'col-md-6' : 'col-md-7' }`}>
                      <div className="text-right defaultMarginTop">
                        {
                          (_get(formState, `${formGroupName}.print_error`, "") !== "") ? (
                            <p className={"defaultHalfMargin"} style={{ color: "red" }}>{_get(formState, `${formGroupName}.print_error`, "")}</p>
                          ) : null
                        }
                      </div>
                      <div className="floatRight">
                        <InputButton
                          elementWrapperStyle={"payBtnStyle"}
                          elementStyle={"paydefaultButton"}
                          btnText={"Pay"}
                          onClickBtnFn={() => onPrintFn()}
                          isBtnDisabled={isBtnDisabled()}
                        />
                      </div>
                    </div>


                  </div>
                </div>
              </PaddingLessUICard>
            </div>



            <DialogPopup
              isDialogActions={false}
              dialogTitle={"Please Confirm"}
              isFullWidth={false}
              isFullScreen={false}
              isCloseButton={true}
              maxWidth={dialogPopupMaxWidth.medium}
              children={
                <ConfirmationPopup
                  closePopupFn={closePopup}
                  onClickSuccessFn={closeMainPopup}
                  uiType={'arrears-temp-licence'}
                  openPopupFn={openPopup}
                  closeConfirmationPopupFn={closeConfirmationPopup}
                  confirmationMsg={"Are you sure you want to complete the Arrears Payment?"}
                />
              }
              isOpen={openConfirmation.showPopup}
              onClosePopupFn={closeConfirmationPopup}
            />

            <DialogPopup
              isDialogActions={false}
              dialogTitle="Print Receipt Confirmation"
              isFullWidth={false}
              isFullScreen={false}
              isCloseButton={true}
              children={
                <PrintReceiptConfirmation
                  onClickSuccessFn={closeMainPopup}
                  closePopupFn={closePopup}
                />
              }
              isOpen={openPrint.showPopup}
              onClosePopupFn={closePopup}
            />
          </div>
        </div>
      </div>

    </FormWrapper>
  );
};

export { PayArrearsOnlyBtn };
export default PaymentUI;
