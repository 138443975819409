
/*
 * @Author: Sujith
 * @Date: 2021-01-08 11:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-11 16:50:14
 */

import React, { Fragment, useContext, useState, useEffect } from "react";
import { Base64 } from "js-base64";

import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { SelectBoxWithState, InputBoxWithState, RoundButton, DatePickerWithState } from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { provincesOfficesListAPI } from "../../../../../config/apiUrl.config";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";

const PreviousOwnerInformation = (props) => {    
    const [isAdded, setIsAdded] = useState(false)
    const [authStatus] = useContext(AuthContext);
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");

    const checkPlusBtnVisibility = (index) => {
        return (
            Object.keys(_get(formState, "transfer_vehicle.previous_owners", {})).length === index + 1            
        );
    };
    
    const [formState, formAction] = useContext(FormContext);
    const [showPopup, setShowPopup] = useState(false);
    const [removeIndex, setRemoveIndex] = useState("");
    const dataLength = _get(formState, "transfer_vehicle.previous_owners_data", []).length;
    const dataL = _get(formState, "transfer_vehicle.previous_owners_data", []);
    const dataLA = _get(formState, "transfer_vehicle.previous_owners_added_data", []);
    const newChangedData = _get(formState, "transfer_vehicle.previous_owners_data_new", []);
    useEffect(() => {

    }, [showPopup])

    const getDisable = (id) => {
        const isExist = dataLA.length > 0 ? dataLA.some(el => el.vehicle_owner_id === id) : false
        return isExist
    }

    const getIndex = (id) => {
        
        const index = id !== null && dataL.length > 0 ? dataL.findIndex(el => el.id === id) : false
        return index
    }

    const changedDateGetFn = (index) => {
        const changedDate = newChangedData.length > 0 && newChangedData[index] && newChangedData[index].transferred_date ? 
        newChangedData[index].transferred_date : null

        return changedDate
    }

    const RemoveBtn = () => {
        return (
            <InputButton
                elementWrapperStyle={"defaultHalfMarginLeftRight"}
                btnName="submit"
                btnText={"Yes"}
                onClickBtnFn={() => {
                    const newData = _get(formState, "transfer_vehicle.previous_owners", {"0": {}})
                    if(props.uiType === "APR" && Object.keys(newData[0]).length === 0) {
                        const incomingData = _get(formState, "transfer_vehicle.previous_owners_added_data", [])
                        incomingData.splice(removeIndex, 1)
                        formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.previous_owners_added_data`, incomingData);
                        
                    }

                        if(removeIndex === 0) {
                            setShowPopup(false)
                            setIsAdded(false)
                            formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.previous_owners`, {"0": {}});
                        } else {
                            let monthRangeObj = _get(formState, "transfer_vehicle.previous_owners", {});
                            delete monthRangeObj[removeIndex.toString()];
                            formAction.changeInputFn("transfer_vehicle", "", "transfer_vehicle.previous_owners", monthRangeObj);
                            setShowPopup(false)
                        }

                        
                    
                    }
                }
             />
        )
    }


    return (
        <Fragment>
            <UICard cardHeading="Previous Owner Information">
            {console.log(_get(formState, "transfer_vehicle.previous_owners_data", []))}
               {_get(formState, "transfer_vehicle.previous_owners_data", []).map((value, index) => {

                   return (
                       <UICard>
                       <div className={"row"}>
                       <div className="floatLeft col-md-3 clearBoth">
                           <InputBoxWithState
                               uiType={templateTypes.bootstrap}
                               formGroupName={props.formGroupName}
                               inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.name`}
                               labelText="Name"
                               isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                               maxLength={100}
                               inputName={"name"}
                               inputValue={value.name && value.name !== null ? getLabelValue(value.name, true, true) : ""}
                               isRequired={true}
                               
                           />
                       </div>

                       <div className="floatLeft col-md-3">
                           <InputBoxWithState
                               uiType={templateTypes.bootstrap}
                               formGroupName="transfer_vehicle"
                               inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.nic`}
                               labelText="Identification Number"
                               isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                               maxLength={12}
                               inputName={"nic"}
                               inputValue={value.nic && value.nic !== null ? Base64.decode(value.nic) : ""}
                               isAllowedFn={(value) => {
                                const checkValue = value.value.toString();
                                return checkValue.length < 13;
                                }}
                               //isRequired={true}
                           />
                       </div>
                       <div className="floatLeft col-md-3">
                           <div className="row justify-content-start">
                               <div className="col-1 pr-0 pt-4">
                                   0094
                               </div>
                               {/* {console.log('SAAA', value.contact_number && value.contact_number !== null ? Base64.decode(value.contact_number) : value.contact_number)} */}
                               <div className="col-11 pt-1">
                               {props.uiType === 'viewApproval' ? 
                                        <InputBoxWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName="transfer_vehicle"
                                        inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.mobile_number`}
                                        labelText={"Mobile Number"}
                                        inputName={"mobile_number"}
                                        inputValue={(props.uiType === 'viewApproval' || props.uiType === 'APR') ? (value.mobile_number && value.mobile_number !== null ? Base64.decode(value.mobile_number) : (value.contact_number && value.contact_number !== null ? Base64.decode(value.contact_number) : "")) : (value.contact_number && value.contact_number !== null ? Base64.decode(value.contact_number) : "")}
                                        isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                                        isRequired={true}
                                        />
                                :
                                        <NumberInputBoxWithState
                                           uiType={templateTypes.bootstrap}
                                           formGroupName="transfer_vehicle"
                                           inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.mobile_number`}
                                           labelText={"Mobile Number"}
                                           inputName={"mobile_number"}
                                           isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                                           isAllowNegative={false}
                                           isThousandSeparator={false}
                                           isAllowLeadingZeros={false}
                                           decimalScale={0}
                                           inputValue={(props.uiType === 'viewApproval' || props.uiType === 'APR') ? (value.mobile_number && value.mobile_number !== null ? Base64.decode(value.mobile_number) : (value.contact_number && value.contact_number !== null ? Base64.decode(value.contact_number) : "")) : (value.contact_number && value.contact_number !== null ? Base64.decode(value.contact_number) : "")}
                                           isAllowedFn={(value) => {
                                               const checkValue = value.value.toString();
                                               return checkValue.length < 10;
                                           }}
                                       />
                                }
                                           
                                   
                               </div>
                           </div>
                          

                       </div>
                       
                       <div className="floatLeft col-md-3">
                           <InputBoxWithState
                               uiType={templateTypes.bootstrap}
                               formGroupName="transfer_vehicle"
                               inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.address1`}
                               labelText="Address Line 1 "
                               maxLength={50}
                               inputName={"address1"}
                               inputValue={(props.uiType === 'viewApproval' || props.uiType === 'APR') ? (value.address1 && value.address1 !== null ? Base64.decode(value.address1) : (value.address_line_1 && value.address_line_1 !== null ? Base64.decode(value.address_line_1) : "")) : (value.address_line_1 && value.address_line_1 !== null ? Base64.decode(value.address_line_1) : "")}
                               isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                               isRequired={true}
                           />
                       </div>
                       <div className="floatLeft col-md-3">
                           <InputBoxWithState
                               uiType={templateTypes.bootstrap}
                               formGroupName="transfer_vehicle"
                               inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.address2`}
                               labelText="Address Line 2 "
                               maxLength={50}
                               inputName={"address2"}
                               inputValue={(props.uiType === 'viewApproval' || props.uiType === 'APR') ? (value.address2 && value.address2 !== null ? Base64.decode(value.address2) : (value.address_line_2 && value.address_line_2 !== null ? Base64.decode(value.address_line_2) : "")) : (value.address_line_2 && value.address_line_2 !== null ? Base64.decode(value.address_line_2) : "")}
                               isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                           />
                       </div>

                       <div className="floatLeft col-md-3">
                           <InputBoxWithState
                               uiType={templateTypes.bootstrap}
                               formGroupName="transfer_vehicle"
                               inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.city`}
                               labelText="City "
                               maxLength={50}
                               inputName={"city"}
                               inputValue={value.city && value.city !== null ? value.city : ""}
                               isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                               isRequired={true}
                           />
                       </div>
                       <div className="floatLeft col-md-3">
                           <SelectBoxWithState
                               uiType={templateTypes.bootstrap}
                               formGroupName="transfer_vehicle"
                               apiUrl={provincesOfficesListAPI.url}
                               keyName={"id"}
                               inputValue={value.province}
                               valueName={"province.name"}
                               inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.province`}
                               labelText="Province"
                               emptySelectOptionTxt={"Select Province"}
                               isRequired={true}
                               isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                           />
                       </div>
                                           {console.log('AAA--', index, dataL[index - 1])}
                       <div className="col-md-2 floatLeft">
                           <DatePickerWithState
                               uiType={templateTypes.bootstrap}
                               formGroupName="transfer_vehicle"
                               labelText="Transferred Date"
                               inputName={"transferred_date"}
                            //    minDate={index !== 0 && dataLength !== 0 ? dataL[index - 1]['transferred_date'] : '01/01/1970'}
                               minDate={index !== 0 && dataLength > 0 ? changedDateGetFn(index - 1) !== null ? changedDateGetFn(index - 1) : new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? _get(formState, "transfer_vehicle.previous_owners_data", [])[index - 1]['transferred_on'] ? 
                               _get(formState, "transfer_vehicle.previous_owners_data", [])[index - 1]['transferred_on'] : '01/01/1970' : 
                               _get(formState, "transfer_vehicle.previous_owners_data", [])[index - 1]['transferred_on']) : '01/01/1970'}
                               maxDate={index !== (dataLength - 1) && dataLength > 0 ? 
                                changedDateGetFn(index + 1) !== null ? 
                                changedDateGetFn(index + 1) : new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? 
                                _get(formState, "transfer_vehicle.previous_owners_data", [])[index + 1]['transferred_on'] ? 
                               _get(formState, "transfer_vehicle.previous_owners_data", [])[index + 1]['transferred_on'] : '01/01/2050' : 
                               _get(formState, "transfer_vehicle.previous_owners_data", [])[index + 1]['transferred_on']) : 
                               
                               Object.keys(_get(formState, "transfer_vehicle.previous_owners", {})).length > 0 && 
                               _get(formState, `transfer_vehicle.previous_owners.${0}.transferred_date`, null) !== null ? 
                               _get(formState, `transfer_vehicle.previous_owners.${0}.transferred_date`, null) : _get(formState, `transfer_vehicle.current_erl_new`, []).length > 0 && 
                               _get(formState, `transfer_vehicle.current_erl_new`, null)[0].transferred_date ? _get(formState, `transfer_vehicle.current_erl_new`, null)[0].transferred_date : '01/01/2050'
                            }
                               inputValue={new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? value.transferred_date ? value.transferred_date : value.transferred_on : value.transferred_on)}
                               inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.transferred_date`}
                               isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? getDisable(value.id) : false}
                               isRequired={true}
                           />
                       </div>
                       {
                        ((props.uiType === 'APR' && index == 0 && _get(formState, "transfer_vehicle.previous_owners_data", []).length > 1) || (index != _get(formState, "transfer_vehicle.previous_owners_data", []).length - 1)) ? null :
                            Object.keys(_get(formState, "transfer_vehicle.previous_owners", {})).map((value, index) => {
                                return (
                                    <>
                                        {
                                            (!isAdded && props.uiType !== 'viewApproval' && _get(formState, "transfer_vehicle.previous_owners_added_data", []).length === 0) ? (
                                                <div className="floatRight">
                                                    <RoundButton
                                                        elementStyle={"mr-2"}
                                                        iconClass={"fas fa-plus"}
                                                        onClickBtnFn={(eventData) => {
                                                            formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.previous_owners.${index}.name`);
                                                            setIsAdded(true)
                                                        }}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                    </>
                                )                    
                            })
                           }
                           {console.log('1111-', _get(formState, "transfer_vehicle.previous_owners_added_data", []))}
                   </div>
                   </UICard>
                   )

               })}
               </UICard>
               <UICard cardHeading="Changed Previous Owner Information">
               {props.uiType === 'APR' && _get(formState, "transfer_vehicle.previous_owners_added_data", []).length > 0 ? 
                _get(formState, "transfer_vehicle.previous_owners_added_data", []).map((value, index) => {
                    return (
                        <UICard>
                        <div className={"row"}>
                        <div className="floatLeft col-md-3 clearBoth">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName={props.formGroupName}
                                inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.name`}
                                labelText="Name"
                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                                maxLength={100}
                                inputName={"name"}
                                inputValue={value.name && value.name !== null ? getLabelValue(value.name, true, true) : ""}
                                isRequired={true}
                                
                            />
                        </div>
    
                        <div className="floatLeft col-md-3">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName="transfer_vehicle"
                                inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.nic`}
                                labelText="Identification Number"
                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                                maxLength={12}
                                inputName={"nic"}
                                inputValue={value.nic && value.nic !== null ? Base64.decode(value.nic) : ""}
                                isAllowedFn={(value) => {
                                 const checkValue = value.value.toString();
                                 return checkValue.length < 13;
                                 }}
                                //isRequired={true}
                            />
                        </div>
                        <div className="floatLeft col-md-3">
                            <div className="row justify-content-start">
                                <div className="col-1 pr-0 pt-4">
                                    0094
                                </div>
                                {console.log('SAAA', value.contact_number)}
                                <div className="col-11 pt-1">
                                            <NumberInputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.mobile_number`}
                                            labelText={"Mobile Number"}
                                            inputName={"mobile_number"}
                                            isDisabled={props.uiType === 'viewApproval' ? true : false}
                                            isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={false}
                                            decimalScale={0}
                                            inputValue={(props.uiType === 'viewApproval' || props.uiType === 'APR') ? (value.mobile_number && value.mobile_number !== null ? Base64.decode(value.mobile_number) : (value.contact_number && value.contact_number !== null ? Base64.decode(value.contact_number) : "")) : (value.contact_number && value.contact_number !== null ? Base64.decode(value.contact_number) : "")}
                                            isAllowedFn={(value) => {
                                                const checkValue = value.value.toString();
                                                return checkValue.length < 10;
                                            }}
                                        />
                                    
                                </div>
                            </div>
                           
    
                        </div>
                        
                        <div className="floatLeft col-md-3">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName="transfer_vehicle"
                                inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.address1`}
                                labelText="Address Line 1 "
                                maxLength={50}
                                inputName={"address1"}
                                inputValue={(props.uiType === 'viewApproval' || props.uiType === 'APR') ? (value.address1 && value.address1 !== null ? Base64.decode(value.address1) : (value.address_line_1 && value.address_line_1 !== null ? Base64.decode(value.address_line_1) : "")) : (value.address_line_1 && value.address_line_1 !== null ? Base64.decode(value.address_line_1) : "")}
                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                                isRequired={true}
                            />
                        </div>
                        <div className="floatLeft col-md-3">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName="transfer_vehicle"
                                inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.address2`}
                                labelText="Address Line 2 "
                                maxLength={50}
                                inputName={"address2"}
                                inputValue={(props.uiType === 'viewApproval' || props.uiType === 'APR') ? (value.address2 && value.address2 !== null ? Base64.decode(value.address2) : (value.address_line_2 && value.address_line_2 !== null ? Base64.decode(value.address_line_2) : "")) : (value.address_line_2 && value.address_line_2 !== null ? Base64.decode(value.address_line_2) : "")}
                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                            />
                        </div>
    
                        <div className="floatLeft col-md-3">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName="transfer_vehicle"
                                inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.city`}
                                labelText="City "
                                maxLength={50}
                                inputName={"city"}
                                inputValue={value.city && value.city !== null ? value.city : ""}
                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                                isRequired={true}
                            />
                        </div>
                        <div className="floatLeft col-md-3">
                            <SelectBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName="transfer_vehicle"
                                apiUrl={provincesOfficesListAPI.url}
                                keyName={"id"}
                                inputValue={value.province}
                                valueName={"province.name"}
                                inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.province`}
                                labelText="Province"
                                emptySelectOptionTxt={"Select Province"}
                                isRequired={true}
                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                            />
                        </div>
                                            {console.log('AAA--', index, dataL[index - 1])}
                        <div className="col-md-2 floatLeft">
                            <DatePickerWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName="transfer_vehicle"
                                labelText="Transferred Date"
                                inputName={"transferred_date"}
                                minDate={index == 0 && dataLength > 0 ? new Date('01/01/1970') : 
                                index > 0 && _get(formState, "transfer_vehicle.previous_owners_added_data", []).length > 0 ? changedDateGetFn(index - 1) !== null ? changedDateGetFn(index - 1) : new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_date'] ? 
                                _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_date'] : _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_on'] : 
                                _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_on']) 
                                : '01/01/1970'}
                                // minDate={index == 0 && dataLength > 0 ? new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] ? 
                                // _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] : _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on'] : 
                                // _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on']) : 
                                // index > 0 && _get(formState, "transfer_vehicle.previous_owners_added_data", []).length > 0 ? new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_date'] ? 
                                // _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_date'] : _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_on'] : 
                                // _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index - 1]['transferred_on']) 
                                // : '01/01/1970'}
                                maxDate={index !== (_get(formState, "transfer_vehicle.previous_owners_added_data", []).length - 1) && _get(formState, "transfer_vehicle.previous_owners_added_data", []).length > 0 ? changedDateGetFn(index + 1) !== null ? changedDateGetFn(index + 1) : new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index + 1]['transferred_date'] ? 
                                _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index + 1]['transferred_date'] : _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index + 1]['transferred_on'] : 
                                _get(formState, "transfer_vehicle.previous_owners_added_data", [])[index + 1]['transferred_on']) 
                                : '01/01/2050'}
                                inputValue={new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? value.transferred_date ? value.transferred_date : value.transferred_on : value.transferred_on)}
                                inputStatePath={`transfer_vehicle.previous_owners_data_new.${index}.transferred_date`}
                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                                isRequired={true}
                            />
                        </div>
                        {
                         (props.uiType === 'APR' && (index !== _get(formState, "transfer_vehicle.previous_owners_added_data", []).length - 1)) ? null :
                             Object.keys(_get(formState, "transfer_vehicle.previous_owners", {})).map((value, index) => {
                                 return (
                                     <>
                                         {
                                             (!isAdded && props.uiType !== 'viewApproval') ? (
                                                 <div className="floatRight">
                                                     <RoundButton
                                                         elementStyle={"mr-2"}
                                                         iconClass={"fas fa-plus"}
                                                         onClickBtnFn={(eventData) => {
                                                             formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.previous_owners.${index}.name`);
                                                             setIsAdded(true)
                                                         }}
                                                     />
                                                 </div>
                                             ) : null
                                         }
                                     </>
                                 )                    
                             })
                            }
                            {console.log('1111-', _get(formState, "transfer_vehicle.previous_owners_added_data", []))}
                            {
                             (props.uiType === 'APR' && (index !== _get(formState, "transfer_vehicle.previous_owners_added_data", []).length - 1)) ? null :
                                     Object.keys(_get(formState, "transfer_vehicle.previous_owners", {})).map((value, index1) => {
                                         return (
                                             <>
                                                 {
                                                     (!isAdded && props.uiType !== 'viewApproval' && roleCategoryCode !== 'OPT' && (index === _get(formState, "transfer_vehicle.previous_owners_added_data", []).length - 1) ) ? (
                                                         <div className="floatRight">
                                                            <RoundButton
                                                                 iconClass={"fas fa-minus"}
                                                                 elementStyle={"errorBtn"}
                                                                 onClickBtnFn={(eventData) => {
                                                                     setShowPopup(true)
                                                                     setRemoveIndex(index)
                                                                 }}
                                                             />
                                                         </div>
                                                     ) : null
                                                 }
                                             </>
                                         )                    
                                     }) 
                                 }
                                 <DialogPopup
                                    isOpen={showPopup}
                                    dialogTitle={"Remove"}
                                    isFullScreen={false}
                                    isCustomButton={true}
                                    customButtonComponent={RemoveBtn}
                                    formGroupName={"transfer_vehicle"}
                                    onClosePopupFn={() =>
                                        setShowPopup(!showPopup)
                                    }
                                    closeButton={{
                                        btnText: "No",
                                    }}
                                >
                                        <div className="fullWidthDiv">
                                            <p>Are you sure you want to remove?</p>
                                        </div>
                                </DialogPopup>
                    </div>
                    </UICard>
                    )
                })
                 : null
                }
                </UICard>
                {/* <UICard cardHeading="Original Previous Owner Data" > */}
                {
                    isAdded ?
                    Object.keys(_get(formState, "transfer_vehicle.previous_owners", {})).map((value, index = 0) => {

                        return (
                            <UICard>
                            <div className={"row"}>
                                <div className="floatLeft col-md-3 clearBoth">
                                    <InputBoxWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName={props.formGroupName}
                                        inputStatePath={`transfer_vehicle.previous_owners.${index}.name`}
                                        labelText="Name"
                                        isDisabled={props.uiType === 'viewApproval' ? true : false}
                                        maxLength={100}
                                        inputName={"name"}
                                        isRequired={true}
                                    />
                                </div>

                                <div className="floatLeft col-md-3">
                                    <InputBoxWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName="transfer_vehicle"
                                        inputStatePath={`transfer_vehicle.previous_owners.${index}.nic`}
                                        labelText="Identification Number"
                                        isDisabled={props.uiType === 'viewApproval' ? true : false}
                                        maxLength={12}
                                        inputName={"nic"}
                                        isAllowedFn={(value) => {
                                            const checkValue = value.value.toString();
                                            return checkValue.length < 13;
                                        }}
                                    />
                                </div>
                                <div className="floatLeft col-md-3">
                                    <div className="row justify-content-start">
                                        <div className="col-1 pr-0 pt-4">
                                            0094
                                        </div>
                                        <div className="col-11 pt-1">
                                                    <NumberInputBoxWithState
                                                    uiType={templateTypes.bootstrap}
                                                    formGroupName="transfer_vehicle"
                                                    inputStatePath={`transfer_vehicle.previous_owners.${index}.mobile_number`}
                                                    labelText={"Mobile Number"}
                                                    inputName={"mobile_number"}
                                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                                    isAllowNegative={false}
                                                    isThousandSeparator={false}
                                                    isAllowLeadingZeros={false}
                                                    decimalScale={0}
                                                    isAllowedFn={(value) => {
                                                        const checkValue = value.value.toString();
                                                        return checkValue.length < 10;
                                                    }}
                                                />
                                            
                                        </div>
                                    </div>
                                   

                                </div>
                                
                                <div className="floatLeft col-md-3">
                                    <InputBoxWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName="transfer_vehicle"
                                        inputStatePath={`transfer_vehicle.previous_owners.${index}.address1`}
                                        labelText="Address Line 1 "
                                        maxLength={50}
                                        inputName={"address1"}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="floatLeft col-md-3">
                                    <InputBoxWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName="transfer_vehicle"
                                        inputStatePath={`transfer_vehicle.previous_owners.${index}.address2`}
                                        labelText="Address Line 2 "
                                        maxLength={50}
                                        inputName={"address2"}
                                        //isRequired={true}
                                    />
                                </div>

                                <div className="floatLeft col-md-3">
                                    <InputBoxWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName="transfer_vehicle"
                                        inputStatePath={`transfer_vehicle.previous_owners.${index}.city`}
                                        labelText="City "
                                        maxLength={50}
                                        inputName={"city"}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="floatLeft col-md-3">
                                    <SelectBoxWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName="transfer_vehicle"
                                        apiUrl={provincesOfficesListAPI.url}
                                        keyName={"id"}
                                        valueName={"province.name"}
                                        inputStatePath={`transfer_vehicle.previous_owners.${index}.province`}
                                        labelText="Province"
                                        emptySelectOptionTxt={"Select Province"}
                                        isRequired={true}
                                    />
                                </div>

                                <div className="col-md-2 floatLeft">
                                    <DatePickerWithState
                                        uiType={templateTypes.bootstrap}
                                        formGroupName="transfer_vehicle"
                                        labelText="Transferred Date"
                                        inputName={"transferred_date"}
                                        inputValue={null}
                                        minDate={new Date(_get(formState, "transfer_vehicle.previous_owners_data", []) && dataLength !== 0 ? _get(formState, "transfer_vehicle.previous_owners_data", [])[dataLength - 1].transferred_on : new Date())}
                                        maxDate={new Date()}
                                        isRequired={true}
                                        inputStatePath={`transfer_vehicle.previous_owners.${index}.transferred_date`}
                                    />
                                </div>
                                {
                                    (checkPlusBtnVisibility(index)) && props.uiType !== 'viewApproval' ? (
                                        <div className="floatRight">
                                            <RoundButton
                                                elementStyle={"mr-2"}
                                                iconClass={"fas fa-plus"}
                                                onClickBtnFn={(eventData) => {
                                                    formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.previous_owners.${index + 1}.name`);
                                                }}
                                            />
                                        </div>
                                    ) : null
                                }


                                {
                                    (Object.keys(_get(formState, "transfer_vehicle.previous_owners", {})).length === index + 1) ? (
                                        <RoundButton
                                            iconClass={"fas fa-minus"}
                                            elementStyle={"errorBtn"}
                                            onClickBtnFn={(eventData) => {
                                                setShowPopup(true)
                                                setRemoveIndex(index)
                                            }}
                                        />
                                    ) : null
                                }
                                
                                <DialogPopup
                                    isOpen={showPopup}
                                    dialogTitle={"Remove"}
                                    isFullScreen={false}
                                    isCustomButton={true}
                                    customButtonComponent={RemoveBtn}
                                    formGroupName={"transfer_vehicle"}
                                    onClosePopupFn={() =>
                                        setShowPopup(!showPopup)
                                    }
                                    closeButton={{
                                        btnText: "No",
                                    }}
                                >
                                        <div className="fullWidthDiv">
                                            <p>Are you sure you want to remove?</p>
                                        </div>
                                </DialogPopup>

                            </div>
                            </UICard>
                        )
                    })
                    : null
                }
                {/* </UICard> */}
            {/* </UICard> */}
        </Fragment>



    );

};

export { PreviousOwnerInformation };



