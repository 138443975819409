import React, { useState, useEffect, Fragment, useContext } from "react";

import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../../../ui-components/ui-elements/modal/DialogPopup";
import { MenuItemWrapper, MenuWrapper } from "../../../../../ui-components/ui-elements/lists-and-menus/ListsAndMenus";
import { AddDealer, AddDealerBtn } from "../../../../request-for-approval/pages/AddDealer";
import { UpdateDealer, UpdateDealerBtn } from "../../../../request-for-approval/pages/UpdateDealer";
import { DealersLicenceReissuance, DealersLicenceReissuanceBtn } from "./DealersLicenceReissuance";
import { AddLicenceTransaction, TransactionSubmitBtn } from "./AddLicenceTransaction";
import { CancelLicence, CancelDlrLcnSubmitBtn } from "./CancelLicence";
import { LicenceReprint, LicenceReprintBtn } from './LicenceReprint'
import { ChangeSerialNumber, ChangeSerialNumberBtn } from './ChangeSerialNumber'
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { AddLicenceTransactionAPI, getDealerCurrentValidlicenceDetailsAPI, getDealerPendingVehicleDetailsAPI, AutharizedVehicleInformationAPI, uploadDetailGetAPI } from "../../../../../../config/apiUrl.config";
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";

import { isEmpty } from "lodash";
import {  getDealerVehicleDetailsAPI } from "../../../../../../config/apiUrl.config";
import { permissions } from "../../../../../../config/permission.config"; 
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
const emptyFn = (...para) => undefined;
const RequestForBtn = ({sessionId, counterId,refreshMainScreenDataFn = emptyFn}) => {
    const [formState, formAction] = useContext(FormContext);
    const [authStatus] = useContext(AuthContext);
    const [getState, setState] = useState({
        searchKey: "",
        showMenu: false,
        anchorEl: null,
        modelType: null,
        showModel: false,
    });

    useEffect(() => {
        getReqApprovalData()
        
      }, [])


    const pendingWorkflowFormKey = "pending_workflow_key";
    const dataTableKey = 'dealer_licence_serial_number_change'

    const addDealer_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_ADD_DEALER_ADD.permissions[0]) ? true : false
    const updateDealer_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_UPDATE_DEALER_ADD.permissions[0]) ? true : false
    const changeSerialNum_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_DEALER_CHANGE_SERIAL_NUMBER_ADD.permissions[0]) ? true : false
    const addLicence_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_DEALER_ADD_LICENCE_TRANSACTION_ADD.permissions[0]) ? true : false
    const cancelLicence_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_DEALER_CANCEL_LICENCE_TRANSACTION_ADD.permissions[0]) ? true : false
    const licenceReprint_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_DEALER_LICENCE_REPRINT_ADD.permissions[0]) ? true : false
    const reissuance_permission = _get(authStatus, 'authUser.permissions', "").find(item => item.permission === permissions.WF_DEALERS_LICENCE_REISSUANCE_ADD.permissions[0]) ? true : false
    let data = [];
    data = _get(formState, `${pendingWorkflowFormKey}.workflows`, [])
    const changeSerialNum = data.filter(item => item.request_type === 'chng_dlr_se_num').length === 0 ? true : false;
   const updateDealer=data.filter(item => item.request_type === 'update_dlr').length === 0 ? true : false;
   const licenceReprint=data.filter(item => item.request_type === 'reprint_dlr_lcn').length === 0 ? true : false;
   const Reissuance=data.filter(item => item.request_type === 'reissue_dlr_lcn').length === 0 ? true : false;
   const addLicence=data.filter(item => item.request_type === 'add_lcn_txn').length === 0 ? true : false;
   const cancelLicence=data.filter(item => item.request_type === 'cncl_dlr_lcn_txn').length === 0 ? true : false;
   const addDealer=data.filter(item => item.request_type === 'add_dlr').length === 0 ? true : false;
   console.log('ADDD', data);
   const isDealerExist = _get(formState, 'dealer_search_form.data', null) &&  _get(formState, 'dealer_search_form.data.dealer_id', null) === _get(formState, 'dealer_search_form.searchKey', null)?.toUpperCase()?.trim() ? true : false
   

   const getReqApprovalData = (props) => {
    const licencedDealerV = sendAPIRequest(`${getDealerCurrentValidlicenceDetailsAPI.url}/${_get(formState, 'dealer_search_form.searchKey', "")}?sort_by=vehicle_number|asc,validity_from|desc`);
    const  pendingDealerV = sendAPIRequest(`${getDealerPendingVehicleDetailsAPI.url}/${_get(formState, 'dealer_search_form.searchKey', "")}`);
    
    Promise.all([pendingDealerV, licencedDealerV])
    .then(result => {
      const pendingVehicles = result[0]['data']
      const licencedVehicles = result[1]['data']

      console.log('PEEEEEEEE', pendingVehicles);
      console.log('PEEEEEEEE22', licencedVehicles);
      
      formAction.changeInputFn("dlr_vehicle_licenced_data_set", "data", "", [...licencedVehicles]);
    })
    // callApi(`${getDealerCurrentValidlicenceDetailsAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.dealer_id : _get(formState, 'dealer_search_form.searchKey', "")}?sort_by=vehicle_number|asc,validity_from|desc`)
    //   .method("get")
    //   .headers(true)
    //   .isMultipart(false)
    //   .send((error, result) => {
    //     if (!error) {
    //       formAction.changeInputFn("vehicle_data_set", "data", "", result.data.data);
    //     }
    //   })
  }
  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result?.data);
        })
    });
  };
   
  // _get(formState, 'vehicle_search_form.data', null) && _get(formState, 'vehicle_search_form.data.vehicle_no', null) === _get(formState, 'vehicle_search_form.searchKey', null)?.trim() ? true : false
   
    const getModelTitle = () => {
        switch (getState.modelType) {
            case "add_dealer":
                return "Add Dealer";
            case "update_dealer":
                return "Update Dealer";
            case "licence_reprint":
                return "Licence Reprint";
            case "change_serial_number":
                return "Change Serial Number";
            case "licence_reissuance":
                return "Dealer's Licence Reissuance";
            case "view_dealer":
                return "View Dealer Information";
            case "add_licence_transaction":
                return "Add Licence Transaction";
            case "cancel_licence_transaction":
                return "Cancel Licence Transaction";
            default:
                return "";
        }
    };

    const getModelUI = (props) => {
        switch (getState.modelType) {
            case "add_dealer":
                return <AddDealer uiType={'request'} />;
            case "update_dealer":
                return <UpdateDealer uiType={'request'} />;
            case "licence_reprint":
                return <LicenceReprint formKey={getState.modelType} uiType={'request'} />;
            case "change_serial_number":
                return <ChangeSerialNumber formKey={getState.modelType} uiType={'request'} />;
            case "licence_reissuance":
                return <DealersLicenceReissuance formKey={getState.modelType} uiType={'request'}/>;
            case "view_dealer":
                return <Fragment uiType={'request'}></Fragment>;
            case "add_licence_transaction":
                return <AddLicenceTransaction counterId={counterId} formKey={getState.modelType} uiType={'request'}/>;
            case "cancel_licence_transaction":
                return <CancelLicence counterId={counterId} formKey={getState.modelType} uiType={'request'} />;
            default:
                return null;
        }
    };

    const getBtn = (props) => {
        switch (getState.modelType) {
            case "add_dealer":
                return <AddDealerBtn sessionId={sessionId} {...props} />;
            case "update_dealer":
                return <UpdateDealerBtn sessionId={sessionId} {...props} />;
            case "licence_reprint":
                return <LicenceReprintBtn sessionId={sessionId} formKey={getState.modelType} {...props} />;
            case "change_serial_number":
                return <ChangeSerialNumberBtn counterId={counterId} sessionId={sessionId} formKey={getState.modelType} {...props} />;
            case "licence_reissuance":
                return <DealersLicenceReissuanceBtn sessionId={sessionId} formKey={getState.modelType} {...props}/>;
            case "view_dealer":
                return <Fragment sessionId={sessionId} {...props}></Fragment>;
            case "add_licence_transaction":
                return <TransactionSubmitBtn counterId={counterId} sessionId={sessionId} {...props} />;
            case "cancel_licence_transaction":
                return <CancelDlrLcnSubmitBtn counterId={counterId} sessionId={sessionId} {...props}/>;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            {!isEmpty(_get(formState, 'dealer_search_form.searchKey', null)?.trim())?
            <span
                onClick={(event) =>
                    setState({
                        ...getState,
                        anchorEl: event.currentTarget,
                        showMenu: true,
                    })
                }
            >
                <InputButton
                    elementWrapperStyle={""}
                    btnName="submit"
                    btnText={"Request For"}
                />

                <InputButton
                    elementWrapperStyle={""}
                    btnName="submit"
                    overrideTextToIcon={<i className="fas fa-caret-down"></i>}
                />
            </span>
          : ""}
            <MenuWrapper
                anchorEl={getState.anchorEl}
                open={getState.showMenu}
                onCloseFn={() =>
                    setState({
                        ...getState,
                        anchorEl: null,
                        showMenu: false,
                    })
                }
                elementStyle={"requestFor"}
            >
                {!isDealerExist  && addDealer_permission ? 
                <>
                    {addDealer===true && <MenuItemWrapper
                        elementStyle={"profileMenuItem"}
                        menuKey="delegation"
                        onClickFn={() =>
                            setState({
                                ...getState,
                                modelType: "add_dealer",
                                showModel: true,
                                anchorEl: null,
                                showMenu: false,
                            })
                        }
                    >
                        <p>Add Dealer</p>
                    </MenuItemWrapper>
                    }
                </>
                :
                    <Fragment>
                     {(updateDealer===true && updateDealer_permission) &&  <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                        setState({
                            ...getState,
                            modelType: "update_dealer",
                            showModel: true,
                            anchorEl: null,
                            showMenu: false,
                        })
                    }
                >
                    <p>Update Dealer</p>
                </MenuItemWrapper>}

              {licenceReprint===true && licenceReprint_permission &&  <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                        setState({
                            ...getState,
                            modelType: "licence_reprint",
                            showModel: true,
                            anchorEl: null,
                            showMenu: false,
                        })
                    }
                >
                    <p>Licence Reprint</p>
                </MenuItemWrapper>}
                    {console.log('SEE', _get(formState, 'dlr_vehicle_licenced_data_set.data', null))}
              {(_get(formState, 'dlr_vehicle_licenced_data_set.data', null) !== null &&
              _get(formState, 'dlr_vehicle_licenced_data_set.data', []).length !== 0) && changeSerialNum===true && changeSerialNum_permission &&  <MenuItemWrapper
                    elementStyle={"requestForMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                        setState({
                            ...getState,
                            modelType: "change_serial_number",
                            showModel: true,
                            anchorEl: null,
                            showMenu: false,
                        })
                    }
                >
                    <p>Change Serial Number</p>
                </MenuItemWrapper>}

             {/* {Reissuance===true && reissuance_permission  && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                        setState({
                            ...getState,
                            modelType: "licence_reissuance",
                            showModel: true,
                            anchorEl: null,
                            showMenu: false,
                        })
                    }
                >
                    <p>Dealer's Licence Reissuance</p>
                </MenuItemWrapper>} */}

                {/* <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                        setState({
                            ...getState,
                            modelType: "view_dealer",
                            showModel: true,
                            anchorEl: null,
                            showMenu: false,
                        })
                    }
                >
                    <p>View Dealer Information</p>
                </MenuItemWrapper> */}

              {addLicence===true && addLicence_permission && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                        setState({
                            ...getState,
                            modelType: "add_licence_transaction",
                            showModel: true,
                            anchorEl: null,
                            showMenu: false,
                        })
                    }
                >
                    <p>Add Licence Transaction</p>
                </MenuItemWrapper>}

                {cancelLicence===true && cancelLicence_permission && <MenuItemWrapper
                    elementStyle={"profileMenuItem"}
                    menuKey="delegation"
                    onClickFn={() =>
                        setState({
                            ...getState,
                            modelType: "cancel_licence_transaction",
                            showModel: true,
                            anchorEl: null,
                            showMenu: false,
                        })
                    }
                >
                    <p>Cancel Licence Transaction</p>
                </MenuItemWrapper>}
                


                    </Fragment>
                }

                
            </MenuWrapper>

            <DialogPopup
                dialogTitle={getModelTitle()}
                isFullScreen={true}
                isOpen={getState.showModel}
                onClosePopupFn={() => {
                    const dataset = _get(formState, `${dataTableKey}`, [])
                    for(let x = 0; x<dataset.length; x++) {
                        formAction.setTableInputFuncValues(`${x}_actual_serial_no`, undefined)
                    }
                    formAction.setTableInputFuncValues(dataTableKey, [])
                    formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
                    formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
                    setState({
                        ...getState,
                        modelType: null,
                        showModel: false,
                    })
                  }
                }
                isCustomButton={true}
                customButtonComponent={getBtn}
                customButtonComponentProps={{refreshMainScreenDataFn }}
                isConfirmationPopup={true}
            >
                {getModelUI()}
            </DialogPopup>
            
        </Fragment>
    );
};

export default RequestForBtn;