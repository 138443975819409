import React, { Fragment, useContext, useState, useEffect } from "react";

import {
  CheckBoxWithState,
  DatePickerWithState,
  FormWrapper,
  InputBoxWithState, InputButton,
  SelectBoxWithState,
  SubmitButton
} from "../../../../../ui-components/ui-elements/form";
import { LimitTextLength, UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { actionBtnTypes, DataTable } from "../../../../../ui-components/ui-elements/table/DataTable";
import { Reason } from "../../../../request-for-approval/pages/includes/Reason";
import DocumentsUploader from "../../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { uploadDetailSaveAPI, getDealerVehicleDetailssAPI, getDealerCurrentValidlicenceDetailsAPI, getDealerVehicleDetailsOrganisationServiceAPI, dealerLicenceReprintAPI, getDealerVehicleDetailsAPI, uploadDetailGetAPI } from "../../../../../../config/apiUrl.config";
import { Action } from "../../../../request-for-approval/pages/includes/Action";
import { DeleteVehicleNumberBtn, DeleteVehicleNumberUI } from "./DeleteVehicleNumber";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { _get, _set } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { RequestTypesList, RequestType, ActionTypeList, ProvincesByCodeMatcher } from "../../../../../../config/enum.config";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { CoreContext } from "../../../../core/context-providers/CoreContext.provider";

const LicenceReprintBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);

  const { formKey, approvalRequestId, requestType, dataSubmittedForApproval } = props;
  const dealer_id = _get(formState, "dealer_search_form.searchKey", null);
  const [authStatus] = useContext(AuthContext);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const approvalAction = _get(
    formState,
    `${formKey}.action`,
    null
  )
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const request_id = _get(formState, "licence_reprint.request_id", null);
  const selectedVehicles = _get(formState, getDealerVehicleDetailsAPI.key, []).map(vehicle => {
    return {
      vehicle_no: props.uiType === 'assign&Perform' ? vehicle.vehicle_no : vehicle.vehicle_number,
      licence_no: props.uiType === 'assign&Perform' ? vehicle.licence_no : vehicle.licence_id,
      licence_number: vehicle.licence_number,
      serial_no: props.uiType === 'assign&Perform' ? vehicle.serial_no : vehicle.serial_number,
      validity_from: props.uiType === 'assign&Perform' ? vehicle.valid_from : vehicle.validity_from,
      validity_to: props.uiType === 'assign&Perform' ? vehicle.valid_to : vehicle.validity_to
    }
  })

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        formGroupName={props.uiType === 'assign&Perform' ? `${formKey}` : formKey}
        tooltip={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${recId}.`,
            messageType: "success",
          },
          // failed: props.uiType === 'assign&Perform' ? {
          //     status: true,
          //     message: `Approval Request Decision Submit Failed. Please Try Again.`,
          //     messageType: "error",
          // } : {
          //     status: true,
          //     message: `Approval Request Submit Failed. Please Try Again.`,
          //     messageType: "error",
          //   },

        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'FRD' ? (_get(formState, "dealerVehicleTempAPIKey_create.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
            }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
          }) : {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",

          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'FRD' ? (_get(formState, "dealerVehicleTempAPIKey_create.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
          }) : {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required",
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'FRD' ? (_get(formState, "dealerVehicleTempAPIKey_create.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
            "forward_authority": "Authority is required",
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province": "Forward Province is required.",
            "forward_role": "Forward Role is required.",
            "action": "Action is required.",
          },
        }}
        onGetAPIEndPointFn={(formObject) => {

          return {
            url: `${dealerLicenceReprintAPI.url}/${props.uiType === 'assign&Perform' ? dataSubmittedForApproval.dealer_id : dealer_id}`,
            key: dealerLicenceReprintAPI.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          // console.log('ttrrrrr-->', formObject);
          if (_get(formState, getDealerVehicleDetailsAPI.key, []).length === 0 && formObject.action !== 'REJ') {
            uiAction.setFlashMessage({
              status: true,
              message: 'Please select at least one Authorised vehicle to reprint',
              messageType: "error",
            });
          } else {
            if (props.uiType === 'assign&Perform') {
              return getDataByFormObject({
                ...formObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["reprint_dlr_lcn"],
                // request_id: requestType ? approvalRequestId : formObject.request_id,
                current_licence_type: current_licence_type,
                // status: RequestStatus.APPROVAL_PENDING,
                request_type: RequestType.Licence_Receipt_Reprint,
                pdmt_id: pdmtId,
                // authority_id: authorityId,
                ui_request_id: _get(props, "approvalRequestId", null),
                counter_session_id: sessionId ? sessionId : null,
                forward_authority_service_id: serviceId,
                document_details: [],
                reason_id: formState[`${formKey}-new`].reason_id,
                action: formObject.action == 'FRD' ? {
                  action: formObject.action,
                  remark: formObject.remark,
                  forward_province: formObject.forward_province,
                  forward_role: formObject.forward_role,
                  forward_authority: formObject.forward_authority
                } : {
                  action: formObject.action,
                  remark: formObject.remark,
                },
                selected_vehicles: selectedVehicles
              });
            } else {
              return getDataByFormObject({
                ...formObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["reprint_dlr_lcn"],
                request_id: formObject.request_id,
                current_licence_type: current_licence_type,
                request_type: RequestType.Licence_Receipt_Reprint,
                pdmt_id: pdmtId,
                authority_id: authorityId,
                reason_id: formObject.reason_id,
                counter_session_id: sessionId ? sessionId : null,
                forward_authority_service_id: serviceId,
                document_details: [],
                action: {
                  action: formObject.action,
                  remark: formObject.remark,
                  forward_province: formObject.forward_province,
                  forward_role: formObject.forward_role,
                  forward_authority: formObject.forward_authority
                },
                selected_vehicles: selectedVehicles
              });
            }
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "assign&Perform" ? 'put' : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          } else {
            if (props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
              });
            }
            else {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
        }}
      />
    </Fragment>
  );
};


let recId = "";
const LicenceReprint = (props) => {
  const { uiType, formKey, requestType, approvalRequestId, dataSubmittedForApproval } = props;
  const [formState, formAction] = useContext(FormContext);
  // const [coreState, coreAction] = useContext(CoreContext);

  const datalist = _get(formState, getDealerVehicleDetailsAPI.key, [])
  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8))
    getReqApprovalData(props)
  }, [])
  recId = requestId;

  const getReqApprovalData = (props) => {
    callApi(props.uiType === "APR" ? `${dealerLicenceReprintAPI.url}/${approvalRequestId}?isApproval=true` : `${getDealerCurrentValidlicenceDetailsAPI.url}/${_get(formState, 'dealer_search_form.searchKey', "")}?is_with_licence_id=true&is_with_valid_licence=true&sort_by=vehicle_number|asc,validity_from|desc`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('result--', result);


          formAction.changeInputFn("reprint_data_set", "data", "", result.data.data.filter(dat => dat.is_reprint !== true));
        }
      })
  }

  return (
    <div className={"container-fluid p-0"}>
      <div className={"row"}>
        <FormWrapper
          setGroupName={formKey}
          setFormObject={props.uiType === "APR" ? { ui_request_id: props.approvalRequestId } : {
            request_id: _set(formState, `${formKey}.request_id`, requestId),
          }}>
          <div className={"col-md-12"}>
            <DataTable
              tableName="Vehicle Number Information"
              // apiUrl={props.uiType === "APR" ? `${dealerLicenceReprintAPI.url}/${approvalRequestId}?isApproval=true` : `${getDealerCurrentValidlicenceDetailsAPI.url}/${_get(formState, 'dealer_search_form.searchKey', "")}?is_with_licence_id=true&is_with_valid_licence=true&sort_by=vehicle_number|asc,validity_from|desc`}
              dataTableKey={getDealerVehicleDetailsAPI.key}
              isSetSearchFrom={false}
              isSelectRow={false}
              tableHeaderList={[
                {
                  displayName: "Authorized Vehicle No.",
                  key: `${props.uiType === "APR" ? 'vehicle_no' : 'vehicle_number'}`,
                  default: "-",
                  isSort: false
                },
                { displayName: "Lic. No.", key: `${props.uiType === "APR" ? 'licence_no' : 'licence_number'}`, isSort: false },
                { displayName: "Serial No.", key: `${props.uiType === "APR" ? 'serial_no' : 'serial_number'}`, isSort: false },
                {
                  displayName: "Validity Period (From - To)",
                  key: "validity_period",
                  onPrintFn: (rawData) => {
                    console.log('rr', rawData);
                    if (props.uiType === "APR") {
                      return (dateObjectToString(rawData.valid_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.valid_to, "dd/mm/YY")))
                    }
                    return (dateObjectToString(rawData.validity_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.validity_to, "dd/mm/YY")))
                  },
                  isSort: false
                },
              ]}
              tableBodyList={_get(formState, 'reprint_data_set.data', [])}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.add,
                  actionBtn: { tooltip: 'Add' },

                }
              ]}
              isSetAction={true}
              isSetTableHeader={false}

            />
          </div>
        </FormWrapper>
      </div>
      {console.log('huuuuu000', _get(formState, getDealerVehicleDetailsAPI.key, []))}
      {_get(formState, getDealerVehicleDetailsAPI.key, []).length !== 0 && <div className={"row defaultMarginTop"}>
        <div className={"col-md-12"}>
          <DataTable
            tableName={"Selected Vehicles For Reprint"}
            dataTableKey={getDealerVehicleDetailsAPI.key}
            isSetSearchFrom={false}
            isSelectRow={false}
            tableHeaderList={[
              {
                displayName: "Authorized Vehicle No.",
                key: `${props.uiType === "APR" ? 'vehicle_no' : 'vehicle_number'}`,
                default: "-",
                isSort: false
              },
              { displayName: "Lic. No.", key: `${props.uiType === "APR" ? 'licence_no' : 'licence_number'}`, isSort: false },
              { displayName: "Serial No.", key: `${props.uiType === "APR" ? 'serial_no' : 'serial_number'}`, isSort: false },
              {
                displayName: "Validity Period (From - To)",
                key: "validity_period",
                onPrintFn: (rawData) => {
                  if (props.uiType === "APR") {
                    return (dateObjectToString(rawData.valid_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.valid_to, "dd/mm/YY")))
                  }
                  return (dateObjectToString(rawData.validity_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.validity_to, "dd/mm/YY")))
                },
                isSort: false
              },
            ]}
            tableBodyList={_get(formState, getDealerVehicleDetailsAPI.key, [])}
            actionTypeList={[
              {
                actionType: actionBtnTypes.delete,
                actionBtn: { tooltip: 'Remove' },
              }
            ]}
            isSetAction={true}
            isSetTableHeader={true}
            isSetTableFooter={true}
          />
        </div>

      </div>}

      <div className={"row"}>
        <div className="col-md-12 col-sm-12 defaultMarginTop">
          <Reason filterKey={'dealers_licence_reprint'} formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
            requestType={uiType}
            dataSubmittedForApproval={props.dataSubmittedForApproval} />
        </div>

        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <DocumentsUploader
            requestType={requestType}
            isUploadFn={true}
            formGroupName={`${formKey}-upload`}
            tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
            requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
            uiType={uiType}
          />
        </div>
        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <Action
            formKey={formKey} uiType={uiType} dataSubmittedForApproval={props.dataSubmittedForApproval}
          />
        </div>
      </div>
    </div>
  );
};

export {
  LicenceReprint,
  LicenceReprintBtn
};