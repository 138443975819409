/*
 * @Author:Gajani Kangesan
 * @Date: 2022-02-22 17:21:07
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:40:05
 */

import React, { useContext, useState, useEffect } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import {
      SelectBoxWithState,
      SubmitButton,
      DatePickerWithState,
      FormWrapper,
      InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { reportGenerateAPI, availableCounterListApi, internalUserListAPI, provincesOfficesListAPI, AuthorityListApi } from "../../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { CoreContext, apiResponseStoringType } from "../../../../../core/context-providers/CoreContext.provider";
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined
const SearchCriteria = ({
      reportCode,
      formGroupName,
      setReportgeneratedValues = emptyFn,
      onGenerateSubmit = emptyFn,
      onResetClick = emptyFn,
}) => {

      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const [coreState, coreAction] = useContext(CoreContext);
      let datefrom = new Date();
      let dateto = new Date();
      let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
      let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
      let authorityId = _get(authStatus, 'authUser.authorityId', "");
      let generated_by = _get(authStatus, 'authUser.name', "")
      let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
      let role = _get(authStatus, 'authUser.rolePrefix', "")
      let provinceCode = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtCode', "") : _get(authStatus, 'authUser.pdmtId.code', "");
      let authorityName = 'All';
      let counterName = 'All';
      let counterId = "All";
      let userId = "All";
      let userName = "All";
      let documentId = "All";
      let documentName = "All";
      const [getdatalist, setDatalist] = useState(" ");
      useEffect(() => {

            coreAction
                  .sendRequestFn(
                        `${AuthorityListApi.url}?province_code=${provinceCode}&sort_by=name|asc`
                  )
                  .method("get")
                  .setInitStoring(apiResponseStoringType.setResponse, {
                        responseKey: AuthorityListApi.key,
                  })
                  .headers(true)
                  .send((error, result) => {
                        if (!error) {
                              if (roleLevelCode === "INT_AU") {
                                    setDatalist(result.data.data.filter((item) => item.id === authorityId));

                              }
                              else {
                                    setDatalist(result.data.data.filter((item) => item.province.id === provinceId))
                              }



                        }
                  });

      }, []);

  
       const dataList = [
            {
                  id: "INS",
                  value: "Insurance",
            },
            {
                  id: "VET",
                  value: "Vehicle Emission Test",
            },
            {
                  id: "VRP",
                  value: "Vehicle Route Permit",
            },

            {
                  id: "VFT",
                  value: "Vehicle Fitness Test",
            },


      ]

      const serviceID = _get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === _get(formState[formGroupName], "authority", null))?.service.id
      const queryObj = () => {
            if (serviceID == undefined) {
                  return `provice_id=${provinceId}`;
            } else {
                  return `service_id=${_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === _get(formState[formGroupName], "authority", null))?.service.id}`;
            }
      }
      return (
            <UICard>
                  <FormWrapper
                        setGroupName={formGroupName}
                        formGroupLinkWith={formGroupName}
                        onDestroyUnsetFormObject={true}
                        setFormObject={{
                              date_from: null,
                              date_to: null,
                        }}

                  >
                        <div className={"row"} >
                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          apiUrl={provincesOfficesListAPI.url}
                                          keyName={"id"}
                                          valueName={"province.name"}
                                          apiStateKey={provincesOfficesListAPI.key}
                                          emptySelectOptionTxt={provinceName}
                                          labelText="Province"
                                          inputName={"province"}
                                          isDisabled={"true"}

                                    />
                              </div>


                              <div className="col-md-6 col-sm-12 floatLeft">
                                    <DatePickerWithState
                                          isRequired={true}
                                          formGroupName={formGroupName}
                                          uiType={templateTypes.bootstrap}
                                          labelText="Date From"
                                          inputName={"date_from"}
                                          maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                                          minDate={setminDateRangeFn(_get(formState[formGroupName], "date_to", null),_get(formState[formGroupName], "date_from", null))}

                                    />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          isRequired={true}
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          emptySelectOptionTxt={" "}
                                          dataList={getdatalist}
                                          keyName={"id"}
                                          valueName={"service.name"}
                                          labelText="Authority"
                                          inputName={"authority"}
                                          onChangeFn={(value) => {
                                                formAction.changeInputFn(formGroupName, "counter", null);
                                                formAction.changeInputFn(formGroupName, "user", null);
                                          }}
                                    />

                              </div>
                              <div className="col-md-6 col-sm-12 floatLeft">
                                    <DatePickerWithState
                                          isRequired={true}
                                          formGroupName={formGroupName}
                                          uiType={templateTypes.bootstrap}
                                          labelText="Date To"
                                          inputName={"date_to"}
                                          minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                                          maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "date_from", null))}

                                    />
                              </div>


                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          emptySelectOptionTxt={"All"}
                                          isEnableAllOption={true}
                                          apiUrl={`${availableCounterListApi.url}/${_get(formState[formGroupName], "authority", authorityId)}/counters?sort_by=name|asc`}
                                          apiStateKey={availableCounterListApi.key}
                                          keyName={"id"}
                                          valueName={"name"}
                                          labelText="Counter"
                                          inputName={"counter"}

                                    />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          isRequired={false}
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          emptySelectOptionTxt={"All"}
                                          isEnableAllOption={_get(formState[formGroupName], "authority", null) !== null ? true : false}
                                          apiUrl={_get(formState[formGroupName], "authority", null) !== null ? `${internalUserListAPI.url}?${queryObj()}&sort_by=full_name|asc&userType=all` : ""}
                                          apiStateKey={internalUserListAPI.key}
                                          keyName={"user.id"}
                                          valueName={"full_name"}
                                          labelText="User"
                                          inputName={"user"}

                                    />
                              </div>

                              <div className="col-md-6 col-sm-12">
                                    <SelectBoxWithState
                                          isRequired={false}
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          emptySelectOptionTxt={"All"}
                                          isEnableAllOption={true}
                                          dataList={dataList}
                                          labelText="Document Type"
                                          inputName={"document"}

                                    />
                              </div>



                              <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">

                                    <SubmitButton
                                          startIcon="fas fa-wrench"
                                          btnText={"Generate"}
                                          elementStyle="btnWrapper"
                                          formGroupName={formGroupName}
                                          isValidate={true}
                                          elementWrapperStyle={"searchBtn"}
                                          validationObject={{
                                                fileds: {
                                                      date_from: "Date From",
                                                      date_to: "Date To",
                                                      authority: "Authority",
                                                },
                                                rules: {
                                                      date_from: "required",
                                                      date_to: "required",
                                                      authority: "required",

                                                },
                                                message: {}
                                          }}


                                          callApiObject={{
                                                isSetHeaders: true,
                                                method: "get",
                                                multipart: false,
                                                onUpload: false,
                                                responseType: 'text'
                                          }}
                                          apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: "API_RESPONSE_LINKED_FORM",
                                                mergeToSuccessResponse: true,
                                                mergeToErrorResponse: true
                                          }}
                                          onGetAPIEndPointFn={(formObject) => {

                                                if (!isEmptyValue(formObject.date_from)) {
                                                      datefrom = dateObjectToString(new Date(formObject.date_from));
                                                }
                                                if (!isEmptyValue(formObject.date_to)) {
                                                      dateto = dateObjectToString(new Date(formObject.date_to));
                                                }
                                                if (!isEmptyValue(formObject.province)) {
                                                      provinceId = formObject.province
                                                      provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                                }
                                                if (!isEmptyValue(formObject.authority)) {
                                                      authorityId = formObject.authority
                                                      authorityName = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.name)
                                                      authorityName = (authorityName === undefined) ? "All" : authorityName
                                                }
                                                // if (formObject.counter === undefined) {
                                                //       counterName = ""
                                                //       counterId=""


                                                // } else if (isEmptyValue(formObject.counter)) {
                                                //       counterName = "All"
                                                //       counterId="All"

                                                // }
                                                if (!isEmptyValue(formObject.counter)) {
                                                      counterId = formObject.counter
                                                      counterName = (_get(coreState, `apiResponses.${availableCounterListApi.key}.result`, []).find(item => item.id === counterId)?.name)
                                                      counterName = counterName === undefined ? "All" : counterName

                                                }

                                                if (!isEmptyValue(formObject.user)) {
                                                      userId = formObject.user
                                                      userName = (_get(coreState, `apiResponses.${internalUserListAPI.key}.result`, []).find(item => item.user.id === userId)?.full_name)
                                                      userName = (userName === undefined) ? "All" : userName

                                                }


                                                if (!isEmptyValue(formObject.document)) {
                                                      documentId = formObject.document
                                                      documentName = dataList.find((item) => (item.id) === documentId)?.value
                                                      documentName = (documentName === undefined) ? "All" : documentName


                                                }

                                                return {
                                                      url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_provinceId=${provinceId}&report_requestProvince=${provinceName}&report_requestFromDate=${datefrom}&report_requestToDate=${dateto}&report_authorityId=${authorityId}&report_requestAuthority=${authorityName}&report_counterId=${counterId}&report_requestCounter=${counterName}&report_userId=${userId}&report_requestUser=${userName}&report_documentTypeId=${documentId}&report_requestDocumentType=${documentName}&report_dateFormatDisplay=dd/MM/yyyy&report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${generated_by + "-" + _get(authStatus, 'authUser.provinceCode', "") + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                                      key: `${reportGenerateAPI.key}`
                                                }
                                          }}

                                          onChangeRequestBodyFn={(formObject) => {
                                                return getDataByFormObject(formObject);
                                          }}

                                          onResponseCallBackFn={(error, response,) => {
                                                if (response?._statue) {
                                                      onGenerateSubmit(response?.data)
                                                      setReportgeneratedValues({
                                                            datefrom,
                                                            dateto,
                                                            counterId,
                                                            counterName,
                                                            provinceId,
                                                            provinceName,
                                                            documentId,
                                                            documentName,
                                                            userId,
                                                            userName,
                                                            authorityName,
                                                            authorityId, authorityName,
                                                            limit: reportDataLimit,
                                                            offset: reportDataOffset,
                                                            reportGeneratedUser: generated_by + "-" + _get(authStatus, 'authUser.provinceCode', "") + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                                      })
                                                }


                                          }}

                                    />
                                    <InputButtonWithState
                                          btnText="Reset"
                                          startIcon="fas fa-sync-alt"
                                          elementWrapperStyle={"refreshBtn"}
                                          formGroupName={formGroupName}
                                          mergeToForm={{
                                                date_from: null,
                                                date_to: null,
                                                authority: null,
                                                province: null,
                                                counter: null,
                                                document: null,
                                                user: null

                                          }}
                                          onClickBtnFn={(event) => {
                                                formAction.setFormErrorFn(formGroupName);
                                                onResetClick()

                                          }}
                                    />


                              </div>
                        </div>
                  </FormWrapper>
            </UICard>


      );
};

export {
      SearchCriteria
}