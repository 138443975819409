import React, { Fragment, useEffect, useState, useContext } from "react";

import {
	UICard,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import {
	FormWrapper,
	InputButton,
} from "../../../../ui-components/ui-elements/form";

import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { reqstUserUnassignAPI, pdmtListAPI, getVehicleInfoAPI, getVehicleMetaDataAPI } from "../../../../../config/apiUrl.config";
import {
	apiResponseStoringType,
	CoreContext,
} from "../../../core/context-providers/CoreContext.provider";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { LicenceReprint } from '../../../counter-session/pages/dealers-licence/includes/LicenceReprint'
import { ChangeSerialNumber } from '../../../counter-session/pages/dealers-licence/includes/ChangeSerialNumber'
import { DealersLicenceReissuance } from '../../../counter-session/pages/dealers-licence/includes/DealersLicenceReissuance'
import { LicenceTypeChangeUI } from '../../../request-for-approval/pages/LicenceTypeChange'
import { RequestTypeList } from '../../../../../config/enum.config';
import { getCodeName } from '../../../../../helpers/common-helpers/common.helpers';
import { VehicleStatusChangeUI } from '../../../request-for-approval/pages/VehicleStatusChange'
import { AddVehicleUI } from '../../../request-for-approval/pages/AddVehicle'
import { UpdateVehicleUI } from '../../../request-for-approval/pages/UpdateVehicle'
import { TransferVehicleUI } from '../../../request-for-approval/pages/TransferVehicle'
import { ReverseLastTransactionUI } from '../../../request-for-approval/pages/ReverseLastTransaction'
import { RequestLicenceInvalidDocUI } from '../../../request-for-approval/pages/RequestLicenceInvalidDoc'
import { LicenceReprintUI } from '../../../request-for-approval/pages/LicenceReprint'
import { SetEffectiveDateUI } from '../../../request-for-approval/pages/SetEffectiveDate'
import { LiceneceWaveOffUI } from '../../../request-for-approval/pages/LicenceWaveOff'
import { RequestLicenceArrearsUI } from '../../../request-for-approval/pages/RequestLicenceArrears'
import { OtherAdditionsDeductionUI } from '../../../request-for-approval/pages/OtherAdditionsDeductions'
import { ChangeSerialNumberUI } from '../../../request-for-approval/pages/ChangeSerialNumber'
import { AddDealer } from "../../../request-for-approval/pages/AddDealer";
import { UpdateDealer } from "../../../request-for-approval/pages/UpdateDealer";
import { AddLicenceUI } from '../../../request-for-approval/pages/includes/AddLicenceUI'
import { CancelLicenceUI } from '../../../request-for-approval/pages/CancelLicence'
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import ViewVehicleInfo from "./ViewVehicleInfo";
import { ActivityLog } from "./ActivityLog";
import ViewDealerInfo from "./ViewDealerInfo";
import { AddLicenceTransaction } from '../../../counter-session/pages/dealers-licence/includes/AddLicenceTransaction'
import { CancelLicence } from '../../../counter-session/pages/dealers-licence/includes/CancelLicence'

const PendingApprovalRequest = (props) => {
	const [coreState, coreAction] = useContext(CoreContext);
	const [authStatus] = useContext(AuthContext);
	const [formState, formAction] = useContext(FormContext);
	const emptyFn = (...para) => undefined;
	useEffect(() => {
		if (
			_get(props, "fromParent.uiType", "") === "assign&Perform" &&
			_get(props, "row.assigned_user_id", "") === null
		) {
			const formData = {
				assigned_user_id: _get(authStatus, "authUser.id", null),
			};
			coreAction
				.sendRequestFn(
					`${reqstUserUnassignAPI.url}/${_get(props, "row.request_id", null)}`
				)
				.isMultipart(false)
				.method("put")
				.body(formData)
				.setLoader(false)
				.setInitStoring(apiResponseStoringType.responseLinkToFrom, {
					responseKey: reqstUserUnassignAPI.key,
				})
				.send();
		}
		formAction.initFromFn("vehicle", "_onLoad", "", true);
		formAction.initFromFn("province", "_onLoad", "", true);
		vehicleInfo()

	}, []);

	const [getState, setState] = useState({
		ShowPop: false,
		status: false,
		"ShowActivityLog": false,
	});

	const getVehileMetaDataFn = (vehicle_No, current_license_type) => {
		callApi(`${getVehicleMetaDataAPI.url}?vehicle_no=${vehicle_No}&requested_licence_type=${current_license_type}`)
			.method("get")
			.headers(true)
			.isMultipart(false)
			.send((error, result) => {
				if (!error) {
					formAction.changeInputFn('vehicle-meta-data', "data", "", result.data.data);

				}
			});
	}
	const vehicleInfo = () => {
		callApi(
			`${getVehicleInfoAPI.url}?vehicle_no=${_get(props, "row.vehicle_no", null)}`
		)
			.method("get")
			.headers(true)
			.send((error, result) => {
				if (!error) {
					formAction.changeInputFn("vehicle", "data", "", result.data);
					formAction.changeInputFn('vehicle', "_onLoad", "", false);
					getProvinceData(result.data.data.province_id)
					getVehileMetaDataFn(_get(props, "row.vehicle_no", null), result.data.data.current_license_type.code)
				}

			});
	}
	const getProvinceData = (province_id) => {
		callApi(`${pdmtListAPI.url}/${province_id}`)
			.method("get")
			.headers(true)
			.isMultipart(false)
			.send((error, result) => {
				if (!error) {

					formAction.changeInputFn('province', "provinceData", "", result.data.data);
					formAction.changeInputFn('province', "_onLoad", "", false);

				}

			});

	}


	const getModelUI = () => {
		// ) : props.row.request_type === "add_lcn_r_txn" ? (
		// 	<AddLicenceRecieptInfoDisplay {...props} />
		// ) : props.row.request_type === "add_lcn_txn" ? (
		// 	<AddLicenceTransactionDisplay {...props} />

		// console.log('PROPS======>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv', props);

		switch (props.row.request_type) {
			case "other_ad_ded":
				return (
					<OtherAdditionsDeductionUI formKey={'Other_Addition_Deduction'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "lcn_w_arrears":
				return (
					<RequestLicenceArrearsUI formKey={'Request_Licence_Arrears'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "chng_se_num":
				return (
					<ChangeSerialNumberUI formKey={'Change_Serial_Number'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "waiveoff_lcn":
				return (
					<LiceneceWaveOffUI formKey={'Licence_waive_Off'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "set_eff_date":
				return (
					<SetEffectiveDateUI formKey={'Set_Effective_Date'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "reprint_lcn":
				return (
					<LicenceReprintUI formKey={'Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "lcn_w_invalid_doc":
				return (
					<RequestLicenceInvalidDocUI formKey={'Request_Licence_Invalid_Doc'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "reverse_last_txn":
				return (
					<ReverseLastTransactionUI formKey={'Reverse_Last_Transaction'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "vcl_transfer":
				return (
					<TransferVehicleUI formKey={'transfer_vehicle'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "update_vcl":
				return (
					<UpdateVehicleUI formKey={'Update_Vehicle'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "add_vcl":
				return (
					<AddVehicleUI formKey={'Add_Vehicle'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "chng_vcl_status":
				return (
					<VehicleStatusChangeUI formKey={'vehicle-status-change'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "chng_lcn_type":
				return (
					<LicenceTypeChangeUI formKey={'licence_type_change'} dataSubmittedForApproval={props.row} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "reprint_dlr_lcn":
				return (
					<LicenceReprint formKey={'Dealers_Licence_Reprint'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "chng_dlr_se_num":
				return (
					<ChangeSerialNumber formKey={'Dealer_Change_Serial_Number'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "reissue_dlr_lcn":
				return (
					<DealersLicenceReissuance formKey={'Dealers_Licence_Reissuance'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "add_dlr":
				return (
					<AddDealer formKey={'dealerVehicleTempAPIKey_create'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "update_dlr":
				return (
					<UpdateDealer formKey={'dealerVehicleTempAPIKey_create'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "add_lcn_txn":
				return (
					<AddLicenceTransaction formKey={'add_licence_transaction'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "cncl_dlr_lcn_txn":
				return (
					<CancelLicence formKey={'cancel_licence_transaction'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "add_lcn_r_txn":
				return (
					<AddLicenceUI formKey={'add-licence'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			case "cncl_lcn_r_txn":
				return (
					<CancelLicenceUI formKey={'cancel-licence'} dataSubmittedForApproval={props.row} requestType={props.row.request_type} approvalRequestId={props.row.request_id} uiType="APR" />
				);
			default:
				return null;
		}
	};

	const getVehicleNumber = () => {

		switch (props.row.request_type) {
			case "cncl_dlr_lcn_txn":
				return _get(props, "row.dealer_id", "-");
			case "add_lcn_txn":
				if (_get(props, "row.vehicle_no", null) === null) {
					return _get(props, "row.dealer_id", "-")
				}
				else {
					return _get(props, "row.vehicle_no", "-")
				}
			case "add_dlr":
				return _get(props, "row.dealer_id", "-")
			case "update_dlr":
				return _get(props, "row.dealer_id", "-")
			case "reissue_dlr_lcn":
				return _get(props, "row.dealer_id", "-")
			case "chng_dlr_se_num":
				return _get(props, "row.dealer_id", "-")
			case "reprint_dlr_lcn":
				return _get(props, "row.dealer_id", "-")
			default:
				return _get(props, "row.vehicle_no", "-")
		}
	};

	const getModelDealerDetailCardChange = () => {

		switch (props.row.request_type) {
			case "cncl_dlr_lcn_txn":
				return (

					<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
						<p>
							<b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
						</p>
						<p>{_get(props, "row.dealer_id", "-")} </p>
					</div>

				);
			case "add_lcn_txn":
				if (_get(props, "row.vehicle_no", null) === null) {
					return (

						<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
							<p>
								<b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
							</p>
							<p>{_get(props, "row.dealer_id", "-")} </p>
						</div>

					);
				}
				else {
					return (

						<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
							<p>
								<b>Vehicle ID &nbsp;&nbsp; :&nbsp;</b>
							</p>
							<p>{_get(props, "row.vehicle_no", "-")} </p>
						</div>

					);
				}

			case "add_dlr":
				return (

					<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
						<p>
							<b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
						</p>
						<p>{_get(props, "row.dealer_id", "-")} </p>
					</div>


				);
			case "update_dlr":
				return (

					<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
						<p>
							<b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
						</p>
						<p>{_get(props, "row.dealer_id", "-")} </p>
					</div>


				);
			case "reissue_dlr_lcn":
				return (

					<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
						<p>
							<b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
						</p>
						<p>{_get(props, "row.dealer_id", "-")} </p>
					</div>


				);
			case "chng_dlr_se_num":
				return (

					<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
						<p>
							<b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
						</p>
						<p>{_get(props, "row.dealer_id", "-")} </p>
					</div>


				);
			case "reprint_dlr_lcn":
				return (

					<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
						<p>
							<b>Dealer ID &nbsp;&nbsp; :&nbsp;</b>
						</p><p>{_get(props, "row.dealer_id", "-")} </p>

					</div>


				);

			default:
				return (

					<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
						<p>
							<b>Vehicle ID &nbsp;&nbsp; :&nbsp;</b>
						</p>
						<p>{_get(props, "row.vehicle_no", "-")} </p>
					</div>


				);
		}
	};
	const CustomButtonsActivityLog = ({
		onClosePopupFn = emptyFn,
		formGroupName = ""
	}) => {

		const close = () => {
			onClosePopupFn();
		}

		return (
			<Fragment>
				<InputButton
					elementWrapperStyle={"floatRight"}
					btnName="view"
					btnText={"Activity Log"}
					onClickBtnFn={() => setState({
						...getState,
						ShowActivityLog: true,
					})}
				/>

			</Fragment>
		)
	}



	const getViewVehicleBtn = (props) => {

		if (props.row.request_type === "cncl_dlr_lcn_txn" || props.row.request_type === "add_lcn_txn" || props.row.request_type === "reissue_dlr_lcn" || props.row.request_type === "chng_dlr_se_num" || props.row.request_type === "reprint_dlr_lcn" || props.row.request_type === "update_dlr") {
			return (<div className="fullWidthDiv">
				<InputButton
					elementWrapperStyle={"floatRight"}
					btnName="view"
					btnText={"View Dealer Info"}
					onClickBtnFn={() =>
						setState({
							...getState,
							ShowPop: true,
							status: true,
						})
					}
				/>

				<div className="fullWidthDiv">

					<DialogPopup
						isOpen={getState.ShowPop}
						isFullScreen={true}
						isCustomButton={false}
						dialogTitle={"View Dealer Information"}
						onClosePopupFn={() => setState({
							...getState,
							ShowPop: false,
						})}
					>
						<ViewDealerInfo {...props} />
					</DialogPopup>


				</div>
			</div>)
		}
		else {
			if (props.row.request_type !== "add_vcl" && props.row.request_type !== "add_dlr") {
				return (
					<div className="fullWidthDiv">
						<InputButton
							elementWrapperStyle={"floatRight"}
							btnName="view"
							btnText={"View Vehicle Info"}
							onClickBtnFn={() =>
								setState({
									...getState,
									ShowPop: true,
									status: true,
								})
							}
						/>

						<div className="fullWidthDiv">

							<DialogPopup
								isOpen={getState.ShowPop}
								isFullScreen={true}
								isCustomButton={true}
								customButtonComponent={CustomButtonsActivityLog}
								dialogTitle={"View Vehicle Information"}
								onClosePopupFn={() => setState({
									...getState,
									ShowPop: false,
								})}
							>
								<ViewVehicleInfo  {...props} />
							</DialogPopup>
							<DialogPopup
								isOpen={getState.ShowActivityLog}
								dialogTitle={"Activity Log"}
								isFullScreen={true}
								onClosePopupFn={() => setState({
									...getState,
									ShowActivityLog: false,
								})}
							>
								<ActivityLog vehicleId={_get(formState, "vehicle.data.data.id", null)} />
							</DialogPopup>

						</div>
					</div>)
			}
		}


	};

	return (
		<FormWrapper
			setGroupName={props.fromParent.formKey}
			formGroupLinkWith={props.fromParent.formKey}
			setFormObject={{
				request_id: `${props.row.request_id}`,
			}}
			onDestroyUnsetFormObject={true}
		>
			{/* {console.log('SLOLOLOLOLOLOLOLOLOLOLL', )} */}
			<div className="row">
				<div className="col-md-12 col-sm-12 ">
					<UICard cardHeading={`${getCodeName(RequestTypeList, props.row.request_type)} Request (${getVehicleNumber()})`}>
						<div className="row">
							<div className="col-md-2 col-sm-4 floatLeft d-flex flex-row">
								<p>
									<b>Request ID &nbsp;:&nbsp;</b>
								</p>
								<p>{_get(props, "row.id", "-")}</p>
							</div>


							<div className="col-md-3 col-sm-4 floatLeft d-flex flex-row">
								<p>
									<b>Request Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;</b>
								</p>
								<p> {getCodeName(RequestTypeList, props.row.request_type)} </p>
							</div>


							<div className="col-md-3 col-sm-4 floatLeft d-flex flex-row">
								<p>
									{" "}
									<b>Requested Date & Time &nbsp;:&nbsp;</b>
								</p>
								<p>

									{dateObjectToString(
										_get(props, "row.created_at", null),
										"dd/mm/YY HH:MM"
									)}
								</p>
							</div>


							<div className="col-md-4 col-sm-4 floatLeft d-flex flex-row">
								<p>
									<b>Requested by&nbsp;:&nbsp;</b>
								</p>
								<p> {_get(props, "row.created_by_name", "-")}({_get(props, "row.created_role_name", "-")}) </p>
							</div>
						</div>
						<div className="row">
							{getModelDealerDetailCardChange()}
							<div className="col-md-3 col-sm-4 floatLeft d-flex flex-row">
								<p>
									<b>Request Status&nbsp; :&nbsp;</b>
								</p>
								<p> {_get(props, "row.status", "-") === "PEN" ? "Approval Pending" : (_get(props, "row.status", "-") === "APP" ? "Approved" : (_get(props, "row.status", "-") === "REJ" && _get(props, "row.action", "-") === "FRD" ? "Withdrawn" : "Rejected"))} </p>
							</div>
							<div className="col-md-5 col-sm-4 floatLeft d-flex flex-row">
								<p>
									<b>Requested Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
								</p>
								<p>{_get(props, "row.created_location_province_code", "-")}({_get(props, "row.created_location_name", "-")})</p>
							</div>


						</div>


						{getViewVehicleBtn(props)}

					</UICard>
				</div>
				<div className="col-md-12 col-sm-12 defaultMarginTopBottom">
					{getModelUI()}
				</div>
			</div>
		</FormWrapper>
	);
};

export { PendingApprovalRequest };
