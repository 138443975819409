import React, { Fragment, useContext, useState, useEffect } from "react";

import {
    CheckBoxWithState,
    DatePickerWithState,
    FormWrapper,
    InputBoxWithState, InputButton,
    SelectBoxWithState,
    SubmitButton
} from "../../../../ui-components/ui-elements/form";
// import { LimitTextLength, UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { actionBtnTypes, DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
// import { Reason } from "../../../request-for-approval/pages/includes/Reason";
// import DocumentsUploader from "../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { uploadDetailSaveAPI, getDealerVehicleDetailsOrganisationServiceAPI, dealerLicenceReprintAPI, getDealerVehicleDetailsAPI } from "../../../../../config/apiUrl.config";
// import { Action } from "../../../request-for-approval/pages/includes/Action";
// import {DeleteVehicleNumberBtn, DeleteVehicleNumberUI} from "./DeleteVehicleNumber";
// import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { _get, _set } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
// import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";
// import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
// import { RequestStatus, RequestType } from "../../../../../../config/enum.config";


const DealerLicenceReprintInformation = (props) => {
    return (
        <div className={"row"}>


            <DataTable
                tableName={"Dealer Licence Reprint Vehicle Information"}
                apiUrl={`${dealerLicenceReprintAPI.url}/${props.requestId}?isApproval=true`}
                dataTableKey={dealerLicenceReprintAPI.key}
                isSetTableHeader={true}
                isSetSearchFrom={false}
                isSelectRow={false}
                tableHeaderList={[
                    {
                        displayName: "Authorized Vehicle No",
                        key: "vehicle_no",
                        default: "-",
                        isSort: false
                    },
                    { displayName: "Lic. No.", key: "licence_no", isSort: false },
                    { displayName: "Serial No.", key: "serial_no", isSort: false },
                    {
                        displayName: "Validity Period (From - To)",
                        key: "validity_period",
                        onPrintFn: (rawData) => {
                            return (dateObjectToString(rawData.valid_from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.valid_to, "dd/mm/YY")))
                        },
                        isSort: false
                    },
                ]}
                // tableBodyList={[
                //     {vehicle_number: "WP12345", licence_id: "12345", serial_number: "acs111", validity_period: '2021-12-22'}
                // ]}

                isSetAction={false}

            />
        </div>

    )
}

export default DealerLicenceReprintInformation
