/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-12 14:00:27 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-14 23:36:43
 */
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { createLicenceArrearsOnlyAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  InputBoxWithState,
  InputButton,
} from "../../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { ConfirmationPopup } from "./ConfirmationPopup";
import { PrintPreview } from "./PrintPreview";

const emptyFun = (...para) => undefined;

const PrintReceiptConfirmation = ({ onClickSuccessFn = emptyFun, closePopupFn = emptyFun }) => {
  const [open, setOpen] = useState(false);
  const [printPreviewPopup, setPrintPreviewPopup] = useState(false);
  const formKey = "printReceipt";
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const transactionId = _get(coreState, `apiResponses.${createLicenceArrearsOnlyAPI.key}.result.transaction_data.id`, "");
  const transactionNo = _get(coreState, `apiResponses.${createLicenceArrearsOnlyAPI.key}.result.transaction_data.txn_number`, "");
  
  const isBtnDisabled = () =>{
    let isDissable = true;
    
    if(!isEmpty(_get(formState, `${formKey}.manual_receipt_no`, null))){
      isDissable = false;
    }
    return isDissable;
  }
  
  return (
    <FormWrapper
      setGroupName={formKey}
      setFormObject={{
        transaction_id: transactionId,
        txn_no: transactionNo,
        manual_receipt_no: '',
       
      }}
    >
    <div>
      <div className="row">
        <div className="col-md-12">
          <InputBoxWithState
            labelText={"Manual Receipt Number"}
            formGroupName={formKey}
            inputName={"manual_receipt_no"}
            maxLength={12}
            // onChangeTxtFn={(event) => {
            //     const checkValue = event.value.toString();
            //     if(checkValue ==0){
            //         formAction.changeInputFn(formKey, "contact_number", "", '');
            //     }
            // }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-right defaultMarginTop">
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnText="Print Receipt"
            onClickBtnFn={() => setPrintPreviewPopup(true)}
            isBtnDisabled={!isBtnDisabled()}
          />
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnText="Do Not Print"
            onClickBtnFn={() => setOpen(true)}
            isBtnDisabled={isBtnDisabled()}
          />
        </div>
      </div>

        <DialogPopup
          dialogTitle={"Print Receipt"}
          isDialogActions={false}
          isFullWidth={true}
          isFullScreen={false}
          isCloseButton={false}
          children={
            <PrintPreview
              onClickSuccessFn={() => {
                setPrintPreviewPopup(false);
                onClickSuccessFn();
              }}
              closePopupFn={() => setPrintPreviewPopup(false)}
            />
          }
          isOpen={printPreviewPopup}
          onClosePopupFn={() => setPrintPreviewPopup(false)}
        />

        <DialogPopup
          isDialogActions={false}
          dialogTitle={"Please Confirm"}
          isFullWidth={false}
          isFullScreen={false}
          isCloseButton={true}
          children={
            <ConfirmationPopup
              onClickSuccessFn={() => {
                setOpen(false);
                onClickSuccessFn();
              }}
              closePopupFn={() => setOpen(false)}
              uiType={'arrears'}
              receipt={'no_print'}
              confirmationMsg={"Are you sure you want to go to back?"}
            />
          }
          isOpen={open}
          onClosePopupFn={() => setOpen(false)}
        />
    </div>
    </FormWrapper>
  );
};
export { PrintReceiptConfirmation };
