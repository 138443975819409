/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-04-02 19:23:25 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 10:40:16
 */

const actionBtnTypes={
    "create":"CREATE",
    "edit":"EDIT",
    "view":"VIEW",
    "delete":"DELETE",
    "other":"OTHER",
    "add":"ADD",
    "reprint":"REPRINT",
    "viewMore":"VIEW MORE"
}

const emptyFun = (...para) => undefined;


const commonActionBtnPops={
    [actionBtnTypes.create]:{
        elementStyle:"createBtn",
        icon:"fas fa-plus-circle",
        btnText:"Create",
        tooltip:"Create New",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },[actionBtnTypes.edit]:{
        elementStyle:"editBtn",
        icon:"fas fa-edit",
        btnText:"Edit",
        tooltip:"Edit",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },[actionBtnTypes.view]:{
        elementStyle:"viewBtn",
        icon:"fas fa-binoculars",
        btnText:"View",
        tooltip:"View",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },
    [actionBtnTypes.viewMore]:{
        elementStyle:"viewBtn",
        icon:"fas fa-binoculars",
        btnText:"View More",
        tooltip:"View More",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },
    [actionBtnTypes.delete]:{
        elementStyle:"deleteBtn",
        icon:"fas fa-trash-alt",
        btnText:"Delete",
        tooltip:"Delete",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    }
    ,[actionBtnTypes.other]:{
        elementStyle:"otherBtn",   
        icon:"far fa-compass",  
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    }
    ,[actionBtnTypes.add]:{
        elementStyle:"addBtn",   
        icon:"fas fa-plus",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    },
    [actionBtnTypes.reprint]:{
        elementStyle:"viewBtn",
        icon:"fas fa-print",
        btnText:"Reprint",
        tooltip:"Reprint",
        isBtnDisabled:false,
        onClickBtnFn:()=>emptyFun()
    }
 }


 export {
    actionBtnTypes,
    commonActionBtnPops
 }