import React, { Fragment, useContext } from "react";
import { additionDeductionDeleteAPI, additionDeductionListAPI } from "../../../../../config/apiUrl.config";
import { OtherAdditionDeductionTempAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  InputBoxWithState,
  SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { apiResponseStoringType } from "../../../core/context-providers/CoreContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const CustomButtonDelete = (props) => {
  const uiType = props.uiType;
  const [formState, formAction] = useContext(FormContext);

  // console.log('QQQQQQQ222222====>', props);
  let selectedIds = []
  let selectedIdss = []
  return (
    <Fragment>
      <SubmitButton
        startIcon={"fas fa-check"}
        btnText={"Yes"}
        formGroupName={props.formGroupName}
        dataTableKey={props.dataTableKey}
        isValidate={false}
        flashMessages={{
          success: {
            status: true,
            message: "Addition / Deduction is successfully removed.",
            messageType: "success",
          },
        }}
        onGetAPIEndPointFn={(formState) => {          
          return {
            url:`${OtherAdditionDeductionTempAPI.url}/${formState.id}`,
            key: OtherAdditionDeductionTempAPI.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          selectedIds = _get(formState, "additions_deductions.selected_ids", [])
          selectedIdss = selectedIds && selectedIds.length !== 0 ? selectedIds.filter(value => value !== props.row.lcn_addition_deduction_id) : [];
          formAction.changeInputFn("additions_deductions", "selected_ids", "", selectedIdss)
         
          return getDataByFormObject({
            ...formObject,
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: "delete",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

const RemoveAdditionDeductionForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  return (
    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      setFormObject={{
        id: props.row.id,
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you sure you want to remove?</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};

export { RemoveAdditionDeductionForm, CustomButtonDelete };
