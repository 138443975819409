import React, { useContext, useEffect } from "react";
import { Base64 } from "js-base64";

import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  DatePickerWithState,
  YearPickerWithState
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import { fuelListAPI, provincesOfficesListAPI, vehicleClassListAPI, vehicleConditionListAPI, addVehicleAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _set } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";

const today = new Date();
const min_date = new Date(new Date().setHours(0,0,0));
const max_date = new Date(new Date().setHours(23,59,0));

const VehicleInformation = (props) => {
  const formKey = props.formKey;
  const [formState, formAction] = useContext(FormContext);

  useEffect(() => {
    if(props.uiType === 'APR') {
      getOwnerInfo()
    }
  }, [])

  const isProvincial = props.uiType === 'APR' ? true : false

  const getOwnerInfo = () => {
    callApi(`${addVehicleAPI.url}${props.approvalRequestId}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      if(result) {
        const {chassis_no, engine_no, tare_weight, gross_weight, no_of_seats, 
          vehicle_reg_condition_id, fuel_type_id, vehicle_class_id, province_id, first_reg_date,
          manufactured_year, cylinder_capacity, tyre_size, wheel_base, type_of_body,
          make, model, color_1, color_2, color_3 } = result.data.data
        formAction.changeInputFn(formKey, "chassis_no", "", getLabelValue(chassis_no, true, true));
        formAction.changeInputFn(formKey, "engine_no", "", getLabelValue(engine_no, true, true));
        formAction.changeInputFn(formKey, "tare_weight", "", tare_weight !== null ? tare_weight : "");
        formAction.changeInputFn(formKey, "gross_weight", "", gross_weight !== null ? gross_weight : "");
        formAction.changeInputFn(formKey, "no_of_seats", "", no_of_seats !== null ? no_of_seats : "");
        formAction.changeInputFn(formKey, "vehicle_reg_condition", "", vehicle_reg_condition_id !== null ? vehicle_reg_condition_id?.id : "")
        formAction.changeInputFn(formKey, "fuel_type", "", fuel_type_id !== null ? fuel_type_id?.id : "");
        formAction.changeInputFn(formKey, "vehicle_class", "", vehicle_class_id !== null ? vehicle_class_id?.id : "")

        formAction.changeInputFn(formKey, "province", "", province_id !== null ? province_id : "")
        formAction.changeInputFn(formKey, "first_reg_date", "", first_reg_date !== null ? first_reg_date : "")
        formAction.changeInputFn(formKey, "manufactured_year", "", manufactured_year !== null ? `01/01/${manufactured_year}` : new Date())
        formAction.changeInputFn(formKey, "cylinder_capacity", "", cylinder_capacity !== null ? cylinder_capacity : "")
        formAction.changeInputFn(formKey, "tyre_size", "", tyre_size !== null ? tyre_size : "")
        formAction.changeInputFn(formKey, "wheel_base", "", wheel_base !== null ? wheel_base : "")
        formAction.changeInputFn(formKey, "type_of_body", "", type_of_body !== null ? type_of_body : "")
        formAction.changeInputFn(formKey, "make", "", make !== null ? make : "")
        formAction.changeInputFn(formKey, "model", "", model !== null ? model : "")
        formAction.changeInputFn(formKey, "color_1", "", color_1 !== null ? color_1 : "")
        formAction.changeInputFn(formKey, "color_2", "", color_2 !== null ? color_2 : "")
        formAction.changeInputFn(formKey, "color_3", "", color_3 !== null ? color_3 : "")
      }
    })
  }

  return (
    <UICard cardHeading="Vehicle Information">
      <FormWrapper setGroupName={formKey}
        setFormObject={{
          request_id: _set(formState, `${formKey}.request_id`, props.requestId),
        }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"chassis_no"}
              maxLength={20}
              labelText="Chassis"
              isRequired={isProvincial}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"engine_no"}
              maxLength={20}
              labelText="Engine"
              isRequired={isProvincial}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <NumberInputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"tare_weight"}
              isThousandSeparator={false}
              decimalScale={2}
              isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                if (checkValue.includes(".")) {
                  checkValue = checkValue.split(".")[0];
                }

                return checkValue.length <= 8;
              }}
              labelText="Tare (Kg)"
              isAllowNegative={false}
              isRequired={isProvincial}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <NumberInputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"gross_weight"}
              isThousandSeparator={false}
              decimalScale={2}
              isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                if (checkValue.includes(".")) {
                  checkValue = checkValue.split(".")[0];
                }

                return checkValue.length <= 8;
              }}
              labelText="Gross (Kg)"
              isAllowNegative={false}
              isRequired={isProvincial}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <NumberInputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              isRequired={isProvincial}
              inputName={"no_of_seats"}
              labelText="Seats (Exp. Driver)"
              isThousandSeparator={false}
              isAllowNegative={false}
              decimalScale={0}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <SelectBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              isRequired={false}
              apiUrl={`${vehicleConditionListAPI.url}?sort_by=name%7Casc&active=true`}
              apiStateKey={vehicleConditionListAPI.key}
              keyName={"id"}
              valueName={"name"}
              inputName={"vehicle_reg_condition"}
              labelText="Vehicle Condition"
              emptySelectOptionTxt={"Select Vehicle Condition"}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <SelectBoxWithState
              uiType={templateTypes.bootstrap}
              isRequired={isProvincial}
              formGroupName={formKey}
              inputName={"fuel_type"}
              apiUrl={`${fuelListAPI.url}?sort_by=name%7Casc&active=true`}
              apiStateKey={fuelListAPI.key}
              keyName={"id"}
              valueName={"name"}
              labelText="Fuel Type"
              emptySelectOptionTxt={"Select Fuel Type"}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <SelectBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              isRequired={isProvincial}
              inputName={"vehicle_class"}
              apiUrl={`${vehicleClassListAPI.url}?sort_by=name%7Casc&active=true`}
              apiStateKey={vehicleClassListAPI.key}
              keyName={"id"}
              valueName={"name"}
              labelText="Class"
              emptySelectOptionTxt={"Select Class"}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <SelectBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              isRequired={isProvincial}
              inputName={"province"}
              apiUrl={`${provincesOfficesListAPI.url}?sort_by=service.name%7Casc&active=true`}
              apiStateKey={provincesOfficesListAPI.key}
              emptySelectOptionTxt={"Select Province Office"}
              keyName={"id"}
              valueName={"service.name"}
              labelText="Province Office"
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <DatePickerWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              labelText="1st Reg"
              inputName={"first_reg_date"}
              maxDate={today}
              minDate={"1970/01/01"}
              isRequired={isProvincial}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <YearPickerWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"manufactured_year"}
              labelText="Manufactured Year"
              isRequired={false}
              format={"yyyy"}
              minDate={"1970"}
              maxDate={today}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <NumberInputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"cylinder_capacity"}
              isThousandSeparator={false}
              decimalScale={2}
              isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                if (checkValue.includes(".")) {
                  checkValue = checkValue.split(".")[0];
                }

                return checkValue.length <= 8;
              }}
              labelText="Cylinder Capacity"
              isAllowNegative={false}
              isAllowLeadingZeros={false}
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"tyre_size"}
              isThousandSeparator={false}
              decimalScale={2}
              isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                // if (checkValue.includes(".")) {
                //   checkValue = checkValue.split(".")[0];
                // }

                return checkValue.length <= 10;
              }}
              labelText="Tyre Size"
              isAllowNegative={false}
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <NumberInputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"wheel_base"}
              isThousandSeparator={false}
              decimalScale={2}
              isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                if (checkValue.includes(".")) {
                  checkValue = checkValue.split(".")[0];
                }

                return checkValue.length <=8;
              }}
              labelText="Wheel Base"
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"type_of_body"}
              maxLength={20}
              labelText="Type of Body"
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"make"}
              maxLength={20}
              labelText="Make"
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"model"}
              maxLength={20}
              labelText="Model"
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"color_1"}
              maxLength={20}
              labelText="Color 1 "
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"color_2"}
              maxLength={20}
              labelText="Color 2 "
              isRequired={false}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formKey}
              inputName={"color_3"}
              maxLength={20}
              labelText="Color 3 "
              isRequired={false}
            />
          </div>
        </div>
      </FormWrapper>
    </UICard>
  );
};

export { VehicleInformation };
