import React, { Fragment, useState, useContext} from "react";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../../../ui-components/ui-elements/modal/DialogPopup";
import { ViewDocuments } from "../../includes/ViewDocuments";
import { ActivityLog } from "../../includes/ActivityLog";
import IssueDealer from "../../../../payment/pages/IssueDealer";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
const DealerFooter = ({
  currentTab = "tab1",
  formChild = {
    issueMode: "1",
    isPendingWorkFlow: false,
    payArrearsOnly: true,
  },
  latestLicence = {}
}) => {
  const [formState, formAction] = useContext(FormContext);
  const [getState, setState] = useState({
    status: false,
    modelType: "",
  });
  const searchFormKey = "dealer_search_form";
  const pendingWorkflowFormKey = "pending_workflow_key";
  const [authStatus, authAction] = useContext(AuthContext);

  const closePopup = () => {
    setState({
      ...getState,
      status: false,
      modelType: "",
    })
  };

 
  const btnName = () => {
    if (_get(formChild, "issueMode", "1") === "1") {
      return "Issue RL";
    } else {
      return "Issue Temp";
    }
  };


  const getModelName = () => {
    if (getState.modelType === "payment") {
      return "Make Payment - Dealer's Licence";
    } else if (getState.modelType === "viewDocuments") {
      return "View & Upload Documents";
    } else if (getState.modelType === "activityLog") {
      return "Activity Log";
    }
  };

  const getModel = () => {
    if (getState.modelType === "payment") {
      return <IssueDealer uiType={"dealer"} latestLicence={latestLicence} closeMainPopup={closePopup} />;
    } else if (getState.modelType === "viewDocuments") {
      return <ViewDocuments uiType='dealer' />;
    } else if (getState.modelType === "activityLog") {
      return <ActivityLog />;
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <Fragment>   
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            btnText={"View/Upload Documents"}
            isBtnDisabled={!_get(formState, `dealer_search_form.data`, null)}
            onClickBtnFn={() =>
              setState({
                ...getState,
                status: true,
                modelType: "viewDocuments",
              })
            }
          />
        </Fragment>
      </div>
      <div className="col-md-6 text-right floatRight">

        {_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length === 0 && _get(formState, "pending_workflow_key._onLoad", false) === false && _get(formState, "pending-licence._onLoad", false) === false ? (
          <InputButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            btnText={btnName(currentTab)}
            isBtnDisabled={(_get(authStatus, "authUser.pdmtId.id", "") !== _get(formState, `dealer_search_form.data.dealer_address.province.id`, null))||(_get(formState, "pending-licence.count", 0)===0)  || !_get(formState, "dealer_search_form.data", null) || ( _get(formState, "dealer_charges_form.total", 0)===0)}
            formGroupName={`dealer`}
            onClickBtnFn={() =>
              setState({
                ...getState,
                status: true,
                modelType: "payment",
              })
            }           
          />
        ) : null}




      </div>
      <DialogPopup
        isOpen={getState.status}
        isFullScreen={getState.modelType === "viewDocuments" ? false : true}
        dialogTitle={getModelName()}
        onClosePopupFn={() =>
          setState({
            ...getState,
            status: false,
            modelType: "",
          })
        }
        closeButton={{
          btnText: getState.modelType !== "viewDocuments" ? 'Back' : 'Cancel'
        }}
        isConfirmationPopup={getState.modelType === "payment" ? true : false}
        confirmationMessage={"Are you sure you want to go back?"}
      >
        {getModel()}
      </DialogPopup>
    </div>
  );
};

export { DealerFooter };
