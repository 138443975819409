/*
 * @Author: Gajani Kangesan
 * @Date: 2022-07-11 11:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:13:47
 */

import React, { useContext, useState, useEffect, Fragment } from "react";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { InputButton } from "../../../../ui-components/ui-elements/form/Button";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { useLicencePrint } from '../../../../hooks/common-hooks/usePrinter.hook';
import { FormContext } from "../../../core/context-providers/FormContext.provider";

import { LicencePeriodIcons } from "./LicencePeriodIcons";
import { ViewTransaction } from "./ViewTransaction";
import { LicenceReprintPreview } from "./LicenceReprintPreview";
import { ViewDealerTransactionDetail } from "./ViewDealerTransactionDetail";
import {
    actionBtnTypes,
    DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { getAllTask, licencePrintLicenceCount } from "../../../../../config/apiUrl.config";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import LicenceReceiptPrint from "../../../payment/pages/includes/LicenceReceiptPrint";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";

const PaymentHistory = ({
    formKey = "",
    dataTableKey = "",
    apiUrl = "",
    getVehicleStatusCode = "ELI",
    tableHeaderList = "",
    licenceType = "",
    isReprint,
    isView, category,
    vehicleId,


}) => {
    let tableKey = dataTableKey.key
    const pendingWorkflowFormKey = "pending_workflow";
    let title, modelElement;
    const [getActionReprint, setActionReprint] = useState(null)
    const [coreState, coreAction] = useContext(CoreContext);
    const [getView, setView] = useState({
        "isViewAll": false
    });
    switch (category) {
        case 'VT':
            title = "View Licence Details";
            modelElement = ViewTransaction;
            break;
        case 'DT':
            title = "View Payment Details";
            modelElement = ViewDealerTransactionDetail;
            break;

        default:
    }

    const [formState, formAction] = useContext(FormContext);
    useEffect(() => {
        coreAction
            .sendRequestFn(`${getAllTask.url}?vehicle_id=${vehicleId}&request_type=reprint_lcn`)
            .method("get")
            .setInitStoring(apiResponseStoringType.setResponse, {
                responseKey: pendingWorkflowFormKey,
            })
            .headers(true)
            .send((error, result) => {
                if (!error) {

                    formAction.changeInputFn(pendingWorkflowFormKey, "workflows", "", result.data.data);
                }
            });
    }, []);



    const getActionTypesList = () => {
        // (getVehicleStatusCode === "ELI")
        const list = [];
        if (isReprint) {
            list.push({
                actionType: actionBtnTypes.reprint,
                modelContentElement: LicenceReprintPreview,
                dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "Print Preview",
                    isCustomButton: true,
                    closeButton: {
                        btnText: "Close",
                        startIcon: "far fa-times-circle",
                    },
                    customButtonComponent: SubmitBtn,
                    customButtonComponentProps: { dataTableKey: tableKey },
                },
                parentToModelProps: {
                    licenceType: licenceType,
                    formKey: formKey,
                },
                checkBeforeActionRenderFn: (rawData) => {
                    return (_get(rawData, "row.actions_reprint", "") === 1)
                }
            });

        }


        if (isView) {
            list.push({
                actionType: actionBtnTypes.viewMore,
                modelContentElement: modelElement,
                dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: title,
                    isCustomButton: false,
                    closeButton: {
                        btnText: "Close",
                        startIcon: "far fa-times-circle",
                    },
                },
                parentToModelProps: {
                    licenceType: licenceType,
                    formKey: formKey,

                },
            });
        }

        return list;
    };


    return (

        <PaddingLessUICard
            cardHeading="Payment History"
            cardHeadingTail={LicencePeriodIcons}
            passPropsToCardHeadingTail={{
                uiType: "paymentHistory",
                formGroupName: formKey,
                getVehicleStatusCode: getVehicleStatusCode,
                onChangeStatusFn: () => {
                    setView({
                        ...getView,
                        isViewAll: !getView.isViewAll

                    })
                }
            }}
        >
            <DataTable
                apiUrl={category === "VT" ? `${apiUrl}/${getView.isViewAll}` : apiUrl}
                dataTableKey={dataTableKey.key}
                elementWrapperStyle={"scrolTable paymentHistoryTable"}
                tableHeaderList={tableHeaderList}
                isSetTableFooter={false}
                isSetTableHeader={false}
                maxItemCount={10}
                isSetAction={isView || isReprint}
                actionTypeList={getActionTypesList()}

                onRowStyleFn={(data) => {
                    if (category === "VT") {
                        if (data.row.approval_pending === 1) {
                            return "Appr"
                        }
                        else {
                            return data.row.status
                        }
                    }
                    if (category === "DT") {
                        if (data.row.lcn_status === "CAN") {
                            return "CAN"
                        }

                    }
                }}
            />

            <div className="fullWidthDiv defaultHalfMarginTopBottom">
                <div className="col-md-11">
                    <div className="row">
                        <div className="col-md-3 floatLeft">
                            <strong>Reversed: </strong>{" "}
                            <i class="fas fa-square-full" style={{ color: "gray" }}></i>
                        </div>
                        <div className="col-md-5 floatLeft">
                            <strong>Reversal Approval Pending: </strong>{" "}
                            <i class="fas fa-square-full" style={{ color: "yellow" }}></i>
                        </div>
                        <div className="col-md-3 floatLeft">
                            <strong>Cancelled: </strong>{" "}
                            <i class="fas fa-square-full" style={{ color: "red" }}></i>
                        </div>
                    </div>
                </div>
            </div>
        </PaddingLessUICard>
    );
};

const SubmitBtn = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const authority_id = _get(authStatus, "authUser.authorityId", "");
    // const { printFn, bulkPrintFn } = useLicencePrint();
    const licencePrint = (printFrame) => {
        let printElement = document.getElementById(printFrame);
        let printWindow = window.open('', 'PRINT', 'height=400,width=600');
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = './licenceReceipt.css';
        document.getElementsByTagName('HEAD')[0].appendChild(link);
        printWindow.document.write(document.documentElement.innerHTML);
        printWindow.document.body.innerHTML = printElement.innerHTML;
        // printWindow.document.close(); // necessary for IE >= 10
        // printWindow.focus(); // necessary for IE >= 10*/
        printWindow.print();
        printWindow.close();
    }
    return (
        <Fragment>
            <InputButton
                btnText="Print"
                startIcon={"far fa-save"}
                elementStyle="btnWrapper"
                onClickBtnFn={() => {
                    // printFn(coreAction, _get(formState, 'licenceReprintFormKey.licenceData', ""));
                    licencePrint("print-card")
                    coreAction
                        .sendRequestFn(
                            `${licencePrintLicenceCount.url}/${props.row.lcn_id}?serial_number=${props.row.lcn_id}&authority_id=${authority_id}`
                        )
                        .isMultipart(false)
                        .method("put")
                        .body({})
                        .setLoader(false)
                        .setInitStoring(apiResponseStoringType.responseLinkToFrom, {
                            responseKey: 'printCountLicenseResponse',
                        })
                        .send((error, response) => {
                            if (!error) {
                                coreAction.resetDataTable(props.dataTableKey);
                                if (!isEmptyValue(_get(response, "data.next_serial_number", null))) {
                                    formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", _get(response, "data.next_serial_number", null));
                                }
                            }
                            props.onClosePopupFn();
                        });


                }}
            />
            <div className='licence-visibility'>
                <div id="print-card">
                    <LicenceReceiptPrint dataList={_get(formState, 'licenceReprintFormKey.licenceData.data', "")} />
                </div>
            </div>
        </Fragment>
    );
};


export { PaymentHistory };
