/*
 * @Author: Madhawa
 * @Date: 2020-08-10 12:18:11
 * @Last Modified by: Jayani NawarathnaNawarathnaNawarathnaNawarathna
 * @Last Modified time: 2021-12-02 17:24:06
 */

import React, { useContext } from "react";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getLicenceTransactionAPI } from "../../../../../config/apiUrl.config";
import { LicenceType } from "../../../../../config/enum.config";


const LastTransactionInformation = (props) => {
    console.log('PROOOO', props);
    const { uiType } = props
    const [formState] = useContext(FormContext);
    const request_id = "99998888";
    const vehicle_no = _get(
        formState,
        "vehicle_search_form.searchKey",
        null
    );
    //const request_id = props.row.request_id;
    const formKey = "approvalRequest";
  let licenceType;
  
    switch(_get(formState, `${formKey}.licence_type`, '-')){
        case "NO":
            licenceType='NL-T - Normal Revenue Licence - Temporary';
            break;
        case "FE":
            licenceType='FR-T - Free Licence - Temporary';
            break;
        case "CA":
            licenceType='CN-T - Carnet Licence - Temporary';
            break;
    }

    return (
        <UICard>
            <FormWrapper
                setGroupName={formKey}
                apiUrl={`${getLicenceTransactionAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
                isReverseTx={true}
                onRebuildResponseFn={(response) => {
                    console.log('RESP--', response);
                    if (response && response.length !== 0 &&
                        response[0].transaction_details && response[0].licence_data) {
                            // console.log('RESP--1', 'response');

                        return {
                            // entry_mode: response[0].entry_mode,
                            tx_id: response[0].transaction_details.id,
                            tx_type: response[0].arrears_only == 1 ? 'Arrears' : 'Licence',
                            tx_status: response[0].transaction_details.status === 1 ? 'Success' : 'Failed',
                            licence_type: response[0].licence_type_code,
                            temp_licence:response[0].temp_licence,
                            issue_mode: response[0].temp_licence == 1 ? 'Temporary Licence' : 'Full Licence',
                            serial_receipt_no: response[0].transaction_details.txn_number,
                            licence_receipt_no: response[0].licence_number,
                            from: response[0].licence_data.valid_from,
                            to: response[0].licence_data.valid_to,
                            licence_id: response[0].licence_data.id,
                            // issued_date: response[0].issued_date,
                            fee: response[0].fee,
                            fine: response[0].fine,
                            addition: response[0].addition,
                            deduction: response[0].deduction,
                            // print_required: response[0].print_required,
                            created_at: response[0].transaction_details.created_at,
                            created_at_location: response[0].created_location_name,
                            created_by: response[0].created_by_name,
                            created_role: response[0].created_role,
                            migrated: response[0].transaction_details.migrated,

                            "_onLoad": true
                        }
                    }

                }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-1"><strong>Tx. No.</strong></div>
                            <div className="col-md-3">{_get(formState, `${formKey}.serial_receipt_no`, '-')}</div>
                            <div className="col-md-1"><strong>Tx. Status</strong></div>
                            <div className="col-md-3">{_get(formState, `${formKey}.tx_status`, '-')}</div>
                            <div className="col-md-1"><strong>Tx. Type</strong></div>
                            <div className="col-md-3">{_get(formState, `${formKey}.tx_type`, '-')}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-1"><strong>Tx. Date & Time</strong></div>
                            <div className="col-md-3">{dateObjectToString(_get(formState, `${formKey}.created_at`, '-'), "dd/mm/YY HH:MM")}</div>
                            <div className="col-md-1"><strong>Created by</strong></div>
                            <div className="col-md-3">{`${_get(formState, `${formKey}.created_by`, '-')}(${_get(formState, `${formKey}.created_role`, '-')})`}</div>
                            <div className="col-md-1"><strong>Created at</strong></div>
                            <div className="col-md-3">{_get(formState, `${formKey}.created_at_location`, '-')}</div>
                        </div>
                    </div>

                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-md-1"><strong>Licence No / Receipt No</strong></div>
                            <div className="col-md-3">{_get(formState, `${formKey}.licence_receipt_no`, '-')}</div>
                            <div className="col-md-1"><strong>Licence Type</strong></div>
                            <div className="col-md-3">{_get(formState, `${formKey}.temp_licence`, '-') ===1 ? licenceType :LicenceType[`${_get(formState, `${formKey}.licence_type`, '-')}`]}</div>
                            <div className="col-md-1"><strong>Validity Period(From - To)</strong></div>
                            <div className="col-md-3">{dateObjectToString(_get(formState, `${formKey}.from`, '-'), "dd/mm/YY")} - {dateObjectToString(_get(formState, `${formKey}.to`, '-'), "dd/mm/YY")}</div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-1"><strong>Licence issue mode</strong></div>
                            <div className="col-md-3">{_get(formState, `${formKey}.issue_mode`, '-')}</div>

                            <div className="col-md-1"><strong>Year</strong></div>
                            <div className="col-md-3">{new Date(_get(formState, `${formKey}.to`, '-')).getFullYear()}</div>
                        </div>
                    </div>

                </div>
            </FormWrapper>
        </UICard>
    );
};

export default LastTransactionInformation;