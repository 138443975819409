import React, {useContext} from "react";

import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";
import { SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import {  dealerVehicleTempAPI } from "../../../../../../config/apiUrl.config";
import { CoreContext } from "../../../../core/context-providers/CoreContext.provider";

const DeleteVehicleNumberUI = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure you want to remove?</p>
        </div>
    )
};

const DeleteVehicleNumberBtn = (props) => {
    const [, uiAction] = useContext(UIContext);
  const [coreState, coreAction] = useContext(CoreContext);

    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            dataTableKey={props.dataTableKey}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Vehicle is successfully deleted.",
                    messageType: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            // apiDataStoringObject={{
            //     setLoader: true,
            //     storingType: "DATA_TABLE_LINKED_FORM",
            //     mergeToSuccessResponse: null,
            //     mergeToErrorResponse: null,
            // }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${dealerVehicleTempAPI.url}/${props.row.id}`,
                    key: dealerVehicleTempAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Vehicle is successfully deleted.",
                        messageType: "success"
                    });
                    // coreAction.initDataTable(dealerVehicleTempAPI.key);
                    props.onClosePopupFn();
                }
            }}
        />
    )
};

export {
    DeleteVehicleNumberUI,
    DeleteVehicleNumberBtn
}