import React, {useEffect, useContext} from "react";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getLatestLicenseAPI } from "../../../../../config/apiUrl.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const LicenceReprintInformation = (props) => {
  const [formState, formAction] = useContext(FormContext);

    // const [data, setData] = useState({})
    const getData = () => {
        const sendAPIRequest = (apiUrl) => {
            return new Promise((resolve, reject) => {
                callApi(apiUrl)
                    .headers(true)
                    .method("get")
                    .isMultipart(false)
                    .send((error, result) => {
                        if (error) {
                            reject(error);
                        }
    
                        resolve(result?.data);
                    })
            });
        };
    
        const lcnData = sendAPIRequest(`${getLatestLicenseAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(props, `row.vehicle_no`, null)}`);
            // const endDatesPromise = sendAPIRequest(`${normalLicenceEndDatesAPI.url}?${fromObjectToQueryString(queryObj)}`);
    
            Promise.all([lcnData])
                .then(result => {
                    const setResult = result && result.length !== 0 ? result[0].data : {}
                    formAction.changeInputFn('allTaskData', "licenceReprint", "", setResult);
                    // setData(setResult)
                    // console.log('RRRRRR', result);
                    
                });
    
    }

    useEffect(() => {
        getData()
    },[])
    

    const data = _get(formState, `allTaskData.licenceReprint`, {})
// console.log('PLLLL', props);
    const {licenceData, type, uiType} = props
    // console.log('LLLLL', props);
    const serialNo = licenceData && licenceData.serial_number ? licenceData.serial_number : null
    return ( 
        <>
            {(type === "AllTasks" || uiType === 'APR') ? <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-6"><strong>Serial Number</strong></div>
                        <div className="col-md-6">{data?.serial_number !== null ? data.serial_number : '-'}</div>
                    </div>
                    <div className="row">
                        {data?.arrears_only === 1 ?
                            <div className="col-md-6"><strong>Receipt Number</strong></div>
                            :
                            <div className="col-md-6"><strong>Revenue Licence Number</strong></div>
                        }
                        <div className="col-md-6">{data?.licence_number}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"><strong>Licence Period</strong></div>
                        <div className="col-md-6">{data && data.valid_from ? dateObjectToString(data?.valid_from, "dd/mm/YY") : '-'} - {data && data.valid_to ? dateObjectToString(data?.valid_to, "dd/mm/YY") : '-'}</div>
                    </div>
                </div>
            </div>
             :
             <div className="row">
             <div className="col-md-6">
                 <div className="row">
                     <div className="col-md-6"><strong>Serial Number</strong></div>
                     <div className="col-md-6">{serialNo !== null ? licenceData.serial_number : '-'}</div>
                 </div>
                 <div className="row">
                     {licenceData?.arrears_only === 1 ?
                         <div className="col-md-6"><strong>Receipt Number</strong></div>
                         :
                         <div className="col-md-6"><strong>Revenue Licence Number</strong></div>
                     }
                     <div className="col-md-6">{licenceData?.licence_number}</div>
                 </div>
                 <div className="row">
                     <div className="col-md-6"><strong>Licence Period</strong></div>
                     <div className="col-md-6">{licenceData && licenceData.valid_from ? dateObjectToString(licenceData?.valid_from, "dd/mm/YY") : '-'} - {licenceData && licenceData.valid_to ? dateObjectToString(licenceData?.valid_to, "dd/mm/YY") : '-'}</div>
                 </div>
             </div>
         </div> 
            }
            </>
    );
};

export default LicenceReprintInformation;