/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-05-23 09:17:11 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 13:28:31
 */

import React, { Fragment, useState, useContext } from 'react';


import { _get, _findindex, _getInt } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { CircleLoaderElement } from "../../common/BaseElements";
import { actionBtnTypes, commonActionBtnPops } from "../../../../../config/dataTable.config";
import { TableHeaderBar } from "./TableHeaderBar";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { SearchBoxWrapper } from "./TableSearchForm";
import { FooterWrapper } from "./TableFooter";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";

const emptyFun = (...para) => undefined;


const TableUI = ({
    _dataTableKey = "",
    _isSetAction = false,
    customActionHeader = null,
    dataTableState = {},
    _actionTypeList = [],
    _tableHeaderList = [],
    _specificComponents = null,
    _screenType = "",
    _shortObject = {},
    tableBody = [],
    maxItemCount = 3,
    _dataTableErros = [],
    pagingObject = {},
    isSelectAllCheckActive = true,
    isSelectRow = false,
    onRowStyleFn = emptyFun,
    onCellStyleFn = emptyFun,
    _setDataTableStateFn = emptyFun,
    onShortFn = emptyFun,
    onChangeDataTableFieldValueFn = emptyFun,
    onCheckSelectALLFn = emptyFun,
}) => {

    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const [getState, setState] = useState({
        "selectAll": false,
        "selectedDataList": []
    });

    const checkBoxSelectAllHandler = (value) => {
        //  To fix Select All not updating form state issues
        let selectedArray = []
        let selectedIndexObj = {}

        //Create data array for all
        _get(coreState, `dataTableResponses.${_dataTableKey}.results`, []).map((currElement, index) => {
            let obj = {
                rowIndex: `row_${index}_key`,
                ...currElement
            }
            selectedArray.push(obj)
        });

            //Create index array for all
        _get(coreState, `dataTableResponses.${_dataTableKey}.results`, []).map((currElement, index) => {
            selectedIndexObj[`row_${index}_key`] = true
        });

        if (value) {
            setState({
                ...getState,
                ...selectedIndexObj,
                "selectedDataList": selectedArray,
                "selectAll": value
            });
        } else {
            setState({
                "selectAll": false,
                "selectedDataList": []
            });
        }

        //to update form states
        if (value) {
            formAction.setCheckBoxValues(_dataTableKey, _get(coreState, `dataTableResponses.${_dataTableKey}.results`, []))
        } else {
            formAction.setCheckBoxValues(_dataTableKey, [])
        }

    };

    const checkBoxSelectHandler = async (key, status, rowData) => {

        if (status === true) {
            setState({
                ...getState,
                [key]: status,
                "selectedDataList": [...getState["selectedDataList"], { rowIndex: key, ...rowData }]
            });
            formAction.setCheckBoxValues(_dataTableKey, [...getState["selectedDataList"], { rowIndex: key, ...rowData }])
        } else {
            const updatedSelectedDataList = await getState.selectedDataList.filter(item => item.rowIndex !== key)
            setState({
                ...getState,
                [key]: status,
                "selectAll": false,
                "selectedDataList": [...updatedSelectedDataList]
            });
            formAction.setCheckBoxValues(_dataTableKey, [...updatedSelectedDataList])
        }

    }

    return (
        <div className="dataTableBodyWrapper">
            <table>
                <TableHeader
                    tableHeaderList={_tableHeaderList}
                    dataTableKey={_dataTableKey}
                    isSetAction={_isSetAction}
                    customActionHeader={customActionHeader}
                    screenType={_screenType}
                    maxItemCount={maxItemCount}
                    shortObject={_shortObject}
                    isSelectRow={isSelectRow}
                    onShortFn={onShortFn}
                    selectAllRowStatus={getState.selectAll}
                    onCheckSelectALLFn={(value) => checkBoxSelectAllHandler(value)}
                    isSelectAllCheckActive={isSelectAllCheckActive}
                />
                <TableBody
                    tableHeaderList={_tableHeaderList}
                    dataTableKey={_dataTableKey}
                    isSetAction={_isSetAction}
                    maxItemCount={maxItemCount}
                    isSelectRow={isSelectRow}
                    actionTypeList={_actionTypeList}
                    specificComponents={_specificComponents}
                    dataTableErros={_dataTableErros}
                    onRowStyleFn={onRowStyleFn}
                    onCellStyleFn={onCellStyleFn}
                    onChangeValueFn={(stateKey, inputKey, value) => onChangeDataTableFieldValueFn(stateKey, inputKey, value)}
                    onActionClickFn={(row = {}, index = null, rowIndex = null) => _setDataTableStateFn({
                        ...dataTableState,
                        modelStatus: {
                            ...dataTableState.modelStatus,
                            status: true,
                            type: "actionList",
                            index: index,
                            props: row,
                            rowIndex: rowIndex
                        }
                    })
                    }
                    screenType={_screenType}
                    tableBody={tableBody}
                    pagingObject={pagingObject}
                    rowCheckObject={getState}
                    onCheckRowFn={(key, status, rowData) => checkBoxSelectHandler(key, status, rowData)}
                />
            </table>
        </div>
    );
}


const TableUIWrapper = ({
    _apiUrl = "",
    _tableName = "",
    _dataTableKey = "",
    _isSetSearchFrom = false,
    _isSetAction = false,
    dataTableState = {},
    _actionTypeList = [],
    _tableHeaderList = [],
    _defaultSearchFormObject = {},
    _searchFromComponent = null,
    _onSetSearchObjectFn = false,
    _specificComponents = null,
    _screenType = "",
    _pagingObject = {},
    _shortObject = {},
    tableBody = [],
    maxItemCount = 3,
    _dataTableErros = [],
    customActionHeader = null,
    isSetTableFooter = true,
    isSetTableHeader = true,
    isSelectRow = false,
    isSelectAllCheckActive = true,
    _responseFetchingStatus = "init",
    isSearchWithSelect = false,
    onRowStyleFn = emptyFun,
    onCellStyleFn=emptyFun,
    _setDataTableStateFn = emptyFun,
    requestAPIDataFn = emptyFun,
    onShortFn = emptyFun,
    onChangeDataTableFieldValueFn = emptyFun,
    onCheckRowFn = emptyFun,
    onClickSearchFn = emptyFun,
    customSearchFunc = emptyFun
}) => {
    return (
        <Fragment>

            {
                (isSetTableHeader == true) ? (
                    <Fragment>
                        <TableHeaderBar
                            tableName={_tableName}
                            isSetSearchFrom={_isSetSearchFrom}
                            dataTableState={dataTableState}
                            setDataTableStateFn={_setDataTableStateFn}
                            actionIndex={_findindex(_actionTypeList, ["actionType", actionBtnTypes.create])}
                            actionBtnPara={{
                                ...commonActionBtnPops[actionBtnTypes.create],
                                ..._get(_actionTypeList, `${_findindex(_actionTypeList, ["actionType", actionBtnTypes.create])}.actionBtn`, {})
                            }}
                            isSetModel={_get(_actionTypeList, `${_findindex(_actionTypeList, ["actionType", actionBtnTypes.create])}.modelContentElement`, null) === null ? false : true}
                        />
                        <SearchBoxWrapper
                            isSetSearchFrom={_isSetSearchFrom}
                            customSearchFunc={customSearchFunc}
                            dataTableState={dataTableState}
                            dataTableKey={_dataTableKey}
                            defaultSearchFormObject={_defaultSearchFormObject}
                            searchFromComponent={_searchFromComponent}
                            setDataTableStateFn={_setDataTableStateFn}
                            onClickSearchFn={onClickSearchFn}
                            onGetData={requestAPIDataFn}
                            onSetSearchObjectFn={_onSetSearchObjectFn}
                            isSearchWithSelect={isSearchWithSelect}
                        />
                    </Fragment>
                ) : (null)
            }

            {
                (_apiUrl && (_responseFetchingStatus === 'init' || _responseFetchingStatus === undefined)) ? (
                    <div className="fullWidthDiv defaultMarginTopBottom">
                        <center>
                            <CircleLoaderElement />
                        </center>
                    </div>
                ) : (
                    <Fragment>
                        {
                            (_responseFetchingStatus === 'error' || tableBody.length === 0) ? (
                                <div className="fullWidthDiv defaultMarginTopBottom defaultPaddingLeftRight">
                                    No record(s) found.
                                </div>
                            ) : (
                                <Fragment>
                                    <TableUI
                                        _dataTableKey={_dataTableKey}
                                        _isSetAction={_isSetAction}
                                        dataTableState={dataTableState}
                                        _actionTypeList={_actionTypeList}
                                        _tableHeaderList={_tableHeaderList}
                                        _specificComponents={_specificComponents}
                                        _screenType={_screenType}
                                        _shortObject={_shortObject}
                                        tableBody={tableBody}
                                        maxItemCount={maxItemCount}
                                        _dataTableErros={_dataTableErros}
                                        pagingObject={_pagingObject}
                                        isSelectRow={isSelectRow}
                                        customActionHeader={customActionHeader}
                                        isSelectAllCheckActive={isSelectAllCheckActive}
                                        _setDataTableStateFn={_setDataTableStateFn}
                                        onShortFn={onShortFn}
                                        onRowStyleFn={onRowStyleFn}
                                        onCellStyleFn={onCellStyleFn}
                                        onChangeDataTableFieldValueFn={onChangeDataTableFieldValueFn}

                                    />

                                    {
                                        (isSetTableFooter === true) ? (
                                            <FooterWrapper
                                                totalRecordsCount={_getInt(_pagingObject, 'total', null)}
                                                currentPage={_getInt(_pagingObject, 'current_page', 1)}
                                                pageCount={_getInt(_pagingObject, 'page_count', 1)}
                                                perPage={_getInt(_pagingObject, 'per_page', 10)}
                                                onNextFn={requestAPIDataFn}
                                            />
                                        ) : (null)
                                    }

                                </Fragment>
                            )
                        }
                    </Fragment>
                )

            }

        </Fragment>
    )
}




export {
    TableUIWrapper
}