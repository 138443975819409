/*
 * @Author: Chamith Jayathilaka
 * @Date: 2022-05-30 12:00:00
 * @Last Modified by: Chamith Jayathilaka
 * @Date: 2022-05-30 12:00:00
 */

import React, { Fragment } from "react";
import {
  InputBoxWithState,
  FormWrapper,
} from "../../../../../ui-components/ui-elements/form/index";

const AddCommentPopup = (props) => {
  const formGroupName = `${props.formGroupName}_add`;
  return (
    <div className="w-100">
      <FormWrapper
        elementStyle={"counterCommentWrapper"}
        setGroupName={formGroupName}
        setFormObject={{
          comment: "",
        }}
      >
        <InputBoxWithState
          isRequired={true}
          isMultiline={true}
          rows={3}
          maxLength={250}
          labelText={"Comment"}
          formGroupName={formGroupName}
          inputName={"comment"}
        />
      </FormWrapper>
    </div>
  );
};

export { AddCommentPopup };
