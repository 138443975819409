/*
 * @Author: Anjula Karunarathne 
 * @Date: 2021-01-12 14:28:35 
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-13 16:43:08
 */

import React, { Fragment, useContext, useState } from "react";
import { DashboardTemplate } from "../../../../../ui-components/templates/DashboardTemplate";
import { templateTypes } from "../../../../../../config/template.config";
import { downloadFile } from "../../../../../../helpers/download-helpers/download.helpers"
import {
  FormWrapper,
  SelectBoxWithState,
  SubmitButton,
  InputButton

} from "../../../../../ui-components/ui-elements/form";
import { _getInt, _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { reportGenerateAPI } from "../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { SearchCriteria } from './includes/SearchCriteria'
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { permissions } from "../../../../../../config/permission.config";
const DetailedIncomeReport = () => {
  const reportCode = `fr04-detailed-income-report-entire`
  const searchformGroupName = `detailedIncomeReportForm`;
  const exportformGroupName = `export_report_form`;
  const [htmlTemplate, sethtmlTemplate] = useState(null);
  const [reportgeneratedValues, setReportgeneratedValues] = useState({});
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [scrollView, setscrollView] = useState({
    "onload": false,
    "bar": false
  });
  const reportName = "Detailed Income Report"
  const [printcontent, sePrintcontent] = useState(null)

  const onGenerateSubmit = (event) => {
    setscrollView({
      "onload": true,
      "bar": JSON.stringify({ __html: event }).includes("No results found.").toString()
    })
    sethtmlTemplate({ __html: event });
    sePrintcontent(event)
  };

  const onSetGeneratedValues = (event) => {
    setReportgeneratedValues(event)
  };

  const onExportToPdfSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.pdf`);
  };

  const onExportToExcelSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.xlsx`);
  };
  const onResetClick = () => {
    sethtmlTemplate(null)
    setReportgeneratedValues({})
  }
  const ExportOption = []
  _get(authStatus, 'authUser.permissions', "").forEach(item => {
    if (item.permission === permissions.EXEL_FR04_DETAILED_INCOME_REPORT_ENTIRE.permissions[0]) {
      ExportOption.push({
        id: "xlsx",
        value: "Excel",
      })
    }
    else if (item.permission === permissions.PDF_FR04_DETAILED_INCOME_REPORT_ENTIRE.permissions[0]) {
      ExportOption.push({
        id: "pdf",
        value: "PDF",
      })
    }
  })
  const reportPrint = () => {
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printcontent;
    window.print();
    window.location.reload();
    document.body.innerHTML = originalContents;
    window.close()

  }
  return (
    <DashboardTemplate
      pageTitle="Detailed Income Report"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        { urlTo: "/income-details", displayName: "Income Details Reports", },
        {
          urlTo: "/report",
          displayName: "Detailed Income Report",
        },
      ]}
    >
      <SearchCriteria
        reportCode={reportCode}
        formGroupName={searchformGroupName}
        onGenerateSubmit={onGenerateSubmit}
        setReportgeneratedValues={onSetGeneratedValues}
        onResetClick={onResetClick}
      />
      {(htmlTemplate && scrollView.onload === true) && (
        <div class="mt-2 report-html-view ">
          <UICard>
            <div class={`${scrollView.bar}`} dangerouslySetInnerHTML={htmlTemplate} />
          </UICard>
        </div>
      )}

      {htmlTemplate && (
        <div class="mt-2">
          <UICard>
            <FormWrapper
              setGroupName={exportformGroupName}
              formGroupLinkWith={exportformGroupName}
              onDestroyUnsetFormObject={true}
              setFormObject={{
                export_to: null
              }}
            >
              <div className={"reportExportToAlignWrapper"}>
                <div className="exporTypeSelectDropdown ">
                  <SelectBoxWithState
                    uiType={templateTypes.bootstrap}
                    formGroupName={exportformGroupName}
                    emptySelectOptionTxt={'  '}
                    inputValue={null}
                    dataList={ExportOption}
                    inputName={"export_to"}
                    labelText="Export To"
                  />

                </div>
                <div className="exportBtn">
                  <SubmitButton
                    btnText={"Export"}
                    elementStyle="btnWrapper"
                    formGroupName={exportformGroupName}
                    isValidate={true}
                    validationObject={{
                      fileds: {
                        export_to: "Export To"
                      },
                      rules: {
                        export_to: "required"
                      },
                      message: {}
                    }}
                    callApiObject={{
                      isSetHeaders: true,
                      method: "get",
                      multipart: false,
                      onUpload: false,
                      responseType: 'arraybuffer'
                    }}
                    apiDataStoringObject={{
                      setLoader: true,
                      storingType: "API_RESPONSE_LINKED_FORM",
                      mergeToSuccessResponse: true,
                      mergeToErrorResponse: true
                    }}
                    onGetAPIEndPointFn={(formState) => {
                      return {
                        url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=${formState?.export_to}&report_limit=${reportgeneratedValues?.limit}&report_offset=${reportgeneratedValues?.offset}&report_requestFromDate=${reportgeneratedValues?.datefrom}&report_requestToDate=${reportgeneratedValues?.dateto}&report_requestProvince=${reportgeneratedValues?.provinceName}&report_provinceId=${reportgeneratedValues?.provinceId}&report_authorityId=${reportgeneratedValues?.authorityId}&report_requestAuthority=${reportgeneratedValues?.authorityName}&report_counterId=${reportgeneratedValues?.counterId}&report_requestCounter=${reportgeneratedValues?.counterName}&report_licenceTypeName=${reportgeneratedValues?.licencetype}&report_licenceTypeCode=${reportgeneratedValues?.licencetypeCode}&report_generatedUser=${reportgeneratedValues?.reportGeneratedUser}`,
                        key: `${reportGenerateAPI.key}`
                      }
                    }}

                    onResponseCallBackFn={(error, response,) => {
                      if (response?._statue) {
                        if (_get(formState[exportformGroupName], "export_to", "") === 'pdf') {
                          onExportToPdfSubmit(response?.data)
                        }
                        if (_get(formState[exportformGroupName], "export_to", "") === 'xlsx') {
                          onExportToExcelSubmit(response?.data)
                        }
                      }
                    }}
                  />
                  <InputButton
                    btnText="Print"
                    formGroupName={exportformGroupName}
                    elementWrapperStyle={"defaultHalfMarginLeftRight payBtnStyle"}
                    onClickBtnFn={() => reportPrint()}
                  />
                </div>

              </div>
            </FormWrapper>
          </UICard>
        </div>
      )}
    </DashboardTemplate>
  );
};

export { DetailedIncomeReport };
