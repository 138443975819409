/*
 * @Author: gajani kangesan
 * @Date: 2022-08-03 11:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 13:12:07
 */
import React, { useContext, useEffect, useState } from "react";
import {
  InputButton,
  FormWrapper,
} from "../../../../ui-components/ui-elements/form/index";

import {
  vehicleCommentHistoryAPI,
  previousOwnerInformationWithPagingAPI,
  getLatestLicenseAPI,
  getAllTask,
  licenceFeeAPI,
  vehiclePaymentHistoryAPI,
  vehicleUploadOriginalAPI,
  normalLicenceStartDateAPI,
  normalLicenceEndDatesAPI,
  issueRLValidationAPI
} from "../../../../../config/apiUrl.config";
import {
  PaddingLessUICard,
  TooltipWrapper,
  UICard,
  ViewDetailBlock,
  CircleLoaderElement
} from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  _size,
  _get,
} from "../../../../../helpers/common-helpers/lodash.wrappers";
import { PendingWorkflow } from "../../../counter-session/pages/includes/PendingWorkflow";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import {
  apiResponseStoringType,
  CoreContext,
} from "../../../core/context-providers/CoreContext.provider.js";
import {
  DataTable,
  actionBtnTypes,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import {
  ProvincesByIds,
  VehicleStatus,
  CurrentLicenceStatus,
} from "../../../../../config/enum.config";
import { getCodeName, thousandSeparator, numberThousandSeperator, fromObjectToQueryString } from "../../../../../helpers/common-helpers/common.helpers";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import {
  calculateLicenceFeeAPI, DMSMediatorApi, vetCompaniesListAPI,
  vftCompanyListAPI,
  vrpCompanyListAPI,
  insuranceAPI,
  vrpAPI,
  insuranceListAPI,
  vftCertificateAPI,
  vetAPI
} from "../../../../../config/apiUrl.config";
import { RowNo, DownloadUI } from "./../../../../ui-components/ui-elements/documents-uploader/documents-uploader-includes/documents-uploader-includes";
import { PaymentHistory } from "../../../counter-session/pages/includes/PaymentHistory";
import { isEmpty } from "lodash";
import { Base64 } from "js-base64";
const ViewVehicleInfo = (props) => {
  const searchFormKey = "vehicle_dealer_search_form";
  const formKey = "vehicleLicence";

  const pendingWorkflowFormKey = "pending_workflow_key";
  const labelClassName = "label-left-align"
  const [coreState, coreAction] = useContext(CoreContext);
  const [formState, formAction] = useContext(FormContext);
  const [documentvalid, setDocumentValid] = useState('');
  const vehicle_id = _get(props, "row.vehicle_no", null);

  const isDownload = true;
  const isDelete = true;
  const tableHeaderList = [

    {
      displayName: "Year", key: "year",
      onPrintFn: (rawData) => {
        return !isEmpty(rawData.valid_to) ? dateObjectToString(rawData.valid_to, "YYYY") : "";
      },
      isSort: false
    },
    {
      displayName: "Lic. Type",
      key: "lic_type",
      isSort: false,
    },
    { displayName: "Lic. No. / Rec. No.", key: "lic_no_rec_no", isSort: false },
    {
      displayName: "Validity Period (From - To)",
      key: "validity_period",
      onPrintFn: (rawData) => {
        return dateObjectToString(rawData.valid_from, "dd/mm/YY") + "-" + dateObjectToString(rawData.valid_to, "dd/mm/YY");

      },
      isSort: false,
    },
    { displayName: "Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fee).toFixed(2)) },
    { displayName: "Fine (LKR)", key: "fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fine).toFixed(2)) },
    {
      displayName: "Additions (LKR)",
      key: "additions",
      isSort: false,
      isRightAlign: true,
      onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.additions).toFixed(2))
    },
    {
      displayName: "Service Charge (LKR)",
      key: "service_charge",
      isSort: false,
      isRightAlign: true,
      onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.service_charge).toFixed(2))

    },
    {
      displayName: "Deductions (LKR)",
      key: "deductions",
      isRightAlign: true,
      onPrintFn: (rawData) => "(" + numberThousandSeperator(Math.abs((Number(rawData.deductions))).toFixed(2)) + ")",
      isSort: false,
    },
    {
      displayName: "Total (LKR)",
      key: "subtotal",
      isSort: false,
      isRightAlign: true,
      onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.total).toFixed(2))
    },


    {
      displayName: "User's Name",
      key: "username",
      onPrintFn: (rawData) => rawData.user_name + "(" + rawData.created_role + ")",
      isSort: false,
    },
    {
      displayName: "Location",
      key: "location",
      onPrintFn: (rawData) => rawData.province_prefix + "(" + rawData.service_name + ")",
      isSort: false,
    },
    {
      displayName: "Tx. Type",
      key: "tx_type",
      isSort: false,
    },
    {
      displayName: "Tx. Date & Time",
      key: "tx_date_time",
      isSort: false,
      onPrintFn: (rawData) => {
        return dateObjectToString(rawData.tx_date_time, "dd/mm/YY HH:MM");
      },
      isSort: false,
    },
    {
      displayName: "Tx. Status",
      key: "status",
      isSort: false,
      onPrintFn: (rawData) => {
        return rawData.status === "OPN" ? "Success" : (rawData.status === "REV" ? "Reversed" : (rawData.status === "CAN" ? "Cancelled" : ""));
      },
    },
  ]

  const [getState, setState] = useState({
    showPendingWorkflow: false,
    pendingWorkflows: [],
  });
  const [getLatestLicenceState, setLatestLicenceState] = useState(null);
  const download = async (fileUrl, fileName, props) => {
    var a = document.createElement("a");
    a.href = await fileUrl;
    a.setAttribute("download", fileName);
    a.click();
    props.onClosePopupFn();
  }
  const DownloadBtn = (props) => {

    return (
      <InputButton
        startIcon={"fas fa-download"}
        btnText={"Yes"}
        onClickBtnFn={() => download(`${DMSMediatorApi.url}/ref-no/${props.row.document_reference}`, props.row.file_name, props)}
      />
    )
  }

  const getVehicleData = () => {
    callApi(`${getLatestLicenseAPI.url}/${_get(props, "row.vehicle_no", null)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('vehicle', "data", "", { data: result.data.data["vehicleData"] });
        }
      });
  }

  const getActionTypesList = () => {
    const list = [];

    if (isDownload) {
      list.push({
        actionType: actionBtnTypes.other,
        actionBtn: {
          tooltip: 'Download',
          icon: 'fas fa-download',
        },
        modelContentElement: DownloadUI,
        dialogPopupProps: {
          isFullScreen: false,
          isFullWidth: false,
          dialogTitle: "Yes",
          closeButton: {
            btnText: "No",
            startIcon: "far fa-times-circle",
          },
          isCustomButton: true,
          customButtonComponent: DownloadBtn,
        },
      });
    }




    return list;
  };
  const [getProvinceDetail, setProvinceDetail] = useState({
    "provinceData": null,
  });


  useEffect(() => {
    formAction.initFromFn({ "licence_charges_form": { _onLoad: true } });
    formAction.changeInputFn('vehicle', "isTempLicence", "", false)
    formAction.changeInputFn('vehicle', "documentRequest", "", null)
    if (_get(props, "fromParent.uiType", null) === "RECONCILE_VIEW_VEHICLE") {
      getVehicleData()
    }


    coreAction
      .sendRequestFn(
        `${getLatestLicenseAPI.url}/${_get(props, "row.vehicle_no", null)}`
      )
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: getLatestLicenseAPI.key,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {

          setLatestLicenceState(result.data.data);

        }
      });

    // getProvinceData(_get(formState, "vehicle.data.data.province_id"),_get(formState, "vehicle.data.data.document_validations.vft"),_get(formState, "vehicle.data.data.document_validations.vet"));



  }, []);

  useEffect(() => {
    if (_get(formState, `vehicle.data.data.current_license_type.code`, null) === "CA") {
      getNormalLicenceFee(0, false, false, _get(formState, "work_flow_record.data.carnet_licence_start_at", null), _get(formState, "work_flow_record.data.carnet_licence_end_at", null), false, _get(formState, `vehicle.data.data.current_license_type.code`, null))
    } else {
      getRelatedData(false, _get(formState, `vehicle.data.data.current_license_type.code`, null))
    }
  }, [])

  const issueValid = (rate_range_detail, endDate) => {

    const queryObj = {
      vehicle_number: _get(props, "row.vehicle_no", null),
      eligible: _get(formState,"vehicle-meta-data.data.eligible",false)===true ? true : false,
      requested_licence_type: _get(formState, `vehicle.data.data.current_license_type.code`, null),
      requested_licence_mode: "FULL",
      requested_licence_end_date: endDate ? dateObjectToString(endDate, "YY-mm-dd"):null,
      documents_validated: true,
      is_reissuance: false,
      rate_range_detail_id: rate_range_detail,

    };

    callApi(`${issueRLValidationAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('issue-RL-Validation', "data", "", result.data.data)

        }

        formAction.changeInputFn("issue-RL-Validation", "_onLoad", "", false);
      })

  }
  const getNormalLicenceFee = (licenceEndtype = 0, isReIssuance = false, startDate = null, endDate = null, temp_request = false, licence_type = null, validity_period = 0, charging_method = "ANN") => {

    let tempReq = temp_request;
    if (temp_request) {
      tempReq = {
        runnable: _get(formState, "vehicle.documentRequest.run", 0) === 1 ? true : false,
        validity_period: _get(formState, "province.provinceData.temp_licence_validity_period", 0),
        charging_method: _get(formState, "province.provinceData.temp_licence_charging_method", 'ANN')
      }

    }

    const queryObj = {
      vehicle_no: _get(props, "row.vehicle_no", null),
      licence_end_type: licenceEndtype,
      licence_period_start_date: startDate ? dateObjectToString(startDate, "YY-mm-dd") : null,
      licence_period_end_date: endDate ? dateObjectToString(endDate, "YY-mm-dd") : null,
      is_reissuance: isReIssuance,
      temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
      requested_licence_type: licence_type
    };

    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totArrearsAddition = 0;
          let totArrearsDeduction = 0;
          let totAddition = 0;
          let totdeduction = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;

          const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

          _get(result, "data.addition_deduction_summary", []).forEach(value => {
            if (value.charge_effect_code === "DED") {
              totdeduction += value.total

            }
            if (value.charge_effect_code === "ADD") {
              totAddition += value.total

            }

          });
          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
            totArrearsAddition += value.addition_total
            totArrearsDeduction += value.deduction_total
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            totAddition + _get(result, "data.additions.current_licence", 0) -
            (totdeduction + _get(result, "data.deductions.current_licence", 0)))
            + serviceCharge;

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          const rate_range_detail_id = _get(result, "data.current_licences[0].rate_range_detail_id", null)
          if (isEmpty(_get(result, "data.arrears", []))) {
            formAction.changeInputFn("licence_charges_form", "allArrears", "", []);
          } else {
            formAction.changeInputFn("licence_charges_form", "allArrears", "", result.data.arrears);
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "rateRange", "", rate_range_detail_id);
          issueValid(rate_range_detail_id, endDate)
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "allArrears", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
          formAction.changeInputFn("licence_charges_form", "rateRange", "", null);
          issueValid(null, endDate)
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);

      });
  };




  // const getVehicleStatusCode = () => {
  //   let vehiclestatusCode = 'INE';
  //   vehiclestatusCode = _get(formState, `vehicle.data.data.vehicle_status`, '');
  //   if (
  //     (_get(formState, `vehicle.data.data.tare_weight`, 0) === null || _get(formState, `vehicle.data.data.tare_weight`, 0) === 0 || _get(formState, `vehicle.data.data.tare_weight`, 0) === '0.00')
  //     &&
  //     (_get(formState, `vehicle.data.data.gross_weight`, 0) === null || _get(formState, `vehicle.data.data.gross_weight`, 0) === 0 || _get(formState, `vehicle.data.data.gross_weight`, 0) === '0.00')
  //   ) {
  //     vehiclestatusCode = "INE";
  //   }

  //   if (_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length > 0) {
  //     vehiclestatusCode = "INE";
  //   }

  //   if ((_get(formState, `vehicle.data.data.fuel_type.name`,) === 'NOT STATED' && _get(formState, `vehicle.data.data.vehicle_class.prefix`,) !== 'TR') || _get(formState, `vehicle.data.data.fuel_type`,) === null || _get(formState, `vehicle.data.data.fuel_type`,) === "") {
  //     vehiclestatusCode = "INE";
  //   }

  //   return vehiclestatusCode;
  // };

  const getArrearsGrandTotal = (records) => {
    let total = 0
    if (!records.length) {
      return total
    }

    records.forEach(element => {
      total += element.fee + element.fine
    });

    return total
  }



  const createListArrayfromcommaSeperatedString = (string) => {
    if (string?.length === 0) {
      return "-";
    }
    let rawArray = string?.split(",");
    // remove if empty
    let processedArray = rawArray?.filter((item) => item.trim().length != 0);
    const listItems = processedArray?.map((item) => <div>{item != 'null' ? item : '-'}</div>);

    return listItems;
  };


  const getRelatedData = (temp_request = false, licence_type) => {
    let tempReq = temp_request;
    if (temp_request) {
      tempReq = {
        runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
        validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
        charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
      }

    }
    else {
      tempReq = null
    }
    const queryObj = {
      vehicle_no: _get(props, "row.vehicle_no", null),
      is_reissuance: false,
      licence_period_start_date: null,
      licence_period_end_date: null,
      is_use_start_date: false,
      temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
      requested_licence_type: licence_type
    };

    const startDatePromise = sendAPIRequest(`${normalLicenceStartDateAPI.url}?${fromObjectToQueryString(queryObj)}`);
    const endDatesPromise = sendAPIRequest(`${normalLicenceEndDatesAPI.url}?${fromObjectToQueryString(queryObj)}`);

    Promise.all([startDatePromise, endDatesPromise])
      .then(result => {
        const endDates = result[1]["data"].map(value => {
          return {
            ...value,
            requested_licence_end_date: value.value,
            value: dateObjectToString(value.value, "dd/mm/YY")
          };
        });
        formAction.changeInputFn("licence-validity-period", "startDate", "", new Date(result[0]["data"]));
        // formAction.changeInputFn("licence-validity-period", "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));         
        formAction.changeInputFn("licence-validity-period", "firstEnddDate", "", endDates[0]);
        formAction.changeInputFn("licence-validity-period", "endDatesData", "", endDates);
        formAction.changeInputFn("licence-validity-period", "endDate", "", !isEmpty(endDates) ? endDates[0].id : '0');
        formAction.changeInputFn("licence-validity-period", "_onLoad", "", false);
        getNormalLicenceFee((!isEmpty(endDates) ? endDates[0].id : '0'), false, new Date(result[0]["data"]), (isEmpty(endDates) ? null : endDates[0].requested_licence_end_date), false, licence_type)

      })
      .catch((error) => {
        formAction.changeInputFn("licence-validity-period", "_onLoad", "", false);
      });

  };
  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result.data);
        })
    });
  };
  return (
    <div className="normalLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper"> <div className="col-md-12">
          <strong>Vehicle ID: </strong>
          <span>
            {_get(props, "row.vehicle_no", null)}
          </span>
        </div></div>
        <div className="statusWrapper ">
          <ul className="statusUL">
            <FormWrapper
              elementStyle="btnFormWrapper"
              setGroupName={pendingWorkflowFormKey}
              apiUrl={`${getAllTask.url}?vehicle_id=${_get(props, "row.vehicle_no", null)}&status=PEN&&assigned_user_id=notnull&sort_by=created_at|desc`}
              onRebuildResponseFn={(response) => {
                setState({ ...getState, pendingWorkflows: response });

                return {
                  workflows: response,
                  "_onLoad": false,
                };
              }}
            >
              <li className="setBorder">
                <InputButton
                  btnName="pendingWorkflow"
                  isBtnDisabled={
                    _get(formState, `${pendingWorkflowFormKey}.workflows`, []).length === 0
                  }
                  btnText={"Pending Workflows"}
                  onClickBtnFn={() =>
                    setState({
                      ...getState,
                      showPendingWorkflow: true,
                    })
                  }
                />
              </li>
            </FormWrapper>

            <li className="setBorder">
              <TooltipWrapper description={"Current Vehicle Status"}>
                <span className={`${_get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")}`}>
                  {getCodeName(VehicleStatus, _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code",""))}
                </span>
              </TooltipWrapper>
            </li>

            <li className="setBorder">
              <TooltipWrapper description={"Current Licence Status"}>
                <span className={`licence-${_get(getLatestLicenceState, `current_licence_status`, 'NL')}`}>
                  {_get(formState, `vehicle.data.data.current_license_type.name`, "-") === "Carnet Licence" ?
                    (_get(formState, `vehicle.data.data`, null) ?
                      (_get(getLatestLicenceState, `current_licence_status`, 'NL') === 'RE' ? 'Valid' : getCodeName(CurrentLicenceStatus, _get(getLatestLicenceState, `current_licence_status`, 'NL')))
                      : null) :
                    (_get(formState, `vehicle.data.data`, "-") ? getCodeName(CurrentLicenceStatus, _get(getLatestLicenceState, `current_licence_status`, 'NL')) : null)}
                </span>
              </TooltipWrapper>
            </li>
            {(
              _get(getLatestLicenceState, `status`, null) === 'OPN' && _get(getLatestLicenceState, `print_count`, null) === 0
            ) ? (
              <li className="setBorder">
                <TooltipWrapper description={"Printing Status"}>
                  <span>Print Pending</span>
                </TooltipWrapper>
              </li>) : null}
            <li className="setBorder">
              <TooltipWrapper description={"Current License Type"}>
                <span>
                  {_get(
                    formState,
                    "vehicle.data.data.current_license_type.name",
                    "-"
                  )}
                </span>
              </TooltipWrapper>
            </li>
            {_get(
              formState,
              "vehicle.data.data.current_license_type.prefix",
              "-"
            ) === "TMP" ? (
              <li className="setBorder">
                <TooltipWrapper description={"Current Licence Issue Mode"}>
                  <span>Temporary Licence</span>
                </TooltipWrapper>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <div className="col-md-12 defaultPaddingTop floatLeft">
        <div className="fullWidthDiv">
          <div className="col-md-6 floatLeft">
            <PaddingLessUICard cardHeading="Vehicle Information">
              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Chassis :"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.chassis_no", ""), true
                    )}
                    labelCol={6}
                  />
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Manufactured Year:"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.yom", "")
                    )}
                    labelCol={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Engine:"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.engine_no", ""), true
                    )}
                    labelCol={6}
                  />
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Cylinder Capacity:"}
                    value={getLabelValue(
                      _get(
                        formState,
                        "vehicle.data.data.cylinder_capacity",
                        ""
                      )
                    )}
                    labelCol={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"1st Registration Date:"}
                    value={dateObjectToString(
                      new Date(
                        _get(
                          formState,
                          "vehicle.data.data.first_reg_date",
                          new Date()
                        )
                      ),
                      "dd/mm/YY"
                    )}
                    labelCol={6}
                  />
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Tire Size (cm):"}
                    value={(_get(formState, `vehicle.data.data.tyre_size`, "-") == null || _get(formState, `vehicle.data.data.tyre_size`, "-") === "NaN") ?
                      "-" : getLabelValue(_get(formState, `vehicle.data.data.tyre_size`, ""))}
                    labelCol={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Tare Weight(Kg):"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.tare_weight", "")
                    )}
                    labelCol={6}
                  />
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Wheel Base:"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.wheel_base", "")
                    )}
                    labelCol={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Gross Weight(Kg):"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.gross_weight", "")
                    )}
                    labelCol={6}
                  />
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Type of Body:"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.type_of_body", "")
                    )}
                    labelCol={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Seats(Exp Driver): "}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.no_of_seats", "")
                    )}
                    labelCol={6}
                  />
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Make:"}
                    value={getLabelValue(
                      _get(formState, "vehicle.data.data.make", "")
                    )}
                    labelCol={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Vehicle Condition:"}
                    value={getCodeName(VehicleStatus, _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code",""))}
                    labelCol={6}
                  />
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Model:"}
                    value={_get(formState, "vehicle.data.data.model", "")}
                    labelCol={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="defaultHalfMarginBottom">
                    <ViewDetailBlock
                      labelWrapperStyle={labelClassName}
                      label={"Fuel Type:"}
                      value={_get(
                        formState,
                        "vehicle.data.data.fuel_type.name",
                        ""
                      )}
                      labelCol={6}
                    />
                  </div>
                  <div className="defaultHalfMarginBottom">
                    <ViewDetailBlock
                      labelWrapperStyle={labelClassName}
                      label={"Class:"}
                      value={_get(
                        formState,
                        "vehicle.data.data.vehicle_class.name",
                        ""
                      )}
                      labelCol={6}
                    />
                  </div>
                  <div className="defaultHalfMarginBottom">
                    <ViewDetailBlock
                      labelWrapperStyle={labelClassName}
                      label={"Province:"}
                      value={getCodeName(
                        ProvincesByIds,
                        _get(formState, "vehicle.data.data.province_id", "")
                      )}
                      labelCol={6}
                    />
                  </div>
                </div>
                <div className="col-md-6 defaultHalfMarginBottom">
                  <ViewDetailBlock
                    labelWrapperStyle={labelClassName}
                    label={"Colors:"}
                    value={createListArrayfromcommaSeperatedString(
                      _get(formState, "vehicle.data.data.colors", "")
                    )}
                    labelCol={6}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 defaultHalfMarginBottom"></div>
              </div>
            </PaddingLessUICard>

            <PaddingLessUICard cardHeading="Previous Owner Information">
              <DataTable
                elementWrapperStyle={"scrolTable"}
                apiUrl={`${previousOwnerInformationWithPagingAPI.url}/${_get(props, "row.vehicle_no", null)}?sort_by=transferred_on|desc`}
                dataTableKey={previousOwnerInformationWithPagingAPI.key}
                tableHeaderList={[
                  {
                    displayName: "Name", key: "name", isSort: false,
                    onPrintFn: (rawData) => getLabelValue(rawData.name, true)

                  },
                  {
                    displayName: "Identification Number",
                    key: "nic",
                    isSort: false,
                    onPrintFn: (rawData) => getLabelValue(rawData.nic, true)
                  },
                  {
                    displayName: "Mobile Number",
                    key: "contact_number",
                    isSort: false,
                    onPrintFn: (rawData) => rawData.contact_number

                  },
                  {
                    displayName: "Address",
                    key: "address",
                    onPrintFn: (rawData) => getLabelValue(rawData.address_line_1, true) + ", " + getLabelValue(rawData.address_line_2, true) +
                      ", " + rawData.city,
                    isSort: false,
                  },
                  {
                    displayName: "Province",
                    key: "province",
                    onPrintFn: (rawData) =>
                      getCodeName(ProvincesByIds, rawData.province),
                    isSort: false,
                  },
                  {
                    displayName: "Transfer Date",
                    key: "transferred_on",
                    isSort: false,
                    onPrintFn: (rawData) =>
                      rawData.transferred_on
                        ? dateObjectToString(rawData.transferred_on, "dd/mm/YY")
                        : null,
                    isSort: false,
                  },
                ]}
                isSetTableFooter={false}
                isSetTableHeader={true}
                isSetAction={false}
              />
            </PaddingLessUICard>

            <PaddingLessUICard
              elementStyle={"defaultMarginTop"}
              cardHeading="Uploaded Documents"
            >
              <DataTable
                apiUrl={`${vehicleUploadOriginalAPI.url}/${vehicle_id}?sort_by=created_at|asc`}
                dataTableKey={`${searchFormKey}_dataTableKey`}
                isSetSearchFrom={false}
                tableHeaderList={[
                  { displayName: "No.", key: "__no__", isSort: false },
                  {
                    displayName: "Uploaded Date & Time", key: "created_at", isSort: false, onPrintFn: (rawData) => {
                      return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM");
                    }
                  },
                  { displayName: "Uploaded by", key: "created_by_name", isSort: false },
                  { displayName: "Document Description", key: "description", isSort: false },
                ]}

                specificComponents={{
                  "__no__": RowNo
                }}

                isSetTableFooter={true}
                isSetTableHeader={false}
                isSetAction={isDownload}
                actionTypeList={getActionTypesList()}
              />
            </PaddingLessUICard>
            <PaddingLessUICard
              cardHeading="Document Validation"
              elementStyle={"defaultMarginTop"}
            >

              {(_get(getLatestLicenceState, `documents`, []).length === 0) ?
                <UICard>
                  <div className="notificationContentWrapper">
                    <p>No record(s) found.</p>
                  </div>
                </UICard> :
                <table className={"fullWidthDiv document-valid"} cellPadding="10" >
                  <tr>
                    <th></th>
                    <th>Status</th>
                    <th>Company</th>
                    <th>Document Number</th>
                    <th>Issued Date</th>
                    <th>Expiry Date</th>

                  </tr>

                  {_get(getLatestLicenceState, `documents`, []).map((item) => (


                    (item.doc_type_code === "VET") && (
                      <tr>
                        <td>VET</td>
                        <td>{item.doc_status === "1" ? "Valid" : item.doc_status === "0" ? "Invalid" : "-"}</td>
                        <td>{isEmpty(item.doc_company_name) ? "-" : item.doc_company_name}</td>
                        <td>{item.doc_ref_no}</td>
                        <td>{isEmpty(item.issued_date) ? "-" : dateObjectToString(item.issued_date, "dd/mm/YY")}</td>
                        <td>{isEmpty(item.expiry_date) ? "-" : dateObjectToString(item.expiry_date, "dd/mm/YY")}</td>



                      </tr>)))}
                  {_get(getLatestLicenceState, `documents`, []).map((item) => (
                    (item.doc_type_code === "INS") && (
                      <tr>
                        <td>Insurance</td>
                        <td>{item.doc_status === "1" ? "Valid" : item.doc_status === "0" ? "Invalid" : "-"}</td>
                        <td>{isEmpty(item.doc_company_name) ? "-" : item.doc_company_name}</td>
                        <td>{item.doc_ref_no}</td>
                        <td>{isEmpty(item.issued_date) ? "-" : dateObjectToString(item.issued_date, "dd/mm/YY")}</td>
                        <td>{isEmpty(item.expiry_date) ? "-" : dateObjectToString(item.expiry_date, "dd/mm/YY")}</td>

                      </tr>)))}
                  {_get(getLatestLicenceState, `documents`, []).map((item) => (
                    (item.doc_type_code === "VFT") && (
                      <tr>
                        <td>VFT</td>
                        <td>{item.doc_status === "1" ? "Valid" : item.doc_status === "0" ? "Invalid" : "-"}</td>
                        <td>{isEmpty(item.doc_company_name) ? "-" : item.doc_company_name}</td>
                        <td>{item.doc_ref_no}</td>
                        <td>{isEmpty(item.issued_date) ? "-" : dateObjectToString(item.issued_date, "dd/mm/YY")}</td>
                        <td>{isEmpty(item.expiry_date) ? "-" : dateObjectToString(item.expiry_date, "dd/mm/YY")}</td>

                      </tr>)))}
                  {_get(getLatestLicenceState, `documents`, []).map((item) => (
                    (item.doc_type_code === "VRP") && (
                      <tr>
                        <td>VRP</td>
                        <td>{item.doc_status === "1" ? "Valid" : item.doc_status === "0" ? "Invalid" : "-"}</td>
                        <td>{isEmpty(item.doc_company_name) ? "-" : item.doc_company_name}</td>
                        <td>{item.doc_ref_no}</td>
                        <td>{isEmpty(item.issued_date) ? "-" : dateObjectToString(item.issued_date, "dd/mm/YY")}</td>
                        <td>{isEmpty(item.expiry_date) ? "-" : dateObjectToString(item.expiry_date, "dd/mm/YY")}</td>

                      </tr>)))}



                </table>
              }
            </PaddingLessUICard>
            <PaddingLessUICard
              cardHeading="Comments"
              elementStyle={"defaultMarginTop"}
            >
              <FormWrapper
                setGroupName="comment_history"
                apiUrl={`${vehicleCommentHistoryAPI.url}/${_get(props, "row.vehicle_no", null)}`}
                onRebuildResponseFn={(response) => {
                  return {
                    comments: response,
                    _onLoad: false,
                  };
                }}
              >
                {_size(_get(formState, `comment_history.comments`, [])) > 0 ? (
                  _get(formState, `comment_history.comments`, []).map(
                    (value, index) => {
                      return (
                        <UICard key={index}>
                          <div className="row">
                            <div className="notificationTimeWrapper col-md-3">
                              <p>
                                <strong>Commented at: </strong>
                              </p>
                              <p>
                                {dateObjectToString(value.created_date, "dd/mm/YY HH:MM")}
                              </p>
                            </div>
                            <div className="notificationTimeWrapper col-md-3">
                              <p>
                                <strong>Commented by: </strong>
                              </p>
                              <p>{(value.created_by + '(' + value.created_role_name + ')')}</p>
                            </div>
                            <div className="notificationTimeWrapper col-md-3">
                              <p>
                                <strong>Authority: </strong>
                              </p>
                              <p> {value.authority}</p>
                            </div>
                            <div className="notificationTimeWrapper col-md-3">
                              <p>
                                <strong>Province: </strong>
                              </p>
                              <p>{value.province}</p>
                            </div>
                          </div>

                          <UICard elementStyle="mt-2">
                            <div className="notificationContentWrapper">
                              <p>{value.comment}</p>
                            </div>
                          </UICard>
                        </UICard>
                      );
                    }
                  )
                ) : (
                  <UICard>
                    <div className="notificationContentWrapper">
                      <p>No Comments</p>
                    </div>
                  </UICard>
                )}
              </FormWrapper>
            </PaddingLessUICard>
          </div>

          <div className="col-md-6 floatRight">
            <div className="fullWidthDiv">
              <PaddingLessUICard cardHeading="Current Owner Information">
                <div className="row">
                  <div className="col-md-6 defaultHalfMarginBottom">
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Name:"}
                        value={getLabelValue(
                          _get(formState, "vehicle.data.data.owner_id.name", null), true)}
                        labelCol={6}
                      />
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Identification Number:"}
                        value={getLabelValue(_get(formState, "vehicle.data.data.owner_id.nic", null), true, true)}
                        labelCol={6}
                      />
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Mobile Number:"}
                        value={getLabelValue(
                          _get(formState, "vehicle.data.data.owner_id.contact_number", null))}
                        labelCol={6}
                      />
                    </div>


                  </div>
                  <div className="col-md-6 defaultHalfMarginBottom">
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Address Line 1:"}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.owner_id.address_line_1", null), true)}
                        labelCol={6}
                      />
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Address Line 2:"}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.owner_id.address_line_2", null), true)}
                        labelCol={6}
                      />
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"City:"}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.owner_id.city",
                            null
                          )
                        )}
                        labelCol={6}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 defaultHalfMarginBottom">
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Absolute Owner:"}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.absolute_owner_id.name", null), true)}
                        labelCol={6}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 defaultHalfMarginBottom">
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Address Line 1: "}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.absolute_owner_id.address_line_1", null), true)}
                        labelCol={6}
                      />
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Address Line 2: "}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.absolute_owner_id.address_line_1", null), true)}
                        labelCol={6}
                      />
                    </div>
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"City:"}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.absolute_owner_id.city",
                            null
                          )
                        )}
                        labelCol={6}
                      />
                    </div>

                  </div>
                  <div className="col-md-6 defaultHalfMarginBottom">
                    <div className="col-md-12 defaultHalfMarginBottom">
                      <ViewDetailBlock
                        labelWrapperStyle={labelClassName}
                        label={"Additional Information:"}
                        value={getLabelValue(
                          _get(
                            formState,
                            "vehicle.data.data.owner_id.additional_information",
                            null
                          )
                        )}
                        labelCol={6}
                      />
                    </div>
                  </div>
                </div>
              </PaddingLessUICard>

              <PaymentHistory
                apiUrl={`${vehiclePaymentHistoryAPI.url}/${vehicle_id}`}
                dataTableKey={vehiclePaymentHistoryAPI}
                tableHeaderList={tableHeaderList}
                licenceType={"vehicle-licence"}
                formKey={formKey}
                isReprint={false}
                isView={true}
                category={"VT"}
              />

              <PaddingLessUICard
                cardHeading="Licence Arrears (LKR)"
                elementStyle={"defaultMarginTop"}
              >
                {isEmpty(_get(formState, "licence_charges_form.allArrears", [])) ?
                  <UICard>
                    <div className="notificationContentWrapper">
                      <p>No record(s) found.</p>
                    </div>
                  </UICard> :
                  <div className="row">
                    <div className="col-12">
                      <DataTable
                        dataTableKey={licenceFeeAPI.key}
                        isSetTableHeader={false}
                        tableHeaderList={[
                          { displayName: "From", key: "start_date", isSort: false, onPrintFn: (rawData) => dateObjectToString(rawData.start_date, "dd/mm/YY") },
                          { displayName: "To", key: "end_date", isSort: false, onPrintFn: (rawData) => dateObjectToString(rawData.end_date, "dd/mm/YY") },
                          { displayName: "Total Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fee).toFixed(2)) },
                          { displayName: "Total Fine (LKR)", key: "fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fine).toFixed(2)) },
                          { displayName: "Additions (LKR)", key: "addition", default: "-", isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.addition_total).toFixed(2)), isSort: false },
                          { displayName: "Deductions (LKR)", key: "deduction", default: "-", isRightAlign: true, onPrintFn: (rawData) => "(" + numberThousandSeperator(Number(rawData.deduction_total).toFixed(2)) + ")", isSort: false },
                          { displayName: "Total Arrears (LKR)", key: "arrears", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fee + rawData.fine + rawData.addition_total - rawData.deduction_total).toFixed(2)) },
                        ]}

                        tableBodyList={_get(formState, "licence_charges_form.allArrears", [])}
                        isSetTableFooter={false}
                        isSetAction={false}
                      />
                      <div className="mt-2 floatRight text-right pr-1">
                        <strong>Grand Total (LKR):</strong> {thousandSeparator(getArrearsGrandTotal(_get(formState, `licence_charges_form.data.arrears`, [])), 2)}
                      </div>
                    </div>
                  </div>}
              </PaddingLessUICard>

              <PaddingLessUICard
                cardHeading="Licence Period"
                elementStyle={"defaultMarginTop"}
              >
                <div className="row">
                  <div className="col-md-6 defaultHalfMarginBottom">
                    <ViewDetailBlock
                      labelWrapperStyle={labelClassName}
                      label={"From:"}
                      value={_get(getLatestLicenceState, "licence_type", null) === "CA" ?
                        (_get(formState, "work_flow_record.data.carnet_licence_start_at", null)
                          ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", null), "dd/mm/YY") : "")
                        : (_get(formState, "licence-validity-period.startDate", null)
                          ? dateObjectToString(_get(formState, "licence-validity-period.startDate", null), "dd/mm/YY") : "")
                      }
                      labelCol={2}
                    />
                  </div>
                  <div className="col-md-6 defaultHalfMarginBottom">
                    <ViewDetailBlock
                      labelWrapperStyle={labelClassName}
                      label={"To:"}
                      value={_get(getLatestLicenceState, "licence_type", null) === "CA" ? (_get(formState, "work_flow_record.data.carnet_licence_end_at", null) ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_end_at", null), "dd/mm/YY") : "") : _get(formState, "licence-validity-period.firstEnddDate.requested_licence_end_date", null) ? dateObjectToString(_get(formState, "licence-validity-period.firstEnddDate.requested_licence_end_date", null), "dd/mm/YY") : ""} labelCol={2}

                    />
                  </div>
                </div>
              </PaddingLessUICard>
              {_get(formState, `issue-RL-Validation.data.calculationRequired`, false) === true &&
                <PaddingLessUICard cardHeading="Licence Amount"
                  elementStyle={"defaultMarginTop"}>
                  {
                    _get(formState, "licence_charges_form._onLoad", true) === true ? (
                      <div>
                        <br />
                        <center>
                          <CircleLoaderElement />
                        </center>
                      </div>
                    ) : (
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th>Fee (LKR)</th>
                          <td className="text-right">
                            {thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFee", 0), true)}
                          </td>
                        </tr>

                        <tr>
                          <th>Fine (LKR)</th>
                          <td className="text-right">
                            {thousandSeparator(_get(formState, "licence_charges_form.totCurrentLicenceFine", 0), true)}
                          </td>
                        </tr>

                        <tr>
                          <th>Arrears Fee (LKR)</th>
                          <td className="text-right">
                            {thousandSeparator(_get(formState, "licence_charges_form.totArrearsFee", 0), true)}
                          </td>
                        </tr>

                        <tr>
                          <th>Arrears Fine (LKR)</th>
                          <td className="text-right">
                            {thousandSeparator(_get(formState, "licence_charges_form.totArrearsFine", 0), true)}
                          </td>
                        </tr>

                        <tr>
                          <th>Additions (LKR)</th>
                          <td className="text-right">
                            {
                              thousandSeparator((_get(formState, "licence_charges_form.totAddition", 0)), true)
                            }
                          </td>
                        </tr>

                        <tr>
                          <th>Deductions (LKR)</th>
                          <td className="text-right">
                            {
                              '(' + thousandSeparator((_get(formState, "licence_charges_form.totdeduction", 0)), true) + ')'
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Service Charge (LKR)</th>
                          <td className="text-right">
                            {thousandSeparator(_get(formState, "licence_charges_form.serviceCharge", 0), true)}
                          </td>
                        </tr>

                        <tr>
                          <th></th>
                          <td className="text-right"></td>
                        </tr>

                        <tr>
                          <th>Total Amount (LKR)</th>
                          <td className="text-right">
                            <b>{thousandSeparator(_get(formState, "licence_charges_form.total", 0), true)}</b>
                          </td>
                        </tr>
                      </table>
                    )
                  }
                </PaddingLessUICard>}
            </div>
          </div>
        </div>
      </div>

      <PendingWorkflow
        getState={getState}
        setState={setState}
      />

    </div>
  );
};

export default ViewVehicleInfo;
