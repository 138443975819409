/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-27 12:52:11
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 14:26:03
 */
import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { checkUserINLocalStorage } from '../../helpers/common-helpers/manageStorage.helpers';
//import {isMobile} from "../../helpers/common-helpers/common.helpers";
import { AuthContext } from '../modules/core/context-providers/AuthContext.provider';
import { UIContext } from '../ui-components/context-providers/UIContext.provider';
import { isAuthorizedINGlobalState } from './helper/init.helpers';
import { _get } from "../../helpers/common-helpers/lodash.wrappers";
/**
 *
 * @param {String} routeKey
 * @param {String} type auth|guess
 * @returns
 */
const useKeycloak = (routeKey, type = 'auth') => {
  const history = useHistory();
  const [uiState, uiAction] = useContext(UIContext);
  
  const [initState, setState] = useState(false);
  const [authStatus, authAction] = useContext(AuthContext);

  useEffect(() => {
    uiAction.setCurrentRouteFn(routeKey);

    if (
      // authStatus.isAuthenticated === false ||
      authStatus.isAuthenticated === true
    ) {
      setState(true);
    } else {
      const localStoreData = checkUserINLocalStorage();
      if (localStoreData.status === true) {
        authAction.setTokensFn(localStoreData.result);
        setState(true);
        uiAction.initializeSocketConnection();
      } else {
        isAuthorizedINGlobalState(authStatus, authAction, (error, status) => {
          setState(true);
          uiAction.initializeSocketConnection();
        });
      }
    }
  }, []);

  useEffect(() => {
    if (uiState.socketConnection && !uiState.isJoinedRoom) {
      uiState.socketConnection.emit("joinRoom", _get(authStatus, "authUser.id", ""));

      // This below joinedRoom block is only intended for developing purpose. Please remove it on production
      uiState.socketConnection.on("joinedRoom", (room) => {
        console.log("Joined room................", room);
      });

      uiState.socketConnection.on("chatToClient", (msj) => {
        const pdmtIdGet = _get(authStatus, "authUser.role_category_code", "") !== "" && 
        (_get(authStatus, "authUser.role_category_code", "") === "SUP" ||
        _get(authStatus, "authUser.role_category_code", "") === "COM") ? _get(authStatus, "authUser.service_id", "") : _get(authStatus, "authUser.pdmtId.service.id", "")

        const isHighRole = _get(authStatus, "authUser.role_category_code", "") !== "" && 
        (_get(authStatus, "authUser.role_category_code", "") === "SUP" ||
        _get(authStatus, "authUser.role_category_code", "") === "COM") ? true : false

        const isInPdmtIds = msj.pdmt_service_ids.includes(pdmtIdGet)
        const isInRoleCatogoryIds = msj.role_categories.includes(_get(authStatus, "authUser.role_category_code", ""))
        const isInAuthorityServicesIds = msj.authority_service_ids.includes(_get(authStatus, "authUser.authorityId", ""))
        const isInUserIds = msj.user_ids.includes(_get(authStatus, "authUser.id", ""))

        console.log('TTTTTTTTTTTTTTvvvvvGGGGGGGGGG==', isInPdmtIds, isInRoleCatogoryIds, isInAuthorityServicesIds, isInUserIds, pdmtIdGet, isHighRole,
        _get(authStatus, "authUser.role_category_code", ""), _get(authStatus, "authUser.authorityId", ""), _get(authStatus, "authUser", ""));
        console.log('TTTTTTTTTTTTTTvvvvv==', msj);
        console.log('TTTTTTTTTTTTTTvvvvvauthh==', authStatus);
      //Add a delay for delegation change
        setTimeout(() => {
          uiAction.getNotificationCount({
            read: false,
            pdmt_service_id: _get(authStatus, "authUser.role_category_code", undefined) !== undefined && 
            (_get(authStatus, "authUser.role_category_code", undefined) === "SUP" || _get(authStatus, "authUser.role_category_code", undefined) === "COM") ? _get(authStatus, "authUser.service_id", undefined) : _get(authStatus, "authUser.pdmtId.service.id", undefined),
             user_id: _get(authStatus, "authUser.id", undefined),
            role_category_code: _get(authStatus, "authUser.role_category_code", undefined),
            current_role_category: _get(authStatus, "authUser.role_category_code", undefined),
          });  
        }, 6000);

        if(msj.application === 'internal') {
          if(msj.pdmt_service_ids.length > 0 && 
            msj.role_categories.length > 0 && 
            msj.authority_service_ids.length > 0 && 
            msj.user_ids.length > 0) {

              if(isInPdmtIds && isInRoleCatogoryIds && isInAuthorityServicesIds && isInUserIds) {
                uiAction.setFlashMessage({
                  status: true,
                  messageType: "info",
                  isNotificationMsj: true,
                  notificationRoute: history,
                  message: "You have 1 new notification"
                });
                
                console.log('TTTTTTTTTTTTTTvvvvv==11', msj);
                uiAction.incrementNotificationCountFn();

              } else {
                if(isHighRole && isInPdmtIds && isInRoleCatogoryIds && !isInAuthorityServicesIds && isInUserIds) {
                  uiAction.setFlashMessage({
                    status: true,
                    messageType: "info",
                    isNotificationMsj: true,
                    notificationRoute: history,
                    message: "You have 1 new notification"
                  });
                 
                  console.log('TTTTTTTTTTTTTTvvvvv==22', msj);
                  uiAction.incrementNotificationCountFn();

                }
              }
          } else if(msj.pdmt_service_ids.length === 0 && 
            msj.role_categories.length === 0 && 
            msj.authority_service_ids.length === 0 && 
            msj.user_ids.length > 0) {

              if(!isInPdmtIds && !isInRoleCatogoryIds && !isInAuthorityServicesIds && isInUserIds) {
                uiAction.setFlashMessage({
                  status: true,
                  messageType: "info",
                  isNotificationMsj: true,
                  notificationRoute: history,
                  message: "You have 1 new notification"
                });
               
                console.log('TTTTTTTTTTTTTTvvvvv==33', msj);
                uiAction.incrementNotificationCountFn();

              }
            } else if(msj.pdmt_service_ids.length > 0 && 
              msj.role_categories.length > 0 && 
              msj.authority_service_ids.length === 0 && 
              msj.user_ids.length === 0) {
                if(isInPdmtIds && isInRoleCatogoryIds && !isInAuthorityServicesIds && !isInUserIds) {
                  uiAction.setFlashMessage({
                    status: true,
                    messageType: "info",
                    isNotificationMsj: true,
                    notificationRoute: history,
                    message: "You have 1 new notification"
                  });
                
                  console.log('TTTTTTTTTTTTTTvvvvv==44', msj);
                  uiAction.incrementNotificationCountFn();

                }

            } else if(msj.pdmt_service_ids.length > 0 && 
              msj.role_categories.length > 0 && 
              msj.authority_service_ids.length > 0 && 
              msj.user_ids.length === 0) {

                if(isInPdmtIds && isInRoleCatogoryIds && isInAuthorityServicesIds && !isInUserIds) {
                  
                  uiAction.setFlashMessage({
                    status: true,
                    messageType: "info",
                    isNotificationMsj: true,
                    notificationRoute: history,
                    message: "You have 1 new notification"
                  });
                 
                  console.log('TTTTTTTTTTTTTTvvvvv==55', msj);
                  uiAction.incrementNotificationCountFn();

                } else {
                  if(isHighRole && isInPdmtIds && isInRoleCatogoryIds && !isInAuthorityServicesIds && !isInUserIds) {
                    uiAction.setFlashMessage({
                      status: true,
                      messageType: "info",
                      isNotificationMsj: true,
                      notificationRoute: history,
                      message: "You have 1 new notification"
                    });
                   
                    console.log('TTTTTTTTTTTTTTvvvvv==66', msj);
                    uiAction.incrementNotificationCountFn();

                  }
                }

            }
        }
        
        
      });

      uiAction.setJoinRoomStatus(true);
    }
  }, [uiState.socketConnection]);
  useEffect(() => {
    if (uiState.notificationCount === null && _get(authStatus, "authUser.id", undefined)) {
      setTimeout(() => {
      uiAction.getNotificationCount({
        read: false,
        pdmt_service_id: _get(authStatus, "authUser.role_category_code", undefined) !== undefined && 
        (_get(authStatus, "authUser.role_category_code", undefined) === "SUP" || _get(authStatus, "authUser.role_category_code", undefined) === "COM") ? _get(authStatus, "authUser.service_id", undefined) : _get(authStatus, "authUser.pdmtId.service.id", undefined),
         user_id: _get(authStatus, "authUser.id", undefined),
        role_category_code: _get(authStatus, "authUser.role_category_code", undefined),
        current_role_category: _get(authStatus, "authUser.role_category_code", undefined)
      });
    }, 6000);
    }
  }, [authStatus.authUser]);

  return [initState, authStatus.isAuthenticated];
};

export default useKeycloak;
