import React, { Fragment, useState, useRef, useContext } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { defaultTemplateType } from "../../../../config/template.config";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { _find, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";

// SplitButton options
const splitBtnVariants = {
    text: "text",
    outlined: "outlined",
    contained: "contained"
};

const splitBtnColors = {
    default: "default",
    inherit: "inherit",
    primary: "primary",
    secondary: "secondary"
};

const splitBtnSizes = {
    small: "small",
    medium: "medium",
    large: "large"
};

const SplitButton = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    btnSize = splitBtnSizes.small,
    btnColor = splitBtnColors.default,
    isFullWidth = false,
    isDisabled = false,
    variant = splitBtnVariants.outlined,
    formGroupName = "",
    inputStatePath = "",
    inputName = "",
    dataList = [],
}) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [formState, formAction] = useContext(FormContext);

    const handleMenuItemClick = (value) => {
        formAction.changeInputFn(formGroupName, inputName, inputStatePath, value);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const getSelectedValue = () => {
        const value = _get(formState, inputStatePath ? inputStatePath : `${formGroupName}.${inputName}`, "");

        if (!isEmptyValue(value)) {
            const obj = _find(dataList, (o) => o.key == value);

            if (obj)
                return obj.value;
        }

        return "";
    }

    return (
        <Fragment>
            <ButtonGroup
                variant={variant}
                color={btnColor}
                className={`defaultSplitBtnStyle ${uiType} ${elementStyle}`}
                fullWidth={isFullWidth}
                size={btnSize}
                ref={anchorRef}
                disabled={isDisabled}
            >
                <Button>{getSelectedValue()}</Button>
                <Button
                    onClick={handleToggle}
                >
                    <i className="mdi mdi-menu-down"></i>
                </Button>
            </ButtonGroup>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {dataList.map((value, index) => (
                                        <MenuItem
                                            key={index}
                                            selected={value["key"] === _get(formState, inputStatePath ? inputStatePath : `${formGroupName}.${inputName}`, "")}
                                            onClick={(event) => handleMenuItemClick(value["key"])}
                                        >
                                            {value["value"]}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    );
};

export default SplitButton;
