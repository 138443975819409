
/*
 * @Author: Chamith Jayathilaka 
 * @Date: 2022-08-29 13:59:59 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:26:44
 */
import React, { Fragment, useContext } from "react";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { CoreContext } from "../../../../core/context-providers/CoreContext.provider";
import { webLicencePrintListUpdateAPI, webLicencePrintListAPI, EnvelopLicencePrintListUpdateAPI, availableCounterListApi } from '../../../../../../config/apiUrl.config';
import { useLicencePrint, useEnvelopPrint } from '../../../../../hooks/common-hooks/usePrinter.hook';
import { AuthContext } from '../../../../core/context-providers/AuthContext.provider';
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import EnvelopePrinting from "./EnvelopePrinting";
import LicenceReceiptPrint from "../../../../payment/pages/includes/LicenceReceiptPrint";

const emptyFun = () => undefined;

const ConfirmationPopup = ({
  closePopupFn = emptyFun,
  closeConfirmationPopupFn = emptyFun,
  onClickSuccessFn = emptyFun,
  openPopupFn = emptyFun,
  uiType = "WEBLICENCE", //WEBLICENCE | ENVELOPE | RE_PRINT_ENVELOPE
  confirmationMsg = "Are you sure you want to Print?"
}) => {

  const getSubmitBtn = (uiType) => {
    switch (uiType) {
      case "WEBLICENCE": return weLicencePrintBtn();
      case "ENVELOPE": return envelopPrintBtn();
      case "RE_PRINT_ENVELOPE": return envelopRePrintBtn();
      default: return <h1>No match</h1>
    }
  }


  const weLicencePrintBtn = () => {
    return <PrintBtn
      btnText={"Yes"}
      elementWrapperStyle={"defaultHalfMarginLeftRight"}
      closePopupFn={closeConfirmationPopupFn}
      onClickSuccessFn={onClickSuccessFn}
      openPopupFn={openPopupFn}
      uiType={uiType} />
  }

  const envelopPrintBtn = () => {
    return <EnvelopPrintBtn
      btnText={"Yes"}
      elementWrapperStyle={"defaultHalfMarginLeftRight"}
      closePopupFn={closeConfirmationPopupFn}
      onClickSuccessFn={onClickSuccessFn}
      openPopupFn={openPopupFn}
      uiType={uiType} />
  }

  const envelopRePrintBtn = () => {
    return <EnvelopRePrintBtn
      btnText={"Yes"}
      elementWrapperStyle={"defaultHalfMarginLeftRight"}
      closePopupFn={closeConfirmationPopupFn}
      onClickSuccessFn={onClickSuccessFn}
      openPopupFn={openPopupFn}
      uiType={uiType} />
  }


  return (
    <div className="row">
      <div className="col-md-12">
        <strong>{confirmationMsg}</strong>
      </div>
      <div className="col-md-12 text-right defaultMarginTop">
        {getSubmitBtn(uiType)}
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="No"
          onClickBtnFn={closePopupFn}
          startIcon={"fas fa-times"}
        />
      </div>
    </div>
  );
}
const onpreview = (printFrame) => {
  let divsToPrint = document.getElementsByClassName(printFrame), n;
  let printContents = "";
  for (n = 0; n < divsToPrint.length; n++) {
    printContents += divsToPrint[n].innerHTML + "<br>";
  }
  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  // uiAction.setFlashMessage({
  //   status: true,
  //   message: "Receipt is successfully Printed .",
  //   messageType: "success"
  // });
  
  window.location.reload();
 
  document.body.innerHTML = originalContents;
  
  window.close()

}


const PrintBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [, coreAction] = useContext(CoreContext);
  const [authStatus] = useContext(AuthContext);
  let sessionForm = 'sessionDataForLicenceForm'
  const authority_id = _get(authStatus, "authUser.authorityId", "");
  const [, uiAction] = useContext(UIContext);
  const { bulkPrintFn } = useLicencePrint();
  console.log("weblicence", _get(formState, `web_licennce_print_form.data`, []))
  return (
    <Fragment>
      <InputButton
        btnText="Yes"
        startIcon={"far fa-save"}
        elementStyle="btnWrapper"
        onClickBtnFn={() => {
          let licences = _get(formState, `webLicencePrintListAPIKey`, []);
          let licencesIdArray = licences.map((item) => { return item.id });
          let paymentResponse = []

          coreAction
            .sendRequestFn(
              `${webLicencePrintListUpdateAPI.url}/${licencesIdArray}/print-list`
            )
            .isMultipart(false)
            .method("put")
            .body({
              serial_number: _get(formState, `${sessionForm}.next_serial`, null),
              counter_id: _get(formState, `${sessionForm}.counter_id`, null),
              counter_session_id: _get(formState, `${sessionForm}.counter_session_id`, null),
              authority_id: authority_id,
              counter_serial_number_range_id: _get(formState, `${sessionForm}.counter_serial_number_range_id`, null),
              counter_code: _get(formState, `${sessionForm}.counter_code`, null),
              authority_code: _get(authStatus, 'authUser.authorityCode', ""),
              province_code: _get(authStatus, 'authUser.pdmtId.code', ""),
            })
            .setLoader(false)
            .send((error) => {
              if (!error) {
                let licenceTemplateSubmit = _get(formState, `web_licennce_print_form.data`, []);
                paymentResponse.push(licenceTemplateSubmit)
                // if (licences.length >= 1) { //Bulk print response handling
                //   bulkPrintFn(coreAction, paymentResponse);
                // }
                onpreview("weblicence-print")
               coreAction.resetDataTable(webLicencePrintListAPI.key);
               props.onClickSuccessFn();
               formAction.changeInputFn('webLicencePrintListAPIKey', "ShouldRefreh", "", true);

                setTimeout(() => {
                  formAction.setCheckBoxValues('webLicencePrintListAPIKey', [])
                }, 100);

                callApi(`${availableCounterListApi.url}/${authority_id}/counters/${_get(formState, `${sessionForm}.counter_id`, null)}`)
                  .method("get")
                  .headers(true)
                  .body({})
                  .isMultipart(false)
                  .send((error, result) => {

                    if (!error) {
                      const nxt_serial = _get(result, "data.data.next_serial_number", "") == null ? "" : _get(result, "data.data.next_serial_number", "");
                      formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", nxt_serial);
                    }
                  });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: _get(error, `data.errors.details[0].message`, 'Something Went Wrong'),
                  messageType: "error",
                });
              }
            });

        }
        }
      />
       <div className='weblicence-visibility'>
        {_get(formState, `web_licennce_print_form.data.data.licences`, []).map((item) => (
          <div className="weblicence-print">
            <LicenceReceiptPrint dataList={item} />
          </div>
        ))}

      </div>

    </Fragment>


  );



};




const EnvelopPrintBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [, coreAction] = useContext(CoreContext);
  const [, uiAction] = useContext(UIContext);
  const { bulkEnvelopPrintFn } = useEnvelopPrint();
  return (
    <Fragment>
      <InputButton
        btnText="Yes"
        startIcon={"far fa-save"}
        elementStyle="btnWrapper"
        onClickBtnFn={() => {
          let licences = _get(formState, `webLicencePrintListAPIKeyPrintedEnvelope`, []);
          let licencesIdArray = licences.map((item) => { return item.id });
          let paymentResponse = []
          coreAction
            .sendRequestFn(
              `${EnvelopLicencePrintListUpdateAPI.url}/${licencesIdArray}/print-envelope-list`
            )
            .isMultipart(false)
            .method("put")
            .body({
            })
            .setLoader(false)
            .send((error, updatePrintResponse) => {
              if (!error) {

                let licenceTemplateSubmit = _get(formState, `envelope_print_form.data`, []);
                paymentResponse.push(licenceTemplateSubmit)
               // bulkEnvelopPrintFn(coreAction, paymentResponse);
                onpreview("envelope-print")
                props.onClickSuccessFn();
                formAction.changeInputFn('webLicencePrintListAPIKeyPrintedEnvelope', "ShouldRefreh", "", true);

                setTimeout(() => {
                  formAction.setCheckBoxValues('webLicencePrintListAPIKeyPrintedEnvelope', [])
                }, 100);


              }
              else {

                uiAction.setFlashMessage({
                  status: true,
                  message: _get(error, `data.errors.details[0].message`, 'Something Went Wrong'),
                  messageType: "error",
                });
              }
            });

        }
        }
      />
      <div className='envelope-visibility'>
        {_get(formState, `envelope_print_form.data.data.envelops`, []).map((item) => (
          <div className="envelope-print">
            <EnvelopePrinting dataList={item}/>
          </div>
        ))}

      </div>

    </Fragment>
  );


}

const EnvelopRePrintBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [, coreAction] = useContext(CoreContext);
  const { bulkEnvelopPrintFn } = useEnvelopPrint();
  const [, uiAction] = useContext(UIContext);
  return (
    <Fragment>
      <InputButton
        btnText="Yes"
        startIcon={"far fa-save"}
        elementStyle="btnWrapper"
        onClickBtnFn={() => {
          let paymentResponse = []
          let licenceTemplateSubmit = _get(formState, `envelope_print_form.data`, null);
          if (licenceTemplateSubmit) {
            paymentResponse.push(licenceTemplateSubmit)
            onpreview("envelope-print")
            // bulkEnvelopPrintFn(coreAction, paymentResponse);
          }
          props.onClickSuccessFn();
          formAction.changeInputFn('webLicencePrintListAPIKeyPrinted', "ShouldRefreh", "", true);
          setTimeout(() => {
            formAction.setCheckBoxValues('webLicencePrintListAPIKeyPrinted', [])
          }, 100);


        }
        }
      />
      <div className='envelope-visibility'>
        {_get(formState, `envelope_print_form.data.data.envelops`, []).map((item) => (
          <div className="envelope-print">
            <EnvelopePrinting dataList={item}/>
          </div>
        ))}

      </div>

    </Fragment>
  );


}

export { ConfirmationPopup }

