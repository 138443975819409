import React, { Fragment, useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { TabsWrapper, SubTab, tabsWrapperScrollButtons, tabsWrapperVariants } from "../../../ui-components/ui-elements/tab/Tabs";
import TransactionList from "./transaction-list/Transactionlist";
import PrintedLicenceTransactionList from "./printed-licence-transaction-list/PrintedLicenceTransactionList";
import { FormWrapper } from "../../../ui-components/ui-elements/form";
import { sessionCollectionsAPI } from "../../../../config/apiUrl.config";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { thousandSeparator } from "../../../../helpers/common-helpers/common.helpers";

const SessionCollection = (props) => {
    // For view session collection
let showCounterSummary =true;
if(props?.fromParent){
        showCounterSummary = props?.fromParent?.showCounterSummary
}
  const [activeStep, setActiveStep] = useState("transaction_list");
  const [formState, formAction] = useContext(FormContext);
  const location = useLocation();

  let sessionId=location?.state?.session_id;

  if(!location?.state?.session_id){
      sessionId =props.row?.id
  }

  const getValue = (value) => {
      if (value) {
          return thousandSeparator(value, true);
      } else {
          return "0.00";
      }
  };

  return (
    <Fragment>
    {showCounterSummary === true ? (
        <div className={"row"}>
              <div className={"col-md-12 col-sm-12"}>
                  <UICard
                      cardHeading={"Counter Summary"}
                  >
                      <FormWrapper
                        setGroupName={"counterSummary"}
                        apiUrl={`${sessionCollectionsAPI.url}/counter-summary/${sessionId}`}
                        onRebuildResponseFn={(response) => {
                            return {
                                counter_name: response.counter_name,
                                total_amount: response.total_amount,
                                total_cash_amount: response.total_cash_amount,
                                total_cheque_amount: response.total_cheque_amount,
                                total_credit_card_amount: response.total_credit_card_amount,
                                counter_start_at: response.counter_start_at,
                                _onLoad: false
                            };
                        }}
                      >
                          <div className="row">
                              <div className="col-md-4 col-sm-12">
                                  <strong>Counter Name: </strong>{_get(formState, "counterSummary.counter_name", "")}
                              </div>

                              <div className="col-md-4 col-sm-12">
                                  <strong>Total Cash (LKR): </strong>
                                  {getValue(_get(formState, "counterSummary.total_cash_amount", "0"))}
                              </div>

                              <div className="col-md-4 col-sm-12">
                                  <strong>Total Credit Card (LKR): </strong>
                                  {getValue(_get(formState, "counterSummary.total_credit_card_amount", "0"))}
                              </div>

                              <div className="col-md-4 col-sm-12">
                                  <strong>Total Amount (LKR): </strong>
                                  {getValue(_get(formState, "counterSummary.total_amount", "0"))}
                              </div>

                              <div className="col-md-4 col-sm-12">
                                  <strong>Total Cheque (LKR): </strong>
                                  {getValue(_get(formState, "counterSummary.total_cheque_amount", "0"))}
                              </div>
                          </div>
                      </FormWrapper>
                  </UICard>
              </div>
          </div>
        ) : null
    }
        <div className={"row mt-2"}>
            <div className={"col-md-12 col-sm-12"}>
                <TabsWrapper
                    isTabFullWidth={false}
                    active={activeStep}
                    variant={tabsWrapperVariants.scrollable}
                    scrollButtons={tabsWrapperScrollButtons.on}
                    onChangeFn={(event) => setActiveStep(event.value)}
                >

                    <SubTab tabid="transaction_list" header="Transaction Licences List" >
                        <TransactionList currentSessionStartAt={_get(formState, "counterSummary.counter_start_at", "-")} sessionId={sessionId} sessionRowData={props?.row}  />
                    </SubTab>

                    <SubTab tabid="printed_licence_transaction_list" header="Printed Licence Transactions List">
                        <PrintedLicenceTransactionList currentSessionStartAt={_get(formState, "counterSummary.counter_start_at", "-")} sessionId={sessionId} sessionRowData={props?.row}  />
                    </SubTab>
                </TabsWrapper>
            </div>
        </div>
    </Fragment>
  );
};

export default SessionCollection;
