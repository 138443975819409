/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-07 10:37:02 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-07 11:11:32
 */
import React, { useContext } from "react";

import Slider from "../../../../ui-components/ui-elements/slide/slider";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { vftCertificateAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { ProvincesMatcher } from "../../../../../config/enum.config"

const VFTCertificate = ({
    vftCertificateId = ""
}) => {
    const [formState] = useContext(FormContext);

    const getImageList = () => {
        const list =[]
        _get(formState, "vft_certificate_view.images", []).forEach((value) => {
            list.push({
                "src": `data:image;base64, ${value.image}`,
                "caption": value.description
            })
        });

        return list;
    };

    const getAddress = () => {
        let addr = ''
        if (_get(formState, "vft_certificate_view.service.addresses", []).length > 0) {
            addr = (_get(formState, "vft_certificate_view.service.addresses", []))[0].line1 + '\n' + (_get(formState, "vft_certificate_view.service.addresses", []))[0].line2 + '\n' + (_get(formState, "vft_certificate_view.service.addresses", []))[0].city  + '\n' + (_get(formState, "vft_certificate_view.vft_company_response.pdmt_id", null) ? ProvincesMatcher[_get(formState, "vft_certificate_view.vft_company_response.pdmt_id", null)]  :'')
        }
        return addr
    }


    const getContact = () => {
        let contact_number = ''
        if (_get(formState, "vft_certificate_view.service.contacts", []).length > 0) {
            contact_number = (_get(formState, "vft_certificate_view.service.contacts", []))[0].contact
        }
        return contact_number
    }

    return (
        <FormWrapper
            style={{ minWidth: '300px' }}
            setGroupName={"vft_certificate_view"}
            apiUrl={`${vftCertificateAPI.url}/${vftCertificateId}`}
            onRebuildResponseFn={(response) => {
                return {
                    ...response,
                    "_onLoad": false
                }
            }}
        >
            <div className={"row"} style={{ marginLeft: '8px', marginRight: '8px' ,  minWidth: '300px'}}>
                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-8  label-right-align" >
                        <strong>VFT Certificate Number: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {_get(formState, "vft_certificate_view.certificate_no", "")}
                    </div>
                </div>

                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-8  label-right-align" >
                        <strong>Issued Date: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {dateObjectToString(_get(formState, "vft_certificate_view.issued_date", ""), "dd/mm/YY")}
                    </div>
                </div>

                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-8  label-right-align" >
                        <strong>VFT Centre Name: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {_get(formState, "vft_certificate_view.service.name", "")}
                    </div>
                </div>

                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-8  label-right-align" >
                        <strong>Expiry Date: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {dateObjectToString(_get(formState, "vft_certificate_view.expiry_date", ""), "dd/mm/YY")}
                    </div>

                </div>

                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-8  label-right-align" >
                        <strong>VFT Centre Address: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {getAddress()}
                    </div>
                </div>

                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-8  label-right-align" >
                        <strong>VFT Centre Contact Number: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {getContact()}
                        {/* {_get(formState, "vft_certificate_view.contact_number", "")} */}
                    </div>
                </div>

                <br/>
                <br/>

                <div className="col-md-6 label-conatiner-center-wrapper">
                    <div className="col-md-8  label-right-align" >
                        <strong>VFT Inspection Report Number: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {_get(formState, "vft_certificate_view.inspection_report_no", "")}
                    </div>
                </div>

                <div className="col-md-6 label-conatiner-center-wrapper ">
                    <div className="col-md-8  label-right-align" >
                        <strong>Certifying Officer’s Name:         </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        {_get(formState, "vft_certificate_view.user_details.display_name", "")}
                    </div>

                </div>

                <div className="col-md-6 label-conatiner-center-wrapper ">

                </div>

                <div className="col-md-6 label-conatiner-center-wrapper ">

                </div>

                <div className="col-md-6 label-conatiner-center-wrapper mt-2">
                    <div className="col-md-8  label-right-align" >
                        <strong>Certifying Officer’s Signature: </strong>
                    </div>
                    <div className="col-md-4 value-left-align">
                        <img src={_get(formState, "vft_certificate_view.user_details.signature", null)} style={{ width: 70 }} />
                    </div>
                </div>
            </div>

            <div className={"row mt-4"} style={{ justifyContent: 'center' }}>
                <div className="col-md-8">
                    <Slider
                        imageList={getImageList()}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};

export { VFTCertificate };
