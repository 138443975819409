/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-07 12:09:06
 */

import React, { useContext,useEffect } from "react";
import {  DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { commentPostAPI } from "../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { CoreContext } from '../../../core/context-providers/CoreContext.provider';
import { divide } from "lodash";
import {
  FormWrapper,
} from "../../../../ui-components/ui-elements/form/index";

const CommentsHistory = () => {
  
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  //to Reset the table 
  useEffect(() => {
    if((_get(coreState, `dataTableResponses.${commentPostAPI.key}`, undefined))){
      coreAction.initDataTable(commentPostAPI.key);
    }
  }, []);
  

  return (
    <FormWrapper
    elementStyle={"counterCommentWrapper"}
  >
    <DataTable
        tableName="View Comment History"
        apiUrl={`${commentPostAPI.url}/${_get(formState,'vehicle_search_form.searchKey','')}`}
        dataTableKey={commentPostAPI.key}
        isSetSearchFrom={false}
        isSetAction={false}
        tableHeaderList={[
            { displayName: "Vehicle Comment", key: "comment", isSort: false },
            { displayName: "Commented at", key: "created_date", onPrintFn: (rawData)=>dateObjectToString(rawData.created_date, "dd/mm/YY HH:MM") , isSort: false ,cellStyle:{ 'white-space': 'nowrap'}},
            { displayName: "Commented by", key: "created_by", isSort: false  ,onPrintFn: (rawData)=>{
              return rawData.created_by + (rawData.created_role_name ? ' ('+rawData.created_role_name+ ')' :'')
            } },
            { displayName: "Authority", key: "authority", isSort: false  },
            { displayName: "Province", key: "province", isSort: false },
        ]}
    />
    </FormWrapper>
  );
};

export { CommentsHistory };
