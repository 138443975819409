/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Material FAB Wrapper, Button with Icon Wrapper
 * @Date: 2020-01-31 09:56:10
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-14 13:18:58
 */

import React,{memo,Fragment,useState,useContext} from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import {TooltipWrapper, Icon} from "../common/BaseElements";
import {defaultTemplateType, templateTypes,formCacheLevel} from "../../../../config/template.config";
import {useBasicButton,useAPIBaseButton} from "../../../hooks/common-hooks/useFromButtonState.hook";
import {DialogPopup} from "../modal/DialogPopup";
import {VariableToComponent} from "../common/BaseElements";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { getDealerVehicleDetailsAPI } from "../../../../config/apiUrl.config";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";

const emptyFun = (...para) => undefined;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Round Button Wrapper
 * --------------------------------------------
 */

// RoundButton color options
const roundBtnColors = {
    default: "default",
    inherit: "inherit",
    primary: "primary",
    secondary: "secondary"
};

// RoundButton size options
const roundBtnSizes = {
    small: "small",
    medium: "medium",
    large: "large"
};

// RoundButton variant options
const roundBtnVariants = {
    round: "round",
    extended: "extended"
};

const RoundButton = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    btnName = "",
    isBtnDisabled = false,
    btnColor = roundBtnColors.primary,
    btnSize = roundBtnSizes.small,
    iconClass = "",
    tooltip = "",
    variant = roundBtnVariants.round,
    onClickBtnFn = emptyFun
}) => {
    return (
        <TooltipWrapper description={tooltip}>
            <Fab
                color={btnColor}
                variant={variant}
                size={roundBtnSizes.small}
                className={`defaultRoundBtn ${uiType}RoundBtn ${elementStyle} ${btnSize}RoundBtn`}
                name={btnName}
                disabled={isBtnDisabled}
                onClick={(event) => onClickBtnFn({ name: btnName, eventInfo: event })}
            >
                <Icon elementStyle={`defaultRoundBtnIcon`} iconClass={iconClass}/>
            </Fab>
        </TooltipWrapper>
    )
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Round Button Wrapper
 * --------------------------------------------
 */

RoundButton.propTypes = {
  /** UI type */
  uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
  /** Button element css class */
  elementStyle: PropTypes.string,
  /** Button name */
  btnName: PropTypes.string,
  /** Is button disable or not */
  isBtnDisabled: PropTypes.bool,
  /** Button color */
  btnColor: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  /** Button size */
  btnSize: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Icon class */
  iconClass: PropTypes.string,
  /** Toot tip description */
  tooltip: PropTypes.string,
  /** Button variant */
  variant: PropTypes.oneOf(['round', 'extended']),
  /** Onclick button function */
  onClickBtnFn: PropTypes.func
};




const RoundButtonWithPopups = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    btnName = "",
    isBtnDisabled = false,
    btnColor = roundBtnColors.primary,
    btnSize = roundBtnSizes.small,
    iconClass = "",
    tooltip = "",
    variant = roundBtnVariants.round,
    dialogProps={},
    modelComponent=null,
    propsToModel={},
    onClickBtnFn = emptyFun
}) => {
    const [getModelStatus,setModelStatus]=useState(false);
    return (
        <Fragment>
            <RoundButton
                uiType={uiType}
                elementStyle={elementStyle}
                btnName={btnName}
                isBtnDisabled={isBtnDisabled}
                btnColor={btnColor}
                btnSize={btnSize}
                iconClass={iconClass}
                tooltip={tooltip}
                variant={variant}
                onClickBtnFn={()=>{
                    setModelStatus(true)
                    onClickBtnFn();
                }}
            />
            <DialogPopup              
              isOpen={getModelStatus}
              onClosePopupFn={()=>setModelStatus(false)}  
              {...dialogProps}              
            >
                {
                    (modelComponent!== null)?(
                        <VariableToComponent component={modelComponent} props={propsToModel}/>
                    ):(null)
                }
            </DialogPopup>
        </Fragment>
    );
}



//----------------RoundButton---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Input Button Wrapper
 * --------------------------------------------
 */

// InputButton color options
const inputBtnColors = {
    default: "default",
    inherit: "inherit",
    primary: "primary",
    secondary: "secondary"
};

// InputButton size options
const inputBtnSizes = {
    small: "small",
    medium: "medium",
    large: "large"
};

// InputButton variant options
const inputBtnVariants = {
    text: "text",
    outlined: "outlined",
    contained: "contained"
};

const InputButton = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    btnText = "",
    btnName = "",
    btnSize = inputBtnSizes.small,
    btnColor = inputBtnColors.primary,
    isFullWidth = false,
    isBtnDisabled = false,
    startIcon = null,
    endIcon = null,
    variant = inputBtnVariants.contained,
    tooltip = "",
    overrideTextToIcon=null,
    onClickBtnFn = emptyFun
}) => {
    return (
        <span className={`defaultInputBtnWrapper ${uiType}InputBtnWrapper ${elementWrapperStyle}`}>
            <TooltipWrapper description={tooltip}>
                <Button
                    variant={variant}
                    color={btnColor}
                    size={btnSize}
                    className={`defaultInputBtnStyle ${uiType}InputBtnStyle ${elementStyle}`}
                    name={btnName}
                    fullWidth={isFullWidth}
                    disabled={isBtnDisabled}
                    onClick={(event) => onClickBtnFn({ name: btnName, eventInfo: event })}
                    startIcon={(startIcon != null) ? (<Icon iconClass={startIcon}/>) : null}
                    endIcon={(endIcon != null) ? (<Icon iconClass={endIcon}/>) : null}
                >
                    
                    <span className={'defaultInputBtnTxt'}>{
                        (overrideTextToIcon==null)?(btnText):(overrideTextToIcon)
                    }</span>

                </Button>
            </TooltipWrapper>
        </span>
    );
}

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Input Button Wrapper
 * --------------------------------------------
 */

InputButton.propTypes = {
    /** UI type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** element Wrapper css class */
    elementWrapperStyle: PropTypes.string,
    /** Button element css class */
    elementStyle: PropTypes.string,
    /** Button text */
    btnText: PropTypes.string,
    /** Button name */
    btnName: PropTypes.string,
    /** Button size */
    btnSize: PropTypes.oneOf(['small', 'medium', 'large']),
    /** Button color */
    btnColor: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    /** Is full width set or not */
    isFullWidth: PropTypes.bool,
    /** Is button disable or not */
    isBtnDisabled: PropTypes.bool,
    /** Button left icon */
    startIcon: PropTypes.string,
    /** Button right icon */
    endIcon: PropTypes.string,
    /** Button variant */
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    /** Tooltip text */
    tooltip: PropTypes.string,
    /** Onclick button function */
    onClickBtnFn: PropTypes.func 
}

/**
 * memo render 
 * @param {Object} prevProps 
 * @param {Object} nextProps 
 */
const areEqual=(prevProps, nextProps) =>{
    if(nextProps.cacheLevel===formCacheLevel.none){        
      return false;
    }else if(nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange){        
      return (
        (prevProps.uiType===nextProps.uiType) && (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
        (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
        (prevProps.btnText===nextProps.btnText) && (prevProps.btnName===nextProps.btnName) &&
        (prevProps.isBtnDisabled===nextProps.isBtnDisabled) && (prevProps.tooltip===nextProps.tooltip) &&
        (prevProps.updateStatus===nextProps.updateStatus) && (prevProps.startIcon===nextProps.startIcon) &&
        (prevProps.startIcon===nextProps.endIcon) && (prevProps.formGroupName===nextProps.formGroupName) &&
        (prevProps.mergeToForm===nextProps.mergeToForm)
      )
    }else if(nextProps.cacheLevel===formCacheLevel.updateOnIndividual){
      return (
        (prevProps.uiType===nextProps.uiType) && (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
        (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
        (prevProps.btnText===nextProps.btnText) && (prevProps.tooltip===nextProps.tooltip) &&
        (prevProps.isBtnDisabled===nextProps.isBtnDisabled) && (prevProps.startIcon===nextProps.startIcon) &&
        (prevProps.startIcon===nextProps.endIcon) && (prevProps.formGroupName===nextProps.formGroupName) &&
        (prevProps.mergeToForm===nextProps.mergeToForm) 
      )
    }
    
  }
  
  const InputButtonMemo=memo(InputButton,areEqual);



  const areEqualSubmitProp=(prevProps, nextProps) =>{
        const status=areEqual(prevProps, nextProps);
        if(nextProps.cacheLevel===formCacheLevel.none){
            return false;
        } else {
            return (
                (status===true) && (prevProps.dataTableKey===nextProps.dataTableKey) &&
                (prevProps.isValidate===nextProps.isValidate) && (prevProps.flashMessages===nextProps.flashMessages) &&
                (prevProps.validationObject===nextProps.validationObject) && (prevProps.callApiObject===nextProps.callApiObject) &&
                (prevProps.apiDataStoringObject===nextProps.apiDataStoringObject) && (prevProps.updateStatus===nextProps.updateStatus) &&
                (prevProps.responseUpdateStatus===nextProps.responseUpdateStatus)
            )
        }
  }

  const SubmitButtonMemo=memo(InputButton,areEqualSubmitProp);

//----------------InputButtonWithState---------------------


/* --------------------------------------------
* @Author:Nisal Madusanka(EruliaF)
* @Description: Input Button WithState 
* --------------------------------------------
*/

const InputButtonWithState = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    btnText = "",
    btnName = "",
    btnSize = inputBtnSizes.small,
    btnColor = inputBtnColors.primary,
    isFullWidth = false,
    isBtnDisabled = false,
    startIcon = null,
    endIcon = null,
    variant = inputBtnVariants.contained,
    tooltip = "",
    formGroupName="",
    mergeToForm=null,
    cacheLevel=formCacheLevel.updateOnFormGroupChange,
    onClickBtnFn = emptyFun
}) => {

    const [handleOnClickFn,updateStatus]= useBasicButton(onClickBtnFn,formGroupName,mergeToForm);

    return (
        <InputButtonMemo
            uiType={uiType}
            elementWrapperStyle={elementWrapperStyle}
            elementStyle={elementStyle}
            btnText={btnText}
            btnName={btnName}
            btnSize={btnSize}
            btnColor={btnColor}
            isFullWidth={isFullWidth}
            isBtnDisabled={isBtnDisabled}
            startIcon={startIcon}
            endIcon={endIcon}
            variant={variant}
            tooltip={tooltip}
            cacheLevel={cacheLevel}
            mergeToForm={mergeToForm}
            updateStatus={updateStatus}
            onClickBtnFn={(eventObj)=>handleOnClickFn(eventObj)}
        />
    );
}

//----------------InputButtonWithState---------------------


//----------------SubmitButton---------------------


/* --------------------------------------------
* @Author:Nisal Madusanka(EruliaF)
* @Description: submit data to api
* --------------------------------------------
*/

const SubmitButton = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    btnText = "",
    btnName = "",
    btnSize = inputBtnSizes.small,
    btnColor = inputBtnColors.primary,
    isFullWidth = false,
    isBtnDisabled = false,
    startIcon = null,
    endIcon = null,
    variant = inputBtnVariants.contained,
    tooltip = "",
    formGroupName="",
    dataTableKey=null,
    isValidate=false,
    flashMessages={},
    validationObject={
        fileds:{},
        rules:{},
        message:{}
    },
    callApiObject={
        isSetHeaders: true,
        multipart:false,
        method: "post",
        onUpload:false,
        responseType :'json',
    },
    apiDataStoringObject={
        setLoader:true,
        storingType:"API_RESPONSE_LINKED_FORM",
        mergeToSuccessResponse:null,
        mergeToErrorResponse:null,
    },
    cacheLevel=formCacheLevel.updateOnFormGroupChange,
    onClickBtnFn = emptyFun,
    onResponseCallBackFn = emptyFun,
    onGetAPIEndPointFn=emptyFun,
    onChangeRequestBodyFn=emptyFun,
}) => {
    const [, uiDispatch] = useContext(UIContext);
    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const [handleOnClickFn,updateStatus,responseUpdateStatus]= useAPIBaseButton(apiDataStoringObject,flashMessages,dataTableKey,formGroupName,validationObject,callApiObject,isValidate,onClickBtnFn,onGetAPIEndPointFn,onChangeRequestBodyFn,onResponseCallBackFn);
    const dealerLicenceReprintdataList = _get(formState, getDealerVehicleDetailsAPI.key, [])
    const dealerLicenceReprintApprovaldataList = _get(formState, getDealerVehicleDetailsAPI.key, [])
    const dealerLicenceCancelLicenceTrxdataList = _get(formState, getDealerVehicleDetailsAPI.key, [])
    const dealerLicenceChangeSerialNumberdataList = _get(formState, 'dealer_licence_serial_number_change', [])
    const dealerLicenceChangeSerialNumberdataListApproval = _get(coreState, `dataTableResponses.changeDealerSerialKEY.results`, [])
    const clickBtnFunc = (eventObj) => {
        if(formGroupName === 'licence_reprint') {
            if(dealerLicenceReprintdataList && dealerLicenceReprintdataList.length !== 0) {
                handleOnClickFn(eventObj)
            } else {
                uiDispatch.setFlashMessage({
                    status: true,
                    message: 'Please select at least one Authorized Vehicle Number for reprint',
                    messageType: "error"
                })
            }
        } 
        // else if(formGroupName === 'change_serial_number') {
        //     if(dealerLicenceChangeSerialNumberdataList && dealerLicenceChangeSerialNumberdataList.length !== 0) {
        //         handleOnClickFn(eventObj)
        //     } else {
        //         uiDispatch.setFlashMessage({
        //             status: true,
        //             message: 'Please enter the New Serial Number for at least one licence number.',
        //             messageType: "error"
        //         })
        //     }
        // }
        //  else if(formGroupName === 'Dealer_Change_Serial_Number') {
        //     if(dealerLicenceChangeSerialNumberdataListApproval && dealerLicenceChangeSerialNumberdataListApproval.length !== 0) {
        //         handleOnClickFn(eventObj)
        //     } else {
        //         uiDispatch.setFlashMessage({
        //             status: true,
        //             message: 'Please enter the New Serial Number for at least one licence number.',
        //             messageType: "error"
        //         })
        //     }
        // } 
        else if(formGroupName === 'Dealers_Licence_Reprint-new') {
            if(dealerLicenceReprintApprovaldataList && dealerLicenceReprintApprovaldataList.length !== 0) {
                handleOnClickFn(eventObj)
            } else {
                uiDispatch.setFlashMessage({
                    status: true,
                    message: 'Please select at least one Authorized Vehicle Number for reprint',
                    messageType: "error"
                })
            }
        } else if(formGroupName === 'cancel_licence_transaction') {
            if((dealerLicenceCancelLicenceTrxdataList && dealerLicenceCancelLicenceTrxdataList.length !== 0) ||
            _get(formState, 'cancel_licence_transaction.action', null) === 'REJ') {
                handleOnClickFn(eventObj)
            } else {
                uiDispatch.setFlashMessage({
                    status: true,
                    message: 'Please select at least one Transaction to cancel',
                    messageType: "error"
                })
            }
        } else {
            handleOnClickFn(eventObj);
        }
    }
    return (
        <SubmitButtonMemo
            uiType={uiType}
            elementWrapperStyle={elementWrapperStyle}
            elementStyle={elementStyle}
            btnText={btnText}
            btnName={btnName}
            btnSize={btnSize}
            btnColor={btnColor}
            isFullWidth={isFullWidth}
            isBtnDisabled={isBtnDisabled}
            startIcon={startIcon}
            endIcon={endIcon}
            variant={variant}
            tooltip={tooltip}

            formGroupName={formGroupName}eventObj
            dataTableKey={dataTableKey}
            isValidate={isValidate}
            cacheLevel={cacheLevel}
            flashMessages={flashMessages}
            validationObject={validationObject}
            callApiObject={callApiObject}
            apiDataStoringObject={apiDataStoringObject}
            updateStatus={updateStatus}
            responseUpdateStatus={responseUpdateStatus}
            onClickBtnFn={(eventObj)=>clickBtnFunc(eventObj)}
        />
    )
}

//----------------SubmitButton---------------------

export {
    RoundButton, 
    InputButton,
    roundBtnColors,
    roundBtnSizes,
    roundBtnVariants,
    inputBtnColors,
    inputBtnSizes,
    inputBtnVariants,
    InputButtonWithState,
    SubmitButton,
    formCacheLevel,
    InputButtonMemo,
    SubmitButtonMemo,
    RoundButtonWithPopups
};
