/*
 * @Author: Binara Medawatta
 * @Date: 2020-12-30 15:10:18
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-04 11:17:00
 */
import React, {Fragment, useContext} from "react";
import { AuditDetail, UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {CheckBoxWithState, FormWrapper} from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import {_get} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {contactsArrayToObject} from "../../../../../helpers/common-helpers/contact.helpers";
import {ContactTypeCode} from "../../../../../config/enum.config";
import {
  userDefaultDelegationAPI,
  userDelegationAPI,
  userDelegationCreateAPI
} from "../../../../../config/apiUrl.config";
import {FormContext} from "../../../core/context-providers/FormContext.provider";
import {dateObjectToString} from "../../../../../helpers/common-helpers/dateTime.helpers";

const View = (props) => {

  const [formState] = useContext(FormContext);

  return (
    <Fragment>
      <UICard cardHeading="User Information">
        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>Title</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(props, `row.tittle.public_tittle`, '-')}</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>Full Name</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(props, `row.full_name`, '-')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>Designation</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(props, `row.users.designation`, '-')}</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>NIC</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(props, `row.nic`, '-')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>
                    <b>Email</b>
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>{ contactsArrayToObject(_get(props, `row.users.contact`, []), false, ContactTypeCode.EMAIL)}</p>
                </div>
              </div>
              </div>

              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>
                    <b>Mobile Number</b>
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>{ contactsArrayToObject(_get(props, `row.users.contact`, []), false, ContactTypeCode.MOBILE)}</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>
                    <b>Status</b>
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>{(_get(props, `row.users.status`, '-') === 1) ? "Active" : "Inactive"}</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UICard>
      <div className="defaultHalfMarginTop" />

      <UICard cardHeading="User Login Information">
        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>
                    <b>Username</b>
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>{_get(props, `row.users.user_name`, '-')}</p>
                </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>
                    <b>Display Name</b>
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                  <p>{_get(props, `row.display_name`, '-')}</p>
                </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 floatLeft">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <CheckBoxWithState
                    isDisabled={true}
                    labelText={"Notify via SMS"}
                    inputName={"notifyViaSMS"}
                    inputValue={_get(props, `row.users.sms_notification`, '-') === 1}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 floatLeft">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <CheckBoxWithState
                    isDisabled={true}
                    labelText={"Notify via Email"}
                    inputName={"notifyViaEmail"}
                    inputValue={_get(props, `row.users.email_notification`, '-') === 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UICard>
      <div className="defaultHalfMarginTop" />

      <UICard cardHeading="Default Role & Location">
        <FormWrapper
            setGroupName={userDefaultDelegationAPI.key}
            apiUrl={`${userDefaultDelegationAPI.url}/${props.row.users.id}`}
            onRebuildResponseFn={(response) => {
              return {
                "internal_role": _get(response, 'internal.role.name', "-"),
                "province": _get(response, 'internal.authority.province.name', "-"),
                "authority": _get(response, 'internal.authority.service.name', ""),
                "admin_role": _get(response, 'admin.role.name', "-"),
                "admin_service": _get(response, 'admin.authority.service.name', "-"),
                "is_admin": _get(response, 'admin', {}) !== {},
                "is_internal": _get(response, 'internal', {}) !== {},
                "_onLoad": false,
              }
            }}
        >
          <div className="row">
            <div className="col-md-12 col-sm-12 floatLeft">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <CheckBoxWithState
                      labelText={"Internal Module"}
                      inputName={"is_internal"}
                      isDisabled={true}
                      formGroupName={userDefaultDelegationAPI.key}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>User Role</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(formState, `${userDefaultDelegationAPI.key}.internal_role`, "-")}</p>
                  </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 floatLeft">
                  <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>Province</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(formState, `${userDefaultDelegationAPI.key}.province`, "-")}</p>
                  </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>Authority</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(formState, `${userDefaultDelegationAPI.key}.authority`, "-")}</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 floatLeft">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <CheckBoxWithState
                      labelText={"Admin Module"}
                      inputName={"is_admin"}
                      isDisabled={true}
                      formGroupName={userDefaultDelegationAPI.key}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                  <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>User Role</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(formState, `${userDefaultDelegationAPI.key}.admin_role`, "-")}</p>
                  </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 floatLeft">
                  <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>
                      <b>Province</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <p>{_get(formState, `${userDefaultDelegationAPI.key}.admin_service`, "-")}</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormWrapper>
      </UICard>
      <div className="defaultHalfMarginTop" />

      <UICard>
        <DataTable
          tableName="Additional Roles & Locations"
          dataTableKey={"chargeRange"}
          apiUrl={`${userDelegationAPI.url}/${props.row.users.id}?default_delegation=false`}
          isSetAction={false}
          isSetTableFooter={false}
          tableHeaderList={[
            {
              displayName: "Province",
              key: "province",
              isSort: false,
            },
            {
              displayName: "Authority",
              key: "authority.service.name",
              isSort: false,
            },

            {
              displayName: "User Role",
              key: "role.name",
              isSort: false,
            },
            {
              displayName: "Status",
              key: "role.status",
              isSort: false,
              onPrintFn: ({ role }) => (role.status === 1 ? "Active" : "Inactive")
            },
            {
              displayName: "Expire Date",
              key: "expiry_at",
              isSort: false,
              onPrintFn: (raw) =>
                  dateObjectToString(
                      raw.expiry_at,
                      "YY-mm-dd"
                  ),
            },
          ]}
        />
      </UICard>

      <div className="defaultHalfMarginTop" />
      <UICard>
          <AuditDetail
            createdBy={_get(props, `row.users.created_by`, '-')}
            createdLocation={_get(props, `row.users.updated_location_id`, '-')}
            createdAt={dateObjectToString(_get(props, `row.users.created_at`, '-'), "YY-mm-dd HH:MM:II")}
            updatedBy={_get(props, `row.users.updated_by`, '-')}
            updatedLocation={_get(props, `row.users.updated_location_id`, '-')}
            updatedAt={dateObjectToString(_get(props, `row.users.updated_at`, '-'), "YY-mm-dd HH:MM:II")}
            labelCol="4"
          />
        </UICard>
    </Fragment>
  );
};

export { View };
