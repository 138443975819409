import React, { useContext, useState, useEffect } from "react";
import { _get, _set } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { ModelBtnWrapper } from "../../../../ui-components/templates/CounterTemplate";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { ViewApprovalHistory } from "../ViewApprovalHistory";
import { ActionList, ActionListWaiveOff, ProvincesCodeMatcher, ActionListLastTrxn } from "../../../../../config/enum.config";
import {
  AuthorityListApi,
  provincesOfficesListAPI,
  userRoleCategoryListAPI,
  userRoleCategoryListForActionAPI
} from "../../../../../config/apiUrl.config";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { InputLabelWrapper } from "../../../../ui-components/ui-elements/form/form-includes/FormCoreIncludes";

const Action = (props) => {

  const formKey = props.formKey;
  const requestType = props.requestType;
  // const { remark } = props.dataSubmittedForApproval;
  const [formState, formAction] = useContext(FormContext);
  const [showPopup, setShowPopup] = useState(false);
  const [authStatus] = useContext(AuthContext);
  const userCatRole = _get(authStatus, "authUser.role_category_code", "");
  console.log('AUTTTTTTTK====', authStatus);
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const authorityId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.authorityId", "") : "";

  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const uiType = _get(props, "uiType", null);
  // {id: authorityId, service_id: serviceId}
  useEffect(() => {
    if ((roleCategoryCode === 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'COM') || (roleCategoryCode === 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'ACC') || roleCategoryCode === 'OPT') {
      formAction.changeInputFn(formKey, "forward_province", "", pdmtId);
    }

  }, [_get(formState, `${formKey}.forward_province`, pdmtId), _get(formState, `${formKey}.forward_authority`, authorityId), _get(formState, `${formKey}.forward_role`, "SUP")])

  return (
    <UICard>
      {console.log('ADD--', roleCategoryCode, _get(formState, `${formKey}.forward_role`, ""))}
      <FormWrapper
        setGroupName={formKey}
        setFormObject={{
          action: uiType === "request" ? "FRD" : undefined,
          forward_province: uiType === "request" ? _get(formState, `${formKey}.forward_province`, pdmtId) : (roleCategoryCode === 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'COM') ? pdmtId :
            (roleCategoryCode === 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'ACC') ? pdmtId : roleCategoryCode === 'OPT' ? pdmtId : '',
          forward_role: _get(formState, `${formKey}.forward_role`, "SUP"),
          forward_authority: _get(formState, `${formKey}.forward_authority`, authorityId),
          remark: _get(formState, `${formKey}.remark`, requestType ? props.dataSubmittedForApproval.remark : ""),
          // remark: _get(formState, `${formKey}.remark`, uiType !== "request" ? props.dataSubmittedForApproval.remark : ""),

        }}
        onDestroyUnsetFormObject={true}
      >
        <div className="row">
          <div className="row col-md-12">
            <div className="col-md-6 col-sm-6">
              <div className="row">

                <div className={props.isHalfWidth ? "col-md-2 requestReasonLabel" : "col-md-3 requestReasonLabel"}>
                  <InputLabelWrapper
                    lableText="Action"
                    isRequired={true}
                  />
                </div>
                <div className={props.isHalfWidth ? "col-md-9" : "col-md-9"}>
                  <SelectBoxWithState
                    formGroupName={formKey}
                    emptySelectOptionTxt={"Select Action"}
                    dataList={(props.isWaiveOff === true || userCatRole === 'OPT') ? ActionListWaiveOff : (props.isReverseTxn === true && props.isInvalidaReverse === true ? ActionListLastTrxn : ActionList)}
                    inputName={"action"}
                    labelText=""
                    isDisabled={uiType === "request" ? true : false}
                    isRequired={false}
                  />
                </div>
              </div>
              {/* <SelectBoxWithState
                formGroupName={formKey}
                emptySelectOptionTxt={"Select Action"}
                dataList={(props.isWaiveOff === true || userCatRole === 'OPT')  ? ActionListWaiveOff : (props.isReverseTxn === true && props.isInvalidaReverse === true ? ActionListLastTrxn : ActionList)}
                inputName={"action"}
                labelText="Action"
                isDisabled={uiType === "request" ? true : false}
                isRequired={true}
              /> */}
            </div>
          </div>
          {
            _get(formState, `${formKey}.action`, "") === "FRD" ? (
              <div className="row col-md-12">
                <div className="col-md-6 col-sm-6">
                  <div className="row">

                    <div className={props.isHalfWidth ? "col-md-2 requestReasonLabel" : "col-md-3 requestReasonLabel"}>
                      <InputLabelWrapper
                        lableText="Forward To"
                        isRequired={true}
                      />
                    </div>
                    <div className={props.isHalfWidth ? "col-md-9" : "col-md-9"}>
                      <SelectBoxWithState
                        apiUrl={provincesOfficesListAPI.url}
                        formGroupName={formKey}
                        keyName={"id"}
                        valueName={"service.name"}
                        apiStateKey={provincesOfficesListAPI.key}
                        emptySelectOptionTxt={"Select Province"}
                        inputName={"forward_province"}
                        labelText=""
                        isDisabled={uiType === "request" ? true : (roleCategoryCode === 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'COM') ? true :
                          (roleCategoryCode === 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'ACC') ? true : roleCategoryCode === 'OPT' ? true : false}
                        isRequired={false}
                      />
                    </div>
                  </div>
                  {/* <SelectBoxWithState
                    apiUrl={provincesOfficesListAPI.url}
                    formGroupName={formKey}
                    keyName={"id"}
                    valueName={"service.name"}
                    apiStateKey={provincesOfficesListAPI.key}
                    emptySelectOptionTxt={"Select Province"}
                    inputName={"forward_province"}
                    labelText="Forward To"
                    isDisabled={uiType === "request" ? true : (roleCategoryCode === 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'COM') ? true : 
                    (roleCategoryCode === 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'COM' && _get(formState, `${formKey}.forward_role`, "") !== 'SUP' && _get(formState, `${formKey}.forward_role`, "") !== 'ACC') ? true : roleCategoryCode === 'OPT' ? true : false}
                    isRequired={true}
                  /> */}
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="row">

                    <div className={props.isHalfWidth ? "col-md-2 requestReasonLabel" : "col-md-3 requestReasonLabel"}>
                      <InputLabelWrapper
                        lableText="Role Category"
                        isRequired={true}
                      />
                    </div>
                    <div className={props.isHalfWidth ? "col-md-9" : "col-md-6"}>
                      <SelectBoxWithState
                        inputName="forward_role"
                        labelText=""
                        isRequired={false}
                        formGroupName={formKey}
                        emptySelectOptionTxt={"Role Category"}
                        apiUrl={uiType === "request" ? `${userRoleCategoryListAPI.url}?role_type_code=INT_APP&role_level_code=INT_PRV` : `${userRoleCategoryListForActionAPI.url}?role_type_code=INT_APP&role_level_code=INT_PRV&include_role_category_code=OPT`}
                        // apiUrl={`${userRoleCategoryListAPI.url}?role_type_code=INT_APP&role_level_code=INT_PRV`}
                        apiStateKey={userRoleCategoryListAPI.key}
                        // isDisabled={uiType === "request" ? true : false}
                        // dataList={(userCatRole === "OPT" && uiType === 'APR') ? [{code: 'SUP', name: 'Supervisor'}] : userCatRole === "OPT" ? [{code: 'SUP', name: 'Supervisor'},{code: 'COM', name: 'Commisioner'},{code: 'ACT', name: 'Accountant'}] : [{code: 'SUP', name: 'Supervisor'},{code: 'COM', name: 'Commisioner'},{code: 'OPT', name: 'Operator'}]}
                        keyName={"code"}
                        valueName={"name"}
                      />
                    </div>
                  </div>
                  {/* <SelectBoxWithState
                    inputName="forward_role"
                    labelText="Role Category"
                    isRequired={true}
                    formGroupName={formKey}
                    emptySelectOptionTxt={"Role Category"}
                    apiUrl={uiType === "request" ? `${userRoleCategoryListAPI.url}?role_type_code=INT_APP&role_level_code=INT_PRV` : `${userRoleCategoryListForActionAPI.url}?role_type_code=INT_APP&role_level_code=INT_PRV&include_role_category_code=OPT`}
                    // apiUrl={`${userRoleCategoryListAPI.url}?role_type_code=INT_APP&role_level_code=INT_PRV`}
                    apiStateKey={userRoleCategoryListAPI.key}
                    // isDisabled={uiType === "request" ? true : false}
                    // dataList={(userCatRole === "OPT" && uiType === 'APR') ? [{code: 'SUP', name: 'Supervisor'}] : userCatRole === "OPT" ? [{code: 'SUP', name: 'Supervisor'},{code: 'COM', name: 'Commisioner'},{code: 'ACT', name: 'Accountant'}] : [{code: 'SUP', name: 'Supervisor'},{code: 'COM', name: 'Commisioner'},{code: 'OPT', name: 'Operator'}]}
                    keyName={"code"}
                    valueName={"name"}
                  /> */}
                </div>
                <div className="col-md-3 col-sm-6">
                  {uiType === "request" ? null : _get(formState, `${formKey}.forward_role`, "") !== "OPT" ? null : (
                    <SelectBoxWithState
                      formGroupName={formKey}
                      labelText="Role Authority"
                      emptySelectOptionTxt={"Select Authority"}
                      inputName={"forward_authority"}
                      apiUrl={`${AuthorityListApi.url}?province_code=${ProvincesCodeMatcher[`${_get(formState, `${formKey}.forward_province`, pdmtId)}`]}&active=true`}
                      apiStateKey={AuthorityListApi.key}
                      keyName={"id"}
                      valueName={"service.name"}
                    // isDisabled={userRole === "INT_AU" ? true : false}
                    />
                  )}
                </div>
              </div>
            ) : (null)
          }
          <div className="col-md-12 col-sm-12">
            <div className="row">
              <div className={"col-md-1 requestReasonLabel"}>
                <InputLabelWrapper
                  lableText="Remark"
                  isRequired={true}
                />
              </div>
              <div className="col-md-9" style={{marginLeft:"50px", flex: 0.83}}>
                
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"remark"}
                  labelText=""
                  isMultiline={true}
                  rows={3}
                  // inputValue={requestType && props.dataSubmittedForApproval.remark}
                  maxLength={509}
                  isRequired={false}
                />
              </div>
            </div>
            {/* <InputBoxWithState
              formGroupName={formKey}
              inputName={"remark"}
              labelText="Remark"
              isMultiline={true}
              rows={3}
              // inputValue={requestType && props.dataSubmittedForApproval.remark}
              maxLength={509}
              isRequired={true}
            /> */}
          </div>
        </div>
        {uiType === "request" ? null : (
          <SubmitButton
            elementWrapperStyle={"floatRight"}
            btnName={"history"}
            btnText={"View Approval History"}
            onClickBtnFn={() => {
              setShowPopup(true);
            }}
          />
        )}
      </FormWrapper>
      {uiType === "request" ? null : (
        <DialogPopup
          isOpen={showPopup}
          isFullScreen={false}
          dialogTitle={"View Approval History"}
          onClosePopupFn={() => {
            setShowPopup(false);
          }}
          isCustomButton={false}
          customButtonComponent={ModelBtnWrapper}
          closeButton={{
            btnText: "Cancel",
          }}
        >
          <ViewApprovalHistory requestId={props.dataSubmittedForApproval.request_id} />
        </DialogPopup>
      )}
    </UICard>
  );
};

export { Action };
