/*
 * @Author: Gajani Kangesan
 * @Date: 2022-08-09 11:00:43
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:12:24
 */

import React, { useContext } from 'react';
import { CircleLoaderElement, PaddingLessUICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { thousandSeparator } from "../../../../../../helpers/common-helpers/common.helpers"
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";


const searchFormKey = "dealer_search_form";
const formKey = "dealerlicence"


const DealerLicenceFee = ({ dealerId }) => {

    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const issuedPdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

    return (

        <PaddingLessUICard cardHeading="Total">
            {
                _get(formState, "dealer_charges_form._onLoad", true) === true ? (
                    <div>
                        <br />
                        <center>
                            <CircleLoaderElement />
                        </center>
                    </div>
                ) : (
                    <table style={{ width: "100%" }}>
                        <tr>
                            <th>Fee (LKR)</th>
                            <td className="text-right">{thousandSeparator(_get(formState, "dealer_charges_form.totFee", 0), true)}</td>
                        </tr>
                        <tr>
                            <th>Additions (LKR)</th>

                            <td className="text-right">{Number(_get(formState, "dealer_charges_form.totAddition", 0)) === 0 ? "0.00" : " "}</td>

                        </tr>
                        {_get(formState, `dealer_charges_form.data.additons_deductions_total`, []).map((item) => (item.charge_effect_code === "ADD") &&
                            (
                                <tr>

                                    <th>
                                        <p className="text-left">{item.addition_deduction_name}</p>
                                    </th>

                                    <td >
                                        <p className="text-right">{thousandSeparator(item.total, true)}</p>
                                    </td>
                                </tr>

                            ))}



                        <tr>
                            <th>Deductions (LKR)</th>
                            <td className="text-right">{Number(_get(formState, "dealer_charges_form.totdeduction", 0)) === 0 ? "(0.00)" : " "}</td>
                        </tr>



                        {_get(formState, `dealer_charges_form.data.additons_deductions_total`, []).map((item) => (item.charge_effect_code === "DED") &&
                            (
                                <tr>
                                    <th>
                                        <p className="text-left">{item.addition_deduction_name}</p>
                                    </th>
                                    <td >
                                        <p className="text-right">({thousandSeparator(item.total, true)})</p>
                                    </td>
                                </tr>

                            ))}
                        <tr>
                            <th>Service Charge (LKR)</th>
                            <td className="text-right">{thousandSeparator(_get(formState, "dealer_charges_form.serviceCharge", 0), true)}</td>
                        </tr>

                        <tr>
                            <th>Total Amount (LKR)</th>
                            <td className="text-right">
                                <b>{thousandSeparator(_get(formState, "dealer_charges_form.total", 0), true)}</b>
                            </td>
                        </tr>
                    </table>)}
        </PaddingLessUICard>




    );
};

export { DealerLicenceFee };
