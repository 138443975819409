/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-06-16 05:32:37
 */

import React, { useState, useContext } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { InputButton, SubmitButton } from "../../../../ui-components/ui-elements/form";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { ViewDocuments } from "../includes/ViewDocuments";
import { ActivityLog } from "../includes/ActivityLog";
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import IssueCarnet from "../../../payment/pages/IssueCarnet";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";

const CarnetFooter = ({
  formChild = {
    issueMode: "1",
    isPendingWorkFlow: false
  },
  latestLicence = {}
}) => {
  const searchFormKey = "vehicle_search_form";
  const pendingWorkflowFormKey = "pending_workflow_key";
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);

  const [state, setState] = useState({
    modelStatus: false,
    modelType: "",
  });
  const closePopup = () => {
    setState({
      ...state,
      modelStatus: false,
      modelType: "",
    })
  };

  const getIssueBtnName = () => {
    if (_get(formChild, "issueMode", "1") === "1") {
      return "Issue Carnet";
    } else {
      return "Issue Temp. Carnet";
    }
  };

  const getModalName = () => {
    if (state.modelType === "payment") {
      return "Make Payment - Carnet Licence";
    } else if (state.modelType === "viewDocuments") {
      return "View & Upload Documents";
    } else if (state.modelType === "activityLog") {
      return "Activity Log";
    }
  };

  const getModalComponent = () => {
    if (state.modelType === "payment") {
      return <IssueCarnet uiType={"carnet"} latestLicence={latestLicence} closeMainPopup={closePopup} />;
    } else if (state.modelType === "viewDocuments") {
      return <ViewDocuments formGroupName="carnet-document-form" />;
    } else if (state.modelType === "activityLog") {
      return <ActivityLog />;
    }
  };


  const btnDisabled = () => {
    let vetRequired = false;
    let vftRequired = false;
    let insuranceRequired = false;
    let isDissable = true;

    if (
      _get(formState,"vehicle-meta-data.data.vet_required",false)
    ) {
      vetRequired = true;
    }
  

    if (
      _get(formState,"vehicle-meta-data.data.insurance_required",false)
    ) {
      insuranceRequired = true;
    }

    if (
      _get(formState,"vehicle-meta-data.data.vft_required",false)
    ) {
      vftRequired = true;
    }
    if (vetRequired === true && _get(formState, "vet_certificate_form.isVet", false) == false) {
      isDissable = false;
    }
    if (insuranceRequired === true && _get(formState, "insurance_form.isInsurance", false) == false) {
      isDissable = false;
    }
    if (vftRequired === true && _get(formState, "vft_certificate_form.isfitness", false) == false) {
      isDissable = false;
    }
    if(_get(authStatus, "authUser.pdmtId.id", "") !== _get(formState, `${searchFormKey}.data.province_id`, "")){
      isDissable = false;
    }

    return !isDissable;
  };

  // const getVehicleStatusCode = () => {
  //   let vehiclestatusCode = 'INE'
  //   vehiclestatusCode = _get(formState, `${searchFormKey}.data.vehicle_status`,)
  //   if ((_get(formState, `${searchFormKey}.data.gross_weight`, 0) === null || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === '0.00')
  //     && (_get(formState, `${searchFormKey}.data.tare_weight`, 0) === null || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === '0.00')
  //   ) {
  //     vehiclestatusCode = "INE"
  //   }

  //   if (_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length > 0) {
  //     vehiclestatusCode = "INE"
  //   }

  //   if (_get(formState, `${searchFormKey}.data.fuel_type.name`,) === 'NOT STATED' && _get(formState, `${searchFormKey}.data.vehicle_class.prefix`,) !== 'TR') {
  //     vehiclestatusCode = "INE"
  //   }

  //   return vehiclestatusCode
  // }


  return (
    <div className="row">
      <div className="col-md-6">
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnName="submit"
          btnText={"Activity Log"}
          isBtnDisabled={!_get(formState, `${searchFormKey}.data`, null)}
          onClickBtnFn={() =>
            setState({
              ...state,
              modelStatus: true,
              modelType: "activityLog",
            })
          }
        />
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnName="submit"
          isBtnDisabled={!_get(formState, `${searchFormKey}.data`, null)}
          btnText={"View/Upload Documents"}
          onClickBtnFn={() =>
            setState({
              ...state,
              modelStatus: true,
              modelType: "viewDocuments",
            })
          }
        />
      </div>

      <div className="col-md-6 text-right">
        {_get(formChild, "issueMode", "1") === "1" && _get(formState,"vehicle-meta-data.data.eligible",false) === true ? (
          <SubmitButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            btnText={"Issue Carnet"}
            isValidate={true}
            formGroupName={`carnet`}
            validationObject={{
              fileds: {
                fromDate: "From Date",
                toDate: "To Date"
              },
              rules: {
                fromDate: "required",
                toDate: "required"
              },
              message: {},
            }}
            callApiObject={{
              isSetHeaders: true,
              multipart: false,
              method: "post",
              onUpload: false,
            }}
            apiDataStoringObject={{
              setLoader: true,
              storingType: apiResponseStoringType.responseLinkToFrom,
            }}
            onGetAPIEndPointFn={(formState) => {
              return {
                url: 'test', //temporary
                key: 'test',
              };
            }}
            onChangeRequestBodyFn={(formObject) => {
              return getDataByFormObject({

              });
            }}
            onResponseCallBackFn={(errors, response) => {
              if (_get(formState, `carnet.fromDate`, null) !== null) {
                formAction.setFormErrorFn('carnet', [{ property: 'toDate', message: 'The To Date is required' }]);
              }
              if (_get(formState, `carnet.toDate`, null) !== null) {
                formAction.setFormErrorFn('carnet', [{ property: 'fromDate', message: 'The From Date is required' }]);
              }
              if (_get(formState, `carnet.fromDate`, null) !== null && _get(formState, `carnet.toDate`, null) !== null) {
                formAction.setFormErrorFn('carnet', []);
                setState({
                  ...state,
                  modelStatus: true,
                  modelType: "payment",
                })
              }
            }}
            isBtnDisabled={btnDisabled() || !_get(formState, "carnet.dmtCarnetLetter", false) || _get(formState, "licence_charges_form.total", 0)===0}
          // onClickBtnFn={() =>
          //   setState({
          //     ...state,
          //     modelStatus: true,
          //     modelType: "payment",
          //   })
          // }          
          />) : null}

        {_get(formChild, "issueMode", "1") !== "1" && _get(formState,"vehicle-meta-data.data.eligible",false) === true && _get(formState, `vehicle_search_form.data.vehicle_status`, "vehicle_status") === 'ELI' && _get(formState, `pending_workflow_key.workflows`, []).length === 0 ? (
          <SubmitButton
            elementWrapperStyle={"defaultHalfMarginLeftRight"}
            btnName="submit"
            btnText={"Issue Temp. Carnet"}
            isValidate={true}
            formGroupName={`carnet`}
            isBtnDisabled={btnDisabled() || !_get(formState, "carnet.dmtCarnetLetter", false)}
            validationObject={{
              fileds: {
                fromDate: "From Date",
                toDate: "To Date"
              },
              rules: {
                fromDate: "required",
                toDate: "required"
              },
              message: {},
            }}
            callApiObject={{
              isSetHeaders: true,
              multipart: false,
              method: "post",
              onUpload: false,
            }}
            apiDataStoringObject={{
              setLoader: true,
              storingType: apiResponseStoringType.responseLinkToFrom,
            }}
            onGetAPIEndPointFn={(formState) => {
              return {
                url: 'test', //temporary
                key: 'test',
              };
            }}
            onChangeRequestBodyFn={(formObject) => {
              return getDataByFormObject({

              });
            }}
            onResponseCallBackFn={(errors, response) => {
              if (_get(formState, `carnet.fromDate`, null) !== null) {
                formAction.setFormErrorFn('carnet', [{ property: 'toDate', message: 'The To Date is required' }]);
              }
              if (_get(formState, `carnet.toDate`, null) !== null) {
                formAction.setFormErrorFn('carnet', [{ property: 'fromDate', message: 'The From Date is required' }]);
              }
              if (_get(formState, `carnet.fromDate`, null) !== null && _get(formState, `carnet.toDate`, null) !== null) {
                formAction.setFormErrorFn('carnet', []);
                setState({
                  ...state,
                  modelStatus: true,
                  modelType: "payment",
                })
              }
            }}
          />
        ) : null}
      </div>

      <DialogPopup
        isOpen={state.modelStatus}
        isFullScreen={state.modelType !== "viewDocuments"}
        dialogTitle={getModalName()}
        onClosePopupFn={() =>
          setState({
            ...state,
            modelStatus: false,
            modelType: "",
          })
        }
        closeButton={{
          btnText: state.modelType !== "viewDocuments" ? 'Cancel' : 'Cancel'
        }}
        isConfirmationPopup={state.modelType === "payment" ? true : false}
        confirmationMessage={"Are you sure you want to go back?"}
      >
        {getModalComponent()}
      </DialogPopup>
    </div>
  );
};

export { CarnetFooter };
