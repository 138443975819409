/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2019-10-08 14:09:14
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 14:51:46
 */
import Keycloak from 'keycloak-js';

import {
  checkUserINLocalStorage,
  setToLocalStorage,
} from '../../../helpers/common-helpers/manageStorage.helpers';
import {
  authServiceClient,
  authServiceClientSecret,
  authTokenStorageKey
} from '../../../config/core.config';
import { axiosWrapper } from '../../../helpers/common-helpers/callApi.helpers';
import { _get } from '../../../helpers/common-helpers/lodash.wrappers';
import {userAuthServiceAPI, userInfoAPI} from "../../../config/apiUrl.config";
import {useState} from "react";

/**
 * @author: Nisal Madusanka(EruliaF)
 * @description : check user authorized in globally
 * @param {Object} authStatus
 * @param {Object} authAction
 * @param {Function} cb callback function
 */
const isAuthorizedINGlobalState = (authStatus, authAction, cb) => {
  if (
    authStatus.isAuthenticated === true &&
    authStatus.accessToken !== null &&
    authStatus.refreshToken !== null
  ) {
    cb(null, true);
  } else {
    connecToLocalStorage(authStatus, authAction, cb);
  }
};

/**
 * @author: Nisal Madusanka(EruliaF)
 * @description : check user data avilable or not in Local Storage
 * @param {Object} authStatus
 * @param {Object} authAction
 * @param {Function} cb callback function
 */
const connecToLocalStorage = (authStatus, authAction, cb) => {
  const userObject = checkUserINLocalStorage();
  if (userObject.status === true) {
    authAction.setTokensFn(userObject.result);
    cb(null, true);
  } else {
    
    const config = {
      realm: 'erl-internal-user',
      url: `${userAuthServiceAPI.url}`,
      'ssl-required': 'external',
      clientId: `${authServiceClient}`,
      clientSecret: `${authServiceClientSecret}`,
      onLoad: 'login-required',
      "enable-cors": true,
      grantType: 'password',
      'confidential-port': 0,
    };

    const keycloak = new Keycloak(config);
    keycloak
      .init({ onLoad: 'login-required', promiseType: 'native' })
      .then((authenticated) => {
        if (authenticated === true) {
          const tokens = {
            accessToken: keycloak.token,
            refreshToken: keycloak.refreshToken,
            keycloakObject: keycloak,
          };

          axiosWrapper(
            userInfoAPI.url,
            'get',
            {
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${keycloak.token}`,
              },
            },
            {},
            false,
            (error, result) => {
              if (error) {
                cb(null, false);
              }
              if(_get(result, 'data.role_level_code', null) == 'INT_AU') {
                tokens['id'] = _get(result, 'data.sub', null);
                tokens['name'] = _get(result, 'data.display_name', null);
                tokens['role'] = _get(result, 'data.role_name', null);
                tokens['role_prefix'] = _get(result, 'data.role_prefix', null)
                tokens['permission'] = _get(result, 'data.permissions', []);
                tokens['current_delegation'] = _get(result, 'data.current_delegation_id', null);
                tokens['service_id'] = _get(result, 'data.service_id', null);
                tokens['keycloak'] = keycloak;
                // tokens['pdmtCode'] = _get(result, 'data.authority.pdmt.code', []);
                tokens['authority_type_code'] = _get(result, 'data.authority.authority_type.code', null);
                tokens['province_code'] = _get(result, 'data.authority.pdmt_id.code', null);
                tokens['authority_name'] = _get(result, 'data.authority.service.name', []);
                tokens['authority_code'] = _get(result, 'data.authority.code', []);
                tokens['authority_prefix'] = _get(result, 'data.authority.prefix', []);
                tokens['authority_id'] = _get(result, 'data.authority.id', []);
                tokens['role_level_code'] = _get(result, 'data.role_level_code', null);
                tokens['pdmt_id'] = _get(result, 'data.authority.pdmt_id', null);
                tokens['pdmt_code'] = _get(result, 'data.pdmt.code', []);
                tokens['role_category_code'] = _get(result, 'data.role_category_code', null);
                tokens['province_id'] = _get(result, 'data.authority.province.id', null);
                tokens['province_name'] = _get(result, 'data.authority.province.name', null);
                setToLocalStorage(authTokenStorageKey, tokens);
                authAction.setTokensFn(tokens);
                cb(null, true);
              } else if(_get(result, 'data.role_level_code', null) == 'INT_PRV') {
                tokens['id'] = _get(result, 'data.sub', null);
                tokens['name'] = _get(result, 'data.display_name', null);
                tokens['role'] = _get(result, 'data.role_name', null);
                tokens['role_prefix'] = _get(result, 'data.role_prefix', null)
                tokens['permission'] = _get(result, 'data.permissions', []);
                tokens['current_delegation'] = _get(result, 'data.current_delegation_id', null);
                tokens['service_id'] = _get(result, 'data.service_id', null);
                tokens['keycloak'] = keycloak;
                tokens['pdmt_code'] = _get(result, 'data.pdmt.code', []);
                // tokens['authorityCode'] = _get(result, 'data.authority.code', []);
                // tokens['authorityPrefix'] = _get(result, 'data.authority.prefix', []);
                // tokens['authorityId'] = _get(result, 'data.authority.id', []);
                tokens['role_level_code'] = _get(result, 'data.role_level_code', null);
                tokens['pdmt_id'] = _get(result, 'data.pdmt.id', null);
                tokens['role_category_code'] = _get(result, 'data.role_category_code', null);
                tokens['province_id'] = _get(result, 'data.pdmt.province.id', null);
                tokens['province_code'] = _get(result, 'data.pdmt.province.code', null);
                tokens['province_name'] = _get(result, 'data.pdmt.province.name', null);
                setToLocalStorage(authTokenStorageKey, tokens);
                authAction.setTokensFn(tokens);
                cb(null, true);
              }
              
            }
          );

        } else {
          cb(null, false);
        }
      })
      .catch((error) => {
        cb(null, false);
      });
  }
};

const logout = () => {

}

export { isAuthorizedINGlobalState };
