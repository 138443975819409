/*
 * @Author:Pradeepa Sandaruwan
 * @Date: 2021-01-12 10:21:07
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-13 14:39:07
 */


import React, { useState, useContext } from "react";
import { DashboardTemplate } from "../../../../../ui-components/templates/DashboardTemplate";
import { UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, SelectBoxWithState, SubmitButton, InputButton } from "../../../../../ui-components/ui-elements/form";
import { downloadFile } from "../../../../../../helpers/download-helpers/download.helpers"
import {
      _get,
} from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { reportGenerateAPI } from "../../../../../../config/apiUrl.config";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { templateTypes } from "../../../../../../config/template.config";
import { SearchCriteria } from "./includes/SearchCriteria";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { permissions } from "../../../../../../config/permission.config";
const FitnessIssuanceReport = () => {
      const reportCode = `fr41-fitness-issuance-report-entire`
      const searchformGroupName = `fitnessissuance`;
      const exportformGroupName = `export_report_form`;
      const [htmlTemplate, sethtmlTemplate] = useState(null);
      const [reportgeneratedValues, setReportgeneratedValues] = useState({});
      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const reportName = "Fitness Issuance Report"
      const [printcontent, sePrintcontent] = useState(null)
      const [scrollView, setscrollView] = useState({
            "onload": false,
            "bar": false
      });

      const onGenerateSubmit = (event) => {
            setscrollView({
                  "onload": true,
                  "bar": JSON.stringify({ __html: event }).includes("No results found").toString()
            })
            sethtmlTemplate({ __html: event });
            sePrintcontent(event)
      };

      const onSetGeneratedValues = (event) => {
            setReportgeneratedValues(event)
      };
      const onExportToPdfSubmit = (event) => {
            downloadFile(new Blob([event]), `${reportName}.pdf`);
      };

      const onExportToExcelSubmit = (event) => {
            downloadFile(new Blob([event]), `${reportName}.xlsx`);
      };
      const onResetClick = () => {
            sethtmlTemplate(null)
            setReportgeneratedValues({})
      }
      const ExportOption = []
      _get(authStatus, 'authUser.permissions', "").forEach(item => {
            if (item.permission === permissions.EXEL_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE.permissions[0]) {
                  ExportOption.push({
                        id: "xlsx",
                        value: "Excel",
                  })
            }
            else if (item.permission === permissions.PDF_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE.permissions[0]) {
                  ExportOption.push({
                        id: "pdf",
                        value: "PDF",
                  })
            }
      })
      const reportPrint = () => {
            let originalContents = document.body.innerHTML;
            document.body.innerHTML = printcontent;
            window.print();
            window.location.reload();
            document.body.innerHTML = originalContents;
            window.close()

      }
      return (
            <DashboardTemplate
                  pageTitle="Fitness Issuance Report "
                  breadCrumbList={[
                        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
                        { urlTo: "/report-other", displayName: "Other Reports", },
                        {
                              urlTo: "/report",
                              displayName: "Fitness Issuance Report ",
                        },
                  ]}
            >
                  <SearchCriteria
                        reportCode={reportCode}
                        formGroupName={searchformGroupName}
                        onGenerateSubmit={onGenerateSubmit}
                        setReportgeneratedValues={onSetGeneratedValues}
                        onResetClick={onResetClick}
                  />

                  {(htmlTemplate && scrollView.onload === true) && (
                        <div class="mt-2 report-html-view">
                              <UICard>
                                    <div class={`${scrollView.bar}`} dangerouslySetInnerHTML={htmlTemplate} />
                              </UICard>
                        </div>
                  )}

                  {htmlTemplate && (
                        <div class="mt-2">
                              <UICard>
                                    <FormWrapper
                                          setGroupName={exportformGroupName}
                                          formGroupLinkWith={exportformGroupName}
                                          onDestroyUnsetFormObject={true}
                                          setFormObject={{
                                                export_to: null
                                          }}
                                    >
                                          <div className={"reportExportToAlignWrapper"}>
                                                <div className="exporTypeSelectDropdown ">
                                                      <SelectBoxWithState
                                                            uiType={templateTypes.bootstrap}
                                                            formGroupName={exportformGroupName}
                                                            emptySelectOptionTxt={'  '}
                                                            inputValue={null}
                                                            dataList={ExportOption}
                                                            inputName={"export_to"}
                                                            labelText="Export To"
                                                      />

                                                </div>
                                                <div className="exportBtn">
                                                      <SubmitButton
                                                            btnText={"Export"}
                                                            elementStyle="btnWrapper"
                                                            formGroupName={exportformGroupName}
                                                            isValidate={true}
                                                            validationObject={{
                                                                  fileds: {
                                                                        export_to: "Export To"
                                                                  },
                                                                  rules: {
                                                                        export_to: "required"
                                                                  },
                                                                  message: {}
                                                            }}
                                                            callApiObject={{
                                                                  isSetHeaders: true,
                                                                  method: "get",
                                                                  multipart: false,
                                                                  onUpload: false,
                                                                  responseType: 'arraybuffer'
                                                            }}
                                                            apiDataStoringObject={{
                                                                  setLoader: true,
                                                                  storingType: "API_RESPONSE_LINKED_FORM",
                                                                  mergeToSuccessResponse: true,
                                                                  mergeToErrorResponse: true
                                                            }}
                                                            onGetAPIEndPointFn={(formState) => {
                                                                  return {
                                                                        url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=${formState?.export_to}&report_limit=${reportgeneratedValues?.limit}&report_offset=${reportgeneratedValues?.offset}&report_requestFromDate=${reportgeneratedValues?.datefrom}&report_requestToDate=${reportgeneratedValues?.dateto}&report_requestProvince=${reportgeneratedValues?.provinceName}&report_provinceId=${reportgeneratedValues?.provinceId}&report_vftCenterId=${reportgeneratedValues?.vftcenterId}&report_vftCenterName=${reportgeneratedValues?.vftcenterName}&report_fitnessStatusName=${reportgeneratedValues?.fitnessName}&report_fitnessStatusId=${reportgeneratedValues?.fitnessId}&report_dateFormatDisplay=dd/MM/yyyy&report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${reportgeneratedValues?.reportGeneratedUser}`,
                                                                        key: `${reportGenerateAPI.key}`
                                                                  }
                                                            }}

                                                            onResponseCallBackFn={(error, response,) => {
                                                                  if (response?._statue) {
                                                                        if (_get(formState[exportformGroupName], "export_to", "") === 'pdf') {
                                                                              onExportToPdfSubmit(response?.data)
                                                                        }
                                                                        if (_get(formState[exportformGroupName], "export_to", "") === 'xlsx') {
                                                                              onExportToExcelSubmit(response?.data)
                                                                        }
                                                                  }
                                                            }}
                                                      />

                                                      <InputButton
                                                            btnText="Print"
                                                            formGroupName={exportformGroupName}
                                                            elementWrapperStyle={"defaultHalfMarginLeftRight payBtnStyle"}
                                                            onClickBtnFn={() => reportPrint()}
                                                      />
                                                </div>

                                          </div>
                                    </FormWrapper>
                              </UICard>
                        </div>
                  )}
            </DashboardTemplate>
      );
};

export { FitnessIssuanceReport };
