/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-14 08:14:39
 */

import React, { Fragment, useContext } from 'react';
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { permissions } from "../../../../../config/permission.config"

import {
    InputButton,
    roundBtnSizes,
    SubmitButton
} from "../../../../ui-components/ui-elements/form/index";
import { CheckPermission } from "../../../../ui-components/ui-elements/common/BaseElements";
import { RoundButtonWithPopups } from "../../../../ui-components/ui-elements/form/Button";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { OwnerInfoEdit } from './OwnerInfoEdit';
import { OwnerInfoDisplay } from './OwnerInfoDisplay';
import { OwnerInfoDisplayPopup } from './OwnerInfoDisplayPopup';
import { VehicleInfoDisplayPopup } from './VehicleInfoDisplayPopup';
import { VehicleInfoEdit } from './VehicleInfoEdit';
import { commentPostAPI, ownerEditAPI } from '../../../../../config/apiUrl.config'
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../../modules/core/context-providers/AuthContext.provider";
const emptyFn = (...para) => undefined;

// const EditBtn = () => {
//     return <InputButton
//         btnName="submit"
//         btnText={"Update"}
//     />
// }

const EditBtn = (props) => {
    const onClosePopupFn = emptyFn;
    const [coreState, coreAction] = useContext(CoreContext);
    const [formState, formAction] = useContext(FormContext);
    const [uiAction] = useContext(UIContext);
    const fromKey = "editOwnerInformation";


    const getAbsoluteAddress1ValidationRule = () => {
        if (_get(formState, `${fromKey}.absolute_owner_name`, '').length > 0) {
            return "required|max:50"
        } else {
            return "max:50"
        }
    }

    return (
        <Fragment>
            <SubmitButton
                btnText="Update"
                startIcon={"far fa-edit"}
                elementStyle="btnWrapper"
                formGroupName={fromKey}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Vehicle Owner is successfully updated.",
                        messageType: "success"
                    },
                    "failed": {
                        status: true,
                        message: "Something went wrong.",
                        messageType: "error"
                    }
                }}
                // mapValidationErrorObj={{
                //     name: "Vehicle Class Name already exists",
                //     prefix: "Vehicle Class Prefix already exists",

                // }}
                // dataTableKey={ownerEditAPI.key}
                validationObject={{
                    fileds: {
                        name: "Owner Name",
                        address_line_1: "Address Line 1",
                        address_line_2: "Address Line 2",
                        city: "City",
                        contact_number: "Mobile Number",
                        nic: "Identification Number",
                        absolute_owner_name: "Absolute Owner Name",
                        absolute_owner_address_line_1: "Absolute Owner Address Line 1",
                        absolute_owner_address_line_2: "Absolute Owner Address Line 2",
                        absolute_owner_city: "Absolute Owner Address City",
                        additional_information: "Additional Information"
                    },
                    rules: {
                        name: "required|max:100",
                        address_line_1: "required|max:50",
                        address_line_2: "max:50",
                        city: "required|max:50",
                        contact_number: "max:10|min:9",
                        nic: "max:12",
                        absolute_owner_name: "max:100",
                        absolute_owner_address_line_1: getAbsoluteAddress1ValidationRule(),
                        absolute_owner_address_line_2: "max:50",
                        absolute_owner_city: "max:50",
                        additional_information: "max:200"
                    },
                    message: {},
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "put",
                    onUpload: false,
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.responseLinkToFrom
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: (`${ownerEditAPI.url}/${_get(formState, 'id', '')}`),
                        key: ownerEditAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    let formobj = { ...fromObject }
                    if (isEmptyValue(formobj.address_line_2)) {
                        formobj.address_line_2 = null
                    }
                    if (isEmptyValue(formobj.nic)) {
                        formobj.nic = null
                    }
                    if (isEmptyValue(formobj.contact_number)) {
                        formobj.contact_number = null
                    }
                    if (isEmptyValue(formobj.absolute_owner_name)) {
                        formobj.absolute_owner_name = null
                    }

                    if (isEmptyValue(formobj.absolute_owner_address_line_2)) {
                        formobj.absolute_owner_address_line_2 = null
                    }

                    if (isEmptyValue(formobj.absolute_owner_city)) {
                        formobj.absolute_owner_city = null
                    }

                    if (isEmptyValue(formobj.additional_information)) {
                        formobj.additional_information = null
                    }

                    return getDataByFormObject({
                        ...formobj,
                    })
                }}
                onResponseCallBackFn={(errors, response) => {
                    if (!errors) {
                        props.onClosePopupFn();
                        let ownerData = response.data.data.owner_id;
                        let absoluteOwnerData = response.data.data.absolute_owner_id;
                        const vehicleData = { ..._get(formState, "vehicle_search_form.data", {}), absolute_owner_id: absoluteOwnerData }
                        formAction.changeInputFn('viewOwnerInformation', "name", "", ownerData.name);
                        formAction.changeInputFn('viewOwnerInformation', "address_line_1", "", ownerData.address_line_1);
                        formAction.changeInputFn('viewOwnerInformation', "address_line_2", "", ownerData.address_line_2);
                        formAction.changeInputFn('viewOwnerInformation', "contact_number", "", ownerData.contact_number);
                        formAction.changeInputFn('viewOwnerInformation', "city", "", ownerData.city);
                        formAction.changeInputFn('viewOwnerInformation', "nic", "", ownerData.nic);
                        formAction.changeInputFn('viewOwnerInformation', "sms_alerts", "", ownerData.sms_alerts);
                        formAction.changeInputFn('viewOwnerInformation', "additional_information", "", ownerData.additional_information);
                        formAction.changeInputFn('vehicle_search_form', `data`, "", vehicleData)
                        // formAction.changeInputFn('vehicle_search_form', `address_line_1`, "data.absolute_owner_id",absoluteOwnerData.address_line_1)
                        // formAction.changeInputFn('vehicle_search_form', `address_line_2`, "data.absolute_owner_id",absoluteOwnerData.address_line_2)
                        // formAction.changeInputFn('vehicle_search_form', `city`, "data.absolute_owner_id",absoluteOwnerData.city)
                        // props.onUpdateOwnerInfo(response.data.data)
                    }
                }}
            />
        </Fragment>
    )

}




const OwnerDetailsIcons = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [authStatus] = useContext(AuthContext);
    const getDialogTitle = () => {
        if (props.uiType === "owner_infor") {
            return "Owner Information";
        } else {
            return "Vehicle Information";
        }
    };

    const getEditMode = (uiType = null) => {

        if (uiType === "owner_infor") {
            return OwnerInfoEdit;
        } else {
            return VehicleInfoEdit;
        }
    };

    const getViewMode = (uiType = null) => {
        if (uiType === "owner_infor") {
            return OwnerInfoDisplayPopup;
        } else {
            return VehicleInfoDisplayPopup;
        }
    };

    
    return (
        <Fragment>

            {props.uiType !== "vehicle_infor" ? (
                <CheckPermission
                    permission={permissions.OWNER_INFORMATION_EDIT.permissions}
                >
                    <RoundButtonWithPopups
                        btnName="edit"
                        iconClass={"mdi mdi-pencil"}
                        btnSize={roundBtnSizes.small}
                        tooltip={`Update ${getDialogTitle()}`}
                        dialogProps={{
                            dialogTitle: `Update ${getDialogTitle()}`,
                            isCustomButton: true,
                            customButtonComponent: EditBtn,
                            customButtonComponentProps: props
                        }}
                        modelComponent={getEditMode(props.uiType)}
                        propsToModel={props}
                        isBtnDisabled={_get(props, "getVehicleStatusCode", "") !== "ELI" || _get(props, 'params.fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" ? true : false}
                    />
                </CheckPermission>
            ) : null}

            <RoundButtonWithPopups
                btnName="viewmore"
                iconClass={"mdi mdi-magnify-plus-outline"}
                btnSize={roundBtnSizes.small}
                tooltip={`View ${getDialogTitle()}`}
                dialogProps={{
                    dialogTitle: `View ${getDialogTitle()}`,
                    closeButton: {
                        btnText: 'Cancel',
                        btnName: 'close_model',
                        startIcon: 'far fa-times-circle',
                        elementStyle: 'hiddenBtn',
                        isBtnDisabled: false,
                    },
                }}
                modelComponent={getViewMode(props.uiType)}
                propsToModel={props}
                isBtnDisabled={_get(props, "getVehicleStatusCode", "") !== "ELI" ? true : false}
            />
        </Fragment>
    );
};

export { OwnerDetailsIcons }