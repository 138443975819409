import React, { Fragment, useContext, useEffect, useState } from "react";

import {
  DatePickerWithState,
  FormWrapper,
  InputButton,
  SelectBoxWithState,
  SubmitButton
} from "../../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { Reason } from "../../../../request-for-approval/pages/includes/Reason";
import DocumentsUploader from "../../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { AddLicenceTransactionAPI, getDealerCurrentValidlicenceDetailsAPI, getDealerPendingVehicleDetailsAPI, AutharizedVehicleInformationAPI, uploadDetailGetAPI } from "../../../../../../config/apiUrl.config";
import { Action } from "../../../../request-for-approval/pages/includes/Action";
import { NumberInputBoxWithState } from "../../../../../ui-components/ui-elements/form/InputBox";
import { templateTypes } from "../../../../../../config/template.config";
import { _get, _getFloat, _set } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { RequestType, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../../../config/enum.config";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { getDataByFormObject, isEmptyValue, thousandSeparator } from "../../../../../../helpers/common-helpers/common.helpers";
import VehicleInfoUploader from "./AddVehicleInformation";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";


const TransactionSubmitBtn = (props) => {
  const [formState] = useContext(FormContext);
  const [coreState, uiAction] = useContext(UIContext);

  // const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);    
  const vehicle_no = _get(formState, "dealer_search_form.searchKey", null);
  const [authStatus] = useContext(AuthContext);
  const current_licence_type = _get(formState, "vehicle_search_form.data.current_license_type.code", null);
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const request_id = _get(formState, "add_licence_transaction.request_id", null);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");

  const approvalAction = _get(
    formState,
    `add_licence_transaction.action`,
    null
  )

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ? 
  _get(authStatus, "authUser.provinceName", "<anonymous province>") : 
  ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={"Submit"}
        formGroupName={"add_licence_transaction"}
        tooltip={"Submit"}
        isValidate={true}
        startIcon={"far fa-save"}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${request_id}.`,
            messageType: "success",
          },
          // failed: props.uiType === 'assign&Perform' ? {
          //   status: true,
          //   message: `Approval Request Decision Submit Failed. Please Try Again.`,
          //   messageType: "error",
          // } : {
          //   status: true,
          //   message: `Approval Request Submit Failed. Please Try Again.`,
          //   messageType: "error",
          // },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "add_licence_transaction.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "add_licence_transaction.action", "") === 'FRD' ? (_get(formState, "add_licence_transaction.forward_role", "") === 'OPT' ?
          {
            entry_mode: "Entry Mode",
            issued_system: "Issued System",
            issued_date: "Issued Date",
            // autharized_vehicle_number: "Autharized Vehicle Number",
            // serial_number: "Serial Number",
            // licence_no: "Licence Number",
            // from_date: "Licence From Date",
            // to_date: "Licence To Date",
            fee: "Fee",
            addition: "Addition",
            deduction: "Deduction",
            print_required: "Print Required",
            reason_id: "Reason",
            remark: "Remark",
            forward_province: "Forward Province",
            forward_role: "Forward Role",
            action: "Action"
          } : {
            entry_mode: "Entry Mode",
            issued_system: "Issued System",
            issued_date: "Issued Date",
            // autharized_vehicle_number: "Autharized Vehicle Number",
            // serial_number: "Serial Number",
            // licence_no: "Licence Number",
            // from_date: "Licence From Date",
            // to_date: "Licence To Date",
            fee: "Fee",
            addition: "Addition",
            deduction: "Deduction",
            print_required: "Print Required",
            reason_id: "Reason",
            remark: "Remark",
            action: "Action"
          }) : {
            entry_mode: "Entry Mode",
            issued_system: "Issued System",
            issued_date: "Issued Date",
            // autharized_vehicle_number: "Autharized Vehicle Number",
            // serial_number: "Serial Number",
            // licence_no: "Licence Number",
            // from_date: "Licence From Date",
            // to_date: "Licence To Date",
            fee: "Fee",
            addition: "Addition",
            deduction: "Deduction",
            print_required: "Print Required",
            reason_id: "Reason",
            remark: "Remark",
            forward_province: "Forward Province",
            forward_role: "Forward Role",
            action: "Action"
          }) : {
            reason_id: "Reason",
            remark: "Remark",
            forward_province: "Forward Province",
            forward_role: "Forward Role",
            action: "Action"
          }
          ,
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "add_licence_transaction.action", "") === 'FRD' ? (_get(formState, "add_licence_transaction.forward_role", "") === 'OPT' ?
          {
            entry_mode: "required",
            issued_system: "required",
            issued_date: "required",
            // autharized_vehicle_number: "required",
            // serial_number: "required",
            // licence_no: "required",
            // from_date: "required",
            // to_date: "required",
            fee: "required",
            addition: "required",
            deduction: "required",
            print_required: "required",
            reason_id: "required",
            remark: "required|max:500",
            action: "required"
          } : {
            entry_mode: "required",
            issued_system: "required",
            issued_date: "required",
            // autharized_vehicle_number: "required",
            // serial_number: "required",
            // licence_no: "required",
            // from_date: "required",
            // to_date: "required",
            fee: "required",
            addition: "required",
            deduction: "required",
            print_required: "required",
            reason_id: "required",
            remark: "required|max:500",
            action: "required"
          }) : {
            entry_mode: "required",
            issued_system: "required",
            issued_date: "required",
            // autharized_vehicle_number: "required",
            // serial_number: "required",
            // licence_no: "required",
            // from_date: "required",
            // to_date: "required",
            fee: "required",
            addition: "required",
            deduction: "required",
            print_required: "required",
            reason_id: "required",
            remark: "required|max:500",
            action: "required"
          }) : {
            reason_id: "required",
            remark: "required|max:500",
            forward_province: "required",
            forward_role: "required",
            action: "required"
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "add_licence_transaction.action", "") === 'FRD' ? (_get(formState, "add_licence_transaction.forward_role", "") === 'OPT' ?
          {
            "entry_mode.required": "Entry Mode is required.",
            "issued_system.required": "Issued System is required.",
            "issued_date.required": "Issued date is required.",
            // "autharized_vehicle_number.required": "Autharized Vehicle Number is required.",
            // "serial_number.required": "Serial Number is required.",
            // "licence_no.required": "Licence Number is required.",
            // "from_date.required": "Licence From Date is required.",
            // "to_date.required": "Licence To Date is required.",
            "fee.required": "Fee is required.",
            "addition.required": "Addition is required.",
            "deduction.required": "Deduction is required.",
            "print_required.required": "Print Required is required.",
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
            "action.required": "Action is required."
          } : {
            "entry_mode.required": "Entry Mode is required.",
            "issued_system.required": "Issued System is required.",
            "issued_date.required": "Issued date is required.",
            // "autharized_vehicle_number.required": "Autharized Vehicle Number is required.",
            // "serial_number.required": "Serial Number is required.",
            // "licence_no.required": "Licence Number is required.",
            // "from_date.required": "Licence From Date is required.",
            // "to_date.required": "Licence To Date is required.",
            "fee.required": "Fee is required.",
            "addition.required": "Addition is required.",
            "deduction.required": "Deduction is required.",
            "print_required.required": "Print Required is required.",
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
            "action.required": "Action is required."
          }) : {
            "entry_mode.required": "Entry Mode is required.",
            "issued_system.required": "Issued System is required.",
            "issued_date.required": "Issued date is required.",
            // "autharized_vehicle_number.required": "Autharized Vehicle Number is required.",
            // "serial_number.required": "Serial Number is required.",
            // "licence_no.required": "Licence Number is required.",
            // "from_date.required": "Licence From Date is required.",
            // "to_date.required": "Licence To Date is required.",
            "fee.required": "Fee is required.",
            "addition.required": "Addition is required.",
            "deduction.required": "Deduction is required.",
            "print_required.required": "Print Required is required.",
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "action.required": "Action is required."
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
            "action.required": "Action is required."
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: props.uiType === 'assign&Perform' ? `${AddLicenceTransactionAPI.url}/update-transaction/${props.dataSubmittedForApproval.id}` : `${AddLicenceTransactionAPI.url}/create-transaction/${vehicle_no}`,
            key: AddLicenceTransactionAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            const addedVehicleData = _get(formState, "vehicle_added_data_set.data", [])
            if(addedVehicleData.length === 0 && _get(formState, "add_licence_transaction.action", undefined) !== 'REJ') {
              setTimeout(() => {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Please add atleast one vehicle to add licence transaction.`,
                  messageType: "error",
                });
              }, 2000)

              return
            }
            if (_get(formState, "add_licence_transaction.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["add_lcn_txn"],
              issued_system: isEmptyValue(fromObject.issued_system)
                ? null
                : fromObject.issued_system,
              entry_mode: isEmptyValue(fromObject.entry_mode) ? null : fromObject.entry_mode,
              issued_date: isEmptyValue(dateObjectToString(fromObject.issued_date, "dd/mm/YY HH:MM")) || fromObject.issued_date === undefined ? new Date() : fromObject.issued_date,
              vehicle_information: [],
              fee: isEmptyValue(fromObject.fee) ? null : parseFloat(fromObject.fee).toFixed(2),
              addition: isEmptyValue(fromObject.addition) ? null : parseFloat(fromObject.addition).toFixed(2),
              deduction: isEmptyValue(fromObject.deduction) ? null : parseFloat(fromObject.deduction).toFixed(2),
              print_required: isEmptyValue(fromObject.print_required) ? null : fromObject.print_required,
              current_licence_type: current_licence_type,
              // status: RequestStatus.APPROVAL_PENDING,
              request_type: RequestType.Request_Licence_Licence_Arrears,
              pdmt_id: pdmtId,
              // authority_id: authorityId,
              ui_request_id: props.approvalRequestId,
              // request_id: fromObject.request_id,
              reason_id: formState['add_licence_transaction-new'].reason_id,
              counter_session_id: '00001',
              forward_authority_service_id: serviceId,
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              },
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["add_lcn_txn"],
              issued_system: isEmptyValue(fromObject.issued_system)
                ? null
                : fromObject.issued_system,
              entry_mode: isEmptyValue(fromObject.entry_mode) ? null : fromObject.entry_mode,
              issued_date: isEmptyValue(dateObjectToString(fromObject.issued_date, "dd/mm/YY HH:MM")) || fromObject.issued_date === undefined ? new Date() : fromObject.issued_date,
              vehicle_information: [],
              fee: isEmptyValue(fromObject.fee) ? null : parseFloat(fromObject.fee).toFixed(2),
              addition: isEmptyValue(fromObject.addition) ? null : parseFloat(fromObject.addition).toFixed(2),
              deduction: isEmptyValue(fromObject.deduction) ? null : parseFloat(fromObject.deduction).toFixed(2),
              print_required: isEmptyValue(fromObject.print_required) ? null : fromObject.print_required,
              current_licence_type: current_licence_type,
              // status: RequestStatus.APPROVAL_PENDING,
              request_type: RequestType.Request_Licence_Licence_Arrears,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: fromObject.request_id,
              reason_id: fromObject.reason_id,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }       

        }}

        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "assign&Perform" ? 'put' : "post",
        }}

        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          } else {
            if(response.data?.meta?.message === 'failed' && Object.keys(response.data.errors).length === 1) {
              uiAction.setFlashMessage({
                status: true,
                message: `${response.data.errors?.name ?? 'Approval Request Decision Submit Failed. Please Try Again.'}`,
                messageType: "error",
              });
            }
            if (props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
              });
            }
            else {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
        }}
      />
    </Fragment>
  );
};


const AddLicenceTransaction = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const { uiType, formKey, requestType, approvalRequestId, dataSubmittedForApproval } = props;
  const today = new Date();
  const [requestId, setRequestId] = useState(null);
  const mandatory = props.uiType === 'APR' ? true : false;

  let total = uiType === 'APR' ? (
    _getFloat(formState, `${formKey}.fee`, 0) +
    _getFloat(formState, `${formKey}.addition`, 0) -
    _getFloat(formState, `${formKey}.deduction`, 0)
  ).toFixed(2) : (
    _getFloat(formState, `${formKey}.fee`, 0) +
    _getFloat(formState, `${formKey}.addition`, 0) -
    _getFloat(formState, `${formKey}.deduction`, 0)
  ).toFixed(2);

  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();
    setRequestId(requ.substr(requ.length - 8))
    getReqApprovalData(props)
  }, [])
  // ${getDealerPendingVehicleDetailsAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.dealer_id : dealer_id}
  const getReqApprovalData = (props) => {
    const licencedDealerV = sendAPIRequest(`${getDealerCurrentValidlicenceDetailsAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.dealer_id : _get(formState, 'dealer_search_form.searchKey', "")}?sort_by=vehicle_number|asc,validity_from|desc`);
    const  pendingDealerV = sendAPIRequest(`${getDealerPendingVehicleDetailsAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.dealer_id : _get(formState, 'dealer_search_form.searchKey', "")}`);
    
    Promise.all([pendingDealerV, licencedDealerV])
    .then(result => {
      const pendingVehicles = result[0]['data']
      const licencedVehicles = result[1]['data']

      console.log('PEEEEEEEE', pendingVehicles);
      console.log('PEEEEEEEE22', licencedVehicles);

      formAction.changeInputFn("vehicle_data_set", "data", "", [...pendingVehicles, ...licencedVehicles]);
    })
    // callApi(`${getDealerCurrentValidlicenceDetailsAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.dealer_id : _get(formState, 'dealer_search_form.searchKey', "")}?sort_by=vehicle_number|asc,validity_from|desc`)
    //   .method("get")
    //   .headers(true)
    //   .isMultipart(false)
    //   .send((error, result) => {
    //     if (!error) {
    //       formAction.changeInputFn("vehicle_data_set", "data", "", result.data.data);
    //     }
    //   })
  }
  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result?.data);
        })
    });
  };

  return (
    <div className={"container-fluid p-0"}>
     
        <FormWrapper
          setGroupName={formKey}
          apiUrl={`${AddLicenceTransactionAPI.url}/get-transaction/${props.uiType === "APR" ? props.approvalRequestId : null}`}
          onRebuildResponseFn={(response) => {

            if (props.uiType === "APR") {
              return {
                issued_system: response.issued_system,
                entry_mode: response.entry_mode,
                issued_date: response.issued_date,
                fee: response.fee,
                addition: response.addition,
                deduction: response.deduction,
                print_required: response.print_required,

                "_onLoad": true
              }
            }

          }}
          setFormObject={props.uiType === "APR" ? { ui_request_id: props.approvalRequestId } : { request_id: _set(formState, `${formKey}.request_id`, requestId), }} >
          <UICard>
          <div className="row addLicenceLabel">
                <div className="col-md-3 col-sm-6 mt">Issued System <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                <div className="col-md-3 col-sm-6">
                  <SelectBoxWithState
                    formGroupName={formKey}
                    dataList={[
                      {
                        id: "ERL1",
                        value: "ERL 1.0",
                      },
                      {
                        id: "ERL2",
                        value: "ERL 2.0",
                      },
                    ]}
                    inputName={"issued_system"}
                    labelText=""
                    emptySelectOptionTxt={"Select Issued System"}
                  />
                </div>
              </div>

              <div className="row addLicenceLabel">
                <div className="col-md-3 col-sm-6 mt">Entry Mode <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                <div className="col-md-3 col-sm-6">
                  <SelectBoxWithState
                    formGroupName={formKey}
                    dataList={[
                      {
                        id: "MIS",
                        value: "Missing Licence",
                      },
                      {
                        id: "COR",
                        value: "Licence Correction",
                      },
                    ]}
                    inputName={"entry_mode"}
                    labelText=""
                    emptySelectOptionTxt={"Select Entry Mode"}
                  />
                </div>
              </div>

              <div className="row addLicenceLabel">
                <div className="col-md-3 col-sm-6 mt">Issued Date <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                <div className="col-md-3 col-sm-6">
                  <DatePickerWithState
                    uiType={templateTypes.bootstrap}
                    formGroupName={formKey}
                    labelText=""
                    inputName={"issued_date"}
                    maxDate={today}
                  />
                </div>
              </div>

          

          </UICard>
          <UICard>
          
              <VehicleInfoUploader
                isUploadFn={true}
                formGroupName={`${formKey}-vehicleupload`}
                tableApiUrl={props.uiType === "APR" ? `${AutharizedVehicleInformationAPI.url}/get-vehicle-info?sort_by=created_at%7Cdesc&request_id=${props.approvalRequestId}` : requestId ? `${AutharizedVehicleInformationAPI.url}/get-vehicle-info?sort_by=created_at%7Cdesc&request_id=${requestId}` : null}
                requestId={props.uiType === "APR" ? props.approvalRequestId : requestId}
                counterId={props.counterId}
                uiType={props.uiType}
              />
           
          </UICard>


          <UICard>

            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Fee (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <NumberInputBoxWithState
                  formGroupName={formKey}
                  elementStyle={"text-right"}
                  inputName={"fee"}
                  isAllowNegative={false}
                  isThousandSeparator={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }

                    return checkValue.length <= 10;
                  }}
                />
              </div>
            </div>

            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Additions (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <NumberInputBoxWithState
                  formGroupName={formKey}
                  elementStyle={"text-right"}
                  inputName={"addition"}
                  isAllowNegative={false}
                  isThousandSeparator={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }

                    return checkValue.length <= 10;
                  }}
                />
              </div>
            </div>

            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Deductions (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <NumberInputBoxWithState
                  formGroupName={formKey}
                  elementStyle={"text-right"}
                  inputName={"deduction"}
                  isAllowNegative={false}
                  isThousandSeparator={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }

                    return checkValue.length <= 10;
                  }}
                />
              </div>
            </div>

            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Total (LKR) <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6 mt text-right ">{thousandSeparator(total,true)}</div>
            </div>

            <div className="row addLicenceLabel">
              <div className="col-md-3 col-sm-6 mt">Print Required <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
              <div className="col-md-3 col-sm-6">
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={[
                    {
                      id: "1",
                      value: "Required",
                    },
                    {
                      id: "0",
                      value: "Not Required",
                    },
                  ]}
                  inputName={"print_required"}
                  labelText=""
                  emptySelectOptionTxt={"Select From Here"}
                />
              </div>
            </div>
          </UICard>

        </FormWrapper>
     
      <div className={"row"}>
        <div className="col-md-12 col-sm-12 defaultMarginTop">
          <Reason filterKey={'add_licence_transaction'} formKey={uiType === 'APR' ? `${formKey}-new` : formKey} requestType={uiType}
            dataSubmittedForApproval={props.dataSubmittedForApproval} />
        </div>

        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <DocumentsUploader
            requestType={requestType}
            isUploadFn={true}
            formGroupName={`${formKey}-upload`}
            tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
            requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
            uiType={uiType}
          />
        </div>

        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <Action
            formKey={formKey}
            uiType={uiType}
            dataSubmittedForApproval={props.dataSubmittedForApproval}
          />
        </div>
      </div>

    </div>
  );
};

const AddLicenceTransactionBtn = (props) => {
  return (
    <InputButton
      btnText={"Submit"}
      onClickBtnFn={() => alert("Hello")}
    />
  );
};

export {
  AddLicenceTransaction, AddLicenceTransactionBtn, TransactionSubmitBtn
};
