import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DataTable, actionBtnTypes } from '../../../../../ui-components/ui-elements/table/DataTable';
import { _get } from '../../../../../../helpers/common-helpers/lodash.wrappers';
import { quotationVehicleDeleteAPI, quotationVehicleListAPI, quotationVehicleUpdateAPI, userDelegationChangeAPI, userListAPI } from '../../../../../../config/apiUrl.config';
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import NormalLicence from '../../normal-licence/NormalLicence';
import { DeleteVehicle, DeleteVehicleBtn } from './DeleteVehicle';
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import IssueQuotation from '../../../../payment/pages/IssueQuotation';
import { DialogPopup } from '../../../../../ui-components/ui-elements/modal/DialogPopup';
import { getDataByFormObject, getLabelValue, isEmptyValue, thousandSeparator } from '../../../../../../helpers/common-helpers/common.helpers';
import { UICard, ViewDetailLeftBlock } from '../../../../../ui-components/ui-elements/common/BaseElements';
import { apiResponseStoringType, CoreContext } from '../../../../core/context-providers/CoreContext.provider';
import { FormWrapper, InputButton, SubmitButton } from '../../../../../ui-components/ui-elements/form';
import { callApi } from '../../../../../../helpers/common-helpers/callApi.helpers';
import { MaxArrearsPeriod } from '../../../../../../config/enum.config';

const QuotationConfirmation = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const formKey = `${props.fromParent.formKey}`;
    let btnStatus = 'ACT'

    const getBtnStatusCode = () => {
        const today = new Date();
        const docValidations = _get(formState, 'quotation_payment.documentValidations', []);
        const quotationVehiclesData = _get(coreState, 'dataTableResponses.quotationVehicleListKey.results', []);
        for (const quotationVehicle of quotationVehiclesData) {
            if (new Date(quotationVehicle.pmt_calculated_at).toDateString() !== today.toDateString()) {
                btnStatus = 'INA';
            }
        }
        
        if(docValidations.length !== quotationVehiclesData.length){
            btnStatus = 'INA';
        }
        return btnStatus;
    }

    const PayButtons = (props) => {
        const [formState, formAction] = useContext(FormContext);
        return <InputButton
            btnText={"Pay"}
            tooltip={"Pay"}
            startIcon="far fa-save"
            isBtnDisabled={getBtnStatusCode() === "ACT" ? false : true}
            onClickBtnFn={() => {
                formAction.changeInputFn('quotation_payment', "isPaymentPopupOpen", "", true);
            }
            }
        />
    }

    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            setFormObject={{
                id: props.row.id,
                isPopupOpen: false,
                isPaymentPopupOpen: false
            }}
            onDestroyUnsetFormObject={true}
        >
            <div className="removeContent">
                <p>This will update the quotation, are you sure you want to proceed?</p>
                <br />
                <br />
            </div>

            <DialogPopup
                isOpen={_get(formState, `${formKey}.isPopupOpen`, false)}
                isFullScreen={true}
                dialogTitle={"Proceed Quotation Payment"}
                isCustomButton={true}
                customButtonComponent={PayButtons}
                customButtonComponentProps={{
                    qtData: props
                }}
                onClosePopupFn={() => {
                    props.onClosePopupFn();
                }

                }
                closeButton={{
                    btnText: "Back"
                }}
                isConfirmationPopup={true}
            >
                <QuotationDetails
                    row={props.row}
                    mainPopupCloseFn={props.onClosePopupFn}
                />
            </DialogPopup>

        </FormWrapper>
    );
};

const QuotationDetails = (props) => {

    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const [getState, setState] = useState({
        status: false,
        modelType: "",
    });

    const closePopup = () => {
        setState({
            ...getState,
            status: false,
            modelType: "",
        })
    };

    const latestLicence = {}

    useEffect(() => {
        callApi(`${userDelegationChangeAPI.url}/internal-user/${_get(props, 'row.created_by', "")}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    setState({
                        ...getState,
                        "user": result.data.data
                    })

                }
            });
    }, []);

    let totalArrearsFee = 0;
    let totalArrearsFine = 0;
    let totalFee = 0;
    let totalFine = 0;
    let totalAddition = 0;
    let totalDeduction = 0;
    let totalServiceCharge = 0;
    let grandTotal = 0;

    const results = _get(coreState, `dataTableResponses.quotationVehicleListKey.results`, "");
    if (results.length > 0) {
        results.forEach(element => {
            totalFee += parseFloat(element.fee);
            totalArrearsFee += parseFloat(element.arrears_fee);
            totalArrearsFine += parseFloat(element.arrears_fine);
            totalFine += parseFloat(element.fine);
            totalAddition += parseFloat(element.addition);
            totalDeduction += parseFloat(element.deduction);
            totalServiceCharge += parseFloat(element.service_charge);
        });
    }

    grandTotal = 0;

    const getYearDiffWithMonth = (startDate, endDate) => {

        if (startDate == null || endDate == null) {
            return 0
        } else {
            const ms = (new Date(endDate).getTime()) - (new Date(startDate).getTime());

            const date = new Date(ms);
            return Math.abs(date.getUTCFullYear() - 1970);
        }

    }

    const btnDisabled = (tab) => {
        let isDissable = false;

        if (_get(formState, "vehicle_search_form.data.document_validations.vet", false) === true && _get(formState, "vet_certificate_form.isVet", false) == false) {
            isDissable = true;
        }
        if (_get(formState, "vehicle_search_form.data.document_validations.insurance", false) === true && _get(formState, "insurance_form.isInsurance", false) == false) {
            isDissable = true;
        }
        if (_get(formState, "vehicle_search_form.data.document_validations.vft", false) === true && _get(formState, "vft_certificate_form.isfitness", false) == false) {
            isDissable = true;
        }
        if (_get(formState, "vehicle_search_form.data.document_validations.vrp", false) === true && _get(formState, "vrp_form.isroutePerm", false) == false) {
            isDissable = true;
        }

        if (parseFloat(_get(formState, "licence_charges_form.total", 0)) - parseFloat(_get(formState, "licence_charges_form.serviceCharge", 0)) === 0 && _get(formState, "vehicle_search_form.data.current_license_type.code", "FE") !== "FE") {
            isDissable = true;
        }
        if (_get(formState, "vehicle_search_form.documentRequest.issue_mode", "") === "FULL") {
            isDissable = false;
        }
        if (getYearDiffWithMonth((_get(formState, "licence_charges_form.data.arrears_valid_from", null)), (_get(formState, "licence_charges_form.data.arrears_valid_to", null))) > MaxArrearsPeriod.NumberOfYears) {
            isDissable = true;
        }
        if (_get(formState, `pending_workflow_key.workflows`, []).length > 0) {
            isDissable = true
        }

        return isDissable;
    };

    const CustomButtons = (props) => {
        const docValidations = _get(formState, 'quotation_payment.documentValidations', []);
        return <SubmitButton
            btnText={"Update"}
            formGroupName={"normalLicence"}
            dataTableKey={"quotationVehicleListKey"}
            tooltip={"Update Vehicle"}
            startIcon="far fa-edit"
            isValidate={false}
            isBtnDisabled={btnDisabled()}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Quotation Vehicle is successfully updated.",
                    messageType: "success"
                },
                "failed": {
                    status: true,
                    message: "Quotation Vehicle updating fail.",
                    messageType: "error"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false,
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm
            }}
            onGetAPIEndPointFn={(formState) => {
                return {
                    url: `${quotationVehicleUpdateAPI.url}/${_get(props, 'row.id', "")}`,
                    key: quotationVehicleUpdateAPI.key
                }
            }}
            onChangeRequestBodyFn={() => {
                return getDataByFormObject({
                    "quotation_id": _get(props, 'row.id', ""),
                    "pmt_arrears_fee": _get(formState, 'licence_charges_form.totArrearsFee', ""),
                    "pmt_arrears_fine": _get(formState, 'licence_charges_form.totArrearsFine', ""),
                    "pmt_fee": _get(formState, 'licence_charges_form.totCurrentLicenceFee', ""),
                    "pmt_fine": _get(formState, 'licence_charges_form.totCurrentLicenceFine', ""),
                    "pmt_addition": _get(formState, 'licence_charges_form.totAddition', ""),
                    "pmt_deduction": _get(formState, 'licence_charges_form.totdeduction', ""),
                    "pmt_service_charge": _get(formState, "licence_charges_form.serviceCharge", 0),
                    "pmt_total": _get(formState, 'licence_charges_form.total', ""),
                    "pmt_calculated_at": new Date(),
                    "document_validation_status": 0,
                })
            }}
            onResponseCallBackFn={(errors, response) => {
                const vehicle_no = _get(formState, 'vehicle_search_form.searchKey', null);
                if (!errors) {
                    const index = docValidations.map(e => e.vehicle_no).indexOf(vehicle_no);
                    const document_validations = {
                        vehicle_no: vehicle_no,
                        insurance_status: _get(formState, 'insurance_form.status', null) === "ACT" ? true : null,
                        insurance_id: isEmptyValue(_get(formState, 'insurance_form.id', null)) ? null : _get(formState, 'insurance_form.id', null),
                        insurance_service_id: _get(formState, 'insurance_form.insurance', null),
                        insurance_reference: _get(formState, 'insurance_form.reference', null),
                        insurance_expiry_date: _get(formState, 'insurance_form.exp_date', null),

                        vet_status: (_get(formState, 'vet_certificate_form.status', null) === true && _get(formState, 'vet_certificate_form.used', null) === false) || (_get(formState, 'vet_certificate_form.vetExpiryDate', null) === null || undefined) ? true : null,
                        vet_id: isEmptyValue(_get(formState, 'vet_certificate_form.id', null)) ? null : _get(formState, 'vet_certificate_form.id', null),
                        vet_service_id: _get(formState, 'vet_certificate_form.vetCompany', null),
                        vet_no: _get(formState, 'vet_certificate_form.vet_no', null),
                        vet_expiry_date: _get(formState, 'vet_certificate_form.vetExpiryDate', null) ? dateObjectToString(_get(formState, 'vet_certificate_form.vetExpiryDate', null)) : null,
                        vet_issued_date: _get(formState, "vet_certificate_form.vetDateTime", null) ? dateObjectToString(_get(formState, "vet_certificate_form.vetDateTime", null), "YY-mm-dd") : null,

                        vft_status: _get(formState, 'vft_certificate_form.type', null) === 'PSS' ? true : _get(formState, 'vft_certificate_form.type', null) === 'FAL' ? false : null,
                        vft_id: isEmptyValue(_get(formState, 'vft_certificate_form.id', null)) ? null : _get(formState, 'vft_certificate_form.id', null),
                        vft_service_id: _get(formState, 'vft_certificate_form.vft', null),
                        vft_no: _get(formState, 'vft_certificate_form.certNo', null),
                        vft_expiry_date: _get(formState, 'vft_certificate_form.exp_date', null),
                        vft_issued_date: (_get(formState, "vft_certificate_form.issuedDate", null)) ? dateObjectToString(_get(formState, "vft_certificate_form.issuedDate", null), "YY-mm-dd") : null,

                        vrp_status: (_get(formState, 'vrp_form.status', null) === "Active" || !isEmptyValue(_get(formState, 'vrp_form.issuedDate', null))) ? true : null,
                        vrp_expiry_date: _get(formState, 'vrp_form.expiryDate', null),
                        vrp_issued_date: _get(formState, 'vrp_form.issuedDate', null),
                        vrp_id: isEmptyValue(_get(formState, 'vrp_form.vrpId', null)) ? null : _get(formState, 'vrp_form.vrpId', null),
                        vrp_service_id: _get(formState, 'vrp_form.vrpCompany', null),
                        vrp_no: _get(formState, 'vrp_form.vrpNo', null),
                    }

                    if(index === -1){
                        docValidations.push(document_validations);
                    }else{
                        docValidations[index] = document_validations;
                    }
                    formAction.changeInputFn('quotation_payment', "documentValidations", "", docValidations);
                    props.onClosePopupFn();
                }
            }}

        />
    }

    const DocValidated = (vehicleNo, documentValidationStatus) => {
        let validated = "Not Completed";
        const docValidations = _get(formState, 'quotation_payment.documentValidations', []);
        const index = docValidations.map(e => e.vehicle_no).indexOf(vehicleNo);

        if(index !== -1){
            validated = "Completed";
        }
        if(documentValidationStatus){            
            validated = "Completed";
        }
        return validated;
    }
    return (
        <Fragment>
            <div className={"col-md-12"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <strong>Quotation No.: </strong> {_get(props, 'row.quotation_no', "")}
                    </div>
                    <div className={"col-md-6"}>
                        <strong>Created By: </strong> {_get(getState, 'user.display_name', "") + " " + "(" + _get(getState, 'user.delegation[0].role.name', "") + ")"}
                    </div>
                    <div className={"col-md-6"}>
                        <strong>Created Date & Time: </strong> {isEmptyValue(_get(props, 'row.created_at', "")) ? '-' : dateObjectToString(_get(props, 'row.created_at', ""), "dd/mm/YY HH:MM")}
                    </div>
                    <div className={"col-md-6"}>
                        <strong>Payment Date & Time: </strong> {isEmptyValue(_get(props, 'row.payment_date', "")) ? 'Payment Pending' : dateObjectToString(_get(props, 'row.payment_date', ""), "dd/mm/YY HH:MM")}
                    </div>

                    <div className={"col-md-12 defaultMarginTop"}>
                        <div className={"row"}>
                            <DataTable
                                tableName="Quotation Information"
                                apiUrl={`${quotationVehicleListAPI.url}?quotation_id=${_get(props, 'row.id', "")}&sort_by=created_at|desc`}
                                dataTableKey={quotationVehicleListAPI.key}
                                tableHeaderList={[
                                    { displayName: "Vehicle ID", key: "vehicle_no", isSort: false, cellStyle:{ 'white-space': 'nowrap'} },
                                    { displayName: "Chassis Number", key: "chassis_no", isSort: false, onPrintFn: ({chassis_no}) => getLabelValue(chassis_no, true) },
                                    { displayName: "Vehicle Class", key: "vehicle_class_id.name", isSort: false },
                                    { displayName: "Owner's Name", key: "owner_name", isSort: false, onPrintFn: ({owner_name}) => getLabelValue(owner_name, true) },
                                    { displayName: "Licence Type", key: "licence_type_id.name", isSort: false },
                                    {
                                        displayName: "Validity Period (From - To)", key: "validity", isSort: false, onPrintFn: (rawData) => {
                                           /* const d = new Date(rawData.validity_to);
                                            const year = d.getFullYear();
                                            const month = d.getMonth();
                                            const day = d.getDate();
                                            const validityFrom = new Date(year, month, day+1);
                                            const validityTo = new Date(year + 1, month, day-1);
                                            */
                                            const validityFrom = new Date(rawData.validity_from);
                                            const validityTo = new Date(rawData.validity_to);
                                            const license_validity_from = (rawData.validity_from) ? dateObjectToString(validityFrom, "dd/mm/YY") : "";
                                            const license_validity_to = (rawData.validity_to) ? dateObjectToString(validityTo, "dd/mm/YY") : "";
                                            return license_validity_from + " - " + license_validity_to;
                                        }
                                    },
                                    { displayName: "Arrears Fee(LKR)", key: "arrears_fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.arrears_fee) ? '-' : thousandSeparator(_get(rawData, 'arrears_fee', 0), true) },
                                    { displayName: "Arrears Fine(LKR)", key: "arrears_fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.arrears_fine) ? '-' : thousandSeparator(_get(rawData, 'arrears_fine', 0), true) },
                                    { displayName: "Fee(LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.fee) ? '-' : thousandSeparator(_get(rawData, 'fee', 0), true) },
                                    { displayName: "Fine(LKR)", key: "fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.fine) ? '-' : thousandSeparator(_get(rawData, 'fine', 0), true) },
                                    { displayName: "Additions(LKR)", key: "addition", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.addition) ? '-' : thousandSeparator(_get(rawData, 'addition', 0), true) },
                                    { displayName: "Deductions(LKR)", key: "deduction", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.deduction) ? '-' : thousandSeparator(_get(rawData, 'deduction', 0), true) },
                                    { displayName: "Service Charge(LKR)", key: "service_charge", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.service_charge) ? '-' : thousandSeparator(_get(rawData, 'service_charge', 0), true) },
                                    { displayName: "Total(LKR)", key: "total", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.total) ? '-' : thousandSeparator(_get(rawData, 'total', 0), true) },
                                    { displayName: "Document Validation", key: "document_validation_status", isSort: false, onPrintFn: (rawData) => (DocValidated(rawData.vehicle_no, rawData.document_validation_status)) },
                                ]}
                                isSetAction={true}
                                isSetTableFooter={false}
                                actionTypeList={[
                                    {
                                        actionType: actionBtnTypes.view,
                                        modelContentElement: NormalLicence,
                                        actionBtn: {
                                            tooltip: 'View Vehicle Details',
                                            btnText: "View Vehicle Details",
                                            elementStyle: "setWidthActionBtn",
                                        },
                                        dialogPopupProps: {
                                            isFullScreen: true,
                                            dialogTitle: "View Vehicle Information",
                                            closeButton: {
                                                btnText: "Close",
                                                startIcon: "far fa-times-circle",
                                            },
                                            isSaveButton: false,
                                            isCustomButton: false,
                                            isConfirmationPopup: true,
                                        },
                                        parentToModelProps: {
                                            uiType: "QUOTATION_VEHICLE_DETAILS_VIEW",
                                            formKey: "",
                                            vehicleNumber: _get(props, 'row.vehicle_no', "")

                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.delete,
                                        actionBtn: {
                                            tooltip: 'Delete Vehicle',
                                            elementStyle: "setWidthActionBtn",
                                        },
                                        modelContentElement: DeleteVehicle,
                                        dialogPopupProps: {
                                            isFullScreen: false,
                                            dialogTitle: "Remove Quotation Vehicle",
                                            closeButton: {
                                                btnText: "No",
                                                startIcon: "far fa-times-circle",
                                            },
                                            isCustomButton: true,
                                            customButtonComponent: DeleteVehicleBtn,
                                            customButtonComponentProps: {
                                                dataTableKey: quotationVehicleListAPI.key,
                                                uiType: "delete",
                                            },
                                            closeButton: {
                                                btnText: "No",
                                                startIcon: "far fa-times-circle",
                                            },
                                        },
                                        parentToModelProps: {
                                            formKey: quotationVehicleDeleteAPI.key,
                                            dataTableKey: quotationVehicleListAPI.key,
                                        },
                                        checkBeforeActionRenderFn: (rawData) => {
                                            return (_get(props, 'row.payment_date', null) !== null) ? false : true;
                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.edit,
                                        modelContentElement: NormalLicence,
                                        actionBtn: {
                                            tooltip: 'Update Vehicle',
                                            btnText: "Update Vehicle",
                                            elementStyle: "setWidthActionBtn",
                                        },
                                        dialogPopupProps: {
                                            isFullScreen: true,
                                            dialogTitle: "Update Vehicle",
                                            closeButton: {
                                                btnText: "Cancel",
                                                startIcon: "far fa-times-circle",
                                            },
                                            isSaveButton: false,
                                            isCustomButton: true,
                                            customButtonComponent: CustomButtons,
                                            isConfirmationPopup: true,
                                            customButtonComponentProps: {
                                                btnText: "Update",
                                                uiType: "CREATE",
                                                dataTableKey: ""
                                            },
                                        },
                                        parentToModelProps: {
                                            uiType: "QUOTATION_UPDATE",
                                            formKey: "",
                                            vehicleNumber: _get(props, 'row.vehicle_no', "")

                                        },
                                        checkBeforeActionRenderFn: () => {
                                            return (_get(props, 'row.payment_date', null) !== null) ? false : true;
                                        }
                                    }

                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    &nbsp;
                </div>

                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <strong>Quotation Details</strong>
                        <UICard elementStyle="defaultMarginTop">
                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={'Total Arrears Fee'}
                                    value={thousandSeparator(totalArrearsFee, true)}
                                    labelCol={"3"}
                                />
                            </div>

                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={'Total Arrears Fine'}
                                    value={thousandSeparator(totalArrearsFine, true)}
                                    labelCol={"3"}
                                />
                            </div>

                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={"Total Fee"}
                                    value={thousandSeparator(totalFee, true)}
                                    labelCol={"3"}
                                />
                            </div>

                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={"Total Fine"}
                                    value={thousandSeparator(totalFine, true)}
                                    labelCol={"3"}
                                />
                            </div>
                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={"Total Additions"}
                                    value={thousandSeparator(totalAddition, true)}
                                    labelCol={"3"}
                                />
                            </div>

                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={"Total Deductions"}
                                    value={thousandSeparator(totalDeduction, true)}
                                    labelCol={"3"}
                                />
                            </div>

                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={"Total Service Charges"}
                                    value={thousandSeparator(totalServiceCharge, true)}
                                    labelCol={"3"}
                                />
                            </div>

                            <div className="col-md-12 col-sm-12 floatLeft">
                                <ViewDetailLeftBlock
                                    label={"Total"}
                                    value={
                                        thousandSeparator((((totalAddition + totalArrearsFee + totalArrearsFine + totalFee + totalFine)
                                            - totalDeduction)
                                            + totalServiceCharge), true)
                                    }
                                    labelCol={"3"}
                                />
                            </div>

                        </UICard>
                    </div>
                    {
                        isEmptyValue(_get(props, 'row.payment_date', "")) ? 
                            null
                        : 
                            <div className={"col-md-6"}>
                                <strong>Payment Details</strong>
                                <UICard elementStyle="defaultMarginTop">
                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={'Total Arrears Fee'}
                                            value={thousandSeparator(totalArrearsFee, true)}
                                            labelCol={"3"}
                                        />
                                    </div>

                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={'Total Arrears Fine'}
                                            value={thousandSeparator(totalArrearsFine, true)}
                                            labelCol={"3"}
                                        />
                                    </div>

                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={"Total Fee"}
                                            value={thousandSeparator(totalFee, true)}
                                            labelCol={"3"}
                                        />
                                    </div>

                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={"Total Fine"}
                                            value={thousandSeparator(totalFine, true)}
                                            labelCol={"3"}
                                        />
                                    </div>
                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={"Total Additions"}
                                            value={thousandSeparator(totalAddition, true)}
                                            labelCol={"3"}
                                        />
                                    </div>

                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={"Total Deductions"}
                                            value={thousandSeparator(totalDeduction, true)}
                                            labelCol={"3"}
                                        />
                                    </div>

                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={"Total Service Charges"}
                                            value={thousandSeparator(totalServiceCharge, true)}
                                            labelCol={"3"}
                                        />
                                    </div>

                                    <div className="col-md-12 col-sm-12 floatLeft">
                                        <ViewDetailLeftBlock
                                            label={"Total"}
                                            value={
                                                thousandSeparator((((totalAddition + totalArrearsFee + totalArrearsFine + totalFee + totalFine)
                                                    - totalDeduction)
                                                    + totalServiceCharge), true)
                                            }
                                            labelCol={"3"}
                                        />
                                    </div>

                                </UICard>
                            </div>
                    }

                </div>
                <div>
                    &nbsp;
                </div>
                <div>
                    <DialogPopup
                        isOpen={_get(formState, `quotation_payment.isPaymentPopupOpen`, false)}
                        isFullScreen={true}
                        dialogTitle={"Make Payment - Quotation"}
                        onClosePopupFn={() => {
                            formAction.changeInputFn('quotation_payment', "isPaymentPopupOpen", "", false);
                            // formAction.changeInputFn('quotation_payment', "documentValidations", "", []);
                        }

                        }
                        closeButton={{
                            btnText: "Back"
                        }}
                        isConfirmationPopup={true}
                        confirmationMessage={"Are you sure you want to go back?"}
                    >
                        <IssueQuotation
                            uiType={"quotation_payment"}
                            latestLicence={latestLicence}
                            closeMainPopup={closePopup}
                            qtData={props}
                            mainPopupCloseFn={props.mainPopupCloseFn}
                        />
                    </DialogPopup>
                </div>
            </div>

        </Fragment>
    )
}

export {
    QuotationDetails,
    QuotationConfirmation
};