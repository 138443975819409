import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get, } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from '../../../../../config/permission.config';
function OnlineLicenseDetails() {
  const [authStatus, authAction] = useContext(AuthContext);
  const ViewPermission = _get(authStatus, 'authUser.permissions', "");
  let FR31, FR32, FR33, FR34
  ViewPermission.forEach(item => {
    if (item.permission === permissions.VIEW_FR31_ONLINE_LICENCE_REQUEST.permissions[0]) {
      FR31 = true
    }
    else if (item.permission === permissions.VIEW_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT.permissions[0]) {
      FR32 = true
    }
    else if (item.permission === permissions.VIEW_FR33_ONLINE_TRANSACTION_REPORT.permissions[0]) {
      FR33 = true
    }
    else if (item.permission === permissions.VIEW_FR34_PRINTED_WEB_LICENCES_REPORT.permissions[0]) {
      FR34 = true
    }
  })
  return (
    <UICard>
      < ol type="1">
        <div className="App">
          {FR31 &&
            <li>
              <Link to="/online-license-request-report">
                <strong>Online Licence Request Report</strong></Link>
              <br />
              <p> This is the Online Licence Request Report</p>
              <br />

            </li>}

          {FR32 &&
            <li>
              <Link to="/online-license-renewal-request-report">
                <strong>Online Licence Renewal Request Report</strong></Link>
              <br />
              <p> This is the Online Licence Renewal Request Report</p>
              <br />
            </li>}

          {FR33 && <li>
            <Link to="/online-transaction-report">
              <strong>Online Transaction Report</strong></Link>
            <br />
            <p> This is the Online Transaction Report</p>
            <br />
          </li>}

          {FR34 && <li>
            <Link to="/printed-web-license-report">
              <strong> Printed Web Licences Report</strong></Link>
            <br />
            <p> This is the Printed Web Licences Report</p>
          </li>}
        </div>
      </ ol>
    </UICard>
  );
};

const OnlineLicenseDetailsReport = () => {
  return (
    <DashboardTemplate
      pageTitle="Online Licence Details Reports"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          //urlTo: "/report",
          displayName: "Online Licence Details Reports List",
        },
      ]}
    >
      <OnlineLicenseDetails />
    </DashboardTemplate>
  );
};

export { OnlineLicenseDetailsReport };