import React, {useContext} from "react";    
import { FormWrapper } from "../../../ui-components/ui-elements/form";
import { DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import {
  approvalHistoryGetAPI, actionApi
} from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { RowNo } from "../../../ui-components/ui-elements/documents-uploader/documents-uploader-includes/documents-uploader-includes";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";

const ViewApprovalHistory = (props) => {
  const [formState] = useContext(FormContext);
    const formKey = "ViewApprovalHistory";
    const vehicle_no = _get(
      formState,
      "vehicle_search_form.searchKey",
      ""
    );
    const dealer_id = _get(formState, "dealer_search_form.searchKey", "");
    return ( 
            <FormWrapper setGroupName={formKey}>  
              <DataTable
                tableName={"View Approval History"}
                apiUrl={`${actionApi.url}/${props.requestId}`}
                isSetSearchFrom={false}
                isSetAction={false}
                dataTableKey={formKey}
                isSetTableHeader={false} 
                tableHeaderList={[
                  { displayName: "No.", key: "__no__", isSort: false },
                  { displayName: "Date & Time", key: "created_at",  isSort: false,
                  onPrintFn: (rawData) => {
                    return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM")} },
                  { displayName: "Action", key: "action", onPrintFn: (rawData) => {
                    if(rawData.action === 'FRD') {
                      return 'Forward'
                    } else if(rawData.action === 'APP' || rawData.action === 'APR') {
                      return 'Approved'
                    } else {
                      return 'Rejected'
                    }
                  }, isSort: false, },
                  { displayName: "Forwarded Location", key: "forwarded_location", isSort: false, },
                  { displayName: "Forwarded Role", key: "forward_role_code",onPrintFn: (rawData) => {
                    console.log('rowww--', rawData);
                    if(rawData.forward_role_code === 'OPT') {
                      return 'Operator'
                    } else if(rawData.forward_role_code === 'SUP') {
                      return 'Supervisor'
                    } else if(rawData.forward_role_code === 'COM') {
                      return 'Commissioner'
                    } else if(rawData.forward_role_code === 'ACC') {
                      return 'Accountant'
                    } else {
                      return '-'
                    }
                  }, isSort: false, },
                  { displayName: "Action by", key: "created_by", isSort: false, },
                  {
                    displayName: "Action Role & Location", key: "created_role_id", onPrintFn: (rawData) => {
                        return rawData.created_role_id.split('-')[0] + " & " + rawData.created_location
                    }, isSort: false
                },
                  { displayName: "Remark", key: "remark", isSort: false }, 
                ]}
                specificComponents={{
                  "__no__": RowNo
              }}
              /> 
            </FormWrapper> 
    )
};

export { ViewApprovalHistory };