/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-06-25 13:10:44
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-07-14 11:09:44
 */

import { uploadComponentConfig, validImageTypes } from "../../config/core.config";

const getValidFiles = (fileArray, count) => {
    let temp = [];

    if (Array.isArray(fileArray)) {
        fileArray.forEach((value, index) => {
            if (index < count) {
                temp.push(value);
            }
        });
    } else {
        temp = [fileArray]
    }

    return temp;
};

const getFileType = (stringValue) => {
    const temp = stringValue.split('.');
    return temp[temp.length - 1];
};

const getRelevantImage = (stringValue) => {
    const type = getFileType(stringValue);
    const images = ["png", "jpeg", "jpg"];

    if (images.includes(type.toLowerCase())) {
        return null;
    } else {
        return uploadComponentConfig[type];
    }
};

const checkValidFileType = (file, type, validTypes) => {
    const temp = [];

    if (type === "image") {
        validTypes.forEach((value) => {
            temp.push(validImageTypes[value]);
        });

        return temp.includes(file);
    } else {
        return true;
    }
};

// Image Compression Helpers
const compressImages = async (imageList) => {
    return new Promise(async (resolve) => {
        const fileList = [];

        for (const image of imageList) {
            const blobURL = URL.createObjectURL(image);

            let img = new Image();
            img.src = blobURL;

            const imageName = image.name.split(".")[0];
            const compressedImage = await imageOnLoad(img, imageName);

            fileList.push(compressedImage);
        }

        resolve(fileList);
    });
};

const imageOnLoad = (image, imageName) => {
    return new Promise(resolve => {
        image.onload = async () => {
            URL.revokeObjectURL(image.src);

            const canvas = document.createElement("canvas");
            canvas.width = 1024;
            canvas.height = 768;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, 1024, 768);

            const blob = await getCanvasBlob(canvas, imageName);
            resolve(blob);
        };
    });
};

const getCanvasBlob = (canvas, imageName) => {
    return new Promise(resolve => {
        canvas.toBlob(blob => {
            resolve(new File([blob], `${imageName}.jpeg`))
        }, "image/jpeg", 0.8);
    });
};

export {
    getValidFiles,
    getFileType,
    getRelevantImage,
    checkValidFileType,
    compressImages
}
