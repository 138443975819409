import React, { Fragment, useContext, useEffect, useState } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { DocumentUploader, UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, SubmitButton, SelectBoxWithState } from "../../../ui-components/ui-elements/form";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { Action } from "./includes/Action";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import LicenceReprintInformation from "./includes/LicenceReprintInformation";
import { licenceReceiptReprintApi, reasonListAPI, uploadDetailGetAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { RequestStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";

const LicenceReprintSubmitButton = (props) => {
  const [formState] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);

  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  const approvalAction = _get(
    formState,
    "Licence_Reprint.action",
    null
  )
  const [authStatus] = useContext(AuthContext);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const request_id = _get(formState, "licence-reprint.request_id", null);
  const licenceId = _get(
    formState,
    "vehicle_search_form.data.last_licence_id",
    null
  )

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");
  console.log('STATErrrrrrrrr', formState);
  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={props.uiType === 'assign&Perform' ? "Licence_Reprint" : "licence-reprint"}
        tooltip={"Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${request_id}.`,
            messageType: "success",
          },
          //   failed: props.uiType === 'assign&Perform' ? {
          //     status: true,
          //     message: `Approval Request Decision Submit Failed. Please Try Again.`,
          //     messageType: "error",
          // } : {
          //     status: true,
          //     message: `Approval Request Submit Failed. Please Try Again.`,
          //     messageType: "error",
          //   },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "Licence_Reprint.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Licence_Reprint.action", "") === 'FRD' ? (_get(formState, "Licence_Reprint.forward_role", "") === 'OPT' ?
            {
              user_role_level: "User role",
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority"
            } : {
              user_role_level: "User role",
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role"

            }) : {
            user_role_level: "User role",
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
          }) : {
            user_role_level: "User role",
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            forward_province: "Province",
            forward_role: "Role"

          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "Licence_Reprint.action", "") === 'FRD' ? (_get(formState, "Licence_Reprint.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required"
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required"
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
          }) : {
            reason_id: "required",
            action: "requiredIf:user_role_level,INT_PRV",
            remark: "required|max:500",
            forward_role: "required",

          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "Licence_Reprint.action", "") === 'FRD' ? (_get(formState, "Licence_Reprint.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
            "forward_authority": "Authority is required"
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required"
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",

          },
        }}
        onGetAPIEndPointFn={() => {
          return {
            url: `${licenceReceiptReprintApi.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
            key: licenceReceiptReprintApi.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "Licence_Reprint.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["reprint_lcn"],
              current_licence_type: current_licence_type,
              ui_request_id: props.approvalRequestId,
              // status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              // authority_id: authorityId,
              // request_id: fromObject.request_id,
              reason_id: formState['Licence_Reprint-new'].reason_id,
              // licence_id: licenceId !== null ? licenceId : '000000000000',
              counter_session_id: '00001',
              forward_authority_service_id: serviceId,
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              },
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["reprint_lcn"],
              current_licence_type: current_licence_type,
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: fromObject.request_id,
              reason_id: fromObject.reason_id,
              licence_id: licenceId !== null ? licenceId : '000000000000',
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === 'assign&Perform' ? "put" : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          }
          else {
            if (props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
              });
            }
            else {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
        }}
      />
    </Fragment>
  );
};


const LicenceReprintUI = (props) => {

  const formKey = props.formKey;
  const uiType = props.uiType;
  const [formState] = useContext(FormContext);
  const [requestId, setRequestId] = useState(null);
  const searchData = _get(
    formState,
    "vehicle_search_form.data",
    null
  )
  const licenceData = _get(formState, `latest_licence_data.data`, {})
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8))
  }, [])
  // console.log('PIIIII===', props);
  return (
    <div className="row">

      <div className="col-md-12 col-sm-12">
        {/* {console.log('SEARCH_DATA====',searchData)} */}
        <UICard>
          <LicenceReprintInformation {...props} licenceData={licenceData} />
        </UICard>
      </div>

      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <UICard>
          <FormWrapper
            setGroupName={uiType === 'APR' ? `${formKey}-new` : formKey}
            setFormObject={uiType === 'APR' ? {
              reason_id: uiType === 'APR' && props.dataSubmittedForApproval.reason_id,
            } : {
              reason_id: uiType === 'APR' && props.dataSubmittedForApproval.reason_id,
              request_id: _set(formState, `${formKey}.request_id`, requestId),
            }}>
            <div className="col-md-4 col-sm-6 floatLeft paddingLeftNone">
              <SelectBoxWithState
                apiUrl={`${reasonListAPI.url}?licence_receipt_reprint=${true}`}
                formGroupName={uiType === 'APR' ? `${formKey}-new` : formKey}
                emptySelectOptionTxt={"Select Reason"}
                keyName={"id"}
                valueName={"name"}
                apiStateKey={reasonListAPI.key}
                isRequired={true}
                inputName={"reason_id"}
                labelText="Reason"
                isDisabled={uiType === 'APR' ? true : false}
              />
            </div>
          </FormWrapper>
        </UICard>
      </div>

      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
        <Action dataSubmittedForApproval={props.dataSubmittedForApproval} formKey={formKey} uiType={uiType} />
      </div>

    </div>
  );
};


const LicenceReprint = () => {
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Licence / Reciept Reprint" },
      ]}
      pageTitle="Licence / Reciept Reprint"
    >
      <LicenceReprintUI />
    </DashboardTemplate>
  );
};

export default LicenceReprint;
export { LicenceReprintUI, LicenceReprintSubmitButton };
