/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-12 14:00:18 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-02 14:39:25
 */
import React, { useContext } from "react";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { CompleteArrearsOnlyBtn } from "./ConfirmationPopup";
import { getLabelValue, thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
const emptyFun = (...para) => undefined;

const PrintPreview = ({
  closePopupFn = emptyFun,
  onClickSuccessFn = emptyFun,
}) => {
  
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <strong>Vehicle Registration Number:</strong>
            </div>
            <div className="col-md-8">{_get(formState, "vehicle_search_form.data.vehicle_no", "")}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Owner's Name:</strong>
            </div>
            <div className="col-md-8">{getLabelValue(_get(formState,'viewOwnerInformation.name',""), true)}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Paid Arrears Duration - From:</strong>
            </div>
            <div className="col-md-8">{dateObjectToString(_get(formState, "licence_charges_form.arrearsStartDate", ""), "dd/mm/YY")}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Paid Arrears Duration - To:</strong>
            </div>
            <div className="col-md-8">{dateObjectToString(_get(formState, "licence_charges_form.arrearsEndDate", ""), "dd/mm/YY")}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Paid Amount (LKR):</strong>
            </div>
            <div className="col-md-8">{thousandSeparator(parseFloat(_get(formState, "paymentTypeFormKey.paid_amount", 0)).toFixed(2), true)}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Manual Receipt Number:</strong>
            </div>
            <div className="col-md-8">{_get(formState, "printReceipt.manual_receipt_no", "-")?.trim()?.length >0 ?_get(formState, "printReceipt.manual_receipt_no", "-"):'-'}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Date & Time:</strong>
            </div>
            <div className="col-md-8">{dateObjectToString(new Date(), "dd/mm/YY HH:MM")}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Performed By:</strong>
            </div>
            <div className="col-md-8">{`${_get(authStatus, 'authUser.name', "")} (${(_get(authStatus, 'authUser.roles', ""))})`}</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <strong>Performed At:</strong>
            </div>
            <div className="col-md-8">{dateObjectToString(new Date(), "dd/mm/YY HH:MM")}</div>
          </div>
        </div>
      </div>

      <div className="col-md-12 text-right defaultMarginTop">
        {/* <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="Print"
          onClickBtnFn={onClickSuccessFn}
        /> */}
        <CompleteArrearsOnlyBtn closePopupFn={closePopupFn} onClickSuccessFn={onClickSuccessFn}/>
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="No"
          onClickBtnFn={closePopupFn}
          startIcon={"fas fa-times"}
        />
      </div>
    </div>
  );
};

export { PrintPreview };
