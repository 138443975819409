/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-11 16:27:00
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-11 17:10:52
 */

import React, { useState,useEffect,useContext } from 'react';
import {
    FormWrapper,
    InputBoxWithState,
    SubmitButton
} from "../../../../ui-components/ui-elements/form";
import { dateObjectToString } from '../../../../../helpers/common-helpers/dateTime.helpers';
import { thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import {AccountNumberListAPI}from "../../../../../config/apiUrl.config"
import { callApi } from '../../../../../helpers/common-helpers/callApi.helpers';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { FormContext } from '../../../core/context-providers/FormContext.provider';
const RevenueSettlementNote = ({ pending, today,currentTime,accountNo,
    formGroupName = "revenueSettlementNoteForm"
}) => {
    
    const [showButton, setShowButton] = useState([]);
  

    return (
        <FormWrapper setGroupName={formGroupName} 
        >
            <div className="row">
                <div className="col-6">
                    <strong>Account Number :</strong> {accountNo}
                </div>
                <div className="col-6">
                    <strong>Amount to be Transferred :</strong> {thousandSeparator(pending.total, 2)} 
                </div>
                <div className="col-6">
                    <strong>Transaction To :</strong> {dateObjectToString(today +' '+ currentTime, "dd/mm/YY HH:MM")}
                </div>
                <div className="col-6">
                    <strong>Settlement Date :</strong> {dateObjectToString(today, "dd/mm/YY")}
                </div>
            </div>
            <div className="defaultMarginTop">
                <NumberInputBoxWithState
                    formGroupName={formGroupName}
                    inputName={"account_balance"}
                    labelText="Account Balance"  
                    maxLength={"20"}          
                    max={"99999999999999999999"}
                    isRequired={true}
                    isThousandSeparator={false}
                    isAllowLeadingZeros={true}
                    isAllowedFn={(value) => {
                          let checkValue = value.value.toString();

                          if (checkValue.includes(".")) {
                                checkValue = checkValue.split(".")[0];
                          }

                          return checkValue.length <= 20;
                    }}
                />
                <InputBoxWithState
                    formGroupName={formGroupName}
                    inputName={"remarks"}
                    maxLength={200}
                    labelText="Remark"
                    isRequired={true}
                />
            </div>
          
        </FormWrapper >
    );
};

export { RevenueSettlementNote };
