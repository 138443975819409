/*
 * @Author: Chamith Jayathilaka
 * @Date: 2022-05-30 12:00:00
 * @Last Modified by: Chamith Jayathilaka
 * @Date: 2022-05-30 12:00:00
 */

import React, { Fragment, useContext } from "react";
import { roundBtnSizes } from "../../../../../ui-components/ui-elements/form/index";
import { RoundButtonWithPopups } from "../../../../../ui-components/ui-elements/form/Button";
import { AddCommentPopup } from "./AddCommentPopup";
import { DealerCommentHistory } from "./DealerCommentHistory";
import { dealerCommentPostAPI } from "../../../../../../config/apiUrl.config";
import { SubmitButton } from "../../../../../ui-components/ui-elements/form/Button";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { apiResponseStoringType } from "../../../../../../config/apiResponseKey";
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";


const DealerCommentSectionIcons = (props) => {
  return (
    <Fragment>
      <RoundButtonWithPopups
        btnName="addComment"
        iconClass={"fas fa-plus"}
        btnSize={roundBtnSizes.small}
        tooltip={`Add Comment`}
        dialogProps={{
          dialogTitle: `Add Comment`,
          isCustomButton: true,
          customButtonComponent: SubmitBtn,
          isConfirmationPopup:true,
          customButtonComponentProps: props,
        }}
        modelComponent={AddCommentPopup}
        propsToModel={props}
      />
      <RoundButtonWithPopups
        btnName="viewmore"
        iconClass={"mdi mdi-magnify-plus-outline"}
        btnSize={roundBtnSizes.small}
        tooltip={` ${"View History"} `}
        dialogProps={{
          dialogTitle: `Comments`,
        }}
        modelComponent={DealerCommentHistory}
        propsToModel={props}
      />
    </Fragment>
  );
};

const SubmitBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  return (
    <Fragment>
      <SubmitButton
        btnText="Save"
        startIcon={"far fa-save"}
        elementStyle="btnWrapper"
        formGroupName={`${props.formGroupName}_add`}
        isValidate={true}
        flashMessages={{
          success: {
            status: true,
            message: "Vehicle Comment is successfully created.",
            messageType: "success",
          },
          failed: {
            status: true,
            message: "Vehicle Comment creation failed",
            messageType: "error",
          },
        }}
        validationObject={{
          fileds: {
            comment: "Comment",
          },
          rules: {
            comment: "required|max:250",
          },
          message: {},
        }}
        callApiObject={{
          isSetHeaders: true,
          multipart: false,
          method: "post",
          onUpload: false,
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.responseLinkToFrom,
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: dealerCommentPostAPI.url,
            key: dealerCommentPostAPI.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          return getDataByFormObject({
            comment: formObject.comment,
            dealer_id: props.dealerId,
          });
        }}
        onResponseCallBackFn={(errors, response) => {
          if (!errors) {
            formAction.changeInputFn(
              `${props.formGroupName}_view`,
              "latestRecord",
              "",
              response.data.data
            );
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

export { DealerCommentSectionIcons };
