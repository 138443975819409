/*
 * @Author:Gajani Kangesan 
 * @Date: 2022-02-17 17:28:35 
 * @Last Modified by: Gajani Kangesan
 * @Last Modified time: 2022-02-18 09:33:08
 */

import React, { Fragment, useContext,useState } from "react";
import { DashboardTemplate } from "../../../../../ui-components/templates/DashboardTemplate";
import { templateTypes } from "../../../../../../config/template.config";
import {
  FormWrapper,
  SelectBoxWithState,
  SubmitButton,
  InputButton
  
} from "../../../../../ui-components/ui-elements/form";
import { _getInt, _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { reportGenerateAPI } from "../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { downloadFile } from "../../../../../../helpers/download-helpers/download.helpers"
import { SearchCriteria } from "./includes/SearchCriteria"
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { permissions } from "../../../../../../config/permission.config";
const DetailedAdditionDeductionReport = () => {
  const reportCode = `fr06-detailed-additions-deductions-report`
  const searchformGroupName = `detailedadditiondeduction`;
  const exportformGroupName = `export_report_form`;
  const [htmlTemplate, sethtmlTemplate] = useState(null);
  const [reportgeneratedValues, setReportgeneratedValues] = useState({});
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const reportName = "Detailed Additions & Deductions Report"
  const [printcontent, sePrintcontent] = useState(null)
  
  const onGenerateSubmit = (event) => {
    sethtmlTemplate({ __html: event });
    sePrintcontent(event)
  };

  const onSetGeneratedValues = (event) => {
    setReportgeneratedValues(event)
  };
  const onExportToPdfSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.pdf`);
  };

  const onExportToExcelSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.xlsx`);
  };
  const onResetClick = () =>{
    sethtmlTemplate(null)
    setReportgeneratedValues({})
  }
  const ExportOption = []
  _get(authStatus, 'authUser.permissions', "").forEach(item => {
    if (item.permission === permissions.EXEL_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT.permissions[0]) {
      ExportOption.push({
        id: "xlsx",
        value: "Excel",
      })
    }
    else if (item.permission === permissions.PDF_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT.permissions[0]) {
      ExportOption.push({
        id: "pdf",
        value: "PDF",
      })
    }
  })

  const reportPrint = () => {
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printcontent;
    window.print();
    window.location.reload();
    document.body.innerHTML = originalContents;
    window.close()

  }
  return (
    <DashboardTemplate
      pageTitle="Detailed Additions & Deductions Report"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        { urlTo: "/income-details", displayName: "Income Details Reports", },
        {
          urlTo: "/report",
          displayName: "Detailed Additions & Deductions Report",
        },
      ]}
    >
      <SearchCriteria
       reportCode={reportCode}
       formGroupName={searchformGroupName}
       onGenerateSubmit={onGenerateSubmit}
       setReportgeneratedValues={onSetGeneratedValues}
       onResetClick={onResetClick}
      />
       {htmlTemplate && (
        <div class="mt-2 report-html-view">
          <UICard>
            <div dangerouslySetInnerHTML={htmlTemplate} />
          </UICard>
        </div>
      )}

      {htmlTemplate && (
        <div class="mt-2">
          <UICard>
            <FormWrapper
              setGroupName={exportformGroupName}
              formGroupLinkWith={exportformGroupName}
              onDestroyUnsetFormObject={true}
              setFormObject={{
                export_to: null
              }}
            >
              <div className={"reportExportToAlignWrapper "}>
                <div className="exporTypeSelectDropdown ">
                  <SelectBoxWithState
                    uiType={templateTypes.bootstrap}
                    formGroupName={exportformGroupName}
                    emptySelectOptionTxt={'  '}
                    inputValue={null}
                    dataList={ExportOption}
                    inputName={"export_to"}
                    labelText="Export To"
                  />

                </div>
                <div className="exportBtn">
                  <SubmitButton
                    btnText={"Export"}
                    elementStyle="btnWrapper"
                    formGroupName={exportformGroupName}
                    isValidate={true}
                    validationObject={{
                      fileds: {
                        export_to: "Export To"
                      },
                      rules: {
                        export_to: "required"
                      },
                      message: {}
                    }}
                    callApiObject={{
                      isSetHeaders: true,
                      method: "get",
                      multipart: false,
                      onUpload: false,
                      responseType: 'arraybuffer'
                    }}
                    apiDataStoringObject={{
                      setLoader: true,
                      storingType: "API_RESPONSE_LINKED_FORM",
                      mergeToSuccessResponse: true,
                      mergeToErrorResponse: true
                    }}
                    onGetAPIEndPointFn={(formState) => {
                      return {
                        url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=${formState?.export_to}&report_limit=${reportgeneratedValues?.limit}&report_offset=${reportgeneratedValues?.offset}&report_fromDate=${reportgeneratedValues?.datefrom}&report_toDate=${reportgeneratedValues?.dateto}&report_pdmtId=${reportgeneratedValues?.pdmtId}&report_provinceName=${reportgeneratedValues?.provinceName}&report_authorityId=${reportgeneratedValues?.authorityId}&report_requestAuthority=${reportgeneratedValues?.authorityName}&report_addition1Id=${reportgeneratedValues?.addition1Id}&report_deduction1Id=${reportgeneratedValues?.deduction1Id}&report_generatedUser=${reportgeneratedValues?.reportGeneratedUser}`,
                       // url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=${formState?.export_to}&report_limit=${reportgeneratedValues?.limit}&report_offset=${reportgeneratedValues?.offset}&report_fromDate=${reportgeneratedValues?.datefrom}&report_toDate=${reportgeneratedValues?.dateto}&report_pdmtId=${reportgeneratedValues?.pdmtId}&report_provinceName=${reportgeneratedValues?.provinceName}&report_authorityId=${reportgeneratedValues?.authorityId}&report_requestAuthority=${reportgeneratedValues?.authorityName}&report_addition1Id=${reportgeneratedValues?.addition1Id}&report_addition2Id=${reportgeneratedValues?.addition2Id}&report_deduction1Id=${reportgeneratedValues?.deduction1Id}&report_deduction2Id=${reportgeneratedValues?.deduction2Id}&report_generatedUser=${reportgeneratedValues?.reportGeneratedUser}`,
                        key: `${reportGenerateAPI.key}`
                      }
                    }}

                    onResponseCallBackFn={(error, response,) => {
                      if (response?._statue) {
                        if (_get(formState[exportformGroupName], "export_to", "") === 'pdf') {
                          onExportToPdfSubmit(response?.data)
                        }
                        if (_get(formState[exportformGroupName], "export_to", "") === 'xlsx') {
                          onExportToExcelSubmit(response?.data)
                        }
                      }
                    }}
                  />
                  <InputButton
                  btnText="Print"
                  formGroupName={exportformGroupName}
                  elementWrapperStyle={"defaultHalfMarginLeftRight payBtnStyle"}
                  onClickBtnFn={() =>reportPrint()}
                /> 
                </div>
               
              </div>
            </FormWrapper>
          </UICard>
        </div>
      )}
    </DashboardTemplate>
  );
};

export {DetailedAdditionDeductionReport };
