import React, { useContext, useState, useEffect } from "react";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { CheckBox, FormWrapper } from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { DmtInformation } from "./DmtInformation";
import LastLicenceInformation from "./LastLicenceInformation";
import LastTransactionInformation from "./LastTransactionInformation";
import LicenceReprintInformation from "./LicenceReprintInformation";
import DealerLicenceReprintInformation from './DealerLIcenceReprintInformation';
import {
  addVehicleStatusChangeAPI, getLatestLicenseAPI, getLatestRevLicenseAPI, getLicenceTransactionAPI, vehiclePaymentHistoryAPI, getLicenceTransactionRecieptPrintDataAPI, getDMTDataListAPI, licenceInvalidDocuments, addLicenceReceiptTransactionAPI,
  changeSerialAPI, addVehicleAPI, licenceChangeAPI, licenceWaveOffAPI, updateVehicleRequestAPI, AutharizedVehicleInformationAPI, AddLicenceTransactionAPI, DealersLicenceReissuanceRequestsAPI, insuranceAPI, vrpAPI,
  OtherAdditionDeductionTempAPI,normalLicenceStartDateAPI,normalLicenceEndDatesAPI, getRequestedEffDataAPI, transferVehicleAPI, calculateLicenceFeeAPI, vetAPI, vetCompanyAPI, insuranceListAPI, vftCompanyListAPI, vrpCompanyListAPI,
  getTempLicenceTransactionAPI, previousOwnerInformationAPI, changeDealerSerialAPI,vftCertificateAPI, getLicenceTransactionRecieptPrintRevDataAPI, dealerCancelLicenceReceiptTransactionAPI, getDealerVehicleDetailsAPI, updateDealerVehicleReqstAPI, dealerVehicleTempAPI, addDealerVehicleReqstAPI
} from "../../../../../config/apiUrl.config";

import { ProvincesByIds } from "../../../../../config/enum.config";
import { _get, _getFloat } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { WaiveOffUpperPannel } from "./LicenceWaveOffInformation";
import { getCodeName, fromObjectToQueryString, numberThousandSeperator, thousandSeparator } from '../../../../../helpers/common-helpers/common.helpers';
import { VehicleStatus, ChargeEffectCode, CurrentLicenceStatus, LicenceType, ProvincesMatcher, LicenceTypeForTopPanel, ProvincesByCodeMatcher } from "../../../../../config/enum.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { PreviousOwnerInformation } from "../includes/PreviousOwnerInformation";
import { ErlInformation } from "../includes/ErlInformation";
import { isEmpty } from "lodash";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import { RawIndexMemo } from "../../../../ui-components/ui-elements/table/DataTable";
import { _getInt } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { pdmtPaymentCategories } from "../../../../../config/enum.config";
// import { RequestStatus, CurrentLicenceStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";

const TransferVehicleInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  // console.log('poops===', props);
  const formKey = 'transfer_vehicle'
  useEffect(() => {
    formAction.changeInputFn(formKey, "previous_owners", "", { "0": {} })
    formAction.changeInputFn(formKey, "current_erl", "", { "0": {} })
    getRelatedData()
  }, [])

  const getRelatedData = async () => {

    console.log('OOPs', props);
    const previousOwnerInfo = await sendAPIRequest(`${transferVehicleAPI.url}/${props.row.request_id}`);
    const previousOwnerInfoOld = await sendAPIRequest(`${previousOwnerInformationAPI.url}/${props.row.vehicle_no}`);

    Promise.all([previousOwnerInfo, previousOwnerInfoOld])
      .then(result => {
        console.log('REEE==', result);
        formAction.changeInputFn(formKey, "current_erls", "", result[0]["data"]['currentErls'].sort((a, b) => a['transferred_date'] - b['transferred_date']));
        formAction.changeInputFn(formKey, "isReissuanceRequired", "",
          result[0]["data"]['currentErls'].length !== 0 ? result[0]["data"]['currentErls'][0]['reissuance_required'] :
            result[0]["data"]['previousOwners'].length !== 0 ? result[0]["data"]['previousOwners'][0]['reissuance_required'] : 0);
        formAction.changeInputFn(formKey, "previous_owners_added_data", "", result[0]["data"]['previousOwners'].sort((a, b) => {
          const date1 = new Date(a['transferred_date'])
          const date2 = new Date(b['transferred_date'])
          return date1 - date2
        }))

        formAction.changeInputFn(formKey, "previous_owners_data", "", [...result[1]["data"]].sort((a, b) => {
          const date1 = new Date(a['transferred_on'])
          const date2 = new Date(b['transferred_on'])
          return date1 - date2
        }))
      }).catch(err => {
        console.log('ERR==>', err);
      })
  };

  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result?.data);
        })
    });
  };

  return (
    <UICard>
      <div className="col-md-12 col-sm-12 defaultPaddingBottom">
        <PreviousOwnerInformation
          formGroupName={formKey}
          {...props}
        />
      </div>
      <div className="col-md-12 col-sm-12">
        <ErlInformation
          formGroupName={formKey}
          uiType={props.uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
          {...props}
        />
      </div>
      {/* <div className="defaultHalfPaddingLeft checkBox_Btn_wrraper">
          <CheckBox
            inputName={"-1"}
            formGroupName={"-1"}
            labelText={"Re-Issuance Required "}
            isDisabled={true}
            inputValue={true}
          />
        </div> */}
    </UICard>
  );
};

const UpdateDealerInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  useEffect(() => {
    newDataGet()
  }, []);

  const newDataGet = () => {
    const sendAPIRequest = (apiUrl) => {
      return new Promise((resolve, reject) => {
        callApi(apiUrl)
          .headers(true)
          .method("get")
          .isMultipart(false)
          .send((error, result) => {
            if (error) {
              // console.log('ERRRRRRRRR====', error);
              reject(error);
            }

            resolve(result?.data);
          })
      });
    };
    const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);
    Promise.all([insuranceCompaniesPromise])
      .then(result => {
        const insuranceCompanies = result[0]['data']
        formAction.changeInputFn(formKey, "insuranceCompanies", "", insuranceCompanies);


      }).catch(error => {
        console.log('ERRRRRRRRR', error);
      });

  }

  return (
    
      <FormWrapper
        setGroupName={formKey}
        apiUrl={`${updateDealerVehicleReqstAPI.url}/${request_id}`}
        onRebuildResponseFn={(response) => {
          formAction.changeInputFn(formKey, "data", "", response)
          formAction.changeInputFn(formKey, "_onLoad", "", false)

          return {
            dlr_id: _get(response, "id", ""),
            reason_id: _get(response, "work_flow_record.reason_id", ""),
            name: _get(response, "name", ""),
            date_of_registration: _get(response, "date_of_registration", ""),
            Office_address_line_1: _get(response, "Office_address_line_1", ""),
            Office_address_line_2: _get(response, "Office_address_line_2", ""),
            Office_address_city: _get(response, "Office_address_city", ""),
            Office_address_district: _get(response, "Office_address_district_name", ""),
            address_line_1: _get(response, "address_line_1", ""),
            address_line_2: _get(response, "address_line_2", ""),
            city: _get(response, "city", ""),
            district: _get(response, "district_name", ""),
            province: ProvincesByCodeMatcher[`${_get(response, "province", "")}`],
            dmt_dealer_letter: _get(response, "dmt_dealer_letter", "") === 0 ? 'No' : 'Yes',
            letter_issued_date: dateObjectToString(_get(response, "letter_issued_date", ""), "dd/mm/YY"),
            validity_next_year: _get(response, "validity_next_year", "") === 0 ? 'No' : 'Yes',
            validity_from: dateObjectToString(_get(response, "validity_from", ""), "dd/mm/YY"),
            validity_to: dateObjectToString(_get(response, "validity_to", ""), "dd/mm/YY"),

            "_onLoad": false
          }
        }}
      >
        <div className="row">
        <div className="col-md-6 col-sm-12 floatLeft">
          <UICard cardHeading="Office Information" noPadLeft>
            <div className="col-md-12 col-sm-4 floatLeft">
              <strong> Name of Dealer/ Manufacturer/ Repairer: </strong>{_get(formState, `${formKey}.data.name`, "-")}
            </div>
            {console.log('FORM_STTTTTT===', formState)}
            <div className="col-md-12 col-sm-6 floatLeft">
              <strong> Date of Registration: </strong> {dateObjectToString(_get(formState, `${formKey}.data.date_of_registration`, "-"), "dd/mm/YY")}
            </div>
            <div className="col-md-12 col-sm-4 floatLeft">
              <strong> Dealer's Office Address Line 1: </strong> {_get(formState, `${formKey}.data.Office_address_line_1`, "-")}
            </div>
            <div className="col-md-12 col-sm-6 floatLeft">
              <strong> Dealer's Office Address Line 2: </strong> {_get(formState, `${formKey}.data.Office_address_line_2`, "-")}
            </div>
            <div className="col-md-12 col-sm-4 floatLeft">
              <strong> Dealer's Office Address City: </strong> {_get(formState, `${formKey}.data.Office_address_city`, "-")}
            </div>
            <div className="col-md-12 col-sm-6 floatLeft">
              <strong> Dealer's Office Address District: </strong> {_get(formState, `${formKey}.data.Office_address_district_name`, "-")}
            </div>
          </UICard>
        </div>

        <div className="col-md-6 col-sm-12 floatLeft">
          <UICard cardHeading="Dealer Information" noPadLeft>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address Line 1: </strong> {_get(formState, `${formKey}.data.address_line_1`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address Line 2: </strong> {_get(formState, `${formKey}.data.address_line_2`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address Line City: </strong> {_get(formState, `${formKey}.data.city`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address District: </strong> {_get(formState, `${formKey}.data.district_name`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Province: </strong> {ProvincesByCodeMatcher[`${_get(formState, `${formKey}.data.province`, "-")}`]}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> DMT Dealer's Letter Includes: </strong> {_get(formState, `${formKey}.data.dmt_dealer_letter`, "-") === 0 ? 'No' : 'Yes'}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> DMT Dealer's Letter Issued Date: </strong> {dateObjectToString(_get(formState, `${formKey}.data.letter_issued_date`, "-"), "dd/mm/YY")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Validity To Next Year: </strong> {_get(formState, `${formKey}.data.validity_next_year`, "-") === 0 ? 'No' : 'Yes'}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Validit From: </strong> {dateObjectToString(_get(formState, `${formKey}.data.validity_from`, "-"), "dd/mm/YY")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Validity To: </strong> {dateObjectToString(_get(formState, `${formKey}.data.validity_to`, "-"), "dd/mm/YY")}
            </div>
          </UICard>
        </div>
        </div>
       

        <div className={" row mt-3"}>
          <div className={"col-md-12"}>
            <DataTable
              tableName={"Vehicle Information"}
              isSetAction={false}
              dataTableKey={dealerVehicleTempAPI.key}
              isSetSearchFrom={false}
              tableHeaderList={[
                { displayName: "Authorized Vehicle No.", key: "vehicle_no", default: "-", isSort: false },
                {
                  displayName: "Insurance Company", key: "insurance_company", onPrintFn: (rawData) => {
                    const a = _get(formState, `${formKey}.insuranceCompanies`, null)
                    const companyName = a && a.length !== 0 ? a.filter(value => value['id'] === rawData.insurance_company) : null

                    const company = companyName && companyName !== null && companyName.length !== 0 ? companyName[0].service.name : '-'
                    return company
                  }, isSort: false
                },
                {
                  displayName: "Insurance Expiry Date", key: "insurance_expiry_date", isSort: false, onPrintFn: (rawData) => {
                    return dateObjectToString(rawData.insurance_expiry_date, "dd/mm/YY");
                  }
                },
              ]}
              tableBodyList={_get(formState, `${formKey}.data.dealerVehicle`, [])}
            />
          </div>
        </div>
      </FormWrapper>
    
  );
}

const AddDealerInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  useEffect(() => {
    newDataGet()
  }, []);

  const newEndDate = `31/12/${new Date().getFullYear()}`
  const newStartDate = `01/01/${new Date().getFullYear()}`
  const nextYearStartDate = new Date().setFullYear(new Date().getFullYear() + 1)
  const validNextYearStartDate = `01/01/${new Date(nextYearStartDate).getFullYear()}`

    const nextYearDate = new Date().setFullYear(new Date().getFullYear() + 1)
    const validNextYearDate = `31/12/${new Date(nextYearDate).getFullYear()}`

  const newDataGet = () => {
    const sendAPIRequest = (apiUrl) => {
      return new Promise((resolve, reject) => {
        callApi(apiUrl)
          .headers(true)
          .method("get")
          .isMultipart(false)
          .send((error, result) => {
            if (error) {
              // console.log('ERRRRRRRRR====', error);
              reject(error);
            }

            resolve(result?.data);
          })
      });
    };
    const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);
    Promise.all([insuranceCompaniesPromise])
      .then(result => {
        const insuranceCompanies = result[0]['data']
        formAction.changeInputFn(formKey, "insuranceCompanies", "", insuranceCompanies);


      }).catch(error => {
        console.log('ERRRRRRRRR', error);
      });

  }

  return (
    
      <FormWrapper
        setGroupName={formKey}
        apiUrl={`${addDealerVehicleReqstAPI.url}/${request_id}`}
        onRebuildResponseFn={(response) => {
          formAction.changeInputFn(formKey, "data", "", response)
          formAction.changeInputFn(formKey, "_onLoad", "", false)

          return {
            dlr_id: _get(response, "id", ""),
            reason_id: _get(response, "work_flow_record.reason_id", ""),
            name: _get(response, "name", ""),
            date_of_registration: _get(response, "date_of_registration", ""),
            Office_address_line_1: _get(response, "Office_address_line_1", ""),
            Office_address_line_2: _get(response, "Office_address_line_2", ""),
            Office_address_city: _get(response, "Office_address_city", ""),
            Office_address_district: _get(response, "Office_address_district_name", ""),
            address_line_1: _get(response, "address_line_1", ""),
            address_line_2: _get(response, "address_line_2", ""),
            city: _get(response, "city", ""),
            district: _get(response, "district_name", ""),
            province: ProvincesByCodeMatcher[`${_get(response, "province", "")}`],
            dmt_dealer_letter: _get(response, "dmt_dealer_letter", "") === 0 ? 'No' : 'Yes',
            letter_issued_date: dateObjectToString(_get(response, "letter_issued_date", ""), "dd/mm/YY"),
            validity_next_year: _get(response, "validity_next_year", "") === 0 ? 'No' : 'Yes',
            validity_from: dateObjectToString(_get(response, "validity_from", ""), "dd/mm/YY"),
            validity_to: dateObjectToString(_get(response, "validity_to", ""), "dd/mm/YY"),
            created_at: dateObjectToString(_get(response, "created_at", ""), "dd/mm/YY"),

            "_onLoad": false
          }
        }}
      >
        <div className="row">
        <div className="col-md-6 col-sm-12 floatLeft">
          <UICard cardHeading="Office Information">
            <div className="col-md-12 col-sm-4 floatLeft">
              <strong> Name of Dealer/ Manufacturer/ Repairer: </strong>{_get(formState, `${formKey}.data.name`, "-")}
            </div>
            <div className="col-md-12 col-sm-6 floatLeft">
              <strong> Date of Registration: </strong> {dateObjectToString(_get(formState, `${formKey}.data.date_of_registration`, "-"), "dd/mm/YY")}
            </div>
            <div className="col-md-12 col-sm-4 floatLeft">
              <strong> Dealer's Office Address Line 1: </strong> {_get(formState, `${formKey}.data.Office_address_line_1`, "-")}
            </div>
            <div className="col-md-12 col-sm-6 floatLeft">
              <strong> Dealer's Office Address Line 2: </strong> {_get(formState, `${formKey}.data.Office_address_line_2`, "-")}
            </div>
            <div className="col-md-12 col-sm-4 floatLeft">
              <strong> Dealer's Office Address City: </strong> {_get(formState, `${formKey}.data.Office_address_city`, "-")}
            </div>
            <div className="col-md-12 col-sm-6 floatLeft">
              <strong> Dealer's Office Address District: </strong> {_get(formState, `${formKey}.data.Office_address_district_name`, "-")}
            </div>
          </UICard>
        </div>

        <div className="col-md-6 col-sm-12 floatLeft">
          <UICard cardHeading="Dealer Information">
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address Line 1: </strong> {_get(formState, `${formKey}.data.address_line_1`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address Line 2: </strong> {_get(formState, `${formKey}.data.address_line_2`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address Line City: </strong> {_get(formState, `${formKey}.data.city`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Dealer's Address District: </strong> {_get(formState, `${formKey}.data.district_name`, "-")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Province: </strong> {ProvincesByCodeMatcher[`${_get(formState, `${formKey}.data.province`, "-")}`]}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> DMT Dealer's Letter Includes: </strong> {_get(formState, `${formKey}.data.dmt_dealer_letter`, "-") === 0 ? 'No' : 'Yes'}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> DMT Dealer's Letter Issued Date: </strong> {dateObjectToString(_get(formState, `${formKey}.data.letter_issued_date`, "-"), "dd/mm/YY")}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Validity To Next Year: </strong> {_get(formState, `${formKey}.data.validity_next_year`, "-") === 0 ? 'No' : 'Yes'}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Validity From: </strong> {_get(formState, `${formKey}.data.validity_next_year`, "-") === 1 ? 
              validNextYearStartDate : newStartDate}
            </div>
            <div className="col-md-6 col-sm-4 floatLeft">
              <strong> Validity To: </strong> {_get(formState, `${formKey}.data.validity_next_year`, "-") === 1 ? 
              validNextYearDate : newEndDate}
            </div>
          </UICard>
        </div>
        </div>
       

        <div className={" row mt-3"}>
          <div className={"col-md-12"}>
            <DataTable
              tableName={"Vehicle Information"}
              isSetAction={false}
              apiUrl={`${dealerVehicleTempAPI.url}/${request_id}`}
              dataTableKey={dealerVehicleTempAPI.key}
              isSetTableFooter={true}
              isSetSearchFrom={false}
              tableHeaderList={[
                { displayName: "Authorized Vehicle No.", key: "vehicle_no", default: "-", isSort: false },
                {
                  displayName: "Insurance Company", key: "insurance_company", onPrintFn: (rawData) => {
                    const a = _get(formState, `${formKey}.insuranceCompanies`, null)
                    const companyName = a && a.length !== 0 ? a.filter(value => value['id'] === rawData.insurance_company) : null

                    const company = companyName && companyName !== null && companyName.length !== 0 ? companyName[0].service.name : '-'
                    return company
                  }, isSort: false
                },
                {
                  displayName: "Insurance Expiry Date", key: "insurance_expiry_date", isSort: false, onPrintFn: (rawData) => {
                    return dateObjectToString(rawData.insurance_expiry_date, "dd/mm/YY");
                  }
                },
              ]}
            />
          </div>
        </div>
      </FormWrapper>
    
  );
}

const AddVehicleInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  return (
    <UICard>
      <FormWrapper
        setGroupName={formKey}
        apiUrl={`${addVehicleAPI.url}${request_id}`}
        onRebuildResponseFn={(response) => {
          console.log('RESPOOOOO=====', response);
          // console.log('FORM_STTTTTT===', formState);
          formAction.changeInputFn(formKey, "data", "", response)
          formAction.changeInputFn(formKey, "_onLoad", "", false)

          return {
            name: response.name,
            address_line_1: response.address_line_1,
            address_line_2: response.address_line_2,
            city: response.city,
            absolute_owner_name: response.absolute_owner_name,
            absolute_owner_address_line_1: response.absolute_owner_address_line_1,
            absolute_owner_address_line_2: response.absolute_owner_address_line_2,
            absolute_owner_city: response.absolute_owner_city,
            chassis_no: response.chassis_no,
            tare_weight: response.tare_weight,
            gross_weight: response.gross_weight,
            no_of_seats: response.no_of_seats,
            vehicle_reg_condition: response.vehicle_reg_condition_id.name,
            fuel_type: response.fuel_type_id.name,
            vehicle_class: response.vehicle_class_id.name,
            province_id: response.province_id,
            first_reg_date: response.first_reg_date,
            engine_no: response.engine_no,
            manufactured_year: response.manufactured_year,
            cylinder_capacity: response.cylinder_capacity,
            tyre_size: response.tyre_size,
            wheel_base: response.wheel_base,
            type_of_body: response.type_of_body,
            make: response.make,
            model: response.model,
            color_1: response.color_1,
            color_2: response.color_2,
            color_3: response.color_3,

            "_onLoad": false
          }
        }}
      >
        <div className="col-md-12 col-sm-12 floatLeft" style={{ marginBottom: 20 }}>
          <UICard cardHeading="Owner Information">
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Name: </strong>{getLabelValue(_get(formState, `${formKey}.data.name`, ""), true)}
            </div>
            {console.log('FORM_STTTTTT===', formState)}
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Address Line 1: </strong> {getLabelValue(_get(formState, `${formKey}.data.address_line_1`, ""), true)}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Address Line 2: </strong> {getLabelValue(_get(formState, `${formKey}.data.address_line_2`, ""), true)}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> City: </strong> {_get(formState, `${formKey}.data.city`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Absolute Owner: </strong> {getLabelValue(_get(formState, `${formKey}.data.absolute_owner_name`, ""), true)}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Absolute Owner Address Line 1: </strong> {getLabelValue(_get(formState, `${formKey}.data.absolute_owner_address_line_1`, ""), true)}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Absolute Owner Address Line 2: </strong> {getLabelValue(_get(formState, `${formKey}.data.absolute_owner_address_line_2`, ""), true)}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Absolute Owner City: </strong> {_get(formState, `${formKey}.data.absolute_owner_city`, "-")}
            </div>
          </UICard>
        </div>

        <div className="col-md-12 col-sm-12 floatLeft">
          <UICard cardHeading="Vehicle Information">
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Chassis: </strong> {getLabelValue(_get(formState, `${formKey}.data.chassis_no`, ""), true)}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Engine: </strong> {getLabelValue(_get(formState, `${formKey}.data.engine_no`, ""), true)}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Manufacture year: </strong> {_get(formState, `${formKey}.data.manufactured_year`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Cylinder capacity: </strong> {_get(formState, `${formKey}.data.cylinder_capacity`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Tyre size: </strong> {_get(formState, `${formKey}.data.tyre_size`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Wheel base: </strong> {_get(formState, `${formKey}.data.wheel_base`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Type of body: </strong> {_get(formState, `${formKey}.data.type_of_body`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Make: </strong> {_get(formState, `${formKey}.data.make`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Model: </strong> {_get(formState, `${formKey}.data.model`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Color 1: </strong> {_get(formState, `${formKey}.data.color_1`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Color 2: </strong> {_get(formState, `${formKey}.data.color_2`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Color 3: </strong> {_get(formState, `${formKey}.data.color_3`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Tare (Kg): </strong>{_get(formState, `${formKey}.data.tare_weight`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Gross (Kg): </strong> {_get(formState, `${formKey}.data.gross_weight`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Seats (Exp. Driver): </strong> {_get(formState, `${formKey}.data.no_of_seats`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Vehicle Condition: </strong>{_get(formState, `${formKey}.data.vehicle_reg_condition_id.name`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Fuel Type: </strong> {_get(formState, `${formKey}.data.fuel_type_id.name`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Class: </strong> {_get(formState, `${formKey}.data.vehicle_class_id.name`, "-")}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> Province office: </strong>{ProvincesMatcher[`${_get(formState, `${formKey}.data.province_id`, "-")}`]}
            </div>
            <div className="col-md-4 col-sm-4 floatLeft">
              <strong> 1st Reg: </strong> {dateObjectToString(_get(formState, `${formKey}.data.first_reg_date`, null), "dd/mm/YY") !== '01/01/1970' ? 
              dateObjectToString(_get(formState, `${formKey}.data.first_reg_date`, null), "dd/mm/YY") : '-'}
            </div>
            
          </UICard>
        </div>
      </FormWrapper>
    </UICard>
  );
};

const LicenceTypeChangeInfoDisplay = (props) => {

  const [formState, formAction] = useContext(FormContext);
  const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  // console.log('FORMMMM===', formState);
  const getData = async () => {
    const sendAPIRequest = (apiUrl) => {
      return new Promise((resolve, reject) => {
        callApi(apiUrl)
          .headers(true)
          .method("get")
          .isMultipart(false)
          .send((error, result) => {
            if (error) {
              reject(error);
            }

            resolve(result?.data);
          })
      });
    };

    const effReqData = await sendAPIRequest(`${licenceChangeAPI.url}/${request_id}`);

    Promise.all([effReqData])
      .then(result => {
        const setResultOther = result && result.length !== 0 ? result[0].data : {}

        const newData = {
          reqData: setResultOther
        }


        formAction.changeInputFn('allTaskData', "vehicleStatusChng", "", newData);


        // setData(setResult)
        // console.log('RRRRRR', result);

      });

  }

  useEffect(() => {
    getData()
  }, [])

  const licnData = _get(formState, `allTaskData.vehicleStatusChng`, {})

  return (
    <UICard>
      {/* <FormWrapper
        setGroupName={formKey}
        apiUrl={`${licenceChangeAPI.url}/${request_id}`}
        onRebuildResponseFn={(response) => {
console.log('RESSSoooo===>', response);
          return {
            vehicle: response,

            "_onLoad": true
          }
        }}
      > */}

      {/* <div className="col-md-12 col-sm-12 paddingLeftNone paddingBottomNone floatLeft"> */}
      {/* {console.log('FORMMMM===', formState)} */}

      <div className="floatLeft col-md-2 d-flex flex-row" style={{ paddingLeft: 0 }}>
        <p> <b> Current Licence Type</b></p><p className="d-flex flex-row"><p><b> &nbsp;: &nbsp;</b></p><p>{licnData && licnData.reqData ? licnData.reqData.vehicle.current_license_type.name : '-'}</p></p>
      </div>
      <div className="floatLeft col-md-3 " style={{ paddingLeft: "1px" }}>
        <b> New Licence Type</b> <b> &nbsp;: &nbsp;</b>{licnData && licnData.reqData ? `${LicenceTypeForTopPanel[`${licnData.reqData.new_licence_type}`]} Licence` : '-'}
      </div>
      <div className="floatLeft col-md-3">
        <b> Effective Date</b><b> &nbsp;: &nbsp;</b> {licnData && licnData.reqData ? dateObjectToString(licnData.reqData.effective_date, "dd/mm/YY") : '-'}
      </div>

      {/* </div> */}
      {/* </FormWrapper> */}
    </UICard>
  );
};
const VehicleStatusChangeInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const request_id = props.row.request_id;
  const vehicle_no = props.row.vehicle_no;
  const formKey = "approvalRequest";

  const getData = async () => {
    const sendAPIRequest = (apiUrl) => {
      return new Promise((resolve, reject) => {
        callApi(apiUrl)
          .headers(true)
          .method("get")
          .isMultipart(false)
          .send((error, result) => {
            if (error) {
              reject(error);
            }

            resolve(result?.data);
          })
      });
    };

    const lcnData = await sendAPIRequest(`${getDMTDataListAPI.url}/${vehicle_no}`);
    const effReqData = await sendAPIRequest(`${addVehicleStatusChangeAPI.url}/${request_id}`);

    Promise.all([lcnData, effReqData])
      .then(result => {
        const setResult = result && result.length !== 0 ? result[0].data : {}
        const setResultOther = result && result.length !== 0 ? result[1].data : {}

        const newData = {
          dmtData: setResult,
          reqData: setResultOther
        }


        formAction.changeInputFn('allTaskData', "vehicleStatusChng", "", newData);


      });

  }

  useEffect(() => {
    getData()
  }, [])

  const licnData = _get(formState, `allTaskData.vehicleStatusChng`, {})

  return (
    <UICard cardHeading="Vehicle Status Information">
      <FormWrapper
      // setGroupName={formKey}
      // apiUrl={`${addVehicleStatusChangeAPI.url}/${request_id}`}
      // onRebuildResponseFn={(response) => {
      //   console.log('RES--->',response);
      //   return {
      //     new_vehicle_status: response.new_vehicle_status,
      //     effective_date: response.effective_date,

      //     "_onLoad": true
      //   }
      // }}
      >
        <div className="col-md-12 col-sm-12 paddingLeftNone paddingBottomNone floatLeft">
          
          <div className="row">
            <div className="floatLeft col-md-2">
              <b> Original Vehicle Status: </b> {licnData && licnData.reqData ? getCodeName(VehicleStatus, licnData.reqData.original_vehicle_status_code) : '-'}
            </div>
            {/* </FormWrapper> */}
            <div className="floatLeft col-md-3">
              <b> New Vehicle Status: </b> {licnData && licnData.reqData ? getCodeName(VehicleStatus, licnData.reqData.new_vehicle_status) : '-'}
            </div>
            {licnData && licnData.reqData && licnData.reqData.new_vehicle_status === 'ACH' && <div className="floatLeft col-md-3">
              <b> Effective Date: </b> {licnData && licnData.reqData ? dateObjectToString(licnData.reqData.effective_date, "dd/mm/YY") : '-'}
            </div>}
          </div>


        </div>
      </FormWrapper>
    </UICard>
  );
};
const AddLicenceRecieptInfoDisplay = (props) => {
  const [formState] = useContext(FormContext);
  const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  return (
    <UICard>
      <FormWrapper
        setGroupName={formKey}
        apiUrl={`${addLicenceReceiptTransactionAPI.url}/get-licence/${request_id}`}
        onRebuildResponseFn={(response) => {
          // console.log('REE===', response);
          return {
            entry_mode: response.entry_mode,
            tx_type: response.txn_type,
            licence_type: response.licence_type_code,
            issue_mode: response.issue_mode,
            serial_receipt_no: response.receipt_serial_number,
            licence_receipt_no: response.licence_no_receipt_no,
            from: response.from,
            to: response.to,
            issued_date: response.issued_date,
            fee: response.fee,
            fine: response.fine,
            addition: response.addition,
            deduction: response.deduction,
            print_required: response.print_required,
            total: (Number(response.fee) + Number(response.fine) + Number(response.addition) - Number(response.deduction)).toFixed(2),

            "_onLoad": true
          }
        }}>
        <div className="row">
          <div className="col-md-2 col-sm-4 floatLeft">
            <strong> Entity Mode: </strong> {_get(formState, `${formKey}.entry_mode`, null) === "MIS" ? "Missing Licence" :
              _get(formState, `${formKey}.entry_mode`, null) === "COR" ? "Licence Correction" : "-"}
          </div>
          <div className="col-md-3 col-sm-4 floatLeft">
            <strong> Tx Type: </strong>{_get(formState, `${formKey}.tx_type`, null) === "LIC" ? "Licence" :
              _get(formState, `${formKey}.tx_type`, null) === "ARR" ? "Arrears" : "-"}
          </div>
          <div className="col-md-5 col-sm-4 floatLeft">
            <strong> Licence Type: </strong> {_get(formState, `${formKey}.licence_type`, null) === "NO" ? "Normal Licence" :
              _get(formState, `${formKey}.licence_type`, null) === "CA" ? "Carnet Licence" : _get(formState, `${formKey}.licence_type`, null) === "NU" ? "Non User Licence" : "-"}
          </div>

        </div>
        <div className="row">
          <div className="col-md-2 col-sm-4 floatLeft">
            <strong> Issue Mode: </strong>{_get(formState, `${formKey}.issue_mode`, null) === "FUL" ? "Full Licence" :
              _get(formState, `${formKey}.issue_mode`, null) === "TMP" ? "Temporary Licence" : "-"}
          </div>

          <div className="col-md-3 col-sm-4 floatLeft">
            <strong> Lic No/ Rec No: </strong> {_get(formState, `${formKey}.licence_receipt_no`, null)}
          </div>
          <div className="col-md-5 col-sm-4 floatLeft">
            <strong> Serial Number/ Reciept Number: </strong>{_get(formState, `${formKey}.serial_receipt_no`, null)}
          </div>


        </div>
        <div className="row">
          <div className="col-md-2 col-sm-4 floatLeft">
            <strong> From: </strong>{dateObjectToString(_get(formState, `${formKey}.from`, null), "dd/mm/YY")}
          </div>
          <div className="col-md-3 col-sm-4 floatLeft">
            <strong> To: </strong> {dateObjectToString(_get(formState, `${formKey}.to`, null), "dd/mm/YY")}
          </div>
          <div className="col-md-5 col-sm-4 floatLeft">
            <strong> Issued Date: </strong>{dateObjectToString(_get(formState, `${formKey}.issued_date`, null), "dd/mm/YY")}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-sm-4 floatLeft">
            <strong> Fee (LKR): </strong>{numberThousandSeperator(Number(_get(formState, `${formKey}.fee`, '0.00')).toFixed(2))}
          </div>
          <div className="col-md-3 col-sm-4 floatLeft">
            <strong> Fine (LKR): </strong> {numberThousandSeperator(Number(_get(formState, `${formKey}.fine`, '0.00')).toFixed(2))}
          </div>
          <div className="col-md-5 col-sm-4 floatLeft">
            <strong> Additions (LKR): </strong>{numberThousandSeperator(Number(_get(formState, `${formKey}.addition`, '0.00')).toFixed(2))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-sm-4 floatLeft">
            <strong> Deductions (LKR): </strong> {numberThousandSeperator(Number(_get(formState, `${formKey}.deduction`, '0.00')).toFixed(2))}
          </div>
          <div className="col-md-3 col-sm-4 floatLeft">
            <strong> Total (LKR): </strong> {numberThousandSeperator(Number(_get(formState, `${formKey}.total`, '0.00')).toFixed(2))}
          </div>
          <div className="col-md-5 col-sm-4 floatLeft">
            <strong> Print Required: </strong>{_get(formState, `${formKey}.print_required`, null) === 1 ? 'Yes' : 'No'}
          </div>
        </div>





      </FormWrapper>
    </UICard>
  );
};


const SetEffectiveDateInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  console.log('ddeeee', props);
  // const [data, setData] = useState({})
  const getData = async () => {
    const sendAPIRequest = (apiUrl) => {
      return new Promise((resolve, reject) => {
        callApi(apiUrl)
          .headers(true)
          .method("get")
          .isMultipart(false)
          .send((error, result) => {
            if (error) {
              reject(error);
              // console.log('EEE====', error);
            }
            console.log('EEE====22222', result);
            resolve(result?.data);
          })
      });
    };

    const lcnData = sendAPIRequest(`${getLatestLicenseAPI.url}/${_get(props, `row.vehicle_no`, null)}`);
    const effReqData = sendAPIRequest(`${getRequestedEffDataAPI.url}/${_get(props, `row.request_id`, null)}`);

    Promise.all([lcnData, effReqData])
      .then(result => {
        console.log('rrrr1111=====================', result);

        const setResult = result && result.length !== 0 ? result[0].data : {}
        const setResultEff = result && result.length !== 0 ? result[1].data : {}
        const newData = {
          licnData: setResult,
          effData: setResultEff
        }

        formAction.changeInputFn('allTaskData', "setEffData", "", newData);


        // setData(setResult)

      });

  }

  const getReqApprovalLicenceTypeData = (props) => {
    callApi(`${getLatestLicenseAPI.url}/${props.row.vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {

          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

        }
      });
  }

  const getNormalLicenceFee = (props) => {
    let queryObj = {}
    if ((_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null) &&
      (_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null)) {
      queryObj = {
        vehicle_no: props.row.vehicle_no,
        licence_end_type: 0,
        is_reissuance: false,
        licence_period_start_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", null)) : null,
        licence_period_end_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_end_at", null)) : null,
      };
    } else {
      queryObj = {
        vehicle_no: props.row.vehicle_no,
        licence_end_type: 0,
        is_reissuance: false,
        temp_request: false
      };
    }


    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totArrearsAddition = 0;
          let totArrearsDeduction = 0;
          let totAddition = 0;
          let totdeduction = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;

          const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

          _get(result, "data.addition_deduction_summary", []).forEach(value => {
            if (value.charge_effect_code === "DED") {
              totdeduction += value.total

            }
            if (value.charge_effect_code === "ADD") {
              totAddition += value.total

            }

          });
          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
            totArrearsAddition += value.addition_total
            totArrearsDeduction += value.deduction_total
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            totAddition + _get(result, "data.additions.current_licence", 0) -
            (totdeduction + _get(result, "data.deductions.current_licence", 0)))
            + serviceCharge;

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
      });
  };

  const toDateLicencePeriod = _get(formState, "work_flow_record", null) !== null && _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null ?
    dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_end_at", '-'), "dd/mm/YY") : _get(formState, 'licence_validity_period', null) !== null && _get(formState, 'licence_validity_period.endDatesData', null) && _get(formState, 'licence_validity_period.endDatesData', null) !== null && _get(formState, 'licence_validity_period.endDatesData', []).length !== 0 ? _get(formState, 'licence_validity_period.endDatesData', [{ id: "0", value: "-" }])[0]['value'] :
      _get(formState, "work_flow_record", null) !== null && _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null ? _get(formState, "work_flow_record.data.carnet_licence_end_at", '-') : _get(formState, 'latest_licence_data.data', null) !== null && _get(formState, 'latest_licence_data.data.valid_to', null) !== null ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_to', '-'), "dd/mm/YY") : (!isEmpty(_get(formState, 'licence_charges_form.currentLicenceEndDate', null)) ? dateObjectToString(_get(formState, 'licence_charges_form.currentLicenceEndDate', '-'), "dd/mm/YY") : dateObjectToString(new Date(), "dd/mm/YY"))

  const fromDateLicencePeriod = _get(formState, "work_flow_record", null) !== null && _get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null ?
    dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", '-'), "dd/mm/YY") : _get(formState, 'licence_validity_period', null) !== null && _get(formState, 'licence_validity_period.startDate', null) !== null && _get(formState, 'licence_validity_period.startDate', null) !== '' ?
      dateObjectToString(_get(formState, 'licence_validity_period.startDate', null), "dd/mm/YY") : _get(formState, 'latest_licence_data.data', null) !== null && _get(formState, 'latest_licence_data.data.valid_from', null) !== null
        ? dateObjectToString(_get(formState, 'latest_licence_data.data.valid_from', '-'), "dd/mm/YY")


        : (props.carnet ? (!isEmpty(_get(formState, 'licence_charges_form.currentLicenceStartDate', null))
          ? dateObjectToString(_get(formState, 'licence_charges_form.currentLicenceStartDate', '-'), "dd/mm/YY") : "")

          : (props.isCarnet && _get(formState, 'carnet.fromDate', null) !== null ? dateObjectToString(_get(formState, 'carnet.fromDate', null), "dd/mm/YY") : !props.isCarnet && !isEmpty(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-')) ? dateObjectToString(_get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-'), "dd/mm/YY") :
            dateObjectToString(new Date(), "dd/mm/YY")))

  useEffect(() => {
    getData()
    getNormalLicenceFee(props)
    getReqApprovalLicenceTypeData(props)
  }, [])



  const licnData = _get(formState, `allTaskData.setEffData.licnData`, {})
  const effData = _get(formState, `allTaskData.setEffData.effData`, {})

  return (
    <UICard>
      <div className="col-md-12 col-sm-12 paddingLeftNone floatLeft">
        <div className="row">
          <div className="floatLeft col-md-2 d-flex flex-row">
            <p> <strong>Licence Period &nbsp;:&nbsp;&nbsp; </strong></p>
            <p>  {licnData && licnData.valid_from ? dateObjectToString(licnData?.valid_from, "dd/mm/YY") : licnData.current_licence_status === 'NL' ? fromDateLicencePeriod : '-'}
              <br />
              {licnData && licnData.valid_to ? dateObjectToString(licnData?.valid_to, "dd/mm/YY") : licnData.current_licence_status === 'NL' ? toDateLicencePeriod : '-'}</p>
          </div>

          <div className="floatLeft d-flex flex-row col-md-3 ">
            <p><strong>Effective Date &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp; </strong></p>
            <p>{effData && effData.effective_date ? dateObjectToString(effData.effective_date, "dd/mm/YY") : '-'}</p>
          </div>

          <div className="floatLeft d-flex flex-row col-md-4 ">
            <p><strong>Licence Status &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp; </strong></p>
            <p>{_get(formState, `latest_licence_data.data.current_licence_status`, undefined) !== undefined ? 
              (getCodeName(CurrentLicenceStatus, _get(formState, `latest_licence_data.data.current_licence_status`, 'NL')) === "Renewable") ? "Valid" :
              (getCodeName(CurrentLicenceStatus, _get(formState, `latest_licence_data.data.current_licence_status`, 'NL')) === "Renewable") ? "Valid" : 
              getCodeName(CurrentLicenceStatus, _get(formState, `latest_licence_data.data.current_licence_status`, 'NL')) : 'No current licence'
              }</p>
          </div>
        </div>

      </div>
    </UICard>
  );
};

const UpdateVehicleInfoDisplay = (props) => {

  const [formState, formAction] = useContext(FormContext);
  const [oldProvince, setoldProvince] = useState(" ");
  const request_id = props.row.request_id;
  const [pro, setPro] = useState();
  let pro1;
  const formKey = "approvalRequest";


  return (
    <UICard>
      <FormWrapper
        setGroupName={formKey}

        apiUrl={`${updateVehicleRequestAPI.url}/${request_id}`}
        onRebuildResponseFn={(response) => {
          console.log('REEEESSSS===]]]]]]]]]]]]]]]]]]]', response);
          formAction.changeInputFn('work_flow_record', "data", "", _get(response, "work_flow_record", {}));
          return {
            vehicle: response,

            "_onLoad": true
          }
        }}
      >

        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-2">
            <strong>Current Value</strong>
          </div>
          <div className="col-md-2">
            <strong>DMT Value</strong>
          </div>
          <div className="col-md-2">
            <strong>New Value</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Chassis</strong>
          </div>
          <div className="col-md-2">{getLabelValue(_get(formState, `${formKey}.vehicle.vehicle.chassis_no`, ""), true)}</div>
          <div className="col-md-2">{getLabelValue(_get(formState, `${formKey}.vehicle.vehicle.chassis_no`, ""), true)}</div>
          <div className="col-md-2">{getLabelValue(_get(formState, `${formKey}.vehicle.chassis_no`, ""), true)}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Engine</strong>
          </div>
          <div className="col-md-2">{getLabelValue(_get(formState, `${formKey}.vehicle.vehicle.engine_no`, ""), true)}</div>
          <div className="col-md-2">{getLabelValue(_get(formState, `${formKey}.vehicle.vehicle.engine_no`, ""), true)}</div>
          <div className="col-md-2">{getLabelValue(_get(formState, `${formKey}.vehicle.engine_no`, ""), true)}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Fisrt Reg Date</strong>
          </div>
          <div className="col-md-2">{dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.first_reg_date`, "-"), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.first_reg_date`, "-"), "dd/mm/YY") : '-'}</div>
          <div className="col-md-2">{dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.first_reg_date`, "-"), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.first_reg_date`, "-"), "dd/mm/YY") : '-'}</div>
          <div className="col-md-2">{dateObjectToString(_get(formState, `${formKey}.vehicle.first_reg_date`, "-"), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.vehicle.first_reg_date`, "-"), "dd/mm/YY") : '-'}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Tare (Kg)</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.tare_weight`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.tare_weight`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.tare_weight`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Gross (KG)</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.gross_weight`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.gross_weight`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.gross_weight`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Seats (Exp Driver)</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.no_of_seats`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.no_of_seats`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.no_of_seats`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Vehicle Condition</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.vehicle_reg_condition.name`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.vehicle_reg_condition.name`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle_reg_condition.name`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Fuel Type</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.fuel_type.name`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.fuel_type.name`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.fuel_type.name`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Vehicle Class</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.vehicle_class.name`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.vehicle_class.name`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle_class.name`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Province</strong>
          </div>
          <div className="col-md-2">{getCodeName(ProvincesByIds, _get(formState, `${formKey}.vehicle.vehicle.province_id`, "-"))}</div>
          <div className="col-md-2">{getCodeName(ProvincesByIds, _get(formState, `${formKey}.vehicle.vehicle.province_id`, "-"))}</div>
          <div className="col-md-2">{getCodeName(ProvincesByIds, _get(formState, `${formKey}.vehicle.province`, "-"))}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Manufactured Year</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.yom`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.yom`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.manufactured_year`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Cylinder Capacity</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.cylinder_capacity`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.cylinder_capacity`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.cylinder_capacity`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Tyre Size</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.tyre_size`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.tyre_size`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.tyre_size`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Wheel Base</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.wheel_base`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.wheel_base`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.wheel_base`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Type Of Body</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.type_of_body`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.type_of_body`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.type_of_body`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Make</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.make`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.make`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.make`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Model</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.model`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.model`, "-")}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.model`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Color 1</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[0] !== "null" ? _get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[0] : ""}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[0] !== "null" ? _get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[0] : ""}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.color_1`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Color 2</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[1] !== "null" ? _get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[1] : ""}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[1] !== "null" ? _get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[1] : ""}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.color_2`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Color 3</strong>
          </div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[2] !== "null" ? _get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[2] : ""}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[2] !== "null" ? _get(formState, `${formKey}.vehicle.vehicle.colors`, "-").split(',')[2] : ""}</div>
          <div className="col-md-2">{_get(formState, `${formKey}.vehicle.color_3`, "-")}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <strong>Effective Date</strong>
          </div>
          <div className="col-md-2">{dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.licence_effective_date`, "-"), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.licence_effective_date`, "-"), "dd/mm/YY") : '-'}</div>
          <div className="col-md-2">{dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.licence_effective_date`, "-"), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.vehicle.vehicle.licence_effective_date`, "-"), "dd/mm/YY") : '-'}</div>
          <div className="col-md-2">{dateObjectToString(_get(formState, `${formKey}.vehicle.effective_date`, "-"), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.vehicle.effective_date`, "-"), "dd/mm/YY") : '-'}</div>
        </div>
      </FormWrapper>
    </UICard>
  );
};

const LicenseWithArrearsInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const vehicle_no = props.row.vehicle_no;
  const formKey = "approvalRequest";
  useEffect(() => {
    // formAction.initFromFn({ "licence_charges_form": { _onLoad: true } });
    // formAction.changeInputFn('la-_licence_data', "data", "", getLa-LicenceState);
    getNormalLicenceFee(props);
  }, []);


  const getRelatedData = (certificate_info_vrp, certificate_info_vft, certificate_info_insurance, certificate_info_vet) => {
    const sendAPIRequest = (apiUrl) => {
      return new Promise((resolve, reject) => {
        callApi(apiUrl)
          .headers(true)
          .method("get")
          .isMultipart(false)
          .send((error, result) => {
            if (error) {
              reject(error);
            }

            resolve(result.data);
          })
      });
    };

    callApi(`${getLatestLicenseAPI.url}/${vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('la-_licence_data', "data", "", result.data.data);

        }
      });

    const vetCompaniesPromise = sendAPIRequest(`${vetCompanyAPI.url}/list?sort_by=name|asc&active=true`);
    const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);
    const vftCompaniesPromise = sendAPIRequest(`${vftCompanyListAPI.url}/list?sort_by=name|asc&active=true`);
    const vrpCompaniesPromise = sendAPIRequest(`${vrpCompanyListAPI.url}/list?sort_by=name|asc&active=true`);

    Promise.all([vetCompaniesPromise, insuranceCompaniesPromise, vftCompaniesPromise, vrpCompaniesPromise])
      .then(result => {
        const vetCompanies = result[0]['data']
        const insuranceCompanies = result[1]['data']
        const vftCompanies = result[2]['data']
        const vrpCompanies = result[3]['data']

        const theVetCompany = vetCompanies.filter(value => value.service['id'] === (certificate_info_vet !== undefined ? certificate_info_vet.service_id : '-'))
        const theInsuranceCompany = insuranceCompanies.filter(value => value.service['id'] === (certificate_info_insurance !== undefined ? certificate_info_insurance.service_id : '-'))
        const theVftCompany = vftCompanies.filter(value => value.service['id'] === (certificate_info_vft !== undefined ? certificate_info_vft.service_id : '-'))
        const theVrpCompany = vrpCompanies.filter(value => value.service['id'] === (certificate_info_vrp !== undefined ? certificate_info_vrp.service_id : '-'))

        const documentValidationCompanies = {
          vetCompany: theVetCompany && theVetCompany.length !== 0 ? theVetCompany[0].service.name : '-',
          insuranceCompany: theInsuranceCompany && theInsuranceCompany.length !== 0 ? theInsuranceCompany[0].service.name : '-',
          vftCompany: theVftCompany && theVftCompany.length !== 0 ? theVftCompany[0].service.name : '-',
          vrpCompany: theVrpCompany && theVrpCompany.length !== 0 ? theVrpCompany[0].service.name : '-',
        }
        console.log('cdddddd============', documentValidationCompanies);
        formAction.changeInputFn(formKey, "companyData", "", documentValidationCompanies);
      }).catch(err => {
        console.log('ERRRR===', err);
      });
  };

  const getNormalLicenceFee = (props) => {
    const queryObj = {
      vehicle_no: props.row.vehicle_no,
      licence_end_type: 0,
      is_reissuance: false,
      temp_request: false
    };

    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        console.log('DDDDDDD====', error);
        if (!error) {
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;


          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            _get(result, "data.additions.arrears", 0) + _get(result, "data.additions.current_licence", 0) -
            (_get(result, "data.deductions.arrears", 0) + _get(result, "data.deductions.current_licence", 0)));

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "total", "", total);
          formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
      });
  };

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <UICard cardHeading="Licence with Arrears Information">
          <FormWrapper
            setGroupName={formKey}
            apiUrl={`${licenceInvalidDocuments.url}/certificates-info/${vehicle_no}`}
            onRebuildResponseFn={(res) => {
              // console.log('RESTTT===', res);

              getRelatedData(res.vrp, res.vft, res.insurance, res.vet)
              return {
                certificate_info_vrp: res.vrp,
                certificate_info_vft: res.vft,
                certificate_info_insurance: res.insurance,
                certificate_info_vet: res.vet,
                "_onLoad": true
              }
            }}>
            <table className="col-md-12 defaultMarginBottom">
              <tr>
                <td><strong>Current Licence Type</strong> &nbsp;&nbsp;{LicenceType[`${_get(formState, `vehicle.data.data.current_license_type.code`, null)}`]} </td>
              </tr>
            </table>
            <table className="col-md-11 defaultMarginTopBottom">
              <tr>
                <th>Arrears Licence Period</th>
                <th className="text-right">Fee (LKR)</th>
                <th className="text-right">Fine (LKR)</th>
                <th className="text-right">Additions (LKR)</th>
                <th className="text-right">Deductions (LKR)</th>
                <th className="text-right">Total (LKR)</th>
              </tr>
              {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
                <tr>
                  <td>{_get(formState, `licence_charges_form.arrearsStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; TO &nbsp;&nbsp; {_get(formState, `licence_charges_form.arrearsEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsEndDate`, ""), "dd/mm/YY") : ""}</td>

                  <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)).toFixed(2))}</td>
                  <td className="text-right" >{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)).toFixed(2))}</td>
                  <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.additions.arrears`, 0)).toFixed(2))}</td>
                  <td className="text-right">({numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.deductions.arrears`, 0)).toFixed(2))})</td>
                  <td className="text-right">{
                    numberThousandSeperator(
                      Number(
                        Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)) +
                        Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)) +
                        _get(formState, `licence_charges_form.data.additions.arrears`, 0) -
                        _get(formState, `licence_charges_form.data.deductions.arrears`, 0)
                      ).toFixed(2)
                    )
                  }</td>

                </tr>
                :
                <tr>No data found</tr>
              }
            </table>

            <table className="col-md-11 defaultMarginTopBottom">
              <tr>
                <th>Current Licence Period</th>
                <th className="text-right">Fee (LKR)</th>
                <th className="text-right">Fine (LKR)</th>
                <th className="text-right">Additions (LKR)</th>
                <th className="text-right">Deductions (LKR)</th>
                <th className="text-right">Total (LKR)</th>
              </tr>
              {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
                <tr>
                  <td >{_get(formState, `licence_charges_form.currentLicenceStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; TO &nbsp;&nbsp; {_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : ""}</td>

                  <td className="text-right" >{_get(formState, `licence_charges_form.totCurrentLicenceFee`, "") && _get(formState, `licence_charges_form.totCurrentLicenceFee`, "") !== null && _get(formState, `licence_charges_form.totCurrentLicenceFee`, "") !== '' ? numberThousandSeperator(Number(_get(formState, `licence_charges_form.totCurrentLicenceFee`, "")).toFixed(2)) : '0.00'}</td>
                  <td className="text-right">{_get(formState, `licence_charges_form.totCurrentLicenceFine`, "") && _get(formState, `licence_charges_form.totCurrentLicenceFine`, "") !== null && _get(formState, `licence_charges_form.totCurrentLicenceFine`, "") !== '' ? numberThousandSeperator(Number(_get(formState, `licence_charges_form.totCurrentLicenceFine`, "")).toFixed(2)) : '0.00'}</td>
                  <td className="text-right" >{_get(formState, `licence_charges_form.data.additions.current_licence`, "") && _get(formState, `licence_charges_form.data.additions.current_licence`, "") !== null && _get(formState, `licence_charges_form.data.additions.current_licence`, "") !== '' ? numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.additions.current_licence`, "")).toFixed(2)) : '0.00'}</td>
                  <td className="text-right">({_get(formState, `licence_charges_form.data.deductions.current_licence`, "") && _get(formState, `licence_charges_form.data.deductions.current_licence`, "") !== null && _get(formState, `licence_charges_form.data.deductions.current_licence`, "") !== '' ? numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.deductions.current_licence`, "")).toFixed(2)) : '0.00'})</td>
                  <td className="text-right">{
                    numberThousandSeperator(Number(Number(_get(formState, `licence_charges_form.totCurrentLicenceFee`, 0)) +
                      Number(_get(formState, `licence_charges_form.totCurrentLicenceFine`, 0)) +
                      Number(_get(formState, `licence_charges_form.data.additions.current_licence`, 0)) -
                      Number(_get(formState, `licence_charges_form.data.deductions.current_licence`, 0))).toFixed(2))
                  }</td>
                </tr>
                :
                <tr>No data found</tr>
              }
            </table>

            <table className="col-md-11 defaultMarginTopBottom">
              <tr>
                <td className={"text-md-right"}><strong>Total Amount (LKR):</strong> &nbsp;&nbsp; 
                  {numberThousandSeperator(
                    Number((
                      Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)) +
                      Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)) +
                      _get(formState, `licence_charges_form.data.additions.arrears`, 0) -
                      _get(formState, `licence_charges_form.data.deductions.arrears`, 0)) + 
                        (_get(formState, `licence_charges_form.data.current_licences[0].fee`, 0) +
                        _get(formState, `licence_charges_form.data.current_licences[0].fine`, 0) +
                        _get(formState, `licence_charges_form.data.additions.current_licence`, 0) -
                        _get(formState, `licence_charges_form.data.deductions.current_licence`, 0)
                        )
                    ).toFixed(2)
                  )} 
                </td>
              </tr>
            </table>
            <div className="col-md-12 col-sm-12">

              <table className={"fullWidthDiv"}>
                {((_get(formState, `${formKey}.certificate_info_vet`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vet.id`, null)) ||
                  (_get(formState, `${formKey}.certificate_info_insurance`, null) !== undefined && _get(formState, `${formKey}.certificate_info_insurance.id`, null)) ||
                  (_get(formState, `${formKey}.certificate_info_vft`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vft.id`, null)) ||
                  (_get(formState, `${formKey}.certificate_info_vrp`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vrp.id`, null)))
                  ?
                  <thead>
                    <tr>
                      <th></th>
                      <th>Status</th>
                      <th>Company</th>
                      <th>Document No</th>
                      <th>Issued Date</th>
                      <th>Expiry Date</th>
                      <th>Validity Expiry Date</th>
                    </tr>
                  </thead>
                  :
                  <tr>No document data found</tr>
                }
                <tbody>
                  {(_get(formState, `${formKey}.certificate_info_vet`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vet.id`, null)) &&
                    <tr>
                      <td><strong>VET</strong></td>
                      <td>{_get(formState, `${formKey}.certificate_info_vet.type`, null) === "PSS" ? "Valid" : "Invalid"}</td>
                      <td>{_get(formState, `${formKey}.companyData.vetCompany`, '-')}</td>
                      <td>{_get(formState, `${formKey}.certificate_info_vet.certificate_no`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.issued_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.issued_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                    </tr>
                  }
                  {(_get(formState, `${formKey}.certificate_info_insurance`, null) !== undefined && _get(formState, `${formKey}.certificate_info_insurance.id`, null)) &&
                    <tr>
                      <td><strong>Insuarance</strong></td>
                      <td>{_get(formState, `${formKey}.certificate_info_insurance.status`, null) === "ACT" ? "Valid" : "Invalid"}</td>
                      <td>{_get(formState, `${formKey}.companyData.insuranceCompany`, '-')}</td>
                      <td>{_get(formState, `${formKey}.certificate_info_insurance.reference`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.issued_at`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") : '-'}</td>
                    </tr>
                  }
                  {(_get(formState, `${formKey}.certificate_info_vft`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vft.id`, null)) &&
                    <tr>
                      <td><strong>VFT</strong></td>
                      <td>{_get(formState, `${formKey}.certificate_info_vft.type`, null) === "PSS" ? "Valid" : "Invalid"}</td>
                      <td>{_get(formState, `${formKey}.companyData.vftCompany`, '-')}</td>
                      <td>{_get(formState, `${formKey}.certificate_info_vft.certificate_no`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                    </tr>
                  }
                  {(_get(formState, `${formKey}.certificate_info_vrp`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vrp.id`, null)) &&
                    <tr>
                      <td><strong>VRP</strong></td>
                      <td>{_get(formState, `${formKey}.certificate_info_vrp.status`, null) === 'Active' ? 'Valid' : 'Invalid'}</td>
                      <td>{_get(formState, `${formKey}.companyData.vrpCompany`, '-')}</td>
                      <td>{_get(formState, `${formKey}.certificate_info_vrp.route_permit_no`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.issued_at`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                    </tr>
                  }

                </tbody>
              </table>
            </div>

          </FormWrapper>
        </UICard>
      </div>
    </div>
  );
};


const ReverseLastTransactionInfoDisplay = (props) => {
  console.log('PROOOO', props);
  const { uiType } = props
  const action = props.row.action
  const isAssignedTab = props.fromParent.ui_type && props.fromParent.ui_type == "assigned" ?
    true : false
  const [formState, formAction] = useContext(FormContext);
  const request_id = "99998888";
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  //const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  let licenceType;


  console.log('RRR', props);

  // const [formState, formAction] = useContext(FormContext);
  //     //const request_id = props.row.request_id;
  //     const {uiType} = props
  //     const request_id = "99998888";
  //     const formKey = "approvalRequest_payments";
  //     const vehicle_no = _get(
  //         formState,
  //         "vehicle_search_form.searchKey",
  //         null
  //       );
  useEffect(() => {
    if (uiType === 'viewApproval' || uiType === 'APR') {
      if(action === 'APR') {
        getNewData()
      } else {
        getNewDataBeforeApprove()
      }
      // getNewDataBeforeApprove()
    }
    getPaymentDetails()
  }, [])

  const getNewDataBeforeApprove = () => {
    callApi(`${getLatestLicenseAPI.url}/${uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : props.row.vehicle_no}`)
                .method("get")
                .headers(true)
                .isMultipart(false)
                .send((error, result) => {
                  if (!error) {
                    console.log('LICENCE-->', result.data.data);
                    formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

                  }
                });
}

  const getNewData = () => {
    callApi(`${getLatestRevLicenseAPI.url}/${uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : props.row.vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('LICENCE-->', result.data.data);
          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

        }
      });
  }


  const getPaymentDetails = () => {
    callApi(`${vehiclePaymentHistoryAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}/true`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          const newData = result.data.data && result.data.data.length !== 0 ? result.data.data.sort((a, b) => {
            const date1 = new Date(a['tx_date_time'])
            const date2 = new Date(b['tx_date_time'])
            return date2 - date1
          }) : []
          formAction.changeInputFn('payment_data', "data", "", newData && newData.length !== 0 ? newData[0] : []);

        }
      });
  }

  const RowNo = (props) => {
    return (
      <RawIndexMemo
        page_size={_getInt(props.pagingObject, "per_page", 1)}
        current_page={_getInt(props.pagingObject, "current_page", 1)}
        index={props.rowIndex || 0}
      />
    )
  };

  switch (_get(formState, `${formKey}.licence_type`, '-')) {
    case "NO":
      licenceType = 'NL-T - Normal Revenue Licence - Temporary';
      break;
    case "FE":
      licenceType = 'FR-T - Free Licence - Temporary';
      break;
    case "CA":
      licenceType = 'CN-T - Carnet Licence - Temporary';
      break;
  }
  return (
    <UICard>
      <div className="col-md-12 col-sm-12">
        {console.log('ghggghhh')}
        <UICard>
          <FormWrapper
            setGroupName={formKey}
            apiUrl={`${getLicenceTransactionAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
            isReverseTx={true}
            onRebuildResponseFn={(response) => {
              console.log('RESP--', response);
              if (response && response.length !== 0 &&
                response[0].transaction_details && response[0].licence_data) {
                console.log('RESP--1', 'response');

                return {
                  // entry_mode: response[0].entry_mode,
                  tx_id: response[0].transaction_details.id,
                  tx_type: response[0].arrears_only == 1 ? 'Arrears' : 'Licence',
                  tx_status: response[0].transaction_details.status === 1 ? 'Success' : 'Failed',
                  licence_type: response[0].licence_type_code,
                  temp_licence: response[0].temp_licence,
                  issue_mode: response[0].temp_licence == 1 ? 'Temporary Licence' : 'Full Licence',
                  serial_receipt_no: response[0].transaction_details.txn_number,
                  licence_receipt_no: response[0].licence_number,
                  from: response[0].licence_data.valid_from,
                  to: response[0].licence_data.valid_to,
                  licence_id: response[0].licence_data.id,
                  // issued_date: response[0].issued_date,
                  fee: response[0].fee,
                  fine: response[0].fine,
                  addition: response[0].addition,
                  deduction: response[0].deduction,
                  // print_required: response[0].print_required,
                  created_at: response[0].transaction_details.created_at,
                  created_at_location: response[0].created_location_name,
                  created_by: response[0].created_by_name,
                  created_role: response[0].created_role,

                  "_onLoad": true
                }
              }

            }}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-1"><strong>Tx. No.</strong></div>
                  <div className="col-md-3">{_get(formState, `${formKey}.serial_receipt_no`, '-')}</div>
                  <div className="col-md-1"><strong>Tx. Status</strong></div>
                  <div className="col-md-3">{_get(formState, `${formKey}.tx_status`, '-')}</div>
                  <div className="col-md-1"><strong>Tx. Type</strong></div>
                  <div className="col-md-3">{_get(formState, `${formKey}.tx_type`, '-')}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-1"><strong>Tx. Date & Time</strong></div>
                  <div className="col-md-3">{dateObjectToString(_get(formState, `${formKey}.created_at`, '-'), "dd/mm/YY HH:MM")}</div>
                  <div className="col-md-1"><strong>Created by</strong></div>
                  <div className="col-md-3">{`${_get(formState, `${formKey}.created_by`, '-')}(${_get(formState, `${formKey}.created_role`, '-')})`}</div>
                  <div className="col-md-1"><strong>Created at</strong></div>
                  <div className="col-md-3">{_get(formState, `${formKey}.created_at_location`, '-')}</div>
                </div>
              </div>

            </div>
            <hr></hr>
            <div className="row">
              <div className="col-md-12">
                <div className="row">

                  <div className="col-md-1"><strong>Licence No / Receipt No</strong></div>
                  <div className="col-md-3">{_get(formState, `${formKey}.licence_receipt_no`, '-')}</div>
                  <div className="col-md-1"><strong>Licence Type</strong></div>
                  <div className="col-md-3">{_get(formState, `${formKey}.temp_licence`, '-') === 1 ? licenceType : LicenceType[`${_get(formState, `${formKey}.licence_type`, '-')}`]}</div>
                  <div className="col-md-1"><strong>Validity Period(From - To)</strong></div>
                  <div className="col-md-3">{dateObjectToString(_get(formState, `${formKey}.from`, '-'), "dd/mm/YY")} - {dateObjectToString(_get(formState, `${formKey}.to`, '-'), "dd/mm/YY")}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-1"><strong>Licence issue mode</strong></div>
                  <div className="col-md-3">{_get(formState, `${formKey}.issue_mode`, '-')}</div>

                  <div className="col-md-1"><strong>Year</strong></div>
                  <div className="col-md-3">{new Date(_get(formState, `${formKey}.to`, '-')).getFullYear()}</div>
                </div>
              </div>

            </div>
          </FormWrapper>
        </UICard>
      </div>

      {/* <LastTransactionInformation {...props} /> */}
      <div className="col-md-12 col-sm-12">
        <div className="row">
          {console.log('FORM_SATATEEE', formState)}
          <div className="col-md-12 col-sm-12 defaultPaddingTopBottom">
            <UICard>
              {console.log('FORRRRRRMMMMMMMMMM', _get(formState, `latest_licence_data.data.documents`, null))}
              <table className={"fullWidthDiv"}>
                {(_get(formState, `latest_licence_data.data.documents`, null) !== null && _get(formState, `latest_licence_data.data.documents`, null).length > 0) ? <thead>
                  <tr>
                    <th>Document</th>
                    {/* <th>Status</th> */}
                    <th>Document No</th>
                    <th>Company</th>
                    <th>Issued Date</th>
                    <th>Expiry Date</th>
                  </tr>
                </thead>
                  :
                  <thead>No Document Details Found</thead>
                }

                <tbody>
                  {(_get(formState, `latest_licence_data.data.documents`, null) !== null && _get(formState, `latest_licence_data.data.documents`, null).length > 0) ?
                    _get(formState, `latest_licence_data.data.documents`, null).map(doc => {
                      return (
                        <tr>
                          <td>{doc.doc_type_code === "INS" ? 'Insurance' : doc.doc_type_code === "VFT" ? 'VFT' : doc.doc_type_code === "VET" ? 'VET' : doc.doc_type_code === "VRP" ? 'VRP' : null}</td>
                          {/* <td>{_get(formState, `insurance_form.isInsurance`, null) ? "Valid" : "Invalid"}</td> */}
                          <td>{doc.doc_ref_no}</td>
                          <td>{doc.doc_company_name}</td>
                          <td>{dateObjectToString(doc.issued_date, "dd/mm/YY") !== '01/01/1970' ? dateObjectToString(doc.issued_date, "dd/mm/YY") : "-"}</td>
                          <td>{dateObjectToString(doc.expiry_date, "dd/mm/YY") !== '01/01/1970' ? dateObjectToString(doc.expiry_date, "dd/mm/YY") : '-'}</td>
                          {/* <td>{dateObjectToString(_get(formState, `insurance_form.exp_date`, null), "dd/mm/YY")}</td> */}
                        </tr>
                      )
                    }) : null
                  }

                </tbody>
              </table>
            </UICard>


          </div>
          {/* <div className="col-md-12 defaultPaddingTopBottom">
                <DataTable
                    tableName={"Document Validation Details"}

                    isSetTableFooter={false}
                    isSetAction={false}
                    tableHeaderList={[
                        { displayName: "Document", key: "document", isSort: false },
                        { displayName: "Document No", key: "document_no", isSort: false },
                        { displayName: "Company", key: "comany", isSort: false },
                        { displayName: "Issued Date", key: "issuedate", isSort: false },
                        { displayName: "Expiry Date", key: "expiredate", isSort: false },
                    ]}
                    tableBodyList={[
                        {
                            "document": "Doc 1",
                            "document_no": "001",
                            "company": "AIA",
                            "issuedate": "2020/10/10",
                            "expiredate": "2021/10/09"
                        },
                        {
                            "document": "Doc 2",
                            "document_no": "002",
                            "company": "AIA",
                            "issuedate": "2020/10/10",
                            "expiredate": "2021/10/09"
                        },
                    ]}
                />

            </div> */}

          <div className="col-md-8">
            <UICard cardHeading="Payment Details">
              <FormWrapper
                setGroupName={formKey}
                apiUrl={isAssignedTab && action === 'APR' ?
                  `${getLicenceTransactionAPI.url}/get-rev-data/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`
                  : `${getLicenceTransactionAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
                isReverseTx={true}
                onRebuildResponseFn={(response, error) => {
                  console.log('EEE--', response, error);
                  if (response && response.length !== 0 &&
                    response[0].transaction_details && response[0].licence_data) {
                    formAction.changeInputFn(formKey, "payTypeData", "", response[0].transaction_details['payment_type_data']);
                    return {
                      tx_type: response[0].arrears_only == 1 ? 'Arrears' : 'Licence',
                      tx_status: response[0].transaction_details.status === 1 ? 'Success' : 'Failed',
                      licence_type: response[0].licence_type_code,
                      issue_mode: response[0].temp_licence == 1 ? 'Temporary Licence' : 'Full Licence',
                      serial_receipt_no: response[0].transaction_details.id,
                      licence_receipt_no: response[0].licence_number,
                      from: response[0].licence_data.valid_from,
                      to: response[0].licence_data.valid_to,
                      // issued_date: response[0].issued_date,
                      fee: response[0].fee,
                      fine: response[0].fine,
                      addition: response[0].addition,
                      deduction: response[0].deduction,
                      total: response[0].total,
                      service_charge: response[0].service_charge,
                      postal_charge: response[0].postal_charge,
                      // print_required: response[0].print_required,
                      created_at: response[0].transaction_details.created_at,
                      created_at_location: response[0].created_location_name,
                      created_by: response[0].created_by_name,
                      created_role: response[0].created_role,

                      "_onLoad": true
                    }
                  }
                }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3"><strong>Fee(LKR)</strong></div>
                      <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.fee`, '0.00')).toFixed(2))}</div>
                    </div>
                    <div className="row">

                      <div className="col-md-3"><strong>Fine(LKR)</strong></div>
                      <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.fine`, '0.00')).toFixed(2))}</div>
                    </div>
                    <div className="row">

                      <div className="col-md-3"><strong>Service Charge(LKR)</strong></div>
                      <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.service_charge`, '0.00')).toFixed(2))}</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">

                      <div className="col-md-3"><strong>Additions(LKR)</strong></div>
                      <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.additions`, '0.00')).toFixed(2))}</div>
                    </div>
                    <div className="row">

                      <div className="col-md-3"><strong>Deductions(LKR)</strong></div>
                      <div className="col-md-3 text-md-right">({numberThousandSeperator(Math.abs(Number(_get(formState, `payment_data.data.deductions`, '0.00'))).toFixed(2))}) </div>
                    </div>
                    <div className="row">

                      <div className="col-md-3"><strong>Total(LKR)</strong></div>
                      <div className="col-md-3 text-md-right">{numberThousandSeperator(Number(_get(formState, `payment_data.data.total`, '0.00')).toFixed(2))}</div>
                    </div>
                  </div>

                </div>
              </FormWrapper>
            </UICard>
          </div>
          <div className="col-md-4">
            <DataTable

              isSetTableHeader={false}
              isSetTableFooter={false}
              isSetAction={false}
              elementWrapperStyle={"paymentType"}
              tableHeaderList={[
                { displayName: "Payment Type", key: "payment_type_code", isSort: false, onPrintFn: ({ payment_type_code }) => payment_type_code ? pdmtPaymentCategories[`${payment_type_code}`] : "-", },
                { displayName: "Amount(LKR)", key: "amount", isSort: false, isRightAlign: true, onPrintFn: ({ amount }) => amount ? numberThousandSeperator(Number(amount).toFixed(2)) : "0.00" },

              ]}
              tableBodyList={_get(formState, `${formKey}.payTypeData`, [])}
            />

          </div>
          <div className="col-md-12 defaultPaddingTop">
            <DataTable
              tableName={"Printed Details"}
              apiUrl={isAssignedTab && action === 'APR' ?
                `${getLicenceTransactionRecieptPrintRevDataAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`
                : `${getLicenceTransactionRecieptPrintDataAPI.url}/${uiType === 'viewApproval' ? props.row.vehicle_no : uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
              isSetTableFooter={false}
              isSetAction={false}
              tableHeaderList={[
                { displayName: "Print Sequence No", key: "__no__", isSort: false },
                { displayName: "Print Type", key: "is_reprint", isSort: false, onPrintFn: ({ is_reprint }) => is_reprint === 1 ? 'Re-print' : is_reprint === 0 ? "New" : "" },
                { displayName: "Date & Time", key: "printed_at", isSort: false, onPrintFn: ({ printed_at }) => !isEmpty(printed_at) ? dateObjectToString(printed_at, "dd/mm/YY HH:MM") : "-" },
                { displayName: "Printed By", key: "print_by_name", isSort: false },
                { displayName: "Printed At", key: "printed_location_name", isSort: false },
                { displayName: "Serial Number", key: "serial_number", isSort: false },
              ]}
              specificComponents={{
                "__no__": RowNo
              }}
            />
          </div>
        </div>
      </div>
    </UICard>
  );
};

const RequestLicenceInfoDisplay = (props) => {
  // Invalid Documents
  const [formState, formAction] = useContext(FormContext);
  const vehicle_no = props.row.vehicle_no;
  const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  console.log('FORMMMMM=======', props.uiType === 'viewApproval');
  const [startingDate, setStartingDate] = useState(null);
  useEffect(() => {
    // formAction.initFromFn({ "licence_charges_form": { _onLoad: true } });
    // formAction.changeInputFn('la-_licence_data', "data", "", getLa-LicenceState);
    getInsuranceData()
    getVetData()
    getVftData()
    getVrpData()
    getLicenceStartDateFn();
  }, []);

  // useEffect(() => {
  //   getRelatedData()
  // }, []);

  const getRelatedData = (docData) => {

    const vetCompaniesPromise = sendAPIRequest(`${vetCompanyAPI.url}/list?sort_by=name|asc&active=true`);
    const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);
    const vftCompaniesPromise = sendAPIRequest(`${vftCompanyListAPI.url}/list?sort_by=name|asc&active=true`);
    const vrpCompaniesPromise = sendAPIRequest(`${vrpCompanyListAPI.url}/list?sort_by=name|asc&active=true`);

    Promise.all([vetCompaniesPromise, insuranceCompaniesPromise, vftCompaniesPromise, vrpCompaniesPromise])
      .then(result => {
        const vetCompanies = result[0]['data']
        const insuranceCompanies = result[1]['data']
        const vftCompanies = result[2]['data']
        const vrpCompanies = result[3]['data']

        // console.log('CNMPANIES=====>', vftCompanies);
        const theVetCompany = vetCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theInsuranceCompany = insuranceCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theVftCompany = vftCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theVrpCompany = vrpCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id !== null ? docData.service_id : docData.service.id : '-'))


        const vetCompany = theVetCompany && theVetCompany.length !== 0 ? theVetCompany[0].service.name : '-'
        const insuranceCompany = theInsuranceCompany && theInsuranceCompany.length !== 0 ? theInsuranceCompany[0].service.name : '-'
        const vftCompany = theVftCompany && theVftCompany.length !== 0 ? theVftCompany[0].service.name : '-'
        const vrpCompany = theVrpCompany && theVrpCompany.length !== 0 ? theVrpCompany[0].service.name : '-'

        if (vetCompany !== '-') {
          formAction.changeInputFn(formKey, "vetCompanyData", "", vetCompany);
        }
        if (insuranceCompany !== '-') {
          formAction.changeInputFn(formKey, "insCompanyData", "", insuranceCompany);
        }
        if (vftCompany !== '-') {
          formAction.changeInputFn(formKey, "vftCompanyData", "", vftCompany);
        }
        if (vrpCompany !== '-') {
          formAction.changeInputFn(formKey, "vrpCompanyData", "", vrpCompany);
        }
      }).catch(err => {
        console.log('ESRRR--->', err);
      });
  };

  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result?.data);
        })
    });
  };

  console.log('PPUUUUUU===', _get(formState, `${formKey}.companyData`, '-'));
  const getNormalLicenceFee = (licenceEndtype = 0, isReIssuance = false, startDate = null, endDate = null, temp_request = false) => {
    let tempReq = temp_request;
        if (temp_request) {
            tempReq = {
                runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false, validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0), charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
            }
        }

        const queryObj = {
          vehicle_no: props.row.vehicle_no,
          requested_licence_type: _get(formState, `la-_licence_data.data.vehicleData.current_license_type.code`, ""),
            licence_end_type: licenceEndtype,
            licence_period_start_date: startDate !== null ? dateObjectToString(startDate, "YY-mm-dd") : dateObjectToString(startingDate),
            licence_period_end_date: endDate ? dateObjectToString(endDate, "YY-mm-dd") : null,
            is_reissuance: isReIssuance,
            temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
        };

    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        console.log('DDDDDDD====', error);
        if (!error) {
          console.log('DDDDDDD====', result);
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;


          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            _get(result, "data.additions.arrears", 0) + _get(result, "data.additions.current_licence", 0) -
            (_get(result, "data.deductions.arrears", 0) + _get(result, "data.deductions.current_licence", 0)));

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "total", "", total);
          formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
          console.log('ERR===>', error);
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
      });
  };

  const getLicenceStartDateFn = (isReIssuance = false, temp_request = false) => {
    let tempReq = temp_request;
    if (temp_request) {
        tempReq = {
            runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
            validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
            charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
        }

    }else{
        tempReq=null
    }
    const queryObj = {
        vehicle_no: props.row.vehicle_no,
        is_reissuance: isReIssuance,
        licence_period_start_date: null,
        licence_period_end_date: null,
        is_use_start_date: false,
        temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
        requested_licence_type: _get(formState, `la-_licence_data.data.vehicleData.current_license_type.code`, "")
    };

    callApi(`${normalLicenceStartDateAPI.url}?${fromObjectToQueryString(queryObj)}`)
        .method("get")
        .headers(true)
        .isMultipart(false)
        .send((error, result) => {
            if (!error) {
              console.log('ERRn', result);
              setStartingDate(new Date(result.data.data))
                    formAction.changeInputFn('licence_validity_period', "startDate", "", new Date(result.data.data));
                formAction.changeInputFn('licence_validity_period', "start_onLoad", "", false);
                getLicenceEndDateFn(false, false, new Date(result.data.data))
            } else {
              console.log('ERR', error);
                formAction.changeInputFn('licence_validity_period', "start_onLoad", "", false);
            }

        });
}


const getLicenceEndDateFn = (isReIssuance = false, temp_request = false, startsDate) => {
    let tempReq = temp_request;
    if (temp_request) {
        tempReq = {
            runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
            validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
            charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
        }

    }else{
        tempReq=null
    }

    const queryObj = {
        vehicle_no: props.row.vehicle_no,
        is_reissuance: isReIssuance,
        licence_period_start_date: null,
        licence_period_end_date: null,
        is_use_start_date: false,
        temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
        requested_licence_type: _get(formState, `la-_licence_data.data.vehicleData.current_license_type.code`, "")
    };
        callApi(`${normalLicenceEndDatesAPI.url}?${fromObjectToQueryString(queryObj)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                  console.log('ERRd', result);

                    const endDates = result.data.data.map(value => {
                        return {
                            ...value,
                            requested_licence_end_date: value.value,
                            value: dateObjectToString(value.value, "dd/mm/YY")
                        };
                    });

                    // if (_get(props.getLatestLicenceState, `current_licence_status`, "") === 'VA') {
                    //     formAction.changeInputFn('licence_validity_period', "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));

                    // }
                    formAction.changeInputFn('licence_validity_period', "reissanceEnddDate", "", new Date(_get(props.currentObject, `last_licence_to`, "")));
                    formAction.changeInputFn('licence_validity_period', "firstEnddDate", "", endDates[0]);
                    formAction.changeInputFn('licence_validity_period', "endDatesData", "", endDates);
                    formAction.changeInputFn('licence_validity_period', "endDate", "", !isEmpty(endDates) ? endDates[0].id : '0');
                    formAction.changeInputFn('licence_validity_period', "_onLoad", "", false);
                    getNormalLicenceFee(!isEmpty(endDates) ? endDates[0].id : '0', startsDate, null)


                }
                formAction.changeInputFn('licence_validity_period', "_onLoad", "", false);
            })
}

const getVrpData = () => {
  callApi(`${vrpAPI.url}/${props.row.vehicle_no}?status=Active`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      if (!error) {
        console.log('RWWWWW==vrp', result.data);
        //formKeycertificate_info_vrp
        formAction.changeInputFn(formKey, "certificate_info_vrp", "", result.data.data[0]);
        getRelatedData(result.data.data[0])
      } else {
        console.log('err--->vrp', error);
      }
    })


}

const getInsuranceData = () => {
  callApi(`${insuranceAPI.url}?vehicle_number=${props.row.vehicle_no}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      if (!error) {
        console.log('RWWWWW==ins', result.data);
        //formKeycertificate_info_insurance
        formAction.changeInputFn(formKey, "certificate_info_insurance", "", result.data.data);
        getRelatedData(result.data.data)
      } else {

        console.log('err--->ins', error);
      }
    })


}

const getVetData = () => {
  callApi(`${vetAPI.url}?vehicle_reg_no=${props.row.vehicle_no}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      if (!error) {
        console.log('RWWWWW==vet', result.data);
        //formKeycertificate_info_vet
        formAction.changeInputFn(formKey, "certificate_info_vet", "", result.data.data[0]);
        getRelatedData(result.data.data[0])
      } else {

        console.log('err--->vet', error);
      }
    })


}

const getVftData = () => {
  callApi(`${vftCertificateAPI.url}?sort_by=issued_date|DESC&vehicle_id=${props.row.vehicle_no}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      if (!error) {
        console.log('RWWWWW==vft', result.data);
        //formKeycertificate_info_vft
        formAction.changeInputFn(formKey, "certificate_info_vft", "", result.data.data[0]);
        getRelatedData(result.data.data[0])
      } else {

        console.log('err--->vft', error);
      }
    })


}

const vetValidity = new Date(_get(formState, `${formKey}.certificate_info_vet.issued_at`, null))
  if (vetValidity && vetValidity !== null) {
    vetValidity.setDate(vetValidity.getDate() + _get(formState, 'vet_validity_period.days', 0))
  }
  const vftValidity = new Date(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null))
  if (vftValidity && vftValidity !== null) {
    vftValidity.setDate(vftValidity.getDate() + _get(formState, 'vft_validity_period.days', 0))
  }

  const insStatus = _get(formState, `${formKey}.certificate_info_insurance.status`, null)
  const insuranceUsed = _get(formState, `${formKey}.certificate_info_insurance.used`, null)
  const insValidStatus = (insStatus === "ACT") &&
    (insuranceUsed === 0) ? 'Valid' : 'Invalid'

  const vetValidTo = new Date(vetValidity).setHours(0, 0, 0, 0)
  const vetStatus = _get(formState, `${formKey}.certificate_info_vet.status`, null)
  const vetUsed = _get(formState, `${formKey}.certificate_info_vet.used`, null)
  console.log('aaaa',);
  const vetValidStatus = (vetValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vetStatus === 1) &&
    (vetUsed === 0) ? 'Valid' : 'Invalid'

  const vftValidTo = new Date(vftValidity).setHours(0, 0, 0, 0)
  const vftStatus = _get(formState, `${formKey}.certificate_info_vft.type`, null)
  const vftUsed = _get(formState, `${formKey}.certificate_info_vft.used`, null)
  const vftValidStatus = (vftValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vftStatus === "PSS") &&
    (vftUsed === 0) ? 'Valid' : 'Invalid'

  const vrpValidTo = new Date(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null)).setHours(0, 0, 0, 0)
  const vrpStatus = _get(formState, `${formKey}.certificate_info_vrp.status`, null)
  const vrpUsed = _get(formState, `${formKey}.certificate_info_vrp.used`, null)
  const vrpValidStatus = (vrpValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vrpStatus === "Active") &&
    (vrpUsed === 0) ? 'Valid' : 'Invalid'

  return (
    <div className="defaultMarginTopBottom">

      {/* <div className="col-md-12 col-sm-12"> */}

      <UICard>
        <table className={"fullWidthDiv"}>
          <thead>
            <tr>
              <th className={"text-md-left"}>Licence Period</th>
              <th></th>
              <th className={"text-md-right"}>Fee (LKR)</th>
              <th className={"text-md-right"}>Fine (LKR)</th>
              <th className={"text-md-right"}>Additions (LKR)</th>
              <th className={"text-md-right"}>Deductions (LKR)</th>
              <th className={"text-md-right"}>Service Charge (LKR)</th>
              <th className={"text-md-right"}>Total (LKR)</th>
            </tr>
          </thead>
          <tbody>
            {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
              <tr>
                <td className={"text-md-left"}>{_get(formState, `licence_charges_form.currentLicenceStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; - &nbsp;&nbsp; {_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : ""}</td>
                <td></td>
                <td className={"text-md-right"}>{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0)).toFixed(2))}</td>
                  <td className={"text-md-right"}>{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fine_total`, 0)).toFixed(2))}</td>
                  <td className={"text-md-right"}>{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_addition_total`, 0)).toFixed(2))}</td>
                  <td className={"text-md-right"}>({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0))).toFixed(2))})</td>
                  <td className={"text-md-right"}>({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.serviceCharge`, 0))).toFixed(2))})</td>

                  <td className={"text-md-right"}>{
                    numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0) +
                      _get(formState, `licence_charges_form.data.current_licence_fine_total`, 0) +
                      (_get(formState, `licence_charges_form.serviceCharge`, 0) * 1) +
                      _get(formState, `licence_charges_form.data.current_licence_addition_total`, 0) -
                      _get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0)).toFixed(2))
                  }</td>
              </tr>
              :
              <tr>No data found</tr>
            }

          </tbody>
        </table>
      </UICard>

      {/* </div> */}

      <div className="defaultMarginTopBottom">
        <UICard>
          <FormWrapper
            setGroupName={formKey}
            // apiUrl={`${licenceInvalidDocuments.url}/certificates-info/${vehicle_no}`}
            >
            <table className={"fullWidthDiv"}>
          {((_get(formState, `${formKey}.certificate_info_vet`, null) !== undefined && _get(formState, `${formKey}.certificate_info_vet.id`, null) && _get(formState, "vehicle.data.data.document_validations.vet", false) === true) ||
            (_get(formState, `${formKey}.certificate_info_insurance`, null) !== undefined &&_get(formState, `${formKey}.certificate_info_insurance.id`, null) && _get(formState, "vehicle.data.data.document_validations.insurance", false) === true) ||
            (_get(formState, `${formKey}.certificate_info_vft`, null) !== undefined &&_get(formState, `${formKey}.certificate_info_vft.id`, null) && _get(formState, "vehicle.data.data.document_validations.vft", false) === true) ||
            (_get(formState, `${formKey}.certificate_info_vrp`, null) !== undefined &&_get(formState, `${formKey}.certificate_info_vrp.id`, null) && _get(formState, "vehicle.data.data.document_validations.vrp", false) === true))
            ?
            <thead>
              <tr>
                <th>Document</th>
                <th>Status</th>
                <th>Company</th>
                <th>Document No</th>
                <th>Issued Date</th>
                <th>Expiry Date</th>
                <th>Validity Expiry Date</th>
              </tr>
            </thead>
            :
            <tr>No document data found</tr>  
            }
            {console.log('DDDDD--', _get(formState, `${formKey}.certificate_info_vet`, null), _get(formState, `${formKey}.certificate_info_vet.id`, null))}
            {console.log('DDDDD2--',_get(formState, "vehicle.data.data.document_validations.vet", false), _get(formState, "vehicle_search_form.data.document_validations.vet", false))}
           <tbody>
              {(_get(formState, `${formKey}.certificate_info_vet`, null) !== undefined &&_get(formState, `${formKey}.certificate_info_vet.id`, null)) && 
              (_get(formState, "vehicle.data.data.document_validations.vet", false) === true) &&
              <tr>
              <td><strong>VET</strong></td>
              <td>{vetValidStatus}</td>
              <td>{_get(formState, `${formKey}.vetCompanyData`, '-')}</td>
              <td>{_get(formState, `${formKey}.certificate_info_vet.vet_no`, null)}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ? 
              dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.issued_at`, null), "dd/mm/YY") : '-'}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ? 
              dateObjectToString(_get(formState, `${formKey}.certificate_info_vet.expiry_date`, null), "dd/mm/YY") : '-'}</td>
               <td>{dateObjectToString(vetValidity, "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(vetValidity, "dd/mm/YY") : '-'}</td>
            </tr>
              }
              {(_get(formState, `${formKey}.certificate_info_insurance`, null) !== undefined &&_get(formState, `${formKey}.certificate_info_insurance.id`, null)) &&
              (_get(formState, "vehicle.data.data.document_validations.insurance", false) === true) &&
              <tr>
              <td><strong>Insurance</strong></td>
              <td>{insValidStatus}</td>
              <td>{_get(formState, `${formKey}.insCompanyData`, '-')}</td>
              <td>{_get(formState, `${formKey}.certificate_info_insurance.reference`, null)}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
              dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.issued_at`, null), "dd/mm/YY") : '-'}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.certificate_info_insurance.valid_to`, null), "dd/mm/YY") : '-'}</td>
              <td>-</td>
            </tr>
              }
              {(_get(formState, `${formKey}.certificate_info_vft`, null) !== undefined &&_get(formState, `${formKey}.certificate_info_vft.id`, null)) &&
              (_get(formState, "vehicle.data.data.document_validations.vft", false) === true) &&
              <tr>
              <td><strong>VFT</strong></td>
              <td>{vftValidStatus}</td>
              <td>{_get(formState, `${formKey}.vftCompanyData`, '-')}</td>
              <td>{_get(formState, `${formKey}.certificate_info_vft.certificate_no`, null)}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.issued_date`, null), "dd/mm/YY") : '-'}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.certificate_info_vft.expiry_date`, null), "dd/mm/YY") : '-'}</td>
              <td>{dateObjectToString(vftValidity, "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(vftValidity, "dd/mm/YY") : '-'}</td>
            </tr>
              }
              {(_get(formState, `${formKey}.certificate_info_vrp`, null) !== undefined &&_get(formState, `${formKey}.certificate_info_vrp.id`, null)) &&
              (_get(formState, "vehicle.data.data.document_validations.vrp", false) === true) &&
              <tr>
              <td><strong>VRP</strong></td>
              <td>{vrpValidStatus}</td>
              <td>{_get(formState, `${formKey}.vrpCompanyData`, '-')}</td>
              <td>{_get(formState, `${formKey}.certificate_info_vrp.route_permit_no`, null)}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.issued_at`, null), "dd/mm/YY") : '-'}</td>
              <td>{dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
            dateObjectToString(_get(formState, `${formKey}.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") : '-'}</td>
              <td>-</td>
            </tr>
              }
              </tbody>
            </table>
          </FormWrapper>
        </UICard>


      </div>


      <UICard>
        <FormWrapper
          setGroupName={formKey}
          apiUrl={`${licenceInvalidDocuments.url}/get-documents/${request_id}`}
          onRebuildResponseFn={(response) => {
            // console.log('REaaaaa====', response);
            return {
              issue_mode: response.issue_mode,
              run: response.run,

              "_onLoad": true
            }
          }}>
          {/* <div className="col-md-6"> */}
          <div className="row">
            <div className="col-md-3"><strong>Current Licence Type</strong></div>
            <div className="col-md-3">{LicenceType[`${_get(formState, 'la-_licence_data.data.vehicleData.current_license_type.code', '-')}`]}</div>
          </div>
          <div className="row">
            <div className="col-md-3"><strong>Licence Validity Period (From - To)</strong></div>
            <div className="col-md-3">{_get(formState, `licence_charges_form.currentLicenceStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; - &nbsp;&nbsp; {_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : ""}</div>
          </div>
          <div className="row">
            <div className="col-md-3"><strong>Issue Mode</strong></div>
            <div className="col-md-3">{(_get(formState, `${formKey}.issue_mode`, '-') === 'TMP' ? 'Temporary Licence' : _get(formState, `${formKey}.issue_mode`, '-') === 'FUL' ? 'Full Licence' : '-')}</div>
          </div>
          {_get(formState, `${formKey}.issue_mode`, undefined) === 'TMP' && <div className="row">
            <div className="col-md-3"><strong>Run</strong></div>
            <div className="col-md-3">{_get(formState, `${formKey}.run`, undefined) ? (_get(formState, `${formKey}.run`, undefined) == 1 ? 'Allowed' : 'Not Allowed') : '-'}</div>
          </div>}
          {/* </div> */}
        </FormWrapper>
      </UICard>


    </div>
  );
};

const ChangeSerialNumberInfoDisplay = (props) => {
  const [formState,formAction] = useContext(FormContext);
  //const [apiResponse, setApiResponse] = useState([]);
  const request_id = props.row.request_id;
  const vehicle_no = props.row.vehicle_id;
  const formKey = "changeSerial";
 
  return (
    <UICard cardHeading="Change Serial Number">
      <FormWrapper
        setGroupName={formKey}
        apiUrl={`${changeSerialAPI.url}/${request_id}`}
        onRebuildResponseFn={(response) => {
           console.log('AAA--', response);
          formAction.changeInputFn('work_flow_record', "data", "", _get(response, "work_flow_record", {}));
          return {
            actual_serial_number: response.actual_serial_number,
            licence_number: response.licence_record.licence_number,
            licence_from: response.licence_record.valid_from,
            licence_to: response.licence_record.valid_to,
            serial_no: response.system_serial_number,
            status: response.work_flow_record.status,

            "_onLoad": true
          }
        }}
      >
        <div className="row">
          <div className="floatLeft col-md-12">
            <strong>Revenue Licence Number / Receipt Number: </strong>
            {_get(formState, `${formKey}.licence_number`, '-')}
          </div>
        </div>
        <div className="row">
          <div className="floatLeft col-md-12 defaultHalfMarginTop">
            <strong>Licence Preiod: </strong> {dateObjectToString(_get(formState, `${formKey}.licence_from`, '-'), "dd/mm/YY")} to {dateObjectToString(_get(formState, `${formKey}.licence_to`, '-'), "dd/mm/YY")}
          </div>
        </div>
        {_get(formState, `${formKey}.serial_no`, null) !== null && (_get(formState, `${formKey}.serial_no`, null) === 'APP') ?
          <>
            <div className="row">
              <div className="floatLeft col-md-12 defaultHalfMarginTop">
                <strong>Changed From Serial Number: </strong> {_get(formState, `${formKey}.serial_no`, '-')}
              </div>
            </div>
            <div className="row">
              <div className="floatLeft col-md-12 defaultHalfMarginTop">
                <strong>Changed To Serial Number: </strong> {_get(formState, `${formKey}.actual_serial_number`, '-')}
              </div>
            </div>
          </>
          :
          <>
            <div className="row">
              <div className="floatLeft col-md-12 defaultHalfMarginTop">
                <strong>Error Serial Number: </strong> {_get(formState, `${formKey}.serial_no`, '-')}
              </div>
            </div>
            <div className="row">
              <div className="floatLeft col-md-12 defaultHalfMarginTop">
                <strong>New Serial Number: </strong> {_get(formState, `${formKey}.actual_serial_number`, '-')}
              </div>
            </div>
          </>
        }
      </FormWrapper>
    </UICard>
  );
};
const LicenceReprintInfoDisplay = (props) => {
  return (
    <UICard>
      {/* <div className="col-md-12 col-sm-12"> */}
      <LicenceReprintInformation type={'AllTasks'} {...props} />
      {/* </div> */}
    </UICard>
  );
};

const CancelLicenceTrxInformation = (props) => {
  console.log('ppppppppppp====', props);
  const formKey = props.formKey;
  return (
    <DataTable
      apiUrl={`${getTempLicenceTransactionAPI.url}/${props.row.request_id}`}
      dataTableKey={getTempLicenceTransactionAPI.key}
      isSetAction={false}
      customActionHeader={'Select'}
      isSetSearchFrom={false}
      isSelectRow={false}
      isSetTableFooter={true}
      isSetTableHeader={true}
      tableHeaderList={[
        { displayName: "Year", key: "year", isSort: false },
        { displayName: "Tx. No", key: "transaction_id", isSort: false },
        { displayName: "Lic No/Rec No", key: "licence_no_receipt_no", isSort: false },
        {
          displayName: "Validity Period (From - To)",
          key: "validity_period",
          onPrintFn: (rawData) => {
            return (dateObjectToString(rawData.from, "dd/mm/YY") + ' - ' + (dateObjectToString(rawData.to, "dd/mm/YY")))
          },
          isSort: false,
        },
        { displayName: "Fee (LKR)", key: "fee", isSort: false },
        { displayName: "Fine (LKR)", key: "fine", isSort: false },
        {
          displayName: "Additions (LKR)",
          key: "addition",
          isSort: false,
        },
        {
          displayName: "Deductions (LKR)",
          key: "deduction",
          isSort: false,
        },
        {
          displayName: "Lic. Type",
          key: "licence_type_code",
          onPrintFn: ({ licence_type_code }) => licence_type_code ? LicenceType[`${licence_type_code}`] : "-",
          isSort: false,
        },
        {
          displayName: "User's Name",
          key: "created_by_name",
          isSort: false,
        },
        {
          displayName: "Location",
          key: "created_location_name",
          onPrintFn: ({ created_location_name }) => created_location_name ? created_location_name : "-",
          isSort: false,
        },
        {
          displayName: "Tx. Type",
          key: "tx_type",
          isSort: false,
        },
        {
          displayName: "Tx. Date & Time",
          key: "tx_date",
          onPrintFn: (rawData) => {
            return (dateObjectToString(rawData.tx_date, "dd/mm/YY HH:MM"))
          },
          isSort: false,
        },
      ]}
    />
  );
};

const DealerLicenceReprintInfoDisplay = (props) => {
  const { requestId } = props;
  return (
    // <UICard cardHeading="Dealer Licence Reprint Vehicle Information">
      <div className="col-md-12 col-sm-12">
        <DealerLicenceReprintInformation requestId={props.row.request_id} />
      </div>
    // </UICard>
  );
};

const DealerLicenceChangeSerialInfoDisplay = (props) => {
  const requestId = _get(props, "row.request_id", null);
  return (
    <UICard cardHeading="Dealer Change Serial Number Vehicle Information">
      <div className="col-md-12 col-sm-12">
        <DataTable
          tableName={"Vehicle Number Information"}
          apiUrl={`${changeDealerSerialAPI.url}/${requestId}?isApproval=true`}
          dataTableKey={changeDealerSerialAPI.key}
          isSetSearchFrom={false}
          isSelectRow={false}
          tableHeaderList={[
            {
              displayName: "Authorized Vehicle No.",
              key: `vehicle_no`,
              default: "-",
              isSort: false
            },
            { displayName: "Lic. No.", key: `licence_number`, isSort: false },

            { displayName: "Actual Serial No.", key: `actual_serial_number`, isSort: false }
          ]}
          isSetAction={false}
          isSetTableHeader={false}

        />
        {/* <DealerLicenceReprintInformation requestId={props.row.request_id} /> */}
      </div>
    </UICard>
  );
};

const LicenceWaiveOffInfoDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [apiResponse, setApiResponse] = useState([]);
  const request_id = props.row.request_id;
  const formKey = "approvalRequest";
  console.log('fgggg==', formState);

  useEffect(() => {
    getApprovalPendingData(props)
  }, [])

  const getTotalAmount = () => {
    let tot = 0.00;
    if (_get(formState, "approvalRequest.response.WaveOffAmountBases", []) && _get(formState, "approvalRequest.response.WaveOffAmountBases", []).length !== 0) {
      tot = _get(formState, "approvalRequest.response.WaveOffAmountBases", []).reduce((a, b) => {
        return Number(a.amount) + Number(b.amount)
      })
    }
    return tot
  }

  const getApprovalPendingData = (props) => {
    callApi(`${licenceWaveOffAPI.url}/get-licence/${request_id}`)
        .method("get")
        .headers(true)
        .isMultipart(false)
        .send((error, result) => {
            const { waveoff_type, WaveOffAmountBases, WaveOffDurationBases, work_flow_record } = result.data.data
            if (!error) {
                formAction.changeInputFn('work_flow_record', "data", "", work_flow_record);
                if (waveoff_type === 'NUL') return
                formAction.changeInputFn("Licence_waive_Off", "waive_off_type", "", waveoff_type);
                if (waveoff_type === 'DUR') {
                    const newObjDur = {}
                    WaveOffDurationBases.map((obj, index) => {
                        const dataObj = {
                            fromDate: obj.from_date,
                            toDate: obj.to_date,
                            wave_component: obj.wave_component,
                        }

                        newObjDur[index] = dataObj

                    })
                    if (newObjDur && Object.keys(newObjDur).length !== 0) {

                        formAction.changeInputFn(formKey, "wave_off_duration_base", "", newObjDur);
                    }
                } else if (waveoff_type === 'AMT') {

                    const newObj = {}
                    WaveOffAmountBases.map((obj, index) => {
                        const dataObj = {
                            wave_component: obj.wave_component,
                            amount: obj.amount
                        }

                        newObj[index] = dataObj

                    })
                    if (newObj && Object.keys(newObj).length !== 0) {

                        formAction.changeInputFn(formKey, "wave_off_amount_base", "", newObj);
                    }
                }

            }
        });
}

  return (
    <UICard>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="row">
            <div className="col-md-2"><strong>Current Licence Type</strong></div>
            <div className="col-md-2">TYPE</div>
          </div> */}
{((_get(formState, "work_flow_record.data", null) !== null)) ? <WaiveOffUpperPannel workFlowRecord={_get(formState, "work_flow_record.data", {})} {...props} formGroupName={formKey} /> : <div>No data(s) found</div>}
          {/* <UICard> */}
          {/* <WaiveOffUpperPannel {...props} /> */}
          {/* </UICard> */}

          <UICard>
            <FormWrapper
              setGroupName={formKey}
              // isReverseTx
              apiUrl={`${licenceWaveOffAPI.url}/get-licence/${request_id}`}
              onRebuildResponseFn={(response) => {
                console.log('RESPPP====>', response);
                // setformState(reapprovalRequest.response.sponse)
                return {
                  response: response,
                  "_onLoad": false
                }
              }}>
              {
                _get(formState, "approvalRequest.response.WaveOffDurationBases", []) && _get(formState, "approvalRequest.response.WaveOffDurationBases", []).length !== 0 &&
                <div className="row defaultPaddingTop">
                  <div className="col-md-2"><strong>Waive Off Type</strong></div>
                  <div className="col-md-2"><strong>From Date</strong></div>
                  <div className="col-md-2"><strong>To Date</strong></div>
                  <div className="col-md-2"><strong>Wave Off Component</strong></div>
                  {/* <div className="col-md-2"><strong>Amount (LKR)</strong></div> */}
                </div>
              }
              {
                _get(formState, "approvalRequest.response.WaveOffDurationBases", []) && _get(formState, "approvalRequest.response.WaveOffDurationBases", []).length !== 0 && _get(formState, "approvalRequest.response.WaveOffDurationBases", []).map((object, index) => {
                  return (
                    <div className="row">
                      <div className="col-md-2">{_get(formState, "approvalRequest.response.waveoff_type", '-') === 'AMT' ? 'Amount base' : (_get(formState, "approvalRequest.response.waveoff_type", []) === 'DUR' ? 'Duration base' : '-')}</div>
                      <div className="col-md-2">{dateObjectToString(_get(object, "from_date", "-"), "dd/mm/YY")}</div>
                      <div className="col-md-2">{dateObjectToString(_get(object, "to_date", "-"), "dd/mm/YY")}</div>
                      <div className="col-md-2">{_get(object, "wave_component", "-") && _get(object, "wave_component", "-") !== '-' ?
                        (_get(object, "wave_component", "-") === "FINE" ? "Fine only" : "Fee and Fine only") : '-'}</div>
                      {/* <div className="col-md-2">{_get(formState, `${formKey}.data`, 1500)}</div> */}
                    </div>
                  )
                })
              }

              {_get(formState, "approvalRequest.response.WaveOffAmountBases", []) && _get(formState, "approvalRequest.response.WaveOffAmountBases", []).length !== 0 &&
                <div className="row defaultPaddingTop">
                  <div className="col-md-2"><strong>Waive Off Type</strong></div>
                  <div className="col-md-2"><strong>Wave Off Component</strong></div>
                  {/* <div className="col-md-2"><strong>Applicable Amount (LKR)</strong></div> */}
                  <div className="col-md-2 text-right"><strong>Amount (LKR)</strong></div>
                </div>
              }
              {
                _get(formState, "approvalRequest.response.WaveOffAmountBases", []) && _get(formState, "approvalRequest.response.WaveOffAmountBases", []).length !== 0 && _get(formState, "approvalRequest.response.WaveOffAmountBases", []).map((object, index) => {
                  return (
                    <div className="row">
                      <div className="col-md-2">{_get(formState, "approvalRequest.response.waveoff_type", '-') === 'AMT' ? 'Amount base' : (_get(formState, "approvalRequest.response.waveoff_type", []) === 'DUR' ? 'Duration base' : '-')}</div>
                      <div className="col-md-2">{_get(object, "wave_component", "-") && _get(object, "wave_component", "-") !== '-' ?
                        (_get(object, "wave_component", "-") === "FINE" ? "Fine only" : "Fee and Fine only") : '-'}</div>
                      {/* <div className="col-md-2">{_get(formState, `${formKey}.data`, 2000)}</div> */}
                      <div className="col-md-2 text-right">{numberThousandSeperator(Number(_get(object, `amount`, 0)).toFixed(2))}</div>
                    </div>
                  )
                })
              }

            </FormWrapper>
          </UICard>
          {/* {_get(formState, "approvalRequest.response.WaveOffAmountBases", []) &&  _get(formState, "approvalRequest.response.WaveOffAmountBases", []).length !== 0 &&
          <div className="row">
            <div className="col-md-2"><strong>Total Waive off Amount (LKR)</strong></div>
            {/* <div className="col-md-2">{getTotalAmount()}</div> */}
          {/* </div> */}
          {/* } */}
        </div>
      </div>
    </UICard>
  );
};

const RoutePermitFineInfoDisplay = () => {
  return (
    <UICard>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3"><strong>Current Licence Type</strong></div>
            <div className="col-md-3">Normal Licence</div>
          </div>
          <div className="row">
            <div className="col-md-3"><strong>Current Vehicle Class</strong></div>
            <div className="col-md-3">Private Coach</div>
          </div>
          <div className="row">
            <div className="col-md-3"><strong>Licence Period</strong></div>
            <div className="col-md-3">2019/10/15</div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3">2020/10/15</div>
          </div>
        </div>
        <div className="col-md-12 defaultMarginTop">
          <div className="row">
            <DataTable
              tableName={"Route Permit History"}
              isSetAction={false}
              isSetTableFooter={false}
              tableHeaderList={[
                {
                  displayName: "Route Permit Number",
                  key: "routePermit",
                  isSort: false
                },
                {
                  displayName: "VRP Organisation Name",
                  key: "vrpOrganization",
                  isSort: false
                },
                {
                  displayName: "Route Permit Issue Date",
                  key: "routePermitIssueDate",
                  isSort: false
                },
                {
                  displayName: "Route Permit Expiry Date",
                  key: "routePermitExpDate",
                  isSort: false
                },
              ]}
              tableBodyList={[
                {
                  routePermit: "02",
                  vrpOrganization: "NTC",
                  routePermitIssueDate: "2019/10/15",
                  routePermitExpDate: "2020/10/15"
                },
                {
                  routePermit: "02",
                  vrpOrganization: "NTC",
                  routePermitIssueDate: "2020/10/15",
                  routePermitExpDate: "2021/10/15"
                }
              ]}
            />
          </div>
        </div>
        <div className="col-md-12 defaultMarginTop">
          <div className="row">
            <div className="col-md-2"><strong>Fine (LKR)</strong></div>
            <div className="col-md-2">1000</div>
          </div>
        </div>
      </div>
    </UICard>
  );
};

const CancelLicenceTransactionDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const request_id = props.row.request_id;
  const vehicle_no = props.row.vehicle_no;
  const formKey = "approvalRequest";
  console.log('PROOOOdd-->', props);
  useEffect(() => {
    getReqApprovalLicenceTypeData(props)
  }, [])


  const getReqApprovalLicenceTypeData = (props) => {
    callApi(`${dealerCancelLicenceReceiptTransactionAPI.url}/${props.row.id}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, result.data.data);

        } else {
          console.log('ertt--', error);
        }
      });
  }

  return (
    // <UICard cardHeading="">
      <div className={"col-md-12"} style={{padding: 0}}>
        <DataTable
          tableName="Selected Transactions To Cancel"
          dataTableKey={getDealerVehicleDetailsAPI.key}
          isSetSearchFrom={false}
          customActionHeader={'Select'}
          isSelectRow={false}
          tableHeaderList={[
            {
              displayName: "Tx Num",
              key: "txn_number",
              default: "-",
              isSort: false,
            },
            {
              displayName: "Trans. Date & Time", key: "created_date_time",
              onPrintFn: (rawData) => {
                console.log('RAW__', rawData);
                return (dateObjectToString(rawData.created_date_time, "dd/mm/YY HH:MM"))
              }, isSort: true
            },
            { displayName: "Total Amount(LKR)", key: "total_amount", isSort: false, isRightAlign: true, onPrintFn: rawData => thousandSeparator(rawData.total_amount, true) },
            { displayName: "", key: "", default: "" },
            { displayName: "Province", key: "pdmt", isSort: false },
            { displayName: "Authority", key: "authority", isSort: false },
          ]}
          tableBodyList={_get(formState, getDealerVehicleDetailsAPI.key, [])}
          isSetAction={false}
          isSetTableHeader={true}
          isSetTableFooter={true}
        />
      </div>
    // </UICard>
  );
};

const AddLicenceTransactionDisplay = (props) => {
  const [formState] = useContext(FormContext);
  const request_id = props.row.request_id;
  const vehicle_no = props.row.vehicle_no;
  const formKey = "approvalRequest";
  console.log('PROOOO-->', props);
  let total = (
    _getFloat(formState, `${formKey}.fee`, 0) +
    _getFloat(formState, `${formKey}.addition`, 0) +
    _getFloat(formState, `${formKey}.deduction`, 0)
  ).toFixed(2);

  return (

    <FormWrapper
      setGroupName={formKey}
      apiUrl={`${AddLicenceTransactionAPI.url}/get-transaction/${request_id}`}
      onRebuildResponseFn={(response) => {
        console.log('RESSS--', response);
        return {
          entry_mode: response.entry_mode,
          issued_date: response.issued_date,
          fee: response.fee,
          addition: response.addition,
          deduction: response.deduction,
          print_required: response.print_required,

          "_onLoad": true
        }
      }}
    >
      <UICard>

        <div className="row addLicenceLabel">
          <div className="col-md-2 col-sm-6 mt"><strong>Entry Mode</strong></div>
          <div className="col-md-2 col-sm-6 mt">{_get(formState, `${formKey}.entry_mode`, null) === "MIS" ? "Missing Licence" :
            _get(formState, `${formKey}.entry_mode`, null) === "COR" ? "Licence Correction" : "-"}
          </div>
        </div>

        <div className="row addLicenceLabel">
          <div className="col-md-2 col-sm-6 mt"><strong>Issued Date</strong></div>
          <div className="col-md-2 col-sm-6 mt">{dateObjectToString(_get(formState, `${formKey}.issued_date`, "-"), "dd/mm/YY")}</div>
        </div>

      </UICard>

      <div className="mt-3 mb-3">
        <UICard>
          <DataTable
            tableName={"Vehicle Information"}
            isSetTableHeader={true}
            apiUrl={`${AutharizedVehicleInformationAPI.url}/get-vehicle-info?sort_by=created_at%7Cdesc&request_id=${request_id}`}
            dataTableKey={`${formKey}_upload_dataTableKey`}
            isSetSearchFrom={false}
            tableHeaderList={[
              { displayName: "Authorized Vehicle No", key: "vehicle_no", default: "-", isSort: false },
              { displayName: "Serial Number", key: "receipt_serial_number", isSort: false },
              { displayName: "Lic. No", key: "licence_no_receipt_no", isSort: false },
              {
                displayName: "Licence From Date", key: "from", isSort: false, onPrintFn: (rawData) => {
                  return dateObjectToString(rawData.from, "dd/mm/YY HH:MM");
                }
              },
              {
                displayName: "Licence to Date", key: "to", isSort: false, onPrintFn: (rawData) => {
                  return dateObjectToString(rawData.to, "dd/mm/YY HH:MM");
                }
              },
            ]}
            isSetAction={false}
          />
        </UICard>
      </div>


      <UICard>

        <div className="row addLicenceLabel">
          <div className="col-md-2 col-sm-6 mt"><strong>Fee (LKR)</strong></div>
          <div className="col-md-1 col-sm-6 mt text-right">{_get(formState, `${formKey}.fee`, null) !== null ? thousandSeparator(_get(formState, `${formKey}.fee`, "-"), true) : '-'}</div>
        </div>

        <div className="row addLicenceLabel">
          <div className="col-md-2 col-sm-6 mt"><strong>Additions (LKR)</strong></div>
          <div className="col-md-1 col-sm-6 mt text-right">{_get(formState, `${formKey}.addition`, null) !== null ? thousandSeparator(_get(formState, `${formKey}.addition`, "-"), true) : '-'}</div>
        </div>

        <div className="row addLicenceLabel">
          <div className="col-md-2 col-sm-6 mt"><strong>Deductions (LKR)</strong></div>
          <div className="col-md-1 col-sm-6 mt text-right">{_get(formState, `${formKey}.deduction`, null) !== null ? thousandSeparator(_get(formState, `${formKey}.deduction`, "-"), true) : '-'}</div>
        </div>

        <div className="row addLicenceLabel">
          <div className="col-md-2 col-sm-6 mt"><strong>Total (LKR)</strong></div>
          <div className="col-md-1 col-sm-6 mt text-right">{thousandSeparator(total, true)}</div>
        </div>

        <div className="row addLicenceLabel">
          <div className="col-md-1 col-sm-6 mt"><strong>Print Required</strong></div>
          <div className="col-md-2 col-sm-6 mt text-right"> {_get(formState, `${formKey}.print_required`, null) == "1" ? "Required" :
            _get(formState, `${formKey}.print_required`, null) == "0" ? "Not Required" : "-"}</div>
        </div>

      </UICard>


    </FormWrapper>

  );
};

const OtherAdditionsDeductions = (props) => {
  const [formState] = useContext(FormContext);

  const requestId = _get(props, "row.request_id", null);
  console.log('FORMMMM===', formState);
  console.log('AAAAAA====', props);
  return (
    // <UICard cardHeading="">
    //   <FormWrapper
    //         setGroupName={formKey}            
    //         apiUrl={`${AddLicenceTransactionAPI.url}/get-transaction/${request_id}`}
    //         onRebuildResponseFn={(response) => {
    //           return {
    //             entry_mode: response.entry_mode,                
    //             issued_date: response.issued_date,                             
    //             fee: response.fee,                
    //             addition: response.addition,
    //             deduction: response.deduction,
    //             print_required: response.print_required,

    //             "_onLoad": true
    //           }
    //         }}
    //     >
    // <UICard>
    //   <div className="row">
    //       <div className="defaultMarginLeft"><strong>Current Licence Type:</strong></div>
    //       <div className="col-md-2">{'currentLicenceType'}</div>
    //       <div><strong>Current Vehicle Class:</strong></div>
    //       <div className="col-md-2">{'currentVehicleClass'}</div>
    //       <div><strong>Licence Period:</strong></div>
    //       <div className="col-md-2">oooooooo</div>
    //   </div>
    //   </UICard>

    <div >
      {/* <UICard cardHeading="Added Additions / Deductions"> */}
      <DataTable
        tableName="Added Additions / Deductions"
        apiUrl={`${OtherAdditionDeductionTempAPI.url}/${requestId}`}
        isSetTableHeader={true}
        dataTableKey={OtherAdditionDeductionTempAPI.key}
        isSetAction={false}
        isSetSearchFrom={false}
        startIcon="mdi mdi-magnify-plus-outline"
        tableHeaderList={[
          {
            displayName: "Type", key: "charge_effect_code", isSort: false,
            onPrintFn: ({ charge_effect_code }) => charge_effect_code ? getCodeName(ChargeEffectCode, charge_effect_code) : "-",
          },
          { displayName: "Addition/Deduction", key: "name", isSort: false, },

          // {
          //     displayName: "Applicability Type", key: "applicability_type_code", isSort: false,
          //     onPrintFn: ({ applicability_type_code }) => applicability_type_code ? getCodeName(ApplicabilityTypeCode, applicability_type_code) : "-",
          // },

          {
            displayName: "Applicable Date", key: "applicable_date", isSort: false, onPrintFn: ({ applicable_date }) =>
              applicable_date ? dateObjectToString(applicable_date, "dd/mm/YY") : "-",
          },
          { displayName: "Amount", key: "amount", isRightAlign: true, isSort: false, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.amount).toFixed(2)) },
          { displayName: "", key: "", isSort: false, default: "" },
        ]}
      />
      {/* </UICard> */}
    </div>

    // </FormWrapper>
    // </UICard>

  );
}

const DealersLicenceReissuanceDisplay = (props) => {
  const requestId = _get(props, "row.request_id", null);
  return (
    <UICard>
      <DataTable
        apiUrl={`${DealersLicenceReissuanceRequestsAPI.url}/${requestId}`}
        dataTableKey={DealersLicenceReissuanceRequestsAPI.key}
        tableHeaderList={[
          {
            displayName: "Authorised Vehicle no",
            key: "vehicle_no",
            isSort: true,
          },
          {
            displayName: "Insurance Company",
            key: "insurance_company",
            isSort: false,
          },
          {
            displayName: "Insurance Expiry Date",
            key: "insurance_expire_date",
            isSort: false,
            onPrintFn: (rawData) => {
              return dateObjectToString(
                rawData.insurance_expire_date,
                "dd/mm/YY"
              );
            },
          },
        ]}
        isSetTableFooter={true}
        isSetTableHeader={false}
        isSetAction={false}
      />
    </UICard>
  );
};

export {
  TransferVehicleInfoDisplay,
  AddVehicleInfoDisplay,
  LicenceTypeChangeInfoDisplay,
  VehicleStatusChangeInfoDisplay,
  UpdateVehicleInfoDisplay,
  ReverseLastTransactionInfoDisplay,
  RequestLicenceInfoDisplay,
  LicenceReprintInfoDisplay,
  SetEffectiveDateInfoDisplay,
  ChangeSerialNumberInfoDisplay,
  LicenceWaiveOffInfoDisplay,
  RoutePermitFineInfoDisplay,
  LicenseWithArrearsInfoDisplay,
  AddLicenceRecieptInfoDisplay,
  AddLicenceTransactionDisplay,
  DealersLicenceReissuanceDisplay,
  OtherAdditionsDeductions,
  DealerLicenceReprintInfoDisplay,
  CancelLicenceTrxInformation,
  AddDealerInfoDisplay,
  UpdateDealerInfoDisplay,
  DealerLicenceChangeSerialInfoDisplay,
  CancelLicenceTransactionDisplay
};
