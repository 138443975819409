import React from "react";

import { templateTypes } from "../../../../../../config/template.config";
import {
    licenceTypesListAPI,
  } from "../../../../../../config/apiUrl.config";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
} from "../../../../../ui-components/ui-elements/form";

const SearchElementOne = (props) => {
    const formKey = `${props.dataTableKey}_search`;

    return (
        <FormWrapper
            setGroupName={formKey}
        >
            <div className="row">
                <div className={"col-md-3 col-sm-12"}>
                    <InputBoxWithState
                        labelText={"Vehicle No. / Authorized Vehicle No."}
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"vehical_id"}
                        inputPlaceholder=""
                        maxLength={25}
                    />
                </div>

                <div className={"col-md-3 col-sm-12"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        emptySelectOptionTxt={'Licence Type'}
                        formGroupName={formKey}
                        inputName={'licence_type_code'}
                        apiUrl={`${licenceTypesListAPI.url}?sort_by=name|asc`}
                        apiStateKey={licenceTypesListAPI.key}
                        keyName={"code"}
                        valueName={"name"}
                        labelText="Licence Type"
                    />
                </div>

                <div className={"col-md-3 col-sm-12"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        emptySelectOptionTxt={'Transaction Type'}
                        dataList={[
                            {
                                id: "1",
                                value: "Arrears",
                            },
                            {
                                id: "2",
                                value: "Licence",
                            },                          
                        ]}
                        inputName={"transaction_type_code"}
                        labelText= "Transaction Type"
                    />
                </div>

                {props.showTransactionStatus ?(<div className={"col-md-3 col-sm-12"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        emptySelectOptionTxt={'Transaction Status'}
                        dataList={[
                            {
                                id: "0",
                                value: "Reversed",
                            },
                            {
                                id: "1",
                                value: "Success",
                            },
                        ]}
                        inputName={"transaction_status"}
                        labelText= "Transaction Status"
                    />
                </div>):null}
            </div>
        </FormWrapper>
    );
};

const SearchElementTwo = (props) => {
    const formKey = `${props.dataTableKey}_search`;

    return (
        <FormWrapper
            setGroupName={formKey}
        >
            <div className="row">
                <div className={"col-md-4 col-sm-12"}>
                    <InputBoxWithState
                        labelText={"Vehicle ID / Authorized Vehicle ID"}
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"vehical_id"}
                        inputPlaceholder=""
                        maxLength={25}
                    />
                </div>

                <div className={"col-md-4 col-sm-12"}>
                <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        emptySelectOptionTxt={'License Type'}
                        formGroupName={formKey}
                        apiUrl={`${licenceTypesListAPI.url}?sort_by=name|asc`}
                        apiStateKey={licenceTypesListAPI.key}
                        keyName={"code"}
                        valueName={"name"}
                        inputName={"licence_type_code"}
                        labelText="Licence Type"
                    />
                </div>

                <div className={"col-md-4 col-sm-12"}>
                <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        emptySelectOptionTxt={'Transaction Type'}
                        dataList={[
                            {
                                id: "1",
                                value: "Arrears",
                            },
                            {
                                id: "2",
                                value: "Licence",
                            },                          
                        ]}
                        inputName={"transaction_type_code"}
                        labelText= "Transaction Type"
                    />
                </div>
            </div>
        </FormWrapper>
    );
};

export {
    SearchElementOne,
    SearchElementTwo
};
