/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-12 15:23:57
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-12 16:12:36
 */
import React from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { AddLicenceUI } from "./includes/AddLicenceUI";

const AddLicence = () => {
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Add Licence" },
      ]}
      pageTitle="Add Licence / Receipt Transaction"
    >
      <AddLicenceUI />
    </DashboardTemplate>
  );
};

export { AddLicenceUI };
export default AddLicence;
