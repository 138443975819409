/*
 * @Author: Anjula Karunarathne 
 * @Date: 2021-01-11 10:39:12 
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-01-12 10:40:52
 */
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import { InterProvinceRevenueSettlement } from './pages/InterProvinceRevenueSettlement';
import { RevenueSettlementHistory } from "./pages/revenue-history/RevenueSettlementHistory"
import React, { Fragment } from 'react';
import authorized from "../../hoc-middlewares/Authorized";

const RevenueSettlementRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/revenue-settlement-inter-province"
        routeKey={"revenue-settlement-inter-province"}
        component={authorized(InterProvinceRevenueSettlement)}
        routePermissions={permissions.REVENUE_SETTLEMENT.permissions} />
      <Navigate
        exact={true}
        path="/revenue-settlement-note-history"
        routeKey={"revenue-settlement-note-history"}
        component={authorized(RevenueSettlementHistory)}
        routePermissions={permissions.REVENUE_SETTLEMENT.permissions} />
    </Fragment>
  );
};

export default RevenueSettlementRoutes;
