import { reportGenerationMaxRangeMonths } from "../../config/report.config";

const setMinDateFn = (date) => {
    if (date) {
        let minDate = new Date(date);
        minDate = new Date(minDate.setDate(minDate.getDate()));
        return minDate;
    } else {
        return new Date(1970, 1, 1);
    }
};

const setMaxDateFn = (date) => {
    if (date) {
        let maxDate = new Date(date);
        maxDate = new Date(maxDate.setDate(maxDate.getDate()));
        return maxDate;
    } else {
        return new Date();
    }
};

const setMaxDateRangeFn = (date) => {
    if (date) {
        let maxDate = new Date(date);
        let max;
        maxDate = new Date(maxDate.setMonth(date.getMonth() + reportGenerationMaxRangeMonths));
        if (maxDate > new Date()) {
            return new Date();
        }
        else {
            return maxDate;
        };

    } else {
        return new Date();
    }
};

const setminDateRangeFn = (dateto,datefrom) => {
    if (dateto) {
        let minDate = new Date(dateto);
        if (minDate == new Date()) {
            return new Date(1970, 1, 1);
        }
        else {
            minDate = new Date(minDate.setMonth(dateto.getMonth() - reportGenerationMaxRangeMonths));
            if(  minDate > datefrom)
            {
                minDate=datefrom
            }
            return minDate;
        }

    } else {
        return new Date(1970, 1, 1);
    }
};

export {setminDateRangeFn ,setMaxDateRangeFn,setMaxDateFn,setMinDateFn}