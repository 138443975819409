/*
 * @Author: Binara Medawatta
 * @Date: 2020-12-30 15:10:05
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2020-12-31 11:15:20
 */

import React, { Fragment } from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { Form, SearchElement } from "./includes/Form";
import { View } from "./includes/ViewUserAccount";
import {CreateStepsWrapper,EditFormWrapper} from "./includes/UserManageForm";
import {getInternalUserListAPI, internalUserAPI} from "../../../../config/apiUrl.config"

const emptyFn = (...para) => undefined;
const CustomButtons = ({ onClosePopupFn = emptyFn, formGroupName = "" }) => {
  const close = () => {
    onClosePopupFn();
  };

  return (
    <Fragment>
      <SubmitButton btnText="Save" elementStyle="btnWrapper" />
    </Fragment>
  );
};
const CustomButtonEdit = ({ onClosePopupFn = emptyFn, formGroupName = "" }) => {
  const close = () => {
    onClosePopupFn();
  };

  return (
    <Fragment>
      <SubmitButton btnText="Update" elementStyle="btnWrapper" />
    </Fragment>
  );
};

const UserAccount = () => {
  const formKey = "testInputs";
  return (
    <DashboardTemplate
      pageTitle="User Account"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "/user", displayName: "User Account" },
      ]}
    >
      <DataTable
        tableName={"Manage User Account"}
        apiUrl={getInternalUserListAPI.url}
        dataTableKey={getInternalUserListAPI.key}
        isSetAction={true}
        defaultSearchFormObject={{
          codeName: "",
          status: "",
        }}
        isSetSearchFrom={true}
        searchFromComponent={
          <SearchElement dataTableKey={"manageUserAccountSearch"} />
        }
        tableHeaderList={[
          {
            displayName: "Full Name",
            key: "full_name",
            default: "-",
            isSort: false,
          },
          {
            displayName: "Display Name",
            key: "display_name",
            isSort: false,
            default: "-",
          },
          {
            displayName: "Username",
            key: "users.user_name",
            isSort: false,
            default: "-",
          },
          {
            displayName: "User Role",
            key: "userRole",
            isSort: false,
            default: "-",
          },
          {
            displayName: "Designation",
            key: "users.designation",
            isSort: false,
            default: "-",
          },
          {
            displayName: "Status",
            key: "users.status",
            isSort: false,
            default: "-",
            onPrintFn: ({ users }) => (users.status === 1 ? "Active" : "Inactive")
          },
        ]}        
     
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            modelContentElement: CreateStepsWrapper,
            dialogPopupProps: {
              isFullScreen: true,
              dialogTitle: "Add User Account",
              isCustomButton: false,
              customButtonComponent: CustomButtons,
            },
            parentToModelProps: {
              formKey:  `${formKey}_create`,
              type:"CRETAE"
          },
          },
          {
            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: "View" },
            modelContentElement: View,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "View User Account",
            },
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { tooltip: "Edit" },
            modelContentElement: EditFormWrapper,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "Edit User Account",

              closeButton: {
                btnText: "Cancel",
              },
              isCustomButton: false,
              customButtonComponent: CustomButtonEdit,
            },
            parentToModelProps: {
              formKey:  `${formKey}_edit`,
              type:"EDIT"
          },
          },
        ]}
      />
    </DashboardTemplate>
  );
};

export default UserAccount;
