/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:19:46
 * @Last Modified by: Nipuna Chandimal
 * @Last Modified time: 2021-01-19 16:55:32
 */

import React, { Fragment } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

//import routers
import DashboardRoutes from "../components/modules/dashboard/Dashboard.routes";
import ErrorBoundary from "../components/ui-components/ui-elements/common/ErrorBoundary";
import DisplayError from "../components/modules/error-boundary/pages/DisplayError";
import ErrorBoundaryRoutes from "../components/modules/error-boundary/ErrorBoundary.routes";
import AuthRoutes from "../components/modules/auth/Auth.routes";
import TasksRoutes from "../components/modules/tasks/Tasks.routes";
import CashierRoutes from "../components/modules/cashier/Cashier.routes";
import NotificationRoutes from "../components/modules/notification/Notification.routes";
import PaymentRoutes from "../components/modules/payment/Payment.routes";
import RequestForApprovalRoutes from "../components/modules/request-for-approval/RequestForApproval.routes";
import ReportRoutes from "../components/modules/reports/Reports.routes";
import CounterSessionRoutes from "../components/modules/counter-session/CounterSession.routes";
import RevenueSettlementRoutes from "../components/modules/revenue-settlement/RevenueSettlement.routes";
import ManageTransactionsReconciliationRoutes from "../components/modules/manage-transactions-reconciliation/ManageTransactionsReconciliation.routes"; 
import CounterSessionHistoryRoutes from "../components/modules/counter-session-history/CounterSessionHistory.routes";
import UserAccount from "../components/modules/user-account/UserAccount.routes";
import VehicleRoutes from "../components/modules/vehicle/Vehicle.routes"; 
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Fragment>
          <ErrorBoundary displayComponent={DisplayError}>
            <AuthRoutes />
            <DashboardRoutes />
            <NotificationRoutes />
            <CashierRoutes />
            <ReportRoutes />
            <TasksRoutes />
            <PaymentRoutes />
            <CounterSessionRoutes />
            <ErrorBoundaryRoutes />
            <RequestForApprovalRoutes />
            <RevenueSettlementRoutes />
            <ManageTransactionsReconciliationRoutes />
            <CounterSessionHistoryRoutes />
            <UserAccount />
            <VehicleRoutes/>
          </ErrorBoundary>
        </Fragment>
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;