/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-21 10:00:34 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 13:21:08
 */
import { io } from "socket.io-client";

import {
    setToggleSideMenuKey,setPageLoaderKey,setRouteKey,
    setFlashMessageKey,removeFlashMessageKey,setSideMenuStatusKey,
    setFixedMenuKey,setToggleSideMenuStatusValueKey,
    setFullScreenKey,setSocketConnection, setJoinRoom,
    setNotificationCount, incrementNotificationCount,
    decrementNotificationCount, setCategoryCode
} from "../../../config/actionKeys.config";

import { dateObjectToString } from "../../../helpers/common-helpers/dateTime.helpers";
import { callApi } from "../../../helpers/common-helpers/callApi.helpers";
import { fromObjectToQueryString } from "../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../helpers/common-helpers/lodash.wrappers";
import { internalSockertBaseURL } from "../../../config/core.config";
import { notificationsAPI } from "../../../config/apiUrl.config";


const setFlashMessage=(dispatch,messageObject)=>{

    const key=dateObjectToString(new Date(),"YYmmddHHMMII");

    dispatch({
        type:setFlashMessageKey,
        playload:{
            ...messageObject,
            key:key
        }
    });
    
    setTimeout(()=>{
        removeFlashMessage(dispatch,key);
    }, 5000);
}

const removeFlashMessage=(dispatch,key)=>{
    dispatch({
        type:removeFlashMessageKey,
        key:key
    });
}

const toggleSideMenu=(dispatch)=>{
    dispatch({
        type:setToggleSideMenuKey
    });
}

const toggleFullScreen=(dispatch)=>{
    dispatch({
        type:setFullScreenKey
    });
}

const setToggleSideMenuFn=(dispatch,status)=>{
    dispatch({
        type:setToggleSideMenuStatusValueKey,
        playload:status
    });
}

const setFixedSideMenuFn=(dispatch)=>{
    dispatch({
        type:setFixedMenuKey
    });
}

const setSideToggleStatus=(dispatch,status)=>{
    dispatch({
        type:setSideMenuStatusKey,
        playload:status
    });
}

const setPageLoader=(dispatch,status)=>{
    dispatch({
        type:setPageLoaderKey,
        playload:status
    });
}

const setCurrentRouteFn=(dispatch,key)=>{
    dispatch({
        type:setRouteKey,
        playload:key
    });
}

const initializeSocketConnection = (dispatch, state) => {
    if (!state.socketConnection) {
        const conn = io(`${internalSockertBaseURL}push-notification-gateway`);//THIS USE FOR STAGING - SURESH GAMAGE 2022/08/24
        // const conn = io(`http://localhost:8090/push-notification-gateway`);


        dispatch({
            type: setSocketConnection,
            playload: conn
        });
    }
};

const closeSocketConnection = (dispatch, state) => {
    if (!state.socketConnection) {
        state.socketConnection.disconnect();

        dispatch({
            type: setSocketConnection,
            playload: null
        });
    }
};

const setJoinRoomStatus = (dispatch, status) => {
    dispatch({
        type: setJoinRoom,
        playload: status
    });
};



const getNotificationCount = (dispatch, filterObj) => {
    const filterQueryString = fromObjectToQueryString(filterObj);

    callApi(`${notificationsAPI.url}?${filterQueryString}`)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
            if (!error) {
                dispatch({
                    type: setNotificationCount,
                    playload: _get(result, "data.meta.total_items", 0)
                });
            }
        });
};

const incrementNotificationCountFn = (dispatch) => {
    dispatch({
        type: incrementNotificationCount
    });
};

const decrementNotificationCountFn = (dispatch) => {
    dispatch({
        type: decrementNotificationCount
    });
};

const setCategoryCodeToNotifyWorkflowReq=(dispatch, categoryCode)=>{
    dispatch({
        type: setCategoryCode,
        playload: categoryCode
    });
}

const uiAction=(dispatch, state) => {
    return {
        toggleSideMenu:()=>toggleSideMenu(dispatch),
        setToggleSideMenuFn:(status=false)=>setToggleSideMenuFn(dispatch,status),
        setFixedSideMenuFn:()=>setFixedSideMenuFn(dispatch),
        setSideToggleStatus:(status)=>setSideToggleStatus(dispatch,status),
        setPageLoader:(status)=>setPageLoader(dispatch,status),
        setCurrentRouteFn:(key)=>setCurrentRouteFn(dispatch,key),
        setFlashMessage:(messageObject)=>setFlashMessage(dispatch,messageObject),
        removeFlashMessage:(key)=>removeFlashMessage(dispatch,key),
        toggleFullScreen:()=>toggleFullScreen(dispatch),
        initializeSocketConnection:()=>initializeSocketConnection(dispatch, state),
        closeSocketConnection:()=>closeSocketConnection(dispatch, state),
        setJoinRoomStatus:(status)=>setJoinRoomStatus(dispatch, status),
        getNotificationCount:(filterObj)=>getNotificationCount(dispatch, filterObj),
        incrementNotificationCountFn:()=>incrementNotificationCountFn(dispatch),
        decrementNotificationCountFn:()=>decrementNotificationCountFn(dispatch),
        setCategoryCodeToNotifyWorkflowReq: (categoryCode)=>setCategoryCodeToNotifyWorkflowReq(dispatch, categoryCode)
    };
};

export {
    uiAction
};