/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-07 11:41:29 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-08-15 09:54:51
 */
import React,{useContext,useState} from "react";
import {UIContext} from "../../context-providers/UIContext.provider";
import { FullMenu } from "../common-includes/menu-includes/FullMenu";

 const TemplateTwoSideMenu=()=>{
    const [uiStatus,dispatchUIFn]=useContext(UIContext);
    const [getState,setState]=useState({
        click:false
    });

   
    const changeToggle=(status=true)=>{        
        if(uiStatus.fixedMenu===false){ 
            setState({
                click:true
            });           
            dispatchUIFn.setToggleSideMenuFn(status);            
        }
    }

    const onClickToggle=()=>{        
        setState({
            click:true
        });           
        dispatchUIFn.setFixedSideMenuFn()
    }

    
     return (
         
        <div className={`templateTwoSideMenu ${(uiStatus.fixedMenu===false && uiStatus.toggleSideMenu===false)?"templateTwoSideMenuHide":"templateTwoSideMenuShow"}`} onMouseLeave={()=>changeToggle(false)} onMouseEnter={()=>changeToggle(true)}>
            <div className={`subMenuHeader ${uiStatus.fixedMenu===true?"sideMenuLargePadding":"sideMenuPadding"}`}>
               
                <img
                className="logo"
                src={'images/eRLfinal.png'}
                alt="logo"
                />                  
                <i className={`mdi ${((getState.click===false)?"mdi-menu":((uiStatus.fixedMenu===true)?"mdi-menu menuIcon":"mdi-close closeIcon"))} iconToggal`} onClick={()=>onClickToggle()}></i>
            </div>
            <div className={`subMenuWrapper ${uiStatus.fixedMenu===true?"sideMenuLargePadding":"sideMenuPadding"}`}>
                <FullMenu/>
                <span style={{float:"left",width:"100%",height:"100px"}}></span>
            </div>
        </div>
     )
 }

 export {
    TemplateTwoSideMenu
 }
 