/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-01-08 16:22:27
 */

import React from "react";
import { InputBoxWithState } from "../../../../ui-components/ui-elements/form/index";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

const VehicleInfoEdit = () => {
  const formKey = "vehicleInfoEdit"; 
  return (
    <div className="row">
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Chassis"}
          formGroupName={formKey}
          inputName={"chassis"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Tare"}
          formGroupName={formKey}
          inputName={"tare"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Vehicle Status"}
          formGroupName={formKey}
          inputName={"vehicleStatus"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Engine"}
          formGroupName={formKey}
          inputName={"engine"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Gross"}
          formGroupName={formKey}
          inputName={"gross"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Fuel Type"}
          formGroupName={formKey}
          inputName={"fuelType"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"1st Reg"}
          formGroupName={formKey}
          inputName={"reg1"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Seats (Exp Driver)"}
          formGroupName={formKey}
          inputName={"seats"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Class"}
          formGroupName={formKey}
          inputName={"class"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Province"}
          formGroupName={formKey}
          inputName={"province"}
        />
      </div>
    </div>
  );
};

export { VehicleInfoEdit };
