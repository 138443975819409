import React, { Fragment, useContext, useState } from "react";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  CheckBoxWithState, SubmitButton
} from "../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { StepsWrapper, CustomStep, stepWrapperOrientations } from "../../../../ui-components/ui-elements/stepper/Stepper";
import { AdditionalRolesAndLocations, CustomButtons } from "./AdditionalRolesAndLocations";
import {
  actionBtnTypes, apiResponseStoringType,
  DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { TabsWrapper, SubTab } from "../../../../ui-components/ui-elements/tab/Tabs";
import {
  AuthorityListApi,
  provinceListApi,
  internalUserAPI,
  userRolesListAPI,
  userTitlesListAPI, userDefaultDelegationAPI, userDelegationCreateAPI, userPasswordResetAPI, provincesOfficesListAPI
} from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {FormContext} from "../../../core/context-providers/FormContext.provider";
import {dateObjectToString} from "../../../../../helpers/common-helpers/dateTime.helpers";
import {contactsArrayToObject} from "../../../../../helpers/common-helpers/contact.helpers";
import {ContactTypeCode} from "../../../../../config/enum.config";

const UserForm = (props) => {
  return (
    <FormWrapper
        setGroupName={props.formKey}
        setFormObject={
          props.uiType !== "create" ?
              (
                  {
                    title_id: _get(props.row, 'tittle.id', ""),
                    full_name: _get(props.row, 'full_name', ""),
                    designation: _get(props.row, 'users.designation', ""),
                    nic: _get(props.row, 'nic', ""),
                    email: contactsArrayToObject(_get(props.row, `users.contact`, []), false, ContactTypeCode.EMAIL),
                    contact_number: contactsArrayToObject(_get(props.row, `users.contact`, []), false, ContactTypeCode.MOBILE),
                    status: _get(props.row, 'users.status', ""),
                    user_name: _get(props.row, 'users.user_name', ""),
                    display_name: _get(props.row, 'display_name', ""),
                    sms_notification: _get(props.row, 'users.sms_notification', false),
                    email_notification: _get(props.row, 'users.email_notification', false),
                    password_status: _get(props.row, 'users.password_status', ""),
                    created_service_id: _get(props.row, 'users.created_service_id', ""),
                    default_service_id: _get(props.row, 'users.default_service_id', ""),
                    delegation_switchable: _get(props.row, 'users.delegation_switchable', ""),
                    current_delegation_id:_get(props.row, 'users.current_delegation_id', ""),
                    active: _get(props.row, 'users.active', ""),
                    deactivation_reason_id: _get(props.row, 'users.deactivation_reason_id', ""),
                    blocked: _get(props.row, 'users.blocked', ""),
                    attempts: _get(props.row, 'users.attempts', ""),
                    password_policy: _get(props.row, 'users.password_policy', ""),
                    password_expiry: _get(props.row, 'users.password_expiry', "")
                  }
              ) : (
                  {
                    title_id: _get(props.row, 'tittle.id', ""),
                    full_name: _get(props.row, 'full_name', ""),
                    designation: _get(props.row, 'users.designation', ""),
                    nic: _get(props.row, 'nic', ""),
                    email: contactsArrayToObject(_get(props.row, `users.contact`, []), false, ContactTypeCode.EMAIL),
                    contact_number: contactsArrayToObject(_get(props.row, `users.contact`, []), false, ContactTypeCode.MOBILE),
                    status: _get(props.row, 'users.status', ""),
                    user_name: _get(props.row, 'users.user_name', ""),
                    display_name: _get(props.row, 'display_name', ""),
                    sms_notification: _get(props.row, 'users.sms_notification', false),
                    email_notification: _get(props.row, 'users.email_notification', false)
                  }
              )
        }
    >
      <UICard cardHeading="User Information">

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <SelectBoxWithState
                  formGroupName={props.formKey}
                  isRequired={true}
                  apiUrl={userTitlesListAPI.url}
                  apiStateKey={userTitlesListAPI.key}
                  keyName={"id"}
                  valueName={"name"}
                  emptySelectOptionTxt={""}
                  inputName={"title_id"}
                  labelText="Title"
                  inputError={"Title is required"}
                />
              </div>
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"full_name"}
                  labelText="Full Name"
                  isRequired={true}
                  inputError={"Full Name is required"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"designation"}
                  labelText="Designation"
                  isRequired={true}
                  inputError={"Designation is required"}
                />
              </div>
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"nic"}
                  labelText="NIC"
                  isRequired={true}
                  inputError={"NIC is required"}
                  isDisabled={props.uiType === "edit"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"email"}
                  labelText="Email"
                  isRequired={true}
                  inputError={"Email is required"}
                />
              </div>
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"contact_number"}
                  labelText="Mobile Number"
                  isRequired={true}
                  inputError={"Mobile Number is required"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <SelectBoxWithState
                  formGroupName={props.formKey}
                  dataList={[
                    {
                      id: "0",
                      value: "Inactive",
                    },
                    {
                      id: "1",
                      value: "Active",
                    },
                  ]}
                  inputName={"status"}
                  labelText="Status"
                  emptySelectOptionTxt={""}
                />
              </div>
            </div>
          </div>
        </div>
      </UICard>
      <UICard cardHeading="User Login Information" elementStyle="defaultMarginTopBottom">

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"user_name"}
                  labelText="Username"
                  isRequired={true}
                  inputError={"Username is required"}
                  isDisabled={props.uiType === "edit"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"display_name"}
                  labelText="Display Name"
                  isRequired={true}
                  inputError={"Display Name is required"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 ">
                <CheckBoxWithState
                  labelText={"Notify via SMS"}
                  formGroupName={props.formKey}
                  inputName={"sms_notification"}
                  inputValue={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 ">
                <CheckBoxWithState
                  labelText={"Notify via Email"}
                  formGroupName={props.formKey}
                  inputName={"email_notification"}
                  inputValue={true}
                />
              </div>
            </div>
            {
              (props.uiType === "edit") ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 floatLeft">
                      <SubmitButton
                          elementWrapperStyle={"floatLeft"}
                          btnName={"ResetPassword"}
                          btnText={"Reset Password"}
                          isValidate={false}
                          flashMessages={{
                            "success": {
                              status: true,
                              message: "User password reset successfully",
                              messageType: "success"
                            }
                          }}
                          // dataTableKey={fullDetailresourceGetAPI.key + "ResHr"}
                          callApiObject={{
                            isSetHeaders: true,
                            multipart: false,
                            method: "put",
                            onUpload: false
                          }}
                          apiDataStoringObject={{
                            setLoader: true,
                            storingType: apiResponseStoringType.responseLinkToFrom
                          }}
                          onGetAPIEndPointFn={(formState) => {
                            return {
                              "url": `${userPasswordResetAPI.url}/${props.row.users.id}`,
                              "key": userPasswordResetAPI.key
                            }
                          }}
                          onResponseCallBackFn={(error, result) => {

                          }}
                      />
                    </div>
                  </div>
              ) : (null)
            }
          </div>
        </div>
      </UICard>
      {
        (props.uiType === "edit") ? (
            <div className="col-md-12 floatLeft">
              <SubmitButton
                  btnText="Update"
                  elementStyle="btnWrapper"
                  formGroupName={props.formKey}
                  isValidate={true}
                  flashMessages={{
                    "success": {
                      status: true,
                      message: "User updated successfully",
                      messageType: "success"
                    }
                  }}
                  // dataTableKey={fullDetailresourceGetAPI.key + "ResHr"}
                  validationObject={{
                    fileds: {
                      title_id: "Title",
                      full_name: "Full Name",
                      designation: "Designation",
                      nic: "NIC",
                      email: "Email",
                      contact_number: "Mobile Number",
                      user_name: "Username",
                      display_name: "Display Name"
                    },
                    rules: {
                      title_id: "required",
                      full_name: "required|max:150",
                      designation: "required|max:150",
                      nic: "required",
                      email: "required|email|max:100",
                      contact_number: "required|max:10",
                      user_name: "required|max:50",
                      display_name: "required|max:20"
                    },
                    message: {}
                  }}
                  callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "put",
                    onUpload: false
                  }}
                  apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.responseLinkToFrom
                  }}
                  onGetAPIEndPointFn={(formState) => {
                    return {
                      "url": `${internalUserAPI.url}/${props.row.users.id}`,
                      "key": internalUserAPI.key
                    }
                  }}
                  onChangeRequestBodyFn={(fromObject) => {
                    return getDataByFormObject({
                      ...fromObject,
                      account_type: "INT",
                      preferred_language: "EN",
                      status: fromObject.status === "1",
                    })
                  }}
                  onResponseCallBackFn={(error, result) => {

                  }}
              />
            </div>
        ) : (null)
      }
    </FormWrapper>
  )
}


const DefaultRoles = (props) => {
  const [formState] = useContext(FormContext);
  return (
      <FormWrapper
          // onDestroyUnsetFormObject={false}
          setGroupName={props.formKey}
          apiUrl={props.uiType !== "create" ? (`${userDefaultDelegationAPI.url}/${props.row.users.id}`) : (null)}
          onRebuildResponseFn={(response) => {
            return {
              "internal_role_id": _get(response, 'internal.role.id', ""),
              "province": _get(response, 'internal.authority.province.code', ""),
              "internal_service_id": _get(response, 'internal.service_id', ""),
              "admin_role_id": _get(response, 'admin.role.id', ""),
              "admin_service_id": _get(response, 'admin.service_id', ""),
              "is_admin": _get(response, 'admin', {}) !== {},
              "is_internal": _get(response, 'internal', {}) !== {},
              "_onLoad": false,
            }
          }}
      >
        <UICard cardHeading="Default Role & Location" elementStyle="defaultMarginBottom">
            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <CheckBoxWithState
                      labelText={"Internal Module"}
                      formGroupName={props.formKey}
                      inputName={"is_internal"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={props.formKey}
                      emptySelectOptionTxt={"User Role"}
                      apiUrl={`${userRolesListAPI.url}?role_type=INL_APP`}
                      apiStateKey={`${userRolesListAPI.key}_INT`}
                      keyName={"id"}
                      valueName={"name"}
                      inputName={"internal_role_id"}
                      labelText="User Role"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={props.formKey}
                      emptySelectOptionTxt={"Province"}
                      apiUrl={provinceListApi.url}
                      apiStateKey={`${provinceListApi.key}_INT`}
                      keyName={"code"}
                      valueName={"name"}
                      inputName={"province"}
                      labelText="Province"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={props.formKey}
                      emptySelectOptionTxt={"Authority"}
                      apiUrl={`${AuthorityListApi.url}?province_code=${_get(formState,`${props.formKey}.province`,null)}`}
                      apiStateKey={AuthorityListApi.key}
                      keyName={"id"}
                      valueName={"code"}
                      inputName={"internal_service_id"}
                      labelText="Authority"
                      isDisabled={_get(formState, `${props.formKey}.province`, null) === null}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <CheckBoxWithState
                      labelText={"Admin Module"}
                      formGroupName={props.formKey}
                      inputName={"is_admin"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={props.formKey}
                      emptySelectOptionTxt={"User Role"}
                      apiUrl={`${userRolesListAPI.url}?role_type=ADN_APP`}
                      apiStateKey={`${userRolesListAPI.key}_ADN`}
                      keyName={"id"}
                      valueName={"name"}
                      inputName={"admin_role_id"}
                      labelText="User Role"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={props.formKey}
                      emptySelectOptionTxt={"Province"}
                      apiUrl={provincesOfficesListAPI.url}
                      apiStateKey={`${provincesOfficesListAPI.key}_ADN`}
                      keyName={"id"}
                      valueName={"service.name"}
                      inputName={"admin_service_id"}
                      labelText="Province"
                    />
                  </div>
                </div>
              </div>
            </div>
        </UICard>
        {
          (props.uiType === "edit") ? (
              <div className="col-md-12 floatLeft">
                <SubmitButton
                    btnText="Update"
                    elementStyle="btnWrapper"
                    formGroupName={props.formKey}
                    isValidate={false}
                    flashMessages={{
                      "success": {
                        status: true,
                        message: "Default roles updated successfully",
                        messageType: "success"
                      }
                    }}
                    callApiObject={{
                      isSetHeaders: true,
                      multipart: false,
                      method: "put",
                      onUpload: false
                    }}
                    apiDataStoringObject={{
                      setLoader: true,
                      storingType: apiResponseStoringType.responseLinkToFrom
                    }}
                    onGetAPIEndPointFn={(formState) => {
                      return {
                        "url": `${userDefaultDelegationAPI.url}/${props.row.users.id}`,
                        "key": userDefaultDelegationAPI.key
                      }
                    }}
                    onChangeRequestBodyFn={(fromObject) => {
                      return getDataByFormObject({
                        ...fromObject,
                        province: undefined
                      })
                    }}
                    onResponseCallBackFn={(error, result) => {

                    }}
                />
              </div>
          ) : (null)
        }
      </FormWrapper>
  )
}


const AdditionalRoles = (props) => {
  const dataTableKey = "AdditionalRolesKey";
  return (
    <Fragment>
      <DataTable
        elementWrapperStyle="defaultMarginBottom"
        tableName="Additional Roles & Locations"
        apiUrl={`${userDelegationCreateAPI.url}/${props.row.users.id}/list?default_delegation=false`}
        dataTableKey={dataTableKey}
        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            modelContentElement: AdditionalRolesAndLocations,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "Add Additional Role & Location",
              closeButton: {
                btnText: "Cancel",
              },
              isCustomButton: true,
              customButtonComponent: CustomButtons,
              customButtonComponentProps: {
                user_id: props.row.users.id
              },
            },
          },
          {
            actionType: actionBtnTypes.delete,
            actionBtn: { tooltip: "Remove" },
          }
        ]}
        tableHeaderList={[
          {
            displayName: "Province",
            key: "province",
            isSort: false,
          },
          {
            displayName: "Authority",
            key: "authority.service.name",
            isSort: false,
          },

          {
            displayName: "User Role",
            key: "role.name",
            isSort: false,
          },
          {
            displayName: "Status",
            key: "role.status",
            isSort: false,
            onPrintFn: ({ role }) => (role.status === 1 ? "Active" : "Inactive")
          },
          {
            displayName: "Expire Date",
            key: "expiry_at",
            isSort: false,
            onPrintFn: (raw) =>
                dateObjectToString(
                    raw.expiry_at,
                    "YY-mm-dd"
                ),
          },
        ]}
      />
    </Fragment>
  )
}

const CreateStepsWrapper = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepType, setActiveStepType] = useState({
    "0_step": "create",
    "1_step": "create",
    "2_step": "create",
  });

  const [currentRow, setRow] = useState({});
  const [coreState, coreAction] = useContext(CoreContext);
  const [uiActionFn] = useContext(UIContext);
  const [uiAction] = useContext(UIContext);

  const formKey = 'userCreate';


  const onGetActionBtnConf = () => {

    const firstStep = {
      formGroupName: formKey,
      isValidate: true,
      flashMessages: {
        "success": {
          status: true,
          message: "User created successfully",
          messageType: "success"
        }
      },
      validationObject: {
        fileds: {
          title_id: "Title",
          full_name: "Full Name",
          designation: "Designation",
          nic: "NIC",
          email: "Email",
          contact_number: "Mobile Number",
          user_name: "Username",
          display_name: "Display Name"
        },
        rules: {
          title_id: "required",
          full_name: "required|max:150",
          designation: "required|max:150",
          nic: "required",
          email: "required|email|max:100",
          contact_number: "required|max:10",
          user_name: "required|max:50",
          display_name: "required|max:20"
        },
        message: {}
      },
      callApiObject: {
        isSetHeaders: true,
        multipart: false,
        method: activeStepType["0_step"] === "create" ? "post" : "put",
        onUpload: false
      },
      onGetAPIEndPointFn: (formState) => {
        return activeStepType["0_step"] === "create" ? internalUserAPI : {
          "url": `${internalUserAPI.url}/${currentRow.users.id}`,
          "key": internalUserAPI.key
        }
      },
      onChangeRequestBodyFn: (fromObject) => {
        return getDataByFormObject({
          ...fromObject,
          account_type: "INT",
          preferred_language: "EN",
          status: fromObject.status === "1",
        })
      },
      onResponseCallBackFn: (error, result) => {
        if (!error) {
          setRow(result.data.data);
          setActiveStep(activeStep + 1)
        }
      }
    };

    const secondStep = {
      formGroupName: 'defaultRole',
      isValidate: false,
      flashMessages: {
        "success": {
          status: true,
          message: "User delegation created successfully",
          messageType: "success"
        }
      },
      callApiObject: {
        isSetHeaders: true,
        multipart: false,
        method: activeStepType["0_step"] === "create" ? "post" : "put",
        onUpload: false
      },
      onGetAPIEndPointFn: (formState) => {
        return activeStepType["0_step"] === "create" ?
            {
              "url": `${userDefaultDelegationAPI.url}/${currentRow.users.id}`,
              "key": userDefaultDelegationAPI.key
            }:
            {
              "url": `${userDefaultDelegationAPI.url}/${currentRow.users.id}`,
              "key": userDefaultDelegationAPI.key
            }
      },
      onChangeRequestBodyFn: (fromObject) => {
        return getDataByFormObject({
          ...fromObject,
          province: undefined
        })
      },
      onResponseCallBackFn: (error, result) => {
        if (!error) {
          setActiveStep(activeStep + 1)
        }
      }
    };

    const thirdStep = {
      onGetAPIEndPointFn: (formState) => {
        return false
      },
    };

    return activeStep === 0 ? firstStep : activeStep === 1 ? secondStep : thirdStep;
  }

  const onNext = () => {
    if (activeStep <= 2) {
      setActiveStep(activeStep + 1);
    }
  }

  const onBackFn = () => {
    setActiveStepType({
      ...activeStepType,
      [`${activeStep - 1}_step`]: "create-edit"
    });
    setActiveStep(activeStep - 1);
  }

  return (
    <Fragment>
      <StepsWrapper
        activeStep={activeStep}
        orientation={stepWrapperOrientations.vertical}
        onNextBtnClickFn={() => onNext()}
        onBackBtnClickFn={() => onBackFn()}
        nextBtnProps={onGetActionBtnConf()}
      >
        <CustomStep stepid="0" label="Add User Information">
          <UserForm formKey={formKey} row={currentRow} activeStep={activeStep} uiType={`${activeStepType[`${activeStep}_step`]}`}/>
        </CustomStep>
        <CustomStep stepid="1" label="Default Role & Location">
          <DefaultRoles formKey={'defaultRole'} row={currentRow} activeStep={activeStep} uiType={`${activeStepType[`${activeStep}_step`]}`}/>
        </CustomStep>
        <CustomStep stepid="2" label="Additional Roles & Locations">
          <AdditionalRoles row={currentRow} uiType={`${activeStepType[`${activeStep}_step`]}`}/>
        </CustomStep>
      </StepsWrapper>
    </Fragment>
  )
}

const EditFormWrapper = (props) => {
  const formKey = props.fromParent.formKey;
  const defaultRoleFormKey = "defaultRoleFormKey";
  const [getStepUIState, setStepUIState] = useState({
    'activeTab': "0",
  });
  return (
    <Fragment>
      <TabsWrapper
        active={getStepUIState.activeTab}
        onChangeFn={(eventInfo) => setStepUIState({ ...getStepUIState, activeTab: eventInfo.value })}
      >
        <SubTab tabid="0" header="Add User Information">
          <UserForm formKey={formKey} uiType={"edit"} row={{ ...props.row }}/>
        </SubTab>
        <SubTab tabid="1" header="Default Role & Location">
          <DefaultRoles formKey={'defaultRole'} uiType={"edit"} row={{ ...props.row }}/>
        </SubTab>
        <SubTab tabid="2" header="Additional Roles & Locations">
          <AdditionalRoles uiType={"edit"} row={{ ...props.row }}/>
        </SubTab>
      </TabsWrapper>
    </Fragment>
  )
}

export {
  CreateStepsWrapper,
  EditFormWrapper
}