/*
 * @Author: Senura Weerasinghe 
 * @Date: 2021-01-12 12:37:41 
 * @Last Modified by:   Senura Weerasinghe 
 * @Last Modified time: 2021-01-12 12:37:41 
 */


import React, { Fragment, useContext, useState, useEffect } from "react";
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable"
import { actionBtnTypes } from "../../../../../config/dataTable.config"
import { ViewRevenueSettlement } from "./includes/ViewRevenueSettlementNote"
import { settlementListAPI } from "../../../../../config/apiUrl.config";
import { _get, _set } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";

const RevenueSettlementHistory = () => {

  return (
    <DashboardTemplate
      pageTitle="Revenue Settlement Note History"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          displayName: "Revenue Settlement Note History",
        },
      ]}
    >
      <DataTable
        tableName="Revenue Settlement Note History"
        apiUrl={`${settlementListAPI.url}`}
        tableHeaderList={[
          { displayName: "Settlement Date", key: "settlement_at", isSort: false,onPrintFn:(rawData)=>dateObjectToString(rawData.settlement_at, "dd/mm/YY")},
          { displayName: "Transaction To", key: "transaction_to", isSort: false ,onPrintFn:(rawData)=>dateObjectToString(rawData.transaction_to, "dd/mm/YY")},
          { displayName: "Total Transferred Amount", key: "amount", isSort: false, isRightAlign: true},
          { displayName: "Created by", key: "created_user_display", isSort: false },
          {
            displayName: "Status", key: "status", isSort: false, onPrintFn: (rawData) => {
              if (rawData.status === "PEN") {
                return "Pending"
              }
              else if(rawData.status === "SUC"){
                return "Success"
              }
              else if(rawData.status === "FAL"){
                return "Failed"
              }
              else if(rawData.status === "PAR"){
                return "Partially Success"
              }
              else if(rawData.status === "PRO"){
                return "Processing"
              }
            }
          },
          { displayName: "Created at", key: "created_at", isSort: false ,onPrintFn:(rawData)=>dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM")},

        ]}
        // tableBodyList={[
        //   {
        //     "settlement_date": "02/08/2019",
        //     "transaction_period": "02/08/2019 - 01/08/2020",
        //     "reference_amount": "00030",
        //     "createdBy": "K.M.S Pathirana",
        //     "createdAt": "01/08/2020 08.00",

        //   }         
        // ]}

        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: "View" },
            modelContentElement: ViewRevenueSettlement,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "View Revenue Settlement Note",
              isCustomButton: false,
              closeButton: {
                btnText: "Close",
              },
            },
          },
        ]}
      />
    </DashboardTemplate>
  );
}

export { RevenueSettlementHistory }