import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get, } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from "../../../../../config/permission.config";

function Serial() {
  const [authStatus, authAction] = useContext(AuthContext);
  const ViewPermission = _get(authStatus, 'authUser.permissions', "");
  let FR35, FR36
  ViewPermission.forEach(item => {
    if (item.permission === permissions.VIEW_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT.permissions[0]) {
      FR35 = true
    }
    else if (item.permission === permissions.VIEW_FR36_DISCARDED_SERIAL_NUMBER_REPORT.permissions[0]) {
      FR36 = true
    }

  })
  return (
    <UICard>
      < ol type="1">
        <div className="App">
          {FR35 &&
            <li>
              <Link to="/preprinted-serial-number"><strong>Preprinted Serial Number Summary Report </strong></Link>
              <br />
              <p> This is the Preprinted Serial Number Summary Report </p>
              <br />
            </li>
          }
          {FR36 &&

            <li>
              <Link to="/discarded-serial-number"><strong>Discarded Serial Number Report </strong></Link>
              <br />
              <p> This is the Discarded Serial Number Report </p>
            </li>}
          <br />


        </div>
      </ ol>
    </UICard>
  );
};

const ReportSerial = () => {
  return (
    <DashboardTemplate
      pageTitle="Serial Number Details Reports"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          //urlTo: "/report",
          displayName: "Serial Number Details Reports List",
        },
      ]}
    >
      <Serial />
    </DashboardTemplate>
  );
};

export { ReportSerial };