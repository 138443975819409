/*
 * @Author: Chamith Jayathilaka
 * @Date: 2021-01-12 13:59:59 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:24:11
 */
import { isEmpty } from 'lodash';
import React, { useContext, useState,Fragment } from 'react';
import { availableCounterListApi, dealerCompletePaymentAPIandPrint } from '../../../../../config/apiUrl.config';
import { getDataByFormObject } from '../../../../../helpers/common-helpers/common.helpers';
import { _get, _unset } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { InputButton, SubmitButton } from '../../../../ui-components/ui-elements/form';
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { useLicencePrint } from '../../../../hooks/common-hooks/usePrinter.hook';
import {
	apiResponseStoringType,
	CoreContext,
} from "../../../core/context-providers/CoreContext.provider";
import { callApi } from '../../../../../helpers/common-helpers/callApi.helpers';
import LicenceReceiptPrint from './LicenceReceiptPrint';


const emptyFun = (...para) => undefined;

const ConfirmationPopupDealer = ({
    closePopupFn = emptyFun,
    closeConfirmationPopupFn = emptyFun,
    onClickSuccessFn = emptyFun,
    openPopupFn = emptyFun,
    uiType = "dealer",
    vehicleCount=0
}) => {
    return (
        <div className="row">
          <div className="col-md-12">
            <strong>Are you sure you want to complete the payment?</strong>
          </div>
          <div className="col-md-12 text-right defaultMarginTop">

              <PayBtn closePopupFn={closePopupFn} onClickSuccessFn={onClickSuccessFn} uiType={uiType} vehicleCount={vehicleCount}/>

            <InputButton
              elementWrapperStyle={"defaultHalfMarginLeftRight"}
              btnText="No"
              onClickBtnFn={closePopupFn}
              startIcon={"fas fa-times"}
            />
          </div>
        </div>
      );
}


const PayBtn = (props) => {
  const formGroupName = "printReceipt";
  const uiType = props.uiType;
  const [, uiAction] = useContext(UIContext);
  const [getState, setState] = useState({});
  const [coreState, coreAction] = useContext(CoreContext);
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const { printFn, bulkPrintFn } = useLicencePrint();
  const vehicleCount = props.vehicleCount;
  const authority_id = _get(authStatus, "authUser.authorityId", "");
  const pdmt_id = _get(authStatus, "authUser.pdmtId.id", "");
  const paid_types = [];
  let rawvehicleList =[]
  rawvehicleList = (_get(formState, "dealer_license_vehiclelist_form.data", []) )

let vehicleSubmitList = [];
rawvehicleList.forEach(item =>{
  let submitVehcileObj ={
    vehicle_number: item.vehicle_number,
    insurance_id: item.insurance_company?.id,
    insurance_service_id: item.insurance_company?.service?.id,
    insurance_expiry_date: item.insurance_expiry_date,
    serial_number: getState.licenseSequenceVal,
  }
  vehicleSubmitList.push(submitVehcileObj)
})
  
  
  const paymentFormObj = getDataByFormObject(_get(formState, "paymentTypeFormKey", {}));
  _unset(paymentFormObj, "isDisplay");
  _unset(paymentFormObj, "payment_categories");
  _unset(paymentFormObj, "paid_amount");
  _unset(paymentFormObj, "cash_amount");
  _unset(paymentFormObj, "cheque_amount");
  _unset(paymentFormObj, "card_amount");
  
  if(!isEmpty(paymentFormObj)){
    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        paid_types.push({
          "payment_type_code": _get(paymentFormObj, `[${key}]['payment_type']`, null),
          "payment_gateway_code": null,
          "amount": parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null)),
          "cus_paid_amount": parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null)),
          "cheque_number": _get(paymentFormObj, `[${key}]['cheque_no']`, null),
          "cheque_date": null,
          "bank_id": _get(paymentFormObj, `[${key}]['bank']`, null),
          "card_type": null,
          "card_holder_name": null,
          "request_ref_no": _get(paymentFormObj, `[${key}]['reference_no']`, null),
          "payment_ref": null,
          "ipg_ref": null,
          "phone_no": null,
          "approval_code": _get(paymentFormObj, `[${key}]['approval_no']`, null),
          "note": null,
          "request_str": null,
          "response_str": null,
          "success": true,
          "legacy_cheque_id": null
        });
      }
    });
  }
  const onpreview = (printFrame) => {
    let divsToPrint = document.getElementsByClassName(printFrame), n;
    let printContents = "";
    for (n = 0; n < divsToPrint.length; n++) {
      printContents += divsToPrint[n].innerHTML + "<br>";
    }
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   
    // uiAction.setFlashMessage({
    //   status: true,
    //   message: "Receipt is successfully Printed .",
    //   messageType: "success"
    // });
    window.location.reload();  
    document.body.innerHTML = originalContents; 
    window.close()

  }

  return (
    <Fragment >
    <SubmitButton
      btnText={"Yes"}
      startIcon={"fas fa-check"}
      formGroupName={formGroupName}
      isValidate={false}
      flashMessages={{
        success: {
          status: true,
          message: `Payment successfully completed.`,
          messageType: "success",
        },
        failed: {
          status: true,
          message: `Payment fail.`,
          messageType: "error",
        },
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          url: dealerCompletePaymentAPIandPrint.url ,
          key: dealerCompletePaymentAPIandPrint.key 
        };
      }}
      onChangeRequestBodyFn={(fromObject) => {
        return getDataByFormObject({
          dealer_vehicle: vehicleSubmitList,
          dealer_id:_get(formState, "dealer_search_form.searchKey"),
          serial_number:_get(formState, `issueDealer.next_serial`, "0"),
          paid_types: paid_types,
          manual_receipt_no: null,
          counter_id: _get(formState, `issueDealer.counter_id`, "0"),
          counter_session_id: _get(formState, `issueDealer.sessionId`, "0"),
          authority_id: authority_id,
          counter_serial_number_range_id: _get(formState, `issueDealer.counter_serial_number_range_id`, "0"),
          issued_pdmt_id:pdmt_id,
          authority_code: _get(authStatus, 'authUser.authorityCode', ""),
          counter_code: _get(formState, `issueDealer.counter_code`, null),
          province_code: _get(authStatus, 'authUser.pdmtId.code', ""),
        });
      }}
      callApiObject={{
        isSetHeaders: true,
        method: "post",
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.responseLinkToFrom,
      }}
      onResponseCallBackFn={(error, response) => {
        if (!error) {
          formAction.changeInputFn("print_licences", "data", "", response.data.data.print_licences);
          let completedLicences = _get(response, 'data.data.completed_licence');
          let printLicenceResponse = _get(response, 'data.data.print_licences', []);
          // if (completedLicences.length > 1) { //Bulk print response handling
          //   bulkPrintFn(coreAction, printLicenceResponse);
          // } else {//Single print response
          //   printFn(coreAction, printLicenceResponse[0]);
          // }
          onpreview("print-card")
              //for vehicle Search Refresh
              formAction.changeInputFn("dealer_search_form", "shouldDealerRefresh", "", true);
              formAction.changeInputFn("dealer_search_form", "searchKey", "","")
              // formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", _get(response, "data.data.licence_details.serial_number", ""));
              
              callApi(`${availableCounterListApi.url}/${authority_id}/counters/${_get(formState, `issueDealer.counter_id`, null)}`)
              .method("get")
              .headers(true)
              .body({})
              .isMultipart(false)
              .send((error, result) => {

                if(!error){
                  const nxt_serial = _get(result, "data.data.next_serial_number", "") == null ? "" : _get(result, "data.data.next_serial_number", "");
                  formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", nxt_serial);
                }
              });
              props.closePopupFn();          
              props.onClickSuccessFn();  
        }else{
          uiAction.setFlashMessage({
            status: true,
            // message: error?.data?.errors.details,
            message: 'Something went wrong',
            messageType: "error",
          });
        }
        
       
      }}
    />
    <div className='licence-visibility'>
        {vehicleCount === 1 ?
          (<div className="print-card">
            <LicenceReceiptPrint dataList={_get(formState, "print_licences.data[0].data", "")} />
          </div>)
          :
          (_get(formState, "print_licences.data[0].data.licences", []).map((item) => (
            <div className="print-card">
             <LicenceReceiptPrint dataList={item} />
            </div>

          )))}

      </div>
</Fragment >


  );
};

const template =<template name="NEW_DEED_REQUEST_PRINT_DETAILS" paper_size="A4" paper_width="300.3" paper_height="152.5" orientaion="portrait" right_margin="0" top_margin="0" left_margin="0" bottom_margin="0" >
<fonts>
    <font code="F0" name="San Serif" size="10" color="0" Bold="true" Italic="false" Underline="false" StrikeOut="false" />
    <font code="F1" name="San Serif" size="12" color="0" Bold="true" Italic="true" Underline="true" StrikeOut="true" />
    <font code="F2" name="San Serif" size="12" color="0" Bold="true" />
    <font code="F3" name="Consolas" size="14" color="0" Bold="true" />
    <font code="FV11" name="Consolas" size="11" color="0" Bold="false" />
    <font code="FV11B" name="Consolas" size="11" color="0" Bold="true" />
    <font code="F3L" name="Consolas" size="11" color="0" Bold="false" />
    <font code="F4B" name="Consolas" size="10" color="0" Bold="true" />

    <font code="F4" name="San Serif" size="8" color="0" />
    <font code="F5" name="Consolas" size="35" color="0" Bold="true" Italic="false" Underline="false" StrikeOut="false" />
    <font code="FS2" name="Nirmala UI" size="11" color="0" Bold="true" />
    <font code="FS3" name="Nirmala UI" size="10" color="0" />
    <font code="F5S" name="Consolas" size="11" color="0" />
    <font code="F5L" name="Consolas" size="13" color="0" Bold="true" />
    <font code="FC12B" name="Consolas" size="13" color="0" Bold="true" />



    <font code="FC7" name="Consolas" size="7" color="0"  />
    <font code="FC8" name="Consolas" size="8" color="0"  />




</fonts>
<header>	
    
     <label name="request_id" left="130" top="8.8" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" ></label>
     <label name="request_status" left="15.0" top="22.4" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" ></label>
     <label name="request_status" left="15.0" top="32.0" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" ></label>

    


    
     <line  name="LINE3" start_x="150.8" start_y="2.1" end_x="150.8" end_y="149.3" penwidth="1" penstyle="SOLID" color="00000" Bold="true" />
    <line  name="LINE3" start_x="11.8" start_y="149.3" end_x="150.8" end_y="149.3" penwidth="1" penstyle="SOLID" color="00000" Bold="true"  />
    <line  name="LINE3" start_x="11.8" start_y="2.1" end_x="150.8" end_y="2.1" penwidth="1" penstyle="SOLID" color="00000"  />
    <line  name="LINE3" start_x="11.8" start_y="2.1" end_x="11.8" end_y="149.3" penwidth="1" penstyle="SOLID" color="00000"  />

     <label name="Request Id: " left="52" top="5" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" >Vehicle Revenue Licence</label>
     <label name="Request Id: " left="52" top="9" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" >වාහන ආදායම් බලපත්‍රය</label>
     <label name="Request Id: " left="52" top="14" right="71" bottom="26" h_align="left" v_align="top" font_code="F3L" > வாகன வருமானவரி பத்திரம்</label>
     <label name="Request Id: " left="130.7" top="6.8" right="141" bottom="26" h_align="right" v_align="top" font_code="F4B" > MTA 99X</label>
  
    <label name="lhs_issued_counter" left="92" top="13" right="136" bottom="26" h_align="right" v_align="top" font_code="F4B" >WP0MAHX.AA0000</label>
    <label name="lhs_licence_no" left="4" top="25" right="141" bottom="26" h_align="left" v_align="top" font_code="F5L" >WP9891309768  </label>
    <label name="lhs_licence_type" left="4" top="29.7" right="141" bottom="26" h_align="left" v_align="top" font_code="F3" >SPECIAL PURPOSE VEHICLE|PETROL-ELE </label>
    <label name="Request Id: " left="4" top="34.8" right="141" bottom="26" h_align="left" v_align="top" font_code="F3" >WP|CAK-1024|NORMAL LICENCE </label>

    <label name="Request Id: " left="65" top="40" right="141" bottom="26" h_align="left" v_align="top" font_code="F5" > JUL 2021 </label>

    <label name="lhs_owner_name_l1" left="4" top="56" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >AAAAAAAAAAAAAAAAAAAAAAAAAAAA</label>
    <label name="lhs_owner_name_l2" left="4" top="59.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >ABHAYASINGHE </label>
    <label name="lhs_owner_name_l3" left="4" top="63.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >AAAAAAAAAAAAAAAAAAAAAAAAA</label>
    <label name="lhs_owner_address_l1" left="4" top="68.4" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >INOVA IT SYSTEMS (PVT) LTD, NO 5, JAWATTA ROAD, KASSAPA ROAD, </label>
    <label name="lhs_owner_address_l2" left="4" top="72.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >COLOMBO 05  </label>
    <label name="lhs_owner_address_l3" left="4" top="75.7" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</label>
     <label name="lhs_total" left="4" top="85.6" right="26" bottom="26" h_align="right" v_align="top" font_code="FV11B" >13,000.00 </label>
    <label name="Request Id: " left="27" top="85.6" right="141" bottom="26" h_align="left" v_align="top" font_code="FV11" >:</label>
    <label name="lhs_charges_breakdown_l1" left="31" top="85.6" right="141" bottom="26" h_align="left" v_align="top" font_code="FV11" >12,000.00 </label>
    
    <label name="lhs_charges_breakdown_l2" left="31" top="89.6" right="141" bottom="26" h_align="left" v_align="top" font_code="FV11" >AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </label>


    <label name="lhs_valid_from" left="4" top="100" right="141" bottom="26" h_align="left" v_align="top" font_code="FC12B" >27-07-2020</label>
    <label name="Request Id: " left="34" top="100" right="141" bottom="26" h_align="left" v_align="top" font_code="FC12B" >To</label>
    <label name="lhs_valid_to" left="44" top="100" right="141" bottom="26" h_align="left" v_align="top" font_code="FC12B" >27-07-2020</label>
    
    <label name="lhs_vet_no" left="90" top="99" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >2020092615114</label>
    <label name="lhs_no_of_seats" left="122" top="107.3" right="123" bottom="26" h_align="left" v_align="top" font_code="F5S" >05</label>

    <label name="lhs_weight" left="49" top="107.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >1,690 kg</label>



    <signature name="signature_uid" format="jpg|png" left="79" top="121" right="43" bottom="138" h_align="left" v_align="top" width="10" height="15" />
    <line name="LINE3" start_x="79" start_y="137" end_x="106" end_y="137" penwidth="1" penstyle="DASH" color="00000" />
    <label name="lhs_issued_by" left="79" top="137" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > G. Saman  </label>
    <label name="lhs_issued_date" left="79" top="140.7" right="146" bottom="26" h_align="left" v_align="top" font_code="F5S" > 22-07-2020  </label>


<label name="rhs_vehicle_no" left="155" top="8.4" right="141" bottom="10.2" h_align="left" v_align="top" font_code="FC7" >CXX-0000 </label>
<label name="rhs_vehicle_class" left="157" top="11.4" right="141" bottom="14" h_align="left" v_align="top" font_code="FC7" >Motor Car </label>
<label name="rhs_owner_name_l1" left="150" top="15" right="141" bottom="17" h_align="left" v_align="top" font_code="FC7" >Arambawelage Don Upali Ranjith 1234567891011121314</label>
<label name="rhs_owner_address_l1" left="152" top="18.5" right="141" bottom="20.5" h_align="left" v_align="top" font_code="FC7" >No 455,kaduwela. </label>
<label name="rhs_chassis_no" left="155" top="33.2" right="141" bottom="37" h_align="left" v_align="top" font_code="FC7" >X6TY7450008 </label>
<label name="rhs_fuel_type" left="154" top="36.5" right="141" bottom="40" h_align="left" v_align="top" font_code="FC7" >PETROL </label>
<label name="rhs_weight" left="168" top="39.9" right="141" bottom="42.2" h_align="left" v_align="top" font_code="FC7" >1343.22 kg </label>
<label name="rhs_first_registration_date" left="169" top="43.3" right="141" bottom="45.4" h_align="left" v_align="top" font_code="FC7" >2021-01-01 </label>
<label name="rhs_no_of_seats" left="156" top="46.5" right="141" bottom="49.6" h_align="left" v_align="top" font_code="FC7" />
<label name="rhs_previous_licence_no" left="158" top="72.4" right="141" bottom="76" h_align="left" v_align="top" font_code="FC7" >WP0233311222 </label>
<label name="Request Id: " left="179" top="75.8" right="141" bottom="78" h_align="left" v_align="top" font_code="FC7" >2021-01-01</label>
<label name="rhs_insurance_status" left="161" top="83.45" right="141" bottom="86.5" h_align="left" v_align="top" font_code="FC7" >ACTIVE </label>
<label name="rhs_vrp_expiry_date" left="170.5" top="86.8" right="141" bottom="89" h_align="left" v_align="top" font_code="FC7" >2022-01-14 </label>
<label name="rhs_fee" left="182" top="91.3" right="198" bottom="93.4" h_align="right" v_align="top" font_code="FC7" >1000.00</label>
<label name="rhs_arrears" left="182" top="94.5" right="198" bottom="96.4" h_align="right" v_align="top" font_code="FC7" >100.00</label>
<label name="rhs_fine" left="182" top="98" right="198" bottom="99" h_align="right" v_align="top" font_code="FC7" >12.00</label>
<label name="rhs_addtions" left="182" top="101.6" right="198" bottom="103.4" h_align="right" v_align="top" font_code="FC7" >0.00</label>
<label name="rhs_deductions" left="182" top="104.6" right="198" bottom="106.4" h_align="right" v_align="top" font_code="FC7" >100.00</label>
<label name="rhs_total" left="182" top="111.4" right="198" bottom="113.5" h_align="right" v_align="top" font_code="FC8" >1012.00</label>
<label name="rhs_issued_date" left="191" top="131" right="136" bottom="133" h_align="left" v_align="top" font_code="FC7" >2022-02-11</label>


      <label name="Request Id: " left="159" top="9" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > CXX-0000  </label>
    <label name="Request Id: " left="161" top="12" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > Motor Car </label>
    <label name="Request Id: " left="154" top="15.3" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > Arambawelage Don Upali Ranjith</label>
    <label name="Request Id: " left="164" top="18.7" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > No 455,kaduwela  </label> 
     <label name="Request Id: " left="152.9" top="18.4" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Address :  </label>
    <label name="Request Id: " left="152.9" top="32.6" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Chassis No. :  </label>
    <label name="Request Id: " left="152.9" top="36.8" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Fuel Type : </label>
    <label name="Request Id: " left="152.9" top="40.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Unladen/ Gross Weight : </label>
    <label name="Request Id: " left="152.9" top="45" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Date of First Registration :  </label>
    <label name="Request Id: " left="152.9" top="49.7" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > No. of Seats :  </label>
    <label name="Request Id: " left="152.9" top="54.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Please issue a Revenue Licence for the above vehicle </label>
    <label name="Request Id: " left="152.9" top="70.2" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Signature of the owner  </label>
    <label name="Request Id: " left="152.9" top="75.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5" > For Official Use </label>
    <label name="Request Id: " left="152.9" top="79.9" right="141" bottom="26" h_align="left" v_align="top" font_code="F5" > Licence No. : </label>
    <label name="Request Id: " left="152.9" top="84.2" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Previous Licence No. and Year :</label>
    <label name="Request Id: " left="152.9" top="91.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Insurance Status : </label>
    <label name="Request Id: " left="152.9" top="95" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Route Permit and Ex. Date :  </label>
    <label name="Request Id: " left="152.9" top="98.8" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Annual Licence Fee :                    Rs.  </label>
    <label name="Request Id: " left="152.9" top="102.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Arrears  :                                       Rs.  </label>
    <label name="Request Id: " left="152.9" top="120.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Fine :                                             Rs.  </label>
    <label name="Request Id: " left="152.9" top="127.9" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Additions :                                     Rs.  </label>
    <label name="Request Id: " left="152.9" top="133.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Deductions :                                  Rs.  </label>
    <label name="Request Id: " left="152.9" top="136.6" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Gross Amount :                             Rs.  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Certified that the above charges are correct  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > ...................................  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Checking Officer                                Date :  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Recieved the above amount.  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Cashier  </label>




     <line name="LINE3" start_x="5" start_y="170" end_x="107" end_y="170" penwidth="1" penstyle="SOLID" color="0" />
    <label name="LABEL_C" left="4" top="172" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" >H 054845 - 10000 (2020/07)P ශ්‍රී ලංකා රජයේ මුද්‍රණ දෙපාර්තමේන්තුව</label>
</header>


</template>
export {ConfirmationPopupDealer}
