/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 16:06:43 
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-11 16:23:33
 */
import React,{useState, Fragment} from 'react';
import {CounterTemplate} from "./../../../ui-components/templates/CounterTemplate.js";

import {TabsWrapper,SubTab,tabsWrapperVariants,tabsWrapperScrollButtons} from "../../../ui-components/ui-elements/tab/Tabs";
import {NormalLicense} from "./includes/NormalLicense";
import {DealerLicense} from "./includes/DealerLicense";
import {InputButton} from "../../../ui-components/ui-elements/form/Button";
import {Quotation} from "./includes/Quotation";
import {QuotationSRS,RevenueLicenseQuotation} from "./includes/QuotationSRS";
import PaymentUI from "../../payment/pages/Payment";
import {DialogPopup} from "../../../ui-components/ui-elements/modal/DialogPopup";
import {WebLicence} from "./includes/WebLicence";
import {Carnet} from "./includes/Carnet";
import {DataTable,actionBtnTypes} from "../../../ui-components/ui-elements/table/DataTable";
import {FormWrapper,InputBoxWithState,SelectBoxWithState} from "../../../ui-components/ui-elements/form/index";
import {_get} from "../../../../helpers/common-helpers/lodash.wrappers";
import IssueTemp from '../../payment/pages/IssueTemp.js';


const ActivityLog=()=>{
    const formKey="activityLogForm";
    return (
        <div className={"fullWidthDiv"}>
            <DataTable
                dataTableKey={"activityLog"}
                elementWrapperStyle={"defaultMarginTop"}
                tableHeaderList={[
                  { displayName: "Date & Time:", key: "date", isSort: false },
                  {
                    displayName: "Action",
                    key: "action",
                    isSort: false,
                  },
                  { displayName: "Location", key: "location", isSort: false },
                  {
                    displayName: "User's Name",
                    key: "user",
                    isSort: false,
                  },{
                    displayName: "Description",
                    key: "description",
                    isSort: false,
                  },
                  {
                    displayName: "Old Value",
                    key: "oldVal",
                    isSort: false,
                  },{
                    displayName: "New Value",
                    key: "newVal",
                    isSort: false,
                  }                    
                ]}
                tableBodyList={[
                    {
                        "date":"01/12/2020 13:20",
                        "action":"update",
                        "location":"WP (Kaduwela)",
                        "user":"M.L Silva(Operator)",
                        "description":"vehicle information update",
                        "oldVal":"Province : Western",
                        "newVal":"Province : Sabaragamuwa"
                    }
                ]}
                isSetTableFooter={true}
                isSetTableHeader={false}
                isSetAction={false}
              />

            <div className={"fullWidthDiv defaultMarginTop"}>

            <FormWrapper
                setFormObject={{}}
                setGroupName={formKey}
             >

                <div className="row">
                <div className={"col-md-3"}>

                    <SelectBoxWithState
                        formGroupName={formKey}
                        labelText={"Export To:"}
                        dataList={[
                            {
                                "id":"1",
                                "value":"Excel"
                            },{
                                "id":"2",
                                "value":"PDF"
                            }
                        ]}
                    />

                </div>
                <div className={"col-md-3 defaultPaddingTop"}>
                    <InputButton
                        btnName="submit"
                        btnText={"Export"}
                    />
                </div>
                 
                </div>
                

             </FormWrapper>
                
            </div>

        </div>
    )
}

const ViewDocuments=()=>{
    const formKey="view_documents";
    const [getState,setState]=useState({
        "docList":[
            {
               "no":"00001", 
               "dateTime":"07/01/2016 12:30",
               "uploadedBy":"L.M Peiris(Operator)-WP(Kaduwela)",
               "docDescription":"registration document"
            },{
                "no":"00002", 
                "dateTime":"06/01/2015 14:30",
                "uploadedBy":"L.M Gunwardana(Operator)-WP(Kaduwela)",
                "docDescription":"registration document"
             }
        ]
    });
    return(
        <div className={"fullWidthDiv"}>
             <FormWrapper
                setFormObject={{}}
                setGroupName={formKey}
             >

                <div className={"col-md-12 defaultMarginBottom floatLeft"}>
                    <div className={"row"}>
                        <strong>Upload Documents : </strong>  
                            <InputButton
                                elementWrapperStyle={'defaultPaddingLeftRight'}
                                btnName="submit"
                                btnText={"Choose File"}
                            />
                    </div>   
                </div>  

                <InputBoxWithState
                  isMultiline={true}
                  rows={2}
                  labelText={"Document Description"}
                  formGroupName={formKey}
                  inputName={"docDescription"}
                  isRequired={true}
                />

            <div className={"fullWidthDiv text-right"}>        
                <InputButton
                    btnName="submit"
                    btnText={"Add"}
                    onClickBtnFn={()=>{

                        getState.docList.push({
                            "no":"00001", 
                            "dateTime":"07-01-2016 12:30",
                            "uploadedBy":"Saman Perera(Operator)-WP(Kaduwela)",
                            "docDescription":"registration document"
                         });

                        setState({
                        ...getState,
                            docList:[...getState.docList]
                        });
                    }
                    }
                />      
             </div>    
             </FormWrapper>

             <br/>

             <DataTable
                dataTableKey={"docView"}
                elementWrapperStyle={"defaultMarginTop"}
                tableHeaderList={[
                  { displayName: "No:", key: "no", isSort: false },
                  {
                    displayName: "Uploaded Date & Time",
                    key: "dateTime",
                    isSort: false,
                  },
                  { displayName: "Uploaded By", key: "uploadedBy", isSort: false },
                  {
                    displayName: "Document Description",
                    key: "docDescription",
                    isSort: false,
                  }                  
                ]}
                tableBodyList={getState.docList}
                isSetTableFooter={true}
                isSetTableHeader={false}
                actionTypeList={[
                  {
                    actionType: actionBtnTypes.view,
                    actionBtn:{
                        icon:"mdi mdi-file-download-outline",
                        tooltip:"Download",
                    },                   
                  },
                  {
                    actionType: actionBtnTypes.view,
                    actionBtn:{
                        icon:"mdi mdi-trash-can-outline",
                        tooltip:"Remove",
                        onClickBtnFn:()=>{
                            getState.docList.pop();
                            setState({
                                ...getState,
                                docList:[...getState.docList]
                            })
                        }
                    },                   
                  },
                ]}
              />

        </div>
    )
}

 
const Footer=({
    currentTab="tab1",
    formChild={
        issueMode:"1",
        isPendingWorkFlow:false,
        payArrearsOnly:true,
    }
})=>{

    const [getState,setState]=useState({
        "status":false,
        "modelType":"",
    });

    const btnName=(currentTab)=>{
        if(currentTab==="tab1"){
            if(_get(formChild,'issueMode','1')==="1"){
                return "Issue RL";
            }else{
                return "Issue Temp";
            }
            
        }else if(currentTab==="tab2"){
            return "Issue";
        }else if(currentTab==="tab5"){
            return "Print";
        }else if(currentTab==="tab4"){
            return "Issue Carnet";
        }else {
            return "Generate Quotation";
        }
    }

    const getModelName=()=>{
        if(getState.modelType==="payment"){
            return "Make Payment";
        }else if(getState.modelType==="payment-arrears"){
            return "Pay Arrears Only";
        }else if(getState.modelType==="viewDocuments"){
            return "View & Upload Documents";
        }else if(getState.modelType==="activityLog"){
            return "Activity Log";
        } else if (getState.modelType==="quotationReport"){
            return "Revenue Licence Quotation";
        }
    }

    const getModel=()=>{
        if(getState.modelType==="payment"){

            let type="normal";
            if(currentTab==="tab2"){
                type="dealer";
            }else if(currentTab==="tab4"){
                type="carnet";
            }else if(currentTab==="tab3"){
                type="quotation";
            }

            return <IssueTemp uiType={type} formGroupName={"form"}/> ;
        }else if(getState.modelType==="payment-arrears"){
            return <PaymentUI uiType={"arrears"}/>;
        }else if(getState.modelType==="viewDocuments"){
            return <ViewDocuments/>;
        }else if(getState.modelType==="activityLog"){
            return <ActivityLog/>;
        }else if(getState.modelType==="quotationReport"){
            return <RevenueLicenseQuotation/>;
        }
    }

    return(
        <div className="row">
            <div className="col-md-6">
            {
                    (currentTab==="tab1")?(
                        <Fragment>
                            <InputButton
                                elementWrapperStyle={'defaultHalfMarginLeftRight'}
                                btnName="submit"
                                btnText={"Activity Log"}
                                onClickBtnFn={()=>setState({
                                    ...getState,
                                    status:true,
                                    modelType:"activityLog"
                                })}
                            />
                            <InputButton
                                elementWrapperStyle={'defaultHalfMarginLeftRight'}
                                btnName="submit"
                                btnText={"View/Upload Documents"}
                                onClickBtnFn={()=>setState({
                                    ...getState,
                                    status:true,
                                    modelType:"viewDocuments"
                                })}
                            />
                        </Fragment>
                    ):(null)
            }
            </div>
            <div className="col-md-6 text-right">
                {
                    ((currentTab==="tab1") && (_get(formChild,'isPendingWorkFlow',false)===false) && (_get(formChild,'payArrearsOnly',true)===true))?(
                        <InputButton
                            elementWrapperStyle={'defaultHalfMarginLeftRight'}
                            btnName="submit"
                            btnText={"Pay Arrears Only"}
                            onClickBtnFn={()=>setState({
                                ...getState,
                                status:true,
                                modelType:"payment-arrears"
                            })}
                        />
                    ):(null)
                }
                
                {
                    (_get(formChild,'isPendingWorkFlow',false)===false)?(
                        <InputButton
                            elementWrapperStyle={'defaultHalfMarginLeftRight'}
                            btnName="submit"
                            btnText={btnName(currentTab)}
                            onClickBtnFn={()=>setState({
                                ...getState,
                                status:true,
                                modelType:currentTab!=="tab3"?"payment":"quotationReport"
                            })}
                        />
                    ):(null)
                }
                


                <InputButton
                    elementWrapperStyle={'defaultHalfMarginLeftRight'}
                    btnName="submit"
                    btnText={"Cancel"}
                />
            </div>
            <DialogPopup
                isOpen={getState.status}
                isFullScreen={getState.modelType==="viewDocuments"?false:true}
                dialogTitle={getModelName()}
                onClosePopupFn={()=>setState({
                    ...getState,
                    status:false,
                    modelType:""
                })}
            >
                {getModel()}
            </DialogPopup>
        </div>
    )
}

 const TestUI=()=>{
     const [getState,setState]=useState({
       "currentTab":"tab1",
       "isShowFooter":false,  
       "mergeWithPerent":{}
     });

     const setShowHideFooterFN=(status=true,mergeWithPerent={})=>{
        setState({
            ...getState,
            isShowFooter:status,
            mergeWithPerent:mergeWithPerent
        });
     }

     return (
         <CounterTemplate
            FooterComponent={Footer}
            propsToFooter={{
                "currentTab":getState.currentTab,
                "formChild":getState.mergeWithPerent
            }}
            isShowFooter={getState.isShowFooter}            
         >
            <TabsWrapper
                onChangeFn={(event)=>setState({
                    ...getState,
                    "currentTab":event.value,
                    "isShowFooter":event.value==="tab5"?true:false
                })}
                active={getState.currentTab}
                variant={tabsWrapperVariants.scrollable}
                scrollButtons={tabsWrapperScrollButtons.on}
                elementWrapperStyle={"counterTab"}
            >
                <SubTab tabid={"tab1"} header={"Normal/Non-User/Free Licence"}>
                    <NormalLicense setShowHideFooterFN={(status,mergeWithPerent)=>setShowHideFooterFN(status,mergeWithPerent)} />
                </SubTab>  
                <SubTab tabid={"tab2"} header={"Dealer Licence"}>
                    <DealerLicense setShowHideFooterFN={(status,mergeWithPerent)=>setShowHideFooterFN(status,mergeWithPerent)}/>
                </SubTab> 
                <SubTab tabid={"tab4"} header={"Carnet"}>
                    <Carnet setShowHideFooterFN={(status,mergeWithPerent)=>setShowHideFooterFN(status,mergeWithPerent)} />
                </SubTab> 
                <SubTab tabid={"tab5"} header={"Web Licence"}>
                    <WebLicence/>
                </SubTab> 
                <SubTab tabid={"tab3"} header={"Quotation"}>
                    <QuotationSRS setShowHideFooterFN={(status,mergeWithPerent)=>setShowHideFooterFN(status,mergeWithPerent)} />
                </SubTab>                 
            </TabsWrapper>
         </CounterTemplate>
     )
 }

 export default TestUI;