/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-10-10 06:36:39
 */
import React, { Fragment, useState, useEffect, useRef, useContext } from "react";

import { UICard, CircleLoaderElement } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { CheckBox, InputButton } from "../../../ui-components/ui-elements/form";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { notificationsAPI } from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { fromObjectToQueryString } from "../../../../helpers/common-helpers/common.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { useHistory } from 'react-router-dom';
import { NotificationTypesList } from "../../../../config/enum.config";

const Notification = () => {
  return (
      <DashboardTemplate
          pageTitle="Notifications"
          breadCrumbList={[
            { urlTo: "/", displayName: "Home" },
            { urlTo: "", displayName: "Notification" },
          ]}
      >
        <NotificationUI />
      </DashboardTemplate>
  );
};



const NotificationUI = () => {
  const [authState] = useContext(AuthContext);
  const [uiState, uiAction] = useContext(UIContext);
  const [getState, setState] = useState({
    "unread": true,
    "read": true,
    initialRender: true,
    showInitialLoader: true,
    showLoader: true,
    showHideModel: false,
    modelData: {},
    dataList: [],
    meta: {}
  });

  const scrollDiv = useRef(null);
  const stateRef = useRef(getState);

  const trackScrolling = (event) => {
    if(
        (scrollDiv.current.getBoundingClientRect().bottom <= window.innerHeight) &&
        stateRef.current.showLoader === false &&
        stateRef.current.showInitialLoader === false &&
        Object.keys(stateRef.current.meta).length > 0
    ) {

      if (parseInt(stateRef.current.meta.current_page) < parseInt(stateRef.current.meta.total_pages)) {
        setState(prevState => {
          stateRef.current = {
            ...prevState,
            showLoader: true
          };

          return {
            ...prevState,
            showLoader: true
          }
        });
        
        setTimeout(() => getNotifications(parseInt(stateRef.current.meta.current_page) + 1), 1000);
      }
    }
  };

  const getNotifications = (page= 1, isAppend = true) => {
    // console.log('OOOOOOPPPP===', authState);
    const queryStringObj = {
      // read: false,
      read: page > 1 ? ((stateRef.current.unread && stateRef.current.read) ? undefined : stateRef.current.read ? true : false) : ((getState.unread && getState.read) ? undefined : getState.read ? true : false),
      pdmt_service_id: _get(authState, "authUser.role_category_code", undefined) !== undefined && 
      (_get(authState, "authUser.role_category_code", undefined) === "SUP" || _get(authState, "authUser.role_category_code", undefined) === "COM") ? _get(authState, "authUser.service_id", undefined) : _get(authState, "authUser.pdmtId.service.id", undefined),
      // authority_service_id
      user_id: _get(authState, "authUser.id", undefined),
      role_category_code: _get(authState, "authUser.role_category_code", undefined),
      current_role_category: _get(authState, "authUser.role_category_code", undefined),

    };
    console.log('QUERY-->', stateRef.current, getState.unread, getState.read, getState);
    // const queryString = (getState.unread && getState.read) ? "" : getState.read ? "&read=true" : "&read=false";
    callApi(`${notificationsAPI.url}?page=${page}&${fromObjectToQueryString(queryStringObj)}`)
        .method("get")
        .headers(true)
        .isMultipart(false)
        .send((error, result) => {
          if (!error) {
            
            setState(prevState => {
              stateRef.current = {
                ...prevState,
                "unread": prevState.unread,
                "read": prevState.read,
                dataList: isAppend ? [...prevState.dataList, ...result.data.data] : result.data.data,
                meta: result.data.meta,
                showInitialLoader: false,
                showLoader: false,
                initialRender: false
              };

              return {
                ...prevState,
                "unread": prevState.unread,
                "read": prevState.read,
                dataList: isAppend ? [...prevState.dataList, ...result.data.data] : result.data.data,
                meta: result.data.meta,
                showInitialLoader: false,
                showLoader: false,
                initialRender: false
              }
            });
          } else {
            setState(prevState => {
              stateRef.current = {
                ...prevState,
                showInitialLoader: false,
                showLoader: false,
                initialRender: false
              };

              return {
                ...prevState,
                showInitialLoader: false,
                showLoader: false,
                initialRender: false
              }
            });
          }
        });
  }

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    getNotifications();

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  },[]);

  useEffect(() => {
    if (!getState.initialRender) {
      if (!getState.read && !getState.unread) {
        setState(prevState => {
          stateRef.current = {
            ...prevState,
            dataList: [],
            meta: {},
            modelData: {},
            showInitialLoader: false
          };

          return {
            ...prevState,
            dataList: [],
            meta: {},
            modelData: {},
            showInitialLoader: false
          }
        });
      } else {
        getNotifications(1, false);
      }
    }

  }, [getState.read, getState.unread]);

  const marksAsRead = (index) => {
    // Update local state
    let dataList = [...getState.dataList];
    dataList[index]["read"] = 1;

    // Decrement notification count by one
    uiAction.decrementNotificationCountFn();

    // Call BE API
    callApi(`${notificationsAPI.url}/${dataList[index]["id"]}`)
        .method("put")
        .headers(true)
        .body({})
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            // console.log('addERRRR==', error);
            uiAction.incrementNotificationCountFn();
          }
        });
  };

  const getNotificationType = (type) => {
    switch (type) {
        case NotificationTypesList.Role_Change:
            return "Role Change";
        case NotificationTypesList.Serial_number_Change:
            return "Serial Number Change";
        case NotificationTypesList.Approval_Requests:
            return "Approval Requests";
        case NotificationTypesList.ReOrder_Level_Reached:
            return "Re-Order Level Reached";
        case NotificationTypesList.Switch_Delegation:
              return "Switch Delegation";
        default:
            return "-";
    }
  };
  return (
      <div className="fullWidthDiv notificationMainWrapper" ref={scrollDiv}>
        <UICard elementStyle={"notificationFrom"}>
          <CheckBox
              labelText={"Unread"}
              inputValue={getState.unread}
              onChangeFn={(eventObj) => setState({
                ...getState,
                unread: eventObj.value,
                showInitialLoader: true
              })}
          />

          <CheckBox
              labelText={"Read"}
              inputValue={getState.read}
              onChangeFn={(eventObj) => setState({
                ...getState,
                read: eventObj.value,
                showInitialLoader: true
              })}
          />
        </UICard>

        {
          (getState.showInitialLoader) ? (
              <div className="fullWidthDiv">
                <center>
                  <CircleLoaderElement />
                </center>
              </div>
          ) : (
              <Fragment>
                {
                  (getState.dataList.length === 0) ? (
                      <UICard>
                        <div className="fullWidthDiv defaultMarginTopBottom">
                          No notification(s) found.
                        </div>
                      </UICard>
                  ) : (
                      <Fragment>
                        {
                          getState.dataList.map((data, index) => {
                            return (
                                <UICard
                                    key={index}
                                    elementStyle={`notificationWrapper ${data.read === 0 ? "new" : "seen" }`}
                                    onClickFn={() => {
                                      if (data.read === 0) {
                                        marksAsRead(index);
                                      }

                                      setState({
                                        ...getState,
                                        showHideModel: true,
                                        modelData: data
                                      })
                                    }}
                                >
                                  <div className="notificationContentWrapper">
                                    <h1 className="defaultHeading">{data.heading}</h1>
                                    <p>{data.subject}</p>
                                  </div>

                                  <div className="notificationTimeWrapper">
                                    <i className="fas fa-circle newIcon"></i>
                                    <p>{dateObjectToString(data.created_at, "dd/mm/YY HH:MM")}</p>
                                  </div>
                                </UICard>
                            )
                          })
                        }

                        <div className="fullWidthDiv footerWrapper ">
                          <center>
                            {
                              (getState.showLoader) ? <CircleLoaderElement /> : null
                            }
                          </center>
                        </div>

                        <DialogPopup
                            isOpen={getState.showHideModel}
                            onClosePopupFn={() => setState({
                              ...getState,
                              showHideModel: false
                            })}
                            dialogTitle={"View Notification Information"}
                            isCustomButton={_get(getState, "modelData.notification_type_code", "") === 'T03' && 
                            (_get(getState, "modelData.content", "").split(' ').includes('Rejected') === true || _get(getState, "modelData.content", "").split(' ').includes('Approved') === true) ? false : 
                            _get(getState, "modelData.notification_type_code", "") !== 'T03' ? false : true}
                            customButtonComponent={ViewButton}
                            customButtonComponentProps={_get(getState, "modelData", "")}
                            isCloseButton={true}
                            isRemoveCancel={true}
                            // closeButton={{
                            //   btnText: "Close",
                            //   startIcon: "far fa-times-circle"
                            // }}
                            >
                          <div className="fullWidthDiv">
                            <dl className="row">
                              <dt className="col-sm-2">Type</dt>
                              <dd className="col-sm-10">
                                {/* {_get(getState, "modelData.notification_type_code", "") === 'T03' ? 'Approval Requests' : (_get(getState, "modelData.notification_type_code", "") === 'T02') ? 'Serial Number Change' : (_get(getState, "modelData.notification_type_code", "") === 'T01') ? 'Role Change'  :'-'} */
                                  getNotificationType(_get(getState, "modelData.notification_type_code", ""))                               
                                }
                              </dd>

                              <dt className="col-sm-2">Description</dt>
                              <dd className="col-sm-10">{_get(getState, "modelData.content", "")}</dd>

                              <dt className="col-sm-2">Received at</dt>
                              <dd className="col-sm-10">{dateObjectToString(_get(getState, "modelData.created_at", ""), "dd/mm/YY HH:MM")}</dd>
                            </dl>
                          </div>
                        </DialogPopup>
                      </Fragment>
                  )
                }
              </Fragment>
          )
        }
      </div>
  )
};

const ViewButton = (props) => {
  console.log('PLP--', props);
  const history = useHistory()
  return <InputButton onClickBtnFn={() => history.push({ 
    pathname: '/all-tasks',
    state: {request_id: props.request_id}
   })} btnText="View Task" btnName="View" startIcon="far fa-eye" />;
};

export {
  Notification,
  NotificationUI
};
