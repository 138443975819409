/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-11-19 17:50:56
 */

import React, { useState, Fragment } from "react";

import {
  FormWrapper,
  InputBoxWithState,
  InputButton,
  CheckBoxWithState,
  RoundButton,
  roundBtnSizes,
  SelectBoxWithState,
  checkBoxLabelPlacement,
} from "../../../../ui-components/ui-elements/form/index";
import {ViewTransaction} from "./NormalLicense";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DataTable,actionBtnTypes } from "../../../../ui-components/ui-elements/table/DataTable";
import { RoundButtonWithPopups } from "../../../../ui-components/ui-elements/form/Button";
import { dealerLicenseDummy } from "../../../../../config/dummyData.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {RequestFor} from "./NormalLicense";

const emptyFun = (...para) => undefined;

const CommentsHistory = () => {
  return (
    <div className="commentHistoryWrapper">
      <div className="commentElementWrapper">
        <h1 className="defaultHeading">
          <strong>Roshan Liyanage</strong> 2020-12-03:00:00:23
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec
        </p>
      </div>
      <div className="commentElementWrapper">
        <h1 className="defaultHeading">
          <strong>Roshan Liyanage</strong> 2020-12-03:00:00:23
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec
        </p>
      </div>
      <div className="commentElementWrapper">
        <h1 className="defaultHeading">
          <strong>Roshan Liyanage</strong> 2020-12-03:00:00:23
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec
        </p>
      </div>
    </div>
  );
};

const LicenseArrearsIcons = ({ uiType = null, formGroupName = null }) => {
  return (
    <Fragment>
      <RoundButtonWithPopups
        btnName="viewmore"
        iconClass={"mdi mdi-magnify-plus-outline"}
        btnSize={roundBtnSizes.small}
        dialogProps={{
          dialogTitle: `Comments `,
        }}
        modelComponent={CommentsHistory}
        propsToModel={{}}
      />
    </Fragment>
  );
};

const DealerLicense = ({ setShowHideFooterFN = emptyFun }) => {
  const [getState, setState] = useState({
    searchKey: "",
    currentObject: null,
  });

  return (
    <div className="normalLicenseWrapper dealerLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper">
          <FormWrapper elementStyle={""} setGroupName={"searchFromKey"}>
            <div className="searchInputWrapper">
              <label className="control-labe">Dealer ID:</label>
              <input
                type="text"
                className="form-control"
                value={getState.searchKey}
                onChange={(event) =>
                  setState({
                    ...getState,
                    searchKey: event.target.value,
                  })
                }
              />
              <InputButton
                btnName="search"
                overrideTextToIcon={
                  <i className="mdi mdi-magnify-plus-outline" />
                }
                onClickBtnFn={() => {
                  if (
                    dealerLicenseDummy.hasOwnProperty(
                      getState.searchKey.toLowerCase()
                    )
                  ) {
                    setState({
                      ...getState,
                      currentObject: _get(
                        dealerLicenseDummy,
                        getState.searchKey,
                        {}
                      ),
                    });
                    setShowHideFooterFN(true);
                  } else {
                    setState({
                      ...getState,
                      currentObject: "none",
                    });
                    setShowHideFooterFN(false);
                  }
                }}
              />
            </div>
          </FormWrapper>
        </div>
        {/* <div className="statusWrapper">

                {
                  (getState.currentObject === null ? null : getState.currentObject ===
                    "none" )?(null):(
                      <ul className="statusUL">
                        <li>
                          <RequestFor/>
                        </li>
                      </ul>
                    )
                }

          
        </div> */}
      </div>

      {getState.currentObject === null ? null : getState.currentObject ===
        "none" ? (
        <div className="col-md-12 defaultPaddingTop floatLeft">
          No result found
        </div>
      ) : (
        <FormWrapper
          setGroupName={"dealerLicense"}
          elementStyle={"normalLicenceWrapper"}
        >

        <div className="col-md-6 floatLeft">

          <div className="fullWidthDiv">
            <PaddingLessUICard cardHeading="Dealer's Information">
              <div className="row">
                <div className="col-md-12 defaultHalfMarginBottom">
                  <strong>Name of Dealer/ Manufacturer/ Repairer: </strong>
                  {_get(getState.currentObject, `ownerInformation.name`, "-")}
                </div>

                <div className="col-md-12 defaultHalfMarginBottom">
                  <strong> Dealer’s Office: </strong>
                  <hr className="marginTopNone defaultHalfMarginBottom" />
                  <div className="row">
                    <div className="col-md-6">
                      <strong>Address 1: </strong> {_get(getState.currentObject, `ownerInformation.officeAddress1`, "-")}
                    </div>
                    <div className="col-md-6">
                      <strong>Address 2: </strong> {_get(getState.currentObject, `ownerInformation.officeAddress2`, "-")}
                    </div>
                    <div className="col-md-6">
                      <strong>City: </strong> {_get(getState.currentObject, `ownerInformation.officeCity`, "-")}
                    </div>
                    <div className="col-md-6">
                      <strong>District: </strong> {_get(getState.currentObject, `ownerInformation.officeDistrict`, "-")}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 defaultHalfMarginBottom">
                  <strong> Garage Address: </strong>
                  <hr className="marginTopNone defaultHalfMarginBottom" />
                  <div className="row">
                    <div className="col-md-6">
                      <strong>Address 1: </strong> {_get(getState.currentObject, `ownerInformation.garageAddress1`, "-")}
                    </div>
                    <div className="col-md-6">
                      <strong>Address 2: </strong> {_get(getState.currentObject, `ownerInformation.garageAddress2`, "-")}
                    </div>
                    <div className="col-md-6">
                      <strong>City: </strong> {_get(getState.currentObject, `ownerInformation.garageCity`, "-")}
                    </div>
                    <div className="col-md-6">
                      <strong>District: </strong> {_get(getState.currentObject, `ownerInformation.garageDistrict`, "-")}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 defaultHalfMarginBottom">
                  <strong>Province: </strong> {_get(getState.currentObject, `ownerInformation.province`, "-")}
                </div>
              </div>
            </PaddingLessUICard>
          </div>

          <div className="fullWidthDiv  ">
            <PaddingLessUICard cardHeading="Payment Hi">
              <DataTable
                tableHeaderList={[
                  { displayName: "Tx. No", key: "txNo", isSort: false },
                  {
                    displayName: "Tx. Date & Time:",
                    key: "txDate",
                    isSort: false,
                  },
                  {
                    displayName: "Total Amount",
                    key: "totalAmount",
                    isSort: false,
                  },
                  {
                    displayName: "Province",
                    key: "province",
                    isSort: false,
                  },
                  { displayName: "Authority", key: "authority", isSort: false },
                ]}
                tableBodyList={_get(getState.currentObject, `paymentHistory`, [])}
                isSetTableFooter={true}
                isSetTableHeader={false}
                actionTypeList={[ 
                  {
                      actionType: actionBtnTypes.view,   
                      modelContentElement:ViewTransaction ,
                      dialogPopupProps:{
                        "isFullScreen":false,
                        "dialogTitle":"View Licence Transaction Details",
                        "isCustomButton":false,
                        closeButton: {
                          btnText: "Close",
                          startIcon: "far fa-times-circle",
                        },      
                      }
                  }                       
                ]}
              />
            </PaddingLessUICard>
          </div>

          <div className="fullWidthDiv defaultMarginTop">
            <PaddingLessUICard
              cardHeading="Comments"
              passPropsToCardHeadingTail={{
                uiType: "comments",
                formGroupName: "normalLicence",
              }}
              cardHeadingTail={LicenseArrearsIcons}
            >
              <div className="row">
                <div className="col-md-12">
                  <InputBoxWithState
                    isMultiline={true}
                    rows={1}
                    labelText={"Comments"}
                    formGroupName={"normalLicence"}
                    inputName={"comments"}
                  />
                </div>
                <div className="col-md-12 text-right">
                  <InputButton
                    elementWrapperStyle={"defaultHalfMarginLeftRight"}
                    btnText="Save"
                  />
                  <InputButton
                    elementWrapperStyle={"defaultHalfMarginLeftRight"}
                    btnText="Cancel"
                  />
                </div>
              </div>
            </PaddingLessUICard>
          </div>
          
        </div>

        <div className="col-md-6 floatRight">

          


          <div className="fullWidthDiv defaultMarginTop">
            <PaddingLessUICard cardHeading="Pending Licence">
              <DataTable
                elementWrapperStyle={"scrolTable"}
                tableHeaderList={[
                  {
                    displayName: "Authorised Vehicle no",
                    key: "vahicleNo",
                    isSort: false,
                  },
                  {
                    displayName: "Validity Period (From -To)",
                    key: "formTo",
                    isSort: false,
                  },
                  {
                    displayName: "Insurance Company",
                    key: "insComapny",
                    isSort: false,
                  },
                  {
                    displayName: "Insurance Expiry Date",
                    key: "insDate",
                    isSort: false,
                  },
                  { displayName: "Request ID", key: "reqID", isSort: false },
                  { displayName: "Requested by", key: "reqBY", isSort: false },
                  {
                    displayName: "Requested Location",
                    key: "reqLoc",
                    isSort: false,
                  },
                ]}
                tableBodyList={_get(getState.currentObject, `pendingLicense`, [])}
                isSetTableFooter={true}
                isSetTableHeader={false}
                isSetAction={false}
              />
            </PaddingLessUICard>
          </div>

          <div className="fullWidthDiv defaultMarginTop">
            <PaddingLessUICard cardHeading="Current Valid Licences Information">
              <DataTable
                elementWrapperStyle={"scrolTable"}
                tableHeaderList={[
                  {
                    displayName: "Authorised Vehicle no",
                    key: "vahicleNo",
                    isSort: false,
                  },
                  { displayName: "Lic No:", key: "licNo", isSort: false },
                  { displayName: "Serial No", key: "serial", isSort: false },
                  {
                    displayName: "Validity Period (From -To)",
                    key: "formTo",
                    isSort: false,
                  },
                  {
                    displayName: "Insurance Company",
                    key: "insComapny",
                    isSort: false,
                  },
                  {
                    displayName: "Insurance Expiry Date",
                    key: "insDate",
                    isSort: false,
                  },
                ]}
                tableBodyList={_get(getState.currentObject, `currentLicences`, [])}
                isSetTableFooter={true}
                isSetTableHeader={false}
                actionTypeList={[                  
                  {
                      actionType: actionBtnTypes.view, 
                      actionBtn:{
                          "icon":"mdi mdi-printer-settings"
                      }                 
                  },
                ]} 
              />
            </PaddingLessUICard>
          </div>


          <div className="fullWidthDiv defaultMarginTop">
            <PaddingLessUICard cardHeading="Licence Amount">
              <table style={{ width: "100%" }}>
                <tr>
                  <th>Fee (LKR)</th>
                  <td className="text-right">{_get(getState.currentObject, `licenceAmount.fee`, "00.00")}</td>
                </tr>
                <tr>
                  <th>Other Charges (LKR)</th>
                  <td className="text-right">{_get(getState.currentObject, `licenceAmount.otherCharges`, "00.00")}</td>
                </tr>
                <tr>
                  <th>Total Amount (LKR)</th>
                  <td className="text-right">
                    <b>{_get(getState.currentObject, `licenceAmount.totalAmount`, "00.00")}</b>
                  </td>
                </tr>
              </table>
            </PaddingLessUICard>
          </div>

          
        </div>

          
        </FormWrapper>
      )}
    </div>
  );
};

export { DealerLicense };
