/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-12 13:59:59 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-29 11:52:57
 */
import { isEmpty } from 'lodash';
import React, { useContext, Fragment, useEffect } from 'react'
import { carnetCompletePaymentAndPrintAPI, getLicenseReceiptPrintDetailsAPI, createLicenceArrearsOnlyAPI, paymentCompleteArrearsOnlyAPI, payAndPrintLicence, availableCounterListApi } from '../../../../../config/apiUrl.config';
import { getDataByFormObject, isEmptyValue } from '../../../../../helpers/common-helpers/common.helpers';
import { dateObjectToString } from '../../../../../helpers/common-helpers/dateTime.helpers';
import { _get, _unset } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { InputButton, SubmitButton } from '../../../../ui-components/ui-elements/form';
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { PayArrearsOnlyBtn } from '../PayArrearsOnly';
import { useReceiptPrint } from '../../../../hooks/common-hooks/usePrinter.hook';
import {
  apiResponseStoringType,
  CoreContext,
} from "../../../core/context-providers/CoreContext.provider";
import { callApi } from '../../../../../helpers/common-helpers/callApi.helpers';
import { UIContext } from '../../../../ui-components/context-providers/UIContext.provider';
import { PaymentCategoriesEnum } from '../../../../../config/enum.config';
import LicenceReceiptPrint from './LicenceReceiptPrint';

const emptyFun = (...para) => undefined;
// const [, uiAction] = useContext(UIContext);
const ConfirmationPopup = ({
  closePopupFn = emptyFun,
  closeConfirmationPopupFn = emptyFun,
  onClickSuccessFn = emptyFun,
  openPopupFn = emptyFun,
  uiType = "normal",
  receipt = "print",
  isTempLicence = false,
  confirmationMsg = "Are you sure you want to Complete Payment?"
}) => {


  return (
    <div className="row">
      <div className="col-md-12">
        <strong>{confirmationMsg}</strong>
      </div>
      <div className="col-md-12 text-right defaultMarginTop">
        {
          uiType === 'arrears-temp-licence' ?
            <PayArrearsOnlyBtn
              btnText={"Yes"}
              elementWrapperStyle={"defaultHalfMarginLeftRight"}
              closePopupFn={closeConfirmationPopupFn}
              onClickSuccessFn={onClickSuccessFn}
              openPopupFn={openPopupFn}
              uiType={uiType} />
            : uiType === 'arrears' ?
              <CompleteArrearsOnlyBtn closePopupFn={closePopupFn} onClickSuccessFn={onClickSuccessFn} uiType={uiType} receipt={receipt} />
              :
              <PayBtn isTempLicence={isTempLicence} closePopupFn={closePopupFn} onClickSuccessFn={onClickSuccessFn} uiType={uiType} />
        }
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="No"
          onClickBtnFn={
            uiType === 'arrears-temp-licence' || uiType === 'arrears' ?
              closePopupFn : closePopupFn}
          startIcon={"fas fa-times"}
        />
      </div>
    </div>
  );
}

const onpreview = (printFrame) => {
  let printContents = document.getElementById(printFrame).innerHTML;
  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  window.location.reload();
  document.body.innerHTML = originalContents;
  window.close()

}
const PayBtn = (props) => {
  const formGroupName = "printReceipt";
  const uiType = props.uiType;
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  // const [coreState, coreAction] = useContext(CoreContext);
  const [, uiAction] = useContext(UIContext);
  const authority_id = _get(authStatus, "authUser.authorityId", "");
  // const { printFn } = useReceiptPrint();
  const paid_types = [];
  const serial_number = uiType === 'normal' ?
    _get(formState, "issueTemp.next_serial", null) : uiType === 'carnet' ?
      _get(formState, "issueCarnet.next_serial", null) : null;
  const counter_id = uiType === 'normal' ?
    _get(formState, "issueTemp.counter_id", null) : uiType === 'carnet' ?
      _get(formState, "issueCarnet.counter_id", null) : null;
  const counter_code = uiType === 'normal' ?
    _get(formState, "issueTemp.counter_code", null) : uiType === 'carnet' ?
      _get(formState, "issueCarnet.counter_code", null) : null;
  const counter_session_id = uiType === 'normal' ?
    _get(formState, "issueTemp.counter_session_id", null) : uiType === 'carnet' ?
      _get(formState, "issueCarnet.counter_session_id", null) : null;
  const counter_serial_number_range_id = uiType === 'normal' ?
    _get(formState, "issueTemp.counter_serial_number_range_id", null) : uiType === 'carnet' ?
      _get(formState, "issueCarnet.counter_serial_number_range_id", null) : null;

  const paymentFormObj = getDataByFormObject(_get(formState, "paymentTypeFormKey", {}));
  _unset(paymentFormObj, "isDisplay");
  _unset(paymentFormObj, "payment_categories");
  _unset(paymentFormObj, "paid_amount");
  _unset(paymentFormObj, "cash_amount");
  _unset(paymentFormObj, "cheque_amount");
  _unset(paymentFormObj, "card_amount");

  if (!isEmpty(paymentFormObj)) {
    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        const cashBalance = uiType === 'carnet'
          ? parseFloat(_get(formState, "paymentTypeFormKey.paid_amount", 0) - +((_get(formState, "licence_charges_form.total", 0)))).toFixed(2)
          : parseFloat(_get(formState, "paymentTypeFormKey.paid_amount", 0) - _get(formState, "licence_charges_form.total", 0)).toFixed(2);

        const totAmount = _get(paymentFormObj, `[${key}]['payment_type']`, null) === PaymentCategoriesEnum.CASH
          ? parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null)) - cashBalance
          : parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null));

        paid_types.push({
          "payment_type_code": _get(paymentFormObj, `[${key}]['payment_type']`, null),
          "payment_gateway_code": null,
          "amount": totAmount,
          "cus_paid_amount": totAmount,
          "cheque_number": _get(paymentFormObj, `[${key}]['cheque_no']`, null),
          "cheque_date": null,
          "bank_id": _get(paymentFormObj, `[${key}]['bank']`, null),
          "card_type": null,
          "card_holder_name": null,
          "request_ref_no": _get(paymentFormObj, `[${key}]['reference_no']`, null),
          "payment_ref": null,
          "ipg_ref": null,
          "phone_no": null,
          "approval_code": _get(paymentFormObj, `[${key}]['approval_no']`, null),
          "note": null,
          "request_str": null,
          "response_str": null,
          "success": true,
          "legacy_cheque_id": null
        });
      }
    });
  }

  const licenceTypeCode = _get(formState, "vehicle_search_form.data.current_license_type.code", "");
  const licenceEndType = _get(formState, "licence_validity_period.endDate", 0);
  const licenceValidityPeriodEndDate = _get(formState, `licence_validity_period.endDatesData[${licenceEndType}].value`, null);

  const getLicenceValidityPeriod = () => {
    if (_get(formState, "vehicle_search_form.isTempLicence", false)) {
      return `${dateObjectToString(_get(formState, "licence_validity_period.startDate", null), "dd-mm-YY")} To ${dateObjectToString(new Date(_get(formState, `licence_validity_period.tempLicenceEndDate`, null)), "dd-mm-YY")}`
    } else if (_get(formState, "latest_licence_data.data.current_licence_status", false) === 'VA' && (!_get(formState, "latest_licence_data.data.temp_licence", 0) == 1 || _get(formState, "licence_validity_period.isReIssuance", false))) {
      return `${dateObjectToString(_get(formState, `vehicle_search_form.data.last_licence_from`, null), "dd-mm-YY")} To ${dateObjectToString(new Date(_get(formState, `vehicle_search_form.data.last_licence_to`, null)), "dd-mm-YY")}`
    } else
      // return (`${dateObjectToString(_get(formState, "licence_validity_period.startDate", null), "dd-mm-YY")} To ${dateObjectToString((new Date("23/05/2023")), "dd-mm-YY")}`)
      return (`${dateObjectToString(_get(formState, "licence_charges_form.data.valid_from", null), "dd-mm-YY")} To ${dateObjectToString((_get(formState, "licence_charges_form.data.valid_to", null)), "dd-mm-YY")}`)
  }

  return (
    <Fragment>
      <SubmitButton
        btnText={"Yes"}
        startIcon={"fas fa-check"}
        formGroupName={formGroupName}
        isValidate={false}
        flashMessages={{
          success: {
            status: true,
            message: `Payment successfully completed.`,
            messageType: "success",
          },
          // failed: {
          //   status: true,
          //   message: `Payment fail.`,
          //   messageType: "error",
          // },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: uiType === 'carnet' ? carnetCompletePaymentAndPrintAPI.url : payAndPrintLicence.url,
            key: uiType === 'carnet' ? carnetCompletePaymentAndPrintAPI.key : payAndPrintLicence.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {

          let tempReq;
          if (_get(formState, "vehicle_search_form.isTempLicence", false)) {
            tempReq = {
              runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false,
              validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0),
              charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
            }
          }
          return getDataByFormObject({
            vehicle_no: _get(formState, "vehicle_search_form.data.vehicle_no", ""),
            licence_end_type: _get(formState, "licence_validity_period.endDate", 0),
            is_reissuance: _get(formState, "licence_validity_period.isReIssuance", false),
            arrears_only: false,
            paid_types: paid_types,
            temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
            manual_receipt_no: null,
            serial_number: serial_number,
            counter_id: counter_id,
            counter_session_id: counter_session_id,
            authority_id: authority_id,
            counter_serial_number_range_id: counter_serial_number_range_id,
            authority_code: _get(authStatus, 'authUser.authorityCode', ""),
            counter_code: counter_code,
            province_code: _get(authStatus, 'authUser.pdmtId.code', ""),
            requested_licence_type: uiType === 'normal' ? _get(formState, `vehicle_search_form.data.current_license_type.code`, "") : null,
            insurance_status: _get(formState, 'insurance_form.status', null) === "ACT" ? true : null,
            insurance_id: isEmptyValue(_get(formState, 'insurance_form.id', null)) ? null : _get(formState, 'insurance_form.id', null),
            insurance_service_id: _get(formState, 'insurance_form.insurance', null),
            insurance_reference: _get(formState, 'insurance_form.reference', null),
            insurance_expiry_date: _get(formState, 'insurance_form.exp_date', null),

            vet_status: (_get(formState, 'vet_certificate_form.status', null) === true && _get(formState, 'vet_certificate_form.used', null) === false) || (_get(formState, 'vet_certificate_form.vetExpiryDate', null) === null || undefined) ? true : null,
            vet_id: isEmptyValue(_get(formState, 'vet_certificate_form.id', null)) ? null : _get(formState, 'vet_certificate_form.id', null),
            vet_service_id: _get(formState, 'vet_certificate_form.vetCompany', null),
            vet_no: _get(formState, 'vet_certificate_form.vet_no', null),
            vet_expiry_date: _get(formState, 'vet_certificate_form.vetExpiryDate', null) ? dateObjectToString(_get(formState, 'vet_certificate_form.vetExpiryDate', null)) : null,
            vet_issued_date: _get(formState, "vet_certificate_form.vetDateTime", null) ? dateObjectToString(_get(formState, "vet_certificate_form.vetDateTime", null), "YY-mm-dd") : null,

            vft_status: _get(formState, 'vft_certificate_form.type', null) === 'PSS' ? true : _get(formState, 'vft_certificate_form.type', null) === 'FAL' ? false : null,
            vft_id: isEmptyValue(_get(formState, 'vft_certificate_form.id', null)) ? null : _get(formState, 'vft_certificate_form.id', null),
            vft_service_id: _get(formState, 'vft_certificate_form.vft', null),
            vft_no: _get(formState, 'vft_certificate_form.certNo', null),
            vft_expiry_date: _get(formState, 'vft_certificate_form.exp_date', null),
            vft_issued_date: (_get(formState, "vft_certificate_form.issuedDate", null)) ? dateObjectToString(_get(formState, "vft_certificate_form.issuedDate", null), "YY-mm-dd") : null,

            vrp_status: (_get(formState, 'vrp_form.status', null) === "Active" || !isEmptyValue(_get(formState, 'vrp_form.issuedDate', null))) ? true : null,
            vrp_expiry_date: _get(formState, 'vrp_form.expiryDate', null),
            vrp_issued_date: _get(formState, 'vrp_form.issuedDate', null),
            vrp_id: isEmptyValue(_get(formState, 'vrp_form.vrpId', null)) ? null : _get(formState, 'vrp_form.vrpId', null),
            vrp_service_id: _get(formState, 'vrp_form.vrpCompany', null),
            vrp_no: _get(formState, 'vrp_form.vrpNo', null),

            certificate_of_registration: _get(formState, 'normal_licence.isVerifyRegister', false) === true ? 1 : 0,
            free_licence_org: licenceTypeCode === 'FE' ? _get(formState, 'freeLicenceFormkey.requested_by', null) : null,
            free_licence_org_address: licenceTypeCode === 'FE' ? _get(formState, 'freeLicenceFormkey.requested_location', null) : null,
            free_licence_requested_date: licenceTypeCode === 'FE' ? dateObjectToString(_get(formState, "freeLicenceFormkey.requested_date", new Date()), "YY-mm-dd") : null,

            non_user_org_address: licenceTypeCode === 'NU' ? _get(formState, 'nonUserFormkey.issued_location', null) : null,
            non_user_org: licenceTypeCode === 'NU' ? _get(formState, 'nonUserFormkey.issued_by', null) : null,
            non_user_letter_date: licenceTypeCode === 'NU' ? dateObjectToString(_get(formState, "nonUserFormkey.issued_date", new Date()), "YY-mm-dd") : null,

            licence_period_start_date: uiType === 'carnet' ? dateObjectToString(_get(formState, "carnet.fromDate", null), "YY-mm-dd") : null,
            licence_period_end_date: uiType === 'carnet' ? dateObjectToString(_get(formState, "carnet.toDate", null), "YY-mm-dd") : null,
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: "post",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.responseLinkToFrom,
        }}

        onResponseCallBackFn={(error, response) => {
          if (!error) {
            formAction.changeInputFn("print_licences", "data", "", response.data.data.print_licences[0]);

            onpreview("web-print-card")
            uiAction.setFlashMessage({
              status: true,
              message: "Receipt is successfully Printed .",
              messageType: "success"
            });
            if (uiType === 'normal' || uiType === 'carnet') {
              formAction.changeInputFn("licence_charges_form", "data", "", []);
              formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
              formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
              formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", 0);
              formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
              formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
              formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
              formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
              formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
              formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
              formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
              formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
              formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
              formAction.changeInputFn("licence_charges_form", "total", "", 0);
            }
            //printFn(coreAction, response?.data?.data?.print_licences[0]);


            callApi(`${availableCounterListApi.url}/${authority_id}/counters/${counter_id}`)
              .method("get")
              .headers(true)
              .body({})
              .isMultipart(false)
              .send((error, result) => {

                if (!error) {
                  const nxt_serial = _get(result, "data.data.next_serial_number", "") == null ? "" : _get(result, "data.data.next_serial_number", "");
                  formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", nxt_serial);
                }
              });

            //for vehicle Search Refresh
            formAction.changeInputFn("vehicle_search_form", "shouldVehicleRefresh", "", true);
            formAction.changeInputFn("vehicle_search_form", "searchKey", "", "")

            props.closePopupFn();
            props.onClickSuccessFn();
            //update print count

          } else {
            if (_get(error, `data.errors.details[0].message`, null) === 'Create Licence Concurrent Validation Failed.') {
              uiAction.setFlashMessage({
                status: true,
                message: 'Licence has already issued for this Vehicle ID.',
                messageType: "error",
              });
            } else if (_get(error, `data.errors.name`, null) == 'Serial No Issue') {
              uiAction.setFlashMessage({
                status: true,
                message: 'Serial No. Not Available.',
                messageType: "error",
              });
            } else {
              uiAction.setFlashMessage({
                status: true,
                message: 'Payment fail.',
                messageType: "error",
              });
            }
          }

        }}

      />
      <div className='licence-visibility'>

        <div id="web-print-card">
          <LicenceReceiptPrint dataList={_get(formState, "print_licences.data.data", [])} />

        </div>
      </div>
    </Fragment >
  );
};

const CompleteArrearsOnlyBtn = (props) => {
  const uiType = props.uiType;
  const receipt = props.receipt;
  const [formState, formAction] = useContext(FormContext);
  const formGroupName = "printReceipt";
  const [coreState, coreAction] = useContext(CoreContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const { printFn } = useReceiptPrint();
  const licenceId = _get(coreState, `apiResponses.${createLicenceArrearsOnlyAPI.key}.result.licence_data.id`, "");
  useEffect(() => {
    callApi(`${getLicenseReceiptPrintDetailsAPI.url}/${licenceId}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log("result123===>", result)
          formAction.changeInputFn("print_arrears", "data", "", _get(result, "data.data.data", []));
          // printFn(coreAction, result.data.data);
          console.log("arrears==", result)
          console.log("arrears1", _get(result, "data", []))
        }
      });
  }, [])
  return (
    <Fragment>
      <SubmitButton
        btnText={"Yes"}
        startIcon={"fas fa-check"}
        formGroupName={formGroupName}
        isValidate={false}
        flashMessages={{
          success: {
            status: true,
            message: `Payment successfully completed.`,
            messageType: "success",
          },
          failed: {
            status: true,
            message: `Payment fail.`,
            messageType: "error",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: paymentCompleteArrearsOnlyAPI.url,
            key: paymentCompleteArrearsOnlyAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          return getDataByFormObject({
            transaction_id: fromObject.transaction_id,
            txn_no: fromObject.txn_no,
            manual_receipt_no: isEmpty(fromObject.manual_receipt_no) ? null : fromObject.manual_receipt_no,
            authority_id: _get(authStatus, 'authUser.authorityId', ""),
            counter_id: _get(formState, "arrearsFormKey.counter_id", null),
            counter_serial_number_range_id: _get(formState, "arrearsFormKey.counter_serial_number_range_id", null),
            counter_session_id: _get(formState, "arrearsFormKey.counter_session_id", null),
            arrears_only: true,
            print_required: isEmpty(fromObject.manual_receipt_no) ? true : false,
            serial_numbers: null,
            province_code: _get(authStatus, 'authUser.pdmtId.code', ""),
            authority_code: _get(authStatus, 'authUser.authorityCode', ""),
            counter_code: _get(formState, "arrearsFormKey.counter_code", null),
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: "put",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.responseLinkToFrom,
        }}
        onResponseCallBackFn={(error, response) => {
          if (!error) {
            formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
            formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
            formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
            formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
            console.log("printdetail---", response)

            if (receipt !== 'no_print') {
              onpreview("arrears-print-card")
            }

            //for vehicle Search Refresh
            formAction.changeInputFn("vehicle_search_form", "shouldVehicleRefresh", "", true);
            formAction.changeInputFn("vehicle_search_form", "searchKey", "", "")
            props.closePopupFn();
            props.onClickSuccessFn();
          } else {

          }
        }}
      />
      <div className='licence-visibility'>

        <div id="arrears-print-card">
          <div className='arrears-card'>
            <span className="arrear-province1" ></span>
            <span className="arrear-province2"></span>
            <span className="arrear-province3" ></span>
            <span className="receipt-type1"></span>
            <span className="receipt-type2"></span>
            <span className="receipt-type3" ></span>
            <span className="arrear-variable1"></span>
            <span className="arrear-variable2" ></span>
            <span className="arrear-variable3"></span>
            <span className="serial-no"></span>
            <span className="label1" >දාතම/திகதி/Date:</span>
            <span className="label2">{_get(formState, "print_arrears.data.date", "")}</span>
            <span className="label3" >මුදල් භාර ගැනීමේ කුවිතාන්සිය</span>
            <span className="label4">பணம் பெற்றுக்கொண்டதற்குப் பற்றுசீட்டு</span>
            <span className="label5">CASH RECEIPT</span>
            <span className="label6" >මුදල් ගෙවූ අය</span>
            <span className="label7">பணம் கொடுத்தவரின்</span>
            <span className="label8" >பெயர்</span>
            <span className="label9">Received From</span>
            <span className="label10">{_get(formState, "print_arrears.data.owner_name_1", "")}</span>
            <span className="label11" >{_get(formState, "print_arrears.data.owner_name_2", "")}</span>
            <span className="label12">&#125;</span>
            <span className="label13" >භාර ගත් මුදල</span>
            <span className="label14">රුපියල්/ரூபா/The Sum of Rupees</span>
            <span className="label15">{_get(formState, "print_arrears.data.fee_in_text", "")}</span>
            <span className="label16" ></span>
            {/* <span className="label17">ශත/சதம்/Cents</span> */}
            <span className="label18" ></span>
            <span className="label19">பெற்றுக்கொண்ட தொகை</span>
            <span className="label20">The Amount Received</span>
            <span className="label21" >&#125;</span>
            <span className="label22">කාරණය/காரணம்/Reason</span>
            <span className="label23" >{_get(formState, "print_arrears.data.Reason_L1", "")}</span>
            <span className="label24">{_get(formState, "print_arrears.data.Reason_L2", "")}</span>
            <span className="label25"></span>
            <span className="label26" >රු./ரூ./Rs.</span>
            <span className="label27">{_get(formState, "print_arrears.data.fee", "")}</span>
            <span className="label28" >.......................................................................</span>
            <span className="label29">අත්සන සහ තනතුර/கையொப்பமும் தொழிலும்</span>
            <span className="label30">Signature and Designation</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export { ConfirmationPopup, CompleteArrearsOnlyBtn }


const template = <template name="NEW_DEED_REQUEST_PRINT_DETAILS" paper_size="A4" paper_width="300.3" paper_height="152.5" orientaion="portrait" right_margin="0" top_margin="0" left_margin="0" bottom_margin="0" >
  <fonts>
    <font code="F0" name="San Serif" size="10" color="0" Bold="true" Italic="false" Underline="false" StrikeOut="false" />
    <font code="F1" name="San Serif" size="12" color="0" Bold="true" Italic="true" Underline="true" StrikeOut="true" />
    <font code="F2" name="San Serif" size="12" color="0" Bold="true" />
    <font code="F3" name="Consolas" size="14" color="0" Bold="true" />
    <font code="FV11" name="Consolas" size="11" color="0" Bold="false" />
    <font code="FV11B" name="Consolas" size="11" color="0" Bold="true" />
    <font code="F3L" name="Consolas" size="11" color="0" Bold="false" />
    <font code="F4B" name="Consolas" size="10" color="0" Bold="true" />

    <font code="F4" name="San Serif" size="8" color="0" />
    <font code="F5" name="Consolas" size="35" color="0" Bold="true" Italic="false" Underline="false" StrikeOut="false" />
    <font code="FS2" name="Nirmala UI" size="11" color="0" Bold="true" />
    <font code="FS3" name="Nirmala UI" size="10" color="0" />
    <font code="F5S" name="Consolas" size="11" color="0" />
    <font code="F5L" name="Consolas" size="13" color="0" Bold="true" />
    <font code="FC12B" name="Consolas" size="13" color="0" Bold="true" />



    <font code="FC7" name="Consolas" size="7" color="0" />
    <font code="FC8" name="Consolas" size="8" color="0" />




  </fonts>
  <header>

    <label name="request_id" left="130" top="8.8" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" ></label>
    <label name="request_status" left="15.0" top="22.4" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" ></label>
    <label name="request_status" left="15.0" top="32.0" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" ></label>





    <line name="LINE3" start_x="150.8" start_y="2.1" end_x="150.8" end_y="149.3" penwidth="1" penstyle="SOLID" color="00000" Bold="true" />
    <line name="LINE3" start_x="11.8" start_y="149.3" end_x="150.8" end_y="149.3" penwidth="1" penstyle="SOLID" color="00000" Bold="true" />
    <line name="LINE3" start_x="11.8" start_y="2.1" end_x="150.8" end_y="2.1" penwidth="1" penstyle="SOLID" color="00000" />
    <line name="LINE3" start_x="11.8" start_y="2.1" end_x="11.8" end_y="149.3" penwidth="1" penstyle="SOLID" color="00000" />

    <label name="Request Id: " left="52" top="5" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" >Vehicle Revenue Licence</label>
    <label name="Request Id: " left="52" top="9" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" >වාහන ආදායම් බලපත්‍රය</label>
    <label name="Request Id: " left="52" top="14" right="71" bottom="26" h_align="left" v_align="top" font_code="F3L" > வாகன வருமானவரி பத்திரம்</label>
    <label name="Request Id: " left="130.7" top="6.8" right="141" bottom="26" h_align="right" v_align="top" font_code="F4B" > MTA 99X</label>

    <label name="lhs_issued_counter" left="92" top="13" right="136" bottom="26" h_align="right" v_align="top" font_code="F4B" >WP0MAHX.AA0000</label>
    <label name="lhs_licence_no" left="4" top="25" right="141" bottom="26" h_align="left" v_align="top" font_code="F5L" >WP9891309768  </label>
    <label name="lhs_licence_type" left="4" top="29.7" right="141" bottom="26" h_align="left" v_align="top" font_code="F3" >SPECIAL PURPOSE VEHICLE|PETROL-ELE </label>
    <label name="Request Id: " left="4" top="34.8" right="141" bottom="26" h_align="left" v_align="top" font_code="F3" >WP|CAK-1024|NORMAL LICENCE </label>

    <label name="Request Id: " left="65" top="40" right="141" bottom="26" h_align="left" v_align="top" font_code="F5" > JUL 2021 </label>

    <label name="lhs_owner_name_l1" left="4" top="56" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >AAAAAAAAAAAAAAAAAAAAAAAAAAAA</label>
    <label name="lhs_owner_name_l2" left="4" top="59.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >ABHAYASINGHE </label>
    <label name="lhs_owner_name_l3" left="4" top="63.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >AAAAAAAAAAAAAAAAAAAAAAAAA</label>
    <label name="lhs_owner_address_l1" left="4" top="68.4" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >INOVA IT SYSTEMS (PVT) LTD, NO 5, JAWATTA ROAD, KASSAPA ROAD, </label>
    <label name="lhs_owner_address_l2" left="4" top="72.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >COLOMBO 05  </label>
    <label name="lhs_owner_address_l3" left="4" top="75.7" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</label>
    <label name="lhs_total" left="4" top="85.6" right="26" bottom="26" h_align="right" v_align="top" font_code="FV11B" >13,000.00 </label>
    <label name="Request Id: " left="27" top="85.6" right="141" bottom="26" h_align="left" v_align="top" font_code="FV11" >:</label>
    <label name="lhs_charges_breakdown_l1" left="31" top="85.6" right="141" bottom="26" h_align="left" v_align="top" font_code="FV11" >12,000.00 </label>

    <label name="lhs_charges_breakdown_l2" left="31" top="89.6" right="141" bottom="26" h_align="left" v_align="top" font_code="FV11" >AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </label>


    <label name="lhs_valid_from" left="4" top="100" right="141" bottom="26" h_align="left" v_align="top" font_code="FC12B" >27-07-2020</label>
    <label name="Request Id: " left="34" top="100" right="141" bottom="26" h_align="left" v_align="top" font_code="FC12B" >To</label>
    <label name="lhs_valid_to" left="44" top="100" right="141" bottom="26" h_align="left" v_align="top" font_code="FC12B" >27-07-2020</label>

    <label name="lhs_vet_no" left="90" top="99" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >2020092615114</label>
    <label name="lhs_no_of_seats" left="122" top="107.3" right="123" bottom="26" h_align="left" v_align="top" font_code="F5S" >05</label>

    <label name="lhs_weight" left="49" top="107.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >1,690 kg</label>



    <signature name="signature_uid" format="jpg|png" left="79" top="121" right="43" bottom="138" h_align="left" v_align="top" width="10" height="15" />
    <line name="LINE3" start_x="79" start_y="137" end_x="106" end_y="137" penwidth="1" penstyle="DASH" color="00000" />
    <label name="lhs_issued_by" left="79" top="137" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > G. Saman  </label>
    <label name="lhs_issued_date" left="79" top="140.7" right="146" bottom="26" h_align="left" v_align="top" font_code="F5S" > 22-07-2020  </label>


    <label name="rhs_vehicle_no" left="155" top="8.4" right="141" bottom="10.2" h_align="left" v_align="top" font_code="FC7" >CXX-0000 </label>
    <label name="rhs_vehicle_class" left="157" top="11.4" right="141" bottom="14" h_align="left" v_align="top" font_code="FC7" >Motor Car </label>
    <label name="rhs_owner_name_l1" left="150" top="15" right="141" bottom="17" h_align="left" v_align="top" font_code="FC7" >Arambawelage Don Upali Ranjith 1234567891011121314</label>
    <label name="rhs_owner_address_l1" left="152" top="18.5" right="141" bottom="20.5" h_align="left" v_align="top" font_code="FC7" >No 455,kaduwela. </label>
    <label name="rhs_chassis_no" left="155" top="33.2" right="141" bottom="37" h_align="left" v_align="top" font_code="FC7" >X6TY7450008 </label>
    <label name="rhs_fuel_type" left="154" top="36.5" right="141" bottom="40" h_align="left" v_align="top" font_code="FC7" >PETROL </label>
    <label name="rhs_weight" left="168" top="39.9" right="141" bottom="42.2" h_align="left" v_align="top" font_code="FC7" >1343.22 kg </label>
    <label name="rhs_first_registration_date" left="169" top="43.3" right="141" bottom="45.4" h_align="left" v_align="top" font_code="FC7" >2021-01-01 </label>
    <label name="rhs_no_of_seats" left="156" top="46.5" right="141" bottom="49.6" h_align="left" v_align="top" font_code="FC7" />
    <label name="rhs_previous_licence_no" left="158" top="72.4" right="141" bottom="76" h_align="left" v_align="top" font_code="FC7" >WP0233311222 </label>
    <label name="Request Id: " left="179" top="75.8" right="141" bottom="78" h_align="left" v_align="top" font_code="FC7" >2021-01-01</label>
    <label name="rhs_insurance_status" left="161" top="83.45" right="141" bottom="86.5" h_align="left" v_align="top" font_code="FC7" >ACTIVE </label>
    <label name="rhs_vrp_expiry_date" left="170.5" top="86.8" right="141" bottom="89" h_align="left" v_align="top" font_code="FC7" >2022-01-14 </label>
    <label name="rhs_fee" left="182" top="91.3" right="198" bottom="93.4" h_align="right" v_align="top" font_code="FC7" >1000.00</label>
    <label name="rhs_arrears" left="182" top="94.5" right="198" bottom="96.4" h_align="right" v_align="top" font_code="FC7" >100.00</label>
    <label name="rhs_fine" left="182" top="98" right="198" bottom="99" h_align="right" v_align="top" font_code="FC7" >12.00</label>
    <label name="rhs_addtions" left="182" top="101.6" right="198" bottom="103.4" h_align="right" v_align="top" font_code="FC7" >0.00</label>
    <label name="rhs_deductions" left="182" top="104.6" right="198" bottom="106.4" h_align="right" v_align="top" font_code="FC7" >100.00</label>
    <label name="rhs_total" left="182" top="111.4" right="198" bottom="113.5" h_align="right" v_align="top" font_code="FC8" >1012.00</label>
    <label name="rhs_issued_date" left="191" top="131" right="136" bottom="133" h_align="left" v_align="top" font_code="FC7" >2022-02-11</label>


    <label name="Request Id: " left="159" top="9" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > CXX-0000  </label>
    <label name="Request Id: " left="161" top="12" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > Motor Car </label>
    <label name="Request Id: " left="154" top="15.3" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > Arambawelage Don Upali Ranjith</label>
    <label name="Request Id: " left="164" top="18.7" right="141" bottom="26" h_align="left" v_align="top" font_code="FC7" > No 455,kaduwela  </label>
    <label name="Request Id: " left="152.9" top="18.4" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Address :  </label>
    <label name="Request Id: " left="152.9" top="32.6" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Chassis No. :  </label>
    <label name="Request Id: " left="152.9" top="36.8" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Fuel Type : </label>
    <label name="Request Id: " left="152.9" top="40.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Unladen/ Gross Weight : </label>
    <label name="Request Id: " left="152.9" top="45" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Date of First Registration :  </label>
    <label name="Request Id: " left="152.9" top="49.7" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > No. of Seats :  </label>
    <label name="Request Id: " left="152.9" top="54.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Please issue a Revenue Licence for the above vehicle </label>
    <label name="Request Id: " left="152.9" top="70.2" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Signature of the owner  </label>
    <label name="Request Id: " left="152.9" top="75.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5" > For Official Use </label>
    <label name="Request Id: " left="152.9" top="79.9" right="141" bottom="26" h_align="left" v_align="top" font_code="F5" > Licence No. : </label>
    <label name="Request Id: " left="152.9" top="84.2" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Previous Licence No. and Year :</label>
    <label name="Request Id: " left="152.9" top="91.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Insurance Status : </label>
    <label name="Request Id: " left="152.9" top="95" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Route Permit and Ex. Date :  </label>
    <label name="Request Id: " left="152.9" top="98.8" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Annual Licence Fee :                    Rs.  </label>
    <label name="Request Id: " left="152.9" top="102.3" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Arrears  :                                       Rs.  </label>
    <label name="Request Id: " left="152.9" top="120.5" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Fine :                                             Rs.  </label>
    <label name="Request Id: " left="152.9" top="127.9" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Additions :                                     Rs.  </label>
    <label name="Request Id: " left="152.9" top="133.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Deductions :                                  Rs.  </label>
    <label name="Request Id: " left="152.9" top="136.6" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Gross Amount :                             Rs.  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Certified that the above charges are correct  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > ...................................  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" >Checking Officer                                Date :  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Recieved the above amount.  </label>
    <label name="Request Id: " left="152.9" top="2.1" right="141" bottom="26" h_align="left" v_align="top" font_code="F5S" > Cashier  </label>




    <line name="LINE3" start_x="5" start_y="170" end_x="107" end_y="170" penwidth="1" penstyle="SOLID" color="0" />
    <label name="LABEL_C" left="4" top="172" right="71" bottom="26" h_align="left" v_align="top" font_code="F3" >H 054845 - 10000 (2020/07)P ශ්‍රී ලංකා රජයේ මුද්‍රණ දෙපාර්තමේන්තුව</label>
  </header>


</template>