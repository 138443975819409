import React, { Fragment, useState, useEffect, useContext } from 'react';
import { UICard, ViewDetailLeftBlock } from '../../../../../ui-components/ui-elements/common/BaseElements';
import { FormWrapper, InputBoxWithState, SubmitButton } from '../../../../../ui-components/ui-elements/form';
import { StepsWrapper, CustomStep, stepWrapperOrientations } from "../../../../../ui-components/ui-elements/stepper/Stepper";
import { DataTable, actionBtnTypes } from '../../../../../ui-components/ui-elements/table/DataTable';
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { _get } from '../../../../../../helpers/common-helpers/lodash.wrappers';
import { getDataByFormObject, getLabelValue, isEmptyValue, thousandSeparator } from '../../../../../../helpers/common-helpers/common.helpers';
import { quotationAPI, quotationCompleteAPI, quotationVehicleAddAPI, quotationVehicleDeleteAPI, quotationVehicleListAPI, sequenceNumberGetAPI, reportGenerateAPI } from '../../../../../../config/apiUrl.config';
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import { apiResponseStoringType, CoreContext } from '../../../../core/context-providers/CoreContext.provider';
import { callApi } from '../../../../../../helpers/common-helpers/callApi.helpers';
import { AuthContext } from '../../../../core/context-providers/AuthContext.provider';
import NormalLicence from '../../normal-licence/NormalLicence';
import { DeleteVehicle, DeleteVehicleBtn } from './DeleteVehicle';
import { MaxArrearsPeriod } from '../../../../../../config/enum.config';
import { Base64 } from 'js-base64';

const CreateQuotation = (props) => {

  const formKey = 'createQuotation';
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepType, setActiveStepType] = useState({
    "0_step": "create",
    "1_step": "create",
  });
  const [currentRow, setRow] = useState({});
  const [coreState, coreAction] = useContext(CoreContext);
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const results = _get(coreState, `dataTableResponses.quotationVehicleListKey.results`, "");

  const prefix = _get(authStatus, 'authUser.authorityPrefix', '')

  const onGetActionBtnConf = () => {
    const firstStep = {
      formGroupName: formKey,
      isValidate: true,
      validationObject: {
        fileds: {
          to: "To",
          address_line_1: "Address Line 1",
          address_line_2: "Address Line 2",
          city: "City"
        },
        rules: {
          to: "required|max:50",
          address_line_1: "required|max:25",
          address_line_2: "required|max:25",
          city: "required|max:25",
        },
        message: {}
      },
      callApiObject: {
        isSetHeaders: true,
        multipart: false,
        method: activeStepType["0_step"] === "create" ? "post" : "put",
        onUpload: false
      },
      onGetAPIEndPointFn: (formState) => {
        return activeStepType["0_step"] === "create" ? {
          "url": `${quotationAPI.url}`,
          "key": quotationAPI.key
        } : {
          "url": `${quotationAPI.url}/${currentRow.id}`,
          "key": quotationAPI.key
        }
      },
      onChangeRequestBodyFn: (fromObject) => {
        let data = getDataByFormObject({
          ...fromObject,
          "authority_id": _get(authStatus, 'authUser.authorityId', '')
        })
        return {
          to: data.to,
          address_line_1: data.address_line_1,
          address_line_2: data.address_line_2,
          city: data.city,
          authority_id: data.authority_id,
        }
      },
      onResponseCallBackFn: (error, result) => {
        if (!error) {
          callApi(`${sequenceNumberGetAPI.url}?seq_no_type=QUOT&authority_code=${prefix}&isNext=true`)
            .method("get")
            .headers(true)
            .body({})
            .isMultipart(false)
            .send((error, result) => {
              if (!error) {
                formAction.changeInputFn("createQuotation", "quotation_no", "", _get(result, 'data.data.formatted_seq', ''));
              }
            });
          setRow(result.data.data);
          setActiveStep(activeStep + 1)
        }
      }
    };

    const secondStep = {
      formGroupName: 'vehicleDetail',
      isBtnDisabled: (results.length === 0) ? true : false,
      flashMessages: {
        "success": {
          status: true,
          message: "Quotation created successfully",
          messageType: "success"
        }
      },
      callApiObject: {
        isSetHeaders: true,
        multipart: false,
        method: "put",
        onUpload: false
      },
      onGetAPIEndPointFn: (formState) => {
        if (activeStep !== 0) {
          return {
            "url": `${quotationCompleteAPI.url}/${currentRow.id}`,
            "key": quotationCompleteAPI.key
          }
        }
        return false

      },
      onChangeRequestBodyFn: (fromObject) => {
        return getDataByFormObject({
          ...fromObject,
          amount_bt: parseFloat(_get(formState, 'createQuotation.total_amount', 0)),
          amount: parseFloat(_get(formState, 'createQuotation.total_amount', 0)),
          payment_date: null,
          printed_licence: 0,
          quotation_no: _get(formState, 'createQuotation.quotation_no', ""),
          number_of_licence: _get(formState, 'createQuotation.number_of_licence', ""),
          authority_prefix: prefix
        })
      },
      onResponseCallBackFn: (error, result) => {
        if (!error) {
          let reportCode = 'fr25int_revenue_licence_quotation';
          let url = `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=pdf&report_limit=1000&report_offset=0&report_generatedUser=${_get(authStatus, 'authUser.name', "")}&report_quotation_id=${_get(result, 'data.data.id')}`;
          window.open().location.href = url;
          props.onClosePopupFn(true);
          coreAction.resetDataTable("quotationAPIKey_list");
        }
      }
    };

    return activeStep === 0 ? firstStep : secondStep;
  }

  const onNext = () => {
    // calculateQuotationId();
    if (activeStep <= 2) {
      setActiveStep(activeStep + 1);
    }
  }

  const onBackFn = () => {
    setActiveStepType({
      ...activeStepType,
      [`${activeStep - 1}_step`]: "create-edit"
    });
    setActiveStep(activeStep - 1);
  }

  return (
    <Fragment>
      <StepsWrapper
        activeStep={activeStep}
        orientation={stepWrapperOrientations.vertical}
        onNextBtnClickFn={() => onNext()}
        onBackBtnClickFn={() => onBackFn()}
        nextBtnProps={onGetActionBtnConf()}
      >
        <CustomStep stepid="0" label="Quotation Information">
          <QuotationDetail formKey={formKey} row={currentRow} activeStep={activeStep} uiType={`${activeStepType[`${activeStep}_step`]}`} />
        </CustomStep>
        <CustomStep stepid="1" label="Vehicle Details">
          <VehicleDetail formKey={formKey} row={currentRow} activeStep={activeStep} uiType={`${activeStepType[`${activeStep}_step`]}`} />
        </CustomStep>
      </StepsWrapper>
    </Fragment>
  );
}

const QuotationDetail = (props) => {

  const [formState, formAction] = useContext(FormContext);
  return (
    <Fragment>
      <div className={"fullWidthDiv floatLeft defaultMarginBottom"}>
        <UICard>
          <FormWrapper
            setFormObject={{
              to: _get(props, 'row.to', ""),
              address_line_1: _get(props, 'row.address_line_1', ""),
              address_line_2: _get(props, 'row.address_line_2', ""),
              city: _get(props, 'row.city', ""),
              quotation_no: _get(formState, 'createQuotation.quotation_no', "")
            }}
            setGroupName={props.formKey}
            onDestroyUnsetFormObject={false}
          >
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <InputBoxWithState
                    elementWrapperStyle={""}
                    labelText={"To"}
                    formGroupName={props.formKey}
                    inputName={"to"}
                    isRequired={true}
                    maxLength="50"
                  />
                  <InputBoxWithState
                    elementWrapperStyle={""}
                    labelText={"Address Line 2"}
                    formGroupName={props.formKey}
                    inputName={"address_line_2"}
                    isRequired={true}
                    maxLength="25"
                  />

                </div>
                <div className="col-md-6">
                  <InputBoxWithState
                    elementWrapperStyle={""}
                    labelText={"Address Line 1"}
                    formGroupName={props.formKey}
                    inputName={"address_line_1"}
                    isRequired={true}
                    maxLength="25"
                  />
                  <InputBoxWithState
                    elementWrapperStyle={""}
                    labelText={"City"}
                    formGroupName={props.formKey}
                    inputName={"city"}
                    isRequired={true}
                    maxLength="25"
                  />
                </div>
              </div>
            </div>
          </FormWrapper>
        </UICard>
      </div>
    </Fragment>
  )
}

const VehicleDetail = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);

  const results = _get(coreState, `dataTableResponses.quotationVehicleListKey.results`, "");

  let totalArrearsFee = 0;
  let totalArrearsFine = 0;
  let totalFee = 0;
  let totalFine = 0;
  let totalAddition = 0;
  let totalDeduction = 0;
  let totalServiceCharge = 0;

  useEffect(() => {
    if (results.length > 0) {
      results.forEach(element => {
        totalFee += parseFloat(element.fee);
        totalArrearsFee += parseFloat(element.arrears_fee);
        totalArrearsFine += parseFloat(element.arrears_fine);
        totalFine += parseFloat(element.fine);
        totalAddition += parseFloat(element.addition);
        totalDeduction += parseFloat(element.deduction);
        totalServiceCharge += parseFloat(element.service_charge);
      });

      formAction.changeInputFn("createQuotation", "number_of_licence", "", results.length);

      const totalAmount = (
        totalFee +
        totalFine +
        totalArrearsFee +
        totalArrearsFine +
        totalAddition -
        totalDeduction +
        totalServiceCharge);

      formAction.changeInputFn("createQuotation", "total_arrears_fee", "", totalArrearsFee);
      formAction.changeInputFn("createQuotation", "total_arrears_fine", "", totalArrearsFine);
      formAction.changeInputFn("createQuotation", "total_fee", "", totalFee);
      formAction.changeInputFn("createQuotation", "total_fine", "", totalFine);
      formAction.changeInputFn("createQuotation", "total_addition", "", totalAddition);
      formAction.changeInputFn("createQuotation", "total_deduction", "", totalDeduction);
      formAction.changeInputFn("createQuotation", "total_service_charge", "", totalServiceCharge);
      formAction.changeInputFn("createQuotation", "total_amount", "", totalAmount);
    }else{
      formAction.changeInputFn("createQuotation", "total_arrears_fee", "", 0);
      formAction.changeInputFn("createQuotation", "total_arrears_fine", "", 0);
      formAction.changeInputFn("createQuotation", "total_fee", "", 0);
      formAction.changeInputFn("createQuotation", "total_fine", "", 0);
      formAction.changeInputFn("createQuotation", "total_addition", "", 0);
      formAction.changeInputFn("createQuotation", "total_deduction", "", 0);
      formAction.changeInputFn("createQuotation", "total_service_charge", "", 0);
      formAction.changeInputFn("createQuotation", "total_amount", "", 0);
    }
  }, [_get(coreState, `dataTableResponses.quotationVehicleListKey.results`, "")]);

  const getYearDiffWithMonth = (startDate, endDate) => {

    if (startDate == null || endDate == null) {
      return 0
    } else {
      const ms = (new Date(endDate).getTime()) - (new Date(startDate).getTime());


      const date = new Date(ms);
      return Math.abs(date.getUTCFullYear() - 1970);
    }

  }

  const btnDisabled = (tab) => {
    let isDissable = false;
    const vehicleStatus = _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","");
    if (parseFloat(_get(formState, "licence_charges_form.total", 0)) - parseFloat(_get(formState, "licence_charges_form.serviceCharge", 0)) === 0 && _get(formState, "vehicle_search_form.data.current_license_type.code", "FE") !== "FE") {
      isDissable = true;
    }
    if (_get(formState, "vehicle_search_form.documentRequest.issue_mode", "") === "FULL") {
      isDissable = false;
    }
    if (getYearDiffWithMonth((_get(formState, "licence_charges_form.data.arrears_valid_from", null)), (_get(formState, "licence_charges_form.data.arrears_valid_to", null))) > MaxArrearsPeriod.NumberOfYears) {
      isDissable = true;
    }
    if (vehicleStatus === "INE") {
      isDissable = true;
    }

    return isDissable;
  };

  const quotationId = _get(props, 'row.id', "");
  const CustomButtons = (props) => {
    return <SubmitButton
      btnText={"Save"}
      formGroupName={"normalLicence"}
      dataTableKey={"quotationVehicleListKey"}
      tooltip={"Create New"}
      startIcon="far fa-save"
      isValidate={false}
      isBtnDisabled={btnDisabled()}
      flashMessages={{
        "success": {
          status: true,
          message: "Quotation Vehicle is successfully added.",
          messageType: "success"
        },
        "failed": {
          status: true,
          message: "Quotation Vehicle adding fail.",
          messageType: "error"
        }
      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "post",
        onUpload: false,
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.dateTableLinkedForm
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          url: quotationVehicleAddAPI.url,
          key: quotationVehicleAddAPI.key
        }
      }}
      onChangeRequestBodyFn={() => {
        return getDataByFormObject({
          "quotation_id": quotationId,
          "vehicle_id": _get(formState, 'vehicle_search_form.data.id', ""),
          "vehicle_no": _get(formState, 'vehicle_search_form.data.vehicle_no', ""),
          "chassis_no": Base64.decode(_get(formState, 'vehicle_search_form.data.chassis_no', "")),
          "owner_name":  Base64.decode(_get(formState, 'vehicle_search_form.data.owner_id.name', "")),
          "vehicle_class_id": _get(formState, 'vehicle_search_form.data.vehicle_class.id', ""),
          "licence_type_id": _get(formState, 'vehicle_search_form.data.current_license_type.id', ""),
          "validity_from": _get(formState, 'licence_charges_form.data.valid_from', ""),
          "validity_to": _get(formState, 'licence_charges_form.data.valid_to', ""),
          "arrears_fee": _get(formState, 'licence_charges_form.totArrearsFee', ""),
          "arrears_fine": _get(formState, 'licence_charges_form.totArrearsFine', ""),
          "fee": _get(formState, 'licence_charges_form.totCurrentLicenceFee', ""),
          "fine": _get(formState, 'licence_charges_form.totCurrentLicenceFine', ""),
          "addition": _get(formState, 'licence_charges_form.totAddition', ""),
          "deduction": _get(formState, 'licence_charges_form.totdeduction', ""),
          "service_charge": _get(formState, "licence_charges_form.serviceCharge", 0),
          "total": _get(formState, 'licence_charges_form.total', ""),
          "document_validation_status": _get(formState, 'licence_charges_form.documentValidationStatus', 0),
        })
      }}
      onResponseCallBackFn={(errors, response) => {
        if (!errors) {
          props.onClosePopupFn();
        }
      }}

    />
  }
  return (
    <Fragment>
      <div className={"col-md-12"}>
        <div className={"row"}>
          <div className={"col-md-6"}>
            <strong>To: </strong> {_get(props, 'row.to', "")}
          </div>
          <div className={"col-md-6"}>
            <strong>Quotation No.: </strong> {_get(formState, 'createQuotation.quotation_no', "")}
          </div>
          <div className={"col-md-6"}>
            <strong>Address: </strong> {_get(props, 'row.address_line_1', "")}, {_get(props, 'row.address_line_2', "")}, {_get(props, 'row.city', "")}
          </div>
          <div className={"col-md-6"}>
            <strong>Date & Time: </strong> {dateObjectToString(_get(props, 'row.created_at', ""), "dd/mm/YY HH:MM")}
          </div>

          <div className={"col-md-12 defaultMarginTop"}>
            <div className={"row"}>
              <DataTable
                tableName="Vehicle List"
                apiUrl={`${quotationVehicleListAPI.url}?quotation_id=${_get(props, 'row.id', "")}&sort_by=created_at|asc`}
                dataTableKey={`${quotationVehicleListAPI.key}`}
                tableHeaderList={[
                  { displayName: "Vehicle ID", key: "vehicle_no", isSort: false, cellStyle:{ 'white-space': 'nowrap'} },
                  { displayName: "Chassis Number", key: "chassis_no", isSort: false, onPrintFn: ({chassis_no}) => getLabelValue(chassis_no, true) },
                  { displayName: "Vehicle Class", key: "vehicle_class_id.name", isSort: false },
                  { displayName: "Owner's Name", key: "owner_name", isSort: false, onPrintFn: ({owner_name}) => getLabelValue(owner_name, true) },
                  { displayName: "Licence Type", key: "licence_type_id.name", isSort: false },
                  {
                    displayName: "Validity Period (From - To)", key: "validity", isSort: false, onPrintFn: (rawData) => {
                      /*
                      const d = new Date(rawData.validity_to);
                      const year = d.getFullYear();
                      const month = d.getMonth();
                      const day = d.getDate();
                      const validityFrom = new Date(year, month, day + 1);
                      const validityTo = new Date(year + 1, month, day - 1);
                      */
                      const validityFrom = new Date(rawData.validity_from);
                      const validityTo = new Date(rawData.validity_to);
                      const license_validity_from = (rawData.validity_from) ? dateObjectToString(validityFrom, "dd/mm/YY") : "";
                      const license_validity_to = (rawData.validity_to) ? dateObjectToString(validityTo, "dd/mm/YY") : "";
                      return license_validity_from + " - " + license_validity_to;
                    }
                  },
                  { displayName: "Arrears Fee(LKR)", key: "arrears_fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.arrears_fee) ? '-' : thousandSeparator(_get(rawData, 'arrears_fee', 0), true) },
                  { displayName: "Arrears Fine(LKR)", key: "arrears_fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.arrears_fine) ? '-' : thousandSeparator(_get(rawData, 'arrears_fine', 0), true) },
                  { displayName: "Fee(LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.fee) ? '-' : thousandSeparator(_get(rawData, 'fee', 0), true) },
                  { displayName: "Fine(LKR)", key: "fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.fine) ? '-' : thousandSeparator(_get(rawData, 'fine', 0), true) },
                  { displayName: "Additions(LKR)", key: "addition", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.addition) ? '-' : thousandSeparator(_get(rawData, 'addition', 0), true) },
                  { displayName: "Deductions(LKR)", key: "deduction", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.deduction) ? '-' : thousandSeparator(_get(rawData, 'deduction', 0), true) },
                  { displayName: "Service Charge(LKR)", key: "service_charge", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.service_charge) ? '-' : thousandSeparator(_get(rawData, 'service_charge', 0), true) },
                  { displayName: "Total(LKR)", key: "total", isSort: false, isRightAlign: true, onPrintFn: (rawData) => isEmptyValue(rawData.total) ? '-' : thousandSeparator(_get(rawData, 'total', 0), true) },
                ]}
                isSetAction={true}
                isSetTableFooter={false}
                actionTypeList={[
                  {
                    actionType: actionBtnTypes.create,
                    modelContentElement: NormalLicence,
                    actionBtn: {
                      tooltip: 'Add Vehicle',
                      btnText: "Add Vehicle",
                    },
                    dialogPopupProps: {
                      isFullScreen: true,
                      dialogTitle: "Add Vehicle",
                      closeButton: {
                        btnText: "Cancel",
                        startIcon: "far fa-times-circle",
                      },
                      isSaveButton: false,
                      isCustomButton: true,
                      customButtonComponent: CustomButtons,
                      isConfirmationPopup: true,
                      customButtonComponentProps: { btnText: "Save", uiType: "CREATE", dataTableKey: "" },
                    },
                    parentToModelProps: {
                      uiType: "QUOTATION_CREATE",
                      formKey: ""
                    },
                  },
                  {
                    actionType: actionBtnTypes.view,
                    modelContentElement: NormalLicence,
                    actionBtn: {
                      tooltip: 'View Vehicle Details',
                      btnText: "View Vehicle Details",
                      elementStyle: "setWidthActionBtn",
                    },
                    dialogPopupProps: {
                      isFullScreen: true,
                      dialogTitle: "View Vehicle Information",
                      closeButton: {
                        btnText: "Cancel",
                        startIcon: "far fa-times-circle",
                      },
                      isSaveButton: false,
                      isCustomButton: false,
                      isConfirmationPopup: true,
                    },
                    parentToModelProps: {
                      uiType: "QUOTATION_VEHICLE_DETAILS_VIEW",
                      formKey: "",
                      vehicleNumber: _get(props, 'row.vehicle_no', "")

                    }
                  },
                  {
                    actionType: actionBtnTypes.delete,
                    actionBtn: {
                      tooltip: 'Delete Vehicle',
                      elementStyle: "setWidthActionBtn",
                    },
                    modelContentElement: DeleteVehicle,
                    dialogPopupProps: {
                      isFullScreen: false,
                      dialogTitle: "Remove Quotation Vehicle",
                      closeButton: {
                        btnText: "No",
                        startIcon: "far fa-times-circle",
                      },
                      isCustomButton: true,
                      customButtonComponent: DeleteVehicleBtn,
                      customButtonComponentProps: {
                        dataTableKey: quotationVehicleListAPI.key,
                        uiType: "delete",
                      },
                      closeButton: {
                        btnText: "No",
                        startIcon: "far fa-times-circle",
                      },
                    },
                    parentToModelProps: {
                      formKey: quotationVehicleDeleteAPI.key,
                      dataTableKey: quotationVehicleListAPI.key,
                    }
                  },
                ]}
              />
            </div>
            <div className={"row"}>
              <UICard elementStyle="defaultMarginTop">
                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={'Total Arrears Fee'}
                    value={thousandSeparator(_get(formState, 'createQuotation.total_arrears_fee', 0), true)}
                    labelCol={"3"}
                  />
                </div>

                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={'Total Arrears Fine'}
                    value={thousandSeparator(_get(formState, 'createQuotation.total_arrears_fine', 0), true)}
                    labelCol={"3"}
                  />
                </div>

                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={"Total Fee"}
                    value={thousandSeparator(_get(formState, 'createQuotation.total_fee', 0), true)}
                    labelCol={"3"}
                  />
                </div>

                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={"Total Fine"}
                    value={thousandSeparator(_get(formState, 'createQuotation.total_fine', 0), true)}
                    labelCol={"3"}
                  />
                </div>
                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={"Total Additions"}
                    value={thousandSeparator(_get(formState, 'createQuotation.total_addition', 0), true)}
                    labelCol={"3"}
                  />
                </div>

                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={"Total Deductions"}
                    value={thousandSeparator(_get(formState, 'createQuotation.total_deduction', 0), true)}
                    labelCol={"3"}
                  />
                </div>

                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={"Total Service Charges"}
                    value={thousandSeparator(_get(formState, 'createQuotation.total_service_charge', 0), true)}
                    labelCol={"3"}
                  />
                </div>

                <div className="col-md-12 col-sm-12 floatLeft">
                  <ViewDetailLeftBlock
                    label={"Total"}
                    value={
                      thousandSeparator(_get(formState, 'createQuotation.total_amount', 0), true)}
                    labelCol={"3"}
                  />
                </div>

              </UICard>
            </div>
          </div>
        </div>
        <div>
          &nbsp;
        </div>
      </div>
    </Fragment>
  )
}

export {
  CreateQuotation,
  QuotationDetail,
  VehicleDetail
};