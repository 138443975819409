/*
 * @Author: Sujith
 * @Date: 2020-09-28 11:11:46 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-11-16 12:20:16
 */

import React, { Fragment } from "react";

import { Slide } from '../../../ui-components/ui-elements/slide/slide';
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { DataTable } from "../../../ui-components/ui-elements/table/DataTable";
const View = () => {
    return (
        <Fragment>

                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VFT Certificate Number: </strong> 00001
                </div>
               
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>Issued Date: </strong> 2020/03/12
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>Expiry Date: </strong> 2020/03/16
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VFT Inspection Report Number: </strong> 0000200
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VFT Certificate Status: </strong> PASS
                </div>
                
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VFT Centre Name: </strong> Ishara Trades
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VFT Centre Contact No: </strong> +94724062590
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VFT Centre Address: </strong> 447A Union Pl, Colombo, Western Province
                </div>
               
               
                <div className="col-md-12 col-sm-12 floatLeft">
                    <strong>Certifying Officer’s Name: </strong> Mr.Anura Sampath
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>Certifying Officer’s Signature: </strong> <img src={"images/signature.svg"} style={{width:70}} />
                </div>

                <div className="col-md-12 floatLeft">
                    <Slide
                        dataList={["images/carFroent.jpg", "images/carBack.jpg", "images/enRoom.JPG", "images/TextDocument.png", "images/TextDocument.png", "images/doc.png"]}
                        helperText={["Front corner", "Rear corner", "Chassis number", "Document 1", "Document 2"]}
                    />
                </div>     
        </Fragment>
    );
};

const PoutePermit = () => {

    return (
        <Fragment>
           
           <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>Route Permit Number: </strong> 138
                </div>

                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>Issued Date: </strong> 2020/03/12
                </div>
                
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>Expiry Date: </strong> 2020/03/16
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VRP Organisation Name: </strong> Ministry of Transport
                </div>
                
                <div className="col-md-6 col-sm-12 floatLeft">
                    <strong>VRP Organisation Address: </strong> 47A Union Pl <br/>  Colombo <br/> Western Province
                </div>
            </div>
                <DataTable
                    tableName={"Route Permit History"}
                    dataTableKey={"routePermitHistory"}
                    tableHeaderList={[
                        { displayName: "Lic. No.", key: "no", isSort: false },
                        { displayName: "Validity Period (From – To)", key: "validityPeriod", default: "-", isSort: false },
                        { displayName: "Route Permit Number", key: "routePermitNumber", isSort: false, default: "-", },
                        { displayName: "Route Permit Issued Date", key: "issueDate", isSort: false, default: "-", },
                        { displayName: "Route Permit Expiry Date", key: "expireDate", isSort: false, default: "-", },
                    ]}
                    tableBodyList={[
                        { "no": "01", "validityPeriod": "19/11/2020 - 19/11/2021", "routePermitNumber": "138", "issueDate": "19/11/2020", "expireDate": "19/11/2021" },
                        { "no": "02", "validityPeriod": "10/10/2020 - 10/10/2021", "routePermitNumber": "120", "issueDate": "19/11/2020", "expireDate": "19/11/2021" }
                    ]}
                    isSetAction={false}
                />
            
        </Fragment>
    );
};

export { View, PoutePermit };