/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-08 15:04:36
 */

import React from "react";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import {
  DataTable,
  actionBtnTypes,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { normalLicenseDummy } from "../../../../../config/dummyData.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

const emptyFn = (...para) => undefined;

const DuplicateVehicleModel = ({
  getState = {},
  setState = emptyFn,
  setShowHideFooterFN = emptyFn,
}) => {
  return (
    <DialogPopup
      isFullScreen={false}
      isOpen={getState.isDuplicateVehicle}
      onClosePopupFn={() =>
        setState({
          ...getState,
          isDuplicateVehicle: false,
        })
      }
      dialogTitle={"Duplicate Vehicle Information"}
    >
      <DataTable
        tableName="Vehicle Information"
        tableHeaderList={[
          { displayName: "Vehicle ID", key: "id", isSort: false },
          { displayName: "Chassis Number", key: "no", isSort: false },
          { displayName: "Vehicle Status", key: "status", isSort: false },
        ]}
        tableBodyList={[
          {
            id: "KS-6666",
            no: "KSP902053525",
            status: "Blacklisted",
          },
          {
            id: "KS-6666",
            no: "1GP90205352P",
            status: "Archived",
          },
        ]}
        isSetTableFooter={false}
        isSetTableHeader={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.view,
            actionBtn: {
              icon: "mdi mdi-check",
              onClickBtnFn: () => {
                setState({
                  ...getState,
                  isDuplicateVehicle: false,
                  currentObject: _get(normalLicenseDummy, "ks-6666", {}),
                  licenseType: _get(
                    normalLicenseDummy,
                    "ks-6666.status.currentLicenceType",
                    "1"
                  ),
                });
                setShowHideFooterFN(true, {
                  issueMode: 1,
                  payArrearsOnly: false,
                });
              },
            },
          },
        ]}
      />
    </DialogPopup>
  );
};

export { DuplicateVehicleModel };
