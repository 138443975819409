/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-08 18:12:24
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 11:09:07
 */
import React, { useState, Fragment, useEffect } from "react";
import { isEmpty } from "lodash";
import {
  FormWrapper,
  InputBoxWithState,
  InputButton,
  CheckBoxWithState,
  CheckBox,
  DatePickerWithState,
  roundBtnSizes,
  SelectBoxWithState,
  checkBoxLabelPlacement,
} from "../../../../ui-components/ui-elements/form/index";
import {
  getLicenceTransactionDetailsAPI
} from "../../../../../config/apiUrl.config";
import { RowNo } from "./../../../../ui-components/ui-elements/documents-uploader/documents-uploader-includes/documents-uploader-includes";
import { PaddingLessUICard, TooltipWrapper, MaskMobile, LimitTextLength, CircleLoaderElement } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import {
  DataTable,
  actionBtnTypes,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { RoundButtonWithPopups } from "../../../../ui-components/ui-elements/form/Button";
import { MenuWrapper, MenuItemWrapper } from "../../../../ui-components/ui-elements/lists-and-menus/ListsAndMenus";
import { AddVehicleUI } from "../../../request-for-approval/pages/AddVehicle";
import { LicenceTypeChangeUI } from "../../../request-for-approval/pages/LicenceTypeChange";
import { TransferVehicleUI } from "../../../request-for-approval/pages/TransferVehicle";
import { UpdateVehicleUI } from "../../../request-for-approval/pages/UpdateVehicle";
import { VehicleStatusChangeUI } from "../../../request-for-approval/pages/VehicleStatusChange";
import { ReverseLastTransactionUI } from "../../../request-for-approval/pages/ReverseLastTransaction";
import { LicenceReprintUI } from "../../../request-for-approval/pages/LicenceReprint";
import { LiceneceWaveOffUI } from "../../../request-for-approval/pages/LicenceWaveOff";
import { SetEffectiveDateUI } from "../../../request-for-approval/pages/SetEffectiveDate";
import { RoutePermitUI } from "../../../request-for-approval/pages/RoutePermiteFine";
import { normalLicenseDummy, statusObject, quotationID } from "../../../../../config/dummyData.config";
import { _get, _getFloat } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { View, PoutePermit } from "../View";
import { QuotationUI } from "./Quotation";
import { OtherAdditionsDeductionUI } from "../../../request-for-approval/pages/OtherAdditionsDeductions";
import { AddLicenceUI } from "../../../request-for-approval/pages/AddLicence";
import { CancelLicenceUI } from "../../../request-for-approval/pages/CancelLicence";
import { RequestLicenceInvalidDocUI } from "../../../request-for-approval/pages/RequestLicenceInvalidDoc";
import { RequestLicenceArrearsUI } from "../../../request-for-approval/pages/RequestLicenceArrears";
import { useContext } from 'react';
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { pdmtPaymentCategories } from "../../../../../config/enum.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { numberThousandSeperator, roundValue } from "../../../../../helpers/common-helpers/common.helpers";
import { getUsernamenLocationFn } from "../../../../../helpers/common-helpers/getUsernamenLocation.helpers";
import {  UICard} from "../../../../ui-components/ui-elements/common/BaseElements";
import { ProvincesPrefixMatcher } from "../../../../../config/enum.config";
const emptyFun = (...para) => undefined;

const LicenceValidityPeriod = ({
  getState = {
    searchKey: "ks-5555",
    currentObject: null,
  },
  setState = emptyFun,
  uiType = "normalLicense",
}) => {

  const [getPeriod, setPeriod] = useState({
    "isReIssuance": false
  });

  return (
    <PaddingLessUICard
      cardHeading="Licence Validity Period"
      cardHeadingTail={LicencePeriodIcons}
      passPropsToCardHeadingTail={{
        uiType: "licence_period",
        formGroupName: "normalLicence",
        modelType: uiType,
        onChangeStatusFn: () => setPeriod({
          ...getPeriod,
          isReIssuance: !getPeriod.isReIssuance
        })
      }}
    >
      <div className="row">
        <div className="col-md-6">
          <strong>From: </strong> {getPeriod.isReIssuance === false ?
            _get(getState.currentObject, 'licenceValidityPeriod.form', "27/04/2020") :
            _get(getState.currentObject, 'licenceValidityPeriod.reIssuance.form', "27/04/2020")}
        </div>

        <div className="col-md-6">
          <SelectBoxWithState
            labelText={"To"}
            formGroupName={"normalLicence"}
            inputName={"licenceValidityeriodTo"}
            emptySelectOptionTxt={"To Date / Period"}
            dataList={getPeriod.isReIssuance === false ? _get(getState.currentObject, 'licenceValidityPeriod.toList', []) : _get(getState.currentObject, 'licenceValidityPeriod.reIssuance.toList', [])}
          />
          {/* 26-04-2021 */}
        </div>
      </div>
    </PaddingLessUICard>
  )
}

const DuplicateVehicleModel = ({
  getState = {},
  setState = emptyFun,
  setShowHideFooterFN = emptyFun
}) => {
  return (
    <DialogPopup
      isFullScreen={false}
      isOpen={getState.isDuplicateVehicle || getState.pendingWorkflow}
      onClosePopupFn={() => setState({
        ...getState,
        isDuplicateVehicle: false,
        pendingWorkflow: false
      })}
      dialogTitle={getState.isDuplicateVehicle === true ? "Duplicate Vehicle Information" : "View Pending Workflows"}
    >

      {
        (getState.isDuplicateVehicle === true) ? (
          <DataTable
            tableName="Vehicle Information"
            tableHeaderList={[
              { displayName: "Vehicle ID", key: "id", isSort: false },
              { displayName: "Chassis Number", key: "no", isSort: false },
            ]}
            tableBodyList={[
              {
                "id": "KS-6666",
                "no": "KSP902053525"
              },
              {
                "id": "KS-6666",
                "no": "1GP90205352P"
              }
            ]}
            isSetTableFooter={false}
            isSetTableHeader={true}
            actionTypeList={[
              {
                actionType: actionBtnTypes.view,
                actionBtn: {
                  "icon": "mdi mdi-check",
                  "onClickBtnFn": () => {

                    setState({
                      ...getState,
                      isDuplicateVehicle: false,
                      currentObject: _get(
                        normalLicenseDummy,
                        "ks-6666",
                        {}
                      ),
                      licenseType: _get(normalLicenseDummy, "ks-6666.status.currentLicenceType", "1"),
                    })
                    setShowHideFooterFN(true, {
                      issueMode: 1,
                      isPendingWorkFlow: true,
                      payArrearsOnly: false
                    });
                  }
                }
              },
            ]}
          />
        ) : (
          <DataTable
            tableName="Pending Workflows"
            tableHeaderList={[
              { displayName: "Workflow Name", key: "name", isSort: false },
              { displayName: "Requested by", key: "reqBy", isSort: false },
              { displayName: "Requested at", key: "reqAt", isSort: false },
              { displayName: "Requested Location", key: "location", isSort: false },
            ]}
            tableBodyList={[
              {
                "name": "Update Vehicle",
                "reqBy": "S.L Perera(Operator)oooooooooo",
                "reqAt": "03-01-2222 12:30",
                "location": "Uva(Kataragamazzzzz)"
              },
            ]}
            isSetTableFooter={false}
            isSetTableHeader={true}
            isSetAction={false}
          />
        )
      }



    </DialogPopup>
  )
}

const EditBtn = () => {
  return <InputButton
    btnName="submit"
    btnText={"Update"}
  />
}

const SubmitBtn = () => {
  return <InputButton
    btnName="submit"
    btnText={"Submit"}
  />
}


const DocumentValidation = ({
  getState = {
    searchKey: "ks-5555",
    currentObject: null,
  },
  setState = emptyFun,
  setShowHideFooterFN = emptyFun,
  uiType = "normalLicense",
}) => {

  const [getModelState, setModelState] = useState({
    "modelState": false,
    "isIssuesTempLicense": false,
    "modelType": ""
  })

  return (
    <div className="fullWidthDiv documentValidation">
      <PaddingLessUICard cardHeading="Document Validation">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <CheckBoxWithState
                  inputName={"isVerifyRegister"}
                  formGroupName={"normalLicence"}
                  labelText={"Certificate of Registration"}
                />
              </div>
              <div className="col-md-4 defaultHalfPaddingTop">
                <strong>Previous Lic. No. /Rec. No : </strong>
                48622628
              </div>
            </div>
          </div>

          {_get(
            getState.currentObject,
            `documentValidation.isSetVET`,
            false
          ) === true ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <SelectBoxWithState
                    labelText={"VET"}
                    isRequired={true}
                    formGroupName={"normalLicence"}
                    inputName={"vetCompany"}
                    emptySelectOptionTxt={"VET Company"}
                    dataList={[
                      {
                        id: "1",
                        value: "LAUGFS ECO PLC",
                      },
                      {
                        id: "2",
                        value: "CLEANCO LANKA PLC",
                      },
                    ]}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetVETDisable', false)}
                  />
                </div>
                <div className="col-md-4">
                  <DatePickerWithState
                    labelText={"Issue date"}
                    isRequired={true}
                    formGroupName={"normalLicence"}
                    inputName={"vetDateTime"}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetVETDisable', false)}
                  />

                </div>
                <div className="col-md-4 checkElement">

                  <InputBoxWithState
                    elementWrapperStyle={"checkElementTxt"}
                    labelText={"VET No"}
                    formGroupName={"normalLicence"}
                    inputName={"vet_no"}
                    isRequired={true}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetVETDisable', false)}
                  />

                  <div className="checkElementViewMore">
                    <CheckBoxWithState
                      inputName={"isVet"}
                      formGroupName={"normalLicence"}
                      isDisabled={_get(getState.currentObject, 'documentValidation.isSetVETDisable', false)}
                    />
                  </div>

                </div>
              </div>
            </div>
          ) : null}

          {_get(
            getState.currentObject,
            `documentValidation.isSetInsurance`,
            false
          ) === true ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <SelectBoxWithState
                    labelText={"Insurance"}
                    isRequired={true}
                    formGroupName={"normalLicence"}
                    emptySelectOptionTxt={"Insurance Company"}
                    inputName={"insurance"}
                    dataList={[
                      {
                        id: "1",
                        value: "AIA INSURANCE LANKA LTD",
                      },
                      {
                        id: "2",
                        value: "ALLIANZ INSURANCE LANKA LTD",
                      },
                      {
                        id: "3",
                        value: "FAIRFIRST INSURANCE LTD ",
                      },
                      {
                        id: "4",
                        value: "CONTINENTAL INSURANCE LANKA LTD",
                      },
                      {
                        id: "5",
                        value: "COOPERATE INSURANCE COMPANY LTD ",
                      },
                    ]}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetInsuranceDisable', false)}
                  />
                </div>
                <div className="col-md-4">
                  <DatePickerWithState
                    labelText={"Expiry Date"}
                    isRequired={true}
                    formGroupName={"normalLicence"}
                    inputName={"exp_date"}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetInsuranceDisable', false)}
                  />
                </div>
                <div className="col-md-4 checkElement">
                  <span className="checkElementTxt defaultPadding"></span>
                  <div className="checkElementViewMore">
                    <CheckBoxWithState
                      inputName={"isInsurance"}
                      formGroupName={"normalLicence"}
                      isDisabled={_get(getState.currentObject, 'documentValidation.isSetInsuranceDisable', false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {_get(
            getState.currentObject,
            `documentValidation.isSetFitness`,
            false
          ) === true ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <SelectBoxWithState
                    labelText={"VFT"}
                    isRequired={true}
                    formGroupName={"normalLicence"}
                    inputName={"fitnessCompany"}
                    emptySelectOptionTxt={"VFT Center"}
                    dataList={[
                      {
                        id: "C02",
                        value: "ASARA MOTORS",
                      },
                      {
                        id: "1",
                        value: "SLTB DEPOT, AWISSAWELLA",
                      },
                      {
                        id: "2",
                        value: "ISURU TRADERS",
                      },
                      {
                        id: "3",
                        value: "K.G MOTORS",
                      },
                    ]}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetFitnessDisable', false)}
                  />
                </div>
                <div className="col-md-4">
                  <DatePickerWithState
                    labelText={"Issued Date"}
                    isRequired={true}
                    formGroupName={"normalLicence"}
                    inputName={"issuedDate"}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetFitnessDisable', false)}
                  />

                </div>
                <div className="col-md-4 checkElement">

                  <InputBoxWithState
                    elementWrapperStyle={"checkElementTxt"}
                    labelText={"Cert No"}
                    formGroupName={"normalLicence"}
                    inputName={"certNo"}
                    isDisabled={_get(getState.currentObject, 'documentValidation.isSetFitnessDisable', false)}
                  />

                  <div className="checkElementViewMore">

                    <CheckBoxWithState
                      inputName={"isfitness"}
                      formGroupName={"normalLicence"}
                      isDisabled={_get(getState.currentObject, 'documentValidation.isSetFitnessDisable', false)}
                    />
                    <InputButton
                      btnName="view_more"
                      overrideTextToIcon={
                        <i className="mdi mdi-magnify-plus-outline" />
                      }
                      onClickBtnFn={() => setModelState({
                        ...getModelState,
                        modelState: true,
                        modelType: "VFT"
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {_get(
            getState.currentObject,
            `documentValidation.isSetRoutePerm`,
            false
          ) === true && uiType !== "carnet" ? (
            <Fragment>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <SelectBoxWithState
                      labelText={"VRP"}
                      formGroupName={"normalLicence"}
                      inputName={"routePermComapny"}
                      isRequired={true}
                      emptySelectOptionTxt={"VRP Authority"}
                      dataList={[
                        {
                          id: "1",
                          value: "NATIONAL TRANSPORT COMMISSION (NTC)",
                        },
                      ]}
                      isDisabled={_get(getState.currentObject, 'documentValidation.isSetRoutePermDisable', false)}
                    />
                  </div>
                  <div className="col-md-4">

                    <DatePickerWithState
                      labelText={"Expiry Date"}
                      isRequired={true}
                      formGroupName={"normalLicence"}
                      inputName={"expiryDate"}
                      isDisabled={_get(getState.currentObject, 'documentValidation.isSetRoutePermDisable', false)}
                    />
                  </div>
                  <div className="col-md-4 checkElement">

                    <InputBoxWithState
                      elementWrapperStyle={"checkElementTxt"}
                      labelText={"VRP No"}
                      formGroupName={"normalLicence"}
                      inputName={"vrpNo"}
                      isRequired={true}
                      isDisabled={_get(getState.currentObject, 'documentValidation.isSetRoutePermDisable', false)}
                    />

                    <div className="checkElementViewMore">

                      <CheckBoxWithState
                        inputName={"isroutePerm"}
                        formGroupName={"normalLicence"}
                        isDisabled={_get(getState.currentObject, 'documentValidation.isSetRoutePermDisable', false)}
                      />
                      <InputButton
                        btnName="view_more"
                        overrideTextToIcon={
                          <i className="mdi mdi-magnify-plus-outline" />
                        }
                        onClickBtnFn={() => setModelState({
                          ...getModelState,
                          modelState: true,
                          modelType: "VRP"
                        })}
                      />
                    </div>

                  </div>
                </div>
              </div>

            </Fragment>
          ) : null}



          {
            uiType !== "quotation" ? (
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <CheckBox
                      labelText={"Issues Temp Licence"}
                      inputName={"issuesTemp"}
                      inputValue={getModelState.isIssuesTempLicense}
                      onChangeFn={() => {
                        setShowHideFooterFN(true, {
                          issueMode: ((!getModelState.isIssuesTempLicense) ? "2" : _get(getState.currentObject, `status.issueMode`, "1")),
                          isPendingWorkFlow: _get(getState.currentObject, `status.isPendingWorkFlow`, false),
                          payArrearsOnly: _get(getState.currentObject, `status.payArrearsOnly`, true)
                        });

                        setModelState({
                          ...getModelState,
                          isIssuesTempLicense: !getModelState.isIssuesTempLicense
                        });

                      }
                      }
                    />
                  </div>
                  <div className="col-md-3 defaultHalfPaddingTop">
                    <strong>Run: </strong> Allowed
                  </div>
                  {
                    (getModelState.isIssuesTempLicense === true) ? (
                      <div className="col-md-3 defaultHalfPaddingTop">
                        <strong>Temp: </strong> 000000000030
                      </div>
                    ) : (null)
                  }

                  <div className="col-md-3">
                    <CheckBoxWithState
                      inputName={"isTransferredVehicle"}
                      formGroupName={"normalLicence"}
                      labelText={"Transfer Vehicle"}
                    />
                  </div>
                </div>
              </div>

            ) : (
              null
            )
          }




        </div>
      </PaddingLessUICard>

      <DialogPopup
        isFullScreen={false}
        isOpen={getModelState.modelState}
        onClosePopupFn={() => setModelState({
          modelType: "",
          modelState: false
        })}
        dialogTitle={getModelState.modelType === "VRP" ? "Route Permit" : "VFT Certificate"}
      >
        {
          (getModelState.modelType === "VRP") ? (
            <PoutePermit />
          ) : (<View />)
        }
      </DialogPopup>

    </div>
  )
}

const RequestFor = () => {


  const [getState, setState] = useState({
    "showMenu": false,
    "anchorEl": null,
    "modelType": null,
    "showModel": false
  });

  const getModelTitle = () => {
    if (getState.modelType === "add-vehicle") {
      return "Add Vehicle";
    } else if (getState.modelType === "licence_type_change") {
      return "Licence Type Change";
    } else if (getState.modelType === "transfer_vehicle") {
      return "Transfer Vehicle";
    } else if (getState.modelType === "update-vehicle") {
      return "Update Vehicle";
    } else if (getState.modelType === "vehicle-status-change") {
      return "Vehicle Status Change";
    } else if (getState.modelType === "reverse-last-transaction") {
      return "Reverse Last Transaction";
    } else if (getState.modelType === "request-licence-invalid-doc") {
      return "Request Licence - Invalid Documents";
    } else if (getState.modelType === "request-licence-arrears") {
      return "Request Licence - Licence with Arrears";
    } else if (getState.modelType === "licence-reprint") {
      return "Licence / Receipt Reprint";
    } else if (getState.modelType === "set-effective-date") {
      return "Set Effective Date";
    } else if (getState.modelType === "licence-wave-off") {
      return "Licence Waive-Off";
    } else if (getState.modelType === "route-permit-fine") {
      return "Route Permit Fine";
    }
    else if (getState.modelType === "other-additions-deduction") {
      return "Other Additions / Deduction";
    }
    else if (getState.modelType === "cancel-licence") {
      return "Cancel Licence / Receipt Transaction";
    }
    else if (getState.modelType === "add-licence") {
      return "Add Licence";
    }
    else {
      return "";
    }
  }

  const getModelUI = () => {
    if (getState.modelType === "add-vehicle") {
      return <AddVehicleUI />;
    } else if (getState.modelType === "licence_type_change") {
      return <LicenceTypeChangeUI />;
    } else if (getState.modelType === "transfer_vehicle") {
      return <TransferVehicleUI />;
    } else if (getState.modelType === "update-vehicle") {
      return <UpdateVehicleUI />;
    } else if (getState.modelType === "vehicle-status-change") {
      return <VehicleStatusChangeUI />;
    } else if (getState.modelType === "reverse-last-transaction") {
      return <ReverseLastTransactionUI />;
    } else if (getState.modelType === "request-licence-invalid-doc") {
      return <RequestLicenceInvalidDocUI />;
    } else if (getState.modelType === "request-licence-arrears") {
      return <RequestLicenceArrearsUI />;
    } else if (getState.modelType === "licence-reprint") {
      return <LicenceReprintUI />;
    } else if (getState.modelType === "set-effective-date") {
      return <SetEffectiveDateUI />;
    } else if (getState.modelType === "licence-wave-off") {
      return <LiceneceWaveOffUI />;
    } else if (getState.modelType === "route-permit-fine") {
      return <RoutePermitUI />;
    }
    else if (getState.modelType === "other-additions-deduction") {
      return <OtherAdditionsDeductionUI />;
    }
    else if (getState.modelType === "cancel-licence") {
      return <CancelLicenceUI />;
    }
    else if (getState.modelType === "add-licence") {
      return <AddLicenceUI />;
    }
    else {
      return (null);
    }
  }


  return (
    <Fragment>
      <span onClick={(event) => setState({
        ...getState,
        anchorEl: event.currentTarget,
        showMenu: true
      })}>
        <InputButton
          elementWrapperStyle={""}
          btnName="submit"
          btnText={"Request For"}
        />
        <InputButton
          elementWrapperStyle={""}
          btnName="submit"
          overrideTextToIcon={<i class="fas fa-caret-down"></i>}
        />




      </span>
      <MenuWrapper
        anchorEl={getState.anchorEl}
        open={getState.showMenu}
        onCloseFn={() => setState({
          ...getState,
          anchorEl: null,
          showMenu: false
        })}
        elementStyle={"requestFor"}
      >
        <MenuItemWrapper elementStyle={"requestForMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "add-vehicle",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Add Vehicle</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "licence_type_change",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Licence Type Change</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "transfer_vehicle",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Transfer Vehicle</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "update-vehicle",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Update Vehicle</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "vehicle-status-change",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Vehicle Status Change</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "reverse-last-transaction",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Reverse Last Transaction</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "request-licence-invalid-doc",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Request Licence - Invalid Documents</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "request-licence-arrears",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Request Licence - Licence with Arrears</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "licence-reprint",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Licence / Receipt Reprint</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "set-effective-date",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Set Effective Date</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "licence-wave-off",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Licence Waive-Off</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"profileMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "route-permit-fine",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Route Permit Fine</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"requestForMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "other-additions-deduction",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Other Additions / Deductions</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"requestForMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "cancel-licence",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Cancel Licence / Receipt Transaction</p>
        </MenuItemWrapper>
        <MenuItemWrapper elementStyle={"requestForMenuItem"} menuKey="delegation" onClickFn={() => setState({
          ...getState,
          modelType: "add-licence",
          showModel: true,
          anchorEl: null,
          showMenu: false
        })}>
          <p>Add Licence</p>
        </MenuItemWrapper>
      </MenuWrapper>


      <DialogPopup
        dialogTitle={getModelTitle()}
        isFullScreen={true}
        isOpen={getState.showModel}
        onClosePopupFn={() => setState({
          ...getState,
          modelType: null,
          showModel: false
        })}
        isCustomButton={true}
        customButtonComponent={SubmitBtn}
      >
        {getModelUI()}
      </DialogPopup>

    </Fragment>
  );
};

const ViewTransaction = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const docValidationDetails = [];
  const formKey = "cancel_licence";


  docValidationDetails.push({
    document_name: "Insurance",
    company_name: _get(formState, `${formKey}.document.insurance.company_name`, "-"),
    expiry_date: _get(formState, `${formKey}.insurance_expiry_date`, {}),
    issued_date: _get(formState, `${formKey}.document.insurance.issued_date`, {}),
    document_no: _get(formState, `${formKey}.insurance_no`, {}),
  })


  docValidationDetails.push({
    document_name: "VET",
    company_name: _get(formState, `${formKey}.document.vet.company_name`, "-"),
    expiry_date: _get(formState, `${formKey}.vet_expiry_date`, {}),
    issued_date: _get(formState, `${formKey}.document.vet.issued_date`, {}),
    document_no: _get(formState, `${formKey}.vet_no`, {}),
  })

  // if (!isEmpty(_get(formState, `${formKey}.document.vft`, {}))) {
  docValidationDetails.push({
    document_name: "VFT",
    company_name: _get(formState, `${formKey}.document.vft.company_name`, "-"),
    expiry_date: _get(formState, `${formKey}.vft_expiry_date`, {}),
    issued_date: _get(formState, `${formKey}.document.vft.issued_date`, {}),
    document_no: _get(formState, `${formKey}.vft_no`, {}),
  })
  // } 
  if (!isEmpty(_get(formState, `${formKey}.document.vrp`, {}))) {
    docValidationDetails.push({
      document_name: "VRP",
      company_name: _get(formState, `${formKey}.document.vrp.company_name`, "-"),
      expiry_date: _get(formState, `${formKey}.vrp_expiry_date`, {}),
      issued_date: _get(formState, `${formKey}.document.vrp.issued_date`, {}),
      document_no: _get(formState, `${formKey}.vrp_no`, {}),
    })
  }

  useEffect(() => {
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.licence.created_by`, null) }, "user", "created_user_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.tx_created_by`, null) }, "user", "tx_created_user", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.requested_by`, null) }, "user", "requested_user_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.service_id`, null) }, "location", "requested_location_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.tx_created_at`, null) }, "location", "tx_location_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.approved_by`, null) }, "user", "approved_user_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.approved_location`, null) }, "location", "approved_location_name", formAction, formKey)
  }, [_get(formState, `${formKey}.created_by`, ''), _get(formState, `${formKey}.tx_created_by`, ''), _get(formState, `${formKey}.requested_by`, ''), _get(formState, `${formKey}.requested_location`, ''), _get(formState, `${formKey}.tx_created_at`, ''), _get(formState, `${formKey}.approved_by`, ''), _get(formState, `${formKey}.approved_location`, '')]);


  return (
    <FormWrapper
      setGroupName={formKey}
      apiUrl={`${getLicenceTransactionDetailsAPI.url}/${_get(props, 'row.licence_id')}`}
      onRebuildResponseFn={(response) => {
        return {
          tx_action_type: _get(response, "licence.lc_txn_action_id", ""),
          licence_number: _get(response, "licence.licence_number", ""),
          lcn_type_code: _get(response, "licence.licence_type_code", ""),
          lcn_valid_from: _get(response, "licence.valid_from", ""),
          lcn_valid_to: _get(response, "licence.valid_to", ""),
          fee: _get(response, "paymentsDetails.feeAmount", ""),
          fine: _getFloat(response, "paymentsDetails.fineAmount", ""),
          serviceCharge: _getFloat(response, "paymentsDetails.serviceChargeAmount", ""),
          additions: _getFloat(response, "paymentsDetails.additionAmount", ""),
          deductions: _getFloat(response, "paymentsDetails.deductionAmount", ""),
          postalFee: _getFloat(response, "paymentsDetails.deliveryAmount", ""),
          total: _getFloat(response, "paymentsDetails.totalAmount", ""),
          tx_no: _get(response, "licence.txn_no", ""),
          tx_status: _get(response, "licence.lcn_status", ""),
          tx_date: _get(response, "licence.txn_date_and_time", ""),
          tx_type: _get(response, "licence.txn_type", ""),
          tx_created_by: _get(response, "licence.txn_created_by", ""),
          tx_created_at: _get(response, "licence.txn_created_at", null),
          tx_role_id: _get(response, "licence.txn_created_role_id", null),
          tx_province: _get(response, "licence.txn_province", null),
          lcn_issue_mode: _get(response, "licence.issue_mode", ""),
          document: _get(response, "documents", null),
          print_history: _get(response, "print_history", null),
          payments: _get(response, "payments", null),
          paymentDetails: _get(response, "paymentsDetails", null),
          print_id: _get(response, "print_history[0].printed_by", null),
          reversed_request: _get(response, "reversed_request", null),
          reversed_txn: _get(response, "reversed_txn", null),
          reversed_request_id: _get(response, "reversed_txn[0].reversed_request_id", null),
          service_id: _get(response, "reversed_txn[0].service_id", null),
          reversed_at: _get(response, "reversed_txn[0].reversed_at", null),
          vet_expiry_date: _get(response, "licence.vet_expiry_date", ""),
          vrp_expiry_date: _get(response, "licence.vrp_expiry_date", ""),
          vft_expiry_date: _get(response, "licence.vft_expiry_date", ""),
          insurance_expiry_date: _get(response, "licence.insurance_expiry_date", ""),
          vet_no: _get(response, "licence.vet_no", ""),
          vrp_no: _get(response, "licence.vrp_no", ""),
          insurance_no: _get(response, "licence.insurance_reference", ""),
          vft_no: _get(response, "licence.vft_no", ""),
          ui_request_id:_get(response, "reversed_request[0].ui_request_id", null),
          _onLoad: true,
        };
      }}
    >
      <div className="fullWidthDiv">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <strong>Txn. Status: </strong> {_get(formState, `${formKey}.tx_status`, null) === 'Open' ? "Success" : _get(formState, `${formKey}.tx_status`, null)}
              </div>

              <div className="col-md-6">
                <strong>Txn. Date & Time: </strong> {dateObjectToString(_get(formState, `${formKey}.tx_date`, null), 'dd/mm/YY HH:MM')}
              </div>

              <div className="col-md-6">
                <strong>Txn. Created By: </strong> {_get(formState, `${formKey}.tx_created_by`, '-') + '(' + _get(formState, `${formKey}.tx_role_id`, '-') + ')'}
              </div>

              <div className="col-md-6">
                <strong>Txn. Type: </strong>

                {_get(formState, `${formKey}.tx_type`, "")}
              </div>

              <div className="col-md-6">
                <strong>Txn. Created Location: </strong>{ProvincesPrefixMatcher[_get(formState, `${formKey}.tx_province`, null)]}({_get(formState, `${formKey}.tx_created_at`, null)})
              </div>
            </div>
          </div>
          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-6">
                <strong>Lic. No. /Rec. No.: </strong> {_get(formState, `${formKey}.licence_number`, "-")}
              </div>

              <div className="col-md-6 d-flex flex-row">
                <strong>Validity Period:&nbsp; <br /> (From - To)  </strong>
                <span>{`${dateObjectToString(_get(formState, `${formKey}.lcn_valid_from`, null), 'dd/mm/YY')} - ${dateObjectToString(_get(formState, `${formKey}.lcn_valid_to`, null), 'dd/mm/YY')}`}</span>
              </div>

              <div className="col-md-6">
                <strong>Licence Type: </strong>
                {_get(formState, `${formKey}.lcn_type_code`, "")}
              </div>

              <div className="col-md-6">
                <strong>Licence Issue Mode: </strong>
                {_get(formState, `${formKey}.lcn_issue_mode`, false) === "TEMP" ? 'Temporary Licence' : 'Full Licence'}
              </div>

              <div className="col-md-6">
                <strong>Year: </strong>{dateObjectToString(_get(formState, `${formKey}.tx_date`, '-'), "YYYY")}
              </div>
            </div>
          </div>


          <div className="col-md-12 defaultMarginTop">
            <div className="row">
            
              <div className="col-md-12">
              <UICard cardHeading="Document Validation Details">
              {_get(formState, `${formKey}.document`, []).length === 0 ?
                <div className="notificationContentWrapper">
                <p>No record(s) found.</p>
              </div>:
              <DataTable
                 
                  isSetAction={false}

                  tableHeaderList={[
                    { displayName: "Document", key: "document_name", isSort: false, 
                      onPrintFn: ({ doc_type_code }) => doc_type_code === 'INS' ? "Insurance" : doc_type_code === 'VET' ? "VET" : doc_type_code === 'VFT' ? "VFT" : doc_type_code === 'VRP' ? 'VRP' : "-"
                    },
                    { displayName: "Document No.", key: "doc_ref_no", isSort: false, onPrintFn: ({ doc_ref_no }) => isEmpty(doc_ref_no) ? "-" : doc_ref_no },
                    { displayName: "Company", key: "doc_company_name", isSort: false, onPrintFn: ({ doc_company_name }) => isEmpty(doc_company_name) ? "-" : doc_company_name },
                    { displayName: "Issued Date", key: "issued_date", isSort: false, onPrintFn: ({ issued_date }) => isEmpty(issued_date) ? "-" : dateObjectToString(issued_date, "dd/mm/YY") },
                    { displayName: "Expiry Date", key: "expiry_date", isSort: false, onPrintFn: ({ expiry_date }) => isEmpty(expiry_date) ? "-" : dateObjectToString(expiry_date, "dd/mm/YY") },
                  ]}
                  tableBodyList={_get(formState, `${formKey}.document`, [])}
                  isSetTableFooter={false}
                  isSetTableHeader={false}
                /> 
                
              }
               </UICard>
              </div>
             
            </div>
          </div>

          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
                <h1 className="defaultHeading defaultHalfMarginBottom">
                  Payment Details
                </h1>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <table className="fullWidthDiv">
                      <tr>
                        <th>Fee (LKR): </th>
                        <td className={"text-right"}>{numberThousandSeperator(_get(formState, `${formKey}.fee`, '0.00'))}</td>
                      </tr>
                      <tr>
                        <th>Fine (LKR):</th>
                        <td className={"text-right"}>{numberThousandSeperator(_get(formState, `${formKey}.fine`, '0.00'))}</td>
                      </tr>
                      <tr>
                        <th>Delivery Fee (LKR):</th>
                        <td className={"text-right"}>{numberThousandSeperator(_get(formState, `${formKey}.postalFee`, '0.00'))}</td>
                      </tr>
                      <tr>
                        <th>Additions (LKR):</th>
                        <td className={"text-right"}>{numberThousandSeperator(_get(formState, `${formKey}.additions`, '0.00'))}</td>
                      </tr>
                      <tr>
                        <th>Deductions (LKR):</th>
                        <td className={"text-right"}>{`(${numberThousandSeperator(Math.abs(_get(formState, `${formKey}.deductions`, '0.00')), 2)})`}</td>
                      </tr>
                      <tr>
                        <th>Service Charge (LKR):</th>
                        <td className={"text-right"}>{numberThousandSeperator(_get(formState, `${formKey}.serviceCharge`, '0.00'), 2)}</td>
                      </tr>
                      <tr>
                        <th>Total (LKR):</th>
                        <td className={"text-right"}>{numberThousandSeperator(_get(formState, `${formKey}.paymentDetails.totalAmount`, '0.00'), 2)}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <DataTable
                      isSetAction={false}

                      tableHeaderList={[
                        { displayName: "Payment Type", key: "payment_type_name", isSort: false, onPrintFn: (rawData) => pdmtPaymentCategories[rawData.payment_type_name] },
                        { displayName: "Amount", key: "payment_type_amount", isSort: false, isRightAlign: true, onPrintFn: (rawData) =>  numberThousandSeperator(rawData.payment_type_amount ? rawData.payment_type_amount : '0.00', 2)}
                      ]}
                      tableBodyList={_get(formState, `${formKey}.payments`, [])}
                      isSetTableFooter={false}
                      isSetTableHeader={false}
                    />
                   
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
              <UICard cardHeading="Printed Details">
                {!isEmpty(_get(formState, `${formKey}.print_history`, []))?
                <DataTable   
                  isSetAction={false}
                  isSetSearchFrom={false}
                  tableHeaderList={[
                    { displayName: "Print Seq. No.", key: "__no__", isSort: false },
                    { displayName: "Print Type", key: "print_type", isSort: false },
                    { displayName: "Date & Time", key: "printed_at", isSort: false, onPrintFn: ({ printed_at }) => isEmpty(printed_at) ? "-" : dateObjectToString(printed_at, "dd/mm/YY HH:MM") },
                    { displayName: "Printed by", key: "printed_by", isSort: false, onPrintFn: (rawData) => (isEmpty(rawData.printed_by) ? "-" : rawData.printed_by) + "(" + (isEmpty(rawData.printed_role_id) ? "-" : rawData.printed_role_id) + ")" },
                    { displayName: "Printed at", key: "printed_location", isSort: false, onPrintFn: (rawData) => (isEmpty(rawData.province) ? "-" : ProvincesPrefixMatcher[rawData.province]) + "(" + (isEmpty(rawData.printed_location) ? "-" : rawData.printed_location) + ")" },
                    { displayName: "Serial Number", key: "serial_number", isSort: false },
                  ]}
                  tableBodyList={_get(formState, `${formKey}.print_history`, [])}
                  isSetTableFooter={false}
                  isSetTableHeader={false}
                  specificComponents={{
                    "__no__": RowNo
                  }}

                />:
                <div className="notificationContentWrapper">
                  <p>No record(s) found.</p>
                </div>}
                </UICard>
              </div>
            </div>
          </div>
          {_get(formState, `${formKey}.reversed_txn`, null) ?
            <div className="col-md-12 defaultMarginTop">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="defaultHeading defaultHalfMarginBottom">
                    Reversed Transaction Details
                  </h1>

                  <div className="row">
                    <div className="col-md-12">
                      <strong>Txn. Reversed Request ID: </strong> {_get(formState, `${formKey}.ui_request_id`, '-')}
                    </div>

                    <div className="col-md-6 d-flex flex-row">
                      <strong>Txn. Reversed Requested:&nbsp;<br/>
                        Date & Time  </strong> <span>{_get(formState, `${formKey}.reversed_at`, null)===null?"-":dateObjectToString(_get(formState, `${formKey}.reversed_at`, null), 'dd/mm/YY HH:MM')}</span>
                    </div>
                    <div className="col-md-6">
                      <strong>Reversal Approved Date & Time: </strong> {_get(formState, `${formKey}.reverse_tnx_details.approved_time`, null)===null?"-":dateObjectToString(_get(formState, `${formKey}.reverse_tnx_details.approved_time`, null), 'dd/mm/YY HH:MM')}
                    </div>

                    <div className="col-md-6">
                      <strong>Requested By: </strong> {_get(formState, `${formKey}.requested_user_name`, '-')}
                    </div>
                    <div className="col-md-6">
                      <strong>Approved By: </strong> {_get(formState, `${formKey}.approved_user_name`, '-')}
                    </div>
                    <div className="col-md-6">
                      <strong>Requested Location: </strong> {_get(formState, `${formKey}.requested_location_name`, '-')}
                    </div>
                    <div className="col-md-6">
                      <strong>Approved Location: </strong> {_get(formState, `${formKey}.approved_location_name`, '-')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : ''
          }
        </div>
      </div>
    </FormWrapper>
  );
};

const NonUserLicence = () => {
  return (
    <div className="fullWidthDiv">
      <PaddingLessUICard cardHeading="Non-User Licence Request">
        <FormWrapper
          elementStyle={""}
          setGroupName={"nonUserKey"}
          setFormObject={{}}
        >
          <div className="row">
            <div className="col-md-6">
              <InputBoxWithState
                labelText={"Issued Organization"}
                formGroupName={"nonUserKey"}
                inputName={"issuedBy"}
                isRequired={true}
              />
            </div>
            <div className="col-md-6">
              <DatePickerWithState
                labelText={"Issued Date"}
                formGroupName={"nonUserKey"}
                inputName={"issuedDate"}
                isRequired={true}
              />
            </div>
            <div className="col-md-12">
              <InputBoxWithState
                labelText={"Address"}
                formGroupName={"nonUserKey"}
                inputName={"issuedLocation"}
                isRequired={true}
              />
            </div>
          </div>
        </FormWrapper>
      </PaddingLessUICard>
    </div>
  );
};

const FreeLicence = () => {
  return (
    <div className="fullWidthDiv">
      <PaddingLessUICard cardHeading="Free Licence Request">
        <FormWrapper
          elementStyle={""}
          setGroupName={"freeUserKey"}
          setFormObject={{}}
        >
          <div className="row">
            <div className="col-md-6">
              <InputBoxWithState
                labelText={"Requested Organization"}
                formGroupName={"freeUserKey"}
                inputName={"requestedBy"}
                isRequired={true}
              />
            </div>
            <div className="col-md-6">
              <DatePickerWithState
                labelText={"Requested Date"}
                formGroupName={"freeUserKey"}
                inputName={"requestedDate"}
                isRequired={true}
              />
            </div>
            <div className="col-md-12">
              <InputBoxWithState
                labelText={"Address"}
                formGroupName={"freeUserKey"}
                inputName={"requestedLocation"}
                isRequired={true}
              />
            </div>
          </div>
        </FormWrapper>
      </PaddingLessUICard>
    </div>
  );
};

const OwnerInfoDisplay = ({ ownerInfo = {}, uiType = "owner_infor" }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <strong>Name: </strong>
        <LimitTextLength
          maxLenth={50}
          displayText={ownerInfo.name}
        />
      </div>
      <div className="col-md-6">
        <strong>Address: </strong>
        <LimitTextLength
          maxLenth={50}
          displayText={`${ownerInfo.address1},${ownerInfo.address2},${ownerInfo.city}.`}
        />
      </div>
      <div className="col-md-6">
        <strong>Mobile Number: </strong>
        <MaskMobile mobile={ownerInfo.mobileNumber} />
      </div>
      <div className="col-md-6">
        <strong>Identification Number: </strong>
        {ownerInfo.identificationNumber}
      </div>
      {uiType === "owner_infor" ? (
        <Fragment>

          <div className="col-md-6">
            <strong>SMS Alerts: </strong>
            {ownerInfo.isSMS}
          </div>

          <div className="col-md-6">
            <strong>Absolute Owner: </strong>
            <LimitTextLength
              maxLenth={50}
              displayText={ownerInfo.absoluteOwner}
            />
          </div>
          <div className="col-md-6">
            <strong>Absolute Owner Address: </strong>
            <LimitTextLength
              maxLenth={50}
              displayText={`${ownerInfo.absoluteOwnerAddress1},${ownerInfo.absoluteOwnerAddress2},${ownerInfo.absoluteOwnerCity}.`}
            />
          </div>


          <div className="col-md-12 defaultMarginTop">
            <DataTable
              tableName="Previous Owner Information"
              elementWrapperStyle={"scrolTable"}
              tableHeaderList={[
                { displayName: "Name", key: "name", isSort: false },
                { displayName: "Identification Number", key: "idNo", isSort: false },
                { displayName: "Mobile Number", key: "mobile", isSort: false },
                { displayName: "Address", key: "address", isSort: false },
                { displayName: "Province", key: "province", isSort: false },
                { displayName: "Transfer Date", key: "trDate", isSort: false },
              ]}
              tableBodyList={_get(ownerInfo, 'previousOwner', [])}
              isSetTableFooter={true}
              isSetTableHeader={true}
              isSetAction={false}
            />
          </div>


        </Fragment>
      ) : null}




    </div>
  );
};

const VehicleInfoDisplay = ({ uiType = "fullInfo", vehicleInfo = {} }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <strong>Chassis: </strong>
        {vehicleInfo.chassis}
      </div>
      <div className="col-md-4">
        <strong>Tire: </strong>
        {vehicleInfo.tare}
      </div>
      <div className="col-md-4">
        <strong>Vehicle Condition: </strong>
        {vehicleInfo.vehicleStatus}
      </div>
      <div className="col-md-4">
        <strong>Engine: </strong>
        {vehicleInfo.engine}
      </div>
      <div className="col-md-4">
        <strong>Gross: </strong>
        {vehicleInfo.gross}
      </div>
      <div className="col-md-4">
        <strong>Fuel Type: </strong> {vehicleInfo.fuelType}
      </div>
      <div className="col-md-4">
        <strong>1st Reg: Date: </strong> {vehicleInfo.regDate1}
      </div>
      <div className="col-md-4">
        <strong>Seats (Exp Driver): </strong> {vehicleInfo.seats}
      </div>
      <div className="col-md-4">
        <strong>Class: </strong>
        {vehicleInfo.class}
      </div>
      <div className="col-md-4">
        <strong>Province: </strong> {vehicleInfo.province}
      </div>
      <div className="col-md-4">
        <strong>Manufacture Year: </strong> {vehicleInfo.manufactureYear}
      </div>
      {uiType === "vehicle_infor" ? (
        <Fragment>
          <div className="col-md-4">
            <strong>Cylinder Capacity: </strong> {vehicleInfo.cylinderCapacity}
          </div>
          <div className="col-md-4">
            <strong>Tire Size: </strong> {vehicleInfo.tareSize}
          </div>
          <div className="col-md-4">
            <strong>Wheel Base: </strong> {vehicleInfo.wheelBase}
          </div>
          <div className="col-md-4">
            <strong>Type Of Body: </strong> {vehicleInfo.typeOfBody}
          </div>
          <div className="col-md-4">
            <strong>Make: </strong> {vehicleInfo.make}
          </div>
          <div className="col-md-4">
            <strong>Model: </strong> {vehicleInfo.model}
          </div>
          <div className="col-md-4">
            <strong>Colors: </strong> {vehicleInfo.colors}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

const OwnerInfoEdit = (props) => {
  const formKey = "searchForm";
  return (
    <FormWrapper
      elementStyle={""}
      setGroupName={formKey}
      setFormObject={props.ownerInfo}
    >
      <div className="row">
        <div className="col-md-6">
          <InputBoxWithState
            isRequired={true}
            labelText={"Name"}
            formGroupName={formKey}
            inputName={"name"}
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            isRequired={true}
            labelText={"Address 1"}
            formGroupName={formKey}
            inputName={"address1"}
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            isRequired={true}
            labelText={"Address 2"}
            formGroupName={formKey}
            inputName={"address2"}
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            isRequired={true}
            labelText={"City"}
            formGroupName={formKey}
            inputName={"city"}
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            labelText={"Mobile Number"}
            formGroupName={formKey}
            inputName={"mobileNumber"}
          />
        </div>
        <div className="col-md-6">
          <InputBoxWithState
            labelText={"Identification Number"}
            formGroupName={formKey}
            inputName={"identificationNumber"}
          />
        </div>
        <div className="col-md-6 defaultHalfMarginLeft">
          <CheckBoxWithState
            formGroupName={formKey}
            inputName={"isSMS"}
            labelText={"SMS Alerts"}
          />
        </div>

        <div className="col-md-12">
          <hr />
          <div className="row">
            <div className="col-md-6">
              <InputBoxWithState
                //isRequired={true}
                labelText={"Absolute Owner Name"}
                formGroupName={formKey}
                inputName={"absoluteOwner"}
              />
            </div>
            <div className="col-md-6">
              <InputBoxWithState
                //isRequired={true}
                labelText={"Absolute Owner Address 1"}
                formGroupName={formKey}
                inputName={"absoluteOwnerAddress1"}
              />
            </div>
            <div className="col-md-6">
              <InputBoxWithState
                //isRequired={true}
                labelText={"Absolute Owner Address 2"}
                formGroupName={formKey}
                inputName={"absoluteOwnerAddress2"}
              />
            </div>
            <div className="col-md-6">
              <InputBoxWithState
                //isRequired={true}
                labelText={"Absolute Owner City"}
                formGroupName={formKey}
                inputName={"absoluteOwnerCity"}
              />
            </div>
          </div>
          <hr />
        </div>

        <div className="col-md-12">
          <InputBoxWithState
            labelText={"Additional Information"}
            formGroupName={formKey}
            isMultiline={true}
            rows={2}
            inputName={"additionalInformation"}
          />

          <hr />
        </div>


        <div className="col-md-12">
          <DataTable
            tableName="Previous Owner Information"
            elementWrapperStyle={"scrolTable"}
            tableHeaderList={[
              { displayName: "Name", key: "name", isSort: false },
              { displayName: "Identification Number", key: "idNo", isSort: false },
              { displayName: "Mobile Number", key: "mobile", isSort: false },
              { displayName: "Address", key: "address", isSort: false },
              { displayName: "Province", key: "province", isSort: false },
              { displayName: "Transfer Date", key: "trDate", isSort: false },
            ]}
            tableBodyList={_get(props, 'ownerInfo.previousOwner', [])}
            isSetTableFooter={true}
            isSetTableHeader={true}
            isSetAction={false}
          />
        </div>


      </div>
    </FormWrapper>
  );
};

const VehicleInfoEdit = () => {
  return (
    <div className="row">
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Chassis"}
          formGroupName={"normalLicence"}
          inputName={"chassis"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Tare"}
          formGroupName={"normalLicence"}
          inputName={"tare"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Vehicle Status"}
          formGroupName={"normalLicence"}
          inputName={"vehicleStatus"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Engine"}
          formGroupName={"normalLicence"}
          inputName={"engine"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Gross"}
          formGroupName={"normalLicence"}
          inputName={"gross"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Fuel Type"}
          formGroupName={"normalLicence"}
          inputName={"fuelType"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"1st Reg"}
          formGroupName={"normalLicence"}
          inputName={"reg1"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Seats (Exp Driver)"}
          formGroupName={"normalLicence"}
          inputName={"seats"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Class"}
          formGroupName={"normalLicence"}
          inputName={"class"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Province"}
          formGroupName={"normalLicence"}
          inputName={"province"}
        />
      </div>
    </div>
  );
};

const OwnerDetailsIcons = (props) => {
  const getDialogTitle = () => {
    if (props.uiType === "owner_infor") {
      return "Owner Information";
    } else {
      return "Vehicle Information";
    }
  };

  const getEditMode = (uiType = null) => {
    if (uiType === "owner_infor") {
      return OwnerInfoEdit;
    } else {
      return VehicleInfoEdit;
    }
  };

  const getViewMode = (uiType = null) => {
    if (uiType === "owner_infor") {
      return OwnerInfoDisplay;
    } else {
      return VehicleInfoDisplay;
    }
  };

  return (
    <Fragment>
      {props.uiType !== "vehicle_infor" ? (
        <RoundButtonWithPopups
          btnName="edit"
          iconClass={"mdi mdi-pencil"}
          btnSize={roundBtnSizes.small}
          dialogProps={{
            dialogTitle: `Edit ${getDialogTitle()}`,
            isCustomButton: true,
            customButtonComponent: EditBtn
          }}
          modelComponent={getEditMode(props.uiType)}
          propsToModel={props}
        />
      ) : null}

      <RoundButtonWithPopups
        btnName="viewmore"
        iconClass={"mdi mdi-magnify-plus-outline"}
        btnSize={roundBtnSizes.small}
        dialogProps={{
          dialogTitle: `View ${getDialogTitle()}`,
        }}
        modelComponent={getViewMode(props.uiType)}
        propsToModel={props}
      />
    </Fragment>
  );
};

const ViewLicenseArrears = ({ licenseArrearsList = [], grandTotal = "0.00" }) => {
  return (
    <Fragment>
      <DataTable
        tableName={"Licence Arrears (LKR)"}
        tableHeaderList={[
          { displayName: "From", key: "from", isSort: false },
          { displayName: "To", key: "to", isSort: false },
          { displayName: "Total Fee (LKR)", key: "fee", isSort: false },
          { displayName: "Total Fine (LKR)", key: "fine", isSort: false },
          { displayName: "Additions (LKR)", key: "addition", isSort: false },
          { displayName: "Deduction (LKR)", key: "deduction", isSort: false },
          { displayName: "Total Arrears (LKR)", key: "arrears", isSort: false },
        ]}
        tableBodyList={licenseArrearsList}
        isSetTableFooter={true}
        isSetTableHeader={true}
        isSetAction={false}
      />
      <div className="col-md-12 floatLeft defaultMarginTop">

        <strong>Grand Total(LKR):</strong> {grandTotal}
      </div>
    </Fragment>
  );
};

const CommentsHistory = () => {
  return (
    <div className="commentHistoryWrapper">
      <div className="commentElementWrapper">
        <div className={"row"}>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Commented By: </strong> Roshan Liyanage
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Commented At: </strong> 09/10/2020 13:05
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Authority: </strong> Kaduwela
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Province: </strong> Western Province
            </h1>
          </div>
        </div>

        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec
        </p>
      </div>
      <div className="commentElementWrapper">
        <div className={"row"}>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Commented By: </strong> Roshan Liyanage
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Commented At: </strong> 07/10/2020 10:00
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Authority:</strong> Kaduwela
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Province: </strong> Western Province
            </h1>
          </div>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec
        </p>
      </div>
      <div className="commentElementWrapper">
        <div className={"row"}>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Commented By: </strong> Roshan Liyanage
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Commented At: </strong> 06/10/2020 16:00
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Authority: </strong> Kaduwela
            </h1>
          </div>
          <div className={"col-md-6"}>
            <h1 className="defaultHeading">
              <strong>Province: </strong> Western Province
            </h1>
          </div>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec
        </p>
      </div>


    </div>
  );
};

const LicenseArrearsIcons = (props) => {
  return (
    <Fragment>
      <RoundButtonWithPopups
        btnName="viewmore"
        iconClass={"mdi mdi-magnify-plus-outline"}
        btnSize={roundBtnSizes.small}
        dialogProps={{
          dialogTitle: ` ${props.uiType == "comments" ? "Comments" : "View Licence Arrears"
            } `,
        }}
        modelComponent={
          props.uiType == "comments" ? CommentsHistory : ViewLicenseArrears
        }
        propsToModel={props}
      />
    </Fragment>
  );
};

const LicencePeriodIcons = ({ uiType = null, formGroupName = null, onChangeStatusFn = emptyFun }) => {
  return (
    <Fragment>
      <CheckBoxWithState
        labelText={uiType === "paymentHistory" ? "View All" : "Re issuance"}
        formGroupName={formGroupName}
        labelPlacement={checkBoxLabelPlacement.start}
        inputName={uiType === "paymentHistory" ? "viewAll" : "isReIssuance"}
        onChangeFn={() => {
          if (uiType === "paymentHistory") {
            emptyFun();
          } else {
            onChangeStatusFn();
          }
        }}
      />
    </Fragment>

  );
};

const LicenseElement = ({
  getState = {
    searchKey: "ks-5555",
    currentObject: null,
  },
  setState = emptyFun,
  uiType = "normalLicense",
  setShowHideFooterFN = emptyFun
}) => {
  return (
    <FormWrapper
      setGroupName={"normalLicence"}
      elementStyle={"normalLicenceWrapper"}
      setFormObject={{
        "isVerifyRegister": _get(getState.currentObject, 'documentValidation.isVerifyRegister', false),
        "licenceValidityeriodTo": _get(getState.currentObject, 'status.currentLicenceStatus', "1") === "2" ? "1" : "0",
        "vet_no": _get(getState.currentObject, 'documentValidation.VETNo', ""),
        "isVet": _get(getState.currentObject, 'documentValidation.checkVET', false),
        "vetCompany": _get(getState.currentObject, 'documentValidation.VETCompany', ""),
        "insurance": _get(getState.currentObject, 'documentValidation.insuranceCompany', ""),
        "isInsurance": _get(getState.currentObject, 'documentValidation.checkInsurance', false),
        "fitnessCompany": _get(getState.currentObject, 'documentValidation.FitnessCompany', ""),
        "certNo": _get(getState.currentObject, 'documentValidation.FitnessNo', ""),
        "isfitness": _get(getState.currentObject, 'documentValidation.checkFitness', false),
        "routePermComapny": _get(getState.currentObject, 'documentValidation.RoutePermCompany', "0"),
        "vrpNo": _get(getState.currentObject, 'documentValidation.RoutePermNo', ""),
        "isroutePerm": _get(getState.currentObject, 'documentValidation.checkRoutePerm', false),
      }}
    >
      <div className="col-md-6 floatLeft">
        <div className="fullWidthDiv">
          <PaddingLessUICard
            cardHeading="Owner Information"
            passPropsToCardHeadingTail={{
              uiType: "owner_infor",
              formGroupName: "normalLicence",
              ownerInfo: _get(getState.currentObject, `ownerInformation`, {}),
            }}
            cardHeadingTail={OwnerDetailsIcons}
          >
            <OwnerInfoDisplay
              ownerInfo={_get(getState.currentObject, `ownerInformation`, {})}
              uiType={"ownerInfo"}
            />
          </PaddingLessUICard>
        </div>

        <div className="fullWidthDiv">
          <PaddingLessUICard
            cardHeading="Vehicle Information"
            passPropsToCardHeadingTail={{
              uiType: "vehicle_infor",
              formGroupName: "normalLicence",
              vehicleInfo: _get(
                getState.currentObject,
                `vehicleInformation`,
                {}
              ),
            }}
            cardHeadingTail={OwnerDetailsIcons}
          >
            <VehicleInfoDisplay
              uiType={"summary"}
              vehicleInfo={_get(
                getState.currentObject,
                `vehicleInformation`,
                {}
              )}
            />
          </PaddingLessUICard>
        </div>

        {(uiType === "normalLicense" || uiType === "quotation") ? (
          <div className="fullWidthDiv">
            <PaddingLessUICard
              cardHeading="Payment History"
              cardHeadingTail={LicencePeriodIcons}
              passPropsToCardHeadingTail={{
                uiType: "paymentHistory",
                formGroupName: "normalLicence",
              }}
            >
              <DataTable
                elementWrapperStyle={"scrolTable paymentHistoryTable"}
                tableHeaderList={[
                  { displayName: "Year", key: "year", isSort: false },
                  { displayName: "Tx. No", key: "txNum", isSort: false },
                  { displayName: "Lic No/Rec No", key: "regNo", isSort: false },
                  {
                    displayName: "Validity Period (From - To)",
                    key: "validity",
                    isSort: false,
                  },
                  { displayName: "Fee (LKR)", key: "fee", isSort: false },
                  { displayName: "Fine (LKR)", key: "fine", isSort: false },
                  { displayName: "Additions (LKR)", key: "additions", isSort: false },
                  { displayName: "Deductions (LKR)", key: "deductions", isSort: false },
                  { displayName: "Total (LKR)", key: "total", isSort: false },
                  {
                    displayName: "Lic. Type",
                    key: "licence",
                    isSort: false,
                  },
                  {
                    displayName: "User's Name",
                    key: "user",
                    isSort: false,
                  },
                  {
                    displayName: "Location",
                    key: "location",
                    isSort: false,
                  },
                  {
                    displayName: "Tx. Type",
                    key: "transType",
                    isSort: false,
                  },
                  {
                    displayName: "Tx. Date & Time",
                    key: "transDate",
                    isSort: false,
                  },
                ]}
                tableBodyList={_get(
                  getState.currentObject,
                  `licensePaymentHistoryList`,
                  []
                )}
                isSetTableFooter={true}
                isSetTableHeader={false}
                actionTypeList={[
                  {
                    actionType: actionBtnTypes.view,
                    modelContentElement: ViewTransaction,
                    dialogPopupProps: {
                      isFullScreen: false,
                      dialogTitle: "View Licence Transaction Details",
                      isCustomButton: false,
                      closeButton: {
                        btnText: "Close",
                        startIcon: "far fa-times-circle",
                      },
                    },
                  },
                ]}
                onRowStyleFn={(data) => data.row.type}
              />

              <div className="fullWidthDiv defaultHalfMarginTopBottom">
                <div className="row">
                  <div className="col-md-2 floatLeft">
                    <strong>Reversed: </strong>{" "}
                    <i class="fas fa-square-full" style={{ color: "gray" }}></i>
                  </div>
                  <div className="col-md-3 floatLeft">
                    <strong>Pending Approval: </strong>{" "}
                    <i
                      class="fas fa-square-full"
                      style={{ color: "yellow" }}
                    ></i>
                  </div>
                  <div className="col-md-3 floatLeft">
                    <strong>Canceled: </strong>{" "}
                    <i
                      class="fas fa-square-full"
                      style={{ color: "red" }}
                    ></i>
                  </div>
                </div>
              </div>
            </PaddingLessUICard>
          </div>
        ) : (
          <div className="fullWidthDiv">
            <PaddingLessUICard
              cardHeading="Payment History"
              cardHeadingTail={LicencePeriodIcons}
              passPropsToCardHeadingTail={{
                uiType: "paymentHistory",
                formGroupName: "normalLicence",
              }}
            >
              <DataTable
                elementWrapperStyle={"scrolTable"}
                tableHeaderList={[
                  { displayName: "Tx. No:", key: "txNum", isSort: false },
                  {
                    displayName: "Tx Date & Time",
                    key: "transDate",
                    isSort: false,
                  },
                  { displayName: "Lic No", key: "regNo", isSort: false },
                  {
                    displayName: "Validity Period (From - To)",
                    key: "validity",
                    isSort: false,
                  },
                  { displayName: "Fee (LKR)", key: "fee", isSort: false },
                  { displayName: "Additions (LKR)", key: "additions", isSort: false },
                  { displayName: "Deductions (LKR)", key: "deductions", isSort: false },
                  { displayName: "Location", key: "location", isSort: false },
                ]}
                tableBodyList={_get(
                  getState.currentObject,
                  `licensePaymentHistoryList`,
                  []
                )}
                isSetTableFooter={true}
                isSetTableHeader={false}
                actionTypeList={[
                  {
                    actionType: actionBtnTypes.view,
                    modelContentElement: ViewTransaction,
                    dialogPopupProps: {
                      isFullScreen: false,
                      dialogTitle: "View Licence Transaction Details",
                      isCustomButton: false,
                      closeButton: {
                        btnText: "Close",
                        startIcon: "far fa-times-circle",
                      },
                    },
                  },
                ]}
              />
            </PaddingLessUICard>
          </div>
        )}

        <div className="fullWidthDiv">
          <PaddingLessUICard
            cardHeading="Comments"
            passPropsToCardHeadingTail={{
              uiType: "comments",
              formGroupName: "normalLicence",
            }}
            cardHeadingTail={LicenseArrearsIcons}
          >
            <div className="row">
              <div className="col-md-12">
                <InputBoxWithState
                  isMultiline={true}
                  rows={1}
                  labelText={"Comments"}
                  formGroupName={"normalLicence"}
                  inputName={"comments"}
                />
              </div>
              <div className="col-md-12 text-right">
                <InputButton
                  elementWrapperStyle={"defaultHalfMarginLeftRight"}
                  btnText="Save"
                />
                <InputButton
                  elementWrapperStyle={"defaultHalfMarginLeftRight"}
                  btnText="Cancel"
                />
              </div>
            </div>
          </PaddingLessUICard>
        </div>
      </div>

      <div className="col-md-6 floatRight">

        <DocumentValidation uiType={uiType} getState={getState} setState={setState} setShowHideFooterFN={setShowHideFooterFN} />


        {(uiType === "normalLicense" || uiType === "quotation") ? (
          <div className="fullWidthDiv">
            {getState["licenseType"] === "2" ? (
              <FreeLicence />
            ) : getState["licenseType"] === "3" ? (
              <NonUserLicence />
            ) : null}


            {
              (_get(getState.currentObject, 'licenseArrears.isShow', true) === true) ? (
                <div className="fullWidthDiv">
                  <PaddingLessUICard
                    cardHeading="Licence Arrears"
                    passPropsToCardHeadingTail={{
                      uiType: "licenseArrears",
                      formGroupName: "normalLicence",
                      licenseArrearsList: _get(
                        getState.currentObject,
                        `licenseArrears.licenseArrearsList`,
                        {}
                      ),
                      grandTotal: _get(
                        getState.currentObject,
                        `licenseArrears.grandTotal`,
                        "0.00"
                      ),
                    }}
                    cardHeadingTail={LicenseArrearsIcons}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <strong>From: </strong>
                        {_get(getState.currentObject, `licenseArrears.from`, "")}
                      </div>
                      <div className="col-md-4">
                        <strong>To: </strong>
                        {_get(getState.currentObject, `licenseArrears.to`, "")}
                      </div>
                      <div className="col-md-4">
                        <strong>Total Fee (LKR): </strong>
                        {_get(
                          getState.currentObject,
                          `licenseArrears.totalFee`,
                          "00.00/="
                        )}
                      </div>
                      <div className="col-md-4">
                        <strong>Total Fine (LKR): </strong>
                        {_get(
                          getState.currentObject,
                          `licenseArrears.totalFine`,
                          "00.00/="
                        )}
                      </div>
                      <div className="col-md-4">
                        <strong>Additions (LKR): </strong>
                        {_get(
                          getState.currentObject,
                          `licenseArrears.additions`,
                          "00.00/="
                        )}
                      </div>
                      <div className="col-md-4">
                        <strong>Deductions (LKR): </strong>
                        {_get(
                          getState.currentObject,
                          `licenseArrears.deduction`,
                          "00.00/="
                        )}
                      </div>
                      <div className="col-md-4">
                        <strong>Total Amount (LKR): </strong>
                        {_get(
                          getState.currentObject,
                          `licenseArrears.totalAmount`,
                          "00.00/="
                        )}
                      </div>
                    </div>
                  </PaddingLessUICard>
                </div>
              ) : (null)
            }


          </div>
        ) : (null)}

        <div className="fullWidthDiv">
          <LicenceValidityPeriod uiType={uiType} getState={getState} setState={setState} />
        </div>

        <div className="fullWidthDiv">
          <PaddingLessUICard cardHeading="Payment Amount">
            <table style={{ width: "100%" }}>
              <tr>
                <th>Fee (LKR)</th>
                <td className="text-right">2,200.00</td>
              </tr>
              <tr>
                <th>Fine (LKR)</th>
                <td className="text-right">220.00</td>
              </tr>
              <tr>
                <th>Arrears Fee (LKR)</th>
                <td className="text-right">00.00</td>
              </tr>
              <tr>
                <th>Arrears Fine (LKR)</th>
                <td className="text-right">00.00</td>
              </tr>
              <tr>
                <th>Additions (LKR)</th>
                <td className="text-right">00.00</td>
              </tr>
              <tr>
                <th>Deductions (LKR)</th>
                <td className="text-right">(00.00)</td>
              </tr>
              <tr>
                <th></th>
                <td className="text-right"></td>
              </tr>
              <tr>
                <th>Total Amount (LKR)</th>
                <td className="text-right">
                  <b>2,420.00</b>
                </td>
              </tr>
            </table>
          </PaddingLessUICard>
        </div>
      </div>
    </FormWrapper>
  );
};

const NormalLicense = ({
  setShowHideFooterFN = emptyFun,
  addNewQuotationFn = emptyFun,
  uiType = "normalLicense",
}) => {

  const [getState, setState] = useState({
    searchKey: "ks-5555",
    searchBy: "1",
    licenseType: "1",
    quotationType: "1",
    onPageLord: null,
    currentObject: null,
    isDuplicateVehicle: false,
    pendingWorkflow: false,
  });


  const onSearch = () => {

    setState({
      ...getState,
      onPageLord: false,
    });

    setTimeout(() => {

      if (getState.searchKey.toLowerCase() === "ks-6666") {

        setState({
          ...getState,
          isDuplicateVehicle: true,
          currentObject: null,
          onPageLord: true,
        });

      } else if (uiType === "quotation" && getState.quotationType === "2") {

        if (quotationID.hasOwnProperty(getState.searchKey.toLowerCase())) {

          const currentObj = _get(normalLicenseDummy, _get(quotationID, `${getState.searchKey}.vehicalID`, ""), {});

          setState({
            ...getState,
            currentObject: currentObj,
            onPageLord: true,
          });
          setShowHideFooterFN(true, {
            issueMode: _get(currentObj, `status.issueMode`, "1"),
            isPendingWorkFlow: _get(currentObj, `status.isPendingWorkFlow`, false),
            payArrearsOnly: _get(currentObj, `status.payArrearsOnly`, true)
          });


        } else {
          setState({
            ...getState,
            currentObject: "none",
            onPageLord: true,
          });
          setShowHideFooterFN(false);
        }


      } else {
        if (
          normalLicenseDummy.hasOwnProperty(
            getState.searchKey.toLowerCase()
          )
        ) {
          setState({
            ...getState,
            currentObject: _get(
              normalLicenseDummy,
              getState.searchKey,
              {}
            ),
            licenseType: _get(
              normalLicenseDummy,
              `${getState.searchKey}.status.currentLicenceType`,
              "1"
            ),
            onPageLord: true,
          });
          setShowHideFooterFN(true, {
            issueMode: _get(normalLicenseDummy, `${getState.searchKey}.status.issueMode`, "1"),
            isPendingWorkFlow: _get(normalLicenseDummy, `${getState.searchKey}.status.isPendingWorkFlow`, false),
            payArrearsOnly: _get(normalLicenseDummy, `${getState.searchKey}.status.payArrearsOnly`, true)
          });
        } else {
          setState({
            ...getState,
            currentObject: "none",
            onPageLord: true,
          });
          setShowHideFooterFN(false);
        }
      }


    }, 800);
  }


  return (
    <div className="normalLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper">
          <FormWrapper elementStyle={""} setGroupName={"searchFromKey"}>
            <div
              className={`searchInputWrapper ${uiType === "quotation" ? "quotationSearch" : ""
                }`}
            >
              <label className="control-labe">{`${uiType === "quotation" ? "Search By:" : "Vehicle ID:"
                }`}</label>

              {uiType === "quotation" ? (
                <select
                  value={getState.quotationType}
                  onChange={(event) =>
                    setState({
                      ...getState,
                      quotationType: event.target.value,
                    })
                  }
                >
                  <option value="1">Vehicle ID</option>
                  <option value="2">Quotation ID</option>
                </select>
              ) : null}

              <input
                type="text"
                className="form-control"
                value={getState.searchKey}
                onChange={(event) =>
                  setState({
                    ...getState,
                    searchKey: event.target.value,
                  })
                }
              />
              <InputButton
                btnName="search"
                onClickBtnFn={() => onSearch()}
                overrideTextToIcon={
                  <i className="mdi mdi-magnify-plus-outline" />
                }
              />
            </div>
          </FormWrapper>
        </div>

        {(getState.currentObject !== null && getState.currentObject !== "none" && getState.onPageLord === true) ? (
          <div className="statusWrapper">
            <ul className="statusUL">


              {
                (_get(getState, 'currentObject.status.isPendingWorkFlow', false) === true) ? (
                  <li className="setBorder">
                    <InputButton
                      btnName="pendingWorkflow"
                      btnText={"Pending Workflow"}
                      onClickBtnFn={() => setState({
                        ...getState,
                        pendingWorkflow: true
                      })}
                    />
                  </li>
                ) : (null)
              }



              <li className="setBorder"><TooltipWrapper description={"Current Vehicle Status"} ><span>{_get(statusObject, `currentVehicleStatus.${_get(getState, 'currentObject.status.currentVehicleStatus')}`, '')}</span></TooltipWrapper></li>

              <li className="setBorder"><TooltipWrapper description={"Current Licence Status"} ><span>{_get(statusObject, `currentLicenceStatus.${_get(getState, 'currentObject.status.currentLicenceStatus')}`, '')}</span></TooltipWrapper></li>
              {
                (!(uiType === "carnet" || _get(getState, 'currentObject.status.printingStatus') === "2")) ? (
                  <li className="setBorder"><TooltipWrapper description={"Printing Status"} ><span>{_get(statusObject, `printingStatus.${_get(getState, 'currentObject.status.printingStatus')}`, '')}</span></TooltipWrapper></li>
                ) : (null)
              }


              {
                (uiType !== "carnet") ? (
                  <li className="setBorder">
                    <select
                      value={getState.licenseType}
                      onChange={(event) =>
                        setState({
                          ...getState,
                          licenseType: event.target.value,
                        })
                      }
                    >
                      <option value="1">Normal </option>
                      <option value="2">Free</option>
                      <option value="3">Non-User</option>
                    </select>
                  </li>
                ) : (null)
              }

              {
                (_get(getState, 'currentObject.status.issueMode') !== "1") ? (
                  <li className="setBorder"><TooltipWrapper description={"Current Licence Issue Mode"} ><span>{_get(statusObject, `issueMode.${_get(getState, 'currentObject.status.issueMode')}`, '')}</span></TooltipWrapper></li>

                ) : (null)
              }



              {
                (uiType === "normalLicense") ? (
                  <li>
                    <RequestFor />
                  </li>
                ) : (null)
              }

            </ul>
          </div>
        ) : null}
      </div>

      {(getState.currentObject === null || getState.currentObject === "none" || getState.onPageLord === false) ? (
        <div className="col-md-12 defaultPaddingTop floatLeft">
          {
            (getState.onPageLord === false) ? (
              <Fragment>
                <center>
                  <CircleLoaderElement />
                </center>
              </Fragment>
            ) : (<p>No result found</p>)
          }
        </div>
      ) : (
        <div className="fullWidthDiv">


          {
            (uiType === "quotation" && getState.quotationType === "2") ? (<QuotationUI />) : (<LicenseElement
              getState={getState}
              setState={setState}
              uiType={uiType}
              setShowHideFooterFN={(status, mergeWithPerent) => setShowHideFooterFN(status, mergeWithPerent)}
            />)
          }



          {uiType === "quotation" && getState.quotationType === "1" ? (
            <div className="col-md-12 floatLeft quotationFooter">
              <div className="row">
                <div className="col-md-6 floatLeft">
                  <Fragment>
                    <InputButton
                      elementWrapperStyle={"defaultHalfMarginLeftRight"}
                      btnName="submit"
                      btnText={"Activity Log"}
                    />
                    <InputButton
                      elementWrapperStyle={"defaultHalfMarginLeftRight"}
                      btnName="submit"
                      btnText={"View/Upload Documents"}
                    />
                  </Fragment>
                </div>
                <div className="col-md-6 floatLeft text-right">
                  <InputButton
                    elementWrapperStyle={"defaultHalfMarginLeftRight"}
                    btnName="submit"
                    btnText={"Add Vehicle"}
                    onClickBtnFn={() => addNewQuotationFn()}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <DuplicateVehicleModel
        getState={getState}
        setState={setState}
        setShowHideFooterFN={setShowHideFooterFN}
      />
    </div>
  );
};

export {
  NormalLicense,
  LicenseElement,
  OwnerInfoDisplay,
  VehicleInfoDisplay,
  OwnerInfoEdit,
  VehicleInfoEdit,
  OwnerDetailsIcons,
  ViewLicenseArrears,
  CommentsHistory,
  LicenseArrearsIcons,
  LicencePeriodIcons,
  ViewTransaction,
  RequestFor
};
