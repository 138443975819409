/*
 * @Author: Gajani Kangesan
 * @Date: 2022-02-02 10:30:00
 * @Last Modified by:  Gajani Kangesan
 * @Last Modified time:2022-02-02 10:30:00
 */


const reportGenerationMaxRangeMonths = 1;
const reportGenerationMaxRangeYears = 12;

const reportDataLimit = 1000;
const reportDataOffset = 0;

const internalReport = {
    name: `internal Report`,
    code: "",
};
export {
    reportDataLimit,
    reportGenerationMaxRangeMonths,
    reportDataOffset,
    reportGenerationMaxRangeYears
};