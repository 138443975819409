import React, { useContext,useEffect, useState } from 'react';
import {DataTable,actionBtnTypes, RawIndexMemo} from "../../../../../ui-components/ui-elements/table/DataTable";
import {SearchElement} from "./SearchElement";
import {ViewTransaction} from "../../includes/ViewTransaction"
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { webLicencePrintListAPI } from '../../../../../../config/apiUrl.config';
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import { _getInt } from '../../../../../../helpers/common-helpers/lodash.wrappers';
import { setThousandSeparator } from '../WebLicence';
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { getLabelValue, isEmptyValue } from '../../../../../../helpers/common-helpers/common.helpers';
import { AuthContext } from '../../../../core/context-providers/AuthContext.provider';

const RowNo = (props) => {
  let index = props.rowIndex;
  return (
      <RawIndexMemo
          page_size={_getInt(props.pagingObject, "per_page", 1)}
          current_page={_getInt(props.pagingObject, "current_page", 1)}
          index={index || 0}
      />
  );
};


const PrintedEnvelopeUI=()=>{

    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const [showTable,setShowtable] =useState(true);
    const pdmt_id = _get(authStatus, "authUser.pdmtId.id", "");

    useEffect(() => {
        formAction.removeFromGroupFn('webLicencePrintListAPIKeyPrintedEnvelope');
    }, []);
	// for after  Success  
	useEffect(() => {
		if(_get(formState, `webLicencePrintListAPIKeyPrintedEnvelope.ShouldRefreh`, false)){
            setShowtable(false)
            setTimeout(() => {
                setShowtable(true) 
            }, 200);
            formAction.changeInputFn('webLicencePrintListAPIKeyPrintedEnvelope', "ShouldRefreh", "", false);
		}
	  }, [_get(formState, `webLicencePrintListAPIKeyPrintedEnvelope.ShouldRefreh`, false)]);
    return (
        <div>
        {showTable ?(
        <DataTable
        elementWrapperStyle={"weblicence"}
        apiUrl={`${webLicencePrintListAPI.url}/print-list?sort_by=created_at|asc&print_count=1&envelop_printed=false&licnece_status=OPN&pdmt_id=${pdmt_id}`}
        dataTableKey={`${webLicencePrintListAPI.key}PrintedEnvelope`}
        isSetSearchFrom={true}
        isSelectRow={true}
        searchFromComponent={<SearchElement dataTableKey={`${webLicencePrintListAPI.key}PrintedEnvelope`}  uiType="printed-envelope" />}
        defaultSearchFormObject={{
            "vehicle_no": '',
            "reference_number": '',
            "tracking_id": '',
            "delivery_method": '',
            "from": null,
            "to": null,
        }}
        onSetSearchObjectFn={(searchObject) => {
            let tempSearchObject = {...searchObject};
                
            if (!isEmptyValue(searchObject.from)) {                           
                tempSearchObject["from"] = dateObjectToString(searchObject.from, "YY-mm-dd");                            
            }

            if (!isEmptyValue(searchObject.to)) {                                                      
                tempSearchObject["to"] = dateObjectToString(searchObject.to, "YY-mm-dd");
            }                        
            return tempSearchObject;
        }}
        tableHeaderList={[
          { displayName: "No.", key: "__no__", isSort: false},
          { displayName: "Tracking ID", key: "tracking_id", isSort: false },
          { displayName: "Vehicle ID", key: "vehicle_no", isSort: false },
          { displayName: "Licence Number", key: "licence_no", isSort: false },
          { displayName: "Owner Name", key: "owner_name", isSort: false, onPrintFn: ({owner_name}) => getLabelValue(owner_name, true) },
          { displayName: "Tx. Date & Time", key: "transaction_created_at", isSort: false, 
          onPrintFn: ({transaction_created_at}) => 
          transaction_created_at == null ? '-' : 
          dateObjectToString(transaction_created_at, "dd/mm/YY HH:MM UTC") },
          { displayName: "Delivery Method", key: "delivery_method_name", isSort: false },
          { displayName: "Validity Period (From – To)", key: "validityPeriod", isSort: false, 
            onPrintFn:  (rowData) => `${dateObjectToString(rowData.licence_valid_from, "dd/mm/YY")} - ${dateObjectToString(rowData.licence_valid_to, "dd/mm/YY")}` 
          },
          { displayName: "Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, 
              onPrintFn: ({fee}) => isEmptyValue(fee) ? '0.00' : setThousandSeparator(fee, 2)
          },
          { displayName: "Fine (LKR)", key: "fine", isSort: false, isRightAlign: true, 
              onPrintFn: ({fine}) => isEmptyValue(fine) ? '0.00' : setThousandSeparator(fine, 2)
          },
          { displayName: "Delivery Fee (LKR)", key: "delivery_charge", isSort: false, isRightAlign: true, 
              onPrintFn: ({delivery_charge}) => isEmptyValue(delivery_charge) ? '0.00' : setThousandSeparator(delivery_charge, 2)
          },
          { displayName: "Additions (LKR)", key: "additions", isSort: false, isRightAlign: true, 
              onPrintFn: ({additions}) => isEmptyValue(additions) ? '0.00' : setThousandSeparator(additions, 2)
          },
          { displayName: "Deductions (LKR)", key: "deductions", isSort: false, isRightAlign: true, 
              onPrintFn: ({deductions}) => isEmptyValue(deductions) ? '0.00' : setThousandSeparator(Math.abs(deductions), 2)
          },
          { displayName: "Total (LKR)", key: "total", isSort: false, isRightAlign: true, 
              onPrintFn: (rowData) => 
              setThousandSeparator(
                (
                parseFloat(rowData.fee) + 
                parseFloat(rowData.fine) + 
                parseFloat(rowData.delivery_charge) + 
                parseFloat(rowData.additions) + 
                parseFloat(rowData.deductions)
                ), 2) 
          },
        ]}
        specificComponents={{
            __no__: RowNo,
        }}
        isSetAction={true}  
        actionTypeList={[
            {
                actionType: actionBtnTypes.view,  
                actionBtn:{tooltip:"View More"},
                modelContentElement: ViewTransaction,
                dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "View Licence Details",
                    isCustomButton: false,
                    closeButton: {
                      btnText: "Close",
                      startIcon: "far fa-times-circle",
                    },
                },
                parentToModelProps: {
                  "uiType": "web-licence",
                  "licenceType": "web-licence",
                  formKey: `${webLicencePrintListAPI.key}PrintedEnvelopeView`,
                },                
            },
        ]}       
        />):null}
        </div> 
        
    )
}

export {PrintedEnvelopeUI}