/*
 * @Author: Chamith
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-15 10:39:54
 */

import React, { Fragment } from "react";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { ViewDetailBlock } from "../../../../../ui-components/ui-elements/common/BaseElements";

const EnvelopPreview = ({ envelopData }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-6 defaultMarginLeftRight">
          <h5>
            <strong>{_get(envelopData, "tracking_id", "-")}</strong>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 defaultMargin">
          <h5>
            <strong>{_get(envelopData, "revenue_licence_number", "-")}</strong>
          </h5>
        </div>
      </div>
      <div className="row defaultHalfMarginTop">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <ViewDetailBlock
                label={"From"}
                value={_get(envelopData, "province_office_name_L1", "-")}
              />
            <ViewDetailBlock
                value={_get(envelopData, "province_office_name_L2", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "provice_office_address_L1", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "provice_office_address_L2", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "provice_office_address_L3", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "provice_office_address_L3", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "phone", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "province_office_web_address", "-")}
              />
            </div>

            <div className="col-md-6">
              <ViewDetailBlock
                labelWrapperStyle={"ClassName"}
                label={"To"}
                value={_get(envelopData, "owner_name_L1", null)}
              />
              <ViewDetailBlock
                value={_get(envelopData, "owner_name_L2", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "owner_name_L3", "-")}
              />
              <ViewDetailBlock
                value={_get(envelopData, "owner_address_L1", null)}
              />
              <ViewDetailBlock
                value={_get(envelopData, "owner_address_L2", null)==="null "?"":_get(envelopData, "owner_address_L2", null)}
              />
              <ViewDetailBlock
                value={_get(envelopData, "owner_address_L3", null)}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export { EnvelopPreview };
