import React, { Fragment, useContext, useState, useEffect } from "react";
import { FormWrapper,SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { actionBtnTypes, DataTable } from "../../../../../ui-components/ui-elements/table/DataTable";
import { Reason } from "../../../../request-for-approval/pages/includes/Reason";
import DocumentsUploader from "../../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { uploadDetailGetAPI, getDealerVehicleDetailsAPI, dealerPaymentHistoryAPI, dealerCancelLicenceReceiptTransactionAPI,getDealerDetailsAPI, getDealerVehicleDetailsOrganisationServiceAPI, getDealerVehicleTrxnDetailsAPI } from "../../../../../../config/apiUrl.config";
import { Action } from "../../../../request-for-approval/pages/includes/Action";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { _get, _set } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject, thousandSeparator } from "../../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { RequestType, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../../../config/enum.config";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { CoreContext } from "../../../../core/context-providers/CoreContext.provider";

const CancelDlrLcnSubmitBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);

  const { formKey } = props;
  const dealer_id = _get(formState, "dealer_search_form.searchKey", null);
  const [authStatus] = useContext(AuthContext);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const approvalAction = _get(
    formState,
    `cancel_licence_transaction.action`,
   null
  )
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const request_id = _get(formState, "cancel_licence_transaction.request_id", null);
  const selectedVehicles = _get(formState, getDealerVehicleDetailsAPI.key, []) && _get(formState, getDealerVehicleDetailsAPI.key, []).length !== 0 && _get(formState, getDealerVehicleDetailsAPI.key, []).map(vehicle => {
    console.log('VEH--', vehicle);
    return {
      vehicle_no: 'DEALER_VEHICLES',
      province_id: vehicle.pdmt_id,
      authority_id: vehicle.authority_id,
      transaction_number: vehicle.txn_number,
      transaction_date: vehicle.created_date_time,
      total: vehicle.total_amount
    }
  })

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ? 
  _get(authStatus, "authUser.provinceName", "<anonymous province>") : 
  ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={"Submit"}
        formGroupName={"cancel_licence_transaction"}
        tooltip={"Submit"}
        isValidate={true}
        startIcon={"far fa-save"}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
        } : {
            status: true,
            message: `Approval Request successfully submitted. Request Id: ${request_id}`,
            messageType: "success",
          },
        //   failed: props.uiType === 'assign&Perform' ? {
        //     status: true,
        //     message: `Approval Request Decision Submit Failed. Please Try Again.`,
        //     messageType: "error",
        // } : {
        //     status: true,
        //     message: `Approval Request Submit Failed. Please Try Again.`,
        //     messageType: "error",
        //   },
        }}
        validationObject={{
          fileds: {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action"
          },
          rules: {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required"
          },
          message: {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
            "action.required": "Action is required."
          },
        }}
        onGetAPIEndPointFn={() => {
          return {
            url: props.uiType === 'assign&Perform' ? `${dealerCancelLicenceReceiptTransactionAPI.url}/${props.dataSubmittedForApproval.id}` : `${dealerCancelLicenceReceiptTransactionAPI.url}/${dealer_id}`,
            key: dealerCancelLicenceReceiptTransactionAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if(props.uiType === 'assign&Perform') {
            if(_get(formState, "cancel_licence_transaction.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                  status: false,
                  message: `Action is required`,
                  messageType: "error",
                });

                return
          }
          return getDataByFormObject({
            ...fromObject,
            userName: userName,
            currentProvince: currentProvince,
            authority: authority,
            requestType: RequestTypesList["cncl_lcn_txn"],
            // request_id: fromObject.request_id,
            current_licence_type: current_licence_type,
            request_type: RequestType.Licence_Receipt_Reprint,
            pdmt_id: pdmtId,
            // authority_id: authorityId,
            ui_request_id: props.approvalRequestId,
            reason_id: formState['cancel_licence_transaction-new'].reason_id,
            counter_session_id: '00001',
            forward_authority_service_id: serviceId,
            document_details: [],
            action: fromObject.action == 'FRD' ? {
              action: fromObject.action,
              remark: fromObject.remark,
              forward_province: fromObject.forward_province,
              forward_role: fromObject.forward_role,
              forward_authority: fromObject.forward_authority
            } : {
              action: fromObject.action,
              remark: fromObject.remark,
            },
            selected_vehicles: selectedVehicles
          });
        } else {
          return getDataByFormObject({
            ...fromObject,
            userName: userName,
            currentProvince: currentProvince,
            authority: authority,
            requestType: RequestTypesList["cncl_lcn_txn"],
            request_id: fromObject.request_id,
            current_licence_type: current_licence_type,
            request_type: RequestType.Licence_Receipt_Reprint,
            pdmt_id: pdmtId,
            authority_id: authorityId,
            reason_id: fromObject.reason_id,
            counter_session_id: sessionId ? sessionId : null,
            forward_authority_service_id: serviceId,
            document_details: [],
            action: {
              action: fromObject.action,
              remark: fromObject.remark,
              forward_province: fromObject.forward_province,
              forward_role: fromObject.forward_role,
              forward_authority: fromObject.forward_authority
            },
            selected_vehicles: selectedVehicles
          });
        }
        
          
        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "assign&Perform" ? 'put' : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
            if(props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
                props.onClosePopupFn();
              } else {
                props.refreshMainScreenDataFn()
                props.onClosePopupFn();
              }
          }
          else {
            if (props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
              });
            }
            else {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
        }}
      />
    </Fragment>
  );
};



const CancelLicence = (props) => {
  const { uiType, formKey, requestType, approvalRequestId, dataSubmittedForApproval } = props;
  const [formState, formAction] = useContext(FormContext);
  const datalist = _get(formState, getDealerVehicleDetailsAPI.key, [])
  const [requestId, setRequestId] = useState(null);

  const [, coreAction] = useContext(CoreContext);

  useEffect(() => {
    coreAction.setDataTable(`${getDealerVehicleTrxnDetailsAPI.key}`, [], 'done', 1, null, null);
    const rId = new Date();
    const requ = rId.valueOf().toString();
    setRequestId(requ.substr(requ.length - 8))
    
    if(props.uiType === 'APR') {
      getReqApprovalLicenceTypeAprOriginalData(props)
      // getReqApprovalLicenceTypeAprData(props)
    } else {
      getReqApprovalLicenceTypeData(props)
    }
  }, [])
  console.log('IDDDDD', props);

  const getReqApprovalLicenceTypeAprOriginalData = (props) => { 
    
                        
    callApi(`${getDealerDetailsAPI.url}/${props.dataSubmittedForApproval.dealer_id}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      if (!error) {
        formAction.changeInputFn('dealer_search_form', "data", "", result.data.data);
        callApi(`${dealerPaymentHistoryAPI.url}/history/${result.data.data.id}`)
        .method("get")
        .headers(true)
        .isMultipart(false)
        .send((error, result) => {
          formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
          if (!error) {
            console.log('REEE****1111===>22e', result.data.data);
            if(_get(formState, getDealerVehicleDetailsAPI.key, []).length !== 0) {
              formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
            } else {
              formAction.changeInputFn("dlr_cancel_data_set", "data", "", result.data.data.sort((a, b) => {
                const date1 = new Date(a['created_date_time'])
                const date2 = new Date(b['created_date_time'])
                return date2 - date1
              }));
              getReqApprovalLicenceTypeAprData(result.data.data)
            }
            // formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, result.data.data);
      
          } else {
            console.log('ertt--', error);
            formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
          }
        });
  
      } else {
        console.log('ertt--', error);
        formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
      }
    });
  }

  const getReqApprovalLicenceTypeData = (props) => {
    callApi(`${dealerPaymentHistoryAPI.url}/history/${_get(formState, 'dealer_search_form.data.id', "")}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
      if (!error) {
        console.log('REEE****1111===>22e', result.data.data);
        if(_get(formState, getDealerVehicleDetailsAPI.key, []).length !== 0) {
          formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
        } else {
          formAction.changeInputFn("dlr_cancel_data_set", "data", "", result.data.data.sort((a, b) => {
            const date1 = new Date(a['created_date_time'])
            const date2 = new Date(b['created_date_time'])
            return date2 - date1
          }));

        }
        // formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, result.data.data);
  
      } else {
        console.log('ertt--', error);
        formAction.changeInputFn("dlr_cancel_data_set", "data", "", []);
      }
    });
  }

  // api to get added data from table
  const getReqApprovalLicenceTypeAprData = (dataSet) => { 
    callApi(`${dealerCancelLicenceReceiptTransactionAPI.url}/${props.dataSubmittedForApproval.id}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, result) => {
      // formAction.changeInputFn(getDealerVehicleDetailsAPI.key, "", "", []);
      formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
      if (!error) {
        let newData = []
        console.log('REEE****1111===>22eg', result.data.data);
        for(let x of result.data.data) {
          console.log('ADD4444-1-', x);

          const selectedIndex = dataSet.findIndex(item => item.txn_number === x.txn_number)
          console.log('ADD4444-2-', selectedIndex);
          const dd = dataSet.length > 0 ? dataSet : []
          const addData = {
            rowIndex: selectedIndex,
            ...x,
            created_date_time: dataSet.length > 0 ? dataSet[selectedIndex]['created_date_time'] : x.created_date_time,
          }
          console.log('ADD4444-3-', addData);
          newData.push(addData)
        }
        // if(_get(formState, getDealerVehicleDetailsAPI.key, []).length !== 0) {
        //   // formAction.changeInputFn(getDealerVehicleDetailsAPI.key, "", "", []);
        //   formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
        // } else {
          // formAction.changeInputFn(getDealerVehicleDetailsAPI.key, "", "", result.data.data);
          formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, newData);

        // }
        // formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, result.data.data);
  
      } else {
        console.log('ertt--', error);
        // formAction.changeInputFn(getDealerVehicleDetailsAPI.key, "", "", []);
        formAction.setAddBtnFuncValues(getDealerVehicleDetailsAPI.key, []);
      }
    });
  }

  return (
    <div className={"container-fluid p-0"}>

      <div className={"row"}>
        <FormWrapper
          setGroupName={formKey}
          setFormObject={props.uiType === "APR" ? { ui_request_id: props.approvalRequestId } : {
            request_id: _set(formState, `${formKey}.request_id`, requestId),
          }}>
          <div className={"col-md-12"}>
            <DataTable
              // tableName="Vehicle Number Information"
              // apiUrl={props.uiType === "APR" ? `${dealerCancelLicenceReceiptTransactionAPI.url}/${props.dataSubmittedForApproval.id}` : `${dealerPaymentHistoryAPI.url}/history/${_get(formState, 'dealer_search_form.data.id', "")}`}
              dataTableKey={getDealerVehicleTrxnDetailsAPI.key}
              
            
              isSetSearchFrom={false}
              isSelectRow={false}
              tableHeaderList={[
                {
                  displayName: "Tx Num",
                  key: "txn_number",
                  default: "-",
                  isSort: false,
                },
                { displayName: "Tx. Date & Time", key: "created_date_time",
                onPrintFn: (rawData)=>{
                  console.log('RAW__', rawData);
                  return (dateObjectToString(rawData.created_date_time, "dd/mm/YY HH:MM"))
              }, isSort: true },
                { displayName: "Total Amount(LKR)", key: "total_amount", isSort: false,isRightAlign: true,onPrintFn :rawData=>thousandSeparator(rawData.total_amount,true)  },
                { displayName: "", key: "", default: " ",  },
                { displayName: "Province", key: "pdmt", isSort: false },
                { displayName: "Authority", key: "authority", isSort: false },
              ]}
              tableBodyList={_get(formState, 'dlr_cancel_data_set.data', [])}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.add,
                  actionBtn: { tooltip: 'Add' },

                }
              ]}
              isSetAction={true}
              isSetTableHeader={true}

            />
          </div>
        </FormWrapper>
      </div>

      {_get(formState, getDealerVehicleDetailsAPI.key, []).length !== 0 && <div className={"row defaultMarginTop"}>
        <div className={"col-md-12"}>
          <DataTable
            tableName="Selected Transactions To Cancel"
            dataTableKey={getDealerVehicleDetailsAPI.key}
            isSetSearchFrom={false}
            customActionHeader={'Action'}
            isSelectRow={false}
            tableHeaderList={[
              {
                displayName: "Tx Num",
                key: "txn_number",
                default: "-",
                isSort: false,
              },
              { displayName: "Tx. Date & Time", key: "created_date_time",
              onPrintFn: (rawData)=>{
                console.log('RAW__', rawData);
                return (dateObjectToString(rawData.created_date_time, "dd/mm/YY HH:MM"))
            }, isSort: true },
              { displayName: "Total Amount(LKR)", key: "total_amount", isSort: false,isRightAlign: true,onPrintFn :rawData=>thousandSeparator(rawData.total_amount,true) },
              { displayName: "", key: "", default: " ",  },
              { displayName: "Province", key: "pdmt", isSort: false },
              { displayName: "Authority", key: "authority", isSort: false },
            ]}
            actionTypeList={[
              {
                  actionType: actionBtnTypes.delete,
                  actionBtn: { tooltip: 'Remove' },
              }
          ]}
            tableBodyList={_get(formState, getDealerVehicleDetailsAPI.key, [])}
            isSetAction={true}
            isSetTableHeader={true}
            isSetTableFooter={true}
          />
        </div>

      </div>}

      <div className={"row"}>
        <div className="col-md-12 col-sm-12 defaultMarginTop">
          <Reason filterKey={'dealers_cancel_licence_receipt_transaction'} formKey={uiType === 'APR' ? `${formKey}-new` : formKey} requestType={uiType}
                        dataSubmittedForApproval={props.dataSubmittedForApproval} />
        </div>

        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <DocumentsUploader
            isUploadFn={true}
            formGroupName={`${formKey}-upload`}
            tableApiUrl={uiType  === 'APR' ?  `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
            requestId={uiType  === 'APR' ?  props.approvalRequestId : requestId}
            uiType={uiType}
          />
        </div>
        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <Action
            formKey={formKey}
            uiType={uiType}
            dataSubmittedForApproval={props.dataSubmittedForApproval}
          />
        </div>
      </div>
    </div>
  );
};

export {
  CancelLicence,
  CancelDlrLcnSubmitBtn
};