import React, {useContext, useState, Fragment, useEffect} from "react";

import {
   InputButton,
   InputBox,
   FormWrapper,
   SubmitButton,
} from "../../../../../ui-components/ui-elements/form";
import { NumberInputBoxWithState } from "../../../../../ui-components/ui-elements/form/InputBox";

import { LimitTextLength, UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { actionBtnTypes, DataTable } from "../../../../../ui-components/ui-elements/table/DataTable";
import { Reason } from "../../../../request-for-approval/pages/includes/Reason";
import DocumentsUploader from "../../../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { uploadDetailSaveAPI, changeSerialNumberWorkFlowApi,changeDealerSerialAPI, getDealerVehicleDetailsAPI, getDealerVehicleDetailsOrganisationServiceAPI, dealerLicenceReprintAPI, uploadDetailGetAPI } from "../../../../../../config/apiUrl.config";
import { Action } from "../../../../request-for-approval/pages/includes/Action";
import {DeleteVehicleNumberBtn, DeleteVehicleNumberUI} from "./DeleteVehicleNumber";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { CoreContext } from "../../../../core/context-providers/CoreContext.provider";

import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";
import {_get, _set} from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { RequestType, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../../../config/enum.config";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";

const dataTableKey = 'dealer_licence_serial_number_change'

const ActualSerialNo = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const datalist = _get(formState, `${dataTableKey}`, [])
    const onChangeValueHandler = (eventData) => {
        props.onChangeValueFn((props.dataTableKey === 'changeSerialKEY' && _get(formState, `vehicle_added_data.data`, []).length > 0) ? `${props.rowIndex}.serial_no` : `${props.rowIndex}.actual_serial_no`, eventData.value)

        const checkSelectedExists = (element) => element.id == props.row.id;
        const selectedIndex = datalist.findIndex(checkSelectedExists);
        if(selectedIndex === -1) {
            formAction.setTableInputFuncValues(dataTableKey, [...datalist, props.row])
        } else {
            if(props.row.actual_serial_no !== "") {
                const updatedList = datalist.filter(vehicle => vehicle.id !== props.row.id)
                formAction.setTableInputFuncValues(dataTableKey, [...updatedList, datalist[selectedIndex] = props.row])
            } else {
                const updatedList = datalist.filter(vehicle => vehicle.id !== props.row.id)
                formAction.setTableInputFuncValues(dataTableKey, [...updatedList])
            }
        }
    }

    return (
        <Fragment>
          {/* {console.log('PROPSSSSSSSSS===', props)} */}
            <NumberInputBoxWithState
                lableText="Actual Serial No."
                onChangeTxtFn={(eventData) => onChangeValueHandler(eventData)}
                formGroupName={`${props.rowIndex}_actual_serial_no`}
                inputName={"actual_serial_no"}
                decimalScale={0}
                isThousandSeparator={false}
                isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                if (checkValue.includes(".")) {
                    checkValue = checkValue.split(".")[0];
                }

                return checkValue.length <= 20;
                }}
                isAllowNegative={false}
            />
        </Fragment>
    )
}


const ChangeSerialNumberBtn = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
	  const [, uiAction] = useContext(UIContext);

    const {formKey, approvalRequestId, requestType, counterId, dataSubmittedForApproval} = props;
    const vehicle_no = _get(formState, "dealer_search_form.searchKey", null);
    const [authStatus] = useContext(AuthContext);
    const { sessionId } = props
    const serviceId = _get(authStatus, "authUser.service_id", "");
    const current_licence_type = _get(
      formState,
      "vehicle_search_form.data.current_license_type.code",
      null
    );
    const approvalAction = _get(
      formState,
      `${formKey}.action`,
     null
    )
    const authorityId = _get(authStatus, "authUser.authorityId", "");
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
      const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    const request_id = _get(formState, `${formKey}.request_id`, null);
    const vehicleData = props.uiType === 'assign&Perform' ? _get(formState, "vehicle_data.data", []) : []
    const selectVehicles = (props.uiType === 'assign&Perform' && _get(formState, `vehicle_added_data.data`, []).length > 0) ? _get(coreState, `dataTableResponses.changeDealerSerialKEY.results`, []).map((vehicle) => {
      console.log('vehicles--', vehicle);
      // if(props.uiType === 'assign&Perform') {
        // return vehicleData.map(regVehicle => {
          // if(regVehicle.vehicle_number === vehicle.vehicle_no) {
            return {
              vehicle_no: vehicle.vehicle_no,
              licence_no: vehicle.licence_id,
              licence_id: vehicle.licence_id,
              system_serial_number: vehicle.system_serial_number,
              actual_serial_number: vehicle.actual_serial_number,
            }
          // } else {
          //   return {
          //     vehicle_no: null
          //   }
          // }
          
        // })
          
      // }
      
    }) : _get(formState, dataTableKey, []).length !== 0 ? _get(formState, dataTableKey, []).map(vehicle => {
      return {
        vehicle_no: vehicle.vehicle_number,
        licence_no: vehicle.licence_id,
          actual_serial_number: vehicle.actual_serial_no ? vehicle.actual_serial_no : null,
          validity_from: vehicle.validity_from,
          validity_to: vehicle.validity_to,
          system_serial_number: vehicle.serial_number
      }
  }) : []

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ? 
  _get(authStatus, "authUser.provinceName", "<anonymous province>") : 
  ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

    const selectedVehicles = selectVehicles.length !== 0 ? selectVehicles : []
    return (
      <Fragment>
        <SubmitButton
          btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
          formGroupName={formKey}
          tooltip={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
          isValidate={true}
          isSetHeaders={true}
          flashMessages={{
            success: props.uiType === 'assign&Perform' ? {
                status: true,
                message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
                messageType: "success",
            } : {
                status: true,
                message: `Approval Request successfully submitted. Request ID : ${request_id}.`,
                messageType: "success",
            },
            failed: props.uiType === 'assign&Perform' ? {
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
            } : {
                status: true,
                message: `Approval Request Submit Failed. Please Try Again.`,
                messageType: "error",
              },
            
        }}
          validationObject={props.uiType === 'assign&Perform' && _get(formState, "Dealer_Change_Serial_Number.action", "") === 'REJ' ? {
            fileds: {
              remark: "Remark",
            },
            rules: {
              remark: "required|max:500",
            },
            message: {
              "remark.required": "Remark is required.",
            }
          } : {
            fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Dealer_Change_Serial_Number.action", "") === 'FRD' ? (_get(formState, "Dealer_Change_Serial_Number.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
            }) : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              }) : {
                "reason_id": "Reason",
              "remark": "Remark",
              "forward_province": "Forward Province",
              "forward_role": "Forward Role",
              "action": "Action",
              
            },
            rules: props.uiType === 'assign&Perform' ? (_get(formState, "Dealer_Change_Serial_Number.action", "") === 'FRD' ? (_get(formState, "Dealer_Change_Serial_Number.forward_role", "") === 'OPT' ? {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              forward_role: "required",
              forward_province: "required",
              forward_authority: "required",
            } : {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              forward_role: "required",
              forward_province: "required",
            }): {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              }) : {
                "reason_id": "required",
                "remark": "required|max:500",
                "forward_province": "required",
                "forward_role": "required",
                "action": "required",
            },
            message: props.uiType === 'assign&Perform' ? (_get(formState, "Dealer_Change_Serial_Number.action", "") === 'FRD' ? (_get(formState, "Dealer_Change_Serial_Number.forward_role", "") === 'OPT' ? {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              "forward_role.required": "Role is required.",
              "forward_province": "Province is required",
              "forward_authority": "Authority is required",
            } : {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              "forward_role.required": "Role is required.",
              "forward_province": "Province is required",
            }): {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              }) : {
                "reason_id.required": "Reason is required.",
              "remark.required": "Remark is required.",
              "forward_province": "Forward Province is required.",
              "forward_role": "Forward Role is required.",
              "action": "Action is required.",
            },
        }}
          onGetAPIEndPointFn={() => {
            return {
              url: props.uiType === 'assign&Perform' ? `${changeSerialNumberWorkFlowApi.url}/dealer-aproval/${approvalRequestId}` : `${changeSerialNumberWorkFlowApi.url}/${vehicle_no}`,
              key: changeSerialNumberWorkFlowApi.key,
            };
          }}
          onChangeRequestBodyFn={(fromObject) => {
            
            if(props.uiType === 'assign&Perform') {

              if(_get(formState, "Dealer_Change_Serial_Number.action", "") !== 'REJ' && _get(formState, `vehicle_added_data.data`, []).length === 0 && _get(formState, "dealer_licence_serial_number_change", []).length === 0) {
                // setTimeout(() => {
                  uiAction.setFlashMessage({
                    status: true,
                    message: `Please enter the New Serial Number for at least one licence number.`,
                    messageType: "error",
                  });
                // }, 2000)

                return
              }
              
              return getDataByFormObject({
                ...fromObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["chng_dlr_se_num"],
                ui_request_id: approvalRequestId,
                current_licence_type: current_licence_type,
                // status: RequestStatus.APPROVAL_PENDING,
                request_type: RequestTypesList["chng_dlr_se_num"],
                pdmt_id: pdmtId,
                // authority_id: authorityId,
                reason_id: formState['Dealer_Change_Serial_Number-new'].reason_id,
                counter_session_id: '00001',
                document_details: [],
                counter_id: counterId ? counterId : null,
                forward_authority_service_id: serviceId,
                action: fromObject.action == 'FRD' ? {
                  action: fromObject.action,
                  remark: fromObject.remark,
                  forward_province: fromObject.forward_province,
                  forward_role: fromObject.forward_role,
                  forward_authority: fromObject.forward_authority
                } : {
                  action: fromObject.action,
                  remark: fromObject.remark,
                },
                selected_vehicles: (_get(formState, `vehicle_added_data.data`, []).length > 0) ? selectedVehicles : selectVehicles
            });
            } else {
              const valueArr = selectVehicles.map(function(item){ 
                return item.actual_serial_number 
              });
              const isDuplicate = valueArr.some(function(item, idx){ 
                  return valueArr.indexOf(item) != idx 
              });

              if(isDuplicate) {
                setTimeout(() => {
                  uiAction.setFlashMessage({
                    status: true,
                    message: `Can not have duplicate actual serial numbers.`,
                    messageType: "error",
                  });
                }, 2000)
                
  
                return
              } 

              return getDataByFormObject({
                ...fromObject,
                userName: userName,
                currentProvince: currentProvince,
                authority: authority,
                requestType: RequestTypesList["chng_dlr_se_num"],
                request_id: fromObject.request_id,
                current_licence_type: current_licence_type,
                // status: RequestStatus.APPROVAL_PENDING,
                pdmt_id: pdmtId,
                request_type: RequestType.Dealer_Change_Serial_Number,
                authority_id: authorityId,
                reason_id: fromObject.reason_id,
                document_details: [],
                counter_session_id: sessionId ? sessionId : null,
                counter_id: counterId ? counterId : null,
                forward_authority_service_id: serviceId,
                action: {
                    action: fromObject.action,
                    remark: fromObject.remark,
                    forward_province: fromObject.forward_province,
                    forward_role: fromObject.forward_role,
                    forward_authority: fromObject.forward_authority
                  },
                  selected_vehicles: selectVehicles
            });
            }
            
          }}
          callApiObject={{
            isSetHeaders: true,
            method: props.uiType === "assign&Perform" ? 'put' : "post",
          }}
          onResponseCallBackFn={(response, error) => {
            if (
              response.length === undefined &&
              error === undefined &&
              response.data.errors === undefined
            ) {
              if(response && response.data && response.data.error) {
                console.log('re', response.data);
                uiAction.setFlashMessage({
                  status: true,
                  message: `${response.data.errorMessage}`,
                  messageType: "error",
                  });
              } else {
                if(props.uiType === 'assign&Perform') {
                  props.refreshMainScreenAfterPerformFn()
                  formAction.setTableInputFuncValues(dataTableKey, [])
                    props.onClosePopupFn();
                  } else {
                  formAction.setTableInputFuncValues(dataTableKey, [])
                    props.onClosePopupFn();
                  }
              }
            }
          }}
        />
      </Fragment>
    );
  };


const ChangeSerialNumber = (props) => {
    const {uiType, formKey, requestType, approvalRequestId, dataSubmittedForApproval} = props;
    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const dealer_id = _get(formState, "dealer_search_form.searchKey", null);
    const datalist = _get(formState, getDealerVehicleDetailsAPI.key, [])
    const [requestId, setRequestId] = useState(null);
    
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8))
    if(props.uiType === 'APR') {
      getReqApprovalData(props)
      getReqAddedData(props)
    }
  }, [])

  const getTagId = () => {
    switch (requestType) {
      case "Dealer_Change_Serial_Number":
         return null
			default:
				return (
          <div className={"defaultPaddingBottom"}><strong>Dealer ID:</strong>  {props.uiType === 'APR' ? props.dataSubmittedForApproval.dealer_id : dealer_id}</div>
        ); 
		}
  }


  const getReqApprovalData = (props) => {
    callApi(`${getDealerVehicleDetailsOrganisationServiceAPI.url}/${props.dataSubmittedForApproval.dealer_id}?is_with_licence_id=true&is_with_valid_licence=true`)
        .method("get")
        .headers(true)
        .isMultipart(false)
        .send((error, result) => {
          if(!error) {
            formAction.changeInputFn("vehicle_data", "data", "", result.data.data);
          }
        })
  }

  const getReqAddedData = (props) => {
    callApi(`${changeDealerSerialAPI.url}/${props.approvalRequestId}?isApproval=true`)
        .method("get")
        .headers(true)
        .isMultipart(false)
        .send((error, result) => {
          if(!error) {
            console.log('ADDED_DATA', result.data.data);
            formAction.changeInputFn("vehicle_added_data", "data", "", result.data.data);
          }
        })
  }
     
    return (
        <div className={"container-fluid p-0"}>
                <div className={"row"}>
                <FormWrapper 
                    setGroupName={formKey}
                    setFormObject={{
                        request_id: _set(formState, `${formKey}.request_id`, requestId),
                }}>
                    <div className={"col-md-12"}>
                  {getTagId()}
                        {console.log('ADDED_DATA@', _get(formState, `vehicle_added_data.data`, null))}
                        {
                          (props.uiType === "APR" && _get(formState, `vehicle_added_data.data`, []).length > 0) ?
                          (
<DataTable
                            tableName={"Vehicle Number Information"}
                            apiUrl={props.uiType === "APR" ? `${changeDealerSerialAPI.url}/${props.approvalRequestId}?isApproval=true` : `${getDealerVehicleDetailsOrganisationServiceAPI.url}/${_get(formState, 'dealer_search_form.searchKey', "")}?is_with_licence_id=true&is_with_valid_licence=true`}
                            dataTableKey={props.uiType === "APR" ? changeDealerSerialAPI.key : getDealerVehicleDetailsOrganisationServiceAPI.key}
                            isSetSearchFrom={false}
                            isSelectRow={false}
                            tableHeaderList={[
                                { 
                                    displayName: "Authorized Vehicle No.",
                                    key: `${props.uiType === "APR" ? 'vehicle_no' : 'vehicle_number'}`, 
                                    default: "-",
                                    isSort: false 
                                },
                                { displayName: "Lic. No.", key: `${props.uiType === "APR" ? 'licence_number' : 'licence_number'}`, isSort: false },
                                !(props.uiType === "APR") ? { 
                                    displayName: "Validity Period (From - To)", 
                                    key: "validity_period",
                                    onPrintFn: (rawData)=>{
                                      // console.log('rawData===', rawData);
                                        return (dateObjectToString(rawData.validity_from, "dd/mm/YY")+' - '+(dateObjectToString(rawData.validity_to, "dd/mm/YY")))
                                    }, 
                                    isSort: false
                                } : { 
                                  displayName: "Validity Period (From - To)", 
                                  key: "validity_period",
                                  onPrintFn: (rawData)=>{
                                    // console.log('rawData===', rawData);
                                      return (dateObjectToString(rawData.valid_from, "dd/mm/YY")+' - '+(dateObjectToString(rawData.valid_to, "dd/mm/YY")))
                                  }, 
                                  isSort: false
                              },
                                { displayName: "System Serial No.", key: `${props.uiType === "APR" ? 'system_serial_number' : 'serial_number'}`, isSort: false},
                                { displayName: "Actual Serial No.*", key: `${props.uiType === "APR" ? 'actual_serial_number' : 'actual_serial_no'}`, default: "-", fixWidth: 30 }
                            ]}
                            specificComponents={props.uiType === "APR" ? {
                              serial_no: ActualSerialNo,
                              
                              
                          } : {
                                actual_serial_no: ActualSerialNo
                            }}
                            
                            isSetAction={false}
                            isSetTableHeader={false}
                            
                        />
                          )
                          : 
                          (
<DataTable
                            tableName={"Vehicle Number Information"}
                            apiUrl={`${getDealerVehicleDetailsOrganisationServiceAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.dealer_id : _get(formState, 'dealer_search_form.searchKey', "")}?is_with_licence_id=true&is_with_valid_licence=true`}
                            dataTableKey={getDealerVehicleDetailsOrganisationServiceAPI.key}
                            isSetSearchFrom={false}
                            isSelectRow={false}
                            tableHeaderList={[
                                { 
                                    displayName: "Authorized Vehicle No.",
                                    key: `${'vehicle_number'}`, 
                                    default: "-",
                                    isSort: false 
                                },
                                { displayName: "Lic. No.", key: `${'licence_number'}`, isSort: false },
                                { 
                                    displayName: "Validity Period (From - To)", 
                                    key: "validity_period",
                                    onPrintFn: (rawData)=>{
                                      // console.log('rawData===', rawData);
                                        return (dateObjectToString(rawData.validity_from, "dd/mm/YY")+' - '+(dateObjectToString(rawData.validity_to, "dd/mm/YY")))
                                    }, 
                                    isSort: false
                                },
                                { displayName: "System Serial No.", key: `${'serial_number'}`, isSort: false},
                                { displayName: "Actual Serial No.*", key: `${'actual_serial_no'}`, default: "-", fixWidth: 30 }
                            ]}
                            specificComponents={{
                                actual_serial_no: ActualSerialNo
                            }}
                            
                            isSetAction={false}
                            isSetTableHeader={false}
                            
                        />
                          )
                        }
                        
                    </div>
                    </FormWrapper>
                </div>



                <div className={"row"}>
                    <div className="col-md-12 col-sm-12 defaultMarginTop">
                        <Reason filterKey={'dealer_change_serial_number'} formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
                     requestType={uiType}
                     dataSubmittedForApproval={props.dataSubmittedForApproval} />
                    </div>

                    <div className="col-md-12 col-sm-12 defaultPaddingTop">
                        <DocumentsUploader
                            requestType={requestType}
                            isUploadFn={true}
                            formGroupName={`${formKey}-upload`}
                            tableApiUrl={uiType  === 'APR' ?  `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
                        requestId={uiType  === 'APR' ?  props.approvalRequestId : requestId}
                        uiType={uiType}
                        />
                    </div>

                    <div className="col-md-12 col-sm-12 defaultPaddingTop">
                        <Action
                            dataSubmittedForApproval={dataSubmittedForApproval}
                            formKey={formKey}
                            uiType={uiType}
                         />
                    </div>
                </div>
        </div>
    );
};

export {
    ChangeSerialNumber,
    ChangeSerialNumberBtn
};