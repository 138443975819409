/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-07 10:37:09
 * @Last Modified by: Madhawa
 * @Last Modified time: 2022-05-20 00:29:15
 */
import React, { Fragment, useContext } from "react";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { vrpAPI } from "../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const RoutePermit = () => {
  const [formState] = useContext(FormContext);

  return (
    <Fragment>
      <DataTable
        isSetTableHeader={false}
        dataTableKey={vrpAPI.key}
        apiUrl={`${vrpAPI.url}/${_get(formState, "vehicle_search_form.searchKey")}?status=Active`}
        isSetAction={false}
        isSetTableFooter={false}
        tableHeaderList={[
          { displayName: "Route Permit Number", key: "route_permit_no", isSort: false, default: "-" },
          { displayName: "VRP Organization Name", key: "service.name", isSort: false, default: "-" },
          {
            displayName: "VRP Organization Address", key: "service.addresses[0].city", isSort: false, default: "-",
            onPrintFn: (orgAddress) => {
              return orgAddress.service.addresses[0].line1 + ", " + orgAddress.service.addresses[0].line2 + ", " +
                orgAddress.service.addresses[0].city;
            }
          },
          {
            displayName: "Permit Validity Period (From - To)", key: "issued_at", default: "-", isSort: false,
            onPrintFn: (rawData) => {
              const issued_at = (rawData.issued_at) ? dateObjectToString(rawData.issued_at, "dd/mm/YY") : "-";
              const expiry_date = (rawData.expiry_date) ? dateObjectToString(rawData.expiry_date, "dd/mm/YY") : "-";
              return issued_at + " - " + expiry_date;

            }
          },
          { displayName: "Status", key: "status", isSort: false },
          {
            displayName: "Effective Date", key: "issued_at", isSort: false, onPrintFn: (effectiveDate) => {
              if (isEmptyValue(effectiveDate.renewed_at)) {
                return (effectiveDate.issued_at) ? dateObjectToString(effectiveDate.issued_at, "dd/mm/YY") : "-";
              }
              else {
                return (effectiveDate.renewed_at) ? dateObjectToString(effectiveDate.renewed_at, "dd/mm/YY") : "-";
              }

            }
          },
          { displayName: "Lic. No.", key: "license_no", isSort: false },
          {
            displayName: "Lic. Validity Period (From - To)", key: "license_validity_from", default: "-", isSort: false,
            onPrintFn: (rawData) => {
              const license_validity_from = (rawData.license_validity_from) ? dateObjectToString(rawData.license_validity_from, "dd/mm/YY") : "-";
              const license_validity_to = (rawData.license_validity_to) ? dateObjectToString(rawData.license_validity_to, "dd/mm/YY") : "-";
              return license_validity_from + " - " + license_validity_to;
            }
          },
        ]}
      />


    </Fragment>
  );
};

export { RoutePermit };
