import React, { Fragment, useContext, useState,useEffect} from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";

import {
  FormWrapper,
  SelectBoxWithState,
  SubmitButton,
  DatePickerWithState,
  InputButtonWithState,
} from "../../../../../../ui-components/ui-elements/form";
import { provincesOfficesListAPI, AuthorityListApi, reportGenerateAPI } from "../../../../../../../config/apiUrl.config";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { isEmptyValue } from "../../../../../../../helpers/common-helpers/common.helpers";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { CoreContext,apiResponseStoringType } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined

const ReversedLicenseSearchCriteria = ({
  reportCode,
  formGroupName,
  setReportgeneratedValues = emptyFn,
  onGenerateSubmit = emptyFn,
  onResetClick = emptyFn,
}) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [coreState, coreAction] = useContext(CoreContext);

  let datefrom = new Date();
  let dateto = new Date();
  let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
  let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
  let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
  let authorityId =roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.authorityId', ""):"All";
  let generated_by = _get(authStatus, 'authUser.name', "")
  let role = _get(authStatus, 'authUser.rolePrefix', "")
  let authorityName = 'All';
  let authorityServiceId = "All";
  let licenceName = null;
  let licenceId = null;
  let licenceCode = null;
  const [getdatalist,setDatalist]=useState(" ");
  useEffect(() => {

  coreAction
    .sendRequestFn(
      `${AuthorityListApi.url}?check_user_role=1&sort_by=name|asc`
    )
    .method("get")
    .setInitStoring(apiResponseStoringType.setResponse, {
      responseKey: AuthorityListApi.key,
    })
    .headers(true)
    .send((error, result) => {
      if (!error) {
        if (roleLevelCode === "INT_AU") {
          setDatalist(result.data.data.filter((item) => item.id === authorityId));

        }
        else {
          setDatalist(result.data.data.filter((item) => item.province.id === provinceId))
        }
      }
    });

}, []);

  const dataList = [
    {
      id: "reissued",
      code: "REI",
      value: "Reissued",
    },
    {
      id: "reversed",
      code: "REV",
      value: "Reversed",
    }
  ]

  return (
    <Fragment>
      <UICard>
        <FormWrapper
          setGroupName={formGroupName}
          formGroupLinkWith={formGroupName}
          onDestroyUnsetFormObject={true}
          setFormObject={{
            date_from: null,
            date_to: null,
            province: provinceId,
            licence: null,
            authority:roleLevelCode === "INT_AU" ? null:authorityId
          }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                apiUrl={`${provincesOfficesListAPI.url}?sort_by=province.name%7Casc`}
                keyName={"id"}
                valueName={"province.name"}
                apiStateKey={provincesOfficesListAPI.key}
                emptySelectOptionTxt={"Province"}
                labelText="Province"
                inputName={"province"}
                isDisabled={true}
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                isRequired={true}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                emptySelectOptionTxt={" "}
                isEnableAllOption={roleLevelCode === "INT_AU" ?false:true}
                dataList={getdatalist}
                keyName={"id"}
                valueName={"service.name"}
                labelText="Authority"
                inputName={"authority"}
              />
            </div>

            <div className="col-md-6 col-sm-12 floatLeft">
              <DatePickerWithState
                isRequired={true}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                labelText="Date From"
                inputName={"date_from"}
                maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                minDate={setminDateRangeFn(_get(formState[formGroupName], "date_to", null),_get(formState[formGroupName], "date_from", null))}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                emptySelectOptionTxt={" "}
                dataList={dataList}
                labelText="Licence"
                inputName={"licence"}
                isRequired={true}
              />
            </div>
            <div className="col-md-6 col-sm-12 floatRight">
              <DatePickerWithState
                isRequired={true}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                labelText="Date To"
                inputName={"date_to"}
                minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "date_from", null))}
              />
            </div>

            <div className="col-md-12 col-sm-12 mt-2 text-center report-btn-wrapper">
              <SubmitButton
                btnName={"generate"}
                btnText={"Generate"}
                startIcon="fas fa-wrench"
                formGroupName={formGroupName}
                isValidate={true}
                validationObject={{
                  fileds: {
                    date_from: "Date From",
                    date_to: "Date To",
                    authority: "Authority",
                    licence: "Licence",
                  },
                  rules: {
                    date_from: "required",
                    date_to: "required",
                    authority:roleLevelCode === "INT_AU"  ? "required" :"alphaSpecial",
                    licence: "required",
                  },
                  message: {}
                }}
                callApiObject={{
                  isSetHeaders: true,
                  method: "get",
                  multipart: false,
                  onUpload: false,
                  responseType: 'text'
                }}
                apiDataStoringObject={{
                  setLoader: true,
                  storingType: "API_RESPONSE_LINKED_FORM",
                  mergeToSuccessResponse: true,
                  mergeToErrorResponse: true
                }}
                onGetAPIEndPointFn={(formObject) => {

                  if (!isEmptyValue(formObject.date_from)) {
                    datefrom = dateObjectToString(new Date(formObject.date_from));
                  }
                  if (!isEmptyValue(formObject.date_to)) {
                    dateto = dateObjectToString(new Date(formObject.date_to));
                  }
                  if (!isEmptyValue(formObject.province)) {
                    provinceId = formObject.province
                    provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                  }
                  if (!isEmptyValue(formObject.authority)) {
                    authorityId = formObject.authority
                    authorityServiceId = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.id)
                    authorityServiceId = (authorityServiceId === undefined )? "All" : authorityServiceId
                    authorityName = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.name)
                    authorityName = (authorityName === undefined )? "All" : authorityName
                  }
                  if (!isEmptyValue(formObject.licence)) {
                    licenceId = formObject.licence;
                    licenceCode = dataList.find(item => item.id === licenceId)?.code;
                    licenceName = dataList.find(item => item.id === licenceId)?.value;
                  }

                  return {
                    url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_fromDate=${datefrom}&report_toDate=${dateto}&report_provinceName=${provinceName}&report_provinceId=${provinceId}&report_authorityServiceId=${authorityServiceId}&report_authorityName=${authorityName}&report_licence=${licenceName}&report_licenceTypeCode=${licenceCode}&report_dateFormatDisplay=dd/MM/yyyy&report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                    key: `${reportGenerateAPI.key}`
                  }
                }}

                onChangeRequestBodyFn={(formObject) => {
                  return getDataByFormObject(formObject);
                }}

                onResponseCallBackFn={(error, response,) => {
                  if (response?._statue) {
                    onGenerateSubmit(response?.data)
                    setReportgeneratedValues({
                      datefrom,
                      dateto,
                      provinceId,
                      provinceName,
                      authorityId,
                      authorityName,
                      licenceName,
                      licenceId,
                      licenceCode,
                      authorityServiceId,
                      limit: reportDataLimit,
                      offset: reportDataOffset,
                      reportGeneratedUser: generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                    })
                  }
                }}
              />
              <InputButtonWithState
                btnName="refresh"
                startIcon="fas fa-sync-alt"
                formGroupName={formGroupName}
                btnText="Reset"
                elementWrapperStyle={"refreshBtn"}
                mergeToForm={{
                  date_from: null,
                  date_to: null,
                  province: provinceId,
                  authority:roleLevelCode === "INT_AU" ? null:authorityId,
                  licence: null,
                }}
                tooltip="Reset"
                onClickBtnFn={(value) => {
                  onResetClick()
                  formAction.setFormErrorFn(formGroupName)
                }}
              />
            </div>
          </div>
        </FormWrapper>
      </UICard>
      <br />
    </Fragment>

  );
};

export {
  ReversedLicenseSearchCriteria
}
