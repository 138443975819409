/*
 * @Author: Madhawa
 * @Date: 2020-08-10 12:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-11 20:30:31
 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
    FormWrapper,
    SelectBoxWithState,
    DatePickerWithState, InputBoxWithState, CheckBoxWithState, RoundButton, CheckBox
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
    ownerEditAPI,
    provincesOfficesListAPI,
} from "../../../../../config/apiUrl.config";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";

const ErlInformation = (props) => {
    const [isAdded, setIsAdded] = useState(false)
    const [authStatus] = useContext(AuthContext);
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");

    const checkPlusBtnVisibility = (index) => {
        return (

            Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === index

        );
    };
    const [formState, formAction] = useContext(FormContext);
    const [showPopup, setShowPopup] = useState(false)
    const [removeIndex, setRemoveIndex] = useState("")
    let currentOwnerInfo;
    let newAddedOwnerInfo;

    useEffect(() => {

    }, [showPopup])

    const RemoveBtn = () => {
        return (
            <InputButton
                elementWrapperStyle={"defaultHalfMarginLeftRight"}
                btnName="submit"
                btnText={"Yes"}
                onClickBtnFn={() => {
                    
                    const newData = _get(formState, "transfer_vehicle.current_erl", {"0": {}})
                    if(props.uiType === "APR" && (Object.keys(newData[0]).length === 0 || 
                    (Object.keys(newData[0]).length === 1 && newData[0].transferred_date && newData[0].transferred_date === null))) {
                        const incomingData = _get(formState, "transfer_vehicle.current_erls", [])
                        const incomingNewData = _get(formState, "transfer_vehicle.current_erl_new", [])
                        if(removeIndex !== 0) {
                            incomingData.splice(removeIndex, 1)
                            incomingData[removeIndex - 1].transferred_date = null
                        }
                        if(incomingNewData && incomingNewData.length > 0) {
                            incomingNewData[0].transferred_date = null
                        }
                        
                        incomingData[removeIndex - 1].transferred_date = null
                        console.log('LOGGGincomingData', incomingData);
                        formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.current_erl`, {"0": {}});
                        formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.current_erls`, removeIndex !== 0 ? incomingData : []);
                        setIsAdded(false)
                        setShowPopup(false)
                    } else {
                        console.log('LOGGGincomingData1', newData[0], Object.keys(newData[0]).length, );

                        if(removeIndex === 0) {
                            setShowPopup(false)
                            setIsAdded(false)
                            const incomingNewData = _get(formState, "transfer_vehicle.current_erl_new", [])
                            if(incomingNewData && incomingNewData.length > 0) {
                                incomingNewData[0].transferred_date = null
                            }
                            formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.current_erl`, {"0": {}});
                        } else {
                            let currentErlObj = _get(formState, "transfer_vehicle.current_erl", {});
                            delete currentErlObj[removeIndex.toString()];
                            currentErlObj[removeIndex - 1].transferred_date = null
                            formAction.changeInputFn("transfer_vehicle", "", "transfer_vehicle.current_erl", currentErlObj);
                            setShowPopup(false)
                        }
                    }
                    
                }
                }
            />
        )
    }

    // useEffect(() => {}, [newAddedOwnerInfo])

    console.log('LOG', formState)

    if(props.uiType === "APR"){
        currentOwnerInfo=_get(formState,"vehicle_search_form.data.owner_id",null);
        newAddedOwnerInfo = _get(formState,"transfer_vehicle.current_erls",null) && _get(formState,"transfer_vehicle.current_erls",null) !== null ? _get(formState,"transfer_vehicle.current_erls",null) : [];
        if(newAddedOwnerInfo && newAddedOwnerInfo.length > 0) {
            newAddedOwnerInfo.sort((a,b) =>{
                const date1 = a['id']
                const date2 = b['id']
                 return date2 - date1
              })
              const fromIdx = newAddedOwnerInfo.findIndex(el => el.transferred_date === null)
              newAddedOwnerInfo.splice(newAddedOwnerInfo.length - 1, 0, newAddedOwnerInfo.splice(fromIdx, 1)[0])
        }
        
    }else if(props.uiType === 'viewApproval') {
        currentOwnerInfo=_get(formState,"vehicle.data",null) && _get(formState,"vehicle.data.data",null) !== null ? _get(formState,"vehicle.data.data.owner_id",null) : null;
    } else {
        currentOwnerInfo=_get(formState,"vehicle_search_form.data.owner_id",null);
    }
    if(props.uiType === 'APR'  && newAddedOwnerInfo.length !== 0) {
        return (
         
            <Fragment>
                {console.log('INFO==>', currentOwnerInfo)}
                <UICard cardHeading="Current Owner Information">
                    {
                        currentOwnerInfo ?
                        <UICard>
                        <div className={"row"}>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current.name`}
                                            labelText="Name "
                                            maxLength={50}
                                            inputName={"name"}
                                            inputValue={props.uiType === 'viewApproval' ? 
                                                (currentOwnerInfo && currentOwnerInfo.name && currentOwnerInfo.name !== null 
                                                    ? getLabelValue(currentOwnerInfo.name, true, true) : "") : 
                                                    (currentOwnerInfo && currentOwnerInfo.name && currentOwnerInfo.name !== null ? 
                                                        getLabelValue(currentOwnerInfo.name, true, true) : "")}
                                            isDisabled={true}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current.nic`}
                                            labelText="Identification Number"
                                            maxLength={13}
                                            inputName={"nic"}
                                            inputValue={props.uiType === 'viewApproval' ? 
                                                (currentOwnerInfo && currentOwnerInfo.nic && currentOwnerInfo.nic !== null 
                                                    ? getLabelValue(currentOwnerInfo.nic, true, true) : "") : 
                                                    (currentOwnerInfo && currentOwnerInfo.nic && currentOwnerInfo.nic !== null ? 
                                                        getLabelValue(currentOwnerInfo.nic, true, true) : "")}
                                            isDisabled={true}
                                            isRequired={false}
                                            isAllowedFn={(value) => {
                                                console.log('VALL==2', value);
                                                const checkValue = value.value.toString();
                                                return checkValue.length < 13;
                                            }}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <div className="row justify-content-start">
                                                <div className="col-1 pr-0 pt-4">
                                                    0094
                                                </div>
                                                <div className="col-11 pt-1">
                                                <NumberInputBoxWithState
                                                uiType={templateTypes.bootstrap}
                                                formGroupName="transfer_vehicle"
                                                inputStatePath={`transfer_vehicle.current.mobile_no`}
                                                labelText="Mobile Number "
                                                maxLength={9}
                                                inputName={"mobile_no"}
                                                isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={false}
                                                inputValue={props.uiType === 'viewApproval' ? 
                                                    (currentOwnerInfo && currentOwnerInfo.contact_number && currentOwnerInfo.contact_number !== null 
                                                        ? currentOwnerInfo.contact_number : "") : 
                                                        (currentOwnerInfo && currentOwnerInfo.contact_number && currentOwnerInfo.contact_number !== null ? 
                                                            currentOwnerInfo.contact_number : "")}
                                                isDisabled={true}
                                                isRequired={false}
                                                isAllowedFn={(value) => {
                                                    const checkValue = value.value.toString();
                                                    return checkValue.length < 10;
                                                }}
                                            />
                                                </div>
                                            </div>
                                        
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current.address1`}
                                            labelText="Address Line 1 "
                                            maxLength={50}
                                            inputName={"address1"}
                                            inputValue={props.uiType === 'viewApproval' ? 
                                                (currentOwnerInfo && currentOwnerInfo.address1 && currentOwnerInfo.address1 !== null 
                                                    ? getLabelValue(currentOwnerInfo.address1, true, true) : "") : 
                                                    (currentOwnerInfo && currentOwnerInfo.address_line_1 && currentOwnerInfo.address_line_1 !== null ? 
                                                        getLabelValue(currentOwnerInfo.address_line_1, true, true) : "")}
                                            isDisabled={true}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current.address2`}
                                            labelText="Address Line 2 "
                                            maxLength={50}
                                            inputName={"address2"}
                                            inputValue={props.uiType === 'viewApproval' ? 
                                                (currentOwnerInfo && currentOwnerInfo.address2 && currentOwnerInfo.address2 !== null 
                                                    ? getLabelValue(currentOwnerInfo.address2, true, true) : "") : 
                                                    (currentOwnerInfo && currentOwnerInfo.address_line_2 && currentOwnerInfo.address_line_2 !== null ? 
                                                        getLabelValue(currentOwnerInfo.address_line_2, true, true) : "")}
                                            isDisabled={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current.city`}
                                            labelText="City "
                                            maxLength={50}
                                            inputName={"city"}
                                            inputValue={props.uiType === 'viewApproval' ? (currentOwnerInfo && currentOwnerInfo.city && currentOwnerInfo.city !== null ? currentOwnerInfo.city : "") : (currentOwnerInfo && currentOwnerInfo.city && currentOwnerInfo.city !== null ? currentOwnerInfo.city : "")}
                                            isDisabled={true}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3 clearBoth">
                                        <SelectBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            apiUrl={provincesOfficesListAPI.url}
                                            keyName={"id"}
                                            valueName={"province.name"}
                                            inputValue={props.uiType === 'viewApproval' ? (currentOwnerInfo && currentOwnerInfo.province && currentOwnerInfo.province !== null ? currentOwnerInfo.province : "") : (currentOwnerInfo && currentOwnerInfo.province)}
                                            inputStatePath={`transfer_vehicle.current.province`}
                                            labelText="Province"
                                            emptySelectOptionTxt={"Select Province"}
                                            isDisabled={true}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="col-md-2 floatLeft">
                                        <DatePickerWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            labelText="Transferred Date"
                                            minDate={props.uiType === 'viewApproval' ? (currentOwnerInfo && currentOwnerInfo.transferred_date && currentOwnerInfo.transferred_date !== null ? currentOwnerInfo.transferred_date : "") : currentOwnerInfo && currentOwnerInfo.transferred_on && new Date(currentOwnerInfo.transferred_on)}
                                            maxDate={new Date()}
                                            inputValue={null}
                                            inputStatePath={`transfer_vehicle.current.transferred_date`}
                                            isDisabled={true}
                                        />
                                    </div>
                              </div>
                              </UICard>
                              : null
                    }
                    {console.log('LOGG', newAddedOwnerInfo)}
                    {
                        newAddedOwnerInfo && newAddedOwnerInfo.length !== 0 ?
                        newAddedOwnerInfo.map((currentOwnerInfor, index) => {
                            return (
                                <UICard>
                                <div className={"row"}>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl_new.${index}.name`}
                                            labelText="Name "
                                            maxLength={50}
                                            inputName={"name"}
                                            inputValue={props.uiType === 'viewApproval' ? 
                                                (currentOwnerInfor && currentOwnerInfor.name && currentOwnerInfor.name !== null 
                                                    ? getLabelValue(currentOwnerInfor.name, true, true) : "") : 
                                                    (currentOwnerInfor && currentOwnerInfor.name && currentOwnerInfor.name !== null ? 
                                                        getLabelValue(currentOwnerInfor.name, true, true) : "")}
                                            isDisabled={props.uiType === 'viewApproval' ? true : false}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl_new.${index}.nic`}
                                            labelText="Identification Number"
                                            maxLength={13}
                                            inputName={"nic"}
                                            inputValue={props.uiType === 'viewApproval' ? 
                                                (currentOwnerInfor && currentOwnerInfor.nic && currentOwnerInfor.nic !== null 
                                                    ? getLabelValue(currentOwnerInfor.nic, true, true) : "") : 
                                                    (currentOwnerInfor && currentOwnerInfor.nic && currentOwnerInfor.nic !== null ? 
                                                        getLabelValue(currentOwnerInfor.nic, true, true) : "")}
                                            isDisabled={props.uiType === 'viewApproval' ? true : false}
                                            isRequired={false}
                                            isAllowedFn={(value) => {
                                                console.log('VALL==', value);
                                                const checkValue = value.value.toString();
                                                return checkValue.length < 13;
                                            }}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <div className="row justify-content-start">
                                            <div className="col-1 pr-0 pt-4">
                                                0094
                                            </div>
                                            <div className="col-11 pt-1">
                                            <NumberInputBoxWithState
                                                uiType={templateTypes.bootstrap}
                                                formGroupName="transfer_vehicle"
                                                inputStatePath={`transfer_vehicle.current_erl_new.${index}.mobile_no`}
                                                labelText="Mobile Number "
                                                maxLength={9}
                                                inputName={"mobile_no"}
                                                isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={false}
                                                inputValue={ 
                                                        (currentOwnerInfor && currentOwnerInfor.mobile_number && currentOwnerInfor.mobile_number !== null ? 
                                                            getLabelValue(currentOwnerInfor.mobile_number, true, true) : "")}
                                                isDisabled={props.uiType === 'viewApproval' ? true : false}
                                                isRequired={false}
                                                isAllowedFn={(value) => {
                                                    const checkValue = value.value.toString();
                                                    return checkValue.length < 10;
                                                }}
                                            />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl_new.${index}.address1`}
                                            labelText="Address Line 1 "
                                            maxLength={50}
                                            inputName={"address1"}
                                            inputValue={(currentOwnerInfor && currentOwnerInfor.address1 && currentOwnerInfor.address1 !== null 
                                                    ? getLabelValue(currentOwnerInfor.address1, true, true) : "")}
                                            isDisabled={props.uiType === 'viewApproval' ? true : false}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl_new.${index}.address2`}
                                            labelText="Address Line 2 "
                                            maxLength={50}
                                            inputName={"address2"}
                                            inputValue={(currentOwnerInfor && currentOwnerInfor.address2 && currentOwnerInfor.address2 !== null 
                                                    ? getLabelValue(currentOwnerInfor.address2, true, true) : "")}
                                            isDisabled={props.uiType === 'viewApproval' ? true : false}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl_new.${index}.city`}
                                            labelText="City "
                                            maxLength={50}
                                            inputName={"city"}
                                            inputValue={props.uiType === 'viewApproval' ? (currentOwnerInfor && currentOwnerInfor.city && currentOwnerInfor.city !== null ? currentOwnerInfor.city : "") : (currentOwnerInfor && currentOwnerInfor.city && currentOwnerInfor.city !== null ? currentOwnerInfor.city : "")}
                                            isDisabled={props.uiType === 'viewApproval' ? true : false}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3 clearBoth">
                                        <SelectBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            apiUrl={provincesOfficesListAPI.url}
                                            keyName={"id"}
                                            valueName={"province.name"}
                                            inputValue={props.uiType === 'viewApproval' ? (currentOwnerInfor && currentOwnerInfor.province && currentOwnerInfor.province !== null ? currentOwnerInfor.province : "") : (currentOwnerInfor && currentOwnerInfor.province)}
                                            inputStatePath={`transfer_vehicle.current_erl_new.${index}.province`}
                                            labelText="Province"
                                            emptySelectOptionTxt={"Select Province"}
                                            isDisabled={props.uiType === 'viewApproval' ? true : false}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="col-md-2 floatLeft">
                                        <DatePickerWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            labelText="Transferred Date"
                                            minDate={props.uiType === 'viewApproval' ? (currentOwnerInfor && currentOwnerInfor.transferred_date && currentOwnerInfor.transferred_date !== null ? currentOwnerInfor.transferred_date : "") : currentOwnerInfor && currentOwnerInfor.transferred_on ? new Date(currentOwnerInfor.transferred_on) :
                                            (_get(formState, "transfer_vehicle.previous_owners_data", []).length > 0 ? 
                                            new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] ? 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] : 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on'] : 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on']) : 
                                            '01/01/1970')}
                                            maxDate={new Date()}
                                            inputValue={currentOwnerInfor && currentOwnerInfor.transferred_date ? new Date(currentOwnerInfor.transferred_date) : null}
                                            inputStatePath={`transfer_vehicle.current_erl_new.${index}.transferred_date`}
                                            isDisabled={props.uiType === 'viewApproval' ? true : props.uiType === 'APR' ? ((index === newAddedOwnerInfo.length - 1) && 
                                            (Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === 0 ||
                                            Object.keys(_get(formState, "transfer_vehicle.current_erl", {"0": {}})[0]).length === 0)) ? true : false :
                                            (_get(formState, "transfer_vehicle.current_erl", null) !== null && Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === 1 && isAdded === false) ? true : false}
                                        />
                                    </div>
                                {
                                    Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).map((value, index1) => {
                                        return (
                                            <>
                                                {
                                                    (!isAdded && props.uiType !== 'viewApproval' && newAddedOwnerInfo.length < 2 && newAddedOwnerInfo[0].vehicle_owner_id && newAddedOwnerInfo[0].vehicle_owner_id !== null && (index === newAddedOwnerInfo.length - 1) ) ? (
                                                        <div className="floatRight">
                                                            <RoundButton
                                                                elementStyle={"mr-2"}
                                                                iconClass={"fas fa-plus"}
                                                                onClickBtnFn={(eventData) => {
                                                                    formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.current_erl.${index1}.name`);
                                                                    setIsAdded(true)
                                                                }}
                                                            />
                                                        </div>
                                                    ) : null
                                                }
                                            </>
                                        )                    
                                    }) 
                                }
                                {
                                    Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).map((value, index1) => {
                                        return (
                                            <>
                                                {
                                                    (!isAdded && roleCategoryCode !== 'OPT' && (index === newAddedOwnerInfo.length - 1) ) ? (
                                                        <div className="floatRight">
                                                           <RoundButton
                                                                iconClass={"fas fa-minus"}
                                                                elementStyle={"errorBtn"}
                                                                onClickBtnFn={(eventData) => {
                                                                    setShowPopup(true)
                                                                    setRemoveIndex(index)
                                                                }}
                                                            />
                                                        </div>
                                                    ) : null
                                                }
                                            </>
                                        )                    
                                    }) 
                                }
                                <DialogPopup
                                        isOpen={showPopup}
                                        dialogTitle={"Remove"}
                                        isFullScreen={false}
                                        isCustomButton={true}
                                        customButtonComponent={RemoveBtn}
                                        formGroupName={"transfer_vehicle"}
                                        onClosePopupFn={() =>
                                            setShowPopup(!showPopup)
                                        }
                                        closeButton={{
                                            btnText: "No",
                                        }}
                                    >
                                        <div className="fullWidthDiv">
                                            <p>Are you sure you want to remove?</p>
                                        </div>
                                    </DialogPopup>
                              </div>
                              </UICard>
                            )
                        })
                        : null
                    }
                        {console.log('OOO--', isAdded, Object.keys(_get(formState, "transfer_vehicle.current_erl", {})))}
                    {
                        isAdded && Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === 1 ? 
                        Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).map((value, index) => {

                            return (
                                <UICard>
                                <div className={"row"}>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.name`}
                                            labelText="Name "
                                            maxLength={50}
                                            inputName={"name"}
                                        isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.nic`}
                                            labelText="Identification Number "
                                            maxLength={13}
                                            inputName={"nic"}
                                        isRequired={false}
                                        isAllowedFn={(value) => {
                                            console.log('VALL==3', value);
                                            const checkValue = value.value.toString();
                                            return checkValue.length < 13;
                                        }}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                    <div className="row justify-content-start">
                                                <div className="col-1 pr-0 pt-4">
                                                    0094
                                                </div>
                                                <div className="col-11 pt-1">
                                                <NumberInputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.mobile_no`}
                                            labelText="Mobile Number "
                                            maxLength={9}
                                            inputName={"mobile_no"}
                                            isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={false}
                                        isRequired={false}
                                        isAllowedFn={(value) => {
                                            const checkValue = value.value.toString();
                                            return checkValue.length < 10;
                                        }}
                                        />
                                                </div>
                                            </div>
                                        
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.address1`}
                                            labelText="Address Line 1 "
                                            maxLength={50}
                                            inputName={"address1"}
                                        isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.address2`}
                                            labelText="Address Line 2 "
                                            maxLength={50}
                                            inputName={"address2"}
                                        //isRequired={true}
                                        />
                                    </div>
                
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.city`}
                                            labelText="City "
                                            maxLength={50}
                                            inputName={"city"}
                                        isRequired={true}
                                        />
                                    </div>
                
                                    <div className="floatLeft col-md-3 clearBoth">
                                        <SelectBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            apiUrl={provincesOfficesListAPI.url}
                                            keyName={"id"}
                                            valueName={"province.name"}
                                            // dataList={[
                                            //     {
                                            //         id: "1",
                                            //         value: "Western",
                                            //     },
                                            //     {
                                            //         id: "2",
                                            //         value: "Southern",
                                            //     },
                                            //     {
                                            //         id: "3",
                                            //         value: "Northern",
                                            //     },
                                            //     {
                                            //         id: "4",
                                            //         value: "Eastern",
                                            //     }
                                            // ]}
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.province`}
                                            labelText="Province"
                                            emptySelectOptionTxt={"Select Province"}
                                            isRequired={true}
                                        />
                                    </div>
                
                                    <div className="col-md-2 floatLeft">
                                        <DatePickerWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            labelText="Transferred Date"
                                            inputValue={null}
                                            minDate={currentOwnerInfo && currentOwnerInfo.transferred_on ? new Date(currentOwnerInfo.transferred_on) :
                                                (_get(formState, "transfer_vehicle.previous_owners_data", []).length > 0 ? 
                                                new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? 
                                                _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] ? 
                                                _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] : 
                                                _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on'] : 
                                                _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on']) : 
                                                '01/01/1970')}
                                            maxDate={new Date()}
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.transferred_date`}
                                            isDisabled={props.uiType === 'viewApproval' ? true : 
                                            (index === Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length - 1) ? true : false}
                                        />
                                    </div>
                
                
                                    {
                                        (checkPlusBtnVisibility(index)) && props.uiType !== 'viewApproval' ? (
                                            <div className="floatRight">
                                                <RoundButton
                                                    elementStyle={"mr-2"}
                                                    iconClass={"fas fa-plus"}
                                                    onClickBtnFn={(eventData) => {
                                                        formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.current_erl.${index + 1}.name`);
                                                       
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }
                
                
                                    {
                                        (Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === index + 1) ? (
                                            <RoundButton
                                                iconClass={"fas fa-minus"}
                                                elementStyle={"errorBtn"}
                                                onClickBtnFn={(eventData) => {
                                                        setShowPopup(true)
                                                        setRemoveIndex(index)
                                                }}
                                            />
                                        ) : null
                                    }
                                    <DialogPopup
                                        isOpen={showPopup}
                                        dialogTitle={"Remove"}
                                        isFullScreen={false}
                                        isCustomButton={true}
                                        customButtonComponent={RemoveBtn}
                                        formGroupName={"transfer_vehicle"}
                                        onClosePopupFn={() =>
                                            setShowPopup(!showPopup)
                                        }
                                        closeButton={{
                                            btnText: "No",
                                        }}
                                    >
                                        <div className="fullWidthDiv">
                                            <p>Are you sure you want to remove?</p>
                                        </div>
                                    </DialogPopup>
                                </div>
                                </UICard>
                            )
                        }) 
                        : null
                    }
                </UICard>
                <div className="defaultHalfPaddingLeft">
                    <CheckBoxWithState
                        labelText={"Re-Issuance Required"}
                        formGroupName={"transfer_vehicle"}
                        inputName={"isReissuanceRequired"}
                        isDisabled={props.uiType === 'viewApproval' ? true : false}
                    />
                </div>
            </Fragment>
        
    );
    }


    return (
         
            <Fragment>
                {console.log('INFO==>', currentOwnerInfo)}
                <UICard cardHeading="Current Owner Information">
                    {
                        currentOwnerInfo &&
                        <UICard>
                        <div className={"row"}>
                            <div className="floatLeft col-md-3">
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.name`}
                                    labelText="Name "
                                    maxLength={50}
                                    inputName={"name"}
                                    inputValue={props.uiType === 'viewApproval' ? 
                                        (currentOwnerInfo && currentOwnerInfo.name && currentOwnerInfo.name !== null 
                                            ? getLabelValue(currentOwnerInfo.name, true, true) : "") : 
                                            (currentOwnerInfo && currentOwnerInfo.name && currentOwnerInfo.name !== null ? 
                                                getLabelValue(currentOwnerInfo.name, true, true) : "")}
                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                    isRequired={true}
                                />
                            </div>
                            <div className="floatLeft col-md-3">
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.nic`}
                                    labelText="Identification Number"
                                    maxLength={13}
                                    inputName={"nic"}
                                    inputValue={props.uiType === 'viewApproval' ? 
                                        (currentOwnerInfo && currentOwnerInfo.nic && currentOwnerInfo.nic !== null 
                                            ? getLabelValue(currentOwnerInfo.nic, true, true) : "") : 
                                            (currentOwnerInfo && currentOwnerInfo.nic && currentOwnerInfo.nic !== null ? 
                                                getLabelValue(currentOwnerInfo.nic, true, true) : "")}
                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                    isRequired={false}
                                    isAllowedFn={(value) => {
                                        console.log('VALL==4', value);
                                        const checkValue = value.value.toString();
                                        return checkValue.length < 13;
                                    }}
                                />
                            </div>
                            <div className="floatLeft col-md-3">
                            <div className="row justify-content-start">
                                                <div className="col-1 pr-0 pt-4">
                                                    0094
                                                </div>
                                                <div className="col-11 pt-1">
                                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.mobile_no`}
                                    labelText="Mobile Number "
                                    maxLength={9}
                                    inputName={"mobile_no"}
                                    isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={false}
                                    inputValue={props.uiType === 'viewApproval' ? 
                                        (currentOwnerInfo && currentOwnerInfo.contact_number && currentOwnerInfo.contact_number !== null 
                                            ? currentOwnerInfo.contact_number : "") : 
                                            (currentOwnerInfo && currentOwnerInfo.contact_number && currentOwnerInfo.contact_number !== null ? 
                                                currentOwnerInfo.contact_number : "")}
                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                    isRequired={false}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length < 10;
                                    }}
                                />
                                                </div>
                                            </div>
                               
                            </div>
                            <div className="floatLeft col-md-3">
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.address1`}
                                    labelText="Address Line 1 "
                                    maxLength={50}
                                    inputName={"address1"}
                                    inputValue={props.uiType === 'viewApproval' ? 
                                        (currentOwnerInfo && currentOwnerInfo.address1 && currentOwnerInfo.address1 !== null 
                                            ? getLabelValue(currentOwnerInfo.address1, true, true) : "") : 
                                            (currentOwnerInfo && currentOwnerInfo.address_line_1 && currentOwnerInfo.address_line_1 !== null ? 
                                                getLabelValue(currentOwnerInfo.address_line_1, true, true) : "")}
                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                    isRequired={true}
                                />
                            </div>
                            <div className="floatLeft col-md-3">
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.address2`}
                                    labelText="Address Line 2 "
                                    maxLength={50}
                                    inputName={"address2"}
                                    inputValue={props.uiType === 'viewApproval' ? 
                                        (currentOwnerInfo && currentOwnerInfo.address2 && currentOwnerInfo.address2 !== null 
                                            ? getLabelValue(currentOwnerInfo.address2, true, true) : "") : 
                                            (currentOwnerInfo && currentOwnerInfo.address_line_2 && currentOwnerInfo.address_line_2 !== null ? 
                                                getLabelValue(currentOwnerInfo.address_line_2, true, true) : "")}
                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                />
                            </div>
                            <div className="floatLeft col-md-3">
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.city`}
                                    labelText="City "
                                    maxLength={50}
                                    inputName={"city"}
                                    inputValue={props.uiType === 'viewApproval' ? (currentOwnerInfo && currentOwnerInfo.city && currentOwnerInfo.city !== null ? currentOwnerInfo.city : "") : (currentOwnerInfo && currentOwnerInfo.city && currentOwnerInfo.city !== null ? currentOwnerInfo.city : "")}
                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                    isRequired={true}
                                />
                            </div>
                            <div className="floatLeft col-md-3 clearBoth">
                                <SelectBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    apiUrl={provincesOfficesListAPI.url}
                                    keyName={"id"}
                                    valueName={"province.name"}
                                    inputValue={props.uiType === 'viewApproval' ? (currentOwnerInfo && currentOwnerInfo.province && currentOwnerInfo.province !== null ? currentOwnerInfo.province : "") : (currentOwnerInfo && currentOwnerInfo.province)}
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.province`}
                                    labelText="Province"
                                    emptySelectOptionTxt={"Select Province"}
                                    isRequired={true}
                                    isDisabled={props.uiType === 'viewApproval' ? true : false}
                                />
                            </div>
                            <div className="col-md-2 floatLeft">
                                <DatePickerWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName="transfer_vehicle"
                                    labelText="Transferred Date"
                                    minDate={props.uiType === 'viewApproval' ? (currentOwnerInfo && currentOwnerInfo.transferred_date && currentOwnerInfo.transferred_date !== null ? currentOwnerInfo.transferred_date : "") : currentOwnerInfo && currentOwnerInfo.transferred_on ? new Date(currentOwnerInfo.transferred_on) : 
                                    (_get(formState, "transfer_vehicle.previous_owners_data", []).length > 0 ? 
                                            new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] ? 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] : 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on'] : 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on']) : 
                                            '01/01/1970')}
                                    maxDate={new Date()}
                                    inputValue={props.uiType === 'viewApproval' ? (currentOwnerInfo && currentOwnerInfo.transferred_date && currentOwnerInfo.transferred_date !== null ? new Date(currentOwnerInfo.transferred_date) : null) : currentOwnerInfo && currentOwnerInfo.transferred_on && new Date(currentOwnerInfo.transferred_on)}
                                    inputStatePath={`transfer_vehicle.current_erl_new.${0}.transferred_date`}
                                    isDisabled={props.uiType === 'viewApproval' ? true : 
                                    (Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === 1 && isAdded === false) ? true : false}
                                />
                            </div>
                           {
                            Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).map((value, index) => {
                                return (
                                    <>
                                        {
                                            (!isAdded && props.uiType !== 'viewApproval' && Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length < 2) ? (
                                                <div className="floatRight">
                                                    <RoundButton
                                                        elementStyle={"mr-2"}
                                                        iconClass={"fas fa-plus"}
                                                        onClickBtnFn={(eventData) => {
                                                            formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.current_erl.${index}.name`);
                                                            setIsAdded(true)
                                                        }}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                    </>
                                )                    
                            }) 
                           }
                        </div>
                        </UICard>
                    }
                        {console.log('OOO--', isAdded, Object.keys(_get(formState, "transfer_vehicle.current_erl", {})))}
                    {
                        isAdded && Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === 1 ? 
                        Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).map((value, index) => {

                            return (
                                <UICard>
                                <div className={"row"}>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.name`}
                                            labelText="Name "
                                            maxLength={50}
                                            inputName={"name"}
                                        isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.nic`}
                                            labelText="Identification Number "
                                            maxLength={13}
                                            inputName={"nic"}
                                        isRequired={false}
                                        isAllowedFn={(value) => {
                                            console.log('VALL==5', value);
                                            const checkValue = value.value.toString();
                                            return checkValue.length < 13;
                                        }}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                    <div className="row justify-content-start">
                                                <div className="col-1 pr-0 pt-4">
                                                    0094
                                                </div>
                                                <div className="col-11 pt-1">
                                                <NumberInputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.mobile_no`}
                                            labelText="Mobile Number "
                                            maxLength={9}
                                            inputName={"mobile_no"}
                                            isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={false}
                                        isRequired={false}
                                        isAllowedFn={(value) => {
                                            const checkValue = value.value.toString();
                                            return checkValue.length < 10;
                                        }}
                                        />
                                                </div>
                                            </div>
                                        
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.address1`}
                                            labelText="Address Line 1 "
                                            maxLength={50}
                                            inputName={"address1"}
                                        isRequired={true}
                                        />
                                    </div>
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.address2`}
                                            labelText="Address Line 2 "
                                            maxLength={50}
                                            inputName={"address2"}
                                        isRequired={false}
                                        />
                                    </div>
                
                                    <div className="floatLeft col-md-3">
                                        <InputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.city`}
                                            labelText="City "
                                            maxLength={50}
                                            inputName={"city"}
                                        isRequired={true}
                                        />
                                    </div>
                
                                    <div className="floatLeft col-md-3 clearBoth">
                                        <SelectBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            apiUrl={provincesOfficesListAPI.url}
                                            keyName={"id"}
                                            valueName={"province.name"}
                                            // dataList={[
                                            //     {
                                            //         id: "1",
                                            //         value: "Western",
                                            //     },
                                            //     {
                                            //         id: "2",
                                            //         value: "Southern",
                                            //     },
                                            //     {
                                            //         id: "3",
                                            //         value: "Northern",
                                            //     },
                                            //     {
                                            //         id: "4",
                                            //         value: "Eastern",
                                            //     }
                                            // ]}
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.province`}
                                            labelText="Province"
                                            emptySelectOptionTxt={"Select Province"}
                                            isRequired={true}
                                        />
                                    </div>
                
                                    <div className="col-md-2 floatLeft">
                                        <DatePickerWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName="transfer_vehicle"
                                            labelText="Transferred Date"
                                            inputValue={null}
                                            minDate={currentOwnerInfo && currentOwnerInfo.transferred_on ? new Date(currentOwnerInfo.transferred_on) :
                                            (_get(formState, "transfer_vehicle.previous_owners_data", []).length > 0 ? 
                                            new Date((props.uiType === 'viewApproval' || props.uiType === 'APR') ? 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] ? 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_date'] : 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on'] : 
                                            _get(formState, "transfer_vehicle.previous_owners_data", [])[_get(formState, "transfer_vehicle.previous_owners_data", []).length - 1]['transferred_on']) : 
                                            '01/01/1970')}
                                            maxDate={new Date()}
                                            inputStatePath={`transfer_vehicle.current_erl.${index}.transferred_date`}
                                            isDisabled={props.uiType === 'viewApproval' ? true : 
                                            (index === Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length - 1) ? true : false}
                                        />
                                    </div>
                
                
                                    {
                                        (checkPlusBtnVisibility(index)) && props.uiType !== 'viewApproval' ? (
                                            <div className="floatRight">
                                                <RoundButton
                                                    elementStyle={"mr-2"}
                                                    iconClass={"fas fa-plus"}
                                                    onClickBtnFn={(eventData) => {
                                                        formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.current_erl.${index + 1}.name`);
                                                       
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }
                
                
                                    {
                                        (Object.keys(_get(formState, "transfer_vehicle.current_erl", {})).length === index + 1) ? (
                                            <RoundButton
                                                iconClass={"fas fa-minus"}
                                                elementStyle={"errorBtn"}
                                                onClickBtnFn={(eventData) => {
                                                        setShowPopup(true)
                                                        setRemoveIndex(index)
                                                }}
                                            />
                                        ) : null
                                    }
                                    <DialogPopup
                                        isOpen={showPopup}
                                        dialogTitle={"Remove"}
                                        isFullScreen={false}
                                        isCustomButton={true}
                                        customButtonComponent={RemoveBtn}
                                        formGroupName={"transfer_vehicle"}
                                        onClosePopupFn={() =>
                                            setShowPopup(!showPopup)
                                        }
                                        closeButton={{
                                            btnText: "No",
                                        }}
                                    >
                                        <div className="fullWidthDiv">
                                            <p>Are you sure you want to remove?</p>
                                        </div>
                                    </DialogPopup>
                                </div>
                                </UICard>
                            )
                        }) 
                        : null
                    }
                </UICard>
                <div className="defaultHalfPaddingLeft">
                    <CheckBoxWithState
                        labelText={"Re-Issuance Required"}
                        formGroupName={"transfer_vehicle"}
                        inputName={"isReissuanceRequired"}
                        isDisabled={props.uiType === 'viewApproval' ? true : false}
                    />
                </div>
            </Fragment>
        
    );
};

export { ErlInformation };

