/*
 * @Author:Gajani Kangesan
 * @Date: 2021-02-21 09:25:07
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:20:32
 */

import React, { Fragment, useContext, useState, useEffect } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import {
    SelectBoxWithState,
    SubmitButton,
    DatePickerWithState,
    FormWrapper,
    InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportGenerateAPI, provincesOfficesListAPI, AuthorityListApi, vehicleClassListAPI } from "../../../../../../../config/apiUrl.config";
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { CoreContext, apiResponseStoringType } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined
const SearchCriteria = ({
    reportCode,
    formGroupName,
    setReportgeneratedValues = emptyFn,
    onGenerateSubmit = emptyFn,
    onResetClick = emptyFn,
}) => {

    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const [coreState, coreAction] = useContext(CoreContext);

    let datefrom = new Date();
    let dateto = new Date();
    let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
    let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
    let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
    let generated_by = _get(authStatus, 'authUser.name', "")
    let role = _get(authStatus, 'authUser.rolePrefix', "")
    let authorityId = roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.authorityId', "") : "All";
    let authorityName = 'All';
    let vehicle_class = '';
    let vehicleId = "All";

    const [getdatalist, setDatalist] = useState(" ");
    useEffect(() => {

        coreAction
            .sendRequestFn(
                `${AuthorityListApi.url}?check_user_role=1&sort_by=name|asc&sort_by=name|asc&sort_by=name|asc`
            )
            .method("get")
            .setInitStoring(apiResponseStoringType.setResponse, {
                responseKey: AuthorityListApi.key,
            })
            .headers(true)
            .send((error, result) => {
                if (!error) {
                    if (roleLevelCode === "INT_AU") {
                        setDatalist(result.data.data.filter((item) => item.id === authorityId));

                    }
                    else {
                        setDatalist(result.data.data.filter((item) => item.province.id === provinceId))
                    }

                }
            });

    }, []);

      
    return (
        <div>
            <Fragment>
                <UICard>
                    <FormWrapper
                        setGroupName={formGroupName}
                        formGroupLinkWith={formGroupName}
                        onDestroyUnsetFormObject={true}
                        setFormObject={{
                            date_from: null,
                            date_to: null,
                            vehicle_class: vehicleId,
                            authority: roleLevelCode === "INT_AU" ? null : authorityId


                        }}

                    >

                        <div className="row ">
                            <div className="col-md-6 col-sm-12 ">
                                <SelectBoxWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    apiUrl={provincesOfficesListAPI.url}
                                    keyName={"id"}
                                    valueName={"province.name"}
                                    apiStateKey={provincesOfficesListAPI.key}
                                    emptySelectOptionTxt={provinceName}
                                    labelText="Province"
                                    inputName={"province"}
                                    isDisabled={"true"}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 ">
                                <DatePickerWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Date From"
                                    inputName={"date_from"}
                                    maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                                    minDate={setminDateRangeFn(_get(formState[formGroupName], "date_to", null),_get(formState[formGroupName], "date_from", null))}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 ">

                                <SelectBoxWithState
                                    isRequired={true}
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formGroupName}
                                    emptySelectOptionTxt={" "}
                                    isEnableAllOption={roleLevelCode === "INT_AU" ? false : true}
                                    dataList={getdatalist}
                                    //apiStateKey={AuthorityListApi.key}
                                    keyName={"id"}
                                    valueName={"service.name"}
                                    labelText="Authority"
                                    inputName={"authority"}
                                    onChangeFn={(value) => {
                                        formAction.changeInputFn(formGroupName, "counter", null);
                                    }}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 ">
                                <DatePickerWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Date To"
                                    inputName={"date_to"}
                                    minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                                    maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "date_from", null))}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 ">
                                <SelectBoxWithState
                                    isRequired={true}
                                    formGroupName={formGroupName}
                                    uiType={templateTypes.bootstrap}
                                    labelText="Vehicle Class"
                                    inputName={"vehicle_class"}
                                    apiUrl={`${vehicleClassListAPI.url}?sort_by=name|asc`}
                                    keyName={"id"}
                                    valueName={"name"}
                                    isEnableAllOption={true}
                                    apiStateKey={vehicleClassListAPI.key}
                                    emptySelectOptionTxt={" "}
                                />
                            </div>
                            <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">
                                <SubmitButton
                                    btnName={"generate"}
                                    btnText={"Generate"}
                                    formGroupName={formGroupName}
                                    startIcon="fas fa-wrench"
                                    isValidate={true}
                                    validationObject={{
                                        fileds: {
                                            date_from: "Date From",
                                            date_to: "Date To",
                                            vehicle_class: "Vehicle Class",
                                            authority: "Authority"
                                        },
                                        rules: {
                                            date_from: "required",
                                            date_to: "required",
                                            // vehicle_class: "required",
                                            authority: roleLevelCode === "INT_AU" ? "required" : "alphaSpecial",
                                        },
                                        message: {}
                                    }}
                                    callApiObject={{
                                        isSetHeaders: true,
                                        method: "get",
                                        multipart: false,
                                        onUpload: false,
                                        responseType: 'text'
                                    }}
                                    apiDataStoringObject={{
                                        setLoader: true,
                                        storingType: "API_RESPONSE_LINKED_FORM",
                                        mergeToSuccessResponse: true,
                                        mergeToErrorResponse: true
                                    }}
                                    onGetAPIEndPointFn={(formObject) => {

                                        if (!isEmptyValue(formObject.date_from)) {
                                            datefrom = dateObjectToString(new Date(formObject.date_from));
                                        }


                                        if (!isEmptyValue(formObject.date_to)) {
                                            dateto = dateObjectToString(new Date(formObject.date_to));
                                        }
                                        if (!isEmptyValue(formObject.province)) {
                                            provinceId = formObject.province
                                            provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                        }
                                        if (!isEmptyValue(formObject.authority)) {
                                            authorityId = formObject.authority
                                            authorityName = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.name)
                                            authorityName = (authorityName === undefined) ? "All" : authorityName
                                        }

                                        if (!isEmptyValue(formObject.vehicle_class)) {
                                            vehicleId = formObject.vehicle_class
                                            vehicle_class = (_get(coreState, `apiResponses.${vehicleClassListAPI.key}.result`, []).find(item => item.id === vehicleId)?.name)
                                            vehicle_class = (vehicle_class == null || '') ? 'All' : vehicle_class
                                        }

                                        return {
                                            url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestFromDate=${datefrom}&report_requestToDate=${dateto}&report_requestProvince=${provinceName}&report_provinceId=${provinceId}&report_authorityId=${authorityId}&report_requestAuthority=${authorityName}&report_vehicleClassId=${vehicleId}&report_requestVehicleClass=${vehicle_class}&report_generatedUser=${generated_by + "-" + _get(authStatus, 'authUser.provinceCode', "") + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                            key: `${reportGenerateAPI.key}`
                                        }
                                    }}

                                    onChangeRequestBodyFn={(formObject) => {
                                        return getDataByFormObject(formObject);
                                    }}

                                    onResponseCallBackFn={(error, response,) => {
                                        if (response?._statue) {
                                            onGenerateSubmit(response?.data)
                                            setReportgeneratedValues({
                                                datefrom,
                                                dateto,
                                                provinceId,
                                                provinceName,
                                                authorityId,
                                                authorityName: authorityName,
                                                vehicleId: vehicleId,
                                                vehicle_class: vehicle_class,
                                                limit: reportDataLimit,
                                                offset: reportDataOffset,
                                                reportGeneratedUser: generated_by + "-" + _get(authStatus, 'authUser.provinceCode', "") + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                            })
                                        }


                                    }}
                                />

                                <InputButtonWithState
                                    btnText="Reset"
                                    startIcon="fas fa-sync-alt"
                                    elementWrapperStyle={"refreshBtn"}
                                    formGroupName={formGroupName}
                                    mergeToForm={{
                                        date_from: null,
                                        date_to: null,
                                        authority: roleLevelCode === "INT_AU" ? null : authorityId,
                                        province: null,
                                        vehicle_class: vehicleId


                                    }}
                                    onClickBtnFn={(event) => {
                                        formAction.setFormErrorFn(formGroupName);
                                        onResetClick()

                                    }}
                                />

                            </div>
                        </div>
                    </FormWrapper>
                    <br />


                </UICard>




            </Fragment>
        </div>


    );
};

export {
    SearchCriteria
}
