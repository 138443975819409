/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-07 10:28:33
 * @Last Modified by: Jayani Nawarathnaa
 * @Last Modified time: 2022-12-01 11:32:16
 */
import React, { useContext } from "react";
import {
    FormWrapper,
    InputBoxWithState,
    InputButton,
    SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { UploadComponent } from "../../../../ui-components/ui-elements/upload/UploadComponent";
import { fileUploadsDetailsAPI, uploadsAPI, vehicleUploadOriginalAPI, dealerDocUploadOriginalAPI } from "../../../../../config/apiUrl.config";
import {
    actionBtnTypes,
    apiResponseStoringType,
    DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { CoreContext } from "../../../../modules/core/context-providers/CoreContext.provider";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { DMSMediatorApi } from "../../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormContext } from "../../../../modules/core/context-providers/FormContext.provider";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { RowNo, DeleteOtherBtn, DeleteUI, DownloadUI } from "./../../../../ui-components/ui-elements/documents-uploader/documents-uploader-includes/documents-uploader-includes";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

const ViewDocuments = ({
    formGroupName = "",
    isUploadFn = true,
    isDownload = true,
    isDelete = true,
    tableApiUrl = fileUploadsDetailsAPI,
    uploadsApiUrl = uploadsAPI.url,
    uiType = 'vehicle', //vehicle |dealer
    requestId = "",
    requestType
}) => {
    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const maxCount = 10;
    const [, uiAction] = useContext(UIContext);
    const formKey = "view_documents";

    const vehicle_id = _get(formState, "vehicle_search_form.searchKey", null);
    const dealer_id = _get(formState, "dealer_search_form.data.id", null);

    const download = async (fileUrl, fileName, props) => {
        var a = document.createElement("a");
        a.href = await fileUrl;
        a.setAttribute("download", fileName);
        a.click();
        props.onClosePopupFn();
    }
    const DownloadBtn = (props) => {
        return (
            <InputButton
                startIcon={"fas fa-download"}
                btnText={"Yes"}
                onClickBtnFn={() => download(`${props.row.document_path}`, props.row.file_name, props)}
            />
        )
    }

    const getActionTypesList = () => {
        const list = [];



        //   download("/static/ad920d8be55b3543cac62c795aa80df5/codesource.png", "Codesource_Logo.png");

        if (isDownload) {
            list.push({
                actionType: actionBtnTypes.other,
                actionBtn: {
                    tooltip: 'Download',
                    icon: 'fas fa-download',
                },
                modelContentElement: DownloadUI,
                dialogPopupProps: {
                    isFullScreen: false,
                    isFullWidth: false,
                    dialogTitle: "Document Download",
                    closeButton: {
                        btnText: "No",
                        startIcon: "far fa-times-circle",
                    },
                    isCustomButton: true,
                    customButtonComponent: DownloadBtn,
                },
            });
        }


        if (isDelete) {
            list.push({
                actionType: actionBtnTypes.delete,
                actionBtn: { tooltip: 'Remove' },
                modelContentElement: DeleteUI,
                dialogPopupProps: {
                    isFullScreen: false,
                    isFullWidth: false,
                    dialogTitle: "Delete File",
                    closeButton: {
                        btnText: "No",
                        startIcon: "far fa-times-circle",
                    },
                    isCustomButton: true,
                    customButtonComponent: DeleteOtherBtn,
                    customButtonComponentProps: {
                        dataTableKey: `${formGroupName}_dataTableKey`,
                        tableApiUrl: uiType === 'vehicle' ? vehicleUploadOriginalAPI : dealerDocUploadOriginalAPI
                    }
                },
                checkBeforeActionRenderFn: (rawData) => {
                    return (_get(rawData, "row.created_by", "") === _get(authStatus, "authUser.id", ""))
                }
            });
        }

        return list;
    };

    return (
        <div className={"fullWidthDiv"}>
            <FormWrapper
                setGroupName={formGroupName}
                setFormObject={{
                    description: ""
                }}
            >
                <div className={"col-md-12 defaultMarginBottom floatLeft"}>
                    <div className={"row"}>
                        <strong style={{ marginTop: '4px', marginRight: '12px' }}>Upload Documents : </strong>
                        <div>
                            <div>  <UploadComponent
                                className={"defaultPaddingTop"}
                                formGroupName={formGroupName}
                                formKey={"file_name"}
                                isMultiple={false}
                                labelText={"Test"}
                                getApiUrl={`${uploadsApiUrl}/`}
                                uploadApiUrl={uploadsApiUrl}
                                formObjectAppendKey={"files"}
                                isButtonOnly={true}
                            /></div>
                            <br></br>
                            <p >Max File Size : 15MB</p>
                        </div>
                    </div>
                </div>

                <InputBoxWithState
                    formGroupName={formGroupName}
                    inputName={"description"}
                    labelText="Document Description"
                    isMultiline={true}
                    rows={2}
                    isRequired={true}
                    maxLength={150}
                />

                <div className={"fullWidthDiv text-right "}>
                    <SubmitButton
                        btnText={"Add"}
                        elementStyle={"defaultSamebutton"}
                        formGroupName={formGroupName}
                        dataTableKey={`${formGroupName}_dataTableKey`}
                        isValidate={true}
                        startIcon={"fas fa-plus-circle"}
                        validationObject={{
                            fileds: {
                                description: "Document Description",
                                document_reference: "document_reference"
                                // file_name: "File Name"
                            },
                            rules: {
                                description: "required",
                                document_reference: "required"
                                // file_name: "required"
                            },
                            message: {
                                "description.required": "Document Description is required.",
                                // "file_name.required": "Choose a file to add"
                            }
                        }}
                        callApiObject={{
                            isSetHeaders: true,
                            multipart: false,
                            method: "post",
                            onUpload: false
                        }}
                        apiDataStoringObject={{
                            setLoader: true,
                            storingType: apiResponseStoringType.responseLinkToFrom,
                        }}
                        onGetAPIEndPointFn={(formObject) => {
                            return {
                                url: uiType === 'vehicle' ? vehicleUploadOriginalAPI.url : dealerDocUploadOriginalAPI.url,
                                key: uiType === 'vehicle' ? vehicleUploadOriginalAPI.key : dealerDocUploadOriginalAPI.key,
                            }


                        }}
                        onChangeRequestBodyFn={(formObject) => {
                            if (formObject && formObject.file_name && formObject.file_name.length !== 0) {
                                if (_get(coreState, `dataTableResponses`, null) !== null && _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey`, null) !== null && _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey.results`, []) && _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey.results`, []).length < maxCount) {
                                    return getDataByFormObject({
                                        ...formObject,
                                        file_name: formObject.file_name && formObject.file_name,
                                        document_reference: formObject.file_name_doc_references && formObject.file_name_doc_references,
                                        ui_request_id: requestId,
                                        vehicle_id: vehicle_id,
                                        dealer_id: dealer_id,
                                        document_path: formObject.file_name_doc_references && formObject.file_name_doc_references,
                                        description: formObject.description,
                                    });
                                } else {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Maximum files count that can be attached is ${maxCount}.`,
                                        messageType: "error",
                                    });
                                    return getDataByFormObject({
                                        description: formObject.description,
                                    });
                                    // setBtnClicked(false)
                                }
                            } else {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: 'Choose a file to add',
                                    messageType: "error",
                                });
                                return getDataByFormObject({
                                    description: formObject.description,
                                });
                            }

                        }}
                        onResponseCallBackFn={(error, result) => {
                            if (!error && result.data.data.file_name !== null) {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Document uploaded successfully`,
                                    messageType: "success",
                                });
                                formAction.initFromObjectFn(formGroupName)
                                    .isBackProsess(false)
                                    .setDescription(formGroupName)
                                    .setGroupName(formGroupName)
                                    .setLinkWithOthers(formGroupName)
                                    .setFormObject({})
                                    .setOnLoad(false)
                                    .generate();
                            }
                        }}
                    />
                </div>
            </FormWrapper>

            <br />

            <div className={"fullWidthDiv defaultPaddingTop"}>
                <DataTable
                    isSetTableHeader={false}
                    apiUrl={uiType === 'vehicle' ? `${vehicleUploadOriginalAPI.url}/${vehicle_id}?sort_by=updated_at|DESC` : `${dealerDocUploadOriginalAPI.url}/${dealer_id}?sort_by=updated_at|DESC`}
                    dataTableKey={`${formGroupName}_dataTableKey`}
                    isSetSearchFrom={false}
                    tableHeaderList={[
                        { displayName: "No.", key: "__no__", isSort: false },
                        {
                            displayName: "Uploaded Date & Time", key: "created_at", isSort: false, onPrintFn: (rawData) => {
                                return dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM");
                            }
                        },
                        { displayName: "Uploaded by", key: "created_by_name", isSort: false },
                        { displayName: "Document Description", key: "description", isSort: false },
                    ]}
                    isSetAction={isDownload || isDelete}
                    actionTypeList={getActionTypesList()}
                    specificComponents={{
                        "__no__": RowNo
                    }}
                />
            </div>
        </div>
    );
};

export { ViewDocuments };
