import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get, } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from "../../../../../config/permission.config";
function VehicleDetail() {
  const [authStatus, authAction] = useContext(AuthContext);
  const ViewPermission = _get(authStatus, 'authUser.permissions', "");
  let FR27, FR28, FR29, FR30
  ViewPermission.forEach(item => {
    if (item.permission === permissions.VIEW_FR27_VEHICLE_TRANSFER_REPORT.permissions[0]) {
      FR27 = true
    }
    else if (item.permission === permissions.VIEW_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT.permissions[0]) {
      FR28 = true
    }
    else if (item.permission === permissions.VIEW_FR29_VEHICLE_COUNT_SUMMARY_REPORT.permissions[0]) {
      FR29 = true
    }
    else if (item.permission === permissions.VIEW_FR30_VEHICLE_EDIT_SUMMARY_REPORT.permissions[0]) {
      FR30 = true
    }
  })
  return (
    <UICard>
      < ol type="1">
        <div className="App">
          {FR27 &&
            <li>
              <Link to="/vehicle-transfer-report">
                <strong>Vehicle Transfer Report</strong>
              </Link>
              <br />
              <p> This is the Vehicle Transfer Report</p>
              <br />
            </li>

          }
          {FR28 &&
            <li>
              <Link to="/vehicle-transfer-validation-report">
                <strong>Vehicle Transfer Validation Report</strong>
              </Link>
              <br />
              <p> This is the Vehicle Transfer Validation Report</p>
              <br />
            </li>
          }
          {FR29 &&
            <li>
              <Link to="/vehicle-count-summary-report">
                <strong>Vehicle Count Summary Report</strong>
              </Link>
              <br />
              <p> This is the Vehicle Count Summary Report</p>
              <br />
            </li>
          }
          {FR30 &&
            <li>
              <Link to="/vehicle-edit-summary-report">
                <strong> Vehicle Edit Summary Report</strong>
              </Link>
              <br />
              <p> This is the Vehicle Edit Summary Report</p>
            </li>
          }
        </div>
      </ ol>
    </UICard>
  );
};

const VehicleDetailReport = () => {
  return (
    <DashboardTemplate
      pageTitle="Vehicle Detail Reports"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          //urlTo: "/report",
          displayName: "Vehicle Detail Reports List",
        },
      ]}
    >
      <VehicleDetail />
    </DashboardTemplate>
  );
};

export { VehicleDetailReport };