/*
 * @Author: Madhawa
 * @Date: 2021-01-12 11:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-01-10 05:14:34
 */

import { cancelLicenceReceiptTransactionAPI,uploadDetailGetAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { Action } from "./includes/Action";
import { Reason } from "./includes/Reason";
import {CancelLicenceInformation } from "../pages/includes/CancelLicenceInformation";
import { RequestStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";

const CancelLcnSubmitBtn = (props) => {
  const [formState] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  const user_role_level = _get(authStatus, "authUser.role_level_code", "");
  const { sessionId } = props
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.data.vehicle_no",
    null
  );
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const approvalAction = _get(
    formState,
    "cancel-licence.action",
   null
  )
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const serviceId = _get(authStatus, "authUser.service_id", "");

  const [, uiAction] = useContext(UIContext);
  const request_id = _get(formState, "cancel-licence.request_id", null);

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ? 
  _get(authStatus, "authUser.provinceName", "<anonymous province>") : 
  ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");
  
  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={"cancel-licence"}
        tooltip={"Submit"}
        isValidate={true}
        isSetHeaders={true}
      //   flashMessages={{
      //     failed: props.uiType === 'assign&Perform' && {
      //         status: true,
      //         message: `Approval Request Decision Submit Failed. Please Try Again.`,
      //         messageType: "error",
      //     } 
      //     // : {
      //     //     status: true,
      //     //     message: `Approval Request Submit Failed. Please Try Again.`,
      //     //     messageType: "error",
      //     // },
      // }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "cancel-licence.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "cancel-licence.action", "") === 'FRD' ? (_get(formState, "cancel-licence.forward_role", "") === 'OPT' ?
          {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
          } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
          }) : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
            }) : {
              "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "cancel-licence.action", "") === 'FRD' ? (_get(formState, "cancel-licence.forward_role", "") === 'OPT' ? {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              forward_role: "required",
              forward_province: "required",
              forward_authority: "required",
          } : {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
              forward_role: "required",
              forward_province: "required",
          }): {
              reason_id: "required",
              action: "required",
              remark: "required|max:500",
            }) : {
              "reason_id": "required",
              "remark": "required|max:500",
              "forward_province": "required",
              "forward_role": "required",
              "action": "required",
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "cancel-licence.action", "") === 'FRD' ? (_get(formState, "cancel-licence.forward_role", "") === 'OPT' ? {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              "forward_role.required": "Role is required.",
              "forward_province": "Province is required",
              "forward_authority": "Authority is required",
          } : {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
              "forward_role.required": "Role is required.",
              "forward_province": "Province is required",
          }): {
              "reason_id.required": "Reason is required.",
              "action.required": "Action is required.",
              "remark.required": "Remark is required.",
            }) : {
              "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province": "Forward Province is required.",
            "forward_role": "Forward Role is required.",
            "action": "Action is required.",

          },
      }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${cancelLicenceReceiptTransactionAPI.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
            key: cancelLicenceReceiptTransactionAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          console.log('FORMMMM===', fromObject);
          if(props.uiType === 'assign&Perform') {
            if(_get(formState, "cancel-licence.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                  status: true,
                  message: `Action is required`,
                  messageType: "error",
                });

                return
          }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["cncl_lcn_r_txn"],
              current_licence_type: current_licence_type,
              ui_request_id: props.approvalRequestId,
              // status: RequestStatus.APPROVAL_PENDING,
              // request_type: RequestType.Cancel_Licence_Receipt_Transaction,
              pdmt_id: pdmtId,
              // authority_id: authorityId,
              // request_id: fromObject.request_id,
              reason_id: formState['cancel-licence-new'].reason_id,
              // vehicle_no: vehicle_no,
              document_details: [],
              counter_session_id: '00001',
              forward_authority_service_id: serviceId,
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              },
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["cncl_lcn_r_txn"],
              current_licence_type: current_licence_type,
              status: RequestStatus.APPROVAL_PENDING,
              // request_type: RequestType.Cancel_Licence_Receipt_Transaction,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: fromObject.request_id,
              reason_id: fromObject.reason_id,
              vehicle_no: vehicle_no,
              document_details: [],
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority,
              },
            });
          }
          
        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === 'assign&Perform' ? "put" : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined          
          ) {
            if(props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
                messageType: "success",
              });
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request successfully submitted. Request ID: ${request_id}`,
                messageType: "success",
              });
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
            
          } else {
            uiAction.setFlashMessage({
              status:
                _get(response, "data.errors.details[0].message", null) == null
                  ? false
                  : true,
              message: 'Select a Licence / Arrears Transaction.',
              messageType: "error",
            });
           
              if (props.uiType === 'assign&Perform') {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Decision Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
              else {
                if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                  uiAction.setFlashMessage({
                    status: true,
                    message: response.data.errors.details[0].message,
                    messageType: "error",
                  });
                }
                else {
                  uiAction.setFlashMessage({
                    status: true,
                    message: `Approval Request Submit Failed. Please Try Again.`,
                    messageType: "error",
                  });
                }
              }
            }
        }}
      />
    </Fragment>
  );
};

const CancelLicenceUI = (props) => {
  const formKey = props.formKey;
  const uiType = props.uiType;
  
  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8))
  }, [])

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <CancelLicenceInformation {...props} formKey={formKey} requestId={uiType  === 'APR' ?  props.approvalRequestId : requestId}/>
      </div>
      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <Reason requestType={uiType} dataSubmittedForApproval={props.dataSubmittedForApproval} formKey={uiType === 'APR' ? `${formKey}-new` : formKey} />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType  === 'APR' ?  `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType  === 'APR' ?  props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <Action dataSubmittedForApproval={props.dataSubmittedForApproval} formKey={formKey} uiType={uiType} />
      </div>
    </div>
  );
};

const CancelLicence = () => {
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Cancel Licence / Receipt Transaction" },
      ]}
      pageTitle="Cancel Licence / Receipt Transaction"
    >
      <CancelLicenceUI />
    </DashboardTemplate>
  );
};

export default CancelLicence;
export { CancelLicenceUI, CancelLcnSubmitBtn };
