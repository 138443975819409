// @Author: Gajani Kangesan 
//  * @Date: 2023-01-04 13:30:59 
import React from "react";
import { isEmpty } from "lodash";
const LicenceReceiptPrint =({
    dataList=[]
})=>{
    return(
        <div className="web-print-card"  >
            {/* <!--========left side======= --> */}
            <span className="F1" >{dataList.lhs_issued_counter}</span>
            <span className="F2">{dataList.lhs_licence_no}</span>
            <span className="F3" >{dataList.lhs_licence_type}</span>
            <span className="F4">{dataList.lhs_vehicle_type}</span>

            <span className="F5">{dataList.lhs_licence_year_month}</span>
            <span className="F6" >{dataList.lhs_owner_name_line1}</span>
            <span className="F7" >{dataList.lhs_owner_name_line2}</span>
            <span className="F8" >{dataList.lhs_owner_name_line3}</span>

            <span className="F9" >{dataList.lhs_owner_address_line1}</span>
            <span className="F10" >{dataList.lhs_owner_address_line2}</span>
            <span className="F11" >{dataList.lhs_owner_address_line3}</span>
            <span className="F12" >{dataList.lhs_total}</span>
            <span name="span_C1" className="F13" >:</span>
            <span className="F14">{dataList.lhs_charges_breakdown_line1}</span>
            {/* <span className="F15"
              font_code="FV11">{dataList.", "")}lhs_charges_breakdown_line2</span> */}
            <span className="F16" >{dataList.lhs_valid_from}</span>
            <span name="span_C2" className="F17" >To</span>
            <span className="F18" >{dataList.lhs_valid_to}</span>
            <span className="F19">{dataList.lhs_vet_no}</span>
            <span className="F20">{dataList.lhs_no_of_seats}</span>

            <span className="F21">{dataList.lhs_weight}</span>

            <span className="F22" format="png">{dataList.signature_uid}</span>
            {/* <span class="F23">
              ---------------------
            </span> */}
            <span className="F24">{dataList.lhs_issued_by}</span>
            <span className="F25">{dataList.lhs_issued_date}</span>
            {/* <span className="F26" >{dataList.", "")}lhs_not_for_running</span> */}

            {/* <!--========Right side======= --> */}
            <span className="F27" >{dataList.rhs_vehicle_no}</span>
            <span className="F28" >{dataList.rhs_vehicle_class}</span>
            <span className="F29">{dataList.rhs_owner_name_line1}</span>
            <span className="F30" >{dataList.rhs_owner_address_line1}</span>
            <span className="F31">{dataList.rhs_owner_address_line2}</span>

            <span className="F32">{isEmpty(dataList.rhs_chassis_no)?"-" : dataList.rhs_chassis_no}</span>
            <span className="F33">{isEmpty(dataList.rhs_fuel_type)?"-":dataList.rhs_fuel_type}</span>
            <span className="F34" >{isEmpty(dataList.rhs_weight)?"-":dataList.rhs_weight}</span>
            <span className="F35" >{dataList.rhs_first_reg_date}</span>
            <span className="F36">{isEmpty(dataList.rhs_no_of_seats)?"-":dataList.rhs_no_of_seats}</span>
            <span className="F37">{dataList.rhs_licence_no}</span>
            <span className="F38">{dataList.rhs_previous_licence_date}</span>
            <span className="F39" >{dataList.rhs_insurance_status}</span>
            <span className="F40">{dataList.rhs_vrp_expiry_date}</span>

            <span className="F41" >{dataList.rhs_fee}</span>
            <span className="F42">{dataList.rhs_arrears}</span>
            <span className="F43">{dataList.rhs_fine}</span>
            <span className="F44" >{dataList.rhs_addtions}</span>
            <span className="F45">{dataList.rhs_deductions}</span>
            <span className="F46">{dataList.rhs_total}</span>
            <span className="F47" >{dataList.rhs_issued_date}</span>
          </div>
    )
}

export default LicenceReceiptPrint;
