/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-12 13:59:59 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-10-27 10:40:45
 */
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, Fragment } from 'react'
import { availableCounterListApi, availableSessionListApi, completeQuotationPaymentAPI } from '../../../../../config/apiUrl.config';
import { callApi } from '../../../../../helpers/common-helpers/callApi.helpers';
import { getDataByFormObject } from '../../../../../helpers/common-helpers/common.helpers';
import { _get, _unset } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { InputButton, SubmitButton } from '../../../../ui-components/ui-elements/form';
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { apiResponseStoringType, CoreContext } from '../../../core/context-providers/CoreContext.provider';
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { useLicencePrint } from '../../../../hooks/common-hooks/usePrinter.hook';
import { UIContext } from '../../../../ui-components/context-providers/UIContext.provider';
import LicenceReceiptPrint from './LicenceReceiptPrint';
const emptyFun = (...para) => undefined;

const QuotationConfirmationPopup = ({
  closePopupFn = emptyFun,
  closeConfirmationPopupFn = emptyFun,
  onClickSuccessFn = emptyFun,
  openPopupFn = emptyFun,
  uiType = "quotation",
  quotationId = "",
  qtData = "",
  vehicleCount = ""
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <strong>Are you sure you want to complete the payment?</strong>
      </div>
      <div className="col-md-12 text-right defaultMarginTop">
        <PayBtn
          closePopupFn={closePopupFn}
          onClickSuccessFn={onClickSuccessFn}
          uiType={uiType}
          quotationId={quotationId}
          qtData={qtData}
          vehicleCount={vehicleCount}
        />
        <InputButton
          elementWrapperStyle={"defaultHalfMarginLeftRight"}
          btnText="No"
          onClickBtnFn={closePopupFn}
          startIcon={"fas fa-times"}
        />
      </div>
    </div>
  );
}


const PayBtn = (props) => {
  const formGroupName = "printReceipt";
  const uiType = props.uiType;
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [, uiAction] = useContext(UIContext);
  const { printFn, bulkPrintFn } = useLicencePrint();
  const vehicleCount = props.vehicleCount;

  const authority_id = _get(authStatus, "authUser.authorityId", "");
  const paid_types = [];

  const paymentFormObj = getDataByFormObject(_get(formState, "paymentTypeFormKey", {}));
  _unset(paymentFormObj, "isDisplay");
  _unset(paymentFormObj, "payment_categories");
  _unset(paymentFormObj, "paid_amount");
  _unset(paymentFormObj, "cash_amount");
  _unset(paymentFormObj, "cheque_amount");
  _unset(paymentFormObj, "card_amount");

  if (!isEmpty(paymentFormObj)) {
    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        paid_types.push({
          "payment_type_code": _get(paymentFormObj, `[${key}]['payment_type']`, null),
          "payment_gateway_code": null,
          "amount": parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null)),
          "cus_paid_amount": parseFloat(_get(paymentFormObj, `[${key}]['amount']`, null)),
          "cheque_number": _get(paymentFormObj, `[${key}]['cheque_no']`, null),
          "cheque_date": null,
          "bank_id": _get(paymentFormObj, `[${key}]['bank']`, null),
          "card_type": null,
          "card_holder_name": null,
          "request_ref_no": _get(paymentFormObj, `[${key}]['reference_no']`, null),
          "payment_ref": null,
          "ipg_ref": null,
          "phone_no": null,
          "approval_code": _get(paymentFormObj, `[${key}]['approval_no']`, null),
          "note": null,
          "request_str": null,
          "response_str": null,
          "success": true,
          "legacy_cheque_id": null
        });
      }
    });
  }
  const onpreview = (printFrame) => {
    let divsToPrint = document.getElementsByClassName(printFrame), n;
    let printContents = "";
    for (n = 0; n < divsToPrint.length; n++) {
      printContents += divsToPrint[n].innerHTML + "<br>";
    }
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    uiAction.setFlashMessage({
      status: true,
      message: "Receipt is successfully Printed .",
      messageType: "success"
    });
    window.location.reload();
    document.body.innerHTML = originalContents;
    setTimeout(function () {
      window.print();
    }, 1100)
    window.close()

  }

  return (
    <Fragment>
      <SubmitButton
        btnText={"Yes"}
        startIcon={"fas fa-check"}
        formGroupName={formGroupName}
        isValidate={false}
        flashMessages={{
          success: {
            status: true,
            message: `Payment successfully completed.`,
            messageType: "success",
          },
          failed: {
            status: true,
            message: `Payment fail.`,
            messageType: "error",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: completeQuotationPaymentAPI.url,
            key: completeQuotationPaymentAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          return getDataByFormObject({
            quotation_id: _get(props, "quotationId", null),
            paid_types: paid_types,
            manual_receipt_no: null,
            licence_end_type: 0,
            authority_id: authority_id,
            is_reissuance: false,
            authority_code: _get(authStatus, 'authUser.authorityCode', ""),
            province_code: _get(authStatus, 'authUser.pdmtId.code', ""),
            document_validations: _get(formState, 'quotation_payment.documentValidations', [])
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: "post",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.responseLinkToFrom,
        }}
        onResponseCallBackFn={(error, response) => {
          if (response) {
            let completedLicences = _get(response, 'data.data.completed_licence');
            formAction.changeInputFn("print_licences", "data", "", response.data.data.print_licences);
            let printLicenceResponse = _get(response, 'data.data.print_licences', []);
            // if (completedLicences.length > 1) { //Bulk print response handling
            //   bulkPrintFn(coreAction, printLicenceResponse);
            // } else {//Single print response
            //   printFn(coreAction, printLicenceResponse[0]);
            // }
            onpreview("print-card")

          }
          if (!error) {

            formAction.changeInputFn("licence_charges_form", "data", "", []);
            formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
            formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
            formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
            formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
            formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
            formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
            formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
            formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
            formAction.changeInputFn("licence_charges_form", "total", "", 0);
            // TODO -> Need to remove after bug fixing
            // callApi(`${availableCounterListApi.url}/${authority_id}/counters/${response.counter_id}`)
            //   .method("get")
            //   .headers(true)
            //   .body({})
            //   .isMultipart(false)
            //   .send((error, result) => {
            //     if (!error) {
            //       const nxt_serial = _get(result, "data.data.next_serial_number", "") == null ? "" : _get(result, "data.data.next_serial_number", "");
            //       formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", nxt_serial);
            //     }
            //   });

            props.closePopupFn();
            props.onClickSuccessFn();
            props.qtData.mainPopupCloseFn();
            coreAction.resetDataTable("quotationAPIKey_list");
          } else {

          }
        }}
      />
      <div className='licence-visibility'>
        {vehicleCount === 1 ?
          (<div className="print-card">
            <LicenceReceiptPrint dataList={_get(formState, "print_licences.data[0].data", "")} />
          </div>)
          :
          (_get(formState, "print_licences.data[0].data.licences", []).map((item) => (
            <div className="print-card">
              <LicenceReceiptPrint dataList={item} />
              <br /><br /><br />
            </div>

          )))}

      </div>
    </Fragment>
  );
};


export { QuotationConfirmationPopup }