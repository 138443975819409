import React, { Component } from 'react';
import { DataTable, actionBtnTypes } from "../../../ui-components/ui-elements/table/DataTable";
import { View, PoutePermit } from "./View";

class Test1 extends Component {

    constructor() {
        super();
        this.state = {
            count: 111,
            prices: 0,
        };
    }

    static getDerivedStateFromProps(props, state) {
        console.log("====getDerivedStateFromProps======");
        return {
            "prices": props.data * 10
        };
    }

    componentDidMount() {
        console.log("====componentDidMount======");
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("====shouldComponentUpdate======");
        return true;
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        console.log("====getSnapshotBeforeUpdate======");
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("====componentDidUpdate======", snapshot);
    }

    componentWillUnmount() {
        console.log("====componentWillUnmount======");
    }


    render() {
        console.log("====render======");
        return (
            // <div>test {this.props.data}
            //     <br />
            //     {this.state.count}
            //     <br />
            //     {this.state.prices}

            //     {/* <div>

            //         <Slide
            //             dataList={["images/file-word.png", "images/licence.jpg", "images/file-pdf.png"]}
            //             helperText={["First one", "Second one", "Third one"]}

            //         />



            //     </div> */}

            // </div>
            <DataTable
                tableName={"View "}
                dataTableKey={"manageRange"}
                isSetAction={true}
                isSetTableFooter={false}

                tableHeaderList={[
                    { displayName: "No", key: "no", isSort: false },
                    { displayName: "Rate Code", key: "rateCode", default: "-", isSort: false },
                    { displayName: "Rate Description", key: "description", isSort: false, default: "-", },
                    { displayName: "Effective From", key: "from", isSort: false, default: "-", },
                    { displayName: "Create Date", key: "crDate", isSort: false, default: "-", },
                ]}
                tableBodyList={[
                    { "no": "01", "rateCode": "000002", "description": "test", "from": "19/11/2020", "crDate": "19/11/2020" },
                    { "no": "02", "rateCode": "000003", "description": "test2", "from": "19/11/2020", "crDate": "19/11/2020" }
                ]}
                actionTypeList={[

                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: { tooltip: 'View' },
                        modelContentElement: View,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Rate",

                            closeButton: {
                                btnText: "Cancel",
                            },
                        }

                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: { tooltip: 'View' },
                        modelContentElement: PoutePermit,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Rate",

                            closeButton: {
                                btnText: "Cancel",
                            },
                        }

                    },

                ]}

            />
            // <div>

            //     <Slide
            //         dataList={["images/file-word.png", "images/licence.jpg", "images/file-pdf.png"]}
            //         helperText={["First one", "Second one", "Third one"]}

            //     />

            // </div>
        )
    }
}

class Test2 extends Component {

    constructor() {
        super();
        this.state = {
            "count": 0
        };
    }

    render() {
        return (
            <div className="col-md-12">
                <button onClick={() => this.setState({
                    count: this.state.count + 1
                })}>Click {this.state.count}</button>
                <Test1 data={this.state.count} />
            </div>
        )
    }
}

export default Test2;