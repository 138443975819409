/*
 * @Author: Chamith Jayathilaka
 * @Date: 2022-05-30 13:17:03
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-05-30 16:38:50
 */

import React, { useContext } from "react";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { CommentSectionIcons } from "./CommentSectionIcons";
import { LimitTextLength } from "../../../../ui-components/ui-elements/common/BaseElements";
import { commentPostAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form/index";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const CommentsSection = ({
  formKey = "carnetComment", //CarnetComment || NormalComment
  vehicleId = "xxxxx-xxxx-xxxx-xxxx-xxxxxxxxxx", //Need The raw id for the api
  vehicle_no = "CAK-XXX", //vehicleId from vehicle search
  getVehicleStatusCode=null,
  params = ""
}) => {
  const [formState, formAction] = useContext(FormContext);
  return (
    <PaddingLessUICard
      cardHeading="Comments"
      passPropsToCardHeadingTail={{
        uiType: "comments",
        formGroupName: formKey,
        vehicleId: vehicleId,
        getVehicleStatusCode: getVehicleStatusCode,
        params: params,
      }}
      cardHeadingTail={CommentSectionIcons}
    >
      <FormWrapper
        elementStyle={"counterCommentWrapper"}
        setGroupName={ `${formKey}_view`}
        apiUrl={`${commentPostAPI.url}/latest/${vehicle_no}`} 
        onRebuildResponseFn={(response) => {
          return {
            latestRecord: response,
            _onLoad: false,
          };
        }}
      >
        <LimitTextLength
          maxLenth={500}
          displayText={
            _get(formState, `${formKey}_view`, null)?.latestRecord?.comment
          }
        />
      </FormWrapper>
    </PaddingLessUICard>
  );
};

export { CommentsSection };
