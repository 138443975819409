/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-05-23 09:42:17 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-01 15:15:54
 */
import { useMemo, useContext, useEffect, useCallback, useState } from 'react';

import { defaultTemplateType } from "../../../config/template.config";
import { CoreContext } from "../../modules/core/context-providers/CoreContext.provider";
import { FormContext } from "../../modules/core/context-providers/FormContext.provider";
import { _get } from "../../../helpers/common-helpers/lodash.wrappers";
import { generateQueryString } from "../../../helpers/common-helpers/common.helpers";
import { useScreenType } from "./useScreenStatus.hook";
import { usePrevious } from "./usePrevious.hook";
import validate from "../../../helpers/validation";

const emptyFun = (...para) => undefined;

const useTableState = (
    apiUrl = null,
    dataTableKey = "",
    templateType = defaultTemplateType,
    tableName = "Data Table",
    isSetAction = true,
    isSetSearchFrom = false,
    searchFromComponent = null,
    specificComponents = {},
    defaultSearchFormObject = {},
    tableHeaderList = [],
    tableBodyList = [],
    actionTypeList = [],
    maxItemCount = 2,
    isSelectRow = false,
    onSetSearchObjectFn = false,
    isSearchWithSelect=false,
) => {

    const [screenType] = useScreenType();
    const [coreState, coreAction] = useContext(CoreContext);
    const [, formAction] = useContext(FormContext);

    const [dataTableState, setDataTableStateFn] = useState({
        isShowSearch: false,
        formObject: defaultSearchFormObject, //setting Default form Object
        modelStatus: {
            type: "actionList",
            status: false,
            index: null,
            rowIndex: null,
            props: {}
        }
    });

    const _apiUrl = useMemo(() => apiUrl, [apiUrl]);
    const _dataTableKey = useMemo(() => dataTableKey, [dataTableKey]);
    const _templateType = useMemo(() => templateType, [templateType]);
    const _tableName = useMemo(() => tableName, [tableName]);
    const _isSetAction = useMemo(() => isSetAction, [isSetAction]);
    const _isSetSearchFrom = useMemo(() => isSetSearchFrom, [isSetSearchFrom]);
    const _searchFromComponent = useMemo(() => searchFromComponent, [searchFromComponent]);
    const _specificComponents = useMemo(() => specificComponents, [specificComponents]);
    const _defaultSearchFormObject = useMemo(() => defaultSearchFormObject, [defaultSearchFormObject]);
    const _tableHeaderList = useMemo(() => tableHeaderList, [tableHeaderList]);
    const _tableBodyList = useMemo(() => tableBodyList, [tableBodyList]);
    const _actionTypeList = useMemo(() => actionTypeList, [actionTypeList]);
    const _screenType = useMemo(() => screenType, [screenType]);
    const _isSelectRow = useMemo(() => isSelectRow, [isSelectRow]);
    const _onSetSearchObjectFn = useMemo(() => onSetSearchObjectFn, [onSetSearchObjectFn]);


    const _maxItemCount = useMemo(() => {
        if (maxItemCount > 0 && maxItemCount <= 3 && _tableHeaderList.length > maxItemCount) {
            return maxItemCount;
        } else {
            return 0;
        }
    }, [maxItemCount, _tableHeaderList]);

    const _responseUpdateStatus = useMemo(() => {
        return _get(coreState, `dataTableResponses.${_dataTableKey}._updateStatus`, undefined);
    }, [_dataTableKey, coreState]);

    const _dataTableErros = useMemo(() => {
        return _get(coreState, `dataTableResponses.${_dataTableKey}.errors`, undefined);
    }, [_dataTableKey, coreState]);

    const _reloadStatus = useMemo(() => {
        return _get(coreState, `dataTableResponses.${_dataTableKey}._reloadDataTable`, undefined);
    }, [_dataTableKey, coreState]);

    const _shortUpdateStatus = useMemo(() => {
        return _get(coreState, `dataTableResponses.${_dataTableKey}.shortBy._updateStatus`, undefined);
    }, [_dataTableKey, coreState]);

    const _shortObject = useMemo(() => {
        return _get(coreState, `dataTableResponses.${_dataTableKey}.shortBy`, {});
    }, [_dataTableKey, coreState]);

    const _responseFetchingStatus = useMemo(() => {
        return _get(coreState, `dataTableResponses.${_dataTableKey}.fetching`, undefined);
    }, [_dataTableKey, coreState]);


    const previousState = usePrevious({
        "shortUpdateStatus": _shortUpdateStatus,
        "reloadStatus": _reloadStatus,
        "apiUrl": _apiUrl
    });

    const _pagingObject = useMemo(() => {
        return {
            "current_page": _get(coreState, `dataTableResponses.${_dataTableKey}.current_page`, 1),
            "per_page": _get(coreState, `dataTableResponses.${_dataTableKey}.per_page`, 10),
            "total": _get(coreState, `dataTableResponses.${_dataTableKey}.total`, null),
            "page_count": _get(coreState, `dataTableResponses.${_dataTableKey}.page_count`, null),
            "page_size": _get(coreState, `dataTableResponses.${_dataTableKey}.page_size`, 10),
        }
    }, [_dataTableKey, coreState]);


    const _tableBody = useMemo(() => {
        if (Array.isArray(_tableBodyList) && _tableBodyList.length !== 0) {
            if (_responseFetchingStatus !== "done") {
                coreAction.setDataTable(_dataTableKey, _tableBodyList, 'done', 1, null, null);
            }
            return _tableBodyList;
        } else {
            if(!isSearchWithSelect) {
                return _get(coreState, `dataTableResponses.${_dataTableKey}.results`, []);
            }
        }
    }, [coreState, _dataTableKey, _tableBodyList, coreAction, _responseFetchingStatus]);

    const requestAPIDataFn = useCallback((type = null, data = null) => {
        if (_apiUrl !== null) {
            let getDataUrl = _apiUrl;
            if (type === "next") {
                let formObject=dataTableState.formObject
                if (onSetSearchObjectFn !== false) {
                    formObject = onSetSearchObjectFn(dataTableState.formObject);
                };
                getDataUrl = `${_apiUrl}${_apiUrl.includes("?") === true ? "&" : "?"}${generateQueryString(data, formObject, _shortObject)}`;
            } else if (type === "searchFrom") {
                getDataUrl = `${_apiUrl}${_apiUrl.includes("?") === true ? "&" : "?"}${generateQueryString(1, data, _shortObject)}`;
            } else if (type === "sort") {
                getDataUrl = `${_apiUrl}${_apiUrl.includes("?") === true ? "&" : "?"}${generateQueryString(1, dataTableState.formObject, _shortObject)}`;
            } else if (type === "none") {
                let formObject=dataTableState.formObject
                if (onSetSearchObjectFn !== false) {
                    formObject = onSetSearchObjectFn(dataTableState.formObject);
                };
                getDataUrl = `${_apiUrl}${_apiUrl.includes("?") === true ? "&" : "?"}${generateQueryString(_pagingObject.current_page, formObject, _shortObject)}`;
            }

            coreAction.sendRequestFn(getDataUrl)
                .method("get")
                .setInitStoring("DATA_TABLE", _dataTableKey)
                .send();
        }

    }, [_apiUrl, _shortObject, dataTableState, _pagingObject, coreAction, _dataTableKey]);


    const onSubmitCurdFn = useCallback((formData, formGroupName) => {

        const formObject = _get(_actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.getRequestBodyFN`, emptyFun)(formData);
        const getResponseBodyFn = _get(_actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.getResponseBodyFN`, emptyFun);
        const urlObj = _get(_actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.getAPIUrlFn`, emptyFun)({
            "row": _get(dataTableState, 'modelStatus.props', {}),
            "formData": formObject
        });
        const apiPara = _get(_actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.callAPiPara`, {});

        if (urlObj !== false) {

            let apiObj = coreAction.sendRequestFn(urlObj.url)
                .method(apiPara.method)
                .setLoader(true)
                .body(formObject)
                .setFlashMessages(_get(apiPara, "flashMessages", {}))
                .setInitStoring(apiPara.dataStoringType, apiPara.dataStoringKeys);

            if (_get(apiPara, 'isValidate', false) === true) {
                apiObj.setValidationObject(
                    _get(apiPara, 'validationObject.fileds', {}),
                    _get(apiPara, 'validationObject.rules', {}),
                    _get(apiPara, 'validationObject.message', {})
                );
            }

            apiObj.send((error, result) => {
                if (error) {
                    console.log("ERR====", error);
                } else {
                    setDataTableStateFn({
                        ...dataTableState,
                        modelStatus: {
                            ...dataTableState.modelStatus,
                            status: false,
                            index: null,
                            rowIndex: null,
                            props: {}
                        }
                    });
                }
                getResponseBodyFn(error, result);
            });
        } else {
            if (_get(apiPara, 'isValidate', false) === true) {

                validate(formObject)
                    .setFileds(_get(apiPara, 'validationObject.fileds', {}))
                    .setRules(_get(apiPara, 'validationObject.rules', {}))
                    .setMessage(_get(apiPara, 'validationObject.message', {}))
                    .run((error, result) => {
                        if (error) {
                            formAction.setFormErrorFn(formGroupName, error);
                            getResponseBodyFn(error, null);
                        } else {
                            formAction.setFormErrorFn(formGroupName, []);

                            if (_get(dataTableState, 'modelStatus.rowIndex', null) !== null) {
                                let newRow = {
                                    ..._get(dataTableState, 'modelStatus.props', {}),
                                    ...formObject
                                };

                                coreAction.updateDataTableRow(dataTableKey, _get(dataTableState, 'modelStatus.rowIndex', null), newRow);
                            }

                            setDataTableStateFn({
                                ...dataTableState,
                                modelStatus: {
                                    ...dataTableState.modelStatus,
                                    status: false,
                                    index: null,
                                    rowIndex: null,
                                    props: {}
                                }
                            });

                            getResponseBodyFn(null, formObject);
                        }
                    });

            } else {
                setDataTableStateFn({
                    ...dataTableState,
                    modelStatus: {
                        ...dataTableState.modelStatus,
                        status: false,
                        index: null,
                        props: {}
                    }
                });

                getResponseBodyFn(null, formObject);
            }
        }

    }, [coreAction, _actionTypeList, dataTableState]);


    const onChangeDataTableFieldValueFn = useCallback((stateKey = "", inputKey = "", value = "") => {
        coreAction.onChangeDataTableFieldValue(stateKey, inputKey, value)
    }, [coreAction]);

    const _setDataTableStateFn = useCallback((stateObject = {}) => {
        setDataTableStateFn(stateObject);
    }, [setDataTableStateFn]);

    const onShortFn = useCallback((tableKey, elementKey, currentStatus) => {
        coreAction.onShortFn(tableKey, elementKey, currentStatus);
    }, [coreAction]);


    useEffect(() => {

        if (_responseFetchingStatus === undefined) {
            coreAction.initDataTable(_dataTableKey);
            if (_apiUrl !== null) {
                
                requestAPIDataFn('none'); //
            }
        }

    }, [_responseFetchingStatus, coreAction, _dataTableKey, _apiUrl, requestAPIDataFn]);

    useEffect(() => {
        if (_get(previousState, 'shortUpdateStatus', undefined) !== _shortUpdateStatus) {
            requestAPIDataFn("sort", null);
        }
    }, [_shortUpdateStatus, requestAPIDataFn, previousState]);

    useEffect(() => {
        if (_get(previousState, 'reloadStatus', undefined) !== _reloadStatus) {
            requestAPIDataFn("none", null);
        }
    }, [_reloadStatus, requestAPIDataFn, previousState]);

    useEffect(() => {
        if (_get(previousState, 'apiUrl', undefined) !== _apiUrl) {
            requestAPIDataFn("none", null);
        }
    }, [_apiUrl, requestAPIDataFn, previousState]);


    return [
        _apiUrl,
        _dataTableKey,
        _templateType,
        _tableName,
        _isSetAction,
        _isSetSearchFrom,
        _searchFromComponent,
        _specificComponents,
        _defaultSearchFormObject,
        _tableHeaderList,
        _actionTypeList,
        _screenType,
        _responseUpdateStatus,
        _reloadStatus,
        _responseFetchingStatus,
        _pagingObject,
        _tableBody,
        _shortObject,
        _maxItemCount,
        _dataTableErros,
        _isSelectRow,
        requestAPIDataFn,
        dataTableState,
        _setDataTableStateFn,
        onChangeDataTableFieldValueFn,
        onSubmitCurdFn,
        onShortFn,
        _onSetSearchObjectFn
    ]
}

export {
    useTableState
}