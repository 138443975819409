/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-04-07 16:34:01
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 13:07:42
 */
import React, { Fragment, useContext, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import { UIContext } from '../../context-providers/UIContext.provider';
import { Icon } from './BaseElements';

const SnackbarWrapper = ({
  openStatus = false,
  duration = 10000,
  message = null,
  index = '1',
  messageType = 'success',
  isNotificationMsj = false,
  notificationRoute = null
}) => {
  
  const [open, setOpen] = useState(openStatus);
  const handleClose = () => {
    setOpen(false);
  };

  const ClosseButton = () => {
    return (
        <div className='floatRight defaultPaddingLeftRight'>
          <Icon
              elementStyle={messageType === 'success' ? ('successPopupCloseIcon') : ('errorPopupCloseIcon')}
              iconClass={'fas fa-times'}
              onClickFn={
                handleClose
              }
          />
        </div>
    )
  };

  const handleNavigateToNotifications = () => {
    notificationRoute.push('/notification')
    setOpen(!openStatus)
  }

  return (
      <div className={'defaulSnackBarWrapper'}>
        <Snackbar
            style={isNotificationMsj ? { marginBottom: index * 70 } : {}}
            open={open}
            autoHideDuration={duration}
            key={index}
            anchorOrigin={{ vertical: isNotificationMsj ? 'bottom' : 'top', horizontal: isNotificationMsj ? 'right' : 'center', }}
            TransitionComponent={Slide}
        >
          <div style={isNotificationMsj && {cursor: 'pointer'}} onClick={isNotificationMsj && handleNavigateToNotifications} className='alertSize'>
            <Alert severity={messageType} variant={isNotificationMsj ? "filled" : "standard"}>
              {message}
              <ClosseButton />
            </Alert>

          </div>
        </Snackbar>
      </div >
  );
};

const SnackBarList = () => {
  const [uiState] = useContext(UIContext);

  return (
      <Fragment>
        {(uiState.flashMessageList || []).map((value, index) => {
          return (
              <SnackbarWrapper
                  key={index}
                  index={index}
                  openStatus={value.status || false}
                  duration={500000}
                  message={value.message || ''}
                  messageType={value.messageType || ''}
                  isNotificationMsj={value.isNotificationMsj || false}
                  notificationRoute={value.notificationRoute || null}
              />
          );
        })}
      </Fragment>
  );
};

export { SnackbarWrapper, SnackBarList };
