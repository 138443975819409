/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Document Uploader
 * @Date: 2021-07-08 13:24:56
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-10-22 18:58:24
 */
import React, { useContext, useEffect, useState, Fragment } from "react";


import { FormWrapper, InputBoxWithState, SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { fileUploadsDetailsAPI, getDealerCurrentValidlicenceDetailsAPI, uploadDetailSaveAPI, uploadDeleteAPI, AutharizedVehicleInformationAPI } from "../../../../../../config/apiUrl.config";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { DatePickerWithState, InputButton } from "../../../../../ui-components/ui-elements/form";
import { DeleteVehicleNumberBtn, DeleteVehicleNumberUI } from "./DeleteVehicleNumber";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { templateTypes } from "../../../../../../config/template.config";
import { UICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { DataTable, actionBtnTypes, apiResponseStoringType } from "../../../../../ui-components/ui-elements/table/DataTable";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";
import { AddVehicleInfoDeleteBtn, VehicleInfoDeleteUI, VehicleInfoRowNo } from "./AddVehicleInfoDeleteBtn";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import {

    CoreContext,
} from "../../../../core/context-providers/CoreContext.provider.js";

const VehicleInfoUploader = ({
    elementWrapperStyle = "",
    formGroupName = "",
    isUploadFn = true,
    isDownload = false,
    isDelete = true,
    tableApiUrl = "",
    uploadsApiUrl = "",
    requestId = "",
    counterId = "",
    uiType
}) => {
    const [authStatus] = useContext(AuthContext);
    const [formState, formAction] = useContext(FormContext);
    const [coreState, coreAction] = useContext(CoreContext);
    const [getLatestState, setLatestState] = useState(null);
  const mandatory = uiType === 'APR' ? true : false;

  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    // const [tableState] = useContext(CoreContext);
    // const tableData = _get(tableState, `dataTableResponses.${formGroupName}.results`, "").length;
    const [, uiAction] = useContext(UIContext);
    useEffect(() => {
        coreAction
            .sendRequestFn(
                tableApiUrl
            )
            .method("get")
            .setInitStoring(apiResponseStoringType.setResponse, {
                responseKey: AutharizedVehicleInformationAPI.key,
            })
            .headers(true)
            .send((error, result) => {
                if (!error) {

                    setLatestState(result.data.data);
                    formAction.changeInputFn("vehicle_added_data_set", "data", "", result.data.data);
                }
            });



    }, []);

    const DisplayActionTypesList = () => {
        const list = [];

        if (isDelete) {
            list.push({
                actionType: actionBtnTypes.delete,
                actionBtn: { tooltip: 'Remove' },
                modelContentElement: VehicleInfoDeleteUI,
                dialogPopupProps: {
                    isFullScreen: false,
                    isFullWidth: false,
                    dialogTitle: "Delete File",
                    closeButton: {
                        btnText: "No",
                        startIcon: "far fa-times-circle",
                    },
                    isCustomButton: true,
                    customButtonComponent: AddVehicleInfoDeleteBtn,
                    customButtonComponentProps: {
                        dataTableKey: `${formGroupName}_dataTableKey`,
                        tableApiUrl: AutharizedVehicleInformationAPI
                    }
                },
            });
        }

        return list;
    };

    console.log('aaaa', getLatestState);

    return (

        <Fragment>

            <FormWrapper setGroupName={formGroupName} >
                <UICard cardHeading={"Authorized Vehicle Information"} >

                    <div className="row addLicenceLabel">
                        <div className="col-md-3 col-sm-6 mt">Authorized Vehicle Number <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                        <div className="col-md-3 col-sm-6">
                            <InputBoxWithState
                                formGroupName={formGroupName}
                                inputValue={""}
                                inputName={"autharized_vehicle_no"}
                                maxLength={20}
                            />
                        </div>
                    </div>

                    <div className="row addLicenceLabel">
                        <div className="col-md-3 col-sm-6 mt">Serial Number <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                        <div className="col-md-3 col-sm-6">
                            <InputBoxWithState
                                formGroupName={formGroupName}
                                inputValue={""}
                                inputName={"serial_no"}
                                maxLength={12}
                            />
                        </div>
                    </div>

                    <div className="row addLicenceLabel">
                        <div className="col-md-3 col-sm-6 mt">Lic. No. <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                        <div className="col-md-3 col-sm-6">
                            <InputBoxWithState
                                formGroupName={formGroupName}
                                inputValue={""}
                                inputName={"licence_no"}
                                maxLength={15}
                            />
                        </div>
                    </div>

                    <div className="row addLicenceLabel">
                        <div className="col-md-3 col-sm-6 mt">Licence From Date <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                        <div className="col-md-3 col-sm-6">
                            <DatePickerWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName={formGroupName}
                                labelText=""
                                maxDate="01/01/2100"
                                inputName={"from_date"}
                            />
                        </div>
                    </div>

                    <div className="row addLicenceLabel">
                        <div className="col-md-3 col-sm-6 mt">Licence To Date <span className="mandatory">{mandatory === true ? "*" : ""}</span></div>
                        <div className="col-md-3 col-sm-6">
                            <DatePickerWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName={formGroupName}
                                labelText=""
                                inputName={"to_date"}
                                maxDate="01/01/2100"
                                minDate={_get(formState, `${formGroupName}.from_date`, new Date())}
                            />
                        </div>
                    </div>
                    <div className="row addLicenceLabel">
                        <div className="col-md-3 col-sm-6 mt"></div>
                        <div className="col-md-3 col-sm-6 d-flex justify-content-end defaultMarginTop">
                            <SubmitButton
                                btnText={"Add"}
                                formGroupName={formGroupName}
                                dataTableKey={`${formGroupName}_dataTableKey`}
                                isValidate={true}
                                startIcon={"fas fa-plus"}
                                flashMessages={{
                                    "success": {
                                        status: true,
                                        message: "Vehicle information successfully added.",
                                        messageType: "success"
                                    }
                                }}
                                validationObject={{
                                    fileds: {
                                        autharized_vehicle_no: "Authorized Vehicle Number",
                                        serial_no: "Serial Number",
                                        licence_no: "Lic. No.",
                                    },
                                    rules: {
                                        autharized_vehicle_no: "required",
                                        serial_no: "required",
                                        licence_no: "required",
                                    },
                                    message: {
                                        "autharized_vehicle_no.required": "Autharized Vehicle Number is required.",
                                        "serial_number.required": "Serial Number is required.",
                                        "licence_no.required": "Licence Number is required.",
                                    }
                                }}
                                callApiObject={{
                                    isSetHeaders: true,
                                    multipart: false,
                                    method: "post",
                                    onUpload: false
                                }}
                                apiDataStoringObject={{
                                    setLoader: true,
                                    storingType: apiResponseStoringType.responseLinkToFrom,
                                }}
                                onGetAPIEndPointFn={(formObject) => {
                                    return {
                                        url: `${AutharizedVehicleInformationAPI.url}/add-vehicle-info`,
                                        key: AutharizedVehicleInformationAPI.key
                                    }
                                }}
                                onChangeRequestBodyFn={(formObject) => {
                                    const error = []
                                    const addedVehicleData = _get(coreState, `dataTableResponses.${formGroupName}_dataTableKey.results`, [])
                                    
                                    if(addedVehicleData.length > 0) {
                                        for(let vehicle of addedVehicleData) {
                                            const messageV = vehicle.vehicle_no === formObject.autharized_vehicle_no ? 'Authorized vehicle number is already added' : null
                                            error.push(messageV)

                                            const messageS = vehicle.receipt_serial_number === formObject.serial_no ? 'Serial number is already added' : null
                                            error.push(messageS)

                                            const messageL = vehicle.licence_no_receipt_no === formObject.licence_no ? 'Licence number is already added' : null
                                            error.push(messageL)
                                          
                                        }
                                    }

                                    const errMsg = error.find(e => e !== null)

                                    if(errMsg) {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: errMsg,
                                            messageType: "error",
                                        });
                                    } else {
                                        const vehicleData = _get(formState, "vehicle_data_set.data", [])
                                        if (vehicleData.length > 0) {
                                            const findVehicle = vehicleData.find(v => v.vehicle_number === formObject.autharized_vehicle_no)
                                            if (!findVehicle) {
                                                if(!formObject.autharized_vehicle_no || !formObject.serial_no || !formObject.licence_no) {
                                                    return
                                                } else {
                                                    uiAction.setFlashMessage({
                                                        status: true,
                                                        message: `Authorized vehicle number ${formObject.autharized_vehicle_no} is not exist in this dealer.`,
                                                        messageType: "error",
                                                    });
                                                }
                                                
                                            } else {
                                                return getDataByFormObject({
                                                    ...formObject,
                                                    request_id: requestId,
                                                    issued_system: _get(formState, "add_licence_transaction.issued_system", null),
                                                    pdmt_id: pdmtId,
                                                    autharized_vehicle_no: formObject.autharized_vehicle_no,
                                                    serial_no: formObject.serial_no,
                                                    licence_no: formObject.licence_no,
                                                    from_date: formObject.from_date ? formObject.from_date : new Date(),
                                                    to_date: formObject.to_date ? formObject.to_date : new Date(),
                                                    counter_id: counterId ? counterId : null
                                                });
                                            }
                                        } else {
                                            uiAction.setFlashMessage({
                                                status: true,
                                                message: `No any registered vehicles found for this dealer`,
                                                messageType: "error",
                                            });
                                        }
                                    }

                                    

                                }}
                                onResponseCallBackFn={(response, error) => {
                                    const vehicleData = _get(formState, "vehicle_data_set.data", [])
                                    if (vehicleData.length > 0) {
                                        const findVehicle = vehicleData.find(v => v.vehicle_number === _get(formState, `${formGroupName}.autharized_vehicle_no`, ''))
                                        if (!findVehicle) {
                                            console.log('fff');
                                        } else {
                                            if (
                                                response.length === undefined &&
                                                error === undefined &&
                                                response.data.errors === undefined
                                              ) {

                                                  if(response.data?.response?.statusCode === 400) {
                                                    uiAction.setFlashMessage({
                                                      status: true,
                                                      message: `${response.data.response?.message ?? 'Add Vehicle For Licence Failed. Please Try Again.'}`,
                                                      messageType: "error",
                                                    });
                                                  } else {
                                                        formAction.initFromObjectFn(formGroupName)
                                                            .isBackProsess(false)
                                                            .setDescription(formGroupName)
                                                            .setGroupName(formGroupName)
                                                            .setLinkWithOthers(formGroupName)
                                                            .setFormObject({})
                                                            .setOnLoad(false)
                                                            .generate();
                                                    
                                                  }
                                              } else {
                                                if(response.data?.meta?.message === 'failed' && Object.keys(response.data.errors).length === 1) {
                                                  uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `${response.data.errors?.name ?? 'Approval Request Decision Submit Failed. Please Try Again.'}`,
                                                    messageType: "error",
                                                  });
                                                }
                                              }
                                        }
                                    }

                                }}
                            />
                        </div>
                    </div>

                    <div className={" mt-3 mb-3" }>
                    <DataTable
                        tableName={"Vehicle Information"}
                        isSetTableHeader={true}
                        apiUrl={tableApiUrl}
                        dataTableKey={`${formGroupName}_dataTableKey`}
                        isSetSearchFrom={false}
                        tableHeaderList={[
                            { displayName: "No.", key: "__no__", isSort: false },
                            { displayName: "Authorized Vehicle No.", key: "vehicle_no", default: "-", isSort: false },
                            { displayName: "Serial Number", key: "receipt_serial_number", isSort: false },
                            { displayName: "Lic. No.", key: "licence_no_receipt_no", isSort: false },
                            {
                                displayName: "Licence From Date", key: "from", isSort: false, onPrintFn: (rawData) => {
                                    return dateObjectToString(rawData.from, "dd/mm/YY HH:MM");
                                }
                            },
                            {
                                displayName: "Licence to Date", key: "to", isSort: false, onPrintFn: (rawData) => {
                                    return dateObjectToString(rawData.to, "dd/mm/YY HH:MM");
                                }
                            },
                        ]}
                        isSetAction={isDelete}
                        actionTypeList={DisplayActionTypesList()}
                        specificComponents={{
                            "__no__": VehicleInfoRowNo
                        }}
                    />
            </div>

                </UICard>

            </FormWrapper>


            

        </Fragment>

    );
};

export default VehicleInfoUploader;