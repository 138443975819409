/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Switch Wrappers
 * @Date: 2020-02-05 09:44:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 13:28:04
 */

import React, { memo,Fragment } from 'react';
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {TooltipWrapper} from "../common/BaseElements";
import { FormGroupWrapper} from "./form-includes/FormCoreIncludes";
import {defaultTemplateType, templateTypes,formCacheLevel} from "../../../../config/template.config";
import { FormHelperTextWrapper } from "./form-includes/FormCoreIncludes";
import {toBoolean} from "../../../../helpers/common-helpers/common.helpers";
import {useBasicInput} from "../../../hooks/common-hooks/useFormInputState.hook";


const emptyFun = (...para) => undefined;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Input Switch Wrapper
 * --------------------------------------------
 */

// InputSwitch size options
const inputSwtSizes = {
    small: "small",
    medium: "medium"
};

// InputSwitch color options
const inputSwtColors = {
    default: "default",
    primary: "primary",
    secondary: "secondary"
};

// InputSwitch label placement options
const inputSwtLabelPlacements = {
    end: "end",
    start: "start",
    top: "top",
    bottom: "bottom"
};

const InputSwitch = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    labelText = "",
    inputName = "",
    inputValue = false,
    inputError = "",
    helperText = "",
    isDisabled = false,
    swtSize = inputSwtSizes.small,
    swtColor = inputSwtColors.secondary,
    fieldDescribe = "",
    labelPlacement = inputSwtLabelPlacements.top,
    onChangeFn = emptyFun
  }) => {
      return (
        <div className={`${uiType}InputSwitchWrapper ${elementWrapperStyle}`}>
            <FormGroupWrapper>
                <Fragment>
                <FormControlLabel
                    label={
                        <div>
                            <h3 className={`defaultInputSwitchLabel ${elementStyle}`}>
                                <TooltipWrapper 
                                    description={fieldDescribe}
                                >
                                    <span>{labelText}</span>
                                </TooltipWrapper>    
                            </h3>
                        </div>
                    }
                    labelPlacement={labelPlacement}
                    control={
                        <Switch
                            size={swtSize}
                            color={swtColor}
                            checked={toBoolean(inputValue)}
                            onChange={event =>
                                onChangeFn({
                                name: inputName,
                                value: !toBoolean(inputValue),
                                eventInfo: event
                                })
                            }
                        />
                    }
                    disabled={isDisabled}
                    />

                <FormHelperTextWrapper
                    elementStyle={`${(inputError !== "") ? "inputSwitchErrorText" : "inputSwitchHelperText"}`}
                    inputError={inputError}
                    helperText={helperText}
                />
                </Fragment>
            </FormGroupWrapper>
        </div>
      );
  };

  /**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Input Switch Wrapper
 * --------------------------------------------
 */

InputSwitch.propTypes = {
    /** UI type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** element Wrapper css class */
    elementWrapperStyle: PropTypes.string,
    /** Switch element css class */
    elementStyle: PropTypes.string,
    /** Lable text */
    lableText: PropTypes.string,
    /** In out value name */
    inputName: PropTypes.string,
    /** In out value */
    inputValue: PropTypes.bool,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Is switch diable or not */
    isSwtDisabled: PropTypes.bool,
    /** Switch size */
    swtSize: PropTypes.oneOf(['small', 'medium']),
    /** Switch color */
    swtColor: PropTypes.oneOf(['primary', 'secondary', 'default']),
    /** Tooltip description */
    fieldDescribe: PropTypes.string,
    /** Label position */
    labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
    /** OnChange event function */
    onChangeFn: PropTypes.func
};

//----------------InputSwitch---------------------

/**
 * memo render 
 * @param {Object} prevProps 
 * @param {Object} nextProps 
 */
const areEqual=(prevProps, nextProps) =>{
    if(nextProps.cacheLevel===formCacheLevel.none){
      return false;
    }else if(nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange){
      return (
        (prevProps.uiType===nextProps.uiType) && (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
        (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.labelText===nextProps.labelText) &&         
        (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
        (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
        (prevProps.inputValue===nextProps.inputValue) && (prevProps.updateStatus===nextProps.updateStatus)
      )
    }else if(nextProps.cacheLevel===formCacheLevel.updateOnIndividual){
      return (
        (prevProps.uiType===nextProps.uiType) && (prevProps.elementWrapperStyle===nextProps.elementWrapperStyle) &&
        (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.labelText===nextProps.labelText) &&         
        (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
        (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
        (prevProps.inputValue===nextProps.inputValue)
      )
    }
    
  }
  
  const InputSwitchMemo=memo(InputSwitch,areEqual);


/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Input Switch With State
 * --------------------------------------------
 */

const InputSwitchWithState = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    labelText = "",
    inputName = "",
    inputValue = false,
    inputError = "",
    helperText = "",
    isDisabled = false,
    swtSize = inputSwtSizes.small,
    swtColor = inputSwtColors.secondary,
    fieldDescribe = "",
    labelPlacement = inputSwtLabelPlacements.top,
    formGroupName = "",
    inputStatePath = "",
    cacheLevel=formCacheLevel.updateOnFormGroupChange,
    onChangeFn = emptyFun
}) => {

    const [currentValue,currentError,updateStatus,handleOnChangeFn]=useBasicInput(inputStatePath,formGroupName,inputName,inputValue,onChangeFn);


    return (
        <InputSwitchMemo 
            uiType={uiType}
            elementWrapperStyle={elementWrapperStyle}
            elementStyle={elementStyle}
            labelText={labelText}
            inputName={inputName}
            inputError={currentError}
            helperText={helperText}
            inputValue={toBoolean(currentValue)}
            isDisabled={isDisabled}
            swtSize={swtSize}
            swtColor={swtColor}
            fieldDescribe={fieldDescribe}
            labelPlacement={labelPlacement}
            onChangeFn={(eventData) => {
                handleOnChangeFn(eventData);
            }}
            updateStatus={updateStatus}
            cacheLevel={cacheLevel}
        />      
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Input Switch With State
 * --------------------------------------------
 */

InputSwitchWithState.propTypes = {
    /** UI type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** element Wrapper css class */
    elementWrapperStyle: PropTypes.string,
    /** Switch element css class */
    elementStyle: PropTypes.string,
    /** Lable text */
    lableText: PropTypes.string,
    /** In out value name */
    inputName: PropTypes.string,
    /** In out value */
    inputValue: PropTypes.bool,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Is switch diable or not */
    isSwtDisabled: PropTypes.bool,
    /** Switch size */
    swtSize: PropTypes.oneOf(['small', 'medium']),
    /** Switch color */
    swtColor: PropTypes.oneOf(['primary', 'secondary', 'default']),
    /** Tooltip description */
    fieldDescribe: PropTypes.string,
    /** Label position */
    labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
    /** Form group name */
    formGroupName: PropTypes.string,
    /** Input element state avilable path(use for manage complex objects) */
    inputStatePath: PropTypes.string,
    /** OnChange event function */
    onChangeFn: PropTypes.func
};

//----------------InputSwitchWithState---------------------

export {
    InputSwitch,
    inputSwtSizes,
    inputSwtColors,
    inputSwtLabelPlacements,
    InputSwitchWithState,
    InputSwitchMemo
};
