/*
 * @Author: Senura Weerasinghe 
 * @Date: 2021-01-12 12:37:57 
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-01-13 10:35:53
 * @Last Modified time: 2021-01-18 11:41:45
 */


import React, { Fragment, useState, useEffect, useContext } from "react";
import { actionBtnTypes } from '../../../../../../config/dataTable.config'
import { ViewTransactions } from "../../includes/ViewTransactions"
import { DataTable } from "../../../../../ui-components/ui-elements/table/DataTable"
import { CircleLoaderElement, ViewDetailBlock } from '../../../../../ui-components/ui-elements/common/BaseElements';
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { settlementAPI, AccountNumberListAPI } from "../../../../../../config/apiUrl.config";
import { dateObjectToString } from '../../../../../../helpers/common-helpers/dateTime.helpers';
import {
  _getInt,
  _get,
} from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { RefreshSettlement } from "./RefreshSettlement";
import { CustomRevenueButton } from "./CustomButton";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";

const ViewRevenueSettlement = (props) => {
  const [authStatus, authAction] = useContext(AuthContext);

  const [settlement, setSettlement] = useState('');
  const [service, setService] = useState([]);
  const [onLoad, setOnLoad] = useState(true);
  const [accountNumber, setAccountNumber] = useState()
  const[formState,formAction]=useContext(FormContext)
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "")
  const provinceId = roleCategoryCode === "COM" ? _get(authStatus, "authUser.pdmtId", "") : _get(authStatus, "authUser.pdmtId.id", "");
  let x = []
  useEffect(() => {
    callApi(`${AccountNumberListAPI.url}/${provinceId}`)
      .method("get")
      .headers(true)
      .body({})
      .isMultipart(false)
      .send((error, result) => {

        if (!error) {
          setAccountNumber(result.data.data.account_number)

        }
      });

  }, [])

  useEffect(() => {
    callApi(`${settlementAPI.url}/${props.row.id}`)
      .headers(true)
      .method("get")
      .send((err, result) => {
        if (result) {
          setSettlement(result.data.data);
          let provinces = result.data.data.ServiceSettlement.map((svc) => {
            return {
              province: svc.province, revenue_transfered: svc.amount, service_settlement_id: svc.id, message: svc.status_message, status: svc.status, manually_settled:svc.manually_settled
            }
          });
          setService(provinces)
        }
        setOnLoad(false)
      });
  }, []);

const mainScreenFn=()=>{
  props.onClosePopupFn()
}
  return (
    <Fragment>

      {onLoad === true ? (
        <div>
          <br />
          <center>
            <CircleLoaderElement />
          </center>
        </div>
      ) :

        (
          <Fragment>
            <ViewDetailBlock
              label={"Account Number:"}
              value={accountNumber}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
            <ViewDetailBlock
              label={"Amount Transferred:"}
              value={settlement.amount}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
            <ViewDetailBlock
              label={"Transaction To:"}
              value={dateObjectToString(settlement.transaction_to, "dd/mm/YY")}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
            <ViewDetailBlock
              label={"Account Balance:"}
              value={settlement.account_balance}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
            <ViewDetailBlock
              label={"Settlement Date:"}
              value={dateObjectToString(settlement.settlement_at, "dd/mm/YY HH:MM")}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
            <ViewDetailBlock
              label={"Remark:"}
              value={settlement.remarks}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
            <ViewDetailBlock
              label={"Created by:"}
              value={settlement.created_user_display}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding"}
            />
            <ViewDetailBlock
              label={"Created at:"}
              value={dateObjectToString(settlement.created_at, "dd/mm/YY HH:MM")}
              labelWrapperStyle={"defaultHalfPadding"}
              valueWrapperStyle={"defaultHalfPadding defaultHalfMarginBottom"}
            />

            {service.length > 0 && <DataTable
              tableHeaderList={[
                { displayName: "Province", key: "province", isSort: false },
                { displayName: "Revenue Transferred", key: "revenue_transfered", isSort: false,isRightAlign: true },
                {
                  displayName: "Status", key: "status", isSort: false, onPrintFn: (rawData) => {
                    if( rawData.manually_settled==1){
                      return "Failed"
                    }else{
                      if (rawData.status === "PEN") {
                        return "Pending"
                      }
                      else if (rawData.status === "SUC") {
                        return "Success"
                      }
                      else if (rawData.status === "FAL") {
                        return "Failed"
                      }
                      else if (rawData.status === "PRO") {
                        return "Processing"
                      }
                      
                    }  
                  }
                },
                { displayName: "Note", key: "message", isSort: false },
                { displayName: "Changed Status", key: "changed_status", isSort: false, onPrintFn: (rawData) => rawData.manually_settled==1?(rawData.status=== "SUC" ? "Success" : rawData.status=== "FAL"?"Failed":rawData.status=== "PEN" ?"Pending":"-" ):"-"},


              ]}
              tableBodyList={service}

              isSetAction={true}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.view,
                  actionBtn: { tooltip: "View Transaction" },
                  modelContentElement: ViewTransactions,
                  dialogPopupProps: {
                    isFullScreen: true,
                    dialogTitle: "View Transactions",
                    isCustomButton: false,
                    closeButton: {
                      btnText: "Close",
                    },
                  },
                },
                {
                  actionType: actionBtnTypes.other,
                  actionBtn: { tooltip: "Check Status" },
                  modelContentElement: RefreshSettlement,
                  dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "Check Status",
                    isCustomButton: true,
                    customButtonComponent: CustomRevenueButton,
                    customButtonComponentProps: {
                     mainScreenCloseFn:mainScreenFn
                  },
                    
                    closeButton: {
                      btnText: "Close",
                    },
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    return (_get(rawData, "row.status", "") === "PRO" || _get(rawData, "row.manually_settled", "") ===1 )
                  }
                },

              ]}
            />}
          </Fragment>
        )}
    </Fragment>
  )
}

export { ViewRevenueSettlement }