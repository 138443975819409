/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-06 11:19:49
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-30 15:20:35
 */
import React, { Fragment, useContext, useEffect, useState } from "react";

import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { PaddingLessUICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper } from "../../../../../ui-components/ui-elements/form";
import { DocumentValidation } from "../../includes/DocumentValidation";
import LicenceValidityPeriod from "./LicenceValidityPeriod";
import { OwnerDetailsIcons } from "../../includes/OwnerDetailsIcons";
import { OwnerInfoDisplay } from "../../includes/OwnerInfoDisplay";
import { VehicleInfoDisplay } from "../../includes/VehicleInfoDisplay";
import { FreeLicence } from "../../includes/FreeLicence";
import { PaymentHistory } from "../../includes/PaymentHistory";
import { NonUserLicence } from "../../includes/NonUserLicence";
import { calculateLicenceFeeAPI, vehiclePaymentHistoryAPI } from "../../../../../../config/apiUrl.config";
import { fromObjectToQueryString } from "../../../../../../helpers/common-helpers/common.helpers"
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { CommentsSection } from "../../includes/CommentsSection"
import { Specialmessage } from "../../includes/Specialmessage"
import { LicenceTypeCodes, MaxArrearsPeriod } from "../../../../../../config/enum.config";
import { numberThousandSeperator } from "../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../helpers/common-helpers/dateTime.helpers";
import { isEmpty } from "lodash";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { ChargingDetails } from "./ChargingDetails";
import LicenceArrearsDetails from "./LicenceArrearsDetails";
import { OwnerInfo, VehicleInfo, vehicleInfo } from "./OwnerInfo";
const emptyFun = (...para) => undefined;
const formKey = "normal_licence";

const NormalLicenceElement = ({
    getState = {
        currentObject: null,
        licenseArrearsRecords: [],
    },
    getVehicleStatusCode = 'ELI',
    getLatestLicenceState = null,
    setState = emptyFun,
    uiType = "normalLicence",
    params = "",
    //refreshIssueValidFn=emptyFun,
    setShowHideFooterFN = emptyFun,
    sessionState,
}) => {
    const [formState, formAction] = useContext(FormContext);
    const [getActionReprint, setActionReprint] = useState(null)
    const [, uiAction] = useContext(UIContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const userProvinceId = _get(authStatus, "authUser.role_level_code", null) === "INT_AU" ? _get(authStatus, "authUser.pdmtId.id", null) : _get(authStatus, "authUser.pdmtId", null)
    useEffect(() => {
        formAction.initFromFn({ "licence_charges_form": { _onLoad: true } });

        formAction.initFromFn({ "special": { _onLoad: true } });

    }, []);

    const tableHeaderList = [
        {
            displayName: "Year", key: "year",
            onPrintFn: (rawData) => {
                setActionReprint(rawData.fee)
                return !isEmpty(rawData.valid_to) ? dateObjectToString(rawData.valid_to, "YYYY") : "";
            },
            isSort: false
        },
        {
            displayName: "Lic. Type",
            key: "lic_type",
            isSort: false,
        },
        { displayName: "Lic. No. / Rec. No.", key: "lic_no_rec_no", isSort: false },
        {
            displayName: "Validity Period (From - To)",
            key: "validity_period",
            onPrintFn: (rawData) => {
                return dateObjectToString(rawData.valid_from, "dd/mm/YY") + "-" + dateObjectToString(rawData.valid_to, "dd/mm/YY");

            },
            isSort: false,
        },
        { displayName: "Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fee).toFixed(2)) },
        { displayName: "Fine (LKR)", key: "fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fine).toFixed(2)) },
        {
            displayName: "Additions (LKR)",
            key: "additions",
            isSort: false,
            onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.additions).toFixed(2)),
            isRightAlign: true
        },
        {
            displayName: "Deductions (LKR)",
            key: "deductions",
            onPrintFn: (rawData) => "(" + numberThousandSeperator(Math.abs((Number(rawData.deductions))).toFixed(2)) + ")",
            isSort: false,
            isRightAlign: true
        },
        {
            displayName: "Service Charge (LKR)",
            key: "service_charge",
            isSort: false,
            onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.service_charge).toFixed(2)),
            isRightAlign: true
        },
        {
            displayName: "Total (LKR)", key: "total",
            onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.total).toFixed(2)),
            isSort: false,
            isRightAlign: true
        },
        {
            displayName: "User's Name",
            key: "username",
            onPrintFn: (rawData) => (rawData.user_name === "-" ? "" : rawData.user_name) + (rawData.created_role_prefix === "-" ? "" : ("(" + rawData.created_role_prefix + ")")),
            isSort: false,
        },
        {
            displayName: "Location",
            key: "location",
            onPrintFn: (rawData) => (rawData.province_prefix === "-" ? "" : rawData.province_prefix) + (rawData.service_name === "-" ? "" : ("(" + rawData.service_name + ")")),
            isSort: false,
        },
        {
            displayName: "Tx. Type",
            key: "tx_type",
            isSort: false,
        },
        {
            displayName: "Tx. Date & Time",
            key: "tx_date_time",
            onPrintFn: (rawData) => {
                return dateObjectToString(rawData.tx_date_time, "dd/mm/YY HH:MM");
            },
            isSort: false,
        },
    ]

    const getNormalLicenceFee = (licenceEndtype = 0, isReIssuance = false, startDate = null, endDate = null, temp_request = false,) => {
        let tempReq = temp_request;
        if (temp_request) {
            tempReq = {
                runnable: _get(formState, "vehicle_search_form.documentRequest.run", 0) === 1 ? true : false, validity_period: _get(formState, "vehicle_search_form.provinceData.temp_licence_validity_period", 0), charging_method: _get(formState, "vehicle_search_form.provinceData.temp_licence_charging_method", 'ANN')
            }
        }

        const queryObj = {
            vehicle_no: _get(formState, "vehicle_search_form.data.vehicle_no", ""),
            licence_end_type: licenceEndtype,
            licence_period_start_date: startDate !== null ? dateObjectToString(startDate, "YY-mm-dd") : dateObjectToString(_get(formState, "licence_validity_period.startDate", null)),
            licence_period_end_date: endDate ? dateObjectToString(endDate, "YY-mm-dd") : null,
            is_reissuance: isReIssuance,
            temp_request: tempReq ? JSON.stringify(tempReq) : tempReq,
            requested_licence_type: _get(formState, `vehicle_search_form.data.current_license_type.code`, ""),
        };

        callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {

                    let totArrearsFee = 0;
                    let totArrearsFine = 0;
                    let totArrearsAddition = 0;
                    let totArrearsDeduction = 0;
                    let totAddition = 0;
                    let totdeduction = 0;
                    let totCurrentLicenceFee = 0;
                    let totCurrentLicenceFine = 0;
                    let arrearsStartDate = null;
                    let arrearsEndDate = null;
                    let currentLicenceStartDate = null;
                    let currentLicenceEndDate = null;
                    let total = 0;
                    let serviceCharge = 0;
                    let rate_range_detail_id = null;
                    let licenceId = null
                    serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

                    _get(result, "data.addition_deduction_summary", []).forEach(value => {
                        if (value.charge_effect_code === "DED") {
                            totdeduction += value.total

                        }
                        if (value.charge_effect_code === "ADD") {
                            totAddition += value.total

                        }

                    });
                    _get(result, "data.arrears", []).forEach(value => {
                        totArrearsFee += value.fee;
                        totArrearsFine += value.fine;
                        totArrearsAddition += value.addition_total
                        totArrearsDeduction += value.deduction_total
                    });

                    if (_get(result, "data.current_licences", []).length > 0) {
                        _get(result, "data.current_licences", []).forEach(value => {
                            totCurrentLicenceFee += value.fee;
                            totCurrentLicenceFine += value.fine;
                        });
                    }
                    else {
                        totCurrentLicenceFee = _get(result, "data.fee", 0)
                    }


                    if (_get(result, "data.arrears", []).length > 0) {
                        arrearsStartDate = result.data.arrears[0]["start_date"];
                        arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
                    }

                    if (_get(result, "data.current_licences", []).length > 0) {
                        currentLicenceStartDate = result.data.current_licences[0]["start_date"];
                        currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
                    }


                    total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
                        totAddition + _get(result, "data.additions.current_licence", 0) -
                        (totdeduction + _get(result, "data.deductions.current_licence", 0)))
                        + serviceCharge;

                    rate_range_detail_id = _get(result, "data.current_licences[0].rate_range_detail_id", null)
                    if (isReIssuance === true) {
                        currentLicenceStartDate = _get(result, "data.reissueanceData.liceneFrom", null);
                        currentLicenceEndDate = _get(result, "data.reissueanceData.liceneTo", null);
                        totdeduction = _get(result, "data.reissueanceData.totalDedcutions", 0);
                        // serviceCharge = _get(result, "data.reissueanceData.service_charge.charge", 0);
                        totAddition = _get(result, "data.reissueanceData.totalAddtions", 0);
                        totCurrentLicenceFee = _get(result, "data.reissueanceData.totalCurrentLicenceFee", 0);
                        totCurrentLicenceFine = _get(result, "data.reissueanceData.totalCurrentLicenceFine", 0);
                        total = _get(result, "data.reissueanceData.totalCurrentLicenceFee", 0) + _get(result, "data.reissueanceData.totalCurrentLicenceFine", 0) + _get(result, "data.reissueanceData.totalAddtions", 0) + serviceCharge - _get(result, "data.reissueanceData.totalDedcutions", 0)
                        rate_range_detail_id = _get(result, "data.reissueanceData.chargesToApply.lastLicenceCharge.rate_range_detail_id", 0);
                        licenceId = _get(result, "data.reissueanceData.reissuedRefLicenceId", null)


                    }
                    if (total.isNaN) {
                        total = _get(result, "data.fee", 0)
                    }

                    formAction.changeInputFn("licence_charges_form", "data", "", result.data);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
                    formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
                    formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
                    formAction.changeInputFn("licence_charges_form", "rateRange", "", rate_range_detail_id);
                    formAction.changeInputFn("licence_charges_form", "licenceId", "", licenceId);

                }
                else {
                    const errorMessage = _get(error, "data.errors.details[0].message")
                    if (!isEmpty(_get(error, "data.errors.details[0]")) && !isEmpty(errorMessage)) {
                        uiAction.setFlashMessage({
                            status: true,
                            message: errorMessage,
                            messageType: "error",
                        });
                    }

                    formAction.changeInputFn("licence_charges_form", "data", "", []);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
                    formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
                    formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
                    formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
                    formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
                    formAction.changeInputFn("licence_charges_form", "total", "", 0);
                    formAction.changeInputFn("licence_charges_form", "rateRange", "", null);
                    formAction.changeInputFn("licence_charges_form", "licenceId", "", null);

                }
                formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
            });
    };

    const onChangeOnPaymentDetails = (licenceEndType, isReIssuance, startDate, endDate, temp_licence = false) => {
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", true);
        getNormalLicenceFee(licenceEndType, isReIssuance, startDate, endDate, temp_licence);

    };

    const getYearDiffWithMonth = (startDate, endDate) => {

        if (startDate == null || endDate == null) {
            return 0
        } else {
            const ms = (new Date(endDate).getTime()) - (new Date(startDate).getTime());

            const date = new Date(ms);
            return Math.abs(date.getUTCFullYear() - 1970);
        }

    }

    const totalArrears =
        _get(formState, "licence_charges_form.data.arrears_fee_total", 0) +
        _get(formState, "licence_charges_form.data.arrears_fine_total", 0) +
        _get(formState, "licence_charges_form.data.arrears_addition_total", 0) -
        _get(formState, "licence_charges_form.data.arrears_deduction_total", 0);


    const showPayments = (tab) => {
        let showPayments = true;
        showPayments=_get(formState, `issue-RL-Validation.data.calculationRequired`, false)
        // if (_get(formState, "vehicle_search_form.data.document_validations.vet", false) === true && _get(formState, "vet_certificate_form.isVet", false) == false) {
        //     showPayments = false;
        // }
        // if (_get(formState, "vehicle_search_form.data.document_validations.insurance", false) === true && _get(formState, "insurance_form.isInsurance", false) == false) {
        //     showPayments = false;
        // }
        // if (_get(formState, "vehicle_search_form.data.document_validations.vft", false) === true && _get(formState, "vft_certificate_form.isfitness", false) == false) {
        //     showPayments = false;
        // }
        // if (_get(formState, "vehicle_search_form.data.document_validations.vrp", false) === true && _get(formState, "vrp_form.isroutePerm", false) == false) {
        //     showPayments = false;
        // }

        // if (parseFloat(_get(formState, "licence_charges_form.total", 0)) - parseFloat(_get(formState, "licence_charges_form.serviceCharge", 0)) <= 0 && _get(formState, "vehicle_search_form.data.current_license_type.code", "FE") !== "FE") {
        //     showPayments = false;
        // }

        // if (_get(sessionState, "mergeWithPerent.isPendingWorkFlow", false) === false &&
        //     _get(sessionState, "mergeWithPerent.payArrearsOnly", true) === true &&
        //     totalArrears != 0 &&
        //     // visible only arrears yrs less than 2 yrs
        //     //     _get(formState, "licence_charges_form.data.arrears", []).length < MaxArrearsPeriod.NumberOfYears && getVehicleStatusCode() ==='ELI' //will show only if eligibale
        //     getYearDiffWithMonth((_get(formState, "licence_charges_form.data.arrears_valid_from", null)), (_get(formState, "licence_charges_form.data.arrears_valid_to", null))) < MaxArrearsPeriod.NumberOfYears
        //     && getVehicleStatusCode === 'ELI' //will show only if eligibale
        // ) {

        //     showPayments = true;
        // }

        // //if temp Licence
        // if (_get(formState, "vehicle_search_form.isTempLicence", false)) {
        //     showPayments = true;
        // }

        // //if Current Licence temp licence
        // if (_get(formState, "latest_licence_data.data.temp_licence", 0) === 1) {
        //     showPayments = true;
        // }

        // if (_get(formState, "licence_validity_period.isReIssuance", false)) {
        //     showPayments = true;
        // }

        return showPayments;
    };



    return (
        <FormWrapper
            setGroupName={formKey}
            elementStyle={"normalLicenceWrapper"}
            setFormObject={{
                isVerifyRegister: true,
                licenceValidityeriodTo:
                    _get(getState.currentObject, "status.currentLicenceStatus", "1") ===
                        "2"
                        ? "1"
                        : "0",
                vet_no: _get(getState.currentObject, "documentValidation.VETNo", ""),
                isVet: _get(
                    getState.currentObject,
                    "documentValidation.checkVET",
                    false
                ),
                vetCompany: _get(
                    getState.currentObject,
                    "documentValidation.VETCompany",
                    ""
                ),
                insurance: _get(
                    getState.currentObject,
                    "documentValidation.insuranceCompany",
                    ""
                ),
                isInsurance: _get(
                    getState.currentObject,
                    "documentValidation.checkInsurance",
                    false
                ),
                fitnessCompany: _get(
                    getState.currentObject,
                    "documentValidation.FitnessCompany",
                    ""
                ),
                certNo: _get(
                    getState.currentObject,
                    "documentValidation.FitnessNo",
                    ""
                ),
                isfitness: _get(
                    getState.currentObject,
                    "documentValidation.checkFitness",
                    false
                ),
                routePermComapny: _get(
                    getState.currentObject,
                    "documentValidation.RoutePermCompany",
                    "0"
                ),
                vrpNo: _get(
                    getState.currentObject,
                    "documentValidation.RoutePermNo",
                    ""
                ),
                isroutePerm: _get(
                    getState.currentObject,
                    "documentValidation.checkRoutePerm",
                    false
                ),
                "viewAll": false
            }}
        >

            {
                (getState.currentObject !== null
                    && getState.currentObject !== "none"
                    && Object.keys(getState.currentObject).length !== 0
                    && (_get(getState, `currentObject.current_license_type.code`, "") === LicenceTypeCodes.FREE_LICENCE || _get(getState, `currentObject.current_license_type.code`, "") === LicenceTypeCodes.NON_USER_LICENCE || _get(getState, `currentObject.current_license_type.code`, "") === LicenceTypeCodes.NORMAL_LICENCE )) ?
                    (
                        <Fragment>
                            <div className="col-md-6 floatLeft">

                                <Specialmessage
                                    vehicleId={_get(getState, `currentObject.id`, null)}
                                    provinceId={userProvinceId}
                                    vehicleNo={_get(formState, "vehicle_search_form.data.vehicle_no", "")}
                                />

                                {/* #region owner Info Panel */}
                                <div className="fullWidthDiv">
                                    <OwnerInfo
                                        formKey={formKey}
                                        getVehicleStatusCode={getVehicleStatusCode}
                                        params={params}
                                    />
                                </div>
                                {/* #endregion */}

                                <div className="fullWidthDiv">
                                    <PaddingLessUICard
                                        cardHeading="Vehicle Information"
                                        passPropsToCardHeadingTail={{
                                            uiType: "vehicle_infor",
                                            formGroupName: { formKey },
                                            vehicleInfo: { ...getState.currentObject, 'vehicle_status': getVehicleStatusCode },
                                            getVehicleStatusCode: getVehicleStatusCode,
                                        }}
                                        cardHeadingTail={OwnerDetailsIcons}
                                    >
                                        <VehicleInfoDisplay
                                            uiType={"summary"}
                                            vehicleInfo={getState.currentObject}
                                        />
                                    </PaddingLessUICard>

                                </div>

                                <div className="fullWidthDiv">
                                    {_get(formState, "pending_workflow_key._onLoad", false) === false &&
                                        <PaymentHistory
                                            tableHeaderList={tableHeaderList}
                                            apiUrl={`${vehiclePaymentHistoryAPI.url}/${_get(formState, "vehicle_search_form.data.vehicle_no", "")}`}
                                            dataTableKey={vehiclePaymentHistoryAPI}
                                            licenceType={"normal-licence"}
                                            formKey="normalLicence"
                                            isReprint={true}
                                            isView={true}
                                            category={"VT"}
                                            vehicleId={_get(formState, "vehicle_search_form.data.vehicle_no", "")}
                                        />
                                    }
                                </div>
                                <div className="fullWidthDiv">
                                    <CommentsSection
                                        formKey="normalComment"
                                        vehicleId={getState.currentObject.id}
                                        vehicle_no={getState.currentObject.vehicle_no}
                                        getVehicleStatusCode={getVehicleStatusCode}
                                        params={params}
                                    ></CommentsSection>
                                </div>
                            </div>

                            <div className="col-md-6 floatRight">
                                <DocumentValidation
                                    uiType={uiType}
                                    getState={getState}
                                    setState={setState}
                                    formGroupName={formKey}
                                    setShowHideFooterFN={setShowHideFooterFN}
                                    getLatestLicenceState={getLatestLicenceState}
                                    getVehicleStatusCode={getVehicleStatusCode}
                                    params={params}
                                />


                                <div className="fullWidthDiv">
                                    {_get(formState, `vehicle_search_form.data.current_license_type.code`, "") === "FE" ? (
                                        <FreeLicence />
                                    ) : _get(formState, `vehicle_search_form.data.current_license_type.code`, "") === "NU" ? (
                                        <Fragment>
                                            <NonUserLicence />
                                        </Fragment>
                                    ) : null}
                                </div>

                                {/* #region Licence Arrears Panel */}
                                {
                                    _get(formState, `licence_charges_form.data.arrears`, []).length > 0 ? (
                                        <div className="fullWidthDiv">
                                            <LicenceArrearsDetails getState={getState} getVehicleStatusCode={getVehicleStatusCode} />
                                        </div>
                                    ) : null
                                }
                                {/* #endregion */}


                                <div className="fullWidthDiv">
                                    <LicenceValidityPeriod
                                        currentObject={getState.currentObject}
                                        getLatestLicenceState={getLatestLicenceState}
                                        isTempLicence={_get(sessionState, "mergeWithPerent.issueMode", 1) === '2'}
                                        refreshDataFn={onChangeOnPaymentDetails}
                                        getVehicleStatusCode={getVehicleStatusCode}
                                        params={params}
                                    />
                                </div>
                                {/* #region Payment Panel */}
                                {_get(formState, `issue-RL-Validation.data.calculationRequired`, false)===true &&
                                    (<div className="fullWidthDiv">
                                        <ChargingDetails />
                                    </div>)}
                                {/* #endregion */}
                            </div>
                        </Fragment>
                    ) : (null)
            }

        </FormWrapper>
    );
};

export { NormalLicenceElement };
