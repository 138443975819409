import React, { Fragment } from "react";
import { quotationDeleteAPI } from "../../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";

import { FormWrapper, InputButton, SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { apiResponseStoringType } from "../../../../core/context-providers/CoreContext.provider";

const DeleteQuotation = (props) => {
    const formKey = `${props.fromParent.formKey}`;
    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            setFormObject={{
                id: props.row.id,
            }}
            onDestroyUnsetFormObject={true}
        >
            <div className="removeContent">
                <p>Are you sure you want to remove?</p>
                <br />
                <br />
            </div>
        </FormWrapper>
    );
};

const DeleteQuotationBtn = (props) => {
    const uiType = props.uiType;

    return (
        <Fragment>
            <SubmitButton
                startIcon={"fas fa-check"}
                btnText={"Yes"}
                formGroupName={props.formGroupName}
                dataTableKey={props.dataTableKey}
                isValidate={false}
                flashMessages={{
                    success: {
                        status: true,
                        message: "Quotation is successfully removed.",
                        messageType: "success",
                    },
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${quotationDeleteAPI.url}/${formState.id}`,
                        key: quotationDeleteAPI.key,
                    };
                }}
                onChangeRequestBodyFn={(formObject) => {
                    return getDataByFormObject({
                        ...formObject,
                        active: parseInt(formObject.active) === 1 ? true : false,
                    });
                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: "delete",
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                }}
                onResponseCallBackFn={(error, response) => {
                    if (!error) {
                        props.onClosePopupFn();
                    }
                }}
            />
        </Fragment>
    );
};

export {
    DeleteQuotation,
    DeleteQuotationBtn
}