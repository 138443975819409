/*
 * @Author: Madhawa
 * @Date: 2021-01-12 11:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-01-10 06:18:22
 */

import React, { Fragment, useContext, useEffect } from "react";
import {
  cancelLicenceReceiptTransactionTempAPI,
  getLicenceTransactionAPI,
  getTempLicenceTransactionAPI,
  vehiclePaymentHistoryAPI,
  deleteTempLicenceTransactionAPI
} from "../../../../../config/apiUrl.config";
import { _get, _set } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import {
  actionBtnTypes,
  DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { ViewTransaction } from "../../../dashboard/pages/includes/NormalLicense";
import { apiResponseStoringType, CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getCodeName } from "./ViewAdditionDeduction";
import { LicenceType } from "../../../../../config/enum.config";
import { getDataByFormObject,thousandSeparator,numberThousandSeperator } from "../../../../../helpers/common-helpers/common.helpers";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { isEmpty } from "lodash";


const DeleteUI = () => {
  return (
      <div className="fullWidthDiv">
          <p>Are you sure want to remove this record?</p>
      </div>
  )
}

const DeleteBtn = (props) => {
  const [, uiAction] = useContext(UIContext);
  return (
      <SubmitButton
          btnText="Yes"
          elementStyle="btnWrapper"
          startIcon={"far fa-save"}
          dataTableKey={props.dataTableKey}
          isValidate={false}
          flashMessages={{
              "success": {
                  status: true,
                  message: "Selected transaction removed successfully.",
                  messageType: "success"
              }
          }}
          callApiObject={{
              isSetHeaders: true,
              multipart: false,
              method: "delete",
              onUpload: false
          }}
          apiDataStoringObject={{
              setLoader: true,
              storingType: "DATA_TABLE_LINKED_FORM",
              mergeToSuccessResponse: null,
              mergeToErrorResponse: null,
          }}
          onGetAPIEndPointFn={(formObject) => {
              return {
                  url: `${props.tableApiUrl.url}/${props.requestId}`,
                  key: props.tableApiUrl.key
              }
          }}
          onChangeRequestBodyFn={(formObject) => {
              return getDataByFormObject(formObject);
          }}
          onResponseCallBackFn={(error) => {
              if (!error) {
                  uiAction.setFlashMessage({
                      status: true,
                      message: "Selected transaction removed successfully.",
                      messageType: "success"
                  });

                  props.onClosePopupFn(false);
              }
          }}
      />
  )
};

const   CancelLicenceInformation = (props) => {
  
  
  const formKey = props.formKey;  
  const requestId = props.requestId;  
  const [formState] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  console.log('POOO11', props);

  const [tableState] = useContext(CoreContext);
  const tableDataNew = _get(tableState, `dataTableResponses.cancel-licence_dataTableKey.results`, "").length;
  const tableData = _get(tableState, `dataTableResponses.${getTempLicenceTransactionAPI.key}.results`, "").length;
  useEffect(() => {}, [tableData])
  const btnList =  tableData > 0 ? [               
    {
      actionType: actionBtnTypes.view,
      actionBtn: { tooltip: "View more" },
      modelContentElement: ViewTransaction,
      dialogPopupProps: {
        isFullScreen: false,
        dialogTitle: "View Licence Transaction Details",
        isCustomButton: false,
        isRemoveCancel:true,
        closeButton: {
          btnText: "Close",
          startIcon: "far fa-times-circle",
        },
      },
     
    },
    {
          actionType: actionBtnTypes.delete,
          actionBtn: { tooltip: 'Remove' },
          modelContentElement: DeleteUI,
          dialogPopupProps: {
              isFullScreen: false,
              isFullWidth: false,
              dialogTitle: "Remove Transaction",
              closeButton: {
                  btnText: "No",
                  startIcon: "far fa-times-circle",
              },
              isCustomButton: true,
              customButtonComponent: DeleteBtn,
              customButtonComponentProps: {
                dataTableKey: getTempLicenceTransactionAPI.key,
                requestId: requestId,
                btnText: "Delete",
                // dataTableKey: `${formGroupName}_dataTableKey`,
                tableApiUrl: deleteTempLicenceTransactionAPI,
              }
          },
          // checkBeforeActionRenderFn: (rawData) => {
          //     return (_get(rawData, "row.created_by", "") ===  _get(authStatus, "authUser.id", ""))
          //   }
      }
  ]
  // : [               
  //   {
  //     actionType: actionBtnTypes.view,
  //     actionBtn: { tooltip: "View more" },
  //     modelContentElement: ViewTransaction,
  //     dialogPopupProps: {
  //       isFullScreen: false,
  //       dialogTitle: "View Licence Transaction Details",
  //       isCustomButton: false,
  //       isRemoveCancel:true,
  //       closeButton: {
  //         btnText: "Close",
  //         startIcon: "far fa-times-circle",
  //       },
  //     },
     
  //   },
  //   ]
  : [
    {
      actionType: actionBtnTypes.create,
      isBtnDisabled: true,
      modelContentElement: Form,
      dialogPopupProps: {
        isFullScreen: true,
        isCustomButton: false,
        isSaveButton: false,
        dialogTitle: "Select Transaction",
        closeButton: {
          btnText: "Cancel",
          startIcon: "far fa-times-circle",
        },
      },
      parentToModelProps: {
        formKey: `${formKey}-temp`,
        requestId: requestId,
        uiType: "add",
        data: {...props}
      },
    },
  ];
  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <FormWrapper
            setGroupName={formKey}
            setFormObject={{
              request_id: _set(
                formState,
                `${formKey}.request_id`,
                requestId
              ),
            }}
          >
            <DataTable
              apiUrl={`${getTempLicenceTransactionAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.request_id : requestId}`}
              dataTableKey={getTempLicenceTransactionAPI.key}
              isSetAction={true}
              customActionHeader={'Action'}
              isSetSearchFrom={false}
              isSelectRow={false}
              isSetTableFooter={true}
              isSetTableHeader={true}
              actionTypeList={btnList}
              tableHeaderList={[
                { displayName: "Year", key: "year", isSort: false },
                { displayName: "Lic. No /Rec. No", key: "licence_no_receipt_no", isSort: false },
                {
                  displayName: "Validity Period (From - To)",
                  key: "validity_period",
                  onPrintFn: (rawData)=>{
                    console.log('rawData==?', rawData);
                    return (dateObjectToString(rawData.from, "dd/mm/YY")+' - '+(dateObjectToString(rawData.to, "dd/mm/YY")))
                },
                  isSort: false,
                },
                { displayName: "Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, 
                  onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fee).toFixed(2))
                },
                { displayName: "Fine (LKR)", key: "fine", isSort: false, isRightAlign: true, 
                  onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fine).toFixed(2))
                },
                {
                  displayName: "Additions (LKR)",
                  key: "addition",
                  isSort: false,
                  onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.addition).toFixed(2)),
                  isRightAlign: true
                },
                {
                  displayName: "Deductions (LKR)",
                  key: "deduction",
                  isSort: false,
                  onPrintFn: (rawData) => "(" + numberThousandSeperator(Math.abs((Number(rawData.deduction))).toFixed(2)) + ")",
                  isRightAlign: true
                },
                {
                  displayName: "Service Charge (LKR)",
                  key: "service_charge",
                  isSort: false,
                  onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.service_charge).toFixed(2)),
                  isRightAlign: true
              },
                { displayName: "Total (LKR)", key: "total", isSort: false , isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(Number(rawData.fee)+Number(rawData.fine)+Number(rawData.addition)+Number(rawData.service_charge)-(Math.abs(Number(rawData.deduction)))).toFixed(2)),},
                {
                  displayName: "Lic. Type",
                  key: "lic_type",
                  onPrintFn: ({licence_type_code})=> licence_type_code ? LicenceType[`${licence_type_code}`] : "-",
                  isSort: false,
                },
                {
                  displayName: "User's Name",
                  key: "created_by_name",
                  isSort: false,
                  onPrintFn:(rawData)=>rawData.created_by_name+"("+rawData.role+")"
                },
                {
                  displayName: "Location",
                  key: "created_location_name",
                  onPrintFn: ({created_location_name})=> created_location_name ? created_location_name : "-",
                  isSort: false,
                },
                {
                  displayName: "Tx. Type",
                  key: "tx_type",
                  isSort: false,
                },
                {
                  displayName: "Tx. Status",
                  key: "tx_status",
                  isSort: false,
                  onPrintFn:(rawData)=>{if(rawData.tx_status==="OPN"){
                    return("Success")
                  }
                  else if(rawData.tx_status==="REV"){
                    return("Reversed")

                  }
                  else if(rawData.tx_status==="CAN"){
                    return("Cancelled")

                  }
                  else{
                    return("-")
                  }
                }
                },
                
                {
                  displayName: "Tx. Date & Time",
                  key: "tx_date",
                  onPrintFn: (rawData)=>{
                    return (dateObjectToString(rawData.tx_date, "dd/mm/YY HH:MM"))
                },
                  isSort: false,
                },
              ]}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  );
};

const AddTempLcnForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  const requestId = `${props.fromParent.requestId}`;
  const vehicleNo = `${props.fromParent.vehicleNo}`;
console.log('PUUUUUUPPP====', props);
  const {txn_id,fine,fee,additions,deductions,tx_date_time,year,user_id,lic_no_rec_no,valid_from,valid_to,lic_type,tx_type,status,service_charge } = props.row
  return (
    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      setFormObject={{
        transaction_id: txn_id,
        request_id: requestId,
        fine: fine,
        fee: fee,
        service_charge: service_charge,
        addition: additions,
        deduction: deductions,
        vehicle_no: vehicleNo,
        year: new Date(valid_to).getFullYear(),
        licence_no_receipt_no:lic_no_rec_no,
        from: valid_from,
        to: valid_to,
        licence_type_code: lic_type,
        user_id: user_id,
        location: '-',
        tx_type: tx_type,
        tx_status: status,
        tx_date: tx_date_time
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you sure to select this transaction?</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};

const AddTempLcnBtn = (props) => {
  console.log('POOO', props);

  const [formState] = useContext(FormContext);
  const { uiType, dataSubmittedForApproval } = props.data
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.data.vehicle_no",
    null
  );

  return (
    <SubmitButton
      btnText={"Yes"}
      startIcon={"fas fa-check"}
      formGroupName={props.formGroupName}
      tooltip={"Select Transaction"}
      dataTableKey={getTempLicenceTransactionAPI.key}
      isValidate={false}
      flashMessages={{
        failed: {
          status: true,
          message: `Select a Licence / Arrears Transaction.`,
          messageType: "error",
        },
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          url: `${cancelLicenceReceiptTransactionTempAPI.url}/${uiType === 'APR' ? dataSubmittedForApproval.vehicle_no : vehicle_no}`,
          key: cancelLicenceReceiptTransactionTempAPI.key,
        };
      }}
      callApiObject={{
        isSetHeaders: true,
        method: "post",
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.dateTableLinkedForm,
      }}
      onResponseCallBackFn={(error, response) => {
        if (!error) {
          props.onClosePopupFn();
        }
      }}
    />
  );
};

const Form = (props) => {
  const formKey = props.fromParent.formKey;
  const requestId = props.fromParent.requestId;
  const [formState] = useContext(FormContext);
  console.log('POOO22', props);
  const { uiType, dataSubmittedForApproval } = props.fromParent.data
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  
  return (
    <Fragment>
      <DataTable
        tableName="Payment History"
        apiUrl={`${vehiclePaymentHistoryAPI.url}/for-cancel-wf/${uiType === 'APR' ? dataSubmittedForApproval.vehicle_no : vehicle_no}/false`}
        dataTableKey={getLicenceTransactionAPI.key}
        elementWrapperStyle={"scrolTable"}
        isSetSearchFrom={false}
        actionTypeList={[
          {
            actionType: actionBtnTypes.add,
            actionBtn: { btnText: "Select", tooltip: "Select" },
            modelContentElement: AddTempLcnForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              isCustomButton: true,
              isFullWidth: true,
              customButtonComponent: AddTempLcnBtn,
              customButtonComponentProps: {
                formGroupKey: formKey,
                requestId: requestId,
                btnText: "Update",
                uiType: "edit",
                onClosePopupFn: props.onClosePopupFn,
                data: props.fromParent.data
                
              },
              dialogTitle: "Select Transaction",
              closeButton: {
                btnText: "No",
                startIcon: "fas fa-times",
              },
              isConfirmationPopup: false,
            },
            parentToModelProps: {
              formKey: formKey,
              requestId: requestId,
              dataTableKey: getLicenceTransactionAPI.key,
              uiType: "add",
              vehicleNo: vehicle_no,
              data: props.fromParent.data
            },
          },
          
        ]}
        tableHeaderList={[
          { displayName: "Year", key: "year", 
          onPrintFn: (rawData) => {

              return !isEmpty(rawData.valid_to) ? dateObjectToString(rawData.valid_to, "YYYY") : "";
            },
            isSort: false },
          { displayName: "Lic. No /Rec. No", key: "lic_no_rec_no", isSort: false },
          {
              displayName: "Validity Period (From - To)",
              key: "validity_period",
              onPrintFn: (rawData) => {
                  return dateObjectToString(rawData.valid_from, "dd/mm/YY")+"-"+ dateObjectToString(rawData.valid_to, "dd/mm/YY");
                 
                },
              isSort: false,
          },
          { displayName: "Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => thousandSeparator(rawData.fee,true) },
          { displayName: "Fine (LKR)", key: "fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => thousandSeparator(rawData.fine,true) },
          {
              displayName: "Additions (LKR)",
              key: "additions",
              isSort: false,
              onPrintFn: (rawData) => thousandSeparator(rawData.additions,true),
              isRightAlign: true, 
          },
          {
            displayName: "Service Charge (LKR)",
            key: "service_charge",
            isSort: false,
            onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.service_charge).toFixed(2)),
            isRightAlign: true
        },
          {
              displayName: "Deductions (LKR)",
              key: "deductions",
              isSort: false,
              onPrintFn: (rawData) => "(" + numberThousandSeperator(Math.abs((Number(rawData.deductions))).toFixed(2)) + ")",
              isRightAlign: true
          },
          
          { displayName: "Total (LKR)", key: "total", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.total).toFixed(2)) },
          {
              displayName: "Lic. Type",
              key: "lic_type",
              isSort: false,
              onPrintFn: ({lic_type})=> lic_type ? LicenceType[`${lic_type}`] : "-",
          },
          {
              displayName: "User's Name",
              key: "user_name",
              isSort: false,
          },
          {
              displayName: "Location",
              key: "location",
              isSort: false,
              onPrintFn: (rawData)=>rawData.province_prefix+"("+rawData.service_name+")"
          },
          {
              displayName: "Tx. Type",
              key: "tx_type",
              isSort: false,
          },
          {
            displayName: "Tx. Status",
            key: "status",
            isSort: false,
            onPrintFn:(rawData)=>{if(rawData.status==="OPN"){
              return("Success")
            }
            else if(rawData.status==="REV"){
              return("Reversed")

            }
            else if(rawData.status==="CAN"){
              return("Cancelled")

            }
            else{
              return("-")
            }
          }
          },
          {
              displayName: "Tx. Date & Time",
              key: "tx_date_time",
              onPrintFn: (rawData) => {
                  return dateObjectToString(rawData.tx_date_time, "dd/mm/YY HH:MM");
                },
              isSort: false,
          },
      ]}
      />
    </Fragment>
  );
};

export { CancelLicenceInformation };
