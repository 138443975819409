
/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 17:01:32
 * @Last Modified time: 2022-12-10 01:30:14
 * @Last Modified by: Jayani Nawarathna
 */

import React, { useContext, useEffect, useState } from "react";
import { CounterTemplate } from "./../../../ui-components/templates/CounterTemplate.js";
import { WebLicence } from "./web-licence/WebLicence";
import {
  TabsWrapper,
  SubTab,
  tabsWrapperVariants,
  tabsWrapperScrollButtons,
} from "../../../ui-components/ui-elements/tab/Tabs";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { Quotation } from "./quatation/Quatation.js";
import { Carnet } from "./carnet/Carnet";
import NormalLicence from "./normal-licence/NormalLicence.js";
import { NormalFooter } from "./normal-licence/includes/NormalLicenceFooter";
import { CarnetFooter } from "./carnet/CarnetFooter";
import { DealerLicense } from "./dealers-licence/DealersLicence";
import { DealerFooter } from "./dealers-licence/includes/DealerFooter";
import { WebLicenceFooter } from "./web-licence/includes/WebFooter.js";
import { apiResponseStoringType, CoreContext } from "../../core/context-providers/CoreContext.provider.js";
import { counterOperationTypeAllowedListApi } from "../../../../config/apiUrl.config.js";
import { AuthContext } from "../../core/context-providers/AuthContext.provider.js";
import { FormContext } from "../../core/context-providers/FormContext.provider";

import { useLocation } from "react-router-dom";
import { LicenceTypeCodes } from "../../../../config/enum.config.js";

const CounterSession = () => {
  const [authStatus, authAction] = useContext(AuthContext);
  const [formState, formAction] = useContext(FormContext);
  const location = useLocation();
  const [coreState, coreAction] = useContext(CoreContext);
  const [licenceTypes, setLicenceTypes] = useState([]);
  const user_id = _get(authStatus, "authUser.id", "");
  const [sessionId, setSessionId] = useState(null)
  const [counterId, setCounterId] = useState(null)

  const [getState, setState] = useState({
    currentTab: null,
    isShowFooter: false,
    mergeWithPerent: null,
    // sessionId: null
  });

  const setShowHideFooterFN = (status = true, mergeWithPerent = {}) => {
    setState({
      ...getState,
      isShowFooter: status,
      mergeWithPerent: mergeWithPerent,
    });
  };
  const setLatestLicenceFN = (latestLicence = {}) => {
    setState({
      ...getState,
      latestLicence: latestLicence,
    });
  };

  const getFooter = () => {
    switch (getState.currentTab) {
      case "tab1":
        return NormalFooter;
      case "tab2":
        return DealerFooter;
      case "tab3":
        return CarnetFooter;
      case "tab4":
        return WebLicenceFooter;
      // case "tab5":
      //   return QuotationFooter;
    }
  };

  const getSessionId = (value) => {
    setSessionId(value)
  }
  const getCounterId = (value) => {
    setCounterId(value)
  }
  
  useEffect(() => {
    let counter_id = _get(location, "state.counter_id", counterId);
    coreAction
    .sendRequestFn(`${counterOperationTypeAllowedListApi.url}/${counter_id}`)
    .method("get")
    .setInitStoring(apiResponseStoringType.setResponse, {
      responseKey: counterOperationTypeAllowedListApi.key,
    })
    .headers(true)
    .send((error, result) => {

        if(!error){
          formAction.changeInputFn("allowedCounterOperationsTypes", "data", "",_get(result, "data.data", []))
          const allowedOperationTpes = _get(result, "data.data", []);
          switch (true) {
            case allowedOperationTpes.includes(LicenceTypeCodes.NORMAL_LICENCE):
              setState({
                ...getState,
                currentTab: "tab1"
              });
              break;
            case allowedOperationTpes.includes(LicenceTypeCodes.DEALERS_LICENCE):
              setState({
                ...getState,
                currentTab: "tab2"
              });
              break;
            case allowedOperationTpes.includes(LicenceTypeCodes.CARNET_LICENCE):
              setState({
                ...getState,
                currentTab: "tab3"
              });
              break;
            case allowedOperationTpes.includes(LicenceTypeCodes.WEB_LICENCE):
              setState({
                ...getState,
                currentTab: "tab4"
              });
              break;
            case allowedOperationTpes.includes(LicenceTypeCodes.QUOTATIONS):
              setState({
                ...getState,
                currentTab: "tab5"
              });
              break;
          }
          setLicenceTypes(allowedOperationTpes);
        }
    });
  }, [_get(location, "state.counter_id", counterId)]);
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <CounterTemplate
      FooterComponent={getFooter()}
      isShowFooter={true}
      propsToFooter={{
        currentTab: getState.currentTab,
        formChild: getState.mergeWithPerent,
        latestLicence: getState.latestLicence
      }}
      getSessionId={getSessionId}
      getCounterId={getCounterId}
    >
      <TabsWrapper
        onChangeFn={(event) =>{
          setState({
            ...getState,
            currentTab: event.value,
            isShowFooter: event.value === "tab5" ? true : false,
          })
          //For Update Status Error
          formAction.removeFromGroupFn('vehicle_search_form');
        }
        }
        active={getState.currentTab}
        variant={tabsWrapperVariants.scrollable}
        scrollButtons={tabsWrapperScrollButtons.on}
        elementWrapperStyle={"counterTab"}
      >
        {licenceTypes.includes(LicenceTypeCodes.NORMAL_LICENCE) ? 
          <SubTab tabid={"tab1"} header={"Normal / Non-User / Free Licence"}>
            <NormalLicence
              setShowHideFooterFN={(status, mergeWithPerent) =>
                setShowHideFooterFN(status, mergeWithPerent)
              }
              sessionState={getState}
              setLatestLicenceFN={(latestLicence) => setLatestLicenceFN(latestLicence)}
              sessionId={sessionId}
              counterId={counterId}
            />
          </SubTab>
        : null}
        
        {licenceTypes.includes(LicenceTypeCodes.DEALERS_LICENCE) ?
          <SubTab tabid={"tab2"} header={"Dealer’s Licence"}>
            <DealerLicense sessionId={sessionId} counterId={counterId} />
          </SubTab>
        : null}
        {licenceTypes.includes("CA") ? 
          <SubTab tabid={"tab3"} header={"Carnet Licence"}>
            <Carnet
              sessionId={sessionId}
              setShowHideFooterFN={(status, mergeWithPerent) =>
                setShowHideFooterFN(status, mergeWithPerent)
              }
              setLatestLicenceFN={(latestLicence) => setLatestLicenceFN(latestLicence)}
              counterId={counterId}
            />
          </SubTab>
        : null}
        {licenceTypes.includes(LicenceTypeCodes.WEB_LICENCE) ?
          <SubTab tabid={"tab4"} header={"Web Licence"}>
            <WebLicence
              setShowHideFooterFN={(status, mergeWithPerent) =>
                setShowHideFooterFN(status, mergeWithPerent)
              }
            />
          </SubTab>
        : null}
        {licenceTypes.includes(LicenceTypeCodes.QUOTATIONS) ? 
          <SubTab tabid={"tab5"} header={"Quotation"}>
            <Quotation
              setShowHideFooterFN={(status, mergeWithPerent) =>
                setShowHideFooterFN(status, mergeWithPerent)
              }
            />
          </SubTab>
        : null}
      </TabsWrapper>
    </CounterTemplate>
  );
};

export default CounterSession;
