/*
 * @Author: Gajani Kangesan
 * @Date: 2022-09-07 18:00:43
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:12:44
 */

import React, { useState, useEffect, useContext } from 'react';
import { PaddingLessUICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { dealerSpecialMessageAPI } from "../../../../../../config/apiUrl.config";
import { FormContext } from '../../../../core/context-providers/FormContext.provider';
import { callApi } from "../../../../../../helpers/common-helpers/callApi.helpers";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";
import { DataTable} from "../../../../../ui-components/ui-elements/table/DataTable";
import { DialogPopup} from "../../../../../ui-components/ui-elements/modal/DialogPopup";
import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { isEmpty } from 'lodash';

const searchFormKey = "dealer_search_form";
const formKey = "dealerlicence"


const DealerSpecialMessage = ({ dealerId }) => {

    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const [getState, setState] = useState({
        status: false,
        modelType: "",
      });
   
   

    const specialMessage = () => {
        callApi(
            `${dealerSpecialMessageAPI.url}/${dealerId}/special-messages`
        )
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    let count = 0;
                    let message = []


                    formAction.changeInputFn("message", "list", "", result.data.data.special_messages);
                    setState({
                        ...getState,
                        status: result.data.is_warning,
                        modelType: "",
                    })

                } else {

                    formAction.changeInputFn("message", "list", "", []);

                }
                formAction.changeInputFn("special", "_onLoad", "", false);
            });

    }
    useEffect(() => {
        formAction.initFromFn({ "special": { _onLoad: true } });
        specialMessage();


    }, []);

    return (
        <div>

            {!isEmpty(_get(formState, `message.list`, [])) ?
                <div className="fullWidthDiv">

                    {_get(formState, "special._onLoad", false) === false && <PaddingLessUICard
                        cardHeading="Special Message"
                        passPropsToCardHeadingTail={{
                            uiType: "",
                            formGroupName: { formKey },

                        }}>
                        <div className="row">
                            <div class="col-md-8 "><p class="mt-1"><span class="highlight" >The Vehicle information has changed.</span></p></div>
                            <div class="col-md-4">
                                <InputButton
                                    elementWrapperStyle={""}
                                    btnName="viewchangehistory"
                                    btnText={"View Messages "}
                                    onClickBtnFn={() =>
                                        setState({
                                            ...getState,
                                            status: true,
                                            modelType: "viewchangeHistory",
                                        })
                                    }


                                /></div>
                            <div className={"fullWidthDiv"}>
                                <DialogPopup
                                    isOpen={getState.status}
                                    dialogTitle={"View Special Messages "}
                                    onClosePopupFn={() =>
                                        setState({
                                            ...getState,
                                            status: false,
                                            modelType: "",
                                        })
                                    }

                                >
                                    
                                        <DataTable
                                             isSetTableHeader={false}
                                            // tableName={"Special Messages"}
                                            elementWrapperStyle={"specialMessage "}
                                            isSetSearchFrom={false}
                                            tableHeaderList={[
                                                { displayName: "Special Messages", key: "message", isSort: false },
                                            ]}
                                            isSetAction={false}
                                            isSetTableFooter={false}
                                            tableBodyList={_get(formState, `message.list`, [])}
                                            onRowStyleFn={(data) => data.row.color_code}
                                        />
                                    


                                   
                                </DialogPopup>

                            </div>
                        </div>



                    </PaddingLessUICard>}

                </div> : null}


        </div>


    );
};

export { DealerSpecialMessage };
