/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-26 20:17:06
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-29 13:07:38
 */

import { permissions } from "./permission.config";

const siteMenu = [
  {
    id: 1,
    labelText: "Dashboard",
    routeName: "dashboard",
    icon: "mdi mdi-home",
    permissions: permissions.NONE.permissions,
    child: false,
    url: "/dashboard",
    size: "1rem"
  },
  {
    id: 2,
    labelText: "Tasks",
    routeName: "task.allTasks2",
    icon: "mdi mdi-format-list-checks",
    permissions: permissions.TASK.permissions,
    child: false,
    url: "/all-tasks",
    size: "1rem"
  },
  {
    id: 3,
    labelText: "Counter",
    routeName: "counter",
    icon: "mdi mdi-calculator",
    permissions: permissions.COUNTER.permissions,
    child: false,
    url: "/counter",
    size: "1rem"
  },
  {
    id: 4,
    labelText: "Counter Session History",
    routeName: "counter-session-history",
    icon: "fas fa-history",
    permissions: permissions.NONE.permissions,
    child: false,
    url: "/counter-session-history",
    size: "1rem"
  },
  {
    id: 5,
    labelText: "Vehicle / Dealer Info",
    routeName: "vehicle-info",
    icon: "mdi mdi-car",
    permissions: permissions.VEHICLE_DEALER_INFO.permissions,
    child: false,
    url: "/vehicle-info",
    size: "1rem"
  },
  {
    id: 6,
    labelText: "Revenue Settlements",
    routeName: "revenue-settlement",
    icon: "fas fa-money-check-alt",
    size: "0.7rem",
    // permissions: permissions.NONE.permissions,
    permissions: permissions.REVENUE_SETTLEMENT.permissions,
    child: true,
    nested: [
      {
        labelText: "Inter Province Revenue Settlement",
        routeName: "revenue-settlement-inter-province",
        icon: "mdi mdi-format-list-checks",
        url: "/revenue-settlement-inter-province",
        child: false,
        permissions: permissions.NONE.permissions,
        size: "1rem",
      },
      {
        labelText: "Revenue Settlement Note History",
        routeName: "revenue-settlement-note-history",
        icon: "mdi mdi-format-list-checks",
        url: "/revenue-settlement-note-history",
        child: false,
        permissions: permissions.NONE.permissions,
        size: "1rem",
      },
    ]
  },
  {
    id: 7,
    labelText: "Reconcile Transactions",
    routeName: "manage-transactions-reconciliation",
    icon: "fas fa-money-check-alt",
    // permissions: permissions.NONE.permissions,
    permissions: permissions.RECONCILE_TRANSACTIONS.permissions,
    child: false,
    size: "0.7rem",
    url: "/manage-transactions-reconciliation"
  },
  // {
  //   labelText: "Setting",
  //   routeName: "setting",
  //   icon: "mdi mdi-settings",
  //   per size:"0.6rem",missions: permissions.NONE.permissions,
  //   child: false,
  //   url: "/"    
  //   // nested: [
  //   //   {
  //   //     labelText: "Create Tasks",
  //   //     routeName: "task.allTasks",
  //   //     icon: "mdi mdi-playlist-check",
  //   //     url: "/",
  //   //     child: false,
  //   //     permissions: permissions.NONE.permissions,
  //   //   },
  //   //   {
  //   //     labelText: "Edit Tasks",
  //   //     routeName: "task.ad-hoc-inspection",
  //   //     icon: "mdi mdi-playlist-edit",
  //   //     url: "/test2",
  //   //     child: false,
  //   //     permissions: permissions.NONE.permissions,
  //   //   },
  //   // ],
  // },
  // {
  //   labelText: "Vehicle",
  //   routeName: "addVehicle",
  //   icon: "fas fa-car",
  //   permissions: permissions.NONE.permissions,
  //   child: true,
  //   nested: [
  //     {
  //       labelText: "Add Vehicle",
  //       routeName: "profile.user",
  //       icon: "fas fa-bus",
  //       url: "/add-vehicle",
  //       child: false,
  //       permissions: permissions.NONE.permissions,
  //     }, {
  //       labelText: "Approval Request",
  //       routeName: "profile.role",
  //       icon: "mdi mdi-account-details",
  //       url: "/approval-request",
  //       child: false,
  //       permissions: permissions.NONE.permissions,
  //     }
  //   ]
  // },
  {
    id: 8,
    labelText: "Reports",
    routeName: "report",
    icon: "fas fa-file",
    permissions: permissions.VIEW_REPORT_MENU.permissions,
    child: true,
    size: "0.9rem",
    nested: [
      {
        labelText: "Income Details Reports",
        routeName: "income-details",
        icon: "fa fa-check",
        url: "/income-details",
        child: false,
        permissions: permissions.VIEW_INCOME_DETAILS_REPORTS.permissions,
        size: "1rem",
      },
      {

        labelText: "User Details Reports",
        routeName: "report-user-details",
        icon: "fa fa-check",
        url: "/report-user-details",
        child: false,
        permissions: permissions.VIEW_USER_DETAILS_REPORT_ENTIRE.permissions,
        size: "1rem",
      }, {

        labelText: "Licence Details Reports",
        routeName: "report-license",
        icon: "fa fa-check",
        url: "/report-license",
        child: false,
        permissions: permissions.VIEW_LICENCE_DETAILS_REPORTS.permissions,
        size: "1rem",
      }, {
        labelText: "Online Licence Details Reports",
        routeName: "report-online-licence",
        icon: "fa fa-check",
        url: "/report-online-licence",
        child: false,
        permissions: permissions.VIEW_ONLINE_LICENCES_REPORT.permissions,
        size: "1rem",
      },
      {
        labelText: "Serial Number Details Reports",
        routeName: "report-serial-number",
        icon: "fa fa-check",
        url: "/report-serial-number",
        child: false,
        permissions: permissions.VIEW_SERIAL_NUMBER_DETAILS_REPORT.permissions,
        size: "1rem",
      },
      {
        labelText: "Other Reports",
        routeName: "report-other",
        icon: "fa fa-check",
        url: "/report-other",
        child: false,
        permissions: permissions.VIEW_OTHER_REPORT.permissions,
        size: "1rem",
      },
      {
        labelText: "Vehicle Details Reports",
        routeName: "vehicle-details",
        icon: "fa fa-check",
        url: "/vehicle-details",
        child: false,
        permissions: permissions.VIEW_VEHICLE_DETAILS_REPORTS.permissions ,
        size: "1rem",
      }
    ]
  },
];

export { siteMenu };
