/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-11 16:27:00
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-10-20 12:30:41
 */

import React, { useState, useEffect, useContext } from "react";
import {
    InputBoxWithState,
    SelectBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { DataTable, RawIndexMemo } from "../../../../ui-components/ui-elements/table/DataTable";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { _getInt, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { settlementOutstandingTransactionAPI,settlementTransactionAPI } from "../../../../../config/apiUrl.config";
import { getLabelValue, numberThousandSeperator } from "../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";

const RowNo = (props) => {
    return (
      <RawIndexMemo
        page_size={_getInt(props.pagingObject, "per_page", 1)}
        current_page={_getInt(props.pagingObject, "current_page", 1)}
        index={props.rowIndex || 0}
      />
    )
  }

const tableKey = "transactions";

const ViewTransactions = (props) => {
    let date = new Date()
let day = date.getDate() - 1;
let month = date.getMonth()+1;
let year = date.getFullYear();
let today = `${year}-${month}-${day}`;

const [authStatus, authAction] = useContext(AuthContext);

const provinceId=_get(authStatus, "authUser.pdmtId", "")

const [transactions, setTransactions] = useState([]);

const gettransactions = () => {
    console.log('PRPPP', props);
    if(props.row.charge_service_id){
        return `${settlementOutstandingTransactionAPI.url}?charge_service_id=${props.row.charge_service_id}&transaction_to=${today}&pdmt_id=${provinceId}`
    }
    else if(props.row.service_settlement_id){
        return `${settlementTransactionAPI.url}?service_settlement_id=${props.row.service_settlement_id}`
    }
}

    return (
        <DataTable
            tableName={"Licence / Arrears Transactions"}
            apiUrl={gettransactions()}
            dataTableKey={tableKey}
            isSetAction={false}
            isSetSearchFrom={true}
            searchFromComponent={<SearchElement dataTableKey={tableKey} />}
            defaultSearchFormObject={{
                "vehicleId": "",
                "licenceType": null,
                "transactionType": null,
                "transactionStats": null
            }}
            tableHeaderList={[
                { displayName: "No.", key: "no", isSort: false },
                { displayName: "Licence Type", key: "licence_type",
                    onPrintFn: (rawData) => (rawData.licence_type_code === "NO" && rawData.temp_licence === 1) ? "NL – T" :
                    "NL", isSort: false 
                },
                { displayName: "Tx. Date & Time", key: "created_at", onPrintFn: (rawData) =>dateObjectToString(rawData.created_at, "dd/mm/YY HH:MM"), isSort: true },
                { displayName: "Lic. No. / Rec. No.", key: "licence_number", isSort: false },
                { displayName: "Vehicle ID", key: "vehicle_number", isSort: false },
                { displayName: "Owner Name / Dealer Name", key: "owner_name", isSort: false,onPrintFn: (rawData) =>getLabelValue(rawData.owner_name, true) },
                { displayName: "Validity Period", key: "validity_period", onPrintFn: (rawData) =>{
                    return `${dateObjectToString(rawData.valid_from, "dd/mm/YY")} - ${dateObjectToString(rawData.valid_to, "dd/mm/YY")}`
                }, isSort: false },
                { displayName: "Tx. Type", key: "txn_type", isSort: false,
                    // onPrintFn: (rawData) => (rawData.ar_fee !== null||rawData.ar_fine !== null||rawData.ar_add !== null||rawData.ar_ded !== null) ? "Arrears" : "Licence"
                    onPrintFn: (rawData) => rawData.txn_type !== "LC" ? "Arrears" : "Licence"
                },
                { displayName: "Tx. Status", key: "txn_status", isSort: false,onPrintFn: (rawData) =>rawData.txn_status ===1 ? "Success":"Cancelled"},
                { displayName: "Fee (LKR)", key: "fee", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fee).toFixed(2)) },
                { displayName: "Fine (LKR)", key: "fine", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.fine).toFixed(2)) },
                { displayName: "Additions (LKR)", key: "addition", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator(Number(rawData.addition).toFixed(2)) },
                { displayName: "Deductions (LKR)", key: "deduction", isSort: false, isRightAlign: true, onPrintFn: (rawData) =>  "("+numberThousandSeperator(Math.abs(Number(rawData.deduction).toFixed(2)))+")"},
                { displayName: "Total (LKR)", key: "total", isSort: false, isRightAlign: true, onPrintFn: (rawData) => numberThousandSeperator((Number(rawData.fee)+Number(rawData.fine)+Number(rawData.addition)+Number(rawData.deduction)).toFixed(2))},
            ]}
            // tableBodyList={transactions}
        />
    );
};

const SearchElement = ({
    dataTableKey = tableKey,
}) => {
    return (
        <div className="row">
            <div className="col-md-6 col-sm-12">
                <div className="row">
                    <div className="col-md-6">
                        <InputBoxWithState
                            labelText={"Vehicle ID"}
                            formGroupName={`${dataTableKey}_search`}
                            inputName={"vehicleId"}
                            maxLength={25}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Licence Type"}
                            formGroupName={`${dataTableKey}_search`}
                            inputName={"licenceType"}
                            emptySelectOptionTxt={"All"}
                            dataList={[
                                {
                                    "id": "CA",
                                    "value": "Carnet Licence"
                                },
                                {
                                    "id": "FE",
                                    "value": "Free Licence"
                                },
                                {
                                    "id": "NO",
                                    "value": "Normal Licence"
                                }
                            ]}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Transaction Type"}
                            formGroupName={`${dataTableKey}_search`}
                            inputName={"transactionType"}
                            emptySelectOptionTxt={"All"}
                            dataList={[
                                {
                                    "id": "1",
                                    "value": "Arrears"
                                },
                                {
                                    "id": "0",
                                    "value": "Licence"
                                }
                            ]}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectBoxWithState
                            labelText={"Transaction Status"}
                            formGroupName={`${dataTableKey}_search`}
                            inputName={"transactionStats"}
                            emptySelectOptionTxt={"All"}
                            dataList={[
                                {
                                    "id": "CL",
                                    "value": "Cancelled"
                                },
                                {
                                    "id": "LC",
                                    "value": "Success"
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ViewTransactions };
