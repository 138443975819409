/*
 * @Author: gajani kangesan 
 * @Date: 2023-02-17 13:0:06 
 * @Last Modified by: gajani kangesan)
 * @Last Modified time: 2023-02-17 13:30:52
 */

import React,{createContext,useState} from "react";

const UIMenuContext=createContext({});


const UIMenuContextProvider=({children})=>{
   
    const [sidenavConfig, setsidenavConfig] = useState([
        { id: 1, open: false },
        { id: 2, open: false },
        { id: 3, open: false },
        { id: 4, open: false },
        { id: 5, open: false },
        { id: 6, open: false },
        { id: 7, open: false },
        { id: 8, open: false },
        { id: 9, open: false },
        
        
      ]);
      const setSidenavConfigurations = (sidenavConfig) => {
        setsidenavConfig(sidenavConfig);
      };
    const type=""
    return(
        <UIMenuContext.Provider value={[sidenavConfig,setSidenavConfigurations,type]}>
            {children}
        </UIMenuContext.Provider>
    )
}

export {
    UIMenuContext,
    UIMenuContextProvider
}