import React, { Fragment, useContext, useState, useEffect } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";

import {
  FormWrapper,
  SelectBoxWithState,
  SubmitButton,
  DatePickerWithState,
  InputButtonWithState,
  InputBoxWithState,
} from "../../../../../../ui-components/ui-elements/form";
import {userActivityLogReportAPI, provincesOfficesListAPI, AuthorityListApi, internalUserListAPI, reportGenerateAPI, userRoleListAPI, getInternalUserListAPI } from "../../../../../../../config/apiUrl.config";
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { isEmptyValue } from "../../../../../../../helpers/common-helpers/common.helpers";
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { CoreContext, apiResponseStoringType } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import {setMinDateFn,setMaxDateRangeFn,setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined

const UserActivityLogReportSearchCriteria = ({
  reportCode,
  formGroupName,
  setReportgeneratedValues = emptyFn,
  onGenerateSubmit = emptyFn,
  onResetClick = emptyFn,
}) => {
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [coreState, coreAction] = useContext(CoreContext);
  let datefrom = new Date();
  let dateto = new Date();
  let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
  let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
  let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
  let authorityId = roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.authorityId', "") : "All";
  let authorityServiceId = ""
  let generated_by = _get(authStatus, 'authUser.name', "")
  let role = _get(authStatus, 'authUser.rolePrefix', "")
  let applicationId = "All";
  let applicationName = "All";
  let userRoleId = "All"
  let userRoleName = "All"
  let userName = "All";
  let userActivityId = "All";
  let userActivityName = "All";
  let authorityName = "All"
  let nic = "";
  let userId = "All";
  const applicationList = [
    {
      id: "admin",
      value: "Admin Module",
    },
    {
      id: "internal",
      value: "Internal Portal",
    },
  ]

  const activityList=[
    {
      id: "create",
      value: "CREATE",
    },
    {
      id: "delete",
      value: "DELETE",
    },
    {
      id: "update",
      value: "UPDATE",
    }
  ]
  const [getdatalist, setDatalist] = useState(" ");
  useEffect(() => {

    coreAction
      .sendRequestFn(
        `${AuthorityListApi.url}?check_user_role=1&sort_by=name|asc`
      )
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: AuthorityListApi.key,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          if (roleLevelCode === "INT_AU") {
            setDatalist(result.data.data.filter((item) => item.id === authorityId));
          }
          else {
            setDatalist(result.data.data.filter((item) => item.province.id === provinceId))
          }
        }
      });

  }, []);
  const serviceID = _get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === _get(formState[formGroupName], "authority", null))?.service.id
  const queryObj = () => {
    if (serviceID == undefined) {
          return `provice_id=${provinceId}`;
    } else {
          return `service_id=${_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === _get(formState[formGroupName], "authority", null))?.service.id}`;
    }
}
  return (
    <Fragment>
      <UICard>
        <FormWrapper
          setGroupName={formGroupName}
          formGroupLinkWith={formGroupName}
          onDestroyUnsetFormObject={true}
          setFormObject={{
            date_from: null,
            date_to: null,
            province: provinceId,
            authority: roleLevelCode === "INT_AU" ? null : authorityId,


          }}
        >
          <div className="row">
            {/* <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                dataList={applicationList}
                emptySelectOptionTxt={"All"}
                labelText="Application"
                inputName={"application"}
                
              />
            </div> */}
             <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                // apiUrl={`${userRoleListAPI.url}?role_type_code=${_get(formState, `${formGroupName}.application`) === "internal" ? "INT_APP" : _get(formState, `${formGroupName}.application`) === "admin" ? "ADN_APP" : "All"}&sort_by=name%7Casc`}
                apiUrl={`${userRoleListAPI.url}?&sort_by=name%7Casc`}
                apiStateKey={userRoleListAPI.key}
                keyName={"id"}
                valueName={"name"}
                emptySelectOptionTxt={"All"}
                labelText="User Role"
                inputName={"userRole"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                keyName={"id"}
                emptySelectOptionTxt={"All"}
                labelText="User Activity"
                inputName={"userActivity"}
                dataList={activityList}
              />
            </div>
           
          </div>
          <div className="row">
      
            <div className="col-md-6 col-sm-12 floatLeft">
              <DatePickerWithState
                isRequired={true}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                labelText="Date From"
                inputName={"date_from"}
                maxDate={setMaxDateFn(_get(formState[formGroupName], "date_to", null))}
                minDate={setminDateRangeFn(_get(formState[formGroupName], "date_to", null),_get(formState[formGroupName], "date_from", null))}
              />
            </div>
            <div className="col-md-6 col-sm-12 floatRight">
              <DatePickerWithState
                isRequired={true}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                labelText="Date To"
                inputName={"date_to"}
                minDate={setMinDateFn(_get(formState[formGroupName], "date_from", null))}
                maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "date_from", null))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                apiUrl={`${provincesOfficesListAPI.url}?sort_by=province.name%7Casc`}
                isDisabled={true}
                apiStateKey={provincesOfficesListAPI.key}
                keyName={"id"}
                emptySelectOptionTxt={provinceName}
                valueName={"province.name"}
                labelText="Province"
                inputName={"province"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                isRequired={true}
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                emptySelectOptionTxt={"All"}
                isEnableAllOption={roleLevelCode === "INT_AU" ? false : true}
                // isDisabled={roleLevelCode === "INT_PRV" ? true : false}
                dataList={getdatalist}
                keyName={"id"}
                valueName={"service.name"}
                labelText="Authority"
                inputName={"authority"}
                
              />
            </div>
          </div>
          <div className="row">
          <div className="col-md-6 col-sm-12">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                emptySelectOptionTxt={"All"}
                // apiUrl={(_get(formState[formGroupName], "authority", null) !== null && _get(formState[formGroupName], "userRole", null) !== null) ? `${internalUserListAPI.url}?service_id=${_get(formState[formGroupName], "authority", null) === "All" ? 0 : _get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === _get(formState[formGroupName], "authority", null))?.service.id}&sort_by=full_name|asc&userType=all&role_id=${_get(coreState, `apiResponses.${userRoleListAPI.key}.result`, []).find(item => item.id === _get(formState[formGroupName], "userRole", null))?.role_level_code.id}` : ""}
                apiUrl={_get(formState[formGroupName], "authority", null) === null ? "" : (`${internalUserListAPI.url}?${queryObj()}&sort_by=full_name|asc&userType=all&role_id=${_get(coreState, `apiResponses.${userRoleListAPI.key}.result`, []).find(item => item.id === _get(formState[formGroupName], "userRole", null))?.id}`)}
                apiStateKey={internalUserListAPI.key}
                keyName={"id"}
                valueName={"full_name"}
                labelText="User's Name"
                inputName={"userName"}
              />
            </div>  
            <div className="col-md-6 col-sm-12">
              <InputBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formGroupName}
                labelText="NIC No."
                emptySelectOptionTxt={""}
                maxLength="12"
                inputName={"nic"}
                valueName={"nic"}
              />
            </div>
          </div>
        
          <div className="col-md-12 col-sm-12 mt-2 text-center report-btn-wrapper">
            <SubmitButton
              btnName={"generate"}
              btnText={"Generate"}
              startIcon="fas fa-wrench"
              formGroupName={formGroupName}
              isValidate={true}
              validationObject={{
                fileds: {
                  date_from: "Date From",
                  date_to: "Date To",
                  nic: "NIC No"


                },
                rules: {
                  date_from: "required",
                  date_to: "required",

                },
                message: {}
              }}
              callApiObject={{
                isSetHeaders: true,
                method: "get",
                multipart: false,
                onUpload: false,
                responseType: 'text'
              }}
              apiDataStoringObject={{
                setLoader: true,
                storingType: "API_RESPONSE_LINKED_FORM",
                mergeToSuccessResponse: true,
                mergeToErrorResponse: true
              }}
              onGetAPIEndPointFn={(formObject) => {
                if (!isEmptyValue(formObject.application)) {
                  applicationId = formObject.application
                  applicationName = applicationList.find(item => item.id === applicationId)?.value
                }
                if (!isEmptyValue(formObject.userActivity)) {
                  userActivityId = formObject.userActivity
                  userActivityName = activityList.find(item => item.id === userActivityId)?.value
                }
                if (!isEmptyValue(formObject.date_from)) {
                  datefrom = dateObjectToString(new Date(formObject.date_from));

                }
                if (!isEmptyValue(formObject.date_to)) {
                  dateto = dateObjectToString(new Date(formObject.date_to));
                }
                if (!isEmptyValue(formObject.province)) {
                  provinceId = formObject.province
                  provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                }
                if (!isEmptyValue(formObject.userRole)) {
                  
                  userRoleId = formObject.userRole
                  userRoleName = (_get(coreState, `apiResponses.${userRoleListAPI.key}.result`, []).find(item => item.id ===  userRoleId )?.role_level_code.id)

                }
                if (!isEmptyValue(formObject.userName)) {
                  userId = formObject.userName
                  userName = (_get(coreState, `apiResponses.${internalUserListAPI.key}.result`, []).find(item => item.id === userId)?.full_name)

                }
                if (!isEmptyValue(formObject.nic)) {
                  nic = formObject.nic

                }
                if (!isEmptyValue(formObject.authority)) {
                  authorityId = formObject.authority
                  authorityServiceId = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.id)
                  authorityServiceId=(authorityServiceId == undefined || '') ? "" : authorityServiceId
                  authorityName = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.name)
                  authorityName = (authorityName == undefined || '') ? "" : authorityName
                  
                }

                return {
                  url: `${userActivityLogReportAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${500}&report_offset=${reportDataOffset}&report_requestFromDate=${datefrom}&report_requestToDate=${dateto}&report_requestProvince=${provinceName}&report_provinceId=${provinceId}&report_authorityServiceId=${authorityServiceId}&report_requestAuthority=${authorityName}&report_application=${applicationName}&report_userRoleId=${userRoleId}&eport_nicNo=${nic}&report_userActivity=${userActivityName}&report_userId=${userId}&report_userName=${userName}&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.provinceCode', "") : _get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                  key: `${userActivityLogReportAPI.key}`
                }
              }}

              onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
              }}

              onResponseCallBackFn={(error, response,) => {
                if (response?._statue) {
                  onGenerateSubmit(response?.data)
                  setReportgeneratedValues({
                    datefrom,
                    dateto,
                    provinceId,
                    authorityId,
                    applicationId,
                    applicationName,
                    userRoleName,
                    userRoleId,
                    provinceName,
                    userName,
                    userId,
                    userActivityName,
                    authorityServiceId,
                    nic,
                    limit: 500,
                    offset: reportDataOffset,
                    reportGeneratedUser: generated_by + "-" + (roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.provinceCode', "") : _get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                  })
                }
              }}
            />

            <InputButtonWithState
              btnName="refresh"
              startIcon="fas fa-sync-alt"
              formGroupName={formGroupName}
              btnText="Reset"
              elementWrapperStyle={"refreshBtn"}
              mergeToForm={{
                date_from: null,
                date_to: null,
                province: provinceId,
                authority: roleLevelCode === "INT_AU" ? null : authorityId,
                application: null,
                userRole: null,
                userActivity: null,
                nic: nic,
                userName:null
              }}
              tooltip="Reset"
              onClickBtnFn={(value) => {
                onResetClick()
                formAction.setFormErrorFn(formGroupName)
              }}
            />
          </div>

        </FormWrapper>
      </UICard>
      <br />
    </Fragment>

  );
};

export {
  UserActivityLogReportSearchCriteria
}