/*
 * @Author: Madhawa
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-06-07 06:59:43
 */

import React, {  Fragment, useContext } from "react";
import { thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const DealerLicenseDetails = ({ uiType = "Dealer", latestLicence = {} ,licenseData={}  ,index}) => {
  const searchFormKey = "dealer_search_form";
  const vehicleLicenseListKey = "dealer_license_vehiclelist_form"
  const [formState] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const helperText = [];
  const dataList = [
    "images/licence-preview-empty.jpg",
    "images/file-word.png",
    "images/profile_img.png",
  ];
  const ownerInfo = _get(formState, "viewOwnerInformation", "");
  const vehicleInfo = _get(formState, "vehicle_search_form.data", "");
 
  return (
    dataList.length > 0 && (
      <Fragment>
      <div className="licence-preview-card ml-4">
        <img src={dataList[0]} alt={""} />
        <div className="mainContent">
        <div className="row d-flex justify-content-center">
            <div className="licence-date float-left">                                                                                                                                               
              {_get(licenseData, "validity_to", null) ? dateObjectToString(_get(licenseData, "validity_to", null), "MMM YYY"): dateObjectToString(new Date(), "MMM YYY") } 
            </div>
          </div>

          <br />
          <div className="row">
            <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
            {`${_get(licenseData, "vehicle_number", "")}`} | DEALER LICENCE
            </div>
          </div>
          <div className="row">
            {/* <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
              {`${_get(formState, "vehicle_search_form.province_data.code", "")} | ${_get(formState, "vehicle_search_form.searchKey", "") }|   ${_get(vehicleInfo, "current_license_type.name", "").toUpperCase()}`} 
            </div> */}
          </div>
        

          <div className="col-md-12 col-sm-12  main-container">
          <div className="row owner-name-address-label">
          Vehicle Owner's Name & Address:
            </div>
            <div className="row owner-name">
            {_get(licenseData, "dealer.dealer_name", "")}
            </div>

            <div className="row owner-address">
            {_get(licenseData, "dealer.service.addresses[0].line1", "") +", "+_get(licenseData, "dealer.service.addresses[0].line2", "") + ", " +_get(licenseData, "dealer.service.addresses[0].city", "") }
            </div>
   
              <br />
              <div className="amount-container">

            <div className="row"> 
            <div className="floatLeft col-md-3 licence-total-amount-label">
            Amount (Rs.):
              </div>               
          
              <div className="floatLeft col-md-9 licence-amounts-label">
                Fee +  Arrears + Fine + Additions - Deductions
              </div>
              </div>
                                
        
              <div className="row"> 
            <div className="floatLeft col-md-3 licence-total-amount">
                {thousandSeparator((_get(formState, "dealer_charges_form.total", 0) / (_get(formState, `${vehicleLicenseListKey}.data`, []).length > 0 ? _get(formState, `${vehicleLicenseListKey}.data`, []).length : 1)), true)
                }:
              </div>               
          
              <div className="floatLeft col-md-9 licence-amounts">
              {thousandSeparator((_get(formState, "dealer_charges_form.totFee", 0) / (_get(formState, `${vehicleLicenseListKey}.data`, []).length > 0 ? _get(formState, `${vehicleLicenseListKey}.data`, []).length : 1)), true)} + 0.00 + 0.00
              + {thousandSeparator((+_get(formState, "dealer_charges_form.payableAddition", 0)  / (_get(formState, `${vehicleLicenseListKey}.data`, []).length > 0 ? _get(formState, `${vehicleLicenseListKey}.data`, []).length : 1)), true)   }  - {thousandSeparator((+_get(formState, "dealer_charges_form.totdeduction", 0) / (_get(formState, `${vehicleLicenseListKey}.data`, []).length > 0 ? _get(formState, `${vehicleLicenseListKey}.data`, []).length : 1)), true)}
              </div>
              </div>
            </div>
            </div>
            <div className="row bottom-label-container">                
              <div className="col-md-6">
                <div className="validity-period-label">
                Validity Period:
                </div>
                <div className="validity-period-value">
                 {
                    `${dateObjectToString(_get(licenseData, "validity_from", null), "dd/mm/YY")} To ${dateObjectToString(_get(licenseData, "validity_to", null), "dd/mm/YY")}`
                   }
                </div>
               </div>
               <div className="col-md-6">
                <div className="vet-no-label">
                     VET Number:
                </div>
                <div className="vet-no-value">
                {_get(licenseData, `lhs_vet_no`, "")}
                </div>
               </div>
               
              <div className="row bottom-label-container2">                
              <div className="col-md-6">
              <div className="row" style={{marginLeft:0}}>
                  <div className="unladen-weight-label">
                  Unladen/ Gross Weight:
                  </div>
                  <div className="unladen-weight-value">
                  {_get(licenseData, `lhs_weight`, "")}
                  </div>
                  </div>
                </div>
         
               <div className="col-md-6">
               <div className="row" style={{marginLeft:0}}>
               <div className="no-of-seats-label">
                     Number of Seats:
                </div>
                <div className="no-of-seats-value">
                {_get(licenseData, `lhs_no_of_seats`, "")} 
                </div>
                </div>
               </div>
            </div>
            </div>

            <div className="footer-content">
              <div className="row">
              <div className="col-md-6">
              <div className="signature-place">
                    .......................................
                </div>
                <div className="signature-details">
                Signature/ Designation & Date
</div>
                </div>

                <div className="col-md-6">
              <div className="signature-place">
                 {_get(authStatus, "authUser.name", "")}
                </div>
                <div className="signature-details">
                {dateObjectToString(new Date(), "dd/mm/YY")}
                </div>
                </div>
              </div>

            </div>
          </div>


        <div className="helperText">{helperText[0]}</div>
      </div>
    </Fragment>
      // <Fragment>
      //   <div className="licence-preview-card">
      //     <img src={dataList[0]} alt={""} />
      //     <div className="mainContent">
      //       <div className="row">
      //         <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
      //        
      //         </div>
      //       </div>
      //       <div className="row">
      //         <div className="defaultPaddingLeft defaultMarginLeft fontStyle">
      //            { dateObjectToString(_get(licenseData, "licence_expiry_date", null), "MMM YYY")}
      //         </div>
      //       </div>

      //       <br />

      //       <div className="col-md-9 col-sm-12 floatLeft">
      //         <div className="row">
      //           <div className="floatLeft col-md-4 fontStyle">Licence Type</div>
      //           {/* Not coming */}
      //           <div className="floatLeft col-md-8">DEALER LICENCE </div> 
      //         </div>
      //         <div className="row">
      //           <div className="floatLeft col-md-4 fontStyle">Licence No</div>
      //           <div className="floatLeft col-md-8">{_get(licenseData, "licence_number", "")}</div>
      //         </div>
      //         <div className="row">
      //           <div className="floatLeft col-md-4 fontStyle">Owner Name</div>
      //           <div className="floatLeft col-md-8"> </div>
      //         </div>
      //         <div className="row">
      //           <div className="floatLeft col-md-4 fontStyle">Owner Address</div>
      //           <div className="floatLeft col-md-8">
      //         
      //           </div>
      //         </div>

      //         <div className="row">
      //           <div className="floatLeft col-md-4 fontStyle">{" "}Unladen(Tare) / Gross Weight </div>
      //           <div className="floatLeft col-md-8">N/A</div>
      //         </div>
      //           <div className="floatLeft col-md-6">
      //             {" "}
      //           </div>
      //           <br />
      //         <div className="row">                
      //           <div className="floatLeft col-md-4 fontStyle">No. of Seats</div>
      //           <div className="floatLeft col-md-8 "> </div>
      //         </div>              
      //         <div className="row">                
      //           <div className="floatLeft col-md-4 fontStyle">{" "}Annual Fee, Arrears, Fine, Addition and Deduction{" "}</div>
      //           <div className="floatLeft col-md-8">
      //        
      //           </div>
      //         </div>
      //           <div className="floatLeft col-md-6">
      //             {" "}
      //             <br />
      //           </div>
      //           <div className="floatLeft col-md-6">
      //             {" "}
      //             <br />
      //           </div>
      //         <div className="row">                
      //           <div className="floatLeft col-md-4 fontStyle">Paid Till</div>
      //           <div className="floatLeft col-md-8">
      //               N/A
      //             </div>
      //         </div>
      //         <div className="row">                
      //           <div className="floatLeft col-md-4 fontStyle">VET Number </div>
      //           <div className="floatLeft col-md-8">
      //               N/A
      //           </div>
      //         </div>
      //         <div className="row">                
      //           <div className="floatLeft col-md-4 fontStyle">Validity Period{" "}</div>
      //           <div className="floatLeft col-md-8">
  
      //           </div>
      //         </div>
      //         <div className="row">                
      //           <div className="floatLeft col-md-4 fontStyle">Issued Date </div>
      //           <div className="floatLeft col-md-8">{dateObjectToString(new Date(), "dd/mm/YY")}</div>
      //         </div>
      //       </div>

      //       <div className="col-md-3 col-sm-4 floatLeft defaultPaddingRight">
      //         <div className="fontStyle"></div>
      //         <div className="fontStyle"></div>
      //       </div>

      //       <div className="col-md-3 col-sm-4 floatLeft qrImage paddingNone defaultPaddingRight">
      //         <img src="images/qr-code.jpg" alt="Image" />
      //       </div>


      //       <div className="col-md-3 col-sm-4 floatLeft emptySpace">
      //         <div className="fontStyle"></div>
      //         <div className="fontStyle"></div>
      //         <div className="fontStyle"></div>
      //       </div>
      //     </div>

      //     <div className="helperText">{helperText[0]}</div>
      //   </div>
      // </Fragment>
    )
  );
};

export { DealerLicenseDetails };
