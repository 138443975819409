/*
 * @Author: Madhawa
 * @Date: 2020-10-10 11:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-01-04 14:35:37
 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import { DocumentUploader, UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { getCodeName, getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { addVehicleStatusChangeAPI, getLatestLicenseAPI, uploadDetailGetAPI } from "../../../../config/apiUrl.config";
import {
  DatePickerWithState,
  FormWrapper,
  SelectBoxWithState,
} from "../../../ui-components/ui-elements/form";

import { Action } from "./includes/Action";
import { templateTypes } from "../../../../config/template.config";
import { Reason } from "./includes/Reason";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { LicenceTypeForTopPanel, RequestStatus, RequestTypesList, VehicleStatus, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { isEmpty } from "lodash";
let vehiclestatusCode
const StatusSubmitBtn = (props) => {
  const [formState] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);
  // const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
  const vehicle_no = _get(formState, "vehicle_search_form.searchKey", null);
  const [authStatus] = useContext(AuthContext);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const approvalAction = _get(
    formState,
    "vehicle-status-change.action",
    null
  )
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
  const request_id = _get(formState, "vehicle-status-change.request_id", null);

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={'vehicle-status-change'}
        tooltip={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${request_id}.`,
            messageType: "success",
          },
          failed: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request Decision Submit Failed. Please Try Again.`,
            messageType: "error",
          } : {
            // status: true,
            // message: `Approval Request Submit Failed. Please Try Again.`,
            // messageType: "error",
          },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "vehicle-status-change.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "vehicle-status-change.action", "") === 'FRD' ? (_get(formState, "vehicle-status-change.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
              effective_date: "Effective Date",
              new_vehicle_status: "New Vehicle Status"
            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              effective_date: "Effective Date",
              new_vehicle_status: "New Vehicle Status"
            }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            effective_date: "Effective Date",
            new_vehicle_status: "New Vehicle Status"
          }) : {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            "effective_date": "Effective Date",
            "new_vehicle_status": "New Licence Type"
          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "vehicle-status-change.action", "") === 'FRD' ? (_get(formState, "vehicle-status-change.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",
            effective_date: "required",
            new_vehicle_status: "required"
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            effective_date: "required",
            new_vehicle_status: "required"
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            effective_date: "required",
            new_vehicle_status: "required"
          }) : {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required",
            "effective_date": "requiredIf:user_role_level,INT_PRV"
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "vehicle-status-change.action", "") === 'FRD' ? (_get(formState, "vehicle-status-change.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
            "forward_authority": "Authority is required",
            "effective_date": "Effective Date is required",
            "new_vehicle_status": "New Vehicle Status is required",
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
            "effective_date": "Effective Date is required",
            "new_vehicle_status": "New Vehicle Status is required",
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "effective_date": "Effective Date is required.",
            "new_vehicle_status": "New Vehicle Status is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province": "Forward Province is required.",
            "forward_role": "Forward Role is required.",
            "action": "Action is required.",
            "effective_date.requiredIf": "Effective Date"

          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: props.uiType === "assign&Perform" ? `${addVehicleStatusChangeAPI.url}/${_get(props, "dataSubmittedForApproval.id", null)}` : `${addVehicleStatusChangeAPI.url}/${vehicle_no}`,
            key: addVehicleStatusChangeAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "vehicle-status-change.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["chng_vcl_status"],
              ui_request_id: props.uiType === "assign&Perform" ? (_get(props, "approvalRequestId", null)) : fromObject.request_id,
              new_vehicle_status: fromObject.new_vehicle_status,
              effective_date: (fromObject.new_vehicle_status) === "ACH" ? fromObject.effective_date ? new Date(fromObject.effective_date) : new Date() : new Date(),
              licence_type: current_licence_type,
              // status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              // authority_id: authorityId,
              // request_id:props.uiType === "assign&Perform"?(_get(props, "dataSubmittedForApproval.id", null)):(fromObject.request_id),
              reason_id: formState['vehicle-status-change-new'].reason_id,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              }
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["chng_vcl_status"],
              ui_request_id: props.uiType === "assign&Perform" ? (_get(props, "approvalRequestId", null)) : fromObject.request_id,
              new_vehicle_status: fromObject.new_vehicle_status,
              original_vehicle_status_code: vehiclestatusCode ? vehiclestatusCode : null,
              effective_date: (fromObject.new_vehicle_status) === "ACH" ? fromObject.effective_date ? new Date(fromObject.effective_date) : new Date() : null,
              licence_type: current_licence_type,
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: props.uiType === "assign&Perform" ? (_get(props, "dataSubmittedForApproval.id", null)) : (fromObject.request_id),
              reason_id: fromObject.reason_id,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }

        }}

        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "assign&Perform" ? "put" : "post",
        }}

        onResponseCallBackFn={(response, error) => {
          if (!isEmpty(response.data.errors)) {
            if (props.uiType !== 'assign&Perform') {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          }
        }}
      />
    </Fragment>
  );
};

const VehicleStatusChangeUI = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const todayDate = new Date();
  const firstRegDate = _get(formState, "vehicle_search_form.data.first_reg_date", null);
  const minDate = new Date(firstRegDate);
  const formKey = props.formKey;
  const uiType = props.uiType;
  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();
    if (props.uiType === 'APR') {
      getReqApprovalLicenceTypeData(props)
    }
    setRequestId(props.uiType === "APR" ? props.approvalRequestId : requ.substr(requ.length - 8))
  }, [])

  const getReqApprovalLicenceTypeData = (props) => {
    callApi(`${getLatestLicenseAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          // vehiclestatusCode = 'ELI'
          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

        }
      });
  }
  const currentStatus = props.uiType === 'APR' ? _get(formState, "latest_licence_data.data.vehicleData.vehicle_status", null) : _get(formState, "vehicle_search_form.data.vehicle_status", null);

  const getVehicleStatusCode = () => {
    // vehiclestatusCode = 'INE'
    vehiclestatusCode = currentStatus

    return vehiclestatusCode
  };


  const newStatus = props.uiType === 'APR' ? _get(formState, `latest_licence_data.data.vehicleData`, null) !== null ? VehicleStatus.filter(obj => obj.id != getVehicleStatusCode()) : [] : VehicleStatus.filter(obj => obj.id != getVehicleStatusCode());


  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <UICard>
          <FormWrapper setGroupName={formKey}
            setFormObject={props.uiType === 'APR' ? {
              ui_request_id: props.approvalRequestId,
            } : { request_id: _set(formState, `${formKey}.request_id`, requestId), }}
            apiUrl={`${addVehicleStatusChangeAPI.url}/${props.approvalRequestId}`}
            onRebuildResponseFn={(response) => {
              console.log('RESPP--', response);
              // formAction.changeInputFn('latest_licence_data', "data", "", result.data.data)
              // formAction.changeInputFn('latest_licence_data', "data", "", result.data.data)
              return {
                new_vehicle_status: _get(response, "new_vehicle_status", ""),
                effective_date: _get(response, "effective_date", ""),
                reason_id: _get(response, "work_flow_record.reason_id", "")
                //current_licence_type: current_licence_type
              };
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <strong>Current Vehicle Status : </strong> {getCodeName(VehicleStatus, getVehicleStatusCode())}
              </div>
            </div>

            <div className="row defaultPaddingTop">
              <div className="col-md-3">
                <SelectBoxWithState
                  uiType={templateTypes.bootstrap}
                  formGroupName={formKey}
                  isRequired={_get(formState, `${formKey}.user_role_level`, undefined) === 'INT_PRV' ? true :
                    _get(formState, `${formKey}.user_role_level`, undefined) === 'INT_AU' ? false : false}
                  dataList={newStatus}
                  inputName={"new_vehicle_status"}
                  labelText="New Vehicle Status "
                  emptySelectOptionTxt={"Select New Vehicle Status "}
                />
              </div>

              <div className="col-md-3">
                {_get(formState, `${formKey}.new_vehicle_status`, undefined) === 'ACH' ?
                  <DatePickerWithState
                    uiType={templateTypes.bootstrap}
                    formGroupName={formKey}
                    labelText="Effective Date"
                    inputName={"effective_date"}
                    minDate={minDate}
                    maxDate={todayDate}
                  /> : null}

              </div>
            </div>
          </FormWrapper>
        </UICard>
      </div>
      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <Reason
          filterKey={'vehicle_status_change'}
          formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
          requestType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
        />
      </div>

      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <Action
          formKey={formKey}
          uiType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
        />
      </div>
    </div >
  );
};

const VehicleStatusChange = () => {
  const formKey = "testInuts";
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Vehicle Status Change" },
      ]}
      pageTitle="Vehicle Status Change"
    >
      <VehicleStatusChangeUI />
    </DashboardTemplate>
  );
};

export default VehicleStatusChange;
export { VehicleStatusChangeUI, StatusSubmitBtn };
