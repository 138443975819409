// @Author: Gajani Kangesan 
//  * @Date: 2023-01-05 13:30:59 
import React from "react";
const EnvelopePrinting =({
    dataList=[]
})=>{
    return(
        <div className="envelope-print-card"  >
          
              <span className="tracking_id" >{dataList.tracking_id}</span>
              <span className="revenue_licence_number">{dataList.revenue_licence_number}</span>
              <span className="from" >From:</span>
              <span className="province_name1">{dataList.province_office_name_L1}</span>
              <span className="province_name2" >{dataList.province_office_name_L2}</span>
              <span className="provice_address1">{dataList.provice_office_address_L1}</span>
              <span className="provice_address2" >{dataList.provice_office_address_L2}</span>
              <span className="provice_address3">{dataList.provice_office_address_L3}</span>
              <span className="phone" >{dataList.phone}</span>
              <span className="web_address">{dataList.province_office_web_address}</span>
              <span className="to" >To: </span>
              <span className="owner_name1">{dataList.owner_name_L1}</span>
              <span className="owner_name2" >{dataList.owner_name_L2}</span>
              <span className="owner_name3">{dataList.owner_name_L3}</span>
              <span className="owner_address1" >{dataList.owner_address_L1}</span>
              <span className="owner_address2">{dataList.owner_address_L2==="null "?"":dataList.owner_address_L2}</span>
              <span className="owner_address3">{dataList.owner_address_L3}</span>
            </div>
    )
}

export default EnvelopePrinting;
