import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DashboardTemplate } from "../../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { _get, } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from '../../../../../config/permission.config';
const UserDetailsReport = () => {
  const [authStatus, authAction] = useContext(AuthContext);
  const ViewPermission = _get(authStatus, 'authUser.permissions', "");
  let FR37, FR38, FR39, FR40
  ViewPermission.forEach(item => {
    if (item.permission === permissions.VIEW_FR37_ACTIVE_USER_REPORT_ENTIRE.permissions[0]) {
      FR37 = true
    }
    else if (item.permission === permissions.VIEW_FR38_INACTIVE_USER_REPORT_ENTIRE.permissions[0]) {
      FR38 = true
    }
    else if (item.permission === permissions.VIEW_FR39_USER_ACTIVITY_LOG_REPORT.permissions[0]) {
      FR39 = true
    }
    else if (item.permission === permissions.VIEW_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE.permissions[0]) {
      FR40 = true
    }
  })
  return (
    <DashboardTemplate
      pageTitle="User Details Reports"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          //urlTo: "/report",
          displayName: "User Details Reports List",
        },
      ]}
    >
      <UICard>
        < ol type="1">
          <div className="App">
            {FR37 &&
              <li>
                <Link to="/active-user-report">
                  <strong>Active User Report</strong>
                </Link>
                <br />
                <p> This is the Active User Report</p>
                <br />
              </li>}
            {FR38 &&
              <li>
                <Link to="/inactive-user-report">
                  <strong>Inactive user Report</strong>
                </Link>
                <br />
                <p> This is the Inactive user Report</p>
                <br />
              </li>}

         {FR39 &&   <li>
              <Link to="/user-activity-log-report">
                <strong>User Activity Log Report</strong>
              </Link>
              <br />
              <p> This is the User Activity Log Report</p>
              <br />
            </li>
           }
            {FR40 &&
              <li>
                <Link to="/login-after-office-hours-report">
                  <strong>Login After Office hours Report</strong>
                </Link>
                <br />
                <p> This is the Login After Office hours Report</p>
              </li>
            }
          </div>
        </ ol>
      </UICard>
    </DashboardTemplate>
  );
};

export { UserDetailsReport };