/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-09 08:25:18
 */
import React, { useState, Fragment, useContext, useEffect } from "react";
import { templateTypes } from '../../../../../config/template.config';
import {
    InputBoxWithState,
    InputButton,
    CheckBoxWithState,
    CheckBox,
    DatePickerWithState,
    SelectBoxWithState,
    FormWrapper
} from "../../../../ui-components/ui-elements/form/index";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { RoutePermit } from "./RoutePermit";
import { VFTCertificate } from "./VFTCertificate";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

import { getCodeName, isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { vetAPI, vftCertificateAPI, vrpAPI, insuranceAPI, insuranceListAPI, issueRLValidationAPI, vftCompanyListAPI, vrpCompanyListAPI, provincesOfficesListAPI, pdmtListAPI, getAllTask, licenceInvalidDocuments, vetCompaniesListAPI, licenceMetaDataAPI } from "../../../../../config/apiUrl.config";

import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { VehicleStatus, } from "../../../../../config/enum.config";
import { fromObjectToQueryString } from "../../../../../helpers/common-helpers/common.helpers"
const emptyFn = (...para) => undefined;

const DocumentValidation = ({
    getState = {
        searchKey: null,
        currentObject: null,
        isSetVETDisable: false,
        checkVET: false,
        isSetVFTDisable: false,
        checkVFT: false,
        isSetInsuranceDisable: false,
        checkInsurance: false,
    },
    setState = emptyFn,
    setShowHideFooterFN = emptyFn,
    refreshLicenceFeeFN = emptyFn,
    disableAll = false, //if ineligibable
    uiType = "normalLicense",
    formGroupName = "normalLicence",
    getLatestLicenceState = null,
    getVehicleStatusCode = null,
    params = ""
}) => {
    const [formState, formAction] = useContext(FormContext);
    const [getModelState, setModelState] = useState({
        vrpModelState: false,
        vftModelState: false,
        isIssuesTempLicense: false,
        modelType: "",
    });
    const [vftCertificateData, setVftCertificateDate] = useState({
        status: "pending",
        id: null
    });
    const [isChecked, setIsChecked] = useState(false);
    const [isTempChecked, setTempIsChecked] = useState(false);
    const [getProvinceDetail, setProvinceDetail] = useState({
        "provinceData": null,
    });

    useEffect(()=>{
        if(_get(formState, "licence_validity_period.isReIssuance", false)===true){
            setModelState({
                ...getModelState,
                isIssuesTempLicense: false,
            });
        }

    },[_get(formState, "licence_validity_period.isReIssuance", false)]);
    
    useEffect(() => {
        if (_get(formState, "vehicle_search_form.data.current_license_type.vft_applicability", "") !== "NOT" &&
            _get(formState, "vehicle_search_form.data.vehicle_class.fitness_applicability_code", "") !== "NOT") {
            checkVftCertificateValidity();
        }

        checkinvalidDocusingWorkFlowReq();
        getProvinceData();
    }, []);

    useEffect(() => {
        if (isTempChecked && _get(formState, `licence_validity_period._onLoad`, true)=== false) {
            licenceMetaData()
        }

    }, [_get(formState, `licence_validity_period._onLoad`,true),_get(formState, `licence_validity_period.startDate`, null)]);

    useEffect(() => {
        if (isChecked && _get(formState,"licence_charges_form._onLoad",true)===false && _get(formState, `licence_validity_period._onLoad`,true)===false) {
            refreshIssueValidFn(_get(formState, "vet_certificate_form.isVet", false), _get(formState, "insurance_form.isInsurance", false), _get(formState, "vft_certificate_form.isfitness", false), _get(formState, "vrp_form.isroutePerm", false), (_get(formState, "vehicle_search_form.isTempLicence", false) === true ? "TEMP" : "FULL"))
        }

    },
        [
            _get(formState, "vet_certificate_form.isVet", false),
            _get(formState, "insurance_form.isInsurance", false),
            _get(formState, "vft_certificate_form.isfitness", false),
            _get(formState, "vrp_form.isroutePerm", false),
            _get(formState, "licence_charges_form.rateRange", null),
            _get(formState, "licence_validity_period.requestedLicenceEndDate", null),
            _get(formState, `licence_validity_period._onLoad`, true),
            _get(formState, "vehicle_search_form.isTempLicence", false),
            _get(formState,"licence_charges_form._onLoad",true)
        ]
    );

    const licenceMetaData = () => {

        let requested_date = null
        if (_get(formState, `licence_validity_period.startDate`, null) > new Date()) {
            requested_date = _get(formState, `licence_validity_period.startDate`, null)
        } else {
            requested_date = new Date()
        }
        callApi(`${licenceMetaDataAPI.url}?vehicle_no=${_get(formState, "vehicle_search_form.data.vehicle_no", "")}&start_date_type=R&requested_date=${dateObjectToString(requested_date)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    formAction.changeInputFn('licence-meta', "data", "", result.data.data)

                }

            })


    };

    const refreshIssueValidFn = (isvet, isinsurance, isvft, isvrp, mode) => {
        let document_validations
        // if (_get(formState, `vehicle_search_form.data.current_license_type.code`, "") === "NU") {
        //     document_validations = false
        // }
        if (mode === "TEMP") {
            document_validations = false
        }
        else {
            // document_validations = ((_get(formState, "vehicle-meta-data.data.vet_validation", false) === "MAN" && _get(formState, "vet_certificate_form.isVet", false))
            // && (_get(formState, "vehicle-meta-data.data.insurance_validation","") === "MAN" && _get(formState, "insurance_form.isInsurance", false))
            // && (_get(formState, "vehicle-meta-data.data.vft_validation", false) === "MAN" && _get(formState, "vft_certificate_form.isfitness", false))
            // && (_get(formState, "vehicle-meta-data.data.vrp_validation", false) === "MAN" && _get(formState, "vrp_form.isroutePerm", false))) ? true : false

            document_validations = (( _get(formState,"vehicle-meta-data.data.vet_required",false) === isvet)
                && ( _get(formState,"vehicle-meta-data.data.insurance_required",false) === isinsurance)
                && ( _get(formState,"vehicle-meta-data.data.vft_required",false) === isvft)
                && ( _get(formState,"vehicle-meta-data.data.vrp_required",false) === isvrp)) ? true : false

        }

        const queryObj = {
            vehicle_number: _get(formState, "vehicle_search_form.data.vehicle_no", ""),
            eligible: _get(formState,"vehicle-meta-data.data.eligible",false)===true ? true : false,
            requested_licence_type: _get(formState, `vehicle_search_form.data.current_license_type.code`, ""),
            requested_licence_mode: mode,
            requested_licence_end_date: dateObjectToString(_get(formState, "licence_validity_period.requestedLicenceEndDate", null)),
            documents_validated: document_validations,
            is_reissuance: _get(formState, "licence_validity_period.isReIssuance", false),
            rate_range_detail_id: _get(formState, "licence_charges_form.rateRange", null),
        };

        callApi(`${issueRLValidationAPI.url}?${fromObjectToQueryString(queryObj)}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    formAction.changeInputFn('issue-RL-Validation', "data", "", result.data.data)

                }
                formAction.changeInputFn("issue-RL-Validation", "_onLoad", "", false);
            })
    };

    const checkVftCertificateValidity = () => {
        callApi(`${vftCertificateAPI.url}?used=false&vehicle_id=${_get(formState, "vehicle_search_form.searchKey")}`)
            .headers(true)
            .method("get")
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    if (result.data.status === true) {
                        setVftCertificateDate({
                            status: true,
                            id: result.data.data.id,
                            certNo: result.data.data.certificate_no,
                        });
                    } else {
                        setVftCertificateDate({
                            status: false,
                            id: null
                        });
                    }
                } else {
                    setVftCertificateDate({
                        status: false,
                        id: null
                    });
                }
            });
    };

    const checkinvalidDocusingWorkFlowReq = () => {
        callApi(`${getAllTask.url}/get-invalid-doc-by-vehicle-no/${_get(formState, "vehicle_search_form.searchKey")}`)
            .headers(true)
            .method("get")
            .isMultipart(false)
            .send((error, result) => {
                console.log('REQQ', error, result);
                if (!error) {
                    if (result.data.data.status === "APP") {
                        callApi(`${licenceInvalidDocuments.url}/get-documents/${result.data.data.request_id}`)
                            .headers(true)
                            .method("get")
                            .isMultipart(false)
                            .send((error, results) => {
                console.log('REQQ2', error, result);

                                if (!error) {
                                    formAction.changeInputFn('vehicle_search_form', "documentRequest", "", results.data.data);
                                }
                            });
                    }
                }
            });
    };

    const getProvinceData = () => {
        callApi(`${pdmtListAPI.url}/${_get(formState, "vehicle_search_form.data.province_id", "")}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    setProvinceDetail({
                        "provinceData": result.data.data
                    })
                    formAction.changeInputFn('vehicle_search_form', "provinceData", "", result.data.data);

                }
            });
    };

    const setMinDateFn = () => {
        let minDate = new Date();
        minDate = new Date(minDate.setDate(minDate.getDate()));
        return minDate;
    };

    if (_get(params, 'fromParent.uiType', '') === 'QUOTATION_CREATE') {
        disableAll = true;
    }

    return (
        <div className="fullWidthDiv documentValidation">
            <PaddingLessUICard cardHeading="Document Validation">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            {uiType === "carnet" ?
                                (
                                    <div className="col-md-8">
                                        <CheckBoxWithState
                                            inputName={"dmtCarnetLetter"}
                                            formGroupName={formGroupName}
                                            labelText={"DMT Carnet Letter"}
                                            inputValue={true}
                                            isDisabled={
                                                getVehicleStatusCode !== "ELI" || 
                                                _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" 
                                                ? true : false
                                            }
                                        />
                                    </div>
                                ) : (
                                    <Fragment>
                                        <div className="col-md-4">
                                            <CheckBoxWithState
                                                inputName={"isVerifyRegister"}
                                                formGroupName={formGroupName}
                                                labelText={"Certificate of Registration"}
                                                isDisabled={
                                                    getVehicleStatusCode !== "ELI" || 
                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" ||                                                                     _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" ||                                                                     _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" 
                                                    ? true : false
                                                }
                                            />
                                        </div>
                                        <div className="col-md-8 floatRight defaultHalfPaddingTop">
                                            <strong>Previous Lic. No. /Rec. No. : </strong> <span>{getLatestLicenceState.licence_number}</span>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>

                    {
                        (_get(formState, "vehicle_search_form.provinceData", null)) ?
                            (
                                <Fragment>
                                    {
                                        (_get(formState, "vehicle-meta-data.data.vet_validation", false) === "MAN" || _get(formState, "vehicle-meta-data.data.vet_validation", false) === "OPT")
                                            ? (
                                                <FormWrapper
                                                    elementStyle={"col-md-12"}
                                                    setGroupName={"vet_certificate_form"}
                                                    formGroupLinkWith={"vet_certificate_form"}
                                                    isLoaderOnError={false}
                                                    apiUrl={`${vetAPI.url}?vehicle_reg_no=${_get(formState, "vehicle_search_form.searchKey")}&used=false&status=true`}
                                                    onDestroyUnsetFormObject={true}
                                                    onRebuildResponseFn={(response) => {
                                                        response = response;
                                                        const currentDate = new Date();
                                                        let vetExpValidation = false  // true = not exempted  ,  false = exempted
                                                        // let vetvalidityPeriod = getProvinceDetail.provinceData.vet_validity_period;
                                                        let vetvalidityPeriod = _get(formState, "vehicle_search_form.provinceData.vet_validity_period", null);

                                                        const firstRegDate = new Date(_get(formState, "vehicle_search_form.data.first_reg_date", ""));
                                                        const vetExpvalue = _get(formState, "vehicle_search_form.data.vehicle_reg_condition.vet_exemption_value", "");
                                                        const vetExpType = _get(formState, "vehicle_search_form.data.vehicle_reg_condition.vet_exemption_duration_code", "");

                                                        let monthrange = (currentDate.getFullYear() - firstRegDate.getFullYear()) * 12;
                                                        monthrange -= firstRegDate.getMonth();
                                                        monthrange += currentDate.getMonth();

                                                        if (vetExpType === 'Y') {
                                                            //calculate Year range
                                                            let yearrange = (currentDate.getFullYear() - firstRegDate.getFullYear());
                                                            if (yearrange > vetExpvalue) {
                                                                vetExpValidation = true
                                                            }
                                                        } else if (vetExpType === 'M') {
                                                            //calculate Month Range
                                                            let monthrange = (currentDate.getFullYear() - firstRegDate.getFullYear()) * 12;
                                                            monthrange -= firstRegDate.getMonth();
                                                            monthrange += currentDate.getMonth();
                                                            if (monthrange > vetExpvalue) {
                                                                vetExpValidation = true
                                                            }
                                                        } else {
                                                            //calculate date range
                                                            const daterange = currentDate.valueOf() - firstRegDate.valueOf()
                                                            const rangeValue = daterange / (1000 * 3600 * 24)
                                                            if (rangeValue > vetExpvalue) {
                                                                vetExpValidation = true
                                                            }
                                                        }
                                                        const issuedDate = new Date(response.issued_at);
                                                        const dateRangeTime = currentDate.valueOf() - issuedDate.valueOf()
                                                        var dateRange = dateRangeTime / (1000 * 3600 * 24);

                                                        if (
                                                            dateRange <= vetvalidityPeriod &&
                                                            vetExpValidation === true
                                                        ) {
                                                            return {
                                                                id: response.id,
                                                                vetCompany: response.service_id,
                                                                vetDateTime: response.issued_at,
                                                                vetExpiryDate: response.expiry_date,
                                                                vet_no: response.vet_no,
                                                                isVet: (_get(response, "service_id", "") !== "" && _get(response, "vet_no", "") !== "" && _get(response, "issued_at", "") !== "") ? (true) : (false),
                                                                status: response.status,
                                                                used: response.used,
                                                                disabled:(new Date(response.expiry_date).getTime() >= new Date().getTime()) ? true : false,
                                                                "_onLoad": false
                                                            }
                                                        } else {
                                                            return {
                                                                id: "",
                                                                vetCompany: "",
                                                                vetDateTime: null,
                                                                vet_no: "",
                                                                isVet: false,
                                                                status: "",
                                                                used: "",
                                                                disabled: false,
                                                                "_onLoad": false
                                                            }
                                                        }

                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <SelectBoxWithState
                                                                uiType={(
                                                                    _get(formState, "vet_certificate_form.isVet", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(getLatestLicenceState, `current_licence_status`, 'NL') === "VA" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true || 
                                                                    _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "FUL"
                                                                ) ? templateTypes.bootstrap : templateTypes.default}
                                                                labelText={"VET"}
                                                                isRequired={true}
                                                                formGroupName={"vet_certificate_form"}
                                                                inputName={"vetCompany"}
                                                                emptySelectOptionTxt={"Select VET Company"}
                                                                apiUrl={`${vetCompaniesListAPI.url}?sort_by=name|asc&active=true`}
                                                                apiStateKey={`${vetCompaniesListAPI.key}`}
                                                                keyName={"service.id"}
                                                                //elementStyle={"DefaultSelectWrapper"}
                                                                valueName={"service.name"}
                                                                isDisabled={
                                                                    (
                                                                        _get(formState, "vet_certificate_form.isVet", false) === true ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ) ? true : false}
                                                                onChangeFn={(event) => {
                                                                    formAction.changeInputFn('vet_certificate_form', "vetDateTime", "", new Date());
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <DatePickerWithState
                                                                labelText={"Issued Date"}
                                                                isRequired={true}
                                                                inputValue={null}
                                                                formGroupName={"vet_certificate_form"}
                                                                inputName={"vetDateTime"}
                                                                maxDate={setMinDateFn()}
                                                                isDisabled={
                                                                    (
                                                                        _get(formState, "vet_certificate_form.isVet", false) === true ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ) ? true : false
                                                                }
                                                            // _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "TMP") ? true : false}
                                                            />

                                                        </div>
                                                        <div className="col-md-4 checkElement">
                                                            <InputBoxWithState
                                                                elementWrapperStyle={"checkElementTxt"}
                                                                labelText={"VET No."}
                                                                formGroupName={"vet_certificate_form"}
                                                                inputName={"vet_no"}
                                                                isRequired={true}
                                                                maxLength={50}
                                                                isDisabled={
                                                                    (
                                                                        _get(formState, "vet_certificate_form.isVet", false) === true ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ) ? true : false
                                                                }
                                                            />

                                                            <div className="checkElementViewMore">
                                                                <CheckBoxWithState
                                                                    inputName={"isVet"}
                                                                    formGroupName={"vet_certificate_form"}
                                                                    isDisabled={
                                                                        (
                                                                            _get(formState, "vet_certificate_form.disabled", false) ||
                                                                            _get(formState, "vet_certificate_form.vetCompany", "") === "" ||
                                                                            _get(formState, "vet_certificate_form.vet_no", "") === "" ||
                                                                            _get(formState, "vet_certificate_form.vetDateTime", "") === "" ||
                                                                            getVehicleStatusCode !== "ELI" ||
                                                                            _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                            _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                            _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                        ) ? (true) : (false)
                                                                    }
                                                                    onChangeFn={(event) => {
                                                                        if (event.value) {
                                                                            formAction.changeInputFn('vet_certificate_form', "status", "", event.value);
                                                                        }
                                                                        setIsChecked(true)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormWrapper>
                                            ) : null
                                    }

                                    {
                                        (_get(formState, "vehicle-meta-data.data.insurance_validation", false) === "MAN" || _get(formState, "vehicle-meta-data.data.insurance_validation", false) === "OPT")
                                            ? (
                                                <FormWrapper
                                                    elementStyle={"col-md-12"}
                                                    setGroupName={"insurance_form"}
                                                    isLoaderOnError={false}
                                                    apiUrl={`${insuranceAPI.url}?vehicle_number=${_get(formState, "vehicle_search_form.searchKey")}&used=false`}
                                                    onRebuildResponseFn={(response) => {

                                                        return {
                                                            id: response.id,
                                                            insurance: response.service_id,
                                                            issued_at: response.issued_at,
                                                            reference: response.reference,
                                                            exp_date: response.valid_to,
                                                            type: response.type,
                                                            is_cover_note: response.is_cover_note,
                                                            isInsurance: _get(response, "status", false) === "ACT" ? (true) : (false),
                                                            status: response.status,
                                                            disabled:_get(response, "status", false) === "ACT" ? true :((new Date(response.valid_to).getTime() >= new Date().getTime()) ? true : false),
                                                            "_onLoad": false
                                                        }

                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <SelectBoxWithState
                                                                labelText={"Insurance"}
                                                                isRequired={true}
                                                                uiType={(
                                                                    _get(formState, "insurance_form.isInsurance", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" || _get(formState, "vehicle_search_form.isTempLicence", false) === true || 
                                                                    _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "FUL" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(getLatestLicenceState, `current_licence_status`, 'NL') === "VA")
                                                                    ? templateTypes.bootstrap : templateTypes.default
                                                                }
                                                                formGroupName={"insurance_form"}
                                                                emptySelectOptionTxt={"Select Insurance Company"}
                                                                inputName={"insurance"}
                                                                apiUrl={`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`}
                                                                apiStateKey={`${insuranceListAPI.key}list`}
                                                                keyName={"service.id"}
                                                                valueName={"service.name"}
                                                                isDisabled={
                                                                    (
                                                                        _get(formState, "insurance_form.isInsurance", false) === true ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ) ? true : false
                                                                }
                                                                onChangeFn={(event) => {
                                                                    formAction.changeInputFn('insurance_form', "status", "", "ACT");
                                                                    formAction.changeInputFn('insurance_form', "disabled", "", false);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <SelectBoxWithState
                                                                labelText={"Status"}
                                                                isRequired={true}
                                                                formGroupName={"insurance_form"}
                                                                inputName={"status"}
                                                                emptySelectOptionTxt={"Select Insurance status"}
                                                                dataList={[
                                                                    {
                                                                        id: "INA",
                                                                        value: "Inactive",
                                                                    },
                                                                    {
                                                                        id: "ACT",
                                                                        value: "Active",
                                                                    },
                                                                ]}
                                                                onChangeFn={(event) => {
                                                                    if (event.value === "ACT") {
                                                                        formAction.changeInputFn('insurance_form', "disabled", "", false)
                                                                    }
                                                                }}
                                                                isDisabled={
                                                                    (
                                                                        _get(formState, "insurance_form.isInsurance", false) === true ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ) ? true : false
                                                                }
                                                            />
                                                        </div>



                                                        <div className="col-md-4 checkElement">
                                                            <InputBoxWithState
                                                                elementWrapperStyle={"checkElementTxt"}
                                                                labelText={"Ref. No."}
                                                                formGroupName={"insurance_form"}
                                                                inputName={"reference"}
                                                                isRequired={true}
                                                                maxLength={30} //#38887
                                                                isDisabled={
                                                                    (
                                                                        _get(formState, "insurance_form.isInsurance", false) === true ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ) ? true : false
                                                                }
                                                            />
                                                            <div className="checkElementViewMore">
                                                                <CheckBoxWithState
                                                                    inputName={"isInsurance"}
                                                                    formGroupName={"insurance_form"}
                                                                    isDisabled={
                                                                        (_get(formState, "insurance_form.disabled", false) ||
                                                                        _get(formState, "insurance_form.reference", "") === "" ||
                                                                        _get(formState, "insurance_form.insurance", undefined) === undefined ||
                                                                        _get(formState, "insurance_form.status", 'INA') === 'INA' ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true)
                                                                         ? (true) : (false)
                                                                    }
                                                                    onChangeFn={(event) => {
                                                                        setIsChecked(true)
                                                                        //refreshIssueValidFn( _get(formState, "vet_certificate_form.isVet", false),event.value,_get(formState, "vft_certificate_form.isfitness", false),_get(formState, "vrp_form.isroutePerm", false))
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormWrapper>
                                            ) : null
                                    }

                                    {
                                        (_get(formState, "vehicle-meta-data.data.vft_validation", false) === "MAN" || _get(formState, "vehicle-meta-data.data.vft_validation", false) === "OPT")
                                            ? (
                                                <FormWrapper
                                                    elementStyle={"col-md-12"}
                                                    setGroupName={"vft_certificate_form"}
                                                    isLoaderOnError={false}
                                                    apiUrl={`${vftCertificateAPI.url}?used=false&type=PSS&sort_by=issued_date|DESC&vehicle_id=${_get(formState, "vehicle_search_form.searchKey")}`}
                                                    onRebuildResponseFn={(response) => {

                                                        let vftExpValidation = false
                                                        const currentDate = new Date();
                                                        currentDate.setHours(0, 0, 0, 0);
                                                        // let vftvalidityPeriod = getProvinceDetail.provinceData.vft_validity_period;
                                                        let vftvalidityPeriod = _get(formState, "vehicle_search_form.provinceData.vft_validity_period", null);

                                                        const firstRegDate = new Date(_get(formState, "vehicle_search_form.data.first_reg_date", ""));
                                                        const vftExpvalue = _get(formState, "vehicle_search_form.data.vehicle_reg_condition.vft_exemption_value", "");
                                                        const vftExpType = _get(formState, "vehicle_search_form.data.vehicle_reg_condition.vft_exemption_duration_code", "");

                                                        if (vftExpType === 'Y') {
                                                            //calculate Year range

                                                            let yearrange = (currentDate.getFullYear() - firstRegDate.getFullYear());
                                                            if (yearrange > vftExpvalue) {
                                                                vftExpValidation = true
                                                            }
                                                        } else if (vftExpType === 'M') {
                                                            //calculate Month Range
                                                            let monthrange = (currentDate.getFullYear() - firstRegDate.getFullYear()) * 12;
                                                            monthrange -= firstRegDate.getMonth();
                                                            monthrange += currentDate.getMonth();
                                                            if (monthrange > vftExpvalue) {
                                                                vftExpValidation = true
                                                            }
                                                        } else {
                                                            //calculate date range
                                                            const daterange = currentDate.valueOf() - firstRegDate.valueOf()
                                                            const rangeValue = daterange / (1000 * 3600 * 24)
                                                            if (rangeValue > vftExpvalue) {

                                                                vftExpValidation = true
                                                            }
                                                        }

                                                        const issuedDate = new Date(response[0].issued_date);
                                                        issuedDate.setHours(0, 0, 0, 0)

                                                        const dateRangeTime = currentDate.valueOf() - issuedDate.valueOf()
                                                        var dateRange = dateRangeTime / (1000 * 3600 * 24);

                                                        if (
                                                            dateRange <= vftvalidityPeriod &&
                                                            response[0].type === 'PSS' &&
                                                            response[0].used === 0 &&
                                                            vftExpValidation === true
                                                        ) {
                                                            return {
                                                                id: response[0].id,
                                                                vft: response[0].service_id,
                                                                certNo: response[0].certificate_no,
                                                                issuedDate: response[0].issued_date,
                                                                isfitness: (_get(response[0], "service_id", "") !== "" && _get(response[0], "certificate_no", "") !== "" && _get(response[0], "issued_date", "") !== "") ? (true) : (false),
                                                                exp_date: response[0].expiry_date,
                                                                type: response[0].type,//FAL or PSS
                                                                disabled: (new Date(response[0].expiry_date).getTime() >= new Date().getTime()) ? true : false,
                                                                "_onLoad": false
                                                            }
                                                        } else {
                                                            return {
                                                                id: "",
                                                                vft: "",
                                                                certNo: "",
                                                                issuedDate: null,
                                                                isfitness: false,
                                                                exp_date: "",
                                                                type: "",//FAL or PSS
                                                                disabled: false,
                                                                "_onLoad": false
                                                            }
                                                        }

                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <SelectBoxWithState
                                                                labelText={"VFT"}
                                                                isRequired={true}
                                                                uiType={
                                                                    _get(formState, "vft_certificate_form.isfitness", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(getLatestLicenceState, `current_licence_status`, 'NL') === "VA" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true || 
                                                                    _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "FUL" 
                                                                    ? templateTypes.bootstrap : templateTypes.default}
                                                                formGroupName={"vft_certificate_form"}
                                                                inputName={"vft"}
                                                                emptySelectOptionTxt={"Select VFT Center"}
                                                                apiUrl={`${vftCompanyListAPI.url}/list?sort_by=name|asc&active=true`}
                                                                apiStateKey={`${vftCompanyListAPI.key}list`}
                                                                keyName={"service.id"}
                                                                valueName={"service.name"}
                                                                isDisabled={
                                                                    _get(formState, "vft_certificate_form.isfitness", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ? true : false
                                                                }
                                                                onChangeFn={(event) => {
                                                                    formAction.changeInputFn('vft_certificate_form', "issuedDate", "", new Date());
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="col-md-4">
                                                            <DatePickerWithState
                                                                labelText={"Issued Date"}
                                                                isRequired={true}
                                                                inputValue={null}
                                                                formGroupName={"vft_certificate_form"}
                                                                inputName={"issuedDate"}
                                                                maxDate={setMinDateFn()}
                                                                isDisabled={
                                                                    _get(formState, "vft_certificate_form.isfitness", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ? true : false
                                                                }
                                                            />
                                                        </div>

                                                        <div className="col-md-4 checkElement">
                                                            <InputBoxWithState
                                                                elementWrapperStyle={"checkElementTxt"}
                                                                labelText={"Cert No."}
                                                                isRequired={true}
                                                                formGroupName={"vft_certificate_form"}
                                                                inputName={"certNo"}
                                                                maxLength={50}
                                                                isDisabled={
                                                                    _get(formState, "vft_certificate_form.isfitness", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ? true : false
                                                                }
                                                            />

                                                            <div className="checkElementViewMore">
                                                                <CheckBoxWithState
                                                                    inputName={"isfitness"}
                                                                    formGroupName={"vft_certificate_form"}
                                                                    onChangeFn={(event) => {
                                                                        if (event.value === true) {
                                                                            formAction.changeInputFn('vft_certificate_form', "type", "", "PSS")
                                                                        }
                                                                        else {
                                                                            formAction.changeInputFn('vft_certificate_form', "type", "", "FAL")
                                                                        }
                                                                        setIsChecked(true);
                                                                    }}
                                                                    isDisabled={(
                                                                        _get(formState, "vft_certificate_form.disabled", false) ||
                                                                        _get(formState, "vft_certificate_form.vft", "") === "" ||
                                                                        _get(formState, "vft_certificate_form.certNo", "") === "" ||
                                                                        _get(formState, "vft_certificate_form.issuedDate", "") === "" ||
                                                                        getVehicleStatusCode !== "ELI" ||
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ) ? (true) : (false)}
                                                                />
                                                                {_get(formState, "vft_certificate_form.id") ? (
                                                                    <InputButton
                                                                        btnName="view_more"
                                                                        tooltip="View More"
                                                                        overrideTextToIcon={
                                                                            <i className="mdi mdi-magnify-plus-outline" />
                                                                        }

                                                                        onClickBtnFn={() => {
                                                                            setVftCertificateDate({
                                                                                id: _get(formState, "vft_certificate_form.id", false)
                                                                            });
                                                                            setModelState({
                                                                                ...getModelState,
                                                                                vftModelState: true,
                                                                                modelType: "VFT",
                                                                            })
                                                                        }
                                                                        }
                                                                        isBtnDisabled={
                                                                            getVehicleStatusCode !== "ELI"
                                                                                ? true : false}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormWrapper>
                                            ) : null
                                    }

                                    {
                                        (_get(formState, "vehicle-meta-data.data.vrp_validation", false) === "MAN" || _get(formState, "vehicle-meta-data.data.vrp_validation", false) === "OPT" &&
                                            uiType !== "carnet") ? (

                                            <Fragment>
                                                <FormWrapper
                                                    elementStyle={"col-md-12"}
                                                    setGroupName={"vrp_form"}
                                                    isLoaderOnError={false}
                                                    apiUrl={`${vrpAPI.url}/${_get(formState, "vehicle_search_form.searchKey")}?status=Active&used=false`}
                                                    onRebuildResponseFn={(response) => {

                                                        formAction.changeInputFn("vrp_form", "_onLoad", "", false);
                                                        return {
                                                            id: response[0].service.id,
                                                            expiryDate: response[0].expiry_date,
                                                            issuedDate: response[0].issued_at,
                                                            vrpNo: response[0].route_permit_no,
                                                            isroutePerm: (_get(response[0], "service.id", "") !== "" && _get(response[0], "route_permit_no", "") !== "" && _get(response[0], "expiry_date", "") !== "") ? (true) : (false),
                                                            vrpCompany: response[0].service.id,
                                                            vrpId: response[0].id,
                                                            status: response[0].status,
                                                            disabled: (new Date(response[0].expiry_date).getTime() >= new Date().getTime()) ? true : false,
                                                            "_onLoad": false
                                                        }

                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-4">

                                                            <SelectBoxWithState
                                                                labelText={"VRP"}
                                                                formGroupName={"vrp_form"}
                                                                inputName={"vrpCompany"}
                                                                isRequired={true}
                                                                uiType={
                                                                    _get(formState, "vrp_form.isroutePerm", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(getLatestLicenceState, `current_licence_status`, 'NL') === "VA" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true || 
                                                                    _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "FUL" 
                                                                    ? templateTypes.bootstrap : templateTypes.default
                                                                }
                                                                emptySelectOptionTxt={"Select VRP Authority"}
                                                                apiUrl={`${vrpCompanyListAPI.url}/list?sort_by=name|asc&active=true`}
                                                                apiStateKey={`${vrpCompanyListAPI.key}list`}
                                                                keyName={"service.id"}
                                                                valueName={"service.name"}
                                                                isDisabled={
                                                                    _get(formState, "vrp_form.isroutePerm", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ? true : false
                                                                }
                                                                onChangeFn={(event) => {
                                                                    formAction.changeInputFn('vrp_form', "expiryDate", "", new Date());
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <DatePickerWithState
                                                                labelText={"Expiry Date"}
                                                                isRequired={true}
                                                                formGroupName={"vrp_form"}
                                                                inputValue={null}
                                                                inputName={"expiryDate"}
                                                                minDate={new Date()}
                                                                maxDate={'9999-12-12'} //Need to remove and fix
                                                                isDisabled={
                                                                    _get(formState, "vrp_form.isroutePerm", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ? true : false
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-4 checkElement">
                                                            <InputBoxWithState
                                                                elementWrapperStyle={"checkElementTxt"}
                                                                labelText={"VRP No."}
                                                                formGroupName={"vrp_form"}
                                                                inputName={"vrpNo"}
                                                                isRequired={true}
                                                                maxLength={50}
                                                                isDisabled={
                                                                    _get(formState, "vrp_form.isroutePerm", false) === true ||
                                                                    getVehicleStatusCode !== "ELI" ||
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                    _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" || 
                                                                    _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                    ? true : false
                                                                }
                                                            />

                                                            <div className="checkElementViewMore">
                                                                <CheckBoxWithState
                                                                    inputName={"isroutePerm"}
                                                                    formGroupName={"vrp_form"}
                                                                    onChangeFn={(event) => {
                                                                        setIsChecked(true);
                                                                    }}
                                                                    isDisabled={
                                                                        _get(formState, "vrp_form.disabled", false) ||
                                                                        _get(formState, "vrp_form.vrpCompany", "") === "" ||
                                                                        _get(formState, "vrp_form.vrpNo", "") === "" ||
                                                                        getVehicleStatusCode !== "ELI" || 
                                                                        _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                                        _get(formState, "vehicle_search_form.isTempLicence", false) === true
                                                                        ? (true) : (false)
                                                                    }
                                                                />
                                                                {_get(formState, "vrp_form.id") ? (
                                                                    <InputButton
                                                                        btnName="view_more"
                                                                        tooltip="View More"
                                                                        overrideTextToIcon={
                                                                            <i className="mdi mdi-magnify-plus-outline" />
                                                                        }
                                                                        onClickBtnFn={() =>
                                                                            setModelState({
                                                                                ...getModelState,
                                                                                vrpModelState: true,
                                                                                modelType: "VRP",
                                                                            })
                                                                        }
                                                                    />
                                                                ) : null}

                                                            </div>
                                                        </div>
                                                    </div>

                                                </FormWrapper>
                                            </Fragment>

                                        ) : null
                                    }
                                </Fragment>
                            ) : null
                    }

                    {((uiType !== "quotation") && _get(formState,"vehicle-meta-data.data.allow_temp",false)===true) ? (
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <CheckBox
                                        labelText={"Issue Temp. Licence"}
                                        formGroupName={formGroupName}
                                        inputValue={getModelState.isIssuesTempLicense}
                                        isDisabled={
                                            parseInt(getLatestLicenceState.temp_sequence) === 4 ||
                                            disableAll ||
                                            getVehicleStatusCode !== "ELI" ||
                                            _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                            _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW"
                                        }
                                        onChangeFn={(event) => {
                                            formAction.changeInputFn("licence_validity_period", "isReIssuance", "",false)
                                            formAction.changeInputFn('vehicle_search_form', "isTempLicence", "", event.value)    
                                            formAction.changeInputFn("licence_validity_period", "start_onLoad", "", true);
                                            formAction.changeInputFn("licence_charges_form", "_onLoad", "", true);
                                            setShowHideFooterFN(true, {
                                                issueMode: !getModelState.isIssuesTempLicense
                                                    ? "2"
                                                    : _get(
                                                        getState.currentObject,
                                                        `status.issueMode`,
                                                        "1"
                                                    ),
                                                isPendingWorkFlow: _get(
                                                    getState.currentObject,
                                                    `status.isPendingWorkFlow`,
                                                    false
                                                ),
                                                payArrearsOnly: _get(
                                                    getState.currentObject,
                                                    `status.payArrearsOnly`,
                                                    true
                                                ),

                                            });

                                            setModelState({
                                                ...getModelState,
                                                isIssuesTempLicense: !getModelState.isIssuesTempLicense,
                                            });
                                            refreshLicenceFeeFN(getModelState.isIssuesTempLicense);
                                            setTempIsChecked(true)
                                            // refreshIssueValidFn(_get(formState, "vet_certificate_form.isVet", false), _get(formState, "insurance_form.isInsurance", false), _get(formState, "vft_certificate_form.isfitness", false), _get(formState, "vrp_form.isroutePerm", false), (event.value ? "TEMP" : "FULL"))
                                            // licenceMetaData()
                                        }}
                                    />
                                </div>
                                {getModelState.isIssuesTempLicense === true ? (
                                    <Fragment>
                                        {
                                            <div className="col-md-4 defaultHalfPaddingTop">
                                                <strong>Run: </strong> {(_get(formState, "vehicle_search_form.documentRequest.run", 0) === 1) ? "Allowed" : "Restricted"}
                                            </div>
                                        }

                                        <div className="col-md-4 defaultHalfPaddingTop">
                                            <strong>
                                                Temp:
                                                {/* {
                                                    // getLatestLicenceState.temp_sequence === null || getLatestLicenceState.temp_sequence === undefined ?
                                                    // (dateObjectToString(_get(formState, 'licence_validity_period.startDate', ''), "YYYY") - dateObjectToString(_get(formState, 'licence_validity_period.tempLicenceEndDate', ''), "YYYY") - 0) :
                                                    // dateObjectToString(_get(formState, 'licence_validity_period.startDate', ''), "YYYY") - dateObjectToString(_get(formState, 'licence_validity_period.tempLicenceEndDate', ''), "YYYY") - parseInt(getLatestLicenceState.temp_sequence) + 1
                                                    isEmptyValue(_get(getLatestLicenceState, "temp_sequence", null)) ?
                                                        ` Temp-${dateObjectToString(_get(formState, 'licence_validity_period.startDate', ''), "YY UTC").slice(-2)}-${dateObjectToString(_get(formState, 'licence_validity_period.tempLicenceEndDate', ''), "YY UTC").slice(-2)}-01` :
                                                        ` Temp-${dateObjectToString(_get(formState, 'licence_validity_period.startDate', ''), "YY UTC").slice(-2)}-${dateObjectToString(_get(formState, 'licence_validity_period.tempLicenceEndDate', ''), "YY UTC").slice(-2)}-${parseInt(getLatestLicenceState.temp_sequence) + 1}`
                                                } */}

                                                {
                                                    _get(formState, "licence-meta.data.tempSequence", "")
                                                    // isEmptyValue(_get(getLatestLicenceState, "temp_sequence", null)) ?
                                                    //     ` Temp-${dateObjectToString(_get(getLatestLicenceState, "valid_from", null), "YY UTC").slice(-2)}-${dateObjectToString(_get(getLatestLicenceState, "valid_to", null), "YY UTC").slice(-2)}-01` :
                                                    //     ` Temp-${dateObjectToString(_get(getLatestLicenceState, "valid_from", null), "YY UTC").slice(-2)}-${dateObjectToString(_get(getLatestLicenceState, "valid_to", null), "YY UTC").slice(-2)}-${parseInt(getLatestLicenceState.temp_sequence) + 1}`
                                                }
                                            </strong>
                                        </div>
                                    </Fragment>

                                ) : null}

                                {uiType !== "carnet" && _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") !== "TMP" ?
                                    (<div className="col-md-3">
                                        <CheckBoxWithState
                                            inputName={"isTransferredVehicle"}
                                            formGroupName={formGroupName}
                                            labelText={"Transfer Vehicle"}
                                            isDisabled={
                                                getVehicleStatusCode !== "ELI" || 
                                                _get(params, 'fromParent.uiType', '') === "QUOTATION_CREATE" || 
                                                _get(params, 'fromParent.uiType', '') === "QUOTATION_VEHICLE_DETAILS_VIEW" 
                                                ? true : false
                                            }
                                        />
                                    </div>)
                                    : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            </PaddingLessUICard>

            <DialogPopup
                isFullScreen={getModelState.modelType === "VRP" ? true : false}
                isFullWidth={true}
                isOpen={getModelState.modelType === "VRP" ? getModelState.vrpModelState : getModelState.vftModelState}
                onClosePopupFn={() =>
                    getModelState.modelType === "VRP" ?
                        setModelState({
                            modelType: "",
                            vrpModelState: false,
                        }) :
                        setModelState({
                            modelType: "",
                            vftModelState: false,
                        })
                }
                dialogTitle={
                    getModelState.modelType === "VRP" ? "Route Permit" : "View VFT Certificate"
                }
            >
                {getModelState.modelType === "VRP" ? (
                    <RoutePermit />
                ) : (
                    <VFTCertificate vftCertificateId={vftCertificateData.id} />
                )}
            </DialogPopup>
        </div>
    );
};

export { DocumentValidation };
