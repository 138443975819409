/*
 * @Author:Gajani Kangesan
 * @Date: 2022-02-18 11:31:20
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:22:35
 */
import React, { Fragment, useContext,useEffect,useState} from "react";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements"
import {
      SelectBoxWithState,
      SubmitButton,
      FormWrapper,
      InputButtonWithState
} from "../../../../../../ui-components/ui-elements/form";
import { _getInt, _get, } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import { templateTypes } from "../../../../../../../config/template.config";
import { reportGenerateAPI, provincesOfficesListAPI, AuthorityListApi, availableCounterListApi } from "../../../../../../../config/apiUrl.config";
import { reportDataLimit, reportDataOffset, reportGenerationMaxRangeMonths } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { YearMonthPickerWithState } from "../../../../../../ui-components/ui-elements/form/DatePicker";
import { CoreContext,apiResponseStoringType } from "../../../../../../modules/core/context-providers/CoreContext.provider";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import {setMaxDateFn,setminDateRangeFn} from "../../../../../../../helpers/common-helpers/report.helpers";
const emptyFn = (...para) => undefined
const SearchCriteria = ({
      reportCode,
      formGroupName,
      setReportgeneratedValues = emptyFn,
      onGenerateSubmit = emptyFn,
      onResetClick = emptyFn,
}) => {

      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const [coreState, coreAction] = useContext(CoreContext);
      let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
      let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");
      let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : _get(authStatus, 'authUser.provinceName', "");
      let authorityId = _get(authStatus, 'authUser.authorityId', "");
      let counterName = 'All';
      let counterId = "All";
      let authorityName = '';
      let year_month=new Date()
      let year=  new Date().getFullYear();
      let selectedMonth = new Date().getMonth() + 1;
      let yearMonth ='';
      let generated_by = _get(authStatus, 'authUser.name', "")
      let role = _get(authStatus, 'authUser.rolePrefix', "")
      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const date = new Date();
      const [getdatalist,setDatalist]=useState(" ");
    useEffect(() => {

    coreAction
      .sendRequestFn(
        `${AuthorityListApi.url}?check_user_role=1&sort_by=name|asc`
      )
      .method("get")
      .setInitStoring(apiResponseStoringType.setResponse, {
        responseKey: AuthorityListApi.key,
      })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          if (roleLevelCode === "INT_AU") {
            setDatalist(result.data.data.filter((item) => item.id === authorityId));

          }
          else {
            setDatalist(result.data.data.filter((item) => item.province.id === provinceId))
          }

        }
      });

  }, []);

      return (
            <Fragment>
                  <UICard>
                        <FormWrapper
                              setGroupName={formGroupName}
                              formGroupLinkWith={formGroupName}
                              onDestroyUnsetFormObject={true}
                              setFormObject={{
                                    year_month: null,

                              }}
                        >

                              <div className="row d-flex align-items-center justify-content-center">
                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <SelectBoxWithState
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                apiUrl={provincesOfficesListAPI.url}
                                                keyName={"id"}
                                                valueName={"province.name"}
                                                apiStateKey={provincesOfficesListAPI.key}
                                                emptySelectOptionTxt={provinceName}
                                                labelText="Province"
                                                inputName={"province"}
                                                isDisabled={"true"}

                                          />
                                    </div>

                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <SelectBoxWithState
                                                isRequired={true}
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                emptySelectOptionTxt={" "}
                                                dataList={getdatalist}
                                                keyName={"id"}
                                                valueName={"service.name"}
                                                labelText="Authority"
                                                inputName={"authority"}
                                                onChangeFn={(value) => {
                                                      formAction.changeInputFn(formGroupName, "counter", null);
                                                }}
                                          />

                                    </div>



                              </div>
                              <div class="row">
                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <YearMonthPickerWithState
                                                isRequired={true}
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                labelText="Year & Month"
                                                inputName={"year_month"}
                                                maxDate={setMaxDateFn(_get(formState[formGroupName], "dateTo", null))}
                                                minDate={setminDateRangeFn(_get(formState[formGroupName], "dateTo", null))}
                                          />

                                    </div>
                                    <div className="col-md-6 col-sm-12 floatLeft">
                                          <SelectBoxWithState
                                                uiType={templateTypes.bootstrap}
                                                formGroupName={formGroupName}
                                                emptySelectOptionTxt={" "}
                                                apiUrl={`${availableCounterListApi.url}/${_get(formState[formGroupName], "authority", authorityId)}/counters?sort_by=name|asc`}
                                                apiStateKey={availableCounterListApi.key}
                                                keyName={"id"}
                                                isRequired={true}
                                                valueName={"name"}
                                                labelText="Counter"
                                                inputName={"counter"}

                                          />
                                    </div>

                                    <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">
                                          <SubmitButton
                                                btnName={"generate"}
                                                btnText={"Generate"}
                                                startIcon="fas fa-wrench"
                                                formGroupName={formGroupName}
                                                isValidate={true}
                                                validationObject={{
                                                      fileds: {
                                                            authority: "Authority",
                                                            year_month: "Year & Month",
                                                            counter: "Counter",

                                                      },
                                                      rules: {
                                                            authority: "required",
                                                            year_month: "required",
                                                            counter: "required",

                                                      },
                                                      message: {}
                                                }}
                                                callApiObject={{
                                                      isSetHeaders: true,
                                                      method: "get",
                                                      multipart: false,
                                                      onUpload: false,
                                                      responseType: 'text'
                                                }}
                                                apiDataStoringObject={{
                                                      setLoader: true,
                                                      storingType: "API_RESPONSE_LINKED_FORM",
                                                      mergeToSuccessResponse: true,
                                                      mergeToErrorResponse: true
                                                }}
                                                onGetAPIEndPointFn={(formObject) => {

                                                      if (!isEmptyValue(formObject.year_month)) {
                                                            year = formObject.year_month.getFullYear();
                                                            selectedMonth = formObject.year_month.getMonth() + 1;
                                                            yearMonth = dateObjectToString(new Date(formObject.year_month).setDate(1));
                                                      }
                                                     
                                                      if (!isEmptyValue(formObject.province)) {
                                                            provinceId = formObject.province
                                                            provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                                      }
                                                      if (!isEmptyValue(formObject.authority)) {
                                                            authorityId = formObject.authority
                                                            authorityName = (_get(coreState, `apiResponses.${AuthorityListApi.key}.result`, []).find(item => item.id === authorityId)?.service.name)
                                                      }
                                                      if (!isEmptyValue(formObject.counter)) {
                                                            counterId = formObject.counter
                                                            counterName = (_get(coreState, `apiResponses.${availableCounterListApi.key}.result`, []).find(item => item.id === counterId)?.name)
                                                            counterName = (counterName == null || '') ? 'All' : counterName
                                                      }



                                                      return {
                                                            url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestProvince=${provinceName}&report_authorityId=${authorityId}&report_authorityName=${authorityName}&report_counterId=${counterId}&report_counterName=${counterName}&report_yearNmonth=${yearMonth}&report_dateFormatDisplay=dd/MM/yyyy&report_dateFormatReceived=yyyy-MM-dd&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                                            key: `${reportGenerateAPI.key}`
                                                      }
                                                }}

                                                onChangeRequestBodyFn={(formObject) => {
                                                      return getDataByFormObject(formObject);
                                                }}

                                                onResponseCallBackFn={(error, response,) => {
                                                      if (response?._statue) {
                                                            onGenerateSubmit(response?.data)
                                                            setReportgeneratedValues({
                                                                  provinceId,
                                                                  authorityId,
                                                                  counterId,
                                                                  counterName,
                                                                  yearMonth,   
                                                                  authorityName,
                                                                  provinceName,
                                                                  limit: reportDataLimit,
                                                                  offset: reportDataOffset,
                                                                  reportYearNMonth: yearMonth,
                                                                  reportGeneratedUser: generated_by + "-" + (roleLevelCode === "INT_AU" ?_get(authStatus, 'authUser.provinceCode', ""):_get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"
                                                            })
                                                      }


                                                }}

                                          />

                                          <InputButtonWithState
                                                btnText="Reset"
                                                startIcon="fas fa-sync-alt"
                                                elementWrapperStyle={"refreshBtn"}
                                                formGroupName={formGroupName}
                                                mergeToForm={{
                                                      counter: null,
                                                      authority: null,
                                                      province: null,
                                                      year_month: null,

                                                }}
                                                onClickBtnFn={(event) => {
                                                      formAction.setFormErrorFn(formGroupName);
                                                      onResetClick()

                                                }}
                                          />
                                    </div>
                              </div>
                              <br />


                        </FormWrapper>
                  </UICard>
            </Fragment>
      )
}

export {
      SearchCriteria
}
