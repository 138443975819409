/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-06-12 15:41:23 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-06-15 10:48:33
 */

export const permissions = {
    "NONE": {
        "key": "NONE",
        "description": "",
        "permissions": "NONE"
    },
    "WF_SET_EFFECTIVE_DATE_ADD": {
        "key": "WF_SET_EFFECTIVE_DATE_ADD",
        "description": "",
        "permissions": [
            "INT.WF_SET_EFFECTIVE_DATE.ADD"
        ]
    },
    "WF_CANCEL_LICENCE_RECEIPT_TRANSACTION_EDIT": {
        "key": "WF_CANCEL_LICENCE_RECEIPT_TRANSACTION_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_CANCEL_LICENCE_RECEIPT_TRANSACTION.EDIT"
        ]
    },
    "OWNER INFORMATION_VIEW": {
        "key": "OWNER INFORMATIO_VIEW",
        "description": "",
        "permissions": [
            "INT.OWNER INFORMATION.VIEW"
        ]
    },
    "WF_ADD_DEALER_ADD": {
        "key": "WF_ADD_DEALER_ADD",
        "description": "",
        "permissions": [
            "INT.WF_ADD_DEALER.ADD"
        ]
    },
    "OWNER_INFORMATION_EDIT": {
        "key": "OWNER_INFORMATION_EDIT",
        "description": "",
        "permissions": [
            "INT.OWNER_INFORMATION.EDIT"
        ]
    },
    "WF_CHANGE_SERIAL_NUMBER_ADD": {
        "key": "WF_CHANGE_SERIAL_NUMBER_ADD",
        "description": "",
        "permissions": [
            "INT.WF_CHANGE_SERIAL_NUMBER.ADD"

        ]
    },
    "QUOTATION_MANAGE": {
        "key": "QUOTATION_MANAGE",
        "description": "",
        "permissions": [
            "INT.QUOTATION.MANAGE"
        ]
    },
    "WF_ADD_VEHICLE_ADD": {
        "key": "WF_ADD_VEHICLE_ADD",
        "description": "",
        "permissions": [
            "INT.WF_ADD_VEHICLE.ADD"
        ]
    },
    "WF_LICENCE_WAIVE_OFF_ADD": {
        "key": "WF_LICENCE_WAIVE_OFF_ADD",
        "description": "",
        "permissions": [
            "INT.WF_LICENCE_WAIVE_OFF.ADD"
        ]
    },
    // "OWNER_INFORMATION_EDIT":{
    //     "key":"OWNER_INFORMATION_EDIT",
    //     "description":"",
    //     "permissions":[
    //         "INT.OWNER INFORMATION.EDIT"
    //     ]
    // },
    "DELEGATION_SWITCH": {
        "key": "DELEGATION_SWITCH",
        "description": "",
        "permissions": [
            "INT.DELEGATION_SWITCH"
        ]
    },
    "WF_UPDATE_VEHICLE_ADD": {
        "key": "WF_UPDATE_VEHICLE_ADD",
        "description": "",
        "permissions": [
            "INT.WF_UPDATE_VEHICLE.ADD"
        ]
    },
    "WF_REQUEST_LICENCE_INVALID_DOCUMENTS_ADD": {
        "key": "WF_REQUEST_LICENCE_INVALID_DOCUMENTS_ADD",
        "description": "",
        "permissions": [
            "INT.WF_REQUEST_LICENCE_INVALID_DOCUMENTS.ADD"
        ]
    },
    "WF_REQUEST_LICENCE_LICENCE_WITH_ARREARS_ADD": {
        "key": "WF_REQUEST_LICENCE_LICENCE_WITH_ARREARS_ADD",
        "description": "",
        "permissions": [
            "INT.WF_REQUEST_LICENCE_LICENCE_WITH_ARREARS.ADD"
        ]
    },
    "WF_VEHICLE_TRANSFER_ADD": {
        "key": "WF_VEHICLE_TRANSFER_ADD",
        "description": "",
        "permissions": [
            "INT.WF_VEHICLE_TRANSFER.ADD"
        ]
    },
    "DASHBOARD": {
        "key": "DASHBOARD",
        "description": "",
        "permissions": [
            "INT.DASHBOARD"
        ]
    },
    "ROUTE_PERMIT_VIEW": {
        "key": "ROUTE_PERMIT_VIEW",
        "description": "",
        "permissions": [
            "INT.ROUTE_PERMIT.VIEW"
        ]
    },
    "NOTIFICATION_VIEW": {
        "key": "NOTIFICATION_VIEW",
        "description": "",
        "permissions": [
            "INT.NOTIFICATION.VIEW"
        ]
    },
    "QUOTATION_ADD": {
        "key": "QUOTATION_ADD",
        "description": "",
        "permissions": [
            "INT.QUOTATION.ADD"
        ]
    },
    "QUOTATION_VIEW": {
        "key": "QUOTATION_VIEW",
        "description": "",
        "permissions": [
            "INT.QUOTATION.VIEW"
        ]
    },
    "QUOTATION_PAYMENT_PROCEED": {
        "key": "QUOTATION_PAYMENT_PROCEED",
        "description": "",
        "permissions": [
            "INT.QUOTATION_PAYMENT.PROCEED"
        ]
    },
    "OTHER_ADDITIONS_DEDUCTIONS": {
        "key": "OTHER_ADDITIONS_DEDUCTIONS",
        "description": "",
        "permissions": [
            "INT.OTHER_ADDITIONS_DEDUCTIONS"
        ]
    },
    "WF_LICENCE_TYPE_CHANGE_ADD": {
        "key": "WF_LICENCE_TYPE_CHANGE_ADD",
        "description": "",
        "permissions": [
            "INT.WF_LICENCE_TYPE_CHANGE.ADD"
        ]
    },
    "PASSWARD_CHANGE": {
        "key": "PASSWARD_CHANGE",
        "description": "",
        "permissions": [
            "INT.PASSWARD_CHANGE"
        ]
    },
    "LICENCE_ARREARS_VIEW": {
        "key": "LICENCE_ARREARS_VIEW",
        "description": "",
        "permissions": [
            "INT.LICENCE_ARREARS.VIEW"
        ]
    },
    "WF_VEHICLE_STATUS_CHANGE_ADD": {
        "key": "WF_VEHICLE_STATUS_CHANGE_ADD",
        "description": "",
        "permissions": [
            "INT.WF_VEHICLE_STATUS_CHANGE.ADD"
        ]
    },
    "WF_CANCEL_LICENCE_RECEIPT_TRANSACTION_ADD": {
        "key": "WF_CANCEL_LICENCE_RECEIPT_TRANSACTION_ADD",
        "description": "",
        "permissions": [
            "INT.WF_CANCEL_LICENCE_RECEIPT_TRANSACTION.ADD"
        ]
    },
    "WF_LICENCE_RECEPT_REPRINT_ADD": {
        "key": "WF_LICENCE_RECEPT_REPRINT_ADD",
        "description": "",
        "permissions": [
            "INT.WF_LICENCE_RECEPT_REPRINT.ADD"
        ]
    },
    "WF_REVERSE_LAST_TRANSACTION_ADD": {
        "key": "WF_REVERSE_LAST_TRANSACTION_ADD",
        "description": "",
        "permissions": [
            "INT.WF_REVERSE_LAST_TRANSACTION.ADD"
        ]
    },
    "WF_OTHER_ADDITION_DEDUCTION_ADD": {
        "key": "WF_OTHER_ADDITION_DEDUCTION_ADD",
        "description": "",
        "permissions": [
            "INT.WF_OTHER_ADDITION_DEDUCTION.ADD"
        ]
    },
    "WF_ADD_LICENCE_RECEIPT_TRANSACTION_ADD": {
        "key": "WF_ADD_LICENCE_RECEIPT_TRANSACTION_ADD",
        "description": "",
        "permissions": [
            "INT.WF_ADD_LICENCE_RECEIPT_TRANSACTION.ADD"
        ]
    },
    "WF_UPDATE_DEALER_ADD": {
        "key": "WF_UPDATE_DEALER_ADD",
        "description": "",
        "permissions": [
            "INT.WF_UPDATE_DEALER.ADD"
        ]
    },
    "WF_ADD_DEALER_EDIT": {
        "key": "WF_ADD_DEALER_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_ADD_DEALER.EDIT"
        ]
    },
    "WF_UPDATE_DEALER_EDIT": {
        "key": "WF_UPDATE_DEALER_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_UPDATE_DEALER.EDIT"
        ]
    },
    "WF_CHANGE_SERIAL_NUMBER_EDIT": {
        "key": "WF_CHANGE_SERIAL_NUMBER_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_CHANGE_SERIAL_NUMBER.EDIT"
        ]
    },
    "WF_REQUEST_LICENCE_INVALID_DOCUMENTS_EDIT": {
        "key": "WF_REQUEST_LICENCE_INVALID_DOCUMENTS_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_REQUEST_LICENCE_INVALID_DOCUMENTS.EDIT"
        ]
    },
    "WF_DEALER_LICENCE_REPRINT_ADD": {
        "key": "WF_DEALER_LICENCE_REPRINT_ADD",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_LICENCE_REPRINT.ADD"
        ]
    },
    "WF_DEALER_LICENCE_REPRINT_EDIT": {
        "key": "WF_DEALER_LICENCE_REPRINT_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_LICENCE_REPRINT.EDIT"
        ]
    },
    "WF_DEALER_CHANGE_SERIAL_NUMBER_ADD": {
        "key": "WF_DEALER_CHANGE_SERIAL_NUMBER_ADD",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_CHANGE_SERIAL_NUMBER.ADD"
        ]
    },
    "WF_DEALER_CHANGE_SERIAL_NUMBER_EDIT": {
        "key": "WF_DEALER_CHANGE_SERIAL_NUMBER_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_CHANGE_SERIAL_NUMBER.EDIT"
        ]
    },
    "WF_DEALERS_LICENCE_REISSUANCE_ADD": {
        "key": "WF_DEALERS_LICENCE_REISSUANCE_ADD",
        "description": "",
        "permissions": [
            "INT.WF_DEALERS_LICENCE_REISSUANCE.ADD"
        ]
    },
    "WF_DEALERS_LICENCE_REISSUANCE_EDIT": {
        "key": "WF_DEALERS_LICENCE_REISSUANCE_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_DEALERS_LICENCE_REISSUANCE.EDIT"
        ]
    },
    "WF_DEALER_ADD_LICENCE_TRANSACTION_ADD": {
        "key": "WF_DEALER_ADD_LICENCE_TRANSACTION_ADD",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_ADD_LICENCE_TRANSACTION.ADD"
        ]
    },
    "WF_DEALER_ADD_LICENCE_TRANSACTION_EDIT": {
        "key": "WF_DEALER_ADD_LICENCE_TRANSACTION_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_ADD_LICENCE_TRANSACTION.EDIT"
        ]
    },
    "WF_DEALER_CANCEL_LICENCE_TRANSACTION_ADD": {
        "key": "WF_DEALER_CANCEL_LICENCE_TRANSACTION_ADD",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_CANCEL_LICENCE_TRANSACTION.ADD"
        ]
    },
    "WF_DEALER_CANCEL_LICENCE_TRANSACTION_EDIT": {
        "key": "WF_DEALER_CANCEL_LICENCE_TRANSACTION_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_DEALER_CANCEL_LICENCE_TRANSACTION.EDIT"
        ]
    },
    "WF_VEHICLE_STATUS_CHANGE_EDIT": {
        "key": "WF_VEHICLE_STATUS_CHANGE_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_VEHICLE_STATUS_CHANGE.EDIT"
        ]
    },
    "WF_VEHICLE_TRANSFER_EDIT": {
        "key": "WF_VEHICLE_TRANSFER_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_VEHICLE_TRANSFER.EDIT"
        ]
    },
    "WF_UPDATE_VEHICLE_EDIT": {
        "key": "WF_UPDATE_VEHICLE_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_UPDATE_VEHICLE.EDIT"
        ]
    },
    "WF_SET_EFFECTIVE_DATE_EDIT": {
        "key": "WF_SET_EFFECTIVE_DATE_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_SET_EFFECTIVE_DATE.EDIT"
        ]
    },
    "WF_REVERSE_LAST_TRANSACTION_EDIT": {
        "key": "WF_REVERSE_LAST_TRANSACTION_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_REVERSE_LAST_TRANSACTION.EDIT"
        ]
    },
    "WF_REQUEST_LICENCE_LICENCE_WITH_ARREARS_EDIT": {
        "key": "WF_REQUEST_LICENCE_LICENCE_WITH_ARREARS_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_REQUEST_LICENCE_LICENCE_WITH_ARREARS.EDIT"
        ]
    },
    "WF_OTHER_ADDITION_DEDUCTION_EDIT": {
        "key": "WF_OTHER_ADDITION_DEDUCTION_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_OTHER_ADDITION_DEDUCTION.EDIT"
        ]
    },
    "WF_LICENCE_WAIVE_OFF_EDIT": {
        "key": "WF_LICENCE_WAIVE_OFF_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_LICENCE_WAIVE_OFF.EDIT"
        ]
    },
    "WF_LICENCE_TYPE_CHANGE_EDIT": {
        "key": "WF_LICENCE_TYPE_CHANGE_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_LICENCE_TYPE_CHANGE.EDIT"
        ]
    },
    "WF_LICENCE_RECEPT_REPRINT_EDIT": {
        "key": "WF_LICENCE_RECEPT_REPRINT_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_LICENCE_RECEPT_REPRINT.EDIT"
        ]
    },
    "WF_ADD_VEHICLE_EDIT": {
        "key": "WF_ADD_VEHICLE_EDIT",
        "description": "",
        "permissions": [
            "INT.WF_ADD_VEHICLE.EDIT"
        ]
    },
    "COUNTER": {
        "key": "COUNTER",
        "description": "",
        "permissions": [
            "INT.COUNTER"
        ]
    },
    "TASK": {
        "key": "TASK",
        "description": "",
        "permissions": [
            "INT.TASK"
        ]
    },
    "COUNTER_SESSION_HISTORY": {
        "key": "COUNTER_SESSION_HISTORY",
        "description": "",
        "permissions": [
            "INT.COUNTER_SESSION_HISTORY"
        ]
    },
    "VEHICLE_DEALER_INFO": {
        "key": "VEHICLE_DEALER_INFO",
        "description": "",
        "permissions": [
            "INT.VEHICLE_DEALER_INFO"
        ]
    },
    "REVENUE_SETTLEMENT": {
        "key": "REVENUE_SETTLEMENT",
        "description": "",
        "permissions": [
            "INT.REVENUE_SETTLEMENT"
        ]
    },
    "RECONCILE_TRANSACTIONS": {
        "key": "RECONCILE_TRANSACTIONS",
        "description": "",
        "permissions": [
            "INT.RECONCILE_TRANSACTIONS"
        ]
    },
    "VIEW_FR01_INCOME_DISTRIBUTION_ENTIRE": {
        "key": "VIEW_FR01_INCOME_DISTRIBUTION_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR01_INCOME_DISTRIBUTION_ENTIRE"
        ]
    },
    "VIEW_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE": {
        "key": "VIEW_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE"
        ]
    },
    "VIEW_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE": {
        "key": "VIEW_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE"
        ]
    },
    "VIEW_FR04_DETAILED_INCOME_REPORT_ENTIRE": {
        "key": "VIEW_FR04_DETAILED_INCOME_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR04_DETAILED_INCOME_REPORT_ENTIRE"
        ]
    },
    "VIEW_FR05_OTHER_INCOME_REPORT_ENTIRE": {
        "key": "VIEW_FR05_OTHER_INCOME_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR05_OTHER_INCOME_REPORT_ENTIRE"
        ]
    },
    "VIEW_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT": {
        "key": "VIEW_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT"
        ]
    },
    "VIEW_FR07_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "VIEW_FR07_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR07_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },
    "VIEW_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "VIEW_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },
    "VIEW_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "VIEW_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },
    "VIEW_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT": {
        "key": "VIEW_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT"
        ]
    },
    "VIEW_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT": {
        "key": "VIEW_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT"
        ]
    },
    "VIEW_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE": {
        "key": "VIEW_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE"
        ]
    },
    "VIEW_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT": {
        "key": "VIEW_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT"
        ]
    },
    "VIEW_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE": {
        "key": "VIEW_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE"
        ]
    },
    "VIEW_FR15_DAILY_AUTHORITY_WISE_SUMMARY": {
        "key": "VIEW_FR15_DAILY_AUTHORITY_WISE_SUMMARY",
        "description": "",
        "permissions": [
            "INT.VIEW_FR15_DAILY_AUTHORITY_WISE_SUMMARY"
        ]
    },
    "VIEW_INCOME_DETAILS_REPORTS": {
        "key":"VIEW_INCOME_DETAILS_REPORTS",
        "description": "",
        "permissions": [
            "INT.VIEW_FR01_INCOME_DISTRIBUTION_ENTIRE",
            "INT.VIEW_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE",
            "INT.VIEW_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE",
            "INT.VIEW_FR04_DETAILED_INCOME_REPORT_ENTIRE",
            "INT.VIEW_FR05_OTHER_INCOME_REPORT_ENTIRE",
            "INT.VIEW_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT",
            "INT.VIEW_FR07_TRANSACTION_SUMMARY_ENTIRE",
            "INT.VIEW_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE",
            "INT.VIEW_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE",
            "INT.VIEW_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT",
            "INT.VIEW_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT",
            "INT.VIEW_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE",
            "INT.VIEW_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT",
            "INT.VIEW_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE",
            "INT.VIEW_FR15_DAILY_AUTHORITY_WISE_SUMMARY"
        ]
    },

    "VIEW_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE": {
        "key": "VIEW_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE"
        ]
    },
    "VIEW_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES": {
        "key": "VIEW_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES",
        "description": "",
        "permissions": [
            "INT.VIEW_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES"
        ]
    },
    "VIEW_FR18_REVERSED_REISSUED_LICENCE_REPORT": {
        "key": "VIEW_FR18_REVERSED_REISSUED_LICENCE_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR18_REVERSED_REISSUED_LICENCE_REPORT"
        ]
    },
    "VIEW_FR19_VEHICLE_LICENCE_HISTORY_REPORT": {
        "key": "VIEW_FR19_VEHICLE_LICENCE_HISTORY_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR19_VEHICLE_LICENCE_HISTORY_REPORT"
        ]
    },
    "VIEW_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT": {
        "key": "VIEW_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT"
        ]
    },
    "VIEW_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT": {
        "key": "VIEW_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT"
        ]
    },
    "VIEW_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT": {
        "key": "VIEW_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT"
        ]
    },
    "VIEW_FR23_NON_LICENCED_VEHICLES_REPORT": {
        "key": "VIEW_FR23_NON_LICENCED_VEHICLES_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR23_NON_LICENCED_VEHICLES_REPORT"
        ]
    },
    "VIEW_FR24_USER_LICENCE_ISSUED_COUNT_REPORT": {
        "key": "VIEW_FR24_USER_LICENCE_ISSUED_COUNT_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR24_USER_LICENCE_ISSUED_COUNT_REPORT"
        ]
    },
    "VIEW_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE": {
        "key": "VIEW_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE"
        ]
    },

    "VIEW_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER": {
        "key": "VIEW_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER",
        "description": "",
        "permissions": [
            "INT.VIEW_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER"
        ]
    },
    "VIEW_LICENCE_DETAILS_REPORTS": {
        "key": "VIEW_LICENCE_DETAILS_REPORTS",
        "description": "",
        "permissions": [
            "INT.VIEW_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE",
            "INT.VIEW_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES",
            "INT.VIEW_FR18_REVERSED_REISSUED_LICENCE_REPORT",
            "INT.VIEW_FR19_VEHICLE_LICENCE_HISTORY_REPORT",
            "INT.VIEW_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT",
            "INT.VIEW_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT",
            "INT.VIEW_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT",
            "INT.VIEW_FR23_NON_LICENCED_VEHICLES_REPORT",
            "INT.VIEW_FR24_USER_LICENCE_ISSUED_COUNT_REPORT",
            "INT.VIEW_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE",
            "INT.VIEW_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER"
        ]
    },

    "VIEW_FR31_ONLINE_LICENCE_REQUEST": {
        "key": "VIEW_FR31_ONLINE_LICENCE_REQUEST",
        "description": "",
        "permissions": [
            "INT.VIEW_FR31_ONLINE_LICENCE_REQUEST"
        ]
    },
    "VIEW_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT": {
        "key": "VIEW_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT"
        ]
    },
    "VIEW_FR33_ONLINE_TRANSACTION_REPORT": {
        "key": "VIEW_FR33_ONLINE_TRANSACTION_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR33_ONLINE_TRANSACTION_REPORT"
        ]
    },
    "VIEW_FR34_PRINTED_WEB_LICENCES_REPORT": {
        "key": "VIEW_FR34_PRINTED_WEB_LICENCES_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR34_PRINTED_WEB_LICENCES_REPORT"
        ]
    },

    "VIEW_ONLINE_LICENCES_REPORT": {
        "key": "VIEW_ONLINE_LICENCES_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR31_ONLINE_LICENCE_REQUEST",
            "INT.VIEW_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT",
            "INT.VIEW_FR33_ONLINE_TRANSACTION_REPORT",
            "INT.VIEW_FR34_PRINTED_WEB_LICENCES_REPORT"
        ]
    },


    "VIEW_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE": {
        "key": "VIEW_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE"
        ]
    },
    "VIEW_FR42_LOSS_REVENUE_REPORT_ENTIRE": {
        "key": "VIEW_FR42_LOSS_REVENUE_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR42_LOSS_REVENUE_REPORT_ENTIRE"
        ]
    },
    "VIEW_FR43_MANUAL_ENTRY_REPORT_ENTIRE": {
        "key": "VIEW_FR43_MANUAL_ENTRY_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR43_MANUAL_ENTRY_REPORT_ENTIRE"
        ]
    },
    "VIEW_FR44_REVENUE_LICENCE_RATES_REPORT": {
        "key": "VIEW_FR44_REVENUE_LICENCE_RATES_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR44_REVENUE_LICENCE_RATES_REPORT"
        ]
    },

    "VIEW_OTHER_REPORT": {
        "key": "VIEW_OTHER_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE",
            "INT.VIEW_FR42_LOSS_REVENUE_REPORT_ENTIRE",
            "INT.VIEW_FR43_MANUAL_ENTRY_REPORT_ENTIRE",
            "INT.VIEW_FR44_REVENUE_LICENCE_RATES_REPORT"
        ]
    },
    

    "": {
        "key": "",
        "description": "",
        "permissions": [
            ""
        ]
    },
    "VIEW_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT": {
        "key": "VIEW_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT"
        ]
    },
    "VIEW_FR36_DISCARDED_SERIAL_NUMBER_REPORT": {
        "key": "VIEW_FR36_DISCARDED_SERIAL_NUMBER_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR36_DISCARDED_SERIAL_NUMBER_REPORT"
        ]
    },
    "VIEW_SERIAL_NUMBER_DETAILS_REPORT": {
        "key": "VIEW_SERIAL_NUMBER_DETAILS_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT",
            "INT.VIEW_FR36_DISCARDED_SERIAL_NUMBER_REPORT"
        ]
    },
    "VIEW_FR37_ACTIVE_USER_REPORT_ENTIRE": {
        "key": "VIEW_FR37_ACTIVE_USER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR37_ACTIVE_USER_REPORT_ENTIRE"
        ]
    },
    "VIEW_FR38_INACTIVE_USER_REPORT_ENTIRE": {
        "key": "VIEW_FR38_INACTIVE_USER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR38_INACTIVE_USER_REPORT_ENTIRE"
        ]
    },

    "VIEW_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE": {
        "key": "VIEW_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE"
        ]
    },

    "VIEW_USER_DETAILS_REPORT_ENTIRE": {
        "key": "VIEW_USER_DETAILS_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.VIEW_FR37_ACTIVE_USER_REPORT_ENTIRE",
            "INT.VIEW_FR38_INACTIVE_USER_REPORT_ENTIRE",
            "INT.VIEW_FR39_USER_ACTIVITY_LOG_REPORT",
            "INT.VIEW_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE",

        ]
    },

    "VIEW_FR27_VEHICLE_TRANSFER_REPORT": {
        "key": "VIEW_FR27_VEHICLE_TRANSFER_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR27_VEHICLE_TRANSFER_REPORT"
        ]
    },

    "VIEW_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT": {
        "key": "VIEW_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT"
        ]
    },
    "": {
        "key": "",
        "description": "",
        "permissions": [
            ""
        ]
    },
    "VIEW_FR29_VEHICLE_COUNT_SUMMARY_REPORT": {
        "key": "VIEW_FR29_VEHICLE_COUNT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR29_VEHICLE_COUNT_SUMMARY_REPORT"
        ]
    },
    "VIEW_FR30_VEHICLE_EDIT_SUMMARY_REPORT": {
        "key": "VIEW_FR30_VEHICLE_EDIT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR30_VEHICLE_EDIT_SUMMARY_REPORT"
        ]
    },
    "VIEW_VEHICLE_DETAILS_REPORTS": {
        "key": "VIEW_VEHICLE_DETAILS_REPORTS",
        "description": "",
        "permissions": [
            "INT.VIEW_FR27_VEHICLE_TRANSFER_REPORT",
            "INT.VIEW_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT",
            "INT.VIEW_FR29_VEHICLE_COUNT_SUMMARY_REPORT",
            "INT.VIEW_FR30_VEHICLE_EDIT_SUMMARY_REPORT"
        ]
    },
    "EXEL_FR15_DAILY_AUTHORITY_WISE_SUMMARY": {
        "key": "EXEL_FR15_DAILY_AUTHORITY_WISE_SUMMARY",
        "description": "",
        "permissions": [
            "INT.EXEL_FR15_DAILY_AUTHORITY_WISE_SUMMARY"
        ]
    },
    "PDF_FR15_DAILY_AUTHORITY_WISE_SUMMARY": {
        "key": "PDF_FR15_DAILY_AUTHORITY_WISE_SUMMARY",
        "description": "",
        "permissions": [
            "INT.PDF_FR15_DAILY_AUTHORITY_WISE_SUMMARY"
        ]
    },

    "EXEL_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT": {
        "key": "EXEL_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT"
        ]
    },
    "PDF_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT": {
        "key": "PDF_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT"
        ]
    },
    "EXEL_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT": {
        "key": "EXEL_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT"
        ]
    },
    "PDF_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT": {
        "key": "PDF_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT"
        ]
    },
    "EXEL_FR04_DETAILED_INCOME_REPORT_ENTIRE": {
        "key": "EXEL_FR04_DETAILED_INCOME_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR04_DETAILED_INCOME_REPORT_ENTIRE"
        ]
    },

    "PDF_FR04_DETAILED_INCOME_REPORT_ENTIRE": {
        "key": "PDF_FR04_DETAILED_INCOME_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR04_DETAILED_INCOME_REPORT_ENTIRE"
        ]
    },
    "EXEL_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE": {
        "key": "EXEL_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE"
        ]
    },

    "PDF_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE": {
        "key": "PDF_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE"
        ]
    },
    "EXEL_FR01_INCOME_DISTRIBUTION_ENTIRE": {
        "key": "EXEL_FR01_INCOME_DISTRIBUTION_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR01_INCOME_DISTRIBUTION_ENTIRE"
        ]
    },

    "PDF_FR01_INCOME_DISTRIBUTION_ENTIRE": {
        "key": "PDF_FR01_INCOME_DISTRIBUTION_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR01_INCOME_DISTRIBUTION_ENTIRE"
        ]
    },
    "EXEL_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE": {
        "key": "EXEL_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE"
        ]
    },

    "PDF_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE": {
        "key": "PDF_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE"
        ]
    },
    "EXEL_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "EXEL_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },

    "PDF_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "PDF_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },
    "EXEL_FR05_OTHER_INCOME_REPORT_ENTIRE": {
        "key": "EXEL_FR05_OTHER_INCOME_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR05_OTHER_INCOME_REPORT_ENTIRE"
        ]
    },

    "PDF_FR05_OTHER_INCOME_REPORT_ENTIRE": {
        "key": "PDF_FR05_OTHER_INCOME_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR05_OTHER_INCOME_REPORT_ENTIRE"
        ]
    },
    "EXEL_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE": {
        "key": "EXEL_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE"
        ]
    },

    "PDF_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE": {
        "key": "PDF_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE"
        ]
    },
    "EXEL_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT": {
        "key": "EXEL_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT"
        ]
    },

    "PDF_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT": {
        "key": "PDF_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT"
        ]
    },
    "EXEL_FR07_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "EXEL_FR07_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR07_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },

    "PDF_FR07_TRANSACTION_SUMMARY_ENTIR": {
        "key": "PDF_FR07_TRANSACTION_SUMMARY_ENTIR",
        "description": "",
        "permissions": [
            "INT.PDF_FR07_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },

    "EXEL_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE": {
        "key": "EXEL_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE"
        ]
    },

    "PDF_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE": {
        "key": "PDF_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE"
        ]
    },
    "EXEL_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT": {
        "key": "EXEL_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT"
        ]
    },

    "PDF_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT": {
        "key": "PDF_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT"
        ]
    },
    "EXEL_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "EXEL_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },

    "PDF_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE": {
        "key": "PDF_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE"
        ]
    },
    "EXEL_FR29_VEHICLE_COUNT_SUMMARY_REPORT": {
        "key": "EXEL_FR29_VEHICLE_COUNT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR29_VEHICLE_COUNT_SUMMARY_REPORT"
        ]
    },

    "PDF_FR29_VEHICLE_COUNT_SUMMARY_REPORT": {
        "key": "PDF_FR29_VEHICLE_COUNT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR29_VEHICLE_COUNT_SUMMARY_REPORT"
        ]
    },
    "EXEL_FR30_VEHICLE_EDIT_SUMMARY_REPORT": {
        "key": "EXEL_FR30_VEHICLE_EDIT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR30_VEHICLE_EDIT_SUMMARY_REPORT"
        ]
    },

    "PDF_FR30_VEHICLE_EDIT_SUMMARY_REPORT": {
        "key": "PDF_FR30_VEHICLE_EDIT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR30_VEHICLE_EDIT_SUMMARY_REPORT"
        ]
    },
    "EXEL_FR27_VEHICLE_TRANSFER_REPORT": {
        "key": "EXEL_FR27_VEHICLE_TRANSFER_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR27_VEHICLE_TRANSFER_REPORT"
        ]
    },

    "PDF_FR27_VEHICLE_TRANSFER_REPORT": {
        "key": "PDF_FR27_VEHICLE_TRANSFER_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR27_VEHICLE_TRANSFER_REPORT"
        ]
    },
    "EXEL_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT": {
        "key": "EXEL_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT"
        ]
    },

    "PDF_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT": {
        "key": "PDF_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT"
        ]
    },
    "EXEL_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT": {
        "key": "EXEL_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT"
        ]
    },
    "PDF_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT": {
        "key": "PDF_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT"
        ]
    },
    "EXEL_FR36_DISCARDED_SERIAL_NUMBER_REPORT": {
        "key": "EXEL_FR36_DISCARDED_SERIAL_NUMBER_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR36_DISCARDED_SERIAL_NUMBER_REPORT"
        ]
    },
    "PDF_FR36_DISCARDED_SERIAL_NUMBER_REPORT": {
        "key": "PDF_FR36_DISCARDED_SERIAL_NUMBER_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR36_DISCARDED_SERIAL_NUMBER_REPORT"
        ]
    },
    "EXEL_FR37_ACTIVE_USER_REPORT_ENTIRE": {
        "key": "EXEL_FR37_ACTIVE_USER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR37_ACTIVE_USER_REPORT_ENTIRE"
        ]
    },

    "PDF_FR37_ACTIVE_USER_REPORT_ENTIRE": {
        "key": "PDF_FR37_ACTIVE_USER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR37_ACTIVE_USER_REPORT_ENTIRE"
        ]
    },
    "EXEL_FR38_INACTIVE_USER_REPORT_ENTIRE": {
        "key": "EXEL_FR38_INACTIVE_USER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR38_INACTIVE_USER_REPORT_ENTIRE"
        ]
    },
    "PDF_FR38_INACTIVE_USER_REPORT_ENTIRE": {
        "key": "PDF_FR38_INACTIVE_USER_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR38_INACTIVE_USER_REPORT_ENTIRE"
        ]
    },
    "EXEL_FR39_USER_ACTIVITY_LOG_REPORT": {
        "key": "EXEL_FR39_USER_ACTIVITY_LOG_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR39_USER_ACTIVITY_LOG_REPORT"
        ]
    },
    "PDF_FR39_USER_ACTIVITY_LOG_REPORT": {
        "key": "PDF_FR39_USER_ACTIVITY_LOG_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR39_USER_ACTIVITY_LOG_REPORT"
        ]
    },
    "VIEW_FR39_USER_ACTIVITY_LOG_REPORT": {
        "key": "VIEW_FR39_USER_ACTIVITY_LOG_REPORT",
        "description": "",
        "permissions": [
            "INT.VIEW_FR39_USER_ACTIVITY_LOG_REPORT"
        ]
    },
    "EXEL_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE": {
        "key": "EXEL_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE"
        ]
    },
    "PDF_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE": {
        "key": "PDF_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE"
        ]
    },
    "EXEL_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE": {
        "key": "EXEL_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE"
        ]
    },
    "PDF_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE": {
        "key": "PDF_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE"
        ]
    },

    "EXEL_FR43_MANUAL_ENTRY_REPORT_ENTIRE": {
        "key": "EXEL_FR43_MANUAL_ENTRY_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR43_MANUAL_ENTRY_REPORT_ENTIRE"
        ]
    },
    "PDF_FR43_MANUAL_ENTRY_REPORT_ENTIRE": {
        "key": "PDF_FR43_MANUAL_ENTRY_REPORT_ENTIRE",
        "description": "",
        "permissions": [
            "INT.PDF_FR43_MANUAL_ENTRY_REPORT_ENTIRE"
        ]
    },
    "EXEL_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT": {
        "key": "EXEL_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT"
        ]
    },

    "PDF_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT": {
        "key": "PDF_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT"
        ]
    },
    "EXEL_FR31_ONLINE_LICENCE_REQUEST": {
        "key": "EXEL_FR31_ONLINE_LICENCE_REQUEST",
        "description": "",
        "permissions": [
            "INT.EXEL_FR31_ONLINE_LICENCE_REQUEST"
        ]
    },
    "PDF_FR31_ONLINE_LICENCE_REQUEST": {
        "key": "PDF_FR31_ONLINE_LICENCE_REQUEST",
        "description": "",
        "permissions": [
            "INT.PDF_FR31_ONLINE_LICENCE_REQUEST"
        ]
    },
    "EXEL_FR33_ONLINE_TRANSACTION_REPORT": {
        "key": "EXEL_FR33_ONLINE_TRANSACTION_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR33_ONLINE_TRANSACTION_REPORT"
        ]
    },
    "PDF_FR33_ONLINE_TRANSACTION_REPORT": {
        "key": "PDF_FR33_ONLINE_TRANSACTION_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR33_ONLINE_TRANSACTION_REPORT"
        ]
    },
    "EXEL_FR34_PRINTED_WEB_LICENCES_REPORT": {
        "key": "EXEL_FR34_PRINTED_WEB_LICENCES_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR34_PRINTED_WEB_LICENCES_REPORT"
        ]
    },
    "PDF_FR34_PRINTED_WEB_LICENCES_REPORT": {
        "key": "PDF_FR34_PRINTED_WEB_LICENCES_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR34_PRINTED_WEB_LICENCES_REPORT"
        ]
    },

    "EXEL_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE": {
        "key": "EXEL_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE",
        "description": "",
        "permissions": [
            "INT.EXEL_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE"
        ]
    },
    "PDF_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE": {
        "key": "PDF_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE",
        "description": "",
        "permissions": [
            "INT.PDF_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE"
        ]
    },
    "EXEL_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER": {
        "key": "EXEL_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER",
        "description": "",
        "permissions": [
            "INT.EXEL_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER"
        ]
    },
    "PDF_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER": {
        "key": "PDF_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER",
        "description": "",
        "permissions": [
            "INT.PDF_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER"
        ]
    },
    "EXEL_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT": {
        "key": "EXEL_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT"
        ]
    },
    "PDF_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT": {
        "key": "PDF_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT"
        ]
    },
    "EXEL_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT": {
        "key": "EXEL_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT"
        ]
    },
    "PDF_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT": {
        "key": "PDF_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT"
        ]
    },
    "EXEL_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT": {
        "key": "EXEL_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT"
        ]
    },
    "PDF_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT": {
        "key": "PDF_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT"
        ]
    },
    "EXEL_FR23_NON_LICENCED_VEHICLES_REPORT": {
        "key": "EXEL_FR23_NON_LICENCED_VEHICLES_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR23_NON_LICENCED_VEHICLES_REPORT"
        ]
    },
    "PDF_FR23_NON_LICENCED_VEHICLES_REPORT": {
        "key": "PDF_FR23_NON_LICENCED_VEHICLES_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR23_NON_LICENCED_VEHICLES_REPORT"
        ]
    },
    "EXEL_FR18_REVERSED_REISSUED_LICENCE_REPORT": {
        "key": "EXEL_FR18_REVERSED_REISSUED_LICENCE_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR18_REVERSED_REISSUED_LICENCE_REPORT"
        ]
    },
    "PDF_FR18_REVERSED_REISSUED_LICENCE_REPORT": {
        "key": "PDF_FR18_REVERSED_REISSUED_LICENCE_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR18_REVERSED_REISSUED_LICENCE_REPORT"
        ]
    },
    "EXEL_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT": {
        "key": "EXEL_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT"
        ]
    },
    "PDF_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE": {
        "key": "PDF_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE",
        "description": "",
        "permissions": [
            "INT.PDF_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE"
        ]
    },
    "EXEL_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES": {
        "key": "EXEL_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES",
        "description": "",
        "permissions": [
            "INT.EXEL_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES"
        ]
    },
    "PDF_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES": {
        "key": "PDF_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES",
        "description": "",
        "permissions": [
            "INT.PDF_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES"
        ]
    },
    "EXEL_FR24_USER_LICENCE_ISSUED_COUNT_REPORT": {
        "key": "EXEL_FR24_USER_LICENCE_ISSUED_COUNT_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR24_USER_LICENCE_ISSUED_COUNT_REPORT"
        ]
    },
    "PDF_FR24_USER_LICENCE_ISSUED_COUNT_REPORT": {
        "key": "PDF_FR24_USER_LICENCE_ISSUED_COUNT_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR24_USER_LICENCE_ISSUED_COUNT_REPORT"
        ]
    },
    "EXEL_FR19_VEHICLE_LICENCE_HISTORY_REPORT": {
        "key": "EXEL_FR19_VEHICLE_LICENCE_HISTORY_REPORT",
        "description": "",
        "permissions": [
            "INT.EXEL_FR19_VEHICLE_LICENCE_HISTORY_REPORT"
        ]
    },
    "PDF_FR19_VEHICLE_LICENCE_HISTORY_REPORT": {
        "key": "PDF_FR19_VEHICLE_LICENCE_HISTORY_REPORT",
        "description": "",
        "permissions": [
            "INT.PDF_FR19_VEHICLE_LICENCE_HISTORY_REPORT"
        ]
    },
    "VIEW_REPORT_MENU": {
        "key": "VIEW_REPORT_MENU",
        "description": "",
        "permissions": [
            "INT.VIEW_FR01_INCOME_DISTRIBUTION_ENTIRE",
            "INT.VIEW_FR02_DETAILED_INCOME_DISTRIBUTION_ENTIRE",
            "INT.VIEW_FR03_INCOME_FROM_OTHER_PROVINCE_ENTIRE",
            "INT.VIEW_FR04_DETAILED_INCOME_REPORT_ENTIRE",
            "INT.VIEW_FR05_OTHER_INCOME_REPORT_ENTIRE",
            "INT.VIEW_FR06_DETAILED_ADDITIONS_DEDUCTIONS_REPORT",
            "INT.VIEW_FR07_TRANSACTION_SUMMARY_ENTIRE",
            "INT.VIEW_FR08_YEARLY_TRANSACTION_SUMMARY_ENTIRE",
            "INT.VIEW_FR09_MONTHLY_TRANSACTION_SUMMARY_ENTIRE",
            "INT.VIEW_FR10_SUMMARY_OF_MOTOR_VEHICLE_REV_COLLEC_REPORT",
            "INT.VIEW_FR11_DAILY_COUNTER_TRANSACTION_SUMMARY_REPORT",
            "INT.VIEW_FR12_VEHICLE_LICENCE_REVENUE_TURNOVER_REPORT_ENTIRE",
            "INT.VIEW_FR13_VEHICLE_REVENUE_LCENCE_COLLECTION_REPORT",
            "INT.VIEW_FR14_SUMMARYOF_ARREARS_PAYMENT_ENTIRE",
            "INT.VIEW_FR15_DAILY_AUTHORITY_WISE_SUMMARY",
            "INT.VIEW_FR16_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE",
            "INT.VIEW_FR17_SUMMARY_OF_VEHICLE_CLASSES_REPORT_LICENCE_ISSUANCE_FROM_OTHER_PROVINCES",
            "INT.VIEW_FR18_REVERSED_REISSUED_LICENCE_REPORT",
            "INT.VIEW_FR19_VEHICLE_LICENCE_HISTORY_REPORT",
            "INT.VIEW_FR20_ISSUED_LICENCE_VEHICLE_CLASS_DETAIL_REPORT",
            "INT.VIEW_FR21_FIRST_LICENCE_ISSUED_VEHICLE_DETAILS_REPORT",
            "INT.VIEW_FR22_ISSUED_LICENCE_COUNT_SUMMARY_REPORT",
            "INT.VIEW_FR23_NON_LICENCED_VEHICLES_REPORT",
            "INT.VIEW_FR24_USER_LICENCE_ISSUED_COUNT_REPORT",
            "INT.VIEW_FR25_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE",
            "INT.VIEW_FR26_ECO_TEST_CERTIFICATE_REPORT_LICENCE_ISSUANCE_FROM_OTHER",
            "INT.VIEW_FR27_VEHICLE_TRANSFER_REPORT",
            "INT.VIEW_FR28_VEHICLE_TRANSFER_VALIDATION_REPORT",
            "INT.VIEW_FR29_VEHICLE_COUNT_SUMMARY_REPORT",
            "INT.VIEW_FR30_VEHICLE_EDIT_SUMMARY_REPORT",
            "INT.VIEW_FR31_ONLINE_LICENCE_REQUEST",
            "INT.VIEW_FR32_ONLINE_LICENCE_RENEWAL_REQUEST_REPORT",
            "INT.VIEW_FR33_ONLINE_TRANSACTION_REPORT",
            "INT.VIEW_FR34_PRINTED_WEB_LICENCES_REPORT",
            "INT.VIEW_FR35_PREPRINTED_SERIAL_NUMBER_SUMMARY_REPORT",
            "INT.VIEW_FR36_DISCARDED_SERIAL_NUMBER_REPORT",
            "INT.VIEW_FR37_ACTIVE_USER_REPORT_ENTIRE",
            "INT.VIEW_FR38_INACTIVE_USER_REPORT_ENTIRE",
            "INT.VIEW_FR39_USER_ACTIVITY_LOG_REPORT",
            "INT.VIEW_FR40_LOGIN_AFTER_OFFICE_HOURS_REPORT_ENTIRE",
            "INT.VIEW_FR41_FITNESS_ISSUANCE_REPORT_ENTIRE",
            "INT.VIEW_FR42_LOSS_REVENUE_REPORT_ENTIRE",
            "INT.VIEW_FR43_MANUAL_ENTRY_REPORT_ENTIRE",
            "INT.VIEW_FR44_REVENUE_LICENCE_RATES_REPORT"
        ]
    },


};
