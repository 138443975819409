/*
 * @Author: Nipuna Chandimal
 * @Date: 2021-01-04 17:01:32
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-09-26 14:03:41
 */
import React, { Fragment, useState,useContext } from 'react';
import { useHistory } from "react-router-dom";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { UICard } from '../../../ui-components/ui-elements/common/BaseElements';
import { counterSessionsListApi } from "../../../../config/apiUrl.config";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import {
  actionBtnTypes,
  DataTable,
  RawIndexMemo,
} from "../../../ui-components/ui-elements/table/DataTable";
import {
  _getInt,
  _get,
} from "../../../../helpers/common-helpers/lodash.wrappers";
import SessionCollection from '../../session-collection/pages/SessionCollection'

import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { thousandSeparator,isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import {CounterSessionSearchCriteria} from "./includes/CounterSessionSearchCriteria";

const CounterSessionHistoryFormKey = "counterSessionHistoryForm";
const CounterSessionHistory = () => {
  return (
    <DashboardTemplate
      pageTitle="Counter Session History"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        {
          displayName: "Counter Session History",
        },
      ]}
    >
      <CounterSessionHistoryElement formGroupName={CounterSessionHistoryFormKey} />
    </DashboardTemplate>
  );
};
const CounterSessionHistoryElement = ({ formGroupName = "paymentSearch" }) => {
  const [state, setState] = useState({
    showSubmitPopup: false,
  });
  let history = useHistory();
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [coreState, coreAction] = useContext(CoreContext);


  let authorityId = _get(authStatus, 'authUser.authorityId', "");
  let loggedInUserId = _get(authStatus, 'authUser.id', "");
  let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
  let isDefault=_get(formState[`${counterSessionsListApi.key}._search`], `authority_id`,false) ? 1:0 
  const serviceId = _get(authStatus, "authUser.service_id", "");
  let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.provinceId', "");

  return (
    <Fragment>
      <UICard>
        <div className="row">
          <div className="col-12 defaultMarginTop">
            <DataTable
              tableName={"View Counter Session History"}
              // apiUrl={`${counterSessionsListApi.url}?role_level_code=${roleLevelCode}&is_default=${isDefault}&sort_by=start_at|desc`}
              apiUrl={`${counterSessionsListApi.url}?pdmt_id=${provinceId}&sort_by=start_at|desc`}
              isSetSearchFrom={true}
              dataTableKey={counterSessionsListApi.key}
              isSetTableFooter={true}
              defaultSearchFormObject={{
                authority_id: roleLevelCode=='INT_AU' ? authorityId :null,
                from: dateObjectToString(new Date()),
                to: dateObjectToString(new Date()),
                counter_id:null,
                user_id:null,
              }}
              onSetSearchObjectFn={(searchObject) => {
                let tempSearchObject = {...searchObject};
                if (!isEmptyValue(searchObject.from)) {
                  tempSearchObject["from"] = dateObjectToString(new Date(searchObject.from));
                }
    
                if (!isEmptyValue(searchObject.to)) {
                  let tempDate=new Date(searchObject.to)
                  tempDate=tempDate.setDate(tempDate.getDate() + 1);
                  
                  tempSearchObject["to"] = dateObjectToString(new Date(tempDate));
                }
    
                
                return tempSearchObject;
            }}
              searchFromComponent={<CounterSessionSearchCriteria  dataTableKey={counterSessionsListApi.key} />}
              isSetAction={true}
              isSetTableHeader={true}
              tableHeaderList={[
                { displayName: "Date", key: "start_at", isSort: false,
                  onPrintFn: (rawData)=>{return (dateObjectToString(rawData.start_at,'dd/mm/YY'))}
                },
                { displayName: "Authority", key: "authority_name", isSort: false, }, // name not there
                { displayName: "Counter", key: "counter_id.name", isSort: false },
                { displayName: "User", key: "user_by_name", isSort: false,
                  onPrintFn: (rawData)=>{
                    return (rawData.role_name ?(rawData.user_by_name + ' ('+ rawData.role_name+')'):rawData.user_by_name)
                  } 
                },
                { displayName: "Session", key: "session", isSort: false ,
                  onPrintFn: (rawData)=>{
                    return (dateObjectToString(rawData.start_at,'HH:MM') +' - ' +dateObjectToString(rawData.end_at,'HH:MM'))
                  }
                },
                { displayName: "Issued Serial Number Count", key: "issuedSerialNumberCount", isSort: false ,
                  onPrintFn: (rawData)=>{ return (_get(rawData, 'serial_number_count', 0))}
                },
                { displayName: "Issued Receipt Number Count", key: "issuedReciptNumberCount", isSort: false,
                  onPrintFn: (rawData)=>{ return (_get(rawData, 'receipt_count', 0))}
                },
                { displayName: "Total Cash (LKR)", key: "cash_amount", isSort: false, isRightAlign: true,
                  onPrintFn: (rawData)=>{ return thousandSeparator(+_get(rawData,'cash_amount', 0), true)} 
                },
                { displayName: "Total Credit Card (LKR)", key: "card_amount", isSort: false, isRightAlign: true,
                  onPrintFn: (rawData)=>{ return thousandSeparator(+_get(rawData,'card_amount',0), true)} 
                },
                { displayName: "Total Cheque (LKR)", key: "cheque_amount", isSort: false, isRightAlign: true,
                  onPrintFn: (rawData)=>{ return thousandSeparator(+_get(rawData,'cheque_amount',0), true)} 
                },
                { displayName: "Total Amount (LKR)", key: "totalAmount", isSort: false, isRightAlign: true,
                  onPrintFn: (rawData)=>{ return thousandSeparator(+_get(rawData,'cash_amount',0) + _get(rawData,'cheque_amount',0) + _get(rawData,'card_amount',0), true)} 
                },
              ]}
              specificComponents={{
                "__no__": RowNo
              }}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.view,
                  actionBtn: {
                    icon: "fa fa-barcode",
                    tooltip: 'View Session Transactions'
                  },    
                  modelContentElement: SessionCollection,
                  dialogPopupProps: {
                    "isFullScreen": true,
                    "dialogTitle": "View Session Transactions",
                    "isCustomButton": false,
                    "popupType": "void",
                  },
                  parentToModelProps: {
                    formKey: formGroupName + "_viewSessionTransactions",
                    showCounterSummary:false 
                  }
                }]}
            />
          </div>
        </div>
      </UICard>
    </Fragment >
  )
};
const RowNo = (props) => {
  return (
    <RawIndexMemo
      page_size={_getInt(props.pagingObject, "per_page", 1)}
      current_page={_getInt(props.pagingObject, "current_page", 1)}
      index={props.rowIndex || 0}
    />
  );
};

export { CounterSessionHistory }