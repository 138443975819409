import React, { useContext } from "react";
import { Base64 } from "js-base64";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  DatePickerWithState,
  YearPickerWithState,
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form/InputBox";
import {
  fuelListAPI,
  provincesOfficesListAPI,
  vehicleClassListAPI,
  vehicleConditionListAPI,
} from "../../../../../config/apiUrl.config";
import { _get, _getFloat, _getInt, _set } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { ProvincesCodeMatcher } from "../../../../../config/enum.config";
import { isEmpty } from "lodash";
const today = new Date();
const min_date = new Date(new Date().setHours(0,0,0));
const max_date = new Date(new Date().setHours(23,59,0));

const VehicleInformationDisplay = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = props.formKey;
  const url = props.url;
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.data.vehicle_no",
    null
  );
  const disabled =
    formKey === "update-vehicle-current" || formKey === "update-vehicle-dmt" || formKey === 'Update_Vehicle-current' || formKey === 'Update_Vehicle-dmt'
      ? true
      : false;
  const requ_id = _get(props, "requestId", null);
  let date=new Date();
  let year = date.getFullYear();
  const isProvincial = props.uiType === 'APR' ? true : false
  return (
    <FormWrapper
      setGroupName={formKey}
      apiUrl={`${url}/${props.uiType === 'APR' ? props.approvalRequestId : vehicle_no}`}
      onRebuildResponseFn={(response) => {
        console.log('TR------>', response);
        const colors = _get(response, "colors", null) !== null ? ((_get(response, "colors", '')).replace(' ', '')).split(",") : ["","",""];
        const archived = props.uiType === 'APR' ? (props.dataType !== 'new' ? (response.vehicle.vehicle_status_code == 'ACH' ? "1" : "0") : (response.archived)) : (response.vehicle_status_code == 'ACH' ? "1" : "0");
        formAction.changeInputFn(formKey, "chassis_no", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.chassis_no", "") !== null ? Base64.decode(_get(response, "vehicle.chassis_no", "")) : '') : (_get(response, "chassis_no", "") !== null ? Base64.decode(_get(response, "chassis_no", "")) : '')) : (_get(response, "chassis_no", "") !== null ? Base64.decode(_get(response, "chassis_no", "")) : ''))
        formAction.changeInputFn(formKey, "engine_no", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.engine_no", "") !== null ? Base64.decode(_get(response, "vehicle.engine_no", "")) : '') : (_get(response, "engine_no", "") !== null ? Base64.decode(_get(response, "engine_no", "")) : '')) : (_get(response, "engine_no", "") !== null ? Base64.decode(_get(response, "engine_no", "")) : '')        )
        formAction.changeInputFn(formKey, "first_reg_date", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? _get(response, "vehicle.first_reg_date", "") : _get(response, "first_reg_date", "")) : _get(response, "first_reg_date", "")        )
        formAction.changeInputFn(formKey, "tare_weight", "", _get(response, "tare_weight", "0.00"))
        formAction.changeInputFn(formKey, "gross_weight", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.gross_weight", "0.00") !== null ? _get(response, "vehicle.gross_weight", "0.00") : '0.00') : (_get(response, "gross_weight", "0.00") !== null ? _get(response, "gross_weight", "0.00") : '0.00')) : (_get(response, "gross_weight", "0.00") !== null ? _get(response, "gross_weight", "0.00") : '0.00')        )
        formAction.changeInputFn(formKey, "no_of_seats", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.no_of_seats", "") !== null ? _get(response, "vehicle.no_of_seats", "") : '') : (_get(response, "no_of_seats", "") !== null ? _get(response, "no_of_seats", "") : '')) : (_get(response, "no_of_seats", "") !== null ? _get(response, "no_of_seats", "") : '')        )
        formAction.changeInputFn(formKey, "vehicle_reg_condition", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.vehicle_reg_condition", "") !== null ? _get(response, "vehicle.vehicle_reg_condition", "") : '') : (_get(response, "vehicle_reg_condition.id", "") !== null ? _get(response, "vehicle_reg_condition.id", "") : '')) : (_get(response, "vehicle_reg_condition", "") !== null ? _get(response, "vehicle_reg_condition.id", "") : '')        )
        formAction.changeInputFn(formKey, "fuel_type", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.fuel_type.id", "") !== null ? _get(response, "vehicle.fuel_type.id", "") : '') : (_get(response, "fuel_type.id", "") !== null ? _get(response, "fuel_type.id", "") : '')) : (_get(response, "fuel_type.id", "") !== null ? _get(response, "fuel_type.id", "") : '')        )
        formAction.changeInputFn(formKey, "vehicle_class", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.vehicle_class.id", "") !== null ? _get(response, "vehicle.vehicle_class.id", "") : '') : (_get(response, "vehicle_class.id", "") !== null ? _get(response, "vehicle_class.id", "") : '')) : (_get(response, "vehicle_class.id", "") !== null ? _get(response, "vehicle_class.id", "") : '')        )
        formAction.changeInputFn(formKey, "province", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? ProvincesCodeMatcher[`${_get(response, "vehicle.province_id", "")}`] : ProvincesCodeMatcher[`${_get(response, "province", "")}`]) : ProvincesCodeMatcher[`${_get(response, "province_id", "")}`]        )
        formAction.changeInputFn(formKey, "manufactured_year", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.yom", "")).toString() : (_get(response, "manufactured_year", "")).toString()) : (_get(response, "yom", "")).toString()        )
        formAction.changeInputFn(formKey, "cylinder_capacity", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.cylinder_capacity", "") !== null ? _get(response, "vehicle.cylinder_capacity", "") : '') : (_get(response, "cylinder_capacity", "") !== null ? _get(response, "cylinder_capacity", "") : '')) : (_get(response, "cylinder_capacity", null) !== null || _getFloat(response, "cylinder_capacity", "") !== null ? _getFloat(response, "cylinder_capacity", "") : '')        )
        formAction.changeInputFn(formKey, "tyre_size", "", props.uiType === 'APR' ? 
        (props.dataType !== 'new' ? 
        (_get(response, "vehicle.tyre_size", null) !== null ?
         _get(response, "vehicle.tyre_size", "") : '')
          : 
          (_get(response, "tyre_size", null) !== null ? _get(response, "tyre_size", "") : '')) : _get(response, "tyre_size", null) !== null ? _get(response, "tyre_size", "") : '')

        formAction.changeInputFn(formKey, "wheel_base", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (!isEmpty(_get(response, "vehicle.wheel_base", ""))  ? _get(response, "vehicle.wheel_base", "0.00") : '0.00') : (!isEmpty(_get(response, "wheel_base", ""))  ? _get(response, "wheel_base", "0.00") : '0.00')) : (_get(response, "wheel_base", "0.00"))        )
        formAction.changeInputFn(formKey, "type_of_body", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.type_of_body", "") !== null ? _get(response, "vehicle.type_of_body", "") : '') : (_get(response, "type_of_body", "") !== null ? _get(response, "type_of_body", "") : '')) : (_get(response, "type_of_body", "") !== null ? _get(response, "type_of_body", "") : '')        )
        formAction.changeInputFn(formKey, "make", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.make", "") !== null ? _get(response, "vehicle.make", "") : '') : (_get(response, "make", "") !== null ? _get(response, "make", "") : '')) : (_get(response, "make", "") !== null ? _get(response, "make", "") : '')        )
        formAction.changeInputFn(formKey, "model", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.model", "") !== null ? _get(response, "vehicle.model", "") : '') : (_get(response, "model", "") !== null ? _get(response, "model", "") : '')) : (_get(response, "model", "") !== null ? _get(response, "model", "") : '')        )
        formAction.changeInputFn(formKey, "color_1", "", props.uiType === 'APR' ? (props.dataType !== 'new' ?( _get(response, "vehicle.colors", "-,-,-").split(',')[0]!=="null"? _get(response, "vehicle.colors", "-,-,-").split(',')[0]:"") : _get(response, "color_1", "")) :(colors[0] !=="null"?colors[0]:"")        )
        formAction.changeInputFn(formKey, "color_2", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.colors", "-,-,-").split(',')[1]!=="null"?_get(response, "vehicle.colors", "-,-,-").split(',')[1]:"") : _get(response, "color_2", "")) :(colors[1] !=="null"?colors[1]:"")        )
        formAction.changeInputFn(formKey, "color_3", "", props.uiType === 'APR' ? (props.dataType !== 'new' ? (_get(response, "vehicle.colors", "-,-,-").split(',')[2]!=="null"?_get(response, "vehicle.colors", "-,-,-").split(',')[2]:"") : _get(response, "color_3", "")) : (colors[2] !=="null"?colors[2]:"")        )
        formAction.changeInputFn(formKey, "archived", "", archived)
        formAction.changeInputFn(formKey, "effective_date", "", _get(response, "effective_date", new Date()))
      }}
      setFormObject={{
        request_id: _set(formState, `${formKey}.request_id`, requ_id),
        chassis_no: _get(formState, `${formKey}.chassis_no`, ""),
        engine_no: _get(formState, `${formKey}.engine_no`, ""),
        first_reg_date: _get(formState, `${formKey}.first_reg_date`, null),
        tare_weight: _get(formState, `${formKey}.tare_weight`, '0.00'),
        gross_weight: _get(formState, `${formKey}.gross_weight`, '0.00'),
        no_of_seats: _get(formState, `${formKey}.no_of_seats`, null),
        vehicle_reg_condition: _get(
          formState,
          `${formKey}.vehicle_reg_condition`,
          null
        ),
        fuel_type: _get(formState, `${formKey}.fuel_type.prefix`, null),
        vehicle_class: _get(formState, `${formKey}.vehicle_class.prefix`, null),
        province: _get(formState, `${formKey}.province`, null),
        manufactured_year: _get(
          formState,
          `${formKey}.manufactured_year`,
          null
        ),
        cylinder_capacity: _get(
          formState,
          `${formKey}.cylinder_capacity`,
          '0.00'
        ),
        tyre_size: _get(formState, `${formKey}.tyre_size`, ''),
        wheel_base: _getInt(formState, `${formKey}.wheel_base`, '0.00'),
        type_of_body: _get(formState, `${formKey}.type_of_body`, "") !== null ? _get(formState, `${formKey}.type_of_body`, "") : "",
        make: _get(formState, `${formKey}.make`, ""),
        model: _get(formState, `${formKey}.model`, ""),
        color_1: _get(formState, `${formKey}.color_1`, ''),
        color_2: _get(formState, `${formKey}.color_2`, ''),
        color_3: _get(formState, `${formKey}.color_3`, ''),
      }}
    >
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"chassis_no"}
            maxLength={20}
            isDisabled={disabled}
            isRequired={isProvincial}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"engine_no"}
            maxLength={20}
            isRequired={isProvincial}
            isDisabled={disabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <DatePickerWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"first_reg_date"}
            maxDate={max_date}
            labelText="   "
            minDate={"1970/01/01"}
            isRequired={isProvincial}
            isDisabled={disabled}
          />

        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"tare_weight"}
            isThousandSeparator={false}
            decimalScale={2}
            isAllowedFn={(value) => {
              let checkValue = value.value.toString();

              if (checkValue.includes(".")) {
                checkValue = checkValue.split(".")[0];
              }

              return checkValue.length <= 10;
            }}
            isDisabled={disabled}
            isAllowNegative={false}
            isRequired={isProvincial}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"gross_weight"}
            isThousandSeparator={false}
            decimalScale={2}
            isAllowedFn={(value) => {
              let checkValue = value.value.toString();

              if (checkValue.includes(".")) {
                checkValue = checkValue.split(".")[0];
              }

              return checkValue.length <= 10;
            }}
            isDisabled={disabled}
            isAllowNegative={false}
            isRequired={isProvincial}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={isProvincial}
            inputName={"no_of_seats"}
            labelText="Seats (Exp. Driver)"
            isThousandSeparator={false}
            isAllowNegative={false}
            decimalScale={0}
            isDisabled={disabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={false}
            apiUrl={`${vehicleConditionListAPI.url}?sort_by=name%7Casc&active=true`}
            apiStateKey={vehicleConditionListAPI.key}
            keyName={"id"}
            valueName={"name"}
            inputName={"vehicle_reg_condition"}
            isDisabled={disabled}
            emptySelectOptionTxt={"Select Vehicle Status"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            isRequired={isProvincial}
            formGroupName={formKey}
            inputName={"fuel_type"}
            apiUrl={`${fuelListAPI.url}?sort_by=name%7Casc&active=true`}
            apiStateKey={fuelListAPI.key}
            keyName={"id"}
            valueName={"name"}
            isDisabled={disabled}
            emptySelectOptionTxt={"Select Fuel Type"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={isProvincial}
            inputName={"vehicle_class"}
            apiUrl={`${vehicleClassListAPI.url}?sort_by=name%7Casc&active=true`}
            apiStateKey={vehicleClassListAPI.key}
            keyName={"id"}
            valueName={"name"}
            isDisabled={disabled}
            emptySelectOptionTxt={"Select Class"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={isProvincial}
            inputName={"province"}
            apiUrl={`${provincesOfficesListAPI.url}?sort_by=service.name%7Casc&active=true`}
            apiStateKey={provincesOfficesListAPI.key}
            emptySelectOptionTxt={"Select Province Office"}
            keyName={"prefix"}
            valueName={"service.name"}
            isDisabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 defaultPaddingTop">
          <YearPickerWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"manufactured_year"}
            isRequired={false}
            isDisabled={disabled}
            format={"yyyy"}
            maxDate={date}
            minDate={"1970"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"cylinder_capacity"}
            isThousandSeparator={false}
            decimalScale={2}
            isAllowedFn={(value) => {
              let checkValue = value.value.toString();
              if(value.value === null) return '0'

              if (checkValue.includes(".")) {
                checkValue = checkValue.split(".")[0];
              }

              return checkValue.length <= 10;
            }}
            isDisabled={disabled}
            isAllowNegative={false}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
        <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"tyre_size"}
            maxLength={50}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"wheel_base"}
            isThousandSeparator={false}
            decimalScale={2}
            isAllowedFn={(value) => {
              let checkValue = value.value.toString();

              if (checkValue.includes(".")) {
                checkValue = checkValue.split(".")[0];
              }

              return checkValue.length <= 10;
            }}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"type_of_body"}
            maxLength={50}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"make"}
            maxLength={20}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"model"}
            maxLength={20}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"color_1"}
            maxLength={20}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"color_2"}
            maxLength={20}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"color_3"}
            maxLength={20}
            isDisabled={disabled}
            isRequired={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 ">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            isRequired={false}
            formGroupName={formKey}
            inputName={"archived"}
            emptySelectOptionTxt={null}
            dataList={[
              {
                id: "1",
                value: "Yes",
              },
              {
                id: "0",
                value: "No",
              }
            ]}
            keyName={"id"}
            valueName={"value"}
            isDisabled={disabled}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export { VehicleInformationDisplay };
