import React, {useContext} from "react";
import { reasonListAPI } from "../../../../../config/apiUrl.config";

import { templateTypes } from "../../../../../config/template.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  SelectBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { InputLabelWrapper } from "../../../../ui-components/ui-elements/form/form-includes/FormCoreIncludes";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const Reason = (props) => {
  const formKey = props.formKey;
  const requestType = props.requestType;
  const isHalfWidth = props.isHalfWidth;
  const filterKey = props.filterKey

  const [formState, formAction] = useContext(FormContext);
  console.log('<===>', formState);
  console.log('jjjjjjjjjjjjjjjjjjjjj', props);

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <UICard>
          <FormWrapper
           setGroupName={formKey}
           setFormObject={{
            reason_id: requestType === 'APR' ? props.dataSubmittedForApproval.reason_id : props.isViewApproval && props.row.reason_id,
          }}
          onDestroyUnsetFormObject={false}
           >
            <div className="row">
              <div className={isHalfWidth ? "col-md-2 requestReasonLabel" : "col-md-1 requestReasonLabel"}>
                <InputLabelWrapper
                  lableText="Reason"
                  isRequired={props.isViewApproval ? false : true}
                />
              </div>
              <div className={isHalfWidth ? "col-md-6" : "col-md-3"}>
                <SelectBoxWithState
                  apiUrl={`${reasonListAPI.url}?${filterKey}=${true}`}
                  formGroupName={formKey}
                  emptySelectOptionTxt={"Select Reason"}
                  keyName={"id"}
                  valueName={"name"}
                  // inputValue={requestType && props.dataSubmittedForApproval.reason_id.reason_name}
                  apiStateKey={reasonListAPI.key}
                  isRequired={false}
                  inputName={"reason_id"}
                  labelText=""
                  isDisabled={requestType === 'APR' || props.isViewApproval ? true : false}
                />
              </div>
            </div>
          </FormWrapper>
        </UICard>
      </div>
    </div>
  );
};

export { Reason };
