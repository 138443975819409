/*
 * @Author: Gajani Kangesan
 * @Date: 2022-07-20 11:23:54
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-09-29 01:23:16
 */
import { isEmpty } from "lodash";
import React, { useContext, useEffect } from "react";
import { getLocationNameAPI, AllLicencedVehicleAPI, getDealerTransactionDetailsAPI, getUsernameAPI } from "../../../../../config/apiUrl.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getUsernamenLocationFn } from "../../../../../helpers/common-helpers/getUsernamenLocation.helpers";
import { _get, _getFloat } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import {
  actionBtnTypes,
  DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { PrintHistory } from "./PrintHistory"
import { pdmtPaymentCategories, ProvincesPrefixMatcher } from "../../../../../config/enum.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { setThousandSeparator } from "../web-licence/WebLicence";

const ViewDealerTransactionDetail = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = props.fromParent.formKey;
  const uiType = props.fromParent.uiType;
  const licenceType = props.fromParent.licenceType;
  let filter;

  filter = `${_get(props, 'row.id')}`;
  useEffect(() => {
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.reversed_created_by`, null) }, "user", "requested_user_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.reversed_created_location`, null) }, "location", "requested_location_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.reversed_approved_by`, null) }, "user", "approved_user_name", formAction, formKey)
    getUsernamenLocationFn({ value: _get(formState, `${formKey}.reversed_approved_location`, null) }, "location", "approved_location_name", formAction, formKey)
  }, [_get(formState, `${formKey}.reversed_created_by`, ''), _get(formState, `${formKey}.reversed_created_location`, ''), _get(formState, `${formKey}.reversed_approved_by`, ''), _get(formState, `${formKey}.reversed_approved_location`, '')]);
  return (
    <FormWrapper
      setGroupName={formKey}
      apiUrl={`${getDealerTransactionDetailsAPI.url}/${filter}`}
      onRebuildResponseFn={(response) => {
        return {
          LicencesAdditionDeductionSummery: _get(response, "LicencesAdditionDeductionSummery", null),
          created_role_id: _get(response, "completedTxn[0].created_role_id", null),
          canceled: _get(response, "completedTxn[0].canceled", ""),
          paymentTypes: _get(response, "paymentTypes", null),
          paymentStatus: _get(response, "payment[0].status", null),
          vehicles: _get(response, "vehicles", null),
          payment: _get(response, "paymentsDetails", null),
          licences: _get(response, "licences", null),
          reversedTxn: _get(response, "reversedTxn", null),
          reversedRequest: _get(response, "reversedRequest", null),
          completedTxn: _get(response, "completedTxn[0]", null),
          performed_by: _get(response, "completedTxn[0].created_by", null),
          created_role: _get(response, "completedTxn[0].created_role_id", null),
          tx_created_at: _get(response, "completedTxn[0].created_at", null),
          created_location: _get(response, "completedTxn[0].created_location", null),
          created_province: _get(response, "completedTxn[0].created_province", null),
          fee: _get(response, "paymentDetails.feeAmount", ""),
          fine: _getFloat(response, "paymentDetails.fineAmount", ""),
          serviceCharge: _getFloat(response, "paymentDetails.serviceChargeAmount", ""),
          additions: _getFloat(response, "paymentDetails.additionAmount", ""),
          deductions: _getFloat(response, "paymentDetails.deductionAmount", ""),
          postalFee: _getFloat(response, "paymentDetails.deliveryAmount", ""),
          total: _getFloat(response, "paymentDetails.totalAmount", ""),
          reversed_request_id: _get(response, "reversed_txn[0].reversed_request_id", null),
          reversed_approved_by: _get(response, "reversed_request[0].approved_by", null),
          ui_request_id: _get(response, "reversed_request[0].ui_request_id", null),
          reversed_approved_at: _get(response, "reversed_request[0].approved_at", null),
          reversed_approved_location: _get(response, "reversed_request[0].approved_location", null),
          reversed_created_at: _get(response, "reversed_request[0].created_at", null),
          reversed_created_by: _get(response, "reversed_request[0].created_by", null),
          status: _get(response, "completedTxn[0].status", null),
          reversed_created_location: _get(response, "reversed_request[0].created_location", null),
          _onLoad: true,
        };
      }}
    >
      <div className="fullWidthDiv">
        <div className="row">
          <div className="col-md-12">
            <div className="row">

              <div className="col-md-6">
                <strong>Payment Status: </strong>{_get(formState,`${formKey}.canceled`, 0) === 1 ? "Cancelled":"Success"}
              </div>

              <div className="col-md-6">
                <strong>Payment Date & Time: </strong> {dateObjectToString(_get(formState, `${formKey}.tx_created_at`, null), 'dd/mm/YY HH:MM')}
              </div>

              <div className="col-md-6">
                <strong>Performed By: </strong> {_get(formState, `${formKey}.performed_by`, null)}{isEmpty(_get(formState, `${formKey}.created_role`, null))?"":`(${_get(formState, `${formKey}.created_role`, null)})`}
              </div>



              <div className="col-md-6">
                <strong>Performed At: </strong>{ProvincesPrefixMatcher[_get(formState, `${formKey}.created_province`, null)]}{isEmpty(_get(formState, `${formKey}.created_location`, null))?"":`(${_get(formState, `${formKey}.created_location`, null)})`}
              </div>
            </div>
          </div>



          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
                <DataTable
                  isSetAction={true}
                  customActionHeader={"Print History"}
                  apiUrl={`${AllLicencedVehicleAPI.url}/${_get(formState, `${formKey}.vehicles`, null)}`}
                  dataTableKey={AllLicencedVehicleAPI.key}
                  tableHeaderList={[
                    { displayName: "Authorized Vehicle No.", key: "vehicle_number", isSort: true },
                    { displayName: "Lic. No.", key: "licence_number", isSort: false },
                    { displayName: "Serial No.", key: "serial_number", isSort: false },
                    { displayName: "Validity Period (From -To)", key: "valid_date", isSort: false, onPrintFn: ({ validity_from, validity_to }) => (isEmpty(validity_from) ? "-" : dateObjectToString(validity_from, "dd/mm/YY")) + "-" + (isEmpty(validity_to) ? "-" : dateObjectToString(validity_to, "dd/mm/YY")) },
                    { displayName: "Insurance Company", key: "insurance_company.service.name", default: "-", isSort: false },
                    {
                      displayName: "Insurance Expiry Date",
                      key: "insurance_expire_date",
                      isSort: false,
                      onPrintFn: (rawData) => {
                        if (rawData.insurance_expire_date!= null) {
                          return (dateObjectToString(rawData.insurance_expire_date, "dd/mm/YY"))
                        }
                        else {
                          return ("-")
                        }
                      },

                    },

                  ]}
                  isSetTableFooter={false}
                  isSetTableHeader={false}
                  actionTypeList={[
                    {
                      actionType: actionBtnTypes.view,
                      modelContentElement: PrintHistory,
                      dialogPopupProps: {
                        isFullScreen: false,
                        isCloseButton: true,
                        dialogTitle: "Print History",
                        isCustomButton: false,
                        closeButton: {
                          btnText: "Close",
                          startIcon: "far fa-times-circle",
                        },
                      },
                      parentToModelProps: {
                        licenceType: licenceType,
                        formKey: formKey,
                      }
                    }
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 defaultMarginTop">
            <div className="row">
              <div className="col-md-12">
                <h1 className="defaultHeading defaultHalfMarginBottom">
                  Payment Details
                </h1>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <table className="fullWidthDiv">
                      <tr>
                        <th>Fee (LKR): </th>
                        <td className={"text-right"}>{setThousandSeparator(_get(formState, `${formKey}.fee`, '0.00'), 2)}</td>
                      </tr>
                      <tr>
                        <th>Fine (LKR):</th>
                        <td className={"text-right"}>{setThousandSeparator(_get(formState, `${formKey}.fine`, '0.00'), 2)}</td>
                      </tr>
                      <tr>
                        <th>Delivery Fee (LKR):</th>
                        <td className={"text-right"}>{setThousandSeparator(_get(formState, `${formKey}.postalFee`, '0.00'), 2)}</td>
                      </tr>
                      <tr>
                        <th>Additions (LKR):</th>
                        <td className={"text-right"}>{_get(formState, `${formKey}.additions`, 0)===0 ?"0.00":""}</td>
                      </tr>
                      {_get(formState, `${formKey}.LicencesAdditionDeductionSummery`, []).map((item) => (item.charge_type_code === "CLAD") &&
                        <tr>
                          <th>{item.name}:</th>
                          <td className={"text-right"}>{setThousandSeparator(Math.abs(item.amount), 2)}</td>
                        </tr>
                      )}
                      <tr>
                        <th>Deductions (LKR):</th>
                        <td className={"text-right"}>{_get(formState, `${formKey}.deductions`, 0)===0 ?("0.00"):""}</td>
                      </tr>
                      {_get(formState, `${formKey}.LicencesAdditionDeductionSummery`, []).map((item) => (item.charge_type_code === "CLDE") &&
                        <tr>
                          <th>{item.name}:</th>
                          <td className={"text-right"}>{`(${setThousandSeparator(Math.abs(item.amount), 2)})`}</td>
                        </tr>
                      )}
                      <tr>
                        <th>Total (LKR):</th>
                        <td className={"text-right"}>{setThousandSeparator(_get(formState, `${formKey}.total`, '0.00'), 2)}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <DataTable
                      isSetAction={false}
                      // elementWrapperStyle={""}
                      tableHeaderList={[
                        {
                          displayName: "Payment Type", key: "payment_type_code", isSort: false,
                          onPrintFn: (rawData) => pdmtPaymentCategories[rawData.payment_type_code]
                        },
                        { displayName: "Amount", key: "amount", isSort: false }
                      ]}
                      tableBodyList={_get(formState, `${formKey}.paymentTypes`, [])}
                      isSetTableFooter={false}
                      isSetTableHeader={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>


          {uiType != 'web-licence' && _get(formState, `${formKey}.reversedRequest`, null) ?
            <div className="col-md-12 defaultMarginTop">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="defaultHeading defaultHalfMarginBottom">
                    Reversed Transaction Details
                  </h1>

                  <div className="row">
                    <div className="col-md-6">
                      <strong>Txn. Reversed Request ID: </strong>{_get(formState, `${formKey}.ui_request_id`, '-')}
                    </div>

                    <div className="col-md-6 d-flex flex-row">
                      <strong>Txn. Reversed Requested:&nbsp; <br />
                        Date & Time  </strong> <span>{_get(formState, `${formKey}.reversed_created_at`, null) === null ? "-" : dateObjectToString(_get(formState, `${formKey}.reversed_created_at`, null), 'dd/mm/YY HH:MM')}</span>
                    </div>

                    <div className="col-md-6">
                      <strong>Requested By: </strong> {_get(formState, `${formKey}.requested_user_name`, '-')}
                    </div>

                    <div className="col-md-6">
                      <strong>Requested Location: </strong> {_get(formState, `${formKey}.requested_location_name`, '-')}
                    </div>

                    <div className="col-md-6">
                      <strong>Reversal Approved Date & Time: </strong> {_get(formState, `${formKey}.reversed_approved_at`, null) === null ? "-" : dateObjectToString(_get(formState, `${formKey}.reversed_approved_at`, null), 'dd/mm/YY HH:MM')}
                    </div>

                    <div className="col-md-6">
                      <strong>Approved By: </strong>{_get(formState, `${formKey}.approved_user_name`, '-')}
                    </div>

                    <div className="col-md-6">
                      <strong>Approved Location: </strong> {_get(formState, `${formKey}.approved_location_name`, '-')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : ''
          }
        </div>
      </div>
    </FormWrapper>
  );
};

const getValue = async (apiType = "user", id = "") => {
  let valueName;
  if (!isEmpty(id)) {
    const val = await getDetails(apiType, id);
    if (val.status === true) {
      valueName = val.data;
    }
  }
  return valueName;
}

const getDetails = (apiType = "user", id = "") => {

  return new Promise(resolve => {
    const api = apiType === "user" ? getUsernameAPI.url : getLocationNameAPI.url;

    callApi(`${api}${id}`)
      .isMultipart(false)
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (!error) {
          const keyName = apiType === "user" ? "username" : "location_name";

          resolve({
            status: true,
            data: _get(result, `data.data[${keyName}]`, "")
          });
        } else {
          resolve({
            status: false
          });
        }
      });
  })
};
export { ViewDealerTransactionDetail };
