/*
 * @Author: Pradeepa Sandaruwan
 * @Date: 2020-09-22 16:06:43
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-06-10 18:49:37
 */
import React, { useState, useContext } from "react";

import { PaddingLessUICard } from "../../../ui-components/ui-elements/common/BaseElements.js";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { InputButton, FormWrapper } from "../../../ui-components/ui-elements/form";
import { VehicleRevenueLicence } from "./includes/VehicleRevenueLicence.js";
import { AmountBreakdown } from "./includes/AmountBreakdown.js";
import { PaymentType } from "./includes/PaymentType.js";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup.js";
import { SerialNumberWrapper } from "./includes/SerialNumberWrapper.js";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { _get, _unset, _find } from "../../../../helpers/common-helpers/lodash.wrappers";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { PaymentCategoriesEnum } from "../../../../config/enum.config.js";
import { ConfirmationPopup } from "./includes/ConfirmationPopup.js";
import { isEmpty } from "lodash";
import { AuthContext } from "../../core/context-providers/AuthContext.provider.js";

const emptyFn = (...para) => undefined;

const IssueTemp = ({
  uiType = "normal",
  formGroupName = "issueTemp",
  latestLicence = {},
  closeMainPopup = emptyFn,
  isTempLicence = false,
}) => {
  const [openPrint, setOpenPrint] = useState({ showPopup: false, });
  const [formState, formAction] = useContext(FormContext);
  const [authState, authAction] = useContext(AuthContext);
  const onPrintFn = () => {
    const paymentFormKey = "paymentTypeFormKey";
    const errorObject = [];
    const paymentFormObj = getDataByFormObject(_get(formState, paymentFormKey, {}));
    _unset(paymentFormObj, "isDisplay");
    _unset(paymentFormObj, "payment_categories");
    _unset(paymentFormObj, "paid_amount");
    _unset(paymentFormObj, "cash_amount");
    _unset(paymentFormObj, "cheque_amount");
    _unset(paymentFormObj, "card_amount");
    formAction.setFormErrorFn("paymentTypeFormKey", []);

    let isValid = false;

    Object.keys(paymentFormObj).forEach((key) => {
      if (paymentFormObj[key]) {
        let index = key.replace("payment_types_", "");
        switch (paymentFormObj[key]['payment_type']) {
          case PaymentCategoriesEnum.CASH:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cash Received is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CHEQUE:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Cheque Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.cheque_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.cheque_no`,
                "message": "Cheque No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.bank`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.bank`,
                "message": "Bank is required."
              });
            }
            break;
          case PaymentCategoriesEnum.CREDITCARDS:
            if (isEmpty(_get(paymentFormObj, `${key}.amount`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.amount`,
                "message": "Credit Card Amount is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.reference_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.reference_no`,
                "message": "Reference No. is required."
              });
            }
            if (isEmpty(_get(paymentFormObj, `${key}.approval_no`, null))) {
              errorObject.push({
                "property": `payment_types_${index}.approval_no`,
                "message": "Approval No. is required."
              });
            }
            break;
        }
        formAction.setFormErrorFn(paymentFormKey, errorObject);
      }
    });

    if (isEmpty(errorObject)) {
      isValid = true;
    }
    if (isValid) {
      formAction.changeInputFn(formGroupName, "print_error", "", "");
      formAction.setFormErrorFn("paymentTypeFormKey", [])
      setOpenPrint({
        ...openPrint,
        showPopup: true
      });
    } else {
      formAction.changeInputFn(formGroupName, "print_error", "", "User cannot proceed with the payment");
    }
  };
  const closePopup = () => {
    setOpenPrint({
      ...openPrint,
      showPopup: false
    })
  }
  const isBtnDisabled = () => {
    const total = _get(formState, "licence_charges_form.total", 0);
    let cardAmount = _get(formState, "paymentTypeFormKey.card_amount", 0);
    let cashAmount = _get(formState, "paymentTypeFormKey.cash_amount", 0);
    let chequeAmount = _get(formState, "paymentTypeFormKey.cheque_amount", 0);
    let isDissable = true;

    if ((cardAmount + chequeAmount) < total) {
      if (cashAmount >= total) {
        isDissable = false;
      }
      if ((cashAmount + cardAmount + chequeAmount) >= total) {
        isDissable = false;
      }
    }
    if ((cardAmount + chequeAmount) == total) {
      isDissable = false;
    }
    return isDissable;
  }
  return (
    <FormWrapper
      setGroupName={formGroupName}
      setFormObject={{
        "total_amount": _get(formState, "licence_charges_form.total", 0),
        "print_error": "",
      }}
    >
      <div className="row">
        <div className="col-md-5">
          <PaddingLessUICard cardHeading="Vehicle Revenue Licence">
            <VehicleRevenueLicence uiType={uiType} latestLicence={latestLicence} />
          </PaddingLessUICard>
        </div>

        <div className="col-md-7">
          <div className="row">
            <div className="col-md-5">
              <PaddingLessUICard cardHeading="Amount Breakdown">
                <AmountBreakdown uiType={uiType} />
              </PaddingLessUICard>

              <PaddingLessUICard cardHeading="Serial Number" elementStyle="defaultHalfMarginTop">
                <SerialNumberWrapper formGroupName={formGroupName} uiType={uiType} />
              </PaddingLessUICard>
            </div>

            <div className="col-md-7 ">
              <PaddingLessUICard elementStyle="payment-type-card" cardHeading="Payment Type">

                <PaymentType uiType={uiType} />
                <div className="fullWidthDiv">
                  <div className="row ">
                    <div className="col-md-4"></div>
                    <div className={`${_get(authState, "authUser.pdmtId.code", "") === _get(formState, "vehicle_search_form.provinceData.code", "") ? 'col-md-6' : 'col-md-7' }`}>
                      <div className="text-right defaultMarginTop">

                        {
                          (_get(formState, `${formGroupName}.print_error`, "") !== "") ? (
                            <p className={"defaultHalfMargin"} style={{ color: "red" }}>{_get(formState, `${formGroupName}.print_error`, "")}</p>
                          ) : null
                        }
                      </div>
                      <div className="floatRight">
                        <InputButton
                          elementWrapperStyle={" payBtnStyle"}
                          btnText={"Pay & Print"}
                          onClickBtnFn={() => onPrintFn()}
                          isBtnDisabled={isBtnDisabled()}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </PaddingLessUICard>


            </div>



            <DialogPopup
              isDialogActions={false}
              dialogTitle={"Please Confirm"}
              isFullWidth={false}
              isFullScreen={false}
              isCloseButton={true}
              children={
                <ConfirmationPopup
                  closePopupFn={closePopup}
                  onClickSuccessFn={closeMainPopup}
                  isTempLicence={isTempLicence}
                  uiType={uiType}
                  confirmationMsg={"Are you sure you want to complete the payment?"}
                />
              }
              isOpen={openPrint.showPopup}
              onClosePopupFn={closePopup}
            />
          </div>
        </div>
      </div >
    </FormWrapper >
  );
};

export default IssueTemp;
