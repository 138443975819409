/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-05-23 18:02:24 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-17 10:00:03
 */
import React, { Fragment,useEffect } from 'react';

import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { VariableToComponent } from "../../common/BaseElements";
import { DialogPopup } from "../../modal/DialogPopup";

const emptyFun = (...para) => undefined;

const EmptyComponent = () => <Fragment></Fragment>

const CURDPops = ({
    dataTableState = {},
    actionTypeList = [],
    setDataTableStateFn = emptyFun,
    onSubmitCurdFn = emptyFun,
    isOnCloseFunc = false,
    onCloseFunc = emptyFun
}) => {
    
useEffect(()=>{
        if('parentCallback' in _get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', {})}.parentToModelProps`, {})){
            _get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', null)}.parentToModelProps`, null).parentCallback(_get(dataTableState, 'modelStatus.props', "none"))   
        }
 
},[dataTableState.modelStatus.status])

    return (
        <DialogPopup
            isFullScreen={true}
            isOpen={dataTableState.modelStatus.status}
            isCloseButton={true}
            isSaveButton={true}
            onClosePopupFn={() => {
                    if(isOnCloseFunc) {
                        onCloseFunc()
                    }
                    setDataTableStateFn({
                    ...dataTableState,
                    modelStatus: {
                        ...dataTableState.modelStatus,
                        status: false,
                        index: null,
                        rowIndex: null,
                        props: {},
                      }
                    })
                }
            }
            onSaveFn={(formData) => onSubmitCurdFn(formData, _get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.parentToModelProps.formKey`, "none"))}
            formGroupName={_get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.parentToModelProps.formKey`, "none")}
            {...{
                ..._get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.dialogPopupProps`, {}),
                customButtonComponentProps:{
                    ..._get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.dialogPopupProps.customButtonComponentProps`, {}),
                    row:_get(dataTableState, 'modelStatus.props', {})
                }
            }}
        >
            <VariableToComponent
                component={_get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.modelContentElement`, EmptyComponent)}
                props={{
                    "row": _get(dataTableState, 'modelStatus.props', {}),
                    "fromParent": _get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.parentToModelProps`, {}),
                    "onClosePopupFn": () => setDataTableStateFn({
                        ...dataTableState,
                        modelStatus: {
                            ...dataTableState.modelStatus,
                            status: false,
                            index: null,
                            rowIndex: null,
                            props: {},
                        }
                    })
                }}
            />
        </DialogPopup>
    )
}


export {
    CURDPops
}