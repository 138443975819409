/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:11:16
 */

import React, { useState, Fragment, useEffect } from 'react';
import { DatePickerWithState } from "../../../../../ui-components/ui-elements/form/index";
import {  PaddingLessUICard } from "../../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { useContext } from 'react';
import { FormContext } from '../../../../core/context-providers/FormContext.provider';


const emptyFn = (...para) => undefined;

const CarnetLicenceValidityPeriod = ({
    getState = {
        searchKey: "ks-5555",
        currentObject: null,
    },
    getLatestLicenceState=null,
    getVehicleStatusCode='ELI',
    setState = emptyFn,
    formGroupName = "carnet",
    effectiveDate=null
}) => {

    const [formState, formAction] = useContext(FormContext);

    const [getPeriod, setPeriod] = useState({
        "isReIssuance": false
    });

    useEffect(() => {
        
        if(effectiveDate){
            setTimeout(() => {
                formAction.changeInputFn(formGroupName, "fromDate", "", new Date(effectiveDate));
            }, 1000);
       
        }else{
            // formAction.changeInputFn(formGroupName, "fromDate", "", null);
        }

	}, [effectiveDate]);


    const setMinDateFn = () => {
        let minDate = new Date();
        minDate.setMonth(minDate.getMonth() - 6);
        return minDate;
    };

    return (
        <PaddingLessUICard
            cardHeading="Licence Validity Period"
            cardHeadingTail={_get(getLatestLicenceState, `current_licence_status`, "") === 'VA' || _get(getLatestLicenceState, `current_licence_status`, "") === 'RE'  ? null : null}  //#38953 removed Eligibale
            passPropsToCardHeadingTail={{
                uiType: "licence_period",
                formGroupName: formGroupName,
                getVehicleStatusCode:getVehicleStatusCode,
                onChangeStatusFn: () => {setPeriod({
                    ...getPeriod,
                    isReIssuance: !getPeriod.isReIssuance
             
                })
            }
            }}
        >
            <div className="row">
                        <Fragment>
                            <div className="col-md-6">
                                <DatePickerWithState
                                    labelText={"From"}
                                    inputName={"fromDate"}
                                    formGroupName={formGroupName}
                                    isDisabled={effectiveDate ? true:false}
                                    isRequired={true}
                                    minDate={setMinDateFn()}
                                    maxDate={new Date(_get(formState, 'carnet.toDate', null) ?? new Date("2060-01-01"))}
                                />
                            </div>
                            <div className="col-md-6">
                                <DatePickerWithState
                                    labelText={"To"}
                                    inputName={"toDate"}
                                    formGroupName={formGroupName}
                                    isDisabled={(_get(formState, 'carnet.fromDate', '') !== 'undefined') ? false : true}
                                    isRequired={true}
                                    minDate={_get(formState, 'carnet.fromDate', null) ? new Date(_get(formState, 'carnet.fromDate', null)) : setMinDateFn()}
                                    maxDate={new Date("2060-01-01")}
                                />
                            </div>
                        </Fragment>
    
            </div>
        </PaddingLessUICard>
    )
}
export { CarnetLicenceValidityPeriod };