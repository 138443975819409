/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-06 12:33:54
 * @Last Modified by: sureshg
 * @Last Modified time: 2021-01-07 14:02:53
 */
import React, { Fragment } from 'react';
import { SubmitButton } from "../../../../ui-components/ui-elements/form/Button";
import { FormWrapper, SelectBoxWithState, DatePickerWithState } from "../../../../ui-components/ui-elements/form";
import { _get, _find } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { insuranceCompany,EditInsuranceCompanyAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";

const EditInsuranceCompany = (props) => {

  return (
    <UICard>
      <FormWrapper
        setGroupName={"EditDealerInsurance"}
        onDestroyUnsetFormObject={true}
        setFormObject={

          {
            id: _get(props, "row.id", ""),
            insurance_company: _get(props, "row.insurance_company.id", ""),
            insurance_expire_date: new Date(),

          }

        }
      >

        <SelectBoxWithState
          apiUrl={`${insuranceCompany.url}`}
          apiStateKey={insuranceCompany.key}
          formGroupName={"EditDealerInsurance"}
          keyName={"id"}
          valueName={"service.name"}
          inputName={"insurance_company"}
          labelText="Insurance Company"
          emptySelectOptionTxt="-Select Insurance Company-"
        />
        <DatePickerWithState
          formGroupName={"EditDealerInsurance"}
          inputName={"insurance_expire_date"}
          labelText="Insurance Expire Date"
          isRequired={true}
          minDate={new Date(new Date)}
           maxDate={new Date("2100-01-01")}
          
        />


      </FormWrapper>
    </UICard>
  )
};
const CustomButtonEdit = (props) => {

  return (
    <Fragment>
      <SubmitButton
        dataTableKey={"dealer_pending_licence"}
        btnText={"Update"}
        startIcon={
          "far fa-edit"
        }
        elementStyle="btnWrapper"
        formGroupName={"EditDealerInsurance"}
        isValidate={true}
        flashMessages={{
          "success": {
            status: true,
            message: "Insurance Company is successfully updated",
            messageType: "success"
          },
          "failed": {
            status: true,
            message: "Insurance Company updating falied",
            messageType: "error"
          }
        }}
        validationObject={{
          fileds: {

            insurance_company: "Insurance Company Name",
            insurance_expire_date: "Expire Date",


          },
          rules: {
            insurance_company: "required",
            insurance_expire_date: "required",

          },
          message: {
          },

        }}
        callApiObject={{
          isSetHeaders: true,
          multipart: false,
          method: "put",
          onUpload: false,
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: "DATA_TABLE_LINKED_FORM"
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${EditInsuranceCompanyAPI.url}/${formState.id}`,
            key: EditInsuranceCompanyAPI.key
          }
        }}
        onChangeRequestBodyFn={(fromObject) => {

          const data = getDataByFormObject(fromObject);
          return {
            ...data,
            insurance_expire_date: _get(data, 'insurance_expire_date', null) ? new Date(data.insurance_expire_date).toISOString() : null
          }
          // let res = {
          //     insurance_company_name:  data.name,
          //     insurance_expire_date:  data.insurance_expire_date,
          // }
          // return res;

        }}
        onResponseCallBackFn={(error, response) => {

          if (!error) {
            props.refreshMainScreenAfterPerformFn()
            props.onClosePopupFn();
          }

        }}
      />
    </Fragment>
  )
}
export { EditInsuranceCompany, CustomButtonEdit };
