/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-05-17 18:00:39
 */

import React, { useState, Fragment, useEffect, useContext } from 'react';
import {
	FormWrapper,
	InputButton,
	SubmitButton,
	InputBoxWithState
} from "../../../../ui-components/ui-elements/form/index";
import {
	TooltipWrapper,
	CircleLoaderElement,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import {
	statusObject,
} from "../../../../../config/dummyData.config";

import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { DuplicateVehicleModel } from '../includes/DuplicateVehicleModel';
import { RequestFor } from '../includes/RequestFor';
import { CarnetLicenceElement } from './includes/CarnetLicenceElement';
import { PendingWorkflow } from "../includes/PendingWorkflow";
import { getDMTDataListAPI, getAllTask, getLatestLicenseAPI, availableSessionListApi, getVehicleMetaDataAPI } from '../../../../../config/apiUrl.config';
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { apiResponseStoringType, CoreContext } from "../../../core/context-providers/CoreContext.provider.js";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { getCodeName } from '../../../../../helpers/common-helpers/common.helpers';
import { VehicleStatus, CurrentLicenceStatus, LicenceTypeCodes } from "../../../../../config/enum.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { Specialmessage } from '../includes/Specialmessage';
import { AuthContext } from '../../../core/context-providers/AuthContext.provider';
import { isEmpty } from 'lodash';
const emptyFn = (...para) => undefined;
const searchFormKey = "vehicle_search_form";
const pendingWorkflowFormKey = "pending_workflow_key";

const Carnet = ({
	setShowHideFooterFN = emptyFn,
	sessionId,
	counterId,
	setLatestLicenceFN = emptyFn,
	uiType = "carnet",
}) => {
	const initialStateForGetState = {
		onPageLoading: true,
		currentObject: null,
		isDuplicateVehicle: false,
		showPendingWorkflow: false,
		pendingWorkflows: [],
		isNoRecordFound: false
	}

	const [getState, setState] = useState(initialStateForGetState);
	const [coreState, coreAction] = useContext(CoreContext);
	const [, uiAction] = useContext(UIContext);
	const [authStatus, authAction] = useContext(AuthContext);
	const user_id = _get(authStatus, "authUser.id", "");
	const [getLatestLicenceState, setLatestLicenceState] = useState(null);
	const [formState, formAction] = useContext(FormContext);

	useEffect(() => {
		formAction.changeInputFn(searchFormKey, "isVehicleSearched", "", false);
		setShowHideFooterFN(true, {
			issueMode: _get(getState.currentObject, `status.issueMode`, "1"),
			isPendingWorkFlow: _get(getState.currentObject, `status.isPendingWorkFlow`, false),
		});
	}, []);

	const getVehileMetaDataFn = (vehicle_No, current_license_type) => {
		callApi(`${getVehicleMetaDataAPI.url}?vehicle_no=${vehicle_No}&requested_licence_type=${current_license_type}`)
		  .method("get")
		  .headers(true)
		  .isMultipart(false)
		  .send((error, result) => {
			if (!error) {
			  formAction.changeInputFn('vehicle-meta-data', "data", "", result.data.data);
			  // _get(formState,"vehicle-meta-data.data.allow_temp",false)
			  // _get(formState,"vehicle-meta-data.data.allow_re_issue",false)
			  // _get(formState,"vehicle-meta-data.data.eligible",false)
			  // _get(formState,"vehicle-meta-data.data.vet_validation",false)==="MAN"
				// _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")==="MAN"
	
			}
		  });
	  }
	const refreshMainScreenDataFn = () => {
		coreAction
			.sendRequestFn(`${getLatestLicenseAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}`)
			.method("get")
			.setInitStoring(apiResponseStoringType.setResponse, {
				responseKey: getLatestLicenseAPI.key,
			})
			.headers(true)
			.send((error, result) => {
				if (!error) {
					setLatestLicenceState(result.data.data,);
					setLatestLicenceFN(result.data.data);
				}
			});

		formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", true);
		coreAction
			.sendRequestFn(`${getAllTask.url}?vehicle_id=${_get(formState, `${searchFormKey}.searchKey`, "")}&assigned_user_id=notnull&status=PEN&sort_by=created_at|desc`)
			.method("get")
			.setInitStoring(apiResponseStoringType.setResponse, {
				responseKey: pendingWorkflowFormKey,
			})
			.headers(true)
			.send((error, result) => {
				if (!error) {
					setState({ ...getState, pendingWorkflows: result.data })
					let count = _get(result, "data.data", []).filter(item => item.request_type === "add_vcl")
					formAction.changeInputFn(pendingWorkflowFormKey, "workflows", "", result.data.data);
					formAction.changeInputFn(pendingWorkflowFormKey, "_onLoad", "", false);
					formAction.changeInputFn(pendingWorkflowFormKey, "addCount", "", count);
				}
			});
			getVehileMetaDataFn(_get(formState, `${searchFormKey}.searchKey`, ""),_get(formState,"vehicle_search_form.data.current_license_type.code"))
	}

	// const getVehicleStatusCode = () => {
	// 	let vehiclestatusCode = 'INE'
	// 	vehiclestatusCode = _get(formState, `${searchFormKey}.data.vehicle_status`,)
	// 	if ((_get(formState, `${searchFormKey}.data.gross_weight`, 0) === null || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.gross_weight`, 0) === '0.00')
	// 		&& (_get(formState, `${searchFormKey}.data.tare_weight`, 0) === null || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === 0 || _get(formState, `${searchFormKey}.data.tare_weight`, 0) === '0.00')
	// 	) {
	// 		vehiclestatusCode = "INE"
	// 	}

	// 	if (_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length > 0) {
	// 		vehiclestatusCode = "INE"
	// 	}

	// 	if (_get(formState, `${searchFormKey}.data.fuel_type.name`,) === 'NOT STATED' && _get(formState, `${searchFormKey}.data.vehicle_class.prefix`,) !== 'TR' || _get(formState, `${searchFormKey}.data.fuel_type`,) === null || _get(formState, `${searchFormKey}.data.fuel_type`,) === "") {
	// 		vehiclestatusCode = "INE"
	// 	}

	// 	return vehiclestatusCode
	// }
	const handleKeyDown = (event) => {
		coreAction.sendRequestFn(`${getDMTDataListAPI.url}/${event}`)
		.isMultipart(false)
		.method("get")
		.setLoader(true)
		// .setInitStoring("none", null)
		.setInitStoring(apiResponseStoringType.setResponse, {
		  responseKey:  getDMTDataListAPI.key,
		})
		.headers(true)
		.send((error, response) => {
			formAction.changeInputFn(searchFormKey, "isVehicleSearched", "", true);
			if (!error) {
				if (_get(response.data.data, `current_license_type.code`, "") !== "CA") {
					uiAction.setFlashMessage({
						status: true,
						message: `This vehicle is not allowed to get Carnet Licence`,
						messageType: "error",
					});
					formAction.changeInputFn(searchFormKey, "licenceCode", "", _get(response.data.data, `current_license_type.code`, ""))
					setState(prevState => {
						return {
							...prevState,
							currentObject: null,
							onPageLoading: false,
							isNoRecordFound: true,
						};
					});
					return
				}

				formAction.changeInputFn(searchFormKey, "shouldVehicleRefresh", "", false);
				callApi(`${getLatestLicenseAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}`)
					.method("get")
					.headers(true)
					.isMultipart(false)
					.send((error, result) => {
						if (!error) {
							formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);
							setLatestLicenceState(result.data.data,);
							setLatestLicenceFN(result.data.data);
						}
					});
				formAction.changeInputFn(searchFormKey, "data", "", response.data.data);
				setState(prevState => {
					return {
						...prevState,
						currentObject: response.data.data,
						onPageLoading: false,
						isNoRecordFound: false,
					};
				});
				getVehileMetaDataFn(_get(formState, `${searchFormKey}.searchKey`, ""),response.data.data.current_license_type.code)
				callApi(`${availableSessionListApi.url}/${user_id}/sessions/list`)
					.method("get")
					.headers(true)
					.body({})
					.isMultipart(false)
					.send(async (error, result) => {
						if (!error) {
							const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");

							formAction.changeInputFn("counter-serial", "id", "", next_serial);
							formAction.changeInputFn("counter-serial", "active", "", _get(result, "data.data[0].counter_id.active", ""));
						}
					});
				if (_get(response, "data.data.errorCode", 0) > 0) {
					uiAction.setFlashMessage({
						status: true,
						message: `Failed to retrieve update from DMT. Vehicle data may be outdated`,
						messageType: "error",
					});
				}
			} else {
				let errorToPop = 'Vehicle not found!';

				if (_get(error, 'data.body.errors.details[0].message', '') === 'DMT_API_ERROR') {
					errorToPop = 'DMT Service Error';
				}

				// if (_get(error, 'data.body.errors.details[0].message', '') === 'VEHICLE_NOT_IN_DMT') {
				//   errorToPop = 'Searched vehicle does not exists in DMT';
				// }

				if (_get(error, 'data.body.errors.details[0].message', '') === 'VEHICLE_INCOM_DATA_IN_DMT') {
					errorToPop = 'Mandatory Vehicle Information Missing in DMT';
				}

				formAction.changeInputFn(searchFormKey, "vehicleFound", "", false);
				formAction.changeInputFn(searchFormKey, "licenceCode", "", null)
				uiAction.setFlashMessage({
					status: true,
					message: errorToPop,
					messageType: "error",
				});
				setState(prevState => {
					return {
						...prevState,
						currentObject: null,
						onPageLoading: false,
						isNoRecordFound: true,
					};
				});
			}
		});
	
		// callApi(`${getDMTDataListAPI.url}/${event}`)
		// 	.method("get")
		// 	.headers(true)
		// 	.isMultipart(false)
		// 	.send((error, response) => {
		// 		formAction.changeInputFn(searchFormKey, "isVehicleSearched", "", true);
		// 		if (!error) {
		// 			if (_get(response.data.data, `current_license_type.code`, "") !== "CA") {
		// 				uiAction.setFlashMessage({
		// 					status: true,
		// 					message: `This vehicle is not allowed to get Carnet Licence`,
		// 					messageType: "error",
		// 				});
		// 				formAction.changeInputFn(searchFormKey, "licenceCode", "", _get(response.data.data, `current_license_type.code`, ""))
		// 				setState(prevState => {
		// 					return {
		// 						...prevState,
		// 						currentObject: null,
		// 						onPageLoading: false,
		// 						isNoRecordFound: true,
		// 					};
		// 				});
		// 				return
		// 			}

		// 			formAction.changeInputFn(searchFormKey, "shouldVehicleRefresh", "", false);
		// 			callApi(`${getLatestLicenseAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}`)
		// 				.method("get")
		// 				.headers(true)
		// 				.isMultipart(false)
		// 				.send((error, result) => {
		// 					if (!error) {
		// 						formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);
		// 						setLatestLicenceState(result.data.data,);
		// 						setLatestLicenceFN(result.data.data);
		// 					}
		// 				});
		// 			formAction.changeInputFn(searchFormKey, "data", "", response.data.data);
		// 			setState(prevState => {
		// 				return {
		// 					...prevState,
		// 					currentObject: response.data.data,
		// 					onPageLoading: false,
		// 					isNoRecordFound: false,
		// 				};
		// 			});
		// 			getVehileMetaDataFn(_get(formState, `${searchFormKey}.searchKey`, ""),response.data.data.current_license_type.code)
		// 			callApi(`${availableSessionListApi.url}/${user_id}/sessions/list`)
		// 				.method("get")
		// 				.headers(true)
		// 				.body({})
		// 				.isMultipart(false)
		// 				.send(async (error, result) => {
		// 					if (!error) {
		// 						const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");

		// 						formAction.changeInputFn("counter-serial", "id", "", next_serial);
		// 						formAction.changeInputFn("counter-serial", "active", "", _get(result, "data.data[0].counter_id.active", ""));
		// 					}
		// 				});
		// 			if (_get(response, "data.data.errorCode", 0) > 0) {
		// 				uiAction.setFlashMessage({
		// 					status: true,
		// 					message: `Failed to retrieve update from DMT. Vehicle data may be outdated`,
		// 					messageType: "error",
		// 				});
		// 			}
		// 		} else {
		// 			let errorToPop = 'Vehicle not found!';

		// 			if (_get(error, 'data.body.errors.details[0].message', '') === 'DMT_API_ERROR') {
		// 				errorToPop = 'DMT Service Error';
		// 			}

		// 			// if (_get(error, 'data.body.errors.details[0].message', '') === 'VEHICLE_NOT_IN_DMT') {
		// 			//   errorToPop = 'Searched vehicle does not exists in DMT';
		// 			// }

		// 			if (_get(error, 'data.body.errors.details[0].message', '') === 'VEHICLE_INCOM_DATA_IN_DMT') {
		// 				errorToPop = 'Mandatory Vehicle Information Missing in DMT';
		// 			}

		// 			formAction.changeInputFn(searchFormKey, "vehicleFound", "", false);
		// 			formAction.changeInputFn(searchFormKey, "licenceCode", "", null)
		// 			uiAction.setFlashMessage({
		// 				status: true,
		// 				message: errorToPop,
		// 				messageType: "error",
		// 			});
		// 			setState(prevState => {
		// 				return {
		// 					...prevState,
		// 					currentObject: null,
		// 					onPageLoading: false,
		// 					isNoRecordFound: true,
		// 				};
		// 			});
		// 		}
		// 	})
	}
	// for after payment Success  
	useEffect(() => {
		if (_get(formState, `${searchFormKey}.shouldVehicleRefresh`, false)) {
			formAction.changeInputFn(searchFormKey, "isVehicleSearched", "", false);
			formAction.changeInputFn('vehicle_search_form', "data", "", undefined)
			formAction.changeInputFn('latest_licence_data', "data", "", undefined)
			formAction.changeInputFn('vehicle_search_form', "isTempLicence", "", false)
			formAction.changeInputFn('vehicle_search_form', "documentRequest", "", null)
			setState(initialStateForGetState);
			setLatestLicenceState(null);
			window.scrollTo(0, 0);
		}
	}, [_get(formState, `${searchFormKey}.shouldVehicleRefresh`, false)]);

	return (
		<div className="normalLicenseWrapper">
			<div className="topWrapper">
				<div className="searchWrapper">
					<FormWrapper elementStyle={""} setGroupName={searchFormKey}>
						<div className="searchInputWrapper">
							<label className="control-label">Vehicle ID: <span style={{ color: "red" }}>*</span></label>
							<InputBoxWithState
								inputName={"searchKey"}
								formGroupName={searchFormKey}
								elementStyle="marginTopBottomNone"
								elementWrapperStyle={"searchInputBoxWrapper"}
								onChangeTxtFn={(eventData) => {
									formAction.changeInputFn(searchFormKey, "data", "", undefined);
									setState(initialStateForGetState);
									formAction.changeInputFn('latest_licence_data', "data", "", null);
									formAction.changeInputFn(searchFormKey, "isVehicleSearched", "", false);
									setLatestLicenceState(null);
									setLatestLicenceFN(null);
									formAction.removeFromGroupFn('carnet');
								}}
								onKeyDown={(event) => {
									if (event.key === "Enter")
										handleKeyDown(event.target.value)
								}}
								onFocus={(event) => event.target.select()}
							/>
							<SubmitButton
								btnText={"Search"}
								formGroupName={searchFormKey}
								tooltip={"Search"}
								startIcon="mdi mdi-magnify-plus-outline"
								isValidate={true}
								onGetAPIEndPointFn={(formState) => {
									return {
										url: `${getDMTDataListAPI.url}/${formState.searchKey}`,
										key: getDMTDataListAPI.key
									}
								}}
								onChangeRequestBodyFn={(formObject) => {
									formAction.changeInputFn(searchFormKey, "data", "", undefined);
									setState(initialStateForGetState);

									formAction.removeFromGroupFn('carnet');
									return getDataByFormObject(formObject);
								}}
								validationObject={{
									fileds: {
										"searchKey": "Vehicle Id",
									},
									rules: {
										"searchKey": "required",
									},
									message: {}
								}}
								onClickBtnFn={() => {
									setState(prevState => {
										return {
											...prevState,
											currentObject: null,
											onPageLoading: false,
											isNoRecordFound: true,
										};
									});
								}}
								callApiObject={{
									isSetHeaders: true,
									method: "get",
								}}
								apiDataStoringObject={{
									setLoader: true,
									storingType: "none"
								}}
								onResponseCallBackFn={(error, response) => {
									formAction.changeInputFn(searchFormKey, "isVehicleSearched", "", true);
									if (!error) {
										if (_get(response.data.data, `current_license_type.code`, "") !== "CA") {
											uiAction.setFlashMessage({
												status: true,
												message: `This vehicle is not allowed to get Carnet Licence`,
												messageType: "error",
											});
											formAction.changeInputFn(searchFormKey, "licenceCode", "", _get(response.data.data, `current_license_type.code`, ""))
											setState(prevState => {
												return {
													...prevState,
													currentObject: null,
													onPageLoading: false,
													isNoRecordFound: true,
												};
											});
											return
										}

										formAction.changeInputFn(searchFormKey, "shouldVehicleRefresh", "", false);
										callApi(`${getLatestLicenseAPI.url}/${_get(formState, `${searchFormKey}.searchKey`, "")}`)
											.method("get")
											.headers(true)
											.isMultipart(false)
											.send((error, result) => {
												if (!error) {
													formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);
													setLatestLicenceState(result.data.data,);
													setLatestLicenceFN(result.data.data);
												}
											});
										formAction.changeInputFn(searchFormKey, "data", "", response.data.data);


										setState(prevState => {
											return {
												...prevState,
												currentObject: response.data.data,
												onPageLoading: false,
												isNoRecordFound: false,
											};
										});
										 getVehileMetaDataFn(_get(formState, `${searchFormKey}.searchKey`, ""),response.data.data.current_license_type.code)

										callApi(`${availableSessionListApi.url}/${user_id}/sessions/list`)
											.method("get")
											.headers(true)
											.body({})
											.isMultipart(false)
											.send(async (error, result) => {
												if (!error) {
													const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");
													const formGroupName = uiType === 'normal' ? "issueTemp" :
														uiType === 'carnet' ? "issueCarnet" :
															uiType === 'arrears' ? "arrearsFormKey" : "issueTemp";

													formAction.changeInputFn(formGroupName, "next_serial", "", next_serial);
													formAction.changeInputFn("counter-serial", "id", "", next_serial);
													formAction.changeInputFn("counter-serial", "active", "", _get(result, "data.data[0].counter_id.active", ""));
												}
											});
									} else {

										let errorToPop = 'Vehicle not found!';

										if (_get(error, 'data.body.errors.details[0].message', '') === 'DMT_API_ERROR') {
											errorToPop = 'DMT Service Error';
										}

										// if (_get(error, 'data.body.errors.details[0].message', '') === 'VEHICLE_NOT_IN_DMT') {
										//   errorToPop = 'Searched vehicle does not exists in DMT';
										// }

										if (_get(error, 'data.body.errors.details[0].message', '') === 'VEHICLE_INCOM_DATA_IN_DMT') {
											errorToPop = 'Mandatory Vehicle Information Missing in DMT';
										}

										formAction.changeInputFn(searchFormKey, "vehicleFound", "", false);
										formAction.changeInputFn(searchFormKey, "licenceCode", "", null)
										uiAction.setFlashMessage({
											status: true,
											message: errorToPop,
											messageType: "error",
										});
										setState(prevState => {
											return {
												...prevState,
												currentObject: null,
												onPageLoading: false,
												isNoRecordFound: true,
											};
										});
									}

								}}


							/>
						</div>
					</FormWrapper>
				</div>

				{(getState.currentObject !== null ||
					getState.currentObject !== "none") &&
					getState.onPageLoading !== true ? (
					<div className="statusWrapper">
						<ul className="statusUL">
							{getState.onPageLoading !== true && !isEmpty(_get(formState, 'vehicle_search_form.searchKey', null)?.trim()) ? (
								<FormWrapper
									elementStyle="btnFormWrapper"
									setGroupName={pendingWorkflowFormKey}
									apiUrl={`${getAllTask.url}?vehicle_id=${_get(formState, `${searchFormKey}.searchKey`, "")}&status=PEN&assigned_user_id=notnull&sort_by=created_at|desc`}
									onRebuildResponseFn={(response) => {
										setState({ ...getState, pendingWorkflows: response })
										let count = response.filter(item => item.request_type === "add_vcl")
										return {
											workflows: response,
											addCount: count,
											pdmtId: response.find(item => item.request_type === "add_vcl")?.pdmt_id,
											"_onLoad": false
										}
									}
									}
								>
									<li className="setBorder">
										<InputButton
											btnName="pendingWorkflow"
											isBtnDisabled={_get(formState, `${pendingWorkflowFormKey}.workflows`, []).length === 0}
											btnText={"Pending Workflows"}
											onClickBtnFn={() =>
												setState({
													...getState,
													showPendingWorkflow: true,
												})
											}
										/>
									</li>
								</FormWrapper>) : null}


							<li className="setBorder">
								<TooltipWrapper description={"Current Vehicle Status for Licence Issuance"}>
									<span className={`${_get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","")}`}>
										{_get(getState, `currentObject`, null) ? getCodeName(VehicleStatus, _get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","INE")) : null}

									</span>
								</TooltipWrapper>
							</li>

							<li className="setBorder">
								<TooltipWrapper description={"Current Licence Status"}>
									<span className={`licence-${_get(getLatestLicenceState, `current_licence_status`, 'NL')}`}>
										{_get(getState, `currentObject`, null) ? (_get(getLatestLicenceState, `current_licence_status`, 'NL') === 'RE' ? 'Valid' : getCodeName(CurrentLicenceStatus, _get(getLatestLicenceState, `current_licence_status`, 'NL'))) : null}
									</span>
								</TooltipWrapper>
							</li>

							{_get(getLatestLicenceState, `temp_licence`, 0) === 1 || _get(formState, 'vehicle_search_form.documentRequest.issue_mode', "") === "TMP" ? (
								<li className="setBorder">
									<TooltipWrapper description={"Current Licence Issue Mode"}>
										<span>
											{_get(getState, `currentObject`, null) ? 'Temp. Licence' : null}
										</span>
									</TooltipWrapper>
								</li>
							) : null}

							{getState.currentObject !== null && _get(formState, `${searchFormKey}.data.vehicle_status`,) && (
								<li className="setBorder">
									<TooltipWrapper description={"New Licence Type"}>
										<span>
											Carnet
										</span>
									</TooltipWrapper>
								</li>
							)}
							{_get(formState, `${searchFormKey}.isVehicleSearched`, false)
								&& _get(formState, `${searchFormKey}.licenceCode`, "") !== LicenceTypeCodes.NORMAL_LICENCE
								&& _get(formState, `${searchFormKey}.licenceCode`, "") !== LicenceTypeCodes.NON_USER_LICENCE
								&& _get(formState, `${searchFormKey}.licenceCode`, "") !== LicenceTypeCodes.FREE_LICENCE
								&& _get(formState, `${pendingWorkflowFormKey}.addCount`, []).length === 0 && _get(formState, "pending_workflow_key._onLoad", false) === false && (
									<li>
										<RequestFor isCarnet={true} counterId={counterId} sessionId={sessionId} refreshMainScreenDataFn={() => refreshMainScreenDataFn()} />
									</li>
								)}
						</ul>
					</div>
				) : null}
			</div>
			{getState.isNoRecordFound === true ?
				_get(formState, `${pendingWorkflowFormKey}.addCount`, []).length !== 0 &&
				<div className="col-md-6">
					<div>
						<Specialmessage provinceId={_get(formState, `${pendingWorkflowFormKey}.pdmtId`, "")} vehicleNo={_get(formState, 'vehicle_search_form.searchKey', null)} />
					</div>
				</div>
				: null}

			{((getState.currentObject === null || getState.currentObject === "none") && getState.onPageLoading === true) ?
				(
					<div className="col-md-12 defaultPaddingTop floatLeft">
						{
							(getState.isNoRecordFound === true) ? (
								<p>No result found</p>
							) : null
						}
					</div>
				) : (
					<div className="fullWidthDiv">
						<CarnetLicenceElement
							getState={getState}
							setState={setState}
							getLatestLicenceState={getLatestLicenceState}
							getVehicleStatusCode={_get(formState,"vehicle-meta-data.data.vehicle_eligibility_code","INE")}
							uiType={uiType}
							setShowHideFooterFN={(status, mergeWithPerent) => setShowHideFooterFN(status, mergeWithPerent)}
						/>
					</div>
				)
			}

			<DuplicateVehicleModel
				getState={getState}
				setState={setState}
				setShowHideFooterFN={setShowHideFooterFN}
			/>

			<PendingWorkflow
				getState={getState}
				setState={setState}
				setShowHideFooterFN={setShowHideFooterFN}
			/>
		</div>
	);
};

export { Carnet };