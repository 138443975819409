import React, { Fragment, useContext } from "react";
import { useLocation } from "react-router-dom";

import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import SearchElement from "./includes/SearchElement";
import { sessionCollectionsAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { RawIndexMemo } from "../../../../ui-components/ui-elements/table/DataTable";
import { _getInt } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { isEmpty } from "lodash";
import { getLabelValue } from "../../../../../helpers/common-helpers/common.helpers";

const RowNo = (props) => {
    let index = props.rowIndex;
    return (
        <RawIndexMemo
            // prefix='0001'
            prefix={''}
            page_size={_getInt(props.pagingObject, "per_page", 1)}
            current_page={_getInt(props.pagingObject, "current_page", 1)}
            index={index || 0}
        />
    );
  };

const PrintedLicenceTransactionList = ({currentSessionStartAt, sessionId, sessionRowData}) => {
    const [formState] = useContext(FormContext);
    const location = useLocation();

    const currentSession = currentSessionStartAt && currentSessionStartAt !== '-' ? `${dateObjectToString(currentSessionStartAt,'dd/mm/YY')} - ${dateObjectToString(currentSessionStartAt,'HH:MM')} - Session Still On` : '-'

    const getDateAndSession=()=>{
        let sessionDateString =''
        if(sessionRowData?.start_at && sessionRowData?.end_at ){
            sessionDateString =`${dateObjectToString(sessionRowData?.start_at,'dd/mm/YY')} - ${dateObjectToString(sessionRowData.start_at,'HH:MM') +'-' +dateObjectToString(sessionRowData.end_at,'HH:MM')}`
            return sessionDateString
        }
        return sessionDateString 
    }

    const displayAmount = (status, amount) => {
        let value = amount;
        if(status !== 'RE'){
            value = amount < 0 ? `(${thousandSeparator(amount * -1, true)})` : thousandSeparator(amount, true);
        }else{
            value = amount < 0 ? thousandSeparator(amount * -1, true) : `(${thousandSeparator(amount, true)})`;
        }
        return value;
    };

    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 p-1"}>

                    <UICard cardHeading={`Printed Licence Transactions ${ currentSession && currentSession !== '-' ? currentSession : getDateAndSession()}`}>
                        <DataTable
                            apiUrl={`${sessionCollectionsAPI.url}/printed-licence-transactions/${sessionId}`}
                            dataTableKey={`${sessionCollectionsAPI.key}_printed_licence`}
                            isSetSearchFrom={true}
                            defaultSearchFormObject={{
                                licence_filter_id: '',
                                licence_type_code: ''
                            }}
                            searchFromComponent={<SearchElement dataTableKey={`${sessionCollectionsAPI.key}_printed_licence`} />}
                            tableHeaderList={[
                                { 
                                    displayName: "Counter Seq. No.", 
                                    key: "counter_sequence_number", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Print Type", 
                                    key: "print_type", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Date & Time", 
                                    key: "tx_date_time", 
                                    isSort: false, 
                                    onPrintFn: (row) => 
                                        isEmpty(row) ?
                                        ''
                                        : dateObjectToString(row.tx_date_time,'dd/mm/YY HH:MM'),
                                    cellStyle:{ 'white-space': 'nowrap'}
                                },
                                { 
                                    displayName: "Lic. No.", 
                                    key: "licence_number", 
                                    isSort: false, 
                                    onPrintFn: (row) => 
                                        isEmpty(_get(row, 'licence_number', '')) ?
                                        ''
                                        : _get(row, 'licence_number', '')
                                },
                                {
                                    displayName: "Vehicle ID/Dealer's ID",
                                    key: "vehicle_number",
                                    isSort: false,
                                },
                                {
                                    displayName: "Owner Name/Dealer's Name",
                                    key: "owner_name",
                                    isSort: false,
                                    onPrintFn: (rowData)=>getLabelValue(rowData.owner_name,true),
                                },
                                {
                                    displayName: "Validity Period",
                                    key: "valid_period",
                                    isSort: false,
                                    onPrintFn: (row) =>  
                                        isEmpty(_get(row, 'valid_from', null)) || isEmpty(_get(row, 'valid_to', null)) ?
                                        ''
                                        : `${dateObjectToString(row.valid_from,'dd/mm/YY')} - ${dateObjectToString(row.valid_to,'dd/mm/YY')}`
                                },
                                { 
                                    displayName: "Licence Type", 
                                    key: "licence_type", 
                                    isSort: false 
                                },
                                { 
                                    displayName: "Tx. Status", 
                                    key: "status", 
                                    isSort: false , 
                                    onPrintFn: (row) =>  
                                        isEmpty(row) ?
                                        ''
                                        : _get(row, 'license_status', '') ==='REV' ? "Reversed" : _get(row, 'license_status', '') ==='CAN' ? "Cancelled" : "Success"
                                },
                                {
                                    displayName: "Fee (LKR)",
                                    key: "fee_amount",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) =>  
                                        isEmpty(row) ?
                                        ''
                                        : displayAmount(_get(row, 'license_status', ''), row.fee_amount)
                                },
                                {
                                    displayName: "Fine (LKR)",
                                    key: "fine_amount",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) => 
                                        isEmpty(row) ?
                                        ''
                                        : displayAmount(_get(row, 'license_status', ''), row.fine_amount)
                                },
                                {
                                    displayName: "Additions (LKR)",
                                    key: "addition_amount",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) => 
                                        isEmpty(row) ?
                                        ''
                                        : displayAmount(_get(row, 'license_status', ''), row.addition_amount)
                                },
                                {
                                    displayName: "Deductions (LKR)",
                                    key: "deduction_amount",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) => 
                                        isEmpty(row) ?
                                        ''
                                        : displayAmount(_get(row, 'license_status', ''), row.deduction_amount)
                                },
                                {
                                    displayName: "Service Charge (LKR)",
                                    key: "service_charge",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) => 
                                        isEmpty(row) ?
                                        ''
                                        : displayAmount(_get(row, 'license_status', ''), row.service_charge)
                                },
                                {
                                    displayName: "Total (LKR)",
                                    key: "total",
                                    isSort: false,
                                    isRightAlign:true,
                                    onPrintFn: (row) => 
                                        displayAmount(_get(row, 'license_status', ''), 
                                            parseFloat(_get(row, 'fee_amount', 0)) +
                                            parseFloat(_get(row, 'fine_amount', 0)) +
                                            parseFloat(_get(row, 'addition_amount', 0)) +
                                            parseFloat(_get(row, 'deduction_amount', 0)) +
                                            parseFloat(_get(row, 'service_charge', 0))
                                        )                                   
                                }
                            ]}
                            specificComponents={{
                                __no__: RowNo,
                            }}
                            isSetAction={false}
                        />

                        <FormWrapper
                            setGroupName={"printedLicenceTx"}
                            apiUrl={`${sessionCollectionsAPI.url}/printed-licence-transactions-details/${sessionId}`}
                            onRebuildResponseFn={(response) => {
                                return {
                                    ...response,
                                    _onLoad: false
                                };
                            }}
                        >
                            <div className="row">
                                <div className="col-md-3 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Started Serial Number: </strong>
                                    {_get(formState, "printedLicenceTx.started_serial_number", "")}
                                </div>

                                <div className="col-md-3 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Last Serial Number: </strong>
                                    {_get(formState, "printedLicenceTx.ended_serial_number", "-")}
                                </div>

                                <div className="col-md-3 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total of Used Serial Numbers: </strong>
                                    {_get(formState, "printedLicenceTx.total_used_serial_numbers", 0)}
                                </div>

                                <div className="col-md-3 col-sm-12 cashier-history-label-wrapper">
                                    <strong>Total of Discarded Serial Numbers: </strong>
                                    {_get(formState, "printedLicenceTx.total_discarded_serial_numbers", 0)}
                                </div>
                            </div>
                        </FormWrapper>
                    </UICard>

                </div>
            </div>
        </Fragment>
    );
};

export default PrintedLicenceTransactionList;