/*
 * @Author: Madhawa
 * @Date: 2020-09-22 16:06:43
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-20 14:03:25
 */
import React, { useState } from "react";
import { PaddingLessUICard } from "../../../ui-components/ui-elements/common/BaseElements.js";
import {
  FormWrapper,
  InputBoxWithState,
  InputButton,
} from "../../../ui-components/ui-elements/form";
import { VehicleRevenueLicence } from "./includes/VehicleRevenueLicence.js";
import { AmountBreakdown } from "./includes/AmountBreakdown.js";
import { PaymentType } from "./includes/PaymentType.js";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { PrintReceiptConfirmation } from "./includes/PrintReceiptConfirmation.js";

const PaymentUI = ({ uiType = "normal" }) => {
  const [openPrint, setOpenPrint] = useState(false);
  return (
    <div className="row">
      {uiType === "carnet" ? (
        <div className="col-md-12 defaultMarginBottom fullWidthDiv">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 text-right">
              <h5>Total (LKR): 2,420.00</h5>
            </div>
          </div>
        </div>
      ) : null}

      {uiType !== "arrears" ? (
        <div className="col-md-5">
          {uiType === "dealer" ? (
            <PaddingLessUICard cardHeading="Payment Information">
              <FormWrapper
                setGroupName={"dealersPaymentFromKey"}
                setFormObject={{ amount: "10000" }}
              >
                <table className={"fullWidthDiv"}>
                  <tr>
                    <th>Number of Dealer’s Licences </th>
                    <td>
                      <InputBoxWithState
                        labelText={""}
                        formGroupName={"dealersPaymentFromKey"}
                        inputName={"numberOfDealers"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Payment For One Dealer’s Licence </th>
                    <td>
                      <InputBoxWithState
                        labelText={""}
                        formGroupName={"dealersPaymentFromKey"}
                        inputName={"payment"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Total (LKR) </th>
                    <td>
                      <InputBoxWithState
                        labelText={""}
                        formGroupName={"dealersPaymentFromKey"}
                        inputName={"total"}
                      />
                    </td>
                  </tr>
                </table>
              </FormWrapper>
            </PaddingLessUICard>
          ) : null}

          <PaddingLessUICard cardHeading="Vehicle Revenue Licence">
            <VehicleRevenueLicence uiType={uiType} />
          </PaddingLessUICard>
        </div>
      ) : null}

      <div className={`${uiType !== "arrears" ? "col-md-7" : "col-md-12"}`}>
        <div className="row">
          {!(uiType === "dealer" || uiType === "carnet") ? (
            <div className="col-md-5">
              <PaddingLessUICard cardHeading="Amount Breakdown">
                <AmountBreakdown uiType={uiType} />
              </PaddingLessUICard>
            </div>
          ) : null}

          <div className="col-md-7 ">
            <PaddingLessUICard
              elementStyle="payment-type-card"
              cardHeading="Payment Type"
            >
              {/* <PaymentType /> */}
              <PaymentType uiType={uiType} />
            </PaddingLessUICard>
          </div>

          <div className="col-md-12 text-right defaultMarginTop">
            <InputButton
              elementWrapperStyle={"defaultHalfMarginLeftRight"}
              btnText="Print"
              onClickBtnFn={() => setOpenPrint(true)}
            />
          </div>

          <DialogPopup
            isDialogActions={false}
            dialogTitle="Print Receipt Confirmation"
            isFullWidth={false}
            isFullScreen={false}
            isCloseButton={true}
            children={
              <PrintReceiptConfirmation
                onClickSuccessFn={() => setOpenPrint(false)}
              />
            }
            isOpen={openPrint}
            onClosePopupFn={() => setOpenPrint(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentUI;
