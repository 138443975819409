/*
 * @Author: Madhawa
 * @Date: 2020-08-10 12:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-11-11 19:50:24
 */
import React, { Fragment, useContext, useEffect, useState } from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { DmtInformation } from "./includes/DmtInformation";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { ErlInformation } from "./includes/ErlInformation";
import { Action } from "./includes/Action";
import { Reason } from "./includes/Reason";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { PreviousOwnerInformation } from "./includes/PreviousOwnerInformation";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";

import { transferVehicleAPI, uploadDetailGetAPI, getDMTTransferListForAVehicleAPI, getDMTDataListAPI } from "../../../../config/apiUrl.config";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { previousOwnerInformationAPI } from '../../../../config/apiUrl.config';
import { RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from '../../../../config/enum.config';
import { getLabelValue } from "../../../../helpers/common-helpers/common.helpers";
import { recomposeColor } from "@material-ui/core";
import { isEmpty } from "lodash";
const VehicleRequestSubmitBtn = (props) => {

    const [formState] = useContext(FormContext);
    const [authStatus] = useContext(AuthContext);
    const [, uiAction] = useContext(UIContext);
    const { sessionId } = props
    const serviceId = _get(authStatus, "authUser.service_id", "");
    const userId = _get(authStatus, "authUser.id", "0000000");
    const { contact_number, name, nic } = _get(authStatus, "vehicle_search_form.data.owner_id", {});
    // console.log('aaaaaa', authData);
    const vehicle_no = _get(
        formState,
        "vehicle_search_form.searchKey",
        null
    );
    const current_licence_type = _get(
        formState,
        "vehicle_search_form.data.current_license_type.code",
        null
    );
    const currentOwnerInfo = _get(
        formState,
        "vehicle_search_form.data.owner_id",
        null
    );
    const authorityId = _get(authStatus, "authUser.authorityId", "");

    const approvalAction = _get(
        formState,
        "transfer_vehicle.action",
        null
    )
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    const userName = _get(authStatus, "authUser.name", "<anonymous name>");
    const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
        _get(authStatus, "authUser.provinceName", "<anonymous province>") :
        ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
    const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
                startIcon={"far fa-save"}
                formGroupName="transfer_vehicle"
                tooltip={"Submit"}
                isValidate={true}
                isSetHeaders={true}
                flashMessages={{
                    success: props.uiType === 'assign&Perform' ? {
                        status: true,
                        message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
                        messageType: "success",
                    } : {
                        status: true,
                        message: `Approval Request successfully submitted. Request ID : ${recId}.`,
                        messageType: "success",
                    },
                    failed: props.uiType === 'assign&Perform' ? {
                        status: true,
                        message: `Approval Request Decision Submit Failed. Please Try Again.`,
                        messageType: "error",
                    } : {
                        // status: true,
                        // message: `Approval Request Submit Failed. Please Try Again.`,
                        // messageType: "error",
                    },
                }}
                validationObject={props.uiType === 'assign&Perform' && _get(formState, "transfer_vehicle.action", "") === 'REJ' ? {
                    fileds: {
                        remark: "Remark",
                    },
                    rules: {
                        remark: "required|max:500",
                    },
                    message: {
                        "remark.required": "Remark is required.",
                    }
                } : {
                    fileds: props.uiType === 'assign&Perform' ? (_get(formState, "transfer_vehicle.action", "") === 'FRD' ? (_get(formState, "transfer_vehicle.forward_role", "") === 'OPT' ?
                        {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                            forward_authority: "Authority",
                        } : {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",

                        }) : {
                        reason_id: "Reason",
                        action: "Action",
                        remark: "Remark",
                    }) : {
                        "reason_id": "Reason",
                        "remark": "Remark",
                        "forward_province": "Forward Province",
                        "forward_role": "Forward Role",
                        "action": "Action",

                    },
                    rules: props.uiType === 'assign&Perform' ? (_get(formState, "transfer_vehicle.action", "") === 'FRD' ? (_get(formState, "transfer_vehicle.forward_role", "") === 'OPT' ? {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                        forward_authority: "required",
                    } : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                    }) : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                    }) : {
                        "reason_id": "required",
                        "remark": "required|max:500",
                        "forward_province": "required",
                        "forward_role": "required",
                        "action": "required",

                    },
                    message: props.uiType === 'assign&Perform' ? (_get(formState, "transfer_vehicle.action", "") === 'FRD' ? (_get(formState, "transfer_vehicle.forward_role", "") === 'OPT' ? {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province.required": "Province is required",
                        "forward_authority.required": "Authority is required",
                    } : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province.required": "Province is required",
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "remark.required": "Remark is required.",
                        "forward_province.required": "Forward Province is required.",
                        "forward_role.required": "Forward Role is required.",
                        "action.required": "Action is required.",

                    },
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${transferVehicleAPI.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
                        key: transferVehicleAPI.key,
                    };
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    let errP = false
                    let errE = false

                    const previous_owners_bulk_list = [];
                    const current_erls_bulk_list = [];
                    console.log("----------------------4444444-----------------------------", fromObject)

                    fromObject.previous_owners && fromObject.previous_owners.length !== 0 &&
                        Object.keys(fromObject.previous_owners).forEach((value) => {
                            if (Object.keys(fromObject.previous_owners[0]).length > 0 && !fromObject.previous_owners[value].transferred_date && errE === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Transferred Date is required except for the last current owner information record`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errP = true
                                return
                            }
                            if (Object.keys(fromObject.previous_owners[0]).length > 0 && !fromObject.previous_owners[value].name && errE === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Name is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errP = true
                                return
                            }
                            if (Object.keys(fromObject.previous_owners[0]).length > 0 && !fromObject.previous_owners[value].address1 && errE === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Address line 1 is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errP = true
                                return
                            }
                            if (Object.keys(fromObject.previous_owners[0]).length > 0 && !fromObject.previous_owners[value].city && errE === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `City is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errP = true
                                return
                            }
                            if (Object.keys(fromObject.previous_owners[0]).length > 0 && !fromObject.previous_owners[value].province && errE === false) {

                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Province is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errP = true
                                return
                            }
                            if ((fromObject.previous_owners[value].transferred_date ||
                                fromObject.previous_owners[value].name ||
                                fromObject.previous_owners[value].nic || fromObject.previous_owners[value].mobile_number ||
                                fromObject.previous_owners[value].address1 || fromObject.previous_owners[value].address2 ||
                                fromObject.previous_owners[value].city || fromObject.previous_owners[value].province)) {
                                previous_owners_bulk_list.push({
                                    name: fromObject.previous_owners[value].name,
                                    nic: fromObject.previous_owners[value].nic,
                                    mobile_number: fromObject.previous_owners[value].mobile_number,
                                    address1: fromObject.previous_owners[value].address1,
                                    address2: fromObject.previous_owners[value].address2,
                                    city: fromObject.previous_owners[value].city,
                                    province: fromObject.previous_owners[value].province,
                                    transferred_date: !fromObject.previous_owners[value].transferred_date &&
                                        (fromObject.previous_owners[value].name ||
                                            fromObject.previous_owners[value].nic || fromObject.previous_owners[value].mobile_number ||
                                            fromObject.previous_owners[value].address1 || fromObject.previous_owners[value].address2 ||
                                            fromObject.previous_owners[value].city || fromObject.previous_owners[value].province) ? new Date() : fromObject.previous_owners[value].transferred_date
                                });
                            }
                            // previous_owners_bulk_list.push({
                            //     name: fromObject.previous_owners[value].name !== undefined && fromObject.previous_owners[value].name,
                            //     nic: fromObject.previous_owners[value].nic !== undefined && fromObject.previous_owners[value].nic,
                            //     mobile_number: fromObject.previous_owners[value].mobile_number !== undefined && fromObject.previous_owners[value].mobile_number,
                            //     address1: fromObject.previous_owners[value].address1 !== undefined && fromObject.previous_owners[value].address1,
                            //     address2: fromObject.previous_owners[value].address2 !== undefined && fromObject.previous_owners[value].address2,
                            //     city: fromObject.previous_owners[value].city !== undefined && fromObject.previous_owners[value].city,
                            //     province: fromObject.previous_owners[value].province !== undefined && fromObject.previous_owners[value].province,
                            //     transferred_date: !fromObject.previous_owners[value].transferred_date &&
                            //     (fromObject.previous_owners[value].name ||
                            //         fromObject.previous_owners[value].nic || fromObject.previous_owners[value].mobile_number ||
                            //         fromObject.previous_owners[value].address1 || fromObject.previous_owners[value].address2 ||
                            //         fromObject.previous_owners[value].city || fromObject.previous_owners[value].province) ? new Date() :  fromObject.previous_owners[value].transferred_date !== undefined && fromObject.previous_owners[value].transferred_date
                            // });
                        });

                    fromObject.previous_owners_data && fromObject.previous_owners_data.length !== 0 &&
                        fromObject.previous_owners_data.forEach((value, index) => {
                            fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 &&
                                fromObject.previous_owners_data_new.forEach((value2, index2) => {

                                    if (Object.keys(value2).length > 0 && value2.name === "" && errE === false) {
                                        setTimeout(() => {
                                            uiAction.setFlashMessage({
                                                status: true,
                                                message: `Name is required`,
                                                messageType: "error",
                                            });
                                        }, 3000)

                                        errP = true
                                        return
                                    }
                                    if (Object.keys(value2).length > 0 && value2.address1 === "" && errE === false) {
                                        setTimeout(() => {
                                            uiAction.setFlashMessage({
                                                status: true,
                                                message: `Address line 1 is required`,
                                                messageType: "error",
                                            });
                                        }, 3000)

                                        errP = true
                                        return
                                    }
                                    if (Object.keys(value2).length > 0 && value2.city === "" && errE === false) {
                                        setTimeout(() => {
                                            uiAction.setFlashMessage({
                                                status: true,
                                                message: `City is required`,
                                                messageType: "error",
                                            });
                                        }, 3000)

                                        errP = true
                                        return
                                    }
                                    if (Object.keys(value2).length > 0 && index === index2 && !value.province && !value2.province && errE === false) {
                                        setTimeout(() => {
                                            uiAction.setFlashMessage({
                                                status: true,
                                                message: `Province is required`,
                                                messageType: "error",
                                            });
                                        }, 3000)

                                        errP = true
                                        return
                                    }
                                    if (index === index2) {
                                        previous_owners_bulk_list.push({
                                            vehicle_owner_id: value.id,
                                            name: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].name ? fromObject.previous_owners_data_new[index].name : getLabelValue(value.name, true, true),
                                            nic: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].nic ? fromObject.previous_owners_data_new[index].nic : getLabelValue(value.nic, true, true),
                                            mobile_number: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].mobile_number ? fromObject.previous_owners_data_new[index].mobile_number : (props.uiType === 'assign&Perform' ? getLabelValue(value.mobile_number, true, true) : getLabelValue(value.contact_number, true, true)),
                                            address1: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].address1 ? fromObject.previous_owners_data_new[index].address1 : (props.uiType === 'assign&Perform' ? getLabelValue(value.address1, true, true) : getLabelValue(value.address_line_1, true, true)),
                                            address2: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].address2 ? fromObject.previous_owners_data_new[index].address2 : (props.uiType === 'assign&Perform' ? getLabelValue(value.address2, true, true) : getLabelValue(value.address_line_2, true, true)),
                                            city: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].city ? fromObject.previous_owners_data_new[index].city : value.city,
                                            province: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].province ? fromObject.previous_owners_data_new[index].province : value.province,
                                            transferred_date: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].transferred_date ? fromObject.previous_owners_data_new[index].transferred_date : (value.transferred_on ? value.transferred_on : (props.uiType === 'assign&Perform' ? value.transferred_date : new Date()))
                                        });
                                    }

                                })
                        })

                    if (props.uiType === 'assign&Perform') {
                        fromObject.previous_owners_added_data && fromObject.previous_owners_added_data.length !== 0 &&
                            fromObject.previous_owners_added_data.forEach((value, index) => {
                                fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 &&
                                    fromObject.previous_owners_data_new.forEach((value2, index2) => {

                                        if (Object.keys(value2).length > 0 && value2.name === "" && errE === false) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `Name is required`,
                                                    messageType: "error",
                                                });
                                            }, 3000)

                                            errP = true
                                            return
                                        }
                                        if (Object.keys(value2).length > 0 && value2.address1 === "" && errE === false) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `Address line 1 is required`,
                                                    messageType: "error",
                                                });
                                            }, 3000)

                                            errP = true
                                            return
                                        }
                                        if (Object.keys(value2).length > 0 && value2.city === "" && errE === false) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `City is required`,
                                                    messageType: "error",
                                                });
                                            }, 3000)

                                            errP = true
                                            return
                                        }
                                        if (Object.keys(value2).length > 0 && index === index2 && !value.province && !value2.province && errE === false) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `Province is required`,
                                                    messageType: "error",
                                                });
                                            }, 3000)

                                            errP = true
                                            return
                                        }
                                        if (index === index2) {
                                            previous_owners_bulk_list.push({
                                                vehicle_owner_id: value.vehicle_owner_id,
                                                name: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].name ? fromObject.previous_owners_data_new[index].name : getLabelValue(value.name, true, true),
                                                nic: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].nic ? fromObject.previous_owners_data_new[index].nic : getLabelValue(value.nic, true, true),
                                                mobile_number: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].mobile_number ? fromObject.previous_owners_data_new[index].mobile_number : (props.uiType === 'assign&Perform' ? getLabelValue(value.mobile_number, true, true) : getLabelValue(value.contact_number, true, true)),
                                                address1: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].address1 ? fromObject.previous_owners_data_new[index].address1 : (props.uiType === 'assign&Perform' ? getLabelValue(value.address1, true, true) : getLabelValue(value.address_line_1, true, true)),
                                                address2: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].address2 ? fromObject.previous_owners_data_new[index].address2 : (props.uiType === 'assign&Perform' ? getLabelValue(value.address2, true, true) : getLabelValue(value.address_line_2, true, true)),
                                                city: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].city ? fromObject.previous_owners_data_new[index].city : value.city,
                                                province: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].province ? fromObject.previous_owners_data_new[index].province : value.province,
                                                transferred_date: fromObject.previous_owners_data_new && fromObject.previous_owners_data_new.length !== 0 && fromObject.previous_owners_data_new[index] && fromObject.previous_owners_data_new[index].transferred_date ? fromObject.previous_owners_data_new[index].transferred_date : (value.transferred_on ? value.transferred_on : (props.uiType === 'assign&Perform' ? value.transferred_date : new Date()))
                                            });
                                        }

                                    })
                            })
                    }

                    fromObject.current_erl && fromObject.current_erl.length !== 0 &&
                        Object.keys(fromObject.current_erl).forEach((value) => {
                            if (Object.keys(fromObject.current_erl[0]).length > 0 && errP === false && (value != (Object.keys(fromObject.current_erl).length - 1)) && !fromObject.current_erl[value].transferred_date) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Transferred Date is required except for the last current owner information record`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errE = true
                                return
                            }
                            if (errP === false && (!fromObject.current_erl_new || fromObject.current_erl_new.length === 0) && Object.keys(fromObject.current_erl[0]).length > 0 && !fromObject.current_erl[value].transferred_date) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Transferred Date is required except for the last current owner information record`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errE = true
                                return
                            }
                            if (Object.keys(fromObject.current_erl[0]).length > 0 && !fromObject.current_erl[value].name && errP === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Name is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errE = true
                                return
                            }
                            if (Object.keys(fromObject.current_erl[0]).length > 0 && !fromObject.current_erl[value].address1 && errP === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Address line 1 is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errE = true
                                return
                            }
                            if (Object.keys(fromObject.current_erl[0]).length > 0 && !fromObject.current_erl[value].city && errP === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `City is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errE = true
                                return
                            }
                            if (Object.keys(fromObject.current_erl[0]).length > 0 && !fromObject.current_erl[value].province && errP === false) {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Province is required`,
                                        messageType: "error",
                                    });
                                }, 3000)

                                errE = true
                                return
                            }
                            if (fromObject.current_erl[value].address1 || fromObject.current_erl[value].address2 || fromObject.current_erl[value].city ||
                                fromObject.current_erl[value].province || fromObject.current_erl[value].transferred_date) {
                                current_erls_bulk_list.push({
                                    name: fromObject.current_erl[value].name,
                                    nic: fromObject.current_erl[value].nic,
                                    mobile_number: fromObject.current_erl[value].mobile_no,
                                    address1: fromObject.current_erl[value].address1,
                                    address2: fromObject.current_erl[value].address2,
                                    city: fromObject.current_erl[value].city,
                                    transferred_date: fromObject.current_erl[value].transferred_date,
                                    province: fromObject.current_erl[value].province
                                });
                            }
                            // if(!fromObject.current_erl_new || fromObject.current_erl_new.length === 0) {
                            //     setTimeout(() => {
                            //         uiAction.setFlashMessage({
                            //             status: true,
                            //             message: `Transferred Date is required except for the last current owner information record`,
                            //             messageType: "error",
                            //           });
                            //     }, 3000)

                            //       errE = true
                            //       return
                            // }
                            // current_erls_bulk_list.push({
                            //     name: name,
                            //     nic: nic,
                            //     mobile_number: contact_number,
                            //     address1: fromObject.current_erl[value].address1,
                            //     address2: fromObject.current_erl[value].address2,
                            //     city: fromObject.current_erl[value].city,
                            //     transferred_date: !fromObject.current_erl[value].transferred_date && (fromObject.current_erl[value].address1 || fromObject.current_erl[value].address2 ||
                            //         fromObject.current_erl[value].city || fromObject.current_erl[value].province) ? new Date() : fromObject.current_erl[value].transferred_date,
                            //     province: fromObject.current_erl[value].province
                            // });
                        });


                    if (props.uiType === 'assign&Perform') {
                        fromObject.current_erls && fromObject.current_erls.length !== 0 ?
                            fromObject.current_erls.forEach((value1, index1) => {

                                fromObject.current_erl_new && fromObject.current_erl_new.length !== 0 &&
                                    fromObject.current_erl_new.forEach((value, index) => {
                                        if (value.transferred_date === undefined && props.uiType !== 'assign&Perform' &&
                                            ((fromObject.current_erl.length === 0 && errP === false) || (fromObject.current_erl.length !== 0 && fromObject.current_erl[0].name === undefined && errP === false))) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `Transferred Date is required except for the last current owner information record`,
                                                    messageType: "error",
                                                });
                                            }, 3000)
                                            errE = true
                                            return
                                        }

                                        if (Object.keys(value).length > 0 && value.name === "" && errP === false) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `Name is required`,
                                                    messageType: "error",
                                                });
                                            }, 3000)

                                            errE = true
                                            return
                                        }
                                        if (Object.keys(value).length > 0 && value.address1 === "" && errP === false) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `Address line 1 is required`,
                                                    messageType: "error",
                                                });
                                            }, 3000)

                                            errE = true
                                            return
                                        }
                                        if (Object.keys(value).length > 0 && value.city === "" && errP === false) {
                                            setTimeout(() => {
                                                uiAction.setFlashMessage({
                                                    status: true,
                                                    message: `City is required`,
                                                    messageType: "error",
                                                });
                                            }, 3000)

                                            errE = true
                                            return
                                        }
                                        if (index === index1) {
                                            current_erls_bulk_list.push({
                                                vehicle_owner_id: value1.vehicle_owner_id,
                                                name: value.name ? value.name : value1 && value1.name && value1.name !== null ? getLabelValue(value1.name, true, true) : "",
                                                nic: value.nic ? value.nic : value1 && value1.nic && value1.nic !== null ? getLabelValue(value1.nic, true, true) : "",
                                                mobile_number: value.mobile_no ? value.mobile_no : value1 && value1.mobile_number && value1.mobile_number !== null ? value1.mobile_number : "",
                                                address1: value.address1 ? value.address1 : value1 && value1.address1 && value1.address1 !== null ? getLabelValue(value1.address1, true, true) : "",
                                                address2: value.address2 ? value.address2 : value1 && value1.address2 && value1.address2 !== null ? getLabelValue(value1.address2, true, true) : "",
                                                city: value.city ? value.city : value1 && value1.city && value1.city !== null ? value1.city : "",
                                                province: value.province ? value.province : value1 && value1.province && value1.province !== null ? value1.province : null,
                                                transferred_date: value.transferred_date ? value.transferred_date : value1 && value1.transferred_date ? new Date(value1.transferred_date) : new Date()
                                            });
                                        }
                                    })
                            }) :

                            fromObject.current_erl_new && fromObject.current_erl_new.length !== 0 &&
                            fromObject.current_erl_new.forEach((value) => {
                                if (value.transferred_date === undefined && props.uiType !== 'assign&Perform' &&
                                    ((fromObject.current_erl.length === 0 && errP === false) || (fromObject.current_erl.length !== 0 && fromObject.current_erl[0].name === undefined && errP === false))) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Transferred Date is required except for the last current owner information record`,
                                            messageType: "error",
                                        });
                                    }, 3000)
                                    errE = true
                                    return
                                }

                                if (Object.keys(value).length > 0 && value.name === "" && errP === false) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Name is required`,
                                            messageType: "error",
                                        });
                                    }, 3000)

                                    errE = true
                                    return
                                }
                                if (Object.keys(value).length > 0 && value.address1 === "" && errP === false) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Address line 1 is required`,
                                            messageType: "error",
                                        });
                                    }, 3000)

                                    errE = true
                                    return
                                }
                                if (Object.keys(value).length > 0 && value.city === "" && errP === false) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `City is required`,
                                            messageType: "error",
                                        });
                                    }, 3000)

                                    errE = true
                                    return
                                }
                                current_erls_bulk_list.push({
                                    vehicle_owner_id: currentOwnerInfo.id,
                                    name: value.name ? value.name : currentOwnerInfo && currentOwnerInfo.name && currentOwnerInfo.name !== null ? getLabelValue(currentOwnerInfo.name, true, true) : "",
                                    nic: value.nic ? value.nic : currentOwnerInfo && currentOwnerInfo.nic && currentOwnerInfo.nic !== null ? getLabelValue(currentOwnerInfo.nic, true, true) : "",
                                    mobile_number: value.mobile_no ? value.mobile_no : currentOwnerInfo && currentOwnerInfo.contact_number && currentOwnerInfo.contact_number !== null ? currentOwnerInfo.contact_number : "",
                                    address1: value.address1 ? value.address1 : currentOwnerInfo && currentOwnerInfo.address_line_1 && currentOwnerInfo.address_line_1 !== null ? getLabelValue(currentOwnerInfo.address_line_1, true, true) : "",
                                    address2: value.address2 ? value.address2 : currentOwnerInfo && currentOwnerInfo.address_line_2 && currentOwnerInfo.address_line_2 !== null ? getLabelValue(currentOwnerInfo.address_line_2, true, true) : "",
                                    city: value.city ? value.city : currentOwnerInfo && currentOwnerInfo.city && currentOwnerInfo.city !== null ? currentOwnerInfo.city : "",
                                    province: value.province ? value.province : currentOwnerInfo && currentOwnerInfo.province && currentOwnerInfo.province !== null ? currentOwnerInfo.province : null,
                                    transferred_date: value.transferred_date ? value.transferred_date : currentOwnerInfo && currentOwnerInfo.transferred_on ? new Date(currentOwnerInfo.transferred_on) : new Date()
                                });
                            })

                    } else {
                        fromObject.current_erl_new && fromObject.current_erl_new.length !== 0 &&
                            fromObject.current_erl_new.forEach((value) => {
                                if (value.transferred_date === undefined && props.uiType !== 'assign&Perform' &&
                                    ((fromObject.current_erl.length === 0 && errP === false) || (fromObject.current_erl.length !== 0 && fromObject.current_erl[0].name === undefined && errP === false))) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Transferred Date is required except for the last current owner information record`,
                                            messageType: "error",
                                        });
                                    }, 3000)
                                    errE = true
                                    return
                                }

                                if (Object.keys(value).length > 0 && value.name === "" && errP === false) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Name is required`,
                                            messageType: "error",
                                        });
                                    }, 3000)

                                    errE = true
                                    return
                                }
                                if (Object.keys(value).length > 0 && value.address1 === "" && errP === false) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Address line 1 is required`,
                                            messageType: "error",
                                        });
                                    }, 3000)

                                    errE = true
                                    return
                                }
                                if (Object.keys(value).length > 0 && value.city === "" && errP === false) {
                                    setTimeout(() => {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `City is required`,
                                            messageType: "error",
                                        });
                                    }, 3000)

                                    errE = true
                                    return
                                }
                                current_erls_bulk_list.push({
                                    vehicle_owner_id: currentOwnerInfo.id,
                                    name: value.name ? value.name : currentOwnerInfo && currentOwnerInfo.name && currentOwnerInfo.name !== null ? getLabelValue(currentOwnerInfo.name, true, true) : "",
                                    nic: value.nic ? value.nic : currentOwnerInfo && currentOwnerInfo.nic && currentOwnerInfo.nic !== null ? getLabelValue(currentOwnerInfo.nic, true, true) : "",
                                    mobile_number: value.mobile_no ? value.mobile_no : currentOwnerInfo && currentOwnerInfo.contact_number && currentOwnerInfo.contact_number !== null ? currentOwnerInfo.contact_number : "",
                                    address1: value.address1 ? value.address1 : currentOwnerInfo && currentOwnerInfo.address_line_1 && currentOwnerInfo.address_line_1 !== null ? getLabelValue(currentOwnerInfo.address_line_1, true, true) : "",
                                    address2: value.address2 ? value.address2 : currentOwnerInfo && currentOwnerInfo.address_line_2 && currentOwnerInfo.address_line_2 !== null ? getLabelValue(currentOwnerInfo.address_line_2, true, true) : "",
                                    city: value.city ? value.city : currentOwnerInfo && currentOwnerInfo.city && currentOwnerInfo.city !== null ? currentOwnerInfo.city : "",
                                    province: value.province ? value.province : currentOwnerInfo && currentOwnerInfo.province && currentOwnerInfo.province !== null ? currentOwnerInfo.province : null,
                                    transferred_date: value.transferred_date ? value.transferred_date : currentOwnerInfo && currentOwnerInfo.transferred_on ? new Date(currentOwnerInfo.transferred_on) : new Date()
                                });
                            })
                    }

                    const previous_owners_bulk_list_new = previous_owners_bulk_list.filter(element => {
                        if (Object.keys(element).length !== 0) {
                            return true;
                        }

                        return false;
                    });

                    const current_erls_bulk_list_new = current_erls_bulk_list.filter(element => {
                        if (Object.keys(element).length !== 0) {
                            return true;
                        }

                        return false;
                    });

                    if (props.uiType === 'assign&Perform') {
                        if (_get(formState, "transfer_vehicle.action", undefined) === undefined) {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Action is required`,
                                messageType: "error",
                            });

                            return
                        }
                        const isCityNull = previous_owners_bulk_list_new.filter(e => e.city === null)
                        if (isCityNull.length === 0) {
                            if (errP === false && errE === false) {
                                return getDataByFormObject({
                                    ...fromObject,
                                    userName: userName,
                                    currentProvince: currentProvince,
                                    authority: authority,
                                    requestType: RequestTypesList["vcl_transfer"],
                                    ui_request_id: props.approvalRequestId,
                                    // request_id: recId,
                                    pdmt_id: pdmtId,
                                    // authority_id: authorityId,
                                    reason_id: formState['transfer_vehicle-new'].reason_id,
                                    current_licence_type: current_licence_type,
                                    previous_owners: previous_owners_bulk_list_new &&
                                        previous_owners_bulk_list_new.length > 0 ? previous_owners_bulk_list_new : [],
                                    current_erl: current_erls_bulk_list_new &&
                                        current_erls_bulk_list_new.length > 0 ? current_erls_bulk_list_new : [],
                                    counter_session_id: '00001',
                                    forward_authority_service_id: serviceId,
                                    is_reissuance: fromObject.isReissuanceRequired ? fromObject.isReissuanceRequired : false,
                                    action: fromObject.action == 'FRD' ? {
                                        action: fromObject.action,
                                        remark: fromObject.remark,
                                        forward_province: fromObject.forward_province,
                                        forward_role: fromObject.forward_role,
                                        forward_authority: fromObject.forward_authority
                                    } : {
                                        action: fromObject.action,
                                        remark: fromObject.remark,
                                    },
                                    document_details: [],
                                });
                            }
                        } else {
                            setTimeout(() => {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `City is required`,
                                    messageType: "error",
                                });
                            }, 5000)
                        }


                    } else {
                        if (currentOwnerInfo && currentOwnerInfo.name !== null) {
                            const isCityNull = previous_owners_bulk_list_new.filter(e => e.city === null)
                            if (isCityNull.length === 0) {
                                if (errP === false && errE === false) {
                                    return getDataByFormObject({
                                        ...fromObject,
                                        userName: userName,
                                        currentProvince: currentProvince,
                                        authority: authority,
                                        requestType: RequestTypesList["vcl_transfer"],
                                        request_id: recId,
                                        pdmt_id: pdmtId,
                                        authority_id: authorityId,
                                        reason_id: fromObject.reason_id,
                                        current_licence_type: current_licence_type,
                                        previous_owners: previous_owners_bulk_list_new,
                                        current_erl: current_erls_bulk_list_new,
                                        counter_session_id: sessionId ? sessionId : null,
                                        forward_authority_service_id: serviceId,
                                        is_reissuance: fromObject.isReissuanceRequired ? fromObject.isReissuanceRequired : false,
                                        action: {
                                            action: fromObject.action,
                                            remark: fromObject.remark,
                                            forward_province: fromObject.forward_province,
                                            forward_role: fromObject.forward_role,
                                            forward_authority: fromObject.forward_authority
                                        },
                                        document_details: [
                                        ],
                                        // assigned_user_id: userId
                                    });
                                }
                            } else {
                                setTimeout(() => {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `City is required`,
                                        messageType: "error",
                                    });
                                }, 5000)
                            }


                        } else {
                            uiAction.setFlashMessage({
                                status: true,
                                message: 'There must be a value for current owner name. Please check with owner info',
                                messageType: "error",
                            });
                        }

                    }





                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: props.uiType === 'assign&Perform' ? "put" : "post",
                }}

                onResponseCallBackFn={(response, error) => {
                    if (!isEmpty(response.data.errors)) {
                        if (props.uiType !== 'assign&Perform') {
                            if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: response.data.errors.details[0].message,
                                    messageType: "error",
                                });
                            }
                            else {
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Approval Request Submit Failed. Please Try Again.`,
                                    messageType: "error",
                                });
                            }
                        }
                    }
                    if (
                        response.length === undefined &&
                        error === undefined &&
                        response.data.errors === undefined
                    ) {
                        if (props.uiType !== 'assign&Perform') {
                            props.refreshMainScreenDataFn()
                            props.onClosePopupFn();
                        } else {
                            props.refreshMainScreenAfterPerformFn()
                            props.onClosePopupFn();
                        }
                    }
                }}
            />
        </Fragment>
    );
};



let recId = "";
const TransferVehicleUI = (props) => {
    const formKey = props.formKey;
    const uiType = props.uiType;
    const dataSubmittedForApproval = props.dataSubmittedForApproval
    const [formState, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    const [loading, setLoading] = useState(false);
    const [requestId, setRequestId] = useState(null);
    const isTransferred = _get(formState, "vehicle_search_form.data.vehicle_transferred", false) === 0 ? false : true
    console.log('PLOPPPPS', props);
    useEffect(() => {
        const rId = new Date();
        const requ = rId.valueOf().toString();
        setRequestId(requ.substr(requ.length - 8))

    }, []);

    useEffect(() => {
        formAction.changeInputFn(formKey, "previous_owners", "", { "0": {} })
        formAction.changeInputFn(formKey, "current_erl", "", { "0": {} })
        // getRelatedData()
        getDmtTransfersData()
        if (props.uiType === 'APR') {
            getApprovalRelatedData()
            getDmtPrevOwnerDataToApproval()
        } else {
            getReqRelatedData()
        }
    }, [])
    recId = requestId;

    const vehicle_no = _get(
        formState,
        "vehicle_search_form.searchKey",
        null
    );

    const getDmtPrevOwnerDataToApproval = async () => {
        await callApi(`${getDMTDataListAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, response) => {
                console.log('LOGHH-', response);
                if (!error) {
                    formAction.changeInputFn('vehicle_search_form', "data", "", response.data.data);
                }
            });
    }



    const getReqRelatedData = () => {
        const previousOwnerInfo = sendAPIRequest(`${previousOwnerInformationAPI.url}/${_get(formState, 'vehicle_search_form.data.vehicle_no', '')}`);

        Promise.all([previousOwnerInfo])
            .then(result => {
                formAction.changeInputFn(formKey, "previous_owners_data", "", result[0]["data"].sort((a, b) => {
                    const date1 = new Date(a['transferred_on'])
                    const date2 = new Date(b['transferred_on'])
                    return date1 - date2
                }))
                // result[0]["data"].map((value, index) => {
                //     const anOwnerInfo = {
                //         ...value,
                //     };
                //     // formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.previous_owners.${index + 1}.name`);
                //     // formAction.changeInputFn(formKey, "previous_owners", "", { ..._get(formState, "transfer_vehicle.previous_owners", {}), index: anOwnerInfo })

                // });
            })
    }

    const getApprovalRelatedData = async () => {
        console.log('OOPs', props);
        const previousOwnerInfo = await sendAPIRequest(`${transferVehicleAPI.url}/${props.approvalRequestId}`);
        const previousOwnerInfoOld = await sendAPIRequest(`${previousOwnerInformationAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`);

        Promise.all([previousOwnerInfo, previousOwnerInfoOld])
            .then(result => {
                console.log('REEE==', result);
                formAction.changeInputFn(formKey, "current_erls", "", result[0]["data"]['currentErls'].sort((a, b) => a['transferred_date'] - b['transferred_date']));
                formAction.changeInputFn(formKey, "isReissuanceRequired", "",
                    result[0]["data"]['currentErls'].length !== 0 ? result[0]["data"]['currentErls'][0]['reissuance_required'] :
                        result[0]["data"]['previousOwners'].length !== 0 ? result[0]["data"]['previousOwners'][0]['reissuance_required'] : 0);
                formAction.changeInputFn(formKey, "previous_owners_added_data", "", result[0]["data"]['previousOwners'].sort((a, b) => {
                    const date1 = new Date(a['transferred_date'])
                    const date2 = new Date(b['transferred_date'])
                    return date1 - date2
                }))

                formAction.changeInputFn(formKey, "previous_owners_data", "", [...result[1]["data"]].sort((a, b) => {
                    const date1 = new Date(a['transferred_on'])
                    const date2 = new Date(b['transferred_on'])
                    return date1 - date2
                }))
                // result[0]["data"].map((value, index) => {
                //     const anOwnerInfo = {
                //         ...value,
                //     };
                //     // formAction.changeInputFn("transfer_vehicle", "", `transfer_vehicle.previous_owners.${index + 1}.name`);
                //     // formAction.changeInputFn(formKey, "previous_owners", "", { ..._get(formState, "transfer_vehicle.previous_owners", {}), index: anOwnerInfo })

                // });
            }).catch(err => {
                console.log('ERR==>2222', err);
            })
    }


    // const getRelatedData = () => {
    //     console.log('LOGGGG', props.uiType);

    //     if(props.uiType === 'APR') {
    //         // getReqRelatedData()
    //     } else {
    //     }

    // };

    const sendAPIRequest = (apiUrl) => {
        return new Promise((resolve, reject) => {
            callApi(apiUrl)
                .headers(true)
                .method("get")
                .isMultipart(false)
                .send((error, result) => {
                    if (error) {
                        reject(error);
                    }

                    resolve(result?.data);
                })
        });
    };

    const getDmtTransfersData = () => {
        uiAction.setPageLoader(true)
        setLoading(true)
        callApi(`${getDMTTransferListForAVehicleAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`)
            .method("get")
            .headers(true)
            .isMultipart(false)
            .send((error, result) => {
                if (!error) {
                    console.log('RESSSS==>', result);
                    formAction.changeInputFn('dmt_transfers', "data", "", result.data.transferHistoryData);
                    uiAction.setPageLoader(false)

                    setLoading(false)
                } else {
                    formAction.changeInputFn('dmt_transfers', "data", "", []);
                    console.log('ERR==>', error);
                    uiAction.setPageLoader(false)

                    setLoading(false)
                }
            });
    }

    return (
        <div className="row">
            <div className="col-md-12 col-sm-12 defaultPaddingBottom">
                <DmtInformation
                    formGroupName={formKey}
                    isTransferred={isTransferred}
                    isLoading={loading}
                    tableName="DMT Value" {...props} />
            </div>
            <div className="col-md-12 col-sm-12 defaultPaddingBottom">
                <PreviousOwnerInformation
                    formGroupName={formKey}
                    {...props}
                />
            </div>
            <div className="col-md-12 col-sm-12 defaultPaddingBottom">
                <ErlInformation
                    formGroupName={formKey}
                    uiType={uiType}
                    dataSubmittedForApproval={dataSubmittedForApproval}
                    {...props}
                />
            </div>
            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                <Reason
                    filterKey={'vehicle_transfer'}
                    requestType={uiType}
                    dataSubmittedForApproval={props.dataSubmittedForApproval}
                    formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
                />
            </div>
            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                <DocumentsUploader
                    isUploadFn={true}
                    formGroupName={`${formKey}-upload`}
                    tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
                    requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
                    uiType={uiType}
                />
            </div>
            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                <Action dataSubmittedForApproval={props.dataSubmittedForApproval} formKey={formKey} uiType={uiType} />
            </div>


        </div>
        // </FormWrapper>

    );
};

const TransferVehicle = () => {

    return (
        <DashboardTemplate
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Transfer Vehicle" },
            ]}
            pageTitle="Transfer Vehicle"
        >

            <TransferVehicleUI />
        </DashboardTemplate >
    );
};


export default TransferVehicle;
export {
    TransferVehicleUI, VehicleRequestSubmitBtn
}