/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-02-28 17:31:46 
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-18 15:34:58
 */

import React, { Fragment } from 'react';
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import authorized from "../../hoc-middlewares/Authorized";
import VehicleInfo from './pages/VehicleInfo';

const VehicleRoutes = () => {
    return (
          <Fragment>
      <Navigate path="/vehicle-info" exact={true} routeKey={"vehicle-info"} component={authorized(VehicleInfo)} routePermissions={permissions.VEHICLE_DEALER_INFO.permissions}/>
    </Fragment>

    );
};

export default VehicleRoutes;
