/*
 * @Author: Madhawa
 * @Date: 2020-08-10 12:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-10-21 14:09:10
 */
import React, { Fragment, useContext, useEffect, useState } from "react";

import { uploadDetailGetAPI, reverseTransactionAPI } from "../../../../config/apiUrl.config";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { Action } from "./includes/Action";
import LastLicenceInformation from "./includes/LastLicenceInformation";
import LastTransactionInformation from "./includes/LastTransactionInformation";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { Reason } from "./includes/Reason";
import { RequestStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { isEmpty } from "lodash";
const ReverseTransactionsSubmitBtn = (props) => {
    const [formState] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const [, uiAction] = useContext(UIContext);
    const { sessionId } = props
    const user_role_level = _get(authStatus, "authUser.role_level_code", "");
    const vehicle_no = _get(
        formState,
        "vehicle_search_form.searchKey",
        null
    );
    const approvalAction = _get(
        formState,
        "ReverseLastTransactionKey.action",
        null
    )
    const userId = _get(authStatus, "authUser.id", "0000000");
    const serviceId = _get(authStatus, "authUser.service_id", "");
    const current_licence_type = _get(formState, "vehicle_search_form.data.current_license_type.code", null);
    const authorityId = _get(authStatus, "authUser.authorityId", "");
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    const transaction_id = _get(formState, `approvalRequest.tx_id`, '-')
    const licence_id = _get(formState, `approvalRequest.licence_id`, '-')

    const userName = _get(authStatus, "authUser.name", "<anonymous name>");
    const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
        _get(authStatus, "authUser.provinceName", "<anonymous province>") :
        ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
    const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
                startIcon={"far fa-save"}
                formGroupName={"ReverseLastTransactionKey"}
                tooltip={"Submit"}
                isValidate={true}
                isSetHeaders={true}
                flashMessages={{
                    success: props.uiType === 'assign&Perform' ? {
                        status: true,
                        message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
                        messageType: "success",
                    } : {
                        status: true,
                        message: `Approval Request successfully submitted. Request ID : ${recId}`,
                        messageType: "success",
                    },
                    // failed: {
                    //     status: true,
                    //     message: `Approval Request failed.`,
                    //     messageType: "error",
                    // },
                }}
                validationObject={props.uiType === 'assign&Perform' && _get(formState, "ReverseLastTransactionKey.action", "") === 'REJ' ? {
                    fileds: {
                        remark: "Remark",
                    },
                    rules: {
                        remark: "required|max:500",
                    },
                    message: {
                        "remark.required": "Remark is required.",
                    }
                } : {
                    fileds: props.uiType === 'assign&Perform' ? (_get(formState, "ReverseLastTransactionKey.action", "") === 'FRD' ? (_get(formState, "ReverseLastTransactionKey.forward_role", "") === 'OPT' ?
                        {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                            forward_authority: "Authority",
                        } : {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                        }) : {
                        reason_id: "Reason",
                        action: "Action",
                        remark: "Remark",
                    }) : {
                        "reason_id": "Reason",
                        "remark": "Remark",
                        "forward_province": "Forward Province",
                        "forward_role": "Forward Role",
                        "action": "Action",
                    },
                    rules: props.uiType === 'assign&Perform' ? (_get(formState, "ReverseLastTransactionKey.action", "") === 'FRD' ? (_get(formState, "ReverseLastTransactionKey.forward_role", "") === 'OPT' ? {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                        forward_authority: "required",
                    } : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                    }) : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                    }) : {
                        "reason_id": "required",
                        "remark": "required|max:500",
                        "forward_province": "required",
                        "forward_role": "required",
                        "action": "required",
                    },
                    message: props.uiType === 'assign&Perform' ? (_get(formState, "ReverseLastTransactionKey.action", "") === 'FRD' ? (_get(formState, "ReverseLastTransactionKey.forward_role", "") === 'OPT' ? {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province": "Province is required",
                        "forward_authority": "Authority is required",
                    } : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province": "Province is required",
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "remark.required": "Remark is required.",
                        "forward_province": "Forward Province is required.",
                        "forward_role": "Forward Role is required.",
                        "action": "Action is required.",

                    },
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: `${reverseTransactionAPI.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
                        key: reverseTransactionAPI.key,
                    };
                }}
                onChangeRequestBodyFn={(fromObject) => {

                    if (props.uiType === 'assign&Perform') {
                        if (_get(formState, "ReverseLastTransactionKey.action", undefined) === undefined) {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Action is required`,
                                messageType: "error",
                            });

                            return
                        }
                        return getDataByFormObject({
                            ...fromObject,
                            userName: userName,
                            currentProvince: currentProvince,
                            authority: authority,
                            requestType: RequestTypesList["reverse_last_txn"],
                            current_licence_type: current_licence_type,
                            ui_request_id: props.approvalRequestId,
                            // status: RequestStatus.APPROVAL_PENDING,
                            pdmt_id: pdmtId,
                            // authority_id: authorityId,
                            // request_id: recId,
                            // transaction_id: transaction_id,
                            // licence_id: licence_id,
                            forward_authority_service_id: serviceId,
                            reason_id: formState['ReverseLastTransactionKey-new'].reason_id,
                            counter_session_id: '00001',
                            document_details: [],
                            action: fromObject.action == 'FRD' ? {
                                action: fromObject.action,
                                remark: fromObject.remark,
                                forward_province: fromObject.forward_province,
                                forward_role: fromObject.forward_role,
                                forward_authority: fromObject.forward_authority
                            } : {
                                action: fromObject.action,
                                remark: fromObject.remark,
                            },
                        });

                    } else {
                        const createYear = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getFullYear()
                        const createMonth = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getMonth()
                        const createDay = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getDate()

                        const currentYear = new Date().getFullYear()
                        const currentMonth = new Date().getMonth()
                        const currentDay = new Date().getDate()

                        if (
                            createYear !== currentYear ||
                            createMonth !== currentMonth ||
                            createDay !== currentDay) {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `You can reverse today's transactions only.`,
                                messageType: "error",
                            });
                        } else {
                            // console.log('FOpppppppppppppppppppp', fromObject);
                            return getDataByFormObject({
                                ...fromObject,
                                userName: userName,
                                currentProvince: currentProvince,
                                authority: authority,
                                requestType: RequestTypesList["reverse_last_txn"],
                                current_licence_type: current_licence_type,
                                status: RequestStatus.APPROVAL_PENDING,
                                pdmt_id: pdmtId,
                                authority_id: authorityId,
                                request_id: recId,
                                transaction_id: transaction_id,
                                licence_id: licence_id,
                                reason_id: fromObject.reason_id,
                                counter_session_id: sessionId ? sessionId : null,
                                forward_authority_service_id: serviceId,
                                document_details: [],
                                action: {
                                    action: fromObject.action,
                                    remark: fromObject.remark,
                                    forward_province: fromObject.forward_province,
                                    forward_role: fromObject.forward_role,
                                    forward_authority: fromObject.forward_authority
                                },
                                assigned_user_id: userId
                            });
                        }
                    }

                    console.log('FROOOM_OBJ====', fromObject);


                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: props.uiType === 'assign&Perform' ? "put" : "post",
                }}

                onResponseCallBackFn={(response, error) => {
                    if (props.uiType === 'assign&Perform') {
                        if (
                            response.length === undefined &&
                            error === undefined &&
                            response.data.errors === undefined
                        ) {
                            props.refreshMainScreenAfterPerformFn()
                            props.onClosePopupFn();
                        }
                    } else {
                        const createYear = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getFullYear()
                        const createMonth = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getMonth()
                        const createDay = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getDate()

                        const currentYear = new Date().getFullYear()
                        const currentMonth = new Date().getMonth()
                        const currentDay = new Date().getDate()
                        if (
                            response.length === undefined &&
                            error === undefined &&
                            response.data.errors === undefined
                        ) {
                            if (
                                createYear !== currentYear ||
                                createMonth !== currentMonth ||
                                createDay !== currentDay) {
                                console.log('errr111');

                            } else {
                                props.refreshMainScreenDataFn()
                                props.onClosePopupFn();
                            }
                        }
                        else {
                            if (
                                createYear !== currentYear ||
                                createMonth !== currentMonth ||
                                createDay !== currentDay) {
                                console.log('errr2222');
                            } else {
                                if (props.uiType === 'assign&Perform') {
                                    uiAction.setFlashMessage({
                                        status: true,
                                        message: `Approval Request Decision Submit Failed. Please Try Again.`,
                                        messageType: "error",
                                    });
                                } else {
                                    if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: response.data.errors.details[0].message,
                                            messageType: "error",
                                        });
                                    }
                                    else {
                                        uiAction.setFlashMessage({
                                            status: true,
                                            message: `Approval Request Submit Failed. Please Try Again.`,
                                            messageType: "error",
                                        });
                                    }

                                }
                            }
                        }
                    }

                }}
            />
        </Fragment>
    );
};
let recId = "";
let isInvalidaReverse = false

const ReverseLastTransactionUI = (props) => {
    const [formState] = useContext(FormContext);

    const uiType = props.uiType;
    const formKey = "ReverseLastTransactionKey";

    const [requestId, setRequestId] = useState(null);
    useEffect(() => {
        const rId = new Date();
        const requ = rId.valueOf().toString();

        setRequestId(requ.substr(requ.length - 8))
    }, [])
    recId = requestId;

    const createYear = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getFullYear()
    const createMonth = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getMonth()
    const createDay = _get(formState, `payment_data.data.tx_date_time`, '01/01/1970') && new Date(_get(formState, `payment_data.data.tx_date_time`, '01/01/1970')).getDate()

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const currentDay = new Date().getDate()

    if (
        createYear !== currentYear ||
        createMonth !== currentMonth ||
        createDay !== currentDay) {
        isInvalidaReverse = true
    } else {
        isInvalidaReverse = false
    }
    return (
        <div className="row">


            <div className="col-md-12 col-sm-12 defaultPaddingTop ">

                <LastTransactionInformation
                    formGroupName={formKey} {...props} />


            </div>

            <div className="col-md-12 col-sm-12 defaultPaddingTop">

                <LastLicenceInformation
                    formGroupName={formKey} {...props} />

            </div>

            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                <Reason
                    filterKey={'reverse_last_transaction'}
                    requestType={uiType} dataSubmittedForApproval={props.dataSubmittedForApproval} formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
                />
            </div>

            <div className="col-md-12 col-sm-12 defaultPaddingTop">
                <DocumentsUploader
                    isUploadFn={true}
                    formGroupName={`${formKey}-upload`}
                    //tableApiUrl={`${uploadDetailSaveAPI.url}?request_id=${requestId}`}
                    tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
                    requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
                    uiType={uiType}
                />
            </div>

            <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
                <Action isReverseTxn={true} isInvalidaReverse={isInvalidaReverse} dataSubmittedForApproval={props.dataSubmittedForApproval} formKey={formKey} uiType={uiType} />
            </div>



        </div>
    );
};

const ReverseLastTransaction = () => {
    return (
        <DashboardTemplate
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Reverse Last Transaction" },
            ]}
            pageTitle="Reverse Last Transaction"
        >
            <ReverseLastTransactionUI />
        </DashboardTemplate>
    )
}

export default ReverseLastTransaction;
export { ReverseLastTransactionUI, ReverseTransactionsSubmitBtn };