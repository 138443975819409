import React, { Fragment, useState, useEffect, useContext } from "react";

import {
    CheckBoxWithState, DatePickerWithState, FormWrapper, InputBoxWithState,
    SelectBoxWithState, SubmitButton
} from "../../../ui-components/ui-elements/form";
import { LimitTextLength, UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { Reason } from "./includes/Reason";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { uploadDetailGetAPI, dealerVehicleTempAPI, insuranceListAPI, addDealerVehicleReqstAPI, districtListAPI, pdmtListAPI, insuranceCompany } from "../../../../config/apiUrl.config";
import { Action } from "./includes/Action";
import { DeleteVehicleNumberBtn, DeleteVehicleNumberUI } from "../../counter-session/pages/dealers-licence/includes/DeleteVehicleNumber";
import { apiResponseStoringType, CoreContext } from "../../core/context-providers/CoreContext.provider";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { RequestType, RequestTypesList, ActionTypeList, ProvincesForDealer, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";

let recId = "";
const today = new Date();
let currentProvince = ""
let currentProvinceCode = ""
let pdmt = ""
const AddDealer = (props) => {
    const { uiType, requestType, approvalRequestId, dataSubmittedForApproval } = props;
    const formKey = dealerVehicleTempAPI.key + "_create";
    const [requestId, setRequestId] = useState(null);
    const [formState, formAction] = useContext(FormContext);
    const [authStatus] = useContext(AuthContext);
    const [coreStatus] = useContext(CoreContext);
    console.log('FRRROOMM====', coreStatus);
    // const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "")
    pdmt = _get(authStatus, "authUser.provinceCode", null) !== null ? _get(authStatus, "authUser.provinceCode", null) : _get(authStatus, "authUser.pdmtCode", null);


    const dealer_id = _get(formState, "dealer_search_form.searchKey", null);
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    console.log('USERR', _get(authStatus, "authUser", ""));
    useEffect(() => {
        const rId = new Date();
        const requ = rId.valueOf().toString();
        setRequestId(props.uiType === "APR" ? props.approvalRequestId : requ.substr(requ.length - 8))
        newDataGet()
    }, []);

    useEffect(() => {
        if (props.uiType !== "APR") {
            if (_get(formState, `${formKey}.isSame`, null) === true) {
                formAction.changeInputFn(formKey, "address_line_1", "", _get(formState, `${formKey}.Office_address_line_1`, '') !== null ? _get(formState, `${formKey}.Office_address_line_1`, '') : '')
                formAction.changeInputFn(formKey, "address_line_2", "", _get(formState, `${formKey}.Office_address_line_2`, '') !== null ? _get(formState, `${formKey}.Office_address_line_2`, '') : '')
                formAction.changeInputFn(formKey, "city", "", _get(formState, `${formKey}.Office_address_city`, '') !== null ? _get(formState, `${formKey}.Office_address_city`, '') : '')
                formAction.changeInputFn(formKey, "district", "", _get(formState, `${formKey}.Office_address_district`, '') !== null ? _get(formState, `${formKey}.Office_address_district`, '') : '')
                formAction.changeInputFn(formKey, "validity_next_year", "", false)
            } else {
                formAction.changeInputFn(formKey, "address_line_1", "", "");
                formAction.changeInputFn(formKey, "address_line_2", "", "");
                formAction.changeInputFn(formKey, "city", "", "");
                formAction.changeInputFn(formKey, "district", "", "");
                formAction.changeInputFn(formKey, "province", "", _get(formState, `${formKey}.forward_province`, pdmt));
                formAction.changeInputFn(formKey, "validity_next_year", "", false)


            }
        }
        formAction.changeInputFn(formKey, "province", "", pdmt)
    }, [_get(formState, `${formKey}.isSame`, null)])



    recId = requestId;

    const getTagId = () => {
        switch (requestType) {
            case "Add_Dealer":
                return null
            default:
                return (
                    <div className={"defaultPaddingBottom"}><strong>Dealer ID:</strong>  {dealer_id}</div>
                );
        }
    }

    const newDataGet = () => {
        const sendAPIRequest = (apiUrl) => {
            return new Promise((resolve, reject) => {
                callApi(apiUrl)
                    .headers(true)
                    .method("get")
                    .isMultipart(false)
                    .send((error, result) => {
                        if (error) {
                            // console.log('ERRRRRRRRR====', error);
                            reject(error);
                        }

                        resolve(result?.data);
                    })
            });
        };
        const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);
        Promise.all([insuranceCompaniesPromise])
            .then(result => {
                const insuranceCompanies = result[0]['data']
                formAction.changeInputFn(formKey, "insuranceCompanies", "", insuranceCompanies);


            }).catch(error => {
                console.log('ERRRRRRRRR', error);
            });

    }



    currentProvince = _get(formState, `${formKey}.forward_province`, pdmtId)
    const endDate = new Date(_get(formState, `${formKey}.validity_from`, new Date())).setMonth(new Date(_get(formState, `${formKey}.validity_from`, new Date())).getMonth() + 12)
    const newEndDate = `31/12/${new Date().getFullYear()}`
    const nextYearDate = new Date().setFullYear(new Date().getFullYear() + 1)
    const validNextYearDate = `31/12/${new Date(nextYearDate).getFullYear()}`

    return (
        <div className={"container-fluid p-0"}>
            <div className={"row defaultMarginBottom"}>
                <FormWrapper
                    setGroupName={formKey}
                    apiUrl={`${addDealerVehicleReqstAPI.url}/${props.approvalRequestId}`}
                    onRebuildResponseFn={(response) => {
                        console.log('RRRR', response);
                        return {
                            dlr_id: _get(response, "id", "") !== null ? _get(response, "id", "") : '',
                            effective_date: _get(response, "effective_date", ""),
                            reason_id: _get(response, "work_flow_record.reason_id", ""),
                            name: _get(response, "name", "") !== null ? _get(response, "name", "") : '',
                            date_of_registration: _get(response, "date_of_registration", ""),
                            Office_address_line_1: _get(response, "Office_address_line_1", "") !== null ? _get(response, "Office_address_line_1", "") : '',
                            Office_address_line_2: _get(response, "Office_address_line_2", "") !== null ? _get(response, "Office_address_line_2", "") : '',
                            Office_address_city: _get(response, "Office_address_city", "") !== null ? _get(response, "Office_address_city", "") : '',
                            Office_address_district: _get(response, "Office_address_district", ""),
                            isSame: ((_get(response, "Office_address_line_1", "") === _get(response, "address_line_1", "")) &&
                                (_get(response, "Office_address_line_2", "") === _get(response, "address_line_2", "")) &&
                                (_get(response, "Office_address_city", "") === _get(response, "city", "")) &&
                                (_get(response, "Office_address_district", "") === _get(response, "district", ""))) ? 1 : 0,
                            address_line_1: _get(response, "address_line_1", "") !== null ? _get(response, "address_line_1", "") : '',
                            address_line_2: _get(response, "address_line_2", "") !== null ? _get(response, "address_line_2", "") : '',
                            city: _get(response, "city", "") !== null ? _get(response, "city", "") : '',
                            district: _get(response, "district", ""),
                            province: _get(response, "province", ""),
                            dmt_dealer_letter: _get(response, "dmt_dealer_letter", ""),
                            letter_issued_date: _get(response, "letter_issued_date", ""),
                            validity_next_year: _get(response, "validity_next_year", ""),
                            validity_from: _get(response, "validity_from", ""),
                            validity_to: _get(response, "validity_to", ""),
                        };
                    }}
                    setFormObject={{
                        validity_next_year: false
                    }}
                >
                    <div className={"col-md-6 floatLeft defaultPaddingTop"}>
                        <UICard
                            cardHeading={"Dealer Information"}

                        >
                            <InputBoxWithState
                                formGroupName={formKey}
                                labelText={"Name of Dealer/ Manufacturer/ Repairer"}
                                inputName={"name"}
                                isRequired={true}
                                maxLength={40}
                            />

                            <DatePickerWithState
                                isRequired={true}
                                formGroupName={formKey}
                                inputName={"date_of_registration"}
                                labelText={"Date of Registration"}
                                minDate={"01/01/1970"}
                                maxDate={new Date()}
                            />

                            <InputBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Office Address Line 1"}
                                inputName={"Office_address_line_1"}
                                isRequired={true}
                                maxLength={100}
                            />

                            <InputBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Office Address Line 2"}
                                inputName={"Office_address_line_2"}
                                isRequired={true}
                                maxLength={40}
                            />

                            <InputBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Office Address City"}
                                inputName={"Office_address_city"}
                                isRequired={true}
                                maxLength={40}
                            />

                            <SelectBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Office Address District"}
                                inputName={"Office_address_district"}
                                apiUrl={districtListAPI.url + `?province_id=${currentProvince}&sort_by=name|asc`}
                                apiStateKey={districtListAPI.key}
                                emptySelectOptionTxt={"Dealer's Office Address District"}
                                keyName="code"
                                valueName="name"
                                isRequired={true}
                            />

                            <CheckBoxWithState
                                formGroupName={formKey}
                                elementWrapperStyle={"checkBoxPadding"}
                                labelText={"Same as Above"}
                                inputName={"isSame"}
                                isRequired={true}
                            />

                            <InputBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Address Line 1"}
                                inputName={_get(formState, `${formKey}.isSame`, null) === true ? "Office_address_line_1" : "address_line_1"}
                                isRequired={true}
                                isDisabled={_get(formState, `${formKey}.isSame`, null) === true}
                                maxLength={100}
                            />

                            <InputBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Address Line 2"}
                                inputName={_get(formState, `${formKey}.isSame`, null) === true ? "Office_address_line_2" : "address_line_2"}
                                isDisabled={_get(formState, `${formKey}.isSame`, null) === true}
                                isRequired={true}
                                maxLength={40}
                            />

                            <InputBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Address City"}
                                inputName={_get(formState, `${formKey}.isSame`, null) === true ? "Office_address_city" : "city"}
                                isDisabled={_get(formState, `${formKey}.isSame`, null) === true}
                                isRequired={true}
                                maxLength={40}
                            />
                            <SelectBoxWithState
                                formGroupName={formKey}
                                labelText={"Dealer's Address District"}
                                emptySelectOptionTxt={"Dealer's Address District"}
                                inputName={_get(formState, `${formKey}.isSame`, null) === true ? "Office_address_district" : "district"}
                                apiUrl={districtListAPI.url + `?province_id=${currentProvince}&sort_by=name|asc`}
                                apiStateKey={districtListAPI.key}
                                isRequired={true}
                                keyName="code"
                                valueName="name"
                                isDisabled={_get(formState, `${formKey}.isSame`, null) === true}
                            />

                            <SelectBoxWithState
                                formGroupName={formKey}
                                labelText={"Province"}
                                emptySelectOptionTxt={"Province"}
                                inputName={"province"}
                                // inputValue={pdmt}
                                isRequired={true}
                                // apiUrl={pdmtListAPI.url + '?sort_by=name|asc'}
                                apiStateKey={pdmtListAPI.key}
                                dataList={ProvincesForDealer}
                                keyName="code"
                                valueName="service.name"
                                isDisabled={true}
                            />
                        </UICard>
                    </div>
                    <div className={"col-md-6 floatLeft defaultPaddingTop"}>
                        <UICard
                            cardHeading={"Document Validation"}
                            elementStyle={"defaultMarginBottom"}
                        >
                            <CheckBoxWithState
                                formGroupName={formKey}
                                elementWrapperStyle={"checkBoxPadding"}
                                labelText={"DMT Dealer's Letter"}
                                inputName={"dmt_dealer_letter"}
                            // isRequired={true}
                            />
                            <div className={"col-md-6"} style={{ marginLeft: '-13px' }}>
                                <DatePickerWithState
                                    isRequired={true}
                                    formGroupName={formKey}
                                    inputName={"letter_issued_date"}
                                    labelText={"DMT Dealer's Letter Issued Date"}
                                    minDate={'01/01/1970'}
                                    maxDate={new Date()}
                                    elementStyle={{ width: '50%' }}
                                />
                            </div>
                        </UICard>
                        <UICard
                            cardHeading={"Validity Period"}
                        >
                            <CheckBoxWithState
                                formGroupName={formKey}
                                elementWrapperStyle={"checkBoxPadding"}
                                labelText={"Next Year"}
                                inputName={"validity_next_year"}
                                isDisabled={new Date().getMonth() !== 12}
                            />

                            <div className={"row"}>
                                <div className={"col-md-6"} style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <strong className={"mr-2"}>From: </strong>
                                    <DatePickerWithState
                                        isRequired={true}
                                        formGroupName={formKey}
                                        inputName={"validity_from"}
                                        // labelText={"From"}
                                        minDate={'01/01/1970'}
                                        maxDate={new Date()}
                                        inputValue={new Date()}
                                    />
                                </div>

                                <div className={"col-md-6"} style={{ marginTop: 5 }}>
                                    <strong className={"mr-2"}>To: </strong>
                                    <LimitTextLength
                                        maxLenth={50}
                                        displayText={_get(formState, `${formKey}.validity_next_year`, false) === true ? validNextYearDate : newEndDate}
                                    />
                                </div>
                            </div>

                        </UICard>
                    </div>
                </FormWrapper>
            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <DataTable
                        apiUrl={`${dealerVehicleTempAPI.url}/${uiType === 'APR' ? props.approvalRequestId : requestId}`}
                        tableName={"Vehicle Number Information"}
                        dataTableKey={dealerVehicleTempAPI.key}
                        isSetTableFooter={true}
                        isSetSearchFrom={false}
                        tableHeaderList={[
                            { displayName: "Authorized Vehicle No.", key: "vehicle_no", default: "-", isSort: false },
                            {
                                displayName: "Insurance Company", key: "insurance_company", onPrintFn: (rawData) => {
                                    const a = _get(formState, `${formKey}.insuranceCompanies`, null)
                                    const companyName = a && a.length !== 0 ? a.filter(value => value['id'] === rawData.insurance_company) : null

                                    const company = companyName && companyName !== null && companyName.length !== 0 ? companyName[0].service.name : '-'
                                    return company
                                }, isSort: false
                            },
                            {
                                displayName: "Insurance Expiry Date", key: "insurance_expiry_date", isSort: false, onPrintFn: (rawData) => {
                                    return dateObjectToString(rawData.insurance_expiry_date, "dd/mm/YY");
                                }
                            },
                        ]}
                        isSetAction={true}
                        actionTypeList={[
                            {
                                actionType: actionBtnTypes.create,
                                modelContentElement: DealerVehicleForm,
                                dialogPopupProps: {
                                    isFullScreen: false,
                                    dialogTitle: "Authorized Vehicle Number Information",
                                    closeButton: {
                                        btnText: "Cancel",
                                        startIcon: "far fa-times-circle",
                                    },
                                    isConfirmationPopup: true,
                                    isCustomButton: true,
                                    customButtonComponent: DealerVehicleBtn,
                                    customButtonComponentProps: {
                                        uiType: uiType === 'APR' ? "APR" : "Add",
                                        dataTableKey: dealerVehicleTempAPI.key,
                                        requestId: uiType === 'APR' ? props.approvalRequestId : requestId
                                    }
                                },
                                parentToModelProps: {
                                    "uiType": "CREATE",
                                    formKey: formKey,
                                },
                            },
                            {
                                actionType: actionBtnTypes.delete,
                                actionBtn: { tooltip: 'Delete' },
                                modelContentElement: DeleteVehicleNumberUI,
                                dialogPopupProps: {
                                    isFullScreen: false,
                                    dialogTitle: "Delete",
                                    closeButton: {
                                        btnText: "No",
                                        startIcon: "far fa-times-circle",
                                    },
                                    isCustomButton: true,
                                    customButtonComponent: DeleteVehicleNumberBtn,
                                    customButtonComponentProps: {
                                        dataTableKey: dealerVehicleTempAPI.key,
                                    }
                                }
                            }
                        ]}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className="col-md-12 col-sm-12 defaultMarginTop">
                    <Reason filterKey={'add_dealer'}
                        formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
                        requestType={uiType}
                        dataSubmittedForApproval={props.dataSubmittedForApproval} />
                </div>

                <div className="col-md-12 col-sm-12 defaultPaddingTop">
                    <DocumentsUploader
                        isUploadFn={true}
                        formGroupName={`${formKey}-upload`}
                        tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
                        requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
                        uiType={uiType}
                    />
                </div>

                <div className="col-md-12 col-sm-12 defaultPaddingTop">
                    <Action formKey={formKey} uiType={uiType}
                        dataSubmittedForApproval={props.dataSubmittedForApproval} />
                </div>
            </div>
            {/* </FormWrapper> */}
        </div>
    );
};

const DealerVehicleForm = () => {
    const formKey = dealerVehicleTempAPI.key + "_create_addVehicle";

    return (
        <FormWrapper
            setGroupName={formKey}
            onDestroyUnsetFormObject={true}
        >
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <InputBoxWithState
                        formGroupName={formKey}
                        labelText={"Authorized Vehicle Number"}
                        inputName={"vehicle_no"}
                        isRequired={true}
                        maxLength={20}
                    />
                </div>

                <div className={"col-md-12"}>
                    <SelectBoxWithState
                        formGroupName={formKey}
                        labelText={"Insurance"}
                        inputName={"insurance_company"}
                        emptySelectOptionTxt="Select Insurance Company"
                        isRequired={true}
                        apiUrl={insuranceCompany.url + '?list?active=true&sort_by=name|asc'}
                        apiStateKey={insuranceCompany.key}
                        keyName="id"
                        valueName="service.name"
                    />
                </div>

                <div className={"col-md-12"}>
                    <DatePickerWithState
                        isRequired={true}
                        formGroupName={formKey}
                        inputName={"insurance_expiry_date"}
                        labelText={"Insurance Expiry Date"}
                        inputValue={new Date()}
                        maxDate={'01/01/2100'}
                        minDate={new Date()}
                    />
                </div>
            </div>
        </FormWrapper>
    )
};

const DealerVehicleBtn = (props) => {
    const [, uiAction] = useContext(UIContext);
    return (
        <Fragment>
            <SubmitButton
                startIcon={"far fa-save"}
                btnText={"Save"}
                formGroupName={`${props.formGroupName}_addVehicle`}
                tooltip={"Create New"}
                dataTableKey={props.dataTableKey}
                isValidate={true}
                isSetHeaders={true}
                validationObject={{
                    fileds: {
                        vehicle_no: "Authorized Vehicle Number",
                        insurance_company: "Insurance",
                        insurance_expiry_date: "Insurance Expiry Date"
                    },
                    rules: {
                        vehicle_no: "required",
                        insurance_company: "required",
                        insurance_expiry_date: "required"
                    },
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: dealerVehicleTempAPI.url,
                        key: dealerVehicleTempAPI.key,
                    };
                }}
                onChangeRequestBodyFn={(formObject) => {
                    return getDataByFormObject({
                        vehicle_no: formObject.vehicle_no,
                        insurance_company: formObject.insurance_company,
                        insurance_expiry_date: formObject.insurance_expiry_date ? formObject.insurance_expiry_date : new Date(),
                        request_id: props.requestId
                    });
                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: "post",
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                }}
                onResponseCallBackFn={(response, error) => {
                    if (
                        response.length === undefined &&
                        error === undefined &&
                        response.data.errors === undefined
                    ) {
                        props.onClosePopupFn();
                    } else {
                        if (response?.data?.meta?.message === "failed") {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Entered vehicle number is already exist.`,
                                messageType: "error",
                            });
                        }
                        console.log('ERRRR=-->', response, error);
                    }
                }}
            />
        </Fragment>
    );
};

const AddDealerBtn = (props) => {

    const [authStatus] = useContext(AuthContext);
    const [formState, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    const [coreStatus] = useContext(CoreContext);

    const authorityId = _get(authStatus, "authUser.authorityId", "");
    const { sessionId } = props
    const serviceId = _get(authStatus, "authUser.service_id", "");
    const approvalAction = _get(
        formState,
        "dealerVehicleTempAPIKey_create.action",
        null
    )
    const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
    const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");
    const userName = _get(authStatus, "authUser.name", "<anonymous name>");
    const currentProvinceName = _get(authStatus, "authUser.provinceName", null) !== null ?
        _get(authStatus, "authUser.provinceName", "<anonymous province>") :
        ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
    const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");
    const dealer_id = _get(formState, "dealer_search_form.searchKey", null);

    return (
        <Fragment>
            <SubmitButton
                startIcon={"far fa-save"}
                btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
                formGroupName={dealerVehicleTempAPI.key + "_create"}
                tooltip={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
                isValidate={true}
                isSetHeaders={true}
                validationObject={(props.uiType === 'assign&Perform' && _get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'REJ') ? {
                    fileds: {
                        remark: "Remark",
                    },
                    rules: {
                        remark: "required|max:500",
                    },
                    message: {
                        "remark.required": "Remark is required.",
                    }
                } : {
                    fileds: props.uiType === 'assign&Perform' ? (_get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'FRD' ? (_get(formState, "dealerVehicleTempAPIKey_create.forward_role", "") === 'OPT' ?
                        {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                            forward_authority: "Authority",
                            name: "Name",
                            Office_address_line_1: "Office_address_line_1",
                            Office_address_line_2: "Office_address_line_2",
                            Office_address_city: "Office_address_city",
                            Office_address_district: "Office_address_district",
                            address_line_1: "address_line_1",
                            address_line_2: "address_line_2",
                            city: "address_city",
                            district: "address_district",
                            province: "province"
                        } : {
                            reason_id: "Reason",
                            action: "Action",
                            remark: "Remark",
                            forward_province: "Province",
                            forward_role: "Role",
                            name: "Name",
                            Office_address_line_1: "Office_address_line_1",
                            Office_address_line_2: "Office_address_line_2",
                            Office_address_city: "Office_address_city",
                            Office_address_district: "Office_address_district",
                            address_line_1: "address_line_1",
                            address_line_2: "address_line_2",
                            city: "address_city",
                            district: "address_district",
                            province: "province"
                        }) : {
                        reason_id: "Reason",
                        action: "Action",
                        remark: "Remark",
                        name: "Name",
                        Office_address_line_1: "Office_address_line_1",
                        Office_address_line_2: "Office_address_line_2",
                        Office_address_city: "Office_address_city",
                        Office_address_district: "Office_address_district",
                        address_line_1: "address_line_1",
                        address_line_2: "address_line_2",
                        city: "address_city",
                        district: "address_district",
                        province: "province"
                    }) : {
                        "reason_id": "Reason",
                        "remark": "Remark",
                        "forward_province": "Forward Province",
                        "forward_role": "Forward Role",
                        "action": "Action",

                    },
                    rules: props.uiType === 'assign&Perform' ? (_get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'FRD' ? (_get(formState, "dealerVehicleTempAPIKey_create.forward_role", "") === 'OPT' ? {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                        forward_authority: "required",
                        name: "required",
                        Office_address_line_1: "required",
                        Office_address_line_2: "required",
                        Office_address_city: "required",
                        Office_address_district: "required",
                        address_line_1: "required",
                        address_line_2: "required",
                        city: "required",
                        district: "required",
                        province: "required"
                    } : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        forward_role: "required",
                        forward_province: "required",
                        name: "required",
                        Office_address_line_1: "required",
                        Office_address_line_2: "required",
                        Office_address_city: "required",
                        Office_address_district: "required",
                        address_line_1: "required",
                        address_line_2: "required",
                        city: "required",
                        district: "required",
                        province: "required"
                    }) : {
                        reason_id: "required",
                        action: "required",
                        remark: "required|max:500",
                        name: "required",
                        Office_address_line_1: "required",
                        Office_address_line_2: "required",
                        Office_address_city: "required",
                        Office_address_district: "required",
                        address_line_1: "required",
                        address_line_2: "required",
                        city: "required",
                        district: "required",
                        province: "required"
                    }) : {
                        "reason_id": "required",
                        "remark": "required|max:500",
                        "forward_province": "required",
                        "forward_role": "required",
                        "action": "required",
                    },
                    message: props.uiType === 'assign&Perform' ? (_get(formState, "dealerVehicleTempAPIKey_create.action", "") === 'FRD' ? (_get(formState, "dealerVehicleTempAPIKey_create.forward_role", "") === 'OPT' ? {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province.required": "Province is required",
                        "forward_authority.required": "Authority is required",
                        "name.required": "Name is required",
                        "Office_address_line_1.required": "Office address line 1 is required",
                        "Office_address_line_2.required": "Office address line 2 is required",
                        "Office_address_city.required": "Office address city is required",
                        "Office_address_district.required": "Office address district is required",
                        "address_line_1.required": "Address line 1 is required",
                        "address_line_2.required": "Address line 2 is required",
                        "city.required": "Address city is required",
                        "district.required": "Address district is required",
                        "province.required": "Province is required"
                    } : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "forward_role.required": "Role is required.",
                        "forward_province.required": "Province is required",
                        "name.required": "Name is required",
                        "Office_address_line_1.required": "Office address line 1 is required",
                        "Office_address_line_2.required": "Office address line 2 is required",
                        "Office_address_city.required": "Office address city is required",
                        "Office_address_district.required": "Office address district is required",
                        "address_line_1.required": "Address line 1 is required",
                        "address_line_2.required": "Address line 2 is required",
                        "city.required": "Address city is required",
                        "district.required": "Address district is required",
                        "province.required": "Province is required"
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "action.required": "Action is required.",
                        "remark.required": "Remark is required.",
                        "name.required": "Name is required",
                        "Office_address_line_1.required": "Office address line 1 is required",
                        "Office_address_line_2.required": "Office address line 2 is required",
                        "Office_address_city.required": "Office address city is required",
                        "Office_address_district.required": "Office address district is required",
                        "address_line_1.required": "Address line 1 is required",
                        "address_line_2.required": "Address line 2 is required",
                        "city.required": "Address city is required",
                        "district.required": "Address district is required",
                        "province.required": "Province is required"
                    }) : {
                        "reason_id.required": "Reason is required.",
                        "remark.required": "Remark is required.",
                        "forward_province": "Forward Province is required.",
                        "forward_role": "Forward Role is required.",
                        "action": "Action is required.",
                    },
                }}
                flashMessages={{
                    success: props.uiType === 'assign&Perform' ? {
                        status: true,
                        message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
                        messageType: "success",
                    } : {
                        status: true,
                        message: `Approval Request successfully submitted. Request ID : ${recId}.`,
                        messageType: "success",
                    },
                    // failed: props.uiType === 'assign&Perform' ? {
                    //     status: true,
                    //     message: `Approval Request Decision Submit Failed. Please Try Again.`,
                    //     messageType: "error",
                    // } : {
                    //     status: true,
                    //     message: `Approval Request Submit Failed. Please Try Again.`,
                    //     messageType: "error",
                    // },

                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: props.uiType === "assign&Perform" ? `${addDealerVehicleReqstAPI.url}/update/${_get(props, "dataSubmittedForApproval.id", null)}` : `${addDealerVehicleReqstAPI.url}/${dealer_id}`,
                        key: addDealerVehicleReqstAPI.key,
                    };
                }}
                onChangeRequestBodyFn={(formObject) => {
                    if (props.uiType === "assign&Perform") {
                        if ((_get(coreStatus, "dataTableResponses.dealerVehicleTempAPIKey.results", null) === null) ||
                            (_get(coreStatus, "dataTableResponses.dealerVehicleTempAPIKey.results", null).length === 0)) {
                            uiAction.setFlashMessage({
                                status: true,
                                message: `Please add atleast one vehicle to add dealer.`,
                                messageType: "error",
                            });

                            return
                        }
                        return getDataByFormObject({
                            ...formObject,
                            userName: userName,
                            currentProvince: currentProvinceName,
                            authority: authority,
                            requestType: RequestTypesList["add_dlr"],
                            province: formObject.province,
                            //request_id: recId,
                            pdmt_id: pdmtId,
                            // authority_id: authorityId.id,
                            request_type: RequestType.Add_Dealer,
                            address_line_1: formObject.isSame ? formObject.Office_address_line_1 : formObject.address_line_1,
                            address_line_2: formObject.isSame ? formObject.Office_address_line_2 : formObject.address_line_2,
                            city: formObject.isSame ? formObject.Office_address_city : formObject.city,
                            district: formObject.isSame ? formObject.Office_address_district : formObject.district,
                            document_details: [],
                            validity_to: formObject.validity_to === undefined ? today : (formObject.validity_to),
                            validity_from: formObject.validity_from === undefined ? (today) : (formObject.validity_from),
                            date_of_registration: formObject.date_of_registration === undefined ? (today) : (formObject.date_of_registration),
                            letter_issued_date: formObject.letter_issued_date === undefined ? (today) : (formObject.letter_issued_date),
                            counter_session_id: sessionId ? sessionId : null,
                            forward_authority_service_id: serviceId,
                            reason_id: formState['dealerVehicleTempAPIKey_create-new'].reason_id,
                            action: formObject.action == 'FRD' ? {
                                action: formObject.action,
                                remark: formObject.remark,
                                forward_province: formObject.forward_province,
                                forward_role: formObject.forward_role,
                                forward_authority: formObject.forward_authority
                            } : {
                                action: formObject.action,
                                remark: formObject.remark,
                            },
                            // request_id: _get(props, "dataSubmittedForApproval.id", null),
                            ui_request_id: _get(props, "approvalRequestId", null),
                        });
                    } else {
                        return getDataByFormObject({
                            ...formObject,
                            userName: userName,
                            currentProvince: currentProvinceName,
                            authority: authority,
                            requestType: RequestTypesList["add_dlr"],
                            province: formObject.province,
                            request_id: recId,
                            pdmt_id: pdmtId,
                            authority_id: authorityId.id,
                            request_type: RequestType.Add_Dealer,
                            document_details: [],
                            validity_to: formObject.validity_to === undefined ? today : (formObject.validity_to),
                            validity_from: formObject.validity_from === undefined ? (today) : (formObject.validity_from),
                            date_of_registration: formObject.date_of_registration === undefined ? (today) : (formObject.date_of_registration),
                            letter_issued_date: formObject.letter_issued_date === undefined ? (today) : (formObject.letter_issued_date),
                            counter_session_id: sessionId ? sessionId : null,
                            forward_authority_service_id: serviceId,
                            reason_id: formObject.reason_id,
                            action: {
                                action: formObject.action,
                                remark: formObject.remark,
                                forward_province: formObject.forward_province,
                                forward_role: formObject.forward_role,
                                forward_authority: formObject.forward_authority
                            },
                            // ui_request_id:recId,
                        });
                    }

                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: props.uiType === "assign&Perform" ? "put" : "post",
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.responseLinkToFrom,
                }}
                onResponseCallBackFn={(response, error) => {
                    if (
                        response.length === undefined &&
                        error === undefined &&
                        response.data.errors === undefined
                    ) {
                        if (props.uiType === 'assign&Perform') {
                            props.refreshMainScreenAfterPerformFn()
                            props.onClosePopupFn();
                        } else {
                            props.refreshMainScreenDataFn()
                            props.onClosePopupFn();
                        }
                    }else {
                        if (props.uiType === 'assign&Perform') {
                          uiAction.setFlashMessage({
                            status: true,
                            message: `Approval Request Decision Submit Failed. Please Try Again.`,
                            messageType: "error",
                          });
                        }
                        else {
                          if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                            uiAction.setFlashMessage({
                              status: true,
                              message: response.data.errors.details[0].message,
                              messageType: "error",
                            });
                          }
                          else {
                            uiAction.setFlashMessage({
                              status: true,
                              message: `Approval Request Submit Failed. Please Try Again.`,
                              messageType: "error",
                            });
                          }
                        }
                      }
                }}
            />
        </Fragment>
    );
};

export {
    AddDealer, AddDealerBtn
};