/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-04 11:15:27 
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-04 11:38:47
 */
import React, {Fragment, useContext} from "react";
import { templateTypes } from "../../../../../config/template.config";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  DatePickerWithState,
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  SubmitButton
} from "../../../../ui-components/ui-elements/form";
import {
    AuthorityListApi,
    provinceListApi, userDelegationAPI,
    userRolesListAPI
} from "../../../../../config/apiUrl.config";
import {FormContext} from "../../../core/context-providers/FormContext.provider";
import {_get} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {getDataByFormObject} from "../../../../../helpers/common-helpers/common.helpers";
import {apiResponseStoringType} from "../../../../../config/apiResponseKey";
const emptyFn = (...para) => undefined;

const AdditionalRolesAndLocations = () => {
  const formKey = "additionalRoles";

  const [formState] = useContext(FormContext);
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 ">
        <UICard cardHeading="Additional Roles & Locations">
          <FormWrapper setGroupName={formKey}>
            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"User Role"}
                      apiUrl={`${userRolesListAPI.url}`}
                      apiStateKey={`${userRolesListAPI.key}`}
                      keyName={"id"}
                      valueName={"name"}
                      inputName={"role_id"}
                      labelText="User Role"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"Province"}
                      apiUrl={provinceListApi.url}
                      apiStateKey={`${provinceListApi.key}_INT`}
                      keyName={"code"}
                      valueName={"name"}
                      inputName={"province"}
                      labelText="Province"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"Authority"}
                      apiUrl={`${AuthorityListApi.url}?province_code=${_get(formState,`${formKey}.province`,null)}`}
                      apiStateKey={AuthorityListApi.key}
                      keyName={"id"}
                      valueName={"code"}
                      inputName={"service_id"}
                      labelText="Authority"
                      isDisabled={_get(formState, `${formKey}.province`, null) === null}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <DatePickerWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      labelText="Expire Date"
                      inputName={"expiry_at"}
                      isRequired={true}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={""}
                      dataList={[
                        {
                          id: "0",
                          value: "Inactive",
                        },
                          {
                              id: "1",
                              value: "Active",
                          }
                      ]}
                      inputName={"status"}
                      labelText="Status"
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormWrapper>
        </UICard>
      </div>
    </div>
  );
};

const CustomButtons = (
    {
        onClosePopupFn = emptyFn,
        formGroupName = "",
        user_id = ""
    }) => {
  const close = () => {
    onClosePopupFn();
  };

  return (
    <Fragment>
      <SubmitButton
          btnText="Save"
          elementStyle="btnWrapper"
          formGroupName={"additionalRoles"}
          dataTableKey={"AdditionalRolesKey"}
          isValidate={true}
          validationObject={{
              fileds: {
                  service_id: "Authority",
                  role_id: "Role",
                  expiry_at: "Expire Date",
              },
              rules: {
                  service_id: "required",
                  role_id: "required",
                  expiry_at: "required",
              },
          }}
          flashMessages={{
              success: {
                  status: true,
                  message: "User role successfully added.",
                  messageType: "success",
              },
          }}
          onGetAPIEndPointFn={(formState) => {
              return {
                  url: `${userDelegationAPI.url}/${user_id}`,
                  key: userDelegationAPI.key
              }
          }}
          onChangeRequestBodyFn={(formObject) => {
              return getDataByFormObject({
                  ...formObject,
                  status: formObject.status === "1",
                  default_delegation: false
              });
          }}
          callApiObject={{
              isSetHeaders: true,
              method:  "post",
          }}
          apiDataStoringObject={{
              setLoader: true,
              storingType: apiResponseStoringType.responseLinkToFrom,
          }}
          onResponseCallBackFn={(response, error) => {
              if (response.length === undefined && error === undefined) {
                  onClosePopupFn();
              }
          }}
      />
    </Fragment>
  );
};

export  {
  AdditionalRolesAndLocations,
  CustomButtons
};
