/*
 * @Author:Pradeepa Sandaruwan
 * @Date: 2021-01-12 10:21:07
 * @Last Modified by: Gajani Kangesan
 * @Last Modified time: 2022-02-17 14:00:33
 */

import React, { useContext } from "react";
import { templateTypes } from "../../../../../../../config/template.config";
import { _getInt, _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import {
      SelectBoxWithState,
      SubmitButton,
      FormWrapper,

} from "../../../../../../ui-components/ui-elements/form";
import { reportGenerateAPI, provincesOfficesListAPI, lossRevenueNewDateListAPI } from "../../../../../../../config/apiUrl.config";
import { UICard } from "../../../../../../ui-components/ui-elements/common/BaseElements";
import { reportDataLimit, reportDataOffset } from '../../../../../../../config/report.config'
import { FormContext } from "../../../../../core/context-providers/FormContext.provider";
import { isEmptyValue, getDataByFormObject } from "../../../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers"
import { AuthContext } from "../../../../../core/context-providers/AuthContext.provider";
import { downloadFile } from "../../../../../../../helpers/download-helpers/download.helpers"
import { ProvincesMatcher } from "../../../../../../../config/enum.config"
import { CoreContext } from "../../../../../../modules/core/context-providers/CoreContext.provider";
const emptyFn = (...para) => undefined
const SearchCriteria = ({
      reportCode,
      formGroupName,
      setReportgeneratedValues = emptyFn,
      onGenerateSubmit = emptyFn,
      onResetClick = emptyFn,
      reportName
}) => {

      const [formState, formAction] = useContext(FormContext);
      const [authStatus, authAction] = useContext(AuthContext);
      const [coreState, coreAction] = useContext(CoreContext);
      const onExportToCsvSubmit = (event) => {
            downloadFile(new Blob([event]), `${reportName}.xlsx`);
      };

      const current = new Date();
      let roleLevelCode = _get(authStatus, 'authUser.role_level_code', "");
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}  ${current.getHours() + ':' + current.getMinutes()}`;
      const currentDate = dateObjectToString(new Date(), 'YY-mm-dd HH:MM:II');
      let provinceName = roleLevelCode === 'INT_PRV' ? ProvincesMatcher[_get(authStatus, 'authUser.pdmtId', "")] : ProvincesMatcher[_get(authStatus, 'authUser.pdmtId.id', "")];
      let provinceId = roleLevelCode === 'INT_PRV' ? _get(authStatus, 'authUser.pdmtId', "") : _get(authStatus, 'authUser.pdmtId.id', "");
      let generated_by = _get(authStatus, 'authUser.name', "")
      let role = _get(authStatus, 'authUser.rolePrefix', "")
      let dateTime = "";
      return (
            <UICard>
                  <FormWrapper
                        setGroupName={formGroupName}
                        formGroupLinkWith={formGroupName}
                        onDestroyUnsetFormObject={true}
                        setFormObject={{

                        }}

                  >
                        <div className={"row"} >
                              <div className="col-md-6 col-sm-12 floatLeft">
                                    <SelectBoxWithState
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          apiUrl={provincesOfficesListAPI.url}
                                          keyName={"id"}
                                          valueName={"province.name"}
                                          apiStateKey={provincesOfficesListAPI.key}
                                          emptySelectOptionTxt={provinceName}
                                          labelText="Province"
                                          inputName={"province"}
                                          isDisabled={true}

                                    />
                              </div>

                              <div className="col-md-6 col-sm-12 floatLeft">
                                    <SelectBoxWithState
                                          isRequired={true}
                                          uiType={templateTypes.bootstrap}
                                          formGroupName={formGroupName}
                                          emptySelectOptionTxt={" "}
                                          apiUrl={`${lossRevenueNewDateListAPI.url}/${provinceId}`}
                                          apiStateKey={lossRevenueNewDateListAPI.key}
                                          keyName={"loss_revenue_as_at"}
                                          valueName={"loss_revenue_as_at"}
                                          labelText="Date & Time"
                                          inputName={"date_time"}
                                    />

                              </div>




                              <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">

                                    <SubmitButton
                                          btnText={"Download"}
                                          elementStyle="btnWrapper"
                                          formGroupName={formGroupName}
                                          isValidate={true}
                                          validationObject={{
                                                fileds: {
                                                      date_time: "Date & Time",
                                                      

                                                },
                                                rules: {
                                                      date_time: "required",
                                                    

                                                },
                                                message: {}
                                          }}
                                          callApiObject={{
                                                isSetHeaders: true,
                                                method: "get",
                                                multipart: false,
                                                onUpload: false,
                                                responseType: 'arraybuffer'
                                          }}
                                          apiDataStoringObject={{
                                                setLoader: true,
                                                storingType: "API_RESPONSE_LINKED_FORM",
                                                mergeToSuccessResponse: true,
                                                mergeToErrorResponse: true
                                          }}
                                          onGetAPIEndPointFn={(formObject) => {

                                                if (!isEmptyValue(formObject.province)) {
                                                      provinceId = formObject.province
                                                      provinceName = (_get(coreState, `apiResponses.${provincesOfficesListAPI.key}.result`, []).find(item => item.province.id === provinceId)?.province.name)
                                                }

                                                if (!isEmptyValue(formObject.date_time)) {
                                                      dateTime = formObject.date_time

                                                }


                                                return {
                                                      url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=xlsx&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestProvince=${provinceName}&report_provinceId=${provinceId}&report_requestToDate=${dateTime}&report_dateFormatDisplay=dd/MM/yyyy HH:MM&report_dateFormatReceived=YY-mm-dd HH:MM:II&report_generatedUser=${generated_by + "-" + (roleLevelCode === "INT_AU" ? _get(authStatus, 'authUser.provinceCode', "") : _get(authStatus, 'authUser.pdmtCode', "")) + "-" + (roleLevelCode === "INT_AU" ? (_get(authStatus, 'authUser.authorityPrefix', "") + "-") : "") + "(" + role + ")"}`,
                                                      key: `${reportGenerateAPI.key}`
                                                }
                                          }}

                                          onChangeRequestBodyFn={(formObject) => {
                                                return getDataByFormObject(formObject);
                                          }}

                                          onResponseCallBackFn={(error, response,) => {
                                                if (response?._statue) {
                                                      //onExportToPdfSubmit(response?.data)
                                                      onExportToCsvSubmit(response?.data)
                                                }
                                          }}


                                    />



                              </div>
                        </div>
                  </FormWrapper>
            </UICard>


      );
};

export {
      SearchCriteria
}