/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-02-28 17:31:46 
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-18 15:34:58
 */

import React, { Fragment } from 'react';
import Dashboard from "./pages/Dashboard";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import authorized from "../../hoc-middlewares/Authorized";

import Test2 from './pages/Test2';
import TestUI from "./pages/TestUI";

const DashboardRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/" routeKey={"dashboard"} component={authorized(Dashboard)} routePermissions={permissions.NONE.permissions} />
            <Navigate exact={true} path="/dashboard" routeKey={"dashboard"} component={authorized(Dashboard)} routePermissions={permissions.NONE.permissions} />
            <Navigate exact={true} path="/test" routeKey={"test"} component={authorized(TestUI)} routePermissions={permissions.NONE.permissions} />
            <Navigate exact={true} path="/test2" routeKey={"test"} component={authorized(Test2)} routePermissions={permissions.NONE.permissions} />
        </Fragment>
    );
};

export default DashboardRoutes;
