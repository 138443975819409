/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Slideshow
 * @Date: 2021-08-05 12:14:12
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-08-05 12:14:12
 */
import React, { useState } from "react";

/**
 * @param elementStyle
 * @param captionStyle
 * @param imageList (Eg:- [{ "src": "abc.jpg", "caption": "Here..." }] )
 * @returns {JSX.Element}
 */
const Slider = ({
    elementStyle = "",
    captionStyle = "",
    imageList = []
}) => {
    const [activeSlide, setActiveSlide] = useState(0);

    const moveSlide = (value) => {
        let slide = value;

        if (value < 0) {
            slide = imageList.length - 1;
        }

        if (value === imageList.length) {
            slide = 0;
        }

        setActiveSlide(slide);
    };

    return (
        <div id="carouselExampleCaptions" className={`carousel slide ${elementStyle}`} data-bs-ride="carousel">

            <div className="carousel-inner">
                {
                    imageList.map((value, index) => {
                        return (
                            <div className={`carousel-item ${activeSlide === index ? "active" : "" }`}>
                                <img src={value["src"]} className="d-block fullWidthDiv" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <h5 className={`caption-style ${captionStyle}`}>{value["caption"]}</h5>
                                </div>
                            </div>
                        );
                    })
                }
            </div>

            <button
                className="carousel-control-prev prev-next-btn-style"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
                onClick={() => moveSlide(activeSlide - 1)}
            >
                <span className="carousel-control-prev-icon temp" aria-hidden="true"></span>
            </button>

            <button
                className="carousel-control-next prev-next-btn-style"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
                onClick={() => moveSlide(activeSlide + 1)}
            >
                <span className="carousel-control-next-icon temp" aria-hidden="true"></span>
            </button>
        </div>
    );
};

export default Slider;
