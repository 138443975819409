/*
 * @Author: Madhawa
 * @Date: 2020-08-10 12:18:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-11-10 11:25:00
 */

import React, { Fragment, useContext, useEffect, useState } from "react";

import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { getDataByFormObject, fromObjectToQueryString, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { licenceTypeChangeAPI, nonUserLicenceValidityPeriodAPI, getHolidayRangesAPI, uploadDetailGetAPI, getLatestLicenseAPI, licenceChangeAPI, calculateLicenceFeeAPI, getNonUserConfigDaysAPI } from "../../../../config/apiUrl.config";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";

import { DocumentUploader, UICard, ViewDetailBlock } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DatePickerWithState,
  FormWrapper,
  SelectBoxWithState
} from "../../../ui-components/ui-elements/form";
import { Action } from "./includes/Action";
import { templateTypes } from "../../../../config/template.config";
import { Reason } from "./includes/Reason";
import { dateObjectToString, } from "../../../../helpers/common-helpers/dateTime.helpers";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { LicenceTypeForTopPanel, RequestStatus, RequestTypesList, ActionTypeList, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { isEmpty } from "lodash";

const LicenceChageSubmitBtn = (props) => {

  // console.log("dddddssssssssssssfffffffffffffffffffffffffff", props);

  const [formState] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const uiType = props.uiType;
  const approvalAction = _get(
    formState,
    "licence_type_change.action",
    null
  )
  const vehicle_no = uiType === 'assign&Perform' ? (_get(props, "dataSubmittedForApproval.vehicle_no", null)) : (_get(formState, "vehicle_search_form.searchKey", null));
  const reqId = uiType === 'assign&Perform' ? (props.approvalRequestId) : (_get(formState, "licence_type_change.request_id", null));
  const current_licence_type = _get(formState, "vehicle_search_form.data.current_license_type.code", null);
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");
  const [, uiAction] = useContext(UIContext);

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={'licence_type_change'}
        tooltip={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${reqId}.`,
            messageType: "success",
          },
          failed: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request Decision Submit Failed. Please Try Again.`,
            messageType: "error",
          } : {
            // status: true,
            // message: `Approval Request Submit Failed. Please Try Again.`,
            // messageType: "error",
          },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "licence_type_change.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "licence_type_change.action", "") === 'FRD' ? (_get(formState, "licence_type_change.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
              effective_date: "Effective Date",
              new_licence_type: "New Licence Type"
            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              effective_date: "Effective Date",
              new_licence_type: "New Licence Type"
            }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            effective_date: "Effective Date",
            new_licence_type: "New Licence Type"
          }) : {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            "effective_date": "Effective Date",
            "new_licence_type": "New Licence Type"
          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "licence_type_change.action", "") === 'FRD' ? (_get(formState, "licence_type_change.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",
            effective_date: "required",
            new_licence_type: "required"
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            effective_date: "required",
            new_licence_type: "required"
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            effective_date: "required",
            new_licence_type: "required"
          }) : {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required",
            "effective_date": "requiredIf:user_role_level,INT_PRV"
          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "licence_type_change.action", "") === 'FRD' ? (_get(formState, "licence_type_change.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
            "forward_authority": "Authority is required",
            "effective_date": "Effective Date is required",
            "new_licence_type": "New Licence Type is required",
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required",
            "effective_date": "Effective Date is required",
            "new_licence_type": "New Licence Type is required",
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "effective_date": "Effective Date is required.",
            "new_licence_type": "New Licence Type is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province": "Forward Province is required.",
            "forward_role": "Forward Role is required.",
            "action": "Action is required.",
            "effective_date.requiredIf": "Effective Date"

          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: props.uiType === "assign&Perform" ? `${licenceTypeChangeAPI.url}/${_get(props, "dataSubmittedForApproval.id", null)}` : `${licenceTypeChangeAPI.url}/${vehicle_no}`,
            key: licenceTypeChangeAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "licence_type_change.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["chng_lcn_type"],
              // current_licence_type: props.uiType === 'assign&Perform' ?fromObject.current_licence_type : 'NO',
              new_licence_type: fromObject.new_licence_type ? fromObject.new_licence_type : null,
              non_user_validity_period: fromObject.non_user_validity_period ? fromObject.non_user_validity_period : null,
              // status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              // authority_id: authorityId,
              // request_id:props.uiType === "assign&Perform"?(_get(props, "dataSubmittedForApproval.id", null)):(fromObject.request_id),
              ui_request_id: props.uiType === "assign&Perform" ? (_get(props, "approvalRequestId", null)) : fromObject.request_id,
              reason_id: formState['licence_type_change-new'].reason_id,
              effective_date: fromObject.effective_date ? new Date(fromObject.effective_date) : new Date(),
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              }
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["chng_lcn_type"],
              current_licence_type: props.uiType === 'assign&Perform' ? fromObject.current_licence_type : 'NO',
              new_licence_type: fromObject.new_licence_type ? fromObject.new_licence_type : null,
              non_user_validity_period: fromObject.non_user_validity_period ? fromObject.non_user_validity_period : null,
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: props.uiType === "assign&Perform" ? (_get(props, "dataSubmittedForApproval.id", null)) : (fromObject.request_id),
              ui_request_id: props.uiType === "assign&Perform" ? (_get(props, "approvalRequestId", null)) : fromObject.request_id,
              reason_id: fromObject.reason_id,
              effective_date: fromObject.effective_date ? new Date(fromObject.effective_date) : new Date(),
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "assign&Perform" ? "put" : "post",
        }}

        onResponseCallBackFn={(response, error) => {
          if (!isEmpty(response.data.errors)) {
            if (props.uiType !== 'assign&Perform') {
              if (response.data.errors.details[0].message==="Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }

          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          }


        }}
      />
    </Fragment>
  );
};

const LicenceTypeChangeUI = (props) => {
  const [authStatus] = useContext(AuthContext);

  console.log("xxxxxxxxxxxxxxmmmmmmmmmmmmmmmmmmmmm", props);
  const { uiType, formKey, requestType, approvalRequestId, dataSubmittedForApproval } = props;
  const [formState, formAction] = useContext(FormContext);
  let licence_type = _get(formState, "latest_licence_data.data.vehicleData.current_license_type.code", null);

  const todayDate = new Date();
  const [requestId, setRequestId] = useState(null);
  const [aprData, setAprData] = useState([{ "licence_type": '' }]);
  const currentEndDate = _get(formState, "latest_licence_data.data.vehicleData.first_reg_date", null) !== null && _get(formState, "latest_licence_data.data.vehicleData.first_reg_date", null)
  const cendMonth = new Date(currentEndDate).getMonth()
  const cendDay = (new Date(currentEndDate).getDate() - 1)
  const currentYear = new Date().getFullYear()

  const curentUpdatedEndDate = new Date(currentYear, cendMonth, cendDay)

  const currentLicenceDate = _get(formState, "latest_licence_data.data.valid_to", null) !== null ? _get(formState, "latest_licence_data.data.valid_to", null) :
    _get(formState, "licence_validity_period.startDate", null) !== null ? _get(formState, "licence_validity_period.startDate", null) : new Date()
  const currentLicenceEndDate = _get(formState, "latest_licence_data.data.valid_to", null) !== null ? _get(formState, "latest_licence_data.data.valid_to", null) : curentUpdatedEndDate
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");


  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();
    if (props.uiType === 'APR') {
      getData()
      getReqApprovalLicenceTypeData(props)
      getNormalLicenceFee(props)
      getNonUserConfigDays()
      if (_get(formState, "latest_licence_data.data.current_licence_status", null) === "EX") {
        getHolidayRanges()
        workingDaysBetweenDates(d, today);
      }
    }
    setRequestId(props.uiType === "APR" ? props.approvalRequestId : requ.substr(requ.length - 8))
  }, [_get(formState, "latest_licence_data.data.current_licence_status", null)])
  licence_type = props.uiType === "APR" ? (`${LicenceTypeForTopPanel[aprData.licence_type]} Licence`) : (`${LicenceTypeForTopPanel[licence_type]} Licence`)
  const nonUserConfigDays = Number(_get(formState, "non_user_admin_config.data1.value", null))
  const today = new Date().setHours(0, 0, 0, 0)
  const d = new Date(currentLicenceDate)
  // console.log('dd---------->', nonUserConfigDays);
  const holidays = _get(formState, "holiday_range_data", null) !== null ? _get(formState, "holiday_range_data.data1.numberOfDays", null) : 0
  const isWorkingDays = _get(formState, "isWorkindDays.data1", null)
  const weekendDays = _get(formState, "weekends_in_range.days", null)

  const greaterThan7Days = +today > +d + ((isWorkingDays !== null && isWorkingDays) ? (nonUserConfigDays + holidays + weekendDays) : nonUserConfigDays) * 24 * 60 * 60 * 1000

  const workingDaysBetweenDates = (date1, date2) => {
    const d1 = new Date(date1);
    d1.setDate(d1.getDate() + 1);
    const d2 = new Date(date2);
    let days = 0
    while (d1 < d2) {
      const day = d1.getDay();
      if ((day === 6) || (day === 0)) {
        days++
        const nextDate = d1; // if one weekend is found, check the next date
        nextDate.setDate(d1.getDate() + 1); // set the next date
        const nextDay = nextDate.getDay(); // get the next day
        if ((nextDay === 6) || (nextDay === 0)) {
          days++; // if next day is also a weekend, return true
        }
      }
      d1.setDate(d1.getDate() + 1);
    }
    formAction.changeInputFn('weekends_in_range', "days", "", days);;
  }





  const date = new Date(currentLicenceEndDate);
  date.setDate(date.getDate() + 7);

  console.log('dddddaaateee', date);

  const getNonUserConfigDays = (props) => {
    callApi(`${getNonUserConfigDaysAPI.url}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          if (result.data.data && result.data.data.value_type === "WRK") {
            formAction.changeInputFn('isWorkindDays', "data1", "", true);
            formAction.changeInputFn('non_user_admin_config', "data1", "", result.data.data);
          } else {
            formAction.changeInputFn('isWorkindDays', "data1", "", false);
            formAction.changeInputFn('non_user_admin_config', "data1", "", result.data.data);
            // console.log('REEE****1111DDD===>1111', result);
          }
        }
      });
  }

  const appliedDate = new Date().setHours(0, 0, 0, 0)
  const parseDate = (input) => {
    var parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
  }

  const holidayWithinRange = (fromDate, toDate, appliedDate, holidayData) => {
    //fromdate- startindate
    //todate - fromdate + fine excemotions
    //hoildays = holiday array
    let numberOfDays = 0;
    let holidays = [];
    const getDateAsDate = parseDate(fromDate)
    const endDate = new Date(toDate);
    const strdate = new Date(getDateAsDate.getTime());
    strdate.setUTCHours(0, 0, 0, 0);

    for (let day = new Date(strdate); day < toDate && day <= appliedDate; day.setDate(day.getDate() + 1)) {
      let holidayFound = false;
      console.log('REEE****1111DDD===>000044', day, toDate, fromDate, appliedDate);
      for (const hDrange of holidayData) {

        const rangeFrom = new Date(hDrange.from_date);
        const rangeTo = new Date(hDrange.to_date);
        console.log('REEE****1111DDD===>000055', rangeFrom, rangeTo);

        if (rangeFrom <= day && rangeTo >= day) {
          console.log('REEE****1111DDD===>000066');

          holidayFound = true;
          break;
        }
      }

      if (holidayFound) {
        holidays.push(new Date(day));
        numberOfDays += 1;
      }
    }


    return {
      numberOfDays: numberOfDays,
      holidays: holidays,
    }

  }

  const getHolidayRanges = (props) => {
    console.log('REEE****1111DDD===>0000', _get(formState, "latest_licence_data.data.valid_to", null));
    // const holidayReqDto = {
    //   pdmt_id: pdmtId,
    //   authority_id: null,
    //   from_date: _get(formState, "latest_licence_data.data.valid_to", null),
    //   to_date: new Date()
    // }

    callApi(`${getHolidayRangesAPI.url}?pdmt_id=${pdmtId}&authority_id=null&from_date=${_get(formState, "latest_licence_data.data.valid_to", null)}&to_date=${new Date()}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send(async (error, result) => {
        if (!error) {
          console.log('REEE****1111DDD===>', result);
          const holidayDetailsObj = [];
          if (_get(formState, "latest_licence_data.data.valid_to", null) !== null) {
            if (result.data.data.length > 0) {
              for (let obj of result.data.data) {
                let holidayRang = {
                  from_date: obj.from_date,
                  to_date: obj.to_date
                };

                holidayDetailsObj.push(holidayRang);
              }
              console.log('REEE****1111DDD===>56599', holidayDetailsObj);
              const retrnData = await holidayWithinRange(_get(formState, "latest_licence_data.data.valid_to", null), new Date(), appliedDate, holidayDetailsObj)
              console.log('REEE****1111DDD===>565', retrnData);

              const findingDaysWithoutWeekendsInARange = retrnData && retrnData.holidays.length !== 0 && retrnData.holidays.map(item => {
                const dd = (new Date(item).getDay() === 6 || new Date(item).getDay() === 0) ? item : null
                return dd
              })
              console.log('REEE****1111DDD===>5651', findingDaysWithoutWeekendsInARange);

              const daysWithoutWeekendsInARange = findingDaysWithoutWeekendsInARange.filter(day => day !== null)
              console.log('REEE****1111DDD===>5652', daysWithoutWeekendsInARange);

              const noOfWeekEndDaysInRange = daysWithoutWeekendsInARange && daysWithoutWeekendsInARange.length
              console.log('REEE****1111DDD===>5653', noOfWeekEndDaysInRange);


              retrnData.numberOfDays = retrnData.numberOfDays - noOfWeekEndDaysInRange
              console.log('REEE****1111DDD===>5654', retrnData);

              formAction.changeInputFn('holiday_range_data', "data1", "", retrnData);
            } else {
              console.log('REEE****1111DDD===>2323', result);
            }
          }
        }
      });
  }

  const getReqApprovalLicenceTypeData = (props) => {
    callApi(`${getLatestLicenseAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          // console.log('REEE****1111===>', result);
          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

        }
      });
  }

  const getNormalLicenceFee = (props) => {
    let queryObj = {}
    if ((_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_start_at", null) !== null) &&
      (_get(formState, "work_flow_record.data", null) !== null && _get(formState, "work_flow_record.data.carnet_licence_end_at", null) !== null)) {
      queryObj = {
        vehicle_no: props.dataSubmittedForApproval.vehicle_no,
        licence_end_type: 0,
        is_reissuance: false,
        licence_period_start_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_start_at", null)) : null,
        licence_period_end_date: _get(formState, "work_flow_record.data", null) !== null ? dateObjectToString(_get(formState, "work_flow_record.data.carnet_licence_end_at", null)) : null,
      };
    } else {
      queryObj = {
        vehicle_no: props.dataSubmittedForApproval.vehicle_no,
        licence_end_type: 0,
        is_reissuance: false,
        temp_request: false
      };
    }


    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totArrearsAddition = 0;
          let totArrearsDeduction = 0;
          let totAddition = 0;
          let totdeduction = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;

          const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

          _get(result, "data.addition_deduction_summary", []).forEach(value => {
            if (value.charge_effect_code === "DED") {
              totdeduction += value.total

            }
            if (value.charge_effect_code === "ADD") {
              totAddition += value.total

            }

          });
          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
            totArrearsAddition += value.addition_total
            totArrearsDeduction += value.deduction_total
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            totAddition + _get(result, "data.additions.current_licence", 0) -
            (totdeduction + _get(result, "data.deductions.current_licence", 0)))
            + serviceCharge;

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
      });
  };

  const firstRegDate = props.uiType === "APR" ? (_get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null) === null ? _get(formState, 'latest_licence_data.data.vehicleData.first_reg_date', '-') : _get(formState, 'latest_licence_data.data.vehicleData.licence_effective_date', null)) : (_get(formState, "vehicle_search_form.data.licence_effective_date", null) === null ? _get(formState, "vehicle_search_form.data.first_reg_date", null) : _get(formState, "vehicle_search_form.data.licence_effective_date", null));
  const minDate = new Date(firstRegDate);
  const renewalDate = _get(formState, `latest_licence_data.data.valid_to`, null);
  const maxDate = renewalDate;



  const getDataList = () => {
    // console.log('iiii', licence_type, _get(formState, 'latest_licence_data.data.current_licence_status', null));
    if (licence_type === "Free Licence") {
      return [{
        id: "NO",
        value: "Normal Licence",
      }]
    }
    //  else if(licence_type === "Free Licence" && _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'EX' && !greaterThan7Days) {
    //   return [
    //     {
    //       id: "NO",
    //       value: "Normal Licence",
    //     },

    //     {
    //       id: "NU",
    //       value: "Non-user Licence",
    //     },

    //   ]
    // } 
    else if (licence_type === "Normal Licence" && (_get(formState, 'latest_licence_data.data.current_licence_status', null) === 'VA')) {
      return [    //valid wenna ona, !greaterThan7Days, 

        {
          id: "FE",
          value: "Free Licence",
        },
        {
          id: "NU",
          value: "Non-user Licence", //expire wenna ona, !greaterThan7Days
        },

      ]
    } else if (licence_type === "Normal Licence" && _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'NL') {
      return [{
        id: "FE",
        value: "Free Licence",
      },]
    } else if (licence_type === "Normal Licence" && _get(formState, 'latest_licence_data.data.current_licence_status', null) === 'EX' && !greaterThan7Days) {
      return [    //valid wenna ona, !greaterThan7Days, 
        {
          id: "NU",
          value: "Non-user Licence", //expire wenna ona, !greaterThan7Days
        },

      ]
    } else if (licence_type === "Non-User Licence") {
      return [
        {
          id: "NO",
          value: "Normal Licence",
        },
        {
          id: "FE",
          value: "Free Licence",
        },

      ]
    } else if (licence_type === "Normal Licence" && (_get(formState, 'latest_licence_data.data.current_licence_status', null) === 'RE')) {
      return [
        {
          id: "NU",
          value: "Non-user Licence", //expire wenna ona, !greaterThan7Days
        },
        {
          id: "FE",
          value: "Free Licence",
        },

      ]
    } else {
      return []
    }
  }

  const getData = async () => {
    const sendAPIRequest = (apiUrl) => {
      return new Promise((resolve, reject) => {
        callApi(apiUrl)
          .headers(true)
          .method("get")
          .isMultipart(false)
          .send((error, result) => {
            if (error) {
              reject(error);
            }

            resolve(result?.data);
          })
      });
    };

    const effReqData = await sendAPIRequest(`${licenceChangeAPI.url}/${approvalRequestId}`);

    Promise.all([effReqData])
      .then(result => {
        const setResultOther = result && result.length !== 0 ? result[0].data : {}

        const newData = {
          reqData: setResultOther
        }

        formAction.changeInputFn('allTaskData', "vehicleStatusChng", "", newData);
        setAprData({ "licence_type": setResultOther.vehicle.current_license_type.code })
        formAction.changeInputFn(formKey, "new_licence_type", "", setResultOther.new_licence_type);
        formAction.changeInputFn(formKey, "effective_date", "", setResultOther.effective_date);
        formAction.changeInputFn(formKey, "non_user_validity_period", "", setResultOther.non_user_licence_period_id);

        // setData(setResult)
        // console.log('RRRRRR', result);

      });

  }

  const licnData = _get(formState, `allTaskData.vehicleStatusChng`, {})

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 defaultMarginBottom">
        {requestType ?
          <UICard>
            <FormWrapper
              setGroupName={formKey}
              apiUrl={`${licenceChangeAPI.url}/${approvalRequestId}`}
              onRebuildResponseFn={(response) => {
                // console.log('CLT====>11111',response)
                return {
                  vehicle: response,

                  "_onLoad": true
                }
              }}
            >
              <div className="col-md-12 col-sm-12 paddingLeftNone paddingBottomNone floatLeft">
                <div className="floatLeft col-md-3">
                  <b> Current Licence Type: </b> {_get(formState, `${formKey}.vehicle.vehicle.current_license_type`, "test")}
                </div>
                <div className="floatLeft col-md-3">
                  <b> New Licence Type: </b> {_get(formState, `${formKey}.vehicle.new_licence_type`, "test")}
                </div>
                <div className="floatLeft col-md-3">
                  <b> Effective Date: </b> {dateObjectToString(_get(formState, `${formKey}.vehicle.effective_date`, "test"), "dd/mm/YY")}
                </div>
              </div>
            </FormWrapper>
          </UICard>
          : <UICard>
            <FormWrapper
              setGroupName={formKey}
              setFormObject={props.uiType === 'APR' ? {
                ui_request_id: props.approvalRequestId,
              } : {
                request_id: _set(formState, `${formKey}.request_id`, requestId)
              }}
            // apiUrl={`${licenceChangeAPI.url}/${_get(props, "dataSubmittedForApproval.id", null)}`}
            // onRebuildResponseFn={(response) => {
            //   setAprData({ licence_type: _get(response, "vehicle.current_license_type", "") })
            //   console.log('CLTttttttttttttttttttttttttttttt====>',response)
            //   return {
            //     new_licence_type: _get(response, "new_licence_type", ""),
            //     effective_date: _get(response, "effective_date", ""),
            //     current_licence_type: _get(response, "vehicle.current_license_type", ""),
            //     reason_id: _get(response, "work_flow_record.reason_id", "")
            //   };
            // }}
            >
              <div className="row col-md-12 col-sm-12">
                <strong>Current Licence Type : </strong> {uiType === 'APR' ? licnData && licnData.reqData ? licnData.reqData.vehicle.current_license_type.name : '-' : licence_type}
              </div>

              <div className="row defaultMarginTopBottom">
                <div className="col-md-3 col-sm-12">
                  <SelectBoxWithState
                    uiType={templateTypes.bootstrap}
                    formGroupName={formKey}
                    dataList={getDataList(props)}
                    inputName={"new_licence_type"}
                    labelText="New Licence Type"
                    emptySelectOptionTxt={"Select Licence Type"}
                    isRequired={props.uiType === 'APR' ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  {/* {console.log('LOGGG-->', _get(formState, "licence_charges_form.currentLicenceEndDate", null), _get(formState, `latest_licence_data.data.current_licence_status`, undefined))} */}
                  <DatePickerWithState
                    uiType={templateTypes.bootstrap}
                    labelText={"Effective Date"}
                    inputName={"effective_date"}
                    formGroupName={formKey}
                    // inputValue={new Date()}
                    minDate={minDate}
                    maxDate={
                      _get(formState, `latest_licence_data.data.current_licence_status`, undefined) === "RE" ? maxDate : todayDate}
                    isRequired={props.uiType === 'APR' ? true : false}
                  />
                </div>
                {_get(formState, `${formKey}.new_licence_type`, null) === "NU" && <div className="col-md-3 col-sm-12">
                  <SelectBoxWithState
                    uiType={templateTypes.bootstrap}
                    apiUrl={`${nonUserLicenceValidityPeriodAPI.url}`}
                    formGroupName={formKey}
                    emptySelectOptionTxt={"Select Period"}
                    keyName={"id"}
                    valueName={"name"}
                    inputName={"non_user_validity_period"}
                    labelText="Non User Validity Period"
                    isRequired={props.uiType === 'APR' ? true : false}
                  />
                </div>}
              </div>
            </FormWrapper>
          </UICard>}
      </div>
      {/* <div className="row"> */}
      <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
        <Reason
          filterKey={'licence_type_change'}
          formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
          requestType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultMarginTopBottom">
        <Action formKey={formKey} uiType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval} />
      </div>
      {/* </div> */}
    </div >
  );
};

const LicenceTypeChange = () => {
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Licence Type Change" },
      ]}
      pageTitle="Licence Type Change "
    >
      <LicenceTypeChangeUI />
    </DashboardTemplate>
  );
};

export default LicenceTypeChange;
export { LicenceTypeChangeUI, LicenceChageSubmitBtn };
