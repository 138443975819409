/*
 * @Author: Gajani Kangesan
 * @Date: 2022-07-22 15:45:03
 * @Last Modified by: Gajani Kangesan
 * @Last Modified time: 2022-07-23 16:38:50
 */

import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PaddingLessUICard, UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { specialMessageAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form/index";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import { callApi } from '../../../../../helpers/common-helpers/callApi.helpers';
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { ProvincesMatcher, ProvincesByCodeMatcher } from "../../../../../config/enum.config";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { isEmpty } from "lodash";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
const Specialmessage = ({
  formKey = "carnetComment", //CarnetComment || NormalComment
  vehicleId = "",
  provinceId = "",
  vehicleNo = "",


}) => {
  const [formState, formAction] = useContext(FormContext);
  const [getState, setState] = useState({
    status: false,
    modelType: "",
  });
  useEffect(() => {

    formAction.initFromFn({ "special": { _onLoad: true } });
    if (!isEmptyValue(_get(formState, 'counter-serial.id', null))) {
      specialMessage();
    }
  }, []);


  const specialMessage = () => {
    callApi(
      `${specialMessageAPI.url}?vehicle_id=${vehicleId}&province_id=${provinceId}&vehicle_no=${vehicleNo}&sort_by=changed_at|desc`
    )
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn("special", "data", "", result.data.data);
          formAction.changeInputFn("special", "message", "", result.data.data.changes);
          formAction.changeInputFn("special", "is_warning", "", result.data.is_warning);
          formAction.changeInputFn("message", "list", "", result.data.special_messages);
          setState({
            ...getState,
            status: result.data.is_warning,
            modelType: "",
          })

        }
        else {
          formAction.changeInputFn("special", "data", "", []);
          formAction.changeInputFn("special", "message", "", []);
          formAction.changeInputFn("message", "list", "", []);
          formAction.changeInputFn("special", "is_warning", "", false);
          setState({
            ...getState,
            status: false,
            modelType: "",
          })
        }
        formAction.changeInputFn("special", "_onLoad", "", false);
      });
  }
  const closePopup = () => {
    setState({
      ...getState,
      status: false,
      modelType: "",
    })
  };


  let history = useHistory();
  const MessageTable = ({ dataList = [] }) => {

    return (
      <Paper sx={{ width: '100%' }} className="scrollMessage">
        <TableContainer sx={{ maxHeight: 80, border: '1px dashed grey' }}>
          <Table stickyHeader aria-label="sticky table"  >
            <TableHead>
            </TableHead>
            <TableBody>

              {(_get(formState, "vehicle_search_form.data.errorCode", 0) > 0) &&
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }} >
                  <TableCell style={{ fontSize:"12px",color: "red" }} ><p>{_get(formState, "vehicle_search_form.data.errorMessage", "")}</p></TableCell>
                </TableRow>}

              {dataList.map((data) => (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }} >
                  <TableCell style={{ fontSize:"12px",color: data.color_code }} ><p>{data.message}</p></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <div className="specialMsg">
      {(!isEmpty(_get(formState, `special.message`, [])) || !isEmpty(_get(formState, `message.list`, [])) || _get(formState, "vehicle_search_form.data.errorCode", 0) > 0) ?
        <div className="fullWidthDiv">
          {_get(formState, "special._onLoad", false) === false &&
            <PaddingLessUICard
              cardHeading="Special Message"
              passPropsToCardHeadingTail={{
                uiType: "",
                formGroupName: { formKey },

              }}>
              <div className="row">
                <div class="col-md-8 "><p class="mt-1"><span class="highlight" >The Vehicle information has changed.</span></p></div>
                <div class="col-md-3">
                  <InputButton
                    elementWrapperStyle={""}
                    btnName="viewchangehistory"
                    btnText={"View Messages "}
                    onClickBtnFn={() =>
                      setState({
                        ...getState,
                        status: true,
                        modelType: "viewchangeHistory",
                      })
                    }


                  /></div>
                <div className={"fullWidthDiv"}>
                  <DialogPopup
                    isOpen={getState.status}
                    dialogTitle={"View Special Messages and Change History"}
                    onClosePopupFn={() =>
                      setState({
                        ...getState,
                        status: false,
                        modelType: "",
                      })
                    }

                  >
                    <UICard cardHeading="Special Messages">
                      {/* <DataTable
                        isSetTableHeader={false}
                        elementWrapperStyle={"specialMessage scrollMessage"}
                        isSetSearchFrom={false}
                        tableHeaderList={[
                          { displayName: "", key: "message", isSort: false },
                        ]}
                        isSetAction={false}
                        isSetTableFooter={false}
                        tableBodyList={_get(formState, `message.list`, [])}
                        onRowStyleFn={(data) => data.row.color_code}
                      /> */}
                      <MessageTable dataList={_get(formState, `message.list`, [])} />
                    </UICard >


                    <UICard cardHeading="Changes">
                      {isEmpty(_get(formState, `special.message`, [])) ? <p>No record(s) found</p> :
                        <DataTable
                          isSetTableHeader={false}
                          elementWrapperStyle={"specialMessage"}
                          isSetSearchFrom={false}
                          tableHeaderList={[
                            { displayName: "Changed Parameter ", key: "change_parameter", isSort: false },
                            {
                              displayName: "Changed Date (eRL)", key: "erl_changed_at", isSort: false,
                              onPrintFn: (rawData) => {
                                if (!isEmpty(rawData.erl_changed_at)) { return dateObjectToString(rawData.erl_changed_at, "dd/mm/YY"); }
                                else { return ("-") }

                              },

                            },

                            {
                              displayName: "Changed Date (DMT)", key: "dmt_Changed_at", isSort: false, onPrintFn: (rawData) => {
                                if (!isEmpty(rawData.dmt_Changed_at)) { return dateObjectToString(rawData.dmt_Changed_at, "dd/mm/YY"); }
                                else { return ("-") }

                              },
                            },
                            {
                              displayName: "Old Value ", key: "old_value", isSort: false,
                              onPrintFn: (rawData) => {
                                if (rawData.change_parameter === "Province") { return ProvincesByCodeMatcher[rawData.old_value] || ProvincesMatcher[rawData.old_value]; }
                                else if (rawData.change_parameter === "Year of Manufacture") {
                                  return !isEmptyValue(rawData.old_value) ? rawData.old_value : ""

                                }
                                else if (rawData.change_parameter === "First Registration Date") {
                                  return dateObjectToString(rawData.old_value, "dd/mm/YY")

                                }

                                else { return (rawData.old_value) }

                              },
                            },

                            {
                              displayName: "New Value ", key: "new_value", isSort: false,
                              onPrintFn: (rawData) => {
                                if (rawData.change_parameter === "Province") { return ProvincesByCodeMatcher[rawData.new_value] || ProvincesMatcher[rawData.new_value]; }
                                else if (rawData.change_parameter === "Year of Manufacture") {
                                  return !isEmptyValue(rawData.new_value) ? rawData.new_value : ""

                                } else if (rawData.change_parameter === "First Registration Date") {
                                  return dateObjectToString(rawData.new_value, "dd/mm/YY")

                                }
                                else { return (rawData.new_value) }

                              },
                            },
                          ]}
                          isSetAction={false}
                          tableBodyList={_get(formState, `special.message`, [])}

                        />}
                    </UICard >
                  </DialogPopup>

                </div>
              </div>



            </PaddingLessUICard>}
        </div> : null
      }
    </div>
  );
};




export { Specialmessage };
