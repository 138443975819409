/*
 * @Author: Gajani Kangesan
 * @Date: 2023-03-14 13:05:28
 * @Last Modified by: Gajani Kangesan
 * @Last Modified time: 2023-03-14 16.30:00
 */

import React, { useContext } from "react";

import { InputButton } from "../../../../../ui-components/ui-elements/form";
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";
import { SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { settlementAPI } from "../../../../../../config/apiUrl.config";
import { apiResponseStoringType, CoreContext } from "../../../../core/context-providers/CoreContext.provider";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";



const CustomRevenueButton = (props) => {
    const [, uiAction] = useContext(UIContext);

    const [coreState, coreAction] = useContext(CoreContext);
    const [formState, formAction] = useContext(FormContext)

    return (

        <SubmitButton

            btnText="Submit"
            elementStyle="btnWrapper"
            formGroupName={"refreshSettlement"}
            startIcon={""}
            isValidate={true}
            validationObject={{
                fileds: {

                    status: "Mark All As",
                    manually_settled_reason_id: "Reason",


                },
                rules: _get(formState, `refreshSettlement.isChanged`, "") == true ?
                    {} : {
                        status: "required",
                        manually_settled_reason_id: "required",

                    },
                message: {
                },

            }}

            flashMessages={{
                success: {
                    status: true,
                    message: "Successfully Updated.",
                    messageType: "success",
                },
                failed: {
                    status: true,
                    message: "Something wrong.",
                    messageType: "error"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false,
            }}
            // apiDataStoringObject={{
            //     setLoader: true,
            //     storingType: "none"
            // }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${settlementAPI.url}`,
                    key: settlementAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject({
                    manually_settled_reason_id: _get(formState, "refreshSettlement.manually_settled_reason_id", null),
                    status: _get(formState, "refreshSettlement.status", null),
                    service_settlement_id: props.row.service_settlement_id
                });
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {

                    props.onClosePopupFn();
                    props.mainScreenCloseFn()

                }
            }}
        />

    )
};

export {
    CustomRevenueButton
}