/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-05 13:17:03
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-20 14:09:05
 */

import React from "react";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { licenceFeeAPI } from '../../../../../config/apiUrl.config';
import { useContext } from 'react';
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { thousandSeparator,numberThousandSeperator } from "../../../../../helpers/common-helpers/common.helpers";


const ViewLicenseArrears = (props) => {
  const [formState, formAction] = useContext(FormContext);
  return (
    <div className="row">
      <div className="col-12">
        <DataTable
          dataTableKey={licenceFeeAPI.key}
          isSetTableHeader={false}
          tableHeaderList={[
            { displayName: "From", key: "start_date", isSort: false, onPrintFn: (rawData)=>dateObjectToString(rawData.start_date, "dd/mm/YY") },
            { displayName: "To", key: "end_date", isSort: false, onPrintFn: (rawData)=>dateObjectToString(rawData.end_date, "dd/mm/YY") },
            { displayName: "Total Fee (LKR)", key: "fee", isSort: false ,  isRightAlign:true,  onPrintFn: (rawData)=>numberThousandSeperator(Number(rawData.fee).toFixed(2)) },
            { displayName: "Total Fine (LKR)", key: "fine", isSort: false , isRightAlign:true,  onPrintFn: (rawData)=>numberThousandSeperator(Number(rawData.fine).toFixed(2)) },
            { displayName: "Additions (LKR)", key: "addition", default: "-",  isRightAlign:true,  onPrintFn: (rawData)=>numberThousandSeperator(Number(rawData.addition_total).toFixed(2)), isSort: false },
            { displayName: "Deductions (LKR)", key: "deduction", default: "-",  isRightAlign:true, onPrintFn: (rawData)=>"("+numberThousandSeperator(Number(rawData.deduction_total).toFixed(2))+")", isSort: false },
            { displayName: "Total Arrears (LKR)", key: "arrears", isSort: false,   isRightAlign:true, onPrintFn: (rawData)=>numberThousandSeperator(Number(rawData.fee+rawData.fine+rawData.addition_total-rawData.deduction_total).toFixed(2))},
          ]}
 
          tableBodyList={_get(formState, `licence_charges_form.data.arrears`, [])}
          isSetTableFooter={false}
          isSetAction={false}
        />
        <div className="col-12 floatLeft defaultMarginTop">
          <strong>Grand Total (LKR):</strong> {thousandSeparator(getGrandTotal(_get(formState, `licence_charges_form.data.arrears`, [])),2)}
        </div>
      </div>
    </div>
  );
};

const getGrandTotal=(records)=>{
 let total =0
 if(!records.length){
   return total
 }

 records.forEach(element => {
  total +=element.fee + element.fine + element.addition_total - element.deduction_total
 });

 return total
}

export { ViewLicenseArrears };
