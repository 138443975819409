/*
 * @Author: Gajani Kangesan
 * @Date: 2022-07-22 15:45:03
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 12:14:17
 */

import React from "react";
import { printHistoryAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { RowNo } from "./../../../../ui-components/ui-elements/documents-uploader/documents-uploader-includes/documents-uploader-includes";
import {
  DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { isEmpty } from "lodash";
const PrintHistory = (props) => {
  return (
    <div className={"fullWidthDiv"}>
      <DataTable
        isSetTableHeader={false}
        apiUrl={`${printHistoryAPI.url}/${props.row.licence_id}`}
        dataTableKey={printHistoryAPI.key}
        elementWrapperStyle={"specialMessage"}
        isSetSearchFrom={false}
        tableHeaderList={[
          { displayName: "Print Sequence", key: "__no__", isSort: false },
          { displayName: "Date & Time", key: "printed_at", isSort: false, onPrintFn: ({ printed_at }) => isEmpty(printed_at) ? "-" : dateObjectToString(printed_at, "dd/mm/YY HH:MM") },
          { displayName: "Printed by", key: "printed_by", isSort: false, onPrintFn: (rawData) => (isEmpty(rawData.printed_by) ? "-" : rawData.printed_by) + "(" + (isEmpty(rawData.printed_role_id) ? "-" : rawData.printed_role_id) + ")" },
          // { displayName: "Print Type", key: "print_type", isSort: false },
          { displayName: "Authority", key: "printed_location", isSort: false },
          { displayName: "Province", key: "printed_province", isSort: false },
          { displayName: "Serial Number", key: "serial_number", isSort: false, onPrintFn: (rawData) => rawData.licence_id.serial_number },
        ]}
        isSetAction={false}
        specificComponents={{
          "__no__": RowNo
        }}

      />



    </div>

  );
};




export { PrintHistory };
