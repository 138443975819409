/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Image/File Upload
 * @Date: 2020-06-24 13:24:56
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 13:29:38
 */

import React, { Fragment, useContext, useRef } from "react";
import PropTypes from "prop-types";

import { Icon } from "../common/BaseElements";
import { _size, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { checkValidFileType, compressImages } from "../../../../helpers/upload-component-helpers/uploadComponent.helpers";
import FileDisplay from "./upload-component-includes/FileDisplay";
import { FormHelperTextWrapper, InputLabelWrapper } from "../form/form-includes/FormCoreIncludes";
import { getValueByFilter } from "../../../../helpers/common-helpers/common.helpers";
import { InputButton } from "../form";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";



/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Upload Component
 * --------------------------------------------
 */

// Upload Types
const uploadTypes = {
    image: "image",
    file: "file"
};

// Valid Image Types
const validImageTypes = {
    png: "png",
    jpg: "jpg",
    jpeg: "jpeg",
};

// Valid File Types
const validFileTypes = {
    txt: "txt",
    pdf: "pdf",
    docx: "docx",
    xlsx: "xlsx",
};

const uploadBtnStyles = {
    btnStyle: "",
    labelStyle: "ml-2",
};

const UploadComponent = ({
    elementWrapperStyle = "",
    uploadSliderStyle = "",
    displayFileStyle = "",
    uploadBtnStyle = "",
    isRequired = false,
    isDisable = false,
    labelText = "",
    helperText = "",
    formGroupName = "",
    formKey = "",
    formObjectAppendKey = "",
    isMultiple = true,
    isDownload = true,
    isDelete = true,
    isButtonOnly = false,
    isCompressImages = false,
    buttonStyle = uploadBtnStyles,
    uploadType = uploadTypes.image,
    uploadApiUrl = "",
    getApiUrl = "",
    maxUploadCount = 3,
    validTypes = [validImageTypes.jpeg, validImageTypes.jpg, validImageTypes.png]
}) => {

    const inputRef = useRef(null);
    const [, coreAction] = useContext(CoreContext);
    const [formState, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    const checkUploadedCount = (isMultiple) ? maxUploadCount : 1;
    const maxSize = 15
    const sizeUnit = 'MB'
    // _get(formState, `${formGroupName}.${formKey}_fileType`, "") === "" ? 'txt' : _get(formState, `${formGroupName}.${formKey}_fileType`, "").split('/')[1]
    let fileType = ''
    const onChangeFn = async (event) => {
        if(event.target.files['0'].type.split('/')[1] === 'csv') {
            if(event.target.files[0].size > (1 * 1000000)) {
           
                uiAction.setFlashMessage({
                    status: true,
                    message: `Maximum file size of .csv type should be less than 1(${sizeUnit}).`,
                    messageType: "error",
                });
                return
            }
        }
        if(event.target.files[0].size > (maxSize * 1000000)) {
           
            uiAction.setFlashMessage({
                status: true,
                message: `Maximum file size should be ${maxSize}(${sizeUnit}).`,
                messageType: "error",
            });
            return
        }

        formAction.changeInputFn(formGroupName, `${formKey}_fileType`, "", null);
        if(event.target.files['0'].type.split('/')[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' || 
        event.target.files['0'].type.split('/')[1] === 'png' || 
        event.target.files['0'].type.split('/')[1] === 'jpg' || 
        event.target.files['0'].type.split('/')[1] === 'jpeg' ||
        event.target.files['0'].type.split('/')[1] === 'pdf' || 
        event.target.files['0'].type.split('/')[1] === 'csv') {
            formAction.changeInputFn(formGroupName, `${formKey}_fileType`, "", event.target.files['0'].type === "" ? 'txt' : (event.target.files['0'].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'docx' : event.target.files['0'].type.split('/')[1]));
            if (_size(event.target.files) > 0) {
                let files = event.target.files;
                let count = _get(formState, `${formGroupName}.${formKey}`, []).length;
                let currentUploadList = [];
    
                for (let i = 0; i < files.length; i++) {
                    if (count < checkUploadedCount) {
                        currentUploadList.push(files[i]);
                        count++;
                    } 
                    // else {
                    //     uiAction.setFlashMessage({
                    //         status: true,
                    //         message: `Maximum files count that can be attached is ${checkUploadedCount}.`,
                    //         messageType: "error",
                    //         });
                    // }
                }
    
                if (_size(currentUploadList) > 0) {
                    if (isCompressImages) {
                        currentUploadList = await compressImages(currentUploadList);
                    }
    
                    const formData = new FormData();
    
                    for (let i in currentUploadList) {
                        formData.append(formObjectAppendKey, currentUploadList[i]);
                    }
    
                    coreAction.sendRequestFn(uploadApiUrl)
                        .isMultipart(true)
                        .method("post")
                        .body(formData)
                        .setLoader(true)
                        .setInitStoring("none", null)
                        .send((error, result) => {
                            if (result?._statue ===true) {
                                if (isMultiple) {
                                    const uploadedFiles = [..._get(formState, `${formGroupName}.${formKey}`, [])];
                                    const uploadedFilesReferences = [..._get(formState, `${formGroupName}.${formKey}_doc_references`, [])];

                                    for (const key in result.data.data) {
                                        uploadedFiles.push(result.data.data[key].originalFileName);
                                        uploadedFilesReferences.push(result.data.data[key].documentReference);
                                    }
    
                                    formAction.changeInputFn(formGroupName, formKey, "", uploadedFiles);
                                    formAction.changeInputFn(formGroupName, `${formKey}_doc_references`, "", uploadedFilesReferences);
                                } else {
                                    formAction.changeInputFn(formGroupName, formKey, "", _get(result.data, "data[0].originalFileName", ""));
                                    formAction.changeInputFn(formGroupName, `${formKey}_doc_references`, "", _get(result.data, "data[0].documentReference", ""));
                                }
                            } else if(error?._statue ===true) {
                                const errorObject = [..._get(formState, `${formGroupName}._errors`, [])];
                                errorObject.push({
                                    "property": formKey,
                                    "message": (_get(error.data, "errors.name", "") === "File too large") ? "File size is too large (max: 10MB)" : _get(error.data, "errors.details[0][message]", "Something went wrong")
                                });
                                uiAction.setFlashMessage({
                                    status: true,
                                    message: `Document upload failed.`,
                                    messageType: "error",
                                    });
                                formAction.setFormErrorFn(formGroupName, errorObject);
                            }
                        });
                   
                }
            }
        } else {
            uiAction.setFlashMessage({
                status: true,
                message: `Invalid file format. File format should be either in .pdf, .docx, .csv, .jpg, .jpeg or .png.`,
                messageType: "error",
                });
        }
        
        
    };

    const deleteImage = (value) => {
        if (isMultiple) {
            const temp = _get(formState, `${formGroupName}.${formKey}`, []).filter(element => element !== value);
            formAction.changeInputFn(formGroupName, formKey, "", temp);
        } else {
            formAction.changeInputFn(formGroupName, formKey, "", "");
        }
    };

    return (
        <Fragment>
            {
                (isButtonOnly) ? (
                    <Fragment>
                        <div className={`upload-btn-wrapper ${uploadBtnStyle}`}>
                            <input ref={inputRef} type="file" multiple={isMultiple} disabled={isDisable} onChange={(event) => onChangeFn(event)} />
                        </div>

                        <div className={`float-left ${buttonStyle.btnStyle}`}>
                            <InputButton
                                startIcon={"fa fa-file"}
                                btnText={"Choose File"}
                                onClickBtnFn={() => inputRef.current.click()}
                            />
                        </div>
                        
                        <div className={`float-left ${buttonStyle.labelStyle}`}>
                            {isMultiple ? 
                                (_get(formState, `${formGroupName}.${formKey}`, "") && _get(formState, `${formGroupName}.${formKey}`, "").length !== 0 && `${_get(formState, `${formGroupName}.${formKey}`, "")[_get(formState, `${formGroupName}.${formKey}`, "").length - 1]}`) 
                                : 
                                (`${_get(formState, `${formGroupName}.${formKey}`, "")}`)
                                }
                        </div>
                    </Fragment>
                ) : (
                    <div className={`defaultUploadComponentWrapper ${elementWrapperStyle}`}>
                        <InputLabelWrapper
                            isRequired={isRequired}
                            lableText={labelText}
                        />

                        <div className={`defaultUploadSlider d-inline-flex ${uploadSliderStyle}`}>

                            {
                                (isMultiple) ? (
                                    <Fragment>
                                        {
                                            _get(formState, `${formGroupName}.${formKey}`, []).map((value, index) => {
                                                return (index < checkUploadedCount) ? (
                                                    <FileDisplay
                                                        key={index}
                                                        elementStyle={displayFileStyle}
                                                        file={value}
                                                        apiUrl={getApiUrl}
                                                        isDownload={isDownload}
                                                        isDelete={isDelete}
                                                        onDeleteFn={deleteImage}
                                                    />
                                                ) : null
                                            })
                                        }

                                        {
                                            (_size(_get(formState, `${formGroupName}.${formKey}`, [])) < checkUploadedCount) ? (
                                                <div className={`upload-btn-wrapper ${uploadBtnStyle}`}>
                                                    <div className="btn text-center" onClick={() => inputRef.current.click() }>
                                                        <Icon iconClass={`${(uploadType === "image") ? "fas fa-camera" : "far fa-file-alt"}`} />
                                                        <h5>Upload</h5>
                                                    </div>
                                                    <input ref={inputRef} type="file" multiple={isMultiple} disabled={isDisable} onChange={(event) => onChangeFn(event)} />
                                                </div>
                                            ) : null
                                        }
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <FileDisplay
                                            elementStyle={displayFileStyle}
                                            file={_get(formState, `${formGroupName}.${formKey}`, "")}
                                            apiUrl={getApiUrl}
                                            isDownload={isDownload}
                                            isDelete={isDelete}
                                            onDeleteFn={deleteImage}
                                        />

                                        {
                                            (_get(formState, `${formGroupName}.${formKey}`, "") === "") ? (
                                                <div className={`upload-btn-wrapper ${uploadBtnStyle}`}>
                                                    <div className="btn text-center" onClick={() => inputRef.current.click() }>
                                                        <Icon iconClass={`${(uploadType === "image") ? "fas fa-camera" : "far fa-file-alt"}`} />
                                                        <h5>Upload</h5>
                                                    </div>
                                                    <input ref={inputRef} type="file" multiple={isMultiple} disabled={isDisable} onChange={(event) => onChangeFn(event)} />
                                                </div>
                                            ) : null
                                        }
                                    </Fragment>
                                )
                            }

                        </div>

                        <FormHelperTextWrapper
                            inputError={getValueByFilter(_get(formState,`${formGroupName}._errors`,[]), ["property", formKey], "message", "", "")}
                            helperText={helperText}
                        />
                    </div>
                )
            }
        </Fragment>
    )
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Upload Component
 * --------------------------------------------
 */

UploadComponent.propTypes = {
    /** Element Wrapper css class */
    elementWrapperStyle: PropTypes.string,
    /** Upload slider element css class */
    uploadSliderStyle: PropTypes.string,
    /** Display uploaded File css class */
    displayFileStyle: PropTypes.string,
    /** Upload button css class */
    uploadBtnStyle: PropTypes.string,
    /** Label text */
    labelText: PropTypes.string,
    /**  input field helper text */
    helperText: PropTypes.string,
    /** set required * mark */
    isRequired: PropTypes.bool,
    /** Is disable or not */
    isDisable: PropTypes.bool,
    /** form group name */
    formGroupName: PropTypes.string,
    /** Form state key */
    formKey: PropTypes.string,
    /** Form object append key */
    formObjectAppendKey: PropTypes.string,
    /** Is multiple files allowed */
    isMultiple: PropTypes.bool,
    /** Is download allowed */
    isDownload: PropTypes.bool,
    /** Is delete files allowed */
    isDelete: PropTypes.bool,
    /** Upload type */
    uploadType: PropTypes.oneOf([uploadTypes.image, uploadTypes.file]),
    /** Upload api url */
    uploadApiUrl: PropTypes.string,
    /** get api url */
    getApiUrl: PropTypes.string,
    /** Max upload count */
    maxUploadCount: PropTypes.number,
    /** Valid file types */
    validTypes: PropTypes.array,
};

//-------------UploadComponent--------------


export {
    UploadComponent,
    uploadTypes,
    validFileTypes,
    validImageTypes
};
