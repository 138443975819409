/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 16:08:58 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-10-26 08:13:02
 */

import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';

import { VariableToComponent, TooltipWrapper } from "../ui-elements/common/BaseElements";
import { DialogPopup } from "../ui-elements/modal/DialogPopup";
import SessionCollection from "../../modules/session-collection/pages/SessionCollection";
import { UIContext } from "../context-providers/UIContext.provider";
import { NotificationUI } from "../../modules/notification/pages/Notification";
import { FormWrapper, InputBoxWithState, SelectBoxWithState, RadioButtonsGroupWithState, SubmitButton } from "../ui-elements/form";
import { availableCounterListApi, availableSessionListApi, changeSerialNumberReasonListApi, counterSerialListAPI, loadSerialCreateAPI, sessionEndApi } from '../../../config/apiUrl.config';
import { FormContext } from '../../modules/core/context-providers/FormContext.provider';
import { _get, _getInt } from '../../../helpers/common-helpers/lodash.wrappers';
import { apiResponseStoringType, DataTable, actionBtnTypes, RawIndexMemo } from '../ui-elements/table/DataTable';
import { AuthContext } from '../../modules/core/context-providers/AuthContext.provider';
import { callApi } from '../../../helpers/common-helpers/callApi.helpers';
import { isEmpty } from 'lodash';
import { ChangeDiscardSerialSubmitBtn } from '../../modules/cashier/pages/Cashier';
import { PageLoader } from "./common-includes/PageLoader";
import { dateObjectToString } from '../../../helpers/common-helpers/dateTime.helpers';
import { NumberInputBoxWithState } from '../ui-elements/form/InputBox';
import { isEmptyValue } from '../../../helpers/common-helpers/common.helpers';

const ModalBtnWrapper = (props) => {
    return <SubmitButton
        btnText={"Submit"}
        tooltip={"Submit"}
    />
}

const ChangeSerialNumber = (props) => {
    const formKey = props.formKey + "_change_serial";
    const [formState, formAction] = useContext(FormContext);
    const counterId = isEmpty(_get(props, "counter_id", null)) ? _get(formState, `cashierSessionForm.counter_id`, "") : _get(props, "counter_id", null);
    
    return (
        <FormWrapper
            setGroupName={formKey}
            setFormObject={{
                usable: "0",
                serial_number_to: "",
                counter_id: counterId,
            }}
        >
            <div className="fullWidthDiv defaultMarginBottom">
                <strong>Current Serial Number: </strong> {_get(props, `current_serial`, "")}
            </div>
            <NumberInputBoxWithState
                isRequired={true}
                labelText={"Change Up to"}
                isAllowNegative={false}
                isThousandSeparator={false}
                formGroupName={formKey}
                inputName={"serial_number_to"}
                decimalScale={0}
                isAllowedFn={(value) => {
                  let checkValue = value.value.toString();
    
                  if (checkValue.includes(".")) {
                    checkValue = checkValue.split(".")[0];
                  }
    
                  return checkValue.length <= 12;
                }}
            />
            <SelectBoxWithState
                isRequired={true}
                labelText={"Reason"}
                formGroupName={formKey}
                inputName={"changed_reason_id"}
                apiUrl={changeSerialNumberReasonListApi.url}
                apiStateKey={changeSerialNumberReasonListApi.key}
                emptySelectOptionTxt={"Please Select Reason"}
                keyName="id"
                valueName="reason"
            />
            <InputBoxWithState
                labelText={"Note"}
                formGroupName={formKey}
                inputName={"note"}
                rows={5}
                maxLength={200}
                isMultiline={true}
            />
            <RadioButtonsGroupWithState
                dataList={[
                    {
                        "value": "1",
                        "label": "Usable"
                    },
                    {
                        "value": "0",
                        "label": "Not usable"
                    }
                ]}
                formGroupName={formKey}
                inputName={"usable"}
            />
        </FormWrapper>
    );
}

const RowNo = (props) => {
    return (
        <RawIndexMemo
            page_size={_getInt(props.pagingObject, "per_page", 1)}
            current_page={_getInt(props.pagingObject, "current_page", 1)}
            index={props.rowIndex || 0}
        />
    );
};

const LoadSerialFrom = (props) => {
    const formKey = `${props.fromParent.formKey}`;

    const serialFrom = _get(props, "row.auth_serial_serial_number_from", 0);
    const serialTo = _get(props, "row.auth_serial_serial_number_to", 0)
    
    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            setFormObject={{
                id: props.row.id,
                serial_number_from: serialFrom,
                serial_number_to: serialTo,
                authority_id: _get(props, "fromParent.authorityId", null),
                counter_id: _get(props, "row.counter_id", null),
                authority_serial_number_range_id: _get(props, "row.counter_serial_number_range_authority_serial_number_range_id", null),
                counter_serial_number_range_id: _get(props, "row.id", null),
            }}
            onDestroyUnsetFormObject={true}
        >
            <div className="removeContent">
                <p>Are you sure to load this range? (From :- {serialFrom} To :- {serialTo} )</p>
                <br />
                <br />
            </div>
        </FormWrapper>
    );
};

const LoadSerialBtn = (props) => {

    const [authStatus, authAction] = useContext(AuthContext);
    const [formState, formAction] = useContext(FormContext);
    const authority_id = _get(authStatus, "authUser.authorityId", "");
    const user_id = _get(authStatus, "authUser.id", "");
    const popupType = _get(props, 'popupType', null);
    const row = _get(props, 'row', null);
    
    return (
        <SubmitButton
            btnText={"Yes"}
            startIcon={"fas fa-check"}
            formGroupName={props.formGroupName}
            tooltip={"Load Serial"}
            dataTableKey={props.dataTableKey}
            isValidate={false}
            flashMessages={{
                success: {
                    status: true,
                    message: `Serial Number successfully Loaded`,
                    messageType: "success",
                },
                failed: {
                    status: true,
                    message: `Serial Number Load fail .`,
                    messageType: "error",
                },
            }}
            validationObject={{
                fileds: {
                    serial_number_to: "New Serial Number",
                    changed_reason_id: "Reason",
                    note: "Note",
                },
                rules: {
                    serial_number_to: "required|max:12",
                    changed_reason_id: "required",
                    note: "max:200",
                },
            }}
            onGetAPIEndPointFn={(formState) => {
                return {
                    url: loadSerialCreateAPI.url,
                    key: loadSerialCreateAPI.key,
                };
            }}
            callApiObject={{
                isSetHeaders: true,
                method: "post",
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.dateTableLinkedForm
            }}
            onResponseCallBackFn={(error, response) => {
                if (!error) {
                    if(popupType === "load-serial"){                        
                        if(isEmpty(_get(response, "data.data[0].serial_number", ""))){
                            formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", _get(row, "auth_serial_serial_number_from", ""));
                        }else{
                            formAction.changeInputFn("change_serial_on_session", "licenseSequenceVal", "", _get(response, "data.data[0].serial_number", ""));
                        }
                    } else {
                        callApi(`${availableCounterListApi.url}/${authority_id}/counters/available-counters/list?sort_by=name|asc&active=true`)
                        .method("get")
                        .headers(true)
                        .body({})
                        .isMultipart(false)
                        .send((error, result) => {
              
                          if(!error){
                            formAction.changeInputFn("cashierSessionForm", "session_data", "", _get(result, "data.data", []));
                          }
                        });
                        if(isEmpty(_get(response, "data.data[0].serial_number", ""))){
                            formAction.changeInputFn("cashierSessionForm", "serial_number", "", _get(row, "auth_serial_serial_number_from", ""));
                        }else{
                            formAction.changeInputFn("cashierSessionForm", "serial_number", "", _get(response, "data.data[0].serial_number", ""));
                        }
                    }
                    props.onClosePopupFn();
                    props.loadSerialUi();
                }
            }}
        />
    );
};

const EndSessionBtn = (props) => {
    const popupType = _get(props, 'popupType', null);
    const sessionId = _get(props, 'sessionId', null);
    const history = useHistory();
    return (
        <SubmitButton
            btnText={popupType === "load-serial" ? "End Session" : "Yes"}
            startIcon={popupType === "load-serial" ? "mdi mdi-logout" : "fas fa-check"}
            formGroupName={props.formGroupName}
            isValidate={false}
            flashMessages={{
                success: {
                    status: true,
                    message: `Cashier Session ended.`,
                    messageType: "success",
                },
                failed: {
                    status: true,
                    message: `Cashier Session end fail .`,
                    messageType: "error",
                },
            }}
            onGetAPIEndPointFn={(formState) => {
                return {
                    url: `${sessionEndApi.url}/${sessionId}/end`,
                    key: sessionEndApi.key,
                };
            }}
            callApiObject={{
                isSetHeaders: true,
                method: "put",
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.responseLinkToFrom
            }}
            onResponseCallBackFn={(error, response) => {
                if (!error) {
                    history.push("/counter");
                }
            }}
        />
    );
};

const EndSessionForm = (props) => {
    const formKey = `${props.formKey}`;
    
    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            setFormObject={{
                end_at: new Date(),
            }}
            onDestroyUnsetFormObject={true}
        >
            <div className="removeContent">
                <p>Are you sure to end this session? </p>
                <br />
                <br />
            </div>
        </FormWrapper>
    );
};

const LoadSerialNumber = (props) => {
    const [formState, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    const popupType = _get(props, "popupType", null);
    const history = useHistory();
    if(isEmptyValue(_get(props, 'counterId', null))){
        uiAction.setFlashMessage({
            status: true,
            message: `No active session for the current user.`,
            messageType: "error",
        });
        history.push("/dashboard");
    }
    return (
        <div className='loadSerialNumber'>
            <DataTable
                tableName={"Serial numbers"}
                isSetSearchFrom={false}
                apiUrl={`${counterSerialListAPI.url}/${props.counterId ? props.counterId:2 }/serial-numbers?used=false`}
                dataTableKey={counterSerialListAPI.key}
                isSetAction={true}
                isSetTableHeader={true}
                tableHeaderList={[
                    { displayName: "Sequence", key: "__no__", isSort: false },
                    { displayName: "From", key: "auth_serial_serial_number_from", isSort: false, },
                    { displayName: "To", key: "auth_serial_serial_number_to", isSort: false },
                    {
                        displayName: "Remaining Numbers", 
                        key: "used_count", 
                        isSort: false, 
                        onPrintFn: (rowData) => 
                        ((parseInt(rowData.auth_serial_serial_number_to)) - (parseInt(rowData.auth_serial_serial_number_from)) + 1 - (parseInt(rowData.discarded_count) + parseInt(rowData.used_count)))
                    },
    
                ]}
                specificComponents={{
                    __no__: RowNo,
                }}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: {
                            icon: "fas fa-upload",
                            tooltip: 'Load Serial numbers',
                        },
                        modelContentElement: LoadSerialFrom,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Please Confirm",
                            closeButton: {
                                btnText: "No",
                                startIcon: "fas fa-times"
                            },
                            isCustomButton: true,
                            customButtonComponent: LoadSerialBtn,
                            customButtonComponentProps: {
                                "uiType": "Add",
                                dataTableKey: counterSerialListAPI.key,
                                loadSerialUi: props.closeSerialUi,
                                popupType: _get(props, 'popupType', null)
                            }
                        },
                        parentToModelProps: {
                            "uiType": "Load",
                            formKey: loadSerialCreateAPI.key + "_load",
                            authorityId: props.authorityId
    
                        },
                    }]}
            />
        </div>
    );
}

const CounterTemplate = ({
    children = null,
    FooterComponent = null,
    isShowFooter = true,
    isShowHeader = true,
    propsToFooter = {},
    getSessionId = () => {},
    getCounterId = () => {}
}) => {
    const history = useHistory();
    const [formState, formAction] = useContext(FormContext);

    const [getState, setState] = useState({
        "showPopup": false,
        "popupType": null
    });
    const [uiState, uiDispach] = useContext(UIContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const user_id = _get(authStatus, "authUser.id", "");
    const authority_id = _get(authStatus, "authUser.authorityId", "");    
    
    getSessionId(getState['sessionId'])
    getCounterId(getState['counterId'])
    const authorityTypeCode = _get(
        authStatus,"authUser.authority_type_code", null
      );
    useEffect(() => {      
        callApi(`${availableSessionListApi.url}/${user_id}/sessions/list?authority_id=${authority_id}`)
        .method("get")
        .headers(true)
        .body({})
        .isMultipart(false)
        .send(async (error, result) => {
            if(!error){
                if(parseInt(_get(result, "data.data[0].counter_id.active", 0)) === 0){
                    uiDispach.setFlashMessage({
                        status: true,
                        message: `Counter deactivated.`,
                        messageType: "error",
                    });
                    history.push("/dashboard");
                }
                const next_serial = isEmpty(_get(result, "data.data[0].counter_id.next_serial_number", "")) ? "" : _get(result, "data.data[0].counter_id.next_serial_number", "");
                const counter_id = isEmpty(_get(result, "data.data[0].counter_id.id", "")) ? "" : _get(result, "data.data[0].counter_id.id", "");
                const counter_name = isEmpty(_get(result, "data.data[0].counter_id.name", "")) ? "" : _get(result, "data.data[0].counter_id.name", "");
                const session_id = isEmpty(_get(result, "data.data[0].id", "")) ? "" : _get(result, "data.data[0].id", "");
                setState({
                    ...getState,
                    "licenseSequenceVal": next_serial,
                    "counterId": counter_id,
                    "counterName": counter_name,
                    "sessionId": session_id,
                    "showPopup": isEmpty(next_serial) ? true : false,
                    "popupType": isEmpty(next_serial) ? "load-serial" : null,
                })
                formAction.changeInputFn("counter-serial", "id", "", next_serial);
                formAction.changeInputFn("counter-serial", "active", "", _get(result, "data.data[0].counter_id.active", 0));
            
            }
        });
    }, [_get(formState, "change_serial_on_session", "")]);

    const closePopup = () => {
        isEmpty(getState.licenseSequenceVal) ? 
            setState({
                ...getState,
                showPopup: true,
                popupType: "load-serial"
            })
        :
            setState({
            ...getState,
            showPopup: !getState.showPopup,
            })
    }
    
    const EndSessionBtnForLoadSerial = () => {
        return (
            <SubmitButton
                startIcon={"mdi mdi-logout"}
                btnText={"End Session"}
                onClickBtnFn={() => setState({
                    ...getState,
                    showPopup: true,
                    popupType: "end-session",
                    sessionId: getState.sessionId,
                })}
            />
        );
    };
    return (
        <div className={"counterTemplateWrapper"}>
            <PageLoader />
            {isShowHeader &&
                <div className="counterTemplateHeaderWrapper">
                    <div className="pageHeading">
                        <h1><b>Revenue Licence Issuance</b> - {_get(authStatus, "authUser.name", "")}
                        ({_get(authStatus, "authUser.roles", "")})  
                        {(_get(authStatus, 'authUser.authorityCode', "")) ?
                            (` - ${_get(authStatus, 'authUser.pdmtId.code', '')} (${_get(authStatus, 'authUser.authorityName', '')})`) : //bug #3885
                            (` - ${_get(authStatus, 'authUser.provinceCode', '')}`)}</h1>
                        <h5><b>Counter -</b> { getState.counterName }</h5>
                        <h5><b>System Date & Time -</b> { dateObjectToString(new Date(), "dd/mm/YY HH:MM") }</h5>
                    </div>

                    <div className="pageHeadingLinkWrapper">
                        <FormWrapper
                            elementStyle={"changeSerialOnSession"}
                            setGroupName={"change_serial_on_session"}
                            setFormObject={
                                {"licenseSequenceVal": _get(formState,"counter-serial.id")}
                            }
                        >
                            <div className="licenseSequenceInputBox">
                                <div className="serialLabel"><b>Current Serial Number</b></div>
                                <label>{_get(formState,"counter-serial.id")}</label>
                                <TooltipWrapper description={'Change Serial Number'}>
                                    <button 
                                        onClick={() => setState({
                                            ...getState,
                                            showPopup: true,
                                            popupType: "edit-model"
                                        })}
                                    >
                                        <i className="mdi mdi-lead-pencil"></i>
                                    </button>
                                </TooltipWrapper>
                            </div>
                        </FormWrapper>

                        <TooltipWrapper description={uiState.fullScreen == false ? 'Full screen' : 'Exit full screen'}>
                            <button 
                            onClick={() => {
                                if(uiState.fullScreen == false)
                                {
                                    uiDispach.toggleFullScreen();
                                }else{ 
                                    uiDispach.toggleFullScreen();
                                    document.webkitExitFullscreen();
                                }
                            }}>
                                <i className={`mdi ${(uiState.fullScreen == false) ? "mdi-arrow-expand-all" : "mdi-arrow-collapse-all"}`}></i>
                            </button>
                        </TooltipWrapper>

                        {
                            authorityTypeCode !== null &&
                                    authorityTypeCode === "WLA" ? null : <TooltipWrapper description={'Session Collection'}>
                                <button onClick={() => setState({
                                    ...getState,
                                    showPopup: true,
                                    popupType: "DrliHistory"
                                })}>
                                    <i className="mdi mdi-calculator"></i>
                                </button>
                            </TooltipWrapper>
                        }

                        <TooltipWrapper description={'Notification'}>
                            <button onClick={() => setState({
                                ...getState,
                                showPopup: true,
                                popupType: "notification"
                            })}>
                                {(uiState.notificationCount === null || uiState.notificationCount === 0 || uiState.notificationCount < 0) ? (
                                <i className="mdi mdi-bell"></i>
                            ) : (    
                                    <Badge badgeContent={uiState.notificationCount} color="error">
                                        <i className="mdi mdi-bell"></i>
                                        {/*<i className="mdi mdi-chevron-down arraow"></i>*/}
                                    </Badge> 
                                    
                            )}
                                {/* <i className="mdi mdi-bell"></i> */}
                            </button>
                        </TooltipWrapper>

                        <TooltipWrapper description={'Exit Temporarily'}>
                            <button onClick={() => {
                                uiDispach.toggleFullScreen();
                                history.push("/dashboard");
                            }}>
                                <i className="mdi mdi-pause"></i>
                            </button>
                        </TooltipWrapper>

                        <TooltipWrapper description={'End Counter Session'}>
                            <button 
                                onClick={() => setState({
                                    ...getState,
                                    showPopup: true,
                                    popupType: "end-session",
                                    sessionId: getState.sessionId,
                                })}
                            >
                                <i className="mdi mdi-logout"></i>
                            </button>
                        </TooltipWrapper>
                    </div>
                </div>
            }

            <div className="counterTemplateBodyWrapper">
                {children}
            </div>

            {isShowFooter &&
                <div className="counterTemplateFooterWrapper">
                    {FooterComponent &&
                        <VariableToComponent component={FooterComponent} props={propsToFooter} />
                    }
                </div>
            }

            <DialogPopup
                isOpen={getState.showPopup}
                isFullScreen={getState.popupType === "end-session" || getState.popupType === "edit-model" ? false : true}
                dialogTitle={
                    getState.popupType === "edit-model"
                    ? "Change Serial Number" :
                    getState.popupType === "load-serial"
                    ? "Load Serial Numbers" :
                    getState.popupType === "end-session"
                    ? "End Counter Session" :
                    getState.popupType === "DrliHistory"
                        ? "Session Collection"
                        : "Notification"
                }
                onClosePopupFn={closePopup}
                isCustomButton={getState.popupType === "edit-model" || getState.popupType === "end-session" || getState.popupType === "load-serial"}
                customButtonComponent={
                    getState.popupType === "edit-model" ? ChangeDiscardSerialSubmitBtn 
                    : getState.popupType === "end-session" ? EndSessionBtn 
                    : getState.popupType === "load-serial" ? EndSessionBtnForLoadSerial : null}
                customButtonComponentProps={{
                    "popupType": getState.popupType,
                    "current_serial": getState.licenseSequenceVal,
                    "formKey": _get(formState, `cashierSessionForm`, ""),
                    "formGroupName": "cashierSessionForm",
                    "sessionId": getState.sessionId,
                    "counter_name": getState.popupType === "edit-model" ? getState.counterName : '',
                    "closeSerialUi": closePopup
                }}
                closeButton={{
                    btnText: getState.popupType === "edit-model" ? "Cancel" : 
                        getState.popupType === "end-session" ? "No" : "Close",
                    startIcon: getState.popupType === "end-session" ? "fas fa-times" : "far fa-times-circle",
                    isBtnDisabled: getState.popupType === "load-serial" ? true : false,
                }}
                isCloseButton={getState.popupType === "load-serial" ? false : true}
            >
                {
                    getState.popupType === "edit-model"? 
                        <ChangeSerialNumber
                            formKey={"cashierSessionForm"}
                            current_serial={getState.licenseSequenceVal}
                            counter_id={getState.counterId}
                            popupType={getState.popupType}
                        />
                    : getState.popupType === "load-serial" ?
                        <LoadSerialNumber 
                            counterId={getState.counterId} 
                            authorityId={authority_id}
                            popupType={getState.popupType}
                            closeSerialUi={closePopup} />
                    : getState.popupType === "end-session" ?
                        <EndSessionForm 
                            formKey={"cashierSessionForm"}
                            sessionId={getState.sessionId}/>
                    : getState.popupType === "notification"
                        ? <NotificationUI />
                        : <SessionCollection />
                }
            </DialogPopup>
        </div>
    );
}


export {
    LoadSerialNumber,
    CounterTemplate,
    ChangeSerialNumber,
    ModalBtnWrapper as ModelBtnWrapper,
    EndSessionBtn,
    EndSessionForm
}