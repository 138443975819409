/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-21 16:05:59
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-01 13:04:11
 */

import React from "react";
import {
  ApplicabilityTypeCode,
  ChargeEffectCode,
  ChargeTypeCode,
  DateBaseCode,
  DateDurationCode,
  WeightBaseCode,
} from "../../../../../config/enum.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import {
  _findindex,
  _get,
} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  AuditDetail,
  UICard,
  ViewDetailBlock,
} from "../../../../ui-components/ui-elements/common/BaseElements";

export const getCodeName = (array, code) => {
  const index = _findindex(array, (e) => e.id === code);
  return index !== -1 ? array[index]["value"] : "-";
};

const ViewAdditionDeduction = (props) => {

  const numberOfLabelCol = 4;

  return (
    <div>
      <div className="defaultMarginBottom">
        <h6 className={"headingTxt defaultMarginBottom"}>
          Addition/Deduction Information
        </h6>

        <ViewDetailBlock
          label={"Name"}
          value={_get(props, "row.name", "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Applicability Type"}
          value={getCodeName(
            ApplicabilityTypeCode,
            _get(props, "row.applicability_type_code", "")
          )}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Charge Effect"}
          value={getCodeName(
            ChargeEffectCode,
            _get(props, "row.charge_effect_code", "")
          )}
          labelCol={numberOfLabelCol}
        />

        <div className="defaultMarginTop">
          <ViewDetailBlock
            label={"Charge Type"}
            value={getCodeName(
              ChargeTypeCode,
              _get(props, "row.charge_type_code", "")
            )}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Value"}
            value={_get(props, "row.charge_type_value", "")}
            labelCol={numberOfLabelCol}
          />
        </div>

        <div className="defaultMarginTop">
          <ViewDetailBlock
            label={"Charge Component"}
            value={_get(props, "row.charge_component.name", "")}
            labelCol={numberOfLabelCol}
          />
        </div>

        <div className="defaultMarginTop">
          <ViewDetailBlock
            label={"Effective Date"}
            value={dateObjectToString(
              _get(props, "row.effective_date", null),
              "dd/mm/YY"
            )}
            labelCol={numberOfLabelCol}
          />

          <ViewDetailBlock
            label={"Status"}
            value={_get(props, "row.active", "") == 1 ? "Active" : "Inactive"}
            labelCol={numberOfLabelCol}
          />
        </div>
      </div>

      {
        (_get(props, "row.applicability_type_code", "") === "VHS") ?
          (
            <div className="defaultMarginBottom">
              <h6 className={"headingTxt defaultPaddingTopBottom"}>
                Applicability Criteria
              </h6>

              <UICard cardHeading="General Criteria">
                <ViewDetailBlock
                  label={"Licence Type"}
                  value={_get(props, "row.licence_type_id.name", "-")}
                  labelCol={numberOfLabelCol}
                />

                <ViewDetailBlock
                  label={"Vehicle Class"}
                  value={_get(props, "row.vehicle_class_id.name", "-")}
                  labelCol={numberOfLabelCol}
                />

                <ViewDetailBlock
                  label={"Fuel Type"}
                  value={_get(props, "row.fuel_type_id.name", "-")}
                  labelCol={numberOfLabelCol}
                />
              </UICard>

              <UICard cardHeading="Weight Basis">
                <ViewDetailBlock
                  label={"Weight Base (kg)"}
                  value={getCodeName(
                    WeightBaseCode,
                    _get(props, "row.weight_base_code", "")
                  )}
                  labelCol={numberOfLabelCol}
                />

                <ViewDetailBlock
                  label={"From"}
                  value={_get(props, "row.weight_base_value_from", 0)}
                  labelCol={numberOfLabelCol}
                />
                <ViewDetailBlock
                  label={"To"}
                  value={_get(props, "row.weight_base_value_to", 0)}
                  labelCol={numberOfLabelCol}
                />
              </UICard>

              <UICard cardHeading="Seat Basis">
                <ViewDetailBlock
                  label={"From"}
                  value={_get(props, "row.seat_base_from", 0)}
                  labelCol={numberOfLabelCol}
                />
                <ViewDetailBlock
                  label={"To"}
                  value={_get(props, "row.seat_base_to", 0)}
                  labelCol={numberOfLabelCol}
                />
              </UICard>

              <UICard cardHeading="Specific Date Basis">
                <ViewDetailBlock
                  label={"Date Base"}
                  value={getCodeName(
                    DateBaseCode,
                    _get(props, "row.date_base_code", "")
                  )}
                  labelCol={numberOfLabelCol}
                />

                <ViewDetailBlock
                  label={"Date"}
                  value={dateObjectToString(_get(props, "row.date_base", null), "dd/mm/YY")}
                  labelCol={numberOfLabelCol}
                />
                <ViewDetailBlock
                  label={"Duration"}
                  value={getCodeName(
                    DateDurationCode,
                    _get(props, "row.date_duration_code", "")
                  )}
                  labelCol={numberOfLabelCol}
                />
                <ViewDetailBlock
                  label={"Value"}
                  value={_get(props, "row.date_value", null)}
                  labelCol={numberOfLabelCol}
                />
              </UICard>

              <UICard cardHeading="Engine Capacity Basis">
                <ViewDetailBlock
                  label={"From"}
                  value={_get(props, "row.engine_capacity_from", null)}
                  labelCol={numberOfLabelCol}
                />
                <ViewDetailBlock
                  label={"To"}
                  value={_get(props, "row.engine_capacity_to", null)}
                  labelCol={numberOfLabelCol}
                />
              </UICard>

            </div>
          ) : null
      }

      <div className="defaultMarginBottom">
        <AuditDetail
          createdById={_get(props, `row.created_by`, "")}
          updatedById={_get(props, `row.updated_by`, "")}
          createdLocationId={_get(props, `row.created_location_id`, "")}
          updatedLocationId={_get(props, `row.updated_location_id`, "")}
          createdAt={dateObjectToString(
            _get(props, `row.created_at`, ""),
            "dd/mm/YY HH:MM"
          )}
          updatedAt={dateObjectToString(
            _get(props, `row.updated_at`, ""),
            "dd/mm/YY HH:MM"
          )}
          labelCol={numberOfLabelCol}
        />
      </div>
    </div>
  );
};

export { ViewAdditionDeduction };
