import React, { Fragment, useContext, useEffect, useState } from "react";
import { Action } from "./includes/Action";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { _get, _set } from "../../../../helpers/common-helpers/lodash.wrappers";
import { Reason } from './includes/Reason';
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { FormWrapper, SubmitButton } from "../../../ui-components/ui-elements/form";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { licenceArrearsApi, vftCertificateAPI, vrpAPI, uploadDetailGetAPI, adminConfigDaysGetApi, licenceInvalidDocuments, vetCompanyAPI, vetAPI, calculateLicenceFeeAPI, getLatestLicenseAPI, insuranceAPI, insuranceListAPI, vftCompanyListAPI, vrpCompanyListAPI } from "../../../../config/apiUrl.config";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import DocumentsUploader from "../../../ui-components/ui-elements/documents-uploader/DocumentsUploader";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { RequestStatus, RequestTypesList, ActionTypeList, LicenceType, ProvincesByCodeMatcher } from "../../../../config/enum.config";
import { numberThousandSeperator, fromObjectToQueryString } from "../../../../helpers/common-helpers/common.helpers";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";

const LicenceArrearsSubmitButton = (props) => {
  const [formState] = useContext(FormContext);
  const [, uiAction] = useContext(UIContext);

  // const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
  const vehicle_no = _get(
    formState,
    "vehicle_search_form.searchKey",
    null
  );
  const [authStatus] = useContext(AuthContext);
  const { sessionId } = props
  const serviceId = _get(authStatus, "authUser.service_id", "");

  const current_licence_type = _get(
    formState,
    "vehicle_search_form.data.current_license_type.code",
    null
  );
  const approvalAction = _get(
    formState,
    "Request_Licence_Arrears.action",
    null
  )
  const authorityId = _get(authStatus, "authUser.authorityId", "");
  const roleCategoryCode = _get(authStatus, "authUser.role_category_code", "");
  const pdmtId = roleCategoryCode === 'OPT' ? _get(authStatus, "authUser.pdmtId.id", "") : _get(authStatus, "authUser.pdmtId", "");

  const userName = _get(authStatus, "authUser.name", "<anonymous name>");
  const currentProvince = _get(authStatus, "authUser.provinceName", null) !== null ?
    _get(authStatus, "authUser.provinceName", "<anonymous province>") :
    ProvincesByCodeMatcher[`${_get(authStatus, "authUser.pdmtCode", "")}`];
  const authority = _get(authStatus, "authUser.authorityName", "<anonymous authority>");

  return (
    <Fragment>
      <SubmitButton
        btnText={props.uiType === 'assign&Perform' ? "Save & Continue" : "Submit"}
        startIcon={"far fa-save"}
        formGroupName={props.uiType === 'assign&Perform' ? "Request_Licence_Arrears" : "request-licence-arrears"}
        tooltip={"Submit"}
        isValidate={true}
        isSetHeaders={true}
        flashMessages={{
          success: props.uiType === 'assign&Perform' ? {
            status: true,
            message: `Approval Request successfully ${ActionTypeList[approvalAction]}. Request ID :${props.approvalRequestId}`,
            messageType: "success",
          } : {
            status: true,
            message: `Approval Request successfully submitted. Request ID : ${recId}`,
            messageType: "success",
          },
          // failed: props.uiType === 'assign&Perform' ? {
          //   status: true,
          //   message: `Approval Request Decision Submit Failed. Please Try Again.`,
          //   messageType: "error",
          // } : {
          //   status: true,
          //   message: `Approval Request Submit Failed. Please Try Again.`,
          //   messageType: "error",
          // },
        }}
        validationObject={props.uiType === 'assign&Perform' && _get(formState, "Request_Licence_Arrears.action", "") === 'REJ' ? {
          fileds: {
            remark: "Remark",
          },
          rules: {
            remark: "required|max:500",
          },
          message: {
            "remark.required": "Remark is required.",
          }
        } : {
          fileds: props.uiType === 'assign&Perform' ? (_get(formState, "Request_Licence_Arrears.action", "") === 'FRD' ? (_get(formState, "Request_Licence_Arrears.forward_role", "") === 'OPT' ?
            {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              forward_authority: "Authority",
              issue_mode: "Issue Mode",
              run: "Run"

            } : {
              reason_id: "Reason",
              action: "Action",
              remark: "Remark",
              forward_province: "Province",
              forward_role: "Role",
              issue_mode: "Issue Mode",
              run: "Run"

            }) : {
            reason_id: "Reason",
            action: "Action",
            remark: "Remark",
            issue_mode: "Issue Mode",
            run: "Run"
          }) : {
            "reason_id": "Reason",
            "remark": "Remark",
            "forward_province": "Forward Province",
            "forward_role": "Forward Role",
            "action": "Action",
            "issue_mode": "Issue Mode"

          },
          rules: props.uiType === 'assign&Perform' ? (_get(formState, "Request_Licence_Arrears.action", "") === 'FRD' ? (_get(formState, "Request_Licence_Arrears.forward_role", "") === 'OPT' ? {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
            forward_authority: "required",
          } : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
            forward_role: "required",
            forward_province: "required",
          }) : {
            reason_id: "required",
            action: "required",
            remark: "required|max:500",
          }) : {
            "reason_id": "required",
            "remark": "required|max:500",
            "forward_province": "required",
            "forward_role": "required",
            "action": "required",

          },
          message: props.uiType === 'assign&Perform' ? (_get(formState, "Request_Licence_Arrears.action", "") === 'FRD' ? (_get(formState, "Request_Licence_Arrears.forward_role", "") === 'OPT' ? {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province": "Province is required.",
            "forward_authority": "Authority is required.",
          } : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
            "forward_role.required": "Role is required.",
            "forward_province.required": "Province is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "action.required": "Action is required.",
            "remark.required": "Remark is required.",
          }) : {
            "reason_id.required": "Reason is required.",
            "remark.required": "Remark is required.",
            "forward_province.required": "Forward Province is required.",
            "forward_role.required": "Forward Role is required.",
            "action.required": "Action is required.",

          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${licenceArrearsApi.url}/${props.uiType === 'assign&Perform' ? props.dataSubmittedForApproval.id : vehicle_no}`,
            key: licenceArrearsApi.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {

          if (props.uiType === 'assign&Perform') {
            if (_get(formState, "Request_Licence_Arrears.action", undefined) === undefined) {
              uiAction.setFlashMessage({
                status: true,
                message: `Action is required`,
                messageType: "error",
              });

              return
            }
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["lcn_w_arrears"],
              // current_licence_type: current_licence_type,
              // status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              ui_request_id: props.approvalRequestId,
              // authority_id: authorityId,
              // request_id: fromObject.request_id,
              reason_id: formState['Request_Licence_Arrears-new'].reason_id,
              counter_session_id: '00001',
              forward_authority_service_id: serviceId,
              document_details: [],
              action: fromObject.action == 'FRD' ? {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              } : {
                action: fromObject.action,
                remark: fromObject.remark,
              },
            });
          } else {
            return getDataByFormObject({
              ...fromObject,
              userName: userName,
              currentProvince: currentProvince,
              authority: authority,
              requestType: RequestTypesList["lcn_w_arrears"],
              current_licence_type: current_licence_type,
              status: RequestStatus.APPROVAL_PENDING,
              pdmt_id: pdmtId,
              authority_id: authorityId,
              request_id: fromObject.request_id,
              reason_id: fromObject.reason_id,
              counter_session_id: sessionId ? sessionId : null,
              forward_authority_service_id: serviceId,
              document_details: [],
              action: {
                action: fromObject.action,
                remark: fromObject.remark,
                forward_province: fromObject.forward_province,
                forward_role: fromObject.forward_role,
                forward_authority: fromObject.forward_authority
              }
            });
          }

        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === 'assign&Perform' ? "put" : "post",
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            if (props.uiType === 'assign&Perform') {
              props.refreshMainScreenAfterPerformFn()
              props.onClosePopupFn();
            } else {
              props.refreshMainScreenDataFn()
              props.onClosePopupFn();
            }
          }
          else {
            if (props.uiType === 'assign&Perform') {
              uiAction.setFlashMessage({
                status: true,
                message: `Approval Request Decision Submit Failed. Please Try Again.`,
                messageType: "error",
              });
            }
            else {
              if (response.data.errors.details[0].message === "Pending Workflow already exist.") {
                uiAction.setFlashMessage({
                  status: true,
                  message: response.data.errors.details[0].message,
                  messageType: "error",
                });
              }
              else {
                uiAction.setFlashMessage({
                  status: true,
                  message: `Approval Request Submit Failed. Please Try Again.`,
                  messageType: "error",
                });
              }
            }
          }
        }}
      />
    </Fragment>
  );
};

let recId = ''
const RequestLicenceArrearsUI = (props) => {
  const [formState, formAction] = useContext(FormContext);

  const vehicle_no = _get(formState, "vehicle_search_form.data.vehicle_no", null);
  const formKey = props.formKey;
  const uiType = props.uiType;
  const current_vehicle_province = _get(formState, "vehicle_search_form.data.province_id", null);
  const vehicleProvince = uiType === 'APR' ? _get(formState, "latest_licence_data.data.vehicleData.province_id", null) : current_vehicle_province

  const [requestId, setRequestId] = useState(null);
  useEffect(() => {
    const rId = new Date();
    const requ = rId.valueOf().toString();

    setRequestId(requ.substr(requ.length - 8))
    getNormalLicenceFee()
    if (props.uiType === 'APR') {

      getReqApprovalLicenceTypeData(props)
      getReqApprovalData(props)
    }
    getInsuranceData()
    getVetData()
    getVftData()
    getVrpData()
    getAdminConfigDaysData()
  }, [])

  recId = requestId

  const getReqApprovalData = (props) => {
    callApi(`${licenceInvalidDocuments.url}/get-documents/${props.approvalRequestId}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn("Request_Licence_Arrears_new", "issue_mode", "", result.data.data.issue_mode);
          formAction.changeInputFn("Request_Licence_Arrears_new", "run", "", result.data.data.run);

        }
      })


  }

  const getAdminConfigDaysData = (props) => {
    callApi(`${adminConfigDaysGetApi.url}/${vehicleProvince}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn("vet_validity_period", "days", "", result.data.data.vet_validity_period);
          formAction.changeInputFn("vft_validity_period", "days", "", result.data.data.vft_validity_period);

        }
      })


  }

  const getReqApprovalLicenceTypeData = (props) => {

    callApi(`${getLatestLicenseAPI.url}/${props.dataSubmittedForApproval.vehicle_no}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          formAction.changeInputFn('latest_licence_data', "data", "", result.data.data);

        }

      });
  }

  const getNormalLicenceFee = () => {
    const queryObj = {
      vehicle_no: props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no,
      licence_end_type: 0,
      is_reissuance: false,
      temp_request: false
    };

    callApi(`${calculateLicenceFeeAPI.url}?${fromObjectToQueryString(queryObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          let totArrearsFee = 0;
          let totArrearsFine = 0;
          let totArrearsAddition = 0;
          let totArrearsDeduction = 0;
          let totAddition = 0;
          let totdeduction = 0;
          let totCurrentLicenceFee = 0;
          let totCurrentLicenceFine = 0;
          let arrearsStartDate = null;
          let arrearsEndDate = null;
          let currentLicenceStartDate = null;
          let currentLicenceEndDate = null;

          const serviceCharge = _get(result, "data.service_charge.applied", false) ? _get(result, "data.service_charge.charge", 0) : 0;

          _get(result, "data.addition_deduction_summary", []).forEach(value => {
            if (value.charge_effect_code === "DED") {
              totdeduction += value.total

            }
            if (value.charge_effect_code === "ADD") {
              totAddition += value.total

            }

          });
          _get(result, "data.arrears", []).forEach(value => {
            totArrearsFee += value.fee;
            totArrearsFine += value.fine;
            totArrearsAddition += value.addition_total
            totArrearsDeduction += value.deduction_total
          });

          if (_get(result, "data.current_licences", []).length > 0) {
            _get(result, "data.current_licences", []).forEach(value => {
              totCurrentLicenceFee += value.fee;
              totCurrentLicenceFine += value.fine;
            });
          }
          else {
            totCurrentLicenceFee = _get(result, "data.fee", 0)
          }


          if (_get(result, "data.arrears", []).length > 0) {
            arrearsStartDate = result.data.arrears[0]["start_date"];
            arrearsEndDate = result.data.arrears[result.data.arrears.length - 1]["end_date"];
          }

          if (_get(result, "data.current_licences", []).length > 0) {
            currentLicenceStartDate = result.data.current_licences[0]["start_date"];
            currentLicenceEndDate = result.data.current_licences[result.data.current_licences.length - 1]["end_date"];
          }

          let total = (totArrearsFee + totArrearsFine + totCurrentLicenceFee + totCurrentLicenceFine +
            totAddition + _get(result, "data.additions.current_licence", 0) -
            (totdeduction + _get(result, "data.deductions.current_licence", 0)))
            + serviceCharge;

          if (total.isNaN) {
            total = _get(result, "data.fee", 0)
          }
          formAction.changeInputFn("licence_charges_form", "data", "", result.data);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", totArrearsFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", totArrearsFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsAddition", "", totArrearsAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", totArrearsDeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totAddition", "", totAddition.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", totdeduction.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", totCurrentLicenceFee.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", totCurrentLicenceFine.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", arrearsStartDate);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", arrearsEndDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", currentLicenceStartDate);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", currentLicenceEndDate);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", serviceCharge.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "total", "", total.toFixed(2));
          formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
        } else {
          formAction.changeInputFn("licence_charges_form", "data", "", []);
          formAction.changeInputFn("licence_charges_form", "totArrearsFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsFine", "", 0);
          formAction.changeInputFn("licence_charges_form", " totArrearsAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totArrearsDeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totAddition", "", 0);
          formAction.changeInputFn("licence_charges_form", "totdeduction", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFee", "", 0);
          formAction.changeInputFn("licence_charges_form", "totCurrentLicenceFine", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "arrearsEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceStartDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "currentLicenceEndDate", "", 0);
          formAction.changeInputFn("licence_charges_form", "serviceCharge", "", 0);
          formAction.changeInputFn("licence_charges_form", "total", "", 0);
        }
        formAction.changeInputFn("licence_charges_form", "_onLoad", "", false);
      });
  };

  const getRelatedData = (docData) => {

    const vetCompaniesPromise = sendAPIRequest(`${vetCompanyAPI.url}/list?sort_by=name|asc&active=true`);
    const insuranceCompaniesPromise = sendAPIRequest(`${insuranceListAPI.url}/list?sort_by=name|asc&active=true`);
    const vftCompaniesPromise = sendAPIRequest(`${vftCompanyListAPI.url}/list?sort_by=name|asc&active=true`);
    const vrpCompaniesPromise = sendAPIRequest(`${vrpCompanyListAPI.url}/list?sort_by=name|asc&active=true`);

    Promise.all([vetCompaniesPromise, insuranceCompaniesPromise, vftCompaniesPromise, vrpCompaniesPromise])
      .then(result => {
        const vetCompanies = result[0]['data']
        const insuranceCompanies = result[1]['data']
        const vftCompanies = result[2]['data']
        const vrpCompanies = result[3]['data']

        console.log('CNMPANIES=====>', docData, insuranceCompanies,);
        const theVetCompany = vetCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theInsuranceCompany = insuranceCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theVftCompany = vftCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))
        const theVrpCompany = vrpCompanies.filter(value => value.service['id'] === (docData !== undefined ? docData.service_id : '-'))


        const vetCompany = theVetCompany && theVetCompany.length !== 0 ? theVetCompany[0].service.name : '-'
        const insuranceCompany = theInsuranceCompany && theInsuranceCompany.length !== 0 ? theInsuranceCompany[0].service.name : '-'
        const vftCompany = theVftCompany && theVftCompany.length !== 0 ? theVftCompany[0].service.name : '-'
        const vrpCompany = theVrpCompany && theVrpCompany.length !== 0 ? theVrpCompany[0].service.name : '-'

        if (vetCompany !== '-') {
          formAction.changeInputFn('request-licence-invalid-doc', "vetCompanyData", "", vetCompany);
        }
        if (insuranceCompany !== '-') {
          formAction.changeInputFn('request-licence-invalid-doc', "insCompanyData", "", insuranceCompany);
        }
        if (vftCompany !== '-') {
          formAction.changeInputFn('request-licence-invalid-doc', "vftCompanyData", "", vftCompany);
        }
        if (vrpCompany !== '-') {
          formAction.changeInputFn('request-licence-invalid-doc', "vrpCompanyData", "", vrpCompany);
        }

      });
  };

  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result?.data);
        })
    });
  };

  const getVrpData = () => {
    callApi(`${vrpAPI.url}/${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}?status=Active`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==vrp', result.data);
          // request-licence-invalid-doc.certificate_info_vrp
          formAction.changeInputFn('request-licence-invalid-doc', "certificate_info_vrp", "", result.data.data[0]);
          getRelatedData(result.data.data[0])
        } else {
          console.log('err--->vrp', error);
        }
      })


  }

  const getInsuranceData = () => {
    callApi(`${insuranceAPI.url}?vehicle_number=${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==ins', result.data);
          // request-licence-invalid-doc.certificate_info_insurance
          formAction.changeInputFn('request-licence-invalid-doc', "certificate_info_insurance", "", result.data.data);
          getRelatedData(result.data.data)
        } else {

          console.log('err--->ins', error);
        }
      })


  }

  const getVetData = () => {
    callApi(`${vetAPI.url}?vehicle_reg_no=${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==vet', result.data);
          // request-licence-invalid-doc.certificate_info_vet
          formAction.changeInputFn('request-licence-invalid-doc', "certificate_info_vet", "", result.data.data[0]);
          getRelatedData(result.data.data[0])
        } else {

          console.log('err--->vet', error);
        }
      })


  }

  const getVftData = () => {
    callApi(`${vftCertificateAPI.url}?used=false&type=PSS&sort_by=issued_date|DESC&vehicle_id=${props.uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : _get(formState, "vehicle_search_form.searchKey")}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          console.log('RWWWWW==vft', result.data);
          // request-licence-invalid-doc.certificate_info_vft
          formAction.changeInputFn('request-licence-invalid-doc', "certificate_info_vft", "", result.data.data[0]);
          getRelatedData(result.data.data[0])
        } else {

          console.log('err--->vft', error);
        }
      })


  }

  const vetValidity = new Date(_get(formState, `request-licence-invalid-doc.certificate_info_vet.issued_at`, null))
  if (vetValidity && vetValidity !== null) {
    vetValidity.setDate(vetValidity.getDate() + _get(formState, 'vet_validity_period.days', 0))
  }
  const vftValidity = new Date(_get(formState, `request-licence-invalid-doc.certificate_info_vft.issued_date`, null))
  if (vftValidity && vftValidity !== null) {
    vftValidity.setDate(vftValidity.getDate() + _get(formState, 'vft_validity_period.days', 0))
  }

  const insStatus = _get(formState, `request-licence-invalid-doc.certificate_info_insurance.status`, null)
  const insValidStatus = (insStatus === "ACT") ? 'Valid' : 'Invalid'

  const vetValidTo = new Date(vetValidity).setHours(0, 0, 0, 0)
  const vetStatus = _get(formState, `request-licence-invalid-doc.certificate_info_vet.status`, null)
  const vetUsed = _get(formState, `request-licence-invalid-doc.certificate_info_vet.used`, null)
  const vetValidStatus = (vetValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vetStatus === 1) &&
    (vetUsed === 0) ? 'Valid' : 'Invalid'

  const vftValidTo = new Date(vftValidity).setHours(0, 0, 0, 0)
  const vftStatus = _get(formState, `request-licence-invalid-doc.certificate_info_vft.type`, null)
  const vftUsed = _get(formState, `request-licence-invalid-doc.certificate_info_vft.used`, null)
  const vftValidStatus = (vftValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vftStatus === "PSS") &&
    (vftUsed === 0) ? 'Valid' : 'Invalid'

  const vrpValidTo = new Date(_get(formState, `request-licence-invalid-doc.certificate_info_vrp.expiry_date`, null)).setHours(0, 0, 0, 0)
  const vrpStatus = _get(formState, `request-licence-invalid-doc.certificate_info_vrp.status`, null)
  const vrpUsed = _get(formState, `request-licence-invalid-doc.certificate_info_vrp.used`, null)
  const vrpValidStatus = (vrpValidTo >= new Date().setHours(0, 0, 0, 0)) &&
    (vrpStatus === "Active") &&
    (vrpUsed === 0) ? 'Valid' : 'Invalid'

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <UICard>
          <FormWrapper
            setGroupName={formKey}
            setFormObject={props.uiType === 'APR' ? {
              ui_request_id: props.approvalRequestId,
            } : {
              request_id: _set(formState, `${formKey}.request_id`, requestId),
            }}
          // apiUrl={`${licenceInvalidDocuments.url}/certificates-info/${uiType === 'APR' ? props.dataSubmittedForApproval.vehicle_no : vehicle_no}`}
          //   onRebuildResponseFn={(response)=> {
          //     getRelatedData(response.vrp,response.vft,response.insurance,response.vet)
          //     return {
          //       certificate_info_vrp: response.vrp,
          //     certificate_info_vft: response.vft,
          //     certificate_info_insurance: response.insurance,
          //     certificate_info_vet: response.vet,

          // "_onLoad": true
          //   }
          // }}
          >
            <table className="col-md-12 defaultMarginBottom">
              <tr>
                <td><strong>Current Licence Type:</strong> &nbsp;&nbsp; {LicenceType[`${_get(formState, `latest_licence_data.data.vehicleData.current_license_type.code`, null)}`]} </td>
              </tr>
            </table>
            <table className="col-md-11 defaultMarginTopBottom">
              <tr>
                <th>Arrears Licence Period</th>
                <th className="text-right">Fee (LKR)</th>
                <th className="text-right ">Fine (LKR)</th>
                <th className="text-right ">Additions (LKR)</th>
                <th className="text-right ">Deductions (LKR)</th>
                <th className="text-right ">Total (LKR)</th>
              </tr>
              {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
                <tr>
                  <td className={"text-md-left"}>{_get(formState, `licence_charges_form.arrearsStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; TO &nbsp;&nbsp; {_get(formState, `licence_charges_form.arrearsEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.arrearsEndDate`, ""), "dd/mm/YY") : ""}</td>
                  <td className="text-right ">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)).toFixed(2))}</td>
                  <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)).toFixed(2))}</td>
                  <td className="text-right">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.totArrearsAddition`, 0)).toFixed(2))}</td>
                  <td className="text-right">({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.totArrearsDeduction`, 0))).toFixed(2))})</td>
                  <td className="text-right">{
                    numberThousandSeperator((Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)) +
                      Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)) +
                      Number(_get(formState, `licence_charges_form.totArrearsAddition`, 0)) -
                      Number(_get(formState, `licence_charges_form.totArrearsDeduction`, 0))).toFixed(2))
                  }</td>

                </tr>
                :
                <tr>No data found</tr>
              }
            </table>

            <table className="col-md-11 defaultMarginTopBottom">
              <tr>
                <th>Current Licence Period</th>
                <th className="text-right ">Fee (LKR)</th>
                <th className="text-right ">Fine (LKR)</th>
                <th className="text-right ">Additions (LKR)</th>
                <th className="text-right ">Deductions (LKR)</th>
                <th className="text-right ">Total (LKR)</th>
              </tr>
              {_get(formState, `licence_charges_form.data`, []) && _get(formState, `licence_charges_form.data`, []) !== '' && _get(formState, `licence_charges_form.data`, []).length !== 0 ?
                <tr>
                  <td className={"text-md-left"}>{_get(formState, `licence_charges_form.currentLicenceStartDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceStartDate`, ""), "dd/mm/YY") : ""} &nbsp;&nbsp; TO &nbsp;&nbsp; {_get(formState, `licence_charges_form.currentLicenceEndDate`, null) ? dateObjectToString(_get(formState, `licence_charges_form.currentLicenceEndDate`, ""), "dd/mm/YY") : ""}</td>
                  {/* text-md-right */}
                  <td className="text-right ">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0)).toFixed(2))}</td>
                  <td className="text-right ">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fine_total`, 0)).toFixed(2))}</td>
                  <td className="text-right ">{numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_addition_total`, 0)).toFixed(2))}</td>
                  <td className="text-right ">({numberThousandSeperator(Math.abs(Number(_get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0))).toFixed(2))})</td>
                  <td className="text-right ">{
                    numberThousandSeperator(Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0) +
                      _get(formState, `licence_charges_form.data.current_licence_fine_total`, 0) +
                      _get(formState, `licence_charges_form.data.current_licence_addition_total`, 0) -
                      _get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0)).toFixed(2))
                  }</td>

                </tr>
                :
                <tr>No data found</tr>
              }
            </table>

            <table className="col-md-11 defaultMarginTopBottom">
              <tr>
                <td className={"text-md-right"}><strong>Total Amount (LKR):</strong> &nbsp;&nbsp; {numberThousandSeperator((Number(_get(formState, `licence_charges_form.totArrearsFee`, 0)) +
                  Number(_get(formState, `licence_charges_form.totArrearsFine`, 0)) +
                  Number(_get(formState, `licence_charges_form.totArrearsAddition`, 0)) -
                  Number(_get(formState, `licence_charges_form.totArrearsDeduction`, 0)) + Number(_get(formState, `licence_charges_form.data.current_licence_fee_total`, 0)) +
                  Number(_get(formState, `licence_charges_form.data.current_licence_fine_total`, 0)) +
                  Number(_get(formState, `licence_charges_form.data.current_licence_addition_total`, 0)) -
                  Number(_get(formState, `licence_charges_form.data.current_licence_deduction_total`, 0))).toFixed(2))} </td>
              </tr>
            </table>
            <div className="col-md-12 col-sm-12">

              <table className={"fullWidthDiv"}>
                {((_get(formState, `request-licence-invalid-doc.certificate_info_vet`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_vet.id`, null) && _get(formState, "vehicle_search_form.data.document_validations.vet", false) === true) ||
                  (_get(formState, `request-licence-invalid-doc.certificate_info_insurance`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_insurance.id`, null) && _get(formState, "vehicle_search_form.data.document_validations.insurance", false) === true) ||
                  (_get(formState, `request-licence-invalid-doc.certificate_info_vft`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_vft.id`, null) && _get(formState, "vehicle_search_form.data.document_validations.vft", false) === true) ||
                  (_get(formState, `request-licence-invalid-doc.certificate_info_vrp`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_vrp.id`, null) && _get(formState, "vehicle_search_form.data.document_validations.vrp", false) === true))

                  ?
                  <thead>
                    <tr>
                      <th></th>
                      <th>Status</th>
                      <th>Company</th>
                      <th>Document No</th>
                      <th>Issued Date</th>
                      <th>Expiry Date</th>
                      <th>Validity Expiry Date</th>
                    </tr>
                  </thead>
                  :
                  <tr>No document data found</tr>
                }
                <tbody>
                  {(_get(formState, `request-licence-invalid-doc.certificate_info_vet`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_vet.id`, null)) &&
                    _get(formState, "vehicle_search_form.data.document_validations.vet", false) === true &&
                    <tr>
                      <td><strong>VET</strong></td>
                      <td>{vetValidStatus}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.vetCompanyData`, '-')}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.certificate_info_vet.vet_no`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vet.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vet.issued_at`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vet.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vet.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(vetValidity, "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(vetValidity, "dd/mm/YY") : '-'}</td>
                    </tr>
                  }
                  {(_get(formState, `request-licence-invalid-doc.certificate_info_insurance`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_insurance.id`, null)) &&
                    _get(formState, "vehicle_search_form.data.document_validations.insurance", false) === true &&
                    <tr>
                      <td><strong>Insuarance</strong></td>
                      <td>{insValidStatus}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.insCompanyData`, '-')}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.certificate_info_insurance.reference`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_insurance.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_insurance.issued_at`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_insurance.valid_to`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_insurance.valid_to`, null), "dd/mm/YY") : '-'}</td>
                      <td>-</td>
                    </tr>
                  }
                  {(_get(formState, `request-licence-invalid-doc.certificate_info_vft`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_vft.id`, null)) &&
                    _get(formState, "vehicle_search_form.data.document_validations.vft", false) === true &&
                    <tr>
                      <td><strong>VFT</strong></td>
                      <td>{vftValidStatus}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.vftCompanyData`, '-')}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.certificate_info_vft.certificate_no`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vft.issued_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vft.issued_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vft.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vft.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(vftValidity, "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(vftValidity, "dd/mm/YY") : '-'}</td>
                    </tr>
                  }
                  {(_get(formState, `request-licence-invalid-doc.certificate_info_vrp`, null) !== undefined && _get(formState, `request-licence-invalid-doc.certificate_info_vrp.id`, null)) &&
                    _get(formState, "vehicle_search_form.data.document_validations.vrp", false) === true &&
                    <tr>
                      <td><strong>VRP</strong></td>
                      <td>{vrpValidStatus}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.vrpCompanyData`, '-')}</td>
                      <td>{_get(formState, `request-licence-invalid-doc.certificate_info_vrp.route_permit_no`, null)}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vrp.issued_at`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vrp.issued_at`, null), "dd/mm/YY") : '-'}</td>
                      <td>{dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") !== '01/01/1970' ?
                        dateObjectToString(_get(formState, `request-licence-invalid-doc.certificate_info_vrp.expiry_date`, null), "dd/mm/YY") : '-'}</td>
                      <td>-</td>
                    </tr>
                  }

                </tbody>
              </table>
            </div>

          </FormWrapper>
        </UICard>
      </div>
      <div className="col-md-12 col-sm-12 defaultMarginTop">
        <Reason
          filterKey={'request_licence_licence_arrears'}
          requestType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
          formKey={uiType === 'APR' ? `${formKey}-new` : formKey}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <DocumentsUploader
          isUploadFn={true}
          formGroupName={`${formKey}-upload`}
          tableApiUrl={uiType === 'APR' ? `${uploadDetailGetAPI.url}?request_id=${props.approvalRequestId}` : (requestId ? `${uploadDetailGetAPI.url}?request_id=${requestId}` : null)}
          requestId={uiType === 'APR' ? props.approvalRequestId : requestId}
          uiType={uiType}
        />
      </div>
      <div className="col-md-12 col-sm-12 defaultPaddingTop">
        <Action
          formKey={formKey}
          uiType={uiType}
          dataSubmittedForApproval={props.dataSubmittedForApproval}
        />
      </div>

    </div>
  );
};


const RequestLicenceArrears = () => {
  const formKey = "requestLicenceArrearsKey";
  return (
    <DashboardTemplate
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Licence Type Change" },
      ]}
      pageTitle="Request Licence – Licence with Arrears"
    >
      <RequestLicenceArrearsUI formKey={formKey} />
    </DashboardTemplate>
  );
};

export default RequestLicenceArrears;
export { RequestLicenceArrearsUI, LicenceArrearsSubmitButton };
